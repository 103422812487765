import type { FormId, IForm } from 'services/api/forms/interfaces';
import { type ActionsUnion, createAction, type IActionWithPayload } from 'store/helper';

import type { EntityFetchFailure, EntityFetchSuccess } from '../interfaces';

const ACTION_NAME_PREFIX = 'ENTITIES/FORMS';

export const FormsActionNames = {
  FETCH: `${ACTION_NAME_PREFIX}/FETCH_REQUEST`,
  FETCH_SUCCESS: `${ACTION_NAME_PREFIX}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${ACTION_NAME_PREFIX}/FETCH_FAILURE`,

  UPDATE: `${ACTION_NAME_PREFIX}/UPDATE_REQUEST`,
  UPDATE_SUCCESS: `${ACTION_NAME_PREFIX}/UPDATE_SUCCESS`,
  UPDATE_FAILURE: `${ACTION_NAME_PREFIX}/UPDATE_FAILURE`,
};

export const FormsActions = {
  fetch: <T extends { id: FormId; groupId?: string }>(payload: T): IActionWithPayload<string, T> => {
    return createAction(FormsActionNames.FETCH, payload);
  },
  fetchSuccess: <T extends EntityFetchSuccess<IForm | null>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(FormsActionNames.FETCH_SUCCESS, payload);
  },
  fetchFailure: <T extends EntityFetchFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(FormsActionNames.FETCH_FAILURE, payload);
  },

  update: <T extends { id: FormId; form: IForm; groupId?: string }>(payload: T): IActionWithPayload<string, T> => {
    return createAction(FormsActionNames.UPDATE, payload);
  },
  updateSuccess: <T extends EntityFetchSuccess<IForm | null>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(FormsActionNames.UPDATE_SUCCESS, payload);
  },
  updateFailure: <T extends EntityFetchFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(FormsActionNames.UPDATE_FAILURE, payload);
  },
};

export type FormsAction = ActionsUnion<typeof FormsActions>;
