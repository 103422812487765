import { type SagaIterator } from 'redux-saga';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

import { getConfig } from 'helpers/config';
import { IntegrationGroupPropertiesActions } from 'store/entities/integration-group-properties/actions';
import { type IActionWithPayload } from 'store/helper';

import { SettingsViewActionNames } from './actions';
import { languageViewSaga } from './language/sagas';
import { getSelectedGroupId } from './selectors';

function* setTicketFormForwardingToHelpDesk(action: IActionWithPayload<string, { enabled: boolean }>): SagaIterator {
  const { enabled } = action.payload;
  const selectedGroupId: ReturnType<typeof getSelectedGroupId> = yield select(getSelectedGroupId);
  const clientId = getConfig().helpDeskAppClientId;

  yield put(
    IntegrationGroupPropertiesActions.update({
      namespace: clientId,
      groupId: parseInt(selectedGroupId, 10),
      properties: {
        forwardTicketFormToHelpdesk: enabled,
      },
    }),
  );
}

function* settingsSaga(): SagaIterator {
  yield takeEvery(SettingsViewActionNames.SET_TICKET_FORM_FORWARDING_TO_HELPDESK, setTicketFormForwardingToHelpDesk);
}

export function* settingsViewSaga(): SagaIterator {
  yield all([fork(settingsSaga), fork(languageViewSaga)]);
}
