import { css } from '@emotion/css';

export const mobileNavigation = css`
  z-index: 11000;

  a:hover {
    text-decoration: none;
  }
`;

export const oneImage = css`
  width: 44px;
  height: 44px;
`;
