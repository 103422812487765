// @ts-strict-ignore
import { createSelector } from 'reselect';

import { type Filters } from 'constants/filters/filter';
import { type Route } from 'constants/tickets/navigation-items';
import { type ITicket } from 'interfaces/entities/ticket';
import { createRequestFetchingSelector, type IWithRequestsState } from 'store/requests/selectors';

import { TicketsViewRequestNames } from './actions';
import { type IEmailMapping, type ITicketsViewState } from './interfaces';

export interface IWithTicketsViewState {
  views: {
    tickets: ITicketsViewState;
  };
}

export function getIsFetching(state: IWithTicketsViewState & IWithRequestsState): boolean {
  return createRequestFetchingSelector(['FETCH_COLLECTION_TICKET'])(state);
}

export function getAnyDataFetched(state: IWithTicketsViewState): boolean {
  return state.views.tickets.anyDataFetched;
}

function getSortedTicketsIds(state: IWithTicketsViewState): string[] {
  return state.views.tickets.sortedIds;
}

function getTicketsData(state: IWithTicketsViewState): { [key: string]: ITicket } {
  return state.views.tickets.data;
}

export function getCurrentPage(state: IWithTicketsViewState): number {
  return state.views.tickets.currentPage;
}

export function getTotalPages(state: IWithTicketsViewState): number {
  return state.views.tickets.totalPages;
}

export function getTotalResults(state: IWithTicketsViewState): number {
  return state.views.tickets.totalResults;
}

function getTicketsBySortedIds(sortedTicketIds: string[], tickets: { [key: string]: ITicket }): ITicket[] {
  return sortedTicketIds.map((ticketId: string) => tickets[ticketId]);
}

export const cachedGetTicketsSelector = createSelector(getSortedTicketsIds, getTicketsData, getTicketsBySortedIds);

export function getTickets(state: IWithTicketsViewState): ITicket[] {
  return cachedGetTicketsSelector(state);
}

export function getTicket(state: IWithTicketsViewState): ITicket {
  return state?.views?.tickets?.current;
}

export function getRoute(state: IWithTicketsViewState): Route {
  return state.views.tickets.route;
}

export function getDataFetchedForRoute(state: IWithTicketsViewState): Route {
  return state.views.tickets.dataFetchedForRoute;
}

export function getFilters(state: IWithTicketsViewState): Filters {
  return state.views.tickets.filters;
}

export function getFiltersOrder(state: IWithTicketsViewState): string[] {
  return state.views.tickets.filtersOrder;
}

export function getSelectedTicketIds(state: IWithTicketsViewState): string[] {
  return state.views.tickets.selectedIds;
}

export function getSearchQuery(state: IWithTicketsViewState): string {
  return state.views.tickets.searchQuery;
}

export function getIsBatchUpdating(state: IWithTicketsViewState & IWithRequestsState): boolean {
  return createRequestFetchingSelector(['VIEWS/TICKETS/UPDATE_BATCH'])(state);
}

export function getTicketCounters(state: IWithTicketsViewState): { [key in Route]?: number } {
  return state.views.tickets.counters;
}

export function isSendingMessage(state: IWithTicketsViewState): boolean {
  return state.views.tickets.sendingMessage;
}

export function isUnspamming(state: IWithTicketsViewState): boolean {
  return state.views.tickets.unspamming;
}

export function getEmailsMapping(state: IWithTicketsViewState): IEmailMapping[] {
  return state.views.tickets.emailsMapping;
}

export function isEmailsMappingFetched(state: IWithTicketsViewState): boolean {
  return state.views.tickets.emailsMappingFetched;
}

export const getIsFetchingTicketCounters = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector([TicketsViewRequestNames.FETCH_TICKET_COUNTERS_REQUEST])(state);
