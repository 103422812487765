export const enum WidgetContent {
  Invitation = 'invitation',
  Queue = 'queue',
  Chat = 'chat',
  HomeScreen = 'homescreen',
  HomeScreenOffline = 'homescreen-offline',
  PreChat = 'prechat',
  PostChat = 'postchat',
  Offline = 'offline',
  EyeCatcher = 'eye-catcher',
  OfflineInfo = 'offline-info',
  EmailPromptOnline = 'email-prompt-online',
  EmailPromptOffline = 'email-prompt-offline',
  Greeting = 'greeting',
  Ticket = 'ticket',
  TicketFormSubmitted = 'ticket-form-submitted',
}
