import { createElement } from 'react';

import { render } from 'react-dom';

import { GlobalModalsWrapper } from 'components/global-modals/GlobalModalsWrapper';

export function renderGlobalModals(): void {
  const div = document.createElement('div');

  document.body.appendChild(div);

  render(createElement(GlobalModalsWrapper), div);
}
