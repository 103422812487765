export const enum ChatEventType {
  Attachmment = 'attachment',
  Event = 'event',
  File = 'file',
  FilledForm = 'filled_form',
  Form = 'form',
  HiddenMessage = 'hidden_message',
  Message = 'message',
  RichMessage = 'rich_message',
  SystemMessage = 'system_message',
  Custom = 'custom',
}

export const enum ChatEventSubType {
  ArchivedByInactivity = 'routing.archived_inactive',
  ArchivedCustomerDisconnected = 'archived_customer_disconnected',
  ArchivedDueToNoAgentsAvailable = 'routing.archived_offline',
  ChatClosedDueToInactivity = 'chat_closed_due_to_inactivity',
  ChatRatingComment = 'rating_comment',
  ChatTransferred = 'chat_transferred', // only LC3
  ChatAssignedOther = 'routing.assigned_other',
  CustomerBanned = 'customer_banned',
  CustomerLeftChat = 'visitor_left_chat',
  CustomerTransferredDueToInactivity = 'visitor_transfered_due_to_inactivity',
  Idle = 'routing.idle',
  ManuallyArchivedByAgent = 'manual_archived_agent',
  ManuallyArchivedByCustomer = 'manual_archived_customer',
  CustomerLeft = 'customer_left',
  NewConversationNewChat = 'new_conversation.new_chat',
  NewConversationOldChat = 'new_conversation.old_chat',
  QueuedDueToAgentRemoved = 'routing.unassigned_deleted',
  RateCanceled = 'rating.chat_rating_canceled',
  RateCommentedOnly = 'rating.chat_commented',
  Rated = 'rated',
  RatedOnly = 'rating.chat_rated',
  ReassignedDueToAgentJoin = 'agent_joined',
  TicketCreated = 'ticket_created',
  Transferred = 'transferred', // deprecated in LC3
  TransferredDueToAgentLogout = 'routing.assigned_signed_out',
  TransferredDueToAgentRemoteLogout = 'routing.assigned_remotely_signed_out',
  TransferredDueToAgentRemoved = 'routing.assigned_deleted',
  TransferredDueToInactivity = 'routing.assigned_inactive',
  AutomationBotTextMessage = 'automation_bot_text_message',
  UnassignedRemotelySignedOut = 'routing.unassigned_remotely_signed_out',
  UnassignedSignedOut = 'routing.unassigned_signed_out',
  UnassignedDisconnected = 'routing.unassigned_disconnected',
}

export const EVENT_TYPE_DEFAULT_MESSAGES = {
  [ChatEventType.Attachmment]: (authorName: string) => `${authorName} uploaded an attachment`,
  [ChatEventType.RichMessage]: (authorName: string) => `${authorName} sent a rich message`,
};
