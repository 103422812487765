import { createAction } from 'store/helper';

export enum TabNotificationsActionNames {
  CHATS_COUNT_CLEAR = 'FEATURES/TAB_NOTIFICATIONS/CHATS_COUNT_CLEAR',
  TRIGGER_CHATS_COUNT_RECALCULATE = 'FEATURES/TAB_NOTIFICATIONS/TRIGGER_CHATS_COUNT_RECALCULATE',
}

export const TabNotificationsActions = {
  clearChatNotifications: () => createAction(TabNotificationsActionNames.CHATS_COUNT_CLEAR),
  triggerChatsCountRecalculate: () => createAction(TabNotificationsActionNames.TRIGGER_CHATS_COUNT_RECALCULATE),
};
