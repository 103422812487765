// It can't be const enum
export enum ComponentType {
  Button = 'button',
  LabelValue = 'label_value',
  Title = 'title',
  Link = 'link',
  Line = 'line',
  Tags = 'tags',
  Customer = 'customer',
}

export const enum TitleImageSize {
  Small = 'small',
  Big = 'big',
}
