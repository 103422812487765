import type { SortOrderType } from 'constants/sort-order';
import type { IButtonTarget, ISelectedWidget } from 'interfaces/customer-details';
import type { IArchive } from 'interfaces/entities/archive';
import type { FilterValues } from 'interfaces/filter';
import type {
  IUpdateCustomerInfoPayload,
  UpdateAccessibleThreadNavigation,
} from 'store/entities/customers/interfaces';
import { type ActionsUnion, createAction } from 'store/helper';

import type {
  IArchivesViewCreateTicketPayload,
  IArchivesViewSetNewArchivesCountPayload,
  IArchivesViewSetQueryParamsFromURLPayload,
  IArchivesViewSetArchivesDataPayload,
  IArchivesViewUpdateArchiveTagsPayload,
  IArchivesViewChangeDetailsConfigurationPayload,
  IArchivesViewChangeListModePayload,
  IArchivesViewStartChatPayload,
  IArchivesViewSuperviseChatPayload,
  IArchiveViewsShowModalPayload,
  IArchivesViewDownloadTranscriptPayload,
  IUpdateFilterOperatorPayload,
  ISetArchiveScrollPositionPayload,
  IUpdateCurrentArchiveCustomPropertyPayload,
  IUpdateArchiveCustomPropertyPayload,
} from './interfaces';

export const enum ArchivesViewActionsNames {
  CHANGE_LIST_MODE = 'VIEWS/ARCHIVES/CHANGE_LIST_MODE',
  CREATE_TICKET = 'VIEWS/ARCHIVES/CREATE_TICKET',
  CLOSE_MODAL = 'VIEWS/ARCHIVES/CLOSE_MODAL',
  DOWNLOAD_TRANSCRIPT = 'VIEWS/ARCHIVES/DOWNLOAD_TRANSCRIPT',
  FETCH_BANNED_VISITORS = 'VIEWS/ARCHIVES/FETCH_BANNED_VISITORS',
  FETCH_CURRENT_ARCHIVE_TICKETS = 'VIEWS/ARCHIVES/FETCH_CURRENT_ARCHIVE_TICKETS',
  FETCH_DATA = 'VIEWS/ARCHIVES/FETCH_DATA',
  FETCH_GREETINGS = 'VIEWS/ARCHIVES/FETCH_GREETINGS',
  FETCH_MORE_DATA = 'VIEWS/ARCHIVES/FETCH_MORE_DATA',
  FETCH_TAGS = 'VIEWS/ARCHIVES/FETCH_TAGS',
  MARK_LIST_MODE_TOOLTIP_AS_READ = 'VIEWS/ARCHIVES/MARK_LIST_MODE_TOOLTIP_AS_READ',
  RESET_FILTERS = 'VIEWS/ARCHIVES/RESET_FILTERS',
  RESET_PAGING = 'VIEWS/ARCHIVES/RESET_PAGING',
  RESET_SEARCH_PARAMS = 'VIEWS/ARCHIVES/RESET_SEARCH_PARAMS',
  SEND_TRANSCRIPT = 'VIEWS/ARCHIVES/SEND_TRANSCRIPT',
  SET_CURRENT_ITEM = 'VIEWS/ARCHIVES/SET_CURRENT_ITEM',
  SET_DATA = 'VIEWS/ARCHIVES/SET_DATA',
  SET_DETAILS_CONFIGURATION = 'VIEWS/ARCHIVES/SET_DETAILS_CONFIGURATION',
  SET_NEW_ARCHIVES_COUNT = 'VIEWS/ARCHIVES/SET_NEW_ARCHIVES_COUNT',
  SET_QUERY_PARAMS_FROM_URL = 'VIEWS/ARCHIVES/SET_QUERY_PARAMS_FROM_URL',
  SET_RECENT_LIST_SCROLL_TOP = 'VIEWS/ARCHIVES/SET_LIST_SCROLL_TOP',
  SET_SEARCH_PHRASE = 'VIEWS/ARCHIVES/SET_SEARCH_PHRASE',
  SET_SORT_ORDER = 'VIEWS/ARCHIVES/SET_SORT_ORDER',
  SHOW_MODAL = 'VIEWS/ARCHIVES/SHOW_MODAL',
  START_CHAT = 'VIEWS/ARCHIVES/START_CHAT',
  START_NEW_ARCHIVES_CHECK = 'VIEWS/ARCHIVES/START_NEW_ARCHIVES_CHECK',
  STOP_NEW_ARCHIVES_CHECK = 'VIEWS/ARCHIVES/STOP_NEW_ARCHIVES_CHECK',
  SUPERVISE_CHAT = 'VIEWS/ARCHIVES/SUPERVISE_CHAT',
  UPDATE_ARCHIVE_TAGS = 'VIEWS/ARCHIVES/UPDATE_TAGS',
  UPDATE_ATTACHMENT_SAFETY_CONFIRMATION = 'VIEWS/ARCHIVES/UPDATE_ATTACHMENT_SAFETY_CONFIRMATION',
  UPDATE_CURRENT_ITEM = 'VIEWS/ARCHIVES/UPDATE_CURRENT_ITEM',
  UPDATE_FILTERS = 'VIEWS/ARCHIVES/UPDATE_FILTERS',
  UPDATE_CUSTOMER_INFO = 'VIEWS/ARCHIVES/UPDATE_CUSTOMER_INFO',
  UPDATE_FILTER_OPERATOR = 'VIEWS/ARCHIVES/UPDATE_FILTER_OPERATOR',
  UPDATE_ACCESSIBLE_THREAD_CURSORS = 'VIEWS/ARCHIVES/UPDATE_ACCESSIBLE_THREAD_CURSORS',
  SET_SCROLL_POSITION = 'VIEWS/ARCHIVES/SET_SCROLL_POSITION',
  UPDATE_CURRENT_ARCHIVE_CUSTOM_PROPERTY = 'VIEWS/ARCHIVES/UPDATE_CURRENT_ARCHIVE_CUSTOM_PROPERTY',
  UPDATE_ARCHIVE_CUSTOM_PROPERTY = 'VIEWS/ARCHIVES/UPDATE_ARCHIVE_CUSTOM_PROPERTY',
  SET_WIDGET_FROM_BUTTON = 'VIEWS/ARCHIVES/SET_WIDGET_FROM_BUTTON',
  OPEN_WIDGET_FROM_MORE_MENU_BUTTON = 'VIEWS/ARCHIVES/OPEN_WIDGET_FROM_MORE_MENU_BUTTON',
}

export const ArchivesViewActions = {
  fetchArchives: () => createAction(ArchivesViewActionsNames.FETCH_DATA),
  fetchCurrentArchiveTickets: () => createAction(ArchivesViewActionsNames.FETCH_CURRENT_ARCHIVE_TICKETS),
  fetchMoreArchives: () => createAction(ArchivesViewActionsNames.FETCH_MORE_DATA),
  fetchGreetings: () => createAction(ArchivesViewActionsNames.FETCH_GREETINGS),
  fetchTags: () => createAction(ArchivesViewActionsNames.FETCH_TAGS),
  setArchivesData: (payload: IArchivesViewSetArchivesDataPayload) =>
    createAction(ArchivesViewActionsNames.SET_DATA, payload),
  setCurrentItem: (current: IArchive) => createAction(ArchivesViewActionsNames.SET_CURRENT_ITEM, { current }),
  updateCurrentItem: (id: string) => createAction(ArchivesViewActionsNames.UPDATE_CURRENT_ITEM, { id }),
  setSortOrder: (sortOrder: SortOrderType) => createAction(ArchivesViewActionsNames.SET_SORT_ORDER, { sortOrder }),
  setSearchPhrase: (searchPhrase: string, shouldClearFilters?: boolean) =>
    createAction(ArchivesViewActionsNames.SET_SEARCH_PHRASE, { searchPhrase, shouldClearFilters }),
  setNewArchivesCount: (payload: IArchivesViewSetNewArchivesCountPayload) =>
    createAction(ArchivesViewActionsNames.SET_NEW_ARCHIVES_COUNT, payload),
  startNewArchivesCheck: () => createAction(ArchivesViewActionsNames.START_NEW_ARCHIVES_CHECK),
  stopNewArchivesCheck: () => createAction(ArchivesViewActionsNames.STOP_NEW_ARCHIVES_CHECK),
  resetFilters: () => createAction(ArchivesViewActionsNames.RESET_FILTERS),
  resetSearch: () => createAction(ArchivesViewActionsNames.RESET_SEARCH_PARAMS),
  setRecentListScrollTop: (scrollTop: number) =>
    createAction(ArchivesViewActionsNames.SET_RECENT_LIST_SCROLL_TOP, { scrollTop }),
  createTicket: (payload: IArchivesViewCreateTicketPayload) =>
    createAction(ArchivesViewActionsNames.CREATE_TICKET, payload),
  updateFilters: (filter: { name: string; value: FilterValues }) =>
    createAction(ArchivesViewActionsNames.UPDATE_FILTERS, { filter }),
  sendTranscript: (chatId: string, threadId: string, email: string, shouldIncludeAllDetails: boolean) =>
    createAction(ArchivesViewActionsNames.SEND_TRANSCRIPT, {
      chatId,
      threadId,
      email,
      shouldIncludeAllDetails,
    }),
  downloadTranscript: (payload: IArchivesViewDownloadTranscriptPayload) =>
    createAction(ArchivesViewActionsNames.DOWNLOAD_TRANSCRIPT, payload),
  setQueryParamsFromURL: (payload: IArchivesViewSetQueryParamsFromURLPayload) =>
    createAction(ArchivesViewActionsNames.SET_QUERY_PARAMS_FROM_URL, payload),
  updateArchiveTags: (payload: IArchivesViewUpdateArchiveTagsPayload) =>
    createAction(ArchivesViewActionsNames.UPDATE_ARCHIVE_TAGS, payload),
  setDetailsConfiguration: (payload: IArchivesViewChangeDetailsConfigurationPayload) =>
    createAction(ArchivesViewActionsNames.SET_DETAILS_CONFIGURATION, payload),
  changeListMode: (payload: IArchivesViewChangeListModePayload) =>
    createAction(ArchivesViewActionsNames.CHANGE_LIST_MODE, payload),
  markListModeTooltipAsRead: () => createAction(ArchivesViewActionsNames.MARK_LIST_MODE_TOOLTIP_AS_READ),
  startChat: (payload: IArchivesViewStartChatPayload) => createAction(ArchivesViewActionsNames.START_CHAT, payload),
  superviseChat: (payload: IArchivesViewSuperviseChatPayload) =>
    createAction(ArchivesViewActionsNames.SUPERVISE_CHAT, payload),
  showModal: (payload: IArchiveViewsShowModalPayload) => createAction(ArchivesViewActionsNames.SHOW_MODAL, payload),
  closeModal: () => createAction(ArchivesViewActionsNames.CLOSE_MODAL),
  updateCustomerInfo: (payload: IUpdateCustomerInfoPayload) =>
    createAction(ArchivesViewActionsNames.UPDATE_CUSTOMER_INFO, payload),
  resetPaging: () => createAction(ArchivesViewActionsNames.RESET_PAGING),
  updateAccessibleThreadNavigation: (payload: UpdateAccessibleThreadNavigation) =>
    createAction(ArchivesViewActionsNames.UPDATE_ACCESSIBLE_THREAD_CURSORS, payload),
  updateFilterOperator: (payload: IUpdateFilterOperatorPayload) =>
    createAction(ArchivesViewActionsNames.UPDATE_FILTER_OPERATOR, payload),
  setScrollPosition: (payload: ISetArchiveScrollPositionPayload) =>
    createAction(ArchivesViewActionsNames.SET_SCROLL_POSITION, payload),
  updateCurrentArchiveCustomProperty: (payload: IUpdateCurrentArchiveCustomPropertyPayload) =>
    createAction(ArchivesViewActionsNames.UPDATE_CURRENT_ARCHIVE_CUSTOM_PROPERTY, payload),
  updateArchiveCustomProperty: (payload: IUpdateArchiveCustomPropertyPayload) =>
    createAction(ArchivesViewActionsNames.UPDATE_ARCHIVE_CUSTOM_PROPERTY, payload),
  setWidgetFromButton: (selectedWidget: ISelectedWidget) =>
    createAction(ArchivesViewActionsNames.SET_WIDGET_FROM_BUTTON, selectedWidget),
  openWidgetFromMoreMenuButton: (buttonTarget: IButtonTarget) =>
    createAction(ArchivesViewActionsNames.OPEN_WIDGET_FROM_MORE_MENU_BUTTON, buttonTarget),
};

export type ArchivesViewAction = ActionsUnion<typeof ArchivesViewActions>;
