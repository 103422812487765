// @ts-strict-ignore
export function compact(obj: Record<string, any>): Record<string, any> {
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key]) {
      result[key] = obj[key];
    }

    return result;
  }, {});
}

// Flattens nested object
// source: https://gist.github.com/penguinboy/762197
export function flatten(obj: Record<string, any>): Record<string, any> {
  const toReturn = {};

  for (const i in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj.hasOwnProperty(i)) continue;

    if (typeof obj[i] === 'object') {
      const flatObject = flatten(obj[i]);

      for (const x in flatObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = obj[i];
    }
  }

  return toReturn;
}

export function ensureFlat<T>(flatOrArray: T | T[]): T {
  if (flatOrArray === null) {
    return null;
  }

  if (flatOrArray === undefined) {
    return undefined;
  }

  return Array.isArray(flatOrArray) ? flatOrArray[0] : flatOrArray;
}

export function ensureArray<T>(flatOrArray: T | T[]): T[] {
  if (flatOrArray === null) {
    return null;
  }

  if (flatOrArray === undefined) {
    return undefined;
  }

  return Array.isArray(flatOrArray) ? flatOrArray : [flatOrArray];
}

export function isNullOrNotArray(valueToCheck): boolean {
  return !valueToCheck || !Array.isArray(valueToCheck);
}

export function splitAt<T = string>(data: T, index: number): T[];
export function splitAt<T>(data: T[], index: number): T[][] {
  return [data.slice(0, index), data.slice(index)];
}
