import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadExists } from 'store/entities/chats/selectors';

import { type IIncomingSneakPeekPushEvent } from '../interfaces';

/**
 * Handles incoming sneak peek push event.
 * 1. Update sneak peek value for the thread.
 * @param {IIncomingSneakPeekPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleIncomingSneakPeek(payload: IIncomingSneakPeekPushEvent, store: IAppState): void {
  const state = store.getState();
  const threadExist = getThreadExists(state, payload.thread_id);

  if (threadExist) {
    /**
     * 1. Update sneak peek value for the thread.
     */
    store.dispatch(
      ChatsEntitiesActions.setSneakPeek({
        threadId: payload.thread_id,
        text: payload.sneak_peek.text,
      })
    );
  }
}
