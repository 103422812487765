import type { SagaIterator } from 'redux-saga';

import type { FiltersOperators } from 'constants/filters/filter';
import type { ReportDistribution } from 'constants/reports/distribution';
import type { ReportType } from 'constants/reports/report-type';
import type { ViewActionSource } from 'constants/view-actions-source';
import type { KeyMap } from 'helpers/interface';
import type { RequestResult } from 'interfaces/api/client';
import type { TicketCreateParams } from 'interfaces/api/ticket';
import type {
  ISerializedFilters,
  ISerializedFiltersWithRawDates,
  IReportCreatePayload,
  IScheduledReportEdit,
  IScheduledReportDelete,
  ReportsViewFilters,
  IHeatmapFilter,
} from 'interfaces/reports';
import type { ReportResponse, SerializedJSONFilters, ReportData } from 'interfaces/reports/api-v3';
import type { IActionWithPayload } from 'store/helper';

import type { RequestFailure } from '../interfaces';

export const enum HeatmapPeriod {
  Previous = 'previous',
  Next = 'next',
}

export interface IFetchReportPayload {
  reportType: ReportType;
  source?: ViewActionSource;
  filters?: ReportsViewFilters;
  filtersOperators?: FiltersOperators;
  distribution?: ReportDistribution;
  shouldBenchmarkFetch?: boolean;
  retry?: number;
  period?: HeatmapPeriod;
  page?: number;
}

export interface IFetchReportSuccessPayload<TData = any> extends IFetchReportPayload {
  data: TData;
  benchmarkData?: TData;
  heatmapFilters?: IHeatmapFilter;
}

export interface IFetchReportFailurePayload extends IFetchReportPayload, RequestFailure {
  action: IActionWithPayload<string, any>;
  source?: ViewActionSource;
}

export interface ICreateReportPayload {
  data: IReportCreatePayload;
  source?: ViewActionSource;
}

export interface ICreateReportSuccessPayload extends ICreateReportPayload {}

export interface ICreateReportFailurePayload extends ICreateReportPayload, RequestFailure {
  source?: ViewActionSource;
}

export interface IEditReportPayload {
  data: IScheduledReportEdit;
  source?: ViewActionSource;
}

export interface IEditReportSuccessPayload extends IEditReportPayload {}

export interface IEditReportFailurePayload extends IEditReportPayload, RequestFailure {
  source?: ViewActionSource;
}

export interface IDeleteReportPayload {
  data: IScheduledReportDelete;
  source?: ViewActionSource;
}

export interface IDeleteReportSuccessPayload extends IDeleteReportPayload {}

export interface IDeleteReportFailurePayload extends IDeleteReportPayload, RequestFailure {
  source?: ViewActionSource;
}

export interface ICreateTicketFromQueueAbandonmentPayload {
  data: TicketCreateParams;
}

export interface ICreateTicketFromQueueAbandonmentSuccessPayload {
  ticketId: string;
}

export interface ICreateTicketFromQueueAbandonmentFailurePayload
  extends ICreateTicketFromQueueAbandonmentPayload,
    RequestFailure {}

export interface IDataSet {
  filters: ISerializedFilters;
  filterSetId: number;
  request: IReportRequest;
  data: KeyMap;
}

export interface ISetsResolver {
  filtersSets: ISerializedFilters[];
  dataSets: IDataSet[];
}

type ISetsResolverFactory = (
  reportType: ReportType,
  requests: IReportRequest[],
  { filters, distribution }: IFetchReportPayload,
  extendedFilters
) => ISetsResolver | SagaIterator<ISetsResolver>;

type IResponseResolver<T = KeyMap, U = KeyMap> = (
  responses: RequestResult<KeyMap | ReportResponse<KeyMap>>[],
  requests: IReportRequest[],
  filterSetIndex: number,
  filtersSets: ISerializedFilters[]
) => KeyMap<ReportData<T, U>>;

export interface IFetchReportOptions {
  extendedFilters?: ISerializedFilters;
  setsResolver?: ISetsResolverFactory;
  responseResolver?: IResponseResolver;
  apiFiltersSerializer?: (filters: ISerializedFilters) => ISerializedFilters;
}

export enum ReportRequestName {
  Goals = 'goals',
  Activity = 'activity',
  AgentsChattingTime = 'agentsChattingTime',
  Performance = 'performance',
  ChatAvailability = 'chatAvailability',
  ChatDuration = 'chatDuration',
  ChatEngagement = 'chatEngagement',
  QueuedCustomers = 'queuedCustomers',
  WaitingTime = 'waitingTime',
  ChatRatingResponse = 'chatRatingResponse',
  ChatRankingResponse = 'chatRankingResponse',
  FirstResponseTime = 'firstResponseTime',
  ResponseTime = 'responseTime',
  ChatSurveys = 'chatSurveys',
  GreetingsConversion = 'greetingsConversion',
  MissedChats = 'missedChats',
  NewTickets = 'newTickets',
  TicketSources = 'ticketSources',
  ScheduledReports = 'scheduledReports',
  SolvedTickets = 'solvedTickets',
  AgentOccupancy = 'agentOccupancy',
  TagsUsage = 'tagsUsage',
  Tagged = 'tagged',
  Untagged = 'untagged',
  TicketsRatingResponse = 'ticketsRatingResponse',
  TicketsRankingResponse = 'ticketsRankingResponse',
  ResolutionTime = 'resolutionTime',
  TotalChats = 'totalChats',
}

type IRequestInterfaceV2 = (filters?: ISerializedFiltersWithRawDates) => Promise<RequestResult<KeyMap>>;
type IRequestInterfaceV3 = (filters?: SerializedJSONFilters) => Promise<RequestResult<ReportData<KeyMap>>>;

export enum RequestVersion {
  V2 = 'v2',
  V3 = 'v3',
}
export interface IReportRequest {
  version?: RequestVersion;
  name: ReportRequestName;
  interface: IRequestInterfaceV2 | IRequestInterfaceV3;
  extendedFilters?: ISerializedFilters;
  disableComparison?: boolean;
}

export const enum AgentsPerformanceMetric {
  TotalChats = 'totalChats',
  Satisfaction = 'satisfaction',
  FirstResponseTime = 'firstResponseTime',
  Efficiency = 'efficiency',
}

export type ReportTargets = {
  [AgentsPerformanceMetric.Satisfaction]?: number;
  [AgentsPerformanceMetric.FirstResponseTime]?: number;
  [AgentsPerformanceMetric.Efficiency]?: number;
};
