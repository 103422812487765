import { type SagaIterator } from 'redux-saga';
import { all, put, takeEvery } from 'redux-saga/effects';

import { GroupActionNames } from 'store/entities/groups/actions';
import { TeamActions } from 'store/views/team/actions';

function* clearSelectedGroupId(): SagaIterator {
  yield put(TeamActions.setSelectedEntityFor('groups', null));
}

export function* teamSagas(): SagaIterator {
  yield all([takeEvery(GroupActionNames.GROUP_REMOVE, clearSelectedGroupId)]);
}
