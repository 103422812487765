import { createSelector } from 'reselect';

import type { IStoreState } from 'interfaces/store/store-state';
import { createRequestFetchingSelector } from 'store/requests/selectors';

export const getTechnologies = (state: IStoreState): string[] => state.views.installCode.technologies;

export const getIsFetchingTechnologies = (state: IStoreState): boolean =>
  createRequestFetchingSelector(['VIEWS/ONBOARDING/FETCH_TECHNOLOGIES'])(state);

export const getIsFetchingTechnologiesForInstructions = (state: IStoreState): boolean =>
  createRequestFetchingSelector(['VIEWS/ONBOARDING/FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS'])(state);

export const hasVisitedInstallCode = (state: IStoreState): boolean => state.views.installCode.installCodeVisited;

export const getWasCtaClicked = (state: IStoreState): boolean => state.views.installCode.wasCtaClicked;

export const getShouldShowManualInstallation = createSelector(
  getTechnologies,
  getIsFetchingTechnologies,
  (technologies, isFetchingTechnologies) => {
    return technologies.length === 0 || isFetchingTechnologies;
  }
);
