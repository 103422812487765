import type { EntityFetchSuccess, RequestFailure } from 'store/entities/interfaces';
import { type ActionsUnion, createAction, type IAction, type IActionWithPayload } from 'store/helper';

import { type IApiTrustedDomain, type ITrustedDomain, type ITrustedDomainsEntityState } from './interfaces';

export const ACTION_NAME_PREFIX = 'ENTITIES/TRUSTED_DOMAINS';

export const TrustedDomainsActionNames = {
  FETCH: `${ACTION_NAME_PREFIX}/FETCH_REQUEST`,
  FETCH_SUCCESS: `${ACTION_NAME_PREFIX}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${ACTION_NAME_PREFIX}/FETCH_FAILURE`,
  REMOVE_TRUSTED: `${ACTION_NAME_PREFIX}/REMOVE_TRUSTED`,
  REMOVE_TRUSTED_SUCCESS: `${ACTION_NAME_PREFIX}/REMOVE_TRUSTED_SUCCESS`,
  ADD_TRUSTED: `${ACTION_NAME_PREFIX}/ADD_TRUSTED`,
  ADD_TRUSTED_SUCCESS: `${ACTION_NAME_PREFIX}/ADD_TRUSTED_SUCCESS`,
  REMOVE_SUGGESTED: `${ACTION_NAME_PREFIX}/REMOVE_SUGGESTED`,
};
export const TrustedDomainsActions = {
  fetch: (): IAction => createAction(TrustedDomainsActionNames.FETCH),
  fetchSuccess: <T extends Omit<EntityFetchSuccess<ITrustedDomainsEntityState>, 'id'>>(
    payload: T
  ): IActionWithPayload<string, T> => createAction(TrustedDomainsActionNames.FETCH_SUCCESS, payload),
  fetchFailure: <T extends RequestFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(TrustedDomainsActionNames.FETCH_FAILURE, payload);
  },
  removeTrusted: (payload: Pick<ITrustedDomain, 'domain'>) =>
    createAction(TrustedDomainsActionNames.REMOVE_TRUSTED, payload),
  removeTrustedSuccess: (payload: Pick<ITrustedDomain, 'domain'>) =>
    createAction(TrustedDomainsActionNames.REMOVE_TRUSTED_SUCCESS, payload),
  addTrusted: (payload: Pick<ITrustedDomain, 'domain'>) => createAction(TrustedDomainsActionNames.ADD_TRUSTED, payload),
  addTrustedSuccess: (payload: IApiTrustedDomain) =>
    createAction(TrustedDomainsActionNames.ADD_TRUSTED_SUCCESS, payload),
  removeSuggested: (payload: { domain: string }) => createAction(TrustedDomainsActionNames.REMOVE_SUGGESTED, payload),
};

export type TrustedDomainsAction = ActionsUnion<typeof TrustedDomainsActions>;
