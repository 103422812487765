export const enum JSONIncomingEvent {
  CannedResponseAdd = 'canned_response_add',
  CannedResponseRemove = 'canned_response_remove',
  CannedResponseUpdate = 'canned_response_update',
  ChatDetailsUpdate = 'chat',
  ChatStatusChange = 'chat_status',
  GroupPropertiesUpdate = 'group_properties_update',
  IncomingChatAttachment = 'chat_attachment',
  MessageSeen = 'message_seen',
  IncomingPostChatSurvey = 'post_chat_survey',
  QueuedVisitor = 'queued_visitor',
  ChatRateComment = 'rating_comment',
  SendMessageResponse = 'send_message_response',
  TicketSummaryUpdate = 'ticket_summary_update',
}
