import { type ActionsUnion, createAction } from 'store/helper';

import { type SetNetworkIssuesPayload, type SetSignInErrorPayload, type SetUnexpectedErrorPayload } from './interfaces';

export const enum StartupErrorActionNames {
  SET_UNEXPECTED_ERROR = 'VIEWS/STARTUP_ERROR/SET_UNEXPECTED_ERROR',
  SET_SIGN_IN_ERROR = 'VIEWS/STARTUP_ERROR/SET_SIGN_IN_ERROR',
  SET_NETWORK_ISSUES = 'VIEWS/STARTUP_ERROR/SET_NETWORK_ISSUES',
}

export const StartupErrorActions = {
  setUnexpectedError: (payload: SetUnexpectedErrorPayload) =>
    createAction(StartupErrorActionNames.SET_UNEXPECTED_ERROR, payload),
  setSignInError: (payload: SetSignInErrorPayload) => createAction(StartupErrorActionNames.SET_SIGN_IN_ERROR, payload),
  setNetworkIssues: (payload: SetNetworkIssuesPayload) =>
    createAction(StartupErrorActionNames.SET_NETWORK_ISSUES, payload),
};

export type StartupErrorAction = ActionsUnion<typeof StartupErrorActions>;
