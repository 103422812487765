import type { FC, ReactElement } from 'react';

import { Badge, Text } from '@livechat/design-system-react-components';

import { SIDE_NAVIGATION_PARENT_BADGE_TEST_ID } from 'components/side-navigation/constants';
import { hasItemBadge } from 'helpers/settings';
import type { ISideNavigationItem } from 'interfaces/side-navigation-item';

import * as styles from '../styles';

interface IProps {
  item: ISideNavigationItem;
  isMainEntry?: boolean;
  isListOpen?: boolean;
  isActive?: boolean;
}

export const SideNavigationItemLabelWithBadge: FC<IProps> = ({
  item,
  isActive,
  isMainEntry,
  isListOpen,
}): ReactElement => {
  const childrenBadgeCount = item.children?.reduce((sum, child) => sum + (child.badgeCount?.() || 0), 0);
  const childHasBadge = Boolean(item.children?.find(({ badge, badgeCount }) => Boolean(badge) || badgeCount?.()));
  const rowHasBadge = item.badge || Boolean(item.badgeCount?.()) || (childHasBadge && !isListOpen);
  const customBadgeType = item.badgeCount?.() || childrenBadgeCount ? 'counter' : 'alert';

  return (
    <div className={styles.labelWithBadgeWrapper}>
      <Text className={styles.labelWithBadge} bold={isActive || isMainEntry}>{item.name}</Text>
      {item.number && <Badge type="counter" count={item.number} />}
      {(hasItemBadge(item) || rowHasBadge) && (
        <Badge
          size="compact"
          type={customBadgeType}
          count={item.badgeCount?.() || childrenBadgeCount}
          data-testid={SIDE_NAVIGATION_PARENT_BADGE_TEST_ID}
        />
      )}
    </div>
  );
};
