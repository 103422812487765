import { MODAL_FEATURES } from 'constants/plan-downgrade-modal-info';
import { PlanType } from 'constants/plan-type';

const plans = [PlanType.Starter, PlanType.Team, PlanType.Business, PlanType.EnterprisePlus];

function normalize(plan: string): string {
  return plan.toLowerCase().replace(' ', '');
}

function getPlanIndex(plan: string): number {
  return plans.findIndex((el) => normalize(el) === normalize(plan));
}

export function isDowngrade(currentPlan: string, formerPlan: string): boolean {
  return getPlanIndex(currentPlan) < getPlanIndex(formerPlan);
}

export function isUpsell(currentPlan: string, formerPlan: string): boolean {
  return getPlanIndex(currentPlan) > getPlanIndex(formerPlan);
}

export function getModalInfo(currentPlan: string, formerPlan: string): string[] | null {
  if (!isDowngrade(currentPlan, formerPlan)) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return MODAL_FEATURES[formerPlan][currentPlan] as string[];
}
