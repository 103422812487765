import type { FC, ReactNode } from 'react';

import { DetailsCard, Heading } from '@livechat/design-system-react-components';

import * as styles from './styles';

interface IProps {
  title: string;
  children: ReactNode;
  openOnInit?: boolean;
}

export const CopilotAccordion: FC<IProps> = ({ title, children, openOnInit }) => (
  <DetailsCard
    openOnInit={openOnInit}
    className={styles.detailsCard}
    label={
      <div>
        <Heading size="xs">{title}</Heading>
      </div>
    }
  >
    <div>{children}</div>
  </DetailsCard>
);
