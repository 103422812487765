// @ts-strict-ignore
import includes from 'lodash.includes';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import union from 'lodash.union';
import uniq from 'lodash.uniq';
import without from 'lodash.without';

import { ChatThreadVisualStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { UserType } from 'constants/user-type';
import { removeDuplicates } from 'helpers/array';
import { type GroupedEventsIds, getGroupedEventsIds, getGroupedEventsIdsForNewEvent } from 'helpers/chat-events-groups';
import { type KeyMap } from 'helpers/interface';
import { deepMerge } from 'helpers/object';
import { uniqueId } from 'helpers/string';
import { type IUpdateAttachmentSafetyConfirmationPayload } from 'store/features/attachments/interfaces';

import type {
  ChatEventEntity,
  ChatThreadEntity,
  IAddChatUsersPayload,
  IAddNewUnassignedThreadsPayload,
  IChatHistoryBase,
  IChatThreadDetails,
  IChatsState,
  IClearAgentSneakPeekPayload,
  IFetchChatHistoryCompletedPayload,
  IIncomingChatThreadPayload,
  IMarkChatAsImportantPayload,
  IMarkMultipleChatsAsImportantPayload,
  INewAttachmentMessagePayload,
  INewMessagePayload,
  INewSurveyPayload,
  INewSystemMessagePayload,
  IQueuedChat,
  IRemoveChatThreadPayload,
  ISaveTagSuggestionsPayload,
  ISetAgentSneakPeekPayload,
  ISetChatHistoryTimelinePayload,
  ISetChatsDataPayload,
  ISetSneakPeekPayload,
  ISetThreadsEventsPayload,
  ISortChatList,
  IStoreTagPayload,
  IUnmarkChatAsImportantPayload,
  IUpdateChatPayload,
  IUpdateChatSupervisorsPayload,
  IUpdateChatTagsPayload,
  IUpdateChatThreadPayload,
  IUpdateEventPayload,
  IUpdateEventsPayload,
  IUpdateMessagePayload,
  IUpdateQueuePositionsPayload,
  IUpdateUnassignedChatsCountPayload,
} from '../interfaces';

import { getThreadVisualStatus, isActiveThread, isActiveUnassignedChat, isUnassignedChat } from './common';

const withoutThreadId =
  (threadId: string) =>
  (id: string): boolean =>
    id !== threadId;

const withoutThreadIds =
  (threadIds: string[]) =>
  (id: string): boolean =>
    !threadIds.includes(id);

const withThreadId =
  (threadId: string) =>
  (id: string): boolean =>
    id === threadId;

type ChatIds = Pick<IChatsState, 'unassignedIds' | 'myChatsIds' | 'queuedIds' | 'supervisedIds' | 'otherAgentsChatIds'>;
export type ChatIdsKeys = keyof ChatIds;

export const CHAT_TYPE_TO_STATE_IDS_MAP: Record<ChatType, ChatIdsKeys> = {
  [ChatType.My]: 'myChatsIds',
  [ChatType.Queued]: 'queuedIds',
  [ChatType.Supervised]: 'supervisedIds',
  [ChatType.Unassigned]: 'unassignedIds',
  [ChatType.Other]: 'otherAgentsChatIds',
};

const EMPTY_ARRAY = [];

function getIdsForChatType(chats: KeyMap<ChatThreadEntity>, type: ChatType): string[] {
  return Object.keys(chats).filter((id) => chats[id].type === type);
}

function getThreadIdsState(threads: KeyMap<ChatThreadEntity>): ChatIds {
  return {
    unassignedIds: getIdsForChatType(threads, ChatType.Unassigned),
    myChatsIds: getIdsForChatType(threads, ChatType.My),
    queuedIds: getIdsForChatType(threads, ChatType.Queued),
    supervisedIds: getIdsForChatType(threads, ChatType.Supervised),
    otherAgentsChatIds: getIdsForChatType(threads, ChatType.Other),
  };
}

function getThreadsGroupedEventsIds(
  threads: KeyMap<ChatThreadEntity | IChatHistoryBase>,
  events: KeyMap<KeyMap<ChatEventEntity>>,
): KeyMap<GroupedEventsIds> {
  return Object.values(threads).reduce((acc, thread) => {
    const threadEvents = events[thread.threadId];

    return { ...acc, [thread.threadId]: getGroupedEventsIds(threadEvents) };
  }, {});
}

/**
 * Determines the chat type based on the visual status of the chat thread. This function is used within the context of a React application using react-redux for state management, redux-saga for side effects, and @tanstack/query for data fetching.
 * @param {ChatThreadVisualStatus} visualStatus - The visual status of the chat thread. This value is typically derived from the redux state.
 * @param {ChatType} defaultChatType - The default chat type to return if the visual status does not match any known statuses. This can be used to control the default behavior of the chat UI.
 * @returns {ChatType} - The chat type corresponding to the given visual status, or the default chat type if the visual status does not match any known statuses.
 * @example
 * ```
 * const visualStatus = selectVisualStatus(state);
 * const defaultChatType = ChatType.My;
 * const chatType = getChatTypeFromVisualStatus(visualStatus, defaultChatType);
 * ```
 * @remarks
 * This function uses a lookup object to map visual statuses to chat types, which is a common pattern for handling this type of logic in JavaScript. It's a pure function with no side effects, so it can be used freely anywhere in your code where you need to determine the chat type based on the visual status.
 * @see `ChatThreadVisualStatus` - The enum used for the `visualStatus` parameter.
 * @see `ChatType` - The enum used for the `defaultChatType` parameter and the return type.
 */
export function getChatTypeFromVisualStatus(visualStatus: ChatThreadVisualStatus, defaultChatType: ChatType): ChatType {
  const visualStatusToChatType: Record<ChatThreadVisualStatus, ChatType> = {
    [ChatThreadVisualStatus.MyChatVisuallyClosed]: ChatType.My,
    [ChatThreadVisualStatus.QueuedChatVisuallyClosed]: ChatType.Queued,
  };

  return visualStatusToChatType[visualStatus] ?? defaultChatType;
}

/**
 * Adds a thread id to a specific array based on the chat type and removes it from all other arrays.
 * This ensures that there are no duplicate entries across arrays.
 * @param {IChatsState} state - The current state of chat entities.
 * @param {string} threadId - The id of the thread to be updated.
 * @param {ChatType} type - The type of the thread that is being added.
 * @param {ChatThreadVisualStatus} visualStatus - The visual status of the thread (optional).
 * @returns {IChatsState} - The new state with the thread id added to the correct array and removed from all others.
 * @example
 * ```
 * const state = {
 *   myChatsIds: ['100', '101', '102'],
 *   queuedIds: ['200', '201', '202'],
 *   supervisedIds: ['300', '301', '302'],
 *   unassignedIds: ['400', '401', '402'],
 *   otherAgentsChatIds: ['500', '501', '502'],
 * };
 * const threadId = '700';
 * const type = ChatType.My;
 * const visualStatus = ChatThreadVisualStatus.QueuedChatVisuallyClosed;
 * const newState = getStateForNewThreadId(state, threadId, type, visualStatus);
 * ```
 * @remarks
 * If the thread id is already in the correct array, it will not be added again. If the thread id is in any other arrays, it will be removed.
 * @see CHAT_TYPE_TO_STATE_IDS_MAP
 */
export function getStateForNewThreadId(
  state: IChatsState,
  threadId: string,
  type: ChatType,
  visualStatus?: ChatThreadVisualStatus,
): IChatsState {
  const chatType = getChatTypeFromVisualStatus(visualStatus, type);
  const keyToAdd = CHAT_TYPE_TO_STATE_IDS_MAP[chatType];

  const newState: IChatsState = { ...state };
  const keyToAddInNewState = newState[keyToAdd];

  if (!includes(keyToAddInNewState, threadId)) {
    newState[keyToAdd] = keyToAddInNewState.concat(threadId);
  }

  Object.values(CHAT_TYPE_TO_STATE_IDS_MAP).forEach((key) => {
    const keyState = newState[key];
    if (key !== keyToAdd && includes(keyState, threadId)) {
      newState[key] = without(keyState, threadId);
    }
  });

  return newState;
}

/**
 * Updates the state with the given chat details. This function is typically used in a reducer to handle actions that update the chat details in the state.
 * @param {IChatsState} state - The current state of the chats. This includes the current threads and events.
 * @param {IChatThreadDetails} details - The chat details to be added to the state. This includes the thread and its associated events.
 * @returns {IChatsState} - The new state with the updated chat details. This includes the updated threads and events.
 * @example
 * ```
 * const initialState: IChatsState = { threads: {}, events: { byIds: {}, groupedIds: {} } };
 * const chatDetails: IChatThreadDetails = { thread: { threadId: '123' }, events: { '1': { id: '1' } } };
 * const newState = getStateForSetChatDetails(initialState, chatDetails);
 * ```
 * @remarks
 * This function does not mutate the original state. Instead, it creates a new state object with the updated chat details. It uses the `getGroupedEventsIds` function to group the event ids by thread id.
 * @see `getGroupedEventsIds` - This function is used to group the event ids by thread id.
 */
export function getStateForSetChatDetails(state: IChatsState, details: IChatThreadDetails): IChatsState {
  const { thread, events } = details;
  const { threads, events: stateEvents } = state;
  const groupedEventsIds = getGroupedEventsIds(events);

  return {
    ...state,
    threads: {
      ...threads,
      [thread.threadId]: thread,
    },
    events: {
      byIds: {
        ...stateEvents.byIds,
        [thread.threadId]: events,
      },
      groupedIds: {
        ...stateEvents.groupedIds,
        [thread.threadId]: groupedEventsIds,
      },
    },
  };
}

/**
 * Returns a new state after a chat has been converted from one type to another.
 * @param {IChatsState} state - The current state of the chats.
 * @param {ChatType} convertedFrom - The type of chat before conversion.
 * @param {ChatType} convertedTo - The type of chat after conversion.
 * @param {string} threadId - The ID of the thread being converted.
 * @param {string} previousThreadId - The ID of the thread before conversion.
 * @returns {IChatsState} - The new state after the chat has been converted.
 * @example
 * ```
 * const newState = getStateForConvertedChat(
 *   currentState,
 *   ChatType.Unassigned,
 *   ChatType.My,
 *   'thread1',
 *   'thread2'
 * );
 * ```
 * @remarks
 * This function handles the conversion of chat types within the state. If the `convertedFrom` chat type does not exist within the state, the original state is returned. If a thread is being converted, it is removed from its previous location in the state and added to its new location. If the `previousThreadId` is different from the `threadId`, all references to the `previousThreadId` are removed from the state.
 * @see CHAT_TYPE_TO_STATE_IDS_MAP
 */
export function getStateForConvertedChat(
  state: IChatsState,
  convertedFrom: ChatType,
  convertedTo: ChatType,
  threadId: string,
  previousThreadId: string,
): IChatsState {
  const stateKey = CHAT_TYPE_TO_STATE_IDS_MAP[convertedFrom];

  if (!stateKey) {
    return state;
  }

  const shouldFilterThreadId = convertedFrom !== ChatType.Unassigned || convertedTo !== ChatType.Unassigned;

  const newState = {
    ...state,
    [stateKey]: without(state[stateKey], previousThreadId, shouldFilterThreadId ? threadId : undefined),
  };

  if (previousThreadId && previousThreadId !== threadId) {
    newState.threads = omit(newState.threads, previousThreadId);
    newState.events.byIds = omit(newState.events.byIds, previousThreadId);
    newState.events.groupedIds = omit(newState.events.groupedIds, previousThreadId);
    newState.sneakPeeks = omit(newState.sneakPeeks, previousThreadId);
  }

  return newState;
}

export function getStateForSaveTag(state: IChatsState, payload: IStoreTagPayload): IChatsState {
  const { threadId, tag } = payload;
  const currentThreadTags = state.tags.chatTags[threadId] || [];

  return {
    ...state,
    tags: {
      ...state.tags,
      chatTags: {
        ...state.tags.chatTags,
        [threadId]: union(currentThreadTags, [tag]),
      },
    },
  };
}

/**
 * Handles the state update for an incoming chat thread. This function is typically used in the context of a reducer in a react-redux application, where it's responsible for producing a new state based on the incoming chat thread payload.
 * @param {IChatsState} state - The current state of the chat. This state is managed by react-redux and may be affected by various redux-saga side effects.
 * @param {IIncomingChatThreadPayload} payload - The payload of the incoming chat thread. This payload may include details about the thread, any conversion information, the previous thread ID, and any associated tags.
 * @returns {IChatsState} - Returns a new state that reflects the incoming chat thread. The new state is produced immutably, meaning the original state is not modified.
 * @example
 * ```
 * const state = { threads: {}, events: { byIds: {}, groupedIds: {} } };
 * const payload = { thread: { threadId: '123', type: 'private' }, tags: ['urgent'] };
 * const newState = getStateForIncomingChatThread(state, payload);
 * ```
 * @remarks
 * This function uses other helper functions like `getStateForSetChatDetails`, `getStateForNewThreadId`, and `getStateForConvertedChat` to produce the new state. It's important to ensure these helper functions are working correctly as they can directly affect the output of this function.
 * @see `getStateForSetChatDetails` - This function is used to set the chat details in the state.
 * @see `getStateForNewThreadId` - This function is used to handle the creation of a new thread ID in the state.
 * @see `getStateForConvertedChat` - This function is used when the chat thread has been converted from another type.
 */
export function getStateForIncomingChatThread(state: IChatsState, payload: IIncomingChatThreadPayload): IChatsState {
  const { thread, tags, previousThreadId, convertedFrom } = payload;
  const { threadId, type } = thread;

  let updatedState = getStateForSetChatDetails(state, payload);

  if (tags) {
    const currentThreadTags = updatedState.tags.chatTags[threadId] || [];
    updatedState.tags = {
      ...updatedState.tags,
      chatTags: {
        ...updatedState.tags.chatTags,
        [threadId]: union(currentThreadTags, tags),
      },
    };
  }

  if (convertedFrom) {
    updatedState = getStateForConvertedChat(updatedState, convertedFrom, type, threadId, previousThreadId);
  }

  return getStateForNewThreadId(updatedState, threadId, type);
}

export function getStateForSupervisedThread(state: IChatsState, payload: IIncomingChatThreadPayload): IChatsState {
  const { thread } = payload;
  const { threadId } = thread;

  let targetState = getStateForSetChatDetails(state, payload);
  targetState = getStateForNewThreadId(targetState, threadId, ChatType.Supervised);

  return targetState;
}

function getStateForNewEvent(state: IChatsState, threadId: string, event: ChatEventEntity): IChatsState {
  const currentEvents = state.events.byIds[threadId] || {};
  const updatedEvents = {
    ...currentEvents,
    [event.id]: event,
  };
  const currentGroupedEventsIds = state.events.groupedIds[threadId] || [];

  const newGroupedEventsIds = getGroupedEventsIdsForNewEvent(event, {
    events: currentEvents,
    groupedEventsIds: currentGroupedEventsIds,
    currentIndex: currentGroupedEventsIds.length - 1,
  });

  return {
    ...state,
    events: {
      byIds: {
        ...state.events.byIds,
        [threadId]: updatedEvents,
      },
      groupedIds: {
        ...state.events.groupedIds,
        [threadId]: newGroupedEventsIds,
      },
    },
  };
}

export function getStateForSetSneakPeek(state: IChatsState, payload: ISetSneakPeekPayload): IChatsState {
  const { threadId, text } = payload;

  return {
    ...state,
    sneakPeeks: {
      ...state.sneakPeeks,
      [threadId]: text,
    },
  };
}

export function getStateForSetAgentSneakPeek(state: IChatsState, payload: ISetAgentSneakPeekPayload): IChatsState {
  const { isTyping, threadId, text } = payload;
  const { [threadId]: currentSneakPeek, ...restSneakPeeks } = state.agentsSneakPeeks;
  const newSneakPeek = isTyping ? { [threadId]: text } : null;

  return {
    ...state,
    agentsSneakPeeks: {
      ...restSneakPeeks,
      ...newSneakPeek,
    },
  };
}

export function getStateForClearAgentSneakPeek(state: IChatsState, payload: IClearAgentSneakPeekPayload): IChatsState {
  const { threadId } = payload;
  const { [threadId]: currentSneakPeek, ...restSneakPeeks } = state.agentsSneakPeeks;

  return {
    ...state,
    agentsSneakPeeks: {
      ...restSneakPeeks,
    },
  };
}

export function getStateForNewMessage(state: IChatsState, payload: INewMessagePayload): IChatsState {
  const { threadId, message } = payload;
  const stateForNewEvent = getStateForNewEvent(state, threadId, message);

  if (message.authorType === UserType.Customer) {
    const stateWithClearedSneakPeek = getStateForSetSneakPeek(stateForNewEvent, { threadId, text: null });

    return stateWithClearedSneakPeek;
  }

  return stateForNewEvent;
}

export function getStateForNewSystemMessage(state: IChatsState, payload: INewSystemMessagePayload): IChatsState {
  const { threadId, systemMessage } = payload;

  return getStateForNewEvent(state, threadId, systemMessage);
}

export function getStateForNewSurvey(state: IChatsState, payload: INewSurveyPayload): IChatsState {
  const { threadId, survey } = payload;

  return getStateForNewEvent(state, threadId, survey);
}

export function getStateForRemoveChatThreadSuccess(state: IChatsState, payload: IRemoveChatThreadPayload): IChatsState {
  const { threadId, omitTags } = payload;
  const {
    threads,
    events,
    tags,
    sneakPeeks,
    unassignedIds,
    myChatsIds,
    queuedIds,
    supervisedIds,
    importantIds,
    otherAgentsChatIds,
    chatsUsers,
  } = state;

  const { [threadId]: threadToRemove, ...modifiedThreads } = threads;
  const { [threadId]: threadEventsToRemove, ...modifiedEventsByIds } = events.byIds;
  const { [threadId]: eventsGroupedIdsToRemove, ...modifiedEventsGroupedIds } = events.groupedIds;
  const { [threadId]: chatTagsToRemove, ...modifiedChatTags } = tags.chatTags;
  const { [threadId]: sneakPeeksToRemove, ...modifiedSneakPeeks } = sneakPeeks;
  const { [threadId]: chatsUsersToRemove, ...modifiedChatsUsers } = chatsUsers;

  return {
    ...state,
    threads: modifiedThreads,
    events: {
      byIds: modifiedEventsByIds,
      groupedIds: modifiedEventsGroupedIds,
    },
    tags: {
      ...state.tags,
      ...(!omitTags && {
        chatTags: modifiedChatTags,
      }),
    },
    sneakPeeks: modifiedSneakPeeks,
    chatsUsers: modifiedChatsUsers,
    ...(unassignedIds.some(withThreadId(threadId)) && {
      unassignedIds: unassignedIds.filter(withoutThreadId(threadId)),
    }),
    ...(myChatsIds.some(withThreadId(threadId)) && { myChatsIds: myChatsIds.filter(withoutThreadId(threadId)) }),
    ...(queuedIds.some(withThreadId(threadId)) && { queuedIds: queuedIds.filter(withoutThreadId(threadId)) }),
    ...(supervisedIds.some(withThreadId(threadId)) && {
      supervisedIds: supervisedIds.filter(withoutThreadId(threadId)),
    }),
    ...(otherAgentsChatIds.some(withThreadId(threadId)) && {
      otherAgentsChatIds: otherAgentsChatIds.filter(withoutThreadId(threadId)),
    }),
    ...(importantIds.some(withThreadId(threadId)) && { importantIds: importantIds.filter(withoutThreadId(threadId)) }),
  };
}

export function getStateForSetThreadsEvents(state: IChatsState, payload: ISetThreadsEventsPayload): IChatsState {
  const { events } = payload;

  const newEventsByIds = {
    ...state.events.byIds,
    ...events,
  };

  const { threads } = state;

  return {
    ...state,
    events: {
      byIds: newEventsByIds,
      groupedIds: getThreadsGroupedEventsIds(threads, newEventsByIds),
    },
  };
}

export function getStateForSetData(state: IChatsState, payload: ISetChatsDataPayload): IChatsState {
  const { threads, events, unassignedChatsNextPageId, unassignedChatsCount = 0, threadTags } = payload;

  const incomingThreadIdsState = getThreadIdsState(threads);

  const threadIdsState = Object.keys(incomingThreadIdsState).reduce<ChatIds>(
    (acc, chatsKey) => {
      acc[chatsKey] = removeDuplicates([...incomingThreadIdsState[chatsKey], ...state[chatsKey]]);

      return acc;
    },
    {
      unassignedIds: EMPTY_ARRAY,
      myChatsIds: EMPTY_ARRAY,
      queuedIds: EMPTY_ARRAY,
      supervisedIds: EMPTY_ARRAY,
      otherAgentsChatIds: EMPTY_ARRAY,
    },
  );

  const activeUnassignedChats = incomingThreadIdsState.unassignedIds.filter((threadId) => {
    const thread = threads[threadId];

    return isActiveUnassignedChat(thread);
  });

  const currentUnassignedChats = state.unassignedIds.filter(
    (threadId) => !incomingThreadIdsState.unassignedIds.includes(threadId),
  );

  // Remove threads that are no longer my or queued chats (LC-1357)
  (['myChatsIds', 'queuedIds'] as ChatIdsKeys[]).forEach(
    (key) =>
      (threadIdsState[key] = threadIdsState[key].filter(
        (threadId) => !incomingThreadIdsState.unassignedIds.includes(threadId),
      )),
  );

  return {
    ...state,
    events: {
      byIds: { ...state.events.byIds, ...events },
      groupedIds: { ...state.events.groupedIds, ...getThreadsGroupedEventsIds(threads, events) },
    },
    initialized: true,
    threads: { ...state.threads, ...threads },
    ...(unassignedChatsNextPageId && { unassignedChatsNextPageId }),
    totalUnassignedChats: unassignedChatsCount + activeUnassignedChats.length + currentUnassignedChats.length,
    ...(threadTags && {
      tags: {
        ...state.tags,
        chatTags: {
          ...state.tags.chatTags,
          ...threadTags,
        },
      },
    }),
    ...threadIdsState,
  };
}

export function getStateForUpdateChatTags(state: IChatsState, payload: IUpdateChatTagsPayload): IChatsState {
  const { threadId, tags } = payload;

  return {
    ...state,
    tags: {
      ...state.tags,
      chatTags: {
        ...state.tags.chatTags,
        [threadId]: tags,
      },
    },
  };
}

export function getStateForUpdateChat(state: IChatsState, payload: IUpdateChatPayload): IChatsState {
  const targetState = getStateForSetChatDetails(state, payload);

  return {
    ...targetState,
    ...getThreadIdsState(targetState.threads),
  };
}

/**
 * Updates the state for a chat thread.
 * @param {IChatsState} state - The current state of the chat threads.
 * @param {IUpdateChatThreadPayload} payload - The payload containing the thread id and the properties to update.
 * @returns {IChatsState} - The new state after updating the chat thread.
 * @example
 * ```
 * const state = {
 *   threads: {
 *     'thread1': {
 *       type: ChatType.My,
 *       status: ChatThreadStatus.Active,
 *       // ... other properties
 *     },
 *     // ... other threads
 *   },
 *   // ... other state properties
 * };
 * const payload = {
 *   threadId: 'thread1',
 *   thread: {
 *     status: ChatThreadStatus.Closed,
 *   },
 * };
 * const newState = getStateForUpdateChatThread(state, payload);
 * ```
 * @remarks
 * This function does not mutate the original state. Instead, it returns a new state object.
 * If the thread is not in the state or if the new thread properties are the same as the existing ones, the function returns the original state.
 * If the thread type has changed, the function also updates the thread ids.
 * @see [getStateForNewThreadId]
 */
export function getStateForUpdateChatThread(state: IChatsState, payload: IUpdateChatThreadPayload): IChatsState {
  const { threadId, thread: threadPropertiesToUpdate } = payload;
  const currentThread = state.threads[threadId];

  if (!currentThread) {
    return state;
  }

  const shouldUpdateThread = !isEqual(currentThread, { ...currentThread, ...threadPropertiesToUpdate });
  const shouldUpdateIds = !!threadPropertiesToUpdate.type && threadPropertiesToUpdate.type !== currentThread.type;

  if (!shouldUpdateThread && !shouldUpdateIds) {
    return state;
  }

  let newState = { ...state };

  if (shouldUpdateThread) {
    newState.threads = {
      ...newState.threads,
      [threadId]: { ...currentThread, ...threadPropertiesToUpdate },
    };
  }

  if (shouldUpdateIds) {
    newState = getStateForNewThreadId(
      newState,
      threadId,
      newState.threads[threadId].type,
      getThreadVisualStatus(newState.threads[threadId]),
    );
  }

  return newState;
}

export function getStateForUpdateEvent(state: IChatsState, payload: IUpdateEventPayload): IChatsState {
  const { threadId, eventId, event } = payload;

  const currentEventsByIds = state.events.byIds[threadId] || {};

  return {
    ...state,
    events: {
      ...state.events,
      byIds: {
        ...state.events.byIds,
        [threadId]: {
          ...currentEventsByIds,
          [eventId]: {
            ...currentEventsByIds[eventId],
            ...event,
          },
        },
      },
    },
  };
}
export function getStateForUpdateEvents(state: IChatsState, payload: IUpdateEventsPayload): IChatsState {
  const { threadId, events } = payload;

  const currentEventsByIds = state.events.byIds[threadId] || {};

  const groupedEventsIds = getGroupedEventsIds({ ...currentEventsByIds, ...events });

  return {
    ...state,
    events: {
      byIds: {
        ...state.events.byIds,
        [threadId]: {
          ...currentEventsByIds,
          ...events,
        },
      },
      groupedIds: {
        ...state.events.groupedIds,
        [threadId]: groupedEventsIds,
      },
    },
  };
}

export function getStateForUpdateIncompleteThreadEvents(
  state: IChatsState,
  payload: IUpdateEventsPayload,
): IChatsState {
  const { threadId } = payload;

  const thread = state.threads[threadId];

  if (!thread) {
    return state;
  }

  const eventsState = getStateForUpdateEvents(state, payload);

  return {
    ...state,
    ...eventsState,
    threads: {
      ...state.threads,
      [threadId]: {
        ...thread,
        incomplete: false,
      },
    },
  };
}

export function getStateForRemoveTag(state: IChatsState, payload: IStoreTagPayload): IChatsState {
  const { threadId, tag } = payload;
  const currentTags = state.tags.chatTags[threadId];

  if (!currentTags) {
    return state;
  }

  const newThreadTags = currentTags.filter((t) => t !== tag);

  return {
    ...state,
    tags: {
      ...state.tags,
      chatTags: {
        ...state.tags.chatTags,
        [threadId]: newThreadTags,
      },
    },
  };
}

export function getStateForSaveTagSuggestions(state: IChatsState, payload: ISaveTagSuggestionsPayload): IChatsState {
  const { threadId, tags, messageCountForSuggestions } = payload;
  const requestId = uniqueId();

  return {
    ...state,
    tags: {
      ...state.tags,
      tagSuggestions: {
        ...state.tags.tagSuggestions,
        tags: {
          ...state.tags.tagSuggestions.tags,
          [threadId]: {
            ...state.tags.tagSuggestions[threadId],
            data: tags,
            requestId,
            messageCountForSuggestions,
          },
        },
      },
    },
  };
}

export function getStateForSetTagSuggestionModelVersion(state: IChatsState, modelVersion: string): IChatsState {
  return {
    ...state,
    tags: {
      ...state.tags,
      tagSuggestions: {
        ...state.tags.tagSuggestions,
        modelVersion,
      },
    },
  };
}

export function getStateForUpdateMessage(state: IChatsState, payload: IUpdateMessagePayload): IChatsState {
  const { threadId, messageId, message } = payload;
  const stateToModify = { ...state };
  const events = stateToModify.events.byIds[threadId] || {};

  if (isEmpty(events)) {
    return stateToModify;
  }

  const groupedEventsIds = (stateToModify.events.groupedIds[threadId] || []).reduce(
    (acc: GroupedEventsIds, eventsGroupElement) => {
      if (eventsGroupElement.some((id) => id === messageId)) {
        const eventsInGroup = eventsGroupElement.filter((id) => id !== messageId);
        if (eventsInGroup.length) {
          acc.push(eventsInGroup);
        }

        return acc;
      }
      acc.push(eventsGroupElement);

      return acc;
    },
    [],
  );

  let eventsToUpdate = events;

  // Locally assigned id may be updated with API generated value
  if (message.id !== messageId) {
    const { [messageId]: duplicatedEvent, ...removedDuplicates } = events;
    eventsToUpdate = removedDuplicates;
  }

  const updatedEvents = { ...eventsToUpdate, [message.id]: message };
  const updatedGroupedEventsIds = getGroupedEventsIdsForNewEvent(message, {
    events,
    groupedEventsIds,
    currentIndex: groupedEventsIds.length - 1,
  });

  return {
    ...stateToModify,
    events: {
      byIds: {
        ...state.events.byIds,
        [threadId]: updatedEvents,
      },
      groupedIds: {
        ...state.events.groupedIds,
        [threadId]: updatedGroupedEventsIds,
      },
    },
  };
}

export function getStateForNewAttachmentMessage(
  state: IChatsState,
  payload: INewAttachmentMessagePayload,
): IChatsState {
  const { threadId, file } = payload;

  return getStateForNewEvent(state, threadId, file);
}

export function getStateForMarkChatAsImportant(state: IChatsState, payload: IMarkChatAsImportantPayload): IChatsState {
  const currentImporantIds = state.importantIds;

  return {
    ...state,
    importantIds: union(currentImporantIds, [payload.threadId]),
  };
}

export function getStateForMarkMultipleChatAsImportant(
  state: IChatsState,
  payload: IMarkMultipleChatsAsImportantPayload,
): IChatsState {
  const currentImporantIds = state.importantIds;

  return {
    ...state,
    importantIds: union(currentImporantIds, payload.threadsIds),
  };
}

export function getStateForUnmarkChatAsImportant(
  state: IChatsState,
  payload: IUnmarkChatAsImportantPayload,
): IChatsState {
  const { threadId } = payload;
  const { importantIds: currentImportantIds } = state;

  return {
    ...state,
    importantIds: currentImportantIds.filter((id) => id !== threadId),
  };
}

export function getStateForUpdateAttachment(
  state: IChatsState,
  payload: IUpdateAttachmentSafetyConfirmationPayload,
): IChatsState {
  const { eventId, threadId, isSafetyConfirmed, historyThreadId } = payload;

  if (historyThreadId) {
    return {
      ...state,
      chatsHistory: {
        ...state.chatsHistory,
        events: {
          ...state.chatsHistory.events,
          byIds: {
            ...state.chatsHistory.events.byIds,
            [threadId]: {
              ...state.chatsHistory.events.byIds[threadId],
              [historyThreadId]: {
                ...state.chatsHistory.events.byIds[threadId][historyThreadId],
                [eventId]: {
                  ...state.chatsHistory.events.byIds[threadId][historyThreadId][eventId],
                  safetyConfirmation: isSafetyConfirmed,
                },
              },
            },
          },
        },
      },
    };
  }

  if (!state.events.byIds[threadId]) {
    return state;
  }

  return {
    ...state,
    events: {
      ...state.events,
      byIds: {
        ...state.events.byIds,
        [threadId]: {
          ...state.events.byIds[threadId],
          [eventId]: {
            ...state.events.byIds[threadId][eventId],
            safetyConfirmation: isSafetyConfirmed,
          },
        },
      },
    },
  };
}

export function getStateForSetChatHistoryTimeline(
  state: IChatsState,
  payload: ISetChatHistoryTimelinePayload,
): IChatsState {
  const { threadId, timeline } = payload;

  return {
    ...state,
    chatsHistory: {
      ...state.chatsHistory,
      timeline: {
        ...state.chatsHistory.timeline,
        [threadId]: {
          currentPage: 1,
          currentPeriod: 0,
          hasMoreHistory: true,
          periods: timeline,
        },
      },
    },
  };
}

function getStateForTimelineBasedChatHistoryUpdate(
  state: IChatsState,
  threadId: string,
  data: IFetchChatHistoryCompletedPayload['timelineBasedData'],
): IChatsState {
  const { currentPage, currentPeriod, hasMoreHistory, threads, threadsOrder, events, tags } = data;

  const newThreadsGroupedEventsIds = getThreadsGroupedEventsIds(threads, events);
  const updatedEvents = {
    ...state.chatsHistory.events,
    byIds: {
      ...state.chatsHistory.events.byIds,
      [threadId]: { ...state.chatsHistory.events.byIds[threadId], ...events },
    },
    groupedIds: {
      ...state.chatsHistory.events.groupedIds,
      [threadId]: { ...state.chatsHistory.events.groupedIds[threadId], ...newThreadsGroupedEventsIds },
    },
  };

  const updatedThreads = {
    ...state.chatsHistory.threads,
    [threadId]: { ...state.chatsHistory.threads[threadId], ...threads },
  };

  const updatedThreadsOrder = { ...state.chatsHistory.threadsOrder, [threadId]: threadsOrder };

  const updatedTimeline = {
    ...state.chatsHistory.timeline,
    [threadId]: {
      ...state.chatsHistory.timeline[threadId],
      currentPage,
      currentPeriod,
      hasMoreHistory,
    },
  };

  const updatedTags = {
    ...state.tags,
    chatTags: {
      ...state.tags.chatTags,
      ...tags,
    },
  };

  return {
    ...state,
    tags: updatedTags,
    chatsHistory: {
      ...state.chatsHistory,
      events: updatedEvents,
      threads: updatedThreads,
      threadsOrder: updatedThreadsOrder,
      timeline: updatedTimeline,
    },
  };
}

export function getStateForCustomerNameChatHistoryUpdate(
  state: IChatsState,
  payload: IUpdateChatThreadPayload,
): IChatsState {
  const {
    threadId,
    thread: { customerName },
  } = payload;
  const { threads } = state.chatsHistory;

  if (threads?.[threadId]) {
    Object.keys(threads[threadId]).forEach((id) => {
      threads[threadId][id].customerName = customerName;
    });
  }

  return {
    ...state,
    chatsHistory: {
      ...state.chatsHistory,
      threads,
    },
  };
}

function getStateForSummaryBasedChatHistoryUpdate(
  state: IChatsState,
  threadId: string,
  payload: IFetchChatHistoryCompletedPayload['summaryBasedData'],
  isHistoryLimitReached: boolean,
): IChatsState {
  const { threads, events, nextPageId, totalThreadsCount, threadsOrder, hasMoreHistory, tags } = payload;

  const newThreadsGroupedEventsIds = getThreadsGroupedEventsIds(threads, events);
  const updatedEvents = {
    ...state.chatsHistory.events,
    byIds: {
      ...state.chatsHistory.events.byIds,
      [threadId]: { ...state.chatsHistory.events.byIds[threadId], ...events },
    },
    groupedIds: {
      ...state.chatsHistory.events.groupedIds,
      [threadId]: { ...state.chatsHistory.events.groupedIds[threadId], ...newThreadsGroupedEventsIds },
    },
  };

  const updatedThreads = {
    ...state.chatsHistory.threads,
    [threadId]: { ...state.chatsHistory.threads[threadId], ...threads },
  };

  const updatedSummary = {
    ...state.chatsHistory.summary,
    [threadId]: {
      ...state.chatsHistory.summary[threadId],
      nextPageId,
      totalThreadsCount,
      hasMoreHistory,
    },
  };

  const updatedThreadsOrder = {
    ...state.chatsHistory.threadsOrder,
    [threadId]: state.chatsHistory.threadsOrder[threadId]
      ? uniq(threadsOrder.concat(state.chatsHistory.threadsOrder[threadId]))
      : threadsOrder,
  };

  const updatedTags = {
    ...state.tags,
    chatTags: {
      ...state.tags.chatTags,
      ...tags,
    },
  };

  const updateHistoryLimitReached = { ...state.chatsHistory.historyLimitReached, [threadId]: isHistoryLimitReached };

  return {
    ...state,
    tags: updatedTags,
    chatsHistory: {
      ...state.chatsHistory,
      historyLimitReached: updateHistoryLimitReached,
      events: updatedEvents,
      threads: updatedThreads,
      threadsOrder: updatedThreadsOrder,
      summary: updatedSummary,
    },
  };
}

/**
 * Disables further history fetching for given thread. Due to two types of history structures
 * it has to be discovered which node to update.
 * @param state Current state.
 * @param threadId Thread to update.
 */
function getStateForStopFetchingHistory(state: IChatsState, threadId: string): IChatsState {
  return {
    ...state,
    chatsHistory: {
      ...state.chatsHistory,
      summary: {
        ...state.chatsHistory.summary,
        [threadId]: {
          ...state.chatsHistory.summary[threadId],
          hasMoreHistory: false,
        },
      },
    },
  };
}

export function getStateForFetchChatHistoryCompleted(
  state: IChatsState,
  payload: IFetchChatHistoryCompletedPayload,
): IChatsState {
  const { threadId, summaryBasedData, timelineBasedData, shouldStopFetching, isHistoryLimitReached } = payload;

  if (summaryBasedData) {
    return getStateForSummaryBasedChatHistoryUpdate(state, threadId, summaryBasedData, isHistoryLimitReached);
  }

  if (timelineBasedData) {
    return getStateForTimelineBasedChatHistoryUpdate(state, threadId, timelineBasedData);
  }

  if (shouldStopFetching) {
    return getStateForStopFetchingHistory(state, threadId);
  }

  return state;
}

export function getStateForAddNewUnassignedThreads(
  state: IChatsState,
  payload: IAddNewUnassignedThreadsPayload,
): IChatsState {
  const { threads, events, nextPageId, unassignedChatsCount, shouldReplace } = payload;

  const newEvents = {
    ...events,
    ...state.events.byIds,
  };

  let newThreads: KeyMap<ChatThreadEntity> = {};

  if (shouldReplace) {
    const threadsToMerge = Object.values(state.threads).reduce((acc, thread) => {
      if (!isUnassignedChat(thread) || !thread.customProperties?.wasUnassigned || isActiveThread(thread)) {
        acc[thread.threadId] = thread;
      }

      return acc;
    }, {});
    newThreads = { ...threadsToMerge, ...threads };
  } else {
    // Deep merge prevent the situation of the inconsistent threads list in store
    newThreads = deepMerge(true, {}, state.threads, threads);
  }

  const unassignedIds = getIdsForChatType(newThreads, ChatType.Unassigned);

  const activeUnassignedChats = Object.values(newThreads).filter((thread) => isActiveUnassignedChat(thread));

  const { supervisedIds } = state;

  const leftoverSupervisedIds = supervisedIds.filter((threadId) => unassignedIds.includes(threadId));

  return {
    ...state,
    events: {
      byIds: newEvents,
      groupedIds: {
        ...getThreadsGroupedEventsIds(threads, events),
        ...state.events.groupedIds,
      },
    },
    unassignedIds,
    ...(leftoverSupervisedIds.length && {
      supervisedIds: supervisedIds.filter(withoutThreadIds(leftoverSupervisedIds)),
    }),
    threads: newThreads,
    unassignedChatsNextPageId: nextPageId,
    totalUnassignedChats: (unassignedChatsCount ?? 0) + activeUnassignedChats.length,
  };
}

export function getStateForUnassignedChatsCountUpdate(
  state: IChatsState,
  payload: IUpdateUnassignedChatsCountPayload,
): IChatsState {
  const newState: Partial<IChatsState> = {};
  if (payload.type === 'increase') {
    newState.totalUnassignedChats = state.totalUnassignedChats + 1;
  }

  if (payload.type === 'decrease' && state.totalUnassignedChats > 0) {
    newState.totalUnassignedChats = state.totalUnassignedChats - 1;
  }

  return {
    ...state,
    ...newState,
  };
}

export function getStateForAddChatUsers(state: IChatsState, payload: IAddChatUsersPayload): IChatsState {
  const { chatId, chatUsers } = payload;

  return {
    ...state,
    chatsUsers: {
      ...state.chatsUsers,
      [chatId]: chatUsers,
    },
  };
}

export function getStateForSortedChatList(state: IChatsState, payload: ISortChatList): IChatsState {
  const { type, ids } = payload;

  const key = CHAT_TYPE_TO_STATE_IDS_MAP[type];

  return {
    ...state,
    [key]: ids,
  };
}

export function getStateForUpdateQueuePositions(
  state: IChatsState,
  payload: IUpdateQueuePositionsPayload,
): IChatsState {
  const { threads } = payload;

  const threadsToUpdate: KeyMap<IQueuedChat> = threads.reduce((acc: KeyMap<IQueuedChat>, thread) => {
    acc[thread.id] = {
      ...state.threads[thread.id],
      queuePosition: thread.position,
      queueWaitingTime: thread.waitingTime,
      type: ChatType.Queued,
    };

    return acc;
  }, {});

  let newState = {
    ...state,
    threads: {
      ...state.threads,
      ...threadsToUpdate,
    },
  };

  const threadsTypeToChange = threads.filter((thread) => state.threads[thread.id].type !== ChatType.Queued);

  threadsTypeToChange.forEach((thread) => {
    newState = getStateForNewThreadId(newState, thread.id, ChatType.Queued);
  });

  return newState;
}

export function getStateForUpdateChatSupervisors(
  state: IChatsState,
  payload: IUpdateChatSupervisorsPayload,
): IChatsState {
  const { threadId, supervisors } = payload;

  return {
    ...state,
    threads: {
      ...state.threads,
      [threadId]: {
        ...state.threads[threadId],
        supervisorsIds: supervisors,
      },
    },
  };
}
