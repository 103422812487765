import { removeSpacesFromString } from 'helpers/string';
import { type ICompanyDetails } from 'interfaces/entities/company-details';

import { mapAudienceToCheckboxes, mapAudienceToAPIValues } from './helpers';

export default abstract class CompanyDetailsSerializer {
  static deserialize(companyDetails: { [key: string]: any }): ICompanyDetails {
    const audience = mapAudienceToCheckboxes(companyDetails.audience);
    const companySize = companyDetails.company_size;
    const size = companySize ? removeSpacesFromString(companySize) : companySize;

    return {
      city: companyDetails.city,
      company: companyDetails.company,
      country: companyDetails.country,
      industry: companyDetails.industry,
      invoiceEmail: companyDetails.invoice_email,
      phone: companyDetails.phone,
      size,
      website: companyDetails.url,
      b2b: audience.b2b,
      b2c: audience.b2c,
      internal: audience.internal,
    };
  }

  static serialize(companyDetails: { [key: string]: any }): any {
    const audience = mapAudienceToAPIValues({
      b2b: companyDetails.b2b,
      b2c: companyDetails.b2c,
      internal: companyDetails.internal,
    });

    return {
      audience,
      company_size: companyDetails.size,
      invoice_email: companyDetails.invoiceEmail,
      phone: companyDetails.phone,
      city: companyDetails.city,
      company: companyDetails.company,
      country: companyDetails.country,
      industry: companyDetails.industry,
      url: companyDetails.website,
    };
  }
}
