import { isDesktopDevice } from 'helpers/device';

import { shouldShowNewBadgeBasedOnAgentCreateDate } from './settings';

const REPLY_SUGGESTIONS_RELEASE_DATE = '2023-10-03';
const NINETY_DAYS = 90;

export function shouldShowReplySuggestionsPromo(): boolean {
  return shouldShowNewBadgeBasedOnAgentCreateDate(REPLY_SUGGESTIONS_RELEASE_DATE, NINETY_DAYS) && isDesktopDevice();
}
