import { type QueryObserverResult, type RefetchOptions, useQuery } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { useItemsPagination, type IUseItemsPagination } from 'hooks/use-items-pagination';
import type { RequestResult } from 'interfaces/api/client';
import { type IBannedVisitor } from 'interfaces/entities/banned-visitors';
import { ApiManager } from 'services/api/api-manager';
import { type IFetchBanListResult } from 'services/api/ban-customer/interfaces';
import { BannedVisitorsSerializer } from 'services/api/ban-customer/serializer';

import { MAX_ITEMS_ON_PAGE } from './constants';

const FETCH_BANNED_VISITORS_STALE_TIME = 1000 * 60 * 2; // 2 minutes

interface IUseFetchBannedCustomers {
  data: IBannedVisitor[];
  filteredData: IBannedVisitor[];
  paginatedData: IUseItemsPagination<IBannedVisitor>;
  isLoading: boolean;
  isFetching: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<IBannedVisitor[], unknown>>;
}

export async function fetchBannedCustomers(): Promise<IBannedVisitor[]> {
  const { result }: RequestResult<IFetchBanListResult> = await ApiManager.bannedCustomersApi.fetchBanList();

  return BannedVisitorsSerializer.deserializeCollection(result);
}

export function useFetchBannedCustomers(searchQuery = ''): IUseFetchBannedCustomers {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuery([QueryKey.BannedCustomers], fetchBannedCustomers, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: FETCH_BANNED_VISITORS_STALE_TIME,
  });

  const filteredData = data.filter(({ ip }: IBannedVisitor) => ip.includes(searchQuery));
  const sortedData = filteredData.sort((a: IBannedVisitor, b: IBannedVisitor) =>
    b.creationTimestamp?.localeCompare(a.creationTimestamp)
  );
  const paginatedData = useItemsPagination<IBannedVisitor>(sortedData, MAX_ITEMS_ON_PAGE);

  return {
    data,
    filteredData,
    paginatedData,
    isLoading,
    isFetching,
    refetch,
  };
}
