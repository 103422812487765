import { updateEventProperties } from './update-event-properties';

export async function setAttachmentSafetyConfirmation(
  chatId: string,
  threadId: string,
  eventId: string,
  isSafetyConfirmed: boolean
): Promise<unknown> {
  return await updateEventProperties(chatId, threadId, eventId, {
    lc2: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      file_safety_confirmation: isSafetyConfirmed,
    },
  });
}
