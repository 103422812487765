import { useRef, useState, type FC } from 'react';

import { cx } from '@emotion/css';
import { FiletypePdf } from '@livechat/design-system-icons';
import { Icon, Tooltip } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { AbsoluteAutomateRoutes } from 'constants/automate/constants';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import { type PDFSource } from 'store/entities/copilot/interfaces';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import * as styles from './styles';

type Props = PDFSource;

export const PdfSourceItem: FC<Props> = ({ name, skillId }) => {
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const [isHovered, setIsHovered] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleSourceClick = (): void => {
    navigate(`${AbsoluteAutomateRoutes.KnowledgeHubPDF}/${skillId}`);
    trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, { action: 'source clicked' });
  };

  return (
    <li
      onClick={handleSourceClick}
      className={styles.pdfLinkContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tooltip
        isVisible={isHovered}
        placement="top-start"
        kind="invert"
        className={styles.sourceMaxWidth}
        hoverOnDelay={300}
        referenceElement={tooltipRef?.current ?? undefined}
        triggerRenderer={() => (
          <div className={cx(styles.urlSourceName, { [styles.sourceMaxWidth]: isExpanded })}>
            <Icon source={FiletypePdf} />
            <div ref={tooltipRef} className={styles.sourceItem}>
              {name}
            </div>
          </div>
        )}
      >
        <p className={styles.tooltipSourceLink}>View preview</p>
      </Tooltip>
    </li>
  );
};
