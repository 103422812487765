import isArray from 'lodash.isarray';
import isEmpty from 'lodash.isempty';
import isString from 'lodash.isstring';

import { type Nullable } from 'helpers/interface';

type ErrorType =
  | string
  | {
      message?: string | string[];
      error?: string | string[];
      errors?: string | string[];
    };

/**
 * Transforms an error object or string into a user-friendly error message.
 * This function is particularly useful for handling errors returned from API calls in a Redux-Saga or @tanstack/query context.
 * @param {ErrorType} error - The error to be deserialized. This can be a string or an object with `message`, `error`, or `errors` properties.
 * @returns {string} - A string that represents the deserialized error. This can be used to display user-friendly error messages in the UI.
 * @example
 * ```
 * const error = { message: ['Error 1', 'Error 2'] };
 * const errorMessage = deserializeError(error);
 * console.log(errorMessage); // "Error 1. Error 2."
 * ```
 * @remarks
 * The function handles different types of `error` inputs: a string, an object with a string `message`, `error`, or `errors` property, or an object with an array of strings `message`, `error`, or `errors` property.
 * If the `error` is null or undefined, or if it's an object but none of the properties `message`, `error`, or `errors` are defined, the function returns a default error message.
 */
export function deserializeError(error: Nullable<ErrorType>): string {
  const defaultErrorMessage = 'Unexpected error occured.';

  if (!error) {
    return defaultErrorMessage;
  }

  if (isString(error)) {
    return `${error}.`;
  }

  const { message, error: errorProp, errors } = error;

  if (isString(message)) {
    return `${message}.`;
  }

  if (isArray(message) && !isEmpty(message)) {
    return `${message.join('. ')}.`;
  }

  if (isString(errorProp)) {
    return `${errorProp}.`;
  }

  if (isArray(errorProp) && !isEmpty(errorProp)) {
    return `${errorProp.join('. ')}.`;
  }

  if (isString(errors)) {
    return `${errors}.`;
  }

  if (isArray(errors) && !isEmpty(errors)) {
    return `${errors.join('. ')}.`;
  }

  return defaultErrorMessage;
}
