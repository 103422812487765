// @ts-strict-ignore
import type { IAppState } from 'services/app-state-provider';
import { invokeCustomerUpdateWithNotifications } from 'services/customers/incoming-customer';
import { getCustomer } from 'store/entities/customers/selectors';

import { type ICustomerUpdatedPushEvent } from '../interfaces';
import { deserializeCustomerUpdatedPushEvent } from '../serialization/deserialize';

/**
 * Handles customer data update.
 * @param {ICustomerUpdatedPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleCustomerUpdated(payload: ICustomerUpdatedPushEvent, store: IAppState): void {
  if (payload) {
    const state = store.getState();

    // Slack Thread: https://text.slack.com/archives/C2FAU97K2/p1718355015633879?thread_ts=1718354032.613409&cid=C2FAU97K2
    // According to it, `customer_updated` can come before `incoming_customers` event, which would lead to malformed customer entity.
    const existingCustomer = getCustomer(state, payload.id);

    if (existingCustomer) {
      // Only small part of `ICustomer` is possible to be deserialized,
      // therefore, existing customer is used for the update.
      const deserializedCustomer = deserializeCustomerUpdatedPushEvent(payload);
      const newCustomer = {
        ...existingCustomer,
        ...deserializedCustomer,
      };

      invokeCustomerUpdateWithNotifications(store, { customer: newCustomer });
    }
  }
}
