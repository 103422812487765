import { type IPlanPrices } from 'constants/plan-type';
import { createAction, type ActionsUnion } from 'store/helper';

import {
  type IRequestSubscriptionDowngradeRequestPayload,
  type ISaveSubscriptionFailurePayload,
  type ISaveSubscriptionSuccessPayload,
  type ISubScriptionFetchedPayload,
  type ISubscriptionChangePayload,
  type ISubscriptionUpdatedPayload,
} from './interfaces';

export enum SubscriptionActionNames {
  PLANS_FETCHED = 'ENTITIES/SUBSCRIPTION/PLANS_FETCHED',
  SUBSCRIPTION_FETCHED = 'ENTITIES/SUBSCRIPTION/SUBSCRIPTION_FETCHED',
  SUBSCRIPTION_UPDATED = 'ENTITIES/SUBSCRIPTION/SUBSCRIPTION_UPDATED',
  SUBSCRIPTION_CHANGED = 'ENTITIES/SUBSCRIPTION/SUBSCRIPTION_CHANGED',
  SUBSCRIPTION_CHANGE_UPDATED = 'ENTITIES/SUBSCRIPTION/SUBSCRIPTION_CHANGE_UPDATED',
  CREATE_SUBSCRIPTION = 'ENTITIES/SUBSCRIPTION/CREATE_SUBSCRIPTION_REQUEST',
  CREATE_SUBSCRIPTION_SUCCESS = 'ENTITIES/SUBSCRIPTION/CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_FAILURE = 'ENTITIES/SUBSCRIPTION/CREATE_SUBSCRIPTION_FAILURE',
  UPDATE_SUBSCRIPTION = 'ENTITIES/SUBSCRIPTION/UPDATE_SUBSCRIPTION_REQUEST',
  UPDATE_SUBSCRIPTION_SUCCESS = 'ENTITIES/SUBSCRIPTION/UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_FAILURE = 'ENTITIES/SUBSCRIPTION/UPDATE_SUBSCRIPTION_FAILURE',
  UPGRADE_SUBSCRIPTION = 'ENTITIES/SUBSCRIPTION/UPGRADE_SUBSCRIPTION_REQUEST',
  UPGRADE_SUBSCRIPTION_SUCCESS = 'ENTITIES/SUBSCRIPTION/UPGRADE_SUBSCRIPTION_SUCCESS',
  UPGRADE_SUBSCRIPTION_FAILURE = 'ENTITIES/SUBSCRIPTION/UPGRADE_SUBSCRIPTION_FAILURE',
  CONTACT_SUBSCRIPTION_OWNER_REQUEST = 'ENTITIES/SUBSCRIPTION/CONTACT_SUBSCRIPTION_OWNER_REQUEST',
  CONTACT_SUBSCRIPTION_OWNER_SUCCESS = 'ENTITIES/SUBSCRIPTION/CONTACT_SUBSCRIPTION_OWNER_SUCCESS',
  CONTACT_SUBSCRIPTION_OWNER_FAILURE = 'ENTITIES/SUBSCRIPTION/CONTACT_SUBSCRIPTION_OWNER_FAILURE',
  REQUEST_SUBSCRIPTION_DOWNGRADE = 'ENTITIES/SUBSCRIPTION/REQUEST_SUBSCRIPTION_DOWNGRADE',
}

export enum SubscriptionRequestFetchingSelectorsNames {
  CREATE_SUBSCRIPTION_REQUEST = 'ENTITIES/SUBSCRIPTION/CREATE_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION_REQUEST = 'ENTITIES/SUBSCRIPTION/UPDATE_SUBSCRIPTION',
  UPGRADE_SUBSCRIPTION_REQUEST = 'ENTITIES/SUBSCRIPTION/UPGRADE_SUBSCRIPTION',
  CONTACT_SUBSCRIPTION_OWNER_REQUEST = 'ENTITIES/SUBSCRIPTION/CONTACT_SUBSCRIPTION_OWNER',
}

export const SubscriptionActions = {
  plansFetched: (plans: IPlanPrices) => createAction(SubscriptionActionNames.PLANS_FETCHED, plans),

  subscriptionFetched: (subscription: ISubScriptionFetchedPayload) =>
    createAction(SubscriptionActionNames.SUBSCRIPTION_FETCHED, subscription),
  subscriptionUpdated: (subscription: ISubscriptionUpdatedPayload) =>
    createAction(SubscriptionActionNames.SUBSCRIPTION_UPDATED, subscription),

  subscriptionChanged: () => createAction(SubscriptionActionNames.SUBSCRIPTION_CHANGED),
  subscriptionChangeUpdated: (subscriptionChange: ISubscriptionChangePayload) =>
    createAction(SubscriptionActionNames.SUBSCRIPTION_CHANGE_UPDATED, subscriptionChange),

  createSubscription: (threeDSecureTokenId?: string) =>
    createAction(SubscriptionActionNames.CREATE_SUBSCRIPTION, { threeDSecureTokenId }),
  createSubscriptionSuccess: (subscription: ISaveSubscriptionSuccessPayload) =>
    createAction(SubscriptionActionNames.CREATE_SUBSCRIPTION_SUCCESS, subscription),
  createSubscriptionFailure: (error: ISaveSubscriptionFailurePayload) =>
    createAction(SubscriptionActionNames.CREATE_SUBSCRIPTION_FAILURE, error),

  updateSubscription: () => createAction(SubscriptionActionNames.UPDATE_SUBSCRIPTION),
  updateSubscriptionSuccess: (subscription: ISaveSubscriptionSuccessPayload) =>
    createAction(SubscriptionActionNames.UPDATE_SUBSCRIPTION_SUCCESS, subscription),
  updateSubscriptionFailure: (error: ISaveSubscriptionFailurePayload) =>
    createAction(SubscriptionActionNames.UPDATE_SUBSCRIPTION_FAILURE, error),

  upgradeSubscription: () => createAction(SubscriptionActionNames.UPGRADE_SUBSCRIPTION),
  upgradeSubscriptionSuccess: (subscription: ISaveSubscriptionSuccessPayload) =>
    createAction(SubscriptionActionNames.UPGRADE_SUBSCRIPTION_SUCCESS, subscription),
  upgradeSubscriptionFailure: (error: ISaveSubscriptionFailurePayload) =>
    createAction(SubscriptionActionNames.UPGRADE_SUBSCRIPTION_FAILURE, error),

  contactSubscriptionOwner: () => createAction(SubscriptionActionNames.CONTACT_SUBSCRIPTION_OWNER_REQUEST),
  contactSubscriptionOwnerSuccess: () => createAction(SubscriptionActionNames.CONTACT_SUBSCRIPTION_OWNER_SUCCESS),
  contactSubscriptionOwnerFailure: () => createAction(SubscriptionActionNames.CONTACT_SUBSCRIPTION_OWNER_FAILURE),

  requestSubscriptionDowngrade: (payload: IRequestSubscriptionDowngradeRequestPayload) =>
    createAction(SubscriptionActionNames.REQUEST_SUBSCRIPTION_DOWNGRADE, payload),
};

export type SubscriptionActionTypes = ActionsUnion<typeof SubscriptionActions>;
