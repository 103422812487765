// @ts-strict-ignore
import { PaymentType, ProductBillingCycle } from 'constants/product-cart';
import type { Product } from 'interfaces/product-cart';

interface IGetSuggestedItems {
  forceAppsInstall: boolean;
}

export const getSuggestedItemsArray = (
  { forceAppsInstall }: IGetSuggestedItems = { forceAppsInstall: false }
): Product[] => [
  {
    id: 'analytics',
    name: 'Google Analytics',
    description: 'The integration lets you track chats and measure conversion in your Google Analytics.',
    icon: 'https://cdn-labs.livechat-static.com/api/file/developers/img/applications/analytics/Rnh6aRDiR-icon-480x480.png',
    url: 'https://www.livechat.com/help/log-live-chat-data-with-google-analytics/',
    trialDays: 0,
    purchasedAt: null,
    isMarkedForInstall: forceAppsInstall,
    isMarkedForUninstall: false,
    plans: [
      {
        id: 'pay-monthly-per-organization',
        type: PaymentType.PerOrganization,
        billingCycle: ProductBillingCycle.Monthly,
        selected: true,
        discount: 0,
        price: {
          initial: 500,
          regular: 500,
          discount: 0,
          total: 500,
        },
      },
      {
        id: 'pay-yearly-per-organization',
        type: PaymentType.PerOrganization,
        billingCycle: ProductBillingCycle.Annually12,
        selected: false,
        discount: 15,
        price: {
          initial: 6000,
          regular: 6000,
          discount: 900,
          total: 5100,
        },
      },
    ],
  },
];
