/**
 * Moved from './login-status.ts' file. to avoid 'design-system-react-components' library in the 'fake-api'
 */
import { DesignToken } from '@livechat/design-system-react-components';

import { LoginStatus } from './login-status';

export const STATUS_TO_DESIGN_TOKEN = {
  [LoginStatus.Online]: DesignToken.SurfaceAccentEmphasisHighPositive,
  [LoginStatus.Offline]: DesignToken.ContentBasicDisabled,
  [LoginStatus.Away]: DesignToken.SurfaceAccentEmphasisHighNegative,
};
