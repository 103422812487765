import { type TagKind } from '@livechat/design-system-react-components';

import ExploringIntentImage from 'assets/img/campaigns/intent-exploring-tooltip.png';
import SupportIntentImage from 'assets/img/campaigns/intent-support-tooltip.png';
import TransactionIntentImage from 'assets/img/campaigns/intent-transaction-tooltip.png';

export enum Intent {
  Transaction = 'Transaction',
  Support = 'Support',
  Exploring = 'Exploring',
}

export const INTENT_LABELS = {
  [Intent.Exploring]: 'Exploring',
  [Intent.Support]: 'Support',
  [Intent.Transaction]: 'Purchase',
} as const;

export const INTENT_KINDS: Record<Intent, TagKind> = {
  [Intent.Transaction]: 'success',
  [Intent.Support]: 'purple',
  [Intent.Exploring]: 'default',
};

export const INTENT_TOOLTIP_TITLES = {
  [Intent.Exploring]: 'Spotlight on explorers!',
  [Intent.Support]: 'Campaigns to the rescue!',
  [Intent.Transaction]: 'Eyes on Buyers!',
};

export const INTENT_TOOLTIP_DESCRIPTIONS = {
  [Intent.Exploring]: 'Deliver tailored campaigns to browsing visitors and guide them through your website.',
  [Intent.Support]: 'Craft campaigns to proactively offer help to visitors looking for your assistance. ',
  [Intent.Transaction]: 'Maximize your sales potential with campaigns enticing visitors to seal the deal.',
};

export const INTENT_IMAGES = {
  [Intent.Exploring]: ExploringIntentImage,
  [Intent.Support]: SupportIntentImage,
  [Intent.Transaction]: TransactionIntentImage,
};
