/* eslint-disable @typescript-eslint/no-unsafe-argument */
import debug from 'debug';
import type { SagaIterator } from 'redux-saga';
import { call, debounce, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { COPILOT_NO_AI_AGENTS_MESSAGE, PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE } from 'constants/copilot';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { QueryKey } from 'constants/query-key';
import { type KeyMap } from 'helpers/interface';
import { uniqueId } from 'helpers/string';
import { type Features } from 'services/api/feature-control/interfaces';
import { getQueryClient } from 'services/query-client/client';
import { RequestAction } from 'store/entities/actions';
import { getLoggedInAgentName } from 'store/entities/agents/selectors';
import { AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import { hasFetchedAgentCustomProperties } from 'store/features/agent-custom-properties/selectors';
import type { IActionWithPayload } from 'store/helper';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCanUseCopilotPopover } from 'store/views/copilot/selectors';
import { OnboardingActionsEnum } from 'store/views/onboarding/actions';
import { getShouldShowAppOnboarding } from 'store/views/onboarding/computed';

import { getIsAIAgentAvailableByName } from '../ai-agents/selectors';

import { CopilotEntitiesActionNames, CopilotEntitiesActions } from './actions';
import { isAccountCreatedBeforeCopilotPopoverRelease } from './helper-sagas';
import { type CopilotEvent, type CopilotAddConversationPayload } from './interfaces';
import { COPILOT_ONBOARDING_DELAY } from './onboarding/constants';
import {
  type OnboardingConfig,
  COPILOT_ONBOARDING_CONFIG,
  COPILOT_ONBOARDING_CONFIG_OLD_USERS,
} from './onboarding/copilot-onboarding-config';
import { getOnboardingToStart, handleCopilotNavigateAction, startCopilotOnboarding } from './onboarding/helper-sagas';
import { getCopilotAllEvents } from './selectors';

const log = debug(DebugLogsNamespace.AppDebug);

function* waitForFeaturesQuerySuccess(): SagaIterator {
  const queryClient = getQueryClient();
  let queryState = queryClient.getQueryState<Features>([QueryKey.Features]);

  log('[Features] waitForFeaturesQuerySuccess queryState', queryState?.data?.customerIntentEnabled);

  while (queryState?.fetchStatus === 'fetching') {
    yield delay(200);

    queryState = queryClient.getQueryState<Features>([QueryKey.Features]);

    log('[Features] waitForFeaturesQuerySuccess queryState assigned', queryState?.data?.customerIntentEnabled);
  }
}

function* waitForAgentCustomProperties(): SagaIterator {
  const hasFetchedCustomProperties = yield select(hasFetchedAgentCustomProperties);

  if (!hasFetchedCustomProperties) {
    yield race({
      success: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]),
      failure: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.FAILURE]),
    });
  }
}

function* sendNoAIAgentsError(): SagaIterator {
  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: 'live-assistant',
      authorType: 'live-assistant',
      eventId: uniqueId(),
      text: `${COPILOT_NO_AI_AGENTS_MESSAGE.header}\n\n${COPILOT_NO_AI_AGENTS_MESSAGE.text}`,
      timestampInMs: Date.now(),
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    }),
  );
}

function* sendWelcomeMessage(): SagaIterator {
  const loggedAgentName: string = yield select(getLoggedInAgentName);

  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: 'live-assistant',
      authorType: 'live-assistant',
      eventId: uniqueId(),
      text: `Hey ${loggedAgentName} 👋. ${PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE}`,
      timestampInMs: Date.now(),
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    }),
  );
}

// check if there is more than welcome message sent on app-ready from Copilot
function* getHasCopilotMoreThanOneEvent(): SagaIterator {
  const copilotAllEvents: KeyMap<CopilotEvent> = yield select(getCopilotAllEvents);

  return Object.keys(copilotAllEvents).length > 1;
}

function* triggerCopilotOnboarding(): SagaIterator {
  const { timeoutOccured } = yield race({
    success: call(function* () {
      yield call(waitForFeaturesQuerySuccess);
      yield call(waitForAgentCustomProperties);
    }),
    timeoutOccured: delay(15000),
  });

  if (timeoutOccured) {
    return;
  }

  const canUseCopilot: boolean = yield select(getCanUseCopilotPopover);
  const hasCopilotMoreThanOneEvent = yield call(getHasCopilotMoreThanOneEvent);
  const hasOneAIAgent = yield select(getIsAIAgentAvailableByName, 'one');
  const shouldShowAppOnboarding = yield select(getShouldShowAppOnboarding);

  if (!canUseCopilot || hasCopilotMoreThanOneEvent) {
    return;
  }

  if (shouldShowAppOnboarding) {
    yield take(OnboardingActionsEnum.ONBOARDING_FINISHED);
  }

  if (!hasOneAIAgent) {
    yield call(sendNoAIAgentsError);

    return;
  }

  const onboardingToStart = yield call(getOnboardingToStart);

  if (!onboardingToStart) {
    const hasCopilotMoreThanOneEvent = yield call(getHasCopilotMoreThanOneEvent);

    if (hasCopilotMoreThanOneEvent) {
      return;
    }

    return;
  }

  const isOldUser = yield call(isAccountCreatedBeforeCopilotPopoverRelease);
  const copilotOnboardingConfig: OnboardingConfig = isOldUser
    ? COPILOT_ONBOARDING_CONFIG_OLD_USERS
    : COPILOT_ONBOARDING_CONFIG;

  switch (onboardingToStart) {
    case 'copilot-onboarding':
      yield call(startCopilotOnboarding, copilotOnboardingConfig);

      return;
    default:
      return;
  }
}

function* handleCopilotConversation(action: IActionWithPayload<string, CopilotAddConversationPayload>): SagaIterator {
  const payload = action.payload;

  for (const message of payload) {
    yield put(CopilotEntitiesActions.addCopilotMessage(message.event));

    if (message.delay) {
      yield put(CopilotViewActions.setCopilotIsLoading(true));
      yield delay(message.delay);
      yield put(CopilotViewActions.setCopilotIsLoading(false));
    }
  }
}

export function* copilotSaga(): SagaIterator {
  yield takeLatest(['APP_READY'], sendWelcomeMessage);
  yield debounce(COPILOT_ONBOARDING_DELAY, ['APP_READY'], triggerCopilotOnboarding);
  yield takeEvery(CopilotEntitiesActionNames.COPILOT_ADD_CONVERSATION, handleCopilotConversation);
  yield takeEvery(CopilotEntitiesActionNames.COPILOT_NAVIGATE_ACTION, handleCopilotNavigateAction);
}
