import { FacebookActionsEnum, type FacebookActionType } from 'store/entities/facebook/actions';

import { type IFacebookState } from './interfaces';

const initialState: IFacebookState = {
  facebookConnectedPages: [],
};

export const facebookReducer = (state: IFacebookState = initialState, action: FacebookActionType): IFacebookState => {
  switch (action.type) {
    case FacebookActionsEnum.SET_FACEBOOK_CONNECTED_PAGES: {
      return {
        ...state,
        facebookConnectedPages: action.payload.result,
      };
    }

    default:
      return state;
  }
};
