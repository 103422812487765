import { type DiscardChangesModalAction, DiscardChangesModalActionNames } from './actions';
import { type IDiscardChangesModalState } from './interfaces';

const initialState: IDiscardChangesModalState = {
  isOpen: false,
};

export function discardChangesModalReducer(
  state: IDiscardChangesModalState = initialState,
  action: DiscardChangesModalAction
): IDiscardChangesModalState {
  switch (action.type) {
    case DiscardChangesModalActionNames.TOGGLE_MODAL:
      return {
        isOpen: !!action.payload.isOpen,
      };

    default:
      return state;
  }
}
