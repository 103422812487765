import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type UntagThreadEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#untag-thread
 */
export async function untagChatThread(chatId: string, threadId: string, tag: string): Promise<unknown> {
  const event: UntagThreadEvent = {
    action: WebSocketAction.UntagThread,
    payload: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      chat_id: chatId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      thread_id: threadId,
      tag,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
