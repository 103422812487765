import { isDevelopmentEnvironment } from 'helpers/feature-toggle';
import { getItem, LocalStorageKey } from 'services/local-storage';

function isExplicitlyEnabled(): boolean {
  return window.location.search.includes('dev-tools=true') || getItem(LocalStorageKey.DevTools) === true;
}

export function areDevToolsEnabled(): boolean {
  return isDevelopmentEnvironment() || isExplicitlyEnabled();
}

export function areDevToolsInitiallyVisible(): boolean {
  return isExplicitlyEnabled();
}

export const getIsDevToolsSwitchVisible = (): boolean => {
  return isDevelopmentEnvironment();
};
