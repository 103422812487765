import { type FC } from 'react';

import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';

import { isDarkModeOnCurrentSection } from 'store/features/dark-mode/selectors';

import * as styles from './styles';

interface IProps {
  className?: string;
}

export const BetaBadge: FC<IProps> = ({ className }) => {
  const isDarkMode = useSelector(isDarkModeOnCurrentSection);

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.betaLabel(isDarkMode))}>
        <span>Beta</span>
      </div>
      <span className={styles.absoluteBadge}>Beta</span>
    </div>
  );
};
