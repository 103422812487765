// @ts-strict-ignore
import mitt from 'mitt';

import { type InitialData } from './interfaces/initial-data';
import { type StartupDataResultFailure } from './interfaces/startup-data';

let initData: InitialData | null = null;
let initError: StartupDataResultFailure | null = null;
let initFreeAccessPage: string | null = null;

const emitter = mitt();

export function broadcastInitialData(initialData: InitialData): void {
  initData = initialData;
  emitter.emit('initial-data', initialData);
}

export async function waitForInitialData(): Promise<InitialData> {
  if (initData) {
    return initData;
  }

  return new Promise((resolve) => {
    emitter.on('initial-data', (initialData: InitialData) => {
      resolve(initialData);
    });
  });
}

export function broadcastInitError(error: StartupDataResultFailure): void {
  initError = error;
  emitter.emit('init-error', error);
}

export async function waitForInitError(): Promise<StartupDataResultFailure> {
  if (initError) {
    return initError;
  }

  return new Promise((resolve) => {
    emitter.on('init-error', (error: StartupDataResultFailure) => {
      resolve(error);
    });
  });
}

export function broadcastFreeAccessPage(path: string): void {
  initFreeAccessPage = path;
  emitter.emit('free-access-page', path);
}

export async function waitForFreeAccessPage(): Promise<string> {
  if (initFreeAccessPage) {
    return initFreeAccessPage;
  }

  return new Promise((resolve) => {
    emitter.on('free-access-page', (path: string) => {
      resolve(path);
    });
  });
}
