export const CHART_TOPICS_DISPLAYED_COUNT = 7;
export const BREAKDOWN_PAGE_SIZE = 50;

export const CHAT_TOPICS_RETRY_COUNT = 1;

// this limit was introduced to avoid big request and potentially signifact parsing time
// it's highly possible that licenses will never reach it
export const CHAT_TOPICS_CSV_RECORDS_LIMIT = 2000;

export const SHOW_SENTIMENT_QUERY_PARAM_NAME = 'show_sentiment';
export const SHOW_SENTIMENT_QUERY_PARAM_VALUE = '1';

export const CHAT_TOPICS_CHART_TITLE = 'Most frequent chat topics';
