export enum ServerError {
  TooManyAgents = 'tooManyAgents',
  LicenseExpired = 'licenseExpired',
  WaitingForApproval = 'waitingForApproval',
  IpNotAllowed = 'ipNotAllowed',
  InvalidSession = 'invalidSession',
  AccountSuspended = 'accountSuspended',
  TooManyRequests = 'tooManyRequests',
  LicenseBlockedInCRM = 'licenseBlockedInCRM',
  UnexpectedError = 'unexpectedError',
}
