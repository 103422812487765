import { getConfig } from 'helpers/config';
import { getAccessToken } from 'services/auth/auth-storage-manager';

import { createApiClient } from '../http/client';
import { type ApiClientInstance } from '../http/types';

export const createQueueApiClient = (): ApiClientInstance => {
  return createApiClient({
    baseURL: getConfig().queueApi,
    getCredentials: getAccessToken,
  });
};
