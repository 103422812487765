import { createAgentChatApiClient } from '../client';
import { type AgentChatApiErrorResponse } from '../types';

import { type RemoveUserFromChatRequest, type AddUserToChatRequest } from './types';

const client = createAgentChatApiClient();

/**
 * Chat users API client.
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#chat-users}
 */
export const chatUsersClient = {
  /**
   * Adds a user to a chat.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#add-user-to-chat}
   */
  addUserToChat: async (data: AddUserToChatRequest) => {
    return client.post<void, AgentChatApiErrorResponse>('add_user_to_chat', {
      json: data,
    });
  },
  /**
   * Removes a user from a chat.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#remove-user-from-chat}
   */
  removeUserFromChat: async (data: RemoveUserFromChatRequest) => {
    return client.post<void, AgentChatApiErrorResponse>('remove_user_from_chat', {
      json: data,
    });
  },
};
