import { createAgentChatApiClient } from '../client';
import { type AgentChatApiErrorResponse } from '../types';

import { type GetLicenseInfoResponse } from './types';

const client = createAgentChatApiClient();

/**
 * License info API client.
 */
export const licenseInfoClient = {
  /**
   * Gets license info.
   * @todo: Add documentation link when available.
   */
  getLicenseInfo: async () => {
    return client.post<GetLicenseInfoResponse, AgentChatApiErrorResponse>('get_license_info', {
      json: {},
    });
  },
};
