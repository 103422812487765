import noop from 'lodash.noop';

import notificationIcon from 'assets/img/notification-icon@2x.png';
import { EventPlace } from 'helpers/analytics';
import { isDesktopAppDetected } from 'helpers/desktop-app/is-detected';
import { navigate } from 'helpers/routing';
import { uniqueId } from 'helpers/string';
import { getDesktopApp } from 'services/desktop-application/desktop-app';
import { trackEvent } from 'services/event-tracking';

import { type INotification } from './interfaces';

interface IWebkitNotification extends Notification {
  show?: () => void;
}

const notifications: Map<string, Notification> = new Map();

export function cancelNotification(id: string): void {
  const notification = notifications.get(id);
  if (notification) {
    notification.close();
    notifications.delete(id);
    getDesktopApp().cancelNotifications([notification.tag]);
  }
}

export function cancelAllNotifications(): void {
  notifications.forEach((notification) => {
    notification.close();
  });
  notifications.clear();

  getDesktopApp().clearAllNotifications();
}

export function cancelSomeNotifications(notificationsTags: string[] = []): void {
  // always close 'reconnect' notification
  if (!notificationsTags.includes('reconnect')) {
    notificationsTags.push('reconnect');
  }

  // all tags have "livechat_" prefix
  const tags = notificationsTags.map((tag) => `livechat_${tag}`);
  notifications.forEach((notification, key) => {
    if (tags.includes(notification.tag)) {
      notification.close();
      notifications.delete(key);
    }
  });

  getDesktopApp().cancelNotifications(tags);
}

export function showNotification(options: INotification): void {
  const opts = {
    id: uniqueId(),
    text: '',
    onClose: noop,
    ...options,
  };

  // replace webapp-only links in notifications
  opts.text = opts.text || '';
  opts.text = opts.text.replace(/\%close_window_link\%/g, '');
  opts.text = opts.text.replace(/\%ticket=([^\%]+)\%/g, 'ticket');

  // remove previous notifications of the same type
  cancelSomeNotifications([opts.tag]);

  // inform SmartClient about new notification
  getDesktopApp().displayNewNotification({
    title: opts.title,
    body: opts.text,
    icon: notificationIcon,
    tag: `livechat_${opts.tag}`,
    afterClick: opts.afterClick,
  });

  if (window.Notification) {
    const properties: NotificationOptions = {
      icon: notificationIcon,
      body: opts.text,
      tag: `livechat_${opts.tag}`,
    };

    try {
      const notification = new Notification(opts.title, properties);

      const onClick = (): void => {
        opts.onClose();
        window.focus();
        notification.close();

        if (opts.afterClick.targetPage) {
          navigate(opts.afterClick.targetPage);
        }

        if (opts.afterClick.trackEvent) {
          trackEvent(opts.afterClick.trackEvent, EventPlace.Notification);
        }

        cancelSomeNotifications([opts.tag]);
        notification.removeEventListener('click', onClick);
      };
      notification.addEventListener('click', onClick);

      if (opts.tag === 'test_notification') {
        const onShow = (): void => {
          setTimeout(() => cancelNotification(String(opts.id)), 4000);
          notification.removeEventListener('show', onShow);
        };
        notification.addEventListener('show', onShow);
      }

      notifications.set(opts.id, notification);

      if (notification) {
        const webkitNotification = notification as IWebkitNotification;
        if (typeof webkitNotification.show === 'function') {
          webkitNotification.show();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
}

export function areNotificationsSupported(): boolean {
  if (isDesktopAppDetected()) {
    return true;
  }

  if (window.Notification) {
    return true;
  }

  return false;
}

export function cleanNotificationText(text: string): string {
  return text
    .replace(/<br\s*(\/|\s+data-role="br")?\s*>/gi, '\n') // Convert <br> tags to newline
    .replace(/&lt;.*?&gt;/g, '') // Remove encoded HTML tags
    .replace(/<[^>]+>/g, '') // Remove remaining HTML tags
    .replace(/[*~]/g, ''); // Remove * and ~ characters
}
