import type { FC } from 'react';

import { cx } from '@emotion/css';

import { TypingIndicator } from 'components/typing-indicator/TypingIndicator';

import * as styles from './styles';

interface IProps {
  eventId: string;
}

export const CopilotLoaderEvent: FC<IProps> = ({ eventId }) => (
  <div className="bubble-wrapper" key={eventId}>
    <div className={styles.eventWrapper(false)}>
      <div className={cx('bubble')}>
        <TypingIndicator className={styles.typingIndicator} />
      </div>
    </div>
  </div>
);
