import { css } from '@emotion/css';

export const sideNavigationStyles = css`
  > div:nth-of-type(2) {
    height: 100%;

    > div:last-of-type {
      margin-top: auto;
    }
  }
`;
