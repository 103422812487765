import type { IAppState } from 'services/app-state-provider';
import { CannedResponseActions } from 'store/entities/canned-responses/actions';

export function handleCannedResponseRemove(payload: any, store: IAppState): void {
  const {
    canned_response: { id },
  } = payload;

  store.dispatch(
    CannedResponseActions.removeCannedResponseData({
      id: String(id),
    })
  );
}
