// @ts-strict-ignore
import { createSelector } from 'reselect';

import { ChatEventType } from 'constants/chat-event-type';
import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { botsSelector } from 'store/entities/bots/selectors';

import {
  isClosedThread,
  isEventWithText,
  isSystemMessage,
  excludeBotMessages,
  getEventsByType,
} from './helpers/common';
import { createLabeledMessageFromThreadEvents } from './helpers/reply-suggestions';
import type { ChatThreadEntity, IMessage, ISurveyMessage, IUnassignedChat } from './interfaces';
import {
  type IWithChatsEntityState,
  getQueuedIds,
  getAllChats,
  getUnassignedIds,
  getThreadEvents,
  getIsQueuedChatVisuallyClosed,
} from './selectors';

/**
 * Looks for the most relevant chat for a customer.
 * A customer may have more than one closed chats and maximum 1 non-closed (active) chat.
 * If an active chat was found, it will be returned.
 * Otherwise, we look for a closed Unassigned chat which is more relevant then regular closed chat.
 * Lastly, we return a regular closed chat that was found for this customer, or null otherwise.
 */
export function getMostRelevantThreadByCustomerId(
  state: IWithChatsEntityState,
  customerId: string
): ChatThreadEntity | null {
  let closedThread: ChatThreadEntity = null;
  let unassignedClosedThread: ChatThreadEntity = null;

  const activeThread = Object.values(state.entities.chats.threads).find((thread: ChatThreadEntity) => {
    if (thread.customerId === customerId) {
      if (!isClosedThread(thread)) {
        return true;
      }

      if (thread.type === ChatType.Unassigned) {
        unassignedClosedThread = thread;
      } else {
        closedThread = thread;
      }
    }

    return false;
  });

  return activeThread || unassignedClosedThread || closedThread;
}

export function getQueuedChatsCount(state: IWithChatsEntityState): number {
  return getQueuedIds(state).filter((threadId) => !getIsQueuedChatVisuallyClosed(state, threadId)).length;
}

const getUnassignedThreads = createSelector(getAllChats, getUnassignedIds, (allChats, unassignedIds) =>
  unassignedIds.map((id) => allChats[id])
);

export function getUnpinnedUnassignedChatsCount(state: IWithChatsEntityState): number {
  const unassignedChats = getUnassignedThreads(state);

  return unassignedChats.filter(
    (thread) =>
      thread.customProperties?.wasUnassigned && (thread as IUnassignedChat)?.status !== ChatThreadStatus.Active
  ).length;
}

export const getClosedThreadIds = (state: IWithChatsEntityState): string[] => {
  const allChats = getAllChats(state);

  return Object.values(allChats).reduce<string[]>((acc, thread): string[] => {
    if (isClosedThread(thread)) {
      acc.push(thread.threadId);
    }

    return acc;
  }, []);
};

export const getThreadMessagesTextArray = createSelector(getThreadEvents, (threadEvents) =>
  Object.values(threadEvents).reduce<{ text: string; timestamp: number }[]>((acc, threadEvent) => {
    if (isEventWithText(threadEvent) && !isSystemMessage(threadEvent)) {
      acc.push({ text: threadEvent.text, timestamp: threadEvent.timestampInMs });
    }

    return acc;
  }, [])
);

export const getMessagesForResponseSuggestion = createSelector(
  getThreadEvents,
  botsSelector,
  (threadEvents, licenseBots) => {
    const eventsWithExcludedBotMessages = excludeBotMessages(threadEvents, licenseBots);
    const filteredEvents = getEventsByType<IMessage | ISurveyMessage>(eventsWithExcludedBotMessages, [
      ChatEventType.Message,
      ChatEventType.FilledForm,
    ]);

    return createLabeledMessageFromThreadEvents(filteredEvents);
  }
);
