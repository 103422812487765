/* eslint-disable @typescript-eslint/naming-convention */
import { differenceInCalendarDays } from 'date-fns';

import { getConfig } from 'helpers/config';
import { secondsTimestampToDate } from 'helpers/date';
import { isMobileDevice } from 'helpers/device';
import { type ICurrentLicense } from 'interfaces/entities/current-license';
import { type IAgent } from 'store/entities/agents/interfaces';

import { getItem, LocalStorageKey } from './local-storage';

interface WindowSprig extends Window {
  configure: (config: { environmentId: string }) => WindowSprig;
  setUserId: (userId: string) => Promise<void>;
  setAttributes: (attributes: {
    license_id?: number;
    license_plan?: string;
    license_type?: string;
    license_seats?: number;
    license_agents?: number;
    license_fresh?: boolean;
    license_age_in_days?: number;
    company_name?: string;
    company_country?: string;
    user_email?: string;
    user_id?: string;
    user_role?: string;
    user_permission?: string;
    user_name?: string;
    user_type?: string;
  }) => Promise<void>;
  track: (eventKey: string) => Promise<void>;
  logoutUser: () => void;
}

declare global {
  interface Window {
    Sprig: WindowSprig;
  }
}

export interface ISprigOptions {
  agent: IAgent | null;
  license: ICurrentLicense;
  companyName: string;
  subscriptionSeats: number;
  companyCountry: string;
  agentsCount: number;
}

interface ISprigService {
  initSprig: (options: ISprigOptions) => Promise<void>;
  initSprigEvent: (eventKey: string) => Promise<void>;
  endSprig: () => void;
}

export interface ISprigServiceStub extends ISprigService {
  getOptions: () => ISprigOptions[];
  getEventKeys: () => string[];
  getHasEnded: () => boolean;
  getEnvironmentId: () => string;
}

let sprigService: ISprigService;

const FRESH_LICENSE_DAYS = 3;

const getIsFreshLicense = (createdAt: number): boolean => {
  if (!createdAt) {
    return false;
  }
  const licenseCreationDate = secondsTimestampToDate(createdAt);

  const now = new Date().getTime();
  const daysDifference = differenceInCalendarDays(now, licenseCreationDate);

  return daysDifference < FRESH_LICENSE_DAYS;
};

const getDifferenceInDays = (createdAt: number): number => {
  const now = new Date();
  const creationDate = new Date(createdAt * 1000);

  return differenceInCalendarDays(now, creationDate);
};

export function getSprigService(): ISprigService {
  if (sprigService) {
    return sprigService;
  }

  if (getItem(LocalStorageKey.SprigHidden)) {
    const stub = new SprigServiceStub();
    sprigService = stub;
    window.sprigStub = stub;
  } else {
    sprigService = new SprigService();
  }

  return sprigService;
}

class SprigServiceStub implements ISprigServiceStub {
  options: ISprigOptions[] = [];
  eventKeys: string[] = [];
  hasEnded = false;
  environmentId = '';

  public async initSprig(options: ISprigOptions): Promise<void> {
    this.options.push(options);
    this.environmentId = getConfig().sprigId;

    return Promise.resolve();
  }

  public async initSprigEvent(eventKey: string): Promise<void> {
    this.eventKeys.push(eventKey);

    return Promise.resolve();
  }

  public endSprig(): void {
    this.hasEnded = true;

    return;
  }

  public getOptions(): ISprigOptions[] {
    return this.options;
  }

  public getEventKeys(): string[] {
    return this.eventKeys;
  }

  public getHasEnded(): boolean {
    return this.hasEnded;
  }

  public getEnvironmentId(): string {
    return this.environmentId;
  }
}

/**
 * Represents a service for managing Sprig integration.
 */
class SprigService implements ISprigService {
  private retryCount = 0;
  private retryLimit = 3;
  private retryInterval = 2000;
  private loadTimeout = 10000;

  /**
   * Waits for the Sprig library to load within a specified timeout.
   * It retries loading at intervals until the retry limit is reached or the library is loaded.
   * @returns A promise that resolves to a boolean indicating whether Sprig was loaded successfully.
   */
  private async waitForSprig(): Promise<boolean> {
    return new Promise((resolve) => {
      const startTime = Date.now();
      this.retryCount = 0;

      const checkSprig = (): void => {
        if (this.sprig?.setUserId) {
          // eslint-disable-next-line no-console
          console.info('Sprig loaded on time.');
          resolve(true);
        } else if (Date.now() - startTime > this.loadTimeout) {
          // eslint-disable-next-line no-console
          console.warn('Sprig did not load in time.');
          resolve(false);
        } else if (this.retryCount >= this.retryLimit) {
          // eslint-disable-next-line no-console
          console.warn(`Sprig loading exceeded retry limit of ${this.retryLimit}.`);
          resolve(false);
        } else {
          this.retryCount++;
          setTimeout(checkSprig, this.retryInterval);
        }
      };

      checkSprig();
    });
  }

  /**
   * Gets the Sprig instance from the window object.
   * @returns The Sprig instance if available, otherwise undefined.
   */
  private get sprig(): WindowSprig | undefined {
    return window.Sprig;
  }

  /**
   * Initializes the Sprig library with the provided options.
   * This method waits for Sprig to be available before setting user and company attributes.
   * @param options - The options to initialize Sprig with, including agent and company information.
   */
  public async initSprig(options: ISprigOptions): Promise<void> {
    const sprigAvailable = await this.waitForSprig();
    if (!sprigAvailable) {
      // eslint-disable-next-line no-console
      console.warn('Sprig is not available.');

      return;
    }

    const { agent, license, companyName, companyCountry, subscriptionSeats, agentsCount } = options;

    if (getItem(LocalStorageKey.SprigHidden)) {
      return;
    }

    const isMobile = isMobileDevice();

    if (isMobile) {
      return;
    }

    if (!agent) {
      return;
    }

    const isFreshLicense = getIsFreshLicense(license?.createdAt);
    const createdDaysAgo = getDifferenceInDays(license?.createdAt);

    await this.sprig?.setUserId(agent.login);
    await this.sprig?.setAttributes({
      license_id: license?.licenseId,
      license_plan: license?.plan,
      license_type: license?.type,
      license_seats: subscriptionSeats,
      license_agents: agentsCount,
      license_fresh: isFreshLicense,
      license_age_in_days: createdDaysAgo,
      company_name: companyName,
      company_country: companyCountry,
      user_email: agent.email || '',
      user_id: agent.login,
      user_role: agent.role || '',
      user_permission: agent.permission || '',
      user_name: agent.name,
      user_type: agent.type,
    });
  }

  /**
   * Initializes a Sprig event tracking with the specified event key.
   * @param eventKey - The key of the event to track.
   */
  public async initSprigEvent(eventKey: string): Promise<void> {
    await this.sprig?.track(eventKey);
  }

  /**
   * Ends the Sprig session by logging out the user.
   */
  public endSprig(): void {
    this.sprig?.logoutUser();
  }
}
