import { getTime } from 'date-fns';

import { getConfig } from 'helpers/config';
import type { IAppState } from 'services/app-state-provider';
import { getAllChats } from 'store/entities/chats/selectors';
import { CustomerActions } from 'store/entities/customers/actions';
import { getAllCustomers, getCustomer } from 'store/entities/customers/selectors';
import { getLongestOfflineCustomers } from 'store/views/traffic/helpers/sagas';

import { type ICustomerLeftPushEvent } from '../interfaces';

export const handleCustomerLeft = (payload: ICustomerLeftPushEvent, store: IAppState): void => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, left_at } = payload;
  const state = store.getState();
  const existingCustomer = getCustomer(state, id);

  if (existingCustomer) {
    store.dispatch(
      CustomerActions.updateCustomer({
        customer: {
          ...existingCustomer,
          visitDetails: {
            ...existingCustomer.visitDetails,
            visitEndedTimestampInMs: getTime(left_at),
          },
        },
      })
    );

    const state = store.getState();
    const offlineCustomersToRemove = getLongestOfflineCustomers(
      getAllCustomers(state),
      getAllChats(state),
      getConfig().offlineCustomersLimit
    );

    if (offlineCustomersToRemove.length > 0) {
      store.dispatch(
        CustomerActions.removeCustomers({
          customerIds: offlineCustomersToRemove,
        })
      );
    }
  }
};
