import { type ActionsUnion, createAction } from 'store/helper';

import {
  type IAddOrUpdateCustomersPayload,
  type IAddVisitedPagePayload,
  type IRemoveCustomerPayload,
  type IRemoveCustomersPayload,
  type ISetCustomersDataPayload,
  type IUpdateCustomerInfoFailurePayload,
  type IUpdateCustomerInfoPayload,
  type IUpdateCustomerInfoSuccessPayload,
  type IUpdateCustomerPayload,
} from './interfaces';

export enum CustomerActionNames {
  ADD_CUSTOMER = 'ENTITIES/CUSTOMERS/ADD_CUSTOMER',
  REMOVE_CUSTOMER = 'ENTITIES/CUSTOMERS/REMOVE_CUSTOMER',
  REMOVE_CUSTOMERS = 'ENTITIES/CUSTOMERS/REMOVE_CUSTOMERS',
  SET_DATA = 'ENTITIES/CUSTOMERS/SET_DATA',
  UPDATE_CUSTOMER = 'ENTITIES/CUSTOMERS/UPDATE_CUSTOMER',
  ADD_OR_UPDATE_CUSTOMERS = 'ENTITIES/CUSTOMERS/ADD_OR_UPDATE_CUSTOMERS',
  UPDATE_CUSTOMER_INFO_REQUEST = 'ENTITIES/CUSTOMERS/UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_INFO_SUCCESS = 'ENTITIES/CUSTOMERS/UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_INFO_FAILURE = 'ENTITIES/CUSTOMERS/UPDATE_CUSTOMER_FAILURE',
  ADD_VISITED_PAGE = 'ENTITIES/CUSTOMERS/ADD_VISITED_PAGE',
}

export const CustomerActions = {
  removeCustomer: (payload: IRemoveCustomerPayload) => createAction(CustomerActionNames.REMOVE_CUSTOMER, payload),
  setData: (payload: ISetCustomersDataPayload) => createAction(CustomerActionNames.SET_DATA, payload),
  updateCustomer: (payload: IUpdateCustomerPayload) => createAction(CustomerActionNames.UPDATE_CUSTOMER, payload),
  addVisitedPage: (payload: IAddVisitedPagePayload) => createAction(CustomerActionNames.ADD_VISITED_PAGE, payload),
  addOrUpdateCustomers: (payload: IAddOrUpdateCustomersPayload) =>
    createAction(CustomerActionNames.ADD_OR_UPDATE_CUSTOMERS, payload),
  updateCustomerInfo: (
    payload: IUpdateCustomerInfoPayload // remove ?
  ) => createAction(CustomerActionNames.UPDATE_CUSTOMER_INFO_REQUEST, payload),
  updateCustomerInfoSuccess: (payload: IUpdateCustomerInfoSuccessPayload) =>
    createAction(CustomerActionNames.UPDATE_CUSTOMER_INFO_SUCCESS, payload),
  updateCustomerInfoFailure: (payload: IUpdateCustomerInfoFailurePayload) =>
    createAction(CustomerActionNames.UPDATE_CUSTOMER_INFO_FAILURE, payload),
  removeCustomers: (payload: IRemoveCustomersPayload) => createAction(CustomerActionNames.REMOVE_CUSTOMERS, payload),
};

export type CustomerAction = ActionsUnion<typeof CustomerActions>;
