import { type IInitChatBotParams } from 'services/api/chatbot-in-livechat-api/interfaces';
import { createAction, type ActionsUnion } from 'store/helper';

import {
  type IIsCompanySizeDetectedPayload,
  type IIsRefetchTechnologiesPayload,
  type IOnboardingFinishedPayload,
  type IShowInviteDeveloperFormPayload,
  type IUpdateCompanySizePayload,
} from './interfaces';

export enum OnboardingActionsEnum {
  SEND_INSTRUCTIONS_EMAIL = 'VIEWS/ONBOARDING/SEND_INSTRUCTIONS_EMAIL',
  RUN_PREONBOARDING_CONFIGURATION = 'VIEWS/ONBOARDING/RUN_PREONBOARDING_CONFIGURATION',
  ONBOARDING_FINISHED = 'VIEWS/ONBOARDING/ONBOARDING_FINISHED',
  ONBOARDING_STATUS_FETCHED = 'VIEWS/ONBOARDING/ONBOARDING_STATUS_FETCHED',
  SET_SHOW_INVITE_DEVELOPER_FORM = 'VIEWS/ONBOARDING/SET_SHOW_INVITE_DEVELOPER_FORM',
  SET_IS_COMPANY_SIZE_DETECTED = 'VIEWS/ONBOARDING/SET_IS_COMPANY_SIZE_DETECTED',
  UPDATE_COMPANY_SIZE = 'VIEWS/ONBOARDING/UPDATE_COMPANY_SIZE',
  INITIALIZE_CHATBOT = 'VIEWS/ONBOARDING/INITIALIZE_CHATBOT',
  SET_SKIP_INSTALLATION_STEP = 'VIEWS/ONBOARDING/SET_SKIP_INSTALLATION_STEP',
  SET_IS_REFETCH_TECHNOLOGIES = 'VIEWS/ONBOARDING/SET_IS_REFETCH_TECHNOLOGIES',
  RESET_ONBOARDING = 'VIEWS/ONBOARDING/RESET_ONBOARDING',
}

export const OnboardingActions = {
  sendInstructionsEmail: () => createAction(OnboardingActionsEnum.SEND_INSTRUCTIONS_EMAIL),
  runPreOnboardingConfiguration: () => createAction(OnboardingActionsEnum.RUN_PREONBOARDING_CONFIGURATION),
  onboardingStatusFetched: (payload: IOnboardingFinishedPayload) =>
    createAction(OnboardingActionsEnum.ONBOARDING_STATUS_FETCHED, payload),
  onboardingFinished: () => createAction(OnboardingActionsEnum.ONBOARDING_FINISHED),
  setShowInviteDeveloperForm: (payload: IShowInviteDeveloperFormPayload) =>
    createAction(OnboardingActionsEnum.SET_SHOW_INVITE_DEVELOPER_FORM, payload),
  setIsCompanySizeDetected: (payload: IIsCompanySizeDetectedPayload) =>
    createAction(OnboardingActionsEnum.SET_IS_COMPANY_SIZE_DETECTED, payload),
  setSkipInstallationStep: () => createAction(OnboardingActionsEnum.SET_SKIP_INSTALLATION_STEP),
  updateCompanySize: (payload: IUpdateCompanySizePayload) =>
    createAction(OnboardingActionsEnum.UPDATE_COMPANY_SIZE, payload),
  initializeChatBot: (payload: IInitChatBotParams) => createAction(OnboardingActionsEnum.INITIALIZE_CHATBOT, payload),
  setIsRefetchTechnologies: (payload: IIsRefetchTechnologiesPayload) =>
    createAction(OnboardingActionsEnum.SET_IS_REFETCH_TECHNOLOGIES, payload),
  resetOnboardig: () => createAction(OnboardingActionsEnum.RESET_ONBOARDING),
};

export type OnboardingActionType = ActionsUnion<typeof OnboardingActions>;
