import { type IFacebookPage } from 'store/entities/facebook/interfaces';

import type { RequestResult } from 'interfaces/api/client';
import { BaseApi, PassVersionMode } from '../base-api';

type IFetchConnectedPagesResponse = IFacebookPage[];

export class FacebookMessengerApi extends BaseApi {
  protected readonly prefix = 'fanpages';
  protected readonly passVersionMode: PassVersionMode = PassVersionMode.None;

  fetchConnectedPages = (): Promise<RequestResult<IFetchConnectedPagesResponse>> => {
    return this.get();
  };
}
