import { type BusinessModel } from 'constants/business-model';
import { type PlanType } from 'constants/plan-type';

export enum LicenseType {
  Paid = 'paid',
  Trial = 'trial',
}

export enum PartnerType {
  Reseller = 'reseller',
  Affiliate = 'affiliate',
  None = '',
}

export interface ICurrentLicense {
  licenseId: number;
  plan: PlanType;
  createdAt: number;
  expiresAt: number;
  type: LicenseType;
  partnerType: PartnerType;
  businessModel?: BusinessModel;
}
