import { type LanguageCode } from 'constants/languages-available';
import { type GroupId } from 'interfaces/groups';
import type { LanguagePhrases } from 'interfaces/language';

import { BaseApi } from '../base-api';

import { type ILanguagesResult } from './interfaces';

export class LanguagesApi extends BaseApi {
  protected readonly prefix: string = 'languages';

  fetchLanguage = (languageCode: LanguageCode, groupId: GroupId) =>
    this.get<ILanguagesResult>(languageCode, { group: groupId });

  saveLanguage = (languageCode: LanguageCode, phrases: Partial<LanguagePhrases>, groupId: GroupId) =>
    this.put(languageCode, { ...phrases, group: Number(groupId) });
}
