/* eslint-disable @typescript-eslint/naming-convention */

import type { KeyMap } from 'helpers/interface';
import type { RequestFailure } from 'store/entities/interfaces';

export enum LicenseCustomPropertiesNames {
  CovidCoupon = 'covid_coupon_used',
  MessagingModeOnboardingModalSeen = 'messaging_mode_modal_onboarding_seen',
  HelpDeskChannelOn = 'helpdesk_channel_on',
  ShouldShowHelpDeskPromoTooltip = 'should_show_helpdesk_promo_tooltip',
  RoktId = 'rokt_id',
  PaymentErrorOnAccessBlocked = 'payment_error_on_access_blocked',
}

export interface IFetchLicenseCustomPropertiesSuccessPayload extends KeyMap<string | string[]> {}

export interface IFetchLicenseCustomPropertiesFailurePayload extends RequestFailure {}

export interface ISaveLicenceCustomPropertyPayload {
  name: string;
  value: string;
  for_agents?: number;
  for_visitors?: number;
}
