import { ADD_WEBSITE_SOURCE_FORM_INITIAL_STATE } from 'constants/knowledge-hub';

import { AutomateViewActionNames, type AutomateViewActionTypes } from './actions';
import { type AutomateViewState } from './interfaces';

const EMPTY_OBJECT = {};

const initialState: AutomateViewState = {
  knowledgeHub: {
    websitesForm: ADD_WEBSITE_SOURCE_FORM_INITIAL_STATE,
    taskId: null,
    blacklistedWebpages: [],
    modal: {
      type: null,
    },
  },
  cannedResponses: {
    searchValue: '',
    cannedResponseForm: EMPTY_OBJECT,
  },
};

export const automateViewReducer = (
  state: AutomateViewState = initialState,
  action: AutomateViewActionTypes,
): AutomateViewState => {
  switch (action.type) {
    case AutomateViewActionNames.SET_ADD_WEBSITE_SOURCE_FORM_VALUES: {
      return {
        ...state,
        knowledgeHub: {
          ...state.knowledgeHub,
          websitesForm: action.payload,
        },
      };
    }

    case AutomateViewActionNames.CLEAR_ADD_WEBSITE_SOURCE_FORM_VALUES: {
      return {
        ...state,
        knowledgeHub: {
          ...state.knowledgeHub,
          websitesForm: ADD_WEBSITE_SOURCE_FORM_INITIAL_STATE,
        },
      };
    }

    case AutomateViewActionNames.SET_CRAWLER_TASK_ID: {
      return {
        ...state,
        knowledgeHub: {
          ...state.knowledgeHub,
          taskId: action.payload.taskId,
        },
      };
    }

    case AutomateViewActionNames.SET_KNOWLEDGE_HUB_MODAL: {
      return {
        ...state,
        knowledgeHub: {
          ...state.knowledgeHub,
          modal: {
            type: action.payload.modal,
            modalProps: action.payload.modalProps,
          },
        },
      };
    }

    case AutomateViewActionNames.SET_BLACKLISTED_WEBPAGES: {
      return {
        ...state,
        knowledgeHub: {
          ...state.knowledgeHub,
          blacklistedWebpages: action.payload.blacklistedWebpages,
        },
      };
    }
    case AutomateViewActionNames.SET_SEARCH: {
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          searchValue: action.payload,
        },
      };
    }
    case AutomateViewActionNames.SET_CANNED_RESPONSE_FORM_VALUES: {
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          cannedResponseForm: action.payload,
        },
      };
    }

    case AutomateViewActionNames.CLEAR_CANNED_RESPONSE_FORM_VALUES: {
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          cannedResponseForm: EMPTY_OBJECT,
        },
      };
    }

    default:
      return state;
  }
};
