import type { IPlanPrices } from 'constants/plan-type';
import { getPlanPriceFromList, preparePlanCode } from 'helpers/subscription';
import { type BillingCycleType, type INewSubscription, type ISubscription } from 'interfaces/subscription';

export function getPlanPrice(
  newSubscription: INewSubscription,
  currentSubscription: ISubscription,
  planPrices: IPlanPrices,
  billingCycle: BillingCycleType
): number {
  return preparePlanCode(newSubscription.plan, newSubscription.billingCycle) === currentSubscription.planCode &&
    !!currentSubscription.currentPeriodStartedAt
    ? currentSubscription.amount / newSubscription.billingCycle
    : (getPlanPriceFromList(newSubscription.plan, billingCycle, planPrices) as number);
}
