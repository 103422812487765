import { type ShortcutsAction, ShortcutsActionNames } from './actions';
import { type IShortcutsState } from './interfaces';

export const initialState = {
  enabled: true,
};

export const shortcutsReducer = (state: IShortcutsState = initialState, action: ShortcutsAction): IShortcutsState => {
  switch (action.type) {
    case ShortcutsActionNames.DISABLE:
      return {
        ...state,
        enabled: false,
      };
    case ShortcutsActionNames.ENABLE:
      return {
        ...state,
        enabled: true,
      };
    default:
      return state;
  }
};
