import type { ReactNode } from 'react';

import { type ITooltipProps } from '@livechat/design-system-react-components';

import { type GlobalModal } from 'constants/global-modal';
import { type UserGuidedTourType } from 'constants/user-guided-tour-type';

export const enum TourStepType {
  InitialTooltip = 'InitialTooltip',
  GuideTooltip = 'GuideTooltip',
  Modal = 'Modal',
}

interface ITooltipTourStep {
  content: ReactNode;
  placement?: ITooltipProps['placement'];
  offsetMainAxis?: ITooltipProps['offsetMainAxis'];
  offsetCrossAxis?: ITooltipProps['offsetCrossAxis'];
}

export interface ITourStep {
  id: string;
  type: TourStepType;
  tooltip?: ITooltipTourStep;
  modal?: GlobalModal;
  isVisible?: boolean;
}

export interface IUserGuidedTour {
  id: UserGuidedTourType;
  isStarted: boolean;
  isPreStarted: boolean;
  isStartedManually?: boolean;
  stepsReady: string[];
}

export interface IUserGuidedTourProperties {
  tourDoneAgentProp: string;
  setAgentPropOnlyOnTourFinish?: boolean;
}
