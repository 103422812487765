// @ts-strict-ignore
import { type Filter } from 'constants/filters/filter';
import { reportsFilters } from 'constants/reports/filters';
import { type ReportType } from 'constants/reports/report-type';
import { type ReportsViewFilters } from 'interfaces/reports';

export function getAvailableReportFilters(filters: ReportsViewFilters, reportType: ReportType): ReportsViewFilters {
  return Object.keys(filters).reduce((acc, filterName) => {
    if (reportsFilters[reportType].filters.includes(filterName as unknown as Filter)) {
      acc[filterName] = filters[filterName];
    }

    return acc;
  }, {} as ReportsViewFilters);
}
