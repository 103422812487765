// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, call, cancel, put, race, select, take, takeEvery } from 'redux-saga/effects';

import { MIN_DISPLAYED_METRICS_COUNT } from 'constants/insights';
import { EventPlace } from 'helpers/analytics';
import { type KeyMap } from 'helpers/interface';
import type { RequestResult } from 'interfaces/api/client';
import {
  type IAgentsPerformanceData,
  type IAgentsPerformanceStats,
  type ITotalChatPeriod,
} from 'interfaces/reports';
import { type ReportData } from 'interfaces/reports/api-v3';
import { ApiManager } from 'services/api/api-manager';
import { serializeJSONFilters } from 'services/api/report/v3/filters';
import { trackEvent } from 'services/event-tracking';
import { RequestAction } from 'store/entities/actions';
import { AgentCustomPropertiesActions, AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import {
  AgentCustomPropertyName,
  type IFetchAgentCustomPropertiesSuccessPayload,
  type IInsightsHintsStatusAgentCustomProperty,
  type IInsightsMetricsAgentCustomProperty,
} from 'store/features/agent-custom-properties/interfaces';
import { getCanUseInsights } from 'store/features/session/selectors';
import { type IActionWithPayload } from 'store/helper';
import { agentsPerformanceDeserializer } from 'store/views/reports/data-deserializers/agents-performance-deserializer';

import { InsightsActions, InsightsActionsNames } from './actions';
import { getOrderedInsightsMetricsData } from './computed';
import {
  AGENTS_PERFORMANCE_REPORT_DEFAULT_FILTERS,
  InsightsEvent,
  MISSED_CHATS_REPORT_DEFAULT_FILTERS,
} from './constants';
import {
  buildReportFilters,
  getInsightsDataFromAgentProperties,
  getInsightsMetricsExpireLocalDate,
  getNow,
  getWasDateReached,
} from './helpers/helpers';
import type {
  AgentsPerformanceReportData,
  IInsightsMetricDetails,
  IInsightsMetrics,
  InsightsAcknowledgedHints,
  InsightsHintId,
  ISerializedInsightsHintsStatus,
  ISerializedInsightsMetrics,
} from './interfaces';
import { getHasMinimumInsightsData, getInsightsAcknowledgedHints } from './selectors';

export function* trackInsightsMetricsGenerated(): SagaIterator {
  const hasMinimumData: boolean = yield select(getHasMinimumInsightsData);
  const metricsData: IInsightsMetricDetails[] = yield select(getOrderedInsightsMetricsData);

  const metrics = metricsData.map((metricData) => ({
    metric: metricData.type,
    trend: metricData.trend,
  }));

  trackEvent(InsightsEvent.MetricsGenerated, EventPlace.Insights, {
    hasMinimumData,
    hasEnoughDataToShowMetrics: metricsData.length >= MIN_DISPLAYED_METRICS_COUNT,
    metrics,
  });
}

export function* handleLoadInsightsData(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>
): SagaIterator {
  const now = getNow();

  const insightsData = getInsightsDataFromAgentProperties(action.payload);
  const shouldFetchNewMetrics = !insightsData || getWasDateReached(now, insightsData.expiresAt);

  if (shouldFetchNewMetrics) {
    yield call(fetchAndStoreInsightsMetrics, now);
  } else {
    yield put(
      InsightsActions.getInsightsSuccess({
        metrics: insightsData.metrics,
        acknowledgedHints: insightsData.acknowledgedHints,
        metricsExpireAt: insightsData.expiresAt,
      })
    );
  }
}

// use metrics from last 2 finished weeks
export function* fetchAndStoreInsightsMetrics(now: Date): SagaIterator {
  const [
    [agentsPerformanceLastWeekStats, agentsPerformanceOneBeforeLastWeekStats],
    [missedChatsLastWeek, missedChatsOneBeforeLastWeek],
  ]: [IAgentsPerformanceStats[], number[]] = yield all([
    call(getMetricsFromAgentsPerformanceReport, now),
    call(getMetricsFromMissedChatsReport, now),
  ]);

  const metrics: IInsightsMetrics = {
    chatsCount: {
      newValue: agentsPerformanceLastWeekStats.chatsCount.value,
      oldValue: agentsPerformanceOneBeforeLastWeekStats.chatsCount.compare,
    },
    chatsPerHour: {
      newValue: agentsPerformanceLastWeekStats.chatsPerHour.value,
      oldValue: agentsPerformanceOneBeforeLastWeekStats.chatsPerHour.compare,
    },
    firstResponseTime: {
      newValue: agentsPerformanceLastWeekStats.firstResponseTime.value,
      oldValue: agentsPerformanceOneBeforeLastWeekStats.firstResponseTime.compare,
    },
    satisfaction: {
      newValue: agentsPerformanceLastWeekStats.satisfaction.value,
      oldValue: agentsPerformanceOneBeforeLastWeekStats.satisfaction.compare,
    },
    missedChats: {
      newValue: missedChatsLastWeek,
      oldValue: missedChatsOneBeforeLastWeek,
    },
  };

  const expireDate = getInsightsMetricsExpireLocalDate(now);

  yield put(InsightsActions.getInsightsSuccess({ metrics, acknowledgedHints: {}, metricsExpireAt: expireDate }));

  yield call(trackInsightsMetricsGenerated);

  // when saving metrics in agent props (should happen once per week, during metrics refresh)
  // always reset hints acknowledge status
  yield call(saveInsightsMetricsInAgentProperties, metrics, expireDate);
  yield call(saveInsightsHintsInAgentProperties, {});
}

function* getMetricsFromAgentsPerformanceReport(now: Date): SagaIterator {
  const lastWeekFilters = buildReportFilters(now, 1, AGENTS_PERFORMANCE_REPORT_DEFAULT_FILTERS);
  const oneBeforeLastWeekFilters = buildReportFilters(now, 2, AGENTS_PERFORMANCE_REPORT_DEFAULT_FILTERS);

  const [lastWeekResponse, oneBeforeLastWeekResponse]: RequestResult<AgentsPerformanceReportData>[] = yield all([
    call(ApiManager.reportApiV3.fetchAgentsPerformance, serializeJSONFilters(lastWeekFilters)),
    call(ApiManager.reportApiV3.fetchAgentsPerformance, serializeJSONFilters(oneBeforeLastWeekFilters)),
  ]);

  const error = lastWeekResponse.error || oneBeforeLastWeekResponse.error;
  if (error) {
    yield put(InsightsActions.getInsightsFailure());
    yield cancel();
  }
  const deserializedLastWeekResponse: IAgentsPerformanceData = yield call(
    agentsPerformanceDeserializer,
    { data: { performance: lastWeekResponse.result } },
    0
  );
  const deserializedOneBeforeLastWeekResponse: IAgentsPerformanceData = yield call(
    agentsPerformanceDeserializer,
    { data: { performance: oneBeforeLastWeekResponse.result } },
    1
  );

  return [deserializedLastWeekResponse.stats, deserializedOneBeforeLastWeekResponse.stats];
}

function* getMetricsFromMissedChatsReport(now: Date): SagaIterator {
  const lastWeekFilters = buildReportFilters(now, 1, MISSED_CHATS_REPORT_DEFAULT_FILTERS);
  const oneBeforeLastWeekFilters = buildReportFilters(now, 2, MISSED_CHATS_REPORT_DEFAULT_FILTERS);

  const [lastWeekResponse, oneBeforeLastWeekResponse]: RequestResult<ReportData<ITotalChatPeriod>>[] = yield all([
    call(ApiManager.reportApiV3.fetchTotalChats, serializeJSONFilters(lastWeekFilters)),
    call(ApiManager.reportApiV3.fetchTotalChats, serializeJSONFilters(oneBeforeLastWeekFilters)),
  ]);

  const error = lastWeekResponse.error || oneBeforeLastWeekResponse.error;
  if (error) {
    yield put(InsightsActions.getInsightsFailure());
    yield cancel();
  }

  return [lastWeekResponse.result.total || 0, oneBeforeLastWeekResponse.result.total || 0];
}

function* saveInsightsMetricsInAgentProperties(metrics: IInsightsMetrics, expireDate: string): SagaIterator {
  const customAgentProperties: KeyMap<ISerializedInsightsMetrics> = {
    [AgentCustomPropertyName.InsightsMetrics]: {
      metrics,
      expiresAt: expireDate,
    },
  };

  yield call(saveInsightsCustomAgentProperties, customAgentProperties);
}

function* saveInsightsHintsInAgentProperties(hints: InsightsAcknowledgedHints): SagaIterator {
  const customAgentProperties: KeyMap<ISerializedInsightsHintsStatus> = {
    [AgentCustomPropertyName.InsightsHints]: {
      acknowledgedHints: hints,
    },
  };

  yield call(saveInsightsCustomAgentProperties, customAgentProperties);
}

function* saveInsightsCustomAgentProperties(
  properties: KeyMap<IInsightsMetricsAgentCustomProperty | IInsightsHintsStatusAgentCustomProperty>
): SagaIterator {
  yield put(AgentCustomPropertiesActions.setAgentCustomProperty(properties));

  const { failure } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (failure) {
    yield cancel();
  }
}

export function* handleCustomPropertiesFetched(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>
): SagaIterator {
  const canUseInsights: boolean = yield select(getCanUseInsights);

  if (!canUseInsights) {
    return;
  }

  yield put(InsightsActions.getInsights(action.payload));
}

function* acknowledgeHint(action: IActionWithPayload<string, InsightsHintId>): SagaIterator {
  const hintId = action.payload;
  const currentAcknowledgedHints: InsightsAcknowledgedHints = yield select(getInsightsAcknowledgedHints);
  const newAcknowledgedHints: InsightsAcknowledgedHints = {
    ...currentAcknowledgedHints,
    [hintId]: true,
  };

  yield call(saveInsightsHintsInAgentProperties, newAcknowledgedHints);
  yield put(InsightsActions.acknowledgeHintSuccess(hintId));
}

export default function* insightsSagas(): SagaIterator {
  yield all([
    takeEvery(
      AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS],
      handleCustomPropertiesFetched
    ),
    takeEvery(InsightsActionsNames.GET_INSIGHTS, handleLoadInsightsData),
    takeEvery(InsightsActionsNames.ACKNOWLEDGE_HINT, acknowledgeHint),
  ]);
}
