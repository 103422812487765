// @ts-strict-ignore
import { ThumbUp } from '@livechat/design-system-icons';

import { RatingFilter, RatingFilterFormatedValues } from 'constants/filters/rating-filter';

import { type FilterConfig, type FilterLabelValue, type FilterLabel } from '../interfaces';

export const ratingFilterConfig: FilterConfig = {
  title: 'Rating',
  icon: ThumbUp,
  mobileViewTitle: 'Select rating',
  multiple: false,
  getFilterLabelValues: (filterValues: string[] | null, filterLabels: FilterLabel[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    return filterValues.map((filterValue: string) => ({
      key: filterValue,
      label: filterLabels.find((labelItem) => labelItem.value === filterValue).label,
    }));
  },
  filterLabels: [
    { value: RatingFilter.Rated, label: RatingFilterFormatedValues[RatingFilter.Rated] },
    { value: RatingFilter.NotRated, label: RatingFilterFormatedValues[RatingFilter.NotRated] },
    { value: RatingFilter.RatedBad, label: RatingFilterFormatedValues[RatingFilter.RatedBad] },
    { value: RatingFilter.RatedGood, label: RatingFilterFormatedValues[RatingFilter.RatedGood] },
    { value: RatingFilter.RatedCommented, label: RatingFilterFormatedValues[RatingFilter.RatedCommented] },
    { value: RatingFilter.RatedBadCommented, label: RatingFilterFormatedValues[RatingFilter.RatedBadCommented] },
    { value: RatingFilter.RatedGoodCommented, label: RatingFilterFormatedValues[RatingFilter.RatedGoodCommented] },
  ],
};
