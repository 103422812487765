// @ts-strict-ignore
import chunk from 'lodash.chunk';
import property from 'lodash.property';
import { type SagaIterator } from 'redux-saga';
import { select, take } from 'redux-saga/effects';

import { type Filter, type Filters } from 'constants/filters/filter';
import { ReportType } from 'constants/reports/report-type';
import { type KeyMap } from 'helpers/interface';
import { isReportTypeIsDisabled } from 'helpers/is-report-type-is-disabled';
import { type ISerializedFilters , type IReportsViewGroupFilter } from 'interfaces/reports';
import type { ReportResponse } from 'interfaces/reports/api-v3';
import { ApiManager } from 'services/api/api-manager';
import { serializeFilters } from 'services/api/report/filters';
import { serializeJSONFilters, serializeReportDistribution } from 'services/api/report/v3/filters';
import { RequestAction, CRUDAction } from 'store/entities/actions';
import { GreetingActionNames } from 'store/entities/greetings/actions';
import { type IGreeting } from 'store/entities/greetings/interfaces';
import { getGreetings } from 'store/entities/greetings/selectors';
import {
  RequestVersion,
  type IFetchReportPayload,
  ReportRequestName,
  type IReportRequest,
  type ISetsResolver,
  type IDataSet,
} from 'store/entities/reports/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { createHasFetchedSelector } from 'store/requests/selectors';
import { getFilterGroup, getGreetingFilter, getFiltersOperators } from 'store/views/reports/selectors';

import { makeFetchingSaga, getCombinations } from './helpers';

const GREETINGS_CHUNK_SIZE = 100;

function* greetingsSetsResolver(
  reportType: ReportType,
  requests: IReportRequest[],
  { filters, distribution }: IFetchReportPayload,
  extendedFilters: ISerializedFilters
): SagaIterator<ISetsResolver> {
  const serializedFilters: ISerializedFilters = {
    ...serializeFilters(filters, distribution),
    ...extendedFilters,
  };

  const isComparisonDisabled = isReportTypeIsDisabled(reportType);
  const comparisons = !isComparisonDisabled
    ? Object.keys(filters).filter((filter) => property('compare')(filters[filter]))
    : [];

  const filtersSets = getCombinations(comparisons).reduce<ISerializedFilters[]>(
    (acc, comparison: (keyof Filters)[]) => {
      acc.push({
        ...serializeFilters(filters, distribution, comparison),
        ...extendedFilters,
      });

      return acc;
    },
    [serializedFilters]
  );

  const filterGroup: IReportsViewGroupFilter = yield select(getFilterGroup);

  const greetingFilter: Filters[Filter.Greeting] = yield select(getGreetingFilter);

  let greetingsChunks = chunk(greetingFilter || [], GREETINGS_CHUNK_SIZE);

  if (!greetingFilter) {
    let greetings: IGreeting[] = yield select(getGreetings);

    if (filterGroup?.groups) {
      greetings = greetings.filter((greeting) => filterGroup.groups.includes(greeting.groupId));
    }

    greetingsChunks = chunk(
      greetings.map((greeting) => greeting.id),
      GREETINGS_CHUNK_SIZE
    );
  }

  const filterOperators = yield select(getFiltersOperators);

  const dataSets = requests.reduce((acc, request): IDataSet[] => {
    if (request.disableComparison || isComparisonDisabled) {
      return acc.concat(
        greetingsChunks.map((greetingsChunk) => {
          const requestFilters: ISerializedFilters = {
            ...filtersSets[0],
            greetings: greetingsChunk,
            operators: filterOperators,
          };

          const serializedFilters = serializeJSONFilters(requestFilters);

          return {
            filters: serializedFilters,
            filterSetId: 0,
            request,
            data: null,
          };
        })
      );
    }

    return acc;
  }, []);

  return {
    dataSets,
    filtersSets,
  };
}

function greetingsResponseResolver(
  responses: ReportResponse<KeyMap>[],
  requests: IReportRequest[],
  filterSetIndex: number,
  filtersSets: ISerializedFilters[]
): KeyMap {
  const responsesPerSet = responses.length / filtersSets.length;

  return requests.reduce((acc, request, requestId) => {
    const startIndex = filterSetIndex * responsesPerSet * requestId;
    const endIndex = startIndex + responsesPerSet;

    const responsesToMerge = responses.slice(startIndex, endIndex).map((response) => response.result.records);

    acc[request.name] = responsesToMerge.reduce((acc2, resp) => {
      Object.keys(resp).forEach((date) => {
        acc2[date] = {
          ...acc2[date],
          ...resp[date],
        };
      });

      return acc2;
    }, {});

    return acc;
  }, {});
}

export function* fetchGreetingsConversion(action: IActionWithPayload<string, IFetchReportPayload>): SagaIterator {
  const wasGreetingsFetched = yield select(createHasFetchedSelector(['FETCH_COLLECTION_GREETING']));

  if (!wasGreetingsFetched) {
    yield take(GreetingActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]);
  }

  const requests: IReportRequest[] = [
    {
      version: RequestVersion.V3,
      name: ReportRequestName.GreetingsConversion,
      interface: ApiManager.reportApiV3.fetchGreetingsConversion,
    },
  ];

  const options = {
    apiFiltersSerializer: serializeReportDistribution,
    setsResolver: greetingsSetsResolver,
    responseResolver: greetingsResponseResolver,
  };

  yield makeFetchingSaga(ReportType.Greetings, action, requests, options);
}
