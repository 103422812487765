import AwaitEmitter, { type CallbackFunction } from 'helpers/await-emitter';
import { browserHistory } from 'services/browser-history';

export interface NavigateOptions {
  replace?: boolean;
}

type ErrorLogger = (error: string) => void;

const defaultOptions: NavigateOptions = {
  replace: false,
};

class History {
  private onBeforeNavigateEmitter: AwaitEmitter;
  private errorLogger: ErrorLogger | null = null;

  constructor() {
    this.onBeforeNavigateEmitter = new AwaitEmitter();
  }

  navigate(fragment: string, options: NavigateOptions = {}): Promise<void> {
    const { replace } = {
      ...defaultOptions,
      ...options,
    };

    return this.onBeforeNavigateEmitter.trigger(fragment).then(
      () => {
        const path = fragment && fragment[0] !== '/' ? `/${fragment}` : fragment;
        if (replace) {
          browserHistory.replaceUrl(path);
        } else {
          browserHistory.push(path);
        }
      },
      () => this.errorLogger?.(`History has rejected route to '${fragment}'`),
    );
  }

  onBeforeNavigate(callback: CallbackFunction): VoidFunction {
    return this.onBeforeNavigateEmitter.on(callback);
  }

  offBeforeNavigate(callback: CallbackFunction): void {
    return this.onBeforeNavigateEmitter.off(callback);
  }

  setErrorLogger(errorLogger: ErrorLogger): void {
    this.errorLogger = errorLogger;
  }
}

// Export default as singleton
export const historyService = new History();
