export enum MobileAppPromoBannerPlace {
  Team,
  TeamAgentDetails,
  TeamChatbotDetails,
  TeamGroupDetails,
}

export interface IWithMobileAppPromoBannerState {
  views: {
    mobileAppPromoBanner: IMobileAppPromoBannerState;
  };
}

export type IMobileAppPromoBannerState = {
  [place in MobileAppPromoBannerPlace]: {
    isVisible: boolean;
  };
};
