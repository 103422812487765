import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import uniqueId from 'lodash.uniqueid';

import { anyToBoolean, booleanToNumericString } from 'helpers/boolean';

import { type IForm, type IFormPayload } from './interfaces';

/**
 * Transforms a form payload from the server into a form structure used within the application.
 * @param {IFormPayload} form - The form payload received from the server.
 * @returns {IForm} - The transformed form structure for use within the application.
 * @example
 * ```
 * const apiForm = {
 *   fields: [
 *     {
 *       type: 'header',
 *       required: 0,
 *       label: 'Lorem ipsum',
 *     },
 *     {
 *       type: 'email',
 *       required: 1,
 *       label: 'E-mail:',
 *     },
 *   ],
 * };
 *
 * const clientForm = deserializeForm(apiForm);
 * ```
 * @remarks
 * If the `fields` array in the form payload is empty, the returned form will also have an empty `fields` array.
 * The `required` field in each form field is transformed from a number to a boolean.
 *
 * @todo The `id` field is added to each form field to ensure uniqueness but this matter should be resolved on API side.
 * @see {@link https://livechatinc.atlassian.net/browse/LC-2300}
 */
export function deserializeForm(form: IFormPayload): IForm {
  return isEmpty(form.fields)
    ? { ...form, fields: [] }
    : {
        ...form,
        fields: map(form.fields, (field) => ({
          ...field,
          required: anyToBoolean(field.required),
          id: uniqueId(),
        })),
      };
}

/**
 * Transforms a form structure used within the application into a form payload for the server.
 * @param {IForm} form - The form structure used within the application.
 * @returns {IFormPayload} - The transformed form payload for the server.
 * @example
 * ```
 * const clientForm: IForm = {
 *   fields: [
 *     {
 *       type: FormFieldType.Header,
 *       required: false,
 *       label: 'Lorem ipsum',
 *     },
 *     {
 *       type: FormFieldType.Email,
 *       required: true,
 *       label: 'E-mail:',
 *     },
 *   ],
 * };
 *
 * const apiForm = serializeForm(clientForm);
 * ```
 * @remarks
 * If the `fields` array in the form is empty, the returned form payload will also have an empty `fields` array.
 * The `required` field in each form field is transformed from a boolean to a number.
 */
export function serializeForm(form: IForm): IFormPayload {
  return isEmpty(form.fields)
    ? { ...form, fields: [] }
    : {
        ...form,
        fields: map(form.fields, ({ id, ...field }) => ({
          ...field,
          required: Number(booleanToNumericString(field.required)),
        })),
      };
}
