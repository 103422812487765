import { AgentRole } from 'constants/agent-role';
import type { Scope } from 'constants/scope';
import { Product } from 'interfaces/products';
import { type ListAccountsResponse } from 'services/connectivity/global-accounts-api/accounts/types';
import { type Role, type ListRolesResponse } from 'services/connectivity/global-accounts-api/roles/types';
import type { IAccount, IAgentRole, InvitationMode, RoleType } from 'store/entities/accounts/interfaces';
import type { IInvitationLink } from 'store/features/invitation-link/interfaces';

export interface IInvitationLinkResponse extends IAccountInfoPayload {}

interface IAccountInfoPayload {
  organization_id: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
  access_token: string;
  account_id: string;
}

export interface IUploadAvatarPayload {
  avatar_url: string;
}

interface IProductDTO {
  product: string;
  client_id: string;
  created_at: string;
}

export interface IOrganizationDTO {
  organization_id: string;
  name: string;
  company: { website: string; phone?: string };
  products: IProductDTO[];
  invitation_mode: InvitationMode;
  created_at: string;
  updated_at: string;
}

export function deserializeInvitationLinkPayload(payload: IInvitationLinkResponse): IInvitationLink {
  return {
    accessToken: payload.access_token,
    expiresIn: payload.expires_in,
  };
}

function filterRoles<T extends { product: string; role: string }>(roles: T[]): T[] {
  return roles.filter(
    (role) => (role.product as Product) === Product.LiveChat || (role.role as AgentRole) === AgentRole.Owner,
  );
}

export function deserializeRolesPayload(payload: ListRolesResponse): IAgentRole[] {
  return filterRoles(payload).map(deserializeSingleRolePayload);
}

export function deserializeSingleRolePayload(role: Role): IAgentRole {
  return {
    roleId: role.role_id,
    predefined: role.predefined,
    roleType: role.type as RoleType,
    roleName: role.role,
    scopes: role.scope.split(',') as Scope[],
  };
}

export function deserializeAccountsPayload(payload: ListAccountsResponse): IAccount[] {
  return payload.map((item) => ({
    accountId: item.account_id,
    email: item.email.toLowerCase(),
    name: item.name,
    roles: filterRoles(item.roles || []).map(({ role_id }) => role_id),
    createdAt: item.created_at,
    mfaEnabled: item.properties?.mfa,
  }));
}
