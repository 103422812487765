// @ts-strict-ignore
import { type InviteLinkActionTypes, InviteLinkActionsEnum } from './actions';
import { type IInviteLinkState } from './interfaces';

const initialState: IInviteLinkState = {
  source: null,
};

export function inviteLinkReducer(
  state: IInviteLinkState = initialState,
  action: InviteLinkActionTypes
): IInviteLinkState {
  switch (action.type) {
    case InviteLinkActionsEnum.GET_INVITE_LINK_SUCCESS:
      return {
        source: action.payload,
      };

    case InviteLinkActionsEnum.REFRESH_INVITE_LINK_SUCCESS:
      return {
        source: action.payload,
      };

    default:
      return state;
  }
}
