import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { BusinessModel } from 'constants/business-model';
import { EventNames } from 'constants/event-bus-events';
import { PlanType } from 'constants/plan-type';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { setDataLayerCustomDimensions } from 'helpers/analytics';
import { booleanToNumericString } from 'helpers/boolean';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { LicenseType } from 'interfaces/entities/current-license';
import type { ISubscription } from 'interfaces/subscription';
import { fetchWebsiteLastActivity } from 'services/activity/fetch-website-last-activity';
import { hasWebsiteLastActivity } from 'services/activity/website-last-activity';
import { ApiManager } from 'services/api/api-manager';
import { type IFetchPlansResult } from 'services/api/billing-v3/interfaces';
import { APIErrorMessage } from 'services/api/subscription/constants';
import { type ISubscriptionDTO } from 'services/api/subscription/interfaces';
import { SubscriptionSerializer } from 'services/api/subscription/serializer';
import { EventBus } from 'services/event-bus';
import type { RequestFailure } from 'store/entities/interfaces';
import { SubscriptionActions } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { CodeInstallationActions } from 'store/features/code-installation/actions';
import { SessionActions } from 'store/features/session/actions';
import { getCanFetchSubscription, getCanManageSubscription, isGhostLogin } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { SubscriptionViewActions } from 'store/views/subscription/actions';

import {
  deserializePlansList,
  getBusinessModel,
  getPartnerType,
  getSalesPlan,
  mapSubscriptionToNewSubscription,
} from './helpers';

/**
 * Update license data for expired licenses and for ghost login feature
 * Expired account and Ghost login on LC3 don't have connection with websocket
 * so we don't receive login response and subscription phase is not set properly
 * so we need to update account model with expiration date and licence type to calculate subscription phase properly
 */
function* updateLicenseData({ inTrial, currentPeriodEndsAt, licenseId, isExpired }: ISubscription): SagaIterator {
  const isGhost = yield select(isGhostLogin);
  if (!isGhost && !isExpired) {
    return;
  }

  const salesPlan = getSalesPlan();
  const partnerType = getPartnerType();
  const businessModel = getBusinessModel() || BusinessModel.PayPerSeat;
  const licenseType = inTrial ? LicenseType.Trial : LicenseType.Paid;

  yield put(
    SessionActions.saveCurrentLicense({
      expiresAt: currentPeriodEndsAt,
      type: licenseType,
      plan: salesPlan,
      partnerType,
      businessModel,
      licenseId,
    }),
  );
}

function* fetchPlans(): SagaIterator {
  const { result }: RequestResult<IFetchPlansResult, unknown> = yield call(ApiManager.billingApiV3.fetchPlans);

  if (result) {
    const plans = deserializePlansList(result);
    yield put(SubscriptionActions.plansFetched(plans));
  }
}

function* updateWidgetSessionVariables({ seats, planName }: ISubscription): SagaIterator {
  const businessModel: BusinessModel = yield select(getBusinessModel);

  yield call(window.LiveChatWidget.call, 'update_session_variables', {
    /* eslint-disable @typescript-eslint/naming-convention */
    og_cv_subscription_seats: seats,
    og_cv_subscription_plan: planName,
    og_cv_business_model: businessModel,
    /* eslint-enable @typescript-eslint/naming-convention */
  });
}

/**
 * Fetch subscription with license data update
 */
function* fetchSubscription(): SagaIterator {
  try {
    const { result, error }: RequestResult<ISubscriptionDTO, RequestFailure> = yield call(
      ApiManager.subscriptionApi.fetch,
    );

    // Fallback to set proper plan when subscription fetching fails
    const salesPlan = getSalesPlan();

    if (error) {
      yield put(SessionActions.saveLicensePlan(salesPlan || PlanType.Team));

      throw error;
    }

    const canManageSubscription = yield select(getCanManageSubscription);
    const subscription = SubscriptionSerializer.deserialize(result);

    yield put(SubscriptionActions.subscriptionFetched(subscription));
    yield all([
      canManageSubscription && call(fetchPlans),
      call(updateLicenseData, subscription),
      call(updateWidgetSessionVariables, subscription),
      call(mapSubscriptionToNewSubscription, subscription),
    ]);
  } catch (error) {
    const handledError = error as RequestFailure;

    if ((handledError.error as APIErrorMessage) !== APIErrorMessage.InsufficientScope) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.SUBSCRIPTION_FETCH_ERROR),
          autoHideDelayTime: ToastAutoHideDelay.Long,
          kind: ToastVariant.Error,
        }),
      );
    }
  }

  EventBus.emit(EventNames.SubscriptionChanged);
}

/**
 * Calls loading for subscription data basing on agent scope
 */
export function* initializeActiveSubscription(): SagaIterator {
  const canFetchSubscription = yield select(getCanFetchSubscription);

  if (canFetchSubscription) {
    yield call(fetchSubscription);
    yield put(SubscriptionActions.subscriptionChanged());
  } else {
    const salesPlan = getSalesPlan();
    yield put(SessionActions.saveLicensePlan(salesPlan || PlanType.Team));
    yield put(SubscriptionActions.subscriptionChanged());
  }
}

/**
 * Calls loading for subscription, and all necessary data for expired account
 * Setup license details (allow to restart trial)
 */
function* initializeExpiredSubscription(): SagaIterator {
  yield all([call(fetchSubscription), call(fetchWebsiteLastActivity)]);

  const { inTrial, isExpired, licenseId, plan }: ISubscription = yield select(getSubscription);
  const hasActivity: boolean = yield call(hasWebsiteLastActivity);

  if (hasActivity) {
    yield put(CodeInstallationActions.activityFound());
  }

  if (!hasActivity && inTrial && isExpired) {
    yield put(SubscriptionViewActions.setCanRestartTrial());
  }

  yield call(setDataLayerCustomDimensions, {
    licenseType: inTrial ? LicenseType.Trial : LicenseType.Paid,
    codeInstalled: booleanToNumericString(hasActivity),
    license: licenseId,
    salesPlan: plan,
  });
}

export function* fetchSubscriptionSaga(): SagaIterator {
  yield takeEvery(['APP_READY'], initializeActiveSubscription);
  yield takeEvery(['APP_ERROR'], initializeExpiredSubscription);
}
