// @ts-strict-ignore
import { Calendar } from '@livechat/design-system-icons';
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { type DateRangeFilter, CustomDateRangeFilterId } from 'constants/filters/date-range-filter';
import { Filter } from 'constants/filters/filter';
import { getCustomDateRange } from 'helpers/filters';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

export const responseDateRangeFilterConfig: FilterConfig = {
  title: 'First response date',
  multiple: false,
  icon: Calendar,
  getFilterLabelValues: (filterValues: DateRangeFilter): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const dateRangeId: CustomDateRangeFilterId = getCustomDateRange(filterValues.from, filterValues.to);
    const labelsMap = {
      [CustomDateRangeFilterId.Today]: 'First response today',
      [CustomDateRangeFilterId.Yesterday]: 'First response yesterday',
      [CustomDateRangeFilterId.Last7Days]: 'First response in last 7 days',
      [CustomDateRangeFilterId.Last30Days]: 'First response in last 30 days',
      [CustomDateRangeFilterId.LastMonth]: 'First response last month',
      [CustomDateRangeFilterId.CurrentMonth]: 'First response current month',
    };
    const label =
      labelsMap[dateRangeId] ||
      `First response: ${format(filterValues.from, DateFormat.ISO8601Date)} – ${format(
        filterValues.to,
        DateFormat.ISO8601Date
      )}`;

    return [
      {
        key: Filter.ResponseDateRange,
        label,
      },
    ];
  },
};
