// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { type ApiClientError } from '../http/types';

import { type GlobalAccountsApiClientError, type GlobalAccountsApiNormalizedError } from './types';

/**
 * Normalizes an API error object from the Global Accounts API.
 * This function handles different structures of error responses and
 * returns a consistently formatted error object.
 *
 * @param error - The API error object to normalize.
 * @returns The normalized error object including the status.
 */
export function normalizeError(
  error: GlobalAccountsApiClientError | ApiClientError<Error>,
): GlobalAccountsApiNormalizedError {
  if (error.http instanceof Error) {
    return {
      ...error.http,
      status: error.status,
      error_description: error.http.message || 'No description provided',
      error: 'server_error',
      sub_error: null,
      request_id: '',
      invalid_fields: {},
    };
  }

  if (error.local instanceof Error) {
    return {
      ...error.local,
      status: error.status,
      error_description: error.local.message || 'No description provided',
      error: 'server_error',
      sub_error: null,
      request_id: '',
      invalid_fields: {},
    };
  }

  if (error.http && 'error' in error.http) {
    return {
      error_description: 'No description provided',
      error: 'server_error',
      sub_error: null,
      request_id: '',
      invalid_fields: {},
      ...error.http,
      status: error.status,
    };
  }

  return {
    error: 'server_error',
    error_description: `An unknown error occurred. ${JSON.stringify(error)}`,
    sub_error: null,
    request_id: '',
    invalid_fields: {},
    status: error.status,
  };
}
