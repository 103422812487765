import { type ProductId } from '@livechat/design-system-react-components';

import { type PlanType } from 'constants/plan-type';
import { type RecurlyCoupon } from 'constants/recurly-coupons';
import { type PaymentOrigin } from 'constants/subscription/payment-origins';

export enum BillingCycleType {
  Monthly = 1,
  Annually12 = 12,
  Annually24 = 24,
  Annually36 = 36,
}

export enum SubscriptionChangedType {
  Unchanged = 'unchanged',
  Update = 'update',
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
}

export interface ICard {
  firstSix: number;
  lastFour: number;
  type: string;
}

export enum CouponType {
  Percent = 'percent',
  Dollars = 'dollars',
}

export interface ICoupon {
  name: RecurlyCoupon;
  type: CouponType;
  value: number;
  singleUse: boolean;
}

export interface ISubscriptionPricing {
  amount: number;
  billingCycle: BillingCycleType;
  seats: number;
  plan: PlanType;
  isCustomDeal: boolean;
}

export interface ISubscription extends ISubscriptionPricing {
  planName: string;
  planCode: string;
  currentPeriodStartedAt: number;
  currentPeriodEndsAt: number;
  daysLeft: number;
  isPastDue: boolean;
  isExpired: boolean;
  isExpiringSoon: boolean;
  card: ICard | null;
  inTrial: boolean;
  isTaxed: boolean;
  credit: number;
  subscriber: boolean;
  email: string;
  automaticUpsellingEnabled: boolean;
  coupon?: ICoupon;
  couponCode?: string;
  partnerName?: string;
  partnerEmail?: string;
  origin: PaymentOrigin | '';
  pendingChange?: ISubscriptionPricing;
  licenseId: number;
  hasManualPayments: boolean;
  isCanceled: boolean;
}

export interface INewSubscription {
  plan: PlanType;
  planName: string;
  planCode: string;
  billingCycle: BillingCycleType;
  seats: number;
  changeType: SubscriptionChangedType;
  automaticUpsellingEnabled: boolean;
  couponCode?: string;
}

export interface IPlanPricesResult {
  /* eslint-disable @typescript-eslint/naming-convention */
  plan_code: string;
  plan_name: string;
  plan_price: number;
  /* eslint-enable @typescript-eslint/naming-convention */
}

type Subscription = {
  status: 'trial' | 'active' | 'cancelled' | 'past_due' | 'expired';
  quantity: number;
  plan: PlanType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  next_charge_at?: string;
};
export type Subscriptions = Partial<Record<ProductId, Subscription>>;
