// @ts-strict-ignore
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { type AgentResponseFilter } from 'constants/filters/agent-response-filter';
import { type AvailabilityFilter } from 'constants/filters/availability-filter';
import { type CustomDateRangeFilter } from 'constants/filters/date-range-filter';
import { Filter } from 'constants/filters/filter';
import { GoalFilter } from 'constants/filters/goal-filter';
import { GreetingFilter } from 'constants/filters/greeting-filter';
import { type OfflineMessageFilter } from 'constants/filters/offline-message-filter';
import { CommentFilter, RatingFilter } from 'constants/filters/rating-filter';
import { type RepliedFilter } from 'constants/filters/replied-filter';
import { type SurveyFilter } from 'constants/filters/survey-filter';
import { TagFilter } from 'constants/filters/tag-filter';
import { ensureFlat } from 'helpers/data';

import { type IFetchAllArchivesParams, type IFetchNewArchivesCountParams } from '../interfaces';

import { type IFetchNewArchivesCountBody, type IFetchArchivesBaseBody } from './interfaces';

type ArchiveFilter =
  | Filter.Agent
  | Filter.Channel
  | Filter.DateRange
  | Filter.Goal
  | Filter.Greeting
  | Filter.Group
  | Filter.Rating
  | Filter.SaleGoal
  | Filter.Survey
  | Filter.Tag
  | Filter.CountryISO;

interface IFilterItem {
  name: string;
  value: string;
}

interface IMultiFilterItem {
  name: string;
  value: string[];
}

function mapRateToFetchParams(filterValue: RatingFilter[]): { name: string; value: RatingFilter | CommentFilter }[] {
  const filtersValues = {
    [RatingFilter.Rated]: [{ name: 'rate', value: RatingFilter.Rated }],
    [RatingFilter.NotRated]: [{ name: 'rate', value: RatingFilter.NotRated }],
    [RatingFilter.RatedGood]: [{ name: 'rate', value: RatingFilter.RatedGood }],
    [RatingFilter.RatedBad]: [{ name: 'rate', value: RatingFilter.RatedBad }],
    [RatingFilter.RatedCommented]: [
      { name: 'rate', value: RatingFilter.Rated },
      { name: 'comment', value: CommentFilter.Commented },
    ],
    [RatingFilter.RatedGoodCommented]: [
      { name: 'rate', value: RatingFilter.RatedGood },
      { name: 'comment', value: CommentFilter.Commented },
    ],
    [RatingFilter.RatedBadCommented]: [
      { name: 'rate', value: RatingFilter.RatedBad },
      { name: 'comment', value: CommentFilter.Commented },
    ],
  };

  return filterValue ? filtersValues[filterValue[0]] : [];
}

function mapDateRangeToFetchParams(filterValue: CustomDateRangeFilter): IFilterItem[] {
  if (filterValue === null || typeof filterValue !== 'object' || !filterValue.from || !filterValue.to) {
    return [];
  }

  return [
    { name: 'date_from', value: format(filterValue.from, DateFormat.ISO8601Date) },
    { name: 'date_to', value: format(filterValue.to, DateFormat.ISO8601Date) },
  ];
}

function mapMultiFilterValuesToFetchParams(filterValues: string[], filterType: ArchiveFilter): IMultiFilterItem[] {
  const archiveFilterParamNames = {
    [Filter.Agent]: 'agents',
    [Filter.Group]: 'groups',
    [Filter.Goal]: 'goals',
    [Filter.SaleGoal]: 'sales',
    [Filter.Tag]: 'tags',
  };

  if (filterValues === null || !Array.isArray(filterValues) || filterValues.length === 0) {
    return [];
  }

  if (filterType === Filter.SaleGoal && filterValues.includes(GoalFilter.Any)) {
    return [{ name: 'has_sale', value: ['1'] }];
  }

  if (filterType === Filter.Goal && filterValues.includes(GoalFilter.Any)) {
    return [{ name: 'has_goal', value: ['1'] }];
  }

  if (filterType === Filter.Tag && filterValues.includes(TagFilter.NotTagged)) {
    return [{ name: 'tagged', value: ['0'] }];
  }

  return [{ name: archiveFilterParamNames[filterType], value: [...filterValues] }];
}

function mapGreetingFilterToFetchParam(filterValue: string[]): IFilterItem[] {
  const value = ensureFlat(filterValue);
  if (value === GreetingFilter.Any) {
    return [{ name: 'has_greeting', value: '1' }];
  }

  return [{ name: 'greeting', value }];
}

export const filterMappers = {
  agent: (filterValue: string[]) => mapMultiFilterValuesToFetchParams(filterValue, Filter.Agent),
  dateRange: (filterValue: CustomDateRangeFilter) => mapDateRangeToFetchParams(filterValue),
  goal: (filterValue: string[]) => mapMultiFilterValuesToFetchParams(filterValue, Filter.Goal),
  greeting: (filterValue: string[]) => mapGreetingFilterToFetchParam(filterValue),
  group: (filterValue: string[]) => mapMultiFilterValuesToFetchParams(filterValue, Filter.Group),
  rating: (filterValue: RatingFilter[]) => mapRateToFetchParams(filterValue),
  replied: (filterValue: RepliedFilter) => [{ name: 'replied', value: filterValue.toString() }],
  saleGoal: (filterValue: string[]) => mapMultiFilterValuesToFetchParams(filterValue, Filter.SaleGoal),
  survey: (filterValue: SurveyFilter) => [{ name: 'survey_answer', value: filterValue.id }],
  tag: (filterValue: string[]) => mapMultiFilterValuesToFetchParams(filterValue, Filter.Tag),
  offlineMessage: (filterValue: OfflineMessageFilter) => [{ name: 'offline_message', value: filterValue.toString() }],
  agentResponse: (filterValue: AgentResponseFilter) => [{ name: 'agent_response', value: filterValue.toString() }],
  availability: (filterValue: AvailabilityFilter) => [{ name: 'availability', value: filterValue.toString() }],
  countryISO: (filterValue: string[]) => [{ name: 'countries', value: filterValue }],
  /** Chat channels and agent assignment are not supported for API v2 */
  channel: () => [],
  agentAssignment: () => [],
  /** Properties below were intentionally NOT adapted to use with v2  */
  topic: () => [],
  sentiment: () => [],
};

/**
 * Converts universal fetch filter parameters to API body.
 * @param filterParams Archive fetch filter parameters to be transformed to body accepted by API.
 */
function mapFetchAllFilterParamsToBody(filterParams: IFetchAllArchivesParams['filters']): IFetchArchivesBaseBody {
  if (!filterParams) {
    return {};
  }

  return Object.keys(filterParams).reduce((acc, filterName: keyof typeof filterMappers) => {
    const filterValue = filterParams[filterName];

    if (filterValue || !Number.isNaN(parseInt(filterValue as string, 10))) {
      const filterValues = filterMappers[filterName](filterValue as never);
      filterValues.forEach((item: { name: string; value: unknown }) => {
        acc[item.name] = item.value;
      });
    }

    return acc;
  }, {});
}

/**
 * Converts universal fetch new archives count parameters to API body.
 * @param params Archive fetch new archives count parameters to be transformed to body accepted by API.
 */
export function mapFetchNewArchivesCountParamsToBody(params: IFetchNewArchivesCountParams): IFetchNewArchivesCountBody {
  const { filters, searchPhrase } = params;
  const filterBody = mapFetchAllFilterParamsToBody(filters);

  return {
    query: searchPhrase,
    ...filterBody,
  };
}
