import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { sumValueFromPeriod, getSeriesDataFromPeriod } from 'helpers/reports';
import { type INewTicketsData } from 'interfaces/reports';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';


export function newTicketsDeserializer({ data }: any): INewTicketsData {
  const newTickets = getSeriesDataFromPeriod<number>(data.newTickets, 'new_tickets');
  const newTicketsSummary = sumValueFromPeriod(data.newTickets, 'new_tickets');

  const emailSummaryValue = sumValueFromPeriod(data.ticketSources, 'tickets_from_mail');
  const ticketFormSummaryValue = sumValueFromPeriod(data.ticketSources, 'tickets_from_chat_window');
  const chatSummaryValue = sumValueFromPeriod(data.ticketSources, 'tickets_from_chat');
  const enteredManuallySummaryValue =
    newTicketsSummary - (emailSummaryValue + ticketFormSummaryValue + chatSummaryValue);

  const emailSummaryLabel = `(${getFormattedPercent(emailSummaryValue / newTicketsSummary, 2)})`;
  const ticketFormSummaryLabel = `(${getFormattedPercent(ticketFormSummaryValue / newTicketsSummary, 2)})`;
  const chatSummaryLabel = `(${getFormattedPercent(chatSummaryValue / newTicketsSummary, 2)})`;
  const enteredManuallySummaryLabel = `(${getFormattedPercent(enteredManuallySummaryValue / newTicketsSummary, 2)})`;

  const emailChartData = getSeriesDataFromPeriod<number>(data.ticketSources, 'tickets_from_mail');
  const ticketFormChartData = getSeriesDataFromPeriod<number>(data.ticketSources, 'tickets_from_chat_window');
  const chatChartData = getSeriesDataFromPeriod<number>(data.ticketSources, 'tickets_from_chat');
  const enteredManuallyChartData = newTickets.map(
    (value, index) => value - (emailChartData[index] + ticketFormChartData[index] + chatChartData[index])
  );

  const labelMapper = (value, index) => `(${getFormattedPercent(value / newTickets[index], 2)})`;

  const emailChartLabels = emailChartData.map(labelMapper);
  const ticketFormChartLabels = ticketFormChartData.map(labelMapper);
  const chatChartLabels = chatChartData.map(labelMapper);
  const enteredManuallyChartLabels = enteredManuallyChartData.map(labelMapper);

  return {
    newTickets: {
      summary: [
        {
          name: 'new tickets',
          value: newTicketsSummary,
          color: DEFAULT_SERIES_COLOR,
        },
      ],
      labels: Object.keys(data.newTickets),
      series: [
        {
          name: 'new tickets',
          color: DEFAULT_SERIES_COLOR,
          data: newTickets,
        },
      ],
    },
    ticketSources: {
      summary: [
        {
          name: 'Email',
          color: DEFAULT_SERIES_COLOR,
          value: emailSummaryValue,
          label: emailSummaryLabel,
        },
        {
          name: 'Ticket form',
          color: DEFAULT_SERIES_COLOR,
          value: ticketFormSummaryValue,
          label: ticketFormSummaryLabel,
        },
        {
          name: 'Chat',
          color: DEFAULT_SERIES_COLOR,
          value: chatSummaryValue,
          label: chatSummaryLabel,
        },
        {
          name: 'Entered manually',
          color: DEFAULT_SERIES_COLOR,
          value: enteredManuallySummaryValue,
          label: enteredManuallySummaryLabel,
        },
      ],
      labels: Object.keys(data.ticketSources),
      series: [
        {
          name: 'from email',
          color: DEFAULT_SERIES_COLOR,
          data: emailChartData,
          label: emailChartLabels,
        },
        {
          name: 'from ticket form',
          color: DEFAULT_SERIES_COLOR,
          data: ticketFormChartData,
          label: ticketFormChartLabels,
        },
        {
          name: 'from chat',
          color: DEFAULT_SERIES_COLOR,
          data: chatChartData,
          label: chatChartLabels,
        },
        {
          name: 'entered manually',
          color: DEFAULT_SERIES_COLOR,
          data: enteredManuallyChartData,
          label: enteredManuallyChartLabels,
        },
      ],
    },
  };
}
