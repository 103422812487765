export const enum AgentsPerformanceColumn {
  Agent = 'login',
  TotalChats = 'chatsCount',
  PercentageofAllChats = 'percentageOfAllChats',
  Satisfaction = 'satisfaction',
  FirstResponseTime = 'firstResponseTime',
  AcceptingChatsTime = 'acceptingTime',
  NotAcceptingChatsTime = 'notAcceptingTime',
  LoggedInTime = 'loggedInTime',
  ChattingTime = 'chattingTime',
  Efficiency = 'chatsPerHour',
  ChatLimit = 'chatsLimit',
}
