export enum AbsoluteAutomateRoutes {
  Base = '/automate',
  Overview = '/automate/overview',
  Chatbots = '/automate/chatbots',
  ReplySuggestions = '/automate/reply-suggestions',
  ReplySuggestionsNew = '/automate/reply-suggestions/new',
  GroupsRouting = '/automate/groups-routing',
  GroupsRoutingAdd = '/automate/groups-routing/add',
  GroupsRoutingEdit = '/automate/groups-routing/:id',
  CannedResponses = '/automate/canned-responses',
  CannedResponsesNew = '/automate/canned-responses/new',
  CannedResponsesEdit = '/automate/canned-responses/:id',
  SuggestedResponses = '/automate/suggested-responses',
  KnowledgeHub = '/automate/knowledge-hub',
  KnowledgeHubAllSources = '/automate/knowledge-hub/all-sources',
  KnowledgeHubWebsites = '/automate/knowledge-hub/websites',
  KnowledgeHubWebsitesPreview = '/automate/knowledge-hub/websites/:id',
  KnowledgeHubWebsitesNestedPreview = '/automate/knowledge-hub/websites/:id/:pageId',
  KnowledgeHubPDF = '/automate/knowledge-hub/pdf',
  KnowledgeHubPDFPreview = '/automate/knowledge-hub/pdf/:id',
  Workflows = '/automate/workflows',
}

export enum RelativeAutomateRoutes {
  Base = '/',
  Overview = '/overview',
  Chatbots = '/chatbots',
  ReplySuggestions = '/reply-suggestions',
  ReplySuggestionsNew = '/reply-suggestions/new',
  GroupsRouting = '/groups-routing',
  GroupsRoutingAdd = '/groups-routing/add',
  GroupsRoutingEdit = '/groups-routing/:id',
  CannedResponses = '/canned-responses',
  CannedResponsesNew = '/canned-responses/new',
  CannedResponsesEdit = '/canned-responses/:id',
  SuggestedResponses = '/suggested-responses',
  KnowledgeHub = '/knowledge-hub',
  KnowledgeHubAllSources = '/knowledge-hub/all-sources',
  KnowledgeHubWebsites = '/knowledge-hub/websites',
  KnowledgeHubWebsitesPreview = '/knowledge-hub/websites/:id',
  KnowledgeHubWebsitesNestedPreview = '/knowledge-hub/websites/:id/:pageId',
  KnowledgeHubPDF = '/knowledge-hub/pdf',
  KnowledgeHubPDFPreview = '/knowledge-hub/pdf/:id',
  Workflows = '/workflows',
}

export enum AutomateEvent {
  SectionOpened = 'Section opened',
  TooltipExploreClicked = 'Automate tooltip explore clicked',
  TooltipDismissed = 'Automate tooltip dismissed',
  UnavailableAutotagsRemoved = 'Unavailable autotags removed',
}

export enum AutomateSectionTitles {
  Overview = 'Overview',
  Chatbots = 'Chatbots',
  ReplySuggestions = 'Reply suggestions',
  RoutingRules = 'Routing rules',
  CannedResponses = 'Canned responses',
  SuggestedResponses = 'Suggested responses',
  KnowledgeHub = 'Knowledge hub',
  KnowledgeHubAllSources = 'All sources',
  KnowledgeHubWebsites = 'Websites',
  KnowledgeHubPDF = 'PDF',
  KnowledgeHubWebsitesPreview = 'Websites preview',
  KnowledgeHubPDFPreview = 'PDF preview',
}

export enum AutomateNavigationItemId {
  Overview = 'overview',
  Chatbots = 'chatbots',
  ReplySuggestions = 'reply-suggestions',
  CannedResponses = 'canned-responses',
  ResponsesList = 'responses-list',
  SuggestedResponses = 'suggested-responses',
  GroupsRouting = 'groups-routing',
  KnowledgeHub = 'knowledge-hub',
  KnowledgeHubAllSources = 'all-sources',
  KnowledgeHubWebsites = 'websites',
  KnowledgeHubPDF = 'pdf',
  Workflows = 'workflows',
}

export const AUTOMATE_SECTION_RELEASE_DATE = '2024-09-17';
