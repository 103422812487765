import type {
  GroupsResetPayload,
  GroupAddedPayload,
  GroupUpdatedPayload,
  GroupRemovedPayload,
} from 'interfaces/groups';
import { type ActionsUnion, createAction } from 'store/helper';


export const GroupActionNames = {
  GROUPS_RESET: 'ENTITIES/GROUPS/GROUPS_RESET',
  GROUP_ADD: 'ENTITIES/GROUPS/GROUP_ADD',
  GROUP_UPDATE: 'ENTITIES/GROUPS/GROUP_UPDATE',
  GROUP_REMOVE: 'ENTITIES/GROUPS/GROUP_REMOVE',
  GROUPS_FETCH: 'ENTITIES/GROUPS/GROUPS_FETCH',
};

export const GroupActions = {
  groupsReset: (payload: GroupsResetPayload) => createAction(GroupActionNames.GROUPS_RESET, payload),
  groupAdded: (payload: GroupAddedPayload) => createAction(GroupActionNames.GROUP_ADD, payload),
  groupUpdated: (payload: GroupUpdatedPayload) => createAction(GroupActionNames.GROUP_UPDATE, payload),
  groupRemoved: (payload: GroupRemovedPayload) => createAction(GroupActionNames.GROUP_REMOVE, payload),
  groupsFetch: () => createAction(GroupActionNames.GROUPS_FETCH, {}),
};

export type GroupAction = ActionsUnion<typeof GroupActions>;
