import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

export const popover = css`
  background-color: var(${DesignToken.PickerListBackground});
  max-width: 256px;
  max-height: 400px;
  padding: var(${SpacingToken.Spacing5});
  overflow: hidden auto;

  > p {
    white-space: normal;
    margin-bottom: var(${SpacingToken.Spacing3});
    color: var(${DesignToken.ContentBasicSecondary});
  }
`;

export const droppableContainer = css`
  display: flex;
  flex-direction: column;
  border-radius: var(${RadiusToken.Radius3});
`;

export const item = css`
  padding-block: calc(var(${SpacingToken.Spacing1}) + var(${SpacingToken.Spacing05}));
  background-color: var(${DesignToken.PickerListBackground});
  gap: var(${SpacingToken.Spacing1});
  justify-content: flex-start;
  box-sizing: border-box;

  label {
    vertical-align: middle;
    padding-right: var(${SpacingToken.Spacing1});
  }
`;
