import { type SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { Interval } from 'constants/date';
import { GlobalModal } from 'constants/global-modal';
import { QueryKey } from 'constants/query-key';
import { EventPlace } from 'helpers/analytics';
import { dateDifference } from 'helpers/date';
import { isMobileView } from 'helpers/device';
import { prepareEmailContent, sendEmail } from 'helpers/email';
import { isNonProfit } from 'helpers/is-non-profit';
import { navigate } from 'helpers/routing';
import { parseQueryParams } from 'helpers/url';
import { PartnerType } from 'interfaces/entities/current-license';
import { BillingProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { getSubscriptionManagersLogins } from 'store/entities/accounts/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import {
  getCanManageSubscription,
  getExpirationTimestamp,
  getIsOnTrial,
  getPartnerType,
} from 'store/features/session/selectors';
import { InstallCodeActions } from 'store/views/install-code/actions';
import { TrialExpiringModalEnum } from 'store/views/trial-expiring-modal/actions';

import { TrialExpiringModalEvent } from './constants';

function* sendEmailSaga(): SagaIterator {
  const subscriptionManagerLogins: string[] = yield select(getSubscriptionManagersLogins);

  const emailContent = prepareEmailContent(
    (subscriptionManagerLogins || []).filter(Boolean).join(','),
    'Expiring LiveChat Trial',
    `Hi there!
We would like to continue using LiveChat on our website and we need your help. Please check our plan and seat number by clicking the link below and subscribe.

https://my.livechatinc.com/subscription/

Thanks!`,
  );

  yield call(sendEmail, emailContent);
  yield put(GlobalModalActions.hideModal(GlobalModal.TrialExpiring));
}

function* goToSubscriptionSaga(): SagaIterator {
  yield call(navigate, '/subscription');
  yield put(GlobalModalActions.hideModal(GlobalModal.TrialExpiring));
}

function* trialExpiringSaga(): SagaIterator {
  const expiringAt: number = yield select(getExpirationTimestamp);
  const isTrial: boolean = yield select(getIsOnTrial);
  const paymentRecurrentEnabled: boolean = getQueryClient().getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
  )?.[BillingProperty.PaymentRecurrentEnabled];
  const partnerType: PartnerType = yield select(getPartnerType);
  const isMobileDevice: boolean = isMobileView();
  const isTrialWithCreatedSubscription = isTrial && paymentRecurrentEnabled;
  const queryParams = parseQueryParams(window.location.search);

  const shouldNotShowTrialExpiring =
    isMobileDevice ||
    !isTrial ||
    isTrialWithCreatedSubscription ||
    partnerType === PartnerType.Reseller ||
    isNonProfit();

  if (shouldNotShowTrialExpiring) {
    return;
  }

  const isRedirectedFromPanel = queryParams?.source === 'panel';
  const daysLeft = dateDifference(new Date(), new Date(expiringAt * 1000), Interval.Day, true);

  if (isRedirectedFromPanel) {
    trackEvent(TrialExpiringModalEvent.RedirectedFromPanel, EventPlace.TrialExpiringModal, { daysLeft });

    return;
  }

  if (daysLeft > 3) {
    return;
  }

  trackEvent(TrialExpiringModalEvent.ModalDisplayed, EventPlace.TrialExpiringModal, { daysLeft });

  yield put(GlobalModalActions.showModal(GlobalModal.TrialExpiring));
}

function* modalButtonClickedSaga(): SagaIterator {
  const expiringAt: number = yield select(getExpirationTimestamp);
  const isInCodeInstalledReminder = window.location.pathname === '/install-code';
  const daysLeft: number = dateDifference(new Date(), new Date(expiringAt * 1000), Interval.Day, true);

  trackEvent(TrialExpiringModalEvent.CTAClicked, EventPlace.TrialExpiringModal, { daysLeft });

  if (isInCodeInstalledReminder) {
    yield put(InstallCodeActions.installCodeVisited());
  }

  const canManageSubscription: boolean = yield select(getCanManageSubscription);

  yield fork(canManageSubscription ? goToSubscriptionSaga : sendEmailSaga);
}

export function* trialExpiringModalSagas(): SagaIterator {
  yield takeEvery('APP_READY', trialExpiringSaga);
  yield takeEvery(TrialExpiringModalEnum.BUTTON_CLICKED, modalButtonClickedSaga);
}
