import { css } from '@emotion/css';

export const paragraph = css`
  max-width: 400px;
  line-height: 20px;
  vertical-align: middle;

  div {
    display: inline-block;
  }
`;

export const modalWrapper = css`
  box-sizing: border-box;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 20px;
    }
  }

  footer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
`;

export const text = css`
  white-space: pre-wrap;
  line-height: 2;
`;

export const iconWrapper = css`
  display: inline-block;
`;

export const tooltipIcon = css`
  margin-left: 5px;
  vertical-align: bottom;
  display: inline-block;

  svg {
    vertical-align: text-bottom;
  }
`;

export const tooltip = css`
  max-width: 300px;
`;

export const icon = css`
  margin-top: 20px;
  width: 48px;
  height: 48px;
  svg {
    width: 48px;
    height: 48px;
  }
`;
