import { round } from './numbers';

/**
 * Format time to max 3 first units Ex. 3d 20h 50m, 5h 20m 30s
 * @param hoursSource hours with seconds and minutes in float - ex. 3.5 = 3h 30m
 */
export function formatTime(hoursSource: number): string {
  let days = Math.floor(hoursSource / 24);
  let hours = Math.floor(hoursSource % 24);
  const minutesPart = Number((hoursSource % 1).toFixed(6));
  let minutes = Math.floor(minutesPart * 60);
  const secondsPart = Number(((minutesPart * 60) % 1).toFixed(6));
  let seconds = Math.round(secondsPart * 60);
  const elements: string[] = [];

  if (seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (hours === 24) {
    days += 1;
    hours = 0;
  }

  if (days) {
    elements.push(`${days}d`);
  }

  if (hours || days) {
    elements.push(`${hours}h`);
  }

  if (minutes || (minutes && seconds)) {
    elements.push(`${minutes}min`);
  }

  if (seconds || !hoursSource) {
    elements.push(`${round(seconds, 2)}s`);
  }

  return elements.slice(0, 3).join(' ');
}

/**
 * Format time to max hours and minutes
 * @param hoursSource hours with seconds and minutes in float - ex. 3.5 = 3h 30m
 */
export function formatTimeToHours(hoursSource: number): string {
  let hours = Math.floor(hoursSource);
  const minutesPart = Number((hoursSource % 1).toFixed(6));
  let minutes = Math.floor(minutesPart * 60);
  const secondsPart = Number(((minutesPart * 60) % 1).toFixed(6));
  const seconds = Math.round(secondsPart * 60);
  const elements: string[] = [];

  if (seconds > 30) {
    minutes += 1;
  }

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (hours || (!hours && !minutes)) {
    elements.push(`${hours}h`);
  }

  if (minutes) {
    elements.push(`${minutes}min`);
  }

  return elements.join(' ');
}
