/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AgentType } from 'constants/agent-type';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { stringifyQueryParams, type IKeyValue } from 'helpers/url';
import { AppStateProvider } from 'services/app-state-provider';
import { agentsAndBotsSelector } from 'store/views/team/computed';

import { type IRanking } from '../../../../interfaces/reports';
import { getFilterGroup } from '../selectors';

export enum RankingType {
  Chats = 'Chats',
  Tickets = 'Tickets',
}

interface IOptions {
  rankingType: RankingType;
  dateFrom: string;
  dateTo: string;
  tag: string[];
  group?: string[];
}

export function rankingDeserializer(data: any, options: IOptions): IRanking[] {
  const isChatsRankingType = options.rankingType === RankingType.Chats;
  const isTicketsRankingType = options.rankingType === RankingType.Tickets;

  const rankingData = isChatsRankingType ? (data && data.records) || {} : (data && data.ranking) || [];
  const parsedRankingData = isChatsRankingType ? Object.keys(rankingData) : rankingData;
  const agents = AppStateProvider.selectFromStore(agentsAndBotsSelector);
  const groups = AppStateProvider.selectFromStore(getFilterGroup)?.groups ?? [];
  const ranking: IRanking[] = [];

  // Parse agents ranking
  parsedRankingData.forEach((agentRanking) => {
    const { bad = 0, good = 0, total = 0, score = 0 } = isChatsRankingType ? rankingData[agentRanking] : agentRanking;
    const name = isChatsRankingType ? agentRanking : agentRanking.name;
    const agent = agents[name];
    const isBot = !!agent && agent.type === AgentType.Bot;
    const seeMoreRoot = isChatsRankingType ? '/archives' : '/tickets/search';
    const seeMoreDefaultQuery: IKeyValue<string> = {
      tag: options.tag,
    };
    let photoUrl: string | undefined;
    let agentName = name as string;
    let seeMoreUrl = '';
    let seeMoreRatedGoodUrl = '';
    let seeMoreRatedBadUrl = '';
    let seeMoreTitle = '';
    let seeMoreRatedGoodTitle = '';
    let seeMoreRatedBadTitle = '';

    if (isChatsRankingType) {
      seeMoreDefaultQuery.date_from = options.dateFrom;
      seeMoreDefaultQuery.date_to = options.dateTo;
    }

    if (isTicketsRankingType) {
      seeMoreDefaultQuery.rate_date_from = options.dateFrom;
      seeMoreDefaultQuery.rate_date_to = options.dateTo;
    }

    if (agent) {
      photoUrl = agent.avatar;
      agentName = agent.name;
    }

    if (isChatsRankingType) {
      seeMoreTitle = `View chats assigned to ${agentName}`;
      seeMoreRatedGoodTitle = `View chats assigned to ${agentName} and rated good`;
      seeMoreRatedBadTitle = `View chats assigned to ${agentName} and rated bad`;
      seeMoreUrl = `${seeMoreRoot}?${stringifyQueryParams({
        ...seeMoreDefaultQuery,
        agent: name,
        rate: 'rated',
      })}`;

      seeMoreRatedGoodUrl = `${seeMoreRoot}?${stringifyQueryParams({
        ...seeMoreDefaultQuery,
        rate: 'rated_good',
        agent: name,
        ...(groups.length > 0 && { group: groups }),
      })}`;

      seeMoreRatedBadUrl = `${seeMoreRoot}?${stringifyQueryParams({
        ...seeMoreDefaultQuery,
        rate: 'rated_bad',
        agent: name,
        ...(groups.length > 0 && { group: groups }),
      })}`;
    } else if (isTicketsRankingType) {
      seeMoreTitle = `View tickets assigned to ${agentName}`;
      seeMoreRatedGoodTitle = `View tickets assigned to ${agentName} and rated good`;
      seeMoreRatedBadTitle = `View tickets assigned to ${agentName} and rated bad`;
      seeMoreUrl = `${seeMoreRoot}?${stringifyQueryParams({
        ...seeMoreDefaultQuery,
        assignee: name,
        rate: 'rated',
      })}`;

      seeMoreRatedGoodUrl = `${seeMoreRoot}?${stringifyQueryParams({
        ...seeMoreDefaultQuery,
        assignee: name,
        rate: 'rated_good',
      })}`;

      seeMoreRatedBadUrl = `${seeMoreRoot}?${stringifyQueryParams({
        ...seeMoreDefaultQuery,
        assignee: name,
        rate: 'rated_bad',
      })}`;
    }

    ranking.push({
      login: name as string,
      isBot,
      name: agentName,
      removed: !agent,
      photoUrl,
      seeMoreUrl,
      seeMoreTitle,
      seeMoreRatedGoodUrl,
      seeMoreRatedGoodTitle,
      seeMoreRatedBadUrl,
      seeMoreRatedBadTitle,
      total: total as number,
      ratedGoodCount: good as number,
      ratedGoodLabel: `(${getFormattedPercent(good / total, 2)})`,
      ratedBadCount: bad as number,
      ratedBadLabel: `(${getFormattedPercent(bad / total, 2)})`,
      score,
    });
  });

  return ranking.sort((a, b) => b.score - a.score);
}
