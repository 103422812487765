import difference from 'lodash.difference';
import memoizeOne from 'memoize-one';
import { createSelector } from 'reselect';

import { DEFAULT_AVATAR_URL_LIGHT } from 'constants/agent-avatar';
import { AgentType } from 'constants/agent-type';
import { ChatEventSubType, ChatEventType } from 'constants/chat-event-type';
import { type Filter } from 'constants/filters/filter';
import { UserType } from 'constants/user-type';
import type { ArchiveAgentItem } from 'interfaces/components/archive/archive-agent-item';
import { type IArchiveEvent } from 'interfaces/entities/archive';
import { getGreetings } from 'store/entities/greetings/selectors';
import { getAgentsAndBots, getAllAgentsIds } from 'store/views/team/computed';

import { type IWithArchivesViewState } from './interfaces';
import {
  getCurrentArchiveEvents,
  getCurrentFailureTagsAdditions,
  getCurrentFailureTagsDeletions,
  getFilters,
  getFiltersSelector,
} from './selectors';

const EMPTY_ARRAY = [];

export const getActiveAndRemovedAgents = createSelector(
  getFiltersSelector,
  getAgentsAndBots,
  getAllAgentsIds,
  (filters, agents, allAgentsIds) => {
    const activeAgents = agents.map<ArchiveAgentItem>(({ login, avatar, name, type }) => ({
      name,
      id: login,
      avatarUrl: avatar,
      type: type === AgentType.Bot ? UserType.BotAgent : UserType.Agent,
    }));

    const { agent: agentsIdsFromFilters } = filters;

    if (!agentsIdsFromFilters?.length) {
      return activeAgents;
    }

    const removedAgents: ArchiveAgentItem[] = difference<string>(
      agentsIdsFromFilters,
      allAgentsIds,
    ).map<ArchiveAgentItem>((removedAgentId) => ({
      id: removedAgentId,
      name: 'Agent Removed',
      type: UserType.Agent,
      avatarUrl: DEFAULT_AVATAR_URL_LIGHT,
      removed: true,
    }));

    return activeAgents.concat(removedAgents);
  },
);

export function getCurrentFailureTags(state: IWithArchivesViewState): string[] {
  return getCurrentFailureTagsAdditions(state).concat(getCurrentFailureTagsDeletions(state));
}

export const getFilterValuesCount = (state: IWithArchivesViewState, filter: Filter): number => {
  const filters = getFilters(state);

  const filterValues = filters[filter] || EMPTY_ARRAY;

  if (Array.isArray(filterValues)) {
    return filterValues.length;
  }

  return 0;
};

export const getCurrentArchiveMessagesTextArray = createSelector(getCurrentArchiveEvents, (events) =>
  Object.values(events).reduce<{ text: string; timestamp: number }[]>((acc, event) => {
    if (event.text && event.type !== ChatEventType.SystemMessage) {
      acc.push({ text: event.text, timestamp: event.timestampInMs });
    }

    return acc;
  }, []),
);

export const getChatRatingComment: (events?: IArchiveEvent[]) => string | null = memoizeOne(
  (events?: IArchiveEvent[]) => {
    if (!events || events.length === 0) {
      return null;
    }

    const eventsWithRatingComment = events.filter(
      (event) => event.subType === ChatEventSubType.Rated || event.subType === ChatEventSubType.RateCommentedOnly,
    );

    if (!eventsWithRatingComment.length) {
      return null;
    }

    const commentEvent = eventsWithRatingComment[eventsWithRatingComment.length - 1];

    return commentEvent?.textVariables?.comment || null;
  },
);

export const getGreetingsFilters = createSelector(getGreetings, (greetings) =>
  greetings.map(({ id, name }) => ({ id, name })),
);
