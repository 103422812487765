import { ReportDistribution } from 'constants/reports/distribution';
import { type Last7DaysMode } from 'constants/reports/last-7-days-mode';
import { ReportType } from 'constants/reports/report-type';
import type { TicketCreateParams } from 'interfaces/api/ticket';
import type {
  IReportsViewSetQueryParamsFromURLPayload,
  IReportsViewUpdateFilterPayload,
  IReportCreatePayload,
  IScheduledReportEdit,
  IScheduledReportDelete,
  IScheduledReport,
  ReportsViewFilters,
  IReportsViewUpdateFiltersPayload,
  IUpdateFilterOperatorPayload,
  IHeatmapFilter,
  ILoadReportViewsPayload,
  ISaveReportViewPaylod,
  IReportView,
  ISaveReportViewFailurePaylod,
  IDeleteReportViewPayload,
  IDeleteReportViewFailurePayload,
  ISelectReportViewPayload,
  ISetColumnsVisibilityPayload,
  ISetColumnsOrderPayload,
  ISaveColumnsVisibilityPayload,
  ISaveColumnsOrderPayload,
} from 'interfaces/reports';
import { type ActionsUnion, createAction } from 'store/helper';


export enum ReportsViewActionsNames {
  SET_CURRENT_VIEW = 'VIEWS/REPORT/SET_CURRENT_VIEW',
  FETCH_DATA = 'VIEWS/REPORT/FETCH_DATA',
  CREATE_REPORT = 'VIEWS/REPORT/CREATE_REPORT_REQUEST',
  CREATE_SCHEDULED_REPORT_SUCCESS = 'VIEWS/REPORT/CREATE_REPORT_SUCCESS',
  EDIT_SCHEDULED_REPORT = 'VIEWS/REPORT/EDIT_SCHEDULED_REPORT_REQUEST',
  EDIT_SCHEDULED_REPORT_SUCCESS = 'VIEWS/REPORT/EDIT_SCHEDULED_REPORT_SUCCESS',
  DELETE_SCHEDULED_REPORT = 'VIEWS/REPORT/DELETE_SCHEDULED_REPORT_REQUEST',
  DELETE_SCHEDULED_REPORT_SUCCESS = 'VIEWS/REPORT/DELETE_SCHEDULED_REPORT_SUCCESS',
  SET_DATA = 'VIEWS/REPORT/SET_DATA',
  SET_QUERY_PARAMS_FROM_URL = 'VIEWS/REPORT/SET_QUERY_PARAMS_FROM_URL',
  SET_DISTRIBUTION = 'VIEWS/REPORT/SET_DISTRIBUTION',
  SET_BENCHMARK_ENABLED = 'VIEWS/REPORT/SET_BENCHMARK_ENABLED',
  UPDATE_FILTER = 'VIEWS/REPORT/UPDATE_FILTER',
  SET_FILTERS = 'VIEWS/REPORT/SET_FILTERS',
  RESET_FILTERS = 'VIEWS/REPORT/RESET_FILTERS',
  UPDATE_FILTER_OPERATOR = 'VIEWS/REPORT/UPDATE_FILTER_OPERATOR',
  FETCH_GREETINGS = 'VIEWS/REPORT/FETCH_GREETINGS',
  FETCH_TAGS = 'VIEWS/REPORT/FETCH_TAGS',
  FETCH_COMPANY_DETAILS = 'VIEWS/REPORT/FETCH_COMPANY_DETAILS',
  SET_PAGE = 'VIEWS/REPORT/SET_PAGE',
  SET_GREETINGS_CONVERSION_BREAKDOWN_CHECKS = 'VIEWS/REPORT/SET_GREETINGS_CONVERSION_BREAKDOWN_CHECKS',
  SET_TAGS_USAGE_BREAKDOWN_CHECKS = 'VIEWS/REPORT/SET_TAGS_USAGE_BREAKDOWN_CHECKS',
  SET_LAST_7_DAYS_MODE = 'VIEWS/REPORT/SET_LAST_7_DAYS_MODE',
  SET_LAST_7_DAYS_FILTERS = 'VIEWS/REPORT/SET_LAST_7_DAYS_FILTERS',
  SET_CACHE_EXPIRATION_TIME = 'VIEWS/REPORT/SET_CACHE_EXPIRATION_TIME',
  CREATE_TICKET_FROM_QUEUE_ABANDONMENT = 'VIEWS/REPORT/CREATE_TICKET_FROM_QUEUE_ABANDONMENT',
  ADD_CHANNEL_BANNER_DATA_READY = 'VIEWS/REPORT/ADD_CHANNEL_BANNER_DATA_READY',
  SET_HEATMAP_RANGE_LIMIT = 'VIEWS/REPORT/SET_HEATMAP_RANGELIMIT',
  SET_HEATMAP_FILTERS = 'VIEWS/REPORT/SET_HEATMAP_FILTERS',

  LOAD_REPORT_VIEWS = 'VIEWS/REPORT/LOAD_REPORT_VIEWS',
  SAVE_REPORT_VIEW = 'VIEWS/REPORT/SAVE_REPORT_VIEW',
  SAVE_REPORT_VIEW_SUCCESS = 'VIEWS/REPORT/SAVE_REPORT_VIEW_SUCCESS',
  SAVE_REPORT_VIEW_FAILURE = 'VIEWS/REPORT/SAVE_REPORT_VIEW_FAILURE',
  DELETE_REPORT_VIEW = 'VIEWS/REPORT/DELETE_REPORT_VIEW',
  DELETE_REPORT_VIEW_SUCCESS = 'VIEWS/REPORT/DELETE_REPORT_VIEW_SUCCESS',
  DELETE_REPORT_VIEW_FAILURE = 'VIEWS/REPORT/DELETE_REPORT_VIEW_FAILURE',
  SELECT_REPORT_VIEW = `VIEWS/REPORT/SELECT_REPORT_VIEW`,
  UNSELECT_REPORT_VIEW = `VIEWS/REPORT/UNSELECT_REPORT_VIEW`,

  SET_COLUMNS_VISIBILITY = 'VIEWS/REPORT/SET_COLUMNS_VISIBILITY',
  SAVE_COLUMNS_VISIBILITY = 'VIEWS/REPORT/SAVE_COLUMNS_VISIBILITY',
  SET_COLUMNS_ORDER = 'VIEWS/REPORT/SET_COLUMNS_ORDER',
  SAVE_COLUMNS_ORDER = 'VIEWS/REPORT/SAVE_COLUMNS_ORDER',
  SHOULD_SHOW_NAVIGATION = 'VIEWS/REPORT/SHOULD_SHOW_NAVIGATION',
}

export const ReportsViewActions = {
  setCurrentView: (reportType: ReportType) =>
    createAction(ReportsViewActionsNames.SET_CURRENT_VIEW, { reportType: reportType || ReportType.Last7days }),

  fetchData: (reportType: ReportType) => createAction(ReportsViewActionsNames.FETCH_DATA, { reportType }),

  createReport: (data: IReportCreatePayload) => createAction(ReportsViewActionsNames.CREATE_REPORT, { data }),

  createReportSuccess: (data: IScheduledReport) =>
    createAction(ReportsViewActionsNames.CREATE_SCHEDULED_REPORT_SUCCESS, { data }),

  editScheduledReport: (data: IScheduledReportEdit) =>
    createAction(ReportsViewActionsNames.EDIT_SCHEDULED_REPORT, { data }),

  editScheduledReportSuccess: (data: IScheduledReportEdit) =>
    createAction(ReportsViewActionsNames.EDIT_SCHEDULED_REPORT_SUCCESS, { data }),

  deleteScheduledReport: (data: IScheduledReportDelete) =>
    createAction(ReportsViewActionsNames.DELETE_SCHEDULED_REPORT, { data }),

  deleteScheduledReportSuccess: (data: IScheduledReportDelete) =>
    createAction(ReportsViewActionsNames.DELETE_SCHEDULED_REPORT_SUCCESS, { data }),

  setReportData: <TData>(reportType: ReportType, data: TData) =>
    createAction(ReportsViewActionsNames.SET_DATA, { reportType, data }),

  setReportDistribution: (type: ReportDistribution = ReportDistribution.Day) =>
    createAction(ReportsViewActionsNames.SET_DISTRIBUTION, { type }),

  setReportBenchmarkEnabled: (benchmarkEnabled = false) =>
    createAction(ReportsViewActionsNames.SET_BENCHMARK_ENABLED, { benchmarkEnabled }),

  fetchGreetings: () => createAction(ReportsViewActionsNames.FETCH_GREETINGS),

  fetchTags: () => createAction(ReportsViewActionsNames.FETCH_TAGS),

  fetchCompanyDetails: () => createAction(ReportsViewActionsNames.FETCH_COMPANY_DETAILS),

  resetFilters: (reportType: ReportType) => createAction(ReportsViewActionsNames.RESET_FILTERS, { reportType }),

  updateFilter: <T extends keyof ReportsViewFilters>(payload: IReportsViewUpdateFilterPayload<T>) =>
    createAction(ReportsViewActionsNames.UPDATE_FILTER, payload),

  setFilters: (payload: IReportsViewUpdateFiltersPayload) => createAction(ReportsViewActionsNames.SET_FILTERS, payload),

  setQueryParamsFromURL: (payload: IReportsViewSetQueryParamsFromURLPayload) =>
    createAction(ReportsViewActionsNames.SET_QUERY_PARAMS_FROM_URL, payload),

  setPage: (page: number) => createAction(ReportsViewActionsNames.SET_PAGE, { page }),

  setGreetingsConversionBreakdownChecks: (checked: string[]) =>
    createAction(ReportsViewActionsNames.SET_GREETINGS_CONVERSION_BREAKDOWN_CHECKS, { checked }),

  setTagsUsageBreakdownChecks: (checked: string[]) =>
    createAction(ReportsViewActionsNames.SET_TAGS_USAGE_BREAKDOWN_CHECKS, { checked }),

  setLast7DaysMode: (last7DaysMode: Last7DaysMode) =>
    createAction(ReportsViewActionsNames.SET_LAST_7_DAYS_MODE, { last7DaysMode }),

  setLast7DaysFilters: (resetFilters: boolean) =>
    createAction(ReportsViewActionsNames.SET_LAST_7_DAYS_FILTERS, { resetFilters }),

  setCacheExpirationTime: (reportType: ReportType, expirationTime: Date) =>
    createAction(ReportsViewActionsNames.SET_CACHE_EXPIRATION_TIME, { reportType, expirationTime }),

  createTicketFromQueueAbandonment: (payload: TicketCreateParams) =>
    createAction(ReportsViewActionsNames.CREATE_TICKET_FROM_QUEUE_ABANDONMENT, payload),

  updateFilterOperator: (payload: IUpdateFilterOperatorPayload) =>
    createAction(ReportsViewActionsNames.UPDATE_FILTER_OPERATOR, payload),

  setHeatmapFilters: (payload: IHeatmapFilter) => createAction(ReportsViewActionsNames.SET_HEATMAP_FILTERS, payload),

  loadReportViews: (payload: ILoadReportViewsPayload) =>
    createAction(ReportsViewActionsNames.LOAD_REPORT_VIEWS, payload),

  saveReportView: (payload: ISaveReportViewPaylod) => createAction(ReportsViewActionsNames.SAVE_REPORT_VIEW, payload),

  saveReportViewSuccess: (payload: IReportView) =>
    createAction(ReportsViewActionsNames.SAVE_REPORT_VIEW_SUCCESS, payload),

  saveReportViewFailure: (payload: ISaveReportViewFailurePaylod) =>
    createAction(ReportsViewActionsNames.SAVE_REPORT_VIEW_FAILURE, payload),

  deleteReportView: (payload: IDeleteReportViewPayload) =>
    createAction(ReportsViewActionsNames.DELETE_REPORT_VIEW, payload),

  deleteReportViewSuccess: (payload: IReportView) =>
    createAction(ReportsViewActionsNames.DELETE_REPORT_VIEW_SUCCESS, payload),

  deleteReportViewFailure: (payload: IDeleteReportViewFailurePayload) =>
    createAction(ReportsViewActionsNames.DELETE_REPORT_VIEW_FAILURE, payload),

  selectReportView: (payload: ISelectReportViewPayload) =>
    createAction(ReportsViewActionsNames.SELECT_REPORT_VIEW, payload),

  unselectReportView: () => createAction(ReportsViewActionsNames.UNSELECT_REPORT_VIEW),

  setColumnsVisibility: (payload: ISetColumnsVisibilityPayload) =>
    createAction(ReportsViewActionsNames.SET_COLUMNS_VISIBILITY, payload),

  setColumnsOrder: (payload: ISetColumnsOrderPayload) =>
    createAction(ReportsViewActionsNames.SET_COLUMNS_ORDER, payload),

  saveColumnsVisibility: (payload: ISaveColumnsVisibilityPayload) =>
    createAction(ReportsViewActionsNames.SAVE_COLUMNS_VISIBILITY, payload),

  saveColumnsOrder: (payload: ISaveColumnsOrderPayload) =>
    createAction(ReportsViewActionsNames.SAVE_COLUMNS_ORDER, payload),

  setShowNavigation: (showNavigation: boolean) =>
    createAction(ReportsViewActionsNames.SHOULD_SHOW_NAVIGATION, { showNavigation }),
};

export type ReportsViewAction = ActionsUnion<typeof ReportsViewActions>;
