import { type FC } from 'react';

import { PromoBannerV2 } from '@livechat/design-system-react-components';

import { openInNewCard } from 'helpers/routing';

import { GOOGLE_FEEDBACK_FORM } from '../constants';

import * as styles from './styles';

function handleLeaveFeedback(): void {
  openInNewCard(GOOGLE_FEEDBACK_FORM);
}

export const FeedbackBanner: FC = () => (
  <div className={styles.detailsCard}>
    <PromoBannerV2
      className={styles.feedbackBanner}
      primaryButton={{
        label: 'Leave feedback',
        handleClick: handleLeaveFeedback,
      }}
    >
      <p>Help us shape this feature to best fit your needs by testing it out and sharing your thoughts.</p>
    </PromoBannerV2>
  </div>
);
