export enum LoaderCircleThickness {
  Thin = 2,
  Medium = 4,
  Thick = 6,
}

export enum LoaderSize {
  ExtraSmall = 12,
  Small = 16,
  Medium = 36,
  Large = 56,
  Big = 70,
}
