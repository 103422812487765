import { useEffect, useState } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { ONE_CHARACTER_LIMIT } from '../message-box/constants';

type UseAboveCharacterLimit = {
  aboveLimit: number;
  isAboveLimit: boolean;
};

export const useAboveCharacterLimit = (): UseAboveCharacterLimit => {
  const [editor] = useLexicalComposerContext();
  const [aboveLimit, setAboveLimit] = useState(0);

  useEffect(() => {
    const registerTextContentListener = editor.registerTextContentListener((textContent) => {
      setAboveLimit(textContent.length - ONE_CHARACTER_LIMIT);
    });

    return () => {
      registerTextContentListener();
    };
  }, [editor]);

  return {
    aboveLimit,
    isAboveLimit: aboveLimit > 0,
  };
};
