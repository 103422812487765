export function round(value: number, precision = 0): number {
  return Math.round(value * 10 ** precision) / 10 ** precision;
}

export function sumReducer(acc = 0, curr: number): number {
  return acc + curr;
}

export function countAverage(arr: number[]): number {
  if (arr.length === 0) {
    return 0;
  }

  const sum = arr.reduce(sumReducer, 0);

  return sum / arr.length;
}

export function sumArraysValues(...arrays: number[][]): number[] {
  const maxCount = Math.max(...arrays.map((array) => array.length));

  return new Array(maxCount).fill(0).map((el, index) => {
    return arrays.map((array) => array[index] || 0).reduce(sumReducer);
  });
}

export function inRange(
  value: number,
  start: number,
  end: number,
  closures: [boolean, boolean] = [false, false]
): boolean {
  const [leftClosure, rightClosure] = closures;
  const passLeft = leftClosure ? value >= start : value > start;
  const passRight = rightClosure ? value <= end : value < end;

  return passLeft && passRight;
}

/**
 * Returns percentage difference between passed values.
 * Example: For params: value: 10, compareValue: 5, the returned value will be 100
 */
export function calcPercentageDifference(value: number, compareValue: number): number | null {
  if (value === 0 && compareValue === 0) {
    return 0;
  }

  if (compareValue === 0) {
    // divide by 0
    return null;
  }

  return ((value - compareValue) / compareValue) * 100;
}
