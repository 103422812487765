export enum CompanySize {
  JustMe = '1',
  To9 = '2-9',
  To49 = '10-49',
  To99 = '50-99',
  To499 = '100-499',
  To999 = '500-999',
  Above1000 = '1000+',
}
export interface ICompanyDetails {
  company: string;
  invoiceEmail: string;
  phone: string;
  website?: string;
  city?: string;
  country?: string;
  industry?: string;
  size?: CompanySize;
  b2b?: boolean;
  b2c?: boolean;
  internal?: boolean;
  audience?: any;
}
