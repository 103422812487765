import type { FC } from 'react';

import columnsImagex1 from 'assets/img/user-guided-tours/customers-list/columns.png';
import columnsImagex2 from 'assets/img/user-guided-tours/customers-list/columns@2x.png';
import customizeImagex1 from 'assets/img/user-guided-tours/customers-list/customize.png';
import customizeImagex2 from 'assets/img/user-guided-tours/customers-list/customize@2x.png';
import filtersImagex1 from 'assets/img/user-guided-tours/customers-list/filters.png';
import filtersImagex2 from 'assets/img/user-guided-tours/customers-list/filters@2x.png';
import filtersImagex3 from 'assets/img/user-guided-tours/customers-list/filters@3x.png';
import { TourStep } from 'components/tours/TourStep';

export const SegmentsStep: FC = () => (
  <TourStep title="Customer segments" message="Quickly access the most important groups of customers." />
);

export const FiltersStep: FC = () => (
  <TourStep
    title="Find key customers"
    message="Use filters to identify specific visitors and send them personalized messages."
    image={<img src={filtersImagex1} srcSet={`${filtersImagex2} 2x, ${filtersImagex3} 3x`} alt="Filters usage" />}
  />
);

export const ColumnsWidthChangeStep: FC = () => (
  <TourStep
    title="Change the width of columns"
    message="To change the width of the column, simply drag the boundary of the column heading until the column is the width that you want."
    image={<img src={columnsImagex1} srcSet={`${columnsImagex2} 2x`} alt="Columns width" />}
  />
);

export const CustomizeStep: FC = () => (
  <TourStep
    title="Customize your view"
    message="Turn on and off any column, including Name and Email address, so only the information that matters the most is displayed. You can also reorder columns."
    image={<img src={customizeImagex1} srcSet={`${customizeImagex2} 2x`} alt="Customize columns" />}
  />
);
