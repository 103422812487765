// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { CustomFormType } from 'constants/custom-form-type';
import type { ThreadEventResult } from 'interfaces/api/event/thread';

const formIdToCustomFormType: Record<string, CustomFormType> = {
  ask_for_email: CustomFormType.AskForEmail,
};

export function getCustomFormType(event: ThreadEventResult): CustomFormType | null {
  const { fields, form_id } = event;

  let type = formIdToCustomFormType[form_id.replace(/_[0-9]+$/, '')];

  if (type === CustomFormType.AskForEmail) {
    type = fields.find((field) => field.type === 'email')
      ? CustomFormType.AskForEmail
      : CustomFormType.InactivityMessage;
  }

  return type;
}
