import { isWindows, isMacOS } from '../browser';

export function getAltKeyCode(): string {
  return isWindows() ? 'alt' : '⌥';
}

export function getControlKeyCode(): string {
  return isWindows() ? 'ctrl' : '⌃';
}

export function getModKey(capitalize = false): string {
  if (isMacOS()) {
    return '⌘';
  }

  return `${capitalize ? 'C' : 'c'}trl`;
}
