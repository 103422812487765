import { type FC } from 'react';

import { Badge, Text, SideNavigationItem as DSSideNavigationItem } from '@livechat/design-system-react-components';
import { useLocation } from 'react-router-dom';

import { navigate } from 'helpers/routing';

import * as styles from './styles';

interface IProps {
  title: string;
  path: string;
  label?: ((count: number) => string) | (() => string);
  badgeCount?: number;
  badgeMax?: number;
}

export const EngageSideNavigationItem: FC<IProps> = ({ title, path, label, badgeCount, badgeMax }) => {
  const { pathname } = useLocation();
  const isActive = pathname.includes(path);
  const hasBadge = typeof badgeCount === 'number';

  return (
    <DSSideNavigationItem
      key={path}
      shouldKeepIconSpace={false}
      isActive={isActive}
      label={title}
      rightNode={
        <Text size="sm" className={styles.rightNode}>
          {hasBadge && (
            <Badge type="counter" size="compact" count={badgeCount} max={badgeMax} className={styles.badge} />
          )}
          {label ? label(badgeCount ?? 0) : null}
        </Text>
      }
      onClick={() => navigate(path)}
    />
  );
};
