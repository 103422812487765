// @ts-strict-ignore
import { CustomerDetailsDefaultSectionEnum } from 'constants/customer-details';
import type { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

const chatGreetingSkippedRegex = 'chat_window_greeting_(\\d+)_skipped';
const chatGreetingClickedRegex = 'chat_window_greeting_(\\d+)_clicked';

const getGreetingIdFromRegex = (propertyName: AgentCustomPropertyName, regex: string): string => {
  const greetingRegex = new RegExp(regex);
  const result = greetingRegex.exec(propertyName);

  if (!result) {
    return null;
  }

  return result[1];
};

const getAgentCustomPropertyNameFromRegex = (greetingId: string, regex: string): AgentCustomPropertyName =>
  regex.replace('(\\d+)', greetingId) as AgentCustomPropertyName;

export const getChatWindowGreetingSkippedPropertyName = (greetingId: string): AgentCustomPropertyName =>
  getAgentCustomPropertyNameFromRegex(greetingId, chatGreetingSkippedRegex);

export const getGreetingIdFromSkippedPropertyName = (propertyName: AgentCustomPropertyName): string =>
  getGreetingIdFromRegex(propertyName, chatGreetingSkippedRegex);

export const getChatWindowGreetingClickedPropertyName = (greetingId: string): AgentCustomPropertyName =>
  getAgentCustomPropertyNameFromRegex(greetingId, chatGreetingClickedRegex);

export const getGreetingIdFromClickedPropertyName = (propertyName: AgentCustomPropertyName): string =>
  getGreetingIdFromRegex(propertyName, chatGreetingClickedRegex);

export const getFilteredSections = (sections: string[]): string[] => {
  // Remove GeneralInfo section from the list of sections,
  // as it is static section and will be not managed by the user
  const generalInfoIndex = sections.indexOf(CustomerDetailsDefaultSectionEnum.GeneralInfo);

  if (generalInfoIndex > -1) {
    sections.splice(generalInfoIndex, 1);
  }

  return sections;
};
