import { createAgentChatApiClient } from '../client';
import { type AgentChatApiErrorResponse } from '../types';

import {
  type ListRoutingStatusesResponse,
  type ListRoutingStatusesRequest,
  type SetRoutingStatusRequest,
} from './types';

const client = createAgentChatApiClient();

/**
 * Routing status API client.
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#status}
 */
export const routingStatusClient = {
  /**
   * Lists the routing statuses of agents or bot agents.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#list-routing-statuses}
   */
  list: async (data: ListRoutingStatusesRequest) => {
    return client.post<ListRoutingStatusesResponse, AgentChatApiErrorResponse>('list_routing_statuses', {
      json: data,
    });
  },
  /**
   * Sets the routing status of an agent or bot agent.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#set-routing-status}
   */
  set: async (data: SetRoutingStatusRequest) => {
    return client.post<void, AgentChatApiErrorResponse>('set_routing_status', { json: data });
  },
};
