import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { QueryKey } from 'constants/query-key';
import { errorDeserialize } from 'helpers/error-deserialize';
import { type IProductOnboardingProgress } from 'interfaces/entities/product-onboarding';
import { ApiManager } from 'services/api/api-manager';
import { deserializeOnboardingProgress } from 'services/api/onboarding/serializer';
import { selectIsNewOnboardingChecklistEnabled } from 'store/entities/experiments/selectors';

interface IUseFetchOnboardingProgress {
  data?: IProductOnboardingProgress | null;
  isFetching: boolean;
}

export async function fetchOnboardingProgress(): Promise<IProductOnboardingProgress> {
  const { result, error } = await ApiManager.onboardingApi.fetchChecklistProgress();

  if (error) {
    throw errorDeserialize(error);
  }

  return deserializeOnboardingProgress(result);
}

export const useFetchOnboardingProgress = (): IUseFetchOnboardingProgress => {
  const inNewChecklistEnabled = useSelector(selectIsNewOnboardingChecklistEnabled);

  const { data, isFetching } = useQuery([QueryKey.OnboardingProgress], fetchOnboardingProgress, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: inNewChecklistEnabled,
  });

  return {
    data,
    isFetching,
  };
};
