// @ts-strict-ignore
import { AgentAssignmentFilter } from 'constants/filters/agent-assignment-filter';
import { type Filters, Filter } from 'constants/filters/filter';
import { Route } from 'constants/tickets/navigation-items';
import { TicketStatus } from 'interfaces/entities/ticket';
import { RequestAction } from 'store/entities/actions';
import { TICKET } from 'store/entities/tickets/actions';
import {
  type IUpdateTicketTagsSuccessPayload,
  type IUpdateTicketSubjectSuccessPayload,
} from 'store/entities/tickets/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { TicketsViewActionsNames, type TicketsViewAction } from 'store/views/tickets/actions';

import { type ITicketsViewState } from './interfaces';

export const initialState: ITicketsViewState = {
  anyDataFetched: false,
  data: {},
  dataFetchedForRoute: null,
  currentPage: 1,
  totalPages: null,
  totalResults: null,
  current: null,
  filters: {
    agent: null,
    agentAssignment: null,
    creationDateRange: null,
    dateRange: null,
    group: null,
    rating: null,
    ratingDateRange: null,
    resolutionDateRange: null,
    responseDateRange: null,
    responseAuthor: null,
    tag: null,
    ticketStatus: null,
  },
  filtersOrder: [],
  selectedIds: [],
  searchQuery: '',
  sendingMessage: false,
  sortedIds: [],
  unspamming: false,
  route: null,
  counters: {},
  emailsMapping: [],
  emailsMappingFetched: false,
};

export const ticketsReducer = (
  state: ITicketsViewState = initialState,
  action: TicketsViewAction
): ITicketsViewState => {
  switch (action.type) {
    case TicketsViewActionsNames.SET_CURRENT_ITEM: {
      return {
        ...state,
        current: action.payload.ticket,
      };
    }

    case TicketsViewActionsNames.SET_ROUTE:
      return {
        ...state,
        route: action.payload.route,
        dataFetchedForRoute: null,
        ...(action.payload.resetPage && { currentPage: 1 }),
      };

    case TicketsViewActionsNames.UPDATE_BATCH:
      return {
        ...state,
        dataFetchedForRoute: null,
      };

    case TicketsViewActionsNames.RESET_SELECTED_TICKETS:
      return {
        ...state,
        selectedIds: [],
      };

    case TicketsViewActionsNames.SELECT_TICKETS:
      return {
        ...state,
        selectedIds: action.payload.ticketIds,
      };

    case TicketsViewActionsNames.SET_DATA: {
      const { data, totalPages, totalResults } = action.payload;
      const dataToSet = data.reduce((acc, ticket) => ({ ...acc, [ticket.id]: ticket }), {});
      const sortedIds = data.reduce(
        (acc: string[], ticket) => (!acc.includes(ticket.id) ? [...acc, ticket.id] : acc),
        []
      );
      const selectedIds = state.selectedIds.filter((selectedId) => dataToSet[selectedId] != null);

      return {
        ...state,
        anyDataFetched: true,
        data: dataToSet,
        dataFetchedForRoute: state.route,
        totalPages,
        totalResults,
        selectedIds,
        sortedIds,
      };
    }

    case TicketsViewActionsNames.SET_PAGE: {
      const { page } = action.payload;

      return {
        ...state,
        currentPage: page || 1,
        dataFetchedForRoute: null,
      };
    }

    case TicketsViewActionsNames.UPDATE_FILTER: {
      const filter = action.payload;

      const newFilters = { ...state.filters };
      let newFiltersOrder = [...state.filtersOrder];
      const filterName = filter.name as any;

      if (filter.value === null) {
        newFilters[filterName] = null;
        newFiltersOrder = newFiltersOrder.filter((name) => name !== filter.name);
      } else {
        newFilters[filterName] = filter.value;
        if (!newFiltersOrder.some((name) => name === filter.name)) {
          newFiltersOrder = [filter.name, ...newFiltersOrder];
        }
      }

      return {
        ...state,
        filters: newFilters,
        filtersOrder: newFiltersOrder,
        dataFetchedForRoute: null,
      };
    }

    case TicketsViewActionsNames.SET_QUERY_PARAMS_FROM_URL: {
      const {
        agent,
        agentAssignment,
        creationDateRange,
        dateRange,
        group,
        page,
        rating,
        ratingDateRange,
        resolutionDateRange,
        responseDateRange,
        responseAuthor,
        tag,
        ticketStatus,
        query,
      } = action.payload.params;

      const newFilterValues: Filters = {
        ...(agent && { agent }),
        ...(agentAssignment && { agentAssignment }),
        ...(creationDateRange && { creationDateRange }),
        ...(dateRange && { dateRange }),
        ...(group && { group }),
        ...(rating && { rating }),
        ...(ratingDateRange && { ratingDateRange }),
        ...(resolutionDateRange && { resolutionDateRange }),
        ...(responseDateRange && { responseDateRange }),
        ...(responseAuthor && { responseAuthor }),
        ...(tag && { tag }),
        ...(ticketStatus && { ticketStatus }),
      };

      return {
        ...state,
        filters: {
          ...newFilterValues,
        },
        searchQuery: query || '',
        currentPage: page || 1,
        dataFetchedForRoute: null,
        filtersOrder: [
          Filter.Agent,
          Filter.AgentAssignment,
          Filter.CreationDateRange,
          Filter.DateRange,
          Filter.Group,
          Filter.Rating,
          Filter.RatingDateRange,
          Filter.ResolutionDateRange,
          Filter.ResponseDateRange,
          Filter.ResponseAuthor,
          Filter.Tag,
          Filter.TicketStatus,
        ].filter((f) => Boolean(newFilterValues[f])),
      };
    }

    case TicketsViewActionsNames.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload.query,
      };

    case TicketsViewActionsNames.UPDATE_TICKET_COUNTERS: {
      const { payload } = action;

      return {
        ...state,
        counters: {
          [Route.Unassigned]: payload[Route.Unassigned],
          [Route.MyOpen]: payload[Route.MyOpen],
        },
      };
    }

    case TicketsViewActionsNames.CLEAR_SEARCH:
      return {
        ...state,
        searchQuery: '',
        filters: {},
        filtersOrder: [],
        dataFetchedForRoute: null,
      };

    case TicketsViewActionsNames.SHOW_FROM_ALL_GROUPS: {
      const { route } = action.payload;

      const filters: Filters = {};
      let filtersOrder: string[] = [];

      if (route === Route.Unassigned) {
        filters[Filter.TicketStatus] = [TicketStatus.Open];
        filters[Filter.AgentAssignment] = [AgentAssignmentFilter.Unassigned];
        filtersOrder = [Filter.TicketStatus, Filter.AgentAssignment];
      } else if (route === Route.MyOpen) {
        filters[Filter.TicketStatus] = [TicketStatus.Open];
        filtersOrder = [Filter.TicketStatus];
      }

      return {
        ...state,
        filters,
        filtersOrder,
      };
    }

    case TicketsViewActionsNames.SET_EMAILS_MAPPING: {
      return {
        ...state,
        emailsMapping: action.payload.mapping,
        emailsMappingFetched: true,
      };
    }

    case TICKET.UPDATE_TAGS[RequestAction.SUCCESS]: {
      const {
        payload: { ticketId, tags },
      } = action as IActionWithPayload<string, IUpdateTicketTagsSuccessPayload>;

      const appliesToCurrent = state.current && state.current.id === ticketId;
      const current = appliesToCurrent
        ? {
            ...state.current,
            tags,
          }
        : state.current;

      const newState = {
        ...state,
        current,
      };

      const appliesToTicketInData = Boolean(newState.data[ticketId]);
      if (!appliesToTicketInData) {
        return newState;
      }

      return {
        ...newState,
        data: {
          ...newState.data,
          [ticketId]: {
            ...newState.data[ticketId],
            tags,
          },
        },
        current,
      };
    }

    case TICKET.UPDATE_SUBJECT[RequestAction.SUCCESS]: {
      const {
        payload: { ticketId, subject },
      } = action as IActionWithPayload<string, IUpdateTicketSubjectSuccessPayload>;

      const appliesToCurrent = state.current && state.current.id === ticketId;
      const current = appliesToCurrent
        ? {
            ...state.current,
            subject,
          }
        : state.current;

      const newState = {
        ...state,
        current,
      };

      const appliesToTicketInData = Boolean(newState.data[ticketId]);
      if (!appliesToTicketInData) {
        return newState;
      }

      return {
        ...newState,
        data: {
          ...newState.data,
          [ticketId]: {
            ...newState.data[ticketId],
            subject,
          },
        },
        current,
      };
    }

    case TICKET.CREATE[RequestAction.REQUEST]:
    case TICKET.SEND_MESSAGE[RequestAction.REQUEST]:
      return { ...state, sendingMessage: true };

    case TICKET.SEND_MESSAGE[RequestAction.SUCCESS]:
    case TICKET.SEND_MESSAGE[RequestAction.FAILURE]:
    case TICKET.CREATE[RequestAction.SUCCESS]:
    case TICKET.CREATE[RequestAction.FAILURE]:
      return { ...state, sendingMessage: false };

    case TICKET.UNSPAM[RequestAction.REQUEST]: {
      return { ...state, unspamming: true };
    }

    case TICKET.UNSPAM[RequestAction.SUCCESS]:
    case TICKET.UNSPAM[RequestAction.FAILURE]: {
      return { ...state, unspamming: false };
    }

    default:
      return state;
  }
};
