import { BaseApi, PassVersionMode } from '../base-api';

import { type IFeaturesResponse } from './interfaces';

export class FeatureControlApi extends BaseApi {
  protected readonly prefix = 'features/livechat';
  protected readonly version = '1';
  protected readonly passVersionMode = PassVersionMode.Prefix;

  fetchAll = () => this.get<IFeaturesResponse>();
}
