import { SoundNotification } from 'constants/sound-notifications';
import { hasAudioSupport } from 'helpers/browser';
import type { CustomSound, SoundSet } from 'interfaces/sound';
import { getCustomSounds as getCustomSoundsFromSmartClient } from 'services/smartclient';

interface SoundElements {
  [key: string]: HTMLAudioElement;
}

export const soundElements: SoundElements = {};

const predefinedCustomSoundsNames = {
  /* eslint-disable @typescript-eslint/naming-convention */
  message_1: 'Flyby',
  message_2: 'Blob',
  message_3: 'Blip',
  message_4: 'Lunaro',
  message_5: 'Radiant',
  message_6: 'Waning',
  message_7: 'Umbra',
  message_8: 'Equinox',
  message_9: 'Parsec',
  message_10: 'Galaxy',
  message_11: 'Twinkling',
  message_12: 'Starlight',
  message_13: 'Popopomm I',
  message_14: 'Popopomm II',
  message_15: 'Popopomm III',
  message_16: 'Syzygy',
  /* eslint-enable @typescript-eslint/naming-convention */
};

const marcosSoundsNames = {
  [SoundNotification.IncomingChat]: 'marcos_incoming_chat.wav',
  [SoundNotification.QueuedVisitor]: 'marcos_queued_visitor.wav',
  [SoundNotification.NewVisitor]: 'marcos_new_visitor.wav',
  [SoundNotification.ReturningVisitor]: 'marcos_returning_visitor.wav',
};

const kaiaSoundsNames = {
  [SoundNotification.IncomingChat]: 'kaia_incoming_chat.wav',
  [SoundNotification.QueuedVisitor]: 'kaia_queued_visitor.wav',
  [SoundNotification.NewVisitor]: 'kaia_new_visitor.wav',
  [SoundNotification.ReturningVisitor]: 'kaia_returning_visitor.wav',
};

const defaultSoundNameMapper = {
  [SoundNotification.NewTicket]: 'message',
};

const urlBase = 'https://cdn.livechatinc.com/sounds/';

function getDefaultSourceSet(soundName: string): SoundSet[] {
  const fileName = defaultSoundNameMapper[soundName] || soundName;

  return [
    {
      src: `${urlBase}${fileName}.mp3`,
      type: 'mp3',
    },
    {
      src: `${urlBase}${fileName}.ogg`,
      type: 'ogg',
    },
    {
      src: `${urlBase}${fileName}.wav`,
      type: 'wav',
    },
  ];
}

function getCustomSoundSet(soundName: string): SoundSet[] {
  const smartClientSounds = getCustomSoundsFromSmartClient();

  return (smartClientSounds[soundName] && smartClientSounds[soundName].sets) || null;
}

function getSoundSourceSet(soundName: string): SoundSet[] {
  const customSound = getCustomSoundSet(soundName);

  return customSound || getDefaultSourceSet(soundName);
}

function appendStringToElement(domElementString: string): void {
  const soundTagContainer = document.getElementById('sound-manager');
  if (soundTagContainer) {
    soundTagContainer.insertAdjacentHTML('beforeend', domElementString);
  }
}

function cleanSoundManagerElement(): void {
  const soundTagContainer = document.getElementById('sound-manager');
  if (soundTagContainer) {
    soundTagContainer.innerHTML = '';
  }
}

export function getPredefinedCustomSounds(soundNotification: SoundNotification): CustomSound[] {
  const sounds = Object.keys(predefinedCustomSoundsNames).map((fileName) => ({
    name: predefinedCustomSoundsNames[fileName],
    sets: getDefaultSourceSet(fileName),
  }));

  const marcosFileName = marcosSoundsNames[soundNotification];
  const kaiaFileName = kaiaSoundsNames[soundNotification];

  if (kaiaFileName) {
    sounds.unshift({
      name: 'Kaia',
      sets: [
        {
          src: `${urlBase}${kaiaFileName}`,
          type: 'wav',
        },
      ],
    });
  }

  if (marcosFileName) {
    sounds.unshift({
      name: 'Marcos',
      sets: [
        {
          src: `${urlBase}${marcosFileName}`,
          type: 'wav',
        },
      ],
    });
  }

  return sounds;
}

export function preloadSounds(): void {
  cleanSoundManagerElement();

  if (hasAudioSupport) {
    const soundNames = Object.values(SoundNotification);
    const preloadHtmlString = soundNames
      .map((soundName) => {
        const sourceSet = getSoundSourceSet(soundName);

        return `
        <audio preload="auto" id="sound-${soundName}">
        ${sourceSet.map(({ src, type }) => `<source src="${src}" type="audio/${type}" />`).join('')}
        </audio>`;
      })
      .join('\n');

    appendStringToElement(preloadHtmlString);
    soundNames.forEach((soundName) => {
      const soundElement = document.getElementById(`sound-${soundName}`) as HTMLAudioElement;
      soundElements[soundName] = soundElement;
    });
  }
}
