import { LC3IncomingEvent } from 'constants/lc3-incoming-event';

export const RTM_EVENTS_V35 = [
  LC3IncomingEvent.AgentApproved,
  LC3IncomingEvent.AgentCreated,
  LC3IncomingEvent.AgentDeleted,
  LC3IncomingEvent.AgentSuspended,
  LC3IncomingEvent.AgentUnsuspended,
  LC3IncomingEvent.AgentUpdated,
  LC3IncomingEvent.BotCreated,
  LC3IncomingEvent.BotDeleted,
  LC3IncomingEvent.BotUpdated,
  LC3IncomingEvent.ChatAccessUpdated,
  LC3IncomingEvent.ChatDeactivated,
  LC3IncomingEvent.ChatTransferred,
  LC3IncomingEvent.CustomerLeft,
  LC3IncomingEvent.CustomerPageUpdated,
  LC3IncomingEvent.CustomerUpdated,
  LC3IncomingEvent.EventPropertiesDeleted,
  LC3IncomingEvent.EventPropertiesUpdated,
  LC3IncomingEvent.EventsMarkedAsSeen,
  LC3IncomingEvent.GroupCreated,
  LC3IncomingEvent.GroupDeleted,
  LC3IncomingEvent.GroupsStatusUpdated,
  LC3IncomingEvent.GroupUpdated,
  LC3IncomingEvent.IncomingChat,
  LC3IncomingEvent.IncomingCustomer,
  LC3IncomingEvent.IncomingCustomers,
  LC3IncomingEvent.IncomingEvent,
  LC3IncomingEvent.IncomingMulticast,
  LC3IncomingEvent.IncomingSneakPeek,
  LC3IncomingEvent.QueuePositionsUpdated,
  LC3IncomingEvent.RoutingStatusSet,
  LC3IncomingEvent.ThreadPropertiesDeleted,
  LC3IncomingEvent.ThreadPropertiesUpdated,
  LC3IncomingEvent.ThreadTagged,
  LC3IncomingEvent.ThreadUntagged,
  LC3IncomingEvent.UserAddedToChat,
  LC3IncomingEvent.UserRemovedFromChat,
];
