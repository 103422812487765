// @ts-strict-ignore
import { type Filters } from 'constants/filters/filter';
import { type LogicOperator } from 'constants/logic-operator';
import { type KeyMap } from 'helpers/interface';
import type { CustomSegment } from 'interfaces/custom-segment';
import {
  AgentCustomPropertyName,
  type AgentCustomPropertyDataType,
  type IFetchAgentCustomPropertiesSuccessPayload,
} from 'store/features/agent-custom-properties/interfaces';
import { type ILegacySegment } from 'store/views/traffic/interfaces';

import { type TransformedSegments } from '../interfaces';

export function getSectionSegmentsFromEntries(
  entries: KeyMap<AgentCustomPropertyDataType>,
  sectionName: string
): KeyMap<CustomSegment> {
  if (!entries || !sectionName) {
    return null;
  }

  return Object.keys(entries)
    .filter(
      (k) =>
        k.startsWith(AgentCustomPropertyName.CustomSegmentPrefix) &&
        !k.includes(AgentCustomPropertyName.CustomSegmentOrder) &&
        k.includes(sectionName)
    )
    .reduce((acc2, k) => {
      const customSegment = entries[k] as any;
      const segmentId = customSegment.id;

      return { ...acc2, [segmentId]: customSegment };
    }, {});
}

export function getSectionSegmentsSortedIdsFromEntries(
  entries: KeyMap<AgentCustomPropertyDataType>,
  sectionName: string
): string[] | null {
  if (!entries || !sectionName) {
    return null;
  }

  const entriesKeys = Object.keys(entries);

  const sortedIdsKey = entriesKeys.find(
    (k) =>
      k.startsWith(AgentCustomPropertyName.CustomSegmentPrefix) &&
      k.includes(AgentCustomPropertyName.CustomSegmentOrder) &&
      k.includes(sectionName)
  );

  if (!sortedIdsKey) return null;

  return entries[sortedIdsKey] as string[];
}

export function mapToCustomSegment({ id, label, filters: legacyFilters, operator }: ILegacySegment): CustomSegment {
  const convertedOperator = operator as unknown as LogicOperator;
  const filters = legacyFilters.reduce((acc, legacyFilter) => {
    acc.push({ [legacyFilter.type]: legacyFilter.filterValues });

    return acc;
  }, [] as Filters[]);

  return {
    id,
    label,
    value: {
      filters,
      operator: convertedOperator,
    },
  };
}

export function transformLegacyCustomersSegments(
  entries: IFetchAgentCustomPropertiesSuccessPayload
): TransformedSegments {
  const entriesKeys = Object.keys(entries).filter((key) =>
    key.startsWith(AgentCustomPropertyName.CustomersSegmentsPrefix)
  );
  const legacySegments = entriesKeys.map((key) => entries[key]);
  const sectionSegments = legacySegments.reduce<KeyMap<CustomSegment>>(
    (ac, segment: ILegacySegment): KeyMap<CustomSegment> => {
      const accumultor = ac;
      accumultor[segment.id] = mapToCustomSegment(segment);

      return accumultor;
    },
    {}
  );
  const sortedIds = legacySegments.map((segment: ILegacySegment) => segment.id);

  return {
    sortedIds,
    segments: sectionSegments,
  };
}

export function buildSegmentsForTraffic(
  agentCustomProperties: KeyMap<AgentCustomPropertyDataType>,
  sortedIds: string[],
  segments: KeyMap<CustomSegment>
): { sortedIds: string[]; segments: KeyMap<CustomSegment>; areLegacySegments?: boolean } {
  const transformedSegments: TransformedSegments = transformLegacyCustomersSegments(agentCustomProperties);

  const areLegacySegmentsAvailable = transformedSegments.sortedIds.length > 0;
  const areRegularSegmentsAvailable = !!sortedIds;
  // due to mishap our customers have segments stored with broken format.
  // This is used to detect if its true and if it is - overwrites broken segments with correct ones. It will be fired once per agent.
  // We will remove it after about month of Traffic launch to make sure that every one is migrated to correct format
  const areRegularSegmentsBroken = Object.keys(segments).some(
    (segment) => !Array.isArray(segments[segment]?.value?.filters)
  );

  if ((areRegularSegmentsBroken || !areRegularSegmentsAvailable) && areLegacySegmentsAvailable) {
    // only legacy segments are available
    // return the transformed segments
    return {
      ...transformedSegments,
      areLegacySegments: true,
    };
  }

  // return regular segments when available
  // or default (empty) values when no segments (legacy or regular) are available
  // (the latter occurs when agent loads the app for the first time and loads Traffic section)
  return {
    sortedIds,
    segments,
  };
}
