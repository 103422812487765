import { type ActionsUnion, createAction } from 'store/helper';

import {
  type IApplicationIDPayload,
  type IAddApplicationSuccessPayload,
  type ISaveCurrentCartPayload,
} from './interfaces';

export enum ProductCartActionNames {
  ADD_APPLICATION = 'ENTITIES/PRODUCT_CART/ADD_APPLICATION_REQUEST',
  ADD_APPLICATION_SUCCESS = 'ENTITIES/PRODUCT_CART/ADD_APPLICATION_SUCCESS',
  ADD_APPLICATION_FAILURE = 'ENTITIES/PRODUCT_CART/ADD_APPLICATION_FAILURE',
  CONFIRM_APPLICATION = 'ENTITIES/PRODUCT_CART/CONFIRM_APPLICATION_REQUEST',
  LOAD_CART_REQUEST = 'ENTITIES/PRODUCT_CART/LOAD_CART_REQUEST',
  LOAD_CART_SUCCESS = 'ENTITIES/PRODUCT_CART/LOAD_CART_SUCCESS',
  LOAD_CART_FAILURE = 'ENTITIES/PRODUCT_CART/LOAD_CART_FAILURE',
  REMOVE_APPLICATION = 'ENTITIES/PRODUCT_CART/REMOVE_APPLICATION',
  REMOVE_APPLICATION_SUCCESS = 'ENTITIES/PRODUCT_CART/REMOVE_APPLICATION_SUCCESS',
  REMOVE_APPLICATION_FAILURE = 'ENTITIES/PRODUCT_CART/REMOVE_APPLICATION_FAILURE',
  SAVE_CURRENT_CART = 'ENTITIES/PRODUCT_CART/SAVE_CURRENT_CART',
  MARKETPLACE_CHECKOUT_PROCESS_STARTED = 'ENTITIES/PRODUCT_CART/MARKETPLACE_CHECKOUT_PROCESS_STARTED',
}

export enum ProductCartRequestFetchingSelectorsNames {
  ADD_APPLICATION_REQUEST = 'ENTITIES/PRODUCT_CART/ADD_APPLICATION',
  LOAD_CART_REQUEST = 'ENTITIES/PRODUCT_CART/LOAD_CART',
}

export const ProductCartActions = {
  loadCart: () => createAction(ProductCartActionNames.LOAD_CART_REQUEST),
  loadCartSuccess: () => createAction(ProductCartActionNames.LOAD_CART_SUCCESS),
  loadCartFailure: () => createAction(ProductCartActionNames.LOAD_CART_FAILURE),

  addApplication: (payload: IApplicationIDPayload) => createAction(ProductCartActionNames.ADD_APPLICATION, payload),
  addApplicationSuccess: (payload: IAddApplicationSuccessPayload) =>
    createAction(ProductCartActionNames.ADD_APPLICATION_SUCCESS, payload),
  addApplicationFailure: (payload: IApplicationIDPayload) =>
    createAction(ProductCartActionNames.ADD_APPLICATION_FAILURE, payload),

  confirmApplication: (payload: IApplicationIDPayload) =>
    createAction(ProductCartActionNames.CONFIRM_APPLICATION, payload),

  removeApplication: (payload: IApplicationIDPayload) =>
    createAction(ProductCartActionNames.REMOVE_APPLICATION, payload),
  removeApplicationSuccess: (payload: IApplicationIDPayload) =>
    createAction(ProductCartActionNames.REMOVE_APPLICATION_SUCCESS, payload),
  removeApplicationFailure: (payload: IApplicationIDPayload) =>
    createAction(ProductCartActionNames.REMOVE_APPLICATION_FAILURE, payload),

  saveCurrentCart: (payload: ISaveCurrentCartPayload) =>
    createAction(ProductCartActionNames.SAVE_CURRENT_CART, payload),
  confirmAllApplications: () => createAction(ProductCartActionNames.MARKETPLACE_CHECKOUT_PROCESS_STARTED),
};

export type ProductCartActionTypes = ActionsUnion<typeof ProductCartActions>;
