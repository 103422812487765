import { type IFacebookMessengerIntegration } from 'constants/integrations/facebook-messenger';
import { type GroupId } from 'interfaces/groups';

export interface IGeolocationDetails {
  city?: string;
  state?: string;
  country?: string;
  countryCode?: string;
  latitude?: string;
  longitude?: string;
  timezone?: string;
}

export enum DeviceGeneralType {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
}

export interface ICustomerBase {
  avatarUrl?: string;
  email?: string;
  groupIds?: GroupId[];
  host?: string;
  id: string;
  ip?: string;
  name?: string;
  revokeChatId?: string;
}

// add more integration types if needed
export interface ICustomerIntegrations {
  [clientId: string]: IFacebookMessengerIntegration;
}

export interface ICustomerStatistics {
  acceptedGreetingsCount?: number;
  chatsCount?: number;
  greetingsCount?: number;
  visitsCount?: number;
}

export interface ICustomerVisitDetails {
  cameFromURL?: string;
  lastVisitTimestampInMs?: number;
  targetedMessageId?: string;
  userAgent?: string;
  visitEndedTimestampInMs?: number;
  visitStartedTimestampInMs?: number;
  operatingSystem?: string;
  deviceType?: DeviceGeneralType;
  browserName?: string;
  isWebCrawler?: boolean;
  wasInvited?: boolean;
}
