import { type ITicket } from 'interfaces/entities/ticket';
import { createAction } from 'store/helper';

import { RequestAction, createEntityActionNames, createEntityActions, createRequestTypes } from '../actions';

import {
  type ICreateTicketFailurePayload,
  type ICreateTicketPayload,
  type ICreateTicketSuccessPayload,
  type IFetchTicketPayload,
  type IFetchTicketsCollectionPayload,
  type ISendTicketMessageFailurePayload,
  type ISendTicketMessagePayload,
  type ISendTicketMessageSuccessPayload,
  type IUnspamPayload,
  type IUpdateTicketAssigneeFailurePayload,
  type IUpdateTicketAssigneePayload,
  type IUpdateTicketAssigneeSuccessPayload,
  type IUpdateTicketCCsFailurePayload,
  type IUpdateTicketCCsPayload,
  type IUpdateTicketCCsSuccessPayload,
  type IUpdateTicketGroupsFailurePayload,
  type IUpdateTicketGroupsPayload,
  type IUpdateTicketGroupsSuccessPayload,
  type IUpdateTicketRequesterFailurePayload,
  type IUpdateTicketRequesterPayload,
  type IUpdateTicketRequesterSuccessPayload,
  type IUpdateTicketSubjectFailurePayload,
  type IUpdateTicketSubjectPayload,
  type IUpdateTicketSubjectSuccessPayload,
  type IUpdateTicketTagsFailurePayload,
  type IUpdateTicketTagsPayload,
  type IUpdateTicketTagsSuccessPayload,
} from './interfaces';

export const TICKET = {
  ...createEntityActionNames('TICKET'),
  CREATE: createRequestTypes('ENTITIES/TICKET/CREATE'),
  UPDATE_TAGS: createRequestTypes('ENTITIES/TICKET/UPDATE_TAGS'),
  UPDATE_SUBJECT: createRequestTypes('ENTITIES/TICKET/UPDATE_SUBJECT'),
  UPDATE_REQUESTER: createRequestTypes('ENTITIES/TICKET/UPDATE_REQUESTER'),
  UPDATE_CCS: createRequestTypes('ENTITIES/TICKET/UPDATE_CCS'),
  UPDATE_ASSIGNEE: createRequestTypes('ENTITIES/TICKET/UPDATE_ASSIGNEE'),
  UPDATE_GROUPS: createRequestTypes('ENTITIES/TICKET/UPDATE_GROUPS'),
  SEND_MESSAGE: createRequestTypes('ENTITIES/TICKET/SEND_MESSAGE'),
  UNSPAM: createRequestTypes('ENTITIES/TICKET/UNSPAM'),
};

const createTicketActions = {
  createTicket: (payload: ICreateTicketPayload) => createAction(TICKET.CREATE[RequestAction.REQUEST], payload),
  createTicketSuccess: (payload: ICreateTicketSuccessPayload) =>
    createAction(TICKET.CREATE[RequestAction.SUCCESS], payload),
  createTicketFailure: (payload: ICreateTicketFailurePayload) =>
    createAction(TICKET.CREATE[RequestAction.FAILURE], payload),
};

const updateTagsActions = {
  updateTags: (payload: IUpdateTicketTagsPayload) => createAction(TICKET.UPDATE_TAGS[RequestAction.REQUEST], payload),
  updateTagsSuccess: (payload: IUpdateTicketTagsSuccessPayload) =>
    createAction(TICKET.UPDATE_TAGS[RequestAction.SUCCESS], payload),
  updateTagsFailure: (payload: IUpdateTicketTagsFailurePayload) =>
    createAction(TICKET.UPDATE_TAGS[RequestAction.FAILURE], payload),
};

const updateSubjectActions = {
  updateSubject: (payload: IUpdateTicketSubjectPayload) =>
    createAction(TICKET.UPDATE_SUBJECT[RequestAction.REQUEST], payload),
  updateSubjectSuccess: (payload: IUpdateTicketSubjectSuccessPayload) =>
    createAction(TICKET.UPDATE_SUBJECT[RequestAction.SUCCESS], payload),
  updateSubjectFailure: (payload: IUpdateTicketSubjectFailurePayload) =>
    createAction(TICKET.UPDATE_SUBJECT[RequestAction.FAILURE], payload),
};

const updateRequesterActions = {
  updateRequester: (payload: IUpdateTicketRequesterPayload) =>
    createAction(TICKET.UPDATE_REQUESTER[RequestAction.REQUEST], payload),
  updateRequesterSuccess: (payload: IUpdateTicketRequesterSuccessPayload) =>
    createAction(TICKET.UPDATE_REQUESTER[RequestAction.SUCCESS], payload),
  updateRequesterFailure: (payload: IUpdateTicketRequesterFailurePayload) =>
    createAction(TICKET.UPDATE_REQUESTER[RequestAction.FAILURE], payload),
};

const updateCCsActions = {
  updateCCs: (payload: IUpdateTicketCCsPayload) => createAction(TICKET.UPDATE_CCS[RequestAction.REQUEST], payload),
  updateCCsSuccess: (payload: IUpdateTicketCCsSuccessPayload) =>
    createAction(TICKET.UPDATE_CCS[RequestAction.SUCCESS], payload),
  updateCCsFailure: (payload: IUpdateTicketCCsFailurePayload) =>
    createAction(TICKET.UPDATE_CCS[RequestAction.FAILURE], payload),
};

const updateAssigneeActions = {
  updateAssignee: (payload: IUpdateTicketAssigneePayload) =>
    createAction(TICKET.UPDATE_ASSIGNEE[RequestAction.REQUEST], payload),
  updateAssigneeSuccess: (payload: IUpdateTicketAssigneeSuccessPayload) =>
    createAction(TICKET.UPDATE_ASSIGNEE[RequestAction.SUCCESS], payload),
  updateAssigneeFailure: (payload: IUpdateTicketAssigneeFailurePayload) =>
    createAction(TICKET.UPDATE_ASSIGNEE[RequestAction.FAILURE], payload),
};

const updateGroupsActions = {
  updateGroups: (payload: IUpdateTicketGroupsPayload) =>
    createAction(TICKET.UPDATE_GROUPS[RequestAction.REQUEST], payload),
  updateGroupsSuccess: (payload: IUpdateTicketGroupsSuccessPayload) =>
    createAction(TICKET.UPDATE_GROUPS[RequestAction.SUCCESS], payload),
  updateGroupsFailure: (payload: IUpdateTicketGroupsFailurePayload) =>
    createAction(TICKET.UPDATE_GROUPS[RequestAction.FAILURE], payload),
};

const sendMessageActions = {
  sendMessage: (payload: ISendTicketMessagePayload) =>
    createAction(TICKET.SEND_MESSAGE[RequestAction.REQUEST], payload),
  sendMessageSuccess: (payload: ISendTicketMessageSuccessPayload) =>
    createAction(TICKET.SEND_MESSAGE[RequestAction.SUCCESS], payload),
  sendMessageFailure: (payload: ISendTicketMessageFailurePayload) =>
    createAction(TICKET.SEND_MESSAGE[RequestAction.FAILURE], payload),
};

const unspamActions = {
  unspam: (payload: IUnspamPayload) => createAction(TICKET.UNSPAM[RequestAction.REQUEST], payload),
  unspamSuccess: () => createAction(TICKET.UNSPAM[RequestAction.SUCCESS]),
  unspamFailure: () => createAction(TICKET.UNSPAM[RequestAction.FAILURE]),
};

export const TicketActions = {
  ...createEntityActions<ITicket, IFetchTicketPayload, IFetchTicketsCollectionPayload>(TICKET),
  ...createTicketActions,
  ...updateTagsActions,
  ...updateSubjectActions,
  ...updateRequesterActions,
  ...updateCCsActions,
  ...updateAssigneeActions,
  ...updateGroupsActions,
  ...sendMessageActions,
  ...unspamActions,
};
