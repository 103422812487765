import { QueryKey } from 'constants/query-key';
import { PlatformNamespace, StatisticsProperty } from 'services/connectivity/configuration-api/properties/constants';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';

import { calculateFirstChat } from './helpers/calculate-first-chat';
import { calculateHasWebsiteLastActivity } from './helpers/calculate-has-website-last-activity';
import { calculateLastActivityInDays } from './helpers/calculate-last-activity-in-days';

export function isWebsiteActivityStored(): boolean {
  const queryState = getQueryClient().getQueryState(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.STATISTICS),
  );

  return queryState?.status === 'success';
}

function getStoredStatisticsProperty(property: StatisticsProperty): string | undefined {
  const cachedData = getQueryClient().getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.STATISTICS),
  );

  return cachedData?.[property] as string | undefined;
}

export function hasWebsiteLastActivity(): boolean {
  if (!isWebsiteActivityStored()) {
    throw new Error('Website activity not stored');
  }

  return calculateHasWebsiteLastActivity(getStoredStatisticsProperty(StatisticsProperty.WebsiteLastActivity));
}

export function getLastActivityInDays(): number | undefined {
  if (!isWebsiteActivityStored()) {
    throw new Error('Website activity not stored');
  }

  const lastActivity = getStoredStatisticsProperty(StatisticsProperty.WebsiteLastActivity);

  if (!lastActivity || !hasWebsiteLastActivity()) {
    return undefined;
  }

  return calculateLastActivityInDays(lastActivity);
}

export function getFirstChatDate(): Date | null {
  if (!isWebsiteActivityStored()) {
    throw new Error('Website activity not stored');
  }

  return calculateFirstChat(getStoredStatisticsProperty(StatisticsProperty.FirstChatDate));
}
