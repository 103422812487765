// @ts-strict-ignore
import { GreetingQuickReply } from '@livechat/design-system-icons';

import { GreetingFilter } from 'constants/filters/greeting-filter';
import { toKeyMap } from 'helpers/array';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

import { type IGreetingFilterItem } from './interfaces';

export const greetingFilterConfigSingle: FilterConfig = {
  title: 'Campaign',
  icon: GreetingQuickReply,
  multiple: false,
  getFilterLabelValues: (filterValues: string[] | null, greetings: IGreetingFilterItem[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const greetingsHash = toKeyMap(greetings.concat([{ id: GreetingFilter.Any, name: 'Any campaign' }]), 'id');

    return filterValues
      .filter((greetingId) => greetingsHash[greetingId])
      .map((greetingId) => ({
        key: greetingsHash[greetingId].id,
        label: greetingsHash[greetingId].name,
      }));
  },
};
