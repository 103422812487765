import { useSelector } from 'react-redux';

import { type IApplicationWidget } from 'store/entities/applications/interfaces';
import { getEngageWidgets } from 'store/entities/applications/selectors';

export const useEngageApplications = (): { applications: IApplicationWidget[] } => {
  const applications = useSelector(getEngageWidgets);

  return {
    applications,
  };
};
