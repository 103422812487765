import type { FC } from 'react';

import { Navigate, useParams, generatePath } from 'react-router-dom';

interface IProps {
  to: string;
}

export const NavigateWithParams: FC<IProps> = ({ to }) => {
  const params = useParams();
  const redirectWithParams = generatePath(to, params);

  return <Navigate to={redirectWithParams} replace />;
};
