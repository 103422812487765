import {
  type IHintsForMetrics,
  InsightsMetricType,
  TotalChatsHintId,
  ChatSatisfactionHintId,
  EfficiencyHintId,
  MissedChatsHintId,
  ResponseTimeHintId
} from 'store/entities/insights/interfaces';

// ids from enums below are used when storing acknowledged insights in agent custom props
// 'p' stands for positive trend hint
// 'n' stands for negative trend hint

export const POSITIVE_CHANGE_HINTS: IHintsForMetrics = {
  [InsightsMetricType.TotalChats]: [
    TotalChatsHintId.AnalyzeLastWeeksArchives,
    TotalChatsHintId.CheckTotalChatsReport,
    TotalChatsHintId.AddMoreAgents,
  ],
  [InsightsMetricType.ChatSatisfaction]: [ChatSatisfactionHintId.CongratulateYourTeam],
  [InsightsMetricType.Efficiency]: [EfficiencyHintId.CongratulateYourTeam],
  [InsightsMetricType.MissedChats]: [MissedChatsHintId.KeepUpTheGreatWork],
  [InsightsMetricType.ResponseTime]: [ResponseTimeHintId.CongratulateYourTeam],
};

export const NEGATIVE_CHANGE_HINTS: IHintsForMetrics = {
  [InsightsMetricType.TotalChats]: [TotalChatsHintId.CheckGreetingsPerformance, TotalChatsHintId.CheckCWSetup],
  [InsightsMetricType.ChatSatisfaction]: [
    ChatSatisfactionHintId.DiscoverLowRatedChats,
    ChatSatisfactionHintId.CheckChatSatisfactionReport,
  ],
  [InsightsMetricType.Efficiency]: [
    EfficiencyHintId.CheckTotalChatsReport,
    EfficiencyHintId.AdjustConcurrentChatsSettings,
    EfficiencyHintId.CreateCannedResponses,
  ],
  [InsightsMetricType.MissedChats]: [MissedChatsHintId.CheckMissedChatsReport],
  [InsightsMetricType.ResponseTime]: [
    ResponseTimeHintId.CheckTotalChatsReport,
    ResponseTimeHintId.AdjustConcurrentChatsSettings,
  ],
};