// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { ToastContent } from 'constants/toasts';
import { getToastContent } from 'helpers/toast';
import { ApiManager } from 'services/api/api-manager';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';

import { TrustedDomainsActionNames, TrustedDomainsActions } from './actions';
import { type ITrustedDomain } from './interfaces';
import { deserializeTrustedDomains } from './serializers';

function* fetchTrustedDomains(): SagaIterator {
  try {
    const { result, error } = yield call(ApiManager.trustedDomainsApi.fetchAll);
    if (error) {
      yield put(
        TrustedDomainsActions.fetchFailure({
          error,
        })
      );
    } else {
      yield put(TrustedDomainsActions.fetchSuccess({ value: deserializeTrustedDomains(result) }));
    }
  } catch (error) {
    yield put(
      TrustedDomainsActions.fetchFailure({
        error: error.message,
      })
    );
  }
}

function* removeTrustedDomain({ payload }: IActionWithPayload<string, Pick<ITrustedDomain, 'domain'>>): SagaIterator {
  const { domain } = payload;
  try {
    const { result, error } = yield call(ApiManager.trustedDomainsApi.remove, domain);
    if (error) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.TRUSTED_DOMAIN_REMOVE_ERROR),
          kind: ToastVariant.Error,
        })
      );
    }
    if (result) {
      yield put(TrustedDomainsActions.removeTrustedSuccess({ domain }));
      yield put(GlobalModalActions.hideModal(GlobalModal.RemoveTrustedDomain));
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.TRUSTED_DOMAIN_REMOVE_SUCCESS),
          kind: ToastVariant.Success,
        })
      );
    }
  } catch (error) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.TRUSTED_DOMAIN_REMOVE_ERROR),
        kind: ToastVariant.Error,
      })
    );
  }
}

function* addTrustedDomain({ payload }: IActionWithPayload<string, Pick<ITrustedDomain, 'domain'>>): SagaIterator {
  const { domain } = payload;
  try {
    const { result, error } = yield call(ApiManager.trustedDomainsApi.add, domain);
    if (error) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.TRUSTED_DOMAIN_ADD_ERROR),
          kind: ToastVariant.Error,
        })
      );
    }
    if (result) {
      yield put(TrustedDomainsActions.addTrustedSuccess(result));
      yield put(TrustedDomainsActions.removeSuggested({ domain }));
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.TRUSTED_DOMAIN_ADD_SUCCESS),
          kind: ToastVariant.Success,
        })
      );
    }
  } catch (error) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.TRUSTED_DOMAIN_ADD_ERROR),
        kind: ToastVariant.Error,
      })
    );
  }
}

export function* trustedDomainsSagas(): SagaIterator {
  yield takeEvery(TrustedDomainsActionNames.FETCH, fetchTrustedDomains);
  yield takeEvery(TrustedDomainsActionNames.REMOVE_TRUSTED, removeTrustedDomain);
  yield takeEvery(TrustedDomainsActionNames.ADD_TRUSTED, addTrustedDomain);
}
