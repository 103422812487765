// @ts-strict-ignore
import { RecurlyActionNames, type RecurlyActionTypes } from './actions';
import { type IRecurlyState } from './interfaces';

const initialState: IRecurlyState = {
  currentCoupon: {
    exists: false,
  },
  newCoupon: {
    exists: false,
  },
  fetched: false,
};

export const recurlyReducer = (state: IRecurlyState = initialState, action: RecurlyActionTypes): IRecurlyState => {
  switch (action.type) {
    case RecurlyActionNames.CHECK_CURRENT_RECURLY_COUPON_SUCCESS: {
      return {
        ...state,
        currentCoupon: action.payload,
        fetched: true,
      };
    }

    case RecurlyActionNames.CHECK_NEW_RECURLY_COUPON_SUCCESS:
    case RecurlyActionNames.CHECK_COVID_COUPON_SUCCESS: {
      return {
        ...state,
        newCoupon: action.payload,
        fetched: true,
      };
    }

    case RecurlyActionNames.CHECK_CURRENT_RECURLY_COUPON_FAILURE:
    case RecurlyActionNames.CHECK_NEW_RECURLY_COUPON_FAILURE: {
      return {
        ...state,
        currentCoupon: {
          exists: false,
        },
        fetched: true,
      };
    }

    case RecurlyActionNames.ACTIVATE_NEW_COUPON: {
      return {
        ...state,
        newCoupon: {
          ...state.newCoupon,
          hideDiscount: false,
        },
      };
    }

    case RecurlyActionNames.REDEMPTION_ERROR: {
      return {
        ...state,
        newCoupon: {
          ...state.newCoupon,
          redemptionError: action.payload.error,
        },
      };
    }

    case RecurlyActionNames.REMOVE_NEW_COUPON: {
      return {
        ...state,
        newCoupon: {
          exists: false,
        },
      };
    }

    case RecurlyActionNames.RESET_RECURLY_COUPONS: {
      return {
        ...initialState,
        fetched: true,
      };
    }

    default:
      return state;
  }
};
