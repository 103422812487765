import { Automation, Brush, CodeBlack, People } from '@livechat/design-system-icons';

export const MARKETPLACE_SECTIONS_LINKS = [
  {
    label: 'Partners',
    link: '/apps/marketplace/partners',
    icon: People,
  },
  {
    label: 'Creators',
    link: '/apps/marketplace/developers',
    icon: Brush,
  },
];

export const ADDITIONAL_SECTIONS_LINKS = [
  {
    label: 'Build your app',
    link: '/apps/automation/build-your-app',
    icon: CodeBlack,
  },
];

export const WEBHOOKS_LINK = {
  label: 'Automate with webhooks',
  link: '/apps/automation/webhooks',
  icon: Automation,
};
