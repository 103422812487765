import type { FC } from 'react';

import { Close } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { FakeLink } from 'components/fake-link/FakeLink';
import { navigate } from 'helpers/routing';
import { useEffectOnce } from 'hooks/use-effect-once';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { NavigationViewActions } from 'store/views/navigation/actions';

import * as styles from './styles';

export const TargetedMessagesTooltip: FC = () => {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.TargetedMessagesTooltipSeen]: '1',
      })
    );
  });

  const handleCancel = (): void => {
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: 'settings' }));
  };

  const handleLinkClick = (): void => {
    handleCancel();
    navigate('/settings/targeted-messages');
  };

  return (
    <div className={styles.wrapper}>
      <div onClick={handleCancel} className={styles.icon}>
        <Icon source={Close} size="xsmall" />
      </div>
      <h3>New refreshed targeted messages section</h3>
      <p className={styles.description}>
        Engage customers with personalised messages when they browse through your website.
      </p>
      <FakeLink className={styles.link} onClick={handleLinkClick}>
        Check it now!
      </FakeLink>
    </div>
  );
};
