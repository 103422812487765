import type { IAddCannedResponse } from 'interfaces/canned-responses';
import { type AddWebsiteSourceFormData } from 'interfaces/knowledge-hub';
import type {
  IFetchCannedResponsesPayload,
  IRemoveCannedResponsePayload,
  ISaveCannedResponsePayload,
} from 'store/entities/canned-responses/interfaces';
import { type ActionsUnion, createAction } from 'store/helper';

import { type KnowledgeHubModalProps, type KnowledgeHubModalType } from './interfaces';

export enum AutomateViewActionNames {
  SET_ADD_WEBSITE_SOURCE_FORM_VALUES = 'VIEWS/SETTINGS/SET_ADD_WEBSITE_FORM_VALUES',
  CLEAR_ADD_WEBSITE_SOURCE_FORM_VALUES = 'VIEWS/SETTINGS/CLEAR_ADD_WEBSITE_FORM_VALUES',
  SET_CRAWLER_TASK_ID = 'VIEWS/SETTINGS/SET_CRAWLER_TASK_ID',
  SET_KNOWLEDGE_HUB_MODAL = 'VIEWS/SETTINGS/SET_KNOWLEDGE_HUB_MODAL',
  SET_BLACKLISTED_WEBPAGES = 'VIEWS/SETTINGS/SET_BLACKLISTED_WEBPAGES',
  SET_SEARCH = 'VIEWS/AUTOMATE/CANNED_RESPONSES/SET_SEARCH',
  SAVE_CANNED_RESPONSE = 'VIEWS/AUTOMATE/CANNED_RESPONSES/SAVE_CANNED_RESPONSE',
  COPY_CANNED_RESPONSE = 'VIEWS/AUTOMATE/CANNED_RESPONSES/COPY_CANNED_RESPONSE',
  UPDATE_CANNED_RESPONSE = 'VIEWS/AUTOMATE/CANNED_RESPONSES/UPDATE_CANNED_RESPONSE',
  FETCH_CANNED_RESPONSES = 'VIEWS/AUTOMATE/CANNED_RESPONSES/FETCH_CANNED_RESPONSES',
  REMOVE_CANNED_RESPONSE = 'VIEWS/AUTOMATE/CANNED_RESPONSES/REMOVE_CANNED_RESPONSE',
  SET_CANNED_RESPONSE_FORM_VALUES = 'VIEWS/AUTOMATE/CANNED_RESPONSES/SET_CANNED_RESPONSE_FORM_VALUES',
  CLEAR_CANNED_RESPONSE_FORM_VALUES = 'VIEWS/AUTOMATE/CANNED_RESPONSES/CLEAR_CANNED_RESPONSE_FORM_VALUES',
}

export const AutomateViewActions = {
  setAddWebsiteSourceFormValues: (payload: AddWebsiteSourceFormData) =>
    createAction(AutomateViewActionNames.SET_ADD_WEBSITE_SOURCE_FORM_VALUES, payload),
  clearAddWebsiteSourceFormValues: () => createAction(AutomateViewActionNames.CLEAR_ADD_WEBSITE_SOURCE_FORM_VALUES),
  setCrawlerTaskId: (taskId: string | null) => createAction(AutomateViewActionNames.SET_CRAWLER_TASK_ID, { taskId }),
  setKnowledgeHubModal: (modal: KnowledgeHubModalType | null, modalProps?: KnowledgeHubModalProps) =>
    createAction(AutomateViewActionNames.SET_KNOWLEDGE_HUB_MODAL, { modal, modalProps }),
  setBlacklistedWebpages: (blacklistedWebpages: string[] | []) =>
    createAction(AutomateViewActionNames.SET_BLACKLISTED_WEBPAGES, { blacklistedWebpages }),
  setSearch: (payload: string) => createAction(AutomateViewActionNames.SET_SEARCH, payload),
  saveCannedResponse: (payload: ISaveCannedResponsePayload) =>
    createAction(AutomateViewActionNames.SAVE_CANNED_RESPONSE, payload),
  copyCannedResponse: (payload: ISaveCannedResponsePayload) =>
    createAction(AutomateViewActionNames.COPY_CANNED_RESPONSE, payload),
  fetchCannedResponses: (payload?: IFetchCannedResponsesPayload) =>
    createAction(AutomateViewActionNames.FETCH_CANNED_RESPONSES, payload),
  updateCannedResponse: (payload: ISaveCannedResponsePayload) =>
    createAction(AutomateViewActionNames.UPDATE_CANNED_RESPONSE, payload),
  removeCannedResponse: (payload: IRemoveCannedResponsePayload) =>
    createAction(AutomateViewActionNames.REMOVE_CANNED_RESPONSE, payload),
  setCannedResponseFormValues: (payload: IAddCannedResponse) =>
    createAction(AutomateViewActionNames.SET_CANNED_RESPONSE_FORM_VALUES, payload),
  clearCannedResponseFormValues: () => createAction(AutomateViewActionNames.CLEAR_CANNED_RESPONSE_FORM_VALUES),
};

export type AutomateViewActionTypes = ActionsUnion<typeof AutomateViewActions>;
