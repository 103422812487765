import { css } from '@emotion/css';

export const fullscreenAppIconStyles = css`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const navigationItemStyles = css`
  position: relative;
  list-style: none;
`;

export const homeIconWrapper = css`
  padding: 8px 12px;

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const imageWrapper = css`
  height: 24px;
`;
