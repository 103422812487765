// @ts-strict-ignore
import { startOfDay, endOfDay, isSameDay, formatISO } from 'date-fns';

import { ChatEventType } from 'constants/chat-event-type';
import { AvailabilityFilter, GroupStatusAtStart } from 'constants/filters/availability-filter';
import { Filter } from 'constants/filters/filter';
import { ReportDistribution } from 'constants/reports/distribution';
import { PredefinedTagName } from 'constants/reports/predefined-tag-name';
import { ReportType } from 'constants/reports/report-type';
import { anyToBoolean } from 'helpers/boolean';
import { getFilterByOperator, prepareRoutingFilters, getIsExcludingValues } from 'helpers/filters';
import { deepMerge } from 'helpers/object';
import type { ISerializedFilters, ISerializedFiltersWithRawDates } from 'interfaces/reports';
import type { SerializedJSONFilters } from 'interfaces/reports/api-v3';

export function serializeReportDistribution<T extends ISerializedFilters | ISerializedFiltersWithRawDates>(
  filters: T
): T {
  const serializedFilters = { ...filters };

  if (serializedFilters.distribution === ReportDistribution.Hour) {
    serializedFilters.distribution = ReportDistribution.DayHours;
  }

  return serializedFilters;
}

// needed because chat availability report accepts only Day and Hours distributions, can be removed when it will be fixed by API
export function serializeReportDistributionForReport<T extends ISerializedFilters | ISerializedFiltersWithRawDates>(
  filters: T,
  reportType: ReportType
): T {
  const serializedFilters = serializeReportDistribution(filters);

  if (reportType === ReportType.ChatAvailability) {
    serializedFilters.distribution = isSameDay(filters.dateFrom, filters.dateTo)
      ? ReportDistribution.Hour
      : ReportDistribution.Day;
  }

  return serializedFilters;
}

export const serializeJSONFilters = ({
  agents,
  groups,
  tags,
  channelIds,
  greetings,
  agentAssignment,
  tagged,
  dateFrom,
  dateTo,
  eventTypes,
  operators,
  distribution,
  page,
  properties,
  agentResponse,
  availability,
  countries,
}: ISerializedFilters | ISerializedFiltersWithRawDates): SerializedJSONFilters => {
  const filters = {
    distribution: distribution || ReportDistribution.Day,
  } as SerializedJSONFilters;

  if (page > 1) {
    filters.page = page;
  }
  if (dateFrom) {
    filters.from = formatISO(startOfDay(dateFrom));
  }

  if (dateTo) {
    filters.to = formatISO(endOfDay(dateTo));
  }

  if (agents?.length) {
    filters.agents = { values: agents };
  }

  if (groups?.length) {
    filters.groups = { values: groups.map(Number) };
  }

  if (countries?.length) {
    filters.customer_countries = getFilterByOperator<string>({
      operators,
      filter: Filter.CountryISO,
      values: countries,
    });
  }

  if (tags?.length) {
    const [tag] = tags;
    switch (tag as PredefinedTagName) {
      case '' as PredefinedTagName:
      case PredefinedTagName.With: {
        filters.tags = { exists: true };
        break;
      }
      case PredefinedTagName.Without: {
        filters.tags = { exists: false };
        break;
      }
      default: {
        filters.tags = getFilterByOperator<string>({ operators, filter: Filter.Tag, values: tags });
        break;
      }
    }
  } else if (tagged) {
    const isTagged = Boolean(Number(tagged));
    const isExcluding = getIsExcludingValues(operators, Filter.Tag);
    filters.tags = { exists: isTagged || isExcluding };
  }

  if (properties) {
    filters.properties = properties;
  }

  if (channelIds?.length) {
    filters.properties = deepMerge(true, {}, filters.properties, {
      source: { customer_client_id: { values: channelIds } },
    });
  }

  if (agentAssignment) {
    if (!filters.agents?.values?.length) {
      filters.agents = { exists: anyToBoolean(agentAssignment) };
    }

    filters.event_types = {
      values: [
        ChatEventType.File,
        ChatEventType.FilledForm,
        ChatEventType.Message,
        ChatEventType.RichMessage,
        ChatEventType.Custom,
        ChatEventType.SystemMessage,
      ],
    };
  }

  if (greetings) {
    filters.greetings = {
      values: greetings.map(Number),
      from: filters.from,
      to: filters.to,
    };

    filters.from = undefined;
    filters.to = undefined;

    if (groups?.length) {
      filters.greetings.groups = {
        values: groups.map(Number),
      };

      delete filters.groups;
    }
  }

  if (availability) {
    filters.properties = deepMerge(
      true,
      {},
      filters.properties,
      prepareRoutingFilters('group_status_at_start', { values: mapGroupStatusAtStart(availability) }).properties
    );
  }

  if (eventTypes) {
    filters.event_types = {
      values: eventTypes,
    };
  }

  if (agentResponse) {
    filters.agent_response = { ...agentResponse };

    if (agentResponse.first) {
      if (agents?.length) {
        filters.agent_response.agents = {
          values: agents,
        };

        delete filters.agents;
        delete filters.agent_response.exists;
      }
      if (groups?.length) {
        filters.agent_response.groups = {
          values: groups.map(Number),
        };

        delete filters.groups;
        delete filters.agent_response.exists;
      }
    }
  }

  return filters;
};

const mapGroupStatusAtStart = (availability: AvailabilityFilter): string[] =>
  ({
    [AvailabilityFilter.Online]: [GroupStatusAtStart.Online, GroupStatusAtStart.OnlineForQueue],
    [AvailabilityFilter.Offline]: [GroupStatusAtStart.Offline, GroupStatusAtStart.NotAcceptingChats],
  }[availability]);
