import { FC } from 'react';

import { TourStep } from 'components/tours/TourStep';
import {
  ANNOUNCEMENT_IMG_URL,
  CONTEXT_MENU_IMG_URL,
  QUICK_REPLIES_IMG_URL,
} from 'services/tour/targeted-messages/constants';

export const GreetingsStep: FC = () => (
  <TourStep
    image={<img src={QUICK_REPLIES_IMG_URL} alt="quick replies recurring campaign animation" />}
    title="Get more chats"
    message="Generate leads, guide customers on your website, and more! Visitors will see a recurring campaign every time they enter a specific page."
  />
);

export const AnnouncementsStep: FC = () => (
  <TourStep
    image={<img src={ANNOUNCEMENT_IMG_URL} alt="card one-time campaign with calendar animation" />}
    title="Keep your customers up to date"
    message="Engage customers with time-limited offers like discounts for returning visitors or users coming from social media campaigns. Visitors will see one-time campaigns only once."
  />
);

export const ContextMenuStep: FC = () => (
  <TourStep
    image={<img src={CONTEXT_MENU_IMG_URL} alt="context menu open animation" />}
    title="Test multiple variations"
    message="Try out new configurations to increase conversion. Duplicate your existing campaigns and fine-tune its look and conditions."
  />
);
