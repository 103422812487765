import { getConfig } from 'helpers/config';
import type { InsightsAcknowledgedHints, InsightsHintId } from 'store/entities/insights/interfaces';

export function getUnreadHintsCount(
  hintsToShow: InsightsHintId[],
  acknowledgedHints: InsightsAcknowledgedHints
): number {
  const hintsCount = hintsToShow.length;

  return hintsToShow.reduce((acc, hintId) => {
    if (acknowledgedHints[hintId]) {
      return acc - 1;
    }

    return acc;
  }, hintsCount);
}

export function getInsightsWebComponentsSrc(): string {
  const config = getConfig();

  return `https://${config.insightsAppHostname}${config.insightsWebComponentsPath}`;
}
