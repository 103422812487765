import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

export const container = css`
  z-index: 10000;
`;

export const news = css`
  &&& .beamer_icon {
    font-family:
      'Inter',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Helvetica Neue',
      Arial,
      sans-serif;
    top: 0px !important;
    right: -1px !important;
    background-color: var(${DesignToken.SurfaceAccentEmphasisHighNegative}) !important;
  }
`;
