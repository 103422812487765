import { type ActionsUnion, createAction } from 'store/helper';

import { type ISelectDetailsTabPayload } from './interfaces';

export const enum DetailsColumnActionNames {
  SELECT_DETAILS_TAB = 'VIEWS/TRAFFIC/SELECT_DETAILS_TAB',
}

export const DetailsColumnActions = {
  selectDetailsTab: (payload: ISelectDetailsTabPayload) =>
    createAction(DetailsColumnActionNames.SELECT_DETAILS_TAB, payload),
};

export type DetailsColumnAction = ActionsUnion<typeof DetailsColumnActions>;
