import { channel, type SagaIterator } from 'redux-saga';
import { call, cancel, fork, take } from 'redux-saga/effects';

import { isTabFocused } from 'helpers/browser';
import { watchTabFocus } from 'store/helpers/tab-focus';

import { watchChatNotificationCount } from './chat-notifications';
import { type INotificationEvent, type NotificationChannel } from './interfaces';
import { setCounterInTabTitle, blinkTabTitle } from './tab-title';

function* handleNotifications(notificationCount: number): SagaIterator {
  const tabFocused = yield call(isTabFocused);

  if (tabFocused) {
    setCounterInTabTitle(notificationCount);
  } else if (notificationCount > 0) {
    yield call(blinkTabTitle, notificationCount);
  }
}

export function* tabNotificationsSaga(): SagaIterator {
  yield take('APP_READY');

  const notificationChannel: NotificationChannel = yield call(channel);
  let chatNotificationCount = 0;
  let currentTask = null;

  yield fork(watchChatNotificationCount, notificationChannel);
  yield fork(watchTabFocus, notificationChannel);

  while (true) {
    const event: INotificationEvent = yield take(notificationChannel);

    if (currentTask) {
      yield cancel(currentTask);
    }

    if (event.type === 'chats') {
      chatNotificationCount = event.count;
    }

    currentTask = yield fork(handleNotifications, chatNotificationCount);
  }
}
