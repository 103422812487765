import { createSelector } from 'reselect';

import { getWaitingForApprovalAgents } from 'store/entities/agents/selectors';
import { getCartItemsCount } from 'store/entities/product-cart/selectors';
import { getIsSubscriptionInTrialAndNotSubscribed } from 'store/entities/subscription/selectors';
import {
  getHasPendingInvites,
  getPendingInvitesCount,
  getTeamApproveAllSeen,
} from 'store/features/agent-custom-properties/selectors';
import { getCanManageInvitations } from 'store/features/session/selectors';

import { calculateBadgeProps } from '../badges/team-badge/helpers';

export const getCalculatedTeamBadge = createSelector(
  [
    getCanManageInvitations,
    getTeamApproveAllSeen,
    getHasPendingInvites,
    getWaitingForApprovalAgents,
    getPendingInvitesCount,
  ],
  (canManageInvitations, teamApproveAllSeen, hasPendingInvites, waitingForApprovalAgents, pendingInvitesCount) => {
    const badgeProps = calculateBadgeProps(
      canManageInvitations,
      teamApproveAllSeen,
      hasPendingInvites,
      waitingForApprovalAgents.length,
      pendingInvitesCount,
    );

    if (!badgeProps || badgeProps.count === undefined) return null;
    return badgeProps.type === 'alert' ? 'alert' : badgeProps.count;
  },
);

export const getCalculatedAppsBadge = createSelector(
  [getCartItemsCount, getIsSubscriptionInTrialAndNotSubscribed],
  (cartItemsCount, isTrial) => {
    return cartItemsCount > 0 && !isTrial ? cartItemsCount : null;
  },
);
