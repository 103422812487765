import { flatten } from 'helpers/data';
import type { GroupWithProperties } from 'interfaces/groups';
import type { IAppState } from 'services/app-state-provider';
import { GroupPropertiesActions } from 'store/entities/group-properties/actions';

export function handleGroupPropertiesUpdate(payload: { group: GroupWithProperties }, store: IAppState): void {
  const {
    group: { id, properties: rawProperties },
  } = payload;
  const groupId = id.toString();

  const properties = flatten(rawProperties);

  store.dispatch(
    GroupPropertiesActions.saveSuccess({
      groupId,
      properties,
    })
  );
}
