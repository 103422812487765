// Sync with device.ts and .forMobileOnly() mixin

import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';

import { isDesktopOSDetected, shouldForceDesktopView } from './device';

// small screens or touch screens or pen controlled screens
const MOBILE_VIEW_MEDIA_QUERY = `@media (max-width: ${SMALL_SCREEN_BREAKPOINT}px), (pointer: coarse), (pointer: fine) and (hover: none)`;

const MOBILE_DEVICE_MEDIA_QUERY = '@media (pointer: coarse), (pointer: fine) and (hover: none)';

const DESKTOP_DEVICE_MEDIA_QUERY = '@media (hover: hover) and (pointer: fine), (pointer: none)';

const ALL_MEDIA_QUERY = '@media all';

const NO_MEDIA_QUERY = '@media not all';

/**
 * @deprecated Don't detect device type to determine view type, instead: use media query with max-width
 */
export const mobileMediaQuery = shouldForceDesktopView() ? NO_MEDIA_QUERY : MOBILE_VIEW_MEDIA_QUERY;

export const mobileDeviceMediaQuery = isDesktopOSDetected() ? NO_MEDIA_QUERY : MOBILE_DEVICE_MEDIA_QUERY;

export const desktopDeviceMediaQuery = isDesktopOSDetected() ? ALL_MEDIA_QUERY : DESKTOP_DEVICE_MEDIA_QUERY;
