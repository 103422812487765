import type { FC } from 'react';

import { Button, Text } from '@livechat/design-system-react-components';

import { PlanType } from 'constants/plan-type';
import { navigateToUpgradeFeature } from 'helpers/routing';

import { COPILOT_SOURCES_DESCRIPTION } from './constants';

import * as styles from './styles';

export const ReplySuggestionsUpsell: FC = () => {
  const handleUpgradeClick = (): void => {
    navigateToUpgradeFeature(PlanType.Team);
  };

  return (
    <div>
      <Text className={styles.starterUpsellText}>{COPILOT_SOURCES_DESCRIPTION}</Text>
      <Button kind="primary" onClick={handleUpgradeClick}>
        Upgrade
      </Button>
    </div>
  );
};
