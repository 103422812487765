import { type FC } from 'react';

import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';

import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import { messageBox, popoverMessageBox } from '../styles';

import { CopilotMessageBoxBottomBar } from './CopilotMessageBoxBottomBar';
import { CopilotMessageBoxPlugins } from './CopilotMessageBoxPlugins';
import { CopilotMessageBoxWrapper } from './CopilotMessageBoxWrapper';

import { popoverMessageBoxWrapper } from './styles';

export const CopilotMessageBox: FC = () => {
  const isExpanded = useSelector(getIsCopilotModalExpanded);

  return (
    <div className={cx({ [popoverMessageBox]: !isExpanded, [messageBox]: isExpanded })}>
      <CopilotMessageBoxWrapper className={cx({ [popoverMessageBoxWrapper]: !isExpanded })}>
        <>
          <CopilotMessageBoxPlugins isExpanded={isExpanded} />
          <CopilotMessageBoxBottomBar isExpanded={isExpanded} />
        </>
      </CopilotMessageBoxWrapper>
    </div>
  );
};
