// @ts-strict-ignore
import type { ITag } from 'store/entities/tags/interfaces';

import { type TagDTO } from './interfaces';

export function deserializeTag(data: TagDTO): ITag {
  if (!data) {
    return null;
  }

  return {
    countInChats: data.count.inChats,
    countInTickets: data.count.inTickets,
    group: String(data.group),
    name: data.name,
  };
}

export function deserializeTagCollection(data: TagDTO[]): ITag[] {
  if (!data || !Array.isArray(data)) {
    return null;
  }

  return data.map((item) => deserializeTag(item));
}
