import { type TopBarNotificationType } from 'constants/notifications';
import { type ITopBarNotification } from 'interfaces/notification-bar';
import { createAction, type ActionsUnion } from 'store/helper';

export enum NotificationsBarActionsNames {
  SHOW_NOTIFICATIONS_BAR = 'FEATURES/NOTIFICATIONS_BAR/SHOW_NOTIFICATIONS_BAR',
  HIDE_NOTIFICATIONS_BAR = 'FEATURES/NOTIFICATIONS_BAR/HIDE_NOTIFICATIONS_BAR',
  HIDE_ALL_NOTIFICATIONS_BAR = 'FEATURES/NOTIFICATIONS_BAR/HIDE_ALL_NOTIFICATIONS_BAR',
  TOGGLE_DISPLAY = 'FEATURES/NOTIFICATIONS_BAR/TOGGLE_DISPLAY',
  SHOW_ALL_NOTIFICATIONS_BAR = 'FEATURES/NOTIFICATIONS_BAR/SHOW_ALL_NOTIFICATIONS_BAR',
  NOTIFICATIONS_BAR_TRANSITON_ENDED = 'FEATURES/NOTIFICATIONS_BAR/NOTIFICATIONS_BAR_TRANSITON_ENDED',
}

export const NotificationsBarActions = {
  showNotificationsBar(notification: ITopBarNotification) {
    return createAction(NotificationsBarActionsNames.SHOW_NOTIFICATIONS_BAR, {
      notification,
      props: notification.props || {},
    });
  },
  hideNotificationsBar(notificationName: TopBarNotificationType) {
    return createAction(NotificationsBarActionsNames.HIDE_NOTIFICATIONS_BAR, { notificationName });
  },
  hideAllNotificationsBar() {
    return createAction(NotificationsBarActionsNames.HIDE_ALL_NOTIFICATIONS_BAR);
  },
  showAllNotificationsBar() {
    return createAction(NotificationsBarActionsNames.SHOW_ALL_NOTIFICATIONS_BAR);
  },
  toggleDisplayNotificationsBar() {
    return createAction(NotificationsBarActionsNames.TOGGLE_DISPLAY);
  },
  notificationsBarTransitionEnded() {
    return createAction(NotificationsBarActionsNames.NOTIFICATIONS_BAR_TRANSITON_ENDED);
  },
};

export type NotificationsBarActionsTypes = ActionsUnion<typeof NotificationsBarActions>;
