import { PlatformProtocolParser } from './legacy/platform-protocol-parser';

let parser: PlatformProtocolParser | null = null;

export function getWebsocketParser(): PlatformProtocolParser {
  if (!parser) {
    parser = new PlatformProtocolParser();
  }

  return parser;
}
