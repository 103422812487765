// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, apply, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IntegrationCardName } from 'constants/integration-card';
import { getGTMApi } from 'helpers/gtm-api';
import { normalizeIntegrationName } from 'helpers/installation-technologies';
import { trackEvent } from 'services/event-tracking';
import { type IGoogleTagManagerAPI } from 'services/google-tag-manager-api';

import { GTMActions, GTMActionsNames } from './actions';
import { type IGTMAccount, type IGTMContainer } from './interfaces';
import { getAccountId, getAccounts, getContainerId, getContainers } from './selectors';

export function* initApi(action): SagaIterator {
  const { eventPlace } = action.payload;
  const gtmApi: IGoogleTagManagerAPI = getGTMApi();

  try {
    const isInitialized: boolean = yield apply(gtmApi, gtmApi.isInitialized, []);
    if (!isInitialized) {
      yield put(GTMActions.setIsInitializing(true));
      yield apply(gtmApi, gtmApi.initGapi, []);
    }
    const isAuthorized: boolean = yield apply(gtmApi, gtmApi.getLoginStatus, []);
    yield put(GTMActions.setIsAuthorized(isAuthorized, eventPlace));
  } catch (e) {
    yield put(GTMActions.stopAuthorizing());
  } finally {
    yield put(GTMActions.setIsInitializing(false));
  }
}

export function* startAuthorizing(action): SagaIterator {
  const { eventPlace, isGTMRecommended } = action.payload;
  const gtmApi: IGoogleTagManagerAPI = getGTMApi();

  trackEvent(`${isGTMRecommended ? 'Suggested i' : 'I'}ntegration CTA clicked`, eventPlace, {
    integrationName: normalizeIntegrationName(IntegrationCardName.GTM),
  });

  try {
    yield apply(gtmApi, gtmApi.signIn, []);
    const isAuthorized: boolean = yield apply(gtmApi, gtmApi.getLoginStatus, []);
    yield put(GTMActions.setIsAuthorized(isAuthorized, eventPlace));
  } catch (e) {
    trackEvent('GTM auth failed', eventPlace);
    yield put(GTMActions.stopAuthorizing());
  }
}

export function* fetchUserAccounts(action): SagaIterator {
  const { isAuthorized, eventPlace } = action.payload;
  const gtmApi: IGoogleTagManagerAPI = getGTMApi();

  if (isAuthorized) {
    trackEvent('GTM auth succeeded', eventPlace);

    try {
      const accounts: IGTMAccount[] = yield apply(gtmApi, gtmApi.getUserAccounts, []);
      if (accounts.length === 0) {
        yield put(GTMActions.accountDataError());
      } else {
        yield put(GTMActions.setAccounts(accounts));
        if (accounts.length === 1) {
          yield put(GTMActions.selectAccountId(accounts[0].key, eventPlace));
        }
      }
    } catch (e) {
      yield put(GTMActions.accountDataError());
    }
  }
}

export function* signOut(action): SagaIterator {
  const { eventPlace } = action.payload;
  const gtmApi: IGoogleTagManagerAPI = getGTMApi();
  yield apply(gtmApi, gtmApi.signOut, []);
  yield put(GTMActions.setIsAuthorized(false, eventPlace));
}

export function* submitTag(action): SagaIterator {
  const gtmApi: IGoogleTagManagerAPI = getGTMApi();

  const containerId = yield select(getContainerId);
  const accountId = yield select(getAccountId);
  const { trackingCode, eventPlace } = action.payload;

  if (containerId && accountId) {
    yield put(GTMActions.startCreatingTag());

    try {
      yield apply(gtmApi, gtmApi.handleSetTag, [accountId, containerId, trackingCode]);
      trackEvent('GTM integration succeeded', eventPlace);
      yield put(GTMActions.tagCreatedSuccessfuly());
    } catch (e) {
      trackEvent('GTM integration failed', eventPlace, {
        reason: e && e.result && e.result.error.message,
      });
      yield put(GTMActions.errorCreatingTag());
    }
  }
}

export function* selectAccount(action): SagaIterator {
  const { accountId, eventPlace } = action.payload;
  const gtmApi: IGoogleTagManagerAPI = getGTMApi();
  const accounts: IGTMAccount[] = yield select(getAccounts);

  if (accounts.some((c) => c.key === accountId)) {
    yield put(GTMActions.setAccountId(accountId));
    trackEvent('GTM account chosen', eventPlace);

    try {
      const containers: IGTMContainer[] = yield apply(gtmApi, gtmApi.getUserContainers, [accountId]);
      yield put(GTMActions.setContainers(containers));
      if (containers.length === 1) {
        yield put(GTMActions.selectContainerId(containers[0].key, eventPlace));
      }
    } catch (e) {
      yield put(GTMActions.accountDataError());
    }
  }
}

export function* selectContainer(action): SagaIterator {
  const { containerId, eventPlace } = action.payload;
  const containers: IGTMContainer[] = yield select(getContainers);

  if (containers.some((c) => c.key === containerId)) {
    yield put(GTMActions.setContainerId(containerId));
    trackEvent('GTM container chosen', eventPlace);
  }
}

function* watchInitApi(): SagaIterator {
  yield takeEvery(GTMActionsNames.INIT_API, initApi);
}

function* watchStartAuthorizing(): SagaIterator {
  yield takeEvery(GTMActionsNames.START_AUTHORIZING, startAuthorizing);
}

function* watchSetIsAuthorized(): SagaIterator {
  yield takeEvery(GTMActionsNames.SET_IS_AUTHORIZED, fetchUserAccounts);
}

function* watchSignOut(): SagaIterator {
  yield takeEvery(GTMActionsNames.SIGN_OUT, signOut);
}

function* watchSubmitTag(): SagaIterator {
  yield takeEvery(GTMActionsNames.SUBMIT_TAG, submitTag);
}

function* watchSelectAccount(): SagaIterator {
  yield takeEvery(GTMActionsNames.SELECT_ACCOUNT_ID, selectAccount);
}

function* watchSelectContainer(): SagaIterator {
  yield takeEvery(GTMActionsNames.SELECT_CONTAINER_ID, selectContainer);
}

export default function* googleTagManagerSagas(): SagaIterator {
  yield all([
    fork(watchInitApi),
    fork(watchStartAuthorizing),
    fork(watchSetIsAuthorized),
    fork(watchSubmitTag),
    fork(watchSignOut),
    fork(watchSelectAccount),
    fork(watchSelectContainer),
  ]);
}
