import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';

export const sprig = `
  #ul-frame {
    left: 64px !important;
  }

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
    #ul-frame {
      left: 0 !important;
    }
  }
`;
