import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type UnfollowChatEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#unfollow-chat
 */
export async function unfollowChat(chatId: string): Promise<unknown> {
  const event: UnfollowChatEvent = {
    action: WebSocketAction.UnfollowChat,
    payload: {
      id: chatId,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
