import { ActiveExperiment } from 'constants/active-experiment';
import { Right } from 'constants/right';
import { SettingsNavigationItemId } from 'constants/settings/navigation-item-id';
import type { ISideNavigationItem } from 'interfaces/side-navigation-item';
import { AppStateProvider } from 'services/app-state-provider';
import { getIsCodeInstalled } from 'store/features/code-installation/selectors';

const ChannelsNavigationItems: ISideNavigationItem[] = [
  {
    id: SettingsNavigationItemId.Overview,
    name: 'Overview',
    path: '/settings/overview',
    badge: !AppStateProvider.selectFromStore(getIsCodeInstalled),
  },
  {
    id: SettingsNavigationItemId.Code,
    name: 'Install LiveChat',
    path: '/settings/code',
    important: !AppStateProvider.selectFromStore(getIsCodeInstalled),
    badge: !AppStateProvider.selectFromStore(getIsCodeInstalled),
  },
  {
    id: SettingsNavigationItemId.Email,
    name: 'Email',
    path: '/settings/email',
    right: Right.EmailForwardManagement,
    experiment: {
      in: false,
      name: ActiveExperiment.DisableNativeTickets,
    },
  },
  {
    id: SettingsNavigationItemId.HelpDesk,
    name: 'Email by HelpDesk',
    path: '/settings/helpdesk',
  },
  {
    id: SettingsNavigationItemId.FacebookMessenger,
    name: 'Facebook Messenger',
    path: '/settings/facebook-messenger',
    right: Right.FacebookMessengerManagement,
  },
  {
    id: SettingsNavigationItemId.AppleBusinessChat,
    name: 'Apple Messages',
    path: '/settings/apple-messages-for-business',
  },
];

// TODO (Overview): temporary hiding Overview from Channels; requested by @maserafi
export const ChannelsNavigationItemsWithoutOverview: ISideNavigationItem[] = ChannelsNavigationItems.filter(
  (navItem) => navItem.id !== (SettingsNavigationItemId.Overview as string)
);
