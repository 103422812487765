import { ChatEventSubType } from 'constants/chat-event-type';
import { type KeyMap } from 'helpers/interface';
import { type IArchiveEvent } from 'interfaces/entities/archive';
import { type ChatEventEntity, type ISystemMessage } from 'store/entities/chats/interfaces';

export const threadStoppedSubtypes = [
  ChatEventSubType.ManuallyArchivedByAgent,
  ChatEventSubType.ManuallyArchivedByCustomer,
  ChatEventSubType.TransferredDueToInactivity,
  ChatEventSubType.ArchivedCustomerDisconnected,
  ChatEventSubType.ArchivedByInactivity,
];

export function getThreadStoppingEvent(
  events: IArchiveEvent[] | KeyMap<ChatEventEntity>
): IArchiveEvent | ChatEventEntity | null {
  const lastEvent = Object.values(events)
    .reverse()
    .find(
      (event) => (event as ISystemMessage)?.subType && threadStoppedSubtypes.includes((event as ISystemMessage).subType)
    );

  return (lastEvent as IArchiveEvent | ChatEventEntity) || null;
}
