import { QueryKey } from 'constants/query-key';
import { type ListLicensePropertiesResponse } from 'services/connectivity/configuration-api/properties/types';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';

export function initializeQueryClient(licenseProperties: ListLicensePropertiesResponse): void {
  for (const namespace in licenseProperties) {
    getQueryClient().setQueryData(QUERY_KEYS[QueryKey.LicenseProperties](namespace), licenseProperties[namespace]);
  }
}
