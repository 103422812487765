import type { FC } from 'react';

import { cx } from '@emotion/css';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';

import * as styles from './styles';

interface IProps {
  className?: string;
}

export const ContentEditableWrapper: FC<IProps> = ({ className }) => (
  <ContentEditable className={cx(styles.contentEditable, className)} />
);
