import type { FC, ComponentProps } from 'react';

import { cx } from '@emotion/css';
import { Badge } from '@livechat/design-system-react-components';

import * as styles from './styles';

type BadgePops = ComponentProps<typeof Badge>;

export interface INavigationBadgeProps {
  type?: BadgePops['type'];
  kind?: BadgePops['kind'];
  className?: string;
  count?: number;
  ariaLabel?: string;
}

export const NavigationBadge: FC<INavigationBadgeProps> = ({
  type = 'counter',
  kind = 'primary',
  className,
  count,
  ariaLabel,
}) => (
  <Badge type={type} count={count} kind={kind} className={cx(styles.container, className)} aria-label={ariaLabel} />
);
