import memoizeOne from 'memoize-one';

import type { IAgentFilterItem } from 'components/filters/agent-filter/agent-filter';
import { AgentType } from 'constants/agent-type';
import { LoginStatus } from 'constants/login-status';
import { Permission } from 'constants/permission';
import type { IAgentBase } from 'interfaces/entities/agent-base';
import type { IAgent } from 'store/entities/agents/interfaces';
import type { IAgentBot } from 'store/entities/bots/interfaces';

export type IAgentAvailability = Pick<IAgentBase, 'lastLogout' | 'status' | 'type'>;
export interface ISessionInstance {
  /* eslint-disable @typescript-eslint/naming-convention */
  app_info: string;
  is_mobile?: boolean;
  /* eslint-enable @typescript-eslint/naming-convention */
}

const MOBILE_DEVICE_TYPES = ['iOS', 'iOS/iPhone', 'iOS/iPad', 'Android', 'Mobile/Android', 'Mobile/iOS'];

/**
 * Return the rang of agent(higher number == higher order)
 */
function getAgentRoleRang(agent: IAgentBase): number {
  const permission = agent.permission as Permission;
  if (permission === Permission.Owner) {
    return 3;
  }

  if ([Permission.ViceOwner, Permission.Administrator].includes(permission)) {
    return 2;
  }

  if (agent.type === AgentType.Bot) {
    return 1;
  }

  return 0;
}

/**
 * Return the rang of agent by (lower number == higher order).
 * Order of agents:
 * 1. Logged in agents - 0 points
 * 2. Logged in bots - 1 point
 * 3. Logged off agents - 2 points
 * 4. Logged off bots - 3 points
 * 5. Pending agents - 6 points - check for isLoggedIn is added because backend don't update lastLogout timestamp
 */
export function getAgentAvailabilityRang(agent: IAgentAvailability): number {
  const isLoggedIn = [LoginStatus.Online, LoginStatus.Away].includes(agent.status);
  const isBot = agent.type === AgentType.Bot;

  return (isLoggedIn ? 0 : 2) + (isBot ? 1 : 0) + (agent.lastLogout === 0 && !isLoggedIn && !isBot ? 4 : 0);
}

/**
 * Get rang of status(need for sorting)
 */
function getAgentStatusRang(agent: IAgentBase): number {
  switch (agent.status) {
    case LoginStatus.Online:
      return 2;
    case LoginStatus.Away:
      return 1;
    default:
      return 0;
  }
}

/**
 * Check if agent is logged in on desktop
 */
export function isLoggedOnDesktop(sessions: ISessionInstance[]): boolean {
  return sessions.some((instance) => /Web/.test(instance.app_info) || /SmartClient/.test(instance.app_info));
}

/**
 * Check if agent has sessions from mobile
 */
export function isLoggedOnMobile(sessions: ISessionInstance[]): boolean {
  return (
    sessions.some((instance) => /LiveChat Mobile/.test(instance.app_info) || /LiveChat Lite/.test(instance.app_info)) ||
    sessions.some((instance) => MOBILE_DEVICE_TYPES.includes(instance.app_info))
  );
}

/**
 * Sort agents by field
 */
export function sortAgents(field: string, desc: boolean): (a: IAgentBase, b: IAgentBase) => number {
  switch (field) {
    case 'status':
      return (a: IAgentBase, b: IAgentBase): number => {
        let order = getAgentStatusRang(a) - getAgentStatusRang(b);

        // Inside group we sort agents by name
        if (order === 0) {
          order = b.name.localeCompare(a.name);
        }

        return (desc ? -1 : 1) * order;
      };

    case 'name':
      return (a: IAgentBase, b: IAgentBase): number => (desc ? -1 : 1) * a.name.localeCompare(b.name);

    case 'login':
      return (a: IAgentBase, b: IAgentBase): number => (desc ? -1 : 1) * a.login.localeCompare(b.login);

    case 'loggedon':
      return (a: IAgentBase, b: IAgentBase): number => {
        const aRang = getAgentAvailabilityRang(a);
        const bRang = getAgentAvailabilityRang(b);

        return (desc ? 1 : -1) * (aRang - bRang);
      };

    case 'role':
      return (a: IAgentBase, b: IAgentBase): number => {
        const aRang = getAgentRoleRang(a);
        const bRang = getAgentRoleRang(b);

        return (desc ? -1 : 1) * (aRang - bRang);
      };

    default:
      return (): number => 0;
  }
}

export const mapAgentToFilterAgent = memoizeOne((agents: IAgentBase[]): IAgentFilterItem[] =>
  agents.map((agent) => ({
    id: agent.login,
    avatarUrl: agent.avatar,
    name: agent.name,
    email: agent.login,
  }))
);

export function guessName(email: string): string {
  return email
    .replace(/@\S+/, '')
    .replace(/\+\S+/, '')
    .replace(/\./g, ' ')
    .replace(/_/g, ' ')
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export function isAgentBot(agent: Pick<IAgentBase, 'type'>): agent is IAgentBot {
  return agent.type === AgentType.Bot;
}

export function isNormalAgent(agent: IAgentBase): agent is IAgent {
  return agent.type === AgentType.Agent;
}
