// @ts-strict-ignore
import { type ThreadPropertiesResult } from 'services/api/interfaces/thread';

export function removeProperties(
  baseObj: Partial<ThreadPropertiesResult>,
  propertiesObj: Partial<ThreadPropertiesResult>
): ThreadPropertiesResult {
  return Object.entries(baseObj).reduce((newBaseObj, [key, value]) => {
    // Check if the propert y exists in propertiesObj and is an array
    if (propertiesObj[key] && Array.isArray(propertiesObj[key])) {
      const propertiesToRemove = propertiesObj[key] as unknown as string[];

      // Filter out the properties that need to be removed and create a new object
      const filteredProps = Object.entries(value).reduce((acc, [propKey, propValue]) => {
        if (!propertiesToRemove.includes(propKey)) {
          acc[propKey] = propValue;
        }

        return acc;
      }, {} as typeof value);

      // If the filtered object is not empty, add it to the newBaseObj
      if (Object.keys(filteredProps).length > 0) {
        newBaseObj[key] = filteredProps;
      }
    } else {
      // If the property does not exist in propertiesObj, just copy it over
      newBaseObj[key] = value;
    }

    return newBaseObj;
  }, {} as ThreadPropertiesResult);
}
