export enum ThreeStateFeatureFlag {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Inherited = 'inherited',
}

export const getStatusFromFeatureFlag = (status: ThreeStateFeatureFlag, inheritedValue: boolean): boolean => {
  switch (status) {
    case ThreeStateFeatureFlag.Enabled:
      return true;

    case ThreeStateFeatureFlag.Inherited:
      return inheritedValue;

    case ThreeStateFeatureFlag.Disabled:
    default:
      return false;
  }
};
