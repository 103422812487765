import { type IActionWithPayload } from 'store/helper';

import type { EntityFetchSuccess } from '../interfaces';

import { type TrustedDomainsAction, TrustedDomainsActionNames } from './actions';
import type { IApiTrustedDomain, ITrustedDomain, ITrustedDomainsEntityState } from './interfaces';

const initialState: ITrustedDomainsEntityState = {
  trusted: [],
  suggested: [],
};

export const trustedDomainsReducer = (
  state: ITrustedDomainsEntityState = initialState,
  action: TrustedDomainsAction
): ITrustedDomainsEntityState => {
  switch (action.type) {
    case TrustedDomainsActionNames.FETCH_SUCCESS: {
      const { payload } = action as IActionWithPayload<string, EntityFetchSuccess<ITrustedDomainsEntityState>>;

      if (payload.value?.trusted && payload.value?.suggested) {
        return {
          ...payload.value,
        };
      }

      return state;
    }
    case TrustedDomainsActionNames.REMOVE_TRUSTED_SUCCESS: {
      const {
        payload: { domain },
      } = action as IActionWithPayload<string, Pick<ITrustedDomain, 'domain'>>;

      return { ...state, trusted: state.trusted.filter((item) => item.domain !== domain) };
    }

    case TrustedDomainsActionNames.ADD_TRUSTED_SUCCESS: {
      const { payload } = action as IActionWithPayload<string, IApiTrustedDomain>;

      return {
        ...state,
        trusted: [
          ...state.trusted,
          {
            createdBy: payload.created_by,
            domain: payload.domain,
            updatedBy: payload.updated_by,
            creationDate: new Date(payload.creation_date),
            updateDate: new Date(payload.update_date),
          },
        ],
      };
    }

    case TrustedDomainsActionNames.REMOVE_SUGGESTED: {
      const {
        payload: { domain },
      } = action as IActionWithPayload<string, { domain }>;

      return { ...state, suggested: state.suggested.filter((item) => item !== domain) };
    }

    default:
      return state;
  }
};
