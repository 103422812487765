import { type AttachmentsFeatureAction, AttachmentsFeatureActionsNames } from 'store/features/attachments/actions';

import { ChatsEntitiesActionNames, type ChatEntitiesAction } from './actions';
import {
  getStateForIncomingChatThread,
  getStateForNewMessage,
  getStateForNewSystemMessage,
  getStateForRemoveChatThreadSuccess,
  getStateForSetData,
  getStateForSetSneakPeek,
  getStateForUpdateChat,
  getStateForUpdateEvents,
  getStateForUpdateMessage,
  getStateForUpdateChatThread,
  getStateForSaveTagSuggestions,
  getStateForRemoveTag,
  getStateForUpdateChatTags,
  getStateForSaveTag,
  getStateForNewSurvey,
  getStateForNewAttachmentMessage,
  getStateForMarkChatAsImportant,
  getStateForUnmarkChatAsImportant,
  getStateForUpdateIncompleteThreadEvents,
  getStateForUpdateAttachment,
  getStateForSupervisedThread,
  getStateForAddNewUnassignedThreads,
  getStateForMarkMultipleChatAsImportant,
  getStateForUnassignedChatsCountUpdate,
  getStateForSetChatHistoryTimeline,
  getStateForFetchChatHistoryCompleted,
  getStateForSetThreadsEvents,
  getStateForCustomerNameChatHistoryUpdate,
  getStateForAddChatUsers,
  getStateForUpdateEvent,
  getStateForSortedChatList,
  getStateForUpdateQueuePositions,
  getStateForSetAgentSneakPeek,
  getStateForUpdateChatSupervisors,
  getStateForClearAgentSneakPeek,
  getStateForSetTagSuggestionModelVersion,
} from './helpers/reducer';
import { type IChatsState } from './interfaces';

export const initialState: IChatsState = {
  initialized: false,
  totalUnassignedChats: 0,

  myChatsIds: [],
  queuedIds: [],
  supervisedIds: [],
  unassignedIds: [],
  importantIds: [],
  otherAgentsChatIds: [],

  threads: {},
  events: {
    byIds: {},
    groupedIds: {},
  },
  tags: {
    tagSuggestions: {
      tags: {},
    },
    chatTags: {},
  },
  sneakPeeks: {},
  agentsSneakPeeks: {},
  chatsHistory: {
    historyLimitReached: {},
    timeline: {},
    summary: {},
    events: {
      byIds: {},
      groupedIds: {},
    },
    threads: {},
    threadsOrder: {},
  },

  chatsUsers: {},
};

export function chatsReducer(
  state: IChatsState = initialState,
  action: ChatEntitiesAction | AttachmentsFeatureAction,
): IChatsState {
  switch (action.type) {
    case ChatsEntitiesActionNames.INCOMING_CHAT_THREAD: {
      return getStateForIncomingChatThread(state, action.payload);
    }
    case ChatsEntitiesActionNames.NEW_MESSAGE: {
      return getStateForNewMessage(state, action.payload);
    }
    case ChatsEntitiesActionNames.NEW_SYSTEM_MESSAGE: {
      return getStateForNewSystemMessage(state, action.payload);
    }
    case ChatsEntitiesActionNames.NEW_SURVEY: {
      return getStateForNewSurvey(state, action.payload);
    }
    case ChatsEntitiesActionNames.NEW_ATTACHMENT: {
      return getStateForNewAttachmentMessage(state, action.payload);
    }
    case ChatsEntitiesActionNames.MARK_CHAT_AS_IMPORTANT: {
      return getStateForMarkChatAsImportant(state, action.payload);
    }
    case ChatsEntitiesActionNames.MARK_MULTIPLE_CHATS_AS_IMPORTANT: {
      return getStateForMarkMultipleChatAsImportant(state, action.payload);
    }
    case ChatsEntitiesActionNames.UNMARK_CHAT_AS_IMPORTANT: {
      return getStateForUnmarkChatAsImportant(state, action.payload);
    }
    case ChatsEntitiesActionNames.REMOVE_CHAT_THREAD_SUCCESS: {
      return getStateForRemoveChatThreadSuccess(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_DATA: {
      return getStateForSetData(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_THREADS_EVENTS: {
      return getStateForSetThreadsEvents(state, action.payload);
    }
    case ChatsEntitiesActionNames.SAVE_TAG_SUGGESTIONS: {
      return getStateForSaveTagSuggestions(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_TAG_SUGGESTION_MODEL_VERSION: {
      return getStateForSetTagSuggestionModelVersion(state, action.payload);
    }
    case ChatsEntitiesActionNames.SAVE_TAG: {
      return getStateForSaveTag(state, action.payload);
    }
    case ChatsEntitiesActionNames.START_SUPERVISING_SUCCESS: {
      return getStateForSupervisedThread(state, action.payload);
    }
    case ChatsEntitiesActionNames.REMOVE_TAG: {
      return getStateForRemoveTag(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_CHAT_TAGS: {
      return getStateForUpdateChatTags(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_SNEAK_PEEK: {
      return getStateForSetSneakPeek(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_AGENT_SNEAK_PEEK: {
      return getStateForSetAgentSneakPeek(state, action.payload);
    }
    case ChatsEntitiesActionNames.CLEAR_AGENT_SNEAK_PEEK: {
      return getStateForClearAgentSneakPeek(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_CHAT: {
      return getStateForUpdateChat(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_CHAT_THREAD: {
      return getStateForUpdateChatThread(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_EVENT: {
      return getStateForUpdateEvent(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_EVENTS: {
      return getStateForUpdateEvents(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_MESSAGE: {
      return getStateForUpdateMessage(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_INCOMPLETE_THREAD_EVENTS: {
      return getStateForUpdateIncompleteThreadEvents(state, action.payload);
    }
    case ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_SUCCESS: {
      return getStateForAddNewUnassignedThreads(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_CHAT_HISTORY_TIMELINE: {
      return getStateForSetChatHistoryTimeline(state, action.payload);
    }
    case ChatsEntitiesActionNames.FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_SUCCESS: {
      return getStateForAddNewUnassignedThreads(state, action.payload);
    }
    case ChatsEntitiesActionNames.FETCH_CHAT_HISTORY_COMPLETED: {
      return getStateForFetchChatHistoryCompleted(state, action.payload);
    }
    case ChatsEntitiesActionNames.ASSIGN_CHAT_TO_OTHER_AGENT: {
      return getStateForRemoveChatThreadSuccess(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_UNASSIGNED_CHATS_COUNT: {
      return getStateForUnassignedChatsCountUpdate(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_CHAT_HISTORY_CUSTOMER_NAME: {
      return getStateForCustomerNameChatHistoryUpdate(state, action.payload);
    }
    case ChatsEntitiesActionNames.ADD_CHAT_USERS: {
      return getStateForAddChatUsers(state, action.payload);
    }
    case ChatsEntitiesActionNames.SORT_CHAT_LIST: {
      return getStateForSortedChatList(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_QUEUE_POSITIONS: {
      return getStateForUpdateQueuePositions(state, action.payload);
    }
    case AttachmentsFeatureActionsNames.UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_SUCCESS: {
      return getStateForUpdateAttachment(state, action.payload);
    }
    case ChatsEntitiesActionNames.UPDATE_CHAT_SUPERVISORS: {
      return getStateForUpdateChatSupervisors(state, action.payload);
    }

    default:
      return state;
  }
}
