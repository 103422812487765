// @ts-strict-ignore

import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThread } from 'store/entities/chats/selectors';

import { type IThreadPropertiesDeletedPushEvent } from '../interfaces';

import { removeProperties } from './helpers/thread-properties-deleted';

/**
 * Handles thread properties delete push event.
 * @param {IThreadPropertiesDeletedPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleThreadPropertiesDeleted(payload: IThreadPropertiesDeletedPushEvent, store: IAppState): void {
  const { thread_id: threadId, properties } = payload;
  const state = store.getState();
  const thread = getThread(state, threadId);

  if (thread) {
    const threadPropertiesToUpdate = removeProperties(thread.customProperties, properties);

    // dispatch action if there is anything to update
    if (Object.keys(threadPropertiesToUpdate).length > 0) {
      store.dispatch(
        ChatsEntitiesActions.updateChatThread({
          threadId,
          thread: {
            customProperties: {
              ...threadPropertiesToUpdate,
            },
          },
        })
      );
    }
  }
}
