import isString from 'lodash.isstring';

import { getConfig } from 'helpers/config';
import type { IAppState } from 'services/app-state-provider';
import { getPropertyValue } from 'services/serialization/property';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { type IAttachmentMessage } from 'store/entities/chats/interfaces';
import { getThreadEventCustomProperties, getEventProperties } from 'store/entities/chats/selectors';

import { type EventPropertiesUpdatedPushEventPayload } from './interfaces';

const CHAT_SENTIMENT_NAMESPACE = getConfig().chatSentimentNamespace;
const CLIENT_ID = getConfig().accountsClientId;

/**
 * Handles LC3 event properties update.
 * @param payload.chat_id Chat id
 * @param payload.thread_id Thread id
 * @param payload.event_id Event id
 * @param properties a full properties object, shows only the properties which have been recently updated
 * @param store redux store
 */
export function handleEventPropertiesUpdated(payload: EventPropertiesUpdatedPushEventPayload, store: IAppState): void {
  const { thread_id: threadId, event_id: eventId, properties } = payload;

  const translatedMessage = getPropertyValue(properties, 'translation', 'target_message', null);
  const sentimentScore = getPropertyValue(properties, CHAT_SENTIMENT_NAMESPACE, 'sentiment_score');
  const messageReaction = getPropertyValue(properties, CLIENT_ID, 'message_reaction');

  const fileSafetyConfirmation: boolean | null = getPropertyValue(properties, 'lc2', 'file_safety_confirmation', null);

  /**
   *  1. Event was translated
   */
  if (translatedMessage) {
    const sourceLangCode = getPropertyValue(properties, 'translation', 'source_lang_code', '');
    const targetLangCode = getPropertyValue(properties, 'translation', 'target_lang_code', '');

    store.dispatch(
      ChatsEntitiesActions.updateEvent({
        threadId,
        eventId,
        event: {
          translation: {
            sourceLangCode,
            targetLangCode,
            targetMessage: translatedMessage,
          },
        },
      })
    );
  }

  /**
   * 2. Safety confirmation that attachment was accepted (true) or declined (false)
   */
  if (fileSafetyConfirmation !== null) {
    const event: Pick<IAttachmentMessage, 'safetyConfirmation'> = {
      safetyConfirmation: fileSafetyConfirmation,
    };
    store.dispatch(
      ChatsEntitiesActions.updateEvent({
        threadId,
        eventId,
        event,
      })
    );
  }

  /**
   * 3. Event sentiment score was updated
   */
  if (sentimentScore) {
    const sentimentMagnitude = getPropertyValue(properties, CHAT_SENTIMENT_NAMESPACE, 'sentiment_magnitude');
    const sentimentUnknown = getPropertyValue(properties, CHAT_SENTIMENT_NAMESPACE, 'sentiment_error_language');

    store.dispatch(
      ChatsEntitiesActions.updateEvent({
        threadId,
        eventId,
        event: {
          properties: {
            [CHAT_SENTIMENT_NAMESPACE]: {
              sentimentScore,
              sentimentMagnitude,
              sentimentUnknown,
            },
          },
        },
      })
    );
    store.dispatch(ChatsEntitiesActions.setSentimentSuccess());
  }

  /**
   *  4. Event got message reaction
   */
  if (isString(messageReaction)) {
    const messageReactionAuthor = getPropertyValue(properties, CLIENT_ID, 'message_reaction_author');
    const state = store.getState();
    const threadEventCustomProperties = getThreadEventCustomProperties(state, threadId, eventId);
    const storedEventProperties = getEventProperties(state, threadId, eventId);

    store.dispatch(
      ChatsEntitiesActions.updateEvent({
        threadId,
        eventId,
        event: {
          properties: {
            ...storedEventProperties,
            [CLIENT_ID]: {
              ...threadEventCustomProperties,
              messageReaction,
              messageReactionAuthor,
            },
          },
        },
      })
    );
  }
}
