import { type FC } from 'react';

import { Badge } from '@livechat/design-system-react-components';

import { useAboveCharacterLimit } from '../hooks/use-above-character-limit';

import { characterLimit } from './styles';

export const CharacterLimit: FC = () => {
  const { aboveLimit, isAboveLimit } = useAboveCharacterLimit();

  if (!isAboveLimit) {
    return null;
  }

  return <Badge className={characterLimit} kind="tertiary" count={aboveLimit * -1} />;
};
