// @ts-strict-ignore
import { ChatThreadStatus, ChatThreadVisualStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { Section } from 'constants/section';
import { areArraysEqualRegardlessOfOrder } from 'helpers/array';
import { type GroupId } from 'interfaces/groups';
import type { IAppState } from 'services/app-state-provider';
import { chatFollowManager } from 'services/chat-follow-manager';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import { getLoggedInAgentLogin, getLoggedInAgentGroupsIds } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { isMyChat, isUnassignedChat } from 'store/entities/chats/helpers/common';
import { getThread, getUnassignedIds, getWasThreadSupervised } from 'store/entities/chats/selectors';
import { CustomerActions } from 'store/entities/customers/actions';
import { getCustomer, getCustomerGroupIds } from 'store/entities/customers/selectors';
import { getIsOnSection } from 'store/features/routing/selectors';
import { ChatsViewActions } from 'store/views/chats/actions';
import { getSelectedThreadId } from 'store/views/chats/selectors';

import { type ChatTransferredPushEvent } from '../interfaces';

/**
 * Handles chat transferred push event.
 * 1. Chat was transferred to a group without available agent.
 *    The chat is queued or removed depending on group access.
 * 2. Agent is assigned.
 *    The chat is removed if transferred by current agent to someone else.
 *    Or it's closed if taken over by other agent.
 * @param {ChatTransferredPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleChatTransferred(payload: ChatTransferredPushEvent, store: IAppState): void {
  const {
    requester_id: requesterId,
    thread_id: threadId,
    chat_id: chatId,
    transferred_to: { agent_ids: agentIds, group_ids: rawGroupIds },
    queue,
  } = payload;

  const state = store.getState();
  const thread = getThread(state, threadId);
  const currentAgentGroups = getLoggedInAgentGroupsIds(state);
  const groupIds = rawGroupIds.map(String);
  const isGroupAssigned = groupIds && groupIds.length > 0;

  if (thread) {
    // Update customer groups on traffic page only if he has access to a group that is target of the transfer.
    const hasAccessToAnyOfTargetGroups = currentAgentGroups.some((agentGroup) => groupIds.includes(agentGroup));
    if (isGroupAssigned && hasAccessToAnyOfTargetGroups) {
      const currentCustomerGroups = getCustomerGroupIds(state, thread.customerId) || [];

      handleUpdateCustomerGroups(store, thread.customerId, currentCustomerGroups, groupIds);
    }

    const isAgentAssigned = agentIds && agentIds.length > 0;
    const wasSupervised = getWasThreadSupervised(state, threadId);
    const isAgentAssignedToAnyOfTransferredGroups = currentAgentGroups.some((g) => groupIds.includes(g));
    const isTransferredOutsideAvailableGroups = !isAgentAssignedToAnyOfTransferredGroups;
    /**
     * 1. Chat was transferred to a group without available agent.
     * The chat is queued/unassigned (or removed depending on group access).
     */
    if (!isAgentAssigned && isGroupAssigned) {
      if (isAgentAssignedToAnyOfTransferredGroups) {
        if (queue) {
          const wasUnassigned = isUnassignedChat(thread);
          const updateThreadPayload = {
            groupIds: groupIds.map(String),
            type: ChatType.Queued,
            queuePosition: queue.position,
            queueWaitingTime: queue.wait_time,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            queuedAtInMs: getThreadEntityTimestampInMs({ created_at: queue.queued_at }),
          };

          // unfollow chat if it's being transferred and lands on queued chats
          chatFollowManager.unfollowChat(chatId);

          store.dispatch(
            ChatsEntitiesActions.updateChatThread({
              threadId,
              thread: updateThreadPayload,
            }),
          );
          store.dispatch(ChatsViewActions.triggerChatNotification({ thread: { ...thread, ...updateThreadPayload } }));

          if (wasUnassigned) {
            store.dispatch(
              ChatsEntitiesActions.updateUnassignedChatsCount({
                threadId: thread.threadId,
                type: 'decrease',
              }),
            );
          }
        } else {
          store.dispatch(
            ChatsEntitiesActions.updateChatThread({
              threadId,
              thread: {
                groupIds: groupIds.map(String),
                type: ChatType.Unassigned,
                visualStatus: null,
              },
            }),
          );

          const unassignedChatIds = getUnassignedIds(store.getState());
          const isAlreadyInUnassignedChats = unassignedChatIds.includes(threadId);

          if (!isAlreadyInUnassignedChats) {
            store.dispatch(
              ChatsEntitiesActions.updateUnassignedChatsCount({
                threadId: thread.threadId,
                type: 'increase',
              }),
            );
          }
        }
      } else {
        if (wasSupervised) {
          store.dispatch(
            ChatsViewActions.stopSupervising({ threadId, shouldMoveToOtherChats: isTransferredOutsideAvailableGroups }),
          );
        }
        // remove the thread we don't have access to
        // such thread will not receive any updates from api anyway
        // and the transfer back to us will be handled by incoming_chat
        store.dispatch(
          ChatsEntitiesActions.removeChatThread({
            threadId,
          }),
        );

        const wasUnassigned = isUnassignedChat(thread);
        if (wasUnassigned) {
          store.dispatch(
            ChatsEntitiesActions.updateUnassignedChatsCount({
              threadId: thread.threadId,
              type: 'decrease',
            }),
          );
        }

        // also remove the customer from the list, as we don't have access to him
        const { customerId } = thread;
        store.dispatch(CustomerActions.removeCustomer({ customerId }));
      }
    }

    /**
     * 2. Agent is assigned.
     * The chat is removed from the list if transferred by current agent to someone else.
     * Or it's closed if taken over by other agent.
     */
    if (isAgentAssigned) {
      const [transferredToAgentWithId] = agentIds;
      const currentAgentLogin = getLoggedInAgentLogin(state);
      const isTransferredToOtherAgent = currentAgentLogin !== transferredToAgentWithId;
      const currentAgentRequestedTransfer = currentAgentLogin === requesterId;

      if (isTransferredToOtherAgent && currentAgentRequestedTransfer) {
        const selectedThreadId = getSelectedThreadId(state);
        const isOnChats = getIsOnSection(state, Section.Chats, false);
        const isTransferredChatCurrentlyVisible = isOnChats && selectedThreadId === threadId;

        if (isTransferredChatCurrentlyVisible) {
          store.dispatch(ChatsViewActions.navigateToNextAvailableChat({ threadId }));
        }

        store.dispatch(
          ChatsEntitiesActions.updateChatThread({
            threadId,
            thread: {
              currentAgentId: transferredToAgentWithId,
              groupIds: groupIds.map(String),
              type: ChatType.Other,
            },
          }),
        );

        store.dispatch(ChatsViewActions.unmarkChatThreadIdsAsNew({ threadId }));
      } else {
        const wasMine = isMyChat(thread);
        const isChatTakenOver = wasMine && isTransferredToOtherAgent;

        if (isChatTakenOver) {
          store.dispatch(
            ChatsEntitiesActions.updateChatThread({
              threadId,
              thread: {
                currentAgentId: transferredToAgentWithId,
                type: ChatType.Other,
                visualStatus: ChatThreadVisualStatus.MyChatVisuallyClosed,
                ...(isGroupAssigned && { groupIds: groupIds.map(String) }),
              },
            }),
          );
        } else {
          const shouldCloseChat = isTransferredOutsideAvailableGroups;
          store.dispatch(
            ChatsEntitiesActions.updateChatThread({
              threadId,
              thread: {
                currentAgentId: transferredToAgentWithId,
                ...(isGroupAssigned && { groupIds: groupIds.map(String) }),
                ...(shouldCloseChat && { status: ChatThreadStatus.Closed }), // todo: research - how to achieve this case? should we close only visually?
              },
            }),
          );
        }

        if (wasSupervised && isTransferredOutsideAvailableGroups) {
          store.dispatch(
            ChatsViewActions.stopSupervising({ threadId, shouldMoveToOtherChats: isTransferredOutsideAvailableGroups }),
          );
        }
      }
    }
  }
}

function handleUpdateCustomerGroups(
  store: IAppState,
  customerId: string,
  currentCustomerGroups: GroupId[],
  newCustomerGroups: GroupId[],
): void {
  const groupsChanged = !areArraysEqualRegardlessOfOrder(currentCustomerGroups, newCustomerGroups);
  const customer = getCustomer(store.getState(), customerId);

  if (groupsChanged && customer) {
    store.dispatch(
      CustomerActions.updateCustomer({
        customer: {
          ...customer,
          groupIds: newCustomerGroups,
        },
      }),
    );
  }
}
