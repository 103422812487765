import { useCallback, useMemo, useEffect, useRef } from 'react';

import { SideNavigation, SideNavigationGroup } from '@livechat/design-system-react-components';
import isEqual from 'lodash.isequal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SideNavigationItemLabelWithBadge } from 'components/side-navigation-v2/components/SideNavigationItemLabelWithBadge';
import { SideNavigationItemWithRightNode } from 'components/side-navigation-v2/components/SideNavigationItemWithRightNode';
import type { ReportType } from 'constants/reports/report-type';
import { EventPlace } from 'helpers/analytics';
import {
  getAdditionalReportTrackingProps,
  getCurrentReportTrackingDetails,
  trackReportAccessed,
} from 'helpers/analytics-reports';
import { filterItemsByCustomCheck } from 'helpers/side-navigation';
import { useFetchFeatures } from 'hooks/api/feature-control/use-fetch-features';
import { useParamSelector } from 'hooks/use-param-selector';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';
import { REPORTS_MENU_TEST_ID } from 'routes/reports/constants';
import { getNavigationItems } from 'routes/reports/helpers';
import { trackEvent } from 'services/event-tracking';
import { getIsInsightsInstalled } from 'store/entities/applications/selectors';
import { BadgeKey } from 'store/entities/badges/interfaces';
import { getBadge } from 'store/entities/badges/selectors';
import { selectIsDisableNativeTicketsExperiment } from 'store/entities/experiments/selectors';
import { getCanAccessReports, getCanExportReports, isNormalAgent } from 'store/features/session/selectors';
import { ReportsViewActions } from 'store/views/reports/actions';
import { benchmarkAvailable, getBenchmarkEnabled, getCurrentView } from 'store/views/reports/selectors';

import { ReportsSideNavigationItem } from './ReportsSideNavigationItem';

export const ReportsSideNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const previousActiveItemRef = useRef<ISideNavigationItem | undefined>(undefined);
  const currentView = useSelector(getCurrentView);
  const benchmarkEnabled = useSelector(getBenchmarkEnabled);
  const hasReportsAccess = useSelector(getCanAccessReports);
  const isInsightsInstalled = useSelector(getIsInsightsInstalled);
  const overviewBadgeCount = useParamSelector(getBadge, BadgeKey.InsightsOverview);
  const recentInsightsBadgeCount = useParamSelector(getBadge, BadgeKey.InsightsRecentInsights);
  const topCustomerQuestionsBadgeCount = useParamSelector(getBadge, BadgeKey.InsightsTopCustomerQuestions);
  const { isFetched: featuresAreFetched } = useFetchFeatures();

  const canExportData = useSelector((state) => getCanExportReports(state) && !isNormalAgent(state));
  const areNativeTicketsDisabled = useSelector(selectIsDisableNativeTicketsExperiment);
  const dispatch = useDispatch();
  const handleCurrentViewChange = useCallback(
    (view: ReportType): void => {
      dispatch(ReportsViewActions.setCurrentView(view));
    },
    [dispatch],
  );
  const items = useMemo(
    () => filterItemsByCustomCheck(getNavigationItems(canExportData, hasReportsAccess, areNativeTicketsDisabled)),
    [
      canExportData,
      hasReportsAccess,
      areNativeTicketsDisabled,

      // re-render Insights menu items when feature flag or badge count change
      isInsightsInstalled,
      featuresAreFetched,
      overviewBadgeCount,
      recentInsightsBadgeCount,
      topCustomerQuestionsBadgeCount,
    ],
  );

  const onActiveItemUpdated = useCallback(
    (item: ISideNavigationItem): void => {
      if (!item.id) {
        return;
      }

      const reportDetails = getCurrentReportTrackingDetails(item.id);
      const currentViewDetails = getCurrentReportTrackingDetails(currentView);
      const isBenchmarkAvailable = benchmarkAvailable.includes(item.id) ? benchmarkEnabled : 'not-available';

      if (item.id === 'helpdesk') {
        trackEvent('HelpDesk reports clicked', EventPlace.Reports);
      }

      if (reportDetails && currentViewDetails) {
        const trackingProps = getAdditionalReportTrackingProps(item.id);
        trackReportAccessed(reportDetails.category, reportDetails.name, isBenchmarkAvailable, null, trackingProps);
      }
      handleCurrentViewChange(item.id as ReportType);
    },
    [currentView, benchmarkEnabled, handleCurrentViewChange],
  );

  const checkItemActive = useCallback((itemPath: string | undefined) => itemPath === pathname, [pathname]);

  const findActiveItem = useCallback(
    (items: ISideNavigationItem[]): ISideNavigationItem | undefined => {
      for (const item of items) {
        if (checkItemActive(item.path)) {
          return item;
        }
        if (item.children) {
          const activeChild = findActiveItem(item.children);
          if (activeChild) {
            return activeChild;
          }
        }
      }

      return undefined;
    },
    [checkItemActive],
  );

  useEffect(() => {
    const activeItem = findActiveItem(items);
    if (activeItem && !isEqual(activeItem, previousActiveItemRef.current)) {
      onActiveItemUpdated(activeItem);
      previousActiveItemRef.current = activeItem;
    }
  }, [items, findActiveItem, onActiveItemUpdated, pathname]);

  const renderNavigationItem = useCallback(
    (item: ISideNavigationItem, isMainEntry = false) => {
      const isActive =
        checkItemActive(item.path) || item.children?.some((child) => checkItemActive(child.path)) || false;

      if (item.children && item.children.length > 0) {
        return (
          <SideNavigationGroup
            key={item.id}
            label={(isOpen) => <SideNavigationItemLabelWithBadge isActive item={item} isListOpen={isOpen} />}
            rightNode={(isOpen) => <SideNavigationItemWithRightNode item={item} isListOpen={isOpen} />}
            isCollapsible
            onItemHover={() => item.onItemHover}
          >
            {item.children.map((subItem) => renderNavigationItem(subItem))}
          </SideNavigationGroup>
        );
      }

      return <ReportsSideNavigationItem key={item.id} item={item} isMainEntry={isMainEntry} isActive={isActive} />;
    },
    [
      checkItemActive,

      // re-render Insights menu items when feature flag or badge count change
      featuresAreFetched,
      overviewBadgeCount,
      recentInsightsBadgeCount,
      topCustomerQuestionsBadgeCount,
    ],
  );

  const navigationItems = useMemo(
    () => items.filter((item) => !item.hidden).map((item) => renderNavigationItem(item, true)),
    [items, renderNavigationItem],
  );

  if (!hasReportsAccess) {
    return null;
  }

  return (
    <SideNavigation title="Reports" noGaps data-testid={REPORTS_MENU_TEST_ID}>
      {navigationItems}
    </SideNavigation>
  );
};
