import { type KnowledgeSourceItem, KnowledgeSourceItemStatus } from 'interfaces/knowledge-sources';

export const filterActiveKnowledgeSources = (items: KnowledgeSourceItem[]): KnowledgeSourceItem[] => {
  const failedAddSources =
    items
      ?.filter(({ status, learnedAt }) => status === KnowledgeSourceItemStatus.FAILED && !learnedAt)
      .map(({ id }) => id) ?? [];

  return items?.filter((item) => !failedAddSources.includes(item.id));
};
