// @ts-strict-ignore
import { VisitorState } from 'constants/visitor-state';
import { getMostRelevantThreadByCustomerId } from 'store/entities/chats/computed';
import type { IWithChatsEntityState } from 'store/entities/chats/selectors';
import { calculateCustomerActivity } from 'store/entities/customers/computed';
import { type ICustomer } from 'store/entities/customers/interfaces';
import {
  customerExists,
  getCustomer,
  isCustomerRemoved,
  type IWithCustomersEntityState,
} from 'store/entities/customers/selectors';

export function shouldNotifyAboutCustomer(
  state: IWithChatsEntityState & IWithCustomersEntityState,
  customer: ICustomer
): boolean {
  const isExistingCustomer = customerExists(state, customer.id) && !isCustomerRemoved(state, customer.id);
  const customerInState = isExistingCustomer ? getCustomer(state, customer.id) : null;
  const thread = getMostRelevantThreadByCustomerId(state, customer.id);
  const activity = customerInState ? calculateCustomerActivity(customerInState, thread) : null;

  return !activity || activity === VisitorState.LeftWebsite;
}
