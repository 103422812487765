// @ts-strict-ignore
export interface IPerformActionPayload {
  integrationAppId: string;
  integrationButtonId: string;
  threadId: string;
  source: ActionPayloadSource;
  retry?: number;
}

export enum ActionPayloadSource {
  Chats = 'chats',
  Customers = 'customers',
  Archives = 'archives',
  Tickets = 'tickets',
}

export interface IActionPayload {
  /* eslint-disable @typescript-eslint/naming-convention */
  licence_id: string;
  agent_id: string;
  chat_id?: string;
  thread_id?: string;
  customer_id?: string;
  customer_name?: string;
  customer_email?: string;
  customer_host?: string;
  customer_ip?: string;
  customer_latitude?: string;
  customer_longitude?: string;
  customer_country?: string;
  customer_region?: string;
  customer_city?: string;
  customer_timezone?: string;
  time: string;
  instance_id: string;
  source: ActionPayloadSource;
  custom_variable_keys?: string;
  [customVariableKey: string]: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export interface IArchivesActionPayload extends IActionPayload {
  ticket: string;
  requestID: string;
}
