import type { SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { getIsInsightsInstalled } from 'store/entities/applications/selectors';

import { CRUDAction, RequestAction } from '../actions';
import { ApplicationsActionNames } from '../applications/actions';

import { BadgeActions } from './actions';
import { BadgeKey } from './interfaces';

function* fetchUnreadInsights(): SagaIterator {
  const isInsightsInstalled = yield select(getIsInsightsInstalled);
  if (!isInsightsInstalled) {
    return;
  }

  const api = ApiManager.insightsApi;
  const { result }: RequestResult<{ overview: number; recentInsights: number; topCustomerQuestions: number }> =
    yield call(api.fetchUnread);
  if (result) {
    const { overview, recentInsights, topCustomerQuestions } = result;
    yield put(BadgeActions.setBadge(BadgeKey.InsightsOverview, overview));
    yield put(BadgeActions.setBadge(BadgeKey.InsightsRecentInsights, recentInsights));
    yield put(BadgeActions.setBadge(BadgeKey.InsightsTopCustomerQuestions, topCustomerQuestions));
  }
}

export function* badgesSagas(): SagaIterator {
  yield take(ApplicationsActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]);
  yield call(fetchUnreadInsights);
}
