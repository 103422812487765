// @ts-strict-ignore
import { format } from 'date-fns';
import identity from 'lodash.identity';

import { DateFormat } from 'constants/date';
import { LogicOperator } from 'constants/logic-operator';
import { getIsFeatureEnabled } from 'helpers/feature-flags';
import { JSONParse } from 'helpers/json';
import { sumReducer } from 'helpers/numbers';
import { getSeriesDataFromPeriod } from 'helpers/reports';
import { CustomerCustomVariableName } from 'store/entities/customers/interfaces';
import {
  AnyValueOperator,
  type ApiCondition,
  type ApiGreetingRuleType,
  type ApiOperator,
  type ApiStringOperator,
  type GreetingRule,
  GreetingRuleType,
  GreetingVisitorType,
  type IApiFunnelUrl,
  type IApiGreeting,
  type IApiGreetingRule,
  type IGreeting,
  type IGreetingAdsTrafficRule,
  type IGreetingCheckStringRule,
  type IGreetingFunnelUrl,
  type IGreetingMeetValueRule,
  type IGreetingOwnVariableRule,
  type IGreetingUrlsFunnelRule,
  type IRichGreeting,
  StringOperator,
  TargetedMessageSubtype,
  type IGreetingExitIntentRule,
} from 'store/entities/greetings/interfaces';
import { getRandomString } from 'store/views/chats/helpers/sagas';

import { FeatureNames } from '../feature-control/interfaces';

function getRuleType(data: IApiGreetingRule): GreetingRuleType {
  switch (data.type) {
    case 'custom_variable':
      return GreetingRuleType.CustomVariable;
    case 'url_funnel':
      return GreetingRuleType.VisitedPagesOrder;
    case 'visit_time_site':
      return GreetingRuleType.BrowsingTime;
    case 'url_current':
      return GreetingRuleType.CurrentPageUrl;
    case 'url_visited':
      return GreetingRuleType.VisitedPage;
    case 'pages_view_number':
      return GreetingRuleType.VisitedPagesNumber;
    case 'url_referrer':
      return GreetingRuleType.ReferrerUrl;
    case 'geolocation':
      return GreetingRuleType.Location;
    case 'ads_traffic':
      return GreetingRuleType.AdsTraffic;
    default:
      return null;
  }
}

function getRuleStringOperator(data: ApiStringOperator): StringOperator {
  switch (data) {
    case 'doesnt_equal':
      return StringOperator.NotEqual;
    case 'contains':
      return StringOperator.Contains;
    case 'doesnt_contain':
      return StringOperator.DoesNotContain;
    default:
      return StringOperator.Equal;
  }
}

function getRuleAnyValueOperator(data: ApiOperator): AnyValueOperator {
  switch (data) {
    case 'doesnt_equal':
      return AnyValueOperator.NotEqual;
    case 'contains':
      return AnyValueOperator.Contains;
    case 'doesnt_contain':
      return AnyValueOperator.DoesNotContain;
    case 'lower_than':
      return AnyValueOperator.LowerThan;
    case 'lower_or_equal':
      return AnyValueOperator.LowerOrEqual;
    case 'greater_than':
      return AnyValueOperator.GreaterThan;
    case 'greater_or_equal':
      return AnyValueOperator.GreaterOrEqual;
    default:
      return AnyValueOperator.Equal;
  }
}

function getFunnelUrl(apiUrl: IApiFunnelUrl): IGreetingFunnelUrl {
  const { url, operator } = apiUrl;

  return {
    url,
    operator: getRuleStringOperator(operator),
    id: getRandomString(),
  };
}

function getRule(data: IApiGreetingRule): GreetingRule {
  const { id } = data;
  const typeRule = getRuleType(data);

  if (!typeRule) {
    // legacy, currently no such rules exist in the frontend: 'visits_number', 'visit_time_page', 'search_keyword'
    return null;
  }
  const isIntentEnabled = getIsFeatureEnabled(FeatureNames.CustomerIntentEnabled);

  const type =
    typeRule === GreetingRuleType.CustomVariable &&
    data.variable_name == (CustomerCustomVariableName.Intent as string) &&
    isIntentEnabled
      ? GreetingRuleType.Intent
      : typeRule;

  const ruleBase: GreetingRule = { id: id.toString(), type };

  switch (ruleBase.type) {
    case GreetingRuleType.BrowsingTime:
    case GreetingRuleType.VisitedPagesNumber: {
      const rule: IGreetingMeetValueRule<number> = { ...ruleBase, value: +data.value };

      return rule;
    }

    case GreetingRuleType.CurrentPageUrl:
    case GreetingRuleType.VisitedPage:
    case GreetingRuleType.ReferrerUrl: {
      const rule: IGreetingCheckStringRule = {
        ...ruleBase,
        value: data.value,
        operator: getRuleStringOperator(data.operator as ApiStringOperator),
      };

      return rule;
    }

    case GreetingRuleType.Location: {
      const rule: IGreetingMeetValueRule<string> = {
        ...ruleBase,
        value: data.value,
      };

      return rule;
    }

    case GreetingRuleType.VisitedPagesOrder: {
      const urlsFunnel = data.urls.map(getFunnelUrl);
      const rule: IGreetingUrlsFunnelRule = { ...ruleBase, urlsFunnel };

      return rule;
    }

    case GreetingRuleType.CustomVariable: {
      const rule: IGreetingOwnVariableRule = {
        ...ruleBase,
        value: data.variable_value,
        variableName: data.variable_name,
        operator: getRuleAnyValueOperator(data.operator),
      };

      return rule;
    }

    case GreetingRuleType.AdsTraffic: {
      const rule: IGreetingAdsTrafficRule = {
        ...ruleBase,
        variableName: data.variable_name,
      };

      return rule;
    }

    case GreetingRuleType.Intent: {
      const rule: IGreetingOwnVariableRule = {
        ...ruleBase,
        value: data.variable_value,
        variableName: data.variable_name,
        operator: getRuleAnyValueOperator(data.operator),
      };

      return rule;
    }
    default:
      return ruleBase;
  }
}

function getRules(rules: IApiGreetingRule[]): GreetingRule[] {
  return rules.map(getRule).filter(identity); // filter out nulls (legacy rules)
}

function getLogicOperator(rules: IApiGreetingRule[]): LogicOperator {
  // check if there is at least one 'or' condition and assume all of them are 'or'
  // with the exception of the 'visits_number' (which is always 'and')
  // we could potentially have some 'or' conditions and some 'and' conditions simultaneously
  // but currently we are not using greetings this way
  return rules.some((rule) => rule.condition === 'or') ? LogicOperator.Or : LogicOperator.And;
}

function getTimeOnPage(rules: IApiGreetingRule[]): number {
  const timeOnPageRule = rules.find((rule) => rule.type === 'visit_time_page');

  // we fallback to 0 because there must be some time on page shown to the user
  return timeOnPageRule ? +timeOnPageRule.value : 0;
}

function getVisitorType(rules: IApiGreetingRule[]): GreetingVisitorType | null {
  const visitsNumberRule = rules.find((rule) => rule.type === 'visits_number');
  if (!visitsNumberRule) {
    return GreetingVisitorType.All;
  }

  const visistsNumber = Number(visitsNumberRule.value);
  if (Number.isNaN(visistsNumber)) {
    return GreetingVisitorType.All;
  }

  switch (visitsNumberRule.operator) {
    case 'equals':
      if (visistsNumber < 2) {
        // visits_number === 0, 1
        return GreetingVisitorType.FirstTime;
      }

      // visits_number === 2, 3, 4, ..., Inf+
      return GreetingVisitorType.Returning;

    case 'greater_than':
      if (visistsNumber === 0) {
        // visits_number > 0
        return GreetingVisitorType.All;
      }

      // visits_number > 1, 2, 3, ..., Inf+
      return GreetingVisitorType.Returning;

    case 'greater_or_equal':
      if (visistsNumber < 2) {
        // visits_number >= 0, 1
        return GreetingVisitorType.All;
      }

      // visits_number >= 2, 3, 4, ..., Inf+
      return GreetingVisitorType.Returning;

    case 'lower_than':
      if (visistsNumber < 3) {
        // visits_number < 0, 1, 2
        return GreetingVisitorType.FirstTime;
      }

      // visits_number < 3, 4, 5, ..., Inf+
      return GreetingVisitorType.All;

    case 'lower_or_equal':
      if (visistsNumber < 2) {
        // visits_number <= 0, 1
        return GreetingVisitorType.FirstTime;
      }

      // visits_number <= 2, 3, 4, ..., Inf+
      return GreetingVisitorType.All;

    default:
      return GreetingVisitorType.All;
  }
}

export function deserializeGreeting(data: IApiGreeting): IGreeting {
  const { id, name, group, rules: dataRules, properties, active } = data;
  const message = properties['greeting-message_text'];
  const isActive = !!active;
  const rules = getRules(dataRules);
  const rulesLogicOperator = getLogicOperator(dataRules);
  const visitorType = getVisitorType(dataRules) || GreetingVisitorType.All;
  const timeOnPage = getTimeOnPage(dataRules);
  const richGreetingData = properties['greeting-message_json']
    ? JSONParse<IRichGreeting>(properties['greeting-message_json'])
    : null;
  const addon = properties.addon || null;
  const subtype = properties.subtype || TargetedMessageSubtype.Greeting;
  const activeUntil = properties.active_until || null;
  const activeFrom = properties.active_from || null;

  // '0' is default value always returned by API, so we recognize the rule set to false as empty string instead
  if (properties.is_exit_intent === '1' || properties.is_exit_intent === '') {
    rules.push({
      id: getRandomString(),
      type: GreetingRuleType.ExitIntent,
      value: properties.is_exit_intent === '1',
    } as IGreetingExitIntentRule);
  }

  return {
    id: id.toString(),
    name,
    groupId: group.toString(),
    message,
    isActive,
    visitorType,
    timeOnPage,
    rules,
    rulesLogicOperator,
    richGreetingData,
    addon,
    subtype,
    activeUntil,
    activeFrom,
  };
}

function serializeRuleType(ruleType: GreetingRuleType): ApiGreetingRuleType {
  // 'visit_time_page' and 'visits_number' are not returned here because they are handled in a different way
  // also, we skip 'search_keyword' which is deprecated
  switch (ruleType) {
    case GreetingRuleType.CustomVariable:
      return 'custom_variable';
    case GreetingRuleType.VisitedPagesOrder:
      return 'url_funnel';
    case GreetingRuleType.BrowsingTime:
      return 'visit_time_site';
    case GreetingRuleType.CurrentPageUrl:
      return 'url_current';
    case GreetingRuleType.VisitedPage:
      return 'url_visited';
    case GreetingRuleType.VisitedPagesNumber:
      return 'pages_view_number';
    case GreetingRuleType.ReferrerUrl:
      return 'url_referrer';
    case GreetingRuleType.Location:
      return 'geolocation';
    case GreetingRuleType.AdsTraffic:
      return 'ads_traffic';
    case GreetingRuleType.Intent:
      return 'custom_variable';
    default:
      return null;
  }
}

function serializeStringOperator(operator: StringOperator): ApiStringOperator {
  switch (operator) {
    case StringOperator.NotEqual:
      return 'doesnt_equal';
    case StringOperator.Contains:
      return 'contains';
    case StringOperator.DoesNotContain:
      return 'doesnt_contain';
    default:
      return 'equals';
  }
}

function serializeOperator(operator: AnyValueOperator): ApiOperator {
  switch (operator) {
    case AnyValueOperator.NotEqual:
      return 'doesnt_equal';
    case AnyValueOperator.Contains:
      return 'contains';
    case AnyValueOperator.DoesNotContain:
      return 'doesnt_contain';
    case AnyValueOperator.LowerThan:
      return 'lower_than';
    case AnyValueOperator.LowerOrEqual:
      return 'lower_or_equal';
    case AnyValueOperator.GreaterThan:
      return 'greater_than';
    case AnyValueOperator.GreaterOrEqual:
      return 'greater_or_equal';
    default:
      return 'equals';
  }
}

function serializeCondition(operator: LogicOperator): ApiCondition {
  return operator === LogicOperator.Or ? 'or' : 'and';
}

function operatorFromRule(rule: GreetingRule): ApiOperator {
  switch (rule.type) {
    case GreetingRuleType.BrowsingTime:
    case GreetingRuleType.VisitedPagesNumber:
      return serializeOperator(AnyValueOperator.GreaterOrEqual);
    case GreetingRuleType.CurrentPageUrl:
    case GreetingRuleType.VisitedPage:
    case GreetingRuleType.ReferrerUrl:
      return serializeStringOperator((rule as IGreetingCheckStringRule).operator);
    case GreetingRuleType.CustomVariable:
      return serializeOperator((rule as IGreetingOwnVariableRule).operator);
    case GreetingRuleType.Intent:
      return serializeOperator((rule as IGreetingOwnVariableRule).operator);
    default:
      // no operator for VisitedPagesOrder, Location
      return null;
  }
}

function valueFromRule(rule: GreetingRule): string {
  switch (rule.type) {
    case GreetingRuleType.BrowsingTime:
    case GreetingRuleType.VisitedPagesNumber:
      return (rule as IGreetingMeetValueRule<number>).value.toString();
    case GreetingRuleType.CurrentPageUrl:
    case GreetingRuleType.VisitedPage:
    case GreetingRuleType.ReferrerUrl: {
      return (rule as IGreetingCheckStringRule).value;
    }
    case GreetingRuleType.Location:
      return (rule as IGreetingMeetValueRule<string>).value;
    case GreetingRuleType.CustomVariable:
      return (rule as IGreetingOwnVariableRule).value;
    case GreetingRuleType.Intent:
      return (rule as IGreetingOwnVariableRule).value;
    default:
      // no value for VisitedPagesOrder, the values are within urls funnel
      return null;
  }
}

function serializeFunnelUrl(funnelUrl: IGreetingFunnelUrl): IApiFunnelUrl {
  const { url, operator } = funnelUrl;

  return {
    url,
    operator: serializeStringOperator(operator),
  };
}

function serializeRule(rule: GreetingRule, logicOperator: LogicOperator): IApiGreetingRule {
  const { type: ruleType } = rule;
  const type = serializeRuleType(ruleType);
  const condition = serializeCondition(logicOperator);
  const operator = operatorFromRule(rule);
  const value = valueFromRule(rule);
  const apiRule: IApiGreetingRule = {
    type,
    condition,
  };

  if (ruleType === GreetingRuleType.CustomVariable || ruleType === GreetingRuleType.Intent) {
    const ownVariableRule = rule as IGreetingOwnVariableRule;
    const { variableName } = ownVariableRule;

    return {
      variable_name: variableName,
      variable_value: value,
      operator,
      ...apiRule,
    };
  }

  if (ruleType === GreetingRuleType.VisitedPagesOrder) {
    const urlsFunnelRule = rule as IGreetingUrlsFunnelRule;
    const { urlsFunnel } = urlsFunnelRule;
    const urls = urlsFunnel.map(serializeFunnelUrl);

    return {
      urls,
      ...apiRule,
    };
  }

  if (ruleType === GreetingRuleType.AdsTraffic) {
    const adsTrafficRule = rule as IGreetingAdsTrafficRule;
    const { variableName } = adsTrafficRule;

    return {
      variable_name: variableName === 'all' ? null : variableName,
      ...apiRule,
    };
  }

  if (operator !== null) {
    apiRule.operator = operator;
  }

  if (value !== null) {
    apiRule.value = value;
  }

  return apiRule;
}

function serializeRules(greeting: IGreeting): IApiGreetingRule[] {
  const { rules, timeOnPage, visitorType, rulesLogicOperator } = greeting;
  const optionalRules = rules.map((rule) => serializeRule(rule, rulesLogicOperator));
  // both "time on page" and "visitor type" are obligatory on UX side but can be filtered out from the api rules list
  const timeOnPageRule: IApiGreetingRule = {
    type: 'visit_time_page',
    value: timeOnPage.toString(),
    condition: serializeCondition(LogicOperator.And), // time on page should be always AND
    operator: serializeOperator(AnyValueOperator.GreaterOrEqual),
  };

  const visitorTypeRule: IApiGreetingRule = {
    type: 'visits_number',
    value: visitorType === GreetingVisitorType.FirstTime ? '1' : '2',
    condition: serializeCondition(LogicOperator.And), // visitor type cannot be grouped with other rules and its conditon must be met
    operator: serializeOperator(
      visitorType === GreetingVisitorType.FirstTime ? AnyValueOperator.Equal : AnyValueOperator.GreaterOrEqual,
    ),
  };

  const apiRules = [
    timeOnPage > 0 && timeOnPageRule,
    visitorType !== GreetingVisitorType.All && visitorTypeRule,
    ...optionalRules,
  ].filter((rule) => !!rule);

  // at least one rule must be sent, otherwise the greeting will not work
  if (apiRules.length === 0) {
    // sending "time on page" with value "0 seconds" so the greeting will show up immediately
    return [timeOnPageRule];
  }

  return apiRules;
}

export function serializeGreeting(greeting: IGreeting, { omitNotUpdatable = false } = {}): IApiGreeting {
  const { id, name, groupId, message, isActive, richGreetingData, addon, subtype, activeUntil, activeFrom, rules } =
    greeting;

  const exitIntentRule = rules.find((rule) => rule.type === GreetingRuleType.ExitIntent) as IGreetingExitIntentRule;
  const otherRules = rules.filter((rule) => rule.type !== GreetingRuleType.ExitIntent);

  const serializedRules = serializeRules({
    ...greeting,
    rules: otherRules,
  });

  const apiGreeting: IApiGreeting = {
    name,
    group: +groupId,
    active: greeting.subtype === TargetedMessageSubtype.Announcement || isActive ? 1 : 0, // must be number for some reason
    properties: {
      addon: addon || null,
      'greeting-message_text': message,
      'greeting-message_json': richGreetingData ? JSON.stringify(richGreetingData) : null,
      ...(subtype === TargetedMessageSubtype.Announcement && { subtype }),
      ...(!!activeUntil && { active_until: format(activeUntil, DateFormat.ISO8601DateTime) }),
      ...(!!activeFrom && { active_from: format(activeFrom, DateFormat.ISO8601DateTime) }),
    },
    rules: serializedRules,
  };

  // when setting customer's activity, 1 and "" correspond to exit intent and 'actively browsing' respectively
  // to properly remove the customer activity condition, we need to set is_exit_intent to false
  if (exitIntentRule) {
    apiGreeting.properties.is_exit_intent = exitIntentRule.value ? 1 : '';
  } else {
    apiGreeting.properties.is_exit_intent = false;
  }

  if (omitNotUpdatable) {
    delete apiGreeting.properties.subtype;
  }

  if (id) {
    return { id: +id, ...apiGreeting };
  }

  return apiGreeting;
}

export function combineGreetingsWithReportData(greetings: IGreeting[], greetingsConversion: any): IGreeting[] {
  return greetings.map((greeting) => {
    const displayedObject = getSeriesDataFromPeriod<number>(greetingsConversion, greeting.id, 'displayed');
    const acceptedObject = getSeriesDataFromPeriod<number>(greetingsConversion, greeting.id, 'accepted');

    const displayedCount = displayedObject.reduce(sumReducer, 0);
    const acceptedCount = acceptedObject.reduce(sumReducer, 0);

    return {
      ...greeting,
      displayedCount,
      acceptedCount,
      conversionValue: acceptedCount / displayedCount,
    };
  });
}

export function combineGreetingsWithPreviousReportData(
  greetings: IGreeting[],
  previousGreetings: IGreeting[],
): IGreeting[] {
  return greetings.map((greeting) => {
    const previousGreeting = previousGreetings.find((previous) => previous.id === greeting.id);
    if (previousGreeting) {
      return {
        ...greeting,
        displayedCount: previousGreeting.displayedCount,
        acceptedCount: previousGreeting.acceptedCount,
        conversionValue: previousGreeting.conversionValue,
      };
    }

    return greeting;
  });
}
