import { type MouseEventHandler, type FC } from 'react';

import { Button, Display, Heading, Text } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ThemeInverter } from 'components/theme-helpers/ThemeInverter';
import { AutomateEvent } from 'constants/automate/constants';
import { NavigationId, NavigationPath } from 'constants/navigation';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { NavigationViewActions } from 'store/views/navigation/actions';

import * as styles from './styles';

const AUTOMATE_PROMO_IMAGE_URL =
  'https://cdn.livechat-static.com/api/file/lc/img/automate/automate-promo-tooltip-image.mp4';

export const AutomateTooltip: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleExploreClick: MouseEventHandler = () => {
    navigate(NavigationPath.Automate);
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: NavigationId.Automate }));
    trackEvent(AutomateEvent.TooltipExploreClicked, EventPlace.Tooltip);
  };
  const handleDismissClick: MouseEventHandler = () => {
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: NavigationId.Automate }));
    trackEvent(AutomateEvent.TooltipDismissed, EventPlace.Tooltip);
  };

  return (
    <Display>
      <Display className={styles.image}>
        <video src={AUTOMATE_PROMO_IMAGE_URL} playsInline autoPlay muted loop className={styles.video} />
      </Display>
      <Heading size="xs">Introducing Automate</Heading>
      <Text>
        Now all your AI-powered features are in one place. Automate routine tasks and free up more time to build
        stronger customer relationships.
      </Text>
      <ThemeInverter shouldBeInverted={true}>
        <Display className={styles.buttons}>
          <Button size="compact" kind="high-contrast" onClick={handleExploreClick}>
            Explore
          </Button>
          <Button size="compact" kind="plain" onClick={handleDismissClick}>
            Remind me later
          </Button>
        </Display>
      </ThemeInverter>
    </Display>
  );
};
