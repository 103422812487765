import { type ActionsUnion, createAction } from 'store/helper';

import type {
  IShowNavigationItemTooltipPayload,
  IHideNavigationItemTooltipPayload,
  ISetNavigationItemBadgePayload,
  IClearNavigationItemBadgePayload,
} from './interfaces';

export enum NavigationViewActionsNames {
  SHOW_ITEM_TOOLTIP = 'VIEW/MAIN_NAVIGATION/SHOW_ITEM_TOOLTIP',
  HIDE_ITEM_TOOLTIP = 'VIEW/MAIN_NAVIGATION/HIDE_ITEM_TOOLTIP',
  SET_ITEM_BADGE = 'VIEW/MAIN_NAVIGATION/SET_ITEM_BADGE',
  CLEAR_ITEM_BADGE = 'VIEW/MAIN_NAVIGATION/CLEAR_ITEM_BADGE',
  OPEN_MOBILE_NAVIGATION = 'VIEW/MAIN_NAVIGATION/OPEN_MOBILE_NAVIGATION',
  CLOSE_MOBILE_NAVIGATION = 'VIEW/MAIN_NAVIGATION/CLOSE_MOBILE_NAVIGATION',
  VISIT_MARKETPLACE = 'VIEW/APPS_NAVIGATION/VISIT_MARKETPLACE',
}

export const NavigationViewActions = {
  showNavigationItemTooltip: (notificationData: IShowNavigationItemTooltipPayload) =>
    createAction(NavigationViewActionsNames.SHOW_ITEM_TOOLTIP, notificationData),
  hideNavigationItemTooltip: (notificationData: IHideNavigationItemTooltipPayload) =>
    createAction(NavigationViewActionsNames.HIDE_ITEM_TOOLTIP, notificationData),
  setNavigationItemBadge: (payload: ISetNavigationItemBadgePayload) =>
    createAction(NavigationViewActionsNames.SET_ITEM_BADGE, payload),
  clearNavigationItemBadge: (payload: IClearNavigationItemBadgePayload) =>
    createAction(NavigationViewActionsNames.CLEAR_ITEM_BADGE, payload),
  openMobileNavigation: () => createAction(NavigationViewActionsNames.OPEN_MOBILE_NAVIGATION),
  closeMobileNavigation: () => createAction(NavigationViewActionsNames.CLOSE_MOBILE_NAVIGATION),
  visitMarketplace: () => createAction(NavigationViewActionsNames.VISIT_MARKETPLACE),
};

export type NavigationViewAction = ActionsUnion<typeof NavigationViewActions>;
