export enum PriorityFilter {
  Yes = 'yes',
  No = 'no',
}

export interface IItemConfig {
  label: string;
}

export const PriorityFilterItemConfig: Record<PriorityFilter, IItemConfig> = {
  [PriorityFilter.Yes]: {
    label: 'Yes',
  },
  [PriorityFilter.No]: {
    label: 'No',
  },
};
