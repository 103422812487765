import { type FC } from 'react';

import { Icon, Tooltip, type IconSource } from '@livechat/design-system-react-components';

import { type Platform } from 'constants/app-platform';
import { EventPlace } from 'helpers/analytics';
import { openInNewCard } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';

interface IProps {
  icon: IconSource;
  downloadLink: string;
  tooltipContent: string;
  trackEventContent: string;
  platform?: Platform;
}

const NavigationDownloadApp: FC<IProps> = ({
  icon,
  tooltipContent,
  downloadLink,
  trackEventContent,
  platform = null,
}) => {
  const handleDownloadDesktopApp = () => {
    openInNewCard(downloadLink);
    trackEvent(trackEventContent, EventPlace.ProfileMenu, {
      ...(platform && { platform }),
    });
  };

  return (
    <Tooltip
      kind="invert"
      placement="bottom"
      transitionDelay={500}
      hoverOffDelay={0}
      triggerRenderer={
        <div onClick={handleDownloadDesktopApp}>
          <Icon source={icon} />
        </div>
      }
    >
      {tooltipContent}
    </Tooltip>
  );
};

export default NavigationDownloadApp;
