import { type TopBarNotificationType } from 'constants/notifications';
import type { ITopBarNotification, ITopBarNotificationsState } from 'interfaces/notification-bar';

import { type NotificationsBarActionsTypes, NotificationsBarActionsNames } from './actions';
import { comparePriority } from './helpers';

const initialState: ITopBarNotificationsState = {
  display: false,
  transitionEnded: false,
  notifications: [],
};

const addNotification = (
  notifications: ITopBarNotification[],
  newNotification: ITopBarNotification
): ITopBarNotification[] => {
  return [...notifications.filter((item) => item.name !== newNotification.name), newNotification].sort(comparePriority);
};

const removeNotification = (
  notifications: ITopBarNotification[],
  notificationName: TopBarNotificationType
): ITopBarNotification[] =>
  notifications.filter((notification: ITopBarNotification) => notification.name !== notificationName);

export function notificationsBarReducer(
  state: ITopBarNotificationsState = initialState,
  action: NotificationsBarActionsTypes
): ITopBarNotificationsState {
  let newNotificationsList: ITopBarNotification[];

  switch (action.type) {
    case NotificationsBarActionsNames.SHOW_NOTIFICATIONS_BAR:
      newNotificationsList = addNotification(state.notifications, action.payload.notification);

      return {
        ...state,
        notifications: newNotificationsList,
      };

    case NotificationsBarActionsNames.HIDE_NOTIFICATIONS_BAR:
      newNotificationsList = removeNotification(state.notifications, action.payload.notificationName);

      return {
        ...state,
        notifications: newNotificationsList,
      };
    case NotificationsBarActionsNames.TOGGLE_DISPLAY:
      return {
        ...state,
        display: !state.display,
      };
    case NotificationsBarActionsNames.NOTIFICATIONS_BAR_TRANSITON_ENDED:
      return {
        ...state,
        transitionEnded: true,
      };
    default:
      return state;
  }
}
