import { createAction } from 'store/helper';

export enum ExternalAppsActionsNames {
  START_EXTERNAL_APPS = 'FEATURES/EXTERNAL_APPS/START_EXTERNAL_APPS',
  TURN_OFF_EXTERNAL_APPS = 'FEATURES/EXTERNAL_APPS/TURN_OFF_EXTERNAL_APPS',
  BEAMER_START = 'FEATURES/EXTERNAL_APPS/BEAMER_START',
  BEAMER_READY = 'FEATURES/EXTERNAL_APPS/BEAMER_READY',
  SPRIG_START = 'FEATURES/EXTERNAL_APPS/SPRIG_START',
  SPRIG_READY = 'FEATURES/EXTERNAL_APPS/SPRIG_READY',
}

export const ExternalAppsActions = {
  startExternalApps: () => createAction(ExternalAppsActionsNames.START_EXTERNAL_APPS),
  turnOffExternalApps: () => createAction(ExternalAppsActionsNames.TURN_OFF_EXTERNAL_APPS),
  beamerStart: () => createAction(ExternalAppsActionsNames.BEAMER_START),
  beamerReady: () => createAction(ExternalAppsActionsNames.BEAMER_READY),
  sprigStart: () => createAction(ExternalAppsActionsNames.SPRIG_START),
  sprigReady: () => createAction(ExternalAppsActionsNames.SPRIG_READY),
};
