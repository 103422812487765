import { CopilotEntitiesActionNames, type CopilotEntitiesAction } from './actions';
import {
  getStateForAddNewCopilotEvent,
  getStateForAddCopilotError,
  getStateForClearCopilotError,
  getStateForCopilotMessageFromSpotlight,
  getStateForSetCopilotFailedMessageContent,
  getStateForUpdateCopilotActionButtons,
} from './helpers';
import { type CopilotEntitiesState } from './interfaces';

const initialState: CopilotEntitiesState = {
  events: {
    byIds: {},
    groupedIds: [[]],
  },
  currentSessionId: null,
  isMessageFromSpotlight: false,
};

export const copilotEntitiesReducer = (state = initialState, action: CopilotEntitiesAction): CopilotEntitiesState => {
  switch (action.type) {
    case CopilotEntitiesActionNames.ADD_COPILOT_MESSAGE: {
      return getStateForAddNewCopilotEvent(state, action.payload);
    }

    case CopilotEntitiesActionNames.ADD_COPILOT_ERROR: {
      return getStateForAddCopilotError(state, action.payload);
    }

    case CopilotEntitiesActionNames.CLEAR_COPILOT_ERROR: {
      return getStateForClearCopilotError(state);
    }

    case CopilotEntitiesActionNames.SET_FAILED_COPILOT_MESSAGE: {
      return getStateForSetCopilotFailedMessageContent(state, action.payload);
    }

    case CopilotEntitiesActionNames.UPDATED_COPILOT_ACTION_BUTTONS: {
      return getStateForUpdateCopilotActionButtons(state, action.payload);
    }

    case CopilotEntitiesActionNames.COPILOT_MESSAGE_SPOTLIGHT: {
      return getStateForCopilotMessageFromSpotlight(state, action.payload);
    }

    default:
      return state;
  }
};
