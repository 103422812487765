import { type IUnsavedChangesState } from './interfaces';

interface IWithUnsavedChangesState {
  features: {
    unsavedChanges: IUnsavedChangesState;
  };
}

export function getHasUnsavedChanges(state: IWithUnsavedChangesState): boolean {
  return state.features.unsavedChanges.hasUnsavedChanges;
}
