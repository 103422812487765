import { type ReactElement } from 'react';

import { type EnvironmentType } from 'constants/environment';
import { type Feature } from 'constants/feature';
import type { Icons } from 'constants/icons';
import { type Right } from 'constants/right';
import type { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { type ExperimentRestrictions } from 'interfaces/experiment';

export const enum OnOffBadge {
  On = 'On',
  Off = 'Off',
}

interface SideNavigationItemCustomComponentProps {
  item: ISideNavigationItem;
  isMainEntry?: boolean;
  isActive?: boolean;
}

export interface ISideNavigationItem {
  name?: string;
  path?: string;
  external?: boolean;
  id?: string;
  badge?: boolean;
  checkBadgeVisibility?(): boolean;
  badgeCount?: () => number;
  number?: number;
  featureAccess?: Feature;
  plan?: string | string[];
  right?: Right;
  children?: ISideNavigationItem[];
  isDivider?: boolean;
  icon?: Icons;
  hidden?: boolean;
  newBadge?: boolean;
  checkNewBadgeVisibility?(): boolean;
  onOffBadge?: OnOffBadge;
  betaBadge?: boolean;
  experiment?: ExperimentRestrictions;
  allowedEnv?: (EnvironmentType.Labs | EnvironmentType.Staging | EnvironmentType.Production)[];
  customCheck?(): boolean;
  tourStep?: {
    id: UserGuidedTourType;
    step: string;
  };
  onItemHover?(): void;
  important?: boolean;
  customLabelComponent?: (props: SideNavigationItemCustomComponentProps) => ReactElement;
}
