import { type DetailedHTMLProps, type HTMLAttributes, type FC, type ChangeEvent } from 'react';

import { cx } from '@emotion/css';
import { DragIndicator } from '@livechat/design-system-icons';
import { Checkbox, DesignToken, Icon, Text } from '@livechat/design-system-react-components';
import { type DraggableProvided, type DraggableStateSnapshot } from 'react-beautiful-dnd';

import { withVar } from 'helpers/styles';

import { ApplicationListItemIcon } from './ApplicationListItemIcon';

import { labelWrapper, icon, itemDragging, dragIcon } from './styles';

interface Props {
  name: string;
  appIcon?: string;
  appId?: string;
  draggableProvided?: DraggableProvided | null;
  snapshot?: DraggableStateSnapshot;
  checked?: boolean;
  disabled?: boolean;
  onAppSelected?: (event: ChangeEvent<HTMLInputElement>, appId: string) => void;
  onClick?: () => void;
  className?: string;
}

export const ApplicationListItem: FC<Props> = ({
  name,
  appIcon,
  appId,
  draggableProvided,
  snapshot,
  checked,
  disabled,
  onAppSelected,
  onClick,
  className,
}) => {
  const isUsingPortal = snapshot?.isDragging;
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onAppSelected && appId) {
      onAppSelected(e, appId);
    }
  };

  return (
    <span
      onClick={onClick}
      className={cx([
        labelWrapper,
        className,
        {
          [itemDragging]: isUsingPortal,
        },
      ])}
      ref={draggableProvided?.innerRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(draggableProvided?.draggableProps as DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(draggableProvided?.dragHandleProps as DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>)}
    >
      {draggableProvided && (
        <>
          <Icon
            source={DragIndicator}
            size="medium"
            customColor={withVar(DesignToken.ContentLockedWhite)}
            className={dragIcon}
          />
          <Checkbox checked={checked} onChange={handleChange} data-testid={appId} disabled={disabled} />
        </>
      )}
      <span className={icon}>
        <ApplicationListItemIcon name={name} icon={appIcon} />
      </span>
      <Text as="span">{name}</Text>
    </span>
  );
};
