// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import debug from 'debug';
import { type SagaIterator } from 'redux-saga';
import { call, delay, put, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { NavigationPath } from 'constants/navigation';
import { PostMessageEvent } from 'constants/post-message-event';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { getFBMessengerAppId, getGoogleAnalyticsAppId } from 'helpers/applications';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { redirectTo } from 'helpers/redirect-to';
import {
  extractRichMessageCard,
  isRichMessageJSON,
  validRichMessageCards,
  type IRichMessageJSON,
} from 'helpers/rich-message';
import { navigate } from 'helpers/routing';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type IUninstallApplicationBody } from 'services/api/integrations/interfaces';
import { trackEvent } from 'services/event-tracking';
import {
  CONNECTION_MISSING_ERROR_MESSAGE,
  POSTMESSAGE_UNAVAILABLE_ERROR_MESSAGE,
  type IConnection,
} from 'services/widget/connection/connection';
import connectionPool from 'services/widget/connection/connection-pool';
import { type IInboxMessage } from 'services/widget/message/inbox/inbox-message';
import { InboxMessageTitles } from 'services/widget/message/inbox/inbox-message-titles';
import { createCustomerProfileMessage } from 'services/widget/message/outbox/customer-profile-message';
import { type IOutboxBroadcastMessage, type IOutboxMessage } from 'services/widget/message/outbox/outbox-message';
import { createPageDataMessage } from 'services/widget/message/outbox/page-data-message';
import { createSendCardsResponseMessage } from 'services/widget/message/outbox/send-cards-response';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import {
  ApplicationsActionNames as ApplicationsActionEntitiesNames,
  ApplicationsActions as ApplicationsActionsEntities,
  ApplicationsActionNames as ApplicationsEntityActionNames,
} from 'store/entities/applications/actions';
import {
  ApplicationWidgetPlacement,
  type IApplication,
  type IApplicationWidget,
} from 'store/entities/applications/interfaces';
import {
  getFilteredInstalledAndPrivateSortedApplicationsIds,
  getApplication,
  getCustomerDetailsSections,
  getInReviewApplication,
  getPrivateApplication,
  getWidgetById,
  hasFetchedApplications,
  hasFetchedPrivateApplications,
} from 'store/entities/applications/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { ChatsViewActions } from 'store/views/chats/actions';
import { getSelectedThreadId } from 'store/views/chats/selectors';
import { NavigationViewActions } from 'store/views/navigation/actions';
import { ReportsViewActions } from 'store/views/reports/actions';

import { AgentCustomPropertiesActions, AGENT_CUSTOM_PROPERTIES } from '../agent-custom-properties/actions';
import {
  AgentCustomPropertyName,
  type IFetchAgentCustomPropertiesSuccessPayload,
} from '../agent-custom-properties/interfaces';

import { ApplicationsActionNames, ApplicationsActions } from './actions';
import type {
  ICampaign,
  SetApplicationsOrderPayload,
  SetApplicationsSelectedPayload,
  UninstallApplicationFailurePayload,
  UninstallApplicationPayload,
  UninstallApplicationSuccessfulPayload,
} from './interfaces';

const log = debug(DebugLogsNamespace.AppApplications);

function sendMessage(connection: IConnection, message?: IOutboxMessage): SagaIterator {
  if (!message) {
    return;
  }

  log('Sending message', { message });

  connection.send(message).catch((error: { code: string; message: string }) => {
    const isIframeDestroyed = error.code === 'DESTROYED';
    const isConnectionUnavailable = [CONNECTION_MISSING_ERROR_MESSAGE, POSTMESSAGE_UNAVAILABLE_ERROR_MESSAGE].includes(
      error.message,
    );

    log('Error sending message', error);

    // Ignored issues
    if (isIframeDestroyed || isConnectionUnavailable) {
      return;
    }

    throw error;
  });
}

function* sendChatMessage(pluginId: string, message: string, richMessageJSON: IRichMessageJSON): SagaIterator {
  if (isRichMessageJSON(richMessageJSON)) {
    log('Detected rich message');

    trackEvent('Rich message sent', EventPlace.Chats, {
      integration: pluginId,
      type: richMessageJSON.payload.template_id,
    });
  }

  const threadId: string = yield select(getSelectedThreadId);
  log(`Sending to thread ${threadId}`);

  if (threadId) {
    yield put(
      ChatsViewActions.sendMessage({
        threadId,
        message,
        richMessageJSON,
      }),
    );
  }
}

function* handleIncomingMessages(
  action: IActionWithPayload<string, { message: IInboxMessage; connectionId: string }>,
): SagaIterator {
  const connection = connectionPool.getByConnectionId(action.payload.connectionId);
  const { message } = action.payload;
  const title = message.title as InboxMessageTitles;

  log('Incoming message', { message, connectionAvailable: !!connection });

  if (!connection) {
    return;
  }

  if (title === InboxMessageTitles.PutMessage) {
    yield put(
      ApplicationsActions.handlePutMessage({
        message: message.data as string,
      }),
    );
  }

  if (title === InboxMessageTitles.SetFullscreenWidgetNotificationBadge) {
    yield put(
      NavigationViewActions.setNavigationItemBadge({
        itemId: message.pluginId,
        badge: {
          numeric: message.data as number,
        },
      }),
    );
  }

  if (title === InboxMessageTitles.NavigateFromFullScreenWidget) {
    yield call(navigate, message.data);
  }

  if (title === InboxMessageTitles.SetReportsFilters) {
    yield put(ReportsViewActions.setFilters(message.data));
  }

  if (title === InboxMessageTitles.Redirect) {
    const target = message.data as string;
    const widget = (yield select(getWidgetById, message.pluginId)) as IApplicationWidget;

    if (!widget) {
      return;
    }

    // temporarily widgets placed in subscription are using createSettingsWidget method from agent-app-sdk
    // only widgets placed in subscription and settings are allowed to use `redirect` method
    const allowedPlacements = [ApplicationWidgetPlacement.Settings, ApplicationWidgetPlacement.Subscription];

    if (!allowedPlacements.includes(widget.placement)) {
      log('Trying to redirect from not allowed widget placement', {
        pluginId: message.pluginId,
        placement: widget.placement,
        target,
      });

      return;
    }

    try {
      const targetUrl = new URL(target, window.location.origin);

      if (targetUrl.origin === window.location.origin) {
        yield call(navigate, targetUrl.href.replace(window.location.origin, ''));
      } else {
        redirectTo(targetUrl.href);
      }
    } catch (error) {
      log('Error when redirecting from settings widget', { pluginId: message.pluginId, target, error });
    }
  }

  if (title === InboxMessageTitles.PluginInited) {
    const widget = (yield select(getWidgetById, message.pluginId)) as IApplicationWidget;

    if (!widget) {
      return;
    }

    // This is workaround for iframe initialization, will be moved after
    // migration to store.
    setTimeout((): void => {
      const { customerId, chatId, source } = connection.getContext();
      const customerProfileMessage = createCustomerProfileMessage(message.pluginId, customerId, chatId, source);
      sendMessage(connection, customerProfileMessage);

      // temporarily widgets placed in subscription are using createSettingsWidget method from agent-app-sdk
      // only widgets placed in subscription, fullscreen and settings are allowed to receive `page_data` event
      const allowedPlacements = [
        ApplicationWidgetPlacement.Settings,
        ApplicationWidgetPlacement.Subscription,
        ApplicationWidgetPlacement.FullScreen,
      ];

      if (!allowedPlacements.includes(widget.placement)) {
        return;
      }

      const pageDataMessage = createPageDataMessage(message.pluginId, new URLSearchParams(window.location.search));
      sendMessage(connection, pageDataMessage);
    }, 100);
  }

  if (title === InboxMessageTitles.SendCards) {
    const cards = message.data;

    if (!validRichMessageCards(cards)) {
      log('Invalid Rich Message Card', { cards });

      sendMessage(
        connection,
        createSendCardsResponseMessage(message.pluginId, {
          message: 'Invalid rich message card',
          status: 'error',
        }),
      );

      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.RICH_MESSAGE_SEND_CARDS_ERROR),
          kind: ToastVariant.Error,
        }),
      );
    } else {
      const elements = cards.map(extractRichMessageCard);

      log('Sending Rich Message Card', { elements });

      yield call(sendChatMessage, message.pluginId, 'Rich message cards', {
        type: 'rich_message',
        payload: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          template_id: 'cards',
          elements,
        },
      });

      sendMessage(
        connection,
        createSendCardsResponseMessage(message.pluginId, {
          message: 'sent',
          status: 'success',
        }),
      );
    }
  }
}

function* handleOutboxMessage(action: IActionWithPayload<string, { message: IOutboxMessage }>): SagaIterator {
  let connection = connectionPool.get(action.payload.message.pluginId);
  if (!connection) {
    // Wait for connection
    yield take(
      (connectionAction: IActionWithPayload<string, { pluginId: string; connectionId: string }>) =>
        (connectionAction.type as ApplicationsActionNames) === ApplicationsActionNames.CONNECTION_ESTABLISHED &&
        connectionAction.payload.pluginId === action.payload.message.pluginId,
    );
    connection = connectionPool.get(action.payload.message.pluginId);
  }
  sendMessage(connection, action.payload.message);
}

function handleOutboxBroadcastMessage(action: IActionWithPayload<string, { message: IOutboxBroadcastMessage }>): void {
  connectionPool.getAll().forEach(({ id, connection }): void => {
    sendMessage(connection, {
      ...action.payload.message,
      pluginId: id,
    });
  });
}

function* markWidgetsAsInitiallyLoaded(): SagaIterator {
  const sectionsMap: { [pluginId: string]: unknown } = yield select(getCustomerDetailsSections);
  const ids = Object.keys(sectionsMap);
  for (let i = 0; i < ids.length; i += 1) {
    yield put(ApplicationsActions.loadWidget(ids[i]));
  }
}

function* installApplication(
  action: IActionWithPayload<string, { id: string; eventPlace?: EventPlace; name?: string }>,
): SagaIterator {
  const { error }: RequestResult = yield call(ApiManager.integrationsApi.install, action.payload.id);

  const installedAppId = action.payload.id;
  const installedPrivateApplication: IApplication = yield select(getPrivateApplication, installedAppId);
  const installedInReviewApplication: IApplication = yield select(getInReviewApplication, installedAppId);
  const application: IApplication = yield select(getApplication, installedAppId);

  const payload = installedPrivateApplication || installedInReviewApplication || application || { id: installedAppId };

  if (error) {
    const failurePayload = { ...payload, name: action.payload.name, eventPlace: action.payload.eventPlace };
    yield put(ApplicationsActions.installApplicationFailure(failurePayload));
  } else {
    const successPayload = { ...payload, eventPlace: action.payload.eventPlace };
    yield put(ApplicationsActions.installApplicationSuccessful(successPayload));
  }
}

function* installationSuccessful(
  action: IActionWithPayload<string, { id: string; eventPlace?: EventPlace }>,
): SagaIterator {
  if (action.payload.id === getFBMessengerAppId() || action.payload.eventPlace === EventPlace.Onboarding) {
    yield put(ApplicationsActionsEntities.fetchCollection({}));
    yield take(ApplicationsActionEntitiesNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]);
  }

  if (action.payload.eventPlace) {
    const application: IApplication = yield select(getApplication, action.payload.id);
    trackEvent(`Integration successfully installed`, EventPlace.Onboarding, {
      integrationName: application.name,
    });
  }
}

function* installationFailure(
  action: IActionWithPayload<string, { id: string; name?: string; eventPlace?: EventPlace }>,
): SagaIterator {
  if (action.payload.id === getGoogleAnalyticsAppId()) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.GA_INSTALL_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      }),
    );
  } else if (action.payload.eventPlace !== EventPlace.Onboarding) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.APPLICATION_INSTALL_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      }),
    );
  }

  if (action.payload.eventPlace && action.payload.name) {
    trackEvent(`Connect ${action.payload.name} failed`, EventPlace.Onboarding);
  }
}

export function* uninstallApplication(action: IActionWithPayload<string, UninstallApplicationPayload>): SagaIterator {
  const { id, displaySuccessToast, notifyMarketplace, reason, redirect } = action.payload;
  const uninstallPayload: IUninstallApplicationBody = {};

  if (reason) {
    uninstallPayload.reason = reason;
  }

  const { error }: RequestResult = yield call(ApiManager.integrationsApi.uninstall, id, uninstallPayload);

  if (!error) {
    const deletedApplication: IApplication = yield select(getApplication, id);
    const deletedAppClientId = deletedApplication?.clientId;

    if (redirect) {
      yield call(navigate, NavigationPath.Apps);
    }

    yield put(
      ApplicationsActions.uninstallApplicationSuccessful({
        id,
        displaySuccessToast,
        appClientId: deletedAppClientId,
        notifyMarketplace,
      }),
    );
  } else {
    yield put(ApplicationsActions.uninstallApplicationFailure({ id: action.payload.id }));
  }
}

function* uninstallationSuccessful(
  action: IActionWithPayload<string, UninstallApplicationSuccessfulPayload>,
): SagaIterator {
  const { appClientId, notifyMarketplace, displaySuccessToast } = action.payload;

  if (appClientId) {
    yield spawn(ApiManager.clientSsoApi.deleteConsent, appClientId);
  }

  if (displaySuccessToast) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.APPLICATION_UNINSTALL_SUCCESS),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Success,
      }),
    );
  }

  if (notifyMarketplace) {
    yield delay(250);
    yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshInstalledApps);
  }
}

function* uninstallationFailure(action: IActionWithPayload<string, UninstallApplicationFailurePayload>): SagaIterator {
  if (action.payload.id === getGoogleAnalyticsAppId()) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.GA_UNINSTALL_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      }),
    );
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.APPLICATION_UNINSTALL_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      }),
    );
  }
}

function* fetchCampaigns(action: IActionWithPayload<string, { ids: string[] }>): SagaIterator {
  const campaignsIds = action.payload.ids;
  const api = ApiManager.campaignsApi;

  const { result, error }: RequestResult<ICampaign[]> = yield call(api.fetchAll);

  if (error) {
    yield put(ApplicationsActions.fetchCampaignsFailure(campaignsIds));
  }

  if (result) {
    const campaigns = result.filter((camp) => campaignsIds.includes(camp.id));
    yield put(ApplicationsActions.fetchCampaignsSuccessful(campaigns));
  }
}

function* updateAgentCustomPropertiesApplicationsSelected(
  action: IActionWithPayload<string, SetApplicationsSelectedPayload>,
): SagaIterator {
  const { selectedApplications, unselectedApplications } = action.payload;

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.CustomerCustomSelectedApplications]: selectedApplications.join(','),
      [AgentCustomPropertyName.CustomerCustomUnselectedApplications]: unselectedApplications.join(','),
    }),
  );

  yield put(ApplicationsActions.setApplicationsSelected({ selectedApplications, unselectedApplications }));
}

function* updateAgentCustomPropertiesApplicationsOrder(
  action: IActionWithPayload<string, SetApplicationsOrderPayload>,
): SagaIterator {
  const { applicationsOrder } = action.payload;

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.CustomerCustomApplicationsOrder]: applicationsOrder.join(','),
    }),
  );

  yield put(ApplicationsActions.setApplicationsOrder({ applicationsOrder }));
}

function* loadCustomersApplicationsConfiguration(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>,
): SagaIterator {
  const areApplicationsFetched = yield select(hasFetchedApplications);
  const arePrivateApplicationsFetched = yield select(hasFetchedPrivateApplications);

  if (!areApplicationsFetched) {
    yield take([
      ApplicationsEntityActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS],
      ApplicationsEntityActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.FAILURE],
    ]);
  }

  if (!arePrivateApplicationsFetched) {
    yield take([
      ApplicationsEntityActionNames.FETCH_PRIVATE_APPLICATIONS_SUCCESS,
      ApplicationsEntityActionNames.FETCH_PRIVATE_APPLICATIONS_FAILURE,
    ]);
  }

  const installedAppsIds: string[] = yield select(getFilteredInstalledAndPrivateSortedApplicationsIds);

  const {
    customer_custom_selected_applications: selected,
    customer_custom_unselected_applications: unselected,
    customer_custom_applications_order: order,
  } = action.payload;

  let selectedApplications: string[];
  let unselectedApplications: string[];

  if (selected !== undefined && unselected !== undefined) {
    selectedApplications = (selected as string).split(',');
    unselectedApplications = (unselected as string).split(',');
  } else {
    // If no 'selected' custom properties were set, mark all installed applications as selected
    selectedApplications = installedAppsIds;
    unselectedApplications = [];
  }
  yield put(ApplicationsActions.setApplicationsSelected({ selectedApplications, unselectedApplications }));

  if (order !== undefined) {
    const applicationsOrder = (order as string).split(',');
    yield put(ApplicationsActions.setApplicationsOrder({ applicationsOrder }));
  }
}

export function* applicationsSagas(): SagaIterator {
  yield takeEvery(ApplicationsActionNames.WIDGET_INBOX_MESSAGE, handleIncomingMessages);
  yield takeEvery(ApplicationsActionNames.WIDGET_OUTBOX_MESSAGE, handleOutboxMessage);
  yield takeEvery(ApplicationsActionNames.WIDGET_OUTBOX_BROADCAST_MESSAGE, handleOutboxBroadcastMessage);
  yield takeEvery(
    ApplicationsActionEntitiesNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS],
    markWidgetsAsInitiallyLoaded,
  );
  yield takeEvery(ApplicationsActionNames.INSTALL_APPLICATION, installApplication);
  yield takeEvery(ApplicationsActionNames.INSTALL_APPLICATION_SUCCESSFUL, installationSuccessful);
  yield takeEvery(ApplicationsActionNames.INSTALL_APPLICATION_FAILURE, installationFailure);
  yield takeEvery(ApplicationsActionNames.UNINSTALL_APPLICATION, uninstallApplication);
  yield takeEvery(ApplicationsActionNames.UNINSTALL_APPLICATION_SUCCESSFUL, uninstallationSuccessful);
  yield takeEvery(ApplicationsActionNames.UNINSTALL_APPLICATION_FAILURE, uninstallationFailure);
  yield takeEvery(ApplicationsActionNames.FETCH_CAMPAIGNS, fetchCampaigns);
  yield takeLatest(ApplicationsActionNames.SAVE_APPLICATIONS_SELECTED, updateAgentCustomPropertiesApplicationsSelected);
  yield takeLatest(ApplicationsActionNames.SAVE_APPLICATIONS_ORDER, updateAgentCustomPropertiesApplicationsOrder);
  yield takeLatest(
    AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS],
    loadCustomersApplicationsConfiguration,
  );
}
