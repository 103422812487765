// @ts-strict-ignore
import { ShoppingCart } from '@livechat/design-system-icons';

import { toKeyMap } from 'helpers/array';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

import { type IGoalFilterItem } from './goal-filter';

export const goalFilterConfigMultiple: FilterConfig = {
  title: 'Goal',
  icon: ShoppingCart,
  mobileViewTitle: 'Select goals',
  multiple: true,
  getFilterLabelValues: (filterValues: string[] | null, goals: IGoalFilterItem[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const goalsHash = toKeyMap(goals, 'id');

    return filterValues
      .filter((goalId) => goalsHash[goalId])
      .map((goalId) => ({
        key: goalsHash[goalId].id,
        label: goalsHash[goalId].name,
      }));
  },
};
