// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { type IInboxMessage } from 'services/widget/message/inbox/inbox-message';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { type IApplication, type IApplicationsState } from 'store/entities/applications/interfaces';
import {
  ApplicationsActionNames as ApplicationsFeaturesActionNames,
  type ApplicationsAction as ApplicationsFeaturesAction,
} from 'store/features/applications/actions';
import {
  type SetApplicationsOrderPayload,
  type SetApplicationsSelectedPayload,
  type UninstallApplicationSuccessfulPayload,
} from 'store/features/applications/interfaces';
import { type IActionWithPayload } from 'store/helper';

import type { EntityFetchCollectionSuccess } from '../interfaces';

import {
  type ApplicationsAction as ApplicationsEntityActions,
  ApplicationsActionNames as ApplicationsEntityActionNames,
} from './actions';
import {
  applicationsStateSerializer,
  applicationApiSerializer,
  customSectionMessageSerializer,
} from './helpers/reducer';

export const initialState: IApplicationsState = {
  installed: {
    byIds: {},
    channels: {
      byClientIds: {},
    },
  },
  private: [],
  inReview: [],
  privateWidgets: { byIds: {} },
  appIdByWidgetId: {},
  isInitialized: false,
  selectedApplications: [],
  unselectedApplications: [],
  applicationsOrder: [],
};

export const applicationsReducer = (
  state: IApplicationsState = initialState,
  action: ApplicationsFeaturesAction | ApplicationsEntityActions,
): IApplicationsState => {
  switch (action.type) {
    case ApplicationsEntityActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]: {
      const { payload } = action as IActionWithPayload<string, EntityFetchCollectionSuccess<IApplication>>;
      const { values } = payload;

      const appIdByWidgetId = values?.reduce((mappedIds, { id, widgets }) => {
        widgets?.forEach((widget) => {
          mappedIds[widget.id] = id;
        });

        return mappedIds;
      }, {});

      return {
        ...state,
        isInitialized: true,
        installed: (values || []).reduce(applicationsStateSerializer, initialState.installed),
        appIdByWidgetId,
      };
    }

    case ApplicationsEntityActionNames.FETCH_PRIVATE_APPLICATIONS_SUCCESS: {
      const { payload } = action as IActionWithPayload<string, IApplication[]>;

      return {
        ...state,
        private: (payload || []).map(applicationApiSerializer),
      };
    }

    case ApplicationsEntityActionNames.FETCH_IN_REVIEW_APPLICATIONS_SUCCESS: {
      const { payload } = action as IActionWithPayload<string, IApplication[]>;

      return {
        ...state,
        inReview: (payload || []).map(applicationApiSerializer),
      };
    }

    case ApplicationsFeaturesActionNames.INSTALL_APPLICATION_SUCCESSFUL: {
      const { payload } = action as IActionWithPayload<string, IApplication>;

      const { id, widgets } = payload;

      const appIdByWidgetId = (widgets || []).reduce((mappedIds, widget) => {
        mappedIds[widget.id] = id;

        return mappedIds;
      }, {});

      return {
        ...state,
        installed: applicationsStateSerializer(state.installed, payload),
        appIdByWidgetId: {
          ...state.appIdByWidgetId,
          ...appIdByWidgetId,
        },
      };
    }

    case ApplicationsFeaturesActionNames.UNINSTALL_APPLICATION_SUCCESSFUL: {
      const { payload } = action as IActionWithPayload<string, UninstallApplicationSuccessfulPayload>;
      const { id } = payload;

      const { [id]: uninstalledId, ...byIds } = state.installed.byIds;

      return {
        ...state,
        installed: {
          ...state.installed,
          byIds,
        },
        selectedApplications: state.selectedApplications.filter((appId) => appId !== id),
        unselectedApplications: state.unselectedApplications.filter((appId) => appId !== id),
      };
    }

    case ApplicationsFeaturesActionNames.UPDATE_APPLICATION: {
      const { payload } = action as IActionWithPayload<string, IInboxMessage>;

      return {
        ...state,
        privateWidgets: {
          byIds: customSectionMessageSerializer(state, payload),
        },
      };
    }

    case ApplicationsFeaturesActionNames.SET_APPLICATIONS_SELECTED: {
      const { payload } = action as IActionWithPayload<string, SetApplicationsSelectedPayload>;

      return {
        ...state,
        selectedApplications: payload.selectedApplications,
        unselectedApplications: payload.unselectedApplications,
      };
    }

    case ApplicationsFeaturesActionNames.ADD_APPLICATION_SELECTED: {
      const { payload } = action as IActionWithPayload<string, string>;
      const uniqueSelectedApplications = new Set([...state.selectedApplications, payload]);

      return {
        ...state,
        selectedApplications: [...uniqueSelectedApplications],
      };
    }

    case ApplicationsFeaturesActionNames.SET_APPLICATIONS_ORDER: {
      const { payload } = action as IActionWithPayload<string, SetApplicationsOrderPayload>;

      return {
        ...state,
        applicationsOrder: payload.applicationsOrder,
      };
    }

    default:
      return state;
  }
};
