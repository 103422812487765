import { extractAutotagsByGroup, extractTagsByGroup } from 'routes/automate/components/canned-responses/helpers';
import { type MismatchedAutotagsWithCannedIds } from 'routes/automate/components/canned-responses/interface';
import { type ICannedResponseAutotags } from 'store/entities/canned-responses/interfaces';
import { type ITag } from 'store/entities/tags/interfaces';

export const getMismatchedAutotags = (
  tags: ITag[],
  allCannedResponseAutotags: Record<string, ICannedResponseAutotags>,
): MismatchedAutotagsWithCannedIds => {
  const groupedTags = extractTagsByGroup(tags);
  const groupedAutotags = extractAutotagsByGroup(allCannedResponseAutotags);

  return Object.entries(groupedAutotags).reduce((acc, [groupId, cannedAutotagsMap]) => {
    const availableTags = new Set(groupedTags[groupId] || []);
    const cannedResponsesWithInvalidTags = Object.entries(cannedAutotagsMap).filter(([, autoTags]) =>
      autoTags.some((tag) => !availableTags.has(tag)),
    );

    if (cannedResponsesWithInvalidTags.length > 0) {
      acc.push({
        groupId,
        tags: Array.from(
          new Set(
            cannedResponsesWithInvalidTags.flatMap(([, autoTags]) => autoTags.filter((tag) => !availableTags.has(tag))),
          ),
        ),
        cannedIds: cannedResponsesWithInvalidTags.map(([cannedId]) => cannedId),
      });
    }

    return acc;
  }, [] as MismatchedAutotagsWithCannedIds);
};
