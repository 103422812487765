import { QueryKey } from 'constants/query-key';
import type { IProductOnboardingProgress } from 'interfaces/entities/product-onboarding';
import type { IStoreState } from 'interfaces/store/store-state';
import { getQueryClient } from 'services/query-client/client';
import { isAtLeastAdmin } from 'store/features/session/selectors';

export const getIsOnboardingCompleted = (state: IStoreState): boolean => {
  const queryClient = getQueryClient();
  const onboardingProgress = queryClient.getQueryData<Partial<IProductOnboardingProgress>>([
    QueryKey.OnboardingProgress,
  ]);

  if (!onboardingProgress) {
    return false;
  }

  const includeInstallStep = isAtLeastAdmin(state);

  if (!includeInstallStep) {
    delete onboardingProgress.codeInstalled;
  }

  return Object.values(onboardingProgress).every((value) => value);
};
