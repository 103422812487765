/**
 * Solution based on https://github.com/Rizwanjamal/ip-check-range/blob/master/index.js
 */

function matchesIp(ip1: string, ip2: string): boolean {
  const subsets1 = ip1.split('.');
  const subsets2 = ip2.split('.');

  return subsets1.reduce((acc, subset, i, subsets) => {
    if (!acc) return false;
    if (subsets2[i] && subsets2[i].indexOf('-') > -1) {
      const range = subsets2[i].split('-');
      if (+subset >= +range[0] && +subset <= +range[1]) return !!subsets2;
      return false;
    }
    if (subsets.length === i + 1 && subsets2[i] && subsets2[i].indexOf('/') > -1) {
      const range = subsets2[i].split('/');
      if (+subset >= +range[0] && +subset <= +range[1]) return !!subsets2;
      return false;
    }
    if (['*', subset, ''].indexOf(subsets2[i] || '*') > -1) return !!subsets2;
    return false;
  }, true);
}

export function ipRangeCheck(ip: string, ipRanges: string[]): boolean {
  return ipRanges.reduce((acc, ipPattern) => acc || matchesIp(ip, ipPattern), false);
}

export function customerIpMatchesRange(customerIp: string, ipPattern: string): boolean {
  if (!customerIp) {
    return false;
  }

  return ipRangeCheck(
    customerIp,
    ipPattern
      .split(',')
      .map((p) => p.trim())
      .filter(Boolean)
  );
}
