export enum CannedResponseAutotagsEvent {
  UpgradeClicked = 'Autotags upgrade button clicked',
  LearnMoreClicked = 'Autotags learn more button clicked',
  DiscardClicked = 'Autotags discard changes button clicked',
  StayHereClicked = 'Autotags stay here button clicked',
  AdvancedSettingsClicked = 'Autotags advanced settings button clicked',
  AddTagsToCannedClicked = 'Autotags modal add tags to canned response button clicked',
  ModalLearnMoreClicked = 'Autotags modal learn more button clicked',
  CreateCannedButtonClicked = 'New canned button clicked',
  ChatAutotagged = 'Canned response autotags have been added',
}
