export enum TopBarNotificationType {
  InstallCode = 'install-code',
  Reconnecting = 'reconnecting',
  StatusAway = 'status-away',
  TrialWarning = 'trial-warning',
  TrialWarningImportant = 'trial-warning-important',
  BrowserNotifications = 'browser-notifications',
  EnableNotifications = 'enable-notifications',
  UnblockNotifications = 'unblock-notifications',
  ChangeBrowser = 'change-browser',
  SendPendingInvites = 'send-pending-invites',
  Offline = 'offline',
  ConnectionRestored = 'connection-restored',
  DownloadDesktopApp = 'download-desktop-app',
  NativeTicketsSunset = 'native-tickets-sunset',
}
