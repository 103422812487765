// @ts-strict-ignore
import { type ActiveExperiment } from 'constants/active-experiment';
import { type KeyMap } from 'helpers/interface';
import { type Experiment } from 'interfaces/experiment';

export function deserializeLicenseCustomProperties(properties: KeyMap<string>[]): KeyMap<string | string[]> {
  return properties.reduce(
    (acc, { name, value }) =>
      name === 'risk_reasons' ? { ...acc, [name]: value.split(',') } : { ...acc, [name]: value },
    {}
  );
}

export function deserializeExperiments(experiments: { name: ActiveExperiment, value: string }[]): Experiment[] {
  if (!experiments.length) {
    return null;
  }

  return experiments.map(({ name, value }) => ({
    name,
    variation: parseInt(value, 10),
  }));
}
