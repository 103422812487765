import {
  type IInsightsMetricDetails,
  type InsightsCoreData,
  type IInsightsState,
  type InsightsHintId,
} from '../interfaces';

export function getStateForGetInsights(state: IInsightsState): IInsightsState {
  return {
    ...state,
    isLoading: true,
  };
}

export function getStateForGetInsightsSuccess(state: IInsightsState, payload: InsightsCoreData): IInsightsState {
  return {
    ...state,
    ...payload,
    isLoading: false,
    error: false,
  };
}

export function getStateForGetInsightsFailure(state: IInsightsState): IInsightsState {
  return {
    ...state,
    isLoading: false,
    error: true,
  };
}

export function getStateForSelectMetric(state: IInsightsState, payload: IInsightsMetricDetails | null): IInsightsState {
  return {
    ...state,
    selectedMetricDetails: payload,
  };
}

export function getStateForAcknowledgeHintSuccess(state: IInsightsState, hintId: InsightsHintId): IInsightsState {
  return {
    ...state,
    acknowledgedHints: {
      ...state.acknowledgedHints,
      [hintId]: true,
    },
  };
}
