// @ts-strict-ignore
import isObject from 'lodash.isobject';

import type { PropertyValue, Properties, PropertyType, WithValue } from 'interfaces/property';

/**
 * Determines if property is wrapped in an object and property named `value`.
 * @param container Property may be contained in an object or a primitive.
 */
function hasValueFormat<T = PropertyType>(container: PropertyValue<T>): container is WithValue<T> {
  return isObject(container) && 'value' in container;
}

/**
 * Deserializes property if chat, thread, event, group etc. from v3.0+ responses.
 * @param properties Object containing properties, including the one to retrieve.
 * @param namespace Property business namespace.
 * @param key Property key/name.
 * @param defaultValue Fallback value in case of invalid format.
 * @see https://platform.text.com/docs/management/configuration-api/#properties
 */
export function getPropertyValue<T = PropertyType>(
  properties: Properties,
  namespace: string,
  key: string,
  defaultValue: T = null
): T {
  const hasValidFormat = properties && properties[namespace] && key in properties[namespace];

  if (!hasValidFormat) {
    return defaultValue;
  }

  const propertyContainer = properties[namespace][key] as PropertyValue<T>;

  return hasValueFormat(propertyContainer) ? propertyContainer.value : propertyContainer;
}
