import { type AgentPriority } from 'constants/agent-priority';
import { type LoginStatus } from 'constants/login-status';
import { type ApiClientBatchRequest } from 'services/connectivity/http/types';

import { type AgentBase, type AgentGroup, type WorkScheduler } from '../agents/types';

export interface Bot extends AgentBase {
  default_group_priority?: AgentPriority;
  login_status?: LoginStatus;
  timezone?: string;
  owner_client_id?: string;
}

export enum BotField {
  Groups = 'groups',
  MaxChatsCount = 'max_chats_count',
  WorkScheduler = 'work_scheduler',
  JobTitle = 'job_title',
  Timezone = 'timezone',
  LoginStatus = 'login_status',
  LastLogout = 'last_logout',
  DefaultGroupPriority = 'default_group_priority',
}

export interface CreateBotRequest {
  name: string;
  avatar?: string;
  max_chats_count?: number;
  default_group_priority?: string;
  job_title?: string;
  groups?: AgentGroup[];
  work_scheduler?: WorkScheduler;
}

export interface UpdateBotRequest extends Partial<Bot> {
  id: string;
}

export type BatchUpdateBotsRequest = ApiClientBatchRequest<UpdateBotRequest>;

export interface GetBotRequest {
  id: string;
  fields?: BotField[];
}

export interface ListBotsRequest {
  all?: boolean;
  fields?: BotField[];
}

export interface DeleteBotRequest {
  id: string;
}

export interface IssueBotTokenRequest {
  bot_id: string;
  client_id: string;
  bot_secret: string;
  organization_id: string;
}

export interface CreateBotResponse {
  id: string;
  secret: string;
}

export interface GetBotResponse extends Bot {}

export type ListBotsResponse = Bot[];

export interface IssueBotTokenResponse {
  token: string;
}
