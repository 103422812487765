import { type StateSnapshot } from 'react-virtuoso';

import { type ActionsUnion, createAction } from 'store/helper';

export enum CopilotViewActionNames {
  RESET_COPILOT_UNREAD_MESSAGES_COUNT = 'VIEWS/COPILOT/RESET_COPILOT_UNREAD_MESSAGES_COUNT',
  SET_COPILOT_IS_LOADING = 'VIEWS/COPILOT/SET_COPILOT_IS_LOADING',
  INCREASE_COPILOT_UNREAD_MESSAGES_COUNT = 'VIEWS/COPILOT/INCREASE_COPILOT_UNREAD_MESSAGES_COUNT',
  SET_COPILOT_LIST_STATE_SNAPSHOT = 'VIEWS/COPILOT/SET_COPILOT_LIST_STATE_SNAPSHOT',
  SAVE_COPILOT_DRAFT_MESSAGE = 'VIEWS/COPILOT/SAVE_COPILOT_DRAFT_MESSAGE',
  REMOVE_COPILOT_DRAFT_MESSAGE = 'VIEWS/COPILOT/REMOVE_COPILOT_DRAFT_MESSAGE',
  SHOW_COPILOT_MODAL = 'VIEWS/COPILOT/SHOW_COPILOT_MODAL',
  HIDE_COPILOT_MODAL = 'VIEWS/COPILOT/HIDE_COPILOT_MODAL',
  TOGGLE_COPILOT_MODAL_EXPAND = 'VIEWS/COPILOT/TOGGLE_COPILOT_MODAL_EXPAND',
  TOGGLE_COPILOT_MODAL = 'VIEWS/COPILOT/TOGGLE_COPILOT_MODAL',
  SET_SCROLL_POSITION = 'VIEWS/COPILOT/SET_SCROLL_POSITION',
  OPEN_COPILOT_DETAILS = 'VIEWS/COPILOT/OPEN_COPILOT_DETAILS',
  CLOSE_COPILOT_DETAILS = 'VIEWS/COPILOT/CLOSE_COPILOT_DETAILS',
  SHOW_COPILOT_NEW_MESSAGE_TOAST = 'VIEWS/COPILOT/SHOW_COPILOT_NEW_MESSAGE_TOAST',
  HIDE_COPILOT_NEW_MESSAGE_TOAST = 'VIEWS/COPILOT/HIDE_COPILOT_NEW_MESSAGE_TOAST',
  OPEN_COMMAND_SUGGESTIONS = 'VIEWS/COPILOT/OPEN_COMMAND_SUGGESTIONS',
  CLOSE_COMMAND_SUGGESTIONS = 'VIEWS/COPILOT/CLOSE_COMMAND_SUGGESTIONS',
  COPILOT_ADD_WEBSITE = 'ENTITIES/CHATS/COPILOT_ADD_WEBSITE',
}

export const CopilotViewActions = {
  resetCopilotUnreadMessagesCount: () => createAction(CopilotViewActionNames.RESET_COPILOT_UNREAD_MESSAGES_COUNT),
  setCopilotIsLoading: (isLoading: boolean) => createAction(CopilotViewActionNames.SET_COPILOT_IS_LOADING, isLoading),
  increaseCopilotUnreadMessagesCount: (count: number) =>
    createAction(CopilotViewActionNames.INCREASE_COPILOT_UNREAD_MESSAGES_COUNT, count),
  setCopilotListStateSnapshot: (listStateSnapshot: StateSnapshot | null) =>
    createAction(CopilotViewActionNames.SET_COPILOT_LIST_STATE_SNAPSHOT, listStateSnapshot),
  saveCopilotDraftMessage: (draftMessage: string) =>
    createAction(CopilotViewActionNames.SAVE_COPILOT_DRAFT_MESSAGE, draftMessage),
  removeCopilotDraftMessage: () => createAction(CopilotViewActionNames.REMOVE_COPILOT_DRAFT_MESSAGE),
  showModal: (isExpanded?: boolean) => createAction(CopilotViewActionNames.SHOW_COPILOT_MODAL, isExpanded),
  hideModal: () => createAction(CopilotViewActionNames.HIDE_COPILOT_MODAL),
  toggleModal: () => createAction(CopilotViewActionNames.TOGGLE_COPILOT_MODAL),
  toggleExpand: () => createAction(CopilotViewActionNames.TOGGLE_COPILOT_MODAL_EXPAND),
  setScrollPosition: (scrollPosition: number | null) =>
    createAction(CopilotViewActionNames.SET_SCROLL_POSITION, scrollPosition),
  openDetails: () => createAction(CopilotViewActionNames.OPEN_COPILOT_DETAILS),
  closeDetails: () => createAction(CopilotViewActionNames.CLOSE_COPILOT_DETAILS),
  showMessageToast: () => createAction(CopilotViewActionNames.SHOW_COPILOT_NEW_MESSAGE_TOAST),
  hideMessageToast: () => createAction(CopilotViewActionNames.HIDE_COPILOT_NEW_MESSAGE_TOAST),
  openCommandSuggestions: () => createAction(CopilotViewActionNames.OPEN_COMMAND_SUGGESTIONS),
  closeCommandSuggestions: () => createAction(CopilotViewActionNames.CLOSE_COMMAND_SUGGESTIONS),
  addWebsite: (payload: string) => createAction(CopilotViewActionNames.COPILOT_ADD_WEBSITE, payload),
};

export type CopilotViewAction = ActionsUnion<typeof CopilotViewActions>;
