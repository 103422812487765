import { isHexColor } from './validate';

export function rgbaToHex(rgba: string): string {
  const rgbaRegexp = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

  function parseHexPart(hexpart: string): string {
    const hexDec = parseInt(hexpart, 10);
    const hexString = `0${hexDec.toString(16)}`;

    return hexString.slice(-2);
  }

  return (
    rgbaRegexp && rgbaRegexp.length === 4
      ? `#${parseHexPart(rgbaRegexp[1])}${parseHexPart(rgbaRegexp[2])}${parseHexPart(rgbaRegexp[3])}`
      : ''
  ).toUpperCase();
}

export function getOpacityFromRGBA(rgba: string): number {
  const opacity = parseFloat(rgba.replace(/^.*,(.+)\)/, '$1'));

  return typeof opacity === 'number' ? opacity : 1;
}

export function hexToRGBA(hex: string, opacity = 1): string {
  const hexRegexpt = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = hexRegexpt
    ? {
        r: parseInt(hexRegexpt[1], 16),
        g: parseInt(hexRegexpt[2], 16),
        b: parseInt(hexRegexpt[3], 16),
      }
    : null;

  return rgb ? `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})` : '';
}

export function getOpacityFromColor(color: string) {
  return isHexColor(color) ? 1 : getOpacityFromRGBA(color);
}

export function getHexFromColor(color: string) {
  return (isHexColor(color) ? color : rgbaToHex(color)).toUpperCase();
}

export function getRGBAFromColor(color: string) {
  return (isHexColor(color) ? hexToRGBA(color) : color.replace(new RegExp(' ', 'g'), '')).toUpperCase();
}

export function getHexFromDec(value: number): string {
  const hex = value.toString(16).toUpperCase();

  return hex.length < 2 ? `0${hex}` : hex;
}

export function getHexFromRGB(rgb: string) {
  const rgbRegexp = rgb.match(/^rgb?a?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

  return rgbRegexp && rgbRegexp.length >= 3
    ? `#${getHexFromDec(parseInt(rgbRegexp[1], 10))}${getHexFromDec(parseInt(rgbRegexp[2], 10))}${getHexFromDec(
        parseInt(rgbRegexp[3], 10)
      )}`
    : '';
}

export function hexToRGB(hex: string): string {
  const hexRegexpt = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = hexRegexpt
    ? {
        r: parseInt(hexRegexpt[1], 16),
        g: parseInt(hexRegexpt[2], 16),
        b: parseInt(hexRegexpt[3], 16),
      }
    : null;

  return rgb ? `rgb(${rgb.r},${rgb.g},${rgb.b})` : '';
}
