import { type PlanType } from 'constants/plan-type';
import { type ICurrentLicense } from 'interfaces/entities/current-license';
import { createAction, type ActionsUnion } from 'store/helper';

import { type IAuthenticationCredentialsPayload, type IRights, type ISaveGhostStatusPayload } from './interfaces';

export enum SessionActionNames {
  AGENT_LOGOUT = 'ENTITIES/AGENTS/AGENT_LOGOUT',
  SAVE_AUTHENTICATION_CREDENTIALS = 'SAVE_AUTHENTICATION_CREDENTIALS',
  SAVE_CURRENT_LICENSE = 'SAVE_CURRENT_LICENSE',
  SAVE_LICENSE_PLAN = 'SAVE_LICENSE_PLAN',
  SAVE_RIGHTS = 'SAVE_RIGHTS',
  SAVE_GHOST_STATUS = 'SAVE_GHOST_STATUS',
  SAVE_HAS_LOGGED_IN = 'SAVE_HAS_LOGGED_IN',
  SAVE_AUTO_AWAY_STATUS = 'SAVE_AUTO_AWAY_STATUS',
  UPDATE_RIGHTS = 'UPDATE_RIGHTS',
}

export const SessionActions = {
  agentLogout: () => createAction(SessionActionNames.AGENT_LOGOUT),
  saveAuthenticationCredentials: (credentials: IAuthenticationCredentialsPayload) =>
    createAction(SessionActionNames.SAVE_AUTHENTICATION_CREDENTIALS, credentials),
  saveCurrentLicense: (license: Partial<ICurrentLicense>) =>
    createAction(SessionActionNames.SAVE_CURRENT_LICENSE, { license }),
  saveLicensePlan: (plan: PlanType) => createAction(SessionActionNames.SAVE_LICENSE_PLAN, { plan }),
  updateRights: () => createAction(SessionActionNames.UPDATE_RIGHTS),
  saveRights: (rights: IRights) => createAction(SessionActionNames.SAVE_RIGHTS, { rights }),
  saveGhostStatus: (payload: ISaveGhostStatusPayload) => createAction(SessionActionNames.SAVE_GHOST_STATUS, payload),
  saveHasLoggedIn: (hasLoggedIn: boolean) => createAction(SessionActionNames.SAVE_HAS_LOGGED_IN, { hasLoggedIn }),
  saveAutoAwayStatus: (isAway: boolean) => createAction(SessionActionNames.SAVE_AUTO_AWAY_STATUS, { isAway }),
};

export type SessionAction = ActionsUnion<typeof SessionActions>;
