import { type ReactNode, Component } from 'react';

import * as Sentry from '@sentry/browser';

interface IProps {
  onRenderError({ message }: { message: string }): ReactNode;
}

interface IState {
  hasError: boolean;
  message: string;
}

export class GenericErrorBoundary extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      message: '',
    };
  }

  componentDidCatch(error: Error) {
    this.setState({ hasError: true, message: error.toString() });
    Sentry.captureException(error);
  }

  render() {
    const { hasError, message } = this.state;

    if (hasError) return this.props.onRenderError({ message });

    return this.props.children;
  }
}
