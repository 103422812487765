import { createElement } from 'react';

import { css } from '@emotion/css';
import { render } from 'react-dom';

import { Application } from 'components/Application';
import { setTitle } from 'helpers/routing';

import { hideGlobalLoader } from './global-loader';

const container = css`
  width: 100%;
`;

let isRendered = false;

export function renderMainLayout(): void {
  const div = document.createElement('div');
  div.className = container;
  div.id = 'main-layout-wrapper';
  document.body.appendChild(div);

  setTitle('LiveChat');
  hideGlobalLoader();

  render(createElement(Application), div);

  isRendered = true;
}

export function isMainLayoutRendered(): boolean {
  return isRendered;
}
