import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type SendMulticastEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#multicast
 */
export async function sendMulticast(
  recipients: Record<string, Record<string, boolean | string[]>>,
  content: Record<string, string | string[] | boolean>
): Promise<unknown> {
  const event: SendMulticastEvent = {
    action: WebSocketAction.Multicast,
    payload: {
      recipients,
      content,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
