export enum RecurlyCoupon {
  CovidBusiness = '2024-business-plan-in-price-of-team-for-3-months',
  BlackFridayLC2021 = 'bfcm2021lc',
  BlackFridayPPLC2021 = 'bfcm2021pplc',
  BlackFridayPPLCALL2021 = 'bfcm2021pplcall',
  BlackFridayLC2022 = 'bfcm2022lc',

  BlackFriday24TrialAndMonthly = 'blackfridaydeal24',
  CyberMonday24TrialAndMonthly = 'cybermondaydeal24',
  BlackFriday24Starter = 'blackfridaydeal12',
  CyberMonday24Starter = 'cybermondaydeal12',
  BlackFriday24Team = 'blackfriday12',
  CyberMonday24Team = 'cybermonday12',

  BlackFriday24Churned = 'blackfridaydeal41',
  CyberMonday24Churned = 'cybermondaydeal41',
  BlackFriday24ExpiredTrial = 'blackfriday41',
  CyberMonday24ExpiredTrial = 'cybermonday41',
}
