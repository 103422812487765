import isObject from 'lodash.isobject';
import isString from 'lodash.isstring';

import { AgentValidationError, PaymentError } from 'constants/error';
import type { RequestError } from 'services/api/types';

const unexpectedError = 'Unexpected error occured.';

export function errorDeserialize(error?: RequestError | RequestError[] | string): string {
  if (!error) {
    return unexpectedError;
  }

  if (isString(error)) {
    return error;
  }

  if (Array.isArray(error)) {
    return error.map((err) => (err.error as RequestError).message).join(', ');
  }

  if (isObject(error.error) && 'message' in error.error) {
    return error.error.message;
  }

  return error.error || error.message || error.errors?.join(', ') || unexpectedError;
}

export function paymentErrorMapper(error: string): string | null {
  if (error.includes('Insufficient funds in your account')) {
    return PaymentError.InsufficientFunds;
  }

  if (error.includes('Billing information missing')) {
    return PaymentError.BillingInformationMissing;
  }

  if (error.includes('Transaction declined')) {
    return PaymentError.TransactionDeclined;
  }

  return null;
}

export function agentErrorMapper(error: string, groupsLimit: number): string | null {
  if (error.includes('Group not found')) {
    return AgentValidationError.GroupNotFound;
  }

  if (error.includes('Agent limit reached')) {
    return AgentValidationError.AgentLimitReached;
  }

  if (error.includes('The agent is assigned to too many groups')) {
    return AgentValidationError.TooManyGroupsLimitReached.replace('{{groupsLimit}}', groupsLimit.toString());
  }

  return null;
}
