import { type SagaIterator } from 'redux-saga';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import { BusinessModel } from 'constants/business-model';
import { PostMessageEvent } from 'constants/post-message-event';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type ISubscriptionDTO } from 'services/api/subscription/interfaces';
import { SubscriptionSerializer } from 'services/api/subscription/serializer';
import { AgentActionNames } from 'store/entities/agents/actions';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';
import { SubscriptionActions } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { getBusinessModel } from 'store/features/session/selectors';
import { mapSubscriptionToNewSubscription } from 'store/sagas/subscription/helpers';
import { SubscriptionViewActionNames } from 'store/views/subscription/actions';

/**
 * Refetch subscription when new agent is added/removed to/from the license
 */
function* updateSubscriptionSeats(): SagaIterator {
  const subscription: ReturnType<typeof getSubscription> = yield select(getSubscription);
  const agentsCount: ReturnType<typeof getActiveAgentsCount> = yield select(getActiveAgentsCount);
  const businessModel: ReturnType<typeof getBusinessModel> = yield select(getBusinessModel);

  if (!subscription) {
    return;
  }

  if (
    businessModel === BusinessModel.PayPerAgent &&
    (agentsCount > subscription.seats || subscription.inTrial || subscription.isCanceled)
  ) {
    const { result, error }: RequestResult<ISubscriptionDTO> = yield call(ApiManager.subscriptionApi.fetch);

    if (error) {
      throw error;
    }
    const newSubscription = SubscriptionSerializer.deserialize(result);
    yield put(SubscriptionActions.subscriptionFetched(newSubscription));
    yield call(mapSubscriptionToNewSubscription, newSubscription);
    yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshSubscriptionInfo);
  }
}

/**
 * Optimistic update of subscription when new agent is added/removed to/from the license
 */
function* optimisticallyUpdateSubscriptionSeats(): SagaIterator {
  const subscription: ReturnType<typeof getSubscription> = yield select(getSubscription);
  const agentsCount: ReturnType<typeof getActiveAgentsCount> = yield select(getActiveAgentsCount);
  const businessModel: ReturnType<typeof getBusinessModel> = yield select(getBusinessModel);

  if (!subscription) {
    return;
  }

  if (
    businessModel === BusinessModel.PayPerAgent &&
    (agentsCount > subscription.seats || (subscription.inTrial && subscription.seats === 100))
  ) {
    yield put(SubscriptionActions.subscriptionUpdated({ seats: agentsCount }));
    yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshSubscriptionInfo);
  }
}

export function* refreshSubscriptionSaga(): SagaIterator {
  yield takeLatest(
    [AgentActionNames.AGENT_ADD, SubscriptionViewActionNames.AGENTS_COUNT_CHANGED],
    optimisticallyUpdateSubscriptionSeats
  );
  yield debounce(
    3000,
    [AgentActionNames.AGENT_ADD, SubscriptionViewActionNames.AGENTS_COUNT_CHANGED],
    updateSubscriptionSeats
  );
}
