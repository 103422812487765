import { type INewSubscription, BillingCycleType, type ISubscription } from 'interfaces/subscription';

import { SubscriptionActionNames, type SubscriptionActionTypes } from './actions';
import { getStateForSubscriptionChange } from './helpers';
import { type ISubscriptionState } from './interfaces';

const initialState: ISubscriptionState = {
  planPrices: {},
  subscription: null,
  subscriptionChange: {
    billingCycle: BillingCycleType.Annually12,
  } as INewSubscription,
};

export const subscriptionReducer = (
  state: ISubscriptionState = initialState,
  action: SubscriptionActionTypes
): ISubscriptionState => {
  switch (action.type) {
    case SubscriptionActionNames.PLANS_FETCHED: {
      return {
        ...state,
        planPrices: action.payload,
      };
    }

    case SubscriptionActionNames.CREATE_SUBSCRIPTION_SUCCESS:
    case SubscriptionActionNames.UPDATE_SUBSCRIPTION_SUCCESS:
    case SubscriptionActionNames.UPGRADE_SUBSCRIPTION_SUCCESS:
    case SubscriptionActionNames.SUBSCRIPTION_FETCHED: {
      return {
        ...state,
        subscription: action.payload,
      };
    }

    case SubscriptionActionNames.SUBSCRIPTION_UPDATED: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...(action.payload as ISubscription),
        },
      };
    }

    case SubscriptionActionNames.SUBSCRIPTION_CHANGE_UPDATED: {
      return getStateForSubscriptionChange(state, action.payload);
    }

    default:
      return state;
  }
};
