export const enum Icons {
  Add = 'add',
  Agents = 'agents',
  Android = 'android',
  AppleLogo = 'apple-logo',
  AppLogoFallback = 'app-logo-fallback',
  Apps = 'apps',
  ArrowDown = 'arrow-down',
  ArrowDownCircle = 'arrow-down-circle',
  ArrowExit = 'arrow-exit',
  ArrowLeft = 'arrow-left',
  ArrowLeftShort = 'arrow-left-short',
  ArrowRight = 'arrow-right',
  ArrowRightShort = 'arrow-right-short',
  ArrowsVertical = 'arrows-vertical',
  ArrowsVerticalCollapse = 'arrows-vertical-collapse',
  ArrowTop = 'arrow-top',
  Audio = 'audio-note',
  Ballpen = 'ballpen',
  Block = 'block',
  BlockUser = 'block-user',
  Call = 'call',
  CannedResponseDrawn = 'canned-response-drawn',
  Channels = 'channels',
  Chart = 'chart',
  Chat = 'chat',
  ChatBarWindow = 'chat-bar-window',
  ChatBasicWindow = 'chat-basic-window',
  ChatBot = 'logo-cb',
  ChatBubbleWindow = 'chat-bubble-window',
  ChatModernWindow = 'chat-modern-window',
  CheckMark = 'check-mark',
  ChevronDown = 'chevron-down',
  ChevronUp = 'chevron-up',
  Close = 'close',
  CloseFixed = 'close-fixed',
  Code = 'code',
  Copy = 'copy',
  CreditCard = 'credit-card',
  Customers = 'customers',
  CustomVariable = 'custom-variable',
  Description = 'description',
  Desktop = 'desktop',
  Device = 'device',
  Disabled = 'disabled',
  DismissChat = 'dismiss-chat',
  Domain = 'domain',
  Download = 'download',
  Draggable = 'draggable',
  Edit = 'edit',
  EmojiActivities = 'emoji-activities',
  EmojiAnimals = 'emoji-animals',
  EmojiFlags = 'emoji-flags',
  EmojiFoods = 'emoji-foods',
  EmojiFrequentlyUsed = 'emoji-frequently-used',
  EmojiObjects = 'emoji-objects',
  EmojiSmiles = 'emoji-smiles',
  EmojiSymbols = 'emoji-symbols',
  EmojiTravels = 'emoji-travels',
  Error = 'error',
  ErrorOutline = 'error-outline',
  Facebook = 'facebook',
  FileTypeAudio = 'audio',
  FileTypeExe = 'exe',
  FileTypeHtml = 'html',
  FileTypeImage = 'image',
  FileTypeOther = 'other',
  FileTypePdf = 'pdf',
  FileTypePpt = 'ppt',
  FileTypeTxt = 'txt',
  FileTypeVideo = 'video',
  FileTypeXls = 'xls',
  FileTypeZip = 'zip',
  FilledCheckMark = 'filled-check-mark',
  FormatBold = 'format-bold',
  FormatItalic = 'format-italic',
  FormatLink = 'format-link',
  FormatListBulleted = 'format-list-bulleted',
  FormatListNumbered = 'format-list-numbered',
  GreetingCard = 'greeting-card',
  GreetingPlainText = 'greeting-plain-text',
  GreetingQuickReplies = 'greeting-quick-replies',
  Group = 'group',
  HandWave = 'hand-wave',
  HelpDesk = 'logo-hd',
  HorseToy = 'horse-toy',
  Hourglass = 'hourglass',
  Incognito = 'incognito',
  Info = 'info',
  Keyboard = 'keyboard',
  KnowledgeBase = 'logo-kb',
  Language = 'language',
  Link = 'link',
  LiveChatIcon = 'livechat-icon',
  LiveChatLogo = 'livechat-logo',
  LockClosed = 'lock-closed',
  LockOpened = 'lock-opened',
  Mail = 'mail',
  Mobile = 'Mobile',
  MoodSmileBeam = 'mood-smile-beam',
  Moon = 'moon',
  More = 'more',
  Notifications = 'notifications',
  NotificationsImportant = 'notification-important',
  Offline = 'offline',
  OpenInNewWindow = 'open-in-new-window',
  OpenMoment = 'open-moment',
  Pin = 'pin',
  Place = 'place',
  Plane = 'plane',
  Play = 'play',
  PlusClear = 'plus-clear',
  Preview = 'preview',
  PushPin = 'push-pin',
  RadioCircle = 'radio-circle',
  RoundFilledCheckMark = 'round-filled-check-mark',
  Search = 'search',
  SendMessage = 'send-message',
  Shirt = 'shirt',
  Spinner = 'spinner',
  Sun = 'sun',
  Tag = 'tag',
  Text = 'text',
  TextBlock = 'text-block',
  TextSpellcheck = 'text-spellcheck',
  ThemeAdditionalTeaks = 'theme-additional-tweaks',
  ThemeAppearance = 'theme-appearance',
  ThemeMobileWidget = 'theme-mobile-widget',
  ThemePosition = 'theme-position',
  ThumbsUp = 'thumbs-up',
  Ticket = 'ticket',
  TicketCheckDrawn = 'ticket-check-drawn',
  Tie = 'tie',
  Time = 'time',
  Tool = 'tool',
  TrashBin = 'trash-bin',
  TrashBinOutline = 'trash-bin-outline',
  Unpin = 'unpin',
  Upload = 'upload',
  VariablePlus = 'variable-plus',
  VerifiedUser = 'verified-user',
  Visibility = 'visibility',
  Waiting = 'waiting',
  Wand = 'wand',
  GradientWandLight = 'gradient-wand-light',
  Warning = 'warning',
  WarningAlert = 'warning-alert',
  WarningHandDrawn = 'warning-hand-drawn',
  WarningHandPointing = 'warning-hand-pointing',
  WarningOutline = 'warning-outline',
  WarningTriangleOutline = 'warning-triangle-outline',
  Windows = 'windows',
  World = 'world',
}
