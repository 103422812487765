import { type FC, type HTMLAttributes, type ReactNode } from 'react';

import { Heading } from '@livechat/design-system-react-components';

import * as styles from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  rightNode?: ReactNode;
  customHeader?: ReactNode;
  noGaps?: boolean;
}

export const SideNavigationContainer: FC<IProps> = ({ title, rightNode, customHeader, noGaps, children, ...props }) => {
  const header = customHeader || (
    <div className={styles.headerRow}>
      <Heading size="sm" className={styles.title}>
        {title}
      </Heading>
      {rightNode}
    </div>
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles.container} {...props}>
      {header}
      <div className={styles.navigationWrapper(noGaps)}>{children}</div>
    </div>
  );
};
