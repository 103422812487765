export const DETAILS_PANEL_TEST_ID = 'details-panel';
export const DETAILS_CUSTOMER_TAB = 'customer-details';
export const DETAILS_ADD_TAB = 'add-app';

export const VISIT_TIME_REFRESH_INTERVAL = 3000;

export enum CustomerDetailsSection {
  GeneralInfo = 'generalInfo',
  Tickets = 'tickets',
  IntegrationsData = 'integrationsData',
  Facebook = 'facebook',
  Prechat = 'prechat',
  Postchat = 'postchat',
  VisitedPages = 'visitedPages',
  AdditionalInfo = 'additionalInfo',
  Technology = 'technology',
  ChatSummary = 'chatSummary',
  FacebookVariables = 'facebookvariables',
  Goals = 'goals',
  ChatInfo = 'chatInfo',
  CustomVariables = 'customvariables',
  ReplySuggestions = 'replySuggestions',
}

export enum CustomerDetailsSectionTitles {
  GeneralInfo = 'General info',
  Tickets = 'Tickets',
  IntegrationsData = 'Integrations data',
  Facebook = 'Facebook',
  Prechat = 'Pre-chat form',
  Postchat = 'Post-chat form',
  VisitedPages = 'Visited pages',
  AdditionalInfo = 'Additional info',
  Technology = 'Technology',
  ChatSummary = 'Chat summary',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Facebookvariables = 'Facebook', // Archives version
  Goals = 'Goals',
  ChatInfo = 'Chat info',
  Customvariables = 'Custom variables',
  Copilot = 'Copilot',
}

export const CUSTOMER_DETAILS_DEFAULT_SECTIONS = [
  CustomerDetailsSection.GeneralInfo,
  CustomerDetailsSection.Tickets,
  CustomerDetailsSection.IntegrationsData,
  CustomerDetailsSection.Facebook,
  CustomerDetailsSection.Prechat,
  CustomerDetailsSection.Postchat,
  CustomerDetailsSection.VisitedPages,
  CustomerDetailsSection.AdditionalInfo,
  CustomerDetailsSection.Technology,
  CustomerDetailsSection.ChatSummary,
  CustomerDetailsSection.ReplySuggestions,
];

export const enum DetailsColumnEvent {
  DetailsWidgetAppClicked = 'Details widget app action taken',
  ShopifyTooltipClosed = 'Shopify customer insights tooltip - closed',
  ChatSummaryCreated = 'Chat summary created',
  ChatSummaryUpdated = 'Chat summary updated',
  ChatSummaryCopied = 'Chat summary copied',
  ChatSummaryDeleted = 'Chat summary deleted',
  ChatSummaryErrorTooShort = 'Chat summary error - too short',
  ChatSummaryErrorTooLong = 'Chat summary error - too long',
  ChatSummaryButtonClicked = 'Chat summary button clicked',
  ChatSummaryWidgetFold = 'Chat summary widget fold',
  ChatSummaryWidgetUnfold = 'Chat summary widget unfold',
  ChatSummaryOptionsOpened = 'Chat summary options opened',
  IntegrationsOrderSettingsOpened = 'Integrations order settings opened',
}
