import { type FC } from 'react';

import { cx } from '@emotion/css';

import { type LoaderCircleThickness } from './interfaces';

import * as styles from './styles';

interface IProps {
  thickness?: LoaderCircleThickness;
  className?: string;
}

export const LoaderCircle: FC<IProps> = ({ thickness, className }) => (
  <div className={cx(styles.loaderCircle(thickness), className)} />
);
