import { type SagaIterator } from 'redux-saga';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { isDesktopAppDetected } from 'helpers/desktop-app/is-detected';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getDesktopAppInstalled } from 'store/features/agent-custom-properties/selectors';

function* detectSmartClientUsage(): SagaIterator {
  if (isDesktopAppDetected()) {
    const desktopAppInstalled = yield select(getDesktopAppInstalled);

    if (desktopAppInstalled !== '1') {
      yield put(
        AgentCustomPropertiesActions.setAgentCustomProperty({
          [AgentCustomPropertyName.DesktopAppInstalled]: '1',
        }),
      );
    }
  }
}

export function* smartClientSaga(): SagaIterator {
  yield all([takeEvery('APP_READY', detectSmartClientUsage)]);
}
