import { combineReducers } from 'redux';

import type { IFeaturesState } from 'interfaces/store/features-state';

import { agentCustomPropertiesReducer } from './agent-custom-properties/reducer';
import { applicationsReducer } from './applications/reducer';
import { codeInstallationReducer } from './code-installation/reducer';
import { customSegmentsReducer } from './custom-segments/reducer';
import { globalModalsReducer } from './global-modals/reducer';
import { gtmReducer } from './google-tag-manager/reducer';
import { inviteLinkReducer } from './invitation-link/reducer';
import { notificationsBarReducer } from './notifications-bar/reducer';
import { routingReducer } from './routing/reducer';
import { sessionReducer } from './session/reducer';
import { shortcutsReducer } from './shortcuts/reducer';
import { toastReducer } from './toasts/reducer';
import { tooltipsReducer } from './tooltips/reducer';
import { unsavedChangesReducer } from './unsaved-changes/reducer';
import { userGuidedToursReducer } from './user-guided-tours/reducer';

export const featuresReducer = combineReducers<IFeaturesState>({
  agentCustomProperties: agentCustomPropertiesReducer,
  applications: applicationsReducer,
  codeInstallation: codeInstallationReducer,
  customSegments: customSegmentsReducer,
  notificationsBar: notificationsBarReducer,
  routing: routingReducer,
  session: sessionReducer,
  shortcuts: shortcutsReducer,
  toast: toastReducer,
  tooltips: tooltipsReducer,
  userGuidedTours: userGuidedToursReducer,
  unsavedChanges: unsavedChangesReducer,
  globalModals: globalModalsReducer,
  inviteLink: inviteLinkReducer,
  googleTagManager: gtmReducer,
});
