import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { SprigEvents } from 'constants/sprig-events';
import type { IAppState } from 'services/app-state-provider';
import { getSprigService } from 'services/sprig';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { isUnassignedChat } from 'store/entities/chats/helpers/common';
import {
  getCanUseTagsSuggestionFeature,
  getIsOtherAgentThread,
  getIsQueueThread,
  getThread,
  getThreadEvents,
} from 'store/entities/chats/selectors';
import { ChatsViewActions } from 'store/views/chats/actions';
import { getIsChatSelected } from 'store/views/chats/selectors';

import { type IChatDeactivatedPushEvent } from '../interfaces';

/**
 * Handles chat deactivated push event.
 * 1. Remove chat from queue.
 * 2. Transition from Queued to Unassigned.
 * 3. Mark chat as deactivated.
 * @param {IChatDeactivatedPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleChatDeactivated(payload: IChatDeactivatedPushEvent, store: IAppState): void {
  const { thread_id: threadId } = payload;
  const state = store.getState();
  const thread = getThread(state, threadId);

  if (thread) {
    const isQueuedThread = getIsQueueThread(state, threadId);
    const isTagSuggestionsEnabled = getCanUseTagsSuggestionFeature(state);
    const isFocused = getIsChatSelected(state, threadId);

    if (isFocused && isTagSuggestionsEnabled) {
      store.dispatch(
        ChatsEntitiesActions.fetchTagSuggestions({
          threadId,
        })
      );
    }

    /**
     * 1. Remove chat from queue.
     * In case when chat was deactivated - eg. queued visitor left page
     */
    if (isQueuedThread) {
      const isContinuousQueuedThread = thread.customProperties?.isContinuous;
      if (isContinuousQueuedThread) {
        const events = getThreadEvents(state, thread.threadId);

        /**
         * 2. Transition from Queued to Unassigned.
         */
        store.dispatch(
          ChatsEntitiesActions.incomingChatThread({
            thread: {
              ...thread,
              type: ChatType.Unassigned,
              status: ChatThreadStatus.Closed,
              customProperties: {
                ...thread.customProperties,
                wasUnassigned: true,
              },
            },
            events,
            convertedFrom: ChatType.Queued,
            previousThreadId: thread.threadId,
          })
        );

        store.dispatch(
          ChatsEntitiesActions.updateUnassignedChatsCount({
            threadId: thread.threadId,
            type: 'increase',
          })
        );
      } else {
        store.dispatch(ChatsEntitiesActions.removeChatThread({ threadId }));
      }
    } else {
      /**
       * 3. Mark chat as deactivated.
       */
      const isOtherAgentThread = getIsOtherAgentThread(state, threadId);

      if (!isOtherAgentThread) {
        store.dispatch(
          ChatsEntitiesActions.updateChatThread({
            threadId,
            thread: {
              status: ChatThreadStatus.Closed,
              incomplete: thread.incomplete || isUnassignedChat(thread), // unassigned chat needs to be refetched after select
              customProperties: {
                ...thread.customProperties,
                wasChatDeactivated: true,
              },
            },
          })
        );
        store.dispatch(ChatsViewActions.unmarkChatThreadIdsAsNew({ threadId }));
        // init sprig event
        void getSprigService().initSprigEvent(SprigEvents.ChatClosed);
      } else {
        store.dispatch(ChatsEntitiesActions.removeChatThread({ threadId }));
      }
    }
  }
}
