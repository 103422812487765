import { type ActionsUnion, createAction } from 'store/helper';

import type { BadgeKey, BadgeValue } from './interfaces';

export enum BadgeActionNames {
  SET_BADGE = 'ENTITIES/BADGES/SET_BADGE',
}

export const BadgeActions = {
  setBadge: (key: BadgeKey, value: BadgeValue) => createAction(BadgeActionNames.SET_BADGE, { key, value }),
};

export type BadgeAction = ActionsUnion<typeof BadgeActions>;
