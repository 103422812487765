import { AppStateProvider } from 'services/app-state-provider';
import { DevToolsActions } from 'store/views/dev-tools/actions';

import { areDevToolsEnabled, areDevToolsInitiallyVisible } from './helpers';

export async function loadDevTools(): Promise<void> {
  if (areDevToolsEnabled()) {
    const { enableDevTools } = await import('components/development/dev-tools/DevTools');
    AppStateProvider.dispatch(DevToolsActions.toggle({ isVisible: areDevToolsInitiallyVisible() }));
    enableDevTools();
  }
}
