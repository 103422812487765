// @ts-strict-ignore
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { DateFilterId } from 'constants/filters/date-filter';
import { CustomDateRangeFilterId } from 'constants/filters/date-range-filter';
import { Filter, type FiltersOperators } from 'constants/filters/filter';
import { type ReportDistribution } from 'constants/reports/distribution';
import { type ReportType } from 'constants/reports/report-type';
import { getDateFilterOptions } from 'helpers/date-filter/options';
import {
  getCompareOptions,
  getOptions as getDateRangeFilterOptions,
} from 'helpers/date-range-filter';
import { type KeyMap } from 'helpers/interface';
import { uniqueId } from 'helpers/string';
import type {
  INewReportView,
  IReportView,
  IReportViewsById,
  ISerializedReportView,
  ISerializedReportViewParams,
  ReportsViewFilters,
} from 'interfaces/reports';
import {
  type AgentCustomPropertyDataType,
  AgentCustomPropertyName,
  type IReportViewsAgentCustomProperty,
} from 'store/features/agent-custom-properties/interfaces';

export function getReportViewsByIdFromEntries(entries: KeyMap<AgentCustomPropertyDataType>): IReportViewsById {
  return Object.keys(entries)
    .filter((key) => key.startsWith(AgentCustomPropertyName.ReportViewPrefix))
    .reduce<IReportViewsById>((reportViewsById, key) => {
      const viewData = entries[key] as ISerializedReportView;

      if (!viewData) {
        return reportViewsById;
      }

      const { id } = viewData;

      reportViewsById[id] = deserializeView(viewData);

      return reportViewsById;
    }, {});
}

export function createAgentCustomPropertiesFromReportView(view: IReportView): Record<string, ISerializedReportView> {
  const { id } = view;

  const key = getReportViewAgentCustomPropertyKey(id);

  const reportViewCustomProperty = serializeView(view);

  return {
    [key]: reportViewCustomProperty,
  };
}

export function createReportView(
  newView: INewReportView,
  type: ReportType,
  filters: ReportsViewFilters,
  distribution: ReportDistribution,
  operators: FiltersOperators
): IReportView {
  const now = new Date();
  const params = Object.fromEntries(
    Object.entries({ ...filters, distribution, operators }).filter(([_, value]) => Boolean(value))
  );

  return {
    ...newView,
    id: uniqueId().slice(-16),
    createdAt: now,
    type,
    params,
  };
}

function deserializeView(data: IReportViewsAgentCustomProperty): IReportView {
  const {
    createdAt,
    params: { [Filter.Date]: dateFilter, [Filter.DateRange]: dateRangeFilter, ...params },
    ...view
  } = data;

  if (dateFilter) {
    const option = getDateFilterOptions().find(({ id }) => id === dateFilter.id);

    if (option) {
      params[Filter.Date] = {
        id: option.id,
        date: option.id === DateFilterId.CustomDate && dateFilter.date ? new Date(dateFilter.date) : option.value.date,
      };
    }
  }

  if (dateRangeFilter) {
    const option = getDateRangeFilterOptions().find(({ id }) => id as CustomDateRangeFilterId === dateRangeFilter.id);

    if (option) {
      const isCustom = option.id as CustomDateRangeFilterId === CustomDateRangeFilterId.CustomPeriod;

      const deserializedDateRangeFilter: ReportsViewFilters[Filter.DateRange] = {
        id: option.id as CustomDateRangeFilterId,
        from: isCustom && dateRangeFilter.from ? new Date(dateRangeFilter.from) : option.value.from,
        to: isCustom && dateRangeFilter.to ? new Date(dateRangeFilter.to) : option.value.to,
      };

      if (dateRangeFilter.compare) {
        const compareOption = getCompareOptions(deserializedDateRangeFilter.from, deserializedDateRangeFilter.to).find(
          ({ id }) => id as CustomDateRangeFilterId === dateRangeFilter.compare.id
        );

        if (compareOption) {
          const isCompareCustom = compareOption.id as CustomDateRangeFilterId === CustomDateRangeFilterId.CustomPeriod;

          deserializedDateRangeFilter.compare = {
            id: compareOption.id as CustomDateRangeFilterId,
            from:
              isCompareCustom && dateRangeFilter.compare.from
                ? new Date(dateRangeFilter.compare.from)
                : compareOption.value.from,
            to:
              isCompareCustom && dateRangeFilter.compare.to
                ? new Date(dateRangeFilter.compare.to)
                : compareOption.value.to,
          };
        }
      }

      params[Filter.DateRange] = deserializedDateRangeFilter;
    }
  }

  return {
    ...view,
    createdAt: new Date(createdAt),
    params,
  };
}

function serializeView(view: IReportView): ISerializedReportView {
  const {
    createdAt,
    params: { [Filter.Date]: dateFilter, [Filter.DateRange]: dateRangeFilter, ...params },
    ...viewData
  } = view;

  if (dateFilter) {
    const serializedDateFilter: ISerializedReportViewParams[Filter.Date] = {
      id: dateFilter.id,
    };

    if (dateFilter.id === DateFilterId.CustomDate) {
      serializedDateFilter.date = format(dateFilter.date, DateFormat.ISO8601Date);
    }

    params[Filter.Date] = serializedDateFilter;
  }

  if (dateRangeFilter) {
    const serializedDateRangeFilter: ISerializedReportViewParams[Filter.DateRange] = {
      id: dateRangeFilter.id,
    };

    if (dateRangeFilter.id === CustomDateRangeFilterId.CustomPeriod) {
      serializedDateRangeFilter.from = format(dateRangeFilter.from, DateFormat.ISO8601Date);
      serializedDateRangeFilter.to = format(dateRangeFilter.to, DateFormat.ISO8601Date);
    }

    if (dateRangeFilter.compare) {
      serializedDateRangeFilter.compare = {
        id: dateRangeFilter.compare.id,
      };

      if (dateRangeFilter.compare.id === CustomDateRangeFilterId.CustomPeriod) {
        serializedDateRangeFilter.compare.from = format(dateRangeFilter.compare.from, DateFormat.ISO8601Date);
        serializedDateRangeFilter.compare.to = format(dateRangeFilter.compare.to, DateFormat.ISO8601Date);
      }
    }

    params[Filter.DateRange] = serializedDateRangeFilter;
  }

  return {
    ...viewData,
    createdAt: createdAt.toJSON(),
    params,
  };
}

export function getReportViewAgentCustomPropertyKey(id: string): string {
  return `${AgentCustomPropertyName.ReportViewPrefix}${id}`;
}

export function reportVisibleColumnsPropertyName(reportName: string): string {
  return `${AgentCustomPropertyName.ReportVisibleColumnsPrefix}${reportName}`;
}

export function reportColumnsOrderPropertyName(reportName: string): string {
  return `${AgentCustomPropertyName.ReportColumnsOrderPrefix}${reportName}`;
}
