import { type IEmailMapping } from './interfaces';

interface IMapping {
  email: string;
  caption: string;
  skill: string | number;
}

export const deserializeTicketsEmailsMapping = (data: { mapping?: IMapping[] }): IEmailMapping[] => {
  return (data.mapping || []).map((mapping) => ({
    email: mapping.email,
    name: mapping.caption,
    groupId: String(mapping.skill),
  }));
};
