import { type EventPlace } from 'helpers/analytics';
import { type ActionsUnion, createAction } from 'store/helper';

export enum InstallCodeActionsEnum {
  INSTALL_CODE_VISITED = 'VIEWS/ONBOARDING/INSTALL_CODE_VISITED',
  FETCH_TECHNOLOGIES = 'VIEWS/ONBOARDING/FETCH_TECHNOLOGIES_REQUEST',
  FETCH_TECHNOLOGIES_SUCCESS = 'VIEWS/ONBOARDING/FETCH_TECHNOLOGIES_SUCCESS',
  FETCH_TECHNOLOGIES_FAILURE = 'VIEWS/ONBOARDING/FETCH_TECHNOLOGIES_FAILURE',
  SAVE_TECHNOLOGIES = 'VIEWS/ONBOARDING/SAVE_TECHNOLOGIES_REQUEST',
  SAVE_TECHNOLOGIES_SUCCESS = 'VIEWS/ONBOARDING/SAVE_TECHNOLOGIES_SUCCESS',
  SAVE_TECHNOLOGIES_FAILURE = 'VIEWS/ONBOARDING/SAVE_TECHNOLOGIES_FAILURE',
  FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS = 'VIEWS/ONBOARDING/FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS_REQUEST',
  FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS_SUCCESS = 'VIEWS/ONBOARDING/FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS_SUCCESS',
  FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS_FAILURE = 'VIEWS/ONBOARDING/FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS_FAILURE',
  SET_WAS_CTA_CLICKED = 'VIEWS/ONBOARDING/SET_WAS_CTA_CLICKED',
}

export const InstallCodeActions = {
  installCodeVisited: () => createAction(InstallCodeActionsEnum.INSTALL_CODE_VISITED),
  fetchTechnologies: (eventPlace: EventPlace, fetchFreshWebsite?: string) =>
    createAction(InstallCodeActionsEnum.FETCH_TECHNOLOGIES, { eventPlace, fetchFreshWebsite }),
  fetchTechnologiesSuccess: (technologies: string[]) =>
    createAction(InstallCodeActionsEnum.FETCH_TECHNOLOGIES_SUCCESS, { technologies }),
  fetchTechnologiesFailure: (error: string) =>
    createAction(InstallCodeActionsEnum.FETCH_TECHNOLOGIES_FAILURE, { error }),
  saveTechnologies: (technologies: string[]) =>
    createAction(InstallCodeActionsEnum.SAVE_TECHNOLOGIES, { technologies }),
  saveTechnologiesSuccess: () => createAction(InstallCodeActionsEnum.SAVE_TECHNOLOGIES_SUCCESS),
  saveTechnologiesFailure: (error: string) => createAction(InstallCodeActionsEnum.SAVE_TECHNOLOGIES_FAILURE, { error }),
  fetchTechnologiesForInstructions: (eventPlace: EventPlace, license: number) =>
    createAction(InstallCodeActionsEnum.FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS, { eventPlace, license }),
  fetchTechnologiesForInstructionsSucess: (technologies: string[]) =>
    createAction(InstallCodeActionsEnum.FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS_SUCCESS, { technologies }),
  fetchTechnologiesForInstructionsFailure: (error: string) =>
    createAction(InstallCodeActionsEnum.FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS_FAILURE, { error }),
  setWasCtaClicked: (clicked: boolean) => createAction(InstallCodeActionsEnum.SET_WAS_CTA_CLICKED, { clicked }),
};

export type InstallCodeActionTypes = ActionsUnion<typeof InstallCodeActions>;
