import { type AgentPriority } from 'constants/agent-priority';
import { TeamViewActions } from 'constants/team/actions';
import { type TeamModalType } from 'constants/team/modal';
import { type TeamSubPaths } from 'constants/team/routes';
import { type KeyMap } from 'helpers/interface';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import { type ActionsUnion, createAction } from 'store/helper';

import type { ISortBy, ITeamView, ITeamTableName, ModalProps } from './interfaces';

export const TeamActions = {
  saveSortFor: (tableView: ITeamView, sortBy: ISortBy, tableName: ITeamTableName = 'table') =>
    createAction(TeamViewActions.TOGGLE_SORT, { tableView, sortBy, tableName }),
  saveFilterFor: (tableView: ITeamView, filterValue) =>
    createAction(TeamViewActions.FILTER_CHANGE, { tableView, filterValue }),
  setSelectedEntityFor: (tableView: ITeamView, selectedId: string | null) =>
    createAction(TeamViewActions.SET_SELECTED_ENTITY, { tableView, selectedId }),
  setGroupFormMembers: (members: KeyMap<AgentPriority>) =>
    createAction(TeamViewActions.GROUP_FORM_SET_MEMBERS, {
      members,
    }),
  addSelectedAgentLogin: (agent: Pick<IAgentBase, 'name' | 'login'>) =>
    createAction(TeamViewActions.ADD_SELECTED_AGENT_LOGIN, { agent }),
  removeSelectedAgentLogin: (agentLogin: string) =>
    createAction(TeamViewActions.REMOVE_SELECTED_AGENT_LOGIN, { agentLogin }),
  unselectAllAgents: () => createAction(TeamViewActions.UNSELECT_ALL_AGENTS),
  setCurrentTeamPage: (currentTeamPath: TeamSubPaths) =>
    createAction(TeamViewActions.SET_TEAM_CURRENT_PAGE, { currentTeamPath }),
  openModal: <T extends TeamModalType>(modal: T, props?: ModalProps<T>) =>
    createAction(TeamViewActions.OPEN_MODAL, {
      modal,
      props: props || {},
    }),
  closeModal: (modal: TeamModalType) => createAction(TeamViewActions.CLOSE_MODAL, { modal }),
};

export type TeamViewActionTypes = ActionsUnion<typeof TeamActions>;
