import type { KeyMap } from 'helpers/interface';
import { createRequestTypes, RequestAction } from 'store/entities/actions';
import { type ActionsUnion, createAction } from 'store/helper';

import type {
  ISetAgentCustomPropertyPayload,
  ISetAgentCustomPropertyFailurePayload,
  IFetchAgentCustomPropertiesSuccessPayload,
  IFetchAgentCustomPropertiesFailurePayload,
  ISetAgentCustomPropertySuccessPayload,
  IUpdateSectionDetailsOpen,
  IDeleteAgentCustomPropertyPayload,
  IDeleteAgentCustomPropertySuccessPayload,
  IDeleteAgentCustomPropertyFailurePayload,
} from './interfaces';

export const FETCH_AGENT_CUSTOM_PROPERTIES = 'FETCH_AGENT_CUSTOM_PROPERTIES';
export const SET_AGENT_CUSTOM_PROPERTY = 'SET_AGENT_CUSTOM_PROPERTY';
export const FETCH_METRICS = 'FETCH_METRICS';

export const AGENT_CUSTOM_PROPERTIES = {
  DELETE_AGENT_CUSTOM_PROPERTY: createRequestTypes('DELETE_AGENT_CUSTOM_PROPERTY'),
  SET_AGENT_CUSTOM_PROPERTY: createRequestTypes(SET_AGENT_CUSTOM_PROPERTY),
  FETCH_AGENT_CUSTOM_PROPERTIES: createRequestTypes(FETCH_AGENT_CUSTOM_PROPERTIES),
  UPDATE_SECTION_DETAILS_OPEN: 'UPDATE_SECTION_DETAILS_OPEN',
  FETCH_METRICS_REQUEST: 'FETCH_METRICS_REQUEST',
  FETCH_METRICS_SUCCESS: 'FETCH_METRICS_SUCCESS',
  FETCH_METRICS_FAILURE: 'FETCH_METRICS_FAILURE',
};

export const AgentCustomPropertiesActions = {
  deleteAgentCustomProperty: (payload: IDeleteAgentCustomPropertyPayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.REQUEST], payload),
  deleteAgentCustomPropertySuccess: (payload: IDeleteAgentCustomPropertySuccessPayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS], payload),
  deleteAgentCustomPropertyFailure: (payload: IDeleteAgentCustomPropertyFailurePayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE], payload),
  setAgentCustomProperty: (payload: ISetAgentCustomPropertyPayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.REQUEST], payload),
  setAgentCustomPropertySuccess: (payload: ISetAgentCustomPropertySuccessPayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS], payload),
  setAgentCustomPropertyFailure: (payload: ISetAgentCustomPropertyFailurePayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE], payload),
  fetchAgentCustomProperties: () =>
    createAction(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.REQUEST]),
  fetchAgentCustomPropertiesSuccess: (payload: IFetchAgentCustomPropertiesSuccessPayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS], payload),
  fetchAgentCustomPropertiesFailure: (payload: IFetchAgentCustomPropertiesFailurePayload) =>
    createAction(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.FAILURE], payload),
  updateSectionDetailsOpen: (payload: IUpdateSectionDetailsOpen) =>
    createAction(AGENT_CUSTOM_PROPERTIES.UPDATE_SECTION_DETAILS_OPEN, payload),
  fetchMertics: () => createAction(AGENT_CUSTOM_PROPERTIES.FETCH_METRICS_REQUEST),
  fetchMerticsSuccess: (payload: KeyMap) => createAction(AGENT_CUSTOM_PROPERTIES.FETCH_METRICS_SUCCESS, payload),
  fetchMerticsFailure: () => createAction(AGENT_CUSTOM_PROPERTIES.FETCH_METRICS_FAILURE),
};

export type AgentCustomPropertiesAction = ActionsUnion<typeof AgentCustomPropertiesActions>;
