/* eslint-disable import/no-restricted-paths */
// @ts-strict-ignore
import { type ReactNode, useMemo, type FC } from 'react';

import { Tickets } from '@livechat/design-system-icons';
import { Icon, Link, Text, NavigationTopBarAlert } from '@livechat/design-system-react-components';
import { addDays, format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { DateFormat } from 'constants/date';
import { TopBarNotificationType } from 'constants/notifications';
import { Permission } from 'constants/permission';
import { EventPlace } from 'helpers/analytics';
import { getHelpDeskAppId } from 'helpers/integration';
import { openInNewCard } from 'helpers/routing';
import { useProductData } from 'hooks/api/use-product-data';
import { useAgentCustomProperty } from 'hooks/use-agent-custom-property';
import { useInstallTrustedApplication } from 'hooks/use-install-trusted-application';
import {
  BIG_LICENSE_MIGRATION_DATE,
  MEDIUM_LICENSE_MIGRATION_DATE,
  STARTER_MIGRATION_DATE,
} from 'routes/tickets/helpdesk-promo/constants';
import { openChatWidget } from 'services/chat-widget';
import { trackEvent } from 'services/event-tracking';
import * as selectors from 'store/entities/agents/selectors';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { getIsEnterpriseOrBigLicense, getIsMediumLicense, getIsSmallLicense } from 'store/views/subscription/selectors';

import * as styles from './styles';

const SUNSET_TICKETS_ARTICLE =
  'https://www.livechat.com/help/helpdesk/?utm_source=my.livechatinc.com&utm_medium=referral&utm_campaign=tickets-sunset-topbar&utm_content=hc_';

type Props = {
  isVisible: boolean;
  onSkip(name: TopBarNotificationType): void;
};

export const NativeTicketsSunsetBar: FC<Props> = ({ onSkip, isVisible }) => {
  const dispatch = useDispatch();
  const { subscriptions } = useProductData();
  const { install, isProcessing } = useInstallTrustedApplication();
  const [, setNewVisibilityTime] = useAgentCustomProperty(
    AgentCustomPropertyName.NativeTicketsSunsetBannerNextVisibilityDate,
  );
  const { permission } = useSelector(selectors.getLoggedInAgent);
  const isEnterpriseOrBigLicense = useSelector(getIsEnterpriseOrBigLicense);
  const isMediumLicense = useSelector(getIsMediumLicense);
  const isSmallLicense = useSelector(getIsSmallLicense);
  const today = new Date();
  const bannerNextVisibilityDate = addDays(today, 21);
  const { plan, quantity: seats } = subscriptions.livechat ?? {};

  const handleActionClick = (eventName: string): void => {
    setNewVisibilityTime(format(bannerNextVisibilityDate, DateFormat.ShortDate));
    trackEvent(eventName, EventPlace.TopBar, { permission, plan, seats });
    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.NativeTicketsSunset));
    onSkip(TopBarNotificationType.NativeTicketsSunset);
  };

  const handleLearnMoreButtonClick = (): void => {
    handleActionClick('Sunset Banner learn more clicked');
    openInNewCard(SUNSET_TICKETS_ARTICLE);
  };

  const handleSkip = (): void => {
    handleActionClick('Sunset Banner skip clicked');
  };

  const handleChatWithUsClick = (): void => {
    handleActionClick('Sunset Banner chat with us clicked');
    openChatWidget();
  };

  const handleStartTrialClick = (trialDays: number): void => {
    handleActionClick('Sunset Banner start trial clicked');
    install({ appId: getHelpDeskAppId(), couponCode: `hdtrial${trialDays}` });
  };

  const bannerDetails = useMemo(() => {
    if ([Permission.Owner, Permission.ViceOwner].includes(permission)) {
      if (isEnterpriseOrBigLicense) {
        return {
          content: (
            <Text as="span">
              Legacy tickets retire on {BIG_LICENSE_MIGRATION_DATE}. Contact your Account Manager for a special{' '}
              <Link
                className={styles.ticketsSunsetLink}
                href={SUNSET_TICKETS_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
              >
                HelpDesk
              </Link>{' '}
              offer.
            </Text>
          ),
          buttonText: 'Chat with us',
          onClick: handleChatWithUsClick,
        };
      }

      if (isMediumLicense) {
        const trialDays = 90;

        return {
          content: (
            <Text as="span">
              Legacy tickets retire on {MEDIUM_LICENSE_MIGRATION_DATE}. Switch to HelpDesk!{' '}
              <Link
                className={styles.ticketsSunsetLink}
                href={SUNSET_TICKETS_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>{' '}
              and get your {trialDays}-day trial.
            </Text>
          ),
          buttonText: 'Start free trial',
          onClick: () => handleStartTrialClick(trialDays),
        };
      }

      if (isSmallLicense) {
        const trialDays = 30;

        return {
          content: (
            <Text as="span">
              Legacy tickets retire on {STARTER_MIGRATION_DATE}. Switch to HelpDesk!{' '}
              <Link
                className={styles.ticketsSunsetLink}
                href={SUNSET_TICKETS_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>{' '}
              and get your {trialDays}-day trial.
            </Text>
          ),
          buttonText: 'Start free trial',
          onClick: () => handleStartTrialClick(trialDays),
        };
      }
    } else {
      const contentGenerator = (retireDate: string): ReactNode => (
        <Text as="span">
          Legacy tickets retire on {retireDate}. Move to HelpDesk! Contact your license owner to switch.
        </Text>
      );
      const buttonText = 'Learn more';
      const onClick = handleLearnMoreButtonClick;

      // admins & agents
      if (isEnterpriseOrBigLicense) {
        return {
          content: contentGenerator(BIG_LICENSE_MIGRATION_DATE),
          buttonText,
          onClick,
        };
      }

      if (isMediumLicense) {
        return {
          content: contentGenerator(MEDIUM_LICENSE_MIGRATION_DATE),
          buttonText,
          onClick,
        };
      }

      if (isSmallLicense) {
        return {
          content: contentGenerator(STARTER_MIGRATION_DATE),
          buttonText,
          onClick,
        };
      }
    }

    return {
      content: null,
      buttonText: '',
      onClick: () => {},
    };
  }, [permission, plan, seats, today]);

  return (
    <NavigationTopBarAlert
      kind="info"
      isVisible={isVisible}
      primaryCta={{
        label: bannerDetails.buttonText,

        onClick: bannerDetails.onClick,
        size: 'compact',
        icon: <Icon source={Tickets} />,
        loading: isProcessing,
      }}
      closeButton={{
        onClick: handleSkip,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'aria-label': 'Close the prompt about native tickets sunset',
      }}
    >
      {bannerDetails.content}
    </NavigationTopBarAlert>
  );
};
