import { DesignToken } from '@livechat/design-system-react-components';

export const scrollbar = `
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: var(${DesignToken.SurfacePrimaryDefault});
  }

  ::-webkit-scrollbar-thumb {
    background: var(${DesignToken.SurfaceModerateDefault});
    border-radius: 8px;
    box-shadow: inset 0 0 0 3px var(${DesignToken.SurfacePrimaryDefault});
  }
`;
