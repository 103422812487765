import { ApiErrorMessagePart, HTTPStatusCode } from 'services/api/constants';
import type { RequestError } from 'services/api/types';
import { isNetworkIssueMessage } from 'services/connectivity/http/helpers';

export type ApiError = { error?: ApiErrorMessagePart; status?: HTTPStatusCode };

export const isRequestUnauthorized = ({ status, error }: ApiError): boolean =>
  status === HTTPStatusCode.Unauthorized ||
  status === HTTPStatusCode.Empty ||
  error === ApiErrorMessagePart.Unauthorized;

export const isRequestSuspended = ({ error }: ApiError): boolean => error === ApiErrorMessagePart.AccountSuspended;

export const isRequestWaitingForApproval = ({ error }: ApiError): boolean =>
  error === ApiErrorMessagePart.AwaitingApproval;

export const isLicenseExpired = ({ error }: ApiError): boolean => error === ApiErrorMessagePart.LicenseExpired;

export const isLicenseBlockedInCRM = ({ error }: RequestError): boolean => error === ApiErrorMessagePart.UserNotActive;

/**
 * Type guard function to check if a given object is of type ApiError.
 * @param obj - The object to check.
 * @returns True if the object is of type ApiError, false otherwise.
 */
export function isWithApiError(obj: unknown): obj is ApiError {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const apiErrorObj = obj as ApiError;

  if (apiErrorObj.error === undefined && apiErrorObj.status === undefined) {
    return false;
  }

  return (
    ('error' in apiErrorObj === false ||
      apiErrorObj.error === undefined ||
      Object.values(ApiErrorMessagePart).includes(apiErrorObj.error)) &&
    ('status' in apiErrorObj === false ||
      apiErrorObj.status === undefined ||
      Object.values(HTTPStatusCode).includes(apiErrorObj.status))
  );
}

/**
 * This method checks normalized API errors to find network issues. Errors that qualify as such:
 * 1. Configuration API or Agent API errors containing `message` property describing a network issue.
 * 2. Global Accounts API errors containing `error_description` that describes a network issue.
 *
 * A network issue description is one of the following:
 *  - Failed to fetch
 *  - NetworkError when attempting to fetch resource.
 *  - Load failed
 *  - Request timed out: <method> <url>
 *
 * Examples:
 *  - {"status":0,"error_description":"Failed to fetch","error":"server_error","sub_error":null,"invalid_fields":{}}
 *  - {"name":"TimeoutError","status":0,"error_description":"Request timed out: ...","error":"server_error","sub_error":null,"invalid_fields":{}}
 *  - {"type":"internal","message":"NetworkError when attempting to fetch resource.","status":0}
 */
export function isNetworkIssuesError(error: unknown): boolean {
  const isObject = typeof error === 'object' && error !== null;

  const hasIssueInMessage =
    isObject && 'message' in error && typeof error.message === 'string' && isNetworkIssueMessage(error.message);

  const hasIssueInErrorDescription =
    isObject &&
    'error_description' in error &&
    typeof error.error_description === 'string' &&
    isNetworkIssueMessage(error.error_description);

  return hasIssueInMessage || hasIssueInErrorDescription;
}
