// @ts-strict-ignore
import type {
  IReportView,
  IReportViewsById,
  ISelectReportViewPayload,
  IUpdateFilterOperatorPayload,
  ReportsViewState,
  IColumnsVisibilityPayload,
  IColumnsOrderPayload,
} from '../../../../interfaces/reports';

export function getStateForUpdateFilterOperator(
  state: ReportsViewState,
  payload: IUpdateFilterOperatorPayload
): ReportsViewState {
  const { filter, operator } = payload;

  const { filtersOperators } = state;

  let newOperators = {};

  if (operator) {
    newOperators = { ...filtersOperators, [filter]: operator };
  } else {
    const { [filter]: operatorToRemove, ...restOperator } = filtersOperators;
    newOperators = { ...restOperator };
  }

  return {
    ...state,
    filtersOperators: newOperators,
  };
}

export function getStateForLoadReportViews(state: ReportsViewState, payload: IReportViewsById): ReportsViewState {
  return {
    ...state,
    views: {
      ...state.views,
      ...payload,
    },
  };
}

export function getStateForSaveReportViewSuccess(state: ReportsViewState, payload: IReportView): ReportsViewState {
  const { id } = payload;

  return {
    ...state,
    views: {
      ...state.views,
      [id]: payload,
    },
    selectedView: id,
  };
}

export function getStateForDeleteReportViewSuccess(state: ReportsViewState, payload: IReportView): ReportsViewState {
  const { id } = payload;
  const { [id]: viewToRemove, ...views } = state.views;

  return {
    ...state,
    views: {
      ...views,
    },
  };
}

export function getStateForSelectReportView(
  state: ReportsViewState,
  payload: ISelectReportViewPayload
): ReportsViewState {
  const { id: selectedView } = payload;

  return {
    ...state,
    selectedView,
  };
}

export function getStateForUnselectReportView(state: ReportsViewState): ReportsViewState {
  return {
    ...state,
    selectedView: null,
  };
}

export function getStateForSetColumnsVisibility(
  state: ReportsViewState,
  payload: IColumnsVisibilityPayload
): ReportsViewState {
  return {
    ...state,
    visibleColumns: {
      ...state.visibleColumns,
      ...payload.visibleColumns,
    },
  };
}

export function getStateForSetColumnsOrder(state: ReportsViewState, payload: IColumnsOrderPayload): ReportsViewState {
  return {
    ...state,
    columnsOrder: {
      ...state.columnsOrder,
      ...payload.columnsOrder,
    },
  };
}
