/* eslint-disable @typescript-eslint/naming-convention */
import type { FetchQueryOptions } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { fetchBannedCustomers } from 'hooks/api/banned-customers/use-fetch-banned-customers';
import { fetchFeatures } from 'hooks/api/feature-control/use-fetch-features';
import { fetchOnboardingProgress } from 'hooks/api/onboarding/use-fetch-onboarding-progress';
import { listSuggestions } from 'hooks/use-list-suggestions';
import { listSkills } from 'hooks/use-reply-suggestions-list';
import { defaultFeatures } from 'services/api/feature-control/serializer';
import { AppStateProvider } from 'services/app-state-provider';
import { selectIsNewOnboardingChecklistEnabled } from 'store/entities/experiments/selectors';
import { TrafficActions } from 'store/views/traffic/actions';

import { getQueryClient } from './client';

const PREFETCH_CONFIG: Partial<Record<QueryKey, FetchQueryOptions>> = {
  [QueryKey.KnowledgeSourcesList]: {
    queryKey: [QueryKey.KnowledgeSourcesList],
    queryFn: listSkills,
    retry: 3,
  },
  [QueryKey.BannedCustomers]: {
    queryKey: [QueryKey.BannedCustomers],
    queryFn: fetchBannedCustomers,
    initialData: [],
  },
  [QueryKey.Features]: {
    queryKey: [QueryKey.Features],
    queryFn: fetchFeatures,
    retry: 1,
    initialData: defaultFeatures,
  },
  [QueryKey.OneSuggestions]: {
    queryKey: [QueryKey.OneSuggestions],
    queryFn: listSuggestions,
    retry: 1,
    initialData: [],
  },
};

/**
 * Create new QueryClient instance or use existing one to prefetch data used in backbone views, collections or models
 */
export function initializeQueryClient(): void {
  const queryClient = getQueryClient();

  queryClient.onQueryUpdated(QueryKey.BannedCustomers, () => {
    AppStateProvider.dispatch(TrafficActions.bannedCustomersListUpdate());
  });

  const unsubscribe = queryClient.onQueryUpdated(QueryKey.OnboardingProgress, () => {
    unsubscribe();
  });

  queryClient.onQueryUpdateFailure(QueryKey.Features, () => {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch features');
  });

  Object.values(PREFETCH_CONFIG).forEach((config) => {
    void queryClient.prefetchQuery(config);
  });

  if (AppStateProvider.selectFromStore(selectIsNewOnboardingChecklistEnabled)) {
    void queryClient.prefetchQuery({
      queryKey: [QueryKey.OnboardingProgress],
      queryFn: fetchOnboardingProgress,
      initialData: {
        account_activated: true,
        avatar_uploaded: true,
        code_installed: true,
        desktop_app_downloaded: true,
        sample_chat: true,
        invite_teammates: true,
        install_messenger: true,
        install_whatsapp: true,
        install_twilio: true,
        install_telegram: true,
      },
    });
  }
}
