import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type IncomingResponseMessage } from 'interfaces/incoming-message';
import { type DeleteThreadPropertiesEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#delete-thread-properties
 */
export async function deleteThreadProperties(
  chatId: string,
  threadId: string,
  properties: { [namespace: string]: string[] }
): Promise<IncomingResponseMessage<void>> {
  const event: DeleteThreadPropertiesEvent = {
    action: WebSocketAction.DeleteThreadProperties,
    payload: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      chat_id: chatId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      thread_id: threadId,
      properties,
    },
    version: '3.5',
  };

  return await sendEvent<void>(getServer(), event);
}
