export enum AgentAssignmentFilter {
  Unassigned = 'unassigned',
  Assigned = 'assigned',
}

export const AgentAssignmentFilterMap = {
  fromQueryString: {
    '0': [AgentAssignmentFilter.Unassigned],
    '1': [AgentAssignmentFilter.Assigned],
  },
  toQueryString: {
    [AgentAssignmentFilter.Unassigned]: '0',
    [AgentAssignmentFilter.Assigned]: '1',
  },
};

export const AgentAssignmentFilterLabels = {
  [AgentAssignmentFilter.Assigned]: 'Assigned',
  [AgentAssignmentFilter.Unassigned]: 'Unassigned',
};

export const AgentAssignmentFilterKey = 'agent_assignment';
