import { type SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';

import { getConfig } from 'helpers/config';
import { ApplicationsActions } from 'store/features/applications/actions';
import { type IActionWithPayload } from 'store/helper';

import { markDetailsPanelOnboardingFinishedKB } from '../applications/helpers';

import { DetailsColumnActionNames } from './actions';
import { type ISelectDetailsTabPayload } from './interfaces';

function* loadSelectedTab(action: IActionWithPayload<string, ISelectDetailsTabPayload>): SagaIterator {
  const { tabId } = action.payload;

  if (tabId === getConfig().knowledgeBaseAppId) {
    markDetailsPanelOnboardingFinishedKB();
  }

  yield put(ApplicationsActions.loadWidget(tabId));
}

export function* customerDetailsSagas(): SagaIterator {
  yield takeLatest(DetailsColumnActionNames.SELECT_DETAILS_TAB, loadSelectedTab);
}
