import type { ThreadEventResult } from 'interfaces/api/event/thread';
import type { ChatSummaryResult } from 'services/api/chat/interfaces';

export function getThreadEventsFromChatSummary(
  data: ChatSummaryResult,
  threadId: string
): ThreadEventResult[] | null {
  if (!data || !data.last_event_per_type || !threadId) {
    return null;
  }

  return Object.values(data.last_event_per_type).reduce<ThreadEventResult[]>((acc, lastEvent) => {
    if (lastEvent.thread_id === threadId && !lastEvent.restricted_access) {
      acc.push(lastEvent.event);
    }

    return acc;
  }, []);
}
