import { BaseApi } from '../base-api';

import { type IOnboardingProgressDTO } from './interfaces';

export class OnboardingApi extends BaseApi {
  protected readonly prefix = 'onboarding_client';

  fetchTechnologies = (domainOrEmail: string) => this.get(`${encodeURIComponent(domainOrEmail)}/technologies`);
  fetchTechnologiesByLicense = (license: number) => this.get(`technologies/${license}`);

  saveTechnologies = (technologies: string[]) => this.post('technologies', { technologies });
  fetchSavedTechnologies = () => this.get('technologies');

  fetchChecklistProgress = () => this.get<IOnboardingProgressDTO>('progress_bar');
  saveChecklistProgress = (step: string) => this.put('progress_bar', { step });
}
