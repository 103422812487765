import { MAX_CUSTOM_SEGMENTS_COUNT } from 'constants/custom-segment';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { type IWithAgentCustomPropertiesState } from 'store/features/agent-custom-properties/selectors';

import { EMPTY_ARRAY } from './constants';
import { type WithCustomSegmentsState, getSectionCustomSegmentsIds } from './selectors';

export function getCustomSegmentsLimitReached(
  state: WithCustomSegmentsState & IWithAgentCustomPropertiesState,
  sectionName: string
): boolean {
  const sortedIds = getSectionCustomSegmentsIds(state, sectionName) ?? EMPTY_ARRAY;

  return sortedIds.length === MAX_CUSTOM_SEGMENTS_COUNT;
}

export function getCustomSegmentsPropertyName(sectionName: string, suffix: number | string): string {
  return `${AgentCustomPropertyName.CustomSegmentPrefix}${sectionName}_${suffix}`;
}
