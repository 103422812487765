import createBackoff from '@livechat/backoff';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';

import { delay } from './delay';

export const STOP_RETRYING_ERROR_MESSAGE = 'stop-retrying-forced';

const log = debug(DebugLogsNamespace.AppDebug);

export type BackoffStatus = 'scheduled' | 'retrying';

export class BackoffError extends Error {
  readonly backoffErrors: Error[];

  constructor(message: string, backoffErrors: Error[] = []) {
    super(message);

    this.backoffErrors = backoffErrors;
  }
}

export default async function promiseBackoff(
  promiseFactory: () => Promise<void>,
  maxAttempts = Infinity,
  initialDelay = 0,
  shouldStopRetrying?: () => boolean,
  hooks?: {
    onRetryScheduled?: (status: BackoffStatus, nextRetryTimestamp: number | null) => void;
    onRetry?: (status: BackoffStatus, nextRetryTimestamp: number | null) => void;
  },
): Promise<void> {
  /**
   * To get following timeout values we are using `@livechat/backoff` library
   * which is using exponential backoff algorithm (@see https://en.wikipedia.org/wiki/Exponential_backoff).
   * There also `jitter` option to make consecutive values more random but still
   * sustain exponential characteristic (@see https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/).
   */
  const backoff = createBackoff({
    min: 900,
    max: 10000,
    jitter: 0.1,
  });

  await delay(initialDelay);

  const backoffErrors: Error[] = [];

  return new Promise((resolve, reject) => {
    let attempt = 0;
    const retry = (): void => {
      if (attempt >= maxAttempts) {
        log(`Reached maximum amount of retry attempts (${maxAttempts})`);

        reject(new BackoffError(`Reached maximum amount of retry attempts (${maxAttempts})`, backoffErrors));

        return;
      }

      ++attempt;

      const timeout = backoff.duration();
      log(`Next retry in ${timeout}ms`);
      const nextRetryTimestamp = Date.now() + timeout;

      hooks?.onRetryScheduled?.('scheduled', nextRetryTimestamp);

      log(`${attempt} attempt of backoff - backing off for ${timeout}ms`);

      setTimeout((): void => {
        log('Retrying...');

        if (shouldStopRetrying?.()) {
          log('Forced to stop retrying!');

          return reject(new Error(STOP_RETRYING_ERROR_MESSAGE));
        }

        hooks?.onRetry?.('retrying', null);

        promiseFactory()
          .then(resolve)
          .catch((error: Error) => {
            log('Error while retrying', error);

            backoffErrors.push(error);

            if (shouldStopRetrying?.()) {
              return reject(new Error(STOP_RETRYING_ERROR_MESSAGE));
            }
            retry();
          });
      }, timeout);
    };

    retry();
  });
}
