// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';

import { ReportDistribution } from 'constants/reports/distribution';
import { ReportType } from 'constants/reports/report-type';
import { ApiManager } from 'services/api/api-manager';
import { type IActionWithPayload } from 'store/helper';

import {
  type IFetchReportPayload,
  type IReportRequest,
  type IFetchReportOptions,
  ReportRequestName,
} from '../interfaces';

import { makeFetchingSaga } from './helpers';

export function* fetchStaffingPrediction(action: IActionWithPayload<string, IFetchReportPayload>): SagaIterator {
  const requests: IReportRequest[] = [
    {
      name: ReportRequestName.AgentOccupancy,
      interface: ApiManager.reportApi.fetchAgentOccupancy,
    },
  ];

  const options: IFetchReportOptions = {
    extendedFilters: {
      distribution: ReportDistribution.Hour,
    },
  };

  yield makeFetchingSaga(ReportType.StaffingPrediction, action, requests, options);
}
