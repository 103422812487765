import { combineReducers } from 'redux';

import { type KeyMap } from 'helpers/interface';
import { type ICampaignApplication } from 'store/features/applications/interfaces';

import { type HomeScreenActionTypes, HomeScreenActionsEnum } from './actions';
import { type IHomeScreenState } from './interfaces';

const initialState: IHomeScreenState = {
  appsGallery: {},
  isAppsGalleryFetched: false,
  isAppsGalleryOpen: true,
  isChecklistAnimationSeen: false,
};

function appsGallery(
  state: KeyMap<ICampaignApplication[]> = initialState.appsGallery,
  action: HomeScreenActionTypes
): KeyMap<ICampaignApplication[]> {
  switch (action.type) {
    case HomeScreenActionsEnum.SET_APPS_GALLERY_LIST: {
      return {
        ...state,
        ...action.payload.applications,
      };
    }
    default:
      return state;
  }
}

function isAppsGalleryFetched(
  state: boolean = initialState.isAppsGalleryFetched,
  action: HomeScreenActionTypes
): boolean {
  switch (action.type) {
    case HomeScreenActionsEnum.SET_APPS_GALLERY_LIST: {
      return true;
    }
    default:
      return state;
  }
}

function isAppsGalleryOpen(state: boolean = initialState.isAppsGalleryOpen, action: HomeScreenActionTypes): boolean {
  switch (action.type) {
    case HomeScreenActionsEnum.TOGGLE_APPS_GALLERY: {
      return !state;
    }
    default:
      return state;
  }
}

function isChecklistAnimationSeen(
  state: boolean = initialState.isChecklistAnimationSeen,
  action: HomeScreenActionTypes
): boolean {
  switch (action.type) {
    case HomeScreenActionsEnum.CHECKLIST_ANIMATION_SEEN: {
      return true;
    }
    default:
      return state;
  }
}

export const homeScreenReducer = combineReducers({
  appsGallery,
  isAppsGalleryFetched,
  isAppsGalleryOpen,
  isChecklistAnimationSeen,
});
