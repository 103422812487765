// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { select, take } from 'redux-saga/effects';

import { PredefinedTagName } from 'constants/reports/predefined-tag-name';
import { ReportType } from 'constants/reports/report-type';
import { ApiManager } from 'services/api/api-manager';
import { serializeReportDistribution } from 'services/api/report/v3/filters';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { TagActionNames } from 'store/entities/tags/actions';
import { type IActionWithPayload } from 'store/helper';
import { createHasFetchedSelector } from 'store/requests/selectors';

import { staticReportFilters } from '../constants/static-filters';
import {
  type IFetchReportPayload,
  type IFetchReportOptions,
  ReportRequestName,
  type IReportRequest,
  RequestVersion,
} from '../interfaces';

import { makeFetchingSaga } from './helpers';

export function* fetchTagsUsage(action: IActionWithPayload<string, IFetchReportPayload>): SagaIterator {
  const wereTagsFetched = yield select(createHasFetchedSelector(['FETCH_COLLECTION_TAG']));

  if (!wereTagsFetched) {
    yield take(TagActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]);
  }

  const requests: IReportRequest[] = [
    {
      version: RequestVersion.V3,
      name: ReportRequestName.TagsUsage,
      interface: ApiManager.reportApiV3.fetchTagsList,
      extendedFilters: staticReportFilters[ReportType.TagsUsage],
    },
    {
      version: RequestVersion.V3,
      name: ReportRequestName.Tagged,
      interface: ApiManager.reportApiV3.fetchTagsList,
      extendedFilters: {
        tags: [PredefinedTagName.With],
        ...staticReportFilters[ReportType.TagsUsage],
      },
    },
    {
      version: RequestVersion.V3,
      name: ReportRequestName.Untagged,
      interface: ApiManager.reportApiV3.fetchTagsList,
      extendedFilters: {
        tags: [PredefinedTagName.Without],
        ...staticReportFilters[ReportType.TagsUsage],
      },
    },
  ];

  const options: IFetchReportOptions = {
    apiFiltersSerializer: serializeReportDistribution,
  };

  yield makeFetchingSaga(ReportType.TagsUsage, action, requests, options);
}
