export enum AudienceField {
  b2b = 'B2B',
  b2c = 'B2C',
  internal = 'Internal use',
}

interface IAudience {
  b2b: boolean;
  b2c: boolean;
  internal: boolean;
}

export const mapAudienceToCheckboxes = (data: string[]): IAudience => ({
  b2b: data.includes(AudienceField.b2b),
  b2c: data.includes(AudienceField.b2c),
  internal: data.includes(AudienceField.internal),
});

export const mapAudienceToAPIValues = (data: IAudience): boolean[] => {
  const audience = Object.keys(data).map((key) => (data[key] ? AudienceField[key] : null));

  return audience.filter((el) => el !== null);
};
