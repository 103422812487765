// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery, select } from 'redux-saga/effects';

import { PostMessageEvent } from 'constants/post-message-event';
import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { isGhostLogin } from 'helpers/ghost';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import type { CreateCartResult, ProductsCart } from 'interfaces/product-cart';
import { ApiManager } from 'services/api/api-manager';
import { isApplicationInstalled } from 'store/entities/applications/selectors';
import { ProductCartActionNames, ProductCartActions } from 'store/entities/product-cart/actions';
import { type IApplicationIDPayload } from 'store/entities/product-cart/interfaces';
import { getCurrentCart } from 'store/entities/product-cart/selectors';
import { type IApplicationData } from 'store/features/applications/interfaces';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';

const CHECKOUT_STARTED_ERROR =
  'PRODUCTS_CART_CHECKOUT_STARTED: someone has started payment process, cart blocked for 15 minutes';

/**
 * Add Application to cart - to be used as a side effect of other sagas when we don't need to:
 * - track adding status
 * - refresh cart data
 * - track if application is marked to install or not
 */
function* addProductToCart(cartId: string, applicationId: string): SagaIterator {
  const isAppInstalled: boolean = yield select(isApplicationInstalled, applicationId);
  if (isAppInstalled) {
    return;
  }

  const integrationDataResult: RequestResult<IApplicationData> = yield call(
    ApiManager.integrationsApi.fetchAppData,
    applicationId
  );

  // Application is free so it cannot be added to the cart
  if (!integrationDataResult.result.payment) {
    return;
  }

  // add application to the cart
  let addApplicationResponse: RequestResult<CreateCartResult>;
  addApplicationResponse = yield call(ApiManager.productCartApi.addApplication, cartId, applicationId);

  // in case checkout was already started in the marketplace we have to cancel it and add the application once again
  if (addApplicationResponse.error?.errors === CHECKOUT_STARTED_ERROR) {
    const checkoutRejectResponse: RequestResult<unknown> = yield call(ApiManager.productCartApi.rejectCheckout, cartId);

    if (checkoutRejectResponse.error) {
      throw checkoutRejectResponse.error;
    }

    addApplicationResponse = yield call(ApiManager.productCartApi.addApplication, cartId, applicationId);
  }

  if (addApplicationResponse.error) {
    throw addApplicationResponse.error;
  }
}

/**
 * Add Application to cart - to be used as the main effect in case we need to:
 * - track adding status
 * - refresh cart data after app is added
 */
function* addProductToCurrentCart(
  action: IActionWithPayload<ProductCartActionNames, IApplicationIDPayload>
): SagaIterator {
  // On ghost login we cannot create product cart, so it's also not possible to add item to the cart
  if (isGhostLogin()) {
    return;
  }

  const { id: productId } = action.payload;

  try {
    const currentCart: ProductsCart = yield select(getCurrentCart);

    yield call(addProductToCart, currentCart.id, productId);
    const recentCartResponse: RequestResult<ProductsCart> = yield call(ApiManager.productCartApi.fetchRecent);
    const product = recentCartResponse.result.products.find(({ id }) => id === productId);

    yield put(ProductCartActions.addApplicationSuccess({ ...product, isMarkedForInstall: true }));

    // Send updated cart to the embedded Marketplace
    yield call(sendPostMessageToMarketplace, PostMessageEvent.CartUpdated, {
      cart: recentCartResponse.result,
    });
  } catch (error) {
    yield put(ProductCartActions.addApplicationFailure({ id: productId }));
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.ADD_APPLICATION_TO_THE_CART_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      })
    );
  }
}

export function* addProductToCurrentCartSaga(): SagaIterator {
  yield takeEvery(ProductCartActionNames.ADD_APPLICATION, addProductToCurrentCart);
}
