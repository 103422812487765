import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

export const betaLabel = (isDarkMode: boolean): string => css`
  font-size: 13px;
  font-weight: 400;
  padding: 0 var(${SpacingToken.Spacing2});
  border-radius: var(${RadiusToken.Radius3});
  line-height: 20px;
  background: var(${DesignToken.ContentBasicGradient01});
  cursor: pointer;
  text-align: center;
  opacity: ${isDarkMode ? 0.4 : 0.1};
`;

export const absoluteBadge = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
  background: var(${DesignToken.ContentBasicGradient01});
  text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const container = css`
  position: relative;
`;

export const tooltipContainer = css`
  width: 242px;
  padding: var(${SpacingToken.Spacing4});
  text-align: left;
`;

export const header = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(${DesignToken.ContentBasicPrimary});
  margin-bottom: var(${SpacingToken.Spacing1});
`;

export const text = css`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(${DesignToken.ContentBasicPrimary});
  margin-bottom: var(${SpacingToken.Spacing3});
`;

export const icon = css`
  margin-bottom: var(${SpacingToken.Spacing3});
`;
