import { createAction } from 'store/helper';

import { type INotificationDataPayload } from './interfaces';

export enum NotificationsActionsNames {
  NEW_NOTIFICATION = 'FEATURES/NOTIFICATIONS/NEW',
}

export const NotificationsActions = {
  newNotification: (payload: INotificationDataPayload) =>
    createAction(NotificationsActionsNames.NEW_NOTIFICATION, payload),
};
