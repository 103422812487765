import { type ChatRecipients } from 'constants/chat-recipients';
import { getServer } from 'helpers/get-server';
import { type SendTypingIndicatorEvent } from 'interfaces/web-socket-events';
import { WebSocketAction } from 'constants/web-socket-action';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#send-typing-indicator
 */
export async function sendTypingIndicator(
  chatId: string,
  isTyping: boolean,
  visibility: ChatRecipients
): Promise<unknown> {
  const event: SendTypingIndicatorEvent = {
    action: WebSocketAction.SendTypingIndicator,
    payload: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      chat_id: chatId,
      visibility,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      is_typing: isTyping,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
