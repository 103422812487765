import { BaseApi, PassVersionMode } from '../base-api';

import type { TagDTO } from './interfaces';

export class TagApi extends BaseApi {
  protected readonly prefix = 'configuration/action';
  protected readonly version = 3.5;
  protected readonly passVersionMode = PassVersionMode.Prefix;

  fetchAll = () => this.get<TagDTO[]>('', undefined, undefined, 'tags', '2');
}
