import { type IOutboxMessage } from './outbox-message';
import { createOutboxMessage } from './outbox-message-factory';
import { OutboxMessageTitles } from './outbox-message-titles';

interface IChangeLivechatTheme {
  theme: string;
}

interface IPageDataMessage extends IOutboxMessage {
  readonly title: OutboxMessageTitles.ChangeLivechatTheme;
  readonly data: IChangeLivechatTheme;
}

export function createChangeLivechatThemeMessage(pluginId: string | null, theme: string): IPageDataMessage {
  return createOutboxMessage<IPageDataMessage>(OutboxMessageTitles.ChangeLivechatTheme, pluginId, { theme });
}
