export enum Source {
  Chats = 'chats',
  Tickets = 'tickets',
  Goals = 'goals',
  Queues = 'queues',
}

export const sourceLabels: Record<string, string> = {
  [Source.Chats]: 'Chats',
  [Source.Tickets]: 'Tickets',
  [Source.Goals]: 'Goals and Sales',
  [Source.Queues]: 'Queue abandonment',
};
