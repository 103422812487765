// @ts-strict-ignore
import { format, getTime } from 'date-fns';
import isNil from 'lodash.isnil';
import union from 'lodash.union';
import uniqBy from 'lodash.uniqby';

import AUTOMATIC_MESSAGE_SVG from 'assets/img/automatic-message.svg';
import { ChatEventSubType, ChatEventType } from 'constants/chat-event-type';
import { CustomFormType } from 'constants/custom-form-type';
import { DateFormat } from 'constants/date';
import { SurveyType } from 'constants/filters/survey-type-filter';
import { GoalType } from 'constants/goal';
import { AUTOMATIC_MESSAGE, DEFAULT_AGENT_NAME, DEFAULT_VISITOR_NAME } from 'constants/localization';
import type RichMessageTemplate from 'constants/rich-message-template';
import { UserType as ArchiveUserType } from 'constants/user-type';
import { anyToBoolean } from 'helpers/boolean';
import { getConfig } from 'helpers/config';
import { isNullOrNotArray } from 'helpers/data';
import { parseSurveyFieldValue, type IFilledFormField } from 'helpers/filled-form';
import type { DeepPartial } from 'helpers/interface';
import { type ChatUserResultType } from 'interfaces/api/chat';
import type { ThreadEventResult } from 'interfaces/api/event/thread';
import {
  ArchiveAgentType,
  ArchiveRate,
  ArchiveType,
  type IArchive,
  type IArchiveAgent,
  type IArchiveEvent,
  type IArchiveEventDetails,
  type IIntegrations,
  type ISurveyResponse,
} from 'interfaces/entities/archive';
import { type Properties } from 'interfaces/property';
import { type IStoreState } from 'interfaces/store/store-state';
import type { IChatCustomerResult } from 'services/api/interfaces/chat/chat';
import {
  isAgentChatUser,
  isCustomerChatUser,
  isFileEvent,
  isFilledFormEvent,
  isFormEvent,
  isRichMessageEvent,
  isSystemMessageEvent,
  isWithAuthorEvent,
  isWithTextEvent,
} from 'services/connectivity/agent-chat-api/chats/type-guards';
import { type ListArchivesResponse } from 'services/connectivity/agent-chat-api/chats/types/list-archives';
import { UserType } from 'services/connectivity/agent-chat-api/types';
import { type Chat } from 'services/connectivity/agent-chat-api/types/chat';
import { type Event } from 'services/connectivity/agent-chat-api/types/event';
import { type Goal } from 'services/connectivity/agent-chat-api/types/goal';
import { type ChatProperties } from 'services/connectivity/agent-chat-api/types/property';
import { type User } from 'services/connectivity/agent-chat-api/types/user';
import { extractCommentFromEventText, shouldCheckEventForComment } from 'services/serialization/chat-rating-event';
import { getPropertyValue } from 'services/serialization/property';
import { deserializeRichMessageDetails } from 'services/serialization/rich-message';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import { ThreadCustomPropertyName } from 'services/socket-lc3/chat/event-handling/constants';
import { getIsMarkdownEvent } from 'services/socket-lc3/chat/serialization/deserialize-event';
import { deserializeEventUserType } from 'services/socket-lc3/chat/serialization/deserialize-event-user-type';
import { getCustomFormType } from 'services/socket-lc3/chat/serialization/helpers/custom-form';
import { getFacebookMessengerIntegration } from 'services/socket-lc3/chat/serialization/helpers/get-facebook-messenger-integration';
import type { WithAgentsState } from 'store/entities/agents/selectors';
import type { IWithBotsState } from 'store/entities/bots/selectors';
import type { IGoal } from 'store/entities/goals/interfaces';
import { agentsAndBotsSelector } from 'store/views/team/computed';
import type { IAgentsAndBots } from 'store/views/team/interfaces';

import { COMMONMARK, DEFAULT_FETCH_ALL_LIMIT } from './constants';

const CLIENT_ID = getConfig().accountsClientId;

export interface IFetchAllResult {
  data: IArchive[];
  totalPages: number;
  totalCount: number;
  nextPage: string | number;
}

export enum RestrictedAccessInformation {
  UserHasNoAccess = 'User has no access to following resource',
  ThreadsOlderThan = "You can't access threads older than",
}

const formIdToSurveyType: Record<string, SurveyType> = {
  ask_for_email: SurveyType.Custom,
};

function deserializeAgents(data: Chat): IArchiveAgent[] {
  const withAuthorIdEvents = data.thread.events?.filter(isWithAuthorEvent) || [];
  const uniqueEventAgentIds = uniqBy(withAuthorIdEvents, ({ author_id }) => author_id)
    .filter((event) => {
      const user = data.users.find(({ id }) => id === event.author_id);

      return user?.type === UserType.AGENT;
    })
    .map((event) => event.author_id);

  const agentUserIds = data.users
    .filter((user) => isAgentChatUser(user) && data.thread.user_ids?.includes(user.id))
    .map(({ id }) => id);

  return union(uniqueEventAgentIds, agentUserIds).map((id) => {
    const agent = data.users.find((user) => user.id === id);

    return {
      displayName: agent ? agent.name : DEFAULT_AGENT_NAME,
      email: agent ? agent.email : null,
      ip: null,
      type: ArchiveAgentType.Agent,
    };
  });
}

function deserializeAuthorName(user: User, fallbackType: ArchiveUserType): string {
  if (!user) {
    return fallbackType === ArchiveUserType.Customer ? DEFAULT_VISITOR_NAME : DEFAULT_AGENT_NAME;
  }

  return user.name;
}

function deserializeEventDate(timestampInMs: number): string {
  return format(timestampInMs, DateFormat.ShortDateWithDayOfWeekAndTime);
}

function deserializeEventAttachment(event: Event): IArchiveEventDetails[] {
  if (!isFileEvent(event)) {
    return null;
  }

  const { content_type: contentType, size, url, height, width, name } = event;
  const isSafetyConfirmed = getPropertyValue<boolean>(event.properties, 'lc2', 'file_safety_confirmation');

  return [
    {
      name,
      contentType,
      size,
      url,
      ...(height && { height }),
      ...(width && { width }),
      isSafetyConfirmed,
    },
  ];
}

function deserializeEventType(type: string): ChatEventType {
  const eventType = type as ChatEventType;
  if (eventType === ChatEventType.SystemMessage) {
    return ChatEventType.Event;
  }

  if (eventType === ChatEventType.File) {
    return ChatEventType.Attachmment;
  }

  return eventType;
}

function deserializeEventTranslation(event: Event): IArchiveEvent['translation'] | null {
  const translation = event?.properties?.translation;
  if (!translation) {
    return null;
  }

  return {
    sourceLangCode: translation.source_lang_code,
    targetLangCode: translation.target_lang_code,
    targetMessage: translation.target_message,
  };
}

function mergeRatingEvents(previousEvent: IArchiveEvent, currentEvent: ThreadEventResult): IArchiveEvent {
  return {
    ...previousEvent,
    subType: ChatEventSubType.Rated,
    textVariables: {
      ...previousEvent.textVariables,
      ...(currentEvent.text_vars && { ...currentEvent.text_vars }),
    },
  };
}

function deserializeForm(event: ThreadEventResult): IArchiveEvent | null {
  const customFormType = getCustomFormType(event);

  const timestampInMs = getTime(event.created_at);

  switch (customFormType) {
    case CustomFormType.InactivityMessage: {
      const { fields, id } = event;
      const [field] = fields;

      return {
        id,
        agentId: null,
        authorName: AUTOMATIC_MESSAGE,
        avatar: AUTOMATIC_MESSAGE_SVG,
        date: deserializeEventDate(timestampInMs),
        text: field.label,
        isMarkdownEvent: getIsMarkdownEvent(event),
        timestampInMs,
        type: ChatEventType.Message,
        userType: ArchiveUserType.Automatic,
        translation: deserializeEventTranslation(event as unknown as Event),
      };
    }
    default: {
      return null;
    }
  }
}

function getSurveyType(event: ThreadEventResult): SurveyType | undefined {
  const { properties, form_id: formId } = event;

  return getPropertyValue<SurveyType>(properties, 'lc2', 'form_type') || formIdToSurveyType[formId];
}

function deserializeCustomFormSurvey(event: ThreadEventResult, users: User[]): IArchiveEvent | null {
  if (getSurveyType(event) === SurveyType.PreChat) {
    const customer = users.find(isCustomerChatUser);
    const timestampInMs = getTime(event.created_at);
    const { fields, id } = event;

    const responses: ISurveyResponse[] = fields.map((field) => {
      return {
        answer: parseSurveyFieldValue(field),
        question: field.label,
        id: field.id,
        type: field.type,
      };
    });

    return {
      id,
      preChatSurvey: responses,
      authorName: deserializeAuthorName(customer, ArchiveUserType.Customer),
      date: deserializeEventDate(timestampInMs),
      text: null,
      timestampInMs,
      type: ChatEventType.FilledForm,
      userType: ArchiveUserType.Customer,
      translation: deserializeEventTranslation(event as unknown as Event),
    };
  }

  if (getSurveyType(event) !== SurveyType.Custom) {
    return null;
  }

  const customFormType = getCustomFormType(event);

  const customer = users.find(isCustomerChatUser);
  const timestampInMs = getTime(event.created_at);

  const { fields, id } = event;
  const [field] = fields;

  let responses: ISurveyResponse[] = [];

  switch (customFormType) {
    case CustomFormType.AskForEmail: {
      responses = [
        {
          answer: parseSurveyFieldValue(field),
          question: 'E-mail:',
          id: field.id,
          type: field.type,
        },
      ];
      break;
    }
  }

  return {
    id,
    customForm: {
      customFormType,
      responses,
    },
    authorName: deserializeAuthorName(customer, ArchiveUserType.Customer),
    date: deserializeEventDate(timestampInMs),
    text: field.label,
    timestampInMs,
    type: ChatEventType.FilledForm,
    userType: ArchiveUserType.Customer,
    translation: deserializeEventTranslation(event as unknown as Event),
  };
}

function deserializeEvents(events: Event[] = [], users: User[], agents: IAgentsAndBots): IArchiveEvent[] {
  if (events.length === 0) {
    return null;
  }
  const serializedEvents = events.reduce((acc: IArchiveEvent[], event) => {
    if (isFormEvent(event)) {
      const formEvent = deserializeForm(event as unknown as ThreadEventResult);
      if (formEvent) {
        acc.push(formEvent);
      }

      return acc;
    }

    if (isFilledFormEvent(event)) {
      const filledFormEvent = deserializeCustomFormSurvey(event as unknown as ThreadEventResult, users);
      if (filledFormEvent) {
        acc.push(filledFormEvent);
      }

      return acc;
    }

    let text: string = isWithTextEvent(event) ? event.text : '';
    let textVariables: Record<string, string>;
    let subType: ChatEventSubType;

    if (isSystemMessageEvent(event)) {
      textVariables = event.text_vars || {};
      subType = event.system_message_type as ChatEventSubType;

      if (shouldCheckEventForComment(event.system_message_type as ChatEventSubType)) {
        const { text: extractedText, comment } = extractCommentFromEventText(text);

        if (comment) {
          text = extractedText;
          textVariables = { ...(textVariables || {}), comment };
        }
      }
    }

    const user = isWithAuthorEvent(event) && users.find((u) => u.id === event.author_id);
    const agent = user && agents[user.id];
    const userType = deserializeEventUserType(
      event as unknown as ThreadEventResult,
      user as unknown as ChatUserResultType
    );
    const previousEvent = acc[acc.length - 1];
    const shouldMerge =
      subType === ChatEventSubType.RateCommentedOnly &&
      previousEvent &&
      previousEvent.subType === ChatEventSubType.RatedOnly;

    if (shouldMerge) {
      const targetEvent = mergeRatingEvents(previousEvent, event as unknown as ThreadEventResult);

      acc[acc.length - 1] = targetEvent;
    } else {
      const timestampInMs = getTime(event.created_at);
      const targetEvent: IArchiveEvent = {
        id: event.id,
        agentId: [ArchiveUserType.Agent, ArchiveUserType.Supervisor].includes(userType)
          ? isWithAuthorEvent(event) && event.author_id
          : null,
        authorName: deserializeAuthorName(user, userType),
        avatar: agent?.avatar || user?.avatar,
        date: deserializeEventDate(timestampInMs),
        details: deserializeEventAttachment(event),
        richMessageDetails: isRichMessageEvent(event) ? deserializeRichMessageDetails(event.elements) : null,
        richMessageTemplate: isRichMessageEvent(event) ? (event.template_id as unknown as RichMessageTemplate) : null,
        subType,
        text,
        isMarkdownEvent: getIsMarkdownEvent(event as unknown as ThreadEventResult),
        textVariables,
        timestampInMs,
        type: deserializeEventType(event.type),
        userType,
        translation: deserializeEventTranslation(event),
        eventId: event.id,
        messageReaction: deserializeEventMessageReaction(event),
        formatting: deserializeEventChatsFormatting(event),
      };

      acc.push(targetEvent);
    }

    return acc;
  }, []);

  serializedEvents.sort((a, b) => a.timestampInMs - b.timestampInMs);

  return serializedEvents;
}

function deserializeGoals(goals: Goal[]): IGoal[] {
  if (!goals) {
    return null;
  }

  return goals.map((goal) => ({
    id: goal.id,
    name: goal.name,
    orderCurrency: goal.currency,
    orderDescription: goal.order_description,
    orderId: goal.order_id,
    orderPrice: goal.order_price,
    timestamp: goal.timestamp ? goal.timestamp * 1000 : null,
    type: (goal.type as GoalType) || GoalType.Goal,
  }));
}

function deserializeIntegrations(properties: ChatProperties): IIntegrations {
  const facebookMessenger = getFacebookMessengerIntegration(properties);

  if (!facebookMessenger) {
    return null;
  }

  return {
    facebookMessenger,
  };
}

function deserializeChatSurvey(surveyType: SurveyType, events: Event[] = []): ISurveyResponse[] {
  const filledForms = events.filter(isFilledFormEvent);
  const event = filledForms.find((e) => getSurveyType(e as never as ThreadEventResult) === surveyType);

  if (!event) {
    return null;
  }

  const fields = event.fields.filter((field) => !['title', 'information'].includes(field.type));

  return fields.map((field: IFilledFormField) => ({
    answer: parseSurveyFieldValue(field),
    question: field.label,
    id: field.id,
    type: field.type,
  }));
}

function deserializeRate(properties: ChatProperties): ArchiveRate {
  const rating = properties?.rating?.score;

  if (isNil(rating)) {
    return ArchiveRate.NotRated;
  }

  return anyToBoolean(rating) ? ArchiveRate.Good : ArchiveRate.Bad;
}

function deserializeVisitorLastVisit(
  lastVisit: IChatCustomerResult['last_visit'],
  firstKey: string,
  secondKey?: string
): string {
  if (!lastVisit) {
    return null;
  }

  if (!secondKey) {
    return lastVisit[firstKey] as string;
  }

  return lastVisit[firstKey]?.[secondKey] as string;
}

function deserializeVisitor(users: User[]): IArchive['visitor'] {
  const customer = users.find(isCustomerChatUser);
  if (!customer) {
    return null;
  }

  const { email, id, last_visit: lastVisit, name, avatar = null } = customer;

  return {
    city: deserializeVisitorLastVisit(lastVisit, 'geolocation', 'city'),
    country: deserializeVisitorLastVisit(lastVisit, 'geolocation', 'country'),
    countryCode: deserializeVisitorLastVisit(lastVisit, 'geolocation', 'country_code'),
    email,
    id,
    ip: deserializeVisitorLastVisit(lastVisit, 'ip'),
    name,
    region: deserializeVisitorLastVisit(lastVisit, 'geolocation', 'region'),
    timezone: deserializeVisitorLastVisit(lastVisit, 'geolocation', 'timezone'),
    userAgent: deserializeVisitorLastVisit(lastVisit, 'user_agent'),
    latitude: deserializeVisitorLastVisit(lastVisit, 'geolocation', 'latitude'),
    longitude: deserializeVisitorLastVisit(lastVisit, 'geolocation', 'longitude'),
    avatar,
  };
}

function deserialize(data: Chat, agents: IAgentsAndBots): IArchive {
  const {
    access,
    id,
    properties,
    restricted_access: restrictedAccessReason,
    queues_duration: queuedFor,
    user_ids: userIds,
    previous_thread_id,
    next_thread_id,
    previous_accessible_thread_id,
    next_accessible_thread_id,
  } = data.thread;

  const isOfflineMessage = data.thread?.properties?.routing?.offline_message;
  const isUnassignedChat =
    (data.thread?.properties?.routing?.continuous as boolean) &&
    (data.thread?.properties?.routing?.unassigned as boolean);
  const isChatThreadPrioritized = properties?.[CLIENT_ID]?.[ThreadCustomPropertyName.PriorityChat] as boolean;

  return {
    agents: deserializeAgents(data),
    cameFromURL: properties?.routing?.referrer,
    chatClientId: data.properties?.source?.client_id,
    chatId: data.id,
    customVariables: data.thread.custom_variables || null,
    events: deserializeEvents(data.thread.events, data.users, agents),
    goals: deserializeGoals(data.thread.goals),
    groupIds: access?.group_ids,
    id,
    userIds,
    integrations: deserializeIntegrations(data.properties),
    isUnassignedChat,
    postChatSurvey: deserializeChatSurvey(SurveyType.PostChat, data.thread.events),
    preChatSurvey: deserializeChatSurvey(SurveyType.PreChat, data.thread.events),
    ticketForm: deserializeChatSurvey(SurveyType.Ticket, data.thread.events),
    queuedFor,
    rate: deserializeRate(data.thread.properties),
    restrictedAccessReason,
    startedOnURL: properties?.routing?.start_url,
    startedTimestamp: getThreadEntityTimestampInMs(data.thread),
    tags: data.thread.tags || [],
    tagsProcessing: [],
    tagsFailureAdditions: [],
    tagsFailureDeletions: [],
    type: isOfflineMessage ? ArchiveType.Missed : ArchiveType.Chat,
    visitor: deserializeVisitor(data.users),
    previousThreadId: previous_thread_id || null,
    nextThreadId: next_thread_id || null,
    previousAccessibleThreadId: previous_accessible_thread_id || null,
    nextAccessibleThreadId: next_accessible_thread_id || null,
    isChatThreadPrioritized,
    // TODO: Abandon Properties as it uses legacy fallback to `value` wrapper.
    customProperties: properties as Properties,
  };
}

function deserializeCollection(data: Chat[], agents: IAgentsAndBots): IArchive[] {
  return data.map((chat) => deserialize(chat, agents));
}

export function deserializeFetchResult(
  state: DeepPartial<IStoreState> & WithAgentsState & IWithBotsState,
  data: ListArchivesResponse
): IArchive | null {
  const { chats } = data;

  if (isNullOrNotArray(chats)) {
    return null;
  }
  const agents = agentsAndBotsSelector(state);

  return deserialize(chats[0], agents);
}

export function deserializeFetchAllResult(
  state: DeepPartial<IStoreState> & WithAgentsState & IWithBotsState,
  data: ListArchivesResponse,
  limitPerPage = DEFAULT_FETCH_ALL_LIMIT
): IFetchAllResult {
  const { chats, found_chats: totalCount, next_page_id: nextPage } = data;
  const agents = agentsAndBotsSelector(state);

  return {
    data: deserializeCollection(chats, agents),
    totalPages: Math.ceil(totalCount / limitPerPage),
    totalCount,
    nextPage,
  };
}

function deserializeEventMessageReaction(event: Event): IArchiveEvent['messageReaction'] | null {
  const messageReaction = event?.properties?.[CLIENT_ID]?.message_reaction;
  if (!messageReaction) {
    return null;
  }

  return messageReaction as string;
}

function deserializeEventChatsFormatting(event: Event): IArchiveEvent['formatting'] | null {
  const isFormatted = event?.properties?.chats?.formatting === COMMONMARK;
  if (!isFormatted) {
    return null;
  }

  return isFormatted;
}
