// @ts-strict-ignore
import type { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { EventPlace } from 'helpers/analytics';
import { AppStateProvider } from 'services/app-state-provider';
import { trackEvent } from 'services/event-tracking';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { UserGuidedTourActions } from 'store/features/user-guided-tours/actions';
import { isTourReady as isTourReadySelector } from 'store/features/user-guided-tours/selectors';

import { getTooltipSteps, getTourStepsCount, getTourEventName, getFirstStep } from './helpers';
import { TourStepType } from './interfaces';
import type { IUserGuidedTour } from './interfaces';

const tours = new Map<UserGuidedTourType, IUserGuidedTour>();

function createTour(id: UserGuidedTourType): IUserGuidedTour {
  return {
    id,
    isStarted: false,
    isPreStarted: false,
    stepsReady: [],
  };
}

function tourExists(tourId: UserGuidedTourType): boolean {
  const tour = tours.get(tourId);

  return !!tour;
}

function getOrCreateTour(tourId: UserGuidedTourType): IUserGuidedTour {
  if (!tourExists(tourId)) {
    const tour = createTour(tourId);
    tours.set(tourId, tour);

    return tour;
  }

  return tours.get(tourId);
}

function isTourStarted(tourId: UserGuidedTourType): boolean {
  return getOrCreateTour(tourId).isStarted;
}

function isTourPreStarted(tourId: UserGuidedTourType): boolean {
  return getOrCreateTour(tourId).isPreStarted;
}

function isTourReady(tourId: UserGuidedTourType): boolean {
  return AppStateProvider.selectFromStore(isTourReadySelector, tourId);
}

export function isTourStartedManually(tourId: UserGuidedTourType): boolean {
  return getOrCreateTour(tourId).isStartedManually;
}

function trackTourStarted(tourId: UserGuidedTourType): void {
  trackEvent('Started', EventPlace.Tour, {
    startedManually: isTourStartedManually(tourId),
    totalSteps: getTourStepsCount(tourId),
    currentStep: 1,
    name: getTourEventName(tourId),
  });
}

function showTour(tourId: UserGuidedTourType): void {
  if (!isTourPreStarted(tourId)) {
    trackTourStarted(tourId);
  }
  AppStateProvider.dispatch(UserGuidedTourActions.showTour(tourId));
}

function setTourReady(tourId: UserGuidedTourType, isReady: boolean): void {
  AppStateProvider.dispatch(UserGuidedTourActions.tourReady(tourId, isReady));
  if (isReady && isTourStarted(tourId)) {
    showTour(tourId);
  }
}

/**
 * Use this method to continue with a tour that starts with a non-tooltip steps (e.g. initial modal)
 */
export function continueTour(tourId: UserGuidedTourType): void {
  const tour = getOrCreateTour(tourId);
  tour.isStarted = true;
  if (isTourReady(tourId)) {
    showTour(tourId);
  }
}

export function startTour(tourId: UserGuidedTourType, isStartedManually = false): void {
  const tour = getOrCreateTour(tourId);
  tour.isStartedManually = isStartedManually;

  const firstStep = getFirstStep(tourId);
  const shouldPreStart = firstStep.type === TourStepType.Modal;
  if (shouldPreStart) {
    tour.isPreStarted = true;
    trackTourStarted(tourId);
    AppStateProvider.dispatch(GlobalModalActions.showModal(firstStep.modal));
  } else {
    continueTour(tourId);
  }
}

export function stopTour(tourId: UserGuidedTourType): void {
  getOrCreateTour(tourId).isStarted = false;
  AppStateProvider.dispatch(UserGuidedTourActions.hideTour(tourId));
}

export function setTourStepReadiness(tourId: UserGuidedTourType, step: string, isReady: boolean): void {
  const tour = getOrCreateTour(tourId);
  tour.stepsReady = isReady ? [...tour.stepsReady, step] : tour.stepsReady.filter((s) => s !== step);

  const steps = getTooltipSteps(tourId);
  const areAllTooltipStepsReady = steps.every((s) => tour.stepsReady.includes(s.id));

  if (areAllTooltipStepsReady) {
    if (!isTourReady(tourId)) {
      setTourReady(tourId, true);
    }
  } else {
    setTourReady(tourId, false);
  }
}
