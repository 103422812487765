import { type FC } from 'react';

import { Badge } from '@livechat/design-system-react-components';

import { getFilterOperatorLabel } from 'components/filter/helpers';
import { FilterOperator } from 'constants/filters/filter';

import { type FilterCompareLabelValues, type FilterLabelValue } from './interfaces';

export type FilterLabelValueType = string | FilterLabelValue[];

interface IProps {
  value: FilterLabelValueType;
  operator?: FilterOperator;
  compareValues?: FilterCompareLabelValues | null;
}

export const SelectedFilterLabel: FC<IProps> = ({ value, operator = FilterOperator.Or, compareValues }) => {
  if (!value) {
    return null;
  }

  const isCompareValues = (compareValues?.secondValues?.length ?? 0) > 0;
  const arrayLength = Array.isArray(value) ? value.length : 1;
  const badgeValue = isCompareValues
    ? (compareValues?.secondValues?.length ?? 0) + (compareValues?.firstValues?.length ?? 0)
    : arrayLength;

  const isArray = Array.isArray(value);
  const displayValue = typeof value === 'string' ? value : value[0]?.label;

  if (isArray && badgeValue > 1) {
    return <Badge size="compact" kind="secondary" count={badgeValue} />;
  }

  if (isArray && value.length === 1) {
    return (
      <span>
        <strong>{getFilterOperatorLabel(operator)} </strong>
        {displayValue}
      </span>
    );
  }

  return <span>{displayValue}</span>;
};
