import { type SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { chatEntitiesSaga } from './sagas/sagas';
import { tagSuggestionsSaga } from './sagas/tag-suggestions';

export * from './sagas/sagas';

export function* chatsSaga(): SagaIterator {
  yield all([fork(chatEntitiesSaga), fork(tagSuggestionsSaga)]);
}
