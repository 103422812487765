// @ts-strict-ignore
import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { anyToBoolean } from 'helpers/boolean';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import {
  type ChatThreadEntity,
  type IMyChat,
  type IStartedThread,
  type ISupervisedChat,
  type IThreadBase,
} from 'store/entities/chats/interfaces';

import { getChatType } from './get-chat-type';

function getStartedTimestamp(data: any[]): number {
  return data[0] ? getThreadEntityTimestampInMs(data[0]) : null;
}

function deserializeChatBase(data: any, groupIds: string[], type: ChatType): IThreadBase {
  const customer = data.visitor || {};
  const currentAgentId = data.agents[0].login;

  return {
    currentAgentId,
    customerId: data.visitor_id,
    customerEmail: customer.email,
    customerName: customer.name,
    chatId: String(data.chat_room),
    groupIds,
    threadId: data.id,
    type,
  };
}

function deserializeStartedChat(data: any): IStartedThread {
  const status = anyToBoolean(data.active) ? ChatThreadStatus.Active : ChatThreadStatus.Idle;

  return {
    startedTimestamp: getStartedTimestamp(data.messages),
    status,
  };
}

function deserializeMyChat(data: any, groupIds: string[], type: ChatType): IMyChat {
  return {
    ...deserializeChatBase(data, groupIds, type),
    ...deserializeStartedChat(data),
  };
}

function deserializeSupervisedChat(data: any, groupIds: string[], type: ChatType): ISupervisedChat {
  return {
    ...deserializeChatBase(data, groupIds, type),
    ...deserializeStartedChat(data),
  };
}

export function deserializeChat(data: any, groupIds: string[]): ChatThreadEntity {
  const type = getChatType(data);
  const deserializers = {
    [ChatType.My]: deserializeMyChat,
    [ChatType.Supervised]: deserializeSupervisedChat,
  };

  return deserializers[type](data, groupIds, type);
}
