import difference from 'lodash.difference';
import { type SagaIterator } from 'redux-saga';
import { all, call, takeLatest, put, takeEvery, select } from 'redux-saga/effects';

import { SESSION_STORAGE_DRAFT_KEY, NEW_TICKET_TEMP_ID } from 'constants/tickets/drafts';
import { deserializeError } from 'helpers/deserialize-error';
import { capitalizeFirstLetter } from 'helpers/string';
import type { RequestResult } from 'interfaces/api/client';
import { type ITicket, TicketStatus } from 'interfaces/entities/ticket';
import { ApiManager } from 'services/api/api-manager';
import {
  type IUpdateTicketTagsResponse,
  type IUpdateTicketSubjectResponse,
  type IUpdateTicketAssigneeResponse,
  type ISendTicketMessageParams,
  type ICreateTicketResponse,
  type ICreateTicketErrorResponse,
} from 'services/api/ticket/api';
import { TicketSerializer } from 'services/api/ticket/serializer';
import { getItem } from 'services/session-storage';
import { type IActionWithPayload } from 'store/helper';
import { TicketsViewActions, type TicketsViewActionsNames } from 'store/views/tickets/actions';
import { getCurrentPage } from 'store/views/tickets/selectors';

import { CRUDAction, RequestAction } from '../actions';
import { getLoggedInAgentLogin } from '../agents/selectors';

import { TicketActions, TICKET } from './actions';
import {
  type ICreateTicketPayload,
  type IFetchTicketPayload,
  type IFetchTicketsCollectionPayload,
  type IUpdateTicketTagsPayload,
  type IUpdateTicketSubjectPayload,
  type IUpdateTicketRequesterPayload,
  type IUpdateTicketCCsPayload,
  type IUpdateTicketAssigneePayload,
  type IUpdateTicketGroupsPayload,
  type ISendTicketMessagePayload,
  type IUnspamPayload,
} from './interfaces';

export function* fetch(action: IActionWithPayload<string, IFetchTicketPayload>): SagaIterator {
  const { id, source } = action.payload;
  const { result }: RequestResult<any> = yield call(ApiManager.ticketApi.fetch, action.payload.id as string);

  if (result) {
    const ticket = TicketSerializer.deserialize(result);
    yield put(TicketActions.fetchSuccess({ id, value: ticket, ...(source && { source }) }));
  } else {
    yield put(TicketActions.fetchFailure({ id, error: 'Not found', ...(source && { source }) }));
  }
}

export function* fetchCollection(
  action: IActionWithPayload<TicketsViewActionsNames, IFetchTicketsCollectionPayload>
): SagaIterator {
  const params = action.payload;
  const currentUserLogin: string = yield select(getLoggedInAgentLogin);
  const { result, error }: RequestResult<any> = yield call(ApiManager.ticketApi.fetchAll, params, currentUserLogin);

  if (result) {
    const { pages, total, tickets } = result;

    const currentPage: number = yield select(getCurrentPage);
    if (pages > 0 && currentPage > pages) {
      yield put(TicketsViewActions.setPage(pages));

      return;
    }

    let ticketDrafts: any = yield call(getItem, SESSION_STORAGE_DRAFT_KEY);
    ticketDrafts = ticketDrafts || {};
    const ticketsData: ITicket[] = TicketSerializer.deserializeCollection(tickets).map((ticket) => {
      const ticketWithDraft = ticket;
      const draft = ticketDrafts[ticket.id];
      if (draft) {
        ticketWithDraft.draft = draft;
      }

      return ticketWithDraft;
    });

    yield put(
      TicketsViewActions.setTicketsData({
        data: ticketsData,
        totalPages: pages,
        totalResults: total,
      })
    );

    yield put(TicketActions.fetchCollectionSuccess({ values: ticketsData }));
  } else {
    yield put(TicketActions.fetchCollectionFailure({ error: error.message }));
  }
}

function* unspam(action: IActionWithPayload<string, IUnspamPayload>): SagaIterator {
  const { ticketId } = action.payload;
  const { result }: RequestResult<any> = yield call(ApiManager.ticketApi.updateStatus, ticketId, TicketStatus.Open);

  if (result) {
    yield put(TicketActions.unspamSuccess());
  } else {
    yield put(TicketActions.unspamFailure());
  }
}

export function* updateTags(action: IActionWithPayload<string, IUpdateTicketTagsPayload>): SagaIterator {
  const { ticketId, tags } = action.payload;
  const { result, error }: RequestResult<IUpdateTicketTagsResponse> = yield call(
    ApiManager.ticketApi.updateTags,
    ticketId,
    { tag: tags }
  );

  if (result) {
    const { tags: newTags } = result;

    yield put(TicketActions.updateTagsSuccess({ ticketId, tags: newTags }));
  } else {
    yield put(TicketActions.updateTagsFailure({ error: error.error || error.message }));
  }
}

export function* updateSubject(action: IActionWithPayload<string, IUpdateTicketSubjectPayload>): SagaIterator {
  const { ticketId, subject } = action.payload;
  const { result, error }: RequestResult<IUpdateTicketSubjectResponse> = yield call(
    ApiManager.ticketApi.updateSubject,
    ticketId,
    { subject }
  );

  if (result) {
    const { subject: newSubject } = result;

    yield put(TicketActions.updateSubjectSuccess({ ticketId, subject: newSubject }));
  } else {
    yield put(TicketActions.updateSubjectFailure({ error: error.message }));
  }
}

export function* updateRequester(action: IActionWithPayload<string, IUpdateTicketRequesterPayload>): SagaIterator {
  const { ticketId, requesterEmail } = action.payload;
  const { result, error }: RequestResult<any> = yield call(ApiManager.ticketApi.updateRequester, ticketId, {
    requesterEmail,
  });

  if (result) {
    const requester = TicketSerializer.deserializeRequester(result.requester);
    yield put(TicketActions.updateRequesterSuccess({ ticketId, requester }));
  } else {
    yield put(TicketActions.updateRequesterFailure({ error: error.message }));
  }
}

export function* updateCCs(action: IActionWithPayload<string, IUpdateTicketCCsPayload>): SagaIterator {
  const { ticketId, ccs } = action.payload;
  const { result, error }: RequestResult<any> = yield call(ApiManager.ticketApi.updateCCs, ticketId, { ccs });

  if (result) {
    const { ccs: newCCs } = result;

    yield put(TicketActions.updateCCsSuccess({ ticketId, ccs: newCCs }));
  } else {
    yield put(TicketActions.updateCCsFailure({ error: error.message }));
  }
}

export function* updateAssignee(action: IActionWithPayload<string, IUpdateTicketAssigneePayload>): SagaIterator {
  const { ticketId, assigneeId } = action.payload;
  const { result, error }: RequestResult<IUpdateTicketAssigneeResponse> = yield call(
    ApiManager.ticketApi.updateAssignee,
    ticketId,
    { assigneeId }
  );

  if (result) {
    const { assignee } = result;

    yield put(TicketActions.updateAssigneeSuccess({ ticketId, assignee }));
  } else {
    yield put(TicketActions.updateAssigneeFailure({ error: error.message }));
  }
}

export function* updateGroups(action: IActionWithPayload<string, IUpdateTicketGroupsPayload>): SagaIterator {
  const { ticketId, currentGroupIds, newGroupIds } = action.payload;

  const ticketGroupIdsInt = currentGroupIds.map((groupId) => Number(groupId));
  const newGroupIdsInt = newGroupIds.map((groupId) => Number(groupId));
  const addGroupsIds = difference(newGroupIdsInt, ticketGroupIdsInt);
  const removeGroupsIds = difference(ticketGroupIdsInt, newGroupIdsInt);

  if (addGroupsIds.length > 0) {
    const { result, error }: RequestResult<any> = yield call(ApiManager.ticketApi.addGroups, ticketId, addGroupsIds);

    if (!result) {
      yield put(TicketActions.updateGroupsFailure({ error: error.message }));

      return;
    }
  }

  if (removeGroupsIds.length > 0) {
    const { result, error }: RequestResult<any> = yield call(
      ApiManager.ticketApi.removeGroups,
      ticketId,
      removeGroupsIds
    );

    if (!result) {
      yield put(TicketActions.updateGroupsFailure({ error: error.message }));

      return;
    }
  }

  yield put(TicketActions.updateGroupsSuccess({ ticketId }));
}

function* sendMessage(action: IActionWithPayload<string, ISendTicketMessagePayload>): SagaIterator {
  const { ticketId, message, isPrivate, status, attachments } = action.payload;
  const apiPayload: ISendTicketMessageParams = { message, isPrivate, attachments };
  if (status) {
    apiPayload.status = status;
  }
  const { result, error }: RequestResult<any> = yield call(ApiManager.ticketApi.sendMessage, ticketId, apiPayload);

  if (result) {
    yield put(TicketActions.sendMessageSuccess({ ...action.payload }));
    yield put(TicketsViewActions.clearDraft({ ticketId }));
  } else {
    yield put(TicketActions.sendMessageFailure({ error: error.message }));
  }
}

function* createTicket(action: IActionWithPayload<string, ICreateTicketPayload>): SagaIterator {
  const { payload } = action;
  const { result, error }: RequestResult<ICreateTicketResponse, ICreateTicketErrorResponse> = yield call(
    ApiManager.ticketApi.createTicket,
    payload
  );

  if (result) {
    yield put(
      TicketActions.createTicketSuccess({
        ...payload,
        ticketId: result.id,
      })
    );
    yield put(TicketsViewActions.clearDraft({ ticketId: NEW_TICKET_TEMP_ID }));
  } else {
    const errorMessage = deserializeError(error);
    yield put(
      TicketActions.createTicketFailure({
        ...payload,
        error: capitalizeFirstLetter(errorMessage),
      })
    );
  }
}

export function* ticketSagas(): SagaIterator {
  yield all([
    takeEvery(TICKET.CREATE[RequestAction.REQUEST], createTicket),
    takeEvery(TICKET[CRUDAction.FETCH_SINGLE][RequestAction.REQUEST], fetch),
    takeLatest(TICKET[CRUDAction.FETCH_COLLECTION][RequestAction.REQUEST], fetchCollection),
    takeEvery(TICKET.UPDATE_TAGS[RequestAction.REQUEST], updateTags),
    takeEvery(TICKET.UPDATE_SUBJECT[RequestAction.REQUEST], updateSubject),
    takeEvery(TICKET.UPDATE_REQUESTER[RequestAction.REQUEST], updateRequester),
    takeEvery(TICKET.UPDATE_CCS[RequestAction.REQUEST], updateCCs),
    takeEvery(TICKET.UPDATE_ASSIGNEE[RequestAction.REQUEST], updateAssignee),
    takeEvery(TICKET.UPDATE_GROUPS[RequestAction.REQUEST], updateGroups),
    takeEvery(TICKET.SEND_MESSAGE[RequestAction.REQUEST], sendMessage),
    takeEvery(TICKET.UNSPAM[RequestAction.REQUEST], unspam),
  ]);
}
