// @ts-strict-ignore
import type { IPickerListItem } from '@livechat/design-system-react-components';
import {
  subDays,
  startOfToday,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfDay,
  differenceInCalendarDays,
  format,
  subYears,
} from 'date-fns';
import memoizeOne from 'memoize-one';

import { type IRangeDatePickerOption, type ISingleItem, InputName } from 'components/filters/date-range-filter/interfaces';
import { DateFormat } from 'constants/date';
import {
  CustomDateRangeFilterId,
  DateRangeFilterFormatedValues,
  type CustomDateRange,
} from 'constants/filters/date-range-filter';


export const calculateOptions = memoizeOne((todayDate: Date): IRangeDatePickerOption[] => [
  {
    id: CustomDateRangeFilterId.Today,
    label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.Today],
    value: { from: todayDate, to: todayDate },
  },
  {
    id: CustomDateRangeFilterId.Yesterday,
    label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.Yesterday],
    value: { from: subDays(todayDate, 1), to: subDays(todayDate, 1) },
  },
  {
    id: CustomDateRangeFilterId.Last7Days,
    label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.Last7Days],
    value: { from: subDays(todayDate, 6), to: todayDate },
  },
  {
    id: CustomDateRangeFilterId.Last30Days,
    label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.Last30Days],
    value: { from: subDays(todayDate, 29), to: todayDate },
  },
  {
    id: CustomDateRangeFilterId.LastMonth,
    label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.LastMonth],
    value: { from: startOfMonth(subMonths(todayDate, 1)), to: endOfMonth(subMonths(todayDate, 1)) },
  },
  {
    id: CustomDateRangeFilterId.CurrentMonth,
    label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.CurrentMonth],
    value: { from: startOfMonth(todayDate), to: todayDate },
  },
  {
    id: CustomDateRangeFilterId.CustomPeriod,
    isManual: true,
    label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.CustomPeriod],
    value: null,
  },
]);

const calculateCompareOptions = memoizeOne((dateFrom: Date, dateTo: Date): IRangeDatePickerOption[] => {
  const daysDiff = Math.abs(differenceInCalendarDays(dateFrom, dateTo)) + 1;

  return [
    {
      id: CustomDateRangeFilterId.SamePeriod,
      label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.SamePeriod],
      value: { from: subDays(dateFrom, daysDiff), to: subDays(dateTo, daysDiff) },
    },
    {
      id: CustomDateRangeFilterId.SamePeriodInLastYear,
      label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.SamePeriodInLastYear],
      value: { from: subYears(dateFrom, 1), to: subYears(dateTo, 1) },
    },
    {
      id: CustomDateRangeFilterId.CustomPeriod,
      isManual: true,
      label: DateRangeFilterFormatedValues[CustomDateRangeFilterId.CustomPeriod],
      value: null,
    },
  ];
});

const calculateItems = memoizeOne((options: IRangeDatePickerOption[]) =>
  options.map((option) => ({
    key: option.id,
    props: {
      label: option.label,
      value: option.id,
      isManual: Boolean(option.isManual),
    },
  }))
);

const calculatePickerItems = memoizeOne((options: IRangeDatePickerOption[]) =>
  options.map((option) => ({
    key: option.id,
    name: option.label,
  }))
);

export function getOptions(): IRangeDatePickerOption[] {
  const todayDate = startOfToday();

  return calculateOptions(todayDate);
}

export function getCompareOptions(dateFrom: Date, dateTo: Date): IRangeDatePickerOption[] {
  return calculateCompareOptions(dateFrom, dateTo);
}

export function getItems(): ISingleItem[] {
  return calculateItems(getOptions());
}

export function getPickerItems(): IPickerListItem[] {
  return calculatePickerItems(getOptions());
}

export function getPickerCompareItems(dateFrom: Date, dateTo: Date): IPickerListItem[] {
  return calculatePickerItems(getCompareOptions(dateFrom, dateTo));
}

export function isCustomPeriodOptionSelected(filterValues: CustomDateRange): boolean {
  return filterValues && filterValues.id === CustomDateRangeFilterId.CustomPeriod;
}

// JS validation, all IOS mobile browsers don't support html5 min and max attributes
export function validateInputValue(fieldName: InputName, value: string, currentFromInputValue: string): string {
  const todayDate = startOfToday();
  const chosenDate = startOfDay(new Date(value));

  if (fieldName === InputName.From) {
    if (differenceInCalendarDays(chosenDate, todayDate) > 0) {
      return `The date can't be later than ${format(todayDate, DateFormat.DateWithoutDayOfTheWeek)}`;
    }
  } else {
    if (currentFromInputValue) {
      const minDate = startOfDay(new Date(currentFromInputValue));
      if (differenceInCalendarDays(minDate, chosenDate) > 0) {
        return "The end date can't be before the start date";
      }
    }
    if (differenceInCalendarDays(chosenDate, todayDate) > 0) {
      return `The date can't be later than ${format(todayDate, DateFormat.DateWithoutDayOfTheWeek)}`;
    }
  }

  return null;
}

export function isNativeBrowserDatePickerSupported(): boolean {
  const input = document.createElement('input');
  const value = 'a';
  input.setAttribute('type', 'date');
  input.setAttribute('value', value);

  return input.value !== value;
}
