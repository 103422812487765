import { GreetingType, RichMessageType, type IGreeting } from 'store/entities/greetings/interfaces';

export const getGreetingType = (greeting: IGreeting): GreetingType => {
  if (!greeting.richGreetingData || !greeting.richGreetingData.template_id) {
    return GreetingType.Text;
  }

  return {
    [RichMessageType.Cards]: GreetingType.Card,
    [RichMessageType.QuickReplies]: GreetingType.QuickReplies,
  }[greeting.richGreetingData.template_id];
};