import { type SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { NavigationPath } from 'constants/navigation';
import { ToastContent } from 'constants/toasts';
import { navigate } from 'helpers/routing';
import { getToastContent } from 'helpers/toast';
import type { Group } from 'interfaces/groups';
import { AppStateProvider } from 'services/app-state-provider';
import { type GetChatResponse } from 'services/connectivity/agent-chat-api/chats/types/get-chat';
import { type AgentChatApiResponse } from 'services/connectivity/agent-chat-api/types';
import { HTTPStatus } from 'services/connectivity/http/types';
import { handleIncomingChat } from 'services/socket-lc3/chat/event-handling/incoming-chat';
import { type IIncomingChatPushEvent } from 'services/socket-lc3/chat/interfaces';
import { isContinuousChatThread } from 'services/socket-lc3/chat/serialization/helpers/common';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { updateCustomersCollections, doFetchChatThreadDetails } from 'store/entities/chats/sagas';
import { getThreadExists } from 'store/entities/chats/selectors';
import { getCurrentAgentGroups } from 'store/entities/groups/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';

import { ChatsViewActions } from '../actions';

export function* selectNonExistingChatFromRoute(chatId: string, threadId: string): SagaIterator {
  const { result, error }: AgentChatApiResponse<GetChatResponse> = yield call(
    doFetchChatThreadDetails,
    chatId,
    threadId
  );

  if (error) {
    const isChatInaccessible = error.status === HTTPStatus.Forbidden;
    yield put(ChatsEntitiesActions.fetchChatThreadDetailsFailure({ chatId, isChatInaccessible }));

    if (isChatInaccessible) {
      // already handled in views reducer
      return false;
    }
  }
  if (result?.thread) {
    const { access, thread } = result;
    const { active, properties } = thread;

    const isArchived = !active && !isContinuousChatThread(properties);

    if (isArchived) {
      yield call(navigate, `${NavigationPath.Archives}/${threadId}`);

      return false;
    }

    const currentAgentGroups: Group[] = yield select(getCurrentAgentGroups);
    const [groupId] = access.group_ids;
    const hasAccess = currentAgentGroups.some((group) => group.id === String(groupId));

    if (!hasAccess) {
      yield put(ChatsViewActions.setInaccessibleChatId({ chatId }));

      return false;
    }

    // HTTP and RTM responses have the same structure but use different types
    const chat = result as never as IIncomingChatPushEvent['chat'];

    yield call(handleIncomingChat, { chat, shouldSkipNotification: true } as IIncomingChatPushEvent, AppStateProvider);
    yield call(updateCustomersCollections, [chat]);
  }

  const isThreadExisting = yield select(getThreadExists, threadId);

  if (isThreadExisting) {
    yield put(ChatsViewActions.setSelectedId({ threadId }));

    return false;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.NO_CHAT_FOUND_ERROR),
      kind: ToastVariant.Error,
    })
  );

  return true;
}
