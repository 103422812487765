import { type PickRequired } from 'helpers/interface';
import { AgentBotSerializer } from 'services/api/agent-bot/serializer';
import type { IAppState } from 'services/app-state-provider';
import { type Bot } from 'services/connectivity/configuration-api/bots/types';
import { AgentBotActions } from 'store/entities/bots/actions';
import { GroupActions } from 'store/entities/groups/actions';

export function handleBotUpdated(payload: PickRequired<Bot, 'id'>, store: IAppState): void {
  const deserializedPayload = AgentBotSerializer.partialDeserializeV3(payload);
  store.dispatch(AgentBotActions.botUpdate(deserializedPayload));

  if (payload.groups) {
    store.dispatch(GroupActions.groupsFetch());
  }
}
