import keyBy from 'lodash.keyby';

import { type IActionWithPayload } from 'store/helper';

import { type AgentBotAction, AgentBotActionNames } from './actions';
import { type IBotsState, type IChatbotInfoSuccessPayload, type IAgentBot } from './interfaces';

const initialState: IBotsState = {};

export const botsReducer = (state: IBotsState = initialState, action: AgentBotAction): IBotsState => {
  switch (action.type) {
    case AgentBotActionNames.BOTS_FETCHED: {
      const { payload } = action as IActionWithPayload<string, IAgentBot[]>;

      return {
        ...state,
        ...keyBy(payload, 'login'),
      };
    }

    case AgentBotActionNames.CHATBOT_INFO_FETCH_SUCCESS: {
      const { payload: chatbotInfo } = action as IActionWithPayload<string, IChatbotInfoSuccessPayload>;
      const chatbots = { ...state };

      const botsWithInfo = Object.entries(chatbots).map(([botId, botInfo]) => {
        const newInfo = chatbotInfo[botId];

        return { ...botInfo, login: botId, chatbotInfo: newInfo };
      });

      return { ...keyBy(botsWithInfo, 'login') };
    }

    case AgentBotActionNames.BOT_ADD: {
      const { payload } = action as IActionWithPayload<string, IAgentBot>;

      return {
        ...state,
        [payload.login]: { ...payload },
      };
    }

    case AgentBotActionNames.BOT_UPDATE: {
      const { payload } = action as IActionWithPayload<string, IAgentBot>;

      return {
        ...state,
        ...(state[payload.login]
          ? {
              [payload.login]: {
                ...state[payload.login],
                ...payload,
              },
            }
          : null),
      };
    }

    case AgentBotActionNames.BOT_REMOVE: {
      const { payload } = action as IActionWithPayload<string, IAgentBot>;
      const newBots = { ...state };
      delete newBots[payload.login];

      return {
        ...newBots,
      };
    }

    default:
      return state;
  }
};
