import { CustomerDetailsSectionTitles } from 'components/customer-details-column/constants';
import { plural } from 'helpers/string';

export enum ChatSummaryStatus {
  Initial = 'initial',
  Generated = 'generated',
  Error = 'error',
}

export enum ChatSummaryValidationRules {
  MinLength = 100,
  MaxLength = 15000,
}

export enum ChatSummaryButtons {
  Cancel = 'Cancel',
  TryAgain = 'Try again',
  SummarizeChat = 'Summarize chat',
  LearnMore = 'Learn more',
  LeaveFeedback = 'Leave feedback',
}

export enum ChatSummaryErrorMessages {
  MinLength = 'You can only summarize chats between agents and clients that are over 100 characters.',
  MaxLength = 'Sorry, this chat is too long. You can summarize chats of max. 15 000 characters.',
  ServerResponse = "Sorry, the chat couldn't be summarized.",
  RemoveFail = "Sorry, the chat summary couldn't be removed.",
  Removed = 'Chat summary deleted.',
  AddFail = "Sorry, the chat summary couldn't be saved.",
  RetrievalFail = "Sorry, the summary couldn't be presented.",
}

export const ChatSummaryContent = {
  onboardingTooltip: {
    title: 'Summarize your chats',
    content: 'Now you can quickly summarize your chats using Artificial Intelligence.',
  },
  header: {
    title: CustomerDetailsSectionTitles.ChatSummary,
  },
  main: {
    initial: 'Summarize your chat in a simple list using AI.',
  },
  box: {
    created: (date: string) => `Created: ${date}`,
    updating: 'Updating summary.',
    mayTakeUpTo: 'This may take up to a minute.',
    copyAriaLabel: 'Copy chat summary',
    completeSummary: 'Complete',
    messageTitle: (count: number, isArchive: boolean) =>
      isArchive ? 'not included' : `${plural(count, 'new message', 'new messages')}`,
    messageCount: (count: number) =>
      `Last ${plural(count, "message isn't", `${count} messages aren’t`)}  included. Update summary to include ${plural(
        count,
        'it',
        'them',
      )}.`,
    chatSummarized: 'Entire chat summarized.',
    menu: {
      button: {
        ariaLabel: 'Chat summary menu',
      },
      items: {
        update: 'Update summary',
        feedback: 'Leave feedback',
        delete: 'Delete summary',
      },
    },
    tooltip: {
      options: 'Options',
      copy: 'Copy',
    },
  },
};

export const SPRIG_SURVEY_URL =
  'https://a.sprig.com/TW9ZRi11RUI2NmJqfnNpZDo1ODJjYzFlYS0yZTRlLTQyNWItYjk5OC1iOTVlMTljMjlkZDU=';
