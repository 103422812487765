import { type FormType, type Visibility } from '../types';

import { type EventProperties } from './property';

export const enum EventType {
  FILE = 'file',
  MESSAGE = 'message',
  FORM = 'form',
  FILLED_FORM = 'filled_form',
  RICH_MESSAGE = 'rich_message',
  CUSTOM = 'custom',
  SYSTEM_MESSAGE = 'system_message',
}

const enum FormFieldType {
  HEADER = 'header',
  SUBJECT = 'subject',
  NAME = 'name',
  EMAIL = 'email',
  QUESTION = 'question',
  TEXTAREA = 'textarea',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  GROUP_CHOOSER = 'group_chooser',
}

export const enum RichMessageTemplateType {
  CARDS = 'cards',
  STICKERS = 'sticker',
  QUICK_REPLIES = 'quick_replies',
}

const enum WebviewHeight {
  COMPACT = 'compact',
  FULL = 'full',
  TALL = 'tall',
}

export const enum ButtonType {
  WEBVIEW = 'webview',
  MESSAGE = 'message',
  URL = 'url',
  PHONE = 'phone',
}

interface BaseEvent {
  id: string;
  custom_id?: string;
  created_at: string;
  type: EventType;
  visibility: Visibility;
  properties?: EventProperties;
}

interface WithAuthorIdEvent extends BaseEvent {
  author_id: string;
}

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#file}
 */
export interface FileEvent extends WithAuthorIdEvent {
  type: EventType.FILE;
  name: string;
  url: string;
  thumbnail_url?: string;
  thumbnail2x_url?: string;
  content_type: string;
  size?: number;
  width?: number;
  height?: number;
  alternative_text?: string;
}

type FormFieldOption = {
  id: string;
  label: string;
};

interface FormField {
  type: FormFieldType;
  id: string;
  label: string;
  required: boolean;
  options?: FormFieldOption[];
}

interface FormBaseEvent extends WithAuthorIdEvent {
  form_id: string;
  form_type?: FormType;
}

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#form}
 */
export interface FormEvent extends FormBaseEvent {
  fields: FormField[];
}

type FormFieldAnswer = {
  id: string;
  label: string;
};

type FormFieldAnswerGroup = {
  id: string;
  group_id: number;
  label: string;
};

type FilledFormField = {
  type: FormFieldType;
  id: string;
  label: string;
  answer?: string | FormFieldAnswer | FormFieldAnswerGroup;
  answers?: FormFieldAnswer[];
};

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#filled-form}
 */
export interface FilledFormEvent extends FormBaseEvent {
  type: EventType.FILLED_FORM;
  fields: FilledFormField[];
}

interface Postback {
  id: string;
  thread_id: string;
  event_id: string;
  type?: string;
  value?: string;
}

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#message}
 */
export interface MessageEvent extends WithAuthorIdEvent {
  type: EventType.MESSAGE;
  text: string;
  postback?: Postback;
}

interface Image {
  name: string;
  url: string;
  content_type: string;
  size: number;
  width: number;
  height: number;
  alternative_text: string;
}

interface Button {
  text: string;
  type?: ButtonType;
  value?: string;
  webview_height?: WebviewHeight;
  postback_id: string;
  user_ids: string[];
  target?: string;
}

interface Element {
  title: string;
  subtitle: string;
  image: Image;
  buttons?: Button[];
}

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#rich-message}
 */
export interface RichMessageEvent extends WithAuthorIdEvent {
  type: EventType.RICH_MESSAGE;
  template_id: RichMessageTemplateType;
  elements: Element[];
}

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#custom}
 */
interface CustomEvent extends WithAuthorIdEvent {
  type: EventType.CUSTOM;
  content?: unknown;
}

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#system-message}
 */
export interface SystemMessageEvent extends BaseEvent {
  type: EventType.SYSTEM_MESSAGE;
  text?: string;
  system_message_type: string;
  text_vars?: Record<string, string>;
}

/**
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#events}
 */
export type Event =
  | FileEvent
  | FormEvent
  | FilledFormEvent
  | MessageEvent
  | RichMessageEvent
  | CustomEvent
  | SystemMessageEvent;

export type WithAuthorEvent = Exclude<Event, SystemMessageEvent>;

export type WithTextEvent = SystemMessageEvent | MessageEvent;
