import { createAction, type ActionsUnion } from 'store/helper';

import type { IAccount, IAgentRole, InvitationMode } from './interfaces';

export enum AccountsActionNames {
  SET_ACCOUNTS = 'ENTITIES/ACCOUNTS/SET_ACCOUNTS',
  SET_ROLES = 'ENTITIES/ACCOUNTS/SET_ROLES',
  SET_AUTHORIZATIONS_CLIENT_IDS = 'ENTITIES/ACCOUNTS/AUTHORIZATIONS_CLIENT_IDS',
  SET_CURRENT_ORGANIZATION_ID = 'ENTITIES/ACCOUNTS/SET_CURRENT_ORGANIZATION_ID',
  SET_INVITATION_MODE = 'ENTITIES/ACCOUNTS/SET_INVITATION_MODE',
  FETCH_ACCOUNT_INFO = 'ENTITIES/ACCOUNTS/FETCH_ACCOUNT_ORGANIZATIONS',
}

type ISetRolesPayload = IAgentRole[];

type ISetAccountsPayload = IAccount[];

export const AccountsActions = {
  setAccounts: (payload: ISetAccountsPayload) => createAction(AccountsActionNames.SET_ACCOUNTS, payload),
  setRoles: (payload: ISetRolesPayload) => createAction(AccountsActionNames.SET_ROLES, payload),
  fetchAccountInfo: () => createAction(AccountsActionNames.FETCH_ACCOUNT_INFO),
  setCurrentOrganizationId: (organizationId: string) =>
    createAction(AccountsActionNames.SET_CURRENT_ORGANIZATION_ID, { organizationId }),
  setInvitationMode: (invitationMode: InvitationMode) =>
    createAction(AccountsActionNames.SET_INVITATION_MODE, { invitationMode }),
  setAuthorizationsClientIds: (authorizationsClientIds: string[]) =>
    createAction(AccountsActionNames.SET_AUTHORIZATIONS_CLIENT_IDS, { authorizationsClientIds }),
};

export type AccountAction = ActionsUnion<typeof AccountsActions>;
