interface IPrepareLastEventMessageParams {
  lastEventText: string;
  currentAgent: string;
  isQueuedChatVisuallyClosed?: boolean;
  wasChatTransferred?: boolean;
}

export const ANOTHER_AGENT_LABEL = 'another agent';

export const prepareLastEventMessage = ({
  lastEventText,
  currentAgent,
  isQueuedChatVisuallyClosed,
  wasChatTransferred,
}: IPrepareLastEventMessageParams): string => {
  if (isQueuedChatVisuallyClosed || wasChatTransferred) {
    return getAssignedAgentText(currentAgent);
  }

  return lastEventText;
};

export function getAssignedAgentText(agentName = ''): string {
  return `Assigned to ${agentName || ANOTHER_AGENT_LABEL}.`;
}
