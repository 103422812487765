import { type ActionsUnion, createAction } from 'store/helper';

import type {
  IAgentsFetchedPayload,
  IAgentUpdatedPayload,
  IAgentRemovedPayload,
  IAgent,
  IAgentAddToGroupPayload,
  IAgentRemoveFromGroupPayload,
} from './interfaces';

export enum AgentActionNames {
  AGENTS_FETCHED = 'ENTITIES/AGENTS/AGENTS_FETCHED',
  AGENT_ADD = 'ENTITIES/AGENTS/AGENT_ADD',
  AGENT_UPDATE = 'ENTITIES/AGENTS/AGENT_UPDATE',
  AGENT_REMOVE = 'ENTITIES/AGENTS/AGENT_REMOVE',
  AGENT_ADD_TO_GROUP = 'ENTITIES/AGENTS/AGENT_ADD_TO_GROUP',
  AGENT_REMOVE_FORM_GROUP = 'ENTITIES/AGENTS/AGENT_REMOVE_FORM_GROUP',
}

export const AgentActions = {
  agentsFetched: (payload: IAgentsFetchedPayload) => createAction(AgentActionNames.AGENTS_FETCHED, payload),
  agentAdd: (payload: IAgent) => createAction(AgentActionNames.AGENT_ADD, payload),
  agentUpdate: (payload: IAgentUpdatedPayload) => createAction(AgentActionNames.AGENT_UPDATE, payload),
  agentRemove: (payload: IAgentRemovedPayload) => createAction(AgentActionNames.AGENT_REMOVE, payload),
  agentAddToGroup: (payload: IAgentAddToGroupPayload) => createAction(AgentActionNames.AGENT_ADD_TO_GROUP, payload),
  agentRemoveFromGroup: (payload: IAgentRemoveFromGroupPayload) =>
    createAction(AgentActionNames.AGENT_REMOVE_FORM_GROUP, payload),
};

export type AgentAction = ActionsUnion<typeof AgentActions>;
