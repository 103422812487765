export enum GlobalEcomerceEvents {
  AddTransaction = 'global.ecommerce:addTransaction',
  AddItem = 'global.ecommerce:addItem',
  Send = 'global.ecommerce:send',
}

export enum GTMEvents {
  FirstPayment = 'firstPayment',
}

export enum SubscriptionEventFields {
  BillingPeriod = 'billingPeriod',
  GoogleAnalytics = 'addon ga',
  SubscriptionChangeType = 'type',
  OldPlan = 'plan before',
  NewPlan = 'plan after',
  IsPlanChanged = 'plan changed',
  OldSeats = 'seats before',
  NewSeats = 'seats after',
  IsSeatsFieldChanged = 'seats changed',
  OldMonths = 'months before',
  NewMonths = 'months after',
  IsMonthsFieldChanged = 'months changed',
  OldFlexiblePricing = 'flexible pricing before',
  NewFlexiblePricing = 'flexible pricing after',
  RecommendedPlan = 'recommended plan',
  HighlightedFeatures = 'highlighted features',
  AppsInstallationStatus = 'apps installation status',
  AppsChanged = 'apps changed',
  AppsInstalled = 'apps installed',
  AppsUninstalled = 'apps uninstalled',
}

export const LabsSalesTrackerId: Record<string, string> = {
  newMonthly: 'xDfzEjQ2h0k1MGD4h22fXSxEVLtzkJbc',
  upgradesMonthly: 'womfhIS9gPgMbgZ9UI4GtfshHHolXsGu',
  newAnnually: 'mykLKpg4DNvEKmKqAIrcBD3J8AWtL2vz',
  upgradesAnnually: 'VYmOJogWvFENBX5Fna205Uyfy8tmdnkb',
};

export const StagSalesTrackerId: Record<string, string> = {
  newMonthly: 'ccxzd9MnR6YRmxvSFZrlU8yxMfRB0D0n',
  upgradesMonthly: 'UKys0m8QljOTqqG3QuXHD5d4LSJ82jxI',
  newAnnually: '3TqrQftW6fOV31SAk9I3H2Tne1bGtMIq',
  upgradesAnnually: '913DpuDDRKIO8wGbdYcALmmppZegSOsU',
};
