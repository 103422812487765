import type { IAppState } from 'services/app-state-provider';
import { deserializeChatEventCollection } from 'services/serialization/chat-events';
import { deserializeChat } from 'services/socket-lc2/chat/serialization/deserialize';
import { getLoggedInAgentName } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadExists } from 'store/entities/chats/selectors';
import { getCustomer } from 'store/entities/customers/selectors';
import { ChatsViewActions } from 'store/views/chats/actions';

import { isLastEventATransfer } from './helpers/chat-details-update';

/**
 * Handle update of chat data triggered by API. Beside basic property updates it may
 * also involve triggering some side effects. Those are:
 * * Marking chat as important,
 * * Adding chat thread as supervised.
 * @param payload Consists of `chat` data provided by API.
 * @param store Current `redux` store state.
 */
export function handleChatDetailsUpdate(payload: any, store: IAppState): void {
  const { chat: responseChat } = payload;
  const { visitor_id: customerId } = responseChat;
  const state = store.getState();

  // Ignore the event if no info is stored about the customer.
  const customer = getCustomer(state, customerId);
  if (!customer) {
    return;
  }

  // Deserialize chat data
  const threadExists = getThreadExists(state, responseChat.id);
  const agentName = getLoggedInAgentName(state);
  const deserializedThread = deserializeChat(responseChat, customer.groupIds?.map(String) ?? []);
  const deserializedEvents = deserializeChatEventCollection(responseChat, agentName);

  // Update thread properties if thread exists in the store.
  if (threadExists) {
    // Mark transferred chats as new chat with dot.
    const wasLastEventATransfer = isLastEventATransfer(responseChat.events);
    if (wasLastEventATransfer) {
      responseChat.active = true;
      store.dispatch(ChatsViewActions.markChatAsNew({ threadId: responseChat.id }));
    }

    store.dispatch(
      ChatsEntitiesActions.updateChat({
        thread: deserializedThread,
        events: deserializedEvents,
      })
    );
  }
}
