/* eslint-disable @typescript-eslint/naming-convention */

import type { SVGProps } from 'react';

import { Automation, ShoppingCart, Report, FiletypeXls, TextBlockFilled } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { ReactElement } from 'react-markdown/lib/react-markdown';

import insightsLogo from 'assets/img/insights.png';

import { iconWrapper, integrationIconWrapper } from './styles';

const shortcutIconConfig: Record<string, (props: SVGProps<SVGSVGElement>) => JSX.Element> = {
  '/automation': Automation,
  '/sales&marketing': ShoppingCart,
  '/my_activity_week': Report,
  '/my_activity_month': Report,
  '/team_activity_week': Report,
  '/team_activity_month': Report,
  '/license_performance': Report,
  '/reports_support': FiletypeXls,
};

const integrationIconConfig: Record<string, { icon: string; title: string }> = {
  '/insights_top_questions': {
    icon: insightsLogo,
    title: 'Insights',
  },
  '/insights_tags': {
    icon: insightsLogo,
    title: 'Insights',
  },
};

export const getIconForShortcut = (shortcut: string): ReactElement => {
  const integrationIcon = integrationIconConfig[shortcut];

  if (integrationIcon) {
    return (
      <div className={integrationIconWrapper}>
        <img src={integrationIcon.icon} alt={integrationIcon.title} />
      </div>
    );
  }

  const shortcutIcon = shortcutIconConfig[shortcut];

  if (shortcutIcon) {
    return (
      <div className={iconWrapper}>
        <Icon source={shortcutIcon} />
      </div>
    );
  }

  return (
    <div className={iconWrapper}>
      <Icon source={TextBlockFilled} />
    </div>
  );
};
