import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type RemoveUserFromChatEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#remove-user-from-chat
 */
export async function removeUserFromChat(chatId: string, userId: string): Promise<unknown> {
  const event: RemoveUserFromChatEvent = {
    action: WebSocketAction.RemoveUserFromChat,
    payload: {
      /* eslint-disable @typescript-eslint/naming-convention */
      chat_id: chatId,
      user_id: userId,
      user_type: 'agent',
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
