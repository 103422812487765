import { type TargetedMessagesTypePath } from 'constants/targeted-messages';

import { type GreetingsViewState, type IGreetingTableSortRules } from './interfaces';

interface IWithGreetingsViewState {
  views: {
    greetings: GreetingsViewState;
  };
}

export function getGreetingName(state: IWithGreetingsViewState): string {
  return state.views.greetings.name;
}

export function getGreetingTableSortRules(
  state: IWithGreetingsViewState,
  type: TargetedMessagesTypePath
): IGreetingTableSortRules {
  return state.views.greetings.tableSortRules[type];
}
