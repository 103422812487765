import { type FC } from 'react';

import { Provider } from 'react-redux';

import { store } from 'store/store';

export const StoreProvider: FC = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
);
