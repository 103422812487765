import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { type IApplication } from 'store/entities/applications/interfaces';
import { getApplicationsOrder, getSelectedApplications } from 'store/entities/applications/selectors';

import { sortByCustomApplicationOrder } from '../helpers';

export const useSelectedVisibleApps = (): [IApplication[], string[]] => {
  const applicationsOrder = useSelector(getApplicationsOrder);
  const selectedApplications = useSelector(getSelectedApplications);

  const selectedVisibleApps = useMemo(
    () => [...selectedApplications].sort(sortByCustomApplicationOrder(applicationsOrder)),
    [applicationsOrder, selectedApplications],
  );
  const selectedVisibleAppsIds = useMemo(() => selectedVisibleApps.map(({ id }) => id), [selectedVisibleApps]);

  return [selectedVisibleApps, selectedVisibleAppsIds];
};
