import { type FC, useEffect, useState } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Button, Text, Tooltip } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { useParamSelector } from 'hooks/use-param-selector';
import { getIsAIAgentAvailableByName } from 'store/entities/ai-agents/selectors';
import { getIsCopilotModalExpanded, getCopilotIsLoading } from 'store/views/copilot/selectors';

import { useAboveCharacterLimit } from '../hooks/use-above-character-limit';
import { useSendCopilotMessage } from '../hooks/use-send-copilot-message';

import { ONE_CHARACTER_LIMIT } from './constants';

import * as styles from './styles';

export const AskButton: FC = () => {
  const [editor] = useLexicalComposerContext();

  const [isDisabled, setDisabled] = useState(() => {
    const currentMessageValue = editor.getRootElement()?.innerText.trim();

    return !currentMessageValue;
  });
  const isLoading = useSelector(getCopilotIsLoading);
  const { isAboveLimit } = useAboveCharacterLimit();
  const handleSend = useSendCopilotMessage();
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const hasOneAIAgent = useParamSelector(getIsAIAgentAvailableByName, 'one');

  const shouldDisableButton = hasOneAIAgent ? isLoading || isDisabled || isAboveLimit : true;

  useEffect(() => {
    const registerTextContentListener = editor.registerTextContentListener((textContent) =>
      setDisabled(!textContent.trim()),
    );

    return () => {
      registerTextContentListener();
    };
  }, [editor]);

  const onSend = (): void => {
    if (isLoading) {
      return;
    }

    handleSend({ source: 'message box', state: isExpanded ? 'fullscreen' : 'popover' });
  };

  const button = (
    <Button
      kind="high-contrast"
      className={styles.sendButton}
      disabled={shouldDisableButton}
      iconPosition="right"
      onClick={onSend}
    >
      <Text bold>Ask</Text>
    </Button>
  );

  if (isLoading && hasOneAIAgent) {
    return (
      <Tooltip className={styles.sendButtonTooltip} theme="invert" triggerRenderer={() => button}>
        <Text>You can send a message once the reply suggestion is generated</Text>
      </Tooltip>
    );
  }

  if (isAboveLimit) {
    return (
      <Tooltip className={styles.aboveLimitTooltip} theme="invert" triggerRenderer={() => button}>
        <Text>You can send up to {ONE_CHARACTER_LIMIT.toLocaleString('en-US')} characters in a single message</Text>
      </Tooltip>
    );
  }

  return button;
};
