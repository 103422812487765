import { createAction } from 'store/helper';

import { type IExampleNotificationOptions, type INotification } from './interfaces';

export enum BrowserNotificationsActionNames {
  SHOW_NOTIFICATION = 'FEATURES/BROWSER_NOTIFICATIONS/SHOW_NOTIFICATION',
  CANCEL_NOTIFICATION = 'FEATURES/BROWSER_NOTIFICATIONS/CANCEL_NOTIFICATION',
  CANCEL_SOME_NOTIFICATIONS = 'FEATURES/BROWSER_NOTIFICATIONS/CANCEL_SOME_NOTIFICATIONS',
  CANCEL_ALL_NOTIFICATIONS = 'FEATURES/BROWSER_NOTIFICATIONS/CANCEL_ALL_NOTIFICATIONS',
  SHOW_EXAMPLE_NOTIFICATION = 'FEATURES/BROWSER_NOTIFICATIONS/SHOW_EXAMPLE_NOTIFICATION',
}

export const BrowserNotificationsActions = {
  show: (options: INotification) => createAction(BrowserNotificationsActionNames.SHOW_NOTIFICATION, options),
  cancel: (notification: string) => createAction(BrowserNotificationsActionNames.CANCEL_NOTIFICATION, notification),
  cancelSome: (tags: string[]) => createAction(BrowserNotificationsActionNames.CANCEL_SOME_NOTIFICATIONS, tags),
  cancelAll: () => createAction(BrowserNotificationsActionNames.CANCEL_ALL_NOTIFICATIONS),
  showExample: (options: IExampleNotificationOptions) =>
    createAction(BrowserNotificationsActionNames.SHOW_EXAMPLE_NOTIFICATION, options),
};
