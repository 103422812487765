import { useEffect, useMemo, useRef } from 'react';

import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';

import { TopBarNotificationType } from 'constants/notifications';
import { useConnectivityStatus } from 'hooks/connectivity/status/use-connectivity-status';
import { ReconnectionStatus } from 'services/connectivity/reconnector/types';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

import { useReconnectionDetails } from './use-reconnection-details';

const reconnectingStatuses = [ReconnectionStatus.Failed, ReconnectionStatus.Retrying, ReconnectionStatus.Scheduled];
const nonReconnectingStatuses = [ReconnectionStatus.Succeeded, ReconnectionStatus.Idle];

export const CONNECTION_RESTORED_NOTIFICATION_TIMEOUT = 2500;

export function useConnectionBarsVisibility(): void {
  const dispatch = useDispatch();
  const { isThrottledOffline } = useConnectivityStatus();
  const { status } = useReconnectionDetails();
  const hasBeenOffline = useRef(false);

  const debouncedHideNotification = useMemo(
    () =>
      debounce(() => {
        dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.ConnectionRestored));
      }, CONNECTION_RESTORED_NOTIFICATION_TIMEOUT),
    [dispatch]
  );

  useEffect(() => {
    if (isThrottledOffline) {
      hasBeenOffline.current = true;
      dispatch(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.Offline }));

      return;
    }

    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.Offline));

    if (hasBeenOffline.current) {
      dispatch(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.ConnectionRestored }));

      debouncedHideNotification();
    }
  }, [isThrottledOffline, dispatch, debouncedHideNotification]);

  useEffect(() => {
    if (reconnectingStatuses.includes(status)) {
      dispatch(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.Reconnecting }));
    } else if (nonReconnectingStatuses.includes(status)) {
      dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.Reconnecting));

      if (status === ReconnectionStatus.Succeeded) {
        dispatch(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.ConnectionRestored }));

        debouncedHideNotification();
      }
    }
  }, [debouncedHideNotification, dispatch, status]);

  useEffect(() => {
    return () => {
      debouncedHideNotification.cancel();
    };
  }, [debouncedHideNotification]);
}
