export const enum TicketEvent {
  AddTag = 'Add tag button clicked',
  AssigneeChanged = 'Assignee changed',
  AttachmentAdded = 'Attachment added',
  CCsChanged = 'CCs changed',
  ChangeCCsModalOpened = 'Change CCs modal opened',
  ChangeRequesterModalOpened = 'Change requester modal opened',
  CreateTicketFormVisited = 'Create ticket form visited',
  FilterApplied = 'Filter applied',
  GroupsChanged = 'Groups changed',
  HelpDeskLandingCTAClicked = 'HelpDesk landing: CTA clicked',
  HelpDeskLandingExternalLinkClicked = 'HelpDesk landing: External link clicked',
  HelpDeskLandingQuestionsChatWithUsClicked = 'HelpDesk landing: Questions > Chat with us clicked',
  HelpDeskPromoClicked = 'HelpDesk promo clicked',
  HelpDeskPromoDismissed = 'HelpDesk promo dismissed',
  ManyTicketsEdited = 'Many tickets edited',
  RequesterChanged = 'Requester changed',
  SampleTicketOpened = 'Sample ticket opened',
  SearchCleared = 'Search cleared',
  SearchUsed = 'Search used',
  SeeTicketsFromAllGroups = 'See tickets from all groups',
  SubjectChanged = 'Subject changed',
  TicketCreatedManually = 'Ticket created manually',
  TicketOpened = 'Ticket opened',
  TicketReplied = 'Ticket replied',
  URLCopied = 'URL copied',
}
