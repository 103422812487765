import { LogicOperator } from 'constants/logic-operator';
import { getConfig } from 'helpers/config';
import { ThreadCustomPropertyName } from 'services/socket-lc3/chat/event-handling/constants';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { calculateAgentIdChattingWithCustomer, calculateCustomerActivity } from 'store/entities/customers/computed';
import { getCustomerProvidedName, isCustomerReturning } from 'store/entities/customers/helpers/customer';
import { CustomerCustomVariableName, type ICustomer, type IVisitedPage } from 'store/entities/customers/interfaces';
import { type EntityData } from 'store/entities/interfaces';

import {
  FilterBooleanOperator,
  FilterIpOperator,
  FilterNumericOperator,
  FilterSetOperator,
  FilterStringOperator,
  FilterType,
  type TrafficFilter,
} from '../interfaces';

import { getReferrerHostname } from './customer-host-name';
import { customerIpMatchesRange } from './customer-ip-check';

export function getLastPageTitle(visitedPages: EntityData<IVisitedPage>): string {
  const lastPageIndex = visitedPages?.allIds.length - 1;
  const lastPageId = visitedPages?.allIds[lastPageIndex];
  const lastVisitedPage = visitedPages?.byIds[lastPageId];

  return lastVisitedPage?.title ?? '';
}

export function matchesStringValue(value: string, filter: TrafficFilter): boolean {
  switch (filter.operator) {
    case FilterStringOperator.Contains:
      return value.toLocaleLowerCase().indexOf(filter.value.toLocaleLowerCase()) >= 0;
    case FilterStringOperator.DoesNotContain:
      return value.toLocaleLowerCase().indexOf(filter.value.toLocaleLowerCase()) < 0;
    case FilterStringOperator.IsExactly:
      return value === filter.value;
    case FilterStringOperator.IsNot:
      return value !== filter.value;
    case FilterStringOperator.HasAnyValue:
      return !!value;
    default:
      return false;
  }
}

export function matchesNumericValue(value: number, filter: TrafficFilter): boolean {
  if (filter.operator === FilterNumericOperator.IsBetween) {
    const { firstValue, secondValue } = filter;

    return value >= firstValue && value <= secondValue;
  }

  switch (filter.operator) {
    case FilterNumericOperator.IsExactly:
      return value === filter.value;
    case FilterNumericOperator.IsNot:
      return value !== filter.value;
    case FilterNumericOperator.IsGreaterThan:
      return value > filter.value;
    case FilterNumericOperator.IsGreaterThanOrEqualTo:
      return value >= filter.value;
    case FilterNumericOperator.IsLessThan:
      return value < filter.value;
    case FilterNumericOperator.IsLessThanOrEqualTo:
      return value <= filter.value;
    default:
      return false;
  }
}

export function matchesBooleanValue(value: boolean, filter: TrafficFilter): boolean {
  switch (filter.operator) {
    case FilterBooleanOperator.IsTrue:
      return value;
    case FilterBooleanOperator.IsFalse:
      return !value;
    default:
      return false;
  }
}

export function matchesSetInclusiveValue(value: string[], filter: TrafficFilter): boolean {
  switch (filter.operator) {
    case FilterSetOperator.Is:
      return filter.value.some((v) => value.includes(v));
    case FilterSetOperator.IsNot:
      return !filter.value.some((v) => value.includes(v));
    default:
      return false;
  }
}

function matchesIPRange(value: string, filter: TrafficFilter): boolean {
  switch (filter.operator) {
    case FilterIpOperator.IsNotMy:
    case FilterIpOperator.IsNotOther:
      return !customerIpMatchesRange(value, filter.value);
    default:
      return false;
  }
}

export function filterCustomer(
  customer: ICustomer,
  thread: ChatThreadEntity,
  visitedPages: EntityData<IVisitedPage>,
  filter: TrafficFilter
): boolean {
  switch (filter.filterType) {
    case FilterType.Activity:
      return matchesSetInclusiveValue([calculateCustomerActivity(customer, thread)], filter);
    case FilterType.AssignedAgent: {
      const agentId = calculateAgentIdChattingWithCustomer(customer, thread);

      return matchesSetInclusiveValue(agentId ? [agentId] : [], filter);
    }
    case FilterType.CameFrom:
      return matchesStringValue(getReferrerHostname(customer.visitDetails?.cameFromURL ?? '') ?? '', filter);
    case FilterType.City:
      return matchesStringValue(customer.geolocation?.city ?? '', filter);
    case FilterType.Country:
      return matchesSetInclusiveValue([customer.geolocation?.country?.toLocaleLowerCase() ?? ''], filter);
    case FilterType.Email:
      return matchesStringValue(customer.email ?? '', filter);
    case FilterType.Group:
      return matchesSetInclusiveValue(customer.groupIds?.map(String) ?? [], filter);
    case FilterType.Intent:
      return matchesSetInclusiveValue([customer.customVariables?.[CustomerCustomVariableName.Intent] ?? ''], filter);
    case FilterType.IP:
      return matchesIPRange(customer.ip ?? '', filter);
    case FilterType.LastPageTitle:
      return matchesStringValue(getLastPageTitle(visitedPages), filter);
    case FilterType.Name:
      return matchesStringValue(getCustomerProvidedName(customer) ?? '', filter);
    case FilterType.NumberOfVisits:
      return matchesNumericValue(customer.statistics?.visitsCount ?? 0, filter);
    case FilterType.ReturningCustomer:
      return matchesBooleanValue(isCustomerReturning(customer), filter);
    case FilterType.State:
      return matchesStringValue(customer.geolocation?.state ?? '', filter);
    case FilterType.WebCrawlers:
      return matchesBooleanValue(!!customer.visitDetails?.isWebCrawler, filter);
    case FilterType.Priority:
      return matchesBooleanValue(
        !!thread.customProperties?.[getConfig().accountsClientId]?.[ThreadCustomPropertyName.PriorityChat],
        filter
      );
  }
}

export function isCustomerWithinFilters(
  customer: ICustomer,
  thread: ChatThreadEntity,
  visitedPages: EntityData<IVisitedPage>,
  filters: TrafficFilter[],
  filterOperator: LogicOperator
): boolean {
  if (filters.length === 0) {
    return true;
  }

  if (filterOperator === LogicOperator.And) {
    return filters.every((filter) => filterCustomer(customer, thread, visitedPages, filter));
  }

  return filters.some((filter) => filterCustomer(customer, thread, visitedPages, filter));
}
