import { PlanType } from 'constants/plan-type';
import { RecurlyFieldType } from 'interfaces/recurly';

export const VERTICAL_VIEW_BREAKPOINT = 842;

export const REQUIRED_RECURLY_FIELDS = [RecurlyFieldType.CVV, RecurlyFieldType.PostalCode, RecurlyFieldType.Country];

export enum SubscriptionSubRoutes {
  Manage = 'manage',
  Upgrade = 'upgrade',
  Checkout = 'checkout',
  ThankYou = 'thank-you',
  PickLiveChatPlan = 'pick-livechat-plan',
  Processing = 'processing',
  Invoices = 'invoices',
  AccountDetails = 'account-details',
  EditBillingDetails = 'account-details#billing-details',
  CancelAccount = 'cancel-account',
  ChoosePlan = 'choose-plan',
  InstallCode = 'install-code',
}

export enum MarketplaceRoutes {
  Cart = '/apps/marketplace/cart',
}

export enum SubscriptionPages {
  MySubscription = 'Your plan',
  PlanSelection = 'Subscription',
  Overview = 'Your plan',
  Invoices = 'Invoices',
  AccountDetails = 'Account details',
  Checkout = 'Checkout',
  Manage = 'Manage',
  RestartTrial = 'Restart trial',
}

export enum MarketplacePages {
  Cart = 'Cart',
}

export const CUSTOM_PRICING_PLANS: PlanType[] = [PlanType.EnterprisePlus];

export const CUSTOM_PRICE_LABEL = 'Custom pricing';

export enum BillingCycleLabel {
  Monthly = 'Monthly',
  MonthlyShort = 'mo',
  Annually = 'Annually',
  AnnuallyShort = 'yr',
  Annually24 = '2yrs',
  Annually36 = '3yrs',
}

export enum PriceLabel {
  AgentAnnualy = 'per agent / yr',
  AgentMonthly = 'per agent / mo',
  SeatMonthly = 'per seat / mo',
  SeatAnnually = 'per seat / yr',
}

export const CONTACT_MANAGER = 'contact-account-manager-button';
export const ENTERPRISE_BOX = 'enterprise-box-container';
export const MANAGE = 'manage-button';
export const UPGRADE_BOX = 'upgrade-box-container';
export const UPGRADE_BUTTON = 'upgrade_button';
export const UPGRADE_TO_ENTERPRISE_BOX = 'upgrade-to-enterprise-box-container';
export const ESTIMATED_TOTAL_ROW = 'estimated-total-row';

export const SUBSCRIPTION_LOADER_TEST_ID = 'subscription-loader-container';

export const CUSTOM_DEAL_DESCRIPTION = 'You’re on a custom LiveChat plan. To make changes, please contact our support.';

export enum SubscriptionPlanFeatureType {
  OneAIAssistant = 'one-ai-assistant',
  Track100Customers = 'track-100-customers',
  OneRecurringCampaign = '1-recurring-campaign',
  SixtyDayChatHistory = '60-day-chat-history',
  BasicWidgetCustomization = 'Basic widget customization',
  TwentyFourSevenSupport = '24/7-support',
  OneUser = '1-user',

  Track400Customers = 'track-400-customers',
  UnlimitedCampaigns = 'unlimited-campaigns',
  UnlimitedChatHistory = 'unlimited-chat-history',
  FullWidgetCustomization = 'full-widget-customization',
  BasicReporting = 'basic-reporting',
  UnlimitedUsers = 'unlimited-users',
  AIFeatures = 'ai-features',
  AdvancedAIFeatures  = 'advanced-ai-features',

  Track1000Customers = 'track-1000-customers',
  WorkScheduler = 'work-scheduler',
  StaffingPrediction = 'staffing-prediction',
  OnDemandReporting = 'on-demand-reporting',
  AdvancedReporting = 'advanced-reporting',
  AgentPerformance = 'agent-performance',
  AppleMessagesForBusinessIntegrations = 'apple-messages-for-business-integrations',

  CustomizeTrackingLimit = 'customize-tracking-limit',
  DedicatedAccountManager = 'dedicated-account-manager',
  DedicatedOnboarding = 'dedicated-onboarding',
  WhiteLabelChatWidget = 'white-label-chat-widget',
  SoftwareEngineerSupport = 'software-engineer-support',
  SingleSignOn = 'single-sign-on',
  HipaaCompliance = 'hipaa-compliance',
  AlternativePaymentTerms = 'alternative-payment-terms',
  ProfessionalServices = 'professional-services',
}
