import { type CustomerAction, CustomerActionNames } from './actions';
import {
  getStateForAddOrUpdateCustomers,
  getStateForAddVisitedPage,
  getStateForRemoveCustomer,
  getStateForRemoveCustomers,
  getStateForSetData,
  getStateForUpdateCustomer,
} from './helpers/reducer';
import { type ICustomersState } from './interfaces';

export const initialState: ICustomersState = {
  byIds: {},
  allIds: [],
  visitedPages: {},
};

export function customersReducer(state: ICustomersState = initialState, action: CustomerAction): ICustomersState {
  switch (action.type) {
    case CustomerActionNames.REMOVE_CUSTOMER:
      return getStateForRemoveCustomer(state, action.payload);
    case CustomerActionNames.REMOVE_CUSTOMERS:
      return getStateForRemoveCustomers(state, action.payload);
    case CustomerActionNames.SET_DATA:
      return getStateForSetData(state, action.payload);
    case CustomerActionNames.UPDATE_CUSTOMER:
      return getStateForUpdateCustomer(state, action.payload);
    case CustomerActionNames.ADD_OR_UPDATE_CUSTOMERS:
      return getStateForAddOrUpdateCustomers(state, action.payload);
    case CustomerActionNames.ADD_VISITED_PAGE:
      return getStateForAddVisitedPage(state, action.payload);
    default:
      return state;
  }
}
