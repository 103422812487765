// @ts-strict-ignore
import { combineReducers, type Reducer } from 'redux';

import { type GTMAction, GTMActionsNames } from './actions';
import { GTMErrorType } from './constants';
import type { IGTMAccount, IGTMContainer, IGTMFlowState, IGTMInstallationState, IGTMState } from './interfaces';

const isInitializing = (state = false, action: GTMAction): boolean => {
  switch (action.type) {
    case GTMActionsNames.SET_IS_INITIALIZING:
      return action.payload.isInitializing;

    default:
      return state;
  }
};

const isAuthorizing = (state = false, action: GTMAction): boolean => {
  switch (action.type) {
    case GTMActionsNames.START_AUTHORIZING:
      return true;

    case GTMActionsNames.STOP_AUTHORIZING:
      return false;

    case GTMActionsNames.SET_IS_AUTHORIZED:
      if (action.payload.isAuthorized) {
        return false;
      }

      return state;

    default:
      return state;
  }
};

const isAuthorized = (state = false, action: GTMAction): boolean => {
  switch (action.type) {
    case GTMActionsNames.SET_IS_AUTHORIZED:
      return action.payload.isAuthorized;

    default:
      return state;
  }
};

const isCreatingTag = (state = false, action: GTMAction): boolean => {
  switch (action.type) {
    case GTMActionsNames.START_CREATING_TAG:
      return true;

    case GTMActionsNames.TAG_CREATED_SUCCESSFULY:
    case GTMActionsNames.ERROR_CREATING_TAG:
    default:
      return state;
  }
};

const isSuccess = (state = false, action: GTMAction): boolean => {
  switch (action.type) {
    case GTMActionsNames.TAG_CREATED_SUCCESSFULY:
      return true;

    default:
      return state;
  }
};

const errorType = (state: GTMErrorType = null, action: GTMAction): GTMErrorType => {
  switch (action.type) {
    case GTMActionsNames.ERROR_CREATING_TAG:
      return GTMErrorType.TagInstallation;

    case GTMActionsNames.ACCOUNT_DATA_ERROR:
      return GTMErrorType.Account;

    case GTMActionsNames.SET_IS_AUTHORIZED:
      if (!action.payload.isAuthorized) {
        return null;
      }

      return state;

    default:
      return state;
  }
};

const flow: Reducer<IGTMFlowState> = combineReducers({
  isInitializing,
  isAuthorizing,
  isAuthorized,
  isCreatingTag,
  isSuccess,
  errorType,
});

const accounts = (state: IGTMAccount[] = [], action: GTMAction): IGTMAccount[] => {
  switch (action.type) {
    case GTMActionsNames.SET_ACCOUNTS:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return action.payload.accounts;

    default:
      return state;
  }
};

const containers = (state: IGTMContainer[] = [], action: GTMAction): IGTMContainer[] => {
  switch (action.type) {
    case GTMActionsNames.SET_CONTAINERS:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return action.payload.containers;

    default:
      return state;
  }
};

const selectedAccountId = (state = '', action: GTMAction): string => {
  switch (action.type) {
    case GTMActionsNames.SET_ACCOUNT_ID:
      return action.payload.accountId;

    case GTMActionsNames.SIGN_OUT:
      return '';

    default:
      return state;
  }
};

const selectedContainerId = (state = '', action: GTMAction): string => {
  switch (action.type) {
    case GTMActionsNames.SET_CONTAINER_ID:
      return action.payload.containerId;

    case GTMActionsNames.SIGN_OUT:
    case GTMActionsNames.SET_CONTAINERS:
      return '';

    default:
      return state;
  }
};

const isFetching = (state = false, action: GTMAction): boolean => {
  switch (action.type) {
    case GTMActionsNames.SET_ACCOUNT_ID:
      return true;

    case GTMActionsNames.SET_IS_AUTHORIZED:
      if (action.payload.isAuthorized) {
        return true;
      }

      return state;

    case GTMActionsNames.SET_ACCOUNTS:
    case GTMActionsNames.SET_CONTAINERS:
    case GTMActionsNames.ACCOUNT_DATA_ERROR:
      return false;

    default:
      return state;
  }
};

const installation: Reducer<IGTMInstallationState> = combineReducers({
  accounts,
  containers,
  selectedAccountId,
  selectedContainerId,
  isFetching,
});

export const gtmReducer: Reducer<IGTMState> = combineReducers({
  flow,
  installation,
});
