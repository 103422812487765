import {
  type IMobileAppPromoBannerState,
  MobileAppPromoBannerPlace,
} from 'store/views/mobile-app-promo-banner/interfaces';

import { MobileAppPromoBannerActionsEnum, type MobileAppPromoBannerActionTypes } from './actions';

const initialState: IMobileAppPromoBannerState = {
  [MobileAppPromoBannerPlace.Team]: { isVisible: true },
  [MobileAppPromoBannerPlace.TeamAgentDetails]: { isVisible: true },
  [MobileAppPromoBannerPlace.TeamChatbotDetails]: { isVisible: true },
  [MobileAppPromoBannerPlace.TeamGroupDetails]: { isVisible: true },
};

export function mobileAppPromoBannerReducer(
  state: IMobileAppPromoBannerState = initialState,
  action: MobileAppPromoBannerActionTypes
): IMobileAppPromoBannerState {
  switch (action.type) {
    case MobileAppPromoBannerActionsEnum.SAVE_IS_MOBILE_APP_PROMO_BANNER_VISIBLE_FOR:
      return {
        ...state,
        [action.payload.place]: {
          isVisible: action.payload.isVisible,
        },
      };

    default:
      return state;
  }
}
