import type { RequestResult } from 'interfaces/api/client';

import { BaseApi, PassVersionMode } from '../base-api';
import type { RequestError } from '../types';

import type { IRateTicketResult, TicketRate } from './interfaces';

export class TicketRatingApi extends BaseApi {
  protected readonly prefix = 'tickets';
  protected readonly version = '2';
  protected readonly passVersionMode: PassVersionMode = PassVersionMode.Prefix;

  rate = (
    ticketId: string,
    rate: TicketRate,
    hash: string
  ): Promise<RequestResult<IRateTicketResult, RequestError>> =>
    this.put(`${ticketId}/rating`, { rate, hash }) as Promise<RequestResult<IRateTicketResult, RequestError>>;
}
