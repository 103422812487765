import { type FC } from 'react';

import { SkeletonLoader } from 'components/loader/SkeletonLoader';

import * as styles from './styles';

const SkeletonWithLoader: FC = () => (
  <div className={styles.appSkeletonWrapper}>
    <div className={styles.appSkeletonLoaderWrapper}>
      <SkeletonLoader />
    </div>
  </div>
);

export const AppSkeleton: FC = () => {
  return <SkeletonWithLoader />;
};
