import { type IWithSubscriptionState } from 'store/entities/subscription/selectors';
import {
  getNumberOfStatusReminderDismisses,
  type IWithAgentCustomPropertiesState,
} from 'store/features/agent-custom-properties/selectors';
import { getCanUseStatusReminder, type IWithSessionState } from 'store/features/session/selectors';

export function shouldShowStatusReminder(
  state: IWithAgentCustomPropertiesState & IWithSessionState & IWithSubscriptionState
): boolean {
  const numberOfDismisses = getNumberOfStatusReminderDismisses(state);
  const canUseStatusReminder = getCanUseStatusReminder(state);

  return canUseStatusReminder && numberOfDismisses !== -1;
}
