import { type ICompanyDetails } from 'interfaces/entities/company-details';
import { createAction, type ActionsUnion } from 'store/helper';

import type { RequestFailure } from '../interfaces';

import type { IUpdateCompanyDetailsPayload } from './interfaces';

export enum CompanyDetailsActionNames {
  FETCH_COMPANY_DETAILS_REQUEST = 'ENTITIES/COMPANY_DETAILS/FETCH_COMPANY_DETAILS_REQUEST',
  FETCH_COMPANY_DETAILS_SUCCESS = 'ENTITIES/COMPANY_DETAILS/FETCH_COMPANY_DETAILS_SUCCESS',
  FETCH_COMPANY_DETAILS_FAILURE = 'ENTITIES/COMPANY_DETAILS/FETCH_COMPANY_DETAILS_FAILURE',
  UPDATE_COMPANY_DETAILS_REQUEST = 'ENTITIES/COMPANY_DETAILS/UPDATE_COMPANY_DETAILS_REQUEST',
  UPDATE_COMPANY_DETAILS_SUCCESS = 'ENTITIES/COMPANY_DETAILS/UPDATE_COMPANY_DETAILS_SUCCESS',
  UPDATE_COMPANY_DETAILS_FAILURE = 'ENTITIES/COMPANY_DETAILS/UPDATE_COMPANY_DETAILS_FAILURE',
  SHOW_MODAL_ON_LOGOUT = 'ENTITIES/COMPANY_DETAILS/SHOW_MODAL_ON_LOGOUT',
}

export enum CompanyDetailsRequestFetchingSelectorsNames {
  FETCH_COMPANY_DETAILS_REQUEST = 'ENTITIES/COMPANY_DETAILS/FETCH_COMPANY_DETAILS',
  UPDATE_COMPANY_DETAILS_REQUEST = 'ENTITIES/COMPANY_DETAILS/UPDATE_COMPANY_DETAILS',
}

export const CompanyDetailsActions = {
  fetch: () => createAction(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_REQUEST),
  fetchSuccess: (payload: ICompanyDetails) =>
    createAction(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS, payload),
  fetchFailure: (error: RequestFailure) =>
    createAction(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_FAILURE, error),
  update: (payload: IUpdateCompanyDetailsPayload) =>
    createAction(CompanyDetailsActionNames.UPDATE_COMPANY_DETAILS_REQUEST, payload),
  updateSuccess: (payload: IUpdateCompanyDetailsPayload) =>
    createAction(CompanyDetailsActionNames.UPDATE_COMPANY_DETAILS_SUCCESS, payload),
  updateFailure: (error: RequestFailure) =>
    createAction(CompanyDetailsActionNames.UPDATE_COMPANY_DETAILS_FAILURE, error),
  showModalOnLogout: () => createAction(CompanyDetailsActionNames.SHOW_MODAL_ON_LOGOUT),
};

export type CompanyDetailsActionTypes = ActionsUnion<typeof CompanyDetailsActions>;
