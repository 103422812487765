import { ChatRecipients } from 'constants/chat-recipients';
import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import {
  type SendMessageEvent,
  type SendMessageEventBody,
  type SendMessagePayload,
} from 'interfaces/web-socket-events';
import { AppStateProvider } from 'services/app-state-provider';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#send-event
 */
export async function sendChatMessage({
  messageId,
  messageText,
  chatId,
  whisper,
  isRichMessage = false,
  json,
  properties,
}: SendMessagePayload): Promise<unknown> {
  const authorId = AppStateProvider.selectFromStore(getLoggedInAgentLogin);

  const eventBase: SendMessageEventBody = {
    type: isRichMessage ? 'rich_message' : 'message',
    text: messageText,
    visibility: whisper ? ChatRecipients.Agents : ChatRecipients.All,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    custom_id: messageId,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    author_id: authorId,
    ...(properties
      ? {
          properties,
        }
      : {}),
  };

  const messageEvent = isRichMessage ? { ...json.payload, ...eventBase } : eventBase;

  const event: SendMessageEvent = {
    action: WebSocketAction.SendEvent,
    payload: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      chat_id: chatId,
      event: messageEvent,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
