// @ts-strict-ignore
import { booleanToNumericString } from 'helpers/boolean';
import {
  deserializeWidgetSnapshotConfig,
  deserializeWidgetSnapshotTheme,
  serializeWidgetSnapshotConfig,
  serializeWidgetSnapshotTheme,
} from 'helpers/widget-preview';
import { GroupProperty, type IGroupProperties } from 'interfaces/entities/group-property';

import { type IWidgetCustomization } from './interfaces';

export function deserializeWidgetCustomization(groupProperties: IGroupProperties): IWidgetCustomization {
  const logoPath = groupProperties[GroupProperty.ChatWindowLogoPath];

  return {
    ...deserializeWidgetSnapshotConfig(groupProperties),
    ...deserializeWidgetSnapshotTheme(groupProperties),
    rating: groupProperties[GroupProperty.RateMeEnabled] === '1',
    agentAvatar: groupProperties[GroupProperty.ChatWindowDisplayAvatar] === '1',
    emailTranscript: groupProperties[GroupProperty.ChatWindowDisplayTranscriptButton] === '1',
    logoEnabled: groupProperties[GroupProperty.ChatWindowDisplayLogo] === '1',
    logoPath: logoPath ? logoPath.replace(/^(\/\/)/, '') : '',
    enableSounds: groupProperties[GroupProperty.ChatWindowDisableSounds] === '0',
    hideOnInit: groupProperties[GroupProperty.ChatWindowHideOnInit] === '1',
    mobileHideOnInit: groupProperties[GroupProperty.ChatWindowMobileHideOnInit] === '1',
    disableMinimized: groupProperties[GroupProperty.ChatWindowDisableMinimized] === '1',
    mobileDisableMinimized: groupProperties[GroupProperty.ChatWindowMobileDisableMinimized] === '1',
    customMobileSettings: groupProperties[GroupProperty.ChatWindowCustomMobileSettings] === '1',
    hideTrademark: groupProperties[GroupProperty.ChatWindowHideTrademark] === '1',
    showOnMobile: groupProperties[GroupProperty.ChatWindowHideOnMobile] === '0',
  };
}

export function serializeWidgetCustomization(
  values: IWidgetCustomization,
  canFullyManageChatWidget: boolean
): Partial<IGroupProperties> {
  return {
    ...serializeWidgetSnapshotConfig(values),
    ...serializeWidgetSnapshotTheme(values),

    [GroupProperty.ChatWindowHideOnMobile]: booleanToNumericString(!values.showOnMobile),
    [GroupProperty.ChatWindowHideOnInit]: booleanToNumericString(values.hideOnInit),
    [GroupProperty.ChatWindowMobileHideOnInit]: booleanToNumericString(values.mobileHideOnInit),
    [GroupProperty.ChatWindowDisableMinimized]: booleanToNumericString(values.disableMinimized),
    [GroupProperty.ChatWindowMobileDisableMinimized]: booleanToNumericString(values.mobileDisableMinimized),
    [GroupProperty.ChatWindowOffsetX]: values.offsetX.toString(),
    [GroupProperty.ChatWindowMobileOffsetX]: values.mobileOffsetX.toString(),
    [GroupProperty.ChatWindowOffsetY]: values.offsetY.toString(),
    [GroupProperty.ChatWindowMobileOffsetY]: values.mobileOffsetY.toString(),
    [GroupProperty.ChatWindowCustomMobileSettings]: booleanToNumericString(values.customMobileSettings),
    [GroupProperty.ChatWindowDisplayLogo]: booleanToNumericString(values.logoEnabled),

    ...(canFullyManageChatWidget && {
      [GroupProperty.ChatWindowLogoPath]: values.logoPath,
      [GroupProperty.RateMeEnabled]: booleanToNumericString(values.rating),
      [GroupProperty.ChatWindowDisableSounds]: booleanToNumericString(!values.enableSounds),
      [GroupProperty.ChatWindowDisplayAvatar]: booleanToNumericString(values.agentAvatar),
      [GroupProperty.ChatWindowDisplayTranscriptButton]: booleanToNumericString(values.emailTranscript),
      [GroupProperty.ChatWindowHideTrademark]: booleanToNumericString(values.hideTrademark),
    }),
  };
}
