import { formatTimeToHours } from 'helpers/format-time';
import { getSeriesDataFromPeriod } from 'helpers/reports';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';

import { type ChartData } from '../../../../interfaces/reports';

export function ticketsTimeDeserializer(data, label: string): ChartData {
  const chartData = getSeriesDataFromPeriod<number>(data, 'hours');
  const labelData = getSeriesDataFromPeriod<number>(data, 'count');

  const hoursSum = Object.keys(data).reduce((sum, key) => sum + (data[key].hours || 0) * data[key].count, 0);
  const countSum = Object.keys(data).reduce((sum, key) => sum + data[key].count, 0);

  const average = hoursSum / (countSum || 1);

  return {
    summary: [
      {
        name: label,
        value: formatTimeToHours(average),
        color: DEFAULT_SERIES_COLOR,
      },
    ],
    labels: Object.keys(data),
    series: [
      {
        name: label,
        data: chartData,
        color: DEFAULT_SERIES_COLOR,
        extras: {
          counts: labelData,
        },
      },
    ],
  };
}
