import { TargetedMessagesTypePath, type TargetedMessagesRoutes } from 'constants/targeted-messages';
import { TargetedMessageSubtype } from 'store/entities/greetings/interfaces';

export function mapTargetedMessageTypeToRoute(
  targetedMessageType: TargetedMessagesTypePath,
  route: TargetedMessagesRoutes
): string {
  return route.replace(':targetedMessageType', targetedMessageType);
}

export const mapTargetedMessageSubtypeToPathType = (
  targetedMessageSubtype: TargetedMessageSubtype
): TargetedMessagesTypePath =>
  ({
    [TargetedMessageSubtype.Greeting]: TargetedMessagesTypePath.Greetings,
    [TargetedMessageSubtype.Announcement]: TargetedMessagesTypePath.Announcements,
  }[targetedMessageSubtype]);
