import { TemplatesCategory } from 'store/entities/greetings/interfaces';

import { type IntegrationTemplatesCategory } from './interfaces';

export enum TemplatesListEventNames {
  TemplateSelected = 'Template selected',
  TemplateCategorySelected = 'Template category selected',
}

const MailchimpRecurringTemplateId = 'bWFpbGNoaW1wLmVXZ2EydUk1Mg==';
const MailchimpOneTimeTemplateId = 'bWFpbGNoaW1wLmVXZ2EydUk1Mw==';
const MailchimpRecurringDevId = 'Smt2Mk83WEdSLmVXZ2EydUk1Mg==';
const MailchimpDevOneTimeId = 'Smt2Mk83WEdSLmFsVkpJQnRKeVo=';

export const integrationIdWithCategory: IntegrationTemplatesCategory = [
  {
    id: MailchimpRecurringTemplateId,
    category: TemplatesCategory.GenerateLeads,
    badge: 'Mailchimp',
  },
  {
    id: MailchimpOneTimeTemplateId,
    category: TemplatesCategory.GenerateLeads,
    badge: 'Mailchimp',
  },
  {
    id: MailchimpRecurringDevId,
    category: TemplatesCategory.GenerateLeads,
    badge: 'MailchimpDev',
  },
  {
    id: MailchimpDevOneTimeId,
    category: TemplatesCategory.GenerateLeads,
    badge: 'MailchimpDev',
  },
];

export const templatesToReplace = {
  [MailchimpRecurringTemplateId]: 'bd09bg93sk200lahkf78583f11',
  [MailchimpOneTimeTemplateId]: 'c4a8af2dc74aa288b015836b7dc6',
} as const;
