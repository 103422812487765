// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';

import { deserializeInvitationLinkPayload, type IInvitationLinkResponse } from 'services/api/accounts/deserializer';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';

import { InviteLinkActionsEnum, InviteLinkActions } from './actions';

function* refreshInviteLink(): SagaIterator {
  try {
    const { result, error }: RequestResult<IInvitationLinkResponse> = yield call(
      ApiManager.accountsApi.refreshInviteToken
    );

    if (error) {
      yield put(InviteLinkActions.refreshInviteLinkFailure(error));

      return;
    }

    const payload = deserializeInvitationLinkPayload(result);
    yield put(InviteLinkActions.refreshInviteLinkSuccess(payload));
  } catch (e) {
    yield put(InviteLinkActions.refreshInviteLinkFailure((e as Error).toString()));
  }
}

function* getInviteLink(): SagaIterator {
  try {
    const { result, error }: RequestResult<IInvitationLinkResponse> = yield call(
      ApiManager.accountsApi.getInviteToken
    );

    if (error && error.result === 'Not Found') {
      yield put(InviteLinkActions.refreshInviteLinkRequest());
      yield put(InviteLinkActions.getInviteLinkFailure('Not found'));

      return;
    }

    const payload = deserializeInvitationLinkPayload(result);
    yield put(InviteLinkActions.getInviteLinkSuccess(payload));
  } catch (e) {
    yield put(InviteLinkActions.getInviteLinkFailure((e as Error).toString()));
    yield put(InviteLinkActions.refreshInviteLinkRequest());
  }
}

export function* inviteLinkSagas(): SagaIterator {
  yield takeEvery(InviteLinkActionsEnum.REFRESH_INVITE_LINK_REQUEST, refreshInviteLink);
  yield takeEvery(InviteLinkActionsEnum.GET_INVITE_LINK_REQUEST, getInviteLink);
}
