// @ts-strict-ignore
import { Calendar } from '@livechat/design-system-icons';
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { type CustomDateFilter, DateFilterId } from 'constants/filters/date-filter';
import { getDateFilterOptions } from 'helpers/date-filter/options';

import type { FilterConfig, FilterLabelValue } from '../interfaces';

import { filterBox, filterButton } from './styles';

export const dateFilterConfig: FilterConfig = {
  title: 'Date',
  icon: Calendar,
  multiple: false,
  getFilterLabelValues: (filterValues: CustomDateFilter | null): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    let label: string;
    if (filterValues.id !== DateFilterId.CustomDate || !filterValues.date) {
      const option = getDateFilterOptions().find((optionItem) => optionItem.id === filterValues.id);
      label = option.label || '';
    } else {
      label = `${format(filterValues.date, DateFormat.ShortDate)}`;
    }

    return [
      {
        key: filterValues.id,
        label,
      },
    ];
  },
  getFilterBoxClassName: (filterValue: CustomDateFilter) => {
    if (filterValue === null || filterValue.id !== DateFilterId.CustomDate) {
      return null;
    }

    return filterBox;
  },
  getFilterButtonClassName: (filterValue: CustomDateFilter) => {
    if (filterValue === null || filterValue.id !== DateFilterId.CustomDate) {
      return null;
    }

    return filterButton;
  },
};
