import { anyToBoolean } from 'helpers/boolean';
import type { KeyMap } from 'helpers/interface';
import type { IWithRequestsState } from 'store/requests/selectors';

import { LicenseCustomPropertiesNames } from './interfaces';

export interface ILicenseCustomPropertiesState {
  entities: {
    licenseCustomProperties: KeyMap<string | string[]>;
  };
}

export const getLicenseCustomProperties = (state: ILicenseCustomPropertiesState): KeyMap<string | string[]> =>
  state.entities.licenseCustomProperties;

function getLicenseCustomProperty(
  state: ILicenseCustomPropertiesState & IWithRequestsState,
  property: string
): boolean {
  const licenseCustomProperties = getLicenseCustomProperties(state);

  if (!licenseCustomProperties) {
    return true;
  }

  return anyToBoolean(licenseCustomProperties[property]);
}

export const getWasCovidCouponUsed = (state: ILicenseCustomPropertiesState): boolean => {
  const licenseCustomProperties = getLicenseCustomProperties(state);

  return anyToBoolean(licenseCustomProperties[LicenseCustomPropertiesNames.CovidCoupon]);
};

export const getHasSeenMessagingModeOnboardingModal = (
  state: ILicenseCustomPropertiesState & IWithRequestsState
): boolean => getLicenseCustomProperty(state, LicenseCustomPropertiesNames.MessagingModeOnboardingModalSeen);

export const getIsHelpDeskChannelOn = (state: ILicenseCustomPropertiesState & IWithRequestsState): boolean =>
  getLicenseCustomProperty(state, LicenseCustomPropertiesNames.HelpDeskChannelOn);

export const getShouldShowHelpDeskPromoTooltip = (state: ILicenseCustomPropertiesState & IWithRequestsState): boolean =>
  getLicenseCustomProperty(state, LicenseCustomPropertiesNames.ShouldShowHelpDeskPromoTooltip);

export const getRoktId = (state: ILicenseCustomPropertiesState & IWithRequestsState): string | undefined => {
  const licenseCustomProperties = getLicenseCustomProperties(state);

  return licenseCustomProperties[LicenseCustomPropertiesNames.RoktId] as string | undefined;
};

export const getPaymentErrorOnAccessBlockedForLicense = (
  state: ILicenseCustomPropertiesState & IWithRequestsState
): string | null => {
  const licenseCustomProperties = getLicenseCustomProperties(state);
  const paymentErrorOnAccessBlocked = licenseCustomProperties[LicenseCustomPropertiesNames.PaymentErrorOnAccessBlocked];

  if (paymentErrorOnAccessBlocked === undefined || paymentErrorOnAccessBlocked === '0') {
    return null;
  }

  return licenseCustomProperties[LicenseCustomPropertiesNames.PaymentErrorOnAccessBlocked] as string;
};
