// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { type SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { PostMessageEvent } from 'constants/post-message-event';
import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type IFetchBillingInfoResult } from 'services/api/billing/interfaces';
import { BillingInfoSerializer } from 'services/api/billing-info/serializer';
import { type ISubscriptionDTO } from 'services/api/subscription/interfaces';
import { SubscriptionSerializer } from 'services/api/subscription/serializer';
import { AppStateProvider } from 'services/app-state-provider';
import { trackEvent } from 'services/event-tracking';
import { BillingActions, BillingActionNames } from 'store/entities/billing/actions';
import { type IAddCreditCardRequestPayload } from 'store/entities/billing/interfaces';
import { LicenseCustomPropertiesActions } from 'store/entities/license-custom-properties/actions';
import { LicenseCustomPropertiesNames } from 'store/entities/license-custom-properties/interfaces';
import { SubscriptionActions } from 'store/entities/subscription/actions';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';

const fetchBillingInfo = () => ApiManager.billingApi.fetchBillingInfo();

const addCreditCard = (props) => ApiManager.billingApi.createWithToken(props);

const updateCreditCard = (props) => ApiManager.billingApi.updateWithToken(props);

const activeAccountState = (props) => ApiManager.billingApi.activeAccountState(props);

const accountCodeError = 'has already been taken';

/**
 * Saves a credit card.
 * Optionally saving the subscription basing on `shouldSaveSubscription` param
 */
function* saveCreditCard(action: IActionWithPayload<BillingActionNames, IAddCreditCardRequestPayload>): SagaIterator {
  try {
    const {
      token,
      three_d_secure_action_result_token,
      isAlreadyUsingRecurly,
      shouldSaveSubscription,
      subscriptionExists,
    } = action.payload;

    let response: RequestResult<unknown> = isAlreadyUsingRecurly
      ? yield call(updateCreditCard, { three_d_secure_action_result_token, token })
      : yield call(addCreditCard, { three_d_secure_action_result_token, token });

    const hasAccountCodeError =
      response.error?.['account.account_code'] === accountCodeError || response.error?.['code'] === accountCodeError;

    if (hasAccountCodeError) {
      response = yield call(updateCreditCard, { three_d_secure_action_result_token, token });
    }

    if (response.error) {
      yield put(BillingActions.addCreditCardFailure({ error: JSON.stringify(response.error) }));

      return;
    }

    yield call(activeAccountState, { three_d_secure_action_result_token, token });

    if (shouldSaveSubscription) {
      if (subscriptionExists) {
        yield put(SubscriptionActions.updateSubscription());
      } else {
        yield put(SubscriptionActions.createSubscription());
      }
    }

    const [fetchBillingInfoResult, fetchSubscriptionResult]: [
      RequestResult<IFetchBillingInfoResult>,
      RequestResult<ISubscriptionDTO>
    ] = yield all([call(fetchBillingInfo), call(ApiManager.subscriptionApi.fetch)]);

    if (fetchBillingInfoResult.error || fetchSubscriptionResult.error) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.SOMETHING_WENT_WRONG),
          kind: ToastVariant.Error,
          autoHideDelayTime: ToastAutoHideDelay.Long,
        })
      );

      return;
    }

    const subscription = SubscriptionSerializer.deserialize(fetchSubscriptionResult.result);
    const cardDetails = BillingInfoSerializer.deserialize(fetchBillingInfoResult.result);

    yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshSubscriptionInfo);

    yield all([
      put(BillingActions.addCreditCardSuccess(cardDetails)),
      put(SubscriptionActions.subscriptionFetched(subscription)),
      put(SubscriptionActions.subscriptionChanged()),
    ]);

    AppStateProvider.dispatch(
      LicenseCustomPropertiesActions.saveLicenseCustomProperty({
        name: LicenseCustomPropertiesNames.PaymentErrorOnAccessBlocked,
        value: '0',
      })
    );

    AppStateProvider.dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.PaymentErrorOnAccessBlocked]: '0',
      })
    );

    AppStateProvider.dispatch(GlobalModalActions.hideModal(GlobalModal.AddCreditCardDetails));

    trackEvent('Updated billing details', EventPlace.AccountDetails);
  } catch (error) {
    yield put(BillingActions.addCreditCardFailure({ error: error.responseText }));
  }
}

export function* saveCreditCardSaga(): SagaIterator {
  yield takeEvery(BillingActionNames.ADD_CREDIT_CARD_REQUEST, saveCreditCard);
}
