// @ts-strict-ignore
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { AgentAssignmentFilter } from 'constants/filters/agent-assignment-filter';
import { Filter, type Filters } from 'constants/filters/filter';
import { PriorityFilter } from 'constants/filters/priority-filter';
import { TagFilter } from 'constants/filters/tag-filter';
import { type ReportDistribution } from 'constants/reports/distribution';
import { getConfig } from 'helpers/config';
import { ensureFlat, ensureArray } from 'helpers/data';
import type { ReportsViewFilters, ISerializedFilters } from 'interfaces/reports';
import { ThreadCustomPropertyName } from 'services/socket-lc3/chat/event-handling/constants';

export function serializeFilters(
  filters: ReportsViewFilters,
  distribution: ReportDistribution,
  comparison: (keyof Filters)[] = [],
  page: number = 1,
): ISerializedFilters {
  const requestParams: ISerializedFilters = {
    distribution,
    ...(page > 1 && { page }),
  };

  if (filters[Filter.Date]) {
    requestParams.date = format(filters[Filter.Date].date, DateFormat.ISO8601Date);
  }

  if (filters[Filter.DateRange]) {
    if (filters[Filter.DateRange].compare && comparison.includes(Filter.DateRange)) {
      requestParams.dateFrom = filters[Filter.DateRange].compare.from;
      requestParams.dateTo = filters[Filter.DateRange].compare.to;
    } else {
      requestParams.dateFrom = filters[Filter.DateRange].from;
      requestParams.dateTo = filters[Filter.DateRange].to;
    }
  }

  if (filters[Filter.Weekday]) {
    requestParams.weekday = ensureFlat(filters[Filter.Weekday]);
  }

  if (filters[Filter.Agent]) {
    if (filters[Filter.Agent].compare && comparison.includes(Filter.Agent)) {
      requestParams.agents = ensureArray(filters[Filter.Agent].compare);
    } else {
      requestParams.agents = ensureArray(filters[Filter.Agent].agents);
    }
  }

  if (filters[Filter.Group]) {
    if (filters[Filter.Group].compare && comparison.includes(Filter.Group)) {
      requestParams.groups = ensureArray(filters[Filter.Group].compare);
    } else {
      requestParams.groups = ensureArray(filters[Filter.Group].groups);
    }
  }

  if (filters[Filter.Tag] && filters[Filter.Tag].includes(TagFilter.NotTagged)) {
    requestParams.tagged = '0';
  }

  if (filters[Filter.Tag]) {
    const filteredTags = filters[Filter.Tag].filter((tag) => (tag as TagFilter) !== TagFilter.NotTagged);

    requestParams.tags = filteredTags.length > 20 ? filteredTags.slice(0, 20) : filteredTags;
  }

  if (filters[Filter.Goal]) {
    requestParams.goals = ensureArray(filters[Filter.Goal]);
  }

  if (filters[Filter.SaleGoal]) {
    requestParams.sales = ensureArray(filters[Filter.SaleGoal]);
  }

  if (filters[Filter.Greeting]) {
    requestParams.greetings = ensureArray(filters[Filter.Greeting]);
  }

  if (filters[Filter.SurveyType]) {
    requestParams.survey = ensureFlat(filters[Filter.SurveyType]);
  }

  if (filters[Filter.Channel]) {
    requestParams.channelIds = ensureArray(filters[Filter.Channel]);
  }

  if (filters[Filter.AgentAssignment]) {
    requestParams.agentAssignment = (
      ensureFlat(filters[Filter.AgentAssignment]) === AgentAssignmentFilter.Assigned
    ).toString();
  }

  if (filters[Filter.Availability]) {
    requestParams.availability = ensureFlat(filters[Filter.Availability]);
  }

  if (filters[Filter.CountryISO]) {
    requestParams.countries = ensureArray(filters[Filter.CountryISO]);
  }

  if (filters[Filter.Priority]) {
    const withPriority = filters[Filter.Priority] === PriorityFilter.Yes;

    const priorityQuery = withPriority ? { values: [true] } : { exists: false };

    requestParams.properties = {
      [getConfig().accountsClientId]: { [ThreadCustomPropertyName.PriorityChat]: priorityQuery },
    };
  }

  return requestParams;
}
