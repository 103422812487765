import { DesignToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

export enum AvailabilityFilter {
  Online = 'online',
  Offline = 'offline',
}

export interface IItemConfig {
  label: string;
  hint: string;
  dotColor: string;
}

export const AvailabilityFilterItemConfig: Record<AvailabilityFilter, IItemConfig> = {
  [AvailabilityFilter.Online]: {
    label: 'Online',
    hint: 'Accepting chats',
    dotColor: withVar(DesignToken.ColorPositiveDefault),
  },
  [AvailabilityFilter.Offline]: {
    label: 'Offline',
    hint: 'Not accepting chats',
    dotColor: withVar(DesignToken.ColorNegativeDefault),
  },
};

export enum GroupStatusAtStart {
  NotAcceptingChats = 'not_accepting_chats',
  Offline = 'offline',
  Online = 'online',
  OnlineForQueue = 'online_for_queue',
}
