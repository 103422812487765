import { type AIAgentTemplateName, type WithAIAgentsEntitiesState } from './interfaces';

export function getIsAIAgentAvailableByName(state: WithAIAgentsEntitiesState, name: AIAgentTemplateName): boolean {
  return !!state.entities.aiAgents.data[name];
}

export function getAIAgentId(state: WithAIAgentsEntitiesState, name: string): string | null {
  return state.entities.aiAgents.data[name]?.id || null;
}

export function getPublicKnowledgeSourceIds(state: WithAIAgentsEntitiesState): string[] {
  return state.entities.aiAgents.publicSourceIds;
}

export function getPrivateKnowledgeSourceIds(state: WithAIAgentsEntitiesState): string[] {
  return state.entities.aiAgents.privateSourceIds;
}
