import { type WithSessionId } from '../types';

interface WithInput {
  input: string;
}

export interface CreateTextTranslationRequest extends WithInput, WithSessionId {
  languages: string[];
}

export interface CreateTextSentimentRequest extends WithInput, WithSessionId {
  sentiment: 'professional' | 'casual' | 'friendly';
}

export interface CreateTextGrammarCorrectionRequest extends WithInput, WithSessionId {}

export interface CreateTextRewordRequest extends WithInput, WithSessionId {
  action: 'expand' | 'summarize' | 'rephrase' | 'simplify';
}

export interface TextEnhancementResponse {
  result: string[];
  session_id: string;
}

/*
  TextEnhancement enum used by useTextEnhancement
  Each value in this enum should match name of method on TextEnhancementsClient:
  `source/tsx/services/connectivity/ml-gateway-api/text-enhancements/client.ts`
*/
export enum MLGatewayTextEnhancement {
  TextTranslation = 'createTextTranslation',
  TextSentiment = 'createTextSentiment',
  TextGrammar = 'createTextGrammarCorrection',
  TextReword = 'createTextReword',
}
