import { type BadgeKey, type BadgeValue, type IBadgesState } from './interfaces';

interface IWithBadgesState {
  entities: {
    badges: IBadgesState;
  };
}

export function getBadge(state: IWithBadgesState, key: BadgeKey): BadgeValue {
  return state.entities.badges[key] || 0;
}
