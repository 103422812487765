import { ChatEventStatus } from 'constants/chat-event-status';
import { ChatEventType } from 'constants/chat-event-type';
import { toKeyMap } from 'helpers/array';
import { type KeyMap } from 'helpers/interface';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import { getItem, removeItem } from 'services/session-storage';
import { type ChatEventEntity, type ChatEventAuthor, type IMessage } from 'store/entities/chats/interfaces';

import { deserializeThreadEvent } from './deserialize-event';
import { getNotDeliveredEventAuthorType, type IEventDetails, type ICurrentAgent } from './helpers/common';

export function deserializeThreadEventCollection(
  events: any[],
  chatUsers: any[],
  currentAgentId: string,
  resolveWasEventSeen: (event: IEventDetails, currentAgent: ICurrentAgent, chatUsers: any[]) => boolean,
  threadId: string
): KeyMap<ChatEventEntity> {
  return toKeyMap(
    events
      .map((event) => deserializeThreadEvent(event, chatUsers, currentAgentId, resolveWasEventSeen, threadId))
      .filter(Boolean),
    'id'
  );
}

export function getThreadEventsMergedWithNotDelivered(
  threadId: string,
  currentAgentId: string,
  events: any[],
  chatUsers: any[],
  resolveWasEventSeen: (event: IEventDetails, currentAgent: ICurrentAgent, chatUsers: any[]) => boolean
): KeyMap<ChatEventEntity> {
  const storedNotDeliveredEvents: KeyMap<{ text: string; timestamp: number; authorType: ChatEventAuthor }> | null =
    getItem(`${threadId}^2`);

  if (!storedNotDeliveredEvents) {
    return deserializeThreadEventCollection(events, chatUsers, currentAgentId, resolveWasEventSeen, threadId);
  }

  const deserializedEvents = events
    .map((event) => deserializeThreadEvent(event, chatUsers, currentAgentId, resolveWasEventSeen, threadId))
    .filter(Boolean);

  const updatedThreadEvents = Object.values(storedNotDeliveredEvents).reduce((acc, event) => {
    const timestampInMs = event.timestamp || getThreadEntityTimestampInMs(event);

    const isEventInDeserializedEvents = deserializedEvents.some((e) => e.timestampInMs === timestampInMs);
    if (isEventInDeserializedEvents) {
      return acc;
    }

    const newEvent: IMessage = {
      authorId: currentAgentId,
      authorType: event.authorType || getNotDeliveredEventAuthorType(threadId),
      id: `${+new Date()}.${Math.round(Math.random() * 1e11)}`,
      status: ChatEventStatus.NotDelivered,
      wasSeen: false,
      text: event.text,
      timestampInMs,
      type: ChatEventType.Message,
    };

    acc.push(newEvent);

    return acc;
  }, deserializedEvents);

  // remove already used data from session storage
  removeItem(`${threadId}^2`);

  return toKeyMap(updatedThreadEvents, 'id');
}
