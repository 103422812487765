import { BaseApi, PassVersionMode } from '../base-api';

import {
  type IFetchUpgradesResponse,
  type IPurchaseResourceResponse,
  type IDeactivateTransactionResponse,
} from './interfaces';

export class InAppUpgradesApi extends BaseApi {
  protected readonly passVersionMode = PassVersionMode.None;
  protected readonly prefix = 'applications';

  fetchUpgrades = (appId: string) => this.get<IFetchUpgradesResponse[]>(`${appId}/resources/upgrades`);

  purchaseResource = (appId: string, resourceId: string, quantity = 1) =>
    this.post<IPurchaseResourceResponse>(`${appId}/resources/${resourceId}/upgrades`, { quantity });

  deactivateTransaction = (appId: string, resourceId: string, upgradeId: string, transactionId: string) =>
    this.delete<IDeactivateTransactionResponse>(
      `${appId}/resources/${resourceId}/upgrades/${upgradeId}/transactions/${transactionId}`
    );
}
