// @ts-strict-ignore
import { BillingActionNames, type BillingActionTypes } from './actions';
import {
  getStateForAddCreditCardSuccess,
  getStateForCancelApplicationRecurringsSuccess,
  getStateForSetRecurrentApplications,
  getStateForFetchBillingInfoSuccess,
  getStateForMarkApplicationToUninstall,
  getStateForUnmarkApplicationToUninstall,
} from './helpers/reducer';
import { type IBillingState } from './interfaces';

export const initialState: IBillingState = {
  billingInfo: null,
  recurrentPaymentApplications: null,
};

export const billingReducer = (state: IBillingState = initialState, action: BillingActionTypes): IBillingState => {
  switch (action.type) {
    case BillingActionNames.BILLING_INFO_FETCHED: {
      return getStateForFetchBillingInfoSuccess(state, action.payload);
    }

    case BillingActionNames.ADD_CREDIT_CARD_SUCCESS: {
      return getStateForAddCreditCardSuccess(state, action.payload);
    }

    case BillingActionNames.SET_RECURRENT_APPLICATIONS: {
      return getStateForSetRecurrentApplications(state, action.payload);
    }

    case BillingActionNames.CANCEL_APPLICATION_RECURRING_SUCCESS: {
      return getStateForCancelApplicationRecurringsSuccess(state, action.payload);
    }

    case BillingActionNames.UNMARK_APPLICATION_TO_UNINSTALL: {
      return getStateForUnmarkApplicationToUninstall(state, action.payload);
    }

    case BillingActionNames.MARK_APPLICATION_TO_UNINSTALL: {
      return getStateForMarkApplicationToUninstall(state, action.payload);
    }

    default:
      return state;
  }
};
