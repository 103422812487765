import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { useListSuggestions } from 'hooks/use-list-suggestions';
import { useParamSelector } from 'hooks/use-param-selector';
import { getIsAIAgentAvailableByName } from 'store/entities/ai-agents/selectors';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import { deserializeSuggestionWithShortcuts } from '../helpers';

import * as styles from './styles';

interface IProps {
  className?: string;
}

export const Placeholder: FC<IProps> = ({ className }) => {
  const isOneExpanded = useSelector(getIsCopilotModalExpanded);
  const { data } = useListSuggestions();
  const suggestions = deserializeSuggestionWithShortcuts(data);
  const hasOneAIAgent = useParamSelector(getIsAIAgentAvailableByName, 'one');

  if (!hasOneAIAgent) {
    return (
      <Text className={cx({ [styles.messageBoxTip]: !isOneExpanded, [styles.placeholder]: isOneExpanded }, className)}>
        Currently unavailable
      </Text>
    );
  }

  if (!isOneExpanded && suggestions.length) {
    return (
      <Text className={cx(styles.messageBoxTip, className)}>
        Type <strong>/</strong> to get suggestions
      </Text>
    );
  }

  return <div className={cx(styles.placeholder, className)}>Your message</div>;
};
