import { QueryKey } from 'constants/query-key';
import { type Namespace } from 'services/connectivity/configuration-api/properties/constants';

type QueryKeyFunction = (...args) => unknown[];

// TODO: Update the return functions to represent actual query key determinations
export const QUERY_KEYS: Record<QueryKey, QueryKeyFunction> = {
  [QueryKey.AnalyzeSource]: () => [QueryKey.AnalyzeSource],
  [QueryKey.ArchivesSearch]: () => [QueryKey.ArchivesSearch],
  [QueryKey.BannedCustomers]: () => [QueryKey.BannedCustomers],
  [QueryKey.BotRoutingStatusChange]: () => [QueryKey.BotRoutingStatusChange],
  [QueryKey.Campaign]: () => [QueryKey.Campaign],
  [QueryKey.ChatButtons]: () => [QueryKey.ChatButtons],
  [QueryKey.ChatSummary]: () => [QueryKey.ChatSummary],
  [QueryKey.ChatSummaryMutate]: () => [QueryKey.ChatSummaryMutate],
  [QueryKey.ContactInfo]: () => [QueryKey.ContactInfo],
  [QueryKey.DeveloperProfile]: () => [QueryKey.DeveloperProfile],
  [QueryKey.DownloadInvoice]: () => [QueryKey.DownloadInvoice],
  [QueryKey.EyeCatcher]: () => [QueryKey.EyeCatcher],
  [QueryKey.EyeCatcherGallery]: () => [QueryKey.EyeCatcherGallery],
  [QueryKey.Features]: () => [QueryKey.Features],
  [QueryKey.FlashScreenshot]: () => [QueryKey.FlashScreenshot],
  [QueryKey.Goals]: () => [QueryKey.Goals],
  [QueryKey.GoalsReports]: () => [QueryKey.GoalsReports],
  [QueryKey.GoalsStats]: () => [QueryKey.GoalsStats],
  [QueryKey.Gravatar]: () => [QueryKey.Gravatar],
  [QueryKey.GroupDetails]: () => [QueryKey.GroupDetails],
  [QueryKey.GroupProperties]: () => [QueryKey.GroupProperties],
  [QueryKey.HelpDeskFormConfiguration]: () => [QueryKey.HelpDeskFormConfiguration],
  [QueryKey.HelpDeskSubscription]: () => [QueryKey.HelpDeskSubscription],
  [QueryKey.InAppUpgrade]: () => [QueryKey.InAppUpgrade],
  [QueryKey.InactivityReasons]: () => [QueryKey.InactivityReasons],
  [QueryKey.Invoices]: () => [QueryKey.Invoices],
  [QueryKey.KnowledgeBaseSubscription]: () => [QueryKey.KnowledgeBaseSubscription],
  [QueryKey.KnowledgeSourcesList]: () => [QueryKey.KnowledgeSourcesList],
  [QueryKey.LegacyIntegration]: () => [QueryKey.LegacyIntegration],
  [QueryKey.LicenseProperties]: (namespace: Namespace) => [QueryKey.LicenseProperties, namespace],
  [QueryKey.LivechatTickets]: () => [QueryKey.LivechatTickets],
  [QueryKey.LocationDetails]: () => [QueryKey.LocationDetails],
  [QueryKey.LoginMethods]: () => [QueryKey.LoginMethods],
  [QueryKey.LogoutAgent]: () => [QueryKey.LogoutAgent],
  [QueryKey.MyInactivity]: () => [QueryKey.MyInactivity],
  [QueryKey.OnboardingProgress]: () => [QueryKey.OnboardingProgress],
  [QueryKey.OneSuggestions]: () => [QueryKey.OneSuggestions],
  [QueryKey.CopilotNotifications]: () => [QueryKey.CopilotNotifications],
  [QueryKey.PerformanceStats]: () => [QueryKey.PerformanceStats],
  [QueryKey.PostChatSurvey]: () => [QueryKey.PostChatSurvey],
  [QueryKey.PreChatSurvey]: () => [QueryKey.PreChatSurvey],
  [QueryKey.PricePreview]: () => [QueryKey.PricePreview],
  [QueryKey.ResendInvitation]: () => [QueryKey.ResendInvitation],
  [QueryKey.Reviews]: () => [QueryKey.Reviews],
  [QueryKey.SalesTracker]: () => [QueryKey.SalesTracker],
  [QueryKey.Sessions]: () => [QueryKey.Sessions],
  [QueryKey.SubscriptionPreview]: () => [QueryKey.SubscriptionPreview],
  [QueryKey.Tags]: () => [QueryKey.Tags],
  [QueryKey.Webhooks]: () => [QueryKey.Webhooks],
  [QueryKey.MyProducts]: () => [QueryKey.MyProducts],
  [QueryKey.ActivatedSubscriptions]: () => [QueryKey.ActivatedSubscriptions],
  [QueryKey.ScrapedWebsitesList]: () => [QueryKey.ScrapedWebsitesList],
  [QueryKey.DocumentStructure]: (id: string) => [QueryKey.DocumentStructure, id],
  [QueryKey.DocumentPreview]: (sourceId: string, documentId: string) => [
    QueryKey.DocumentPreview,
    sourceId,
    documentId,
  ],
};
