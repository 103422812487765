import type { FC } from 'react';

import { NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';
import { addMonths, format } from 'date-fns';
import { useDispatch } from 'react-redux';

import { DateFormat } from 'constants/date';
import { type TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { getAppDownloadUrl } from 'helpers/downloads';
import { openInNewCard } from 'helpers/routing';
import { useAgentCustomProperty } from 'hooks/use-agent-custom-property';
import { trackEvent } from 'services/event-tracking';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

interface Props {
  onSkip(name: TopBarNotificationType): void;
  name: TopBarNotificationType;
  isVisible: boolean;
}

export const DownloadDesktopAppBar: FC<Props> = ({ onSkip, name, isVisible }) => {
  const dispatch = useDispatch();
  const [, closeBanner] = useAgentCustomProperty(AgentCustomPropertyName.DownloadDesktopAppBannerNextVisibilityDate);

  const handleClose = (): void => {
    closeBanner(format(addMonths(new Date(), 1), DateFormat.ShortDate));
    dispatch(NotificationsBarActions.hideNotificationsBar(name));
    onSkip(name);
  };

  const handleSkip = (): void => {
    trackEvent('Download desktop app skipped', EventPlace.TopBar);
    handleClose();
  };

  const handleDownload = (): void => {
    openInNewCard(getAppDownloadUrl());
    trackEvent('Download desktop app clicked', EventPlace.TopBar);
    handleClose();
  };

  return (
    <NavigationTopBarAlert
      kind="info"
      isVisible={isVisible}
      primaryCta={{
        label: 'Download Desktop app',
        onClick: handleDownload,
        size: 'compact',
      }}
      closeButton={{
        onClick: handleSkip,
        'aria-label': "Close the prompt about desktop app download", // eslint-disable-line @typescript-eslint/naming-convention
      }}
    >
      <Text bold as="span">
        Never miss a chat!
      </Text>
    </NavigationTopBarAlert>
  )
};
