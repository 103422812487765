// @ts-strict-ignore
import {
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  getYear,
  isAfter,
  isBefore,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
  parse,
} from 'date-fns';

import { DateFormat } from 'constants/date';
import type { ChatUserResultType } from 'interfaces/api/chat';
import type { ThreadDetailsResult } from 'services/api/chat/interfaces';
import { isChattingAgent } from 'services/api/interfaces/chat/helpers';
import { AppStateProvider } from 'services/app-state-provider';

import { type ChatThreadEntity, type ITimelinePeriod } from '../interfaces';
import { getThread } from '../selectors';

enum TimelinesPeriod {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  ThisYear = 'thisYear',
}

export function deserializeThreadChattingAgent(users: ChatUserResultType[]): string {
  const threadCurrentAgent = users.find(isChattingAgent);

  return threadCurrentAgent ? threadCurrentAgent.id : null;
}

function isLastWeek(date: string): boolean {
  const currentDate = new Date();
  const validatedDate = date;
  const diff = differenceInCalendarWeeks(currentDate, validatedDate, { weekStartsOn: 1 });

  return diff === 1;
}

function isLastMonth(date: string): boolean {
  const currentDate = new Date();
  const validatedDate = date;
  const diff = differenceInCalendarMonths(currentDate, validatedDate);

  return diff === 1;
}

function getNewTimelinePeriod(currentPeriods: ITimelinePeriod, date: string): ITimelinePeriod {
  const current = currentPeriods;
  const currentDate = date;
  const currentDateFrom = current && current.dateFrom;
  const currentDateTo = current && current.dateTo;
  const newDateFrom = current && isBefore(currentDateFrom, currentDate) ? currentDateFrom : currentDate;
  const newDateTo = current && isAfter(currentDateTo, currentDate) ? currentDateTo : currentDate;

  return {
    dateFrom: newDateFrom,
    dateTo: newDateTo,
  };
}

/**
 * Returns array of object with serialized and sorted date periods.
 * Strongly depends on `date-fns` library for each date validation.
 * @param dates array of strings comes from the API response
 */
export function getSerializedTimelines(dates: string[]): ITimelinePeriod[] {
  const serializedTimelines = dates.reduce((acc, date) => {
    if (isToday(date)) {
      acc[TimelinesPeriod.Today] = {
        dateFrom: date,
      };

      return acc;
    }

    if (isYesterday(date)) {
      acc[TimelinesPeriod.Yesterday] = {
        dateFrom: date,
        dateTo: date,
      };

      return acc;
    }

    if (isThisWeek(date)) {
      acc[TimelinesPeriod.ThisWeek] = getNewTimelinePeriod(acc[TimelinesPeriod.ThisWeek] as ITimelinePeriod, date);

      return acc;
    }

    if (isLastWeek(date)) {
      acc[TimelinesPeriod.LastWeek] = getNewTimelinePeriod(acc[TimelinesPeriod.LastWeek] as ITimelinePeriod, date);

      return acc;
    }

    if (isThisMonth(date)) {
      acc[TimelinesPeriod.ThisMonth] = getNewTimelinePeriod(acc[TimelinesPeriod.ThisMonth] as ITimelinePeriod, date);

      return acc;
    }

    if (isLastMonth(date)) {
      acc[TimelinesPeriod.LastMonth] = getNewTimelinePeriod(acc[TimelinesPeriod.LastMonth] as ITimelinePeriod, date);

      return acc;
    }

    if (isThisYear(date)) {
      acc[TimelinesPeriod.ThisYear] = getNewTimelinePeriod(acc[TimelinesPeriod.ThisYear] as ITimelinePeriod, date);

      return acc;
    }

    if (!isThisYear(date)) {
      const currentDate = date;
      const year = getYear(parse(currentDate, DateFormat.ISO8601Date, new Date()));

      acc[year] = getNewTimelinePeriod(acc[year] as ITimelinePeriod, date);

      return acc;
    }

    return acc;
  }, {});

  const periods: ITimelinePeriod[] = Object.values(serializedTimelines);
  const sortedPeriods = periods.sort((a: ITimelinePeriod, b: ITimelinePeriod) => (b.dateFrom > a.dateFrom ? 1 : -1));

  return sortedPeriods;
}

export function getExtractedMessageText(text: string): string {
  const maxNotificationTextLength = 150;

  return text.length > maxNotificationTextLength ? `${text.substring(0, maxNotificationTextLength - 3)}...` : text;
}

type ChatThreadEntityWithStartedTimestamp = ChatThreadEntity & { startedTimestamp?: number };
type IThreadCreationDateComparator = (thread: ThreadDetailsResult) => boolean;

export function getThreadCreationDateComparator(threadId: string): IThreadCreationDateComparator {
  const state = AppStateProvider.getState();
  const skipThread = threadId && (getThread(state, threadId) as ChatThreadEntityWithStartedTimestamp);

  return (thread: ThreadDetailsResult): boolean => isBefore(thread?.created_at, skipThread?.startedTimestamp);
}
