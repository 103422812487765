import { BaseApi } from '../base-api';

export interface IUploadFinishPayload {
  s3Image: string;
  crop_coordinates?: ICropCoordinates;
}

interface ICropCoordinates {
  top_left: ICoordinates;
  bottom_right: ICoordinates;
}

// @bug: https://livechatinc.atlassian.net/browse/API-2773
// Change to number after ticket is resolved
interface ICoordinates {
  x: string;
  y: string;
}

interface IUploadResponse {
  result: {
    path: string;
  };
  error?: any;
}

export class PreviewApi extends BaseApi {
  protected readonly prefix = 'previews';

  previewEyeCatcher = (payload: FormData): Promise<IUploadResponse> => this.post('eye_catcher', payload);
  finishEyeCatcher = (payload: IUploadFinishPayload): Promise<IUploadResponse> => this.post('eye_catcher', payload);

  previewUpload = (payload: FormData): Promise<IUploadResponse> => this.post('avatarPreview', payload);
  finishUpload = (payload: IUploadFinishPayload): Promise<IUploadResponse> => this.post('avatarPreview', payload);

  previewChatButton = (payload: FormData): Promise<IUploadResponse> => this.post('button', payload);
  finishChatButton = (payload: IUploadFinishPayload): Promise<IUploadResponse> => this.post('button', payload);
}
