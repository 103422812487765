/**
 * Definitions of currently running experiments.
 * This enum is necessary to keep track of application changes, that need to be done after experiment has been completed.
 * The values in this enum are the actual experiments names from API so they cannot be reformated in any way.
 */
export enum ActiveExperiment {
  ContinuousBETA = 'continuousBeta',
  SubscriptionBeta = 'newSubscription2020Beta',
  QueuedChatListPagination = 'queuedChatListPagination',
  BulkGroupRoutingSettings = 'bulkGroupRoutingSettings',
  DisableNativeTickets = 'disableNativeTickets',
  NewOnboardingChecklist = 'newOnboardingChecklist',
}
