import { useMemo, type FC } from 'react';

import { SideNavigation } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { plural } from 'helpers/string';
import { EngageSideNavigationItem } from 'routes/engage/side-navigation/EngageSideNavigationItem';
import { getAllCustomerIdsCount } from 'store/entities/customers/selectors';
import { getActiveGreetingsCount } from 'store/entities/greetings/selectors';
import { getCanManageGreetings } from 'store/features/session/selectors';

import { useActiveGoalsCount } from '../hooks/use-active-goals-count';
import { useEngageApplications } from '../hooks/use-engage-applications';

const EngageSideNavigation: FC = () => {
  const canManageGreetings = useSelector(getCanManageGreetings);
  const allCustomerIdsCount = useSelector(getAllCustomerIdsCount);
  const activeGreetingsCount = useSelector(getActiveGreetingsCount);
  const activeGoalsCount = useActiveGoalsCount();
  const { applications } = useEngageApplications();

  const items = useMemo(
    () =>
      [
        {
          title: 'Traffic',
          path: '/engage/traffic',
          label: (count: number) => plural(count, 'customer', 'customers'),
          badge: {
            count: allCustomerIdsCount,
            max: 998,
          },
          enabled: true,
        },
        {
          title: 'Campaigns',
          path: '/engage/campaigns',
          label: () => 'active',
          badge: {
            count: activeGreetingsCount,
          },
          enabled: canManageGreetings,
        },

        {
          title: 'Goals',
          path: '/engage/goals',
          label: () => 'active',
          badge: {
            count: activeGoalsCount,
          },
          enabled: canManageGreetings,
        },
        ...applications.map(({ name, id }) => ({
          title: name,
          path: `/engage/applications/${id}`,
          label: null,
          badge: null,
          enabled: true,
        })),
      ].filter((item) => item.enabled),
    [applications, allCustomerIdsCount, activeGreetingsCount, activeGoalsCount, canManageGreetings],
  );

  return (
    <SideNavigation data-testid="side-navigation" title="Engage" noGaps>
      {items.map(({ title, path, label, badge }) => (
        <EngageSideNavigationItem
          path={path}
          title={title}
          key={path}
          label={label ? label : undefined}
          badgeCount={(badge as { count: number })?.count}
          badgeMax={(badge as { max: number })?.max}
        />
      ))}
    </SideNavigation>
  );
};

export default EngageSideNavigation;
