import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { getGoogleAnalyticsAppId } from 'helpers/applications';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type ICreateRecurringRequestResult } from 'services/api/billing/interfaces';
import { BillingActions, BillingActionNames } from 'store/entities/billing/actions';
import { type ICancelApplicationRecurringRequestPayload } from 'store/entities/billing/interfaces';
import { ApplicationsActions } from 'store/features/applications/actions';
import { type UninstallApplicationPayload, type IApplicationData } from 'store/features/applications/interfaces';
import { uninstallApplication } from 'store/features/applications/sagas';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';

export function* unsubscribeApplication(
  action: IActionWithPayload<BillingActionNames, ICancelApplicationRecurringRequestPayload>
): SagaIterator {
  const { applicationId } = action.payload;

  try {
    // Get application data
    const integrationDataResult: RequestResult<IApplicationData> = yield call(
      ApiManager.integrationsApi.fetchAppData,
      applicationId
    );
    if (integrationDataResult.error) {
      throw integrationDataResult.error;
    }

    // Find payment
    const currentRecurring: RequestResult<{ result: ICreateRecurringRequestResult[] }> = yield call(
      ApiManager.billingApi.fetchRecurrings,
      integrationDataResult.result.authorization.clientId
    );
    if (currentRecurring.error || !currentRecurring.result) {
      throw currentRecurring.error;
    }

    // Uinstall application if it's free app
    if (!currentRecurring.result.result || currentRecurring.result.result.length === 0) {
      yield call(uninstallApplication, { payload: { id: applicationId } } as IActionWithPayload<
        string,
        UninstallApplicationPayload
      >);
      yield put(BillingActions.cancelApplicationRecurringSuccess({ applicationId }));

      return;
    }

    const recurring = currentRecurring.result.result[0];

    // Cancel payment
    const cancelRecurringResult: RequestResult<ICreateRecurringRequestResult> = yield call(
      ApiManager.billingApi.cancelRecurring,
      recurring.id
    );
    if (cancelRecurringResult.error) {
      throw cancelRecurringResult.error;
    }

    yield put(BillingActions.cancelApplicationRecurringSuccess({ applicationId }));
    yield put(ApplicationsActions.uninstallApplicationSuccessful({ id: applicationId }));
  } catch (error) {
    if (applicationId === getGoogleAnalyticsAppId()) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.GA_UNINSTALL_ERROR),
          autoHideDelayTime: ToastAutoHideDelay.Long,
          kind: ToastVariant.Error,
        })
      );
    } else {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.APPLICATION_UNINSTALL_ERROR),
          autoHideDelayTime: ToastAutoHideDelay.Long,
          kind: ToastVariant.Error,
        })
      );
    }

    yield put(BillingActions.cancelApplicationRecurringFailure({ applicationId }));
  }
}

export function* unsubscribeApplicationSaga(): SagaIterator {
  yield takeEvery(BillingActionNames.CANCEL_APPLICATION_RECURRING_REQUEST, unsubscribeApplication);
}
