import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';
import { HOUR_IN_SECONDS, MINUTE_IN_SECONDS } from 'constants/time-units';
import { formatTime } from 'helpers/format-time';
import { round } from 'helpers/numbers';
import { getAverageSeconds, getPeriodValuesFilled, getSeriesDataFromPeriod } from 'helpers/reports';
import type { IChatDurationData, IChatDurationPeriod } from 'interfaces/reports';
import { type ReportData } from 'interfaces/reports/api-v3';

interface IAgentsChattingDurationPayload {
  data: {
    chatDuration: ReportData<IChatDurationPeriod>;
  };
}

export function chatDurationDeserializer({ data }: IAgentsChattingDurationPayload): IChatDurationData {
  const detailsData = getSeriesDataFromPeriod<IChatDurationPeriod>(data.chatDuration.records);

  const durationSeconds = getPeriodValuesFilled<IChatDurationPeriod>(detailsData, 'duration');
  const agentsChattingDurationSeconds = getPeriodValuesFilled<IChatDurationPeriod>(
    detailsData,
    'agents_chatting_duration'
  );
  const countList = getPeriodValuesFilled<IChatDurationPeriod>(detailsData, 'count');

  const durationAverage = getAverageSeconds<IChatDurationPeriod>(detailsData, 'duration');
  const agentsChattingDurationAverage = getAverageSeconds<IChatDurationPeriod>(detailsData, 'agents_chatting_duration');

  const chatDuration = {
    summary: [
      {
        name: 'Chat duration',
        value: formatTime(durationAverage / HOUR_IN_SECONDS),
        color: DEFAULT_SERIES_COLOR,
      },
      {
        name: 'Agent chatting time',
        value: formatTime(agentsChattingDurationAverage / HOUR_IN_SECONDS),
        color: DEFAULT_SERIES_COLOR,
      },
    ],
    labels: Object.keys(data.chatDuration.records),
    series: [
      {
        data: durationSeconds.map((seconds) => round(seconds / MINUTE_IN_SECONDS, 2)),
        color: DEFAULT_SERIES_COLOR,
        extras: {
          counts: countList,
        },
        name: 'Chat duration',
        csvColumnName: 'Chat duration',
      },
      {
        data: agentsChattingDurationSeconds.map((seconds) => round(seconds / MINUTE_IN_SECONDS, 2)),
        color: DEFAULT_SERIES_COLOR,
        extras: {
          counts: countList,
        },
        name: 'Agent chatting time',
        csvColumnName: 'Agent chatting time',
      },
    ],
  };

  return {
    chatDuration,
  };
}
