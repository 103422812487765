// @ts-strict-ignore
import { type KeyMap } from 'helpers/interface';
import { RequestAction } from 'store/entities/actions';
import { type IActionWithPayload } from 'store/helper';

import { AGENT_CUSTOM_PROPERTIES, type AgentCustomPropertiesAction } from './actions';
import {
  type IAgentCustomPropertiesState,
  type IFetchAgentCustomPropertiesSuccessPayload,
  type ISetAgentCustomPropertySuccessPayload,
  AgentCustomPropertyName,
  type IDeleteAgentCustomPropertySuccessPayload,
} from './interfaces';

const initialState = null;

export const agentCustomPropertiesReducer = (
  state: IAgentCustomPropertiesState = initialState,
  action?: AgentCustomPropertiesAction
): IAgentCustomPropertiesState => {
  switch (action.type) {
    case AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]: {
      const { payload } = action as IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>;
      // the utm source from metrics should have precedence
      const utmSource =
        (state && state[AgentCustomPropertyName.UtmSource]) || payload[AgentCustomPropertyName.UtmSource];

      return {
        ...state,
        ...payload,
        ...(utmSource && { [AgentCustomPropertyName.UtmSource]: utmSource }),
      };
    }

    case AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]: {
      const {
        payload: { values },
      } = action as IActionWithPayload<string, ISetAgentCustomPropertySuccessPayload>;

      return {
        ...state,
        ...values,
      };
    }

    case AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]: {
      const {
        payload: { id },
      } = action as IActionWithPayload<string, IDeleteAgentCustomPropertySuccessPayload>;

      const { [id]: toRemove, ...rest } = state;

      return {
        ...rest,
      };
    }

    case AGENT_CUSTOM_PROPERTIES.FETCH_METRICS_SUCCESS: {
      const { payload } = action as IActionWithPayload<string, KeyMap>;

      return {
        ...state,
        [AgentCustomPropertyName.UtmSource]: payload.utm_source,
      };
    }

    default:
      return state;
  }
};
