// @ts-strict-ignore
import isObject from 'lodash.isobject';
import isString from 'lodash.isstring';
import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { type BusinessModel } from 'constants/business-model';
import { Cookie } from 'constants/cookies';
import { EnvironmentType } from 'constants/environment';
import { ALL_PLANS, PlanType, type IPlanPrices } from 'constants/plan-type';
import { QueryKey } from 'constants/query-key';
import { ToastContent } from 'constants/toasts';
import { removeCookie } from 'helpers/cookies';
import { getEnvironment } from 'helpers/feature-toggle';
import { getToastContent } from 'helpers/toast';
import { LicenseType, type PartnerType } from 'interfaces/entities/current-license';
import type { INewSubscription, ISubscription } from 'interfaces/subscription';
import { type IFetchPlansResult } from 'services/api/billing-v3/interfaces';
import { normalizeError } from 'services/connectivity/configuration-api/helpers';
import { propertiesClient } from 'services/connectivity/configuration-api/properties/client';
import {
  BillingProperty,
  CoreProperty,
  PlatformNamespace,
} from 'services/connectivity/configuration-api/properties/constants';
import { type UpdateLicensePropertiesResponse } from 'services/connectivity/configuration-api/properties/types';
import { type ConfigurationApiErrorResponse } from 'services/connectivity/configuration-api/types';
import { type ApiClientResponse } from 'services/connectivity/http/types';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import type { IAgent } from 'store/entities/agents/interfaces';
import { getActiveAgents } from 'store/entities/agents/selectors';
import { checkCurrentRecurlyCouponSaga, removeNewCouponSaga } from 'store/entities/recurly/sagas';
import { SubscriptionActions } from 'store/entities/subscription/actions';
import { getSubscription, getSubscriptionChange } from 'store/entities/subscription/selectors';
import { getSubscriptionType } from 'store/features/session/selectors';
import { LabsSalesTrackerId, StagSalesTrackerId } from 'store/sagas/subscription/constants';

const queryClient = getQueryClient();

type Error =
  | string
  | { message: string }
  | { error: string }
  | { errors: string }
  | { errors: string[] }
  | { ['#']: string };

export const checkIsRecurlyError = (error: Error): boolean => {
  return (
    !isString(error) &&
    (('error' in error && isObject(error.error) && error.error['recurly']) ||
      ('#' in error && error['#'].includes('insufficient funds in your account')))
  );
};

export function deserializeError(error: Error): string {
  let errorMessage = 'Unexpected error occured.';
  if (!error) {
    return errorMessage;
  }

  if (isString(error)) {
    errorMessage = `${error}.`;
  } else if ('#' in error) {
    // Handle payment errors - subscription cannot be updated when payment fails
    errorMessage = error['#'];
  } else if ('message' in error && isString(error.message)) {
    errorMessage = `${error.message}.`;
  } else if ('error' in error && isString(error.error)) {
    errorMessage = `${error.error}.`;
  } else if ('error' in error && isObject(error.error)) {
    if (error.error['subscription.coupon_code']) {
      errorMessage = error.error['subscription.coupon_code'] as string;
    } else if (error.error['recurly']) {
      errorMessage = error.error['recurly'] as string;
    } else if (error.error['result']) {
      errorMessage = error.error['result'] as string;
    }
  } else if ('errors' in error && isString(error.errors)) {
    errorMessage = `${error.errors}.`;
  } else if ('errors' in error && Array.isArray(error.errors)) {
    errorMessage = `${error.errors.join('. ')}.`;
  }

  if (errorMessage.includes('groups per agent')) {
    errorMessage = getToastContent(ToastContent.GROUPS_PER_AGENT_LIMIT_EXCEEDED);
  }

  return errorMessage;
}

export function* saveAutomaticUpsellingEnabled(): SagaIterator {
  const oldSubscription: ISubscription = yield select(getSubscription);
  const newSubscription: INewSubscription = yield select(getSubscriptionChange);

  if (oldSubscription.automaticUpsellingEnabled === newSubscription.automaticUpsellingEnabled) {
    return;
  }

  const { error }: ApiClientResponse<UpdateLicensePropertiesResponse, ConfigurationApiErrorResponse> = yield call(
    propertiesClient.updateLicense,
    {
      properties: {
        [PlatformNamespace.BILLING]: {
          [BillingProperty.AutomaticUpsellingEnabled]: newSubscription.automaticUpsellingEnabled,
        },
      },
    },
  );

  if (error) {
    const normalizedError = normalizeError(error);
    // eslint-disable-next-line no-console
    console.error(normalizedError);
  }
}

export function* refreshCouponData(subscription: ISubscription): SagaIterator {
  if (subscription.coupon?.name) {
    yield call(checkCurrentRecurlyCouponSaga);
    yield call(removeNewCouponSaga);
  }

  removeCookie(Cookie.CouponCode);
}

export function* mapSubscriptionToNewSubscription(subscription: ISubscription): SagaIterator {
  const {
    plan,
    planName,
    planCode,
    seats,
    billingCycle,
    subscriber,
    couponCode,
    coupon,
    automaticUpsellingEnabled,
    isCanceled,
  } = subscription;
  const type: LicenseType = yield select(getSubscriptionType);
  const agents: IAgent[] = yield select(getActiveAgents);
  const agentsCount = agents.length;
  const calculatedSeats = (!type || type === LicenseType.Trial) && seats === 100 ? agentsCount : seats;

  const newSubscription = {
    plan: ALL_PLANS.includes(plan) ? plan : PlanType.Business,
    planName,
    planCode,
    seats: calculatedSeats,
    billingCycle,
    subscriber,
    couponCode,
    coupon: coupon && !coupon.singleUse ? coupon : undefined,
    automaticUpsellingEnabled,
    isCanceled,
  };

  yield put(SubscriptionActions.subscriptionChangeUpdated(newSubscription));
}

export function deserializePlansList(plans: IFetchPlansResult): IPlanPrices {
  if (!plans?.result) {
    return {};
  }

  return Object.fromEntries(plans.result.map((plan) => [plan.plan_code, plan.plan_price / 100]));
}

export function triggerSalesTracker(trackerId: string, orderPrice?: string): void {
  window.LiveChatWidget.call('trigger_sales_tracker', {
    trackerId,
    orderPrice,
  });
}

export function getTrackerId(type: string): string {
  const environment = getEnvironment();

  switch (environment) {
    case EnvironmentType.Production:
    case EnvironmentType.Staging:
      return StagSalesTrackerId[type];
    case EnvironmentType.Labs:
      return LabsSalesTrackerId[type];
  }
}

export function getSalesPlan(): PlanType | undefined {
  const salesPlan: PlanType = queryClient.getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
  )?.[BillingProperty.SalesPlan];

  return salesPlan;
}

export function getBusinessModel(): BusinessModel | undefined {
  const businessModel: BusinessModel = queryClient.getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
  )?.[BillingProperty.PaymentBusinessModel];

  return businessModel;
}

export function getPartnerType(): PartnerType | undefined {
  const partnerType: PartnerType = queryClient.getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.CORE),
  )?.[CoreProperty.PartnerType];

  return partnerType;
}
