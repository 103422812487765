import type { IChartOptions, IHeatmapSingleData } from 'interfaces/reports';

import { TimeFormat } from '../date';

const THOUSAND = 1e3;
const MILLION = 1e6;

const categories12h = [
  '11:00 pm',
  '10:00 pm',
  '9:00 pm',
  '8:00 pm',
  '7:00 pm',
  '6:00 pm',
  '5:00 pm',
  '4:00 pm',
  '3:00 pm',
  '2:00 pm',
  '1:00 pm',
  '12:00 pm',
  '11:00 am',
  '10:00 am',
  '9:00 am',
  '8:00 am',
  '7:00 am',
  '6:00 am',
  '5:00 am',
  '4:00 am',
  '3:00 am',
  '2:00 am',
  '1:00 am',
  '12:00 am',
];

const standarizeHour = (hourItem: string): string => {
  const [hour, rest] = hourItem.split(':');

  return `${hour.padStart(2, '0')}:${rest}`;
};

export const categories12hFromApi = categories12h.map(standarizeHour);

const categories24h = [
  '23:00',
  '22:00',
  '21:00',
  '20:00',
  '19:00',
  '18:00',
  '17:00',
  '16:00',
  '15:00',
  '14:00',
  '13:00',
  '12:00',
  '11:00',
  '10:00',
  '9:00',
  '8:00',
  '7:00',
  '6:00',
  '5:00',
  '4:00',
  '3:00',
  '2:00',
  '1:00',
  '00:00',
];

/**
 * Type for the context object passed to the formatter function for axis labels.
 * This type picks only the 'isFirst' and 'value' properties from Highcharts.AxisLabelsFormatterContextObject.
 */
type SelectedAxisLabelsFormatterContextObject = Pick<Highcharts.AxisLabelsFormatterContextObject, 'isFirst' | 'value'>;

/**
 * Formatter function for the legend labels in a heatmap.
 *
 * This function formats the labels based on the following rules:
 * - If the label is the first one, it returns '1'.
 * - If the label value is a string, it tries to parse it to a number.
 * - If the label value is a number and it's between 1,000 and 1,000,000, it returns the value in thousands (k).
 * - If the label value is a number and it's greater than or equal to 1,000,000, it returns the value in millions (M).
 * - In all other cases, it returns the label value as a string.
 *
 * @param label - The context object for the label. This object should have 'isFirst' and 'value' properties.
 * @returns The formatted label as a string.
 */
export function heatmapLegendFormatter(label: SelectedAxisLabelsFormatterContextObject): string {
  if (label.isFirst) {
    return '1';
  }

  let value = label.value;
  if (typeof value === 'string') {
    value = Number(value);
  }

  if (typeof value === 'number' && !isNaN(value)) {
    if (value >= THOUSAND && value < MILLION) {
      return `${value / THOUSAND}k`;
    }

    if (value >= MILLION) {
      return `${value / MILLION}M`;
    }
  }

  return String(label.value);
}

export const heatmapCommonOptions: Partial<IChartOptions<IHeatmapSingleData>> = {
  credits: false,
  chart: {
    type: 'heatmap',
    height: 600,
    marginTop: 50,
    marginRight: 20,
    plotBorderWidth: 0,
  },
  title: {
    text: '',
  },
  xAxis: {
    tickWidth: 0,
    lineWidth: 0,
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    opposite: true,
  },
  yAxis: {
    categories: categories12h,
    tickWidth: 0,
    lineWidth: 0,
    gridLineWidth: 0,
    minorGridLineWidth: 0,
  },
  colorAxis: {
    min: 1,
    minColor: '#d1e2fa',
    maxColor: '#3670d3',
    allowDecimals: false,
    tickPixelInterval: 60,
    labels: {
      formatter() {
        return heatmapLegendFormatter(this);
      },
    },
  },
  tooltip: {
    padding: 0,
    borderWidth: 0,
    borderRadius: '10',
    backgroundColor: '#fff',
    useHTML: true,
    shadow: true,
  },
  legend: {
    y: 40,
    margin: 0,
    align: 'right',
    layout: 'horizontal',
    verticalAlign: 'bottom',
    symbolWidth: 250,
    padding: 20,
  },
  series: [
    {
      color: '#ffffff',
      borderWidth: 4,
      states: {
        hover: {
          brightness: 0.1,
        },
      },
    },
  ],
};

export const getHeatmapCommonOptions = (timeFormat: TimeFormat): Partial<IChartOptions<IHeatmapSingleData>> => {
  if (timeFormat === TimeFormat.F24) {
    return {
      ...heatmapCommonOptions,
      xAxis: {
        categories: categories24h,
        tickWidth: 0,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
      },
      yAxis: {
        categories: categories24h,
        tickWidth: 0,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
      },
    };
  }

  return heatmapCommonOptions;
};

export const HEATMAP_NULL_CELL_COLOR = '#f1f6f8';
