import { AgentType } from 'constants/agent-type';
import { LoginStatus } from 'constants/login-status';
import { type Permission, PermissionScore } from 'constants/permission';

import { BATCH_REQUESTS_LIMIT } from '../constants';

export const getStatusSortScore = (status: LoginStatus, lastLogin: number): number => {
  if (!lastLogin && status === LoginStatus.Offline) {
    return 4;
  }
  switch (status) {
    case LoginStatus.Online:
      return 1;
    case LoginStatus.Away:
      return 2;
    case LoginStatus.Offline:
      return 3;
  }
};

export const getAgentPermissionScore = (permission: Permission | undefined, type: AgentType): number => {
  return type === AgentType.Bot || !permission ? 0 : PermissionScore[permission];
};

export const getApproveButtonKind = (waitingForApprovalAgentsCount?: number): 'plain' | 'basic' => {
  return waitingForApprovalAgentsCount && waitingForApprovalAgentsCount <= BATCH_REQUESTS_LIMIT ? 'plain' : 'basic';
};
