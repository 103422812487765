// @ts-strict-ignore
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { Filter } from 'constants/filters/filter';
import type {
  IChatRatingsData,
  IChatSatisfactionRatingPeriodData,
  IChatSatisfactionRankingPeriodData,
} from 'interfaces/reports';
import { type ReportData } from 'interfaces/reports/api-v3';
import { AppStateProvider } from 'services/app-state-provider';

import { getFilters } from '../selectors';

import { rankingDeserializer, RankingType } from './ranking-deserializer';
import { ratingDeserializer } from './rating-deserializer';

interface IChatRatingsPayload {
  data: {
    chatRankingResponse: ReportData<IChatSatisfactionRankingPeriodData>;
    chatRatingResponse: ReportData<IChatSatisfactionRatingPeriodData>;
  };
}

export function chatRatingsDeserializer({ data }: IChatRatingsPayload): IChatRatingsData {
  const filters = AppStateProvider.selectFromStore(getFilters);
  const dateFrom = format(filters[Filter.DateRange].from, DateFormat.ISO8601Date);
  const dateTo = format(filters[Filter.DateRange].to, DateFormat.ISO8601Date);
  const tag = filters[Filter.Tag];
  const ranking = rankingDeserializer(data.chatRankingResponse || [], {
    rankingType: RankingType.Chats,
    dateFrom,
    dateTo,
    tag,
  });

  const chatRating = ratingDeserializer(data.chatRatingResponse.records);

  const ratingData: IChatRatingsData = {
    ranking,
    chatRating,
  };

  return ratingData;
}
