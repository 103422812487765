// @ts-strict-ignore
import uniq from 'lodash.uniq';
import { type SagaIterator } from 'redux-saga';
import { all, call, cancel, delay, fork, put, race, select, take, takeEvery, takeLeading } from 'redux-saga/effects';

import { ArchivesEvent } from 'constants/archives/event';
import { Filter, type Filters } from 'constants/filters/filter';
import { GlobalModal } from 'constants/global-modal';
import { GENERAL_GROUP_ID } from 'constants/groups';
import { Icons } from 'constants/icons';
import { SectionName } from 'constants/section-name';
import { ActionHandlerExecutionDelay, ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { ViewActionSource } from 'constants/view-actions-source';
import { EventPlace } from 'helpers/analytics';
import { anyToBoolean } from 'helpers/boolean';
import { navigate } from 'helpers/routing';
import { getToastContent } from 'helpers/toast';
import { type IArchive } from 'interfaces/entities/archive';
import { SourceType } from 'interfaces/entities/ticket';
import type { Group } from 'interfaces/groups';
import type { IStoreState } from 'interfaces/store/store-state';
import { DEFAULT_FETCH_ALL_LIMIT } from 'services/api/archive/constants';
import { APIErrorMessage } from 'services/api/chat/interfaces';
import { AppStateProvider } from 'services/app-state-provider';
import { chatsClient } from 'services/connectivity/agent-chat-api/chats/client';
import { type GetChatResponse } from 'services/connectivity/agent-chat-api/chats/types/get-chat';
import { type ResumeChatResponse } from 'services/connectivity/agent-chat-api/chats/types/resume-chat';
import { normalizeError } from 'services/connectivity/agent-chat-api/helpers';
import { AgentChatApiErrorType, type AgentChatApiResponse } from 'services/connectivity/agent-chat-api/types';
import { HTTPStatus } from 'services/connectivity/http/types';
import { trackEvent } from 'services/event-tracking';
import { RequestAction } from 'store/entities/actions';
import { AgentActionNames } from 'store/entities/agents/actions';
import { type IAgentRemovedPayload } from 'store/entities/agents/interfaces';
import { ArchiveActionNames, ArchiveActions } from 'store/entities/archives/actions';
import {
  type IFetchArchivesCollectionPayload,
  type IFetchSingleArchiveSuccessPayload,
} from 'store/entities/archives/interfaces';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { isClosedThread, isMyChat, isQueuedChat, isSupervisedChat } from 'store/entities/chats/helpers/common';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { doFetchChatThreadDetails } from 'store/entities/chats/sagas';
import { getIsActiveUnassignedThreadByChatId } from 'store/entities/chats/selectors';
import { customerExists } from 'store/entities/customers/selectors';
import { GreetingActions } from 'store/entities/greetings/actions';
import { getCurrentAgentGroups, getGroup } from 'store/entities/groups/selectors';
import { TagActions } from 'store/entities/tags/actions';
import { TICKET, TicketActions } from 'store/entities/tickets/actions';
import {
  type ICreateTicketFailurePayload,
  type ICreateTicketPayload,
  type ICreateTicketSuccessPayload,
} from 'store/entities/tickets/interfaces';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { TranscriptFeatureActions } from 'store/features/transcript/actions';
import { type IActionWithPayload } from 'store/helper';
import { createHasFetchedSelector } from 'store/requests/selectors';
import { ArchivesViewActions, ArchivesViewActionsNames } from 'store/views/archives/actions';

import { ChatsViewActions } from '../chats/actions';
import { createChatPath } from '../chats/helpers/navigation';

import type {
  IArchivesViewChangeDetailsConfigurationPayload,
  IArchivesViewChangeListModePayload,
  IArchivesViewCreateTicketPayload,
  IArchivesViewDownloadTranscriptPayload,
  IArchivesViewSendTranscriptPayload,
  IArchivesViewSetNewArchivesCountPayload,
  IArchivesViewStartChatPayload,
  IArchivesViewSuperviseChatPayload,
  IArchivesViewUpdateArchiveTagsPayload,
} from './interfaces';
import {
  getArchive,
  getArchiveThread,
  getCurrentArchive,
  getCurrentArchiveTags,
  getCurrentFailureTagsAdditions,
  getCurrentFailureTagsDeletions,
  getFilters,
  getLatestThreadByChatId,
  getNextPage,
  getSearchPhrase,
} from './selectors';

export function* fetchArchives(modifiedParams: IFetchArchivesCollectionPayload): SagaIterator {
  const state: IStoreState = yield select();

  const currentParams = {
    searchPhrase: state.views.archivesView.searchPhrase,
    order: state.views.archivesView.sortOrder,
    filters: state.views.archivesView.filters,
    filtersOperators: state.views.archivesView.filtersOperators,
  };

  const shouldAppend = modifiedParams ? modifiedParams.shouldAppend : false;
  const params: IFetchArchivesCollectionPayload = {
    ...currentParams,
    ...modifiedParams,
    source: ViewActionSource.Archives,
    additionalRequestId: [ViewActionSource.Archives, shouldAppend ? 'MORE' : 'NEW'].filter(Boolean).join('_'),
    limit: DEFAULT_FETCH_ALL_LIMIT,
  };

  yield put(ArchiveActions.fetchCollectionRequest(params));

  if (!shouldAppend) {
    yield put(ArchivesViewActions.startNewArchivesCheck());
  }
}

function* fetchGreetings(): SagaIterator {
  yield put(GreetingActions.fetchCollection({}));
}

export function* fetchTags(): SagaIterator {
  const wasTagsFetched = yield select(createHasFetchedSelector(['FETCH_COLLECTION_TAG']));

  if (!wasTagsFetched) {
    yield put(TagActions.fetchCollection({}));
  }
}

function* fetchCurrentArchiveTickets(): SagaIterator {
  const { id }: IArchive = yield select(getCurrentArchive);
  yield put(ArchiveActions.fetchTickets({ id }));
}

export function* watchFetchArchives(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<string, any> = yield take(ArchivesViewActionsNames.FETCH_DATA);
    yield fork(fetchArchives, { ...payload });
  }
}

export function* watchSetQueryParams(): SagaIterator {
  while (true) {
    yield take(ArchivesViewActionsNames.SET_QUERY_PARAMS_FROM_URL);
    yield fork(fetchArchives, {});
  }
}

/**
 * Triggers fetching the next page of archives. Requires `nextPage` to be set in the store.
 */
export function* watchFetchArchivesMore(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<string, any> = yield take(ArchivesViewActionsNames.FETCH_MORE_DATA);

    const nextPage = yield select(getNextPage);

    if (nextPage) {
      yield fork(fetchArchives, { ...payload, shouldAppend: true, page: nextPage });
    }
  }
}

export function* watchSortChange(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<string, any> = yield take(ArchivesViewActionsNames.SET_SORT_ORDER);
    yield fork(fetchArchives, { ...payload });
  }
}

export function* watchFilterChange(): SagaIterator {
  while (true) {
    yield take(ArchivesViewActionsNames.UPDATE_FILTERS);
    yield fork(fetchArchives, {});
  }
}

function* watchFilterOperatorChange(): SagaIterator {
  while (true) {
    yield take(ArchivesViewActionsNames.UPDATE_FILTER_OPERATOR);
    yield fork(fetchArchives, {});
  }
}

export function* watchSearchReset(): SagaIterator {
  while (true) {
    yield take(ArchivesViewActionsNames.RESET_SEARCH_PARAMS);
    yield fork(fetchArchives, {});
  }
}

function* watchSearchPhraseChange(): SagaIterator {
  let task;
  while (true) {
    const { payload }: IActionWithPayload<string, { searchPhrase: string; shouldClearFilters?: boolean }> = yield take(
      ArchivesViewActionsNames.SET_SEARCH_PHRASE,
    );

    if (task) {
      yield cancel(task);
    }
    task = yield fork(fetchArchives, { ...payload });
  }
}

export function* watchTicketCreate(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<string, IArchivesViewCreateTicketPayload> = yield take(
      ArchivesViewActionsNames.CREATE_TICKET,
    );
    yield put(
      TicketActions.createTicket({
        ...payload,
        sourceType: SourceType.Chat,
        sourceView: ViewActionSource.Archives,
      }),
    );
  }
}

const handleCreateTicketRetry =
  (payload: ICreateTicketPayload): (() => void) =>
  (): void => {
    setTimeout((): void => {
      AppStateProvider.dispatch(TicketActions.createTicket(payload));
    }, ActionHandlerExecutionDelay.Short);
  };

const handleOpenTicket =
  (ticketId: string): (() => void) =>
  (): void => {
    navigate(`/tickets/${ticketId}`);
  };

function* createTicketSuccess(action: IActionWithPayload<string, ICreateTicketSuccessPayload>): SagaIterator {
  const { payload } = action;

  if (payload.sourceType !== SourceType.Chat || payload.sourceView !== ViewActionSource.Archives) {
    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_SUCCESS),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Success,
      action: {
        label: 'Open ticket',
        onClick: handleOpenTicket(payload.ticketId),
        closeOnClick: true,
      },
    }),
  );
  trackEvent(ArchivesEvent.TicketCreated, EventPlace.Archives);
}

function* createTicketFailure(action: IActionWithPayload<string, ICreateTicketFailurePayload>): SagaIterator {
  const { payload } = action;

  if (payload.sourceType !== SourceType.Chat || payload.sourceView !== ViewActionSource.Archives) {
    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_ERROR, { error: payload.error }),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
      action: {
        label: 'Retry',
        onClick: handleCreateTicketRetry(payload),
        closeOnClick: true,
      },
    }),
  );
}

export function* watchSendTranscript(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<ArchivesViewActionsNames, IArchivesViewSendTranscriptPayload> = yield take(
      ArchivesViewActionsNames.SEND_TRANSCRIPT,
    );
    yield put(
      TranscriptFeatureActions.sendTranscript(
        payload.chatId,
        payload.threadId,
        payload.email,
        payload.shouldIncludeAllDetails,
        EventPlace.Archives,
      ),
    );
  }
}

export function* watchNewArchivesCheckToggle(waitTime = 10000): SagaIterator {
  let timestamp = null;
  while (true) {
    yield take(ArchivesViewActionsNames.START_NEW_ARCHIVES_CHECK);
    timestamp = timestamp || Math.floor(new Date().getTime() / 1000);
    while (true) {
      const { shouldStop, shouldStartAgain }: { shouldStop: boolean; shouldStartAgain: boolean } = yield race({
        timeout: delay(waitTime),
        shouldStartAgain: take(ArchivesViewActionsNames.START_NEW_ARCHIVES_CHECK),
        shouldStop: take(ArchivesViewActionsNames.STOP_NEW_ARCHIVES_CHECK),
      });

      if (shouldStartAgain) {
        timestamp = Math.floor(new Date().getTime() / 1000);
      } else if (!shouldStop) {
        const state: IStoreState = yield select();
        const filters = getFilters(state);
        const searchPhrase = getSearchPhrase(state);
        yield put(ArchiveActions.fetchNewCount({ timestamp, filters, searchPhrase }));
      } else {
        break;
      }
    }
  }
}

export function* watchFetchNewArchivesSuccess(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<string, IArchivesViewSetNewArchivesCountPayload> = yield take(
      ArchiveActionNames.FETCH_NEW_COUNT_SUCCESS,
    );
    if (payload.count > 0) {
      yield put(ArchivesViewActions.setNewArchivesCount({ count: payload.count }));
    }
  }
}

function* watchCurrentArchiveIdUpdate(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<string, { id }> = yield take(ArchivesViewActionsNames.UPDATE_CURRENT_ITEM);
    const state: IStoreState = yield select();

    const { data } = state.views.archivesView;

    const archiveToShow = anyToBoolean(data) ? data[payload.id] : null;
    if (archiveToShow) {
      yield put(ArchivesViewActions.setCurrentItem(archiveToShow));
      yield put(ArchivesViewActions.fetchCurrentArchiveTickets());

      if (archiveToShow.nextAccessibleThreadId && !data[archiveToShow.nextAccessibleThreadId]) {
        const hasAccess = yield call(
          checkThreadAccessibility,
          archiveToShow.chatId,
          archiveToShow.nextAccessibleThreadId,
        );
        if (!hasAccess) {
          yield put(
            ArchivesViewActions.updateAccessibleThreadNavigation({
              archiveId: payload.id,
              nextAccessibleThreadId: null,
            }),
          );
        }
      }
    } else {
      yield put(ArchiveActions.fetch({ id: payload.id, source: ViewActionSource.Archives }));
    }
  }
}

function* checkThreadAccessibility(chatId: string, threadId: string): SagaIterator<boolean> {
  const { result, error }: AgentChatApiResponse<GetChatResponse> = yield call(
    doFetchChatThreadDetails,
    chatId,
    threadId,
  );

  if (error) {
    if (error.status === HTTPStatus.Forbidden) {
      return false;
    }

    return true;
  }

  if (result?.thread) {
    const { access } = result;

    const currentAgentGroups: Group[] = yield select(getCurrentAgentGroups);
    const [groupId] = access.group_ids;

    return currentAgentGroups.some((group) => group.id === String(groupId));
  }

  return true;
}

export function* updateCurrentArchive(
  action: IActionWithPayload<string, IFetchSingleArchiveSuccessPayload>,
): SagaIterator {
  const { payload } = action;

  if ((payload.source as ViewActionSource) !== ViewActionSource.Archives) {
    return;
  }

  yield put(ArchivesViewActions.setCurrentItem(payload.value));
  yield put(ArchivesViewActions.fetchCurrentArchiveTickets());
}

export function* updateArchiveTags(
  action: IActionWithPayload<string, IArchivesViewUpdateArchiveTagsPayload>,
): SagaIterator {
  const { archiveId, tags } = action.payload;
  const failedTagsAdditions: string[] = yield select(getCurrentFailureTagsAdditions);
  const failedTagsDeletions: string[] = yield select(getCurrentFailureTagsDeletions);
  const currentArchiveTags: string[] = yield select(getCurrentArchiveTags);

  yield put(
    ArchiveActions.updateArchiveTags({
      archiveId,
      tags,
      previousTags: uniq(
        currentArchiveTags.filter((tag) => !failedTagsAdditions.includes(tag)).concat(failedTagsDeletions),
      ),
    }),
  );
}

export function* resetArchivesFilters(): SagaIterator {
  yield put(ArchivesViewActions.fetchArchives());
}

function* setDetailsConfiguration(
  action: IActionWithPayload<string, IArchivesViewChangeDetailsConfigurationPayload>,
): SagaIterator {
  const { selected, order } = action.payload;
  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.ArchiveDetailsSelected]: selected,
      [AgentCustomPropertyName.ArchiveDetailsOrder]: order,
    }),
  );
}

function* changeListMode(action: IActionWithPayload<string, IArchivesViewChangeListModePayload>): SagaIterator {
  const { listMode } = action.payload;

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.ArchiveListMode]: listMode,
    }),
  );
}

/**
 * Starts (or navigates to) a chat from Archives perspecitve based on archived thread id.
 * Following scenarios are handled:
 * 1. Related thread is active. Navigate to it.
 * 2. Chat requires activation
 * 2a. Activation successful, navigate to new thread.
 * 2b. Activation unsuccessful, chat already active. Show `supervise` modal.
 * 2c. Activation unsuccessful, chat is in disallowed group.
 * 2e. Activation unsuccessful, chat is in disallowed group.
 * Similar functionality of `startChat` is also used in `views/chats/sagas.ts`
 */
function* startChat({
  payload,
}: IActionWithPayload<ArchivesViewActionsNames, IArchivesViewStartChatPayload>): SagaIterator {
  const { threadId } = payload;
  const archivedThread: IArchive = yield select(getArchiveThread, threadId);

  if (archivedThread) {
    const relatedThread: ChatThreadEntity = yield select(getLatestThreadByChatId, archivedThread.chatId);

    const currentGroupExist = yield select(getGroup, String(archivedThread.groupIds[0]));
    const canNavigateTo =
      relatedThread &&
      !isClosedThread(relatedThread) &&
      (isMyChat(relatedThread) || isSupervisedChat(relatedThread) || isQueuedChat(relatedThread));

    // 1. Related thread is active. Navigate to it.
    if (canNavigateTo) {
      trackEvent(ArchivesEvent.OngoingChatViewed, EventPlace.Archives);

      navigate(createChatPath(archivedThread.chatId, relatedThread.threadId));

      return;
    }

    // 2. Chat requires activation.
    const { result, error }: AgentChatApiResponse<ResumeChatResponse> = yield call(chatsClient.resumeChat, {
      chat: {
        id: archivedThread.chatId,
        access: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          group_ids: !currentGroupExist ? [Number(GENERAL_GROUP_ID)] : archivedThread.groupIds,
        },
      },
      continuous: true,
    });

    // 2a. Activation successful, navigate to new thread.
    if (result) {
      const newThreadId = result.thread_id;

      navigate(createChatPath(archivedThread.chatId, newThreadId));

      yield put(ChatsEntitiesActions.fetchChatHistory({ threadId: newThreadId }));

      yield put(ChatsEntitiesActions.removeChatThread({ threadId, omitTags: true }));
      trackEvent(ArchivesEvent.ChatStartedFromHistory, EventPlace.Archives);
    }

    if (error) {
      const { message, type } = normalizeError(error);
      const activeUnassignedExist = yield select(getIsActiveUnassignedThreadByChatId, archivedThread.chatId);
      const chatAlreadActive =
        type === AgentChatApiErrorType.VALIDATION && message.includes(APIErrorMessage.ChatAlreadyActive);

      // 2b. Activation unsuccessful, chat already active. Show `supervise` modal.
      if (chatAlreadActive && !activeUnassignedExist) {
        const hasAccess = yield call(
          checkThreadAccessibility,
          archivedThread.chatId,
          archivedThread.nextAccessibleThreadId,
        );
        if (!hasAccess) {
          yield put(
            GlobalModalActions.showModal(GlobalModal.Info, {
              iconName: Icons.WarningHandDrawn,
              heading: 'This chat can’t be opened',
              content:
                'This customer is currently chatting with another agent in a group you don’t have access to. You can’t open or supervise this chat.',
              buttonContent: 'Got it',
            }),
          );

          return;
        }

        // Show Supervise Modal
        yield put(GlobalModalActions.showModal(GlobalModal.SuperviseChat, { source: SectionName.Archives, threadId }));
      }

      // Temporary hotfix for the release
      // Will be removed and handled in the correct way in the https://livechatinc.atlassian.net/browse/AA-10819
      if (chatAlreadActive && activeUnassignedExist) {
        navigate(createChatPath(relatedThread.chatId, relatedThread.threadId));

        return;
      }

      // 2c. Activation unsuccessful, chat is in disallowed group.
      if (type === AgentChatApiErrorType.MISSING_ACCESS) {
        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.CANT_START_CHAT_INACCESSIBLE),
            kind: ToastVariant.Warning,
          }),
        );
      }

      // 2d. One of threads removed.
      if (type === AgentChatApiErrorType.NOT_FOUND) {
        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.CANT_START_CHAT_THREAD_REMOVED),
            kind: ToastVariant.Error,
          }),
        );
      }

      // 2e. Activation unsuccessful, chat is in disallowed group.
      if (message.includes(APIErrorMessage.MissingAccessGroup)) {
        const groupName = message.substring(message.lastIndexOf('=') + 1, message.lastIndexOf(')'));

        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.MISSING_GROUP_ACCESS, { groupName }),
            kind: ToastVariant.Warning,
          }),
        );
      }
    }
  }
}

function* downloadTranscript({
  payload,
}: IActionWithPayload<ArchivesViewActionsNames, IArchivesViewDownloadTranscriptPayload>): SagaIterator {
  const { threadId } = payload;

  yield put(TranscriptFeatureActions.downloadTranscript({ threadId }));
}

function* superviseChat({
  payload,
}: IActionWithPayload<ArchivesViewActionsNames, IArchivesViewSuperviseChatPayload>): SagaIterator {
  const { threadId } = payload;
  const archivedThread: IArchive = yield select(getArchive, threadId);

  if (archivedThread && archivedThread.visitor && archivedThread.visitor.id) {
    const isExistingCustomer = yield select(customerExists, archivedThread.visitor.id);
    if (isExistingCustomer) {
      yield put(ChatsViewActions.markCustomerAsAwaitingNavigation({ customerId: archivedThread.visitor.id }));
      yield put(ChatsEntitiesActions.supervise({ chatId: archivedThread.chatId }));
    }
  }
}

function* resetPaging(): SagaIterator {
  yield put(ArchivesViewActions.resetPaging());
}

export function* removeAgentFromFilters({
  payload: { login: loginToRemove },
}: IActionWithPayload<string, IAgentRemovedPayload>): SagaIterator {
  const { agent: currentValues }: Filters = yield select(getFilters);

  if (currentValues === null) {
    return;
  }

  const filteredValues = currentValues.filter((agentLogin) => agentLogin !== loginToRemove);
  const newValues = filteredValues.length > 0 ? filteredValues : null;

  yield put(
    ArchivesViewActions.updateFilters({
      name: Filter.Agent,
      value: newValues,
    }),
  );
}

export default function* archivesViewSagas(): SagaIterator {
  yield all([
    fork(watchFetchArchives),
    fork(watchFetchArchivesMore),
    fork(watchFetchNewArchivesSuccess),
    fork(watchSortChange),
    fork(watchSearchPhraseChange),
    fork(watchSearchReset),
    fork(watchTicketCreate),
    fork(watchFilterChange),
    fork(watchFilterOperatorChange),
    fork(watchNewArchivesCheckToggle),
    fork(watchSendTranscript),
    fork(watchSetQueryParams),
    fork(watchCurrentArchiveIdUpdate),
    takeEvery(ArchiveActionNames.FETCH_SINGLE_SUCCESS, updateCurrentArchive),
    takeEvery(ArchivesViewActionsNames.FETCH_GREETINGS, fetchGreetings),
    takeEvery(ArchivesViewActionsNames.FETCH_TAGS, fetchTags),
    takeEvery(ArchivesViewActionsNames.UPDATE_ARCHIVE_TAGS, updateArchiveTags),
    takeEvery(ArchivesViewActionsNames.FETCH_CURRENT_ARCHIVE_TICKETS, fetchCurrentArchiveTickets),
    takeEvery(ArchivesViewActionsNames.RESET_FILTERS, resetArchivesFilters),
    takeEvery(ArchivesViewActionsNames.SET_DETAILS_CONFIGURATION, setDetailsConfiguration),
    takeEvery(ArchivesViewActionsNames.CHANGE_LIST_MODE, changeListMode),
    takeEvery(TICKET.CREATE[RequestAction.SUCCESS], createTicketSuccess),
    takeEvery(TICKET.CREATE[RequestAction.FAILURE], createTicketFailure),
    takeLeading(ArchivesViewActionsNames.START_CHAT, startChat),
    takeEvery(ArchivesViewActionsNames.SUPERVISE_CHAT, superviseChat),
    takeEvery(ArchivesViewActionsNames.DOWNLOAD_TRANSCRIPT, downloadTranscript),
    takeEvery(
      [
        ArchivesViewActionsNames.SET_SORT_ORDER,
        ArchivesViewActionsNames.SET_SEARCH_PHRASE,
        ArchivesViewActionsNames.RESET_SEARCH_PARAMS,
        ArchivesViewActionsNames.RESET_FILTERS,
        ArchivesViewActionsNames.UPDATE_FILTERS,
      ],
      resetPaging,
    ),
    takeEvery(AgentActionNames.AGENT_REMOVE, removeAgentFromFilters),
  ]);
}
