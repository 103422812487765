// @ts-strict-ignore
import { QueryKey } from 'constants/query-key';
import { filterActiveKnowledgeSources } from 'helpers/knowledge-hub';
import { type KnowledgeSourceItem, KnowledgeSourceType } from 'interfaces/knowledge-sources';
import { AppStateProvider, type IAppState } from 'services/app-state-provider';
import { invokeCustomerUpdateWithNotifications } from 'services/customers/incoming-customer';
import { getQueryClient } from 'services/query-client/client';
import { deserializeVisitedPages } from 'services/socket-lc3/chat/serialization/deserialize-customer-visited-pages';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getWidgetWebsite } from 'store/views/copilot/selectors';

import { getListOfVisitedPages } from '../helpers';
import { type IncomingCustomerPushEvent } from '../interfaces';
import { deserializeIncomingCustomerPushEvent } from '../serialization/deserialize';

export const handleIncomingCustomer = (payload: IncomingCustomerPushEvent, store: IAppState): void => {
  const customer = deserializeIncomingCustomerPushEvent(payload);
  const visitedPages = deserializeVisitedPages(payload.last_visit);
  const savedWidgetWebsite = AppStateProvider.selectFromStore(getWidgetWebsite);

  const sourcesList = getQueryClient().getQueryData<KnowledgeSourceItem[]>([QueryKey.KnowledgeSourcesList]);
  const activeUrlSources = filterActiveKnowledgeSources(sourcesList)?.filter(
    ({ sourceType }) => sourceType === KnowledgeSourceType.URL,
  );

  if (activeUrlSources?.length === 0 && !savedWidgetWebsite) {
    const listOfVisitedPages = getListOfVisitedPages(visitedPages);
    const widgetWebsite = listOfVisitedPages[0];

    if (widgetWebsite) {
      AppStateProvider.dispatch(CopilotViewActions.addWebsite(widgetWebsite));
    }
  }

  invokeCustomerUpdateWithNotifications(store, { customer, visitedPages });
};
