import { useRef, useEffect } from 'react';

import * as Sentry from '@sentry/browser';

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export default function useInterval(callback: VoidFunction, delay: number | null): void {
  const savedCallback = useRef<VoidFunction>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick(): void {
      savedCallback.current?.();
    }

    try {
      if (delay !== null) {
        const id = setInterval(tick, delay);

        return () => clearInterval(id);
      }

      return undefined;
    } catch (error) {
      Sentry.captureException(error);

      return undefined;
    }
  }, [delay]);
}
