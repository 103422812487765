import { useCallback } from 'react';

import { useTheme } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { ThemeName } from 'constants/themes';
import { useLocalStorage } from 'hooks/use-local-storage';
import { LocalStorageKey } from 'services/local-storage';
import { getCurrentTheme } from 'store/features/agent-custom-properties/selectors';

interface UseAppTheme {
  theme: ThemeName;
  setTheme: (newTheme: ThemeName) => void;
}

export const useAppTheme = (): UseAppTheme => {
  const [lastUsedTheme, setLastUsedTheme] = useLocalStorage<ThemeName>(LocalStorageKey.UserTheme, ThemeName.Light);
  const currentTheme = useSelector(getCurrentTheme);
  const { setTheme } = useTheme();

  const handleThemeChange = useCallback(
    (newTheme: ThemeName) => {
      setTheme(newTheme);
      setLastUsedTheme(newTheme);
    },
    [setTheme, setLastUsedTheme],
  );

  return {
    theme: currentTheme ?? lastUsedTheme,
    setTheme: handleThemeChange,
  };
};
