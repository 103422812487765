import { LoginStatus } from 'constants/login-status';
import { type RoutingStatus } from 'services/connectivity/agent-chat-api/status/types';

const loginStatuses: Record<RoutingStatus, LoginStatus> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  not_accepting_chats: LoginStatus.Away,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  accepting_chats: LoginStatus.Online,
  offline: LoginStatus.Offline,
};

export function mapRoutingStatusToLoginStatus(apiStatus: RoutingStatus): LoginStatus {
  return loginStatuses[apiStatus];
}
