import { preparePlanCode } from 'helpers/subscription';
import { isDowngrade, isUpsell } from 'helpers/subscription-features';
import { getSubscriptionPlanName } from 'helpers/subscription-plans';
import { type INewSubscription, type ISubscription, SubscriptionChangedType } from 'interfaces/subscription';

import { type ISubscriptionChangePayload, type ISubscriptionState } from './interfaces';


export const getStateForSubscriptionChange = (
  state: ISubscriptionState,
  payload: ISubscriptionChangePayload
): ISubscriptionState => {
  const subscriptionChange = {
    ...state.subscriptionChange,
    ...payload,
  };

  if (!state.subscription) {
    return {
      ...state,
      subscriptionChange,
    };
  }

  subscriptionChange.changeType = getSubscriptionChangeType(state.subscription, subscriptionChange);
  subscriptionChange.planName = getSubscriptionPlanName(subscriptionChange.plan);
  subscriptionChange.planCode = preparePlanCode(subscriptionChange.plan, subscriptionChange.billingCycle);

  return {
    ...state,
    subscriptionChange,
  };
};

export function getSubscriptionChangeType(
  subscription: ISubscription,
  newSubscription: INewSubscription
): SubscriptionChangedType {
  if (isUpsell(newSubscription.plan, subscription.plan)) {
    return SubscriptionChangedType.Upgrade;
  }

  if (isDowngrade(newSubscription.plan, subscription.plan)) {
    return SubscriptionChangedType.Downgrade;
  }

  if (
    subscription.billingCycle !== newSubscription.billingCycle ||
    subscription.seats !== newSubscription.seats ||
    subscription.automaticUpsellingEnabled !== newSubscription.automaticUpsellingEnabled
  ) {
    return SubscriptionChangedType.Update;
  }

  return SubscriptionChangedType.Unchanged;
}
