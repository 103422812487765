import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadExists, getHistoryThreadExists } from 'store/entities/chats/selectors';

import { type IThreadTaggedPushEvent } from '../interfaces';

/**
 * Handles thread tagged push event.
 * 1. Updates the tag for the thread.
 * @param {IThreadTaggedPushEvent} payload Push event payload.
 * @param {Store<IWithChatsEntityState>} store Redux store.
 */
export function handleThreadTagged(payload: IThreadTaggedPushEvent, store: IAppState): void {
  const state = store.getState();
  const threadExist = getThreadExists(state, payload.thread_id) || getHistoryThreadExists(state, payload.thread_id);

  if (threadExist) {
    /**
     * 1. Updates the tag for the thread.
     * @todo: This should be handled by a single action without using
     *        actions for saving tag request.
     */
    store.dispatch(
      ChatsEntitiesActions.saveTag({
        threadId: payload.thread_id,
        tag: payload.tag,
      })
    );
    store.dispatch(ChatsEntitiesActions.setTagSuccess({ threadId: payload.thread_id, tag: payload.tag }));
  }
}
