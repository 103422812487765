import { GridDots, PlugDisconnected } from '@livechat/design-system-icons';

export const MARKETPLACE_LINKS = [
  {
    label: 'Explore apps',
    link: '/apps/marketplace/apps',
    icon: GridDots,
  },
  {
    label: 'External integrations',
    link: '/apps/marketplace/apps/self-setup',
    icon: PlugDisconnected,
  },
];
