import { useState } from 'react';

type UseOnHover = {
  isHovered: boolean;
  handleMouseOver: () => void;
  handleMouseOut: () => void;
};

export const useOnHover = (initialState = false): UseOnHover => {
  const [isHovered, setIsHovered] = useState(initialState);

  const handleMouseOver = (): void => setIsHovered(true);
  const handleMouseOut = (): void => setIsHovered(false);

  return {
    isHovered,
    handleMouseOver,
    handleMouseOut,
  };
};
