import { featureBranchURL } from 'helpers/regexp';

import { MARKETPLACE_SECTIONS_LINKS } from './additional-sections-list/constants';
import { MARKETPLACE_LINKS } from './constants';

const ALL_MARKETPLACE_LINKS = [...MARKETPLACE_LINKS, ...MARKETPLACE_SECTIONS_LINKS];
const [, ...campaignLinks] = MARKETPLACE_LINKS;

// Remove feature branch URL from pathname
const normalizePathname = (pathname: string): string => pathname.replace(featureBranchURL, '');

export const normalizeListPathname = (pathname: string): string => {
  const normalizedPathname = normalizePathname(pathname);
  const listPathname = normalizedPathname.split('/').slice(0, 4).join('/');

  return listPathname;
};

export const checkIfActive = (
  link: string,
  currentMarketplaceRoute: string | null,
  isDefaultRoute?: boolean,
): boolean => {
  if (!window.location.pathname.includes('/apps/marketplace')) {
    return false;
  }

  if (!currentMarketplaceRoute) {
    return normalizePathname(window.location.pathname) === link;
  }

  const normalizedCurrentRoute = normalizePathname(currentMarketplaceRoute);

  // Check if any campaign section is active
  const activeCampaignSection = campaignLinks.find(({ link: campaignLink }) =>
    normalizedCurrentRoute.startsWith(campaignLink),
  );

  if (activeCampaignSection) {
    return activeCampaignSection.link === link;
  }

  // Check if any partners section is active
  const activePartnersSection = MARKETPLACE_SECTIONS_LINKS.find(({ link: marketplaceLink }) =>
    normalizedCurrentRoute.startsWith(marketplaceLink),
  );

  if (activePartnersSection) {
    return activePartnersSection.link === link;
  }

  // Mark default route as active if user is on marketplace page but it doesn't match any of the marketplace links
  if (isDefaultRoute && normalizedCurrentRoute !== link) {
    return !ALL_MARKETPLACE_LINKS.some(({ link }) => {
      return link === normalizedCurrentRoute;
    });
  }

  return normalizedCurrentRoute.startsWith(link);
};
