import type { ISession } from 'interfaces/session';

export enum EventNames {
  SubscriptionChanged = 'subcription-changed',
  UserLoggedOut = 'user-logout-out',
  UrlRulesAddGroupFormClosed = 'url-rules-add-group-form-closed',
  NavigationChanged = 'navigation-changed',
  SetMessageBoxValue = 'set-message-box-value',
  SendCopilotMessageFromSpotlight = 'send-copilot-message-from-spotlight',
}

export type Events = {
  [EventNames.SubscriptionChanged]: never;
  [EventNames.UserLoggedOut]: Partial<{
    manual: boolean;
    redirect: boolean;
    currentOnly: boolean;
    sessionsList: ISession[];
    currentSession: ISession;
  }>;
  [EventNames.UrlRulesAddGroupFormClosed]: never;
  [EventNames.SetMessageBoxValue]: string;
  [EventNames.SendCopilotMessageFromSpotlight]: string;
};
