import { useEffect, useState, useCallback, useMemo } from 'react';

import {
  ConnectivityStatusEvent,
  type InternetStatus,
  type NetworkStatus,
  type ServiceStatus,
} from 'services/connectivity/status/types';

import { useConnectivityStatusContext } from './use-connectivity-status-context';

type Status<T> = {
  current: T;
  throttled: T;
};

type UseConnectivityStatusResult = {
  isOnline: boolean;
  isOffline: boolean;
  isThrottledOffline: boolean;
  network: Status<NetworkStatus>;
  internet: Status<InternetStatus>;
  services: { [key: string]: ServiceStatus };
  resetThrottledStatuses: () => void;
};

export const useConnectivityStatus = (): UseConnectivityStatusResult => {
  const connectivityStatus = useConnectivityStatusContext();

  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(connectivityStatus.getNetworkStatus());
  const [throttledNetworkStatus, setThrottledNetworkStatus] = useState<NetworkStatus>(
    connectivityStatus.getThrottledNetworkStatus()
  );
  const [internetStatus, setInternetStatus] = useState<InternetStatus>(connectivityStatus.getInternetStatus());
  const [throttledInternetStatus, setThrottledInternetStatus] = useState<InternetStatus>(
    connectivityStatus.getThrottledInternetStatus()
  );
  const [servicesStatus, setServicesStatus] = useState<{ [key: string]: ServiceStatus }>({});
  const [isOnline, setIsOnline] = useState(connectivityStatus.getIsOnline());
  const [isOffline, setIsOffline] = useState(connectivityStatus.getIsOffline());
  const [isThrottledOffline, setIsThrottledOffline] = useState(connectivityStatus.getThrottledIsOffline());

  const updateStatuses = useCallback(() => {
    setNetworkStatus(connectivityStatus.getNetworkStatus());
    setThrottledNetworkStatus(connectivityStatus.getThrottledNetworkStatus());
    setInternetStatus(connectivityStatus.getInternetStatus());
    setThrottledInternetStatus(connectivityStatus.getThrottledInternetStatus());
    setIsOnline(connectivityStatus.getIsOnline());
    setIsOffline(connectivityStatus.getIsOffline());
    setIsThrottledOffline(connectivityStatus.getThrottledIsOffline());
    const services = Object.keys(connectivityStatus.getServiceStatuses()).reduce((acc, service) => {
      acc[service] = connectivityStatus.getServiceStatus(service);

      return acc;
    }, {} as { [key: string]: ServiceStatus });
    setServicesStatus(services);
  }, [connectivityStatus]);

  useEffect(() => {
    const handleNetworkStatusChange = (status: NetworkStatus): void => {
      setNetworkStatus(status);
      setIsOnline(connectivityStatus.getIsOnline());
      setIsOffline(connectivityStatus.getIsOffline());
    };

    const handleThrottledNetworkStatusChange = (status: NetworkStatus): void => {
      setThrottledNetworkStatus(status);
      setIsThrottledOffline(connectivityStatus.getThrottledIsOffline());
    };

    const handleInternetStatusChange = (status: InternetStatus): void => {
      setInternetStatus(status);
      setIsOnline(connectivityStatus.getIsOnline());
      setIsOffline(connectivityStatus.getIsOffline());
    };

    const handleThrottledInternetStatusChange = (status: InternetStatus): void => {
      setThrottledInternetStatus(status);
      setIsThrottledOffline(connectivityStatus.getThrottledIsOffline());
    };

    const handleServiceStatusChange = ({ service, status }: { service: string; status: ServiceStatus }): void => {
      setServicesStatus((prevStatus) => ({ ...prevStatus, [service]: status }));
    };

    connectivityStatus.subscribe(ConnectivityStatusEvent.NETWORK_STATUS_CHANGED, handleNetworkStatusChange);
    connectivityStatus.subscribe(
      ConnectivityStatusEvent.THROTTLED_NETWORK_STATUS_CHANGED,
      handleThrottledNetworkStatusChange
    );
    connectivityStatus.subscribe(ConnectivityStatusEvent.INTERNET_STATUS_CHANGED, handleInternetStatusChange);
    connectivityStatus.subscribe(
      ConnectivityStatusEvent.THROTTLED_INTERNET_STATUS_CHANGED,
      handleThrottledInternetStatusChange
    );
    connectivityStatus.subscribe(ConnectivityStatusEvent.SERVICE_STATUS_CHANGED, handleServiceStatusChange);

    updateStatuses();

    return () => {
      connectivityStatus.unsubscribe(ConnectivityStatusEvent.NETWORK_STATUS_CHANGED, handleNetworkStatusChange);
      connectivityStatus.unsubscribe(
        ConnectivityStatusEvent.THROTTLED_NETWORK_STATUS_CHANGED,
        handleThrottledNetworkStatusChange
      );
      connectivityStatus.unsubscribe(ConnectivityStatusEvent.INTERNET_STATUS_CHANGED, handleInternetStatusChange);
      connectivityStatus.unsubscribe(
        ConnectivityStatusEvent.THROTTLED_INTERNET_STATUS_CHANGED,
        handleThrottledInternetStatusChange
      );
      connectivityStatus.unsubscribe(ConnectivityStatusEvent.SERVICE_STATUS_CHANGED, handleServiceStatusChange);
    };
  }, [connectivityStatus, updateStatuses]);

  const network = useMemo(
    () => ({
      current: networkStatus,
      throttled: throttledNetworkStatus,
    }),
    [networkStatus, throttledNetworkStatus]
  );

  const internet = useMemo(
    () => ({
      current: internetStatus,
      throttled: throttledInternetStatus,
    }),
    [internetStatus, throttledInternetStatus]
  );

  return {
    isOnline,
    isOffline,
    isThrottledOffline,
    network,
    internet,
    services: servicesStatus,
    resetThrottledStatuses: connectivityStatus.resetThrottledStatuses,
  };
};
