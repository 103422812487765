import { CustomSegmentsActionsNames, type CustomSegmentsAction } from './actions';
import {
  getStateForSaveCustomSegmentSuccess,
  getStateForSaveCustomSegments,
  getStateForLoadCustomSegments,
  getStateForSelectCustomSegment,
  getStateForUpdateCustomSegmentLabel,
  getStateForUpdateCustomSegmentLabelSuccess,
  getStateForUpdateCustomSegmentsOrder,
  getStateForDeleteCustomSegmentSuccess,
  getStateForUpdateCustomSegmentSuccess,
  getStateForSetCustomSegmentEditMode,
  getStateForUpdateCustomSegmentLabelFailure,
} from './helpers/reducer';
import { type CustomSegmentsState } from './interfaces';

const initialState: CustomSegmentsState = {};

export function customSegmentsReducer(
  state: CustomSegmentsState = initialState,
  action: CustomSegmentsAction
): CustomSegmentsState {
  switch (action.type) {
    case CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT_SUCCESS:
      return getStateForSaveCustomSegmentSuccess(state, action.payload);
    case CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_SUCCESS:
      return getStateForUpdateCustomSegmentSuccess(state, action.payload);
    case CustomSegmentsActionsNames.SAVE_CUSTOM_SEGMENTS:
      return getStateForSaveCustomSegments(state, action.payload);
    case CustomSegmentsActionsNames.LOAD_CUSTOM_SEGMENTS:
      return getStateForLoadCustomSegments(state, action.payload);
    case CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL:
      return getStateForUpdateCustomSegmentLabel(state, action.payload);
    case CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL_SUCCESS:
      return getStateForUpdateCustomSegmentLabelSuccess(state, action.payload);
    case CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL_FAILURE:
      return getStateForUpdateCustomSegmentLabelFailure(state, action.payload);
    case CustomSegmentsActionsNames.SELECT_CUSTOM_SEGMENT:
      return getStateForSelectCustomSegment(state, action.payload);
    case CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER:
    case CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER_FAILURE:
      return getStateForUpdateCustomSegmentsOrder(state, action.payload);
    case CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT_SUCCESS:
      return getStateForDeleteCustomSegmentSuccess(state, action.payload);
    case CustomSegmentsActionsNames.SET_EDITED_CUSTOM_SEGMENT:
      return getStateForSetCustomSegmentEditMode(state, action.payload);
    default:
      return state;
  }
}
