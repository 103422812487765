import config from 'env-config';
import { type IAmplitudeEvent } from 'services/api/amplitude/interfaces';
import type { RequestResult } from 'interfaces/api/client';
import { BaseApi, PassVersionMode } from 'services/api/base-api';

export class AmplitudeApi extends BaseApi {
  protected readonly passVersionMode = PassVersionMode.None;

  postCustomAmplitudeEvent = (events: IAmplitudeEvent[]): Promise<RequestResult<void>> => {
    return this.post(config.product_event_api_url, {
      api_key: config.amplitude_api_key,
      events,
    });
  };
}
