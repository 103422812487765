/**
 * User friendly error related to payments declined by recurly
 */
export enum PaymentError {
  InsufficientFunds = 'Check if you have adequate funds and sufficient credit card limits, or update your credit card details.',
  BillingInformationMissing = 'Your transaction was declined. Please add a valid credit card.',
  TransactionDeclined = 'Your transaction was declined. Update your credit card details or contact your bank for assistance.',
}

/**
 * User friendly error related to agent validation
 */
export enum AgentValidationError {
  GroupNotFound = `You can't assign agents to groups in your current plan. Upgrade your subscription to add agents to groups.`,
  AgentLimitReached = 'Limit of agents reached (400). To be able to add more agents contact our support.',
  TooManyGroupsLimitReached = 'Limit of allowed groups per agent reached ({{groupsLimit}} per agent). To be able to add agents to more groups you need to upgrade your subscription.',
}
