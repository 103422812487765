import { type SagaIterator } from 'redux-saga';
import { put, call, takeEvery, select, take } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import type { RequestError } from 'services/api/types';
import { ApiManager } from 'services/api/api-manager';
import { type TagDTO } from 'services/api/tag/interfaces';
import { deserializeTagCollection } from 'services/api/tag/serialization';
import { SessionActionNames } from 'store/features/session/actions';
import { getCanUseTags, getPlanType } from 'store/features/session/selectors';

import { TagActions } from './actions';

export function* fetchTags(): SagaIterator {
  const { tagApi } = ApiManager;
  const plan = yield select(getPlanType);

  if (plan === null) {
    yield take(SessionActionNames.SAVE_CURRENT_LICENSE);
  }

  const canUseTags = yield select(getCanUseTags);
  if (!canUseTags) {
    return;
  }

  const { result, error }: RequestResult<TagDTO[], RequestError> = yield call(tagApi.fetchAll);

  if (result) {
    const deserializedTags = deserializeTagCollection(result);
    yield put(TagActions.fetchCollectionSuccess({ values: deserializedTags }));
  } else {
    yield put(TagActions.fetchCollectionFailure({ error: error?.message || 'Unexpected error occured' }));
  }
}

export default function* tagsSagas(): SagaIterator {
  yield takeEvery('APP_READY', fetchTags);
}
