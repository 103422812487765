import { TrackingEvent } from 'constants/tracking-events';
import { AppStateProvider } from 'services/app-state-provider';
import { trackEvent } from 'services/event-tracking';
import { ReportBugActions } from 'store/features/report-bug/actions';

import { EventPlace } from './analytics';

export function downloadBugReport(): void {
  AppStateProvider.dispatch(ReportBugActions.downloadData());
  trackEvent(TrackingEvent.DownloadReport, EventPlace.Console);
}
