import { type ActionsUnion, createAction } from 'store/helper';

import type {
  IFetchGroupPropertiesPayload,
  IFetchGroupPropertiesSuccessPayload,
  IFetchGroupPropertiesFailurePayload,
  ISaveGroupPropertiesPayload,
  ISaveGroupPropertiesSuccessPayload,
  ISaveGroupPropertiesFailurePayload,
} from './interfaces';

export const GroupPropertiesActionNames = {
  FETCH: 'ENTITIES/GROUP_PROPERTIES/FETCH_REQUEST',
  FETCH_SUCCESS: 'ENTITIES/GROUP_PROPERTIES/FETCH_SUCCESS',
  FETCH_FAILURE: 'ENTITIES/GROUP_PROPERTIES/FETCH_FAILURE',
  SAVE: 'ENTITIES/GROUP_PROPERTIES/SAVE_REQUEST',
  SAVE_SUCCESS: 'ENTITIES/GROUP_PROPERTIES/SAVE_SUCCESS',
  SAVE_FAILURE: 'ENTITIES/GROUP_PROPERTIES/SAVE_FAILURE',
};

export enum GroupPropertiesRequestNames {
  SAVE = 'ENTITIES/GROUP_PROPERTIES/SAVE',
  FETCH = 'ENTITIES/GROUP_PROPERTIES/FETCH',
}

export const GroupPropertiesActions = {
  fetch: (payload: IFetchGroupPropertiesPayload) => createAction(GroupPropertiesActionNames.FETCH, payload),
  fetchSuccess: (payload: IFetchGroupPropertiesSuccessPayload) =>
    createAction(GroupPropertiesActionNames.FETCH_SUCCESS, payload),
  fetchFailure: (payload: IFetchGroupPropertiesFailurePayload) =>
    createAction(GroupPropertiesActionNames.FETCH_FAILURE, payload),
  save: (payload: ISaveGroupPropertiesPayload) => createAction(GroupPropertiesActionNames.SAVE, payload),
  saveSuccess: (payload: ISaveGroupPropertiesSuccessPayload) =>
    createAction(GroupPropertiesActionNames.SAVE_SUCCESS, payload),
  saveFailure: (payload: ISaveGroupPropertiesFailurePayload) =>
    createAction(GroupPropertiesActionNames.SAVE_FAILURE, payload),
};

export type GroupPropertiesAction = ActionsUnion<typeof GroupPropertiesActions>;
