import { NavigationViewActionsNames, type NavigationViewAction } from './actions';
import { type INavigationViewState } from './interfaces';

export const initialState: INavigationViewState = {
  items: {},
  mobileNavigationOpened: true,
  marketplaceVisited: false,
};

export const navigationReducer = (
  state: INavigationViewState = initialState,
  action: NavigationViewAction
): INavigationViewState => {
  switch (action.type) {
    case NavigationViewActionsNames.SHOW_ITEM_TOOLTIP: {
      const { itemId, type, theme, kind } = action.payload;
      const item = state.items[itemId] || {};

      return {
        ...state,
        items: {
          ...state.items,
          [itemId]: {
            ...item,
            tooltip: {
              show: true,
              type,
              theme,
              kind,
            },
          },
        },
      };
    }

    case NavigationViewActionsNames.HIDE_ITEM_TOOLTIP: {
      const { itemId } = action.payload;
      if (state.items[itemId]) {
        const { tooltip, ...item } = state.items[itemId];

        return {
          ...state,
          items: {
            ...state.items,
            [itemId]: item,
          },
        };
      }

      return state;
    }

    case NavigationViewActionsNames.SET_ITEM_BADGE: {
      const item = state.items[action.payload.itemId] || {};
      const { badge } = action.payload;

      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.itemId]: { ...item, badge },
        },
      };
    }

    case NavigationViewActionsNames.CLEAR_ITEM_BADGE: {
      if (state.items[action.payload.itemId]) {
        const { badge, ...item } = state.items[action.payload.itemId];

        return {
          ...state,
          items: {
            ...state.items,
            [action.payload.itemId]: item,
          },
        };
      }

      return state;
    }

    case NavigationViewActionsNames.OPEN_MOBILE_NAVIGATION:
      return {
        ...state,
        mobileNavigationOpened: true,
      };

    case NavigationViewActionsNames.CLOSE_MOBILE_NAVIGATION:
      return {
        ...state,
        mobileNavigationOpened: false,
      };

    case NavigationViewActionsNames.VISIT_MARKETPLACE:
      return {
        ...state,
        marketplaceVisited: true,
      };

    default:
      return state;
  }
};
