/* eslint-disable @typescript-eslint/naming-convention */
export const PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE = 'Ask me anything!';

export const COPILOT_LOGO = 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/one.png';

export const COPILOT_NO_AI_AGENTS_MESSAGE = {
  header: `I think I'm unplugged. 🔌`,
  text: `My team is plugging me right back in so I should be back soon!`,
};

/* Based on source/tsx/_constants/settings/navigation-items.ts due to
store access used in NavigationItems which crashes agent app if we use this in `one` sagas.
*/
export const SETTINGS_NAVIGATION_PATHS = {
  Channels: [
    '/settings/overview',
    '/settings/code',
    '/settings/email',
    '/settings/helpdesk',
    '/settings/facebook-messenger',
    '/settings/apple-messages-for-business',
  ],
  'Chat page': ['/settings/chat-page'],
  'Website widget': ['/settings/theme', '/settings/language', '/settings/availability', '/settings/welcome-screen'],
  Forms: ['/settings/pre-chat-form', '/settings/ask-for-email', '/settings/post-chat-form', '/settings/ticket-form'],
  Engagement: [
    '/settings/targeted-messages/greetings',
    '/settings/eye-catcher',
    '/settings/chat-buttons',
    '/settings/quality-badge',
  ],
  'Reply suggestions': ['/settings/reply-suggestions'],
  'Canned responses': ['/settings/canned-responses', '/settings/suggested-responses'],
  Tags: ['/settings/tags'],
  'Sales tracker': ['/settings/sales-tracker'],
  'Chat settings': [
    '/settings/chats-assignment',
    '/settings/groups-routing',
    '/settings/archives-forwarding',
    '/settings/file-sharing',
    '/settings/inactivity-timeouts',
  ],
  'Ticket settings': ['/settings/automated-emails'],
  Security: [
    '/settings/trusted-domains',
    '/settings/two-step-verification',
    '/settings/banned-customers',
    '/settings/access-restriction',
    '/settings/credit-cards-masking',
    '/settings/login-settings',
  ],
};

export const COPILOT_GLOBAL_SOURCES = [
  'https://www.livechat.com/',
  'https://www.helpdesk.com/help',
  'https://www.chatbot.com/help/',
  'https://www.knowledgebase.com/help/',
  'https://openwidget.com/',
  'https://platform.text.com/',
  'https://news.livechat.com/en',
  'https://partners.livechat.com/',
];
