// @ts-strict-ignore
/**
 * Synchronises Redux state with query string.
 *
 * Example usage in a route component connected to Redux:
 * ```ts
 * import { UrlQueryParamsFeatureActions } from 'store/features/url-query-params/actions';
 *
 * // in component
 * componentDidMount() {
 *   this.props.onMount();
 * }
 *
 * componentWillUnmount() {
 *   this.props.onUnmount();
 * }
 *
 * // in mapDispatchToProps
 * onMount: () {
 *   dispatch(
 *     UrlQueryParamsFeatureActions.registerView({ ... });
 *   );
 * },
 * onUnmount: () => {
 *   dispatch(UrlQueryParamsFeatureActions.unregisterView({ ... }));
 * }
 * ```
 *
 * registerView method requires the following params:
 * - `view`: one of UrlQueryParamsView enum values
 * - `setQueryParamsAction`: action that will be dispatched when query params have changed
 *   and Redux store must be synchronised with it. Note that you must implement the reducer
 *   that handles this store change yourself.
 * - `queryStringSerializer`: function that serializes current Redux store state to a query string
 * - `queryStringParser`: function that parsers query string object and fills in the `setQueryParamsAction` payload
 */
import { Action as HistoryAction } from 'history';
import pick from 'lodash.pick';
import { type Action } from 'redux';
import { type EventChannel, eventChannel, type SagaIterator } from 'redux-saga';
import { all, call, cancel, fork, put, type PutEffect, select, take, takeEvery } from 'redux-saga/effects';

import { SESSION_STORAGE_SESSION_SOURCE_PARAMS_KEY } from 'constants/analytics';
import { parseQueryParams, UTM_PARAMS } from 'helpers/url';
import { browserHistory, type IBrowserHistory } from 'services/browser-history';
import { saveItem } from 'services/session-storage';
import { type IActionWithPayload } from 'store/helper';
import { buildArchivesViewQueryString } from 'store/views/archives/url-query-params';

import { UrlQueryParamsFeatureActionNames } from './actions';
import { filterOutInactiveAgents } from './helpers';
import {
  type IUrlQueryParamsFeatureRegisterViewPayload,
  type IUrlQueryParamsFeatureUnregisterViewPayload,
  type IViewRegistry,
  type IViewRegistryItem,
  type IWithViewQueryParams,
  UpdateQueryTrigger,
  UrlQueryParamsView,
} from './interfaces';

const queryStringBuilders = {
  [UrlQueryParamsView.Archives]: buildArchivesViewQueryString,
};

const viewRegistry: IViewRegistry = {};

function registeredQueryStringSerializers(): IViewRegistryItem[] {
  return Object.values(viewRegistry);
}

/**
 * Builds a query string from all registered views based on the state of
 * redux store. All results are joined using `&`.
 * @desc If two builders provide `tag[]=spam` and `rate=rated_good` results,
 * it will return `tag[]=spam&rate=rated_good`.
 * @return {string} Query string based on redux store state.
 */
function buildQueryString(state: IWithViewQueryParams, pathname: string): string {
  return registeredQueryStringSerializers()
    .map((registryItem) => registryItem.queryStringSerializer(state, pathname))
    .filter(Boolean)
    .join('&');
}

function shouldActionReplaceHistory(action: Action): boolean {
  return registeredQueryStringSerializers().some((registryItem) => {
    switch (typeof registryItem.replaceHistoryActions) {
      case 'string':
        return registryItem.replaceHistoryActions === action.type;
      case 'object':
        return Array.isArray(registryItem.replaceHistoryActions)
          ? registryItem.replaceHistoryActions.includes(action.type)
          : null;
      default:
        return null;
    }
  });
}

function* updateUrlQueryParams(action): SagaIterator {
  if (registeredQueryStringSerializers().length === 0) {
    return;
  }

  const state = yield select();
  const newQueryString = buildQueryString(state, browserHistory.pathname);

  if (newQueryString !== browserHistory.queryString) {
    const replace =
      action.type === UrlQueryParamsFeatureActionNames.REGISTER_VIEW || shouldActionReplaceHistory(action);
    if (replace) {
      browserHistory.replaceQueryString(newQueryString);
    } else {
      browserHistory.pushQueryString(newQueryString);
    }
  }
}

function* registerView(
  action: IActionWithPayload<UrlQueryParamsFeatureActionNames, IUrlQueryParamsFeatureRegisterViewPayload>
): SagaIterator {
  const {
    view,
    updateQueryActions,
    replaceHistoryActions,
    setQueryParamsAction,
    queryStringSerializer,
    queryStringParser,
    updateQueryTrigger,
    shouldSetQueryParamsOnRegister,
    shouldSetQueryParamsOnEmptyPath = false,
    isPathMatchingPattern,
  } = action.payload;

  viewRegistry[view] = {
    setQueryParamsAction,
    queryStringSerializer: queryStringSerializer || queryStringBuilders[view],
    queryStringParser,
    updateQueryActions,
    replaceHistoryActions,
    updateQueryTrigger,
    isPathMatchingPattern,
    shouldSetQueryParamsOnEmptyPath,
    updateUrlQueryParams: yield updateQueryActions && takeEvery(updateQueryActions, updateUrlQueryParams),
  };

  if (setQueryParamsAction) {
    const state = yield select();
    const queryStringFromStore = viewRegistry[view].queryStringSerializer(state);
    // We have to check if user comes from different in-app navigation that contain query params. E.g: Raports -> Archives
    if (
      !browserHistory.hasLocationEverChanged ||
      shouldSetQueryParamsOnRegister ||
      (browserHistory.queryString.length > 0 && queryStringFromStore !== browserHistory.queryString) ||
      shouldSetQueryParamsOnEmptyPath
    ) {
      const params = queryStringParser(browserHistory.queryParams, browserHistory.pathname);
      const sanitizedParams = yield call(filterOutInactiveAgents, params);
      yield put(setQueryParamsAction({ params: sanitizedParams }));
    }
  }
}

function* unregisterView(
  action: IActionWithPayload<UrlQueryParamsFeatureActionNames, IUrlQueryParamsFeatureUnregisterViewPayload>
): SagaIterator {
  const { view } = action.payload;
  yield cancel(viewRegistry[view].updateUrlQueryParams);
  delete viewRegistry[view];
}

/**
 * Creates {@link https://redux-saga.js.org/docs/advanced/Channels.html | eventChannel}
 * that lets a saga listen to popState browser history events
 */
function createHistoryPopStateChannel(history: IBrowserHistory): EventChannel<unknown> {
  return eventChannel((emit) => {
    const unlisten = history.listen(({ location, action }) => {
      if (action === HistoryAction.Pop) {
        emit({ location });
      }
    });

    return unlisten;
  });
}

function* listenToHistoryPopState() {
  const historyChannel = yield call(createHistoryPopStateChannel, browserHistory.browserHistoryObject);
  while (true) {
    const { location } = yield take(historyChannel);
    const state = yield select();

    const actions = yield registeredQueryStringSerializers()
      .reduce<PutEffect[]>((acc, registryItem) => {
        const queryStringFromStore = registryItem.queryStringSerializer(state);
        if (
          registryItem.updateQueryTrigger === UpdateQueryTrigger.QueryParamsChange &&
          queryStringFromStore === browserHistory.queryString
        ) {
          return acc;
        }

        if (!registryItem.isPathMatchingPattern || registryItem.isPathMatchingPattern(location.pathname)) {
          acc.push(
            put(
              registryItem.setQueryParamsAction({
                params: registryItem.queryStringParser(parseQueryParams(location.search), location.pathname),
              })
            )
          );
        }

        return acc;
      }, [])
      .filter(Boolean);

    yield all(actions);
  }
}

function rememberSessionSourceParams(): void {
  const queryParams = parseQueryParams(location.search);
  const utmQueryParams = pick(queryParams, UTM_PARAMS);
  if (Object.keys(utmQueryParams).length > 0) {
    saveItem(SESSION_STORAGE_SESSION_SOURCE_PARAMS_KEY, utmQueryParams);
  }
}

export default function* urlQueryParamsFeatureSaga(): SagaIterator {
  yield takeEvery('APP_READY', rememberSessionSourceParams);
  yield takeEvery(UrlQueryParamsFeatureActionNames.REGISTER_VIEW, registerView);
  yield takeEvery(UrlQueryParamsFeatureActionNames.UNREGISTER_VIEW, unregisterView);
  yield fork(listenToHistoryPopState);
}
