import { SHOW_INSIGHTS_LAST_WEEK_MINIMUM_CHATS_COUNT } from './constants';
import { type IInsightsMetrics, type IInsightsState } from './interfaces';

export interface IWithInsightsState {
  entities: {
    insights: IInsightsState;
  };
}

export function getInsights(state: IWithInsightsState): IInsightsState {
  return state.entities.insights;
}

export function getInsightsMetrics(state: IWithInsightsState): IInsightsMetrics | null {
  return state.entities.insights.metrics;
}

export function getInsightsAcknowledgedHints(state: IWithInsightsState): IInsightsState['acknowledgedHints'] {
  return state.entities.insights.acknowledgedHints;
}

export function getHasMinimumInsightsData(state: IWithInsightsState): boolean {
  const lastWeekChatsCount = state.entities.insights.metrics?.chatsCount.newValue ?? 0;

  return lastWeekChatsCount >= SHOW_INSIGHTS_LAST_WEEK_MINIMUM_CHATS_COUNT;
}
