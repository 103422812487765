import { type ActionsUnion, createAction } from 'store/helper';

import {
  type IGoalAddedPayload,
  type IGoalRemovedPayload,
  type IGoalResetPayload,
  type IGoalUpdatedPayload,
} from './interfaces';

export const GoalActionNames = {
  GOALS_RESET: 'ENTITIES/GOALS/GOALS_RESET',
  GOAL_ADD: 'ENTITIES/GOALS/GOAL_ADD',
  GOAL_UPDATE: 'ENTITIES/GOALS/GOAL_UPDATE',
  GOAL_REMOVE: 'ENTITIES/GOALS/GOAL_REMOVE',
};

export const GoalActions = {
  goalsReset: (payload: IGoalResetPayload) => createAction(GoalActionNames.GOALS_RESET, payload),
  goalAdded: (payload: IGoalAddedPayload) => createAction(GoalActionNames.GOAL_ADD, payload),
  goalUpdated: (payload: IGoalUpdatedPayload) => createAction(GoalActionNames.GOAL_UPDATE, payload),
  goalRemoved: (payload: IGoalRemovedPayload) => createAction(GoalActionNames.GOAL_REMOVE, payload),
};

export type GoalAction = ActionsUnion<typeof GoalActions>;
