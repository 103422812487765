import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { isDesktopView } from 'helpers/device';
import { getHomePage, navigate } from 'helpers/routing';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { ApplicationsActionNames as ApplicationsEntityActionNames } from 'store/entities/applications/actions';
import {
  ApplicationWidgetPlacement,
  type IApplicationWidget,
  type IWidget,
} from 'store/entities/applications/interfaces';
import { getIsFetchingApplications, getWidgetsWithPlacement } from 'store/entities/applications/selectors';
import { ApplicationsActions } from 'store/features/applications/actions';
import { getLoadedFullscreenWidgets } from 'store/features/applications/selectors';
import { type IActionWithPayload } from 'store/helper';

import { FullscreenWidgetsViewActionNames, FullscreenWidgetsViewActions } from './actions';
import { type ILoadWidgetPayload } from './interfaces';

function updateDocumentTitle(widget: IWidget) {
  document.title = widget.name;
}

function* loadWidget(
  action: IActionWithPayload<FullscreenWidgetsViewActionNames.LOAD_WIDGET, ILoadWidgetPayload>
): SagaIterator {
  const { id: targetWidgetId, loadedOnAgentAppInit } = action.payload;
  const isFetchingApps = yield select(getIsFetchingApplications);

  if (isFetchingApps) {
    yield take([
      ApplicationsEntityActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS],
      ApplicationsEntityActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.FAILURE],
    ]);
  }

  const widget: IWidget[] = yield select(getWidgetsWithPlacement, ApplicationWidgetPlacement.FullScreen);
  const targetWidget = widget.find((app) => app.id === targetWidgetId);

  if (targetWidget) {
    const loadedWidgetIds: string[] = yield select(getLoadedFullscreenWidgets);
    const isTargetWidgetLoaded = !!loadedWidgetIds.find((id) => targetWidgetId === id);

    if (!loadedOnAgentAppInit) {
      yield call(updateDocumentTitle, targetWidget);
    }

    if (!isTargetWidgetLoaded) {
      yield put(ApplicationsActions.loadFullscreenWidget(targetWidgetId));
    }
  } else {
    yield call(navigate, getHomePage(), { replace: true });
  }
}

function* widgetsFetched() {
  if (isDesktopView()) {
    const widgets: IApplicationWidget[] = yield select(getWidgetsWithPlacement, 'fullscreen');
    yield all(
      widgets.map((widget) =>
        put(
          FullscreenWidgetsViewActions.load({
            id: widget.id,
            loadedOnAgentAppInit: true,
          })
        )
      )
    );
  }
}

export default function* fullscreenWidgetsViewSagas() {
  yield takeLatest(FullscreenWidgetsViewActionNames.LOAD_WIDGET, loadWidget);
  yield takeLatest(ApplicationsEntityActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS], widgetsFetched);
}
