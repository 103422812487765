// @ts-strict-ignore
import { useCallback } from 'react';

import { MobileNavigation as DSMobileNavigation } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { buildMobileNavigationItems } from 'components/navigation/configuration';
import type { BadgeValue } from 'components/new-navigation/hooks/use-badge-counter';
import { navigate } from 'helpers/routing';
import type { NavigationItem } from 'interfaces/navigation-item';
import { getMyChatsIds, getQueuedIds, getUnassignedIds } from 'store/entities/chats/selectors';
import { getCurrentSection } from 'store/features/routing/selectors';

import { MobileNavigationItem } from './MobileNavigationItem';
import MobileNavigationMyProfileItem from './MobileNavigationMyProfileItem';
import { MobileNavigationOne } from './MobileNavigationOne';

import * as styles from './styles';

function getChatsCountBadge(assignedChats = 0, queuedChats = 0, unassignedChats = 0): BadgeValue {
  if (assignedChats > 0) {
    return assignedChats;
  }

  if (queuedChats + unassignedChats > 0) {
    return 'dot';
  }

  return null;
}

function getBadgeContent(
  id: string,
  chatsCount: number,
  queuedChatsCount: number,
  unassignedChatsCount: number,
): BadgeValue {
  const builders = {
    chats: () => getChatsCountBadge(chatsCount, queuedChatsCount, unassignedChatsCount),
    visitors: () => (queuedChatsCount > 0 ? queuedChatsCount : null),
    customers: () => (queuedChatsCount > 0 ? queuedChatsCount : null),
  };

  const builder = builders[id];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return builder ? builder() : null;
}

export const MobileNavigation = (): JSX.Element => {
  const chatsCount = useSelector(getMyChatsIds).length;
  const queuedChatsCount = useSelector(getQueuedIds).length;
  const unassignedChatsCount = useSelector(getUnassignedIds).length;
  const navigationItems = buildMobileNavigationItems();
  const currentSection = useSelector(getCurrentSection);

  const onItemClick = useCallback((path: string) => {
    navigate(path, {
      replace: false,
    });
  }, []);

  const itemRenderers: Record<string, (item: NavigationItem) => JSX.Element> = {
    'mobileProfile': (item) => (
      <MobileNavigationMyProfileItem
        path={item.path}
        active={item.id?.startsWith(currentSection)}
        onItemClick={onItemClick}
      />
    ),
    'one': () => <MobileNavigationOne />,
    'default': (item) => (
      <MobileNavigationItem
        icon={item.iconName}
        activeIcon={item.activeIconName}
        title={item.name}
        path={item.path}
        active={currentSection?.startsWith(item.id)}
        badgeContent={getBadgeContent(item.id, chatsCount, queuedChatsCount, unassignedChatsCount)}
        onItemClick={onItemClick}
      />
    ),
  };
  

  return (
    <DSMobileNavigation className={styles.mobileNavigation}>
      {navigationItems.map((item) => 
        (itemRenderers[item.id] || itemRenderers.default)(item)
      )}
    </DSMobileNavigation>
  );
};
