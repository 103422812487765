import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';
import { ellipsis } from 'polished';

import { resetLegacyLcBorderBox } from 'styles/reset-border-box';

export const wrapper = css`
  ${resetLegacyLcBorderBox};
  height: 100%;
  min-width: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
  transition:
    width 0.3s ease-in-out,
    min-width 0.3s ease-in-out,
    visibility 0.2s ease-in-out,
    opacity 0.2s ease;
  border-left: 1px solid var(${DesignToken.BorderBasicTertiary});
`;

export const openedDetailsWrapper = css`
  height: 100%;
  min-width: 403px;
  width: 403px;
  visibility: visible;
  opacity: 1;
  border-left: 1px solid var(${DesignToken.BorderBasicTertiary});
`;

export const detailsWrapper = css`
  height: 100%;
  overflow-y: auto;
`;

export const noSourcesContainer = css`
  color: var(${DesignToken.ContentBasicPrimary});

  & > div {
    display: flex;
    align-items: center;
    gap: var(${SpacingToken.Spacing4});
    margin-top: var(${SpacingToken.Spacing5});
  }
`;

export const sourcesList = css`
  margin-left: var(${SpacingToken.Spacing5});
  list-style: disc;

  li {
    color: var(${DesignToken.ContentBasicPrimary});
    margin-bottom: var(${SpacingToken.Spacing2});

    p {
      ${ellipsis()};
      max-width: 90%;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }
  }
`;

export const loaderWrapper = css`
  display: flex;
  justify-content: center;
  padding: 12px;
`;

export const settingsButton = css`
  margin-top: var(${SpacingToken.Spacing4});
  margin-left: var(${SpacingToken.Spacing5});
  display: flex;
  align-items: center;
  align-self: stretch;
  color: var(${DesignToken.ActionPrimaryDefault});

  h3 {
    font-size: 14px;
  }
`;

export const starterUpsellText = css`
  margin-bottom: 14px;
`;

export const detailsCard = css`
  border-bottom: 1px solid var(${DesignToken.BorderBasicTertiary});
  background-color: var(${DesignToken.SurfacePrimaryDefault});
`;

export const detailsCopilotInfo = css`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(${DesignToken.ContentBasicSecondary});
  padding: var(${SpacingToken.Spacing6});
`;

export const aboutCopilotText = css`
  color: var(${DesignToken.ContentBasicPrimary});
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: var(${SpacingToken.Spacing4});
`;

export const feedbackBanner = css`
  margin: var(${SpacingToken.Spacing5});
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: var(${DesignToken.SurfaceGradient10});

  p {
    color: var(${DesignToken.ContentBasicPrimary});
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const sourceListItem = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  margin-bottom: var(${SpacingToken.Spacing2});

  &:hover {
    cursor: pointer;
  }
`;

const sourceListIcon = (color: string, bgColor: string, opacity: number): string => css`
  padding: var(${SpacingToken.Spacing1});
  border-radius: var(${RadiusToken.Radius1});
  color: var(${color});
  position: relative;
  overflow: hidden;

  & > div {
    position: absolute;
    background-color: var(${bgColor});
    opacity: ${opacity};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

export const pdfIcon = css`
  ${sourceListIcon(DesignToken.DecorRed300, DesignToken.DecorRed300, 0.15)};
`;

export const urlIcon = css`
  ${sourceListIcon(DesignToken.ContentBasicInfo, DesignToken.SurfacePrimaryActiveColored, 0.75)};
`;

export const sourceLoaderWrapper = css`
  padding: var(${SpacingToken.Spacing1});
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const sourceLoaderContent = css`
  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  span {
    margin-top: var(${SpacingToken.Spacing1});
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
`;
