import { type SagaIterator } from 'redux-saga';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { getGoogleAnalyticsAppId } from 'helpers/applications';
import type { RequestResult } from 'interfaces/api/client';
import type { CreateCartResult, ProductsCart } from 'interfaces/product-cart';
import { ApiManager } from 'services/api/api-manager';
import { getIsGoogleAnalyticsInstalled } from 'store/entities/applications/selectors';
import { ProductCartActions, ProductCartActionNames } from 'store/entities/product-cart/actions';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getIsSubscriptionSubscribed, getSubscription } from 'store/entities/subscription/selectors';
import { getIsSubscriptionExpired } from 'store/features/session/selectors';

/**
 * Load recent cart and add it to store
 * In case cart is not available create a new one
 * Add GA to the cart in case of fresh license
 */
function* fetchCart(): SagaIterator {
  // Wait for the subscription to be fetched
  const subscription: ReturnType<typeof getSubscription> = yield select(getSubscription);

  if (!subscription) {
    yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
  }

  let recentCartResponse: RequestResult<ProductsCart> = yield call(ApiManager.productCartApi.fetchRecent);
  let currentCartId = recentCartResponse.result?.id;

  // Create the cart if none available
  if (recentCartResponse.error) {
    const createCartResponse: RequestResult<CreateCartResult> = yield call(ApiManager.productCartApi.create);
    currentCartId = createCartResponse.result?.id;

    if (createCartResponse.error) {
      yield put(ProductCartActions.loadCartFailure());

      return;
    }
  }

  const isExpired = yield select(getIsSubscriptionExpired);
  const isSubscriber = yield select(getIsSubscriptionSubscribed);
  const isGoogleAnalyticsInstalled = yield select(getIsGoogleAnalyticsInstalled);
  const isGoogleAnalyticsAddedToCart = recentCartResponse.result?.products?.some(
    ({ id }) => id === getGoogleAnalyticsAppId(),
  );

  const shouldMarkGAToInstall = !isExpired && !isSubscriber && !isGoogleAnalyticsInstalled;
  const shouldAddGAToCart = !isExpired && shouldMarkGAToInstall && !isGoogleAnalyticsAddedToCart;

  // Add GA to the cart for non-subscriber and non-expired license
  if (shouldAddGAToCart) {
    yield call(ApiManager.productCartApi.addApplication, currentCartId, getGoogleAnalyticsAppId());
  }

  // Refetch cart data after the cart was created or addon was added
  if (recentCartResponse.error || shouldAddGAToCart) {
    recentCartResponse = yield call(ApiManager.productCartApi.fetchRecent);
  }

  // Save cart data to the store
  yield put(ProductCartActions.saveCurrentCart(recentCartResponse.result));

  yield put(ProductCartActions.confirmAllApplications());

  yield put(ProductCartActions.loadCartSuccess());
}

export function* fetchCartSaga(): SagaIterator {
  yield takeEvery(ProductCartActionNames.LOAD_CART_REQUEST, fetchCart);
}
