import { type ActionsUnion, createAction } from 'store/helper';

import { type ISetDraftStatePayload, type IGreetingTableSortSchema } from './interfaces';

export enum GreetingViewActionsEnum {
  SET_GREETING_DRAFT = 'VIEWS/GREETINGS/SET_GREETING_DRAFT',
  SET_TABLE_SORT = 'VIEWS/GREETINGS/SET_TABLE_SORT',
  RESET_TABLE_SORT = 'VIEWS/GREETINGS/RESET_TABLE_SORT',
}

export const GreetingViewActions = {
  setDraft: (payload: ISetDraftStatePayload) => createAction(GreetingViewActionsEnum.SET_GREETING_DRAFT, payload),
  setTableSort: (payload: Partial<IGreetingTableSortSchema>) =>
    createAction(GreetingViewActionsEnum.SET_TABLE_SORT, payload),
  resetTableSort: () => createAction(GreetingViewActionsEnum.RESET_TABLE_SORT),
};

export type GreetingViewActionTypes = ActionsUnion<typeof GreetingViewActions>;
