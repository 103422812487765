// @ts-strict-ignore
import { Place } from '@livechat/design-system-icons';

import { getCountryNameByCountryCode } from 'constants/recurly-countries';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

const COUNTRY_ISO_FILTER_RELASE_DATE = '2022-03-30';

export const countryIsoFilterConfigMultiple: FilterConfig = {
  title: 'Country',
  icon: Place,
  mobileViewTitle: 'Select countries',
  multiple: true,
  newBadge: {
    relaseDate: COUNTRY_ISO_FILTER_RELASE_DATE,
    duration: 48,
    agentCustomProperty: AgentCustomPropertyName.CountryIsoFilterSeen,
  },
  getFilterLabelValues(filterValues: string[] | null): FilterLabelValue[] {
    if (!filterValues) {
      return null;
    }

    return filterValues
      .map((countryIsoCode) => ({
        key: countryIsoCode,
        label: getCountryNameByCountryCode(countryIsoCode),
      }))
      .filter(({ label }) => label !== null);
  },
};
