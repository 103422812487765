import type { IPickerListItem } from '@livechat/design-system-react-components';

import { SurveyType } from 'constants/filters/survey-type-filter';

export const items = [
  {
    key: SurveyType.PreChat,
    props: {
      label: 'Pre-chat form',
      value: SurveyType.PreChat,
    },
  },
  {
    key: SurveyType.PostChat,
    props: {
      label: 'Post-chat form',
      value: SurveyType.PostChat,
    },
  },
];

export const getPickerOptions = (): IPickerListItem[] =>
  items.map((item) => {
    return {
      key: item.key,
      name: item.props.label,
    };
  });
