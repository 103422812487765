import { css, keyframes } from '@emotion/css';

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -8px, 0);
  }

  70% {
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0,0,0);
  }
`;

export const badgeAnimationStyles = css`
  animation: ${bounce} 1s ease 1s 1;
`;
