import { type KeyMap } from 'helpers/interface';
import { type ICampaignApplication } from 'store/features/applications/interfaces';
import { createAction, type ActionsUnion } from 'store/helper';

export enum HomeScreenActionsEnum {
  SET_APPS_GALLERY_LIST = 'VIEWS/HOME_SCREEN/APPS_GALLERY/SET_LIST',
  TOGGLE_APPS_GALLERY = 'VIEWS/HOME_SCREEN/APPS_GALLERY/TOGGLE',
  CHECKLIST_ANIMATION_SEEN = 'VIEWS/HOME_SCREEN/CHECKLIST_ANIMATION_SEEN',
}

export const HomeScreenActions = {
  setAppsGalleryList: (applications: KeyMap<ICampaignApplication[]>) =>
    createAction(HomeScreenActionsEnum.SET_APPS_GALLERY_LIST, { applications }),
  toggleAppsGallery: () => createAction(HomeScreenActionsEnum.TOGGLE_APPS_GALLERY),
  markChecklistAnimationAsSeen: () => createAction(HomeScreenActionsEnum.CHECKLIST_ANIMATION_SEEN),
};

export type HomeScreenActionTypes = ActionsUnion<typeof HomeScreenActions>;
