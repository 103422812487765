import { Section } from 'constants/section';

import { type IRoutingState } from './interfaces';

export interface IWithRoutingState {
  features: {
    routing: IRoutingState;
  };
}

/**
 * Currently, this function uses `strict` param with a default value to determine the comparison type.
 * Our mechanism that store the section name based on URL includes the URL params in the string,
 * which complicate comparison with a given section name.
 * Current workaround is the `strict` prop which switches the comparison type to find the section name
 * in the part of the stored string.
 * TODO: Remodel the mechanism responsible for storing the section name in the store which will not merge
 * the main section name with their additional params.
 */
export function getIsOnSection(state: IWithRoutingState, section: Section, strict = true): boolean {
  if (strict) {
    return state.features.routing.currentSection === section;
  }

  return Boolean(state.features.routing.currentSection?.startsWith(section));
}

export function getSectionName(state: IWithRoutingState): Section | undefined {
  const currentSection = state.features.routing.currentSection;

  return Object.values(Section).find((section) => currentSection?.startsWith(section));
}

export function getCurrentSection(state: IWithRoutingState): Section | undefined {
  const currentSection = state.features.routing.currentSection;

  return Object.values(Section).find((section) => currentSection === section);
}

export function getIsPreviousSection(state: IWithRoutingState, section: Section, strict = true): boolean {
  if (strict) {
    return state.features.routing.previousSection === section;
  }

  return Boolean(state.features.routing.previousSection?.includes(section));
}
