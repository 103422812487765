import { QueryKey } from 'constants/query-key';
import { EventPlace } from 'helpers/analytics';
import type { ProductOnboardingStep } from 'interfaces/entities/product-onboarding';
import type { ICompanyContactInfo, IContactInfoApiResponse } from 'services/api/lookup/interfaces';
import { AppStateProvider } from 'services/app-state-provider';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getAgentCustomProperty } from 'store/features/agent-custom-properties/selectors';

interface ISuccessOnboardingStepPayload {
  step: ProductOnboardingStep;
  eventName: string;
  isLastStep?: boolean;
}

export const handleOnboardingStepSuccess = async ({
  step,
  isLastStep,
  eventName,
}: ISuccessOnboardingStepPayload): Promise<void> => {
  const queryClient = getQueryClient();
  await queryClient.refetchQueries([QueryKey.OnboardingProgress]);
  trackEvent(eventName, EventPlace.HomeScreen, { itemName: step });
  const isOnboardingSuggestedListOpen = AppStateProvider.selectFromStore((state) =>
    getAgentCustomProperty(state, AgentCustomPropertyName.OnboardingSuggestedListOpen)
  );

  if (isLastStep) {
    if (!isOnboardingSuggestedListOpen) {
      AppStateProvider.dispatch(
        AgentCustomPropertiesActions.setAgentCustomProperty({
          [AgentCustomPropertyName.OnboardingSuggestedListOpen]: true,
        })
      );
    }

    trackEvent('Checklist completed', EventPlace.HomeScreen);
  }
};

const isPersonContactInfo = (response): response is IContactInfoApiResponse => 'person' in response;
export const mapApiResponseToDto = (
  response: IContactInfoApiResponse | ICompanyContactInfo
): IContactInfoApiResponse => {
  if (isPersonContactInfo(response)) {
    return {
      person: response.person,
      company: response.company,
    };
  }

  return {
    person: null,
    company: response,
  };
};
