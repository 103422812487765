import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { type IWidgetItem, buildFullscreenWidgetsItems } from 'components/navigation/configuration';
import { type IApplicationWidget } from 'store/entities/applications/interfaces';
import { getFullscreenWidgets } from 'store/entities/applications/selectors';

import { usePinnedFullscreenWidgetStorage } from './use-pinned-fullscreen-widgets-storage';

const getPinnedFullscreenWidgets = (
  pinnedApps: string[],
  fullscreenWidgets: IApplicationWidget[],
  route: string
): IWidgetItem[] => {
  if (!fullscreenWidgets.length) {
    return [];
  }

  const widgets = fullscreenWidgets.filter((widget) => widget.isInternalProduct || pinnedApps?.includes(widget.appId));

  return buildFullscreenWidgetsItems(widgets, route);
};

export const usePinnedFullscreenWidgetList = (): IWidgetItem[] => {
  const fullscreenWidgets = useSelector(getFullscreenWidgets);
  const { data: pinnedWidgetIds } = usePinnedFullscreenWidgetStorage();
  const location = useLocation();

  return getPinnedFullscreenWidgets(pinnedWidgetIds, fullscreenWidgets, location.pathname);
};
