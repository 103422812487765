export enum ChatEvent {
  TransferButtonClicked = 'Transfer button clicked',
  ChatTransferredError = 'Chat transferred error',
  NewChatThreadViewed = 'New chat thread viewed',
  ChatReopened = 'Chat re-opened',
  ChatTransferred = 'Chat transfered',
  TicketCreated = 'Ticket created',
  WhisperMessageSent = 'Whisper message sent',
  ChatsListSentiment = 'Chats list sentiment',
  ChatsMessageReactionUsed = 'Chats message reaction used',
  ChatsMessageReactionRemoved = 'Chats message reaction removed',
  // Priority chat
  MarkPriority = 'Mark priority',
  RemovePriority = 'Remove priority',
}
