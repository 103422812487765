// @ts-strict-ignore
import { subDays, startOfToday, format } from 'date-fns';
import memoizeOne from 'memoize-one';

import { DateFormat } from 'constants/date';
import { DateFilterId, DateFilterFormatedValues } from 'constants/filters/date-filter';

export interface DatePickerOption {
  id: DateFilterId;
  label: string;
  isManual?: boolean;
  value: {
    date: Date;
  };
}

const calculateOptions = memoizeOne((year: number, month: number, day: number): DatePickerOption[] => {
  const todayDate = new Date(year, month, day);

  return [
    {
      id: DateFilterId.Today,
      label: DateFilterFormatedValues[DateFilterId.Today],
      value: { date: todayDate },
    },
    {
      id: DateFilterId.Yesterday,
      label: DateFilterFormatedValues[DateFilterId.Yesterday],
      value: { date: subDays(todayDate, 1) },
    },
    {
      id: DateFilterId.TwoDaysAgo,
      label: format(subDays(todayDate, 2), DateFormat.ShortDate),
      value: { date: subDays(todayDate, 2) },
    },
    {
      id: DateFilterId.ThreeDaysAgo,
      label: format(subDays(todayDate, 3), DateFormat.ShortDate),
      value: { date: subDays(todayDate, 3) },
    },
    {
      id: DateFilterId.CustomDate,
      isManual: true,
      label: DateFilterFormatedValues[DateFilterId.CustomDate],
      value: null,
    },
  ];
});

export function getDateFilterOptions(): DatePickerOption[] {
  const todayDate = startOfToday();

  return calculateOptions(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
}


