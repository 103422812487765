import { useSelector } from 'react-redux';

import { useListSuggestions } from 'hooks/use-list-suggestions';
import { useParamSelector } from 'hooks/use-param-selector';
import { type Suggestion } from 'interfaces/copilot';
import { getIsOnlyWelcomeMessage } from 'store/entities/copilot/computed';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getAgentCustomProperty } from 'store/features/agent-custom-properties/selectors';

import { MAX_NUMBER_OF_API_SUGGESTIONS } from '../suggestions/constants';
import { getRandomSuggestions } from '../suggestions/helpers';

const EMPTY_SUGGESTIONS = [];

type UseCopilotSuggestions = {
  suggestions?: Suggestion[];
  randomizedSuggestions: Suggestion[];
  shouldShowSuggestions: boolean;
};

export const useCopilotSuggestions = (
  maxSuggestions: number = MAX_NUMBER_OF_API_SUGGESTIONS,
): UseCopilotSuggestions => {
  const { data: suggestions } = useListSuggestions();

  const isOnlyWelcomeMessage = useSelector(getIsOnlyWelcomeMessage);
  const hasSeenOneOnboarding = useParamSelector(
    getAgentCustomProperty,
    AgentCustomPropertyName.OnePopoverOnboardingSeen,
  ) as boolean;

  const shouldShowSuggestions = isOnlyWelcomeMessage && hasSeenOneOnboarding && !!suggestions;

  const randomizedSuggestions = shouldShowSuggestions
    ? getRandomSuggestions(suggestions, maxSuggestions)
    : EMPTY_SUGGESTIONS;

  return {
    suggestions,
    randomizedSuggestions,
    shouldShowSuggestions,
  };
};
