// @ts-strict-ignore
import { combineReducers } from 'redux';

import type { IStoreState } from 'interfaces/store/store-state';

import { entitiesReducer } from './entities/reducer';
import { featuresReducer } from './features/features-reducer';
import { requestsReducer } from './requests/reducer';
import { viewsReducer } from './views/views-reducer';

export const reducers = combineReducers<IStoreState>({
  views: viewsReducer,
  entities: entitiesReducer,
  features: featuresReducer,
  requests: requestsReducer,
});
