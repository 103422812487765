import { type IActionWithPayload } from 'store/helper';

import { IntegrationGroupPropertiesActionNames, type IntegrationGroupPropertiesAction } from './actions';
import {
  type IFetchGroupPropertiesSuccessPayload,
  type IIntegrationGroupProperties,
  type IUpdateGroupPropertiesSuccessPayload,
} from './interfaces';

const initialState: IIntegrationGroupProperties = {};

export function integrationGroupPropertiesReducer(
  state: IIntegrationGroupProperties = initialState,
  action: IntegrationGroupPropertiesAction
): IIntegrationGroupProperties {
  switch (action.type) {
    case IntegrationGroupPropertiesActionNames.FETCH_SUCCESS: {
      const {
        payload: { groupId, properties },
      } = action as IActionWithPayload<string, IFetchGroupPropertiesSuccessPayload>;

      return {
        ...state,
        [groupId]: {
          ...properties,
        },
      };
    }

    case IntegrationGroupPropertiesActionNames.UPDATE_SUCCESS: {
      const {
        payload: { groupId, properties, namespace },
      } = action as IActionWithPayload<string, IUpdateGroupPropertiesSuccessPayload>;

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          [namespace]: {
            ...state[groupId][namespace],
            ...properties,
          },
        },
      };
    }

    default:
      return state;
  }
}
