export const enum TeamViewActions {
  TOGGLE_SORT = 'VIEWS/TEAM/TOGGLE_SORT',
  FILTER_CHANGE = 'VIEWS/TEAM/FILTER_CHANGE',
  SET_SELECTED_ENTITY = 'VIEWS/TEAM/SET_SELECTED_ENTITY',
  ADD_SELECTED_AGENT_LOGIN = 'VIEWS/ADD_SELECTED_AGENT_LOGIN',
  REMOVE_SELECTED_AGENT_LOGIN = 'VIEWS/REMOVE_SELECTED_AGENT_LOGIN',
  UNSELECT_ALL_AGENTS = 'VIEWS/UNSELECT_ALL_AGENTS',
  GROUP_FORM_SET_MEMBERS = 'VIEWS/TEAM/GROUP_FORM_SET_MEMBERS',
  SET_TEAM_CURRENT_PAGE = 'VIEWS/SET_TEAM_CURRENT_PAGE',
  OPEN_MODAL = 'VIEWS/OPEN_MODAL',
  CLOSE_MODAL = 'VIEWS/CLOSE_MODAL',
}
