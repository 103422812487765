export enum OutboxMessageTitles {
  CustomerProfile = 'livechat:customer_profile',
  ActionButton = 'livechat:action_button',
  CustomerDetailsSectionButtonClick = 'livechat:customer_details_section_button_click',
  MessageBoxText = 'livechat:message_box_text',
  MessageSent = 'livechat:message_sent',
  SendCardsResponse = 'livechat:send_cards_response',
  PageData = 'livechat:page_data',
  ChangeLivechatTheme = 'livechat:change_livechat_theme',
}
