import { KnowledgeSourceItemStatus } from 'interfaces/knowledge-sources';

export const COPILOT_HELP_CENTER_LINK = 'https://www.livechat.com/help/one-ai-powered-personal-assistant/';

export const COPILOT_SOURCES_DESCRIPTION = 'Train Copilot with sources and receive reply suggestions while chatting.';

export const LOADING_STATUSES = [
  KnowledgeSourceItemStatus.IDLE,
  KnowledgeSourceItemStatus.PENDING,
  KnowledgeSourceItemStatus.IN_PROGRESS,
];
