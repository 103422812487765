import { type ActionsUnion, createAction } from 'store/helper';

export enum UnsavedChangesNames {
  SET_UNSAVED_CHANGES = 'FEATURES/UNSAVED-CHANGES/SET_HAS_UNSAVED_CHANGES',
}

export const UnsavedChanges = {
  setRouteHasUnsavedChanges: (hasUnsavedChanges: boolean) =>
    createAction(UnsavedChangesNames.SET_UNSAVED_CHANGES, { hasUnsavedChanges }),
};

export type UnsavedChangesAction = ActionsUnion<typeof UnsavedChanges>;
