// @ts-strict-ignore
import type { ErrorEvent, EventHint } from '@sentry/types';
import { differenceInSeconds } from 'date-fns';

interface EventStats {
  count: number;
  lastSeen: Date;
  lastSent: Date;
}

const storedEvents: Record<string, EventStats> = {};

// Limit 1 unique event per minute
const eventSendingTimeLimit = 60;

function getEventKey(event: ErrorEvent, hint?: EventHint): string {
  return hint && hint.originalException && (hint.originalException as Error).stack;
}

export function isEventWithInTheLimit(event: ErrorEvent, hint?: EventHint): boolean {
  const eventKey = getEventKey(event, hint);

  const eventStats: EventStats = storedEvents[eventKey] || {
    count: 0,
    lastSeen: null,
    lastSent: null,
  };

  const canSend = !eventStats.lastSeen || differenceInSeconds(new Date(), eventStats.lastSent) > eventSendingTimeLimit;

  eventStats.count += 1;
  eventStats.lastSeen = new Date();

  if (canSend) {
    eventStats.lastSent = new Date();
  }

  storedEvents[eventKey] = eventStats;

  return canSend;
}
