import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const labelItem = css`
  a {
    color: var(${DesignToken.DecorGray200});

    &:hover {
      color: var(${DesignToken.ContentLockedWhite});
    }

    span {
      color: unset;

      &:hover {
        color: unset;
      }
    }
  }
`;

export const collapseLabel = css`
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const nestedItem = css`
  .display-on-hover {
    display: none;
  }

  &:hover {
    .display-on-hover {
      display: flex;
    }
    .hide-siblings-on-hover + * {
      display: none;
    }
  }
`;

export const nestedItemAnchor = css`
  padding-inline: var(${SpacingToken.Spacing6});
`;

export const pin = css`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const pinIcon = css`
  width: 14px;
  height: 14px;
`;

export const badge = css`
  && {
    color: var(${DesignToken.ContentLockedWhite});
  }
`;
