// @ts-strict-ignore
import uniq from 'lodash.uniq';

import { Currency } from 'constants/currencies';
import { getSeriesDataFromPeriod, sumValueFromPeriod } from 'helpers/reports';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';

import type { ITrackedSalesData, IChartSeries, IChartSummary } from '../../../../interfaces/reports';

const colors = [
  '#7bb5ec',
  '#434347',
  '#90ed7c',
  '#efa842',
  '#4384f5',
  '#d64646',
  '#4bb678',
  '#ffd285',
  '#424d57',
  '#436E39',
  '#BD8435',
  '#39546E',
  '#571D1D',
  '#3468C2',
  '#9A63C1',
  '#A36B1D',
  '#71BA61',
  '#F7705B',
  '#618FBA',
  '#704F1F',
  '#A33636',
  '#DDDB5F',
  '#6822BD',
  '#203F75',
];

function getColorByIndex(index: number): string {
  return colors[index % colors.length];
}

function getSeriesByCurrency(data, currency): IChartSeries {
  const series = data.map((el) => (el[currency] && el[currency].value) || 0);

  return {
    name: currency,
    color: getColorByIndex(Object.values(Currency).indexOf(currency)),
    data: series,
  };
}

function getSummaryByCurrency(data, currency): IChartSummary {
  const summarySum = data.reduce((sum, el) => sum + ((el[currency] && el[currency].value) || 0), 0);

  return {
    name: currency,
    color: getColorByIndex(Object.values(Currency).indexOf(currency)),
    value: summarySum,
  };
}

export function trackedSalesDeserializer({ data }: any): ITrackedSalesData {
  const goals = getSeriesDataFromPeriod<number>(data.goals, 'goals');
  const goalsSummary = sumValueFromPeriod(data.goals, 'goals');

  const values = getSeriesDataFromPeriod(data.goals, 'value');

  const currencies = uniq([].concat(...values.map((el) => Object.keys(el))))
    .filter(Boolean)
    .sort((a, b) => (a > b ? 1 : -1));

  return {
    salesMade: {
      summary: [
        {
          name: 'sales',
          color: DEFAULT_SERIES_COLOR,
          value: goalsSummary,
        },
      ],
      labels: Object.keys(data.goals),
      series: [
        {
          name: 'sales',
          color: DEFAULT_SERIES_COLOR,
          data: goals,
        },
      ],
    },
    salesValue: {
      summary: currencies.map((currency) => getSummaryByCurrency(values, currency)),
      labels: Object.keys(data.goals),
      series: currencies.map((currency) => getSeriesByCurrency(values, currency)),
    },
  };
}
