import { type FC } from 'react';

import { navigate } from 'helpers/routing';
import { useEffectOnce } from 'hooks/use-effect-once';

/**
 * this handler takes care of manually rendered <a> elements that omit react routing handlers
 * otherwise we would reload the page on click
 */
function handleBodyClick(event: MouseEvent): void {
  // don't navigate when already navigating or event is handled another way
  const isAlreadyHandled = event.defaultPrevented;
  if (isAlreadyHandled) {
    return;
  }

  // check if <a> element was clicked
  const anchor = (event.target as HTMLElement)?.closest('a');
  if (anchor) {
    // legacy views use this manual "prevent navigation" mechanism
    const isNavigationBlockedManually = anchor.getAttribute('data-navigate') === '0';
    const hasDifferentOrigin = anchor.origin !== window.location.origin;
    const isOpeningInNewTab = event.ctrlKey || event.metaKey || event.shiftKey || anchor.target === '_blank';
    const isMailto = anchor.href.startsWith('mailto:');

    const preventNavigation = isNavigationBlockedManually || hasDifferentOrigin || isOpeningInNewTab || isMailto;

    if (!preventNavigation) {
      event.preventDefault();
      navigate(anchor.pathname + anchor.search + anchor.hash);
    }
  }
}

export const GlobalDOMHandlers: FC = ({ children }) => {
  useEffectOnce(() => {
    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  });

  return <>{children}</>;
};
