import type { UserGuidedTourType } from 'constants/user-guided-tour-type';
import type { KeyMap } from 'helpers/interface';
import type { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

/**
 * Don't put all the tooltips here. Only those who are manually managed (controlled)
 * and you can't provide a decent flag in a component's state.
 */
export enum TooltipType {
  AgentsGroups = 'AgentsGroups',
  ArchivesTagPicker = 'ArchivesTagPicker',
  ChatCannedResponses = 'ChatCannedResponses',
  TicketPrivacy = 'TicketPrivacy',
  TicketStatus = 'TicketStatus',
  HomeScreenExplore = 'HomeScreenExplore',
  OpenChat = 'OpenChat',
  RichGreetingEdit = 'RichGreetingEdit',
  MultipleGreetings = 'MultipleGreetings',
  CreateTargetedMessage = 'CreateTargetedMessage',
  CreateRoutingRule = 'CreateRoutingRule',
}

export interface ITooltipPayload {
  tooltip: TooltipType;
  savePropertyName?: AgentCustomPropertyName;
  tourId?: UserGuidedTourType;
}

export interface IShowDelayedTooltipPayload extends ITooltipPayload {
  delay: number;
}

export interface ITooltipsState {
  tooltipsVisible: KeyMap<boolean>;
}
