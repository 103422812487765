import { useCallback } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { type TriggerFn } from '@lexical/react/LexicalTypeaheadMenuPlugin';

type UseCustomTypeahead = {
  checkTriggerMatch: TriggerFn;
};

/* 
This is adjusted version of `useBasicTypeaheadTriggerMatch` from Lexical (see below) which accepts `_` and `-` as proper character in the trigger.
https://github.com/facebook/lexical/blob/b49170a526b6e91462914207023f7004b82cc972/packages/lexical-react/src/LexicalTypeaheadMenuPlugin.tsx#L154-L157
*/
const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\[\\]\\\\/!%\'"~=<>:;';

const useBasicTypeaheadTriggerMatch = (
  trigger: string,
  { minLength, maxLength }: { minLength: number; maxLength: number }
): TriggerFn => {
  return useCallback(
    (text: string) => {
      const validChars = '[^' + trigger + PUNCTUATION + '\\s/]';
      const TypeaheadTriggerRegex = new RegExp(
        '(^|\\s|\\()(' + '[' + trigger + ']' + '((?:' + validChars + '){0,' + maxLength + '})' + ')$'
      );
      const match = TypeaheadTriggerRegex.exec(text.trim());

      if (match !== null) {
        const maybeLeadingWhitespace = match[1];
        const matchingString = match[3];
        if (matchingString.length >= minLength) {
          return {
            leadOffset: match.index + maybeLeadingWhitespace.length,
            matchingString,
            replaceableString: match[2],
          };
        }
      }

      return null;
    },
    [maxLength, minLength, trigger]
  );
};

/*
  Custom hook wrapper for `useBasicTypeaheadTriggerMatch` which is used for 
  business logic to match `/` only at the beginning of the message box.
*/

const COMMAND_SUGGESTIONS_TRIGGER_REGEX = new RegExp(`^\/(?:[a-zA-Z0-9-_]+)?$`);

export const useCustomTypeahead = (): UseCustomTypeahead => {
  const [editor] = useLexicalComposerContext();

  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
    maxLength: 75,
  });

  const customCheck: TriggerFn = useCallback(() => {
    const currentMessageBoxValue = editor.getRootElement()?.innerText.trim();

    if (!currentMessageBoxValue || !COMMAND_SUGGESTIONS_TRIGGER_REGEX.test(currentMessageBoxValue)) {
      return null;
    }

    return checkForTriggerMatch(currentMessageBoxValue, editor);
  }, [checkForTriggerMatch, editor]);

  return {
    checkTriggerMatch: customCheck,
  };
};
