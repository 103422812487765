import { type SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { normalizeError } from 'services/connectivity/configuration-api/helpers';
import { propertiesClient } from 'services/connectivity/configuration-api/properties/client';
import {
  type ListGroupsPropertiesResponse,
  type UpdateGroupPropertiesResponse,
} from 'services/connectivity/configuration-api/properties/types';
import { type ConfigurationApiResponse } from 'services/connectivity/configuration-api/types';
import { type IActionWithPayload } from 'store/helper';
import { SettingsViewActionNames } from 'store/views/settings/actions';
import { getSelectedGroupId } from 'store/views/settings/selectors';

import { IntegrationGroupPropertiesActionNames, IntegrationGroupPropertiesActions } from './actions';
import { type IFetchGroupPropertiesPayload, type IUpdateGroupPropertiesPayload } from './interfaces';

function* fetchIntegrationGroupProperties(
  action: IActionWithPayload<string, IFetchGroupPropertiesPayload>
): SagaIterator {
  const { groupId } = action.payload;
  const { result, error }: ConfigurationApiResponse<ListGroupsPropertiesResponse> = yield call(
    propertiesClient.listGroups,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { group_ids: [Number(groupId)] }
  );

  if (result) {
    const properties = result.find(({ id }) => id === Number(groupId))?.properties || {};
    yield put(IntegrationGroupPropertiesActions.fetchSuccess({ groupId, properties }));
  } else {
    yield put(
      IntegrationGroupPropertiesActions.fetchFailure({
        error: normalizeError(error).message,
      })
    );
  }
}

function* updateIntegrationGroupProperties(
  action: IActionWithPayload<string, IUpdateGroupPropertiesPayload>
): SagaIterator {
  const { namespace, groupId, properties } = action.payload;
  const { result, error }: ConfigurationApiResponse<UpdateGroupPropertiesResponse> = yield call(
    propertiesClient.updateGroup,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { group_id: Number(groupId), properties: { [namespace]: properties } }
  );

  if (result) {
    yield put(IntegrationGroupPropertiesActions.updateSuccess({ groupId, properties, namespace }));
  } else {
    const normalizedError = normalizeError(error);
    yield put(IntegrationGroupPropertiesActions.updateFailure({ error: normalizedError.message }));
  }
}

function* fetchCurrentIntegrationGroupProperties(): SagaIterator {
  const groupId: ReturnType<typeof getSelectedGroupId> = yield select(getSelectedGroupId);
  yield put(IntegrationGroupPropertiesActions.fetch({ groupId }));
}

export function* integrationGroupPropertiesSagas(): SagaIterator {
  yield takeEvery(IntegrationGroupPropertiesActionNames.FETCH_REQUEST, fetchIntegrationGroupProperties);
  yield takeEvery(IntegrationGroupPropertiesActionNames.UPDATE_REQUEST, updateIntegrationGroupProperties);
  yield takeEvery(SettingsViewActionNames.SET_SELECTED_GROUP_ID, fetchCurrentIntegrationGroupProperties);
}
