import { type StartupData } from 'entries/base/interfaces/startup-data';
import { getFirstChatDate } from 'services/activity/website-last-activity';
import { updateFirstChatDate } from 'services/chat-widget';

import { initializeQueryClient } from './initialize-query-client';
import { initializeStore } from './initialize-store';

export function initializeApp(startupData: StartupData): void {
  initializeQueryClient(startupData.licenseProperties);
  initializeStore(startupData);

  updateFirstChatDate(getFirstChatDate());
}
