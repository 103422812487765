import type { BooleanNumericFlag, TernaryNumericFlag } from 'helpers/boolean';
import type { ThemeVariant, WidgetMinimizedTypeEnum } from 'interfaces/widget-state';

export enum GroupProperty {
  AddTagReminder = 'add_tag_reminder',
  ChatBoosters = 'chat_boosters',
  ChatWindowCustomMobileSettings = 'chat_window.custom_mobile_settings',
  ChatWindowDisableMinimized = 'chat_window.disable_minimized',
  ChatWindowDisableSounds = 'chat_window.disable_sounds',
  ChatWindowDisplayAvatar = 'chat_window.display_avatar',
  ChatWindowDisplayLogo = 'chat_window.display_logo',
  ChatWindowDisplayTranscriptButton = 'chat_window.display_transcript_button',
  ChatWindowHideOnInit = 'chat_window.hide_on_init',
  ChatWindowHideOnMobile = 'chat_window.hide_on_mobile',
  ChatWindowHideTrademark = 'chat_window.hide_trademark',
  ChatWindowLogoPath = 'chat_window.logo_path',
  ChatWindowMobileDisableMinimized = 'chat_window.mobile_disable_minimized',
  ChatWindowMobileHideOnInit = 'chat_window.mobile_hide_on_init',
  ChatWindowMobileOffsetX = 'chat_window.mobile_offset_x',
  ChatWindowMobileOffsetY = 'chat_window.mobile_offset_y',
  ChatWindowNewThemeVariant = 'chat_window.new_theme.variant',
  ChatWindowNewThemeName = 'chat_window.new_theme.name',
  ChatWindowOffsetX = 'chat_window.offset_x',
  ChatWindowOffsetY = 'chat_window.offset_y',
  ChatWindowThemeCTAColor = 'chat_window.new_theme.cta_color',
  ChatWindowThemeMinimized = 'chat_window.theme.minimized',
  EmbeddedChatHideWhenOffline = 'embedded_chat.hide_when_offline',
  FacebookUrl = 'facebook_url',
  Language = 'language',
  LeftMsgMails = 'left_msg_mails',
  LeftMsgToOps = 'left_msg_to_ops',
  PlusOneUrl = 'plusone_url',
  PreChatSurveyAfterGreeting = 'pre_chat_survey_after_greeting',
  QualityBadgesValue = 'quality_badges',
  QualityPageVisible = 'quality_page_visible',
  RateMeEnabled = 'rate_me.enabled',
  RoutingAgentTimeoutInactivityForm = 'routing.agent_timeout_inactivity_form',
  RoutingChatTimeout = 'routing.chat_timeout',
  RoutingManualChatSelection = 'routing.manual_chat_selection',
  TicketsEmailChatLink = 'tickets.email_chat_link',
  TicketsEmailNewTicket = 'tickets.email_new_ticket',
  TicketsEmailRating = 'tickets.email_rating',
  TwitterUrl = 'twitter_url',
}

export interface IGroupProperties {
  [GroupProperty.QualityPageVisible]: string;
  [GroupProperty.QualityBadgesValue]: string;
  [GroupProperty.AddTagReminder]: string;
  [GroupProperty.ChatBoosters]: string;
  [GroupProperty.ChatWindowCustomMobileSettings]: BooleanNumericFlag;
  [GroupProperty.ChatWindowDisableMinimized]: BooleanNumericFlag;
  [GroupProperty.ChatWindowDisableSounds]: BooleanNumericFlag;
  [GroupProperty.ChatWindowDisplayAvatar]: BooleanNumericFlag;
  [GroupProperty.ChatWindowDisplayLogo]: BooleanNumericFlag;
  [GroupProperty.ChatWindowDisplayTranscriptButton]: BooleanNumericFlag;
  [GroupProperty.ChatWindowHideOnInit]: BooleanNumericFlag;
  [GroupProperty.ChatWindowHideOnMobile]: BooleanNumericFlag;
  [GroupProperty.ChatWindowHideTrademark]: BooleanNumericFlag;
  [GroupProperty.ChatWindowLogoPath]: string;
  [GroupProperty.ChatWindowMobileDisableMinimized]: BooleanNumericFlag;
  [GroupProperty.ChatWindowMobileHideOnInit]: BooleanNumericFlag;
  [GroupProperty.ChatWindowMobileOffsetX]: string;
  [GroupProperty.ChatWindowMobileOffsetY]: string;
  [GroupProperty.ChatWindowNewThemeVariant]: ThemeVariant;
  [GroupProperty.ChatWindowOffsetX]: string;
  [GroupProperty.ChatWindowOffsetY]: string;
  [GroupProperty.ChatWindowThemeMinimized]: WidgetMinimizedTypeEnum;
  [GroupProperty.ChatWindowThemeCTAColor]: string;
  [GroupProperty.FacebookUrl]: string;
  [GroupProperty.Language]: string;
  [GroupProperty.PlusOneUrl]: string;
  [GroupProperty.RateMeEnabled]: BooleanNumericFlag;
  [GroupProperty.RoutingManualChatSelection]: TernaryNumericFlag;
  [GroupProperty.RoutingChatTimeout]: string;
  [GroupProperty.RoutingAgentTimeoutInactivityForm]: string;
  [GroupProperty.TicketsEmailChatLink]: BooleanNumericFlag;
  [GroupProperty.TicketsEmailNewTicket]: BooleanNumericFlag;
  [GroupProperty.TicketsEmailRating]: BooleanNumericFlag;
  [GroupProperty.TwitterUrl]: string;
  [GroupProperty.EmbeddedChatHideWhenOffline]: BooleanNumericFlag;
  [GroupProperty.LeftMsgMails]: string;
  [GroupProperty.PreChatSurveyAfterGreeting]: BooleanNumericFlag;
}
