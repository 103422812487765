import { getTime } from 'date-fns';

import { ChatEventStatus } from 'constants/chat-event-status';
import { ChatEventType } from 'constants/chat-event-type';
import { SurveyType } from 'constants/survey-type';
import { UserType } from 'constants/user-type';
import { parseSurveyFieldValue, mergeSurveyResponses } from 'helpers/filled-form';
import { type ISurveyResponse } from 'interfaces/entities/archive';
import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { type ChatEventAuthor } from 'store/entities/chats/interfaces';
import { getThread } from 'store/entities/chats/selectors';

export function handleIncomingPostChat(payload: any, store: IAppState): void {
  const {
    conference_id: threadId,
    survey: { survey_id: surverId, fields },
  } = payload;
  const thread = getThread(store.getState(), threadId);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const surveyResponses: ISurveyResponse[] = fields
    .map((field: any) => ({
      answer: parseSurveyFieldValue(field),
      question: field.label,
      id: field.id,
    }))
    .reduce((acc, value) => mergeSurveyResponses(acc, value), []);

  if (thread) {
    const survey = {
      id: surverId,
      responses: surveyResponses,
      status: ChatEventStatus.Read,
      text: '',
      timestampInMs: getTime(new Date()),
      authorId: thread.customerId,
      authorType: UserType.Customer as ChatEventAuthor,
      type: ChatEventType.FilledForm,
      surveyType: SurveyType.Post,
    };

    store.dispatch(
      ChatsEntitiesActions.newSurvey({
        threadId,
        survey,
      })
    );
  }
}
