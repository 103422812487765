// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { type SagaIterator } from 'redux-saga';

import { AgentType } from 'constants/agent-type';
import { ReportType } from 'constants/reports/report-type';
import { type KeyMap } from 'helpers/interface';
import type { RequestResult } from 'interfaces/api/client';
import type { ISerializedFilters, IAgentsPerformance } from 'interfaces/reports';
import type { ReportResponseData, ReportResponse } from 'interfaces/reports/api-v3';
import { ApiManager } from 'services/api/api-manager';
import { AppStateProvider } from 'services/app-state-provider';
import { type IActionWithPayload } from 'store/helper';
import { getFilterGroup } from 'store/views/reports/selectors';
import { getAgentsAndBots } from 'store/views/team/computed';

import { staticReportFilters } from '../constants/static-filters';
import {
  type IFetchReportPayload,
  type IReportRequest,
  ReportRequestName,
  RequestVersion,
  type IFetchReportOptions,
} from '../interfaces';

import { makeFetchingSaga, defaultResponseResolver } from './helpers';

const agentPerformanceInitialValue: IAgentsPerformance = {
  accepting_chats_time: 0,
  chats_count: 0,
  chats_rated_bad: 0,
  chats_rated_good: 0,
  chatting_time: 0,
  first_response_chats_count: 0,
  first_response_time: 0,
  logged_in_time: 0,
  max_chats_count: 0,
  not_accepting_chats_time: 0,
  is_bot: false,
};

const responseResolver = (
  responses: RequestResult<KeyMap | ReportResponse<KeyMap>>[],
  requests: IReportRequest[],
  filterSetIndex: number,
  filtersSets: ISerializedFilters[]
): ReportResponseData<IAgentsPerformance> => {
  const agents = AppStateProvider.selectFromStore(getAgentsAndBots);
  const groupFilters = AppStateProvider.selectFromStore(getFilterGroup);

  const resolver: ReportResponseData<IAgentsPerformance> = defaultResponseResolver<IAgentsPerformance>(
    responses,
    requests,
    filterSetIndex,
    filtersSets
  );

  const requestName = requests[0].name;

  if (!requestName) {
    return resolver;
  }

  resolver[requestName].records = agents
    .filter((agent) => {
      if (!groupFilters?.groups?.length) {
        return true;
      }

      return agent.groups.some((group) => groupFilters.groups.includes(group?.toString()));
    })
    .reduce((acc, agent) => {
      const agentRecord = resolver[requestName].records[agent.login];
      acc[agent.login] = {
        ...agentPerformanceInitialValue,
        max_chats_count: agent?.maxChatsCount,
        is_bot: agent.type === AgentType.Bot,
        ...(agentRecord || {}),
      };

      return acc;
    }, {});

  return resolver;
};

export function* fetchAgentsPerformance(action: IActionWithPayload<string, IFetchReportPayload>): SagaIterator {
  const requests: IReportRequest[] = [
    {
      version: RequestVersion.V3,
      name: ReportRequestName.Performance,
      interface: ApiManager.reportApiV3.fetchAgentsPerformance,
      extendedFilters: staticReportFilters[ReportType.AgentsPerformance],
    },
  ];

  const options: IFetchReportOptions = {
    responseResolver,
  };

  yield makeFetchingSaga(ReportType.AgentsPerformance, action, requests, options);
}
