import { ChatEventStatus } from 'constants/chat-event-status';
import { toKeyMap } from 'helpers/array';
import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadEvents } from 'store/entities/chats/selectors';

import { getShouldEventBeMarkedAsRead } from './helpers/message-seen';

/**
 * Handle update of seen events. The user has read events he has not seen before
 *
 * This handler involves taking care of following scenarios:
 * 1. Update events status to `ChatEventStatus.Read`
 *
 * @param payload.conference_id ID of thread
 * @param payload.message ID of event that was sent
 * @param payload.message.agent_id AgentID which was author of message that has been seen
 * @param payload.message.id MessageID which has been seen - it's eventID
 * @param store store
 */
export function handleMessageSeen(payload: any, store: IAppState): void {
  const { conference_id: threadId, message } = payload;
  const { id: eventId } = message;

  const state = store.getState();

  const events = getThreadEvents(state, threadId);
  const eventsMarkedAsRead = Object.values(events)
    .filter((event) => getShouldEventBeMarkedAsRead(event, events[eventId]))
    .map((event) => ({ ...event, status: ChatEventStatus.Read }));
  const newEvents = toKeyMap(eventsMarkedAsRead, 'id');

  /**
   * 1. Update events status to `ChatEventStatus.Read`
   */
  if (eventsMarkedAsRead.length) {
    store.dispatch(
      ChatsEntitiesActions.updateEvents({
        threadId,
        events: {
          ...newEvents,
        },
      })
    );
  }
}
