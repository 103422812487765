// @ts-strict-ignore
import { Chart } from '@livechat/design-system-icons';

import { type SurveyType } from 'constants/filters/survey-type-filter';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

import { items } from './survey-type-filter';

export const surveyTypeConfig: FilterConfig = {
  title: 'Form',
  multiple: false,
  icon: Chart,
  getFilterLabelValues: (filterValues: SurveyType | null): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const selectedItem = items.find((item) => item.props.value === filterValues);

    return [
      {
        key: filterValues,
        label: selectedItem && selectedItem.props.label,
      },
    ];
  },
};
