// @ts-strict-ignore
import { NotificationStatus } from 'constants/browser-notifications';

import { isDesktopAppDetected } from './desktop-app/is-detected';
import { shouldHideNotificationsTopBar } from './device';

export const deviceSupportsNotifications = (): boolean =>
  (!!window.Notification || isDesktopAppDetected()) && !shouldHideNotificationsTopBar();

export const getNotificationPermissionStatus = (): NotificationPermission => {
  if (isDesktopAppDetected()) {
    return NotificationStatus.Enabled;
  }
  if (window.Notification) {
    return window.Notification.permission;
  }

  return null;
};

export const notificationsEnabled = (): boolean => getNotificationPermissionStatus() === NotificationStatus.Enabled;
