import type { FC } from 'react';

import manageSegmentsImage from 'assets/img/user-guided-tours/custom-segments/manage_segments@2x.png';
import { TourStep } from 'components/tours/TourStep';

export const ButtonStep: FC = () => (
  <TourStep
    title="Work more efficiently with segments"
    message="Do you often use the same filters? Use segments instead. Segments allow you to automatically group customers and focus on those that are most important to your business."
  />
);

export const CustomSegmentsStep: FC = () => (
  <TourStep
    title="Easy access to target groups"
    message="Customer segments are available at the top of the list. Quickly see your key groups of customers."
  />
);

export const ManageSegmentsStep: FC = () => (
  <TourStep
    title="Quickly adjust the segment list"
    message="Make sure segments are displayed in an efficient way for you. Change segment order and edit names or delete those that are no longer needed."
    image={<img src={manageSegmentsImage} alt="Manage segments" />}
  />
);
