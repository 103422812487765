import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { redirectToNetworkIssuesError } from 'helpers/routing';
import { getReconnector, isReconnectorInitialized } from 'services/connectivity/reconnector/service';
import { hideGlobalLoader } from 'services/global-loader';
import { renderGlobalModals } from 'services/global-modals';
import { isMainLayoutRendered, renderMainLayout } from 'services/render-main-layout';

const log = debug(DebugLogsNamespace.AppServerConnection);

export function handleNetworkIssuesError(error: unknown): void {
  log('handleNetworkIssuesError', error);

  if (isReconnectorInitialized()) {
    getReconnector().loginFailed();
  }

  if (isMainLayoutRendered()) {
    log(
      'handleNetworkIssuesError main layout is rendered - any reconnections should be handled by reconnector or connectivity status client',
    );

    return;
  }

  renderMainLayout();
  renderGlobalModals();
  hideGlobalLoader();

  log('Redirecting to network issues page', { error });
  redirectToNetworkIssuesError(error);
}
