export enum TicketStatusFilter {
  Open = 'open',
  Pending = 'pending',
  Solved = 'solved',
  Spam = 'spam',
}

export const TicketStatusFilterFormatedValues = {
  [TicketStatusFilter.Open]: 'Open',
  [TicketStatusFilter.Pending]: 'Pending',
  [TicketStatusFilter.Solved]: 'Solved',
  [TicketStatusFilter.Spam]: 'Spam',
};
