/* eslint-disable @typescript-eslint/naming-convention */
import { PlanType } from 'constants/plan-type';
import { getConfig } from 'helpers/config';
import { preparePlanCode } from 'helpers/subscription';
import { getSubscriptionPlanName } from 'helpers/subscription-plans';
import { BillingCycleType } from 'interfaces/subscription';
import { RecurlyCouponType, type IRecurlyCoupon } from 'store/entities/recurly/interfaces';

declare const window: Window & {
  // Missing coupon method definition in recurly
  recurly: {
    coupon: (params: { coupon: string; plans: string[] }, callback: (error, response: RecurlyCoupon) => void) => void;
  };
};

export interface IRecurlyValidationError {
  name: string;
  code: string;
  message: string;
  fields: string[];
}

/**
 * Service used to call methods from recurly.js library
 * recurly.js is loaded using `useScript` hook in subscription router
 */
export class RecurlyService {
  /**
   * Loads the coupon details from recurly.
   * Used to check if a given coupon is applicable to current plan and to calculate discounts.
   * @param {string} coupon - code of the coupon
   * @returns given coupon details or error (eg. not-found, expired)
   */
  static checkCoupon(coupon: string): Promise<IRecurlyCoupon> {
    const { recurly } = window;
    const planCodes = [
      preparePlanCode(PlanType.Starter, BillingCycleType.Monthly),
      preparePlanCode(PlanType.Starter, BillingCycleType.Annually12),
      preparePlanCode(PlanType.Starter, BillingCycleType.Annually24),
      preparePlanCode(PlanType.Starter, BillingCycleType.Annually36),
      preparePlanCode(PlanType.Team, BillingCycleType.Monthly),
      preparePlanCode(PlanType.Team, BillingCycleType.Annually12),
      preparePlanCode(PlanType.Team, BillingCycleType.Annually24),
      preparePlanCode(PlanType.Team, BillingCycleType.Annually36),
      preparePlanCode(PlanType.Business, BillingCycleType.Monthly),
      preparePlanCode(PlanType.Business, BillingCycleType.Annually12),
      preparePlanCode(PlanType.Business, BillingCycleType.Annually24),
      preparePlanCode(PlanType.Business, BillingCycleType.Annually36),
      preparePlanCode(PlanType.EnterprisePlus, BillingCycleType.Monthly),
      preparePlanCode(PlanType.EnterprisePlus, BillingCycleType.Annually12),
    ];

    recurly.configure(getConfig().recurlyKey);

    return new Promise((resolve, reject) => {
      recurly.coupon(
        {
          coupon,
          plans: planCodes,
        },
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            const {
              discount,
              duration,
              temporal_amount,
              temporal_unit,
              applies_to_all_plans,
              redemption_resource,
              plans,
            } = response;
            const discountValue: number =
              discount.type === RecurlyCouponType.Dollars ? discount.amount.USD : discount.rate * 100;
            const result = {
              exists: true,
              discount: {
                type: discount.type,
                value: discountValue,
                suffixValue: `${discountValue}${discount.type === RecurlyCouponType.Dollars ? '$' : '%'}`,
              },
              duration,
              temporal_amount,
              temporal_unit,
              redemptionResource: redemption_resource,
              appliesToAllPlans: applies_to_all_plans,
              plans,
              plansNames: [
                ...new Set(plans.map((plan) => getSubscriptionPlanName(plan.replace(/\d+m$/, '') as PlanType))),
              ],
            } as IRecurlyCoupon;

            resolve(result);
          }
        }
      );
    });
  }
}
