// @ts-strict-ignore
import { createSelector } from 'reselect';

import { MAX_DISPLAYED_METRICS_COUNT } from 'constants/insights';
import { NEGATIVE_CHANGE_HINTS, POSITIVE_CHANGE_HINTS } from 'constants/insights/hints';
import { getUnreadHintsCount } from 'helpers/insights';
import { calcPercentageDifference } from 'helpers/numbers';

import { insightsMetricsKeyToTypeMap, INSIGHTS_METRICS_ORDER } from './constants';
import { getAreInsightsHintsEnabled, getTrend, isMetricValueMissing } from './helpers/helpers';
import { type IInsightsMetricDetails, type IInsightsMetrics } from './interfaces';
import { getHasMinimumInsightsData, getInsightsAcknowledgedHints, getInsightsMetrics } from './selectors';

const EMPTY_ARRAY: IInsightsMetricDetails[] = [];

export const getOrderedInsightsMetricsData = createSelector(
  getInsightsMetrics,
  getHasMinimumInsightsData,
  (insightsMetrics, hasMinimumData): IInsightsMetricDetails[] => {
    if (!insightsMetrics || !hasMinimumData) {
      return EMPTY_ARRAY;
    }

    const metricsData: IInsightsMetricDetails[] = [];
    const metricsKeys = Object.keys(insightsMetrics);

    const lastWeekTotalChats = insightsMetrics.chatsCount.newValue;

    metricsKeys.forEach((key: keyof IInsightsMetrics) => {
      const metricData = insightsMetrics[key];
      const isMissingData = isMetricValueMissing(metricData.newValue) || isMetricValueMissing(metricData.oldValue);

      if (isMissingData) {
        // metrics with missing data are skipped
        return;
      }

      const metricType = insightsMetricsKeyToTypeMap[key];
      const metricDiffPercentage = calcPercentageDifference(metricData.newValue, metricData.oldValue);
      const areHintsEnabled = getAreInsightsHintsEnabled(metricType, metricDiffPercentage, lastWeekTotalChats);

      const metricDiff = metricData.newValue - metricData.oldValue;
      const trend = getTrend(metricType, metricDiff);

      let hintsToShow = [];

      if (areHintsEnabled) {
        if (trend === 'positive') {
          hintsToShow = POSITIVE_CHANGE_HINTS[metricType];
        }

        if (trend === 'negative') {
          hintsToShow = NEGATIVE_CHANGE_HINTS[metricType];
        }
      }

      if (metricType) {
        metricsData.push({
          type: metricType,
          newValue: metricData.newValue,
          oldValue: metricData.oldValue,
          hintsToShow,
          trend,
        });
      }
    });

    // sort metrics by order
    const metricsSortedByOrder = [...metricsData].sort(
      (a, b) => INSIGHTS_METRICS_ORDER[a.type] - INSIGHTS_METRICS_ORDER[b.type]
    );

    // metrics with difference should be displayed before metrics with NO difference
    const metricsWithDifferenceData = metricsSortedByOrder.filter(
      (metricData) => metricData.newValue - metricData.oldValue !== 0
    );
    const metricsWithNoDifferenceData = metricsSortedByOrder.filter(
      (metricData) => metricData.newValue - metricData.oldValue === 0
    );

    return metricsWithDifferenceData.concat(metricsWithNoDifferenceData);
  }
);

export const getInsightsDetails = createSelector(
  getOrderedInsightsMetricsData,
  getInsightsAcknowledgedHints,
  (metricsData, acknowledgedHints): IInsightsMetricDetails[] => {
    return metricsData
      .map((metricData) => ({
        ...metricData,
        unreadHintsCount: getUnreadHintsCount(metricData.hintsToShow, acknowledgedHints),
      }))
      .slice(0, MAX_DISPLAYED_METRICS_COUNT);
  }
);
