import { type SagaIterator } from 'redux-saga';
import { select, delay, put, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { RoutingActionsEnum } from 'store/features/routing/actions';

import { getShouldShowAppInterviewModal, getAppForInterviewModal } from './selectors';

export function* showAppInterviewModalSaga(): SagaIterator {
  yield delay(45 * 1000); // 45 seconds

  const shouldShowModal = yield select(getShouldShowAppInterviewModal);

  if (!shouldShowModal) {
    return;
  }

  const appInterview = yield select(getAppForInterviewModal);

  if (!appInterview) {
    return;
  }

  yield put(GlobalModalActions.showModal(GlobalModal.AppInterview));
}

export function* appInterviewSagas(): SagaIterator {
  yield takeEvery(RoutingActionsEnum.SET_CURRENT_SECTION, showAppInterviewModalSaga);
}
