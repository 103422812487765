import { type ChangeEvent, useCallback, type FC } from 'react';

import { cx } from '@emotion/css';
import { MoreHoriz } from '@livechat/design-system-icons';
import { Button, DesignToken, Icon, Popover, Text, ThemeClassName } from '@livechat/design-system-react-components';
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { AppsEvent, AppsSection } from 'constants/apps/events';
import { reorder } from 'helpers/reorder';
import { withVar } from 'helpers/styles';
import { useInstalledOrderedApps } from 'routes/apps/hooks/use-installed-ordered-apps';
import { useSelectedVisibleApps } from 'routes/apps/hooks/use-selected-visible-apps';
import { trackAppsSectionEvent } from 'services/event-tracking';
import { ApplicationsActions } from 'store/features/applications/actions';

import { ApplicationRenderItem } from './ApplicationRenderItem';

import { droppableContainer, popover } from './styles';

export const AppsConfigurationList: FC = () => {
  const [, selectedAppsIds] = useSelectedVisibleApps();
  const installedOrderedApps = useInstalledOrderedApps();
  const dispatch = useDispatch();

  const handleAppSelected = useCallback(
    (event: ChangeEvent<HTMLInputElement>, changedAppId: string): void => {
      const { checked } = event.target;
      let updatedSelectedIds = [...selectedAppsIds];
      let updatedUnselectedIds = installedOrderedApps
        .map((app) => app.id)
        .filter((appId) => !updatedSelectedIds.includes(appId));

      if (checked) {
        updatedSelectedIds.push(changedAppId);

        updatedUnselectedIds = updatedUnselectedIds.filter((unselectedAppId) => unselectedAppId !== changedAppId);
      } else {
        updatedSelectedIds = updatedSelectedIds.filter((selectedApp) => selectedApp !== changedAppId);

        updatedUnselectedIds.push(changedAppId);
      }

      dispatch(
        ApplicationsActions.saveApplicationsSelected({
          selectedApplications: updatedSelectedIds,
          unselectedApplications: updatedUnselectedIds,
        }),
      );
    },
    [dispatch, installedOrderedApps, selectedAppsIds],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;
      const isDroppedOutsideTheList = !destination;
      const noMovement = destination?.index === source.index;

      if (isDroppedOutsideTheList || noMovement) {
        return;
      }

      const order = reorder(
        installedOrderedApps.map((app) => app.id),
        source.index,
        destination.index,
      );

      dispatch(ApplicationsActions.saveApplicationsOrder({ applicationsOrder: order }));
    },
    [dispatch, installedOrderedApps],
  );

  const getSortedApplicationsItems = useCallback(
    () =>
      installedOrderedApps.map((app, index) => {
        const { id } = app;

        return (
          <Draggable key={id} draggableId={id} index={index}>
            {(provided, snapshot, rubric) => (
              <ApplicationRenderItem
                applications={installedOrderedApps}
                handleAppSelected={handleAppSelected}
                provided={provided}
                snapshot={snapshot}
                rubric={rubric}
              />
            )}
          </Draggable>
        );
      }),
    [handleAppSelected, installedOrderedApps],
  );

  const handleConfigurationListOpen = useCallback(() => {
    trackAppsSectionEvent(AppsEvent.YourAppsConfigurationListOpened, AppsSection.SideNavigation, {});
  }, []);
  const handleConfigurationListClose = useCallback(() => {
    trackAppsSectionEvent(AppsEvent.YourAppsConfigurationListClosed, AppsSection.SideNavigation, {});
  }, []);

  return (
    <Popover
      triggerRenderer={
        <Button
          size="compact"
          kind="plain"
          icon={<Icon source={MoreHoriz} customColor={withVar(DesignToken.ContentLockedWhite)} />}
        />
      }
      placement="bottom-end"
      floatingStrategy="fixed"
      offsetSize={{
        crossAxis: 16,
      }}
      onOpen={handleConfigurationListOpen}
      onClose={handleConfigurationListClose}
      className={cx(popover, ThemeClassName.Dark)}
    >
      <Text size="xs">Decide which apps should always be visible.</Text>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId="your-apps-reorder"
          renderClone={(provided, snapshot, rubric) => (
            <ApplicationRenderItem
              applications={installedOrderedApps}
              handleAppSelected={handleAppSelected}
              provided={provided}
              snapshot={snapshot}
              rubric={rubric}
            />
          )}
        >
          {(droppableProvided) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={droppableContainer}>
              {getSortedApplicationsItems()}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Popover>
  );
};
