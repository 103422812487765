import { createAction, type ActionsUnion } from 'store/helper';

import {
  type IFetchLicensePropertiesSuccessPayload,
  type IFetchLicensePropertiesFailurePayload,
  type IUpdateLicensePropertiesPayload,
  type IUpdateLicensePropertiesDataPayload,
  type IFetchForNamespacePayload,
} from './interfaces';

export enum IntegrationLicensePropertiesActionNames {
  FETCH_REQUEST = 'ENTITIES/INTEGRATION_LICENSE_PROPERTIES/FETCH',
  FETCH_SUCCESS = 'ENTITIES/INTEGRATION_LICENSE_PROPERTIES/FETCH_SUCCESS',
  FETCH_FAILURE = 'ENTITIES/INTEGRATION_LICENSE_PROPERTIES/FETCH_FAILURE',
  UPDATE_DATA = 'ENTITIES/INTEGRATION_LICENSE_PROPERTIES/UPDATE_DATA',
  UPDATE_PROPERTIES = 'ENTITIES/INTEGRATION_LICENSE_PROPERTIES/UPDATE',
  FETCH_SINGLE_NAMESPACE_REQUEST = 'ENTITIES/INTEGRATION_LICENSE_PROPERTIES/FETCH_SINGLE_NAMESPACE',
  FETCH_SINGLE_NAMESPACE_SUCCESS = 'ENTITIES/INTEGRATION_LICENSE_PROPERTIES/FETCH_SINGLE_NAMESPACE_SUCCESS',
}

export const IntegrationLicensePropertiesActions = {
  fetch: () => createAction(IntegrationLicensePropertiesActionNames.FETCH_REQUEST),
  fetchSuccess: (payload: IFetchLicensePropertiesSuccessPayload) =>
    createAction(IntegrationLicensePropertiesActionNames.FETCH_SUCCESS, payload),
  updateData: (payload: IUpdateLicensePropertiesDataPayload) =>
    createAction(IntegrationLicensePropertiesActionNames.UPDATE_DATA, payload),
  fetchFailure: (error: IFetchLicensePropertiesFailurePayload) =>
    createAction(IntegrationLicensePropertiesActionNames.FETCH_FAILURE, error),
  fetchForNamespace: (payload: IFetchForNamespacePayload) =>
    createAction(IntegrationLicensePropertiesActionNames.FETCH_SINGLE_NAMESPACE_REQUEST, payload),
  fetchForNamespaceSuccess: (payload: IFetchLicensePropertiesSuccessPayload) =>
    createAction(IntegrationLicensePropertiesActionNames.FETCH_SINGLE_NAMESPACE_SUCCESS, payload),

  updateProperties: (payload: IUpdateLicensePropertiesPayload) =>
    createAction(IntegrationLicensePropertiesActionNames.UPDATE_PROPERTIES, payload),
};

export type IntegrationLicensePropertiesAction = ActionsUnion<typeof IntegrationLicensePropertiesActions>;
