// @ts-strict-ignore
import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns';
import { createSelector } from 'reselect';

import { BusinessModel } from 'constants/business-model';
import { Interval } from 'constants/date';
import { Feature, FEATURE_PLANS } from 'constants/feature';
import { STARTER_ONE_SEAT_LIMIT_DATE } from 'constants/limits';
import { PermissionScore } from 'constants/permission';
import { atLeastBusiness, atLeastTeam, PlanType } from 'constants/plan-type';
import { QueryKey } from 'constants/query-key';
import { Right } from 'constants/right';
import { getChatSentimentAppId } from 'helpers/applications';
import { anyToBoolean } from 'helpers/boolean';
import { dateDifference } from 'helpers/date';
import { type Nullable } from 'helpers/interface';
import { isNonProfit } from 'helpers/is-non-profit';
import { LicenseType, type ICurrentLicense, type PartnerType } from 'interfaces/entities/current-license';
import { type ExperimentRestrictions, type WithExperimentsState } from 'interfaces/experiment';
import { CoreProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { type PlatformNamespaceProperties } from 'services/connectivity/configuration-api/properties/types';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { InvitationMode } from 'store/entities/accounts/interfaces';
import { getInvitationMode, type WithAccountsState } from 'store/entities/accounts/selectors';
import { getAgentPermission, getLoggedInAgent, type WithAgentsState } from 'store/entities/agents/selectors';
import { ChatApplicationDisabledFeatures } from 'store/entities/applications/interfaces';
import { type IWithApplicationsState } from 'store/entities/applications/selectors';
import { getExperiment } from 'store/entities/experiments/selectors';
import { type IIntegrationLicensePropertiesState } from 'store/entities/integration-license-properties/interfaces';
import { getIsChatSummaryDisabled, getPaymentProvider } from 'store/entities/integration-license-properties/selectors';
import {
  getSubscription,
  getWasSubscriptionCreated,
  type IWithSubscriptionState,
} from 'store/entities/subscription/selectors';

import { UNKNOWN_LICENSE } from './constants';
import type { IRights, ISessionState } from './interfaces';

export interface IWithSessionState {
  features: {
    session: ISessionState;
  };
}

export interface IWithAgentsSessionState extends WithAgentsState, IWithSessionState {}

export function isGhostLogin(state: IWithSessionState): boolean {
  return state.features.session.isGhost;
}

export function hasLoggedInSuccessfully(state: IWithSessionState): boolean {
  return !!state.features.session.hasLoggedIn;
}

export function isAutoAwaySet(state: IWithSessionState): boolean {
  return !!state.features.session.isAutoAwaySet;
}

/* ---------- session.license ---------- */

export function getCurrentLicense(state: IWithSessionState): ICurrentLicense | null {
  return state.features.session.license ?? null;
}

export function getLicenseId(state: IWithSessionState): number | null {
  return state.features.session.license?.licenseId ?? null;
}

export function getLicenseIdForLinks(state: IWithSessionState): string {
  // license id may be undefined for ghost logins
  return getLicenseId(state)?.toString() ?? UNKNOWN_LICENSE;
}

export function getCreationTimestamp(state: IWithSessionState): number | null {
  const createdAt = state.features.session.license?.createdAt;
  if (createdAt) {
    return createdAt * 1000;
  }

  return null;
}

export function getExpirationTimestamp(state: IWithSessionState): number {
  return state.features.session.license?.expiresAt ?? null;
}

export function getSubscriptionType(state: IWithSessionState): LicenseType {
  return state.features.session.license?.type ?? null;
}

export function getPartnerType(state: IWithSessionState): PartnerType | null {
  return state.features.session.license?.partnerType ?? null;
}

export function getPlanType(state: IWithSessionState): PlanType | null {
  return state.features.session.license?.plan ?? null;
}

export function getBusinessModel(state: IWithSessionState): BusinessModel | null {
  return state.features.session.license?.businessModel ?? null;
}

export function getIsPPSBusinessModel(state: IWithSessionState): boolean {
  const businessModel = getBusinessModel(state);

  return businessModel === BusinessModel.PayPerSeat;
}

export function getIsPPABusinessModel(state: IWithSessionState): boolean {
  const businessModel = getBusinessModel(state);

  return businessModel === BusinessModel.PayPerAgent;
}

/* ---------- expiration ---------- */

export function getIsSubscriptionExpired(state: IWithSessionState): boolean {
  const expirationTimestamp = getExpirationTimestamp(state);

  return expirationTimestamp && isAfter(new Date(), new Date(expirationTimestamp * 1000));
}

/* ---------- trial ---------- */

export function getIsPaidLicense(state: IWithSessionState): boolean {
  return getSubscriptionType(state) === LicenseType.Paid;
}

export function getIsOnTrial(state: IWithSessionState): boolean {
  return getSubscriptionType(state) === LicenseType.Trial;
}

export function getTrialDay(state: IWithSessionState): number | null {
  const isTrial = getIsOnTrial(state);
  const now = new Date();
  const creationDate = new Date(getCreationTimestamp(state));
  const createdDaysAgo = differenceInCalendarDays(now, creationDate);

  return isTrial && createdDaysAgo < 14 ? createdDaysAgo : null;
}

export function getTrialDaysLeft(state: IWithSessionState): number {
  if (!getIsOnTrial(state)) {
    return 0;
  }

  return Math.max(0, dateDifference(new Date(), new Date(getExpirationTimestamp(state) * 1000), Interval.Day, true));
}

/* ---------- session.rights ---------- */

function getUserRights(state: IWithSessionState): IRights {
  return state.features.session.rights;
}

export function hasRight(state: IWithSessionState, right: Right): boolean {
  const userRights = getUserRights(state);

  return userRights?.[right] || false;
}

/* ---------- role permissions ---------- */

export function isNormalAgent(state: IWithAgentsSessionState): boolean {
  return !hasRight(state, Right.AgentsManagement);
}

export function isAtLeastAdmin(state: IWithAgentsSessionState): boolean {
  return hasRight(state, Right.AgentsManagement);
}

export function isOwner(state: IWithSessionState): boolean {
  return hasRight(state, Right.OrganizationManagement);
}

/* ---------- plan permissions ---------- */

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
function isNotStarter(state: IWithSessionState): boolean {
  return getPlanType(state) !== PlanType.Starter;
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function isAtLeastTeamPlan(state: IWithSessionState): boolean {
  return atLeastTeam.includes(getPlanType(state));
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function isAtLeastBusinessPlan(state: IWithSessionState): boolean {
  return atLeastBusiness.includes(getPlanType(state));
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function getIsEnterprisePlan(state: IWithSessionState): boolean {
  const planType = getPlanType(state);

  return planType === PlanType.EnterprisePlus;
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function getIsStarterPlan(state: IWithSessionState): boolean {
  const planType = getPlanType(state);

  return [PlanType.Starter].includes(planType);
}

export function getIsAfterStarterLimit(state: IWithSessionState): boolean {
  const licenseCreateDate = getCreationTimestamp(state);

  return isAfter(new Date(licenseCreateDate), STARTER_ONE_SEAT_LIMIT_DATE);
}

export function getIsOneSeatStarter(state: IWithSessionState): boolean {
  return getIsStarterPlan(state) && getIsAfterStarterLimit(state);
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function getCanUseFeature(state: IWithSessionState, feature: Feature): boolean {
  const planType = getPlanType(state);
  const featurePlans: PlanType[] = FEATURE_PLANS[feature];

  return featurePlans?.includes(planType);
}

/* ---------- page access restrictions ---------- */

export function fulfillsExperimentRestrictions(
  state: IWithSessionState & WithExperimentsState,
  experiment?: ExperimentRestrictions,
): boolean {
  if (!experiment) {
    return true;
  }

  const { name, in: isIn, orIsCreatedAfter, andIsCreatedBefore } = experiment;

  const isExperimentActive = Boolean(getExperiment(state, name));
  const isInExperiment = isIn ? isExperimentActive : !isExperimentActive;
  const creationDate = new Date(getCreationTimestamp(state));

  if (orIsCreatedAfter) {
    return isInExperiment || !isBefore(creationDate, orIsCreatedAfter);
  }

  if (andIsCreatedBefore) {
    return isInExperiment && isBefore(creationDate, andIsCreatedBefore);
  }

  return isInExperiment;
}

/* ---------- rights agents ---------- */

export function getCanSendInvitations(state: IWithSessionState & WithAccountsState): boolean {
  const invitationMode = getInvitationMode(state);

  return hasRight(state, Right.InvitationsManagement) || invitationMode === InvitationMode.Lax;
}

export function getCanManageInvitations(state: IWithSessionState): boolean {
  return hasRight(state, Right.InvitationsManagement);
}

export function getCanManageAgents(state: IWithSessionState): boolean {
  return hasRight(state, Right.AgentsManagement);
}

export function isMe(state: IWithAgentsSessionState, login: string): boolean {
  const agent = getLoggedInAgent(state);

  return agent.login === login;
}

export function getCanChangePassword(state: IWithAgentsSessionState, loginToChange: string): boolean {
  return (
    (isMe(state, loginToChange) && hasRight(state, Right.MyPasswordChange)) || hasRight(state, Right.PasswordsChange)
  );
}

export function getCanManageAgentSessions(state: IWithAgentsSessionState): boolean {
  return hasRight(state, Right.AgentSessionsManagement);
}

/* ---------- rights tags ---------- */

export function getCanAccessTags(state: IWithSessionState): boolean {
  return hasRight(state, Right.TagsAccess);
}

export function getCanManageTags(state: IWithSessionState): boolean {
  return hasRight(state, Right.TagsManagement);
}

export function getCanUseTags(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.Tags);
}

export const getTagsUsageAllowed = isNotStarter;

/* ---------- rights canned responses ---------- */

export function getCanAccessCannedResponses(state: IWithSessionState): boolean {
  return hasRight(state, Right.CannedResponsesAccess);
}

export function getArePrivateCannedResponsesAvailable(state: IWithSessionState): boolean {
  return isAtLeastTeamPlan(state);
}

export function getAreSuggestedResponsesAvailable(state: IWithSessionState): boolean {
  return isAtLeastTeamPlan(state);
}

export function getAreAutotagsAvailable(state: IWithSessionState): boolean {
  return isAtLeastTeamPlan(state);
}

/* ---------- rights reports ---------- */

export function getCanAccessReports(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.AccessReports);
}

export function getCanUseEmailFollowUp(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.AllowEmailFollowUp);
}

/* ---------- rights reports ---------- */

export function getCanExportReports(state: IWithSessionState): boolean {
  return hasRight(state, Right.ExportReportsAccess);
}

export function getCanUseBenchmark(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.Benchmark);
}

/* ---------- rights subscription ---------- */

export const getCanFetchSubscription = (state: IWithAgentsSessionState): boolean => {
  return hasRight(state, Right.SubscriptionFetching);
};

export const getCanManageSubscription = (state: IWithAgentsSessionState): boolean => {
  return hasRight(state, Right.SubscriptionManagement);
};

const getHasRightToCancel = (state: IWithAgentsSessionState): boolean => {
  return hasRight(state, Right.CancelLicenseAccess);
};

export const getCanCancelLicense = createSelector(
  [getHasRightToCancel, getPaymentProvider, getWasSubscriptionCreated, getIsSubscriptionExpired],
  (canManageSubscription, paymentProvider, wasSubscriptionCreated, isSubscriptionExpired) =>
    !paymentProvider && canManageSubscription && wasSubscriptionCreated && !isSubscriptionExpired,
);

export const getCanAccessInvoices = (state: IWithAgentsSessionState): boolean => {
  return hasRight(state, Right.InvoicesAccess);
};

export const getCanSeeSubscriptionMenuItem = (state: IWithAgentsSessionState): boolean => {
  return (
    !isNonProfit() &&
    (hasRight(state, Right.SubscriptionManagement) || hasRight(state, Right.SubscriptionPotentialAccess))
  );
};

export const getCanManageCompanyDetails = (state: IWithAgentsSessionState): boolean => {
  return hasRight(state, Right.OrganizationManagement);
};

/* ---------- rights agents ---------- */

// check if it's possible to edit agents data
export function getCanEditAgent(state: IWithAgentsSessionState, loginToEdit: string): boolean {
  const me = getLoggedInAgent(state);
  const canManageAgents = getCanManageAgents(state);
  const agentPermission = getAgentPermission(state, loginToEdit);
  const hasHigherPermission = PermissionScore[me.permission] >= PermissionScore[agentPermission];

  return me.login === loginToEdit || (canManageAgents && hasHigherPermission);
}

export function getCanUseInviteLink(state: IWithSessionState): boolean {
  return hasRight(state, Right.InviteLinkUsage);
}

/* ---------- rights bots ---------- */

export const getCanManageBots = (state: IWithSessionState): boolean => {
  return hasRight(state, Right.BotAgentsManagement);
};
/* ---------- rights groups ---------- */

export const getGroupsUsageAllowed = isNotStarter;

export function getCanUseGroups(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.Groups);
}

function canManageGroups(state: IWithSessionState): boolean {
  return hasRight(state, Right.GroupsManagement) && getCanUseGroups(state);
}

export const getCanSeeOtherAgentsGroups = canManageGroups;

export function getCanManageAllGroups(state: IWithSessionState): boolean {
  return hasRight(state, Right.AllGroupsManagement);
}

/* ---------- rights greetings ---------- */

export const canAddMultipleGreetings = isNotStarter;

export const getCanAddGreetingsWithNonMessageType = isNotStarter;

export function getCanManageGreetings(state: IWithSessionState): boolean {
  return hasRight(state, Right.GreetingsManagement);
}

/* ---------- rights tickets ---------- */

export function getCanManageTickets(state: IWithSessionState): boolean {
  return hasRight(state, Right.TicketsManagement);
}

/* ---------- rights home ---------- */

export const getCanManageEmailSettings = (state: IWithSessionState): boolean =>
  hasRight(state, Right.TicketsManagement);

export const getCanManageCustomization = (state: IWithSessionState): boolean =>
  hasRight(state, Right.CustomizationManagement);

/* ---------- rights status reminder ---------- */

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function getCanUseStatusReminder(state: IWithSessionState): boolean {
  const planType = getPlanType(state);
  const plans = [PlanType.Starter, PlanType.Team];

  return plans.includes(planType) || getIsOnTrial(state);
}

/* ---------- rights customers ---------- */

export const getCustomSegmentsUsageAllowed = isNotStarter;

/* ---------- rights chats ---------- */
export const getCanUseAttachments = (
  state: IWithSessionState & IWithApplicationsState,
  chatClientId: string,
): boolean => {
  const app = state.entities.applications.installed.channels.byClientIds[chatClientId];
  const getAreAttachmentsDisabledForChatApplication =
    app && app.disabledFeatures && app.disabledFeatures.includes(ChatApplicationDisabledFeatures.Attachments);

  return isNotStarter(state) && !getAreAttachmentsDisabledForChatApplication;
};

export function getCanUsePrivateMode(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.PrivateMode);
}

export function getCanTakeOverChat(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.ChatTakeover);
}

/* ---------- rights archives ---------- */

export function getCanAccessArchivesFullHistory(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.ArchivesFullHistoryAccess);
}

/* ---------- rights chat widget ---------- */

export function getCanFullyManageChatWidget(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.ChatWidgetAdvancedManagement);
}

export function getCanManageEyeCatcher(state: IWithSessionState): boolean {
  return hasRight(state, Right.EyeCatcherManagement);
}

/* ---------- rights goals ---------- */

export function getCanManageGoals(state: IWithSessionState): boolean {
  return hasRight(state, Right.GoalsManagement);
}

export function getCanUseGoals(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.Goals);
}

/* ---------- rights goals ---------- */

export function getCanManageSalesTracker(state: IWithSessionState): boolean {
  return hasRight(state, Right.SalesTrackerManagement);
}

export function getCanUseSalesTracker(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.SalesTracker);
}

/* ---------- rights chat buttons ---------- */

export function getCanManageChatButtons(state: IWithSessionState): boolean {
  return hasRight(state, Right.ChatButtonsManagement);
}

export function getCanUseChatButtons(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.ChatButtons);
}

/* ---------- rights languages ---------- */

export function getCanManageLanguages(state: IWithSessionState): boolean {
  return hasRight(state, Right.LanguagesManagement);
}

/* ---------- url rules ---------- */

export function getCanManageAutoAccessRules(state: IWithSessionState): boolean {
  return hasRight(state, Right.AutoAccessRulesManagement);
}

/* ---------- rights chat routing ---------- */

export function getCanManageChatRouting(state: IWithSessionState): boolean {
  return hasRight(state, Right.ChatRoutingManagement);
}

/* ---------- rights channels ---------- */

export function getCanManageEmailForwarding(state: IWithSessionState): boolean {
  return hasRight(state, Right.EmailForwardManagement);
}

export function getCanManageFacebookMessenger(state: IWithSessionState): boolean {
  return hasRight(state, Right.FacebookMessengerManagement);
}

/* ---------- rights chat forms ---------- */

export function getCanManageChatForms(state: IWithSessionState): boolean {
  return hasRight(state, Right.ChatFormsManagement);
}

/* ---------- rights chat boosters ---------- */

export function getCanManageChatBoosters(state: IWithSessionState): boolean {
  return hasRight(state, Right.ChatBoostersAccess);
}

/* ---------- rights chat surveys ---------- */

export function getCanManageChatSurveys(state: IWithSessionState): boolean {
  return hasRight(state, Right.ChatSurveyManagement);
}

/* ---------- rights marketplace settings ---------- */

export function getCanManageApplications(state: IWithSessionState): boolean {
  return hasRight(state, Right.ApplicationsManagement);
}

export function getCanManageApplicationPayments(state: IWithSessionState): boolean {
  return hasRight(state, Right.BillingManagement);
}

export function getCanManageQualityPage(state: IWithSessionState): boolean {
  return hasRight(state, Right.QualityPageManagement);
}

export function getCanActivateProduct(state: IWithSessionState): boolean {
  return hasRight(state, Right.OrganizationProductActivation);
}

export function getCanManageWebhooks(state: IWithSessionState): boolean {
  return hasRight(state, Right.WebhooksManagement);
}

/* ---------- rights banned visitors ---------- */

export function getCanManageBannedVisitors(state: IWithSessionState): boolean {
  return hasRight(state, Right.BanningVisitorsManagement);
}

/* ---------- rights auto access rules ---------- */
export const getCanUseAutoAccessRules = isNotStarter;

/* ---------- rights messaging mode ---------- */
export function getCanManageMessagingMode(state: IWithSessionState): boolean {
  return hasRight(state, Right.MessagingModeManagement);
}

/* ---------- rights transcript forwarding ---------- */

export function getCanManageTranscriptForwarding(state: IWithSessionState): boolean {
  return hasRight(state, Right.TranscriptForwardingManagement);
}

/* ---------- rights file sharing ---------- */

export function getCanManageFileSharing(state: IWithSessionState): boolean {
  return hasRight(state, Right.FileSharingManagement);
}

export function getCanUseFileSharing(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.ChatFileSharing);
}

/* ---------- rights inactivity timeouts ---------- */

export function getCanManageInactivityTimeouts(state: IWithSessionState): boolean {
  return hasRight(state, Right.InactivityTimeoutsManagement);
}

export function getCanUseInactivityTimeouts(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.AgentsInactivityTimeouts);
}

/* ---------- rights email notifications ---------- */

export function getCanManageEmailNotifications(state: IWithSessionState): boolean {
  return hasRight(state, Right.EmailNotificationsManagement);
}

/* ---------- rights trusted domains ---------- */

export function getCanManageTrustedDomains(state: IWithSessionState): boolean {
  return hasRight(state, Right.TrustedDomainsManagement);
}

/* ---------- rights credit cards masking ---------- */

export function getCanManageCreditCardsMasking(state: IWithSessionState): boolean {
  return hasRight(state, Right.CreditCardMaskingManagement);
}

/* ---------- rights access restriction ---------- */

export function getCanManageAccessRestriction(state: IWithSessionState): boolean {
  return hasRight(state, Right.AccessRestrictionManagement);
}

export function getCanUseAccessRestriction(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.AgentsAccessRestriction);
}

/* ---------- insights ---------- */

export function getCanUseInsights(state: IWithSessionState): boolean {
  return getCanUseFeature(state, Feature.Insights) && hasRight(state, Right.InsightsAccess);
}

export function canManageWhiteLabel(state: IWithSessionState): boolean {
  const queryData: PlatformNamespaceProperties[PlatformNamespace.CORE] = getQueryClient().getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.CORE),
  );
  const isEnabledForLicense = anyToBoolean(queryData?.[CoreProperty.WhiteLabelEnabled]);
  const plans = [PlanType.EnterprisePlus];
  const isEnabledInPlan = plans.includes(getPlanType(state));

  return isEnabledForLicense || isEnabledInPlan;
}

export function getCanManageGroupProperties(state: IWithSessionState): boolean {
  return hasRight(state, Right.GroupsPropertiesManagement);
}

const isApplicationInstalled = (state: IWithApplicationsState, applicationId: Nullable<string>): boolean => {
  if (!applicationId) {
    return false;
  }

  return !!state.entities.applications.installed.byIds[applicationId];
};

export const getIsChatSummaryEnabled = (
  state: IWithApplicationsState & IWithSessionState & IIntegrationLicensePropertiesState & IWithSubscriptionState,
): boolean => {
  const isChatSummaryDisabled = getIsChatSummaryDisabled(state);
  // At the moment Chat Summary is 'bound' to Sentiment integration
  const isChatSummaryInstalled = isApplicationInstalled(state, getChatSentimentAppId());

  return isChatSummaryInstalled && !isChatSummaryDisabled;
};

export const getManageDetails = createSelector(
  [getSubscription, getCanManageSubscription, getCanManageGreetings],
  (subscription, canManage, canManageGreetings) => ({
    canManageSubscription: !!subscription && canManage,
    canManageGreetings,
  }),
);
