import { type FC } from 'react';

import {
  type ProductId,
  ProductSwitcher as DSProductSwitcher,
  ProductSwitcherProducts,
  useProductSwitcher,
} from '@livechat/design-system-react-components';

import { EventPlace } from 'helpers/analytics';
import { useProductData } from 'hooks/api/use-product-data';
import { trackEvent } from 'services/event-tracking';

import { ProductSwitcherEvent } from './constants';
import { getEnvName } from './helpers';

import * as styles from './styles';

const TEXT_URL = 'https://www.text.com?utm_source=my.livechatinc.com&utm_medium=referral&utm_campaign=productswitcher';

export const ProductSwitcher: FC = () => {
  const { installedProducts, subscriptions, organizationId } = useProductData();

  const { products } = useProductSwitcher({
    env: getEnvName(),
    installedProducts,
    subscriptions,
    organizationId,
    mainProductId: 'livechat',
  });

  const handleProductSelect = (id: ProductId): void => {
    const product = ProductSwitcherProducts.find((product) => product.id === id);
    if (!product || product.id === 'livechat') {
      return;
    }

    trackEvent(ProductSwitcherEvent.ProductSwitched, EventPlace.ProductSwitcher, {
      product: product.name,
    });
  };

  const handleSwitcherOpen = (): void => {
    trackEvent(ProductSwitcherEvent.Opened, EventPlace.ProductSwitcher);
  };

  const handleSwitcherClose = (): void => {
    trackEvent(ProductSwitcherEvent.Closed, EventPlace.ProductSwitcher);
  };

  return (
    <li className={styles.switcherWrapper}>
      <DSProductSwitcher
        mainProductId="livechat"
        productOptions={products}
        onSelect={handleProductSelect}
        onOpen={handleSwitcherOpen}
        onClose={handleSwitcherClose}
        textURL={TEXT_URL}
      />
    </li>
  );
};
