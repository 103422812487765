import { ChatThreadStatus } from 'constants/chat-thread-status';
import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadExists } from 'store/entities/chats/selectors';

export function handleChatStatusChange(payload: any, store: IAppState): void {
  const { conference_id: threadId, active: isActive } = payload;
  const state = store.getState();
  const threadExists = getThreadExists(state, threadId);
  const status = isActive ? ChatThreadStatus.Active : ChatThreadStatus.Idle;

  if (threadExists) {
    store.dispatch(
      ChatsEntitiesActions.updateChatThread({
        thread: {
          status,
        },
        threadId,
      })
    );
  }
}
