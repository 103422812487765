import { ITourStep, TourStepType } from '../interfaces';

import { SegmentsStep, FiltersStep, ColumnsWidthChangeStep, CustomizeStep } from './tour-steps';

export enum CustomersListTourStep {
  Segments = 'Segments',
  Filters = 'Filters',
  Columns = 'Columns',
  Customize = 'Customize',
}

export const customersListSteps: ITourStep[] = [
  {
    id: CustomersListTourStep.Segments,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <SegmentsStep />,
    },
  },
  {
    id: CustomersListTourStep.Filters,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <FiltersStep />,
    },
  },
  {
    id: CustomersListTourStep.Columns,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <ColumnsWidthChangeStep />,
    },
  },
  {
    id: CustomersListTourStep.Customize,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <CustomizeStep />,
      placement: 'left-start',
    },
  },
];
