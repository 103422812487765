import type { FC } from 'react';

import { useCopilotSuggestions } from '../hooks/use-copilot-suggestions';

import { SUGGESTIONS_WRAPPER_DATA_TEST_ID } from './constants';
import { StaticSuggestion } from './StaticSuggestion';
import { Suggestion } from './Suggestion';

import * as styles from './styles';

export const Suggestions: FC = (): JSX.Element | null => {
  const { randomizedSuggestions, shouldShowSuggestions } = useCopilotSuggestions();

  if (!shouldShowSuggestions || !randomizedSuggestions.length) {
    return null;
  }

  return (
    <div className={styles.suggestionsWrapper} data-testid={SUGGESTIONS_WRAPPER_DATA_TEST_ID}>
      {randomizedSuggestions.map(({ message, description }, index) => (
        <Suggestion key={index} id={index} message={message} description={description} />
      ))}
      <StaticSuggestion />
    </div>
  );
};
