import { type FC } from 'react';

import { useSuspendableApplicationsFetch } from 'hooks/use-suspendable-applications-fetch';

/**
 * @description Suspends the rendering of its child components until the necessary data for user-installed applications is fully loaded. This component leverages the React Suspense mechanism to ensure that data is available before rendering.
 */
export const ApplicationsDataSuspender: FC = ({ children }) => {
  const { applications, hasInitialized, areFetched, error, fetch, hasFetchFailed, promise } =
    useSuspendableApplicationsFetch();

  if (!hasInitialized) {
    fetch();
  }
  if (areFetched) {
    promise.resolve(applications);
  }
  if (hasFetchFailed) {
    promise.reject(error);
  }

  return <>{children}</>;
};
