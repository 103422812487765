import { VisitorState } from 'constants/visitor-state';

import { FilterSetOperator, FilterType, FilterValueType, type TrafficFilter } from '../interfaces';

export const ONLINE_FILTER: TrafficFilter = {
  id: 'online-customers-filter',
  operator: FilterSetOperator.IsNot,
  value: [VisitorState.LeftWebsite],
  filterType: FilterType.Activity,
  valueType: FilterValueType.SetInclusive,
};
