// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { all, call, cancel, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import { isTechnologyAvailable, normalizeResponseTechnology } from 'helpers/installation-technologies';
import { AmplitudeUserProperty, setUserPropertiesForAmplitude } from 'services/amplitude';
import { ApiManager } from 'services/api/api-manager';
import { EMPTY_URL_ERROR, extractDomain } from 'services/api/onboarding/serializer';
import { trackEvent } from 'services/event-tracking';
import { CompanyDetailsActionNames, CompanyDetailsActions } from 'store/entities/company-details/actions';
import {
  getCompanyDetails,
  getHasFetchedCompanyDetails,
  getIsFetchingCompanyDetails,
} from 'store/entities/company-details/selectors';
import { getIsCodeInstalled } from 'store/features/code-installation/selectors';
import type { IActionWithPayload } from 'store/helper';
import { FailReason, InstallCodeEvent } from 'store/views/install-code/constants';
import { OnboardingActions } from 'store/views/onboarding/actions';

import { InstallCodeActions, InstallCodeActionsEnum } from './actions';
import { type IFetchTechnologyPayload, type ISaveTechnologiesPayload } from './interfaces';
import { getTechnologies } from './selectors';

function* parseDetectedTechnologies(technologies: string[], shouldSave: boolean) {
  setUserPropertiesForAmplitude({ [AmplitudeUserProperty.Technologies]: technologies });

  const availableTechnolgies = technologies.filter(isTechnologyAvailable);

  yield all([
    yield put(InstallCodeActions.fetchTechnologiesSuccess(availableTechnolgies)),
    yield put(InstallCodeActions.fetchTechnologiesForInstructionsSucess(availableTechnolgies)),
  ]);

  if (shouldSave) {
    yield put(InstallCodeActions.saveTechnologies(technologies));
  }
  if (!technologies.length) {
    yield put(InstallCodeActions.fetchTechnologiesFailure('No technologies found'));
    yield put(InstallCodeActions.fetchTechnologiesForInstructionsFailure('No technologies found'));
  }
}

function* fetchTechnologiesForInstructionsSaga(action: IActionWithPayload<string, IFetchTechnologyPayload>) {
  const { eventPlace, license } = action.payload;

  const API = ApiManager.onboardingApi;

  const technologiesResponse = yield call(API.fetchTechnologiesByLicense, license);

  if (technologiesResponse.result) {
    const detectedTechnologies = (technologiesResponse.result.technologies || technologiesResponse.result || []).map(
      normalizeResponseTechnology
    );
    yield call(parseDetectedTechnologies, detectedTechnologies, false);
  } else {
    trackEvent('Technologies detection failed', eventPlace, { error: technologiesResponse.error });
    yield put(
      InstallCodeActions.fetchTechnologiesForInstructionsFailure(
        technologiesResponse.error && technologiesResponse.error.message
      )
    );
  }
}

function* fetchTechnologiesSaga(action: IActionWithPayload<string, IFetchTechnologyPayload>) {
  const { eventPlace, fetchFreshWebsite } = action.payload;

  const API = ApiManager.onboardingApi;

  const technologies = yield select(getTechnologies);

  if (!fetchFreshWebsite && technologies.length) {
    yield put(InstallCodeActions.fetchTechnologiesFailure('Tech already fetched'));

    yield cancel();
  }

  try {
    const wasCompanyDetailsFetched = yield select(getHasFetchedCompanyDetails);
    const isCompanyDetailsFetching = yield select(getIsFetchingCompanyDetails);

    if (!wasCompanyDetailsFetched) {
      if (!isCompanyDetailsFetching) {
        yield put(CompanyDetailsActions.fetch());
      }

      yield take([
        CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS,
        CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_FAILURE,
      ]);
    }

    const website = fetchFreshWebsite || (yield select(getCompanyDetails)).website;

    const isCodeInstalled: boolean = yield select(getIsCodeInstalled);

    const apiRequest = isCodeInstalled && !fetchFreshWebsite ? API.fetchSavedTechnologies : API.fetchTechnologies;

    const domainOrEmail: string = yield call(extractDomain, website.trim());
    const { result } = yield call(apiRequest, domainOrEmail);
    const detectedTechnologies = (result.technologies || result || []).map(normalizeResponseTechnology);

    yield put(OnboardingActions.setIsRefetchTechnologies({ isRefetch: !!detectedTechnologies.length }));

    yield fork(parseDetectedTechnologies, detectedTechnologies, !isCodeInstalled);
  } catch (error) {
    const isEmptyUrl = error.message === EMPTY_URL_ERROR;
    trackEvent(InstallCodeEvent.TechnologiesDetectionFailed, eventPlace, {
      error,
      ...(isEmptyUrl && { reason: FailReason.EmptyUrl }),
    });
    yield put(InstallCodeActions.fetchTechnologiesFailure(error && error.message));
  }
}

function* saveTechnologies(action: IActionWithPayload<string, ISaveTechnologiesPayload>) {
  const API = ApiManager.onboardingApi;
  const {
    payload: { technologies },
  } = action;

  const { error } = yield call(API.saveTechnologies, technologies);

  if (error) {
    yield put(InstallCodeActions.saveTechnologiesFailure(error));

    return;
  }

  yield put(InstallCodeActions.saveTechnologiesSuccess());
}

export function* installCodeSaga() {
  yield takeLatest(InstallCodeActionsEnum.FETCH_TECHNOLOGIES, fetchTechnologiesSaga);
  yield takeLatest(InstallCodeActionsEnum.FETCH_TECHNOLOGIES_FOR_INSTRUCTIONS, fetchTechnologiesForInstructionsSaga);
  yield takeLatest(InstallCodeActionsEnum.SAVE_TECHNOLOGIES, saveTechnologies);
}
