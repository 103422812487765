import { type GroupId } from 'interfaces/groups';

import { type EyeCatcherAction, EyeCatcherActionNames } from './actions';
import { type IEyeCatcherState } from './interfaces';

const initialState: Record<GroupId, IEyeCatcherState> = {};
export const defaultEyeCatcherState: IEyeCatcherState = {
  enabled: true,
  path: '',
  xPosition: 0,
  yPosition: 0,
};

export function eyeCatcherReducer(
  state: typeof initialState = initialState,
  action: EyeCatcherAction
): typeof initialState {
  switch (action.type) {
    case EyeCatcherActionNames.TOGGLE_ENABLED:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          enabled: action.payload.enabled,
        },
      };

    case EyeCatcherActionNames.SET_PATH:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          path: action.payload.path,
        },
      };

    case EyeCatcherActionNames.SET_POSITION:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          xPosition: action.payload.x,
          yPosition: action.payload.y,
        },
      };
    case EyeCatcherActionNames.SET_EYE_CATCHER:
      return {
        ...state,
        [action.payload.groupId]: action.payload.eyeCatcher,
      };

    default:
      return state;
  }
}
