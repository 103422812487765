import { useState, useEffect, type FC } from 'react';

import { ChevronDown } from '@livechat/design-system-icons';
import { ActionMenu, type IActionMenuOption, Tooltip, Icon } from '@livechat/design-system-react-components';
import noop from 'lodash.noop';
import { useDispatch, useSelector } from 'react-redux';

import { FilterOperator } from 'constants/filters/filter';
import { useParamSelector } from 'hooks/use-param-selector';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getAgentCustomProperty,
  getFiltersOperatorsTooltipSeen,
} from 'store/features/agent-custom-properties/selectors';

import * as styles from './styles';

interface IProps {
  className?: string;
  selectedOperator?: FilterOperator;
  shouldShowPromoTooltip?: boolean;
  onOperatorChange?: (operator: FilterOperator) => void;
  availableOperators?: FilterOperator[];
}

export const itemTitles = {
  [FilterOperator.And]: 'Match all',
  [FilterOperator.Or]: 'Match any',
  [FilterOperator.Exclude]: 'Exclude',
};

const defaultAvailableOperators: FilterOperator[] = [FilterOperator.And, FilterOperator.Or, FilterOperator.Exclude];

const getListItems = (
  availableOperators: string[],
  handleOperatorChange: (operator: FilterOperator) => void
): IActionMenuOption[] =>
  [
    {
      key: FilterOperator.Or,
      element: 'Match any (OR)',
      onClick: () => handleOperatorChange(FilterOperator.Or),
    },
    {
      key: FilterOperator.And,
      element: 'Match all (AND)',
      onClick: () => handleOperatorChange(FilterOperator.And),
    },
    {
      key: FilterOperator.Exclude,
      element: 'Exclude (IS NOT)',
      onClick: () => handleOperatorChange(FilterOperator.Exclude),
    },
  ].filter(({ key }) => availableOperators.includes(key));

const Operators: FC<IProps> = ({
  className,
  shouldShowPromoTooltip,
  selectedOperator = FilterOperator.Or,
  onOperatorChange = noop,
  availableOperators = defaultAvailableOperators,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isOperatorTooltipSeen = useParamSelector(
    getAgentCustomProperty,
    AgentCustomPropertyName.FiltersOperatorsTooltipSeen
  );
  const isTooltipSeen = useSelector(getFiltersOperatorsTooltipSeen);
  const dispatch = useDispatch();

  const handleOperatorsTooltipClose = (): void => {
    dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.FiltersOperatorsTooltipSeen]: '1',
      })
    );
  };

  const handleDropdownToggle = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  const handleDropdownClose = (): void => {
    setIsOpen(false);
  };

  const handleTooltipClose = (): void => {
    setIsTooltipOpen(false);
    handleOperatorsTooltipClose();
  };

  const handleOperatorChange = (operator: FilterOperator): void => {
    onOperatorChange(operator);
    handleDropdownClose();

    if (!isOperatorTooltipSeen) {
      handleTooltipClose();
    }
  };

  useEffect(() => {
    if (!isTooltipSeen && shouldShowPromoTooltip) {
      setIsTooltipOpen(true);
    }
  }, [shouldShowPromoTooltip, isTooltipSeen]);

  const renderDropdownTrigger = (
    <button type="button" className={styles.dropdownTrigger} data-list-mode="menu" onClick={handleDropdownToggle}>
      <span className={styles.itemContent}>{itemTitles[selectedOperator]}</span>
      <Icon source={ChevronDown} size="small" />
    </button>
  );

  return (
    <Tooltip
      triggerClassName={className}
      placement="right"
      kind="important"
      isVisible={isTooltipOpen}
      onClose={handleTooltipClose}
      triggerRenderer={
        <ActionMenu
          options={getListItems(availableOperators, handleOperatorChange)}
          selectedOptions={[selectedOperator]}
          triggerRenderer={renderDropdownTrigger}
          onClose={handleDropdownClose}
          visible={isOpen}
        />
      }
    >
      <div>
        <h3>Advanced filtering preferences</h3>
        <p>
          Find chats or tickets which have <b>any</b> or <b>all</b> tags assigned.
        </p>
      </div>
    </Tooltip>
  );
};

export default Operators;
