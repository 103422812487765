import { App } from 'config/setup';
import { initializeTrackingTools } from 'services/startup/initialize/initialize-tracking-tools';

import { initializeBase } from './initialize-base';

export function initializeFreeAccessPage(path: string): void {
  initializeBase();
  initializeTrackingTools();
  App.visitInfoPage?.(path);
}
