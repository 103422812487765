// @ts-strict-ignore
import { subDays, isSameDay } from 'date-fns';
import { type SagaIterator } from 'redux-saga';
import { put, call, takeEvery, select } from 'redux-saga/effects';

import { CustomDateRangeFilterId } from 'constants/filters/date-range-filter';
import { Filter } from 'constants/filters/filter';
import { ReportType } from 'constants/reports/report-type';
import { TargetedMessagesRoutes, TargetedMessagesTypePath } from 'constants/targeted-messages';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';

import { getGreetingType } from 'helpers/get-greeting-type';
import { getGreetingTypeForEvent } from 'helpers/get-greeting-type-for-event';
import { navigate } from 'helpers/routing';
import { mapTargetedMessageTypeToRoute } from 'helpers/targeted-messages';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import {
  deserializeGreeting,
  serializeGreeting,
  combineGreetingsWithReportData,
  combineGreetingsWithPreviousReportData,
} from 'services/api/greeting/serialization';
import { trackEvent } from 'services/event-tracking';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { getGreetingName } from 'store/views/greetings/selectors';
import { ReportsViewActions } from 'store/views/reports/actions';

import type { EntityUpdate } from '../interfaces';
import { ReportActions, REPORT } from '../reports/actions';
import { type IFetchReportSuccessPayload } from '../reports/interfaces';

import { GreetingActions, GreetingActionNames } from './actions';
import { getGreetingEventProperties } from './helpers';
import { type IGreeting, type IApiGreeting, TargetedMessageSubtype } from './interfaces';
import { getGreetings, getGreeting } from './selectors';

function navigateAfterAction(greetingSubtype: string): void {
  navigate(
    mapTargetedMessageTypeToRoute(
      (greetingSubtype as TargetedMessageSubtype) === TargetedMessageSubtype.Announcement
        ? TargetedMessagesTypePath.Announcements
        : TargetedMessagesTypePath.Greetings,
      TargetedMessagesRoutes.SelectedType
    )
  );
}

export function* fetchAllGreetings(): SagaIterator {
  const { greetingApi } = ApiManager;
  const { result, error }: RequestResult<IApiGreeting[]> = yield call(greetingApi.fetchAll);

  if (result) {
    const previousGreetings: IGreeting[] = yield select(getGreetings);
    const greetings: IGreeting[] = combineGreetingsWithPreviousReportData(
      result.map(deserializeGreeting),
      previousGreetings
    );
    yield put(GreetingActions.fetchCollectionSuccess({ values: greetings }));
  } else {
    yield put(GreetingActions.fetchCollectionFailure({ error: error.message }));
  }
}

function* fetchAll(): SagaIterator {
  yield put(GreetingActions.fetchCollection({}));

  // must clear the filters to fetch all reports (not only for greetings recently filtered out)
  yield put(
    ReportsViewActions.updateFilter<Filter.Greeting>({
      name: Filter.Greeting,
      value: null,
      untracked: true,
    })
  );

  const now = new Date();

  yield put(
    ReportActions.fetch({
      reportType: ReportType.Greetings,
      filters: {
        [Filter.DateRange]: {
          id: CustomDateRangeFilterId.Last7Days,
          from: subDays(now, 6),
          to: now,
        },
      },
    })
  );
}

function* createGreeting(action: IActionWithPayload<string, IGreeting>): SagaIterator {
  const { greetingApi } = ApiManager;
  const name = action.payload.name || (yield select(getGreetingName));
  const greeting = action.payload;
  const apiGreeting = serializeGreeting({ ...action.payload, name });

  const { result, error }: RequestResult<IApiGreeting> = yield call(greetingApi.create, apiGreeting);

  if (result) {
    trackEvent('Greeting added', EventPlace.EngageCampaigns, getGreetingEventProperties(greeting));
    yield put(GreetingActions.createGreetingSuccess());
    navigateAfterAction(greeting.subtype);
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Success,
        content: getToastContent(ToastContent.GREETING_CREATE_SUCCESS, { targetedMessageSubtype: greeting.subtype }),
      })
    );
  }
  if (error) {
    yield put(GreetingActions.createGreetingFailure());
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Error,
        content: getToastContent(ToastContent.GREETING_CREATE_FAILURE, { targetedMessageSubtype: greeting.subtype }),
      })
    );
  }
}

function* createGreetingFromModal(action: IActionWithPayload<string, IGreeting>): SagaIterator {
  const { greetingApi } = ApiManager;
  const greeting = action.payload;
  const name = greeting.name || (yield select(getGreetingName));
  const apiGreeting = serializeGreeting({ ...greeting, name });

  const { result, error }: RequestResult<IApiGreeting> = yield call(greetingApi.create, apiGreeting);

  if (result) {
    yield put(GreetingActions.createGreetingSuccess());

    trackEvent('Greeting added', EventPlace.IntentCampaignModal, getGreetingEventProperties(greeting));
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Success,
        content: getToastContent(ToastContent.GREETING_CREATE_SUCCESS, { targetedMessageSubtype: greeting.subtype }),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        action: {
          label: 'View Campaigns',
          onClick: () => navigate('engage/campaigns/recurring'),
          closeOnClick: true,
        },
      })
    );
    navigate('engage/traffic');
  }
  if (error) {
    yield put(GreetingActions.createGreetingFailure());
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Error,
        content: getToastContent(ToastContent.GREETING_CREATE_FAILURE, { targetedMessageSubtype: greeting.subtype }),
      })
    );
  }
}

function* updateGreeting(action: IActionWithPayload<string, EntityUpdate<IGreeting>>): SagaIterator {
  const { greetingApi } = ApiManager;

  const payloadGreeting = action.payload.value;
  const name = payloadGreeting.name || (yield select(getGreetingName));
  const greeting = { ...payloadGreeting, name };
  const apiGreeting = serializeGreeting(greeting, { omitNotUpdatable: true });

  apiGreeting.properties['greeting-message_json_key'] = null;
  apiGreeting.properties['greeting-message_phrase_key'] = null;

  const { result, error }: RequestResult<IApiGreeting> = yield call(greetingApi.update, apiGreeting);
  if (result) {
    trackEvent('Greeting edited', EventPlace.EngageCampaigns, getGreetingEventProperties(greeting));
    navigateAfterAction(greeting.subtype);
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Success,
        content: getToastContent(ToastContent.GREETING_UPDATE_SUCCESS, { targetedMessageSubtype: greeting.subtype }),
      })
    );
    yield put(GreetingActions.updateSuccess({ id: result.id }));
    yield put(GreetingActions.setGreeting(greeting));
  }
  if (error) {
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Error,
        content: getToastContent(ToastContent.GREETING_UPDATE_FAILURE, { targetedMessageSubtype: greeting.subtype }),
      })
    );
    yield put(GreetingActions.updateFailure({ error, id: greeting.id }));
  }
}

function* updateOnboardingGreeting(action: IActionWithPayload<string, EntityUpdate<IGreeting>>): SagaIterator {
  const greeting = action.payload.value;

  const serializedGreeting = serializeGreeting(greeting, { omitNotUpdatable: true });

  serializedGreeting.properties['greeting-message_json_key'] = null;
  serializedGreeting.properties['greeting-message_phrase_key'] = null;

  const { result, error }: RequestResult<IApiGreeting> = yield call(ApiManager.greetingApi.update, serializedGreeting);
  if (result) {
    yield put(GreetingActions.updateSuccess({ id: result.id }));
    yield put(GreetingActions.setGreeting(greeting));
  }
  if (error) {
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Error,
        content: getToastContent(ToastContent.GREETING_UPDATE_FAILURE, { targetedMessageSubtype: greeting.subtype }),
      })
    );
    yield put(GreetingActions.updateFailure({ error, id: greeting.id }));
  }
}

function* deleteGreeting(action: IActionWithPayload<string, string>): SagaIterator {
  const { greetingApi } = ApiManager;
  const greetingId = action.payload;
  const greeting: IGreeting = yield select(getGreeting, greetingId);
  const { result, error }: RequestResult<any> = yield call(greetingApi.remove, greetingId);

  if (result) {
    yield put(GreetingActions.deleteGreetingSuccess());
    trackEvent('Greeting deleted', EventPlace.EngageCampaigns, {
      type: getGreetingTypeForEvent(greeting),
      greetingType: getGreetingType(greeting),
      subtype: greeting.subtype,
    });
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.GREETING_DELETE_SUCCESS, { targetedMessageSubtype: greeting.subtype }),
        kind: ToastVariant.Success,
      })
    );
    yield put(GreetingActions.fetchGreetingsTable());
  }

  if (error) {
    yield put(GreetingActions.deleteGreetingFailure());
    const greeting: IGreeting = yield select(getGreeting, greetingId);
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.GREETING_DELETE_ERROR, { targetedMessageSubtype: greeting.subtype }),
        kind: ToastVariant.Error,
      })
    );
  }
}

function* duplicateGreeting(action: IActionWithPayload<string, string>): SagaIterator {
  const greetingId = action.payload;
  const { greetingApi } = ApiManager;
  const greeting: IGreeting = yield select(getGreeting, greetingId);
  const greetingCopy = JSON.parse(JSON.stringify(greeting)) as IGreeting;

  greetingCopy.name = `${greeting.name} (Copy)`;

  if (greeting.subtype === TargetedMessageSubtype.Greeting) {
    greetingCopy.isActive = false;
  }

  if (greeting.subtype === TargetedMessageSubtype.Announcement) {
    const yesterday = subDays(Date.now(), 1).toISOString();
    greetingCopy.activeFrom = yesterday;
    greetingCopy.activeUntil = yesterday;
  }

  const apiGreeting = serializeGreeting(greetingCopy);
  const { result, error }: RequestResult<IApiGreeting> = yield call(greetingApi.create, apiGreeting);

  if (result) {
    trackEvent('Greeting duplicated', EventPlace.EngageCampaigns, getGreetingEventProperties(greeting));
    yield put(GreetingActions.createGreetingSuccess());
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Success,
        content: getToastContent(ToastContent.GREETING_DUPLICATE_SUCCESS, { targetedMessageSubtype: greeting.subtype }),
      })
    );
  }

  if (error) {
    yield put(GreetingActions.createGreetingFailure());
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Error,
        content: getToastContent(ToastContent.GREETING_DUPLICATE_FAILURE, { targetedMessageSubtype: greeting.subtype }),
      })
    );
  }

  yield put(GreetingActions.fetchGreetingsTable());
}

function* setGreetingVisibility(
  action: IActionWithPayload<string, { greetingId: string; isActive: boolean }>
): SagaIterator {
  const { greetingApi } = ApiManager;
  const { greetingId, isActive } = action.payload;
  const { result, error }: RequestResult<IApiGreeting> = yield call(greetingApi.update, {
    id: +greetingId,
    active: isActive ? 1 : 0,
  });

  if (result) {
    yield put(GreetingActions.setGreetingVisibilitySuccess());

    const previousGreeting: IGreeting = yield select(getGreeting, greetingId);
    const previousReportsData = {
      displayedCount: previousGreeting.displayedCount,
      acceptedCount: previousGreeting.acceptedCount,
      conversionValue: previousGreeting.conversionValue,
    };

    const greeting = deserializeGreeting(result);
    yield put(
      GreetingActions.setGreeting({
        ...greeting,
        ...previousReportsData,
      })
    );

    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Success,
        content: getToastContent(ToastContent.GREETING_ACTIVATION_SUCCESS, {
          isActive,
          targetedMessageSubtype: greeting.subtype,
        }),
      })
    );

    trackEvent('Greeting state changed', EventPlace.EngageCampaigns, {
      type: getGreetingTypeForEvent(greeting),
      enabled: isActive,
      subtype: greeting.subtype,
    });
  }

  if (error) {
    const greeting: IGreeting = yield select(getGreeting, greetingId);
    yield put(GreetingActions.setGreetingVisibilityFailure());
    yield put(
      ToastsActions.createToast({
        kind: ToastVariant.Error,
        content: getToastContent(ToastContent.GREETING_ACTIVATION_FAILURE, {
          isActive,
          targetedMessageSubtype: greeting.subtype,
        }),
      })
    );
  }
}

function* updateGreetingWithReportsSaga(action: IActionWithPayload<string, IFetchReportSuccessPayload>): SagaIterator {
  const { data, filters } = action.payload.data[0];
  const { dateFrom, dateTo } = filters;
  const now = new Date();
  const sevenDaysAgo = subDays(now, 6);

  if (isSameDay(dateTo, now) && isSameDay(dateFrom, sevenDaysAgo)) {
    const greetings: IGreeting[] = yield select(getGreetings);
    const greetingsWithReportData = combineGreetingsWithReportData(greetings, data.greetingsConversion);
    yield put(GreetingActions.setGreetings({ values: greetingsWithReportData }));
  }
}

export default function* greetingsSagas(): SagaIterator {
  yield takeEvery(GreetingActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.REQUEST], fetchAllGreetings);
  yield takeEvery(GreetingActionNames.FETCH_GREETINGS_TABLE, fetchAll);
  yield takeEvery(GreetingActionNames.CREATE_GREETING, createGreeting);
  yield takeEvery(GreetingActionNames.DELETE_GREETING, deleteGreeting);
  yield takeEvery(GreetingActionNames.DUPLICATE_GREETING, duplicateGreeting);
  yield takeEvery(GreetingActionNames.SET_VISIBILITY, setGreetingVisibility);
  yield takeEvery(GreetingActionNames[CRUDAction.UPDATE][RequestAction.REQUEST], updateGreeting);
  yield takeEvery(REPORT[ReportType.Greetings][RequestAction.SUCCESS], updateGreetingWithReportsSaga);
  yield takeEvery(GreetingActionNames.UPDATE_ONBOARDING_GREETING, updateOnboardingGreeting);
  yield takeEvery(GreetingActionNames.CREATE_GREETING_FROM_MODAL, createGreetingFromModal);
}
