import { type FC, memo } from 'react';

import { Icon, type IconSource } from '@livechat/design-system-react-components';

interface IProps {
  inactiveIcon: IconSource;
  activeIcon?: IconSource;
  isActive?: boolean;
  className?: string;
}

export const NavigationIcon: FC<IProps> = memo(({ activeIcon, inactiveIcon, isActive, className }) => (
  <Icon source={isActive && activeIcon ? activeIcon : inactiveIcon} size="large" className={className} />
));
