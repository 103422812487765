import type { IWidgetSnapshot } from 'interfaces/widget-snapshot';
import { type ActionsUnion, createAction } from 'store/helper';

export enum WidgetSnapshotActionNames {
  SET_SNAPSHOT = 'VIEWS/WIDGET_SNAPSHOT/SET_SNAPSHOT',
  SET_SNAPSHOT_PRECHAT_FIELDS = 'VIEWS/WIDGET_SNAPSHOT/SET_SNAPSHOT_PRECHAT_FIELDS',
  SET_SNAPSHOT_POSTCHAT_FIELDS = 'VIEWS/WIDGET_SNAPSHOT/SET_SNAPSHOT_POSTCHAT_FIELDS',
  SET_SNAPSHOT_OFFLINE_FIELDS = 'VIEWS/WIDGET_SNAPSHOT/SET_SNAPSHOT_OFFLINE_FIELDS',
  SET_SNAPSHOT_FEATURES = 'VIEWS/WIDGET_SNAPSHOT/SET_SNAPSHOT_FEATURES',
}

export const WidgetSnapshotActions = {
  setSnapshot: (payload: Partial<IWidgetSnapshot>) => createAction(WidgetSnapshotActionNames.SET_SNAPSHOT, payload),
  setPreChatFields: (payload: any[]) => createAction(WidgetSnapshotActionNames.SET_SNAPSHOT_PRECHAT_FIELDS, payload),
  setPostChatFields: (payload: any[]) => createAction(WidgetSnapshotActionNames.SET_SNAPSHOT_POSTCHAT_FIELDS, payload),
  setOfflineFields: (payload: any[]) => createAction(WidgetSnapshotActionNames.SET_SNAPSHOT_OFFLINE_FIELDS, payload),
  setFeatures: (payload: any) => createAction(WidgetSnapshotActionNames.SET_SNAPSHOT_FEATURES, payload),
};

export type WidgetSnapshotAction = ActionsUnion<typeof WidgetSnapshotActions>;
