import { createSelector } from 'reselect';

import { isGhostLogin } from 'helpers/ghost';
import { getCreationTimestamp } from 'store/features/session/selectors';

import { ONBOARDING_CUTOFF_YEAR } from './constants';
import { getIsOnboardingFinished } from './selectors';

export const getShouldShowAppOnboarding = createSelector(
  getIsOnboardingFinished,
  getCreationTimestamp,
  (isOnboardingFinished, licenseCreatedAt) => {
    const isGhost = isGhostLogin();
    const isTooOldForOnboarding = licenseCreatedAt && new Date(licenseCreatedAt).getFullYear() < ONBOARDING_CUTOFF_YEAR;

    return !isOnboardingFinished && !isGhost && !isTooOldForOnboarding;
  },
);
