import { getSeriesDataFromPeriod, sumValueFromPeriod } from 'helpers/reports';
import type { IMissedChatsData, ITotalChatPeriod } from 'interfaces/reports';
import type { ReportData } from 'interfaces/reports/api-v3';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';

interface IMissedChatsPayload {
  data: {
    missedChats: ReportData<ITotalChatPeriod>;
  };
}

export function missedChatsDeserializer({ data }: IMissedChatsPayload): IMissedChatsData {
  const missedChatsSummary = sumValueFromPeriod(data.missedChats.records, 'total');
  const missedChats = getSeriesDataFromPeriod<number>(data.missedChats.records, 'total');

  return {
    missedChats: {
      summary: [
        {
          name: 'missed chats',
          color: DEFAULT_SERIES_COLOR,
          value: missedChatsSummary,
        },
      ],
      labels: Object.keys(data.missedChats.records),
      series: [
        {
          name: 'missed chats',
          color: DEFAULT_SERIES_COLOR,
          data: missedChats,
        },
      ],
    },
  };
}
