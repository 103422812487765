import { ReportType } from 'constants/reports/report-type';

export function isReportTypeIsDisabled(reportType: ReportType): boolean {
  return [
    ReportType.Activity,
    ReportType.ChatForms,
    ReportType.QueueAbandonment,
    ReportType.StaffingPrediction,
    ReportType.Greetings,
    ReportType.TagsUsage,
    ReportType.ChatTopics,
  ].includes(reportType);
}
