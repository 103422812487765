// @ts-strict-ignore
import { type KeyMap } from 'helpers/interface';
import type { ChatUserResultType } from 'interfaces/api/chat';
import { type ChatSummaryResult } from 'services/api/chat/interfaces';
import { isUnassignedChat, isQueuedChat } from 'store/entities/chats/helpers/common';
import { type ChatThreadEntity, type ChatEventEntity } from 'store/entities/chats/interfaces';

import { deserializeChatSummary } from './deserialize';
import { type IEventDetails, type ICurrentAgent } from './helpers/common';
import { getChatsWithCustomers } from './helpers/get-chats-with-customers';

interface IDeserializeChatSummaryCollection {
  threads: KeyMap<ChatThreadEntity>;
  events: KeyMap<KeyMap<ChatEventEntity>>;
  chatIdsToFollow: string[];
  threadTags: KeyMap<string[]>;
}

export function deserializeChatSummaryCollection(
  data: ChatSummaryResult[],
  currentAgentId: string,
  resolveWasEventSeen: (event: IEventDetails, currentAgent: ICurrentAgent, chatUsers: ChatUserResultType[]) => boolean
): IDeserializeChatSummaryCollection {
  const withCustomers = getChatsWithCustomers(data);

  const deserializedData = withCustomers.reduce(
    (acc, chat) => {
      const deserializedThreadData = deserializeChatSummary(chat, currentAgentId, resolveWasEventSeen);

      if (chat.is_followed) {
        acc.chatIdsToFollow.push(chat.id);
      }

      if (!deserializedThreadData) {
        return acc;
      }

      const { thread, events } = deserializedThreadData;

      if (!thread) {
        return acc;
      }

      // TODO: Optimize by checking lastEventPerType in order to make sure there is anything to fetch
      thread.incomplete = isUnassignedChat(thread) || isQueuedChat(thread);
      acc.threads[thread.threadId] = thread;

      if (events) {
        acc.events[thread.threadId] = events;
      }

      if (chat?.last_thread_summary?.tags) {
        acc.threadTags[thread.threadId] = chat.last_thread_summary.tags;
      }

      return acc;
    },
    {
      threads: {},
      events: {},
      chatIdsToFollow: [],
      threadTags: {},
    }
  );

  return deserializedData;
}
