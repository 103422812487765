/* eslint-disable @typescript-eslint/naming-convention */
export interface IApiSession {
  account_id: string;
  session_id: string;
  current: boolean;
  user_agent: string;
  identity_source: 'credentials' | 'explicit' | 'google' | 'token';
  ip: string;
}

export enum IdentitySource {
  Credentials = 'credentials',
  Explicit = 'explicit',
  GoogleOauth = 'google-oauth',
  DeprecatedLC2Token = 'deprecated-lc2-token',
}

export interface ISession {
  sessionId: string;
  isCurrent: boolean;
  identitySource: IdentitySource;
  userAgent: string;
  ip: string;
}

export enum SignoutReason {
  AccessDenied = 'access_denied',
  IdentityLost = 'identity_lost',
  InvalidRequest = 'invalid_request',
  LicenseBlocked = 'license_blocked',
  LicenseVersionChanged = 'license_version_changed',
  SignedOutRemotely = 'signed_out_remotely',
  AgentDisconnectedByServer = 'agent_disconnected_by_server',
  UnsupportedVersion = 'unsupported_version',
  LicenseNotFound = 'license_not_found',
  AccessTokenRevoked = 'access_token_revoked',
  AgentLoggedOutRemotely = 'agent_logged_out_remotely',
  ConnectionEvicted = 'connection_evicted',
  MisdirectedConnection = 'misdirected_connection',
}
