export enum Right {
  AccessRestrictionManagement = 'AccessRestrictionManagement',
  AgentsAdd = 'AgentsAdd',
  AgentsAuthenticationManagement = 'AgentsAuthenticationManagement',
  AgentsManagement = 'AgentsManagement',
  AgentsSectionAccess = 'AgentsSectionAccess',
  AgentsRoleManagement = 'AgentsRoleManagement',
  AllGroupsManagement = 'AllGroupsManagement',
  ApplicationsViewAccess = 'ApplicationsViewAccess',
  ApplicationsManagement = 'ApplicationsManagement',
  KBIntegrationManagement = 'KBIntegrationManagement',
  HDIntegrationManagement = 'HDIntegrationManagement',
  AutoAccessRulesManagement = 'AutoAccessRulesManagement',
  BanningVisitorsManagement = 'BanningVisitorsManagement',
  BotAgentsManagement = 'BotAgentsManagement',
  BillingManagement = 'BillingManagement',
  CancelLicenseAccess = 'CancelLicenseAccess',
  CannedResponsesAccess = 'CannedResponsesAccess',
  CannedResponsesManage = 'CannedResponsesManage',
  CannedResponsesManagement = 'CannedResponsesManagement',
  ChangeOwnerManagement = 'ChangeOwnerManagement',
  ChatBoostersAccess = 'ChatBoostersAccess',
  ChatButtonsManagement = 'ChatButtonsManagement',
  ChatFormsManagement = 'ChatFormsManagement',
  ChatRoutingManagement = 'ChatRoutingManagement',
  ChatSurveyManagement = 'ChatSurveyManagement',
  CreditCardManagement = 'CreditCardManagement',
  CreditCardMaskingManagement = 'CreditCardMaskingManagement',
  CustomizationManagement = 'CustomizationManagement',
  EmailForwardManagement = 'EmailForwardManagement',
  EmailNotificationsManagement = 'EmailNotificationsManagement',
  ExportReportsAccess = 'ExportReportsAccess',
  ExportReportsManagement = 'ExportReportsManagement',
  EyeCatcherManagement = 'EyeCatcherManagement',
  FacebookMessengerManagement = 'FacebookMessengerManagement',
  FileSharingManagement = 'FileSharingManagement',
  FormsManagement = 'FormsManagement',
  GoalsAccess = 'GoalsAccess',
  GoalsManagement = 'GoalsManagement',
  GreetingsManagement = 'GreetingsManagement',
  GroupsManagement = 'GroupsManagement',
  InactivityTimeoutsManagement = 'InactivityTimeoutsManagement',
  InviteLinkUsage = 'InviteLinkUsage',
  InvoicesAccess = 'InvoicesAccess',
  InvoicesEmailManagement = 'InvoicesEmailManagement',
  LanguagesManagement = 'LanguagesManagement',
  MessagingModeManagement = 'MessagingModeManagement',
  MyPasswordChange = 'MyPasswordChange',
  MyProfileManagement = 'MyProfileManagement',
  PasswordsChange = 'PasswordsChange',
  QualityPageManagement = 'QualityPageManagement',
  SalesTrackerManagement = 'SalesTrackerManagement',
  SubscriptionFetching = 'SubscriptionFetching',
  SubscriptionManagement = 'SubscriptionManagement',
  SubscriptionPotentialAccess = 'SubscriptionPotentialAccess',
  TagsAccess = 'TagsAccess',
  TagsManagement = 'TagsManagement',
  ThemeManagement = 'ThemeManagement',
  TicketsManagement = 'TicketsManagement',
  TranscriptForwardingManagement = 'TranscriptForwardingManagement',
  TrustedDomainsManagement = 'TrustedDomainsManagement',
  WebhooksManagement = 'WebhooksManagement',
  WorkSchedulerManagement = 'WorkSchedulerManagement',
  OrganizationManagement = 'OrganizationManagement',
  AgentSessionsManagement = 'AgentSessionsManagement',
  OwnSessionsManagement = 'OwnSessionsManagement',
  InvitationsManagement = 'InvitationsManagement',
  InsightsAccess = 'InsightsAccess',
  OrganizationProductActivation = 'OrganizationProductActivation',
  GroupsPropertiesManagement = 'GroupsPropertiesManagement',
}
