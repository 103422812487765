import { FC } from 'react';

import { TourStep } from 'components/tours/TourStep';

export const SendTicketsToStep: FC = () => (
  <TourStep
    title="Get your tickets into HelpDesk"
    message={
      <>
        Choose “HelpDesk” and save changes.
        <br />
        Set this up for any group you’d like.
      </>
    }
  />
);
