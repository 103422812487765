interface IWebSocketEvent {
  name: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  request_id?: string;
  payload: any;
}

export class WebSocketsEventsHistoryManager {
  private readonly maxStackSize: number;
  private eventsStack: IWebSocketEvent[] = [];

  constructor(maxStackSize = 150) {
    this.maxStackSize = maxStackSize;
  }

  addWebSocketEvent(event: IWebSocketEvent): void {
    if (this.eventsStack.length >= this.maxStackSize) {
      this.eventsStack.shift();
    }
    this.eventsStack.push(event);
  }

  geEvents(): IWebSocketEvent[] {
    return this.eventsStack;
  }
}

export const websocketEventsHistoryManager = new WebSocketsEventsHistoryManager();
