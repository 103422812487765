export enum ChatThreadStatus {
  Active,
  Idle,
  Closed,
}

export enum ChatThreadVisualStatus {
  MyChatVisuallyClosed = 'my-chat-visually-closed',
  QueuedChatVisuallyClosed = 'queued-chat-visually-closed',
}
