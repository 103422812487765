import type { FC } from 'react';

import { ArrowForward, FiletypeHtml, FiletypePdf } from '@livechat/design-system-icons';
import { Button, Text, Loader, Icon, Tooltip, Heading } from '@livechat/design-system-react-components';

import { AddSourceButtonWithPopover } from 'components/knowledge-hub/AddSourceButtonWithPopover';
import { AbsoluteAutomateRoutes } from 'constants/automate/constants';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { Feature } from 'constants/feature';
import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { useParamSelector } from 'hooks/use-param-selector';
import { useReplySuggestionsSourcesList } from 'hooks/use-reply-suggestions-list';
import { KnowledgeSourceType, type KnowledgeSourceUrlItem } from 'interfaces/knowledge-sources';
import { trackEvent } from 'services/event-tracking';
import { getCanUseFeature } from 'store/features/session/selectors';

import { LOADING_STATUSES, COPILOT_SOURCES_DESCRIPTION } from './constants';
import { CopilotAccordion } from './CopilotAccordion';
import { getKnowledgeSourcesData } from './helpers';
import { ReplySuggestionsUpsell } from './ReplySuggestionsUpsell';

import * as styles from './styles';

const DEFAULT_EXTRACTED_SIZE = 5;

function navigateToKnowledgeHubOverview(): void {
  navigate(AbsoluteAutomateRoutes.KnowledgeHubAllSources);
  trackEvent(CopilotTrackEvent.ViewSourceClicked, EventPlace.One, { from: 'details' });
}

const handlePdfClick = (sourceId: string): void => {
  navigate(`${AbsoluteAutomateRoutes.KnowledgeHubPDF}}/${sourceId}`);
};

export const ReplySuggestionsAccordion: FC = () => {
  const { data, isLoading } = useReplySuggestionsSourcesList();

  const canUseKnowledgeHub = useParamSelector(getCanUseFeature, Feature.KnowledgeHub);
  const shouldDisplayUpsell = !canUseKnowledgeHub;

  if (isLoading) {
    return (
      <CopilotAccordion title="Knowledge sources">
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      </CopilotAccordion>
    );
  }

  const { extractedSources, remainingSourcesCount } = getKnowledgeSourcesData(data, DEFAULT_EXTRACTED_SIZE);
  const isEmpty = extractedSources?.length === 0;

  const loader = (source: string): JSX.Element => (
    <Tooltip
      triggerClassName={styles.sourceLoaderContent}
      kind="invert"
      placement="top"
      hoverOffDelay={300}
      triggerRenderer={
        <div className={styles.sourceLoaderWrapper}>
          <Loader size="small" />
        </div>
      }
    >
      <div>
        <Heading>Adding source</Heading>
        <Text as="span">Time of adding the source depends on the {source} content.</Text>
        <br />
        <Text as="span">With the very heave sources this might even take up to few hours.</Text>
      </div>
    </Tooltip>
  );

  return (
    <CopilotAccordion title="Your sources">
      <div>
        {shouldDisplayUpsell && <ReplySuggestionsUpsell />}
        {isEmpty && !shouldDisplayUpsell && (
          <div className={styles.noSourcesContainer}>
            <Text>{COPILOT_SOURCES_DESCRIPTION}</Text>

            <div>
              <AddSourceButtonWithPopover eventPlace={EventPlace.One} place="one-details" />
              <Button
                kind="link"
                onClick={navigateToKnowledgeHubOverview}
                icon={<Icon source={ArrowForward} />}
                iconPosition="right"
              >
                Settings
              </Button>
            </div>
          </div>
        )}

        {!isEmpty && !shouldDisplayUpsell && (
          <div>
            <ul className={styles.sourcesList}>
              {extractedSources.map((source) => {
                switch (source.sourceType) {
                  case KnowledgeSourceType.URL: {
                    const urlSource = source as KnowledgeSourceUrlItem;
                    const isLoading = LOADING_STATUSES.includes(source.status);

                    return (
                      <li
                        className={styles.sourceListItem}
                        key={urlSource.name}
                        onClick={() => navigate(`${AbsoluteAutomateRoutes.KnowledgeHubWebsites}/${urlSource.id}`)}
                      >
                        {isLoading && loader('website')}
                        {!isLoading && (
                          <div className={styles.urlIcon}>
                            <div />
                            <Icon source={FiletypeHtml} />
                          </div>
                        )}
                        <Text>{urlSource.name}</Text>
                      </li>
                    );
                  }

                  case KnowledgeSourceType.File: {
                    const isLoading = LOADING_STATUSES.includes(source.status);

                    return (
                      <li className={styles.sourceListItem} key={source.name} onClick={() => handlePdfClick(source.id)}>
                        {isLoading && loader('PDF File')}
                        {!isLoading && (
                          <div className={styles.pdfIcon}>
                            <div />
                            <Icon source={FiletypePdf} />
                          </div>
                        )}
                        <Text>{source.name}</Text>
                      </li>
                    );
                  }

                  default:
                    return null;
                }
              })}
            </ul>
            <div className={styles.settingsButton}>
              <Button
                kind="link"
                onClick={navigateToKnowledgeHubOverview}
                icon={<Icon source={ArrowForward} />}
                iconPosition="right"
              >
                {remainingSourcesCount ? `See ${remainingSourcesCount} more` : 'See all'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </CopilotAccordion>
  );
};
