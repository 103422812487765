import { type FC } from 'react';

import { cx } from '@emotion/css';
import { type InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { useSelector } from 'react-redux';

import { useParamSelector } from 'hooks/use-param-selector';
import { getIsAIAgentAvailableByName } from 'store/entities/ai-agents/selectors';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import * as styles from './styles';

interface IProps {
  children: JSX.Element;
  className?: string;
  initialConfig?: InitialConfigType;
}

export const CopilotMessageBoxWrapper: FC<IProps> = ({ children, className, initialConfig }) => {
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const hasCopilotAIAgent = useParamSelector(getIsAIAgentAvailableByName, 'one');

  const DEFAULT_CONFIG: InitialConfigType = {
    namespace: 'MyEditor',
    onError: (error) => {
      throw error;
    },
  };

  return (
    <LexicalComposer initialConfig={initialConfig ?? DEFAULT_CONFIG}>
      <div className={cx(styles.messageBoxWrapper(isExpanded, hasCopilotAIAgent), className)}>{children}</div>
    </LexicalComposer>
  );
};
