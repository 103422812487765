import { round } from 'helpers/numbers';
import { getFormattedNumberValue } from 'helpers/string';

export function getFormattedPercent(value: number, precision = 0): string {
  if (Number.isNaN(value) || !value) {
    return '0%';
  }

  if (value === 1) {
    return '100%';
  }

  return `${getFormattedNumberValue(round(value * 100, precision), precision)}%`;
}
