import { type EventPlace } from 'helpers/analytics';
import { type ActionsUnion, createAction } from 'store/helper';

export enum GTMActionsNames {
  INIT_API = 'FEATURES/GTM/INIT_API',
  START_AUTHORIZING = 'FEATURES/GTM/START_AUTHORIZING',
  STOP_AUTHORIZING = 'FEATURES/GTM/STOP_AUTHORIZING',
  SET_IS_INITIALIZING = 'FEATURES/GTM/SET_IS_INITIALIZING',
  SET_IS_AUTHORIZED = 'FEATURES/GTM/SET_IS_AUTHORIZED',
  SIGN_OUT = 'FEATURES/GTM/SIGN_OUT',
  SUBMIT_TAG = 'FEATURES/GTM/SUBMIT_TAG',
  START_CREATING_TAG = 'FEATURES/GTM/START_CREATING_TAG',
  TAG_CREATED_SUCCESSFULY = 'FEATURES/GTM/TAG_CREATED_SUCCESSFULY',
  ERROR_CREATING_TAG = 'FEATURES/GTM/ERROR_CREATING_TAG',
  SELECT_ACCOUNT_ID = 'FEATURES/GTM/SELECT_ACCOUNT_ID',
  SELECT_CONTAINER_ID = 'FEATURES/GTM/SELECT_CONTAINER_ID',
  SET_ACCOUNT_ID = 'FEATURES/GTM/SET_ACCOUNT_ID',
  SET_CONTAINER_ID = 'FEATURES/GTM/SET_CONTAINER_ID',
  SET_CONTAINERS = 'FEATURES/GTM/SET_CONTAINERS',
  SET_ACCOUNTS = 'FEATURES/GTM/SET_ACCOUNTS',
  ACCOUNT_DATA_ERROR = 'FEATURES/GTM/ACCOUNT_DATA_ERROR',
}

export const GTMActions = {
  initApi: (eventPlace: EventPlace) => createAction(GTMActionsNames.INIT_API, { eventPlace }),
  startAuthorizing: (eventPlace: EventPlace, isGTMRecommended: boolean) =>
    createAction(GTMActionsNames.START_AUTHORIZING, { eventPlace, isGTMRecommended }),
  stopAuthorizing: () => createAction(GTMActionsNames.STOP_AUTHORIZING),
  setIsInitializing: (isInitializing: boolean) => createAction(GTMActionsNames.SET_IS_INITIALIZING, { isInitializing }),
  setIsAuthorized: (isAuthorized: boolean, eventPlace: EventPlace) =>
    createAction(GTMActionsNames.SET_IS_AUTHORIZED, { isAuthorized, eventPlace }),
  signOut: (eventPlace: EventPlace) => createAction(GTMActionsNames.SIGN_OUT, { eventPlace }),
  submitTag: (trackingCode: string, eventPlace: EventPlace) =>
    createAction(GTMActionsNames.SUBMIT_TAG, { trackingCode, eventPlace }),
  startCreatingTag: () => createAction(GTMActionsNames.START_CREATING_TAG),
  tagCreatedSuccessfuly: () => createAction(GTMActionsNames.TAG_CREATED_SUCCESSFULY),
  errorCreatingTag: () => createAction(GTMActionsNames.ERROR_CREATING_TAG),
  selectAccountId: (accountId: string, eventPlace: EventPlace) =>
    createAction(GTMActionsNames.SELECT_ACCOUNT_ID, { accountId, eventPlace }),
  selectContainerId: (containerId: string, eventPlace: EventPlace) =>
    createAction(GTMActionsNames.SELECT_CONTAINER_ID, { containerId, eventPlace }),
  setAccountId: (accountId: string) => createAction(GTMActionsNames.SET_ACCOUNT_ID, { accountId }),
  setContainerId: (containerId: string) => createAction(GTMActionsNames.SET_CONTAINER_ID, { containerId }),
  setAccounts: (accounts: any[]) => createAction(GTMActionsNames.SET_ACCOUNTS, { accounts }),
  setContainers: (containers: any[]) => createAction(GTMActionsNames.SET_CONTAINERS, { containers }),
  accountDataError: () => createAction(GTMActionsNames.ACCOUNT_DATA_ERROR),
};

export type GTMAction = ActionsUnion<typeof GTMActions>;
