import { createAction, type ActionsUnion } from 'store/helper';

import type { IInitChatbotInLivechatPayload } from './interfaces';

enum ChatbotInLivechatActionNames {
  INIT_CHATBOT_IN_LIVECHAT_REQUEST = 'ENTITIES/CHATBOT_IN_LIVECHAT/INIT_CHATBOT_IN_LIVECHAT_REQUEST',
  INIT_CHATBOT_IN_LIVECHAT_SUCCESS = 'ENTITIES/CHATBOT_IN_LIVECHAT/INIT_CHATBOT_IN_LIVECHAT_SUCCESS',
  INIT_CHATBOT_IN_LIVECHAT_FAILURE = 'ENTITIES/CHATBOT_IN_LIVECHAT/INIT_CHATBOT_IN_LIVECHAT_FAILURE',
}

export const ChatbotInLivechatActions = {
  initChatbotInLivechatRequest: (payload: IInitChatbotInLivechatPayload) =>
    createAction(ChatbotInLivechatActionNames.INIT_CHATBOT_IN_LIVECHAT_REQUEST, payload),
  initChatbotInLivechatSuccess: (payload: { status: string }) =>
    createAction(ChatbotInLivechatActionNames.INIT_CHATBOT_IN_LIVECHAT_SUCCESS, payload),
  initChatbotInLivechatFailure: (payload: { error: string }) =>
    createAction(ChatbotInLivechatActionNames.INIT_CHATBOT_IN_LIVECHAT_FAILURE, payload),
};

export type ChatbotInLivechatAction = ActionsUnion<typeof ChatbotInLivechatActions>;
