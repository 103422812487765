export const QUEUED_LIST_UPDATE_THROTTLE_TIME = 1000;
export const CHATS_LIST_UPDATE_THROTTLE_TIME = 300;
export const AUTO_TAGS_FETCH_DEBOUNCE_TIME = 30e3;
export const CHATS_SENTIMENT_THROW_ERROR_TIME = 4000;

export enum BrowserNotificationTag {
  ChatEvent = 'chat_event',
  QueuedVisitor = 'queued_visitor',
}

export const NO_REACTION = '';
