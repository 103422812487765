export enum KeyCodeEnum {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Esc = 27,
  Space = 32,
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
  Zero = 48,
  KeyB = 66,
  KeyC = 67,
  KeyE = 69,
  KeyF = 70,
  KeyG = 71,
  KeyI = 73,
  KeyJ = 74,
  KeyK = 75,
  KeyL = 76,
  KeyM = 77,
  KeyO = 79,
  KeyP = 80,
  KeyU = 85,
  Slash = 191,
}

export enum KeyEnum {
  'ArrowUp' = 'ArrowUp',
  'ArrowRight' = 'ArrowRight',
  'ArrowDown' = 'ArrowDown',
  'ArrowLeft' = 'ArrowLeft',
  'Enter' = 'Enter',
  'Slash' = 'Slash',
  'Tab' = 'Tab',
  'KeyQ' = 'KeyQ',
  'KeyZ' = 'KeyZ',
  'Backspace' = 'Backspace',
  'Escape' = 'Escape',
  'Space' = 'Space',
  'Delete' = 'Delete',
  'Spacebar' = ' ',
  'Semicolon' = ';',
  'Comma' = ',',
}
