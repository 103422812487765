import * as Sentry from '@sentry/browser';

import { getWidgetConnection } from 'components/one-click-payment/helpers';
import { EVENT_PREFIX, MARKETPLACE_IFRAME_ID } from 'constants/apps/marketplace';
import type { PostMessageEvent } from 'constants/post-message-event';
import type { PostMessage } from 'interfaces/post-message';
import { type IInboxMessage } from 'services/widget/message/inbox/inbox-message';

export const sendPostMessageToMarketplace = async (
  eventName: PostMessageEvent,
  data?: PostMessage['event_data'],
): Promise<void> => {
  const connection = await getWidgetConnection(MARKETPLACE_IFRAME_ID);

  if (connection) {
    try {
      void connection.send({ title: `${EVENT_PREFIX}${eventName}`, data, pluginId: MARKETPLACE_IFRAME_ID });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Failed to send post message to Marketplace - ${eventName}`, error);
      Sentry.captureException(new Error(`Failed to send post message to Marketplace - ${eventName}`));
    }
  }
};

/* eslint-disable @typescript-eslint/naming-convention */
export const normalizePostMessageEvent = <T>(message: IInboxMessage): T =>
  ({
    event_name: message.title,
    event_data: message.data,
  }) as T;
/* eslint-enable @typescript-eslint/naming-convention */
