import type { GroupProperty, IGroupProperties } from 'interfaces/entities/group-property';
import { createHasFetchedSelector, createRequestFetchingSelector } from 'store/requests/selectors';

import { GroupPropertiesRequestNames } from './actions';
import type { IGroupPropertiesState } from './interfaces';

export interface IWithGroupPropertiesState {
  entities: {
    groupProperties: IGroupPropertiesState;
  };
}

export function getGroupProperties(state: IWithGroupPropertiesState, groupId: string): IGroupProperties {
  return state.entities.groupProperties[groupId];
}

export function getGroupProperty(state: IWithGroupPropertiesState, groupId: string, property: GroupProperty): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return state.entities.groupProperties[groupId]?.[property];
}

export const getIsFetchingGroupProperties = createRequestFetchingSelector([GroupPropertiesRequestNames.FETCH]);
export const getAreGroupPropertiesFetched = createHasFetchedSelector([GroupPropertiesRequestNames.FETCH]);

export const getIsSavingGroupProperties = createRequestFetchingSelector([GroupPropertiesRequestNames.SAVE]);
