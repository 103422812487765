import { PointFilled } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';

import { listItemIcon } from './styles';

interface IProps {
  icon?: string;
  name: string;
}

export const ApplicationListItemIcon: React.FC<IProps> = ({ icon, name }) => {
  if (!icon) {
    return <Icon source={PointFilled} />;
  }

  return <img src={icon} alt={`${name} application icon`} className={listItemIcon} />;
};
