import { type EffectCallback, useEffect, useRef, type DependencyList } from 'react';

import noop from 'lodash.noop';

import { useIsMounted } from './use-is-mounted';

/**
 * @see https://stackoverflow.com/a/58217148
 */
export const useUpdateOnlyEffect = (effect: EffectCallback, dependencies: DependencyList): void => {
  const isMounted = useIsMounted();
  const isInitialMount = useRef(true);

  useEffect(() => {
    let effectCleanupFunc = noop;

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effectCleanupFunc = effect() || effectCleanupFunc;
    }

    return (): void => {
      effectCleanupFunc();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!isMounted.current) {
        isInitialMount.current = true;
      }
    };
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};
