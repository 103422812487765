import { createGlobalAccountsApiClient } from '../client';
import type { GlobalAccountsApiErrorResponse } from '../types';

import { type GetRoleResponse, type ListRolesResponse } from './types';

const client = createGlobalAccountsApiClient();

/**
 * Global Accounts API accounts client.
 * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Roles}
 */
export const rolesClient = {
  /**
   * Retrieves the roles of the organization.
   * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Roles/paths/~1roles/get}
   */
  list: async () => {
    return client.get<ListRolesResponse, GlobalAccountsApiErrorResponse>('roles');
  },

  /**
   * Retrieves a role by its id.
   * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Roles/paths/~1roles~1%7Brole_id%7D/get}
   */
  get: async (roleId: string) => {
    return client.get<GetRoleResponse, GlobalAccountsApiErrorResponse>(`roles/${roleId}`);
  },
};
