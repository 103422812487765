import { useState } from 'react';

import { type Nullable } from 'helpers/interface';
import { getItem, removeItem, saveItem, type LocalStorageItem } from 'services/local-storage';

export function useLocalStorage<T extends Nullable<LocalStorageItem>>(
  key: string,
  initialValue: T
): [T, (value: T | ((prevVal: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>((): T => {
    const item = getItem(key);

    return (item || initialValue) as T;
  });
  const setValue = (value: T | ((prevVal: T) => T)): void => {
    setStoredValue(value);
    const valueToSave = typeof value === 'function' ? value(storedValue) : value;
    if (valueToSave === null || valueToSave === undefined) {
      removeItem(key);
    } else {
      saveItem(key, valueToSave);
    }
  };

  return [storedValue, setValue];
}
