export enum SubscriptionRoutes {
  Subscription = '/subscription',
  Manage = '/subscription/manage',
  Upgrade = '/subscription/upgrade',
  Checkout = '/subscription/checkout',
  ThankYou = '/subscription/thank-you',
  PickLiveChatPlan = '/subscription/pick-livechat-plan',
  Processing = '/subscription/processing',
  Invoices = '/subscription/invoices',
  AccountDetails = '/subscription/account-details',
  EditBillingDetails = '/subscription/account-details#billing-details',
  CancelAccount = '/subscription/cancel-account',
  ChoosePlan = '/subscription/choose-plan',
  InstallCode = '/subscription/install-code',
}
