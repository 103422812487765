import { type FC, type MouseEvent, useState } from 'react';

import { cx } from '@emotion/css';
import { Popover } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { AgentAvatar } from 'components/avatar/AgentAvatar';
import { useMyInactivity } from 'components/navigation/hooks/use-my-inactivity';
import { NavigationMyProfileMenu } from 'components/navigation/NavigationMyProfileMenu';
import { GlobalModal } from 'constants/global-modal';
import { EventPlace } from 'helpers/analytics';
import useToggle from 'hooks/use-toggle';
import { trackEvent } from 'services/event-tracking';
import { ProfileMenuEvent } from 'store/entities/agents/constants';
import { getLoggedInAgent } from 'store/entities/agents/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';

import { AGENT_AVATAR_NAVIGATION_TEST_ID } from '../../constants';

import * as styles from './styles';

export const MyProfileItem: FC = () => {
  const dispatch = useDispatch();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { status, avatar, name } = useSelector(getLoggedInAgent);
  const { data } = useMyInactivity();
  const [isFocused, setFocus] = useToggle(false);

  const handleDisplayNotificationsModal = (): void => {
    dispatch(GlobalModalActions.showModal(GlobalModal.Notifications));
  };

  const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    setIsMenuVisible((prevState) => !prevState);

    if (!isMenuVisible) {
      trackEvent(ProfileMenuEvent.MenuOpened, EventPlace.ProfileMenu);
    }
  };

  const hideMenu = (): void => {
    setIsMenuVisible(false);
  };

  return (
    <li className={styles.container}>
      <Popover
        className={styles.popover}
        isVisible={isMenuVisible}
        placement="right-end"
        onClose={hideMenu}
        triggerRenderer={() => (
          <a
            id="my-profile-menu-button"
            onClick={handleClick}
            className={cx(styles.link, { [styles.active]: isMenuVisible || isFocused })}
            data-testid={AGENT_AVATAR_NAVIGATION_TEST_ID}
            role="button"
            aria-label="My Profile"
            aria-expanded={isMenuVisible}
            aria-controls="my-profile-menu"
            href="#my-profile-non-existing-id-to-enable-interaction-with-element"
            onFocus={setFocus}
            onBlur={setFocus}
          >
            <AgentAvatar status={status} src={avatar} text={name} size="small" className={styles.agentAvatar} />
          </a>
        )}
      >
        <NavigationMyProfileMenu
          hideMenu={hideMenu}
          onNotificationsClick={handleDisplayNotificationsModal}
          customStatus={data}
        />
      </Popover>
    </li>
  );
};
