// @ts-strict-ignore
import memoizeOne from 'memoize-one';

import { QueryKey } from 'constants/query-key';
import { type KeyMap } from 'helpers/interface';
import { type IBannedVisitor } from 'interfaces/entities/banned-visitors';
import { getQueryClient } from 'services/query-client/client';
import { type ICustomer } from 'store/entities/customers/interfaces';

export function getBannedCustomers(): IBannedVisitor[] {
  const queryClient = getQueryClient();

  return queryClient.getQueryData<IBannedVisitor[]>([QueryKey.BannedCustomers]) || [];
}

export function checkCustomerIsBanned(customer: ICustomer, bannedCustomers: Set<string>): boolean {
  return bannedCustomers.has(customer.id) || bannedCustomers.has(customer.ip);
}

const getBannedCustomersHashMap = memoizeOne((bannedCustomers: IBannedVisitor[]): Set<string> => {
  return new Set(bannedCustomers.map(({ id, ip }) => id || ip));
});

export function filterOutBannedCustomers(customers: KeyMap<ICustomer>, bannedCustomers: Set<string>): ICustomer[] {
  return Object.values(customers).filter((customer) => {
    return !checkCustomerIsBanned(customer, bannedCustomers);
  });
}

export function getNonBannedCustomers(customers: KeyMap<ICustomer>): ICustomer[] {
  return filterOutBannedCustomers(customers, getBannedCustomersHashMap(getBannedCustomers()));
}

export function isCustomerBanned(customer: ICustomer): boolean {
  return checkCustomerIsBanned(customer, getBannedCustomersHashMap(getBannedCustomers()));
}
