export const MODAL_FEATURES = {
  enterpriseplus: {
    starter: [
      'Chat archives will be limited to 60 days',
      'All recurring campaigns except one will be lost',
      'Access to reporting & analytics',
      'Advanced customization will be lost',
      'Group organization will be disabled',
      'Routing rules will stop working',
      'Tagging feature will be disabled',
      'File sharing will be disabled',
      'Two-factor authentication and SSO will be disabled',
      'Traffic list will show only last 100 customers',
      'Dedicated account manager',
      'Legal consulting and product training won’t be available ',
      'Work scheduler will be disabled',
      'Scheduled reports and CSV data export won’t be available',
      'Custom customer segments will be lost',
      'Your private canned responses will be lost',
      'Canned responses will be disabled',
      'Reply suggestions and your knowledge sources',
      // TODO: https://livechatinc.atlassian.net/browse/AA-10022
      // 'Contact list will only show contacts from the last 60 days'
    ],
    team: [
      'Work scheduler will be disabled',
      'Sending private chat messages',
      'Agent performance metrics',
      'Predictive analytics won’t be available',
      'Scheduled reports and CSV data export',
      'SSO will be disabled',
      'Traffic list will show only last 400 customers',
      'Dedicated Account Manager',
      'Hands-on product training won’t be available',
      'Account setup guidance won’t be available',
      'Legal consulting won’t be available',
      'Payment via wire transfer won’t be possible',
      'Knowledge sources exceeding the limit of your plan',
      // TODO: https://livechatinc.atlassian.net/browse/AA-10022
      // 'Contact list will only show contacts from the last 12 months'
    ],
    enterprise: [
      'Dedicated Account Manager',
      'Hands-on product training won’t be available',
      'Account setup guidance won’t be available',
      'Legal consulting won’t be available',
      'Payment via wire transfer won’t be possible',
      'SSO will be disabled',
      // TODO: https://livechatinc.atlassian.net/browse/AA-10022
      // 'Contact list will only show contacts from the last 24 month'
    ],
  },
  enterprise: {
    starter: [
      'Chat archives will be limited to 60 days',
      'All recurring campaigns except one will be lost',
      'Access to reporting & analytics',
      'Advanced customization will be lost',
      'Group organization will be disabled',
      'Routing rules will stop working',
      'Tagging feature will be disabled',
      'File sharing will be disabled',
      'Two-factor authentication and',
      'Traffic list will show only last 100 customers',
      'Work scheduler will be disabled',
      'Scheduled reports and CSV data export',
      'Custom customer segments will be lost',
      'Your private canned responses will be lost',
      'Canned responses will be disabled',
      'Reply suggestions and your knowledge sources',
      // TODO: https://livechatinc.atlassian.net/browse/AA-10022
      // 'Contact list will only show contacts from the last 60 days'
    ],
    team: [
      'Work scheduler will be disabled',
      'Sending private chat messages',
      'Agent performance metrics',
      'Predictive analytics won’t be available',
      'Scheduled reports and CSV data export',
      'Traffic list will show only last 400 customers',
      'Knowledge sources exceeding the limit of your plan',
      // TODO: https://livechatinc.atlassian.net/browse/AA-10022
      // 'Contact list will only show contacts from the last 12 months'
    ],
  },
  team: {
    starter: [
      'Chat archives will be limited to 60 days',
      'All recurring campaigns except one will be lost',
      'Group organization will be disabled',
      'Routing rules will stop working',
      'Advanced customization will be lost',
      'Access to reporting & analytics',
      'Tagging feature will be disabled',
      'File sharing will be disabled',
      'Traffic list will show only last 100 customers',
      'Custom customer segments will be lost',
      'Your private canned responses will be lost',
      'Canned responses will be disabled',
      'Reply suggestions and your knowledge sources',
      // TODO: https://livechatinc.atlassian.net/browse/AA-10022
      // 'Contact list will only show contacts from the last 60 days'
    ],
  },
  regular: {
    starter: [
      'Chat archives will be limited to 60 days',
      'All recurring campaigns except one will be lost',
      'Advanced customization will be lost',
      'Access to reporting & analytics',
      'Tagging feature will be disabled',
      'File sharing will be disabled',
      'Traffic list will show only last 100 customers',
      'Custom customer segments will be lost',
      'Your private canned responses will be lost',
      'Canned responses will be disabled',
      'Reply suggestions and your knowledge sources',
      // TODO: https://livechatinc.atlassian.net/browse/AA-10022
      // 'Contact list will only show contacts from the last 60 days'
    ],
  },
};
