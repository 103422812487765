import type { Product } from 'interfaces/product-cart';
import { createAction, type ActionsUnion } from 'store/helper';

import {
  type IFetchBillingInfoSuccessPayload,
  type IAddCreditCardRequestPayload,
  type IAddCreditCardSuccessPayload,
  type IAddCreditCardFailurePayload,
  type ICancelApplicationRecurringRequestPayload,
  type ICancelApplicationRecurringSuccessPayload,
  type ICancelApplicationRecurringFailurePayload,
} from './interfaces';

export enum BillingActionNames {
  BILLING_INFO_FETCHED = 'ENTITIES/BILLING/BILLING_INFO_FETCHED',
  FETCH_BILLING_INFO = 'ENTITIES/BILLING/FETCH_BILLING_INFO',
  ADD_CREDIT_CARD_REQUEST = 'ENTITIES/BILLING/ADD_CREDIT_CARD_REQUEST',
  ADD_CREDIT_CARD_SUCCESS = 'ENTITIES/BILLING/ADD_CREDIT_CARD_SUCCESS',
  ADD_CREDIT_CARD_FAILURE = 'ENTITIES/BILLING/ADD_CREDIT_CARD_FAILURE',
  CANCEL_APPLICATION_RECURRING_REQUEST = 'ENTITIES/BILLING/CANCEL_APPLICATION_RECURRING_REQUEST',
  CANCEL_APPLICATION_RECURRING_SUCCESS = 'ENTITIES/BILLING/CANCEL_APPLICATION_RECURRING_SUCCESS',
  CANCEL_APPLICATION_RECURRING_FAILURE = 'ENTITIES/BILLINGCANCEL_APPLICATION_RECURRING_FAILURE',
  SET_RECURRENT_APPLICATIONS = 'ENTITIES/APPLICATIONS/FETCH_APPLICATIONS_RECURRINGS_SUCCESS',
  MARK_APPLICATION_TO_UNINSTALL = 'ENTITIES/APPLICATIONS/MARK_APPLICATION_TO_UNINSTALL',
  UNMARK_APPLICATION_TO_UNINSTALL = 'ENTITIES/APPLICATIONS/UNMARK_APPLICATION_TO_UNINSTALL',
}

export enum BillingRequestFetchingSelectorsNames {
  ADD_CREDIT_CARD_REQUEST = 'ENTITIES/BILLING/ADD_CREDIT_CARD',
  CANCEL_APPLICATION_RECURRING_REQUEST = 'ENTITIES/BILLING/CANCEL_APPLICATION_RECURRING',
}

export const BillingActions = {
  billingInfoFetched: (payload: IFetchBillingInfoSuccessPayload) =>
    createAction(BillingActionNames.BILLING_INFO_FETCHED, payload),
  fetchBillingInfo: () => createAction(BillingActionNames.FETCH_BILLING_INFO),
  addCreditCard: (payload: IAddCreditCardRequestPayload) =>
    createAction(BillingActionNames.ADD_CREDIT_CARD_REQUEST, payload),
  addCreditCardSuccess: (payload: IAddCreditCardSuccessPayload) =>
    createAction(BillingActionNames.ADD_CREDIT_CARD_SUCCESS, payload),
  addCreditCardFailure: (payload: IAddCreditCardFailurePayload) =>
    createAction(BillingActionNames.ADD_CREDIT_CARD_FAILURE, payload),

  cancelApplicationRecurring: (payload: ICancelApplicationRecurringRequestPayload) =>
    createAction(BillingActionNames.CANCEL_APPLICATION_RECURRING_REQUEST, payload),
  cancelApplicationRecurringSuccess: (payload: ICancelApplicationRecurringSuccessPayload) =>
    createAction(BillingActionNames.CANCEL_APPLICATION_RECURRING_SUCCESS, payload),
  cancelApplicationRecurringFailure: (payload: ICancelApplicationRecurringFailurePayload) =>
    createAction(BillingActionNames.CANCEL_APPLICATION_RECURRING_FAILURE, payload),

  setRecurrentApplications: (applications: Product[]) =>
    createAction(BillingActionNames.SET_RECURRENT_APPLICATIONS, applications),

  markApplicationToUnInstall: (id: string) => createAction(BillingActionNames.MARK_APPLICATION_TO_UNINSTALL, { id }),

  unmarkApplicationToUnInstall: (id: string) =>
    createAction(BillingActionNames.UNMARK_APPLICATION_TO_UNINSTALL, { id }),
};

export type BillingActionTypes = ActionsUnion<typeof BillingActions>;
