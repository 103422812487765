import type { Section } from 'constants/section';
import { createAction, type ActionsUnion } from 'store/helper';

export enum RoutingActionsEnum {
  SET_CURRENT_SECTION = 'FEATURES/ROUTING/SET_CURRENT_SECTION',
  HIDE_PRIVATE_SUGGESTED_CANNED_TOOLTIP = 'FEATURES/ROUTING/HIDE_PRIVATE_SUGGESTED_CANNED_TOOLTIP',
}

export const RoutingActions = {
  hidePrivateSuggestedCannedTooltip: () => createAction(RoutingActionsEnum.HIDE_PRIVATE_SUGGESTED_CANNED_TOOLTIP),
  setCurrentSection: (route: Section) => createAction(RoutingActionsEnum.SET_CURRENT_SECTION, { route }),
};

export type RoutingAction = ActionsUnion<typeof RoutingActions>;
