import { useContext } from 'react';

import { type ConnectivityStatusInstance } from 'services/connectivity/status/types';

import { ConnectivityStatusContext } from './ConnectivityStatusContext';

export const useConnectivityStatusContext = (): ConnectivityStatusInstance => {
  const context = useContext(ConnectivityStatusContext);
  if (!context) {
    throw new Error('useConnectivityStatusContext must be used within a ConnectivityStatusProvider');
  }

  return context;
};
