// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { ChatEventType } from 'constants/chat-event-type';
import { SurveyType } from 'constants/survey-type';
import { type KeyMap } from 'helpers/interface';
import { type ICustomVariable } from 'interfaces/entities/archive';
import { AppStateProvider } from 'services/app-state-provider';
import { type ISurveyMessage } from 'store/entities/chats/interfaces';
import { getThread } from 'store/entities/chats/selectors';
import { getCustomer } from 'store/entities/customers/selectors';
import { getCurrentArchive } from 'store/views/archives/selectors';
import { getChatLastEvent } from 'store/views/chats/selectors';

import { type IOutboxMessage } from './outbox-message';
import { createOutboxMessage } from './outbox-message-factory';
import { OutboxMessageTitles } from './outbox-message-titles';

export enum ProfileMessageSource {
  Chats = 'chats',
  Archives = 'archives',
  Customers = 'customers',
}

interface ICustomerProfileMessageData {
  id: string;
  name: string;
  geolocation: {
    longitude?: string;
    latitude?: string;
    country?: string;
    country_code?: string;
    region?: string;
    city?: string;
    timezone?: string;
  };
  email?: string;
  chat:
    | {
        id: string;
        groupID: number;
        preChatSurvey: { question: string; answer: string }[];
        chat_id?: string;
        thread_id?: string;
      }
    | { groupID: number }
    | null;
  source: string;
  customVariables: KeyMap<string>;
}

interface ICustomerProfileMessage extends IOutboxMessage {
  readonly title: OutboxMessageTitles.CustomerProfile;
  readonly data: ICustomerProfileMessageData;
}

function createCustomerProfileDataForChats(
  customerId: string,
  threadId: string,
  source: ProfileMessageSource
): ICustomerProfileMessageData {
  const customer = AppStateProvider.selectFromStore(getCustomer, customerId);
  const thread = AppStateProvider.selectFromStore(getThread, threadId);

  if (!customer && !thread) {
    return null;
  }

  const messageData: ICustomerProfileMessageData = {
    id: customerId,
    /**
     * According to Developer Platform docs, `chat` object should either be empty
     * or contain chat data (it they exists). Chat data are filled in code below,
     * after checking if `thread` for this customer exists.
     * For backward compatibility
     * previous behavior is preserved (filling `groupID` from customer data) but
     * additional checks were added.
     *
     * @see {@link https://platform.text.com/docs/extending-agent-app/products-sdk/agent-app-sdk#customer_profile}
     */
    chat: null,
    geolocation: null,
    name: null,
    email: null,
    source,
    customVariables: null,
  };

  if (customer) {
    const { groupIds, geolocation, name, email, customVariables } = customer;

    const geolocationMap = geolocation
      ? {
          longitude: geolocation.longitude,
          latitude: geolocation.latitude,
          country: geolocation.country,
          city: geolocation.city,
          timezone: geolocation.timezone,
          country_code: geolocation.countryCode,
          region: geolocation.state,
        }
      : null;

    if (groupIds?.length) {
      messageData.chat = {
        groupID: Number(groupIds[0]),
      };
    }
    messageData.geolocation = geolocationMap;
    messageData.name = name;
    messageData.email = email;
    messageData.customVariables = customVariables;
  }

  if (thread) {
    // TODO: implement `getChatFirstEvent` in oder to always get `PreChat` survey.
    // Alternatively create dedicated method to get surveys of specific type.
    // In most cases there will be no `PostChat` survey for active chats.
    let preChatSurvey = [];
    const firstThreadSurvey = AppStateProvider.selectFromStore(getChatLastEvent, threadId, [
      ChatEventType.FilledForm,
    ]) as ISurveyMessage;
    if (firstThreadSurvey && firstThreadSurvey.surveyType === SurveyType.Pre) {
      preChatSurvey = firstThreadSurvey.responses;
    }

    messageData.chat = {
      ...messageData.chat,
      id: thread.threadId,
      chat_id: thread.chatId,
      thread_id: thread.threadId,
      groupID: thread.groupIds && parseInt(thread.groupIds[0], 10),
      preChatSurvey,
    };

    messageData.name = thread.customerName;
    messageData.email = thread.customerEmail;
  }

  return messageData;
}

function createCustomerProfileDataForArchives(customerId: string, archiveId: string): ICustomerProfileMessageData {
  const baseData: ICustomerProfileMessageData = {
    id: customerId,
    chat: null,
    geolocation: null,
    name: null,
    source: ProfileMessageSource.Archives,
    customVariables: null,
  };

  const archive = AppStateProvider.selectFromStore(getCurrentArchive);

  if (!archive || archive.id !== archiveId) {
    return baseData;
  }

  baseData.chat = {
    id: archiveId,
    groupID: archive.groupIds ? archive.groupIds[0] : null,
    preChatSurvey: (archive.preChatSurvey || []).map((item) => {
      const question = item.question.replace(/:$/, '');

      return { question, answer: item.answer };
    }),
  };

  baseData.chat = {
    ...baseData.chat,
    chat_id: archive.chatId,
    thread_id: archiveId,
  };

  const {
    visitor: { name, email, city, country, countryCode, region, timezone, latitude, longitude },
    customVariables,
  } = archive;

  return {
    ...baseData,
    email,
    geolocation: {
      city,
      country,
      country_code: countryCode,
      latitude,
      longitude,
      region,
      timezone,
    },
    name,
    customVariables: (customVariables || []).reduce((acc: object, cur: ICustomVariable) => {
      acc[cur.key] = cur.value;

      return acc;
    }, {}),
  };
}

function createCustomerProfileData(
  customerId: string,
  chatId: string,
  source: ProfileMessageSource
): ICustomerProfileMessageData {
  if (source === ProfileMessageSource.Chats || source === ProfileMessageSource.Customers) {
    return createCustomerProfileDataForChats(customerId, chatId, source);
  }

  if (source === ProfileMessageSource.Archives) {
    return createCustomerProfileDataForArchives(customerId, chatId);
  }

  return null;
}

export function createCustomerProfileMessage(
  pluginId: string | null,
  customerId: string,
  chatId: string,
  source: ProfileMessageSource
): ICustomerProfileMessage {
  if (!customerId) {
    return null;
  }

  const data = createCustomerProfileData(customerId, chatId, source);
  if (!data) {
    return null;
  }

  return createOutboxMessage<ICustomerProfileMessage>(OutboxMessageTitles.CustomerProfile, pluginId, data);
}
