import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';

export const handleInstallCodeLinkClicked = (): void => {
  trackEvent('Install code button clicked', EventPlace.TopBar);
  navigate('/settings/code');
};

export const handleSendCodeBtnClicked = (): void => {
  trackEvent('Send code button clicked', EventPlace.TopBar);
};

export const handleNavigateToSubscription = (): void => {
  navigate('/subscription');
};
