import type { GlobalModal } from 'constants/global-modal';
import type { GlobalPayload } from 'interfaces/global-modal';
import { type ActionsUnion, createAction } from 'store/helper';

export enum GlobalModalActionsEnum {
  SHOW_GLOBAL_MODAL = 'FEATURES/GLOBAL_MODALS/SHOW',
  HIDE_GLOBAL_MODAL = 'FEATURES/GLOBAL_MODALS/HIDE',
  HIDE_ALL_GLOBAL_MODALS = 'FEATURES/GLOBAL_MODALS/HIDE_ALL',
}

export const GlobalModalActions = {
  showModal: <T extends GlobalModal>(modal: GlobalModal, payload?: GlobalPayload<T>) =>
    createAction(GlobalModalActionsEnum.SHOW_GLOBAL_MODAL, { modal, payload: payload || {} }),
  hideModal: (modal: GlobalModal) => createAction(GlobalModalActionsEnum.HIDE_GLOBAL_MODAL, { modal }),
  hideAllModals: () => createAction(GlobalModalActionsEnum.HIDE_ALL_GLOBAL_MODALS),
};

export type GlobalModalActionTypes = ActionsUnion<typeof GlobalModalActions>;
