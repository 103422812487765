import { multicastChannel, type MulticastChannel } from 'redux-saga';
import { take } from 'redux-saga/effects';

import { type NotificationChannel } from 'store/features/tab-notifications/sagas/interfaces';

interface ITabFocusAction {
  type: 'focus-in' | 'focus-out';
}

/**
 * This channel acts as a singleton and should be created only once
 * and reused in the app over it's whole lifetime
 */
let channelInstance;
function createTabFocusChannel(): MulticastChannel<ITabFocusAction> {
  channelInstance = multicastChannel();

  const onFocusIn = (): void => {
    const focusAction: ITabFocusAction = { type: 'focus-in' };
    channelInstance.put(focusAction);
  };

  const onFocusOut = (): void => {
    const focusAction: ITabFocusAction = { type: 'focus-out' };
    channelInstance.put(focusAction);
  };

  window.addEventListener('focus', onFocusIn);
  window.addEventListener('blur', onFocusOut);

  return channelInstance;
}
createTabFocusChannel();

export function* watchTabFocus(notificationChannel: NotificationChannel) {
  while (true) {
    const event = yield take(channelInstance, '*');
    notificationChannel.put(event);
  }
}

export { channelInstance as tabFocusChannel };
