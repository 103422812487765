import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { QueryKey } from 'constants/query-key';
import { FeatureNames, type Features } from 'services/api/feature-control/interfaces';
import { getQueryClient } from 'services/query-client/client';

const log = debug(DebugLogsNamespace.AppDebug);

export function getIsFeatureEnabled(featureName: FeatureNames): boolean {
  const queryClient = getQueryClient();

  const queryData: Features = queryClient.getQueryData<Features>([QueryKey.Features]) || ({} as Features);

  if (featureName === FeatureNames.CustomerIntentEnabled) {
    log('[Features] getIsFeatureEnabled queryData.customerIntentEnabled', queryData.customerIntentEnabled);
  }

  return queryData[featureName];
}
