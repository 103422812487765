import uniqueId from 'lodash.uniqueid';

import { COPILOT_GLOBAL_SOURCES } from 'constants/copilot';
import type { Suggestion } from 'interfaces/copilot';
import type { Source, CopilotErrorEvent, CopilotEvent } from 'store/entities/copilot/interfaces';

import { TOP_SOURCES_LIMIT } from './constants';

const EMPTY_ARRAY = [];

function createErrorEvent(): CopilotErrorEvent {
  return {
    authorType: 'live-assistant',
    eventId: uniqueId(),
    timestampInMs: Date.now(),
    type: 'error-message',
    authorId: 'live-assistant',
  };
}

interface IBuildGroupedEventsParams {
  groupedEvents: CopilotEvent[][];
  isCopilotError: boolean | null;
}

export function createGroupedEvents({ groupedEvents, isCopilotError }: IBuildGroupedEventsParams): CopilotEvent[][] {
  if (isCopilotError) {
    return [...groupedEvents, [createErrorEvent()]];
  }

  return groupedEvents;
}

export const getSources = (sources?: Source[]): { topSources: Source[]; remainingSources: number } => {
  if (!sources) {
    return {
      topSources: [],
      remainingSources: 0,
    };
  }

  return {
    topSources: sources.slice(0, TOP_SOURCES_LIMIT).map((source) => {
      if (source.type === 'url') {
        const urlObj = new URL(source.url);

        if (COPILOT_GLOBAL_SOURCES.some((source) => source.includes(urlObj.hostname))) {
          urlObj.searchParams.delete('utm_campaign');
          urlObj.searchParams.set('utm_source', 'livechat');
          urlObj.searchParams.set('utm_medium', 'copilot');
        }

        return {
          ...source,
          url: urlObj.toString(),
        };
      }

      return source;
    }),
    remainingSources: sources.slice(TOP_SOURCES_LIMIT)?.length,
  };
};

export function deserializeSuggestionWithShortcuts(suggestions?: Suggestion[]): Required<Suggestion>[] {
  return (
    (suggestions?.filter(({ shortcut }) => !!shortcut) as Required<Suggestion>[]) ||
    (EMPTY_ARRAY as Required<Suggestion>[])
  );
}
