import { UserType } from 'constants/user-type';
import type { ChatUserResultType } from 'interfaces/api/chat';
import type { ChatResultType, ChatSummaryResult } from 'services/api/chat/interfaces';

import type { IChatCustomerResult } from './chat';

export function isChatCustomerResult(user: ChatUserResultType): user is IChatCustomerResult {
  return user && user.type === UserType.Customer;
}

export function isChatSummaryResult(data: ChatResultType): data is ChatSummaryResult {
  return Boolean(data && (data as ChatSummaryResult).last_thread_summary);
}

/**
 * Determines if a user is an active agent in a chat.
 *
 * @remarks
 * The function checks three conditions:
 * - `present`: Indicates that the agent is an active part of a chat, meaning they have not left the chat purposely.
 * - `type`: We want to filter the user type by agent.
 * - `visibility`: This is a new field introduced in API 3.3 and later versions. Some users may still not contain a value for visibility, and in that case we assume the agent is visible to the customer. Otherwise, it's only when visibility is set to 'all'.
 *
 * @param user - The user to check.
 * @returns A boolean indicating whether the user is an active agent in a chat.
 */
export function isChattingAgent(user: ChatUserResultType): boolean {
  const isPresent = user.present;
  const isAgent = user.type === UserType.Agent;
  const isVisibleToCustomer = user.visibility === 'all' || !user.visibility;

  return isPresent && isAgent && isVisibleToCustomer;
}

export function isSupervisingAgent(user: ChatUserResultType): boolean {
  const isPresent = user.present;
  const isAgent = user.type === UserType.Agent;
  const isVisibleToAgents = user.visibility === 'agents';

  return isPresent && isAgent && isVisibleToAgents;
}
