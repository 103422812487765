import { type ActionsUnion, createAction } from 'store/helper';

import { createEntityActionNames, createEntityActions } from '../actions';

import { type ITagAddedPayload, type ITagRemovedPayload, type ITag } from './interfaces';

const TagExtendedActionNames = {
  TAG_ADDED: 'ENTITIES/TAGS/TAG_ADDED',
  TAG_REMOVED: 'ENTITIES/TAGS/TAG_REMOVED',
};

const TagExtendedActions = {
  tagAdded: (payload: ITagAddedPayload) => createAction(TagExtendedActionNames.TAG_ADDED, payload),
  tagRemoved: (payload: ITagRemovedPayload) => createAction(TagExtendedActionNames.TAG_REMOVED, payload),
};

const TAG = createEntityActionNames('TAG');

export const TagActionNames = {
  ...TAG,
  ...TagExtendedActionNames,
};

export const TagActions = {
  ...createEntityActions<ITag>(TAG),
  ...TagExtendedActions,
};

export type TagAction = ActionsUnion<typeof TagActions>;
