// @ts-strict-ignore
import { Tag } from '@livechat/design-system-icons';

import type { TagFilterItem } from 'interfaces/components/tag/tag-filter';

import type { FilterConfig, FilterLabelValue } from '../interfaces';

export const tagFilterConfigMultiple: FilterConfig = {
  title: 'Tag',
  icon: Tag,
  mobileViewTitle: 'Select tags',
  multiple: true,
  getFilterLabelValues: (filterValues: string[] | null, tags: TagFilterItem[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const tagsHash = tags.reduce((acc, tag) => {
      acc[tag.name] = tag;

      return acc;
    }, {});

    return filterValues.reduce((acc: FilterLabelValue[], tagName) => {
      if (tagsHash[tagName]) {
        acc.push({ key: tagName, label: tagsHash[tagName].name });
      }

      return acc;
    }, []);
  },
};
