export enum SettingsEvent {
  AddTrackerCTAClicked = 'Add tracker CTA clicked',
  AddTrackerConfigurationOpened = 'Add tracker configuration opened',
  AddWebhookButtonClicked = 'Add webhook button clicked',
  BanCustomer = 'Ban visitor clicked',
  ButtonAdded = 'Button added',
  ButtonInstallationPageOpened = 'Button installation page opened',
  ChatPageLogoDisabled = 'Chat page logo disabled',
  ChatPageLogoEnabled = 'Chat page logo enabled',
  ChatPageLogoUpload = 'Chat page logo upload',
  ChatPageTestLinkClicked = 'Chat page test link clicked',
  CTAClicked = 'CTA clicked',
  ChatWithUsCTAClicked = 'Chat with us CTA clicked',
  CloseMessagingModeBannerClicked = 'Close messaging mode banner clicked',
  ConfigurationOpened = 'Configuration opened',
  CopiedToClipboard = 'Copied to clipboard',
  DeleteCTAClicked = 'Delete CTA clicked',
  DirectChatLinkUsed = 'Direct Chat Link Used',
  Disabled = 'Disabled',
  EmailForwardingSaved = 'Email forwarding saved',
  Enabled = 'Enabled',
  EyeCatcherSaved = 'Eye-catcher saved',
  GoToAvailabilityClicked = 'Availability link clicked',
  GoToConfiguratorWebsiteClicked = 'Go to configurator website clicked',
  GoalAdded = 'Goal added',
  GoalDeleted = 'Goal deleted',
  GoalSetAsActive = 'Goal set as active',
  GoalSetAsInactive = 'Goal set as inactive',
  GoalUpdated = 'Goal updated',
  GoalsSorted = 'Goals sorted',
  GoalAddNewClicked = 'Add new goal button clicked',
  GroupChangedInDefaultRule = 'Group changed in default rule',
  LearnMoreMessagingModeBannerClicked = 'Learn more messaging mode banner clicked',
  OnboardingModalClosed = 'Onboarding modal closed',
  OnboardingModalOpened = 'Onboarding modal opened',
  PagePublished = 'Page published',
  PageUnpublished = 'Page unpublished',
  RemoveBannedIP = 'Removed banned IP',
  RuleAdded = 'Rule added',
  RuleDeleted = 'Rule deleted',
  RuleEdited = 'Rule edited',
  SaveButtonClicked = 'Save button clicked',
  SectionOpened = 'Section opened',
  SocialMediaCTAClicked = 'Social media CTA clicked',
  StepOpened = 'Step opened',
  SubscribeHelpDeskClicked = 'Subscribe help desk clicked',
  TellMeMoreButtonClicked = 'Tell me more button clicked',
  TrackerAdded = 'Tracker added',
  TrackerDeleted = 'Tracker deleted',
  TrackerDetailsOpened = 'Tracker details opened',
  TrackerUpdated = 'Tracker updated',
  TrackersSorted = 'Trackers sorted',
  TrackingPixelSettingsOpen = 'Tracking Pixel settings open',
  TryHelpDeskClicked = 'HelpDesk promo button clicked ',
  TryMessagingModeBannerClicked = 'Try messaging mode banner clicked',
  TutorialCTAClicked = 'Tutorial CTA clicked',
}
