// @ts-strict-ignore
import { type IApiAutoAccessRule, type IAutoAccessRule } from 'store/entities/auto-access-rules/interfaces';

export function deserializeAutoAccessRule(rule: Partial<IApiAutoAccessRule>): IAutoAccessRule {
  return {
    id: rule.id,
    description: rule.description,
    ...('access' in rule && {
      access: {
        groups: rule.access.groups,
      },
    }),
    ...('next_id' in rule && { nextId: rule.next_id }),
    ...('conditions' in rule && {
      conditions: {
        ...('url' in rule.conditions && {
          url: {
            ...('values' in rule.conditions.url && {
              values: rule.conditions.url.values.map(({ value, exact_match }) => ({
                value,
                ...(exact_match && { exactMatch: exact_match }),
              })),
            }),
            ...('exclude_values' in rule.conditions.url && {
              excludeValues: rule.conditions.url.exclude_values.map(({ value, exact_match }) => ({
                value,
                ...(exact_match && { exactMatch: exact_match }),
              })),
            }),
          },
        }),
        ...('domain' in rule.conditions && {
          domain: {
            ...('values' in rule.conditions.domain && {
              values: rule.conditions.domain.values.map(({ value, exact_match }) => ({
                value,
                ...(exact_match && { exactMatch: exact_match }),
              })),
            }),
            ...('exclude_values' in rule.conditions.domain && {
              excludeValues: rule.conditions.domain.exclude_values.map(({ value, exact_match }) => ({
                value,
                ...(exact_match && { exactMatch: exact_match }),
              })),
            }),
          },
        }),
        ...('geolocation' in rule.conditions && {
          geolocation: {
            ...('values' in rule.conditions.geolocation && {
              values: rule.conditions.geolocation.values.map(({ country_code, ...rest }) => ({
                ...(country_code && { countryCode: country_code }),
                ...rest,
              })),
            }),
          },
        }),
      },
    }),
  } as IAutoAccessRule;
}

export function serializeAutoAccessRule(rule: Partial<IAutoAccessRule>): IApiAutoAccessRule {
  return {
    id: rule.id,
    description: rule.description,
    ...('access' in rule && {
      access: {
        groups: rule.access.groups,
      },
    }),
    ...(typeof rule.nextId !== 'undefined' && { next_id: rule.nextId }),
    ...('conditions' in rule && {
      conditions: {
        ...('url' in rule.conditions && {
          url: {
            ...('values' in rule.conditions.url && {
              values: rule.conditions.url.values.map(({ value, exactMatch }) => ({
                value,
                ...(exactMatch && { exact_match: exactMatch }),
              })),
            }),
            ...('excludeValues' in rule.conditions.url && {
              exclude_values: rule.conditions.url.excludeValues.map(({ value, exactMatch }) => ({
                value,
                ...(exactMatch && { exact_match: exactMatch }),
              })),
            }),
          },
        }),
        ...('domain' in rule.conditions && {
          domain: {
            ...('values' in rule.conditions.domain && {
              values: rule.conditions.domain.values.map(({ value, exactMatch }) => ({
                value,
                ...(exactMatch && { exact_match: exactMatch }),
              })),
            }),
            ...('excludeValues' in rule.conditions.domain && {
              exclude_values: rule.conditions.domain.excludeValues.map(({ value, exactMatch }) => ({
                value,
                ...(exactMatch && { exact_match: exactMatch }),
              })),
            }),
          },
        }),
        ...('geolocation' in rule.conditions && {
          geolocation: {
            ...('values' in rule.conditions.geolocation && {
              values: rule.conditions.geolocation.values.map(({ countryCode, ...rest }) => ({
                ...(countryCode && { country_code: countryCode }),
                ...rest,
              })),
            }),
          },
        }),
      },
    }),
  } as IApiAutoAccessRule;
}
