import { type Namespace } from 'services/connectivity/configuration-api/properties/constants';
import { type ListLicensePropertiesResponse } from 'services/connectivity/configuration-api/properties/types';

import { useLicenseProperties } from './use-license-properties';

type PropertyValue = ListLicensePropertiesResponse['namespace']['key'];

export type UseLicensePropertyResult<T = PropertyValue> = Omit<ReturnType<typeof useLicenseProperties>, 'data'> & {
  data: T | undefined;
};

/**
 * Custom React hook to get a specific property from license properties.
 *
 * @param namespace - The namespace of the property.
 * @param key - The key of the property.
 * @returns An object containing the license property and query details.
 */
export function useLicenseProperty<T = PropertyValue>(
  namespace: Namespace,
  key: string
): UseLicensePropertyResult<T> {
  const licenseProperties = useLicenseProperties(namespace);

  const property = licenseProperties.data?.[key] as T;

  return {
    ...licenseProperties,
    data: property,
  };
}
