// @ts-strict-ignore
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { broadcastFreeAccessPage, broadcastInitError, broadcastInitialData } from 'entries/base/base';
import { type InitialData } from 'entries/base/interfaces/initial-data';
import { prepareApplicationCore } from 'entries/base/services/application-core/app';
import { fetchStartupData } from 'entries/base/services/initial-data/fetch-startup-data';
import { redirectToAccounts } from 'helpers/redirect-to-accounts';
import { getAccessToken, getExpiresIn, getScopes, isGhostLogin } from 'services/auth/auth-storage-manager';

import { saveCredentials } from './helpers/credentials';

const log = debug(DebugLogsNamespace.AppStartup);

export async function start(): Promise<void> {
  /**
   * Save credentials to the cookies if they exist.
   * They are passed in the url hash by the accounts service.
   * We should save the credentials before doing anything else because we want to stay logged in even after a runtime error occurs.
   */
  saveCredentials();

  log('Starting the application');

  /**
   * Get the credentials if they are present.
   */
  const accessToken = getAccessToken();
  const scopes = getScopes() ?? [];
  const expiresIn = getExpiresIn();
  const isGhost = isGhostLogin();
  const isLoggedIn = !!accessToken;

  log('Credentials', { accessToken, expiresIn, scopes, isGhost, isLoggedIn });

  /**
   * Check if the user is on the logout screen or ticket rating screen.
   */
  const path = window.location.pathname;
  const isLogoutScreen = /\/keep-on-chatting-with-customers$/.test(path);
  const isTicketRatingScreen = /\/ticket_rating\/(.*)\/(.*)\/(.*)$/.test(path);
  const isFreeAccessScreen = isLogoutScreen || isTicketRatingScreen;

  log('Checking free access screen', { isLogoutScreen, isTicketRatingScreen });

  /**
   * We can quickly check that credentials are not present and redirect to accounts.
   */
  if (!isLoggedIn && !isFreeAccessScreen) {
    log('Redirecting to accounts');
    redirectToAccounts();

    return;
  }

  /**
   * Create application core `App` object.
   */
  prepareApplicationCore();

  /**
   * Show logout screen or ticket rating screen for logged out users after refresh.
   * No data will be fetched in this case.
   */
  if (!isLoggedIn && isFreeAccessScreen) {
    broadcastFreeAccessPage(path);

    return;
  }

  /**
   * We are logged in by some credentials (not necessarily valid ones).
   * Try to fetch the most critical data.
   */
  const startupDataResult = await fetchStartupData(isGhost);

  log('Startup data fetch result', startupDataResult);

  if (startupDataResult.success === false) {
    /**
     * Handle the error by showing specific error page or redirecting to accounts.
     */
    broadcastInitError(startupDataResult);

    return;
  }

  const { startupData } = startupDataResult;

  const initialData: InitialData = {
    startupData,
    isGhost,
    accessToken,
    expiresIn,
    scopes,
  };

  /**
   * All good for initialization. The license is ready to be used by logged in agent.
   */
  broadcastInitialData(initialData);
}

void start();
