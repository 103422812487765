import { type IServer } from 'interfaces/server';

let connection: IServer | null = null;

export function setWebsocketConnection(newConnection: IServer): void {
  if (connection) {
    // Only one connection instance is allowed.
    // It doesn't make sense to reinitialize connection object even for reconnects.
    throw new Error('Websocket connection already initialized.');
  }

  connection = newConnection;
}

export function getWebsocketConnection(): IServer {
  if (!connection) {
    throw new Error('Websocket connection not initialized.');
  }

  return connection;
}

export function isWebsocketOpen(): boolean {
  return !!connection && connection.isOpen();
}
