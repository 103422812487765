import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

export const onOffBadge = (isOn: boolean, important?: boolean): string => css`
  min-width: 25px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(${isOn ? DesignToken.DecorGreen300 : DesignToken.DecorGray300});
  ${important ? `color: var(${DesignToken.DecorRed400});` : ''}
`;
