// @ts-strict-ignore
import { memo, type FC } from 'react';

import { cx } from '@emotion/css';

import { anyToBoolean } from 'helpers/boolean';

import * as styles from './styles';

interface IProps {
  selected?: boolean;
  hovered?: boolean;
  className?: string;
}

export const TypingIndicator: FC<IProps> = memo(({ selected, hovered, className }) => (
  <div
    data-test="typing-indicator"
    className={cx(styles.typingIndicator, {
      [styles.hoveredThreadTypingIndicator]: hovered,
      [styles.selectedThreadTypingIndicator]: selected,
      [className]: anyToBoolean(className),
    })}
  >
    <span />
    <span />
    <span />
  </div>
));
