import { followChat as follow } from './web-socket/actions/follow-chat';
import { unfollowChat as unfollow } from './web-socket/actions/unfollow-chat';

/**
 * `ChatFollowManager` will help us to keep track of recently followed chats
 * It should be used for Unassigned and Queued chats, Supervised chats follow...
 * ...should not be limmited for now - the App needs often updates of the chat last message
 */
class ChatFollowManager {
  followedChatsIds: string[] = [];

  setFollowedChatsIds = (ids: string[]): void => {
    this.followedChatsIds = ids;
  };

  isChatFollowed = (chatId: string): boolean => this.followedChatsIds.includes(chatId);

  followChat = (chatId: string): void => {
    if (!this.isChatFollowed(chatId)) {
      /**
       * need to unfollow one of previously followed chats before following a new one
       * TODO: Uncomment when follow mechanism will be implemented correctly  in API ...
       * ... - when all App instances will receive messages about chat unfollow
       */
      // this.unfollowChat();

      void follow(chatId);
      this.followedChatsIds = [...this.followedChatsIds, chatId];
    }
  };

  unfollowChat = (chatId: string): void => {
    if (chatId && this.isChatFollowed(chatId)) {
      void unfollow(chatId);
      this.followedChatsIds = this.followedChatsIds.filter((id) => id !== chatId);
    }
  };
}

export const chatFollowManager = new ChatFollowManager();
