import { agentAssignmentFilterConfig } from 'components/filters/agent-assignment-filter/default-config';
import { agentFilterConfigSingle } from 'components/filters/agent-filter/default-config-single';
import { creationDateRangeFilterConfig } from 'components/filters/creation-date-range-filter/default-config';
import { dateRangeFilterConfig } from 'components/filters/date-range-filter/default-config';
import { groupFilterConfigSingle } from 'components/filters/group-filter/default-config-single';
import { type AvailableFilter } from 'components/filters/interfaces';
import { ratingDateRangeFilterConfig } from 'components/filters/rating-date-range-filter/default-config';
import { ratingFilterConfig } from 'components/filters/rating-filter/default-config';
import { resolutionDateRangeFilterConfig } from 'components/filters/resolution-date-range-filter/default-config';
import { responseAuthorFilterConfig } from 'components/filters/response-author-filter/default-config';
import { responseDateRangeFilterConfig } from 'components/filters/response-date-range-filter/default-config';
import { tagFilterConfigSingle } from 'components/filters/tag-filter/default-config-single';
import { ticketStatusFilterConfig } from 'components/filters/ticket-status-filter/default-config';
import { Filter } from 'constants/filters/filter';
import { RatingFilter } from 'constants/filters/rating-filter';

import { Route } from './navigation-items';

const filters: { [key: string]: AvailableFilter } = {
  dateRange: { filter: Filter.DateRange, config: dateRangeFilterConfig },
  agent: {
    filter: Filter.Agent,
    config: {
      ...agentFilterConfigSingle,
      title: 'Assignee',
    },
  },
  agentAssignment: { filter: Filter.AgentAssignment, config: agentAssignmentFilterConfig },
  creationDateRange: { filter: Filter.CreationDateRange, config: creationDateRangeFilterConfig, readOnly: true },
  group: { filter: Filter.Group, config: groupFilterConfigSingle },
  tag: { filter: Filter.Tag, config: tagFilterConfigSingle },
  rating: {
    filter: Filter.Rating,
    config: {
      ...ratingFilterConfig,
      filterLabels: [
        { value: RatingFilter.Rated, label: 'Rated' },
        { value: RatingFilter.NotRated, label: 'Not rated' },
        { value: RatingFilter.RatedBad, label: 'Rated bad' },
        { value: RatingFilter.RatedGood, label: 'Rated good' },
      ],
    },
  },
  ratingDateRange: { filter: Filter.RatingDateRange, config: ratingDateRangeFilterConfig, readOnly: true },
  resolutionDateRange: { filter: Filter.ResolutionDateRange, config: resolutionDateRangeFilterConfig, readOnly: true },
  responseDateRange: { filter: Filter.ResponseDateRange, config: responseDateRangeFilterConfig, readOnly: true },
  responseAuthor: { filter: Filter.ResponseAuthor, config: responseAuthorFilterConfig, readOnly: true },
  status: { filter: Filter.TicketStatus, config: ticketStatusFilterConfig },
};

const readOnlyFilters = [
  filters.ratingDateRange,
  filters.resolutionDateRange,
  filters.responseDateRange,
  filters.creationDateRange,
  filters.responseAuthor,
];

// prettier-ignore
export const availableFiltersRouteMap: { [key in Route]: AvailableFilter[] } = {
  [Route.Unassigned]: [filters.dateRange, filters.tag, filters.rating].concat(readOnlyFilters),
  [Route.MyOpen]: [filters.dateRange, filters.tag, filters.rating].concat(readOnlyFilters),
  [Route.Open]: [filters.dateRange, filters.agent, filters.group, filters.agentAssignment, filters.tag, filters.rating].concat(readOnlyFilters),
  [Route.Pending]: [filters.dateRange, filters.agent, filters.group, filters.agentAssignment, filters.tag, filters.rating].concat(readOnlyFilters),
  [Route.Solved]: [filters.dateRange, filters.agent, filters.group, filters.agentAssignment, filters.rating, filters.tag].concat(readOnlyFilters),
  [Route.Spam]: [filters.dateRange, filters.agent, filters.group, filters.tag].concat(readOnlyFilters),
  [Route.Search]: [filters.dateRange, filters.status, filters.agent, filters.group, filters.rating, filters.tag].concat(readOnlyFilters),
  [Route.All]: [filters.dateRange, filters.status, filters.agent, filters.group, filters.agentAssignment, filters.rating, filters.tag].concat(readOnlyFilters)
};
