export enum CustomerDetailsPlacement {
  Chats = 'Chats',
  Customers = 'Customers',
  Archives = 'Archives',
  Tickets = 'Tickets',
}

export enum CustomerDetailsDefaultSectionEnum {
  GeneralInfo = 'generalInfo',
  VisitedPages = 'visitedPages',
  AdditionalInfo = 'additionalInfo',
  ChatSummary = 'chatSummary',
  Prechat = 'prechat',
  Postchat = 'postchat',
  Tickets = 'tickets',
  Technology = 'technology',
  IntegrationsData = 'integrationsData',
  Facebook = 'facebook',
}

export enum CustomerDetailsDefaultSectionTitleEnum {
  GeneralInfo = 'General info',
  Tickets = 'Tickets',
  IntegrationsData = 'Integrations data',
  Facebook = 'Facebook',
  Prechat = 'Pre-chat form',
  Postchat = 'Post-chat form',
  VisitedPages = 'Visited pages',
  AdditionalInfo = 'Additional info',
  Technology = 'Technology',
  ChatSummary = 'Chat summary',
}

enum CustomerDetailsDefaultSectionSelected {
  GeneralInfo = 'generalInfo',
  VisitedPages = 'visitedPages',
  AdditionalInfo = 'additionalInfo',
}

export const CUSTOMER_DETAILS_DEFAULT_SECTIONS: string[] = Object.keys(CustomerDetailsDefaultSectionEnum).map(
  (key) => CustomerDetailsDefaultSectionEnum[key] as string,
);

export const CUSTOMER_DETAILS_DEFAULT_SECTIONS_SELECTED: string[] = Object.keys(
  CustomerDetailsDefaultSectionSelected,
).map((key) => CustomerDetailsDefaultSectionSelected[key] as string);

export const CUSTOMER_DETAILS_WIDGET_ID = 'customer_details';
export const MARKETPLACE_WIDGET_ID = 'marketplace';
