import { type FC } from 'react';

import { OpenInNewFilled } from '@livechat/design-system-icons';
import { Button, Icon, Text } from '@livechat/design-system-react-components';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { openInNewCard } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';

import { COPILOT_HELP_CENTER_LINK } from './constants';
import { CopilotAccordion } from './CopilotAccordion';

import { aboutCopilotText } from './styles';

const handleLearnMore = (): void => {
  openInNewCard(COPILOT_HELP_CENTER_LINK);
  trackEvent(CopilotTrackEvent.LearnMoreClicked, EventPlace.One);
};

export const AboutCopilotAccordion: FC = () => (
  <CopilotAccordion title="About Copilot" openOnInit>
    <Text className={aboutCopilotText}>Your source of information on your own business and LiveChat.</Text>

    <Button icon={<Icon source={OpenInNewFilled} />} iconPosition="right" kind="link-light" onClick={handleLearnMore}>
      <strong>Learn more</strong>
    </Button>
  </CopilotAccordion>
);
