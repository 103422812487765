// @ts-strict-ignore
import { Calendar } from '@livechat/design-system-icons';

import { type Weekday } from 'constants/filters/weekday-filter';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

import { type ISingleItem } from './weekday-filter';
import { filterBoxStyles, filterButtonStyles } from './WeekdayFilter';

export const weekdayFilterConfig: FilterConfig = {
  title: 'Weekday',
  icon: Calendar,
  multiple: false,
  getFilterLabelValues: (filterValues: Weekday | null, items: ISingleItem[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const selectedItem = items.find((item) => item.props.value === filterValues);

    return [
      {
        key: filterValues,
        label: selectedItem && selectedItem.props.label,
      },
    ];
  },
  getFilterBoxClassName: (filterValue: Weekday) => {
    return filterValue && filterBoxStyles;
  },
  getFilterButtonClassName: (filterValue: Weekday) => {
    return filterValue && filterButtonStyles;
  },
};
