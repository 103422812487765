// @ts-strict-ignore
import type { Product } from 'interfaces/product-cart';
import {
  type IWithRequestsState,
  createRequestErrorSelector,
  createRequestFetchingSelector,
} from 'store/requests/selectors';

import { BillingRequestFetchingSelectorsNames } from './actions';
import { type IBillingInfo, type IBillingState } from './interfaces';

const EMPTY_ARRAY: Product[] = [];

export interface IWithBillingEntitiesState {
  entities: {
    billing: IBillingState;
  };
}

export const getIsSavingCreditCard = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector([BillingRequestFetchingSelectorsNames.ADD_CREDIT_CARD_REQUEST])(state);

export const getCreditCardError = (state: IWithRequestsState): string =>
  createRequestErrorSelector([BillingRequestFetchingSelectorsNames.ADD_CREDIT_CARD_REQUEST])(state);

export const getBillingInfo = (state: IWithBillingEntitiesState): IBillingInfo | null =>
  state.entities.billing?.billingInfo;

export const getRecurringPaymentApplications = (state: IWithBillingEntitiesState): Product[] =>
  state.entities.billing?.recurrentPaymentApplications || EMPTY_ARRAY;

export const getApplicationsIncludedOnReceipt = (state: IWithBillingEntitiesState): Product[] =>
  getRecurringPaymentApplications(state).filter(({ isMarkedForUninstall }) => !isMarkedForUninstall);

export const getApplicationsToUninstall = (state: IWithBillingEntitiesState): Product[] =>
  getRecurringPaymentApplications(state).filter(({ isMarkedForUninstall }) => isMarkedForUninstall);

export const getHasApplicationsToUninstall = (state: IWithBillingEntitiesState): boolean =>
  !!getApplicationsToUninstall(state).length;
