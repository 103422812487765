import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { SETTINGS_NAVIGATION_PATHS } from 'constants/copilot';
import { reportViewsDetails } from 'constants/reports/report-type';
import { Section } from 'constants/section';
import { TeamSubPaths } from 'constants/team/routes';
import { removeDuplicates } from 'helpers/array';
import { capitalizeFirstLetter } from 'helpers/string';
import { getCurrentSection } from 'store/features/routing/selectors';
import { getCurrentView } from 'store/views/reports/selectors';
import { getPageTitle, getRecentPage } from 'store/views/settings/selectors';
import { getCurrentTeamPage } from 'store/views/team/selectors';

function formatTitle(inputString: string): string {
  if (!inputString) {
    return '';
  }

  const words = inputString.split('-');
  const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));
  const formattedString = capitalizedWords.join(' ');

  return formattedString;
}

function getSettingsCategoryName(path: string): string | null {
  for (const [section, paths] of Object.entries(SETTINGS_NAVIGATION_PATHS)) {
    if (paths.includes(path)) {
      return section;
    }
  }

  return null;
}

function arrayToContextString(arr: (string | null)[]): string {
  const filteredArr = arr.filter((item): item is string => !!item);
  const withLivechatProduct = removeDuplicates(['LiveChat', ...filteredArr]);

  return withLivechatProduct.map((item) => capitalizeFirstLetter(item)).join(' > ');
}

function getUserContextString(userSections: (string | null)[]): string {
  return `User is currently in the ${arrayToContextString(userSections)}`;
}

type UseCopilotContext = {
  getAppContext: () => string;
  appContext: string;
};

export const useCopilotContext = (): UseCopilotContext => {
  const currentSection = useSelector(getCurrentSection);
  const currentTeamPage = useSelector(getCurrentTeamPage);
  const currentReportView = useSelector(getCurrentView);
  const currentPageTitle = useSelector(getPageTitle);
  const recentSettingsPage = useSelector(getRecentPage);

  const getAppContext = useCallback((): string => {
    switch (currentSection) {
      case Section.EngageTraffic: {
        return getUserContextString(currentSection.split('/'));
      }

      case Section.EngageCampaigns: {
        return getUserContextString(currentSection.split('/'));
      }

      case Section.EngageGoals: {
        return getUserContextString(currentSection.split('/'));
      }

      case Section.Team: {
        if (currentTeamPage === null) {
          return '';
        }

        const teamView = currentTeamPage === TeamSubPaths.Agents ? 'agents' : currentTeamPage.split(`/`)[1];
        const userSections = [currentSection, formatTitle(teamView)];

        return getUserContextString(userSections);
      }

      case Section.Reports: {
        if (!currentReportView) {
          return '';
        }

        const reportCategory = reportViewsDetails[currentReportView]?.category;
        const userSections = [currentSection, formatTitle(currentReportView as string), reportCategory];

        return getUserContextString(userSections);
      }

      case Section.Settings: {
        const userSections = [currentSection, getSettingsCategoryName(recentSettingsPage)];
        if (typeof currentPageTitle === 'string') {
          userSections.push(currentPageTitle);
        }

        return getUserContextString(userSections);
      }

      default: {
        return getUserContextString([currentSection as string]);
      }
    }
  }, [currentSection, currentTeamPage, currentReportView, recentSettingsPage, currentPageTitle]);

  const appContext = useMemo(() => getAppContext(), [getAppContext]);

  return { getAppContext, appContext };
};
