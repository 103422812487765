import { PlanType } from 'constants/plan-type';
import { type ISubscription } from 'interfaces/subscription';
import { AutoAccessRulesRequestActions } from 'store/entities/auto-access-rules/actions';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { type IActionWithPayload, getRequestActionName, type IWithIdOrError } from 'store/helper';

import type { IRequestsState } from './interfaces';

const initialState = {};

export const requestsReducer = (
  state: IRequestsState = initialState,
  action: IActionWithPayload<string, IWithIdOrError>
) => {
  const { type } = action;
  const targetState = { ...state };

  switch (action.type) {
    case SubscriptionActionNames.UPGRADE_SUBSCRIPTION_SUCCESS:
    case SubscriptionActionNames.UPDATE_SUBSCRIPTION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const payload = action.payload as ISubscription;

      if (payload?.plan !== PlanType.Starter) {
        delete targetState[AutoAccessRulesRequestActions.FETCH_COLLECTION];
      }
  }

  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  const requestActionName = action.payload ? getRequestActionName(requestName, action.payload) : requestName;

  return {
    ...targetState,
    [requestActionName]: {
      isFetching: requestState === 'REQUEST',
      error: requestState === 'FAILURE' ? action.payload?.error || 'Unknown error' : null,
    },
  };
};
