export const enum ProfileMenuEvent {
  ChecklistClosedOnLogout = 'Checklist closed on log out',
  KeyboardShortcutsClicked = 'Keyboard shortcuts clicked',
  MenuOpened = 'Menu opened',
  NewsAndUpdatesClicked = 'News & updates clicked',
  NotificationsPreferencesClicked = 'Notification preferences clicked',
  OtherProductLinkClicked = 'Other product link clicked',
  ProfileSettingsChanged = 'Profile settings changed',
  StatusChangedToOffline = 'Status changed to offline',
  StatusChangedToOnline = 'Status changed to online',
  SwitchedToDesktopView = 'Switched to desktop view',
  SwitchedToMobileView = 'Switched to mobile view',
  ViewProfileClicked = 'View profile clicked',
  CompanyDetailsClicked = 'Company details clicked',
}
