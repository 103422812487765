import React, { createContext, useEffect, type ReactNode } from 'react';

import { type ConnectivityStatusInstance } from 'services/connectivity/status/types';

interface ConnectivityStatusProviderProps {
  instance: ConnectivityStatusInstance;
  children: ReactNode;
}

export const ConnectivityStatusContext = createContext<ConnectivityStatusInstance | null>(null);

export const ConnectivityStatusProvider: React.FC<ConnectivityStatusProviderProps> = ({ instance, children }) => {
  useEffect(() => {
    void instance.initialize();

    return () => {
      instance.destroy();
    };
  }, [instance]);

  return <ConnectivityStatusContext.Provider value={instance}>{children}</ConnectivityStatusContext.Provider>;
};
