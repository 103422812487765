import { JSONIncomingEvent } from 'constants/socket';
import { AppStateProvider, type IAppState } from 'services/app-state-provider';
import { triggerLastActivity } from 'services/socket-activity-logger';

import { handleCannedResponseAdd } from './canned-response/event-handling/canned-response-add';
import { handleCannedResponseRemove } from './canned-response/event-handling/canned-response-remove';
import { handleCannedResponseUpdate } from './canned-response/event-handling/canned-response-update';
import { handleChatDetailsUpdate } from './chat/event-handling/chat-details-update';
import { handleChatRateComment } from './chat/event-handling/chat-rate-comment';
import { handleChatStatusChange } from './chat/event-handling/chat-status-change';
import { handleCustomerStateChangeToQueued } from './chat/event-handling/customer-state-change-to-queued';
import { handleIncomingChatAttachment } from './chat/event-handling/incoming-chat-attachment';
import { handleIncomingPostChat } from './chat/event-handling/incoming-post-chat-survey';
import { handleMessageSeen } from './chat/event-handling/message-seen';
import { handleSendMessageResponse } from './chat/event-handling/send-message-response';
import { handleGroupPropertiesUpdate } from './settings/event-handling/group-properties-update';

const jsonEventHandlers: Record<string, EventHandler> = {
  [JSONIncomingEvent.CannedResponseAdd]: handleCannedResponseAdd,
  [JSONIncomingEvent.CannedResponseRemove]: handleCannedResponseRemove,
  [JSONIncomingEvent.CannedResponseUpdate]: handleCannedResponseUpdate,
  [JSONIncomingEvent.ChatDetailsUpdate]: handleChatDetailsUpdate,
  [JSONIncomingEvent.ChatStatusChange]: handleChatStatusChange,
  [JSONIncomingEvent.GroupPropertiesUpdate]: handleGroupPropertiesUpdate,
  [JSONIncomingEvent.IncomingChatAttachment]: handleIncomingChatAttachment,
  [JSONIncomingEvent.IncomingPostChatSurvey]: handleIncomingPostChat,
  [JSONIncomingEvent.MessageSeen]: handleMessageSeen,
  [JSONIncomingEvent.ChatRateComment]: handleChatRateComment,
  [JSONIncomingEvent.SendMessageResponse]: handleSendMessageResponse,
  [JSONIncomingEvent.QueuedVisitor]: handleCustomerStateChangeToQueued,
};

type EventHandler = (payload: any, store: IAppState) => void;

export function handleJSONIncomingEvent(eventName: JSONIncomingEvent, payload: any): void {
  triggerLastActivity();
  const handler: EventHandler = jsonEventHandlers[eventName];

  if (handler) {
    handler(payload, AppStateProvider);
  }
}
