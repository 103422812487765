import { TitleImageSize } from 'constants/custom-section';

function isValidImgSize(imgSize: TitleImageSize | undefined): boolean {
  if (typeof imgSize === 'undefined' || [TitleImageSize.Big, TitleImageSize.Small].includes(imgSize)) {
    return true;
  }

  return false;
}

export function isValidTitleDefinition(rawDefinitionData: unknown): boolean {
  if (!rawDefinitionData) {
    return false;
  }

  const { title, imgSize } = rawDefinitionData as Record<string, unknown>;

  return Boolean(title && isValidImgSize(imgSize as TitleImageSize));
}
