// @ts-strict-ignore
import { getTime, isAfter, isEqual } from 'date-fns';

import { ChatType } from 'constants/chat-type';
import { UserType } from 'constants/user-type';
import { type KeyMap } from 'helpers/interface';
import { AppStateProvider } from 'services/app-state-provider';
import { getPropertyValue } from 'services/serialization/property';
import { type ChatEventAuthor } from 'store/entities/chats/interfaces';
import { type IWithChatsEntityState, getThreadType } from 'store/entities/chats/selectors';

export interface IEventDetails {
  timestampInMs: number;
  authorId: string;
}

export interface ICurrentAgent {
  id: string;
  userType: UserType;
}

/**
 * Returns last seen timestamps for:
 * * Customer,
 * * Any active Agent (we assume there might be multiple),
 * * Currently logged in agent.
 * @param chatUsers Chat users list with information about last seen timestamp and presence in the chat.
 * @param currentAgentId Id of currently logged in agent.
 */
export function getLastSeenParticipantsTimestamp(chatUsers: any[], currentAgentId: string): KeyMap<number> {
  /**
   * We're interested if any active agent (non-supervisor) has seen the event.
   */
  const agentWithLatestTimestamp = Math.max(
    ...chatUsers
      .filter((user): boolean => user.events_seen_up_to && user.present && user.type === UserType.Agent)
      .map((agent): number => getTime(agent.events_seen_up_to))
  );

  const currentAgentLatestTimestamp = Math.max(
    ...chatUsers
      .filter((user): boolean => user.events_seen_up_to && user.id === currentAgentId && user.type === UserType.Agent)
      .map((agent): number => getTime(agent.events_seen_up_to))
  );

  const customerWithLatestTimestamp = Math.max(
    ...chatUsers
      .filter((user): boolean => user.events_seen_up_to && user.type === UserType.Customer)
      .map((customer): number => getTime(customer.events_seen_up_to))
  );

  const currentTimestamp = new Date().getTime();

  /**
   * If there is no lastSeenAgentTimestamp use current time
   * e.g transfer w/e agent interaction with chat
   * * Agent didn't read messages, did insta tranfer to another agent.
   * * chat w/e pre-chat.
   */
  const latestLastSeenTimestampOfAllAgents = agentWithLatestTimestamp > 0 ? agentWithLatestTimestamp : currentTimestamp;
  const latestLastSeenTimestampOfCurrentAgent =
    currentAgentLatestTimestamp > 0 ? currentAgentLatestTimestamp : currentTimestamp;
  const latestLastSeenTimestampOfCustomers = customerWithLatestTimestamp || null;

  return {
    latestLastSeenTimestampOfAllAgents,
    latestLastSeenTimestampOfCurrentAgent,
    latestLastSeenTimestampOfCustomers,
  };
}

/**
 * Returns true if the event was seen by another chatting user.
 * The chatting user differs based on the kind of current Agent.
 * We treat active Agent (my chat) and Supervisor differently.
 * For active Agent:
 * * We're interested if Customer has seen the event being authored by the Agent.
 * For Supervisor:
 * * We're interested if Agent has seen the event being authored by the Supervisor.
 * For events not being authored by current agent:
 * * We're checking if current agent has seen the event.
 * @param event Event to be analyzed.
 * @param currentAgent Details about current agent (Agent or Supervisor).
 * @param chatUsers List of chat users with their activity timestamps.
 */
export function getWasEventSeen(event: IEventDetails, currentAgent: ICurrentAgent, chatUsers: any[]): boolean {
  const {
    latestLastSeenTimestampOfAllAgents,
    latestLastSeenTimestampOfCustomers,
    latestLastSeenTimestampOfCurrentAgent,
  } = getLastSeenParticipantsTimestamp(chatUsers, currentAgent.id);

  const isCurrentAgentEvent = event.authorId === currentAgent.id;

  let lastSeenTimestamp = latestLastSeenTimestampOfCurrentAgent;

  if (isCurrentAgentEvent) {
    lastSeenTimestamp =
      currentAgent.userType === UserType.Agent
        ? latestLastSeenTimestampOfCustomers
        : latestLastSeenTimestampOfAllAgents;
  }

  const wasEventSeen =
    lastSeenTimestamp &&
    event.timestampInMs &&
    (isEqual(lastSeenTimestamp, event.timestampInMs) || isAfter(lastSeenTimestamp, event.timestampInMs));

  return Boolean(wasEventSeen);
}

export function isContinuousChatThread(chatProperties: any): boolean {
  const isPinned = getPropertyValue(chatProperties, 'routing', 'pinned') === true;
  const isContinuous = getPropertyValue(chatProperties, 'routing', 'continuous') === true;

  return isPinned && isContinuous;
}

export function getNotDeliveredEventAuthorType(
  threadId: string,
  curentState: IWithChatsEntityState = AppStateProvider.getState()
): ChatEventAuthor {
  const threadType = getThreadType(curentState, threadId);

  if (!threadType) {
    return null;
  }

  if (threadType === ChatType.Supervised) {
    return UserType.Supervisor;
  }

  return UserType.Agent;
}
