import { type FC, useCallback, useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useSelector } from 'react-redux';

import { useEffectOnce } from 'hooks/use-effect-once';
import { useParamSelector } from 'hooks/use-param-selector';
import { usePrevious } from 'hooks/use-previous';
import { CopilotEmitterEvent, copilotEventEmitter } from 'services/copilot-event-emitter';
import { getIsAIAgentAvailableByName } from 'store/entities/ai-agents/selectors';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

export const AutoFocusPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const previousExpanded = usePrevious(isExpanded);
  const hasCopilotAIAgent = useParamSelector(getIsAIAgentAvailableByName, 'one');

  const handleFocus = useCallback((): void => {
    editor.focus();
  }, [editor]);

  useEffect(() => {
    if (isExpanded !== previousExpanded) {
      handleFocus();
    }
  }, [isExpanded, previousExpanded, handleFocus]);

  useEffectOnce(() => {
    copilotEventEmitter.on(CopilotEmitterEvent.FocusCopilotMessageBox, handleFocus);

    return () => {
      copilotEventEmitter.removeListener(CopilotEmitterEvent.FocusCopilotMessageBox, handleFocus);
    };
  });

  useEffect(() => {
    editor.setEditable(hasCopilotAIAgent);
  }, [editor, hasCopilotAIAgent]);

  return null;
};
