import { type KeyMap } from 'helpers/interface';
import { type ICustomVariable } from 'interfaces/entities/archive';

import { getConfig } from './config';

enum CustomerDetailsField {
  City = 'city',
  Country = 'country',
  Email = 'email',
  Host = 'host',
  Id = 'id',
  IP = 'ip',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  Region = 'region',
  PreChatSurvey = 'stringifiedPreChatSurvey',
  Timezone = 'timezone',
}

const fieldMarkerMap = {
  [CustomerDetailsField.City]: '{!Visitor_City}',
  [CustomerDetailsField.Country]: '{!Visitor_Country}',
  [CustomerDetailsField.Email]: '{!Visitor_Mail}',
  [CustomerDetailsField.Host]: '{!Visitor_Host}',
  [CustomerDetailsField.Id]: '{!Visitor_ID}',
  [CustomerDetailsField.IP]: '{!Visitor_IP}',
  [CustomerDetailsField.Latitude]: '{!Visitor_Latitude}',
  [CustomerDetailsField.Longitude]: '{!Visitor_Longitude}',
  [CustomerDetailsField.Name]: '{!Visitor_Nick}',
  [CustomerDetailsField.Region]: '{!Visitor_Region}',
  [CustomerDetailsField.PreChatSurvey]: '{!Visitor_Prechat}',
  [CustomerDetailsField.Timezone]: '{!Visitor_Timezone}',
};

export interface IIntegrationCustomerDetails {
  [CustomerDetailsField.City]?: string;
  [CustomerDetailsField.Country]?: string;
  [CustomerDetailsField.Email]: string;
  [CustomerDetailsField.Host]?: string;
  [CustomerDetailsField.Id]: string;
  [CustomerDetailsField.IP]: string;
  [CustomerDetailsField.Latitude]?: string;
  [CustomerDetailsField.Longitude]?: string;
  [CustomerDetailsField.Name]: string;
  [CustomerDetailsField.Region]?: string;
  [CustomerDetailsField.PreChatSurvey]?: string;
  [CustomerDetailsField.Timezone]?: string;
}

function replaceCustomerMarkers(url: string, customerDetails: IIntegrationCustomerDetails) {
  const fieldMarkerPairs = Object.entries(fieldMarkerMap);

  return fieldMarkerPairs.reduce(
    (acc: string, [field, marker]) =>
      acc.replace(marker, customerDetails[field] ? encodeURIComponent(customerDetails[field]) : ''),
    url,
  );
}

function replaceIdMarkers(url: string, chatId: string, licenseId: number, agentId: string): string {
  return url
    .replace('{!Visitor_ConferenceID}', chatId)
    .replace('{!Licence_ID}', encodeURIComponent(licenseId.toString()))
    .replace('{!User_ID}', encodeURIComponent(agentId))
    .replace('{!User_ContactID}', encodeURIComponent(agentId));
}

/**
 * Temporary method to encapsulate App.protocol usage to be replaced by redux action.
 */
export function getRegisteredIntegrationTicket(): string {
  return new Date().getTime().toString();
}

export function replaceIntegrationUrlMarkers(
  integrationBaseUrl: string,
  threadId: string,
  licenseId: number,
  agentId: string,
  customerDetails: IIntegrationCustomerDetails,
): string {
  let url = replaceCustomerMarkers(integrationBaseUrl, customerDetails);
  url = replaceIdMarkers(url, threadId, licenseId, agentId);

  return url;
}

export function getCustomVariablesMap(customVariables?: ICustomVariable[]): KeyMap<string> {
  const customVariableKeys = (customVariables || []).map((customVariable) => customVariable.key);

  if (!customVariableKeys.length) {
    return {};
  }

  return (customVariables || []).reduce(
    (acc, customVariable) => {
      acc[`cv__${customVariable.key}`] = customVariable.value;

      return acc;
    },
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      custom_variable_keys: customVariableKeys.map((key) => `cv__${key}`).join(','),
    },
  );
}

export const getHelpDeskAppId = (): string => getConfig().helpdeskAppId;

export const getWhatsAppBusinessId = (): string => getConfig().whatsappBusinessAppId;

export const getTwilioAppId = (): string => getConfig().twilioAppId;

export const getTelegramBusinessAppId = (): string => getConfig().telegramBusinessAppId;
