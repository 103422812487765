import { type SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';

import { ApplicationsActionNames } from 'store/features/applications/actions';
import { type IActionWithPayload } from 'store/helper';

import { BillingActions } from './actions';

function* filterUninstalledApplication({ payload }: IActionWithPayload<string, { id: string }>): SagaIterator {
  yield put(
    BillingActions.cancelApplicationRecurringSuccess({
      applicationId: payload.id,
    })
  );
}

export function* billingSagas(): SagaIterator {
  yield takeEvery(ApplicationsActionNames.UNINSTALL_APPLICATION_SUCCESSFUL, filterUninstalledApplication);
}
