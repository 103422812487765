import { css } from '@emotion/css';

interface IBadgeOptions {
  badgeBackground: string;
  textColor: string;
}

export const badge = ({ badgeBackground, textColor }: IBadgeOptions): string => css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  height: 18px;
  padding: 2px 4px;
  border-radius: 50px;
  background-color: ${badgeBackground};
  box-sizing: border-box;

  span {
    position: relative;
    font-size: 10px;
    font-weight: 700;
    color: ${textColor};
    opacity: 1 !important;
  }
`;

export const whiteDot = css`
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background-color: #fff;
`;
