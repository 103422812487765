import { LEGEND_WITHOUT_SERIES, ExtraPalettes } from 'constants/reports/chart-color-palette';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { getSeriesDataFromPeriod, sumValueFromPeriod } from 'helpers/reports';

import { type ChartData } from '../../../../interfaces/reports';

export function ratingDeserializer(ratingResponse: any): ChartData {
  const ratedGood = sumValueFromPeriod(ratingResponse, 'good');
  const ratedBad = sumValueFromPeriod(ratingResponse, 'bad');
  const average = getFormattedPercent(ratedGood / (ratedGood + ratedBad));

  const chartDataFromRatedGood = getSeriesDataFromPeriod<number>(ratingResponse, 'good');
  const chartDataFromRatedBad = getSeriesDataFromPeriod<number>(ratingResponse, 'bad');

  const labelMapper = (value: number, index: number): string =>
    `(${getFormattedPercent(value / (chartDataFromRatedGood[index] + chartDataFromRatedBad[index]), 0)})`;

  return {
    summary: [
      {
        name: 'Average satisfaction rate',
        value: average,
        urlParams: {
          rate: 'rated',
        },
        color: LEGEND_WITHOUT_SERIES,
        displayAsPercentageCircle: true,
      },
      {
        name: 'Rated good',
        value: ratedGood,
        color: ExtraPalettes.Positive,
        label: `(${getFormattedPercent(ratedGood / (ratedGood + ratedBad), 2)})`,
        urlParams: {
          rate: 'rated_good',
        },
      },
      {
        name: 'Rated bad',
        value: ratedBad,
        color: ExtraPalettes.Negative,
        label: `(${getFormattedPercent(ratedBad / (ratedGood + ratedBad), 2)})`,
        urlParams: {
          rate: 'rated_bad',
        },
      },
    ],
    labels: Object.keys(ratingResponse),
    series: [
      {
        name: 'Rated good',
        slug: 'rated_good',
        data: chartDataFromRatedGood,
        label: chartDataFromRatedGood.map(labelMapper),
        color: ExtraPalettes.Positive,
      },
      {
        name: 'Rated bad',
        slug: 'rated_bad',
        data: chartDataFromRatedBad,
        label: chartDataFromRatedBad.map(labelMapper),
        color: ExtraPalettes.Negative,
      },
    ],
  };
}
