import { type IOutboxMessage, type INormalizedOutboxMessage } from './outbox-message';

export function createOutboxMessage<T extends IOutboxMessage = IOutboxMessage>(
  title: string,
  pluginId: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any = null,
): T {
  return { title, data, pluginId } as T;
}

export function normalizeOutboxMessage(message: IOutboxMessage): INormalizedOutboxMessage {
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_name: message.title,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_data: message.data,
  };
}
