import type { Task } from 'redux-saga';
import type { ActionPattern } from 'redux-saga/effects';

import type { Filters } from 'constants/filters/filter';
import type { IKeyValue } from 'helpers/url';

export enum UrlQueryParamsView {
  Archives,
  Tickets,
  Reports,
  Subscription,
}

export enum UpdateQueryTrigger {
  QueryParamsChange,
}

export interface IUrlQueryParamsFeatureRegisterViewPayload {
  view: UrlQueryParamsView;
  updateQueryActions?: ActionPattern;
  replaceHistoryActions?: ActionPattern;
  updateQueryTrigger?: UpdateQueryTrigger;
  shouldSetQueryParamsOnRegister?: boolean;
  shouldSetQueryParamsOnEmptyPath?: boolean;
  isPathMatchingPattern?(pathname: string): boolean;
  setQueryParamsAction?(payload: { params: IKeyValue<string> });
  queryStringSerializer?(state: IWithViewQueryParams, pathname?: string): string;
  queryStringParser?(params: IKeyValue<string>, pathname?: string): IKeyValue<string>;
}

export interface IUrlQueryParamsFeatureUnregisterViewPayload {
  view: UrlQueryParamsView;
}

export interface IWithViewQueryParams {
  views: {
    archivesView?: {
      filters: Filters;
      searchPhrase: string;
    };
    tickets?: {
      filters: Filters;
    };
  };
}

export interface IViewRegistryItem {
  updateQueryActions?: ActionPattern;
  replaceHistoryActions?: ActionPattern;
  updateQueryTrigger?: UpdateQueryTrigger;
  updateUrlQueryParams: Task;
  shouldSetQueryParamsOnEmptyPath: boolean;
  isPathMatchingPattern?(pathname: string): boolean;
  setQueryParamsAction(payload: { params: IKeyValue<string> });
  queryStringSerializer(state: IWithViewQueryParams, pathname?: string): string;
  queryStringParser(params: IKeyValue<string>, pathname?: string): IKeyValue<string>;
}

export interface IViewRegistry {
  [key: string]: IViewRegistryItem;
}
