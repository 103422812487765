import { type FC } from 'react';

import { useDispatch } from 'react-redux';

import { uniqueId } from 'helpers/string';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';

import { STATIC_DESCRIPTION, STATIC_MESSAGE, STATIC_QUESTION, STATIC_SUGGESTION_DELAY } from './constants';
import { Suggestion } from './Suggestion';

export const StaticSuggestion: FC = () => {
  const dispatch = useDispatch();

  const handleOnClick = (): void => {
    dispatch(
      CopilotEntitiesActions.addCopilotConversation([
        {
          event: {
            authorId: 'agent',
            authorType: 'agent',
            eventId: uniqueId(),
            text: STATIC_QUESTION,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
          delay: STATIC_SUGGESTION_DELAY,
        },
        {
          event: {
            authorId: 'live-assistant',
            authorType: 'live-assistant',
            eventId: uniqueId(),
            text: STATIC_MESSAGE,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
        },
      ]),
    );
  };

  return <Suggestion id={4} message={STATIC_QUESTION} description={STATIC_DESCRIPTION} onClick={handleOnClick} />;
};
