import { type IConnection } from './connection';

interface IPoolConnection {
  id: string;
  connection: IConnection;
}

export class ConnectionPool {
  private connectionPool: IPoolConnection[] = [];

  add(id: string, connection: IConnection) {
    this.connectionPool.push({ id, connection });
  }

  remove(connectionId: string) {
    this.connectionPool = this.connectionPool.filter((c) => c.connection.connectionId !== connectionId);
  }

  get(id: string) {
    // Due to multiple instances of iframes per widget we need
    // to take the last one. This is a hack and will require
    // refactor to provide one iframe per widget.
    const conn = this.connectionPool.filter((c) => c.id === id).pop();
    if (!conn) {
      return null;
    }

    return conn.connection;
  }

  getByConnectionId(id: string) {
    const [conn] = this.connectionPool.filter((c) => c.connection.connectionId === id);
    if (!conn) {
      return null;
    }

    return conn.connection;
  }

  getAll() {
    return this.connectionPool;
  }
}

export default new ConnectionPool();
