// @ts-strict-ignore
import isEmpty from 'lodash.isempty';
import isFunction from 'lodash.isfunction';
import isObject from 'lodash.isobject';
import omitBy from 'lodash.omitby';

import { type KeyMap } from 'helpers/interface';

type PlainObject = Record<string, unknown>;

/**
 * Checks if the provided value is a plain object.
 *
 * A plain object is an object created by `{}` or `new Object`.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a plain object, `false` otherwise.
 */
export function isPlainObject(value: unknown): value is PlainObject {
  return !!value && typeof value === 'object' && value.constructor === Object;
}

/**
 * This function trims an object by omitting properties with undefined, null, or empty values.
 * It uses the `omitBy` function from lodash to iterate over the properties of the object and omit the ones that satisfy the provided condition.
 *
 * @param obj - The object to trim. It can be of any type that extends from KeyMap.
 * @returns A new object of the same type as the input, but with the properties with undefined, null, or empty values omitted.
 */
export function trimObject<T extends KeyMap>(obj: T): Partial<T> {
  return omitBy<T>(obj, (value) => value === undefined || value === null || (isObject(value) && isEmpty(value)));
}

export function deepMerge(...args) {
  let options;
  let name;
  let src;
  let copy;
  let copyIsArray;
  let clone;
  let target = arguments[0] || {};
  let i = 1;
  const { length } = arguments;
  let deep = false;

  // Handle a deep copy situation
  if (typeof target === 'boolean') {
    deep = target;

    // Skip the boolean and the target
    target = arguments[i] || {};
    i++;
  }

  // Handle case when target is a string or something (possible in deep copy)
  if (typeof target !== 'object' && !isFunction(target)) {
    target = {};
  }

  // Extend jQuery itself if only one argument is passed
  if (i === length) {
    target = this;
    i--;
  }

  for (; i < length; i++) {
    // Only deal with non-null/undefined values
    if ((options = arguments[i]) != null) {
      // Extend the base object
      for (name in options) {
        copy = options[name];

        // Prevent Object.prototype pollution
        // Prevent never-ending loop
        if (name === '__proto__' || target === copy) {
          continue;
        }

        // Recurse if we're merging plain objects or arrays
        if (deep && copy && (isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
          src = target[name];

          // Ensure proper type for the source value
          if (copyIsArray && !Array.isArray(src)) {
            clone = [];
          } else if (!copyIsArray && !isPlainObject(src)) {
            clone = {};
          } else {
            clone = src;
          }
          copyIsArray = false;

          // Never move original objects, clone them
          target[name] = deepMerge(deep, clone, copy);

          // Don't bring in undefined values
        } else if (copy !== undefined) {
          target[name] = copy;
        }
      }
    }
  }

  // Return the modified object
  return target;
}
