// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-return */
import uniq from 'lodash.uniq';
import { type SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';
import { type IAgent } from 'store/entities/agents/interfaces';
import { getAgents } from 'store/entities/agents/selectors';

// Based on https://dracoblue.net/dev/linear-least-squares-in-javascript/
function findLineByLeastSquares(valuesX: number[], valuesY: number[]): [number[], number[]] {
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  let count = 0;

  let x = 0;
  let y = 0;
  const valuesLength = valuesX.length;

  if (valuesLength !== valuesY.length) {
    throw new Error('The parameters values_x and values_y need to have same size!');
  }

  if (valuesLength === 0) {
    return [[], []];
  }

  for (let v = 0; v < valuesLength; v += 1) {
    x = valuesX[v];
    y = valuesY[v];
    sumX += x;
    sumY += y;
    sumXX += x * x;
    sumXY += x * y;
    count += 1;
  }

  const m = (count * sumXY - sumX * sumY) / (count * sumXX - sumX * sumX);
  const b = sumY / count - (m * sumX) / count;

  const resultX = [];
  const resultY = [];

  for (let v = 0; v < valuesLength; v += 1) {
    x = valuesX[v];
    y = x * m + b;
    resultX.push(x);
    resultY.push(y);
  }

  return [resultX, resultY];
}

function calculatePredictedValue(valuesX: number[], valuesY: number[]): number {
  const regression = findLineByLeastSquares(valuesX, valuesY);

  const result = regression[1][valuesX.length - 1] * 2 - regression[1][valuesY.length - 2];

  return Math.max(0, result);
}

export function* staffingPredictionDeserializer({ data }: any): SagaIterator {
  const hours = uniq(Object.keys(data.agentOccupancy).map((key) => key.split(' ')[1])).sort();
  const days = uniq(Object.keys(data.agentOccupancy).map((key) => key.split(' ')[0])).sort();

  const hoursMap = hours.map((hour) => days.map((day) => data.agentOccupancy[`${day} ${hour}`]));

  // GLOBAL ACCOUNTS TODO: ROLE SCOPE CHECK
  const agents: IAgent[] = yield select(getAgents);

  const agentRequired = hours.map((hour, index) => {
    const averageMaxChats = agents.reduce((sum, agent) => sum + agent.maxChatsCount, 0) / agents.length;
    const concurrentChats = Math.max(1, calculatePredictedValue([1, 2, 3, 4], hoursMap[index]));

    return averageMaxChats > 0 ? Math.ceil(concurrentChats / averageMaxChats) : 1;
  });
  const agentRequiredSummary = Math.max(...agentRequired, 1);

  return {
    staffingPrediction: {
      summary: [
        {
          name: 'Agents required in the peak:',
          color: DEFAULT_SERIES_COLOR,
          value: agentRequiredSummary,
        },
      ],
      labels: hours,
      series: [
        {
          name: 'required agents',
          color: DEFAULT_SERIES_COLOR,
          data: agentRequired,
          csvColumnName: 'agents',
        },
      ],
    },
  };
}
