import { LoginStatus } from 'constants/login-status';
import { AgentBotSerializer } from 'services/api/agent-bot/serializer';
import type { IAppState } from 'services/app-state-provider';
import { type Bot } from 'services/connectivity/configuration-api/bots/types';
import { AgentBotActions } from 'store/entities/bots/actions';
import { GroupActions } from 'store/entities/groups/actions';

export function handleBotCreated(payload: Bot, store: IAppState): void {
  const deserializedPayload = AgentBotSerializer.deserializeV3(payload, LoginStatus.Offline);
  store.dispatch(AgentBotActions.botAdd(deserializedPayload));
  store.dispatch(GroupActions.groupsFetch());
  store.dispatch(AgentBotActions.fetchChatbotInfo());
}
