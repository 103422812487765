import type { QueryObserverOptions } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';

export const CUSTOM_QUERY_CLIENT_OPTIONS: Record<string, QueryObserverOptions> = {
  [QueryKey.AnalyzeSource]: {
    retry: 3,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  },
};
