// @ts-strict-ignore
import isEmpty from 'lodash.isempty';
import merge from 'lodash.merge';

import { type KeyMap } from 'helpers/interface';
import { type EntityData } from 'store/entities/interfaces';

import {
  type IAddOrUpdateCustomersPayload,
  type IAddVisitedPagePayload,
  type ICustomer,
  type ICustomerDetails,
  type ICustomersState,
  type IRemoveCustomerPayload,
  type IRemoveCustomersPayload,
  type ISetCustomersDataPayload,
  type IUpdateCustomerPayload,
  type IVisitedPage,
} from '../interfaces';

import { REMOVED_CUSTOMERS_LIMIT } from './constants';

export function getStateForUpsertCustomerDetails(state: ICustomersState, details: ICustomerDetails): ICustomersState {
  const { customer, visitedPages = null } = details;
  const { id } = customer;

  const isNew = !state.byIds[id];
  const allIds = isNew ? [...state.allIds, id] : state.allIds;

  const hasVisitedPagesUpdate = !!visitedPages;
  const visitedPagesUpdated = hasVisitedPagesUpdate
    ? {
        byIds: visitedPages,
        allIds: Object.keys(visitedPages).sort(),
      }
    : null;

  // we don't want to merge customVariables, it should be always replaced
  const oldCustomer = state.byIds[id];
  delete oldCustomer?.customVariables;

  const newCustomer = merge({}, oldCustomer, customer);

  return {
    ...state,
    byIds: {
      ...state.byIds,
      [id]: {
        ...newCustomer,
        ...(newCustomer.groupIds && { groupIds: newCustomer.groupIds.map(String) }),
      },
    },
    allIds,
    visitedPages: hasVisitedPagesUpdate
      ? { ...state.visitedPages, [id]: visitedPagesUpdated }
      : state.visitedPages || {},
  };
}

function keepOnlyLatestCustomers(
  removedByIdsMap: KeyMap<ICustomer>,
  visitedPagesMap: KeyMap<EntityData<IVisitedPage>>,
  threshold: number,
): { removedByIds: KeyMap<ICustomer>; visitedPages: KeyMap<EntityData<IVisitedPage>> } {
  const removedByIds = { ...removedByIdsMap };
  const visitedPages = { ...visitedPagesMap };
  const removedByIdsKeys = Object.keys(removedByIds);
  if (removedByIdsKeys.length > threshold) {
    removedByIdsKeys.slice(0, removedByIdsKeys.length - threshold).forEach((key) => {
      delete removedByIds[key];
      delete visitedPages[key];
    });
  }

  return { removedByIds, visitedPages };
}

export function getStateForRemoveCustomer(state: ICustomersState, payload: IRemoveCustomerPayload): ICustomersState {
  const { customerId } = payload;

  const { [customerId]: removedCustomer, ...newByIds } = state.byIds;
  const newAllIds = state.allIds.filter((id) => id != customerId);

  const { removedByIds, visitedPages } = keepOnlyLatestCustomers(
    { ...state.removedByIds, [customerId]: removedCustomer },
    state.visitedPages,
    REMOVED_CUSTOMERS_LIMIT,
  );

  return {
    ...state,
    byIds: newByIds,
    allIds: newAllIds,
    removedByIds,
    visitedPages,
  };
}

export function getStateForRemoveCustomers(state: ICustomersState, payload: IRemoveCustomersPayload): ICustomersState {
  const { customerIds } = payload;

  const result = state.allIds.reduce(
    (acc, customerId) => {
      const customer = state.byIds[customerId];

      if (customerIds.includes(customerId)) {
        acc.removed[customerId] = customer;
      } else {
        acc.preserved[customerId] = customer;
      }

      return acc;
    },
    { preserved: {}, removed: {} },
  );

  const newByIds = result.preserved;
  const newAllIds = Object.keys(newByIds);
  const removedCustomers = result.removed;
  const { removedByIds, visitedPages } = keepOnlyLatestCustomers(
    {
      ...state.removedByIds,
      ...removedCustomers,
    },
    state.visitedPages,
    REMOVED_CUSTOMERS_LIMIT,
  );

  return {
    ...state,
    byIds: newByIds,
    allIds: newAllIds,
    removedByIds,
    visitedPages,
  };
}

export function getStateForSetData(state: ICustomersState, payload: ISetCustomersDataPayload): ICustomersState {
  const { customers } = payload;

  return {
    ...state,
    byIds: customers,
    allIds: Object.keys(customers),
    removedByIds: {},
  };
}

export function getStateForAddOrUpdateCustomers(
  state: ICustomersState,
  payload: IAddOrUpdateCustomersPayload,
): ICustomersState {
  const { customers, visitedPages = {} } = payload;

  const byIdsUpdated = { ...state.byIds };
  const visitedPagesUpdates = {};
  const newCustomerIds = [];

  Object.keys(customers).forEach((customerId) => {
    const customer = customers[customerId];
    const existingCustomer = state.byIds[customerId];

    if (existingCustomer) {
      byIdsUpdated[customerId] = merge({}, existingCustomer, customer);
    } else {
      byIdsUpdated[customerId] = customer;
      newCustomerIds.push(customerId);
    }

    if (byIdsUpdated[customerId].groupIds) {
      byIdsUpdated[customerId].groupIds = byIdsUpdated[customerId].groupIds.map(String);
    }

    const hasVisitedPagesUpdate = !!visitedPages[customerId];
    if (hasVisitedPagesUpdate) {
      visitedPagesUpdates[customerId] = {
        byIds: visitedPages[customerId],
        allIds: Object.keys(visitedPages[customerId]).sort(),
      };
    }
  });

  const hasAddedNewCustomers = newCustomerIds.length > 0;
  const hasUpdatedVisitedPages = !isEmpty(visitedPagesUpdates);

  const allIdsUpdated = hasAddedNewCustomers ? [...state.allIds, ...newCustomerIds] : state.allIds;
  const visitedPagesUpdated = hasUpdatedVisitedPages
    ? { ...state.visitedPages, ...visitedPagesUpdates }
    : state.visitedPages || {};

  return {
    ...state,
    byIds: byIdsUpdated,
    allIds: allIdsUpdated,
    visitedPages: visitedPagesUpdated,
  };
}

export function getStateForUpdateCustomer(state: ICustomersState, payload: IUpdateCustomerPayload): ICustomersState {
  return getStateForUpsertCustomerDetails(state, payload);
}

export function getStateForAddVisitedPage(state: ICustomersState, payload: IAddVisitedPagePayload): ICustomersState {
  const { customerId, visitedPage } = payload;
  const customerVisitedPages = state.visitedPages[customerId];

  return {
    ...state,
    visitedPages: {
      ...(state.visitedPages || {}),
      [customerId]: {
        byIds: {
          ...(customerVisitedPages?.byIds || {}),
          [visitedPage.timestampInMS]: {
            ...visitedPage,
          },
        },
        allIds: [...(customerVisitedPages?.allIds || []), `${visitedPage.timestampInMS}`],
      },
    },
  };
}
