import { createAction } from 'store/helper';

import type {
  EnityCollectionFetch,
  EntityFetch,
  EntityFetchCollectionFailure,
  EntityFetchCollectionSuccess,
  EntityFetchFailure,
  EntityFetchSuccess,
  EntityUpdate,
  EntityUpdateFailure,
  EntityUpdateSuccess
} from './interfaces';

type ActionNames = Record<string, Record<string, string>>;

export enum RequestAction {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum CRUDAction {
  FETCH_SINGLE = 'FETCH_SINGLE',
  FETCH_COLLECTION = 'FETCH_COLLECTION',
  UPDATE = 'UPDATE',
}

type CreateRequestTypes = Record<RequestAction, any>

export const createRequestTypes = (base: string): CreateRequestTypes =>
  [RequestAction.REQUEST, RequestAction.SUCCESS, RequestAction.FAILURE].reduce(
    (acc, type) => ({
      ...acc,
      [type]: `${base}_${type}`,
    }),
    {} as CreateRequestTypes
  );

export const createEntityActionNames: (base: string) => ActionNames = (base) =>
  [CRUDAction.FETCH_SINGLE, CRUDAction.FETCH_COLLECTION, CRUDAction.UPDATE].reduce(
    (acc, type) => ({
      ...acc,
      [type]: createRequestTypes(`${type}_${base}`),
    }),
    {}
  );

export const createEntityActions = <
  Entity,
  EntityFetchType = EntityFetch,
  EntityFetchCollectionType = EnityCollectionFetch
>(
  actionNames: ActionNames
) => ({
  fetch: (payload: EntityFetchType) => createAction(actionNames[CRUDAction.FETCH_SINGLE][RequestAction.REQUEST], payload),

  fetchSuccess: (payload: EntityFetchSuccess<Entity>) =>
    createAction(actionNames[CRUDAction.FETCH_SINGLE][RequestAction.SUCCESS], payload),

  fetchFailure: (payload: EntityFetchFailure) =>
    createAction(actionNames[CRUDAction.FETCH_SINGLE][RequestAction.FAILURE], payload),

  fetchCollection: (payload: EntityFetchCollectionType) =>
    createAction(actionNames[CRUDAction.FETCH_COLLECTION][RequestAction.REQUEST], payload),

  fetchCollectionSuccess: (payload: EntityFetchCollectionSuccess<Entity>) =>
    createAction(actionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS], payload),

  fetchCollectionFailure: (payload: EntityFetchCollectionFailure) =>
    createAction(actionNames[CRUDAction.FETCH_COLLECTION][RequestAction.FAILURE], payload),

  update: (payload: EntityUpdate<Entity>) =>
    createAction(actionNames[CRUDAction.UPDATE][RequestAction.REQUEST], payload),

  updateSuccess: (payload: EntityUpdateSuccess) =>
    createAction(actionNames[CRUDAction.UPDATE][RequestAction.SUCCESS], payload),

  updateFailure: (payload: EntityUpdateFailure) =>
    createAction(actionNames[CRUDAction.UPDATE][RequestAction.FAILURE], payload),
});
