import mitt, { type Handler } from 'mitt';

import type { Events } from 'constants/event-bus-events';

export class EventBus {
  private static bus = mitt<Events>();

  static on<Key extends keyof Events>(type: Key, handler: Handler<Events[Key]>): typeof EventBus {
    this.bus.on(type, handler);

    return this;
  }

  static off<Key extends keyof Events>(type: Key, handler: Handler<Events[Key]>): typeof EventBus {
    this.bus.off(type, handler);

    return this;
  }

  static once<Key extends keyof Events>(type: Key, handler: Handler<Events[Key]>): typeof EventBus {
    this.bus.on(type, (...args) => {
      handler(...args);
      this.bus.off(type, handler);
    });

    return this;
  }

  static emit<Key extends keyof Events>(type: Key, event?: Events[Key]): typeof EventBus {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    this.bus.emit(type, event!);

    return this;
  }
}
