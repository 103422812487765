import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { QueryKey } from 'constants/query-key';
import { inactivitiesClient } from 'services/connectivity/custom-status-api/inactivities/client';
import { type MyInactivityResponse } from 'services/connectivity/custom-status-api/inactivities/types';
import { getIsInactivityReasonInstalled } from 'store/entities/applications/selectors';

async function fetchMyInactivity(): Promise<MyInactivityResponse> {
  const { result, error } = await inactivitiesClient.get();

  if (error) {
    throw error;
  }

  return result;
}

export function useMyInactivity(): {
  isFetching: boolean;
  data?: MyInactivityResponse;
} {
  const isInactivityReasonInstalled = useSelector(getIsInactivityReasonInstalled);

  const { data, isFetching } = useQuery({
    queryKey: [QueryKey.MyInactivity],
    queryFn: fetchMyInactivity,
    refetchOnWindowFocus: false,
    enabled: isInactivityReasonInstalled,
    staleTime: 5 * 60 * 1000,
  });

  return {
    isFetching,
    data,
  };
}
