import type { AnyAction } from 'redux';

import { EventNames } from 'constants/event-bus-events';
import { LoginStatus } from 'constants/login-status';
import { EventPlace } from 'helpers/analytics';
import { preloadSounds as preloadSmartClientSounds } from 'helpers/custom-sounds';
import { getDesktopPlatform } from 'helpers/desktop-app/get-platform';
import { isDesktopAppDetected } from 'helpers/desktop-app/is-detected';
import { navigate } from 'helpers/routing';
import { handleError, handleSuccess, statusChangeRequest } from 'hooks/api/agents/use-status-change';
import { type DesktopAppApi } from 'interfaces/desktop-app/api';
import { LoginStatusElectron } from 'interfaces/desktop-app/login-status';
import { DesktopClientPlatform } from 'interfaces/desktop-app/platform';
import * as SmartClientService from 'services/smartclient';
import { setAwayStatus } from 'services/web-socket/actions/set-away-status';
import { getLoggedInAgentLogin, getLoggedInAgentStatus } from 'store/entities/agents/selectors';
import type { INotification } from 'store/features/browser-notifications/interfaces';
import { SessionActions } from 'store/features/session/actions';

import { AppStateProvider } from '../app-state-provider';
import { EventBus } from '../event-bus';
import { logout } from '../session';

const loginStatusMap = new Map<LoginStatus, LoginStatusElectron>([
  [LoginStatus.Online, LoginStatusElectron.Online],
  [LoginStatus.Away, LoginStatusElectron.Away],
  [LoginStatus.Offline, LoginStatusElectron.Offline],
]);

export class DesktopApplication implements DesktopAppApi {
  private static apiMethods = {
    receive: ['onStatusChange', 'onNotificationShow', 'onNotificationClose', 'onLog', 'onAgentLogin'],
  };

  private callbacks: Record<string, CallableFunction> = {};

  private callbacksQueue: Record<string, unknown[][]> = {};

  private additionaLogsEnabled = false;

  receive: Record<string, CallableFunction> = {};

  send = {
    enableAdditionalLogs(this: DesktopApplication, enabled: boolean) {
      this.additionaLogsEnabled = enabled;
    },
    onCustomSoundsChange(): void {
      preloadSmartClientSounds();
    },
    onNotificationClick(afterClick: { targetPage: string }) {
      if (afterClick.targetPage) {
        navigate(afterClick.targetPage);
      }
    },
    onStatusChange(status: LoginStatusElectron): void {
      const handleStatusChangeRequest = async (status: LoginStatus): Promise<void> => {
        const login = AppStateProvider.selectFromStore(getLoggedInAgentLogin);

        try {
          await statusChangeRequest({ login, status });
          handleSuccess({ login, status, eventPlace: EventPlace.SmartClient }, (action: AnyAction) =>
            AppStateProvider.dispatch(action),
          );
        } catch (error) {
          handleError();
        }
      };

      switch (status.toLowerCase() as LoginStatusElectron) {
        case LoginStatusElectron.Online:
          void handleStatusChangeRequest(LoginStatus.Online);
          break;
        case LoginStatusElectron.Away:
          void handleStatusChangeRequest(LoginStatus.Away);
          break;
        case LoginStatusElectron.Offline:
          void logout();
      }
    },
    onIdle(isIdle: boolean): void {
      if (isIdle) {
        AppStateProvider.dispatch(SessionActions.saveAutoAwayStatus(true));
        void setAwayStatus(true);
      } else {
        AppStateProvider.dispatch(SessionActions.saveAutoAwayStatus(false));
        void setAwayStatus(false);
      }
    },
    onWindowFocus() {
      window.focus();
    },
    onWindowBlur() {
      window.blur();
    },
  };

  constructor() {
    EventBus.on(EventNames.UserLoggedOut, this.onLogout.bind(this) as typeof this.onLogout);
    AppStateProvider.subscribe(this.onStatusChange.bind(this) as typeof this.onStatusChange);

    DesktopApplication.apiMethods.receive.forEach((method) => {
      this.receive[method] = (callback: CallableFunction) => {
        // invoke previously queued calls to "method"
        // (when DesktopApplication hasn't yet registered the callbacks)
        if (this.callbacksQueue[method] !== undefined) {
          this.callbacksQueue[method].forEach((value): void => void callback(...value));
          this.callbacksQueue[method] = [];
        }
        this.callbacks[method] = callback;

        return true;
      };
    });
  }

  toString(): string {
    if (getDesktopPlatform() === DesktopClientPlatform.Windows) {
      return 'SmartClient/Win';
    } else {
      return 'SmartClient/Mac';
    }
  }

  enableAdditionalLogs(): boolean {
    return this.additionaLogsEnabled;
  }

  saveAdvancedLog(message: string | string[]): void {
    if (this.enableAdditionalLogs()) {
      this.saveLog(message);
    }
  }

  saveLog(message: string | string[]): void {
    return this.invokeMethod('onLog', Array.isArray(message) ? message.join(', ') : message);
  }

  agentLoggedIn(agent: { login: string; appUrl: string }): void {
    return this.invokeMethod('onAgentLogin', agent);
  }

  handleBadgeContentChange(count: number): void {
    if (SmartClientService.initialized) {
      return SmartClientService.updateBadge(count);
    }
  }

  displayNewNotification(notification: INotification & { body: string; icon: string }): void {
    return this.invokeMethod('onNotificationShow', notification);
  }

  cancelNotifications(tags: string[] = []): void {
    return this.invokeMethod('onNotificationClose', tags);
  }

  clearAllNotifications(): void {
    return this.invokeMethod('onNotificationClose', []);
  }

  getVersion(): string | null {
    const version = navigator.userAgent.match(/LiveChatSmartClient\/([0-9\.]+)?/);
    if (version !== null && version[1] !== undefined) {
      return version[1];
    } else {
      return null;
    }
  }

  private invokeMethod(method: string, ...parameters: unknown[]): void {
    try {
      if (this.callbacks[method] !== undefined) {
        this.callbacks[method](...parameters);
      } else if (isDesktopAppDetected()) {
        // callback is not yet registered by the DesktopApplication
        // - remember to invoke method when callback is present
        if (this.callbacksQueue[method] === undefined) {
          this.callbacksQueue[method] = [];
        }
        this.callbacksQueue[method].push(parameters);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  private onLogout(): void {
    return this.invokeMethod('onStatusChange', LoginStatusElectron.Offline);
  }

  private onStatusChange(): void {
    return this.invokeMethod(
      'onStatusChange',
      loginStatusMap.get(AppStateProvider.selectFromStore(getLoggedInAgentStatus)),
    );
  }
}
