/* eslint-disable @typescript-eslint/naming-convention */
// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, debounce, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { Section } from 'constants/section';
import { UserType } from 'constants/user-type';
import { mapTagsToStrings } from 'helpers/tags';
import { type IArchive } from 'interfaces/entities/archive';
import { type ApiClientResponse } from 'services/connectivity/http/types';
import { eventCollectorClient } from 'services/connectivity/ml-gateway-api/event-collector/client';
import type { TagSuggestionsEvent } from 'services/connectivity/ml-gateway-api/event-collector/types';
import { tagSuggestionsClient } from 'services/connectivity/ml-gateway-api/tag-suggestions/client';
import { type FetchTagSuggestionsResponse } from 'services/connectivity/ml-gateway-api/tag-suggestions/types';
import { type GenericMlGatewayError } from 'services/connectivity/ml-gateway-api/types';
import type { ITag } from 'store/entities/tags/interfaces';
import { getTagsFromLoggedInAgentGroups } from 'store/entities/tags/selectors';
import { getIsOnSection } from 'store/features/routing/selectors';
import { getLicenseId } from 'store/features/session/selectors';
import { type IActionWithPayload } from 'store/helper';
import { ArchivesViewActionsNames } from 'store/views/archives/actions';
import { getCurrentArchiveMessagesTextArray } from 'store/views/archives/computed';
import { ChatsViewActionsNames } from 'store/views/chats/actions';
import { getIsChatSelected } from 'store/views/chats/selectors';

import { ChatsEntitiesActionNames, ChatsEntitiesActions } from '../actions';
import { getThreadMessagesTextArray } from '../computed';
import { AUTO_TAGS_FETCH_DEBOUNCE_TIME } from '../constants';
import { isClosedThread } from '../helpers/common';
import type {
  ChatThreadEntity,
  IFetchTagSuggestionsOnNewMessagePayload,
  IFetchTagSuggestionsPayload,
  SendTagSuggestionsEventPayload,
} from '../interfaces';
import {
  getTagSuggestionsRequestId,
  getTagSuggestionModelVersion,
  getThread,
  hasFetchedTagSuggestions,
  getCanUseTagsSuggestionFeature,
  getMessageCountForSuggestions,
} from '../selectors';

function* sendTagSuggestionEvent(action: IActionWithPayload<string, SendTagSuggestionsEventPayload>): SagaIterator {
  const { type, threadId } = action.payload;
  const licenseId = yield select(getLicenseId);
  const tagSuggestionsRequestId: string | null = yield select(getTagSuggestionsRequestId, threadId);
  const tagSeModelVersion: string | null = yield select(getTagSuggestionModelVersion);

  if (!tagSeModelVersion || !tagSuggestionsRequestId) {
    return;
  }

  let eventToSend: TagSuggestionsEvent | null = null;

  switch (type) {
    case 'displayed_tag_suggestions': {
      const { receivedTags, suggestedTags, messagesTimestamps } = action.payload;

      eventToSend = {
        project: 'tag_suggestions',
        type,
        request_id: tagSuggestionsRequestId,
        template_version: '1.0.0',
        data: {
          version: '1.0.0',
          license_id: licenseId,
          thread_id: threadId,
          timestamp: Date.now(),
          received_tags: receivedTags,
          suggested_tags: suggestedTags,
          messages_timestamps: messagesTimestamps,
          model_version: tagSeModelVersion,
        },
      };
      break;
    }

    case 'selected_tags': {
      const { selectedTag, source } = action.payload;

      eventToSend = {
        project: 'tag_suggestions',
        type,
        request_id: tagSuggestionsRequestId,
        template_version: '1.0.0',
        data: {
          version: '1.0.0',
          license_id: licenseId,
          thread_id: threadId,
          timestamp: Date.now(),
          selected_tag: selectedTag,
          model_version: tagSeModelVersion,
          from_suggestion: source === 'suggestion',
        },
      };
      break;
    }

    default: {
      eventToSend = null;
      break;
    }
  }

  if (!eventToSend) {
    return;
  }

  yield call(() => eventCollectorClient.sendEvent(eventToSend));
}

function* fetchTagSuggestions(threadId: string, withArchivesEvents = false): SagaIterator {
  const isTagSuggestionsEnabled = yield select(getCanUseTagsSuggestionFeature);
  const messageCountForSuggestions: number = yield select(getMessageCountForSuggestions, threadId);

  if (!isTagSuggestionsEnabled) {
    return;
  }

  let threadEvents: { text: string; timestamp: number }[] = [];

  if (withArchivesEvents) {
    threadEvents = yield select(getCurrentArchiveMessagesTextArray);
  } else {
    threadEvents = yield select(getThreadMessagesTextArray, threadId);
  }

  const mergedMessage = threadEvents.map(({ text }) => text).join(' ');
  const areNewMessagesInChat = messageCountForSuggestions !== threadEvents.length;

  if (!mergedMessage || !areNewMessagesInChat) {
    return;
  }

  const messagesTimestamps = threadEvents.map(({ timestamp }) => timestamp);

  const { result }: ApiClientResponse<FetchTagSuggestionsResponse, GenericMlGatewayError> = yield call(() =>
    tagSuggestionsClient.fetchTagSuggestions({
      message: mergedMessage,
      thread_id: threadId,
    }),
  );

  if (!result) {
    return;
  }

  const { predictions, model_version: modelVersion } = result;

  yield put(ChatsEntitiesActions.setTagSuggestionModelVersion(modelVersion));
  const tags: ITag[] = yield select(getTagsFromLoggedInAgentGroups);

  const availableTags = mapTagsToStrings(tags);
  const filteredSuggestedTags = predictions.filter((tag) => availableTags.includes(tag));

  yield put(
    ChatsEntitiesActions.saveTagSuggestions({
      threadId,
      tags: filteredSuggestedTags,
      messageCountForSuggestions: threadEvents.length,
    }),
  );

  if (!filteredSuggestedTags.length) {
    return;
  }

  yield put(
    ChatsEntitiesActions.sendTagSuggestionEvent({
      type: 'displayed_tag_suggestions',
      receivedTags: predictions,
      suggestedTags: filteredSuggestedTags,
      threadId,
      messagesTimestamps,
    }),
  );
}

function* fetchTagSuggestionsRequest(action: IActionWithPayload<string, IFetchTagSuggestionsPayload>): SagaIterator {
  if (!action.payload.threadId) {
    return;
  }

  const isOnChats = yield select(getIsOnSection, Section.Chats, false);

  if (!isOnChats) {
    return;
  }

  yield call(fetchTagSuggestions, action.payload.threadId);
}

function* fetchTagSuggestionsOnNewMessage(
  action: IActionWithPayload<string, IFetchTagSuggestionsOnNewMessagePayload>,
): SagaIterator {
  const isOnChats = yield select(getIsOnSection, Section.Chats, false);

  if (!isOnChats) {
    return;
  }

  const { threadId, message } = action.payload;

  const isChatSelected = yield select(getIsChatSelected, threadId);
  const thread: ChatThreadEntity = yield select(getThread, threadId);

  const isCorrectMessageAuthor = [UserType.Agent, UserType.Customer, UserType.Visitor].includes(message.authorType);
  if (isChatSelected && !isClosedThread(thread) && isCorrectMessageAuthor) {
    yield call(fetchTagSuggestions, threadId);
  }
}

function* fetchArchivesTagSuggestions(
  action: IActionWithPayload<
    ArchivesViewActionsNames.SET_CURRENT_ITEM,
    {
      current: IArchive;
    }
  >,
): SagaIterator {
  const threadId = action.payload.current.id;
  const hasSuggestions = yield select(hasFetchedTagSuggestions, threadId);

  if (!hasSuggestions) {
    yield call(fetchTagSuggestions, action.payload.current.id, true);
  }
}

export function* tagSuggestionsSaga(): SagaIterator {
  yield takeEvery(ChatsEntitiesActionNames.FETCH_TAG_SUGGESTIONS, fetchTagSuggestionsRequest);
  yield takeEvery(ChatsEntitiesActionNames.SEND_TAG_SUGGESTION_EVENT, sendTagSuggestionEvent);
  yield debounce(AUTO_TAGS_FETCH_DEBOUNCE_TIME, ChatsEntitiesActionNames.NEW_MESSAGE, fetchTagSuggestionsOnNewMessage);
  yield takeLatest(ChatsViewActionsNames.SET_SELECTED_ID, fetchTagSuggestionsRequest);
  yield takeLatest(ArchivesViewActionsNames.SET_CURRENT_ITEM, fetchArchivesTagSuggestions);
}
