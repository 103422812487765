import { type GlobalAccountsApiNormalizedError } from './types';

export function isAccountsApiError(item: unknown): item is GlobalAccountsApiNormalizedError {
  const error = item as GlobalAccountsApiNormalizedError;

  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    'error' in error &&
    typeof error.error === 'string' &&
    'error_description' in error &&
    typeof error.error_description === 'string' &&
    'request_id' in error &&
    typeof error.request_id === 'string'
  );
}
