import type { FC } from 'react';

import { Avatar as DSAvatar } from '@livechat/design-system-react-components';

import type { LoginStatus } from 'constants/login-status';

import { statusesMap } from './helpers';
import type { DSAvatarProps } from './interfaces';

interface IProps extends Omit<DSAvatarProps, 'color' | 'shape' | 'status' | 'type'> {
  status?: LoginStatus;
}

export const AgentAvatar: FC<IProps> = ({ src, status, text, size, className, alt, withRim }) => {
  return (
    <DSAvatar
      size={size}
      type={src ? 'image' : 'text'}
      src={src}
      status={status ? statusesMap.get(status) : undefined}
      text={text}
      className={className}
      alt={alt}
      withRim={withRim}
    />
  );
};
