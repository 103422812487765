import { createAction, type ActionsUnion } from 'store/helper';

export enum DiscardChangesModalActionNames {
  TOGGLE_MODAL = 'VIEWS/DISCARD_CHANGES_MODAL/TOGGLE',
}

export const DiscardChangesModalActions = {
  toggleModal: (isOpen: boolean) => createAction(DiscardChangesModalActionNames.TOGGLE_MODAL, { isOpen }),
};

export type DiscardChangesModalAction = ActionsUnion<typeof DiscardChangesModalActions>;
