export enum Rating {
  Good = 'good',
  Bad = 'bad',
}

export interface TableColumn {
  title: string;
  field?: string;
  isSortable?: boolean;
  iconName?: string;
  width?: number;
  initialSort?: string;
  minWidth?: number;
}

export interface CustomColumnsInterface {
  selected: string[];
  order: string[];
}
