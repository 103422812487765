import isArray from 'lodash.isarray';

import { type Nullable } from 'helpers/interface';
import { type IBannedVisitor } from 'interfaces/entities/banned-visitors';

import { type IFetchBanListResult, type IFetchBanListResultItem } from './interfaces';

/**
 * A utility class for transforming raw API data about banned visitors into a format that's easier to use within application data layer.
 */
export abstract class BannedVisitorsSerializer {
  /**
   * Converts a single banned visitor item from the raw API response format to the application's banned visitor object format.
   * @param {IFetchBanListResultItem} data - The raw API data for a single banned visitor, including metadata like IP, IDs, and timestamps.
   * @returns {IBannedVisitor} - A normalized banned visitor object ready for use within the application, conforming to the IBannedVisitor interface.
   * @example
   * ```
   * const rawBanItem = { ip: "192.168.1.1", visitor_id: "123", creation_timestamp: "2023-01-01T00:00:00Z", expiration_timestamp: "2023-07-01T00:00:00Z", operator_id: "operator123", conference_id: "chat123" };
   * const bannedVisitor = BannedVisitorsSerializer.deserialize(rawBanItem);
   * ```
   */
  static deserialize(data: Nullable<IFetchBanListResultItem>): IBannedVisitor | null {
    if (!data) return null;

    return {
      ip: data.ip,
      id: data.visitor_id,
      creationTimestamp: data.creation_timestamp,
      expirationTimestamp: data.expiration_timestamp,
      bannedBy: data.operator_id,
      chatId: data.conference_id,
    };
  }

  /**
   * Converts a collection of banned visitor items from the raw API response to an array of application's banned visitor objects.
   * @param {IFetchBanListResult} data - The complete API response containing an array of banned visitor items.
   * @returns {IBannedVisitor[]} - An array of normalized banned visitor objects, each conforming to the IBannedVisitor interface.
   * @example
   * ```
   * const rawBanListResult = { banned_visitors: [{ ip: "192.168.1.1", visitor_id: "123", ... }, ...] };
   * const bannedVisitors = BannedVisitorsSerializer.deserializeCollection(rawBanListResult);
   * ```
   * @remarks This method utilizes `deserialize` internally to ensure each item in the collection is normalized according to the same standards.
   */
  static deserializeCollection(data: Nullable<IFetchBanListResult>): IBannedVisitor[] {
    if (!data || !isArray(data.banned_visitors)) return [];

    return data.banned_visitors.reduce<IBannedVisitor[]>((acc, item) => {
      const deserialized = BannedVisitorsSerializer.deserialize(item);
      if (deserialized) acc.push(deserialized);
      return acc;
    }, []);
  }
}
