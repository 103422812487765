export const enum WebSocketAction {
  AddUserToChat = 'add_user_to_chat',
  ChangePushNotifications = 'change_push_notifications',
  DeactivateChat = 'deactivate_chat',
  FollowChat = 'follow_chat',
  Login = 'login',
  Logout = 'logout',
  MarkEventsAsSeen = 'mark_events_as_seen',
  Multicast = 'multicast',
  Ping = 'ping',
  RemoveUserFromChat = 'remove_user_from_chat',
  SendEvent = 'send_event',
  SendTypingIndicator = 'send_typing_indicator',
  SetAwayStatus = 'set_away_status',
  TagThread = 'tag_thread',
  TransferChat = 'transfer_chat',
  UnfollowChat = 'unfollow_chat',
  UntagThread = 'untag_thread',
  UpdateChatProperties = 'update_chat_properties',
  UpdateEventProperties = 'update_event_properties',
  UpdateThreadProperties = 'update_thread_properties',
  DeleteThreadProperties = 'delete_thread_properties',
}
