import { type InsightsAction, InsightsActionsNames } from './actions';
import {
  getStateForAcknowledgeHintSuccess,
  getStateForGetInsights,
  getStateForSelectMetric,
  getStateForGetInsightsSuccess,
  getStateForGetInsightsFailure,
} from './helpers/reducer';
import { type IInsightsState } from './interfaces';

export const initialState: IInsightsState = {
  isLoading: false,
  selectedMetricDetails: null,
  metrics: null,
  metricsExpireAt: null,
  acknowledgedHints: {},
  error: false,
};

export function insightsReducer(state: IInsightsState = initialState, action: InsightsAction): IInsightsState {
  switch (action.type) {
    case InsightsActionsNames.GET_INSIGHTS:
      return getStateForGetInsights(state);
    case InsightsActionsNames.GET_INSIGHTS_SUCCESS:
      return getStateForGetInsightsSuccess(state, action.payload);
    case InsightsActionsNames.GET_INSIGHTS_FAILURE:
      return getStateForGetInsightsFailure(state);
    case InsightsActionsNames.SELECT_METRIC:
      return getStateForSelectMetric(state, action.payload);
    case InsightsActionsNames.ACKNOWLEDGE_HINT_SUCCESS:
      return getStateForAcknowledgeHintSuccess(state, action.payload);
    default:
      return state;
  }
}
