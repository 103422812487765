// @ts-strict-ignore
import { endOfDay, format, startOfDay } from 'date-fns';
import isEmpty from 'lodash.isempty';

import { DateFormat } from 'constants/date';
import { AgentAssignmentFilter } from 'constants/filters/agent-assignment-filter';
import { AgentResponseFilter } from 'constants/filters/agent-response-filter';
import { AvailabilityFilter, GroupStatusAtStart } from 'constants/filters/availability-filter';
import { type CustomDateRange } from 'constants/filters/date-range-filter';
import { Filter } from 'constants/filters/filter';
import { GoalFilter } from 'constants/filters/goal-filter';
import { OfflineMessageFilter } from 'constants/filters/offline-message-filter';
import { PriorityFilter } from 'constants/filters/priority-filter';
import { RatingFilter } from 'constants/filters/rating-filter';
import { RepliedFilter } from 'constants/filters/replied-filter';
import { TagFilter } from 'constants/filters/tag-filter';
import { getConfig } from 'helpers/config';
import { ensureFlat, splitAt } from 'helpers/data';
import { getFilterByOperator, getIsExcludingValues } from 'helpers/filters';
import { type KeyMap } from 'helpers/interface';
import { deepMerge } from 'helpers/object';
import type { AgentResponseFilters } from 'interfaces/chat/filter';
import { type ListArchivesRequest } from 'services/connectivity/agent-chat-api/chats/types/list-archives';
import { type SortOrder } from 'services/connectivity/agent-chat-api/types';
import { EventType } from 'services/connectivity/agent-chat-api/types/event';
import { ThreadCustomPropertyName } from 'services/socket-lc3/chat/event-handling/constants';

import type { IFetchAllArchivesParams } from './interfaces';

const surveyTypeMap = {
  postchat: 'post_chat',
  prechat: 'pre_chat',
};

function hasValue(value: unknown): boolean {
  return Boolean(value || !Number.isNaN(parseInt(value as string, 10)));
}

function hasValidDateRangeValue(dateRange: CustomDateRange): boolean {
  return !(dateRange === null || typeof dateRange !== 'object' || !dateRange.from || !dateRange.to);
}

export function mapSurveyFilterParamsToBody(
  surveyParams: IFetchAllArchivesParams['filters']['survey'],
): ListArchivesRequest['filters']['surveys'] {
  const { id } = surveyParams;
  const separatorIndex = id.indexOf(':');
  if (separatorIndex >= 0) {
    const [type, answerId] = splitAt(id, separatorIndex);

    return [
      {
        type: surveyTypeMap[type],
        values: [answerId.substr(1)],
      },
    ];
  }

  return [];
}

function mapRatingToFetchParams(
  filterValue: IFetchAllArchivesParams['filters']['rating'],
): ListArchivesRequest['filters']['properties']['rating'] {
  const filtersValues: KeyMap<ListArchivesRequest['filters']['properties']['rating']> = {
    [RatingFilter.Rated]: {
      score: {
        exists: true,
      },
    },
    [RatingFilter.NotRated]: {
      score: {
        exists: false,
      },
    },
    [RatingFilter.RatedGood]: {
      score: {
        values: [1],
      },
    },
    [RatingFilter.RatedBad]: {
      score: {
        values: [0],
      },
    },
    [RatingFilter.RatedCommented]: {
      score: {
        exists: true,
      },
      comment: {
        exists: true,
      },
    },
    [RatingFilter.RatedGoodCommented]: {
      score: {
        values: [1],
      },
      comment: {
        exists: true,
      },
    },
    [RatingFilter.RatedBadCommented]: {
      score: {
        values: [0],
      },
      comment: {
        exists: true,
      },
    },
  };

  return filtersValues[filterValue[0]];
}

function mapRepliedFilterParamsToBody(
  filterValue: IFetchAllArchivesParams['filters']['replied'],
): ListArchivesRequest['filters']['properties']['routing']['unreplied'] {
  const withReplied = filterValue === RepliedFilter.WithReplied;
  const withoutReplied = filterValue === RepliedFilter.WithoutReplied;

  return {
    ...(withReplied && { exists: false }),
    ...(withoutReplied && { values: [true] }),
  };
}

function mapOfflineMessageFilterParamsToBody(
  filterValue: IFetchAllArchivesParams['filters']['offlineMessage'],
): ListArchivesRequest['filters']['properties']['routing']['offline_message'] {
  const withOffline = filterValue === OfflineMessageFilter.WithOffline;
  const withoutOffline = filterValue === OfflineMessageFilter.WithoutOffline;

  return {
    ...(withOffline && { values: [true] }),
    ...(withoutOffline && { exists: false }),
  };
}

function mapAvailabilityFilterParamsToBody(
  filterValues: IFetchAllArchivesParams['filters']['availability'],
): ListArchivesRequest['filters']['properties']['routing']['group_status_at_start'] {
  const withOffline = filterValues === AvailabilityFilter.Offline;
  const withOnline = filterValues === AvailabilityFilter.Online;

  return {
    ...(withOffline && { values: [GroupStatusAtStart.Offline, GroupStatusAtStart.NotAcceptingChats] }),
    ...(withOnline && { values: [GroupStatusAtStart.Online, GroupStatusAtStart.OnlineForQueue] }),
  };
}

/**
 * Converts universal fetch filter parameters to API body.
 * @param filterParams Archive fetch filter parameters to be transformed to body accepted by API.
 */
function mapFetchAllFilterParamsToBody(
  filterParams: IFetchAllArchivesParams['filters'],
  operators: IFetchAllArchivesParams['filtersOperators'],
): ListArchivesRequest['filters'] {
  const filterBody: ListArchivesRequest['filters'] = {};

  if (!filterParams) {
    return filterBody;
  }

  const {
    agent,
    dateRange,
    goal,
    greeting,
    group,
    rating,
    replied,
    saleGoal,
    survey,
    tag,
    channel,
    agentAssignment,
    offlineMessage,
    agentResponse,
    availability,
    countryISO,
    topic,
    sentiment,
    priority,
  } = filterParams;

  if (hasValue(agent)) {
    filterBody.agents = { values: agent };
  }

  if (hasValue(dateRange) && hasValidDateRangeValue(dateRange)) {
    filterBody.from = format(startOfDay(dateRange.from), DateFormat.ISO8601DateTimeWithMicrosAndTimezone);
    filterBody.to = format(endOfDay(dateRange.to).toISOString(), DateFormat.ISO8601DateTimeWithMicrosAndTimezone);
  }

  if (hasValue(goal)) {
    if (goal.includes(GoalFilter.Any)) {
      filterBody.goals = {
        exists: true,
      };
    } else {
      filterBody.goals = {
        values: goal.map(Number),
      };
    }
  }

  if (hasValue(group)) {
    filterBody.group_ids = group.map(Number);
  }

  if (hasValue(greeting)) {
    filterBody.greetings = {
      values: greeting?.map(Number),
      from: filterBody.from,
      to: filterBody.to,
    };
    delete filterBody.from;
    delete filterBody.to;

    if (group?.length) {
      filterBody.greetings.groups = {
        values: group.map(Number),
      };

      delete filterBody.group_ids;
    }
  }

  if (hasValue(rating)) {
    filterBody.properties = {
      ...filterBody.properties,
      rating: mapRatingToFetchParams(rating),
    };
  }

  if (hasValue(replied)) {
    filterBody.properties = deepMerge(true, {}, filterBody.properties, {
      routing: {
        unreplied: mapRepliedFilterParamsToBody(replied),
      },
    });
    filterBody.event_types = {
      values: [EventType.MESSAGE],
    };

    if (!filterBody.agents?.values?.length) {
      filterBody.agents = { exists: true };
    }
  }

  if (hasValue(offlineMessage)) {
    filterBody.properties = deepMerge(true, {}, filterBody.properties, {
      routing: {
        offline_message: mapOfflineMessageFilterParamsToBody(offlineMessage),
      },
    });
  }

  if (hasValue(availability)) {
    filterBody.properties = deepMerge(true, {}, filterBody.properties, {
      routing: {
        group_status_at_start: mapAvailabilityFilterParamsToBody(availability),
      },
    });
  }

  if (hasValue(saleGoal)) {
    if (saleGoal.includes(GoalFilter.Any)) {
      filterBody.sales = {
        exists: true,
      };
    } else {
      filterBody.sales = {
        values: saleGoal.map(Number),
      };
    }
  }

  if (hasValue(survey)) {
    filterBody.surveys = mapSurveyFilterParamsToBody(survey);
  }

  if (hasValue(tag)) {
    if (tag.includes(TagFilter.NotTagged)) {
      const isExcluding = getIsExcludingValues(operators, Filter.Tag);
      filterBody.tags = {
        exists: isExcluding,
      };
    } else {
      filterBody.tags = getFilterByOperator<string>({ operators, filter: Filter.Tag, values: tag });
    }
  }

  if (hasValue(channel)) {
    filterBody.properties = {
      ...filterBody.properties,
      source: {
        ...((filterBody.properties && filterBody.properties.source) || {}),
        customer_client_id: {
          values: channel,
        },
      },
    };
  }

  if (hasValue(agentAssignment)) {
    if (!filterBody.agents?.values?.length) {
      filterBody.agents = { exists: ensureFlat(agentAssignment) === (AgentAssignmentFilter.Assigned as string) };
    }

    /**
     * To prevent filtering chats that have only system message, we need to use
     * event type filter with all kinds of event types without system message.
     *
     * Supported types are a little different that we have in enum.
     * https://developers.livechat.com/docs/messaging/customer-chat-api/v3.5/#events
     */
    filterBody.event_types = {
      values: [
        EventType.FILE,
        EventType.FILLED_FORM,
        EventType.MESSAGE,
        EventType.RICH_MESSAGE,
        EventType.CUSTOM,
        EventType.SYSTEM_MESSAGE,
      ],
    };
  }

  if (hasValue(agentResponse)) {
    const agentResponsePayload: AgentResponseFilters = { exists: true };
    const isAgentFilterApplied = filterBody.agents?.values;
    const isGroupFilterApplied = group;

    if (agentResponse === AgentResponseFilter.WithFirstResponse) {
      agentResponsePayload.first = true;

      if (isAgentFilterApplied) {
        agentResponsePayload.agents = {
          values: filterBody.agents.values,
        };

        delete filterBody.agents;
        delete agentResponsePayload.exists;
      }

      if (isGroupFilterApplied) {
        agentResponsePayload.groups = {
          values: group.map(Number),
        };

        delete filterBody.group_ids;
        delete agentResponsePayload.exists;
      }
    }

    filterBody.agent_response = agentResponsePayload;
  }

  if (hasValue(countryISO)) {
    filterBody.customer_countries = getFilterByOperator<string>({
      operators,
      filter: Filter.CountryISO,
      values: countryISO,
    });
  }

  const chatsAnalyserNamespace = getConfig().chatsAnalyserNamespace;

  if (hasValue(topic)) {
    filterBody.properties = deepMerge(true, {}, filterBody.properties, {
      [chatsAnalyserNamespace]: {
        topic: {
          values: topic,
        },
      },
    });
  }

  if (hasValue(sentiment)) {
    filterBody.properties = deepMerge(true, {}, filterBody.properties, {
      [chatsAnalyserNamespace]: {
        sentiment: {
          values: [sentiment],
        },
      },
    });
  }

  if (hasValue(priority)) {
    const withPriority = (priority as PriorityFilter) === PriorityFilter.Yes;

    const priorityQuery = withPriority ? { values: [true] } : { exists: false };

    filterBody.properties = deepMerge(true, {}, filterBody.properties, {
      [getConfig().accountsClientId]: {
        [ThreadCustomPropertyName.PriorityChat]: priorityQuery,
      },
    });
  }

  return filterBody;
}

/**
 * Converts universal fetch parameters to API body.
 * @param params Archive fetch parameters to be transformed to body accepted by API.
 */
export function mapFetchAllParamsToBody(params: IFetchAllArchivesParams): ListArchivesRequest {
  const { filters, filtersOperators, order, page, limit, searchPhrase } = params;
  const body: ListArchivesRequest = {};
  const filterBody = mapFetchAllFilterParamsToBody(filters, filtersOperators);

  /**
   * Right now order, limit and filters are stored inside page string
   * so if page is present we can't pass order and limit props
   */

  if (page) {
    body.page_id = page as string;

    return body;
  }

  if (!isEmpty(filterBody)) {
    body.filters = filterBody;
  }

  if (searchPhrase) {
    body.filters = {
      ...body.filters,
      query: searchPhrase,
    };
  }

  if (order) {
    body.sort_order = order as unknown as SortOrder;
  }

  if (limit) {
    body.limit = limit;
  }

  return body;
}
