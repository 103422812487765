import { type SagaIterator } from 'redux-saga';
import { put, takeEvery, select, take, race, delay } from 'redux-saga/effects';

import { CRUDAction, RequestAction } from 'store/entities/actions';
import { ApplicationsActionNames as ApplicationEntitiesActionNames } from 'store/entities/applications/actions';
import { getInstalledApplications, hasFetchedApplications } from 'store/entities/applications/selectors';
import { ApplicationsActionNames } from 'store/features/applications/actions';
import { type ICampaign, type ICampaignApplication } from 'store/features/applications/interfaces';
import { type IActionWithPayload } from 'store/helper';

import { HomeScreenActions } from './actions';
import { filterHiddenAndInstalledApps } from './helpers';

function* calculateAppsGalleryList(action: IActionWithPayload<string, { campaigns: ICampaign[] }>): SagaIterator {
  const applicationsFetched = yield select(hasFetchedApplications);

  if (!applicationsFetched) {
    yield take(ApplicationEntitiesActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]);
  }

  yield race([
    take(ApplicationsActionNames.INSTALL_APPLICATION_SUCCESSFUL),
    take(ApplicationsActionNames.INSTALL_APPLICATION_FAILURE),
    delay(0),
  ]);

  const installedApplications: ICampaignApplication[] = yield select(getInstalledApplications);
  const { campaigns } = action.payload;
  const appsGalleryList = filterHiddenAndInstalledApps(campaigns, installedApplications);

  yield put(HomeScreenActions.setAppsGalleryList(appsGalleryList));
}

export default function* homeScreenSagas(): SagaIterator {
  yield takeEvery(ApplicationsActionNames.FETCH_CAMPAIGNS_SUCCESSFUL, calculateAppsGalleryList);
}
