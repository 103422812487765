import type { IAgentsPerformance, IAgentsPerformanceSummary } from 'interfaces/reports';
import type { ReportData } from 'interfaces/reports/api-v3';

export enum InsightsMetricType {
  TotalChats = 'TotalChats',
  ChatSatisfaction = 'Satisfaction',
  Efficiency = 'Efficiency',
  ResponseTime = 'ResponseTime',
  MissedChats = 'MissedChats',
}

export type IInsightsState = {
  selectedMetricDetails: IInsightsMetricDetails | null;
  metrics: IInsightsMetrics | null;
  metricsExpireAt: string | null;
  acknowledgedHints: InsightsAcknowledgedHints;
  isLoading: boolean;
  error: boolean;
};

interface IInsightsMetricValue {
  oldValue: number;
  newValue: number;
}

export interface IInsightsMetrics {
  chatsCount: IInsightsMetricValue;
  satisfaction: IInsightsMetricValue;
  chatsPerHour: IInsightsMetricValue;
  firstResponseTime: IInsightsMetricValue;
  missedChats: IInsightsMetricValue;
}

export type AgentsPerformanceReportData = ReportData<IAgentsPerformance, IAgentsPerformanceSummary>;

export type InsightsCoreData = Pick<IInsightsState, 'metrics' | 'acknowledgedHints' | 'metricsExpireAt'>;

export interface ISerializedInsightsMetrics extends Pick<IInsightsState, 'metrics'> {
  expiresAt: string;
}

export interface ISerializedInsightsHintsStatus extends Pick<IInsightsState, 'acknowledgedHints'> {}

export type InsightsSerializedData = ISerializedInsightsMetrics & ISerializedInsightsHintsStatus;

export type InsightsMetricTrend = 'positive' | 'neutral' | 'negative';

export type InsightsAcknowledgedHints = Partial<Record<InsightsHintId, boolean>>;

export interface IInsightsMetricDetails {
  type: InsightsMetricType;
  newValue: number;
  oldValue: number;
  hintsToShow: InsightsHintId[];
  trend: InsightsMetricTrend;
  unreadHintsCount?: number;
}

export enum TotalChatsHintId {
  AnalyzeLastWeeksArchives = 'totalch-p-1',
  CheckTotalChatsReport = 'totalch-p-2',
  AddMoreAgents = 'totalch-p-3',
  CheckGreetingsPerformance = 'totalch-n-1',
  CheckCWSetup = 'totalch-n-2',
}

export enum ChatSatisfactionHintId {
  CongratulateYourTeam = 'chatsat-p-1',
  DiscoverLowRatedChats = 'chatsat-n-1',
  CheckChatSatisfactionReport = 'chatsat-n-2',
}

export enum ResponseTimeHintId {
  CongratulateYourTeam = 'restime-p-1',
  CheckTotalChatsReport = 'restime-n-1',
  AdjustConcurrentChatsSettings = 'restime-n-2',
}

export enum EfficiencyHintId {
  CongratulateYourTeam = 'effi-p-1',
  CheckTotalChatsReport = 'effi-n-1',
  AdjustConcurrentChatsSettings = 'effi-n-2',
  CreateCannedResponses = 'effi-n-3',
}

export enum MissedChatsHintId {
  KeepUpTheGreatWork = 'missedch-p-1',
  CheckMissedChatsReport = 'missedch-n-1',
}

export type InsightsHintId =
  | TotalChatsHintId
  | ChatSatisfactionHintId
  | ResponseTimeHintId
  | EfficiencyHintId
  | MissedChatsHintId;

export interface IHintsForMetrics {
  [InsightsMetricType.TotalChats]: TotalChatsHintId[];
  [InsightsMetricType.ChatSatisfaction]: ChatSatisfactionHintId[];
  [InsightsMetricType.Efficiency]: EfficiencyHintId[];
  [InsightsMetricType.MissedChats]: MissedChatsHintId[];
  [InsightsMetricType.ResponseTime]: ResponseTimeHintId[];
}
