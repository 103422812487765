// @ts-strict-ignore
import {
  type IFacebookMessengerIntegration,
  FacebookMessengerProperty,
} from 'constants/integrations/facebook-messenger';
import { getClientIdByChatChannel, ChatChannel } from 'helpers/chat-channels';
import { getPropertyValue } from 'services/serialization/property';

/**
 * Returns Faceboook Messenger properties from chat properties based on registered `client_id`.
 * @param chatProperties Properties on a chat level.
 */
export function getFacebookMessengerIntegration(chatProperties): IFacebookMessengerIntegration {
  const facebookMessengerClientId = getClientIdByChatChannel(ChatChannel.FacebookMessenger);

  if (!chatProperties || !chatProperties[facebookMessengerClientId]) {
    return null;
  }

  const integrationValues: IFacebookMessengerIntegration = Object.values(FacebookMessengerProperty).reduce(
    (acc, key: string) => {
      const value = getPropertyValue<string>(chatProperties, facebookMessengerClientId, key);

      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    },
    {}
  );

  return integrationValues;
}
