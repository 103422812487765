import { type SagaIterator } from 'redux-saga';
import { delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { initBeamer } from 'services/beamer';
import { getSprigService } from 'services/sprig';
import { getActiveAgentsCount, getLoggedInAgent } from 'store/entities/agents/selectors';
import { CompanyDetailsActionNames } from 'store/entities/company-details/actions';
import { getCompanyDetails, getCompanyDetailsCountry, getCompanyName } from 'store/entities/company-details/selectors';
import { isHelpDeskAppConnected } from 'store/entities/integration-license-properties/selectors';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getSubscription, getSubscriptionSeats } from 'store/entities/subscription/selectors';
import { SessionActionNames } from 'store/features/session/actions';
import { getCurrentLicense } from 'store/features/session/selectors';

import { ExternalAppsActions, ExternalAppsActionsNames } from './actions';

function* startBeamer(): SagaIterator {
  const isHelpdeskUser = yield select(isHelpDeskAppConnected);
  const agent = yield select(getLoggedInAgent);
  const license = yield select(getCurrentLicense);
  const companyName = yield select(getCompanyName);

  initBeamer({ agent, license, companyName, isHelpdeskUser });
  yield put(ExternalAppsActions.beamerReady());
}

function* startSprig(): SagaIterator {
  const subscription = yield select(getSubscription);
  if (!subscription) {
    yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
  }

  const companyDetails = yield select(getCompanyDetails);
  if (!companyDetails) {
    yield take(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS);
  }

  const subscriptionSeats = yield select(getSubscriptionSeats);
  const companyCountry = yield select(getCompanyDetailsCountry);
  const agent = yield select(getLoggedInAgent);
  const license = yield select(getCurrentLicense);
  const companyName = yield select(getCompanyName);
  const agentsCount = yield select(getActiveAgentsCount);

  void getSprigService().initSprig({ agent, license, companyName, companyCountry, subscriptionSeats, agentsCount });
  yield put(ExternalAppsActions.sprigReady());
}

function* updateSprig(): SagaIterator {
  const { isCompanyDetailsModal } = yield race({
    isCompanyDetailsModal: take(CompanyDetailsActionNames.SHOW_MODAL_ON_LOGOUT),
    timeout: delay(3000),
  });

  if (!isCompanyDetailsModal) {
    yield put(ExternalAppsActions.sprigStart());
  }
}

function* startExternalApps(): SagaIterator {
  yield put(ExternalAppsActions.beamerStart());
  yield put(ExternalAppsActions.sprigStart());
  yield put(ExternalAppsActions.startExternalApps());
}

function* endExternalApps(): SagaIterator {
  getSprigService().endSprig();
  yield put(ExternalAppsActions.turnOffExternalApps());
}

export function* externalAppsSagas(): SagaIterator {
  yield takeEvery('APP_READY', startExternalApps);
  yield takeEvery(SessionActionNames.AGENT_LOGOUT, endExternalApps);
  yield takeEvery(ExternalAppsActionsNames.BEAMER_START, startBeamer);
  yield takeEvery(ExternalAppsActionsNames.SPRIG_START, startSprig);
  yield takeLatest(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS, updateSprig);
}
