import { type FC } from 'react';

import { NavigationTopBarAlert } from '@livechat/design-system-react-components';

interface IProps {
  isVisible: boolean;
  onChangeStatusToOnlineClicked: () => void;
  isMessagingModeEnabled: boolean;
}

export const NotAcceptingChatsBar: FC<IProps> = ({
  onChangeStatusToOnlineClicked,
  isMessagingModeEnabled,
  isVisible,
}) => {
  const info = isMessagingModeEnabled
    ? 'You are not accepting chats, but customers can leave a message.'
    : 'You are currently not accepting chats.';

  return (
    <NavigationTopBarAlert
      kind="error"
      isVisible={isVisible}
      primaryCta={{
        label: 'Start accepting chats',
        onClick: onChangeStatusToOnlineClicked,
        size: 'compact',
      }}
    >
      {info}
    </NavigationTopBarAlert>
  );
};
