import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { PaymentType } from 'constants/product-cart';
import { LCBillingCycleToProductCycleMap } from 'helpers/subscription';
import type { Product } from 'interfaces/product-cart';
import { type IApplicationIcons } from 'store/entities/applications/interfaces';

import { type ICreateRecurringRequestResult } from './interfaces';

export function deserializeApplicationRecurrings(
  activeAgentsCount: number,
  recurrings: ICreateRecurringRequestResult[],
  applicationId: string,
  icons?: IApplicationIcons
): Product {
  const application = recurrings[0];
  const renewDate = format(new Date(application.next_charge_at), DateFormat.TrialEndDate);
  const trialEndDate = format(new Date(application.trial_ends_at), DateFormat.TrialEndDate);
  const totalPrice = application.price * (application.per_account ? activeAgentsCount : 1);

  return {
    id: applicationId,
    name: application.name,
    isInstalled: true,
    renewDate,
    trialEndDate,
    trialDays: application.trial_days,
    icon: icons?.small,
    plans: [
      {
        id: 'plan',
        billingCycle: LCBillingCycleToProductCycleMap[application.months],
        selected: true,
        type: application.per_account ? PaymentType.PerAgent : PaymentType.PerOrganization,
        price: {
          initial: application.price,
          regular: totalPrice,
          discount: 0,
          total: totalPrice,
        },
        discount: 0,
      },
    ],
    isMarkedForUninstall: false,
  };
}
