import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type SetAwayStatusEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#set-away-status
 */
export async function setAwayStatus(isAway: boolean): Promise<unknown> {
  const event: SetAwayStatusEvent = {
    action: WebSocketAction.SetAwayStatus,
    payload: {
      away: isAway,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
