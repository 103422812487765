import { type IChannelsActivity } from './interfaces';

export interface IChannelActivityData {
  /* eslint-disable @typescript-eslint/naming-convention */
  channel_type: 'code' | 'integration' | 'direct_link';
  channel_subtype?: 'facebook';
  /* eslint-enable @typescript-eslint/naming-convention */
}

export const deserializeChannelsActivity = (data: IChannelActivityData[]): IChannelsActivity => {
  const website = data.find((channel) => channel.channel_type === 'code');
  const facebook = data.find(
    (channel) => channel.channel_type === 'integration' && channel.channel_subtype === 'facebook'
  );
  const directChat = data.find((channel) => channel.channel_type === 'direct_link');

  return {
    website: {
      isActive: !!website,
    },
    facebook: {
      isActive: !!facebook,
    },
    directChat: {
      isActive: !!directChat,
    },
  };
};
