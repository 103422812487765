import { type SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';

import { AgentBotActionNames, AgentBotActions } from './actions';

function* fetchChatbotInfoSaga(): SagaIterator {
  const { result, error }: RequestResult<any> = yield call(ApiManager.chatbotApi.fetchConnectedBots);

  if (error) {
    yield put(AgentBotActions.fetchChatbotInfoFailure());

    return;
  }

  yield put(AgentBotActions.fetchChatbotInfoSuccess(result));
}

export function* botsSagas(): SagaIterator {
  yield all([takeEvery(AgentBotActionNames.CHATBOT_INFO_FETCH_REQUEST, fetchChatbotInfoSaga)]);
}
