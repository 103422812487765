import type { IAppState } from 'services/app-state-provider';

import { handleEventPropertiesUpdated } from './event-properties-updated';
import type { IEventPropertiesDeletedPushEventPayload } from './interfaces';

/**
 * Handles LC3 event properties delete.
 * @param payload.chat_id Chat id
 * @param payload.thread_id Thread id
 * @param payload.event_id Event id
 * @param store redux store
 */
export function handleEventPropertiesDeleted(payload: IEventPropertiesDeletedPushEventPayload, store: IAppState): void {
  handleEventPropertiesUpdated(payload, store);
}
