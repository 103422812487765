import { Interval } from 'constants/date';
import { dateDifference } from 'helpers/date';
import { inRange } from 'helpers/numbers';

export const enum NpsFirstChatValue {
  NoFirstChat = 'N/A',
  FirstPeriod = '0-30',
  SecondPeriod = '31-90',
  SecondQuarter = '91-180',
  ThirdQuarter = '181-270',
  FourthQuarter = '271-360',
  AfterFourthQuarter = '360+',
}

export const getNpsFirstChat = (date: Date | null): NpsFirstChatValue => {
  if (!date) {
    return NpsFirstChatValue.NoFirstChat;
  }

  const dateNow = new Date();
  const dateNowServerSide = new Date(dateNow.getTime() + dateNow.getTimezoneOffset() * 60000);
  const daysFromNow = dateDifference(dateNowServerSide, date, Interval.Day);

  if (inRange(daysFromNow, 0, 30, [true, true])) {
    return NpsFirstChatValue.FirstPeriod;
  }

  if (inRange(daysFromNow, 31, 90, [true, true])) {
    return NpsFirstChatValue.SecondPeriod;
  }

  if (inRange(daysFromNow, 91, 180, [true, true])) {
    return NpsFirstChatValue.SecondQuarter;
  }

  if (inRange(daysFromNow, 181, 270, [true, true])) {
    return NpsFirstChatValue.ThirdQuarter;
  }

  if (inRange(daysFromNow, 271, 360, [true, true])) {
    return NpsFirstChatValue.FourthQuarter;
  }

  return NpsFirstChatValue.AfterFourthQuarter;
};
