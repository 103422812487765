import { AccountCircle } from '@livechat/design-system-icons';

import { type IAgentFilterItem } from '../agent-filter/agent-filter';
import { type FilterConfig, type FilterLabelValue } from '../interfaces';

export const responseAuthorFilterConfig: FilterConfig = {
  title: 'Response author',
  icon: AccountCircle,
  multiple: false,
  getFilterLabelValues: (filterValues: string[] | null, agents: IAgentFilterItem[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return [];
    }

    const responseAuthor = agents.find((agent) => agent.id === filterValues[0]);
    if (responseAuthor == null) {
      return [];
    }

    return [
      {
        key: filterValues[0],
        label: `First response by ${responseAuthor.name}`,
      },
    ];
  },
};
