import { DEFAULT_RESPONSE_TIMEOUT } from 'constants/server';

export enum ChatEventStatus {
  // Initial status for new messages sent by the agent
  Sent = 'sent',
  // Sending - message status was not resolved within 3000ms - no update from server
  Pending = 'pending',
  // Sending message was unsuccessful due to various events
  // ie. no response from server within 20s or network disconnect when message was in Sending status
  NotDelivered = 'not_delivered',
  // Message delivered succeessfully
  Delivered = 'delivered',
  // Message read by the other party
  Read = 'read',
}

export const MESSEGE_DELIVERY_WAIT_TIME = DEFAULT_RESPONSE_TIMEOUT; // 15s server timeout + 5s AA treshhold

export const MESSAGE_STATUS_CHANGE_TO_PENDING_TIME = 3000;
export const FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_FALLBACK_TIME = 3000;
