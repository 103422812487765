import { desktopAppReady } from 'entries/base/services/desktop-app/desktop-app';
import { type DesktopAppApi } from 'interfaces/desktop-app/api';

import { DesktopApplication } from './desktop-app-api';

let desktopApp: DesktopAppApi | null = null;

export function initializeDesktopApp(): void {
  desktopApp = new DesktopApplication();

  desktopAppReady(desktopApp);
}

export function getDesktopApp(): DesktopAppApi {
  if (!desktopApp) {
    throw new Error('Desktop app is not initialized');
  }

  return desktopApp;
}
