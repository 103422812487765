// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */

export interface IRichMessageJSON {
  type: string;
  payload: {
    template_id?: string;
    elements: object[];
  };
}

export function isRichMessageJSON(json: IRichMessageJSON): boolean {
  return (
    json.type === 'rich_message' && json.payload && json.payload.template_id && Array.isArray(json.payload.elements)
  );
}

export function validRichMessageButton(button): boolean {
  return typeof button.text === 'string' && typeof button.postback_id === 'string';
}

export function validRichMessageButtons(buttons): boolean {
  return Array.isArray(buttons) && buttons.every(validRichMessageButton);
}

export function validRichMessageCard(card): boolean {
  return !!card.title && (!card.buttons || validRichMessageButtons(card.buttons)) && (!card.image || !!card.image.url);
}

export function validRichMessageCards(cards: any[]): boolean {
  return cards.every(validRichMessageCard);
}

export function validRichMessageQuickReplies({ title, buttons }): boolean {
  return !!title && validRichMessageButtons(buttons);
}

function extractRichMessageButton(button) {
  return {
    text: button.text || '',
    postback_id: button.postback_id || '',
    user_ids: [],
    type: button.type || 'message',
    value: button.value || button.text || '',
    ...(button.webview_height && { webview_height: button.webview_height }),
  };
}

function extractRichMessageImage(image) {
  if (image) {
    return {
      name: image.name,
      url: image.url,
      content_type: image.content_type,
      size: image.size,
      width: image.width,
      height: image.height,
    };
  }

  return null;
}

export function extractRichMessageCard(card: { image: string; title: string; subtitle: string; buttons: any[] }) {
  if (card) {
    const image = extractRichMessageImage(card.image);

    return {
      title: card.title,
      subtitle: card.subtitle,
      ...(image && { image }),
      buttons: Array.isArray(card.buttons) && card.buttons.map(extractRichMessageButton),
    };
  }

  return {};
}
