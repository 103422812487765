// @ts-strict-ignore
import { NOT_AVAILABLE_TEXT } from 'constants/empty-state';
import { DEFAULT_SERIES_COLOR, ExtraPalettes } from 'constants/reports/chart-color-palette';
import { Last7DaysMode } from 'constants/reports/last-7-days-mode';
import { formatTime } from 'helpers/format-time';
import {
  calculateSatisfaction,
  sumValueFromPeriod,
  avgValueFromPeriod,
  getRatingsFromPeriod,
  getSeriesDataFromPeriod,
} from 'helpers/reports';
import {
  type ILast7DaysReportChatsData,
  type ILast7DaysReportTicketsData,
  type ILast7DaysReportData,
  type ILast7DaysReportPayload,
} from 'interfaces/reports';
import { AppStateProvider } from 'services/app-state-provider';

import { REPORT_TOTAL_CHATS_SUBPATH } from '../constants';
import { getLast7DaysMode } from '../selectors';

const parseDateData = (data) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (acc, day) => ({ ...acc, [day]: data[day] });
};

function deserializeLast7DaysChats(data: ILast7DaysReportPayload['data']): ILast7DaysReportChatsData {
  const days = Object.keys(data.totalChats);
  const last7DaysMode = AppStateProvider.selectFromStore(getLast7DaysMode);
  const hasDataFromTwoWeeks = last7DaysMode === Last7DaysMode.Agent;

  const totalChatsData = days.slice(7).reduce(parseDateData(data.totalChats), {});
  const totalChatsTwoWeeksBefore = days.slice(0, 7).reduce(parseDateData(data.totalChats), {});

  let totalChats = sumValueFromPeriod(totalChatsData, REPORT_TOTAL_CHATS_SUBPATH);
  const totalChatsFromPeriod = sumValueFromPeriod(totalChatsTwoWeeksBefore, REPORT_TOTAL_CHATS_SUBPATH, 'missed_chats');

  let salesAchived = sumValueFromPeriod(data.salesTrackers, 'goals');
  let goalsAchived = data.last7Days.chats.goals;

  const chatSatisfactionTwoWeeksBefore = days.slice(0, 7).reduce(parseDateData(data.chatsSatisfaction.records), {});
  const chatSatisfactionLast7Days = days.slice(7).reduce(parseDateData(data.chatsSatisfaction.records), {});

  const chatRatingWeekBefore = getRatingsFromPeriod(chatSatisfactionTwoWeeksBefore);
  const chatSatisfactionFromPeriod = calculateSatisfaction(chatRatingWeekBefore.good, chatRatingWeekBefore.bad);

  let chatRating = getRatingsFromPeriod(chatSatisfactionLast7Days);

  /**
   * if hasDataFromTwoWeeks is true it means we deserialize data for agent, not license
   */
  if (hasDataFromTwoWeeks) {
    totalChats = sumValueFromPeriod(totalChatsData, REPORT_TOTAL_CHATS_SUBPATH, 'missed_chats');

    chatRating = getRatingsFromPeriod(chatSatisfactionLast7Days);

    const goalsLast7Days = days.slice(7, 15).reduce(parseDateData(data.goals), {});
    goalsAchived = sumValueFromPeriod(goalsLast7Days, 'goals');
    const salesLast7Days = days.slice(7, 15).reduce(parseDateData(data.salesTrackers), {});
    salesAchived = sumValueFromPeriod(salesLast7Days, 'goals');
  }

  const totalChatsDifference = totalChats - totalChatsFromPeriod;
  const chatSatisfaction = calculateSatisfaction(chatRating.good, chatRating.bad);
  const chatSatisfactionDifference = chatSatisfaction - chatSatisfactionFromPeriod;

  return {
    totalChats,
    totalChatsDifference,
    queuedVisitors: hasDataFromTwoWeeks ? NOT_AVAILABLE_TEXT : data.last7Days.chats.queues,
    goals: goalsAchived,
    sales: salesAchived,
    chatSatisfactionDifference,
    chatSatisfaction: chatSatisfaction && `${chatSatisfaction}%`,
    chart: {
      summary: [
        {
          name: 'Last 7 days',
          value: totalChats,
          color: DEFAULT_SERIES_COLOR,
        },
      ],
      labels: Object.keys(totalChatsData),
      series: [
        {
          name: 'Last 7 days',
          color: DEFAULT_SERIES_COLOR,
          data: getSeriesDataFromPeriod(totalChatsData, REPORT_TOTAL_CHATS_SUBPATH),
        },
      ],
    },
  };
}

function deserializeLast7DaysTickets(data: any): ILast7DaysReportTicketsData {
  const { ratings: ticketRating, new_tickets: newTicketsData } = data.last7Days.tickets;
  const newTickets = sumValueFromPeriod(newTicketsData, 'new_tickets');
  const newTicketsWeekBefore = sumValueFromPeriod(data.newTickets, 'new_tickets');
  const newTicketsDifference = newTickets - newTicketsWeekBefore;

  const ticketSatisfaction = calculateSatisfaction(ticketRating.good, ticketRating.bad);
  const ticketRatingWeekBefore = getRatingsFromPeriod(data.ticketsSatisfaction);
  const ticketSatisfactionWeekBefore = calculateSatisfaction(ticketRatingWeekBefore.good, ticketRatingWeekBefore.bad);
  const ticketSatisfactionDifference = ticketSatisfaction - ticketSatisfactionWeekBefore;

  const firstTimeResponse = data.last7Days.tickets.first_response_time.hours;
  const resolutionTime = avgValueFromPeriod(data.ticketsResolutionTime, 'hours');

  return {
    newTickets,
    newTicketsDifference,
    solvedTickets: data.last7Days.tickets.solved_tickets || 0,
    firstTimeResponse: firstTimeResponse && formatTime(firstTimeResponse),
    resolutionTime: resolutionTime && formatTime(resolutionTime),
    ticketSatisfaction: ticketSatisfaction && `${ticketSatisfaction}%`,
    ticketSatisfactionDifference,
    chart: {
      summary: [
        {
          name: 'Last 7 days',
          value: newTickets,
          color: ExtraPalettes.Positive,
        },
      ],
      labels: Object.keys(newTicketsData),
      series: [
        {
          name: 'Last 7 days',
          color: ExtraPalettes.Positive,
          data: getSeriesDataFromPeriod(newTicketsData, 'new_tickets'),
        },
      ],
    },
  };
}

export function deserializeLast7Days({ data }: ILast7DaysReportPayload): ILast7DaysReportData {
  const chats = deserializeLast7DaysChats(data);
  const tickets = deserializeLast7DaysTickets(data);

  return {
    chats,
    tickets,
  };
}
