import { isLegacyBrowser } from 'helpers/browser';

/**
 * Return `true` if system/browser detected via `navigator` id qualified
 * as no longer supported by Agent App.
 */
export function isUnsupportedSystemOrBrowser(): boolean {
  const isWindowsVista = navigator.appVersion ? /Windows NT 6.0/.test(navigator.appVersion) : false;

  return isWindowsVista || isLegacyBrowser;
}
