import { type FC, type ReactNode } from 'react';

import { SideNavigationItem as DSSideNavigationItem } from '@livechat/design-system-react-components';

import {
  SIDE_NAVIGATION_ACTIVE_ITEM_TEST_ID,
  SIDE_NAVIGATION_ITEM_TEST_ID,
} from 'components/side-navigation/constants';
import { UserGuidedTourStep } from 'components/tours/UserGuidedTourStep';
import { noop } from 'helpers/noop';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';

interface IProps {
  label: ReactNode;
  rightNode?: ReactNode;
  icon?: ReactNode;
  shouldKeepIconSpace?: boolean;
  url?: string;
  className?: string;
  isActive?: boolean;
  isMainEntry?: boolean;
  onClick: () => void;
  onItemHover?: () => void;
  isIconHidden?: boolean;
  tourStep?: ISideNavigationItem['tourStep'];
}

export const SideNavigationItem: FC<IProps> = ({
  icon,
  shouldKeepIconSpace = true,
  url,
  label,
  className,
  rightNode,
  onClick,
  isActive,
  isMainEntry,
  onItemHover,
  tourStep,
  isIconHidden,
}) => {
  const handleClick = (): void => onClick?.();

  const navigationItem = (
    <DSSideNavigationItem
      data-testid={isActive ? SIDE_NAVIGATION_ACTIVE_ITEM_TEST_ID : SIDE_NAVIGATION_ITEM_TEST_ID}
      className={className}
      key={url}
      url={url}
      shouldKeepIconSpace={shouldKeepIconSpace}
      isActive={isActive}
      isMainEntry={isMainEntry}
      label={label}
      onClick={handleClick}
      onItemHover={onItemHover || noop}
      leftNode={icon}
      rightNode={rightNode}
      isIconHidden={isIconHidden}
    />
  );

  return tourStep ? (
    <UserGuidedTourStep tourId={tourStep.id} step={tourStep.step}>
      {navigationItem}
    </UserGuidedTourStep>
  ) : (
    navigationItem
  );
};
