// @ts-strict-ignore
import { type IWithRequestsState, createRequestFetchingSelector } from 'store/requests/selectors';

import { RecurlyRequestFetchingSelectorsNames } from './actions';
import { type IRecurlyCoupon, type IRecurlyState } from './interfaces';

export interface IWithRecurlyEntitiesState {
  entities: {
    recurly: IRecurlyState;
  };
}

const getIsCheckingCurrentRecurlyCoupon = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector([RecurlyRequestFetchingSelectorsNames.CHECK_CURRENT_RECURLY_COUPON_REQUEST])(state);

const getIsCheckingNewRecurlyCoupon = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector([RecurlyRequestFetchingSelectorsNames.CHECK_NEW_RECURLY_COUPON_REQUEST])(state);

export const getIsLoadingCoupons = (state: IWithRequestsState): boolean =>
  getIsCheckingCurrentRecurlyCoupon(state) || getIsCheckingNewRecurlyCoupon(state);

export const getCurrentRecurlyCoupon = (state: IWithRecurlyEntitiesState): IRecurlyCoupon =>
  state.entities.recurly.currentCoupon;

export const getNewRecurlyCoupon = (state: IWithRecurlyEntitiesState): IRecurlyCoupon =>
  state.entities.recurly.newCoupon;

export const getAreCouponsFetched = (state: IWithRecurlyEntitiesState & IWithRequestsState): boolean =>
  state.entities.recurly.fetched;

export const getCouponRedemptionError = (state: IWithRecurlyEntitiesState): string | null =>
  state.entities.recurly.newCoupon?.redemptionError;
