import { type GroupPropertiesAction, GroupPropertiesActionNames } from './actions';
import type {
  IFetchGroupPropertiesSuccessPayload,
  IGroupPropertiesState,
  ISaveGroupPropertiesSuccessPayload,
} from './interfaces';

const initialState: IGroupPropertiesState = {};

export function groupPropertiesReducer(
  state: IGroupPropertiesState = initialState,
  action: GroupPropertiesAction
): IGroupPropertiesState {
  switch (action.type) {
    case GroupPropertiesActionNames.FETCH_SUCCESS: {
      const { groupId, properties } = action.payload as IFetchGroupPropertiesSuccessPayload;

      return {
        ...state,
        [groupId]: properties,
      };
    }

    case GroupPropertiesActionNames.SAVE_SUCCESS: {
      const { groupId, properties } = action.payload as ISaveGroupPropertiesSuccessPayload;

      return {
        ...state,
        [groupId]: { ...state[groupId], ...properties },
      };
    }

    default:
      return state;
  }
}
