import type { MouseEvent } from 'react';

import type { NavigationId, NavigationPath } from 'constants/navigation';
import { type Section } from 'constants/section';
import { trackEventOncePerUniqueKey } from 'helpers/event-saver';
import { navigate } from 'helpers/routing';

export interface IActivityComparatorParams {
  section?: Section;
  id: NavigationId;
}

export interface IOnItemClickParams {
  event: MouseEvent<HTMLAnchorElement>;
  path: NavigationPath;
  trackingTitle: string;
}

export const activityComparator = ({ section, id }: IActivityComparatorParams): boolean =>
  !!section && id.startsWith(section);

export const onItemClick = ({ event, path, trackingTitle }: IOnItemClickParams): void => {
  event.preventDefault();
  navigate(path);
  trackEventOncePerUniqueKey('menu-trigger', trackingTitle);
};
