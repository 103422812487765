import { type KeyMap } from 'helpers/interface';

import { type IChatTagsOperations } from '../interfaces';

const EMPTY_ARRAY: string[] = [];

export function addTagToInProgressStatus(
  tagsOperations: KeyMap<IChatTagsOperations>,
  threadId: string,
  tag: string
): string[] {
  if (tagsOperations[threadId] && tagsOperations[threadId].inProgress) {
    return [...tagsOperations[threadId].inProgress, tag];
  }

  return [tag];
}

export function removeTagFromInProgressStatus(
  tagsOperations: KeyMap<IChatTagsOperations>,
  threadId: string,
  tag: string
): string[] {
  return tagsOperations[threadId]?.inProgress?.filter((t) => t !== tag) || EMPTY_ARRAY;
}

export function addTagToFailureStatus(
  tagsOperations: KeyMap<IChatTagsOperations>,
  threadId: string,
  tag: string
): string[] {
  if (tagsOperations[threadId] && tagsOperations[threadId].failure) {
    return [...tagsOperations[threadId].failure, tag];
  }

  return [tag];
}

export function removeTagFromFailureStatus(
  tagsOperations: KeyMap<IChatTagsOperations>,
  threadId: string,
  tag: string
): string[] {
  return tagsOperations[threadId]?.failure?.filter((t) => t !== tag) || EMPTY_ARRAY;
}
