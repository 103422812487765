import { createConfigurationApiClient } from '../client';
import { type ApiClientBatchResponse, type ConfigurationApiErrorResponse } from '../types';

import {
  type BatchUpdateBotsRequest,
  type CreateBotRequest,
  type CreateBotResponse,
  type DeleteBotRequest,
  type GetBotRequest,
  type GetBotResponse,
  type IssueBotTokenRequest,
  type IssueBotTokenResponse,
  type ListBotsRequest,
  type ListBotsResponse,
  type UpdateBotRequest,
} from './types';

const client = createConfigurationApiClient();

/**
 * Configuration API chatbots client.
 * @see {@link https://platform.text.com/docs/management/configuration-api#bots}
 */
export const botsClient = {
  /**
   * Creates a new bot.
   * @see {@link https://platform.text.com/docs/management/configuration-api#create-bot}
   */
  create: async (data: CreateBotRequest) => {
    return client.post<CreateBotResponse, ConfigurationApiErrorResponse>('create_bot', { json: data });
  },

  /**
   * Retrieves a bot by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#get-bot}
   */
  get: async (data: GetBotRequest) => {
    return client.post<GetBotResponse, ConfigurationApiErrorResponse>('get_bot', { json: data });
  },

  /**
   * Lists all bots.
   * @see {@link https://platform.text.com/docs/management/configuration-api#list-bots}
   */
  list: async (data: ListBotsRequest) => {
    return client.post<ListBotsResponse, ConfigurationApiErrorResponse>('list_bots', { json: data });
  },

  /**
   * Updates a bot by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#update-bot}
   */
  update: async (data: UpdateBotRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('update_bot', { json: data });
  },

  /**
   * Batch update bots.
   * @see {@link https://platform.text.com/docs/management/configuration-api#update-bot}
   */
  batchUpdate: async (data: BatchUpdateBotsRequest) => {
    return client.post<ApiClientBatchResponse<void>, never>('batch_update_bots', { json: data });
  },

  /**
   * Deletes a bot by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#delete-bot}
   */
  delete: async (data: DeleteBotRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('delete_bot', { json: data });
  },

  /**
   * Issues a new token for a bot.
   * @see {@link https://platform.text.com/docs/management/configuration-api#issue-bot-token}
   */
  issueToken: async (data: IssueBotTokenRequest) => {
    return client.post<IssueBotTokenResponse, ConfigurationApiErrorResponse>('issue_bot_token', { json: data });
  },
};
