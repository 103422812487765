// @ts-strict-ignore
import { type KeyMap } from 'helpers/interface';
import { isDefined } from 'helpers/is-defined';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import { AgentSerializer } from 'services/api/agent/v3/agent-serializer';
import { AgentStatusSerializerV3 } from 'services/api/agent/v3/agent-status-serializer';
import { AgentBaseSerializerV3 } from 'services/api/common/agent-base/agent-base-serializer-v3';
import { type IAgent } from 'store/entities/agents/interfaces';

export function isAgentStatusChanged(agent: KeyMap<any>): boolean {
  return isDefined(agent.suspended) || isDefined(agent.awaiting_approval);
}

function deserializeAgent(agent: any): Partial<IAgent> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const getValue = (key, value = agent[key]) => (key in agent ? value : null);

  return {
    email: getValue('login'),
    statusAfterLogin:
      getValue('login_status') && AgentStatusSerializerV3.deserializeStatusAfterLogin(agent.login_status),
    permission: getValue('permission'),
  };
}

interface ISesson {
  ip: string;
  version: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  app_info: string;
}

/**
 * Return true if device from which customer is hailing from is either web app or desktop app. Mobile is excluded.
 * @param appInfo string
 */
export const isValidDevice = (appInfo: string): boolean => appInfo === 'Web' || appInfo.startsWith('SmartClient');

/**
 * Filter duplicate sessions by comparing IP and version props.
 * @param sessions Array<ISesson>
 */
export const filterDuplicateSessions = (sessions: Array<ISesson>): Array<ISesson> => {
  return sessions.reduce<Array<ISesson>>((acc, session) => {
    if (acc.find(({ ip, version }) => ip === session.ip && version === session.version)) {
      return acc;
    }

    return [...acc, session];
  }, []);
};

/**
 * Agent deserializer provide full information about agent. If hasn't some data, then fill if with null
 * @param agent JSON provided from protocol
 */
export function deserialize(agent: any): IAgentBase {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const getValue = (key, value = agent[key]) => (key in agent ? value : null);

  const ips = [];
  const unfilteredSessions = (getValue('instances') || getValue('sessions') || []) as Array<ISesson>;
  const validDevices: Array<ISesson> = unfilteredSessions.filter((session) => isValidDevice(session.app_info));

  const sessionsInLC = filterDuplicateSessions(validDevices);

  if (getValue('instances')) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    ips.push(...agent.instances.map((instance) => instance.ip));
  } else if (getValue('sessions')) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    ips.push(...agent.sessions.map((sessions) => sessions.ip));
  }

  return {
    login: getValue('login'),
    email: getValue('login'),
    name: getValue('name'),
    avatar: getValue('avatar'),
    jobTitle: getValue('job_title'),
    maxChatsCount: getValue('routing.max_chats_count'),
    lastLogout: AgentSerializer.deserializeLastLogout(getValue('status'), getValue('last_logout')),
    groups: getValue('groups'),
    sessionTypes: getValue('sessions') && AgentSerializer.deserializeSessionTypes(agent.sessions),
    status: getValue('status') && AgentBaseSerializerV3.deserializeStatus(agent.status),
    ips: ips.length ? ips : null,
    sessionsInLC: sessionsInLC.length ? sessionsInLC.map(({ ip }) => ip) : null,
    type: AgentBaseSerializerV3.deserializeType(agent.is_bot),
    organizationId: getValue('organization_id'),
    accountId: getValue('account_id'),
    ...(isAgentStatusChanged(agent) ? { accountStatus: AgentSerializer.deserializeAccountStatus(agent) } : {}),
    ...(agent.is_bot ? {} : deserializeAgent(agent)),
  } as unknown as IAgentBase;
}

/**
 * The same like above but trim object to filled fields
 * @param agent JSON provided from protocol
 */
export function partialDeserialize(agent: any): Partial<IAgentBase> {
  const values = deserialize(agent);

  return Object.keys(values)
    .filter((key) => values[key] !== null)
    .reduce((payload, key) => ({ ...payload, [key]: values[key] }), {}) as Partial<IAgentBase>;
}
