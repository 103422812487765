import { type BadgeAction, BadgeActionNames } from './actions';
import { type IBadgesState } from './interfaces';

const initialState = {};

export const badgesReducer = (state: IBadgesState = initialState, action: BadgeAction): IBadgesState => {
  switch (action.type) {
    case BadgeActionNames.SET_BADGE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};
