import { type FC, useCallback, useMemo } from 'react';

import { InviteAgents as InviteAgentsComponent } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { statusesMap } from 'components/avatar/helpers';
import { GlobalModal } from 'constants/global-modal';
import { InviteAgentsSource } from 'constants/invite-agents-source';
import { isAgentBot } from 'helpers/agent';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { getActiveAgents } from 'store/entities/agents/selectors';
import { getActiveChatbots, getBotsCount } from 'store/entities/bots/selectors';
import { getIsSubscriptionInTrialAndNotSubscribed } from 'store/entities/subscription/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getIsStarterPlan } from 'store/features/session/selectors';
import { getCanUseCopilotPopover } from 'store/views/copilot/selectors';

import { InviteAgentsEvent } from './constants';
import { onAvailableAgentsClick, onSetUpChatbotClick } from './helpers';

import * as styles from './styles';

export const InviteAgents: FC = () => {
  const dispatch = useDispatch();
  const { isStarterPlan, isTrial } = useSelector((state) => ({
    isStarterPlan: getIsStarterPlan(state),
    isTrial: getIsSubscriptionInTrialAndNotSubscribed(state),
  }));

  const agents = useSelector(getActiveAgents);
  const chatbots = useSelector(getActiveChatbots);
  const botsCount = useSelector(getBotsCount);
  const canUseCopilotChat = useSelector(getCanUseCopilotPopover);

  const onAddTeammateClick = useCallback((): void => {
    dispatch(GlobalModalActions.showModal(GlobalModal.InviteAgents, { source: InviteAgentsSource.TopBar }));
    trackEvent(InviteAgentsEvent.AddTeammate, EventPlace.TopBar);
  }, [dispatch]);

  const mappedAgents = useMemo(() => {
    return [...agents, ...chatbots].map((agentOrBot) => ({
      name: agentOrBot.name,
      email: agentOrBot.login,
      avatar: agentOrBot.avatar || '',
      status: statusesMap.get(agentOrBot.status) ?? 'unknown',
      isBot: isAgentBot(agentOrBot),
    }));
  }, [agents, chatbots]);

  if (isStarterPlan) {
    return null;
  }

  return (
    <InviteAgentsComponent
      className={styles.inviteAgents(canUseCopilotChat)}
      agents={mappedAgents}
      onAddTeammateClick={onAddTeammateClick}
      onSetUpChatbotClick={onSetUpChatbotClick}
      animatedInviteButton={!isTrial}
      onAvailableAgentsClick={onAvailableAgentsClick}
      showBotsInTooltip={botsCount > 0}
    />
  );
};
