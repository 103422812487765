import type {
  IApiGetGoal,
  IApiExtendedCreateGoal,
  IApiUpdateGoal,
  IApiDeleteGoal,
  IApiBaseGoal,
  IApiExtendedGoal,
} from 'store/entities/goals/interfaces';

import { createRestApiClient } from '../client';

import type { GoalApiClientError } from './types';

const client = createRestApiClient();

export const goalsApi = {
  fetchGoals: () => client.get<IApiBaseGoal[], GoalApiClientError>('goals'),
  fetchGoalById: (goalId: IApiGetGoal) => client.get<IApiExtendedGoal, GoalApiClientError>(`goals/${goalId}`),
  createGoal: (goalData: IApiExtendedCreateGoal) =>
    client.post<IApiExtendedGoal, GoalApiClientError>('goals', {
      json: goalData,
    }),
  updateGoal: (goal: IApiUpdateGoal) =>
    client.put<IApiExtendedGoal, GoalApiClientError>(`goals/${goal.id}`, {
      json: goal,
    }),
  deleteGoal: (goalId: IApiDeleteGoal) => client.delete<void, GoalApiClientError>(`goals/${goalId}`),
};
