import { getServer } from 'helpers/get-server';
import { type DeactivateChatEvent } from 'interfaces/web-socket-events';
import { WebSocketAction } from 'constants/web-socket-action';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference/#deactivate-chat
 */
export async function deactivateChat(id: string, ignoreRequesterPresence: boolean): Promise<unknown> {
  const event: DeactivateChatEvent = {
    action: WebSocketAction.DeactivateChat,
    payload: {
      id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ignore_requester_presence: ignoreRequesterPresence,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
