import { createMlGatewayApiClient } from '../client';
import { type GenericMlGatewayError } from '../types';

import type {
  CreateTextTranslationRequest,
  CreateTextSentimentRequest,
  CreateTextGrammarCorrectionRequest,
  CreateTextRewordRequest,
  TextEnhancementResponse,
} from './types';

const client = createMlGatewayApiClient();

export const textEnhancementsClient = {
  createTextTranslation: async (data: CreateTextTranslationRequest) => {
    return client.post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/translations', {
      json: data,
    });
  },

  createTextSentiment: async (data: CreateTextSentimentRequest) => {
    return client.post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/sentiments', {
      json: data,
    });
  },

  createTextGrammarCorrection: async (data: CreateTextGrammarCorrectionRequest) => {
    return client.post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/grammar-corrections', {
      json: data,
    });
  },

  createTextReword: async (data: CreateTextRewordRequest) => {
    return client.post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/rewordings', {
      json: data,
    });
  },
};
