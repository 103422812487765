import { type FC } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { OnboardingRoute } from 'constants/onboarding/routes';
import { getHomePage } from 'helpers/routing';
import { useRouteOverride } from 'hooks/use-route-override';
import { LocalStorageKey, saveItem } from 'services/local-storage';

export const NotFound404: FC = () => {
  const location = useLocation();
  const { shouldShowOnboarding, shouldShowInstallCode, hasAccessToAppRoutes } = useRouteOverride();

  if (shouldShowOnboarding) {
    return <Navigate to={OnboardingRoute.Base} replace />;
  }

  if (shouldShowInstallCode) {
    saveItem(LocalStorageKey.RedirectAfterInstallCode, location.pathname + location.search);

    return <Navigate to={`/install-code${location.search}`} replace />;
  }

  if (hasAccessToAppRoutes) {
    return <Navigate to={getHomePage()} replace />;
  }

  return null;
};
