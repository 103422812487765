import { type ActionsUnion, createAction } from 'store/helper';

import { type IDevToolsTogglePayload } from './interfaces';

export enum DevToolsActionNames {
  TOGGLE = 'VIEWS/DEV_TOOLS/TOGGLE',
}

export const DevToolsActions = {
  toggle: (payload: IDevToolsTogglePayload) => createAction(DevToolsActionNames.TOGGLE, payload),
};

export type DevToolsAction = ActionsUnion<typeof DevToolsActions>;
