// @ts-strict-ignore
import { TeamViewActions } from 'constants/team/actions';

import { type TeamViewActionTypes } from './actions';
import { type ITeamState } from './interfaces';

export const initialState: ITeamState = {
  agents: {
    activeAgents: {
      sortBy: [
        { id: 'status', desc: false },
        { id: 'simplified-status', desc: false },
      ],
    },
    waitingForApproval: {
      sortBy: [{ id: 'name', desc: false }],
    },
    filter: '',
    selectedId: undefined,
  },
  chatbots: {
    active: {
      sortBy: [
        { id: 'name', desc: false },
        { id: 'simplified-name', desc: false },
      ],
    },
    inactive: {
      sortBy: [
        { id: 'name', desc: false },
        { id: 'simplified-name', desc: false },
      ],
    },
    filter: '',
    selectedId: undefined,
  },
  groups: {
    table: { sortBy: [{ id: 'status', desc: true }] },
    filter: '',
    selectedId: undefined,
  },
  suspendedAgents: {
    table: { sortBy: [{ id: 'name', desc: false }] },
    filter: '',
    selectedId: undefined,
  },
  selectedAgents: {},
  currentTeamPage: null,
  groupMembers: {
    table: {
      sortBy: [
        { id: 'status', desc: false },
        { id: 'role', desc: true },
      ],
    },
    filter: '',
  },
  groupFormMembers: {},
  modal: {
    type: undefined,
    props: undefined,
  },
};

const EMPTY_OBJECT = {};

export function teamReducer(state: ITeamState = initialState, action: TeamViewActionTypes): ITeamState {
  switch (action.type) {
    case TeamViewActions.TOGGLE_SORT: {
      return {
        ...state,
        [action.payload.tableView]: {
          ...state[action.payload.tableView],
          [action.payload.tableName]: {
            sortBy: action.payload.sortBy,
          },
        },
      };
    }

    case TeamViewActions.FILTER_CHANGE: {
      return {
        ...state,
        [action.payload.tableView]: {
          ...state[action.payload.tableView],
          filter: action.payload.filterValue,
        },
      };
    }

    case TeamViewActions.SET_SELECTED_ENTITY: {
      return {
        ...state,
        [action.payload.tableView]: {
          ...state[action.payload.tableView],
          selectedId: action.payload.selectedId,
        },
      };
    }

    case TeamViewActions.ADD_SELECTED_AGENT_LOGIN: {
      const {
        agent: { login, name },
      } = action.payload;

      return {
        ...state,
        selectedAgents: {
          ...state.selectedAgents,
          [login]: {
            name,
            login,
          },
        },
      };
    }

    case TeamViewActions.REMOVE_SELECTED_AGENT_LOGIN: {
      const { [action.payload.agentLogin]: toDelete, ...selectedAgents } = state.selectedAgents;

      return {
        ...state,
        selectedAgents,
      };
    }

    case TeamViewActions.UNSELECT_ALL_AGENTS: {
      return {
        ...state,
        selectedAgents: EMPTY_OBJECT,
      };
    }
    case TeamViewActions.SET_TEAM_CURRENT_PAGE: {
      return {
        ...state,
        currentTeamPage: action.payload.currentTeamPath,
      };
    }

    case TeamViewActions.GROUP_FORM_SET_MEMBERS: {
      return {
        ...state,
        groupFormMembers: action.payload.members || {},
      };
    }

    case TeamViewActions.OPEN_MODAL: {
      return {
        ...state,
        modal: {
          type: action.payload.modal,
          props: action.payload.props,
        },
      };
    }

    case TeamViewActions.CLOSE_MODAL: {
      return {
        ...state,
        modal: {
          type: null,
          props: null,
        },
      };
    }

    default:
      return state;
  }
}
