import { EnvironmentType } from 'constants/environment';
import { getEnvironment } from 'helpers/feature-toggle';

type EnvName = 'labs' | 'staging' | 'prod';

export const getEnvName = (): EnvName => {
  const env = getEnvironment();

  switch (env) {
    case EnvironmentType.Labs:
      return 'labs';
    case EnvironmentType.Staging:
      return 'staging';
    default:
      return 'prod';
  }
};
