import type { IStoreState } from 'interfaces/store/store-state';
import { createRequestFetchingSelector } from 'store/requests/selectors';

import { ApplicationsRequestFetchingSelectorsNames } from './actions';
import { type IApplicationsState } from './interfaces';

interface IWithAppsState {
  features: {
    applications: IApplicationsState;
  };
}

export function getLoadedWidgets(state: IWithAppsState): string[] {
  return state.features.applications.loadedWidgets;
}

export function getLoadedFullscreenWidgets(state: IWithAppsState): string[] {
  return state.features.applications.loadedFullscreenWidgets;
}

export const getIsInstallingApplication = (state: IStoreState, id: string): boolean => {
  return createRequestFetchingSelector([`${ApplicationsRequestFetchingSelectorsNames.INSTALL_APPLICATION}_${id}`])(
    state
  );
};
