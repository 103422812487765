import type { FC } from 'react';

import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';

import { UserType } from 'constants/user-type';
import { type CopilotEvent as CopilotEventType } from 'store/entities/copilot/interfaces';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import { CopilotAvatar } from './CopilotAvatar';
import { CopilotEvent } from './CopilotEvent';

import * as styles from './styles';

interface IProps {
  eventGroup: CopilotEventType[];
}

export const CopilotEventsGroup: FC<IProps> = ({ eventGroup }) => {
  const [firstEvent] = eventGroup;
  const isAgentMessage = firstEvent.authorType === UserType.Agent;
  const isCopilotExpanded = useSelector(getIsCopilotModalExpanded);

  return (
    <div
      className={cx({
        [styles.groupedEventsWrapper(isCopilotExpanded)]: isAgentMessage,
      })}
    >
      <div className={styles.bubblePosition}>
        {isCopilotExpanded && !isAgentMessage && <CopilotAvatar />}
        <div
          className={cx(styles.eventsWrapper(isAgentMessage, isCopilotExpanded), {
            [styles.eventsWrapperGroupedBorders(isAgentMessage)]: eventGroup.length > 1,
          })}
        >
          {eventGroup.map((event) => {
            return <CopilotEvent key={event.eventId} event={event} isAgentMessage={isAgentMessage} />;
          })}
        </div>
      </div>
    </div>
  );
};
