import type { ReactNode } from 'react';

import { type GroupId } from 'interfaces/groups';
import { type ActionsUnion, createAction } from 'store/helper';

import { type ISetRecentPagePayload } from './interfaces';

export enum SettingsViewActionNames {
  SET_PAGE_TITLE = 'VIEWS/SETTINGS/SET_PAGE_TITLE',
  SET_SIDEBAR_COLUMNS = 'VIEWS/SETTINGS/SET_SIDEBAR_COLUMNS',
  SET_SELECTED_GROUP_ID = 'VIEWS/SETTINGS/SET_SELECTED_GROUP_ID',
  SET_CURRENT_PAGE_ID = 'VIEWS/SETTINGS/SET_CURRENT_PAGE_ID',
  TOGGLE_PGRADE_MODAL = 'VIEWS/SETTINGS/TOGGLE_UPGRADE_MODAL',
  SET_TICKET_FORM_FORWARDING_TO_HELPDESK = 'VIEWS/SETTINGS/SET_TICKET_FORM_FORWARDING_TO_HELPDESK',
  HIDE_TICKET_EMAIL_FORWARDING_INTRODUCTION = 'VIEWS/SETTINGS/HIDE_TICKET_EMAIL_FORWARDING_INTRODUCTION',
  SET_LEFT_NODES = 'VIEWS/SETTINGS/SET_LEFT_NODES',
  SET_SHOW_BACK_BUTTON = 'VIEWS/SETTINGS/SET_SHOW_BACK_BUTTON',
  SHOULD_SHOW_NAVIGATION = 'VIEWS/SETTINGS/SHOULD_SHOW_NAVIGATION',
  SET_WIDGET_PREVIEW_BOTTOM_NOTE = 'VIEWS/SETTINGS/WIDGET_PREVIEW/SET_BOTTOM_NOTE',
  SET_HIDE_GROUP_SELECT = 'VIEWS/SETTINGS/SET_HIDE_GROUP_SELECT',
  SET_HIDE_WIDGET_PREVIEW = 'VIEWS/SETTINGS/SET_HIDE_WIDGET_PREVIEW',
  SET_IS_FULL_SCREEN = 'VIEWS/SETTINGS/SET_IS_FULL_SCREEN',
  SET_RECENT_PAGE = 'VIEWS/SETTINGS/SET_RECENT_PAGE',
}

export const SettingsViewActions = {
  setPageTitle: (pageTitle: string | ReactNode) => createAction(SettingsViewActionNames.SET_PAGE_TITLE, { pageTitle }),
  setSidebarColumns: (column: 1 | 2) => createAction(SettingsViewActionNames.SET_SIDEBAR_COLUMNS, { column }),
  setSelectedGroupId: (groupId: GroupId) => createAction(SettingsViewActionNames.SET_SELECTED_GROUP_ID, { groupId }),
  setCurrentPageId: (currentPageId: string) =>
    createAction(SettingsViewActionNames.SET_CURRENT_PAGE_ID, { currentPageId }),
  toggleUpgradeModal: () => createAction(SettingsViewActionNames.TOGGLE_PGRADE_MODAL),
  setTicketFormForwardingToHelpDesk: (enabled: boolean) =>
    createAction(SettingsViewActionNames.SET_TICKET_FORM_FORWARDING_TO_HELPDESK, { enabled }),
  hideTicketEmailForwardingIntroduction: () =>
    createAction(SettingsViewActionNames.HIDE_TICKET_EMAIL_FORWARDING_INTRODUCTION),
  setLeftNodes: (leftNodes: ReactNode) => createAction(SettingsViewActionNames.SET_LEFT_NODES, { leftNodes }),
  setShowBackButton: (showBackButton: boolean, navigateBackTo?: string) =>
    createAction(SettingsViewActionNames.SET_SHOW_BACK_BUTTON, { showBackButton, navigateBackTo }),
  setShowNavigation: (showNavigation: boolean) =>
    createAction(SettingsViewActionNames.SHOULD_SHOW_NAVIGATION, { showNavigation }),
  setWidgetPreviewBottomNote: (bottomNote: ReactNode) =>
    createAction(SettingsViewActionNames.SET_WIDGET_PREVIEW_BOTTOM_NOTE, { bottomNote }),
  setHideGroupSelect: (hideGroupSelect: boolean) =>
    createAction(SettingsViewActionNames.SET_HIDE_GROUP_SELECT, { hideGroupSelect }),
  setHideWidgetPreview: (hideWidgetPreview: boolean) =>
    createAction(SettingsViewActionNames.SET_HIDE_WIDGET_PREVIEW, { hideWidgetPreview }),
  setIsFullScreen: (isFullScren: boolean) => createAction(SettingsViewActionNames.SET_IS_FULL_SCREEN, { isFullScren }),
  setRecentPage: (payload: ISetRecentPagePayload) => createAction(SettingsViewActionNames.SET_RECENT_PAGE, payload),
};

export type SettingsViewActionTypes = ActionsUnion<typeof SettingsViewActions>;
