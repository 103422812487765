import { getSeriesDataFromPeriod, sumValueFromPeriod } from 'helpers/reports';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';

import { type ISolvedTicketsData } from '../../../../interfaces/reports';

export function solvedTicketsDeserializer({ data }: any): ISolvedTicketsData {
  const solvedTicketsSummary = sumValueFromPeriod(data.solvedTickets, 'solved_tickets');
  const solvedTickets = getSeriesDataFromPeriod<number>(data.solvedTickets, 'solved_tickets');

  return {
    solvedTickets: {
      summary: [
        {
          name: 'solved tickets',
          value: solvedTicketsSummary,
          color: DEFAULT_SERIES_COLOR,
        },
      ],
      labels: Object.keys(data.solvedTickets),
      series: [
        {
          name: 'solved tickets',
          color: DEFAULT_SERIES_COLOR,
          data: solvedTickets,
        },
      ],
    },
  };
}
