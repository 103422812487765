// @ts-strict-ignore
import { RoutingActionsEnum, type RoutingAction } from './actions';
import type { IRoutingState } from './interfaces';

const initialState = {};

export function routingReducer(state: IRoutingState = initialState, action?: RoutingAction): IRoutingState {
  switch (action.type) {
    case RoutingActionsEnum.SET_CURRENT_SECTION: {
      return {
        currentSection: action.payload.route,
        previousSection: state.currentSection,
      };
    }
    default:
      return state;
  }
}
