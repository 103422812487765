import { css } from '@emotion/css';

export const container = css`
  position: absolute;
  top: 0;
  right: -1px;
  z-index: 10;
  transform: scale(0.9);
  font-size: 10px;
  font-weight: 700;
`;
