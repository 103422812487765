import type { FC } from 'react';

import filtersImagex1 from 'assets/img/user-guided-tours/tags-usage/or.png';
import filtersImagex2 from 'assets/img/user-guided-tours/tags-usage/or@2x.png';
import { TourStep } from 'components/tours/TourStep';

export const DescriptionStep: FC = () => (
  <TourStep
    title="All tag stats in one place"
    message="Check the differences in chat volume between particular tags and spot your tag usage trends."
  />
);

export const FiltersStep: FC = () => (
  <TourStep
    title="Select more than one tag"
    message="As a result, you will see chats that match either value."
    image={<img src={filtersImagex1} srcSet={`${filtersImagex2} 2x`} alt="Filters usage" />}
  />
);
