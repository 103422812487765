import { type KeyMap } from 'helpers/interface';
import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import { type AgentProperty } from './interfaces';

export class AgentPropertiesApi extends BaseApi {
  protected readonly prefix = 'properties/agent';

  set = (property: AgentProperty, propertyValue: string, agentLogin: string): Promise<RequestResult<never>> =>
    this.put(`${agentLogin}`, { [property]: propertyValue });

  fetchAllCustom = (agentLogin: string): Promise<RequestResult<KeyMap<string>>> => this.get(`${agentLogin}/custom`);

  setCustom = (
    propertyName: string,
    propertyValue: string | string[],
    agentLogin: string
  ): Promise<RequestResult<never>> => this.put(`${agentLogin}/custom/${propertyName}`, { value: propertyValue });

  expireCustom = (propertyName: string, agentLogin: string): Promise<RequestResult<never>> =>
    this.put(`${agentLogin}/custom/${propertyName}`, {
      expiration: 1,
      value: '',
    });
}
