export const enum NetworkStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export const enum InternetStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
}

export const enum ServiceStatus {
  REACHABLE = 'reachable',
  TIMED_OUT = 'timed_out',
  CLIENT_ERROR = 'client_error',
  SERVER_ERROR = 'server_error',
  NETWORK_ERROR = 'network_error',
  UNREACHABLE = 'unreachable',
}

export const enum ConnectivityStatusEvent {
  NETWORK_STATUS_CHANGED = 'networkStatusChanged',
  INTERNET_STATUS_CHANGED = 'internetStatusChanged',
  SERVICE_STATUS_CHANGED = 'serviceStatusChanged',
  THROTTLED_NETWORK_STATUS_CHANGED = 'throttledNetworkStatusChanged',
  THROTTLED_INTERNET_STATUS_CHANGED = 'throttledInternetStatusChanged',
  ONLINE_STATUS_CHANGED = 'onlineStatusChanged',
  THROTTLED_IS_OFFLINE_CHANGED = 'throttledIsOfflineChanged',
}

type ConnectivityStatusEventMap = {
  [ConnectivityStatusEvent.NETWORK_STATUS_CHANGED]: NetworkStatus;
  [ConnectivityStatusEvent.INTERNET_STATUS_CHANGED]: InternetStatus;
  [ConnectivityStatusEvent.SERVICE_STATUS_CHANGED]: { service: string; status: ServiceStatus };
  [ConnectivityStatusEvent.THROTTLED_NETWORK_STATUS_CHANGED]: NetworkStatus;
  [ConnectivityStatusEvent.THROTTLED_INTERNET_STATUS_CHANGED]: InternetStatus;
  [ConnectivityStatusEvent.ONLINE_STATUS_CHANGED]: boolean;
  [ConnectivityStatusEvent.THROTTLED_IS_OFFLINE_CHANGED]: boolean;
};

export type ConnectivityStatus = {
  network: NetworkStatus;
  internet: InternetStatus;
  services: Record<string, ServiceStatus>;

  throttledNetworkStatus: NetworkStatus;
  throttledInternetStatus: InternetStatus;
};

export type CreateConnectivityStatusOptions = {
  checkURLTimeout?: number;
  internetCheckInterval?: number;
  internetCheckURLs?: string[];
  servicesURLs?: string[];
  offlineThrottleInterval?: number;
};

export type ConnectivityStatusInstance = {
  getIsOnline: () => boolean;
  getIsOffline: () => boolean;
  getThrottledIsOffline: () => boolean;
  getNetworkStatus: () => NetworkStatus;
  getInternetStatus: () => InternetStatus;
  getServiceStatus: (service: string) => ServiceStatus;
  getServiceStatuses: () => Record<string, ServiceStatus>;
  getThrottledNetworkStatus: () => NetworkStatus;
  getThrottledInternetStatus: () => InternetStatus;
  resetThrottledStatuses: () => void;
  subscribe: <T extends keyof ConnectivityStatusEventMap>(
    event: T,
    listener: (payload: ConnectivityStatusEventMap[T]) => void
  ) => void;
  unsubscribe: <T extends keyof ConnectivityStatusEventMap>(
    event: T,
    listener: (payload: ConnectivityStatusEventMap[T]) => void
  ) => void;
  initialize: () => Promise<void>;
  destroy: () => void;
};
