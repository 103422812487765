import type { FC } from 'react';

import { AgentAvatar } from 'components/avatar/AgentAvatar';
import { COPILOT_LOGO } from 'constants/copilot';

import * as styles from './styles';

export const CopilotAvatar: FC = () => (
  <AgentAvatar src={COPILOT_LOGO} text="Copilot" size="small" className={styles.botAvatar} />
);
