// @ts-strict-ignore
import * as React from 'react';

import { css } from '@emotion/css';
import { FormField, type IPickerListItem, Picker } from '@livechat/design-system-react-components';
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { Filter } from 'constants/filters/filter';
import { type Weekday } from 'constants/filters/weekday-filter';

import { getSelectedItems } from '../helpers';
import type { FilterConfig } from '../interfaces';
import { formField } from '../styles';
import { withFilterBase } from '../with-filter-base';

import { getPickerItems, type ISingleItem } from './weekday-filter';

export const filterBoxStyles = css`
  width: auto;
`;

export const filterButtonStyles = css`
  max-width: 128px;
  margin-left: auto;
`;

interface IProps {
  filterValues: Weekday | null;
  items: ISingleItem[];
  filterConfig: FilterConfig;
  onChange(filter: { name: string; value: Weekday | null }): void;
  onClose(): void;
}

class WeekdayFilterComponent extends React.PureComponent<IProps> {
  private handleChange = (selectedItem: IPickerListItem[]): void => {
    const value =
      (selectedItem[0].key as Weekday) || (format(new Date(), DateFormat.DayOfWeek).toLowerCase() as Weekday);

    this.props.onChange({ name: Filter.Weekday, value });
    this.props.onClose();
  };

  render() {
    const items = getPickerItems(this.props.items);
    const selectedId = this.props.filterValues || null;

    return (
      <FormField className={formField} description={this.props.filterConfig.description}>
        <Picker
          id="weekday-filter"
          onSelect={this.handleChange}
          options={items}
          placeholder="Select"
          openedOnInit
          selected={getSelectedItems(items, [selectedId])}
        />
      </FormField>
    );
  }
}

export const WeekdayFilter = withFilterBase(WeekdayFilterComponent);
