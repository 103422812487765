import { type SagaIterator } from 'redux-saga';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { type ActiveExperiment } from 'constants/active-experiment';
import { setDataLayerCustomDimensions } from 'helpers/analytics';
import type { KeyMap } from 'helpers/interface';
import type { RequestResult } from 'interfaces/api/client';
import { AmplitudeUserProperty, setUserPropertiesForAmplitude } from 'services/amplitude';
import { ApiManager } from 'services/api/api-manager';
import { deserializeExperiments } from 'services/api/license-properties/serializer';

import { ExperimentsActionNames, ExperimentsActions } from './actions';
import { getExperimentsGroups } from './selectors';

interface ExperimentsResponse {
  experiment: {
    name: ActiveExperiment;
    value: string;
  }[];
}

function* fetchExperimentsSaga(): SagaIterator {
  const { result, error }: RequestResult<ExperimentsResponse, Error> = yield call(
    ApiManager.licensePropertiesApi.fetchExperiments,
  );
  if (error) {
    yield put(ExperimentsActions.fetchExperimentsFailure(error));
    yield cancel();
  }
  if (result && result.experiment) {
    const experiments = deserializeExperiments(result.experiment);
    yield put(ExperimentsActions.fetchExperimentsSuccess(experiments));
    yield call(setDataLayerCustomDimensions, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'lc-experiments': experiments.map(({ name }) => name).join(', '),
    });
  }
}

function* setupExperimentsDependants(): SagaIterator {
  const experimentsGroups: KeyMap<number> = yield select(getExperimentsGroups);

  setUserPropertiesForAmplitude({ [AmplitudeUserProperty.Experiments]: experimentsGroups });

  yield call(window.LiveChatWidget.call, 'update_session_variables', {
    ...Object.keys(experimentsGroups).reduce((acc, item) => {
      acc[`og_cv_exp_${item}`] = experimentsGroups[item];

      return acc;
    }, {}),
  });
}

export function* experimentsSaga(): SagaIterator {
  yield takeEvery(ExperimentsActionNames.FETCH_EXPERIMENTS_REQUEST, fetchExperimentsSaga);
  yield takeEvery(ExperimentsActionNames.FETCH_EXPERIMENTS_SUCCESS, setupExperimentsDependants);
}
