import { type IOutboxMessage } from './outbox-message';
import { createOutboxMessage } from './outbox-message-factory';
import { OutboxMessageTitles } from './outbox-message-titles';

interface ISendCardsResponseMessage extends IOutboxMessage {
  title: OutboxMessageTitles.SendCardsResponse;
}

interface ISendCardsResponse {
  status: 'success' | 'error';
  message: string;
}

export function createSendCardsResponseMessage(pluginId: string, data: ISendCardsResponse): ISendCardsResponseMessage {
  return createOutboxMessage<ISendCardsResponseMessage>(OutboxMessageTitles.SendCardsResponse, pluginId, data);
}
