import { type ActionsUnion, createAction } from 'store/helper';

import {
  type IRefreshCustomersPayload,
  type IResizeColumnPayload,
  type ISortColumnPayload,
  type IAddFilterPayload,
  type IRemoveFilterPayload,
  type IUpdateFilterPayload,
  type IChangeOperatorPayload,
  type ISaveColumnsVisibilityPayload,
  type ISetColumnsOrderPayload,
  type ISetColumnsVisibilityPayload,
  type ISaveColumnsOrderPayload,
  type IApplySegmentFilterPayload,
  type ISetCustomMessageModalStatePayload,
  type IUpdateCustomersCountersPayload,
  type ISetIntentCampaignModalStatePayload,
} from './interfaces';

export const enum TrafficActionNames {
  REFRESH_LIST = 'VIEWS/TRAFFIC/REFRESH_LIST',
  REFRESH_LIST_THROTTLED = 'VIEWS/TRAFFIC/REFRESH_LIST_THROTTLED',
  RESIZE_COLUMN = 'VIEWS/TRAFFIC/RESIZE_COLUMN',
  SORT_COLUMN = 'VIEWS/TRAFFIC/SORT_COLUMN',
  ADD_FILTER = 'VIEWS/TRAFFIC/ADD_FILTER',
  REMOVE_FILTER = 'VIEWS/TRAFFIC/REMOVE_FILTER',
  UPDATE_FILTER = 'VIEWS/TRAFFIC/UPDATE_FILTER',
  CLEAR_FILTERS = 'VIEWS/TRAFFIC/CLEAR_FILTERS',
  CHANGE_OPERATOR = 'VIEWS/TRAFFIC/CHANGE_OPERATOR',
  CUSTOMER_THREAD_STATUS_CHANGE = 'VIEWS/TRAFFIC/CUSTOMER_THREAD_STATUS_CHANGE',
  SET_COLUMNS_VISIBILITY = 'VIEWS/TRAFFIC/SET_COLUMNS_VISIBILITY',
  SAVE_COLUMNS_VISIBILITY = 'VIEWS/TRAFFIC/SAVE_COLUMNS_VISIBILITY',
  SET_COLUMNS_ORDER = 'VIEWS/TRAFFIC/SET_COLUMNS_ORDER',
  SAVE_COLUMNS_ORDER = 'VIEWS/TRAFFIC/SAVE_COLUMNS_ORDER',
  BANNED_CUSTOMERS_LIST_UPDATE = 'VIEWS/TRAFFIC/BANNED_CUSTOMERS_LIST_UPDATE',
  APPLY_SEGMENT_FILTERS = 'VIEWS/TRAFFIC/APPLY_SEGMENT_FILTERS',
  CUSTOMERS_LIST_MOUNT = 'VIEWS/TRAFFIC/CUSTOMERS_LIST_MOUNT',
  SET_CUSTOM_MESSAGE_MODAL_STATE = 'VIEWS/TRAFFIC/SET_CUSTOM_MESSAGE_MODAL_STATE',
  UPDATE_CUSTOMERS_COUNTERS = 'VIEWS/TRAFFIC/UPDATE_CUSTOMERS_COUNTERS',
  PICK_FROM_QUEUE = 'VIEWS/TRAFFIC/PICK_FROM_QUEUE',
  SET_INTENT_CAMPAIGN_MODAL_STATE = 'VIEWS/TRAFFIC/SET_INTENT_CAMPAIGN_MODAL_STATE',
}

export const TrafficActions = {
  refreshList: (payload: IRefreshCustomersPayload) => createAction(TrafficActionNames.REFRESH_LIST, payload),
  refreshListThrottled: () => createAction(TrafficActionNames.REFRESH_LIST_THROTTLED),
  resizeColumn: (payload: IResizeColumnPayload) => createAction(TrafficActionNames.RESIZE_COLUMN, payload),
  sortColumn: (payload: ISortColumnPayload) => createAction(TrafficActionNames.SORT_COLUMN, payload),
  addFilter: (payload: IAddFilterPayload) => createAction(TrafficActionNames.ADD_FILTER, payload),
  removeFilter: (payload: IRemoveFilterPayload) => createAction(TrafficActionNames.REMOVE_FILTER, payload),
  updateFilter: (payload: IUpdateFilterPayload) => createAction(TrafficActionNames.UPDATE_FILTER, payload),
  changeOperator: (payload: IChangeOperatorPayload) => createAction(TrafficActionNames.CHANGE_OPERATOR, payload),
  applySegmentFilters: (payload: IApplySegmentFilterPayload) =>
    createAction(TrafficActionNames.APPLY_SEGMENT_FILTERS, payload),
  customerThreadStatusChange: () => createAction(TrafficActionNames.CUSTOMER_THREAD_STATUS_CHANGE),
  customersListMount: () => createAction(TrafficActionNames.CUSTOMERS_LIST_MOUNT),
  setColumnsVisibility: (payload: ISetColumnsVisibilityPayload) =>
    createAction(TrafficActionNames.SET_COLUMNS_VISIBILITY, payload),
  setColumnsOrder: (payload: ISetColumnsOrderPayload) => createAction(TrafficActionNames.SET_COLUMNS_ORDER, payload),
  saveColumnsVisibility: (payload: ISaveColumnsVisibilityPayload) =>
    createAction(TrafficActionNames.SAVE_COLUMNS_VISIBILITY, payload),
  saveColumnsOrder: (payload: ISaveColumnsOrderPayload) => createAction(TrafficActionNames.SAVE_COLUMNS_ORDER, payload),
  bannedCustomersListUpdate: () => createAction(TrafficActionNames.BANNED_CUSTOMERS_LIST_UPDATE),
  clearFilters: () => createAction(TrafficActionNames.CLEAR_FILTERS),
  setCustomMessageModalState: (payload: ISetCustomMessageModalStatePayload) =>
    createAction(TrafficActionNames.SET_CUSTOM_MESSAGE_MODAL_STATE, payload),
  updateCustomersCounters: (payload: IUpdateCustomersCountersPayload) =>
    createAction(TrafficActionNames.UPDATE_CUSTOMERS_COUNTERS, payload),
  pickFromQueue: () => createAction(TrafficActionNames.PICK_FROM_QUEUE),
  setIntentCampaignModalState: (payload: ISetIntentCampaignModalStatePayload) =>
    createAction(TrafficActionNames.SET_INTENT_CAMPAIGN_MODAL_STATE, payload),
};

export type TrafficAction = ActionsUnion<typeof TrafficActions>;
