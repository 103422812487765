import type { ChatbotInLivechatAction } from './actions';
import type { IChatbotInLivechatState } from './interfaces';

const initialState: IChatbotInLivechatState = {
  content: '',
  error: '',
  faq: [],
  status: 'idle',
};

export function chatbotInLivechatReducer(
  state: IChatbotInLivechatState = initialState,
  action: ChatbotInLivechatAction
): IChatbotInLivechatState {
  switch (action.type) {
    default:
      return state;
  }
}
