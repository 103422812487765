import { css } from '@emotion/css';

export const titleStyles = css`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const messageStyles = css`
  margin-top: 8px;
`;

export const imageStyles = css`
  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
`;

export const tooltipStyles = css`
  max-width: 320px;
  padding: 16px;
`;

export const tooltipContentStyles = css`
  position: relative;
  line-height: 1.42;
  padding: 8px;
`;

export const tooltipCloseStyles = css`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

export const tooltipFooterStyles = css`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const tooltipStepCounterStyles = css`
  color: #8e949a;
  margin-right: auto;
`;
