import { useMutation } from '@tanstack/react-query';

import { AcknowledgeNotificationsRequest } from 'services/connectivity/ml-gateway-api/ai-engine/types';
import { aiEngineClient } from 'services/connectivity/ml-gateway-api/ai-engine/client';

type AcknowledgeNotificationsData = {
  notificationIds: string[];
};

type UseAcknowledgeNotifications = {
  acknowledgeNotifications: (data: AcknowledgeNotificationsData) => void;
};

export const useAnnotateNotifications = (): UseAcknowledgeNotifications => {
  const { mutate } = useMutation<void, Error, AcknowledgeNotificationsData>({
    mutationFn: async ({ notificationIds }) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const request: AcknowledgeNotificationsRequest = { notification_ids: notificationIds };
      /* eslint-enable @typescript-eslint/naming-convention */
      const { error } = await aiEngineClient.acknowledgeNotifications(request);
      if (error) {
        throw error;
      }
    },
  });

  return {
    acknowledgeNotifications: mutate,
  };
};
