import { type IApiTrustedDomains, type ITrustedDomainsEntityState } from './interfaces';

export function deserializeTrustedDomains(apiTrustedDomains: IApiTrustedDomains): ITrustedDomainsEntityState {
  return {
    trusted: apiTrustedDomains.domains.map((entry) => ({
      createdBy: entry.created_by,
      creationDate: new Date(entry.creation_date),
      domain: entry.domain,
      updateDate: new Date(entry.update_date),
      updatedBy: entry.updated_by,
    })),
    suggested: apiTrustedDomains.suggested_domains,
  };
}
