// @ts-strict-ignore
import { toKeyMap } from 'helpers/array';
import { type KeyMap } from 'helpers/interface';
import type { CannedResponse } from 'interfaces/canned-responses';

import { type IFetchCannedResult } from './interfaces';

export function deserializeCannedResponse(data: IFetchCannedResult): CannedResponse {
  const {
    group,
    id,
    created_by: createdBy,
    modified_by: modifiedBy,
    creation_date: creationDate,
    modification_date: modificationDate,
    tags,
    text,
    private: isPrivate,
  } = data;

  return {
    groupId: String(group),
    id: String(id),
    modificationTimestampInMs: modificationDate ? modificationDate * 1000 : creationDate * 1000,
    tags,
    text,
    createdBy,
    modifiedBy,
    isPrivate,
  };
}

export function deserializeCannedResponseCollection(data: CannedResponse[]): KeyMap<CannedResponse> {
  return toKeyMap(data, 'id');
}
