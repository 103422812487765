/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, type FC, type HTMLAttributes } from 'react';

import { useEffectOnce } from 'hooks/use-effect-once';
import { constructStepId } from 'services/tour/helpers';
import type { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { setTourStepReadiness } from 'services/tour/tour';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  tourId: UserGuidedTourType;
  step: string;
  asyncReady?: boolean;
  className?: string;
  dataTestId?: string;
  onClick?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

export const UserGuidedTourStep: FC<IProps> = ({
  tourId,
  step,
  children,
  className,
  asyncReady,
  dataTestId,
  onClick,
  onMouseEnter,
  onMouseLeave,
  ...restProps
}) => {
  useEffectOnce(() => {
    if (!asyncReady) {
      setTourStepReadiness(tourId, step, true);

      return () => {
        setTourStepReadiness(tourId, step, false);
      };
    }
  });

  const handleStepReady = useCallback(
    (isReady: boolean): void => {
      setTourStepReadiness(tourId, step, isReady);
    },
    [tourId, step]
  );

  return (
    <div
      id={constructStepId(tourId, step)}
      data-testid={dataTestId}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...restProps}
    >
      {asyncReady && typeof children === 'function' ? children({ onStepReady: handleStepReady }) : children}
    </div>
  );
};
