import { type UseQueryResult, useQuery } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { normalizeError } from 'services/connectivity/configuration-api/helpers';
import { propertiesClient } from 'services/connectivity/configuration-api/properties/client';
import { type Namespace } from 'services/connectivity/configuration-api/properties/constants';
import {
  type ListLicensePropertiesResponse,
  type PropertyValue,
} from 'services/connectivity/configuration-api/properties/types';
import { type ConfigurationApiErrorResponse } from 'services/connectivity/configuration-api/types';
import { QUERY_KEYS } from 'services/query-client/keys';

const STALE_TIME = 15 * 60 * 1000; // 15 minutes

/**
 * Fetches license properties from the API, filtered by namespace.
 *
 * @param {string} [namespace] - Optional namespace to filter properties.
 * @throws {ConfigurationApiNormalizedError} If an error occurs while fetching.
 * @returns {Promise<ListLicensePropertiesResponse[0]>} The license properties for selected namespace.
 */
async function fetchLicenseProperties(namespace: Namespace): Promise<ListLicensePropertiesResponse[0]> {
  const { result, error } = await propertiesClient.listLicense({ namespace });

  if (error) {
    const normalizedError = normalizeError(error);
    throw normalizedError;
  }

  return result[namespace] ?? {};
}

/**
 * React hook to get license properties, filtered by namespace.
 *
 * @param {string} [namespace] - Namespace to filter properties.
 * @returns {UseQueryResult<Record<string, PropertyValue>, ConfigurationApiErrorResponse>}
 * An object containing the license properties and query details.
 */
export function useLicenseProperties(
  namespace: Namespace,
): UseQueryResult<Record<string, PropertyValue>, ConfigurationApiErrorResponse> {
  return useQuery({
    queryKey: QUERY_KEYS[QueryKey.LicenseProperties](namespace),
    queryFn: () => fetchLicenseProperties(namespace),
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME,
  });
}
