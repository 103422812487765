import { type FC } from 'react';

import { Text } from '@livechat/design-system-react-components';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';

import { useCopilotMessageContext } from '../CopilotMessageContext';

import * as styles from './styles';
interface Props {
  message: string;
  onClick?: () => void;
}

export const PopoverSuggestion: FC<Props> = ({ message, onClick }) => {
  const { handleSendMessage } = useCopilotMessageContext();

  const sendEvent = (): void => {
    trackEvent(CopilotTrackEvent.MessageSent, EventPlace.One, {
      source: 'suggestion chip',
      message,
      state: 'popover',
    });
  };

  const handleSuggestionClick = (): void => {
    if (onClick) {
      onClick();
      sendEvent();

      return;
    }

    handleSendMessage(message);
    sendEvent();
  };

  return (
    <div className={styles.popoverSuggestion} onClick={handleSuggestionClick}>
      <Text bold>{message}</Text>
    </div>
  );
};
