import { type FC } from 'react';

import { BetaBadge } from 'components/beta-badge/BetaBadge';
import { OnOffBadge } from 'components/on-off-badge/OnOffBadge';
import { SIDE_NAVIGATION_PARENT_NEW_BADGE_TEST_ID } from 'components/side-navigation/constants';
import * as styles from 'components/side-navigation/styles';
import { hasItemNewBadge } from 'helpers/settings';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';

interface IProps {
  item: ISideNavigationItem;
  isListOpen?: boolean;
}

export const SideNavigationItemWithRightNode: FC<IProps> = ({ item, isListOpen }) => {
  const childHasNewBadge = Boolean(item.children?.find((child) => hasItemNewBadge(child)));

  return (
    <>
      {item.onOffBadge && <OnOffBadge value={item.onOffBadge} important={item.important} />}
      {item.betaBadge && <BetaBadge className={styles.betaBadge} />}
      {(hasItemNewBadge(item) || childHasNewBadge) && (!isListOpen || !item.children) && (
        <span className={styles.newBadge} data-testid={SIDE_NAVIGATION_PARENT_NEW_BADGE_TEST_ID}>
          NEW!
        </span>
      )}
    </>
  );
};
