export const enum LC3IncomingEvent {
  AgentApproved = 'agent_approved',
  AgentCreated = 'agent_created',
  AgentDeleted = 'agent_deleted',
  AgentDisconnected = 'agent_disconnected',
  AgentSuspended = 'agent_suspended',
  AgentUnsuspended = 'agent_unsuspended',
  AgentUpdated = 'agent_updated',
  BotCreated = 'bot_created',
  BotDeleted = 'bot_deleted',
  BotUpdated = 'bot_updated',
  ChatAccessUpdated = 'chat_access_updated',
  ChatDeactivated = 'chat_deactivated',
  ChatTransferred = 'chat_transferred',
  CustomerLeft = 'customer_left',
  CustomerPageUpdated = 'customer_page_updated',
  CustomerUpdated = 'customer_updated',
  EventPropertiesDeleted = 'event_properties_deleted',
  EventPropertiesUpdated = 'event_properties_updated',
  EventsMarkedAsSeen = 'events_marked_as_seen',
  GroupCreated = 'group_created',
  GroupDeleted = 'group_deleted',
  GroupUpdated = 'group_updated',
  GroupsStatusUpdated = 'groups_status_updated',
  IncomingChat = 'incoming_chat',
  IncomingCustomer = 'incoming_customer',
  IncomingCustomers = 'incoming_customers',
  IncomingEvent = 'incoming_event',
  IncomingMulticast = 'incoming_multicast',
  IncomingSneakPeek = 'incoming_sneak_peek',
  QueuePositionsUpdated = 'queue_positions_updated',
  RoutingStatusSet = 'routing_status_set',
  ThreadPropertiesDeleted = 'thread_properties_deleted',
  ThreadPropertiesUpdated = 'thread_properties_updated',
  ThreadTagged = 'thread_tagged',
  ThreadUntagged = 'thread_untagged',
  UserAddedToChat = 'user_added_to_chat',
  UserRemovedFromChat = 'user_removed_from_chat',
}
