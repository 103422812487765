import type { KeyMap, PickRequired } from 'helpers/interface';

import { type CopilotEntitiesState, type CopilotEvent } from './interfaces';

export function getGroupedIdsForNewCopilotEvent(
  currentEvents: KeyMap<CopilotEvent>,
  currentGroupedEventIds: string[][],
  newEvent: CopilotEvent,
): string[][] {
  if (Object.keys(currentEvents).length === 0) {
    return [[newEvent.eventId]];
  }

  const latestGroupedEventIds = currentGroupedEventIds[currentGroupedEventIds.length - 1];
  const latestEventId = latestGroupedEventIds[latestGroupedEventIds.length - 1];
  const latestEvent = currentEvents[latestEventId];

  if (latestEvent.authorType === newEvent.authorType && latestEvent.type === newEvent.type) {
    return [...currentGroupedEventIds.slice(0, -1), [...latestGroupedEventIds, newEvent.eventId]];
  }

  return [...currentGroupedEventIds, [newEvent.eventId]];
}

export function getStateForAddNewCopilotEvent(
  state: CopilotEntitiesState,
  payload: CopilotEvent,
): CopilotEntitiesState {
  const currentEvents = state.events.byIds;
  const currentGroupedEventIds = state.events.groupedIds;

  const groupedIds = getGroupedIdsForNewCopilotEvent(currentEvents, currentGroupedEventIds, payload);

  return {
    ...state,
    events: {
      ...state.events,
      byIds: {
        ...currentEvents,
        [payload.eventId]: payload,
      },
      groupedIds,
    },
  };
}

export function getStateForAddCopilotError(state: CopilotEntitiesState, payload: boolean): CopilotEntitiesState {
  return {
    ...state,
    isError: payload,
  };
}

export function getStateForClearCopilotError(state: CopilotEntitiesState): CopilotEntitiesState {
  return {
    ...state,
    isError: false,
  };
}

export function buildEventGroups(groupedEventsIds: string[][], events: KeyMap<CopilotEvent>): CopilotEvent[][] {
  return groupedEventsIds.map((ids) => ids.map((id) => events[id]));
}

export function getStateForSetCopilotFailedMessageContent(
  state: CopilotEntitiesState,
  payload: string,
): CopilotEntitiesState {
  return {
    ...state,
    failedMessageContent: payload,
  };
}

export function getStateForUpdateCopilotActionButtons(
  state: CopilotEntitiesState,
  payload: PickRequired<CopilotEvent, 'eventId'>,
): CopilotEntitiesState {
  const { eventId, properties } = payload;

  return {
    ...state,
    events: {
      ...state.events,
      byIds: {
        ...state.events.byIds,
        [eventId]: {
          ...state.events.byIds[eventId],
          properties,
        },
      },
    },
  };
}

export function getStateForCopilotMessageFromSpotlight(
  state: CopilotEntitiesState,
  payload: boolean,
): CopilotEntitiesState {
  return {
    ...state,
    isMessageFromSpotlight: payload,
  };
}
