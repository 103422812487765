import { type FC, useEffect, useRef, type MutableRefObject } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CONTROLLED_TEXT_INSERTION_COMMAND } from 'lexical';
import { useDispatch, useSelector } from 'react-redux';

import { useEffectOnce } from 'hooks/use-effect-once';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCopilotDraftMessage } from 'store/views/copilot/selectors';

interface IProps {
  isCommandMenuVisibleRef?: MutableRefObject<boolean>;
}

export const DraftMessagePlugin: FC<IProps> = ({ isCommandMenuVisibleRef }) => {
  const [editor] = useLexicalComposerContext();
  const draftMessage = useSelector(getCopilotDraftMessage);
  const dispatch = useDispatch();
  const currentMessageBoxValueRef = useRef<string | null>(null);

  useEffect(() => {
    const unregisterTextContentListener = editor.registerTextContentListener((textContent) => {
      currentMessageBoxValueRef.current = textContent;
    });

    return () => {
      unregisterTextContentListener();
    };
  }, [editor]);

  useEffectOnce(() => {
    if (draftMessage) {
      editor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, draftMessage);
    }

    return () => {
      if (isCommandMenuVisibleRef?.current) {
        dispatch(CopilotViewActions.saveCopilotDraftMessage(''));

        return;
      }
      dispatch(CopilotViewActions.saveCopilotDraftMessage(currentMessageBoxValueRef.current ?? ''));
    };
  });

  return null;
};
