// @ts-strict-ignore
import type { IRequestsState } from './interfaces';

export interface IWithRequestsState {
  requests: IRequestsState;
}

export const createRequestErrorSelector =
  (actions: string[]) =>
  (state: IWithRequestsState): string =>
    actions
      .reduce(
        (acc: string[], action: string) =>
          state.requests[action] && state.requests[action].error ? [...acc, state.requests[action].error] : acc,
        []
      )
      .join(', ');

export const createRequestFetchingSelector =
  (actions: string[]) =>
  (state: IWithRequestsState): boolean =>
    actions.some((action) => state.requests[action] && state.requests[action].isFetching);

export const createHasFetchedSelector =
  (actions: string[]) =>
  (state: IWithRequestsState): boolean =>
    actions.some(
      (action) => state.requests[action] && !state.requests[action].isFetching && !state.requests[action].error
    );

export const createHasFetchFailedSelector =
  (actions: string[]) =>
  (state: IWithRequestsState): boolean =>
    actions.some(
      (action) => state.requests[action] && !state.requests[action].isFetching && state.requests[action].error
    );

export const createRequestStartedSelector =
  (actions: string[]) =>
  (state: IWithRequestsState): boolean =>
    actions.some((action) => state.requests[action]);
