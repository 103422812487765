import { QueryKey } from 'constants/query-key';
import { CoreProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';

import { anyToBoolean, type BooleanNumericFlag } from './boolean';

export function isNonProfit(): boolean {
  const isNonProfitLicense: BooleanNumericFlag = getQueryClient().getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.CORE),
  )?.[CoreProperty.NonProfit];

  return anyToBoolean(isNonProfitLicense);
}
