import type { RequestResult } from 'interfaces/api/client';
import { type ICampaign } from 'store/features/applications/interfaces';

import { BaseApi, PassVersionMode } from '../base-api';

export class CampaignsApi extends BaseApi {
  protected readonly prefix = 'campaigns';
  protected readonly passVersionMode: PassVersionMode = PassVersionMode.None;

  fetchAll = () => this.get('');
  fetch = (campaignId: string): Promise<RequestResult<ICampaign>> => this.get(campaignId);
}
