import { getDay, addDays, startOfDay, isValid, isBefore, format } from 'date-fns';

import { DAY_MONDAY_INDEX, DAY_SUNDAY_INDEX } from 'constants/date';
import { getDateOfDayFromFinishedWeek } from 'helpers/date';
import { type ISerializedFilters } from 'interfaces/reports';
import {
  AgentCustomPropertyName,
  type IFetchAgentCustomPropertiesSuccessPayload,
  type IInsightsHintsStatusAgentCustomProperty,
  type IInsightsMetricsAgentCustomProperty,
} from 'store/features/agent-custom-properties/interfaces';

import {
  INSIGHTS_HINTS_PERCENTAGE_DIFF_THRESHOLD_LOW_CHATS_COUNT,
  INSIGHTS_HINTS_PERCENTAGE_DIFF_THRESHOLD_NORMAL_CHATS_COUNT,
  WEEKLY_CHATS_COUNT_LOW_NORMAL_THRESHOLD,
} from '../constants';
import { type InsightsMetricTrend, type InsightsSerializedData, InsightsMetricType } from '../interfaces';

export function getDateOfNextMonday(date: Date): Date {
  let daysToAdd = DAY_MONDAY_INDEX - getDay(date);
  if (daysToAdd <= 0) daysToAdd += 7;

  return addDays(date, daysToAdd);
}

export function buildReportFilters(
  date: Date,
  weeksAgo: number,
  defaultFilters: ISerializedFilters = {}
): ISerializedFilters {
  return {
    ...defaultFilters,
    dateFrom: getDateOfDayFromFinishedWeek(date, DAY_MONDAY_INDEX, weeksAgo),
    dateTo: getDateOfDayFromFinishedWeek(date, DAY_SUNDAY_INDEX, weeksAgo),
  };
}

export function getInsightsMetricsExpireLocalDate(date: Date): string {
  const expiresAtDate = startOfDay(getDateOfNextMonday(date));

  // when creating a Date from such string it will be created in local timezone
  // and we want to take this data expired on start of Monday (in local time)
  return format(expiresAtDate, "yyyy-MM-dd'T'HH:mm");
}

export function getInsightsDataFromAgentProperties(
  agentCustomProperties: IFetchAgentCustomPropertiesSuccessPayload
): InsightsSerializedData | null {
  const metricsData = agentCustomProperties[AgentCustomPropertyName.InsightsMetrics] as
    | IInsightsMetricsAgentCustomProperty
    | undefined;
  const hintsData = agentCustomProperties[AgentCustomPropertyName.InsightsHints] as
    | IInsightsHintsStatusAgentCustomProperty
    | undefined;

  if (!metricsData || !hintsData) {
    return null;
  }

  return {
    metrics: metricsData.metrics,
    acknowledgedHints: hintsData.acknowledgedHints,
    expiresAt: metricsData.expiresAt,
  };
}

export function getWasDateReached(date: Date, targetLocalDate: string): boolean {
  // targetLocalDate stores for example '13.03.2023T00:00' - as a result the date will be created in LOCAL time (not UTC)
  const targetDate = new Date(targetLocalDate);
  const isTargetDateValid = isValid(targetDate);

  if (!isTargetDateValid) {
    return false;
  }

  return !isBefore(date, targetDate);
}

export function isMetricValueMissing(value: number | null | undefined): boolean {
  return !value && value !== 0;
}

export function getAreInsightsHintsEnabled(
  metricType: InsightsMetricType,
  percentageDiff: number | null,
  lastWeekTotalChats: number
): boolean {
  if (percentageDiff === null) {
    return true;
  }

  const absolutePercentageDiff = Math.abs(percentageDiff);

  if (lastWeekTotalChats <= WEEKLY_CHATS_COUNT_LOW_NORMAL_THRESHOLD) {
    return absolutePercentageDiff >= INSIGHTS_HINTS_PERCENTAGE_DIFF_THRESHOLD_LOW_CHATS_COUNT[metricType];
  }

  return absolutePercentageDiff >= INSIGHTS_HINTS_PERCENTAGE_DIFF_THRESHOLD_NORMAL_CHATS_COUNT[metricType];
}

export function getTrend(metricType: InsightsMetricType, metricDiffValue: number): InsightsMetricTrend {
  if (metricDiffValue === 0) {
    return 'neutral';
  }

  switch (metricType) {
    case InsightsMetricType.MissedChats:
    case InsightsMetricType.ResponseTime:
      return metricDiffValue < 0 ? 'positive' : 'negative';

    default:
      return metricDiffValue > 0 ? 'positive' : 'negative';
  }
}

export function getNow(): Date {
  return new Date();
}
