import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import type { Properties } from 'interfaces/property';
import { type UpdateChatPropertiesEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#update-chat-properties
 */
export async function updateChatProperties(id: string, chatProperties: Properties): Promise<unknown> {
  const event: UpdateChatPropertiesEvent = {
    action: WebSocketAction.UpdateChatProperties,
    payload: {
      id,
      properties: chatProperties,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
