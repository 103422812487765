// @ts-strict-ignore
import { SubscriptionActionNames, type SubscriptionActionTypes } from 'store/entities/subscription/actions';

import { SubscriptionViewActionNames, type SubscriptionViewActionTypes } from './actions';
import { type ISubscriptionViewState } from './interfaces';

type ActionTypes = SubscriptionViewActionTypes | SubscriptionActionTypes;

const initialState: ISubscriptionViewState = {
  currentCardBrand: null,
  canRestartTrial: false,
  isDowngradeConfirmFlowEnabled: true,
  licenseDataRefreshFailure: false,
  recurlyError: null,
  isSubscribeButtonDisabled: false,
  isCalendlyModalOpen: false,
  isComparePlansModalOpen: false,
  shouldGoBackToPlansModal: false,
  showNavigation: true,
};

export const subscriptionReducer = (
  state: ISubscriptionViewState = initialState,
  action: ActionTypes
): ISubscriptionViewState => {
  switch (action.type) {
    case SubscriptionActionNames.CREATE_SUBSCRIPTION_SUCCESS:
    case SubscriptionActionNames.UPDATE_SUBSCRIPTION_SUCCESS:
    case SubscriptionActionNames.UPGRADE_SUBSCRIPTION_SUCCESS:
    case SubscriptionViewActionNames.REMOVE_RECOMMENDED_PLAN: {
      return {
        ...state,
        recommendedPlan: undefined,
        recommendedCycle: undefined,
        highlightedFeatures: undefined,
      };
    }
    case SubscriptionViewActionNames.SET_RECOMMENDED_PLAN_FROM_QUERY_PARAMS: {
      return {
        ...state,
        recommendedPlan: action.payload.params.recommendedPlan,
        recommendedCycle: action.payload.params.cycle,
        highlightedFeatures: action.payload.params.highlightedFeatures,
      };
    }
    case SubscriptionViewActionNames.SET_RECOMMENDED_PLAN_FOR_COMPANY_SIZE: {
      return {
        ...state,
        companySizeRecommendedPlan: action.payload,
      };
    }
    case SubscriptionViewActionNames.RESET_SUBSCRIPTION_STATE: {
      return {
        ...state,
        isDowngradeConfirmFlowEnabled: true,
      };
    }
    case SubscriptionViewActionNames.DISABLE_DOWNGRADE_CONFIRMATION_FLOW: {
      return {
        ...state,
        isDowngradeConfirmFlowEnabled: false,
      };
    }
    case SubscriptionViewActionNames.SET_CURRENT_CARD_BRAND: {
      return {
        ...state,
        currentCardBrand: action.payload,
      };
    }
    case SubscriptionViewActionNames.SET_CAN_RESTART_TRIAL: {
      return {
        ...state,
        canRestartTrial: true,
      };
    }
    case SubscriptionViewActionNames.LICENSE_DATA_REFRESH_FAILURE: {
      return {
        ...state,
        licenseDataRefreshFailure: true,
      };
    }
    case SubscriptionViewActionNames.SET_RECURLY_ERROR: {
      return {
        ...state,
        recurlyError: action.payload,
      };
    }
    case SubscriptionViewActionNames.SET_IS_SUBSCRIBE_BUTTON_DISABLED: {
      return {
        ...state,
        isSubscribeButtonDisabled: action.payload,
      };
    }
    case SubscriptionViewActionNames.SET_IS_CALENDLY_MODAL_OPEN: {
      return {
        ...state,
        isCalendlyModalOpen: action.payload,
      };
    }

    case SubscriptionViewActionNames.SET_IS_COMPARE_PLANS_MODAL_OPEN: {
      return {
        ...state,
        isComparePlansModalOpen: action.payload,
      };
    }

    case SubscriptionViewActionNames.SET_SHOULD_GO_BACK_TO_PLANS_MODAL: {
      return {
        ...state,
        shouldGoBackToPlansModal: action.payload,
      };
    }

    case SubscriptionViewActionNames.SET_SHOW_NAVIGATION: {
      return {
        ...state,
        showNavigation: action.payload,
      };
    }
    default:
      return state;
  }
};
