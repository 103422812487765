export enum Recurs {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export const recursLabels: Record<string, string> = {
  [Recurs.Day]: 'Daily',
  [Recurs.Week]: 'Weekly',
  [Recurs.Month]: 'Monthly',
};
