import { type FC } from 'react';

import { cx } from '@emotion/css';

import * as styles from './styles';

interface IProps {
  className?: string;
  wide?: boolean;
  tall?: boolean;
  loading: boolean;
  removeMargin?: boolean;
}

export const SkeletonLine: FC<IProps> = ({ className, wide, tall, loading, removeMargin }) => (
  <div
    className={cx(styles.skeletonLine, className, {
      [styles.skeletonLineWide]: wide,
      [styles.skeletonLineTall]: tall,
      [styles.skeletonLineRemovedMargin]: removeMargin,
      [styles.loadingAnimation]: loading,
    })}
  />
);
