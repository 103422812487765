import * as Sentry from '@sentry/browser';
import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import type { AnyAction } from 'redux';

import { LoginStatus } from 'constants/login-status';
import { TeamEvent } from 'constants/team/event';
import { ToastContent } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { showToast } from 'helpers/toast';
import { normalizeError } from 'services/connectivity/agent-chat-api/helpers';
import { routingStatusClient } from 'services/connectivity/agent-chat-api/status/client';
import { trackEvent } from 'services/event-tracking';
import { RoutingStatus } from 'services/socket-lc3/agent/interfaces';
import { AgentActions } from 'store/entities/agents/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';

interface UseStatusChange {
  handleStatusChange: UseMutateFunction<void, string, StatusChangePayload, unknown>;
  isProcessing: boolean;
  isSuccess: boolean;
  error: string | null;
}

interface StatusChangePayload {
  login: string;
  status: LoginStatus;
  eventPlace?: EventPlace;
}

export const statusChangeRequest = async ({ login, status }: StatusChangePayload): Promise<void> => {
  const serializedStatus =
    status === LoginStatus.Online ? RoutingStatus.AcceptingChats : RoutingStatus.NotAcceptingChats;

  const { error } = await routingStatusClient.set({
    status: serializedStatus,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    agent_id: login,
  });

  if (error) {
    const normalizedError = normalizeError(error);
    throw normalizedError;
  }
};

export function handleError(): void {
  showToast(ToastVariant.Error, ToastContent.AGENT_UPDATE_ERROR);

  if (!window.navigator.onLine) {
    const error = new Error(
      `Failed to send status change request. Browser is in offline mode, navigator.onLine: ${window.navigator.onLine.toString()}`
    );
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function handleSuccess(
  { login, status, eventPlace = EventPlace.Team }: StatusChangePayload,
  dispatch: (action: AnyAction) => AnyAction
): void {
  dispatch(AgentActions.agentUpdate({ login, status }));
  trackEvent(TeamEvent.AgentStatusChanged, eventPlace, {
    agent: login,
    status,
  });
}

export const useStatusChange = (): UseStatusChange => {
  const dispatch = useDispatch();

  const mutation = useMutation<void, string, StatusChangePayload, unknown>(statusChangeRequest, {
    onSuccess: (_, params) => handleSuccess(params, dispatch),
    onError: handleError,
  });

  return {
    handleStatusChange: mutation.mutate,
    isProcessing: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
  };
};
