import { type FC } from 'react';

import { useSelector } from 'react-redux';

import { getCanUseCopilotPopover } from 'store/views/copilot/selectors';

import { NOTIFICATIONS_NEW_TOP_BAR_TEST_ID } from './constants';
import { CopilotWithBadge } from './CopilotWithBadge';
import { InviteAgents } from './invite-agents/InviteAgents';
import { SpotlightTrigger } from './spotlight/SpotlightTrigger';

import * as styles from './styles';

export const TopBar: FC = () => {
  const canUseCopilotChat = useSelector(getCanUseCopilotPopover);

  return (
    <div id="spotlight-container" className={styles.spotlightContainer}>
      <div className={styles.topBarWrapper} data-testid={NOTIFICATIONS_NEW_TOP_BAR_TEST_ID}>
        <SpotlightTrigger />
        <InviteAgents />
        {canUseCopilotChat && <CopilotWithBadge />}
      </div>
    </div>
  );
};
