import { FilterTypeEquality, FilterTypeNumber, FilterTypeString } from 'constants/filters/filter-type';
import type { Filter } from 'interfaces/components/filter/filter';
import type { NumberRangeFilter } from 'interfaces/filter';

import {
  type FilterBooleanOperator,
  FilterIpOperator,
  FilterNumericOperator,
  type FilterSetOperator,
  FilterStringOperator,
  FilterType,
  FilterValueType,
  type TrafficFilter,
} from './interfaces';

export function serializeValues(filter: TrafficFilter): Filter['filterValues']['values'] {
  const { valueType, filterType, operator } = filter;

  switch (valueType) {
    case FilterValueType.String: {
      const value = operator === FilterStringOperator.HasAnyValue ? '' : filter.value;

      return {
        [FilterTypeString.IsExactly]: { first: '' },
        [FilterTypeString.IsNot]: { first: '' },
        [FilterTypeString.Contains]: { first: '' },
        [FilterTypeString.DoesNotContain]: { first: '' },
        [operator]: { first: value },
      };
    }
    case FilterValueType.Numeric: {
      const value =
        operator === FilterNumericOperator.IsBetween
          ? { first: filter.firstValue, second: filter.secondValue }
          : { first: filter.value };

      return {
        [FilterTypeNumber.IsExactly]: { first: 1 },
        [FilterTypeNumber.IsNot]: { first: 1 },
        [FilterTypeNumber.IsGreaterThan]: { first: 1 },
        [FilterTypeNumber.IsGreaterThanOrEqualTo]: { first: 1 },
        [FilterTypeNumber.IsLessThan]: { first: 1 },
        [FilterTypeNumber.IsLessThanOrEqualTo]: { first: 1 },
        [FilterTypeNumber.IsBetween]: { first: 1, second: 1 },
        [operator]: value,
      };
    }
    case FilterValueType.SetInclusive: {
      const { value } = filter;
      const convertedValues = filterType === FilterType.Group ? value.map((v) => +v) : value;

      return {
        [FilterTypeEquality.Is]: [],
        [FilterTypeEquality.IsNot]: [],
        [operator]: convertedValues,
      };
    }
  }
}

export function serializeFilterValues(filter: TrafficFilter): Filter['filterValues'] {
  const { valueType } = filter;

  switch (valueType) {
    case FilterValueType.Boolean: {
      const { operator } = filter;

      return {
        type: operator,
      };
    }
    case FilterValueType.IPRange: {
      const { operator, value } = filter;
      const myIp = operator === FilterIpOperator.IsNotMy ? value : '';
      const ip = operator === FilterIpOperator.IsNotOther ? value : '';
      const type = operator;

      return { type, myIp, ip };
    }
    case FilterValueType.String:
    case FilterValueType.Numeric:
    case FilterValueType.SetInclusive: {
      const { operator } = filter;

      return {
        type: operator,
        values: serializeValues(filter),
      };
    }
  }
}

export function serializeFilter(filter: TrafficFilter, isDraft = false): Filter {
  const { id, filterType } = filter;

  return {
    id: +id,
    type: filterType as unknown as Filter['type'],
    filterValues: serializeFilterValues(filter),
    removable: true,
    isDraft,
  };
}

export function deserializeFilterValueType(filterType: FilterType) {
  switch (filterType) {
    case FilterType.Activity:
    case FilterType.AssignedAgent:
    case FilterType.Country:
    case FilterType.Group:
    case FilterType.Intent:
      return FilterValueType.SetInclusive;
    case FilterType.City:
    case FilterType.Email:
    case FilterType.Name:
    case FilterType.CameFrom:
    case FilterType.State:
    case FilterType.LastPageTitle:
      return FilterValueType.String;
    case FilterType.IP:
      return FilterValueType.IPRange;
    case FilterType.NumberOfVisits:
      return FilterValueType.Numeric;
    case FilterType.ReturningCustomer:
    case FilterType.WebCrawlers:
    case FilterType.Priority:
      return FilterValueType.Boolean;
  }
}

export function deserializeFilter(filter: Filter): TrafficFilter {
  const { id: serializedId, type, filterValues } = filter;
  const id = serializedId?.toString() || (Math.random() * 1000).toFixed(0); // todo: use uniqueId()
  const filterType = type as unknown as FilterType;
  const valueType = deserializeFilterValueType(filterType);

  const filterBase = {
    id,
    filterType,
  };

  switch (valueType) {
    case FilterValueType.Numeric: {
      const operator = filterValues.type as FilterNumericOperator;
      const { first, second } = (filterValues.values?.[operator] ?? { first: 1 }) as NumberRangeFilter['values'];

      if (operator === FilterNumericOperator.IsBetween) {
        return {
          ...filterBase,
          valueType,
          operator,
          firstValue: first,
          secondValue: second as unknown as number,
        };
      }

      return {
        ...filterBase,
        valueType,
        operator,
        value: first,
      };
    }
    case FilterValueType.String: {
      const operator = filterValues.type as FilterStringOperator;

      if (operator === FilterStringOperator.HasAnyValue) {
        return {
          ...filterBase,
          valueType,
          operator,
        };
      }

      const { first: value } = (filterValues.values?.[filterValues.type] ?? { first: '' }) as { first: string };

      return {
        ...filterBase,
        valueType,
        operator,
        value,
      };
    }
    case FilterValueType.SetInclusive: {
      const operator = filterValues.type as FilterSetOperator;
      const setValues: (string | number)[] = filterValues.values?.[filterValues.type] ?? [];
      const value = setValues.map((v) => v.toString());

      return {
        ...filterBase,
        valueType,
        operator,
        value,
      };
    }
    case FilterValueType.IPRange: {
      const { type, myIp, ip } = filterValues;
      const operator = type as FilterIpOperator;
      const value = operator === FilterIpOperator.IsNotMy ? myIp : ip;

      return {
        ...filterBase,
        valueType,
        operator,
        value,
      };
    }
    case FilterValueType.Boolean: {
      const operator = filterValues.type as FilterBooleanOperator;

      return {
        ...filterBase,
        valueType,
        operator,
      };
    }
  }
}
