import { AbsoluteAutomateRoutes } from 'constants/automate/constants';
import { BaseRouteName } from 'constants/routes';
import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';

import { InviteAgentsEvent } from './constants';

export const onSetUpChatbotClick = (): void => {
  navigate(AbsoluteAutomateRoutes.Chatbots);
  trackEvent(InviteAgentsEvent.SetUpChatbot, EventPlace.TopBar);
};
export const onAvailableAgentsClick = (): void => {
  navigate(BaseRouteName.Team);
  trackEvent(InviteAgentsEvent.NavigateToTeam, EventPlace.TopBar);
};
