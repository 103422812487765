import { createAction, type ActionsUnion } from 'store/helper';

import { type IInvitationLink } from './interfaces';

export enum InviteLinkActionsEnum {
  GET_INVITE_LINK_REQUEST = 'FEATURES/INVITE-LINK/GET_INVITE_LINK_REQUEST',
  GET_INVITE_LINK_SUCCESS = 'FEATURES/INVITE-LINK/GET_INVITE_LINK_SUCCESS',
  GET_INVITE_LINK_FAILURE = 'FEATURES/INVITE-LINK/GET_INVITE_LINK_FAILURE',
  REFRESH_INVITE_LINK_REQUEST = 'FEATURES/INVITE-LINK/REFRESH_INVITE_LINK_REQUEST',
  REFRESH_INVITE_LINK_SUCCESS = 'FEATURES/INVITE-LINK/REFRESH_INVITE_LINK_SUCCESS',
  REFRESH_INVITE_LINK_FAILURE = 'FEATURES/INVITE-LINK/REFRESH_INVITE_LINK_FAILURE',
}

export const InviteLinkActions = {
  getInviteLinkRequest: () => createAction(InviteLinkActionsEnum.GET_INVITE_LINK_REQUEST),
  getInviteLinkSuccess: (newInviteLink: IInvitationLink) =>
    createAction(InviteLinkActionsEnum.GET_INVITE_LINK_SUCCESS, newInviteLink),
  getInviteLinkFailure: (error) => createAction(InviteLinkActionsEnum.GET_INVITE_LINK_FAILURE, { error }),
  refreshInviteLinkRequest: () => createAction(InviteLinkActionsEnum.REFRESH_INVITE_LINK_REQUEST),
  refreshInviteLinkSuccess: (newInviteLink: IInvitationLink) =>
    createAction(InviteLinkActionsEnum.REFRESH_INVITE_LINK_SUCCESS, newInviteLink),
  refreshInviteLinkFailure: (error) => createAction(InviteLinkActionsEnum.REFRESH_INVITE_LINK_FAILURE, { error }),
};

export type InviteLinkActionTypes = ActionsUnion<typeof InviteLinkActions>;
