// @ts-strict-ignore
import { getTime } from 'date-fns';

interface ITimestampResult {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  created_at?: string;
  timestamp?: number;
}

/**
 * Deserializes thread/event timestamp in milliseconds based on the format provided by API.
 * @param data Thread or event that contains information about its timestamp, known also as creation time.
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#events}
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#threads}
 */
export function getThreadEntityTimestampInMs(data: ITimestampResult): number {
  if (data.created_at) {
    const timestampInMs = getTime(data.created_at);

    return timestampInMs && !Number.isNaN(timestampInMs) ? timestampInMs : null;
  }

  if (data.timestamp) {
    const timestampInMs = data.timestamp * 1000;

    return timestampInMs && !Number.isNaN(timestampInMs) ? timestampInMs : null;
  }

  return null;
}
