import { type FC } from 'react';

import { NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';

import * as styles from '../styles';

interface IProps {
  onSkip(): void;
  isVisible: boolean;
}

export const ChangeBrowserBar: FC<IProps> = ({ onSkip, isVisible }) => (
  <NavigationTopBarAlert
    kind="warning"
    isVisible={isVisible}
    closeButton={{
      onClick: onSkip,
      'aria-label': 'Close the prompt about unsupported notifications', // eslint-disable-line @typescript-eslint/naming-convention
    }}
  >
    <Text bold className={styles.textStyles}>
      Your browser doesn’t support browser notifications. We recommend using a modern browser, such as Chrome or
      Firefox.
    </Text>
  </NavigationTopBarAlert>
);
