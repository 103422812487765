// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { EventNames } from 'constants/event-bus-events';
import { PostMessageEvent } from 'constants/post-message-event';
import { SubscriptionRouteEvent } from 'constants/subscription/event';
import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { isCovidCoupon } from 'helpers/recurly';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { type INewSubscription, type ISubscription } from 'interfaces/subscription';
import { ApiManager } from 'services/api/api-manager';
import { type ISubscriptionDTO } from 'services/api/subscription/interfaces';
import { SubscriptionSerializer } from 'services/api/subscription/serializer';
import { EventBus } from 'services/event-bus';
import { trackEvent } from 'services/event-tracking';
import { LicenseCustomPropertiesActions } from 'store/entities/license-custom-properties/actions';
import { SubscriptionActionNames, SubscriptionActions } from 'store/entities/subscription/actions';
import { getSubscription, getSubscriptionChange } from 'store/entities/subscription/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { SubscriptionViewActions } from 'store/views/subscription/actions';
import { getIsNewCouponApplicable } from 'store/views/subscription/selectors';

import { deserializeError, refreshCouponData, mapSubscriptionToNewSubscription, checkIsRecurlyError } from './helpers';
import { AppsInstallationStatus, trackSubscriptionUpdateEvents } from './update-subscription';

function* upgradeSubscription(): SagaIterator {
  const oldSubscription: ISubscription = yield select(getSubscription);
  const newSubscription: INewSubscription = yield select(getSubscriptionChange);
  const isNewCouponApplicable = yield select(getIsNewCouponApplicable);
  const serializedSubscription = SubscriptionSerializer.serialize(newSubscription, { isNewCouponApplicable });

  try {
    const { error }: RequestResult<unknown> = yield call(ApiManager.subscriptionApi.update, serializedSubscription);
    if (error) throw error;

    if (isCovidCoupon(serializedSubscription.coupon_code)) {
      yield put(LicenseCustomPropertiesActions.saveLicenseCustomProperty({ name: 'covid_coupon_used', value: '1' }));
    }

    EventBus.emit(EventNames.SubscriptionChanged);
    yield call(trackSubscriptionUpdateEvents, newSubscription, oldSubscription, AppsInstallationStatus.Skipped);
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.SUBSCRIPTION_UPDATED),
        kind: ToastVariant.Success,
        autoHideDelayTime: ToastAutoHideDelay.Long,
      })
    );
  } catch (e) {
    const isErrorFromRecurly = checkIsRecurlyError(e);
    if (isErrorFromRecurly) {
      yield put(SubscriptionViewActions.setRecurlyError(e.error.recurly || e['#']));
    }

    const error = deserializeError(e);
    trackEvent(SubscriptionRouteEvent.SubscriptionFailed, EventPlace.Subscription, { error });

    if (!isErrorFromRecurly) {
      yield put(ToastsActions.createToast({ content: error, kind: ToastVariant.Error, autoHideDelayTime: undefined }));
    }

    yield put(SubscriptionActions.upgradeSubscriptionFailure(e));

    return;
  }

  try {
    const { result, error }: RequestResult<ISubscriptionDTO> = yield call(ApiManager.subscriptionApi.fetch);
    if (error) {
      yield put(SubscriptionViewActions.licenseDataRefreshFailure());

      return;
    }

    const deserializedSubscription = SubscriptionSerializer.deserialize(result);

    yield call(refreshCouponData, deserializedSubscription);
    yield put(SubscriptionActions.upgradeSubscriptionSuccess(deserializedSubscription));
    yield call(mapSubscriptionToNewSubscription, deserializedSubscription);
    yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshSubscriptionInfo);
  } catch (error) {
    trackEvent(SubscriptionRouteEvent.UpgradeSubscriptionSideEffectFailed, EventPlace.Subscription, { error });
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.SOMETHING_WENT_WRONG),
        kind: ToastVariant.Error,
        autoHideDelayTime: ToastAutoHideDelay.Long,
      })
    );
  }
}

export function* upgradeSubscriptionSaga(): SagaIterator {
  yield takeEvery(SubscriptionActionNames.UPGRADE_SUBSCRIPTION, upgradeSubscription);
}
