import { type ICannedResponseAutotags } from 'store/entities/canned-responses/interfaces';
import { type ITag } from 'store/entities/tags/interfaces';

export function getExtractedText(text: string, maxTextLength = 150): string {
  return text.length > maxTextLength ? `${text.substring(0, maxTextLength - 3)}...` : text;
}

export function filterAvailableTags(tags: string[], availableTagNames: Set<string>): string[] {
  return availableTagNames.size > 0 ? tags.filter((tag) => availableTagNames.has(tag)) : tags;
}

export function extractTagsByGroup(tags: ITag[]): Record<string, Set<string>> {
  return tags.reduce(
    (acc, tag) => {
      if (!acc[tag.group]) acc[tag.group] = new Set();
      acc[tag.group].add(tag.name);

      return acc;
    },
    {} as Record<string, Set<string>>,
  );
}

export function extractAutotagsByGroup(
  autotags: Record<string, ICannedResponseAutotags>,
): Record<string, Record<string, string[]>> {
  return Object.entries(autotags).reduce(
    (acc, [cannedId, { groupId, tags }]) => {
      if (groupId) {
        if (!acc[groupId]) acc[groupId] = {};
        acc[groupId][cannedId] = tags;
      }

      return acc;
    },
    {} as Record<string, Record<string, string[]>>,
  );
}
