import { type IDevToolsViewState } from './interfaces';

interface IWithDevToolsViewState {
  views: {
    devTools: IDevToolsViewState;
  };
}

export function areDevToolsVisible(state: IWithDevToolsViewState): boolean {
  return state.views.devTools.isVisible;
}
