import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import { type IChatButton } from './interfaces';

export class ButtonsApi extends BaseApi {
  protected readonly prefix = 'buttons';

  fetchAll = (groupId: string): Promise<RequestResult<IChatButton[]>> => this.get('', { group: groupId });
  remove = (buttonId: string): Promise<RequestResult<void>> => this.delete(buttonId);
  update = (buttonId: string, data: FormData): Promise<RequestResult<IChatButton>> => this.put(buttonId, data);
  create = (groupId: string, data: FormData): Promise<RequestResult<IChatButton>> =>
    this.post('', data, undefined, `buttons?group=${groupId}`);
}
