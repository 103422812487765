import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ApiManager } from 'services/api/api-manager';
import { FacebookActions, FacebookActionsEnum } from 'store/entities/facebook/actions';

function* fetchFacebookConnectedPages(): SagaIterator {
  const response = yield call(ApiManager.facebookMessengerApi.fetchConnectedPages);
  if (response.result) {
    const pages = response.result;
    yield put(FacebookActions.setFacebookMessengerPages(pages));
  }
}

export function* facebookSaga(): SagaIterator {
  yield takeEvery('APP_READY', fetchFacebookConnectedPages);
  yield takeEvery(FacebookActionsEnum.FETCH_FACEBOOK_CONNECTED_PAGES, fetchFacebookConnectedPages);
}
