import { type KeyMap } from 'helpers/interface';
import type { Experiment } from 'interfaces/experiment';

import { ExperimentsActionNames, type ExperimentAction } from './actions';

const initialState: KeyMap<Experiment> = {};

export function experimentsReducer(
  state: KeyMap<Experiment> = initialState,
  action: ExperimentAction,
): KeyMap<Experiment> {
  switch (action.type) {
    case ExperimentsActionNames.FETCH_EXPERIMENTS_SUCCESS:
      return action.payload.experiments.reduce((acc, value) => {
        acc[value.name] = value;

        return acc;
      }, {});
    default:
      return state;
  }
}
