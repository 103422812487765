import { createMlGatewayApiClient } from '../client';
import { type GenericMlGatewayError } from '../types';

import { type FetchTagSuggestionsRequest, type FetchTagSuggestionsResponse } from './types';

const client = createMlGatewayApiClient();

export const tagSuggestionsClient = {
  fetchTagSuggestions: async (data: FetchTagSuggestionsRequest) => {
    return client.post<FetchTagSuggestionsResponse, GenericMlGatewayError>('predict/tag-suggestions', {
      json: data,
    });
  },
};
