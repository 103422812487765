export enum OnboardingRoute {
  Base = '/welcome',
  One = '/welcome/one',
  Role = '/welcome/role',
  Name = '/welcome/name',
  Website = '/welcome/website',
  InstallCode = '/welcome/installation',
  CompanySize = '/welcome/company-size',
  Team = '/welcome/team',
  Intent = '/welcome/intent',
  Integrations = '/welcome/integrations',
  DownloadApp = '/welcome/download-app',
}

export enum OnboardingSubRoute {
  One = 'one',
  Role = 'role',
  Name = 'name',
  Website = 'website',
  InstallCode = 'installation',
  CompanySize = 'company-size',
  Team = 'team',
  Intent = 'intent',
  Integrations = 'integrations',
  DownloadApp = 'download-app',
}
