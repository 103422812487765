import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { TicketsViewActions } from 'store/views/tickets/actions';
import { isEmailsMappingFetched } from 'store/views/tickets/selectors';

export const useFetchEmailsMapping = (): void => {
  const dispatch = useDispatch();
  const isFetched = useSelector(isEmailsMappingFetched);

  useEffect(() => {
    if (!isFetched) {
      dispatch(TicketsViewActions.fetchTicketsEmailsMapping({ groupId: -1 }));
    }
  }, [dispatch, isFetched]);
};
