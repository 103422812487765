import { ChatRecipients } from 'constants/chat-recipients';
import { UserType } from 'constants/user-type';
import { AppStateProvider } from 'services/app-state-provider';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { getThreadByChatId } from 'store/entities/chats/selectors';

import { addUserToChat } from './add-user-to-chat';

export async function superviseChat(chatId: string): Promise<unknown> {
  const thread = AppStateProvider.selectFromStore(getThreadByChatId, chatId);

  if (!thread) {
    return;
  }

  const currentLogin = AppStateProvider.selectFromStore(getLoggedInAgentLogin);

  return await addUserToChat(chatId, currentLogin, UserType.Agent, ChatRecipients.Agents);
}
