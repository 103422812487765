import { useMutation } from '@tanstack/react-query';
import { AnnotateReplyType, AnnotateReplyValue } from 'interfaces/knowledge-sources';
import { AnnotateReplyRequest } from 'services/connectivity/ml-gateway-api/ai-engine/types';
import { aiEngineClient } from 'services/connectivity/ml-gateway-api/ai-engine/client';

type AnnotationData = {
  traceId: string;
  comment?: string;
  type: AnnotateReplyType;
  value: AnnotateReplyValue;
};

type UseSendAnnotation = {
  sendAnnotation: (data: AnnotationData) => void;
};

export const useSendAnnotation = (): UseSendAnnotation => {
  const { mutate } = useMutation<void, Error, AnnotationData>({
    mutationFn: async ({ traceId, comment, type, value }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const request: AnnotateReplyRequest = { trace_id: traceId, comment, name: type, value };

      const { error } = await aiEngineClient.sendAnnotation(request);
      if (error) {
        throw error;
      }
    },
  });

  return {
    sendAnnotation: mutate,
  };
};
