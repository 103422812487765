/* eslint-disable @typescript-eslint/naming-convention */
import type { IWidgetSnapshotConfigProps } from 'helpers/widget-preview';
import type { IFormConfiguration } from 'interfaces/helpdesk';

export enum WidgetVisibility {
  Maximized = 'maximized',
  Minimized = 'minimized',
  Hidden = 'hidden',
}

export interface WidgetStateInterface {
  visibility?: { state: WidgetVisibility };
  localization?: WidgetStateLocalizationInterface;
  mobile?: boolean;
  config?: WidgetConfigInterface;
  fields: any[];
  theme: any;
  features: any;
}

type IBooster =
  | {
      id: string;
      icon: string;
      title: string;
      description: string;
      action: any;
      template_id: 'moment';
    }
  | {
      id: string;
      icon: string;
      title: string;
      description: string;
      action: any;
      template_id: 'links';
      links?: Array<{
        text: string;
        url: string;
      }>;
    };

export enum WidgetStateView {
  Queue = 'queue',
  Chat = 'Chat',
  PreChat = 'prechat',
  PostChat = 'postchat',
  Offline = 'offline',
  Timeline = 'timeline',
}

export interface WidgetStateLocalizationInterface {
  welcome_title?: string;
  embedded_chat_now?: string;
  embedded_leave_message?: string;
  embedded_textarea_placeholder?: string;
  agents_not_available?: string;
  welcome_to_chat?: string;
  client?: string;
  offline_message_sent?: string;
}

interface WidgetConfigInterface extends IWidgetSnapshotConfigProps {
  features?: WidgetFeaturesInterface;
  theme?: WidgetThemeInterface;
}

export interface WidgetFeaturesInterface {
  agentAvatar?: {
    enabled: boolean;
  };
  rating?: {
    enabled: boolean;
  };
  emailTranscript?: {
    enabled: boolean;
  };
  logo?: {
    enabled: boolean;
    path: string;
  };
  disableSounds?: {
    enabled: boolean;
  };
  hideTrademark?: {
    enabled: boolean;
  };
  ticketForm?: {
    enabled: boolean;
  };
  boosters?: {
    enabled: boolean;
    items: IBooster[];
  };
  homeScreen?: {
    enabled: boolean;
  };
  helpdeskFormConfiguration?: IFormConfiguration;
}

export enum Skin {
  Modern = 'modern',
  Smooth = 'smooth',
}

export enum ThemeVariant {
  Light = 'light',
  Dark = 'dark',
}

export interface WidgetThemeInterface {
  name: Skin;
  variant: ThemeVariant;
  agentbarBackgroundColor: string;
  agentbarText: string;
  agentMessageColorBackground: string;
  agentMessageColorText: string;
  backgroundColor: string;
  minimizedColorBackground: string;
  minimizedColorIcon: string;
  minimizedColorText: string;
  visitorMessageColorText: string;
  visitorMessageColorBackground: string;
  titlebarBackgroundColor: string;
  titlebarText: string;
  systemMessageColor: string;
  ctaColor: string;
  id?: string;
  label?: string;
}

export enum WidgetMinimizedTypeEnum {
  Circle = 'circle',
  Bar = 'bar',
}

export enum WidgetPositionTypeEnum {
  Left = 'left',
  Right = 'right',
}
