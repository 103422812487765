import { type TextEnahncementsPayloadWithMethod } from 'hooks/text-enhancements/use-text-enhancement';
import { TextEnhancementAction } from 'interfaces/text-enhancements';
import { MLGatewayTextEnhancement } from 'services/connectivity/ml-gateway-api/text-enhancements/types';

export const prepareTextEnhancementPayload = (
  action: TextEnhancementAction,
  value: string,
): TextEnahncementsPayloadWithMethod | null => {
  const config = {
    [TextEnhancementAction.SentimentMoreCasual]: {
      sentiment: 'casual',
      input: value,
      method: MLGatewayTextEnhancement.TextSentiment,
    },
    [TextEnhancementAction.SentimentMoreFormal]: {
      sentiment: 'professional',
      input: value,
      method: MLGatewayTextEnhancement.TextSentiment,
    },
    [TextEnhancementAction.SentimentMoreFriendly]: {
      sentiment: 'friendly',
      input: value,
      method: MLGatewayTextEnhancement.TextSentiment,
    },
    [TextEnhancementAction.GrammarCorrection]: {
      input: value,
      method: MLGatewayTextEnhancement.TextGrammar,
    },
    [TextEnhancementAction.ExpandMessage]: {
      action: 'expand',
      input: value,
      method: MLGatewayTextEnhancement.TextReword,
    },
    [TextEnhancementAction.RephraseText]: {
      action: 'rephrase',
      input: value,
      method: MLGatewayTextEnhancement.TextReword,
    },
    [TextEnhancementAction.RephraseTextLikeImFive]: {
      action: 'simplify',
      input: value,
      method: MLGatewayTextEnhancement.TextReword,
    },
    [TextEnhancementAction.SummarizeText]: {
      action: 'summarize',
      input: value,
      method: MLGatewayTextEnhancement.TextReword,
    },
  };

  return config[action] || null;
};
