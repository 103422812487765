// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { ChatEventStatus } from 'constants/chat-event-status';
import { ChatEventSubType, ChatEventType } from 'constants/chat-event-type';
import { CustomFormType } from 'constants/custom-form-type';
import { UserType } from 'constants/user-type';
import { type KeyMap } from 'helpers/interface';
import type { ThreadEventResult } from 'interfaces/api/event/thread';
import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import {
  isClosedThread,
  isEventWithStatus,
  isQueuedChat,
  isSystemMessage,
  isThreadWithStatus,
} from 'store/entities/chats/helpers/common';
import { type ChatEventAuthor, type IMessage } from 'store/entities/chats/interfaces';
import { getChatUsers, getThread, getThreadEvent, messageExists } from 'store/entities/chats/selectors';
import { getChatLastEvent } from 'store/views/chats/selectors';

import { type IncomingEventPushEvent } from '../interfaces';
import {
  deserializeAttachmentMessage,
  deserializeChatMessage,
  deserializeInactivityMessage,
  deserializeRichMessage,
  deserializeSurvey,
  deserializeSystemMessage,
} from '../serialization/deserialize-event';
import { deserializeEventUserType } from '../serialization/deserialize-event-user-type';
import { getCustomFormType } from '../serialization/helpers/custom-form';

const messageDeserializers: KeyMap<
  (event: ThreadEventResult, authorType: ChatEventAuthor, status: ChatEventStatus, wasSeen: boolean) => IMessage
> = {
  message: deserializeChatMessage,
  rich_message: deserializeRichMessage,
};

function deserializeChatEventAuthor(payload: IncomingEventPushEvent, store: IAppState): ChatEventAuthor {
  const { event, chat_id: chatId } = payload;

  const state = store.getState();

  const chatsUsers = getChatUsers(state, chatId);
  const authorUser = chatsUsers.find((u) => u.id === event.author_id);

  return deserializeEventUserType(event, authorUser) as ChatEventAuthor;
}

function dispatchNewMessage(payload: IncomingEventPushEvent, store: IAppState): void {
  const wasSeen = false;
  const deserializer = messageDeserializers[payload.event.type] || messageDeserializers.message;

  const authorType = deserializeChatEventAuthor(payload, store);

  store.dispatch(
    ChatsEntitiesActions.newMessage({
      threadId: payload.thread_id,
      message: deserializer(payload.event, authorType, ChatEventStatus.Delivered, wasSeen),
    })
  );
}

function dispatchUpdateMessage(payload: IncomingEventPushEvent, store: IAppState): void {
  const { thread_id: threadId, event } = payload;

  if (!event) {
    return;
  }

  const state = store.getState();
  const authorType = deserializeChatEventAuthor(payload, store);
  const deserializer = messageDeserializers[event.type] || messageDeserializers.message;
  const currentEvent = getThreadEvent(state, threadId, event.id);
  const currentEventStatus =
    currentEvent && isEventWithStatus(currentEvent) && currentEvent.status === ChatEventStatus.Read
      ? ChatEventStatus.Read
      : ChatEventStatus.Delivered;
  const currentWasSeen = currentEvent && isEventWithStatus(currentEvent) ? currentEvent.wasSeen : true;
  store.dispatch(
    ChatsEntitiesActions.updateMessage({
      threadId: payload.thread_id,
      messageId: payload.event.custom_id,
      message: deserializer(payload.event, authorType, currentEventStatus, currentWasSeen),
    })
  );
}

function handleNewMessage(payload: IncomingEventPushEvent, store: IAppState): void {
  const state = store.getState();
  const isMessageUpdate = messageExists(state, payload.thread_id, payload.event.custom_id);
  const thread = getThread(state, payload.thread_id);
  const isActiveChat = isThreadWithStatus(thread) && !isClosedThread(thread);
  const isQueuedChatThread = isQueuedChat(thread);
  const isContinuousQueuedChat = thread && thread.customProperties && thread.customProperties.isContinuous;
  const shouldDispatchNewMessage = thread && (isActiveChat || isContinuousQueuedChat || isQueuedChatThread);

  if (shouldDispatchNewMessage) {
    if (isMessageUpdate) {
      dispatchUpdateMessage(payload, store);
    } else {
      dispatchNewMessage(payload, store);
    }
  }
}

function handleNewSystemMessage(payload: IncomingEventPushEvent, store: IAppState): void {
  const { thread_id: threadId, event } = payload;

  const state = store.getState();
  const thread = getThread(state, threadId);

  if (!thread) {
    return;
  }

  const lastThreadSystemMessageEvent = getChatLastEvent(state, threadId, [ChatEventType.Event]);
  const isLastEventAInactivityTransfer =
    lastThreadSystemMessageEvent &&
    isSystemMessage(lastThreadSystemMessageEvent) &&
    lastThreadSystemMessageEvent.subType === ChatEventSubType.TransferredDueToInactivity;
  const isClosed = isClosedThread(thread);
  const isContinuousQueuedChat = thread.customProperties && thread.customProperties.isContinuous;

  /**
   * Skip system message in cases
   * 1. Chat is transferred with closed status, eg: inactivity transfer
   */
  const shouldDispatchNewSystemMessage =
    (!isLastEventAInactivityTransfer && isClosed) || !isClosed || isContinuousQueuedChat;

  if (shouldDispatchNewSystemMessage) {
    store.dispatch(
      ChatsEntitiesActions.newSystemMessage({
        threadId: payload.thread_id,
        systemMessage: deserializeSystemMessage(event),
      })
    );
  }
}

function handleNewSurvey(payload: IncomingEventPushEvent, store: IAppState): void {
  const survey = deserializeSurvey(payload.event, UserType.Customer);

  if (survey) {
    store.dispatch(
      ChatsEntitiesActions.newSurvey({
        threadId: payload.thread_id,
        survey,
      })
    );
  }
}

function handleNewForm(payload: IncomingEventPushEvent, store: IAppState): void {
  const customFormType = getCustomFormType(payload.event);

  switch (customFormType) {
    case CustomFormType.InactivityMessage: {
      store.dispatch(
        ChatsEntitiesActions.newMessage({
          threadId: payload.thread_id,
          message: deserializeInactivityMessage(payload.event, payload.thread_id, false),
        })
      );
    }
  }
}

function handleNewAttachmentMessage(payload: IncomingEventPushEvent, store: IAppState): void {
  const authorType = deserializeChatEventAuthor(payload, store);
  const wasSeen = false;

  store.dispatch(
    ChatsEntitiesActions.newAttachmentMessage({
      threadId: payload.thread_id,
      file: deserializeAttachmentMessage(payload.event, authorType, ChatEventStatus.Delivered, wasSeen),
    })
  );
}

/**
 * Handles incoming event push event.
 * 1. Text message.
 * 2. Rich message.
 * 3. System message.
 * 4. Survey event.
 * 5. File event.
 * @param {IncomingEventPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleIncomingEvent(payload: IncomingEventPushEvent, store: IAppState): void {
  const dispatchers: KeyMap<(payload: IncomingEventPushEvent, store: IAppState) => void> = {
    message: handleNewMessage,
    rich_message: handleNewMessage,
    system_message: handleNewSystemMessage,
    filled_form: handleNewSurvey,
    form: handleNewForm,
    file: handleNewAttachmentMessage,
  };

  const dispatcher = dispatchers[payload.event.type];
  if (dispatcher) {
    dispatcher(payload, store);
  }
}
