import { KnowledgeSourceItemStatus, type KnowledgeSourceItem } from 'interfaces/knowledge-sources';

const extractKnowledgeSources = (size: number, data: KnowledgeSourceItem[]): KnowledgeSourceItem[] => {
  return data.filter((item) => item.status !== KnowledgeSourceItemStatus.FAILED).slice(0, size);
};

const getRemainingSources = (size: number, data: KnowledgeSourceItem[]): number | null => {
  const filteredData = data.filter((item) => item.status === KnowledgeSourceItemStatus.FAILED);
  const result = filteredData.length - size;

  return result > 0 ? result : null;
};

export const getKnowledgeSourcesData = (
  data: KnowledgeSourceItem[] | undefined,
  extractedSize: number,
): { extractedSources: KnowledgeSourceItem[]; remainingSourcesCount: number | null } => {
  if (!data) {
    return {
      extractedSources: [],
      remainingSourcesCount: null,
    };
  }
  const extractedSources = extractKnowledgeSources(extractedSize, data);
  const remainingSourcesCount = getRemainingSources(extractedSize, data);

  return {
    extractedSources,
    remainingSourcesCount,
  };
};
