/**
 * Definition of z-index values to be used across application.
 * Final values are yet to be defined.
 */
export enum StackingContextLevel {
  Popover = 500,
  Tooltip = 1000,
  Dropdown = 2000,
  SaveBar = 2500,
  ModalOverlay = 3000,
  ModalTooltip = 5000,
  ModalDropdown = 6000,
  ContentOverlay = 7000,
  ContentOverlayTooltip = 8000,
  ContentOverlayDropdown = 9000,
  FullOverlay = 10000,
  NavigationTooltip = 11000,
  GlobalToast = 15000,
  Modal = 20000,
  ImagePreviewOverlay = 21000,
  FixedSaveBar = 999999 /* this value is required to be that high in order to hide the scrollbar behind this element */,
}
