import { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import type { Suggestion } from 'interfaces/copilot';
import { useCopilotMessageContext } from 'routes/copilot/CopilotMessageContext';

import { EMPTY_ARRAY } from '../../constants';
import { CopilotPickerOption } from '../../picker/copilot-picker-option';

interface IProps {
  suggestions: Required<Suggestion>[];
  queryString: string | null;
}

type UseDynamicPickerOptions = {
  options: CopilotPickerOption[];
};

export const useDynamicPickerOptions = ({ suggestions, queryString }: IProps): UseDynamicPickerOptions => {
  const dispatch = useDispatch();
  const { handleSendMessage } = useCopilotMessageContext();

  const options = useMemo(() => {
    if (queryString === null) {
      return EMPTY_ARRAY as CopilotPickerOption[];
    }

    return suggestions
      .filter(({ shortcut }) => shortcut.includes(queryString))
      .sort((a, b) => a.shortcut.localeCompare(b.shortcut))
      .map(
        ({ shortcut, message }) =>
          new CopilotPickerOption(shortcut, {
            description: message,
            onSelect: () => {
              handleSendMessage(message);
            },
          }),
      );
  }, [suggestions, queryString, dispatch, handleSendMessage]);

  return {
    options,
  };
};
