// @ts-strict-ignore
import { ReportType } from 'constants/reports/report-type';
import { ApiManager } from 'services/api/api-manager';
import { serializeReportDistribution } from 'services/api/report/v3/filters';
import { type IActionWithPayload } from 'store/helper';

import { staticReportFilters } from '../constants/static-filters';
import {
  type IFetchReportPayload,
  ReportRequestName,
  type IReportRequest,
  RequestVersion,
  type IFetchReportOptions,
} from '../interfaces';

import { makeFetchingSaga } from './helpers';

export function* fetchMissedChats(action: IActionWithPayload<string, IFetchReportPayload>) {
  const requests: IReportRequest[] = [
    {
      version: RequestVersion.V3,
      name: ReportRequestName.MissedChats,
      interface: ApiManager.reportApiV3.fetchTotalChats,
      extendedFilters: staticReportFilters[ReportType.MissedChats],
    },
  ];

  const options: IFetchReportOptions = {
    apiFiltersSerializer: serializeReportDistribution,
  };

  yield makeFetchingSaga(ReportType.MissedChats, action, requests, options);
}
