import { BaseApi } from '../base-api';

interface ITranscriptBody {
  to: string;
  visitor: 0 | 1;
}

export class TranscriptApi extends BaseApi {
  protected readonly prefix = 'chats';

  private sendTranscript = (chatId: string, body: ITranscriptBody) => this.post(`${chatId}/send_transcript`, body);

  send = (chatId: string, email: string) => this.sendTranscript(chatId, { to: email, visitor: 1 });

  sendWithDetails = (chatId: string, email: string) => this.sendTranscript(chatId, { to: email, visitor: 0 });

  download = (chatId: string) => this.get(`${chatId}/transcript_details`);
}
