import { getConfig } from 'helpers/config';

import connectivityStatus from './service';
import { type ConnectivityStatusInstance } from './types';

const baseURL: string | undefined = getConfig().api;

if (!baseURL) {
  // eslint-disable-next-line no-console
  console.warn('API base URL is not defined or is malformed in the configuration. Falling back to empty service URLs.');
}

type ServiceUrls = {
  agentApi: string;
  configurationApi: string;
  restApi: string;
};

const SERVICE_URLS: ServiceUrls | null = baseURL
  ? {
      agentApi: `${baseURL}/agent/status`,
      configurationApi: `${baseURL}/configuration/status`,
      restApi: `${baseURL}/service/alive`,
    }
  : null;

const serviceUrlsArray: string[] = SERVICE_URLS
  ? [SERVICE_URLS.agentApi, SERVICE_URLS.configurationApi, SERVICE_URLS.restApi]
  : [];

export const connectivityStatusClient: ConnectivityStatusInstance = connectivityStatus.create({
  servicesURLs: serviceUrlsArray,
});
