import { useState } from 'react';

import { getItem, type SessionStorageItem, saveItem, removeItem } from 'services/session-storage';

export function useSessionStorage<T extends SessionStorageItem>(
  key: string,
  initialValue: T
): [T, (value: T | ((prevVal: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>((): T => {
    if (!key) {
      return initialValue;
    }

    const item = getItem(key);

    return (item || initialValue) as T;
  });
  const setValue = (value: T | ((prevVal: T) => T)): void => {
    if (!key) return;

    setStoredValue(value);
    const valueToSave: SessionStorageItem = typeof value === 'function' ? value(storedValue) : value;
    if (valueToSave === null) {
      removeItem(key);
    } else {
      saveItem(key, valueToSave);
    }
  };

  return [storedValue, setValue];
}
