import { HTTPError } from 'ky';

import { type KyHTTPError } from './types';

/**
 * Type guard function to check if a given error is an instance of `HTTPError`.
 * @param error - The error object to check.
 * @returns A boolean indicating whether the error is an instance of `HTTPError`.
 */
export function isHTTPError(error: unknown): error is HTTPError {
  return error instanceof HTTPError;
}

/**
 * Type guard function to check if a given error is an instance of `KyHTTPError`.
 *
 * @param error - The error object to check.
 * @returns A boolean indicating whether the error is an instance of `KyHTTPError`.
 * If the function returns `true`, TypeScript will narrow the type of the `error` parameter to `KyHTTPError` in the scope where the function is called.
 */
export function isKyHTTPError(error: unknown): error is KyHTTPError {
  return error instanceof HTTPError && 'response' in error;
}

/**
 * Type guard function to check if a given error is an instance of `Error`.
 *
 * @param error - The error object to check.
 * @returns A boolean indicating whether the error is an instance of `Error`.
 * If the function returns `true`, TypeScript will narrow the type of the `error` parameter to `Error` in the scope where the function is called.
 */
export function isError(error: unknown): error is Error {
  return error instanceof Error;
}

/**
 * Checks if the provided `body` parameter is an instance of `XMLHttpRequestBodyInit`.
 *
 * This function is a type guard that narrows the `BodyInit` type to `XMLHttpRequestBodyInit`
 * by checking if `body` is one of the types that `XMLHttpRequestBodyInit` can represent.
 *
 * @param body - The body to check. It can be any type that `BodyInit` represents.
 * @returns A boolean value indicating whether `body` is an instance of `XMLHttpRequestBodyInit`.
 */
export function isXMLHttpRequestBodyInit(body: BodyInit): body is XMLHttpRequestBodyInit {
  return (
    body instanceof Blob ||
    body instanceof ArrayBuffer ||
    ArrayBuffer.isView(body) ||
    body instanceof FormData ||
    body instanceof URLSearchParams ||
    typeof body === 'string'
  );
}
