import { type AgentChatApiResponse } from 'services/connectivity/agent-chat-api/types';

export function getTagFailures(responses: AgentChatApiResponse<void>[], changedTags: string[]): string[] {
  return responses.reduce<string[]>((acc, response, index) => {
    if (response.error) {
      acc.push(changedTags[index]);
    }

    return acc;
  }, []);
}
