import type { SagaIterator } from 'redux-saga';
import { call, cancel, delay, put, select, take, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { LoginStatus } from 'constants/login-status';
import { TrackingEvent } from 'constants/tracking-events';
import { EventPlace } from 'helpers/analytics';
import { notificationsEnabled } from 'helpers/notifications';
import { trackEvent } from 'services/event-tracking';
import { RequestAction } from 'store/entities/actions';
import { AgentActionNames } from 'store/entities/agents/actions';
import type { IAgent, IAgentUpdatedPayload } from 'store/entities/agents/interfaces';
import { getLoggedInAgent, getLoggedInAgentLogin, getLoggedInAgentStatus } from 'store/entities/agents/selectors';
import { AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import { hasFetchedAgentCustomProperties } from 'store/features/agent-custom-properties/selectors';
import { showNotification } from 'store/features/browser-notifications/helpers';
import type { INotification } from 'store/features/browser-notifications/interfaces';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { isGhostLogin } from 'store/features/session/selectors';
import type { IActionWithPayload } from 'store/helper';

import { shouldShowStatusReminder } from './selectors';

/**
 * Status reminder
 *
 * A lot of agents login, but have "not accepting chats" status. Probably they forgot about it, we want to remind them to start accepting.
 * 10 minutes after login we want to show browser notification that remind if agent don't accept the chats.
 *
 * 10 minutes after login (with not accepting chats status) shows notification + amplitude event
 * If during this time user change status the notification will no show
 * Click on notification will back to application + amplitude event
 * Click on notification button "start accepting chats" back to site and change agent status for accepting chats + amplitude event
 *
 * For Starters/Teams we are showing this for everyone. Don’t show again is shown ONLY after 3 dismisses or “X” clicks.
 * On “start accepting chats button” click inside modal we are “zeroing” the counter.
 * For Business/Enterprise we don’t show this at all
 *
 * Browser notification is shown, when agent is not FOCUSED on application (on tab)
 */

const REMINDER_TIME = 10 * 60 * 1000; // 10 minutes

let hasAgentBeenOnline = false;

function* onAgentStatusChange(action: IActionWithPayload<string, IAgentUpdatedPayload>): SagaIterator {
  const {
    payload: { status = null, login: actionAgentLogin = null },
  } = action;

  const currentAgentLogin = yield select(getLoggedInAgentLogin);

  // If status has any time change for online
  if (actionAgentLogin === currentAgentLogin && status === LoginStatus.Online) {
    hasAgentBeenOnline = true;
  }
}

function* hideStatusReminder(action: IActionWithPayload<string, IAgentUpdatedPayload>): SagaIterator {
  const {
    payload: { status = null, login: actionAgentLogin = null },
  } = action;

  const currentAgentLogin = yield select(getLoggedInAgentLogin);

  // If status has any time change for online
  if (actionAgentLogin === currentAgentLogin && status === LoginStatus.Online) {
    yield put(GlobalModalActions.hideModal(GlobalModal.StatusReminder));
  }
}

function* showStatusReminder(): SagaIterator {
  const currentAgent: IAgent = yield select(getLoggedInAgent);
  const isGhost = yield select(isGhostLogin);

  if (currentAgent.status === LoginStatus.Online || isGhost) {
    return;
  }

  const statusChangeTask = yield takeEvery(AgentActionNames.AGENT_UPDATE, onAgentStatusChange);

  yield delay(REMINDER_TIME);
  yield cancel(statusChangeTask);

  const wereAgentCustomPropertiesFetched = yield select(hasFetchedAgentCustomProperties);

  if (!wereAgentCustomPropertiesFetched) {
    yield take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]);
  }

  const currentAgentStatus = yield select(getLoggedInAgentStatus);

  const shouldShow = yield select(shouldShowStatusReminder);

  if (shouldShow && !hasAgentBeenOnline && currentAgentStatus !== LoginStatus.Online) {
    const isWindowFocused = document.visibilityState === 'visible';

    // show browser notification if app is not visible
    if (!isWindowFocused && notificationsEnabled()) {
      const notificationOption: INotification = {
        id: 'status-reminder',
        title: 'Ready to accept chats?',
        text: 'It looks like you’re active but not accepting chats.',
        tag: 'status-reminder',
        afterClick: {
          trackEvent: TrackingEvent.NotificationClicked,
        },
      };
      yield call(showNotification, notificationOption);
      trackEvent(TrackingEvent.NotificationDisplayed, EventPlace.StatusReminder);
    }

    yield put(GlobalModalActions.showModal(GlobalModal.StatusReminder));
    yield takeEvery(AgentActionNames.AGENT_UPDATE, hideStatusReminder);
  }
}

export function* statusReminderSagas(): SagaIterator {
  yield takeEvery('APP_READY', showStatusReminder);
}
