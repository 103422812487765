// @ts-strict-ignore
import { format } from 'date-fns';
import isEmpty from 'lodash.isempty';

import { DateFormat } from 'constants/date';
import {
  FacebookMessengerProperty,
  FACEBOOK_MESSENGER_PROPERTY_LABELS,
  type IFacebookMessengerIntegration,
} from 'constants/integrations/facebook-messenger';
import { Intent } from 'constants/intent';
import { DEFAULT_VISITOR_NAME } from 'constants/localization';
import { ChatChannel, getClientIdByChatChannel } from 'helpers/chat-channels';
import { getConfig } from 'helpers/config';
import { prettyDateDifference, tryFormatToTimeZone } from 'helpers/date';
import { capitalizeFirstLetter } from 'helpers/string';
import { type CustomVariable } from 'interfaces/customer-details';

import { CustomerCustomVariableName, type ICustomer, type IVisitedPage } from '../interfaces';

function getFullNameFromFacebookData(properties: IFacebookMessengerIntegration): string {
  if (properties[FacebookMessengerProperty.Name]) {
    return properties[FacebookMessengerProperty.Name];
  }

  if (!properties[FacebookMessengerProperty.FirstName] && !properties[FacebookMessengerProperty.LastName]) {
    return null;
  }

  return [properties[FacebookMessengerProperty.FirstName], properties[FacebookMessengerProperty.LastName]]
    .filter(Boolean)
    .join(' ');
}

export function getCustomerProvidedName(customer: ICustomer): string {
  if (!customer) {
    return null;
  }

  const facebookIntegration =
    customer.integrations && customer.integrations[getClientIdByChatChannel(ChatChannel.FacebookMessenger)];
  const facebookName = facebookIntegration && getFullNameFromFacebookData(facebookIntegration);

  return customer.name || facebookName;
}

export function getCustomerReadableName(customer: ICustomer | null): string {
  return getCustomerProvidedName(customer) || DEFAULT_VISITOR_NAME;
}

export function getCustomerNameForList(customer: ICustomer | null): string {
  return getCustomerProvidedName(customer) || customer?.host || customer?.ip || DEFAULT_VISITOR_NAME;
}

export function isCustomerReturning(customer: ICustomer): boolean {
  return customer.statistics?.visitsCount > 1;
}

/**
 * Returns custom variables based on Facebook Messenger properties.
 * @param properties Facebook Messenger thread properties.
 */
export function getFacebookMessengerVariables(properties: IFacebookMessengerIntegration): CustomVariable[] {
  if (!properties || isEmpty(properties)) {
    return null;
  }

  const variablesToExclude = [
    FacebookMessengerProperty.FirstName, // full name is handled later on
    FacebookMessengerProperty.LastName,
    FacebookMessengerProperty.Name,
    FacebookMessengerProperty.ProfilePicture, // avatar and timezone are handled in different boxes
    FacebookMessengerProperty.Timezone,
  ];

  const variables = Object.keys(properties).reduce((acc: CustomVariable[], key): CustomVariable[] => {
    const value = properties[key];

    if (
      !value || // filter out empty values
      variablesToExclude.includes(key as FacebookMessengerProperty)
    ) {
      return acc;
    }

    acc.push({
      id: key,
      name: FACEBOOK_MESSENGER_PROPERTY_LABELS[key],
      value,
    });

    return acc;
  }, []);

  const fullName = getFullNameFromFacebookData(properties);

  if (fullName) {
    variables.unshift({
      id: FacebookMessengerProperty.Name,
      name: FACEBOOK_MESSENGER_PROPERTY_LABELS[FacebookMessengerProperty.Name],
      value: fullName,
    });
  }

  return variables;
}

export function getLocalTimeFromFacebookTimezone(facebookIntegration: IFacebookMessengerIntegration): string {
  if (Number.isNaN(+facebookIntegration?.timezone)) {
    return null;
  }

  const now = new Date();
  const timezone = +facebookIntegration.timezone;
  const timezoneDiff = timezone * 60 + now.getTimezoneOffset();
  const offsetTime = new Date(now.getTime() + timezoneDiff * 60 * 1000);

  return `${format(offsetTime, DateFormat.Time12HourWithoutSeconds)} local time`;
}

/**
 * Gets the local time of a customer.
 *
 * @param {ICustomer} customer - The customer object.
 * @returns {string} - The local time of the customer.
 */
export function getCustomerLocalTime(customer: ICustomer): string {
  const geolocation = customer?.geolocation;
  const facebookIntegration = customer?.integrations?.[getClientIdByChatChannel(ChatChannel.FacebookMessenger)];

  const localTimeFromFacebook = getLocalTimeFromFacebookTimezone(facebookIntegration);
  const localTimeFromGeolocation = geolocation?.timezone ? formatLocalTime(geolocation?.timezone) : null;

  return localTimeFromGeolocation || localTimeFromFacebook;
}

/**
 * Formats the local time.
 *
 * @param {string} timezone - The timezone to format the local time.
 * @returns {string} - The formatted local time.
 */
function formatLocalTime(timezone: string): string {
  const formattedTimeZone = tryFormatToTimeZone(new Date(), DateFormat.Time12HourWithoutSeconds, {
    timeZone: timezone,
  });

  return formattedTimeZone ? `${formattedTimeZone} local time` : null;
}

export function getCustomerLocation(customer: ICustomer): string {
  const { geolocation } = customer || {};

  const city = geolocation?.city && capitalizeFirstLetter(geolocation.city);
  const state = geolocation?.state && capitalizeFirstLetter(geolocation.state);
  const country = geolocation?.country && capitalizeFirstLetter(geolocation.country);

  return [city, state, country].filter(Boolean).join(', ');
}

export function getCustomerLocationMapUrl(customer: ICustomer | null): string {
  const { geolocation } = customer || {};

  return (
    geolocation?.latitude &&
    geolocation?.longitude &&
    `https://www.google.com/maps/embed/v1/place?key=${getConfig().googleMapsApiKey}&q=${geolocation.latitude},${
      geolocation.longitude
    }&zoom=12`
  );
}

export function getVisitedPageVisitTime(
  currentPage: IVisitedPage,
  lastActivityTimestamp: number,
  previousPage?: IVisitedPage
): string {
  if (previousPage) {
    return prettyDateDifference(new Date(currentPage.timestampInMS), new Date(previousPage.timestampInMS));
  }

  return prettyDateDifference(new Date(lastActivityTimestamp), new Date(currentPage.timestampInMS));
}

export function resolveCustomerIntent(customer: ICustomer): Intent | null {
  const intentValue = customer?.customVariables?.[CustomerCustomVariableName.Intent];

  if (intentValue && Object.values(Intent).includes(intentValue as Intent)) {
    return intentValue as Intent;
  }

  return null;
}
