export enum FileFormat {
  Csv = 'csv',
  Tsv = 'tsv',
  TsvExcel = 'tsv-excel',
}

export const fileFormatLabels = {
  [FileFormat.Csv]: 'CSV',
  [FileFormat.Tsv]: 'TSV',
  [FileFormat.TsvExcel]: 'TSV (excel)',
};
