// @ts-strict-ignore
import { type IIntegrationCustomerDetails, replaceIntegrationUrlMarkers } from 'helpers/integration';

import { type IActionPayload } from './interfaces';

/**
 * Change link target. This allows us to open clicked link in a new tab
 */
export function openLinkInNewTab(url: string, event?: MouseEvent) {
  if (event && event.currentTarget) {
    const currentTarget = event.currentTarget as any;

    if (currentTarget) {
      currentTarget.href = url;
      currentTarget.target = '_blank';
    }
  }
}

/**
 * Handle URL expansions (`{!Visitor_ConferenceID}` etc.) for old integrations from the CRM.
 * This can be removed once all integrations are migrated to DPS.
 */
export function replaceLegacyUrlMarkers(url: string, actionPayload: IActionPayload): string {
  const integrationCustomerDetails: IIntegrationCustomerDetails = {
    city: actionPayload.customer_city,
    country: actionPayload.customer_country,
    email: actionPayload.customer_email,
    id: actionPayload.customer_id,
    ip: actionPayload.customer_ip,
    name: actionPayload.customer_name,
    region: actionPayload.customer_region,
    timezone: actionPayload.customer_timezone,
  };

  return replaceIntegrationUrlMarkers(
    url,
    actionPayload.thread_id,
    Number(actionPayload.licence_id),
    actionPayload.agent_id,
    integrationCustomerDetails
  );
}
