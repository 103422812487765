import { ITourStep, TourStepType } from '../interfaces';

import { DescriptionStep, FiltersStep } from './tour-steps';

export enum TagsUsageTourStep {
  Description = 'Description',
  Filters = 'Filters',
}

export const tagsUsageTourSteps: ITourStep[] = [
  {
    id: TagsUsageTourStep.Description,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <DescriptionStep />,
    },
  },
  {
    id: TagsUsageTourStep.Filters,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <FiltersStep />,
    },
  },
];
