import { type FC } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SideNavigationItemLabelWithBadge } from 'components/side-navigation-v2/components/SideNavigationItemLabelWithBadge';
import { SideNavigationItemWithRightNode } from 'components/side-navigation-v2/components/SideNavigationItemWithRightNode';
import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { navigate } from 'helpers/routing';
import { useEffectOnce } from 'hooks/use-effect-once';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';
import { SettingsViewActions } from 'store/views/settings/actions';

interface IProps {
  item: ISideNavigationItem;
  isMainEntry?: boolean;
  isActive?: boolean;
}

export const SettingsSideNavigationItem: FC<IProps> = ({ item, isMainEntry, isActive }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const CustomComponent = item.customLabelComponent;

  useEffectOnce(() => {
    if (isActive) {
      dispatch(SettingsViewActions.setPageTitle(item.name));
    }
  });

  const handleNavigationClick = (): void => {
    if (item.path && pathname !== item.path) {
      dispatch(SettingsViewActions.setPageTitle(item.name));
      navigate(item.path);
    }
  };

  return (
    <SideNavigationItem
      key={item.id}
      label={
        CustomComponent ? (
          <CustomComponent isActive={isActive} isMainEntry={isMainEntry} item={item} />
        ) : (
          <SideNavigationItemLabelWithBadge isActive={isActive} isMainEntry={isMainEntry} item={item} />
        )
      }
      url={item.path}
      isMainEntry={isMainEntry}
      onClick={handleNavigationClick}
      isActive={isActive}
      rightNode={<SideNavigationItemWithRightNode item={item} />}
      onItemHover={item.onItemHover}
      tourStep={item.tourStep}
    />
  );
};
