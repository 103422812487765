import { LC3IncomingEvent } from 'constants/lc3-incoming-event';
import { JSONIncomingEvent } from 'constants/socket';
import { AppStateProvider, type IAppState } from 'services/app-state-provider';
import { triggerLastActivity } from 'services/socket-activity-logger';
import { handleJSONIncomingEvent as handleLC2JSONIncomingEvent } from 'services/socket-lc2/socket-event-handler';

import { handleAgentApproved } from './agent/event-handling/agent-approved';
import { handleAgentCreated } from './agent/event-handling/agent-created';
import { handleAgentDeleted } from './agent/event-handling/agent-deleted';
import { handleAgentSuspended } from './agent/event-handling/agent-suspended';
import { handleAgentUnsuspended } from './agent/event-handling/agent-unsuspended';
import { handleAgentUpdated } from './agent/event-handling/agent-updated';
import { handleBotCreated } from './agent/event-handling/bot-created';
import { handleBotDeleted } from './agent/event-handling/bot-deleted';
import { handleBotUpdated } from './agent/event-handling/bot-updated';
import { handleRoutingStatusSet } from './agent/event-handling/routing-status-set';
import { handleChatAccessUpdated } from './chat/event-handling/chat-access-updated';
import { handleChatDeactivated } from './chat/event-handling/chat-deactivated';
import { handleChatTransferred } from './chat/event-handling/chat-transferred';
import { handleEventPropertiesDeleted } from './chat/event-handling/event-properties-deleted';
import { handleEventPropertiesUpdated } from './chat/event-handling/event-properties-updated';
import { handleEventsMarkedAsSeen } from './chat/event-handling/events-marked-as-seen';
import { handleIncomingChat } from './chat/event-handling/incoming-chat';
import { handleIncomingEvent } from './chat/event-handling/incoming-event';
import { handleIncomingMulticast } from './chat/event-handling/incoming-multicast';
import { handleIncomingSneakPeek } from './chat/event-handling/incoming-sneak-peek';
import { handleQueuePositionsUpdated } from './chat/event-handling/queue-positions-updated';
import { handleThreadPropertiesDeleted } from './chat/event-handling/thread-properties-deleted';
import { handleThreadPropertiesUpdated } from './chat/event-handling/thread-properties-updated';
import { handleThreadTagged } from './chat/event-handling/thread-tagged';
import { handleThreadUntagged } from './chat/event-handling/thread-untagged';
import { handleUserAddedToChat } from './chat/event-handling/user-added-to-chat';
import { handleUserRemovedFromChat } from './chat/event-handling/user-removed-from-chat';
import { handleCustomerLeft } from './customer/event-handling/customer-left';
import { handleCustomerPageUpdated } from './customer/event-handling/customer-page-updated';
import { handleCustomerUpdated } from './customer/event-handling/customer-updated';
import { handleIncomingCustomer } from './customer/event-handling/incoming-customer';
import { handleIncomingCustomers } from './customer/event-handling/incoming-customers';
import { handleTicketSummaryUpdate } from './ticket/event-handling/summary-update';

type IEventHandler = (payload: any, store: IAppState, version?: string) => void;

const pushEventHandlers: Record<string, IEventHandler> = {
  [LC3IncomingEvent.ChatDeactivated]: handleChatDeactivated,
  [LC3IncomingEvent.ChatTransferred]: handleChatTransferred,
  [LC3IncomingEvent.CustomerPageUpdated]: handleCustomerPageUpdated,
  [LC3IncomingEvent.CustomerUpdated]: handleCustomerUpdated,
  [LC3IncomingEvent.EventPropertiesUpdated]: handleEventPropertiesUpdated,
  [LC3IncomingEvent.EventPropertiesDeleted]: handleEventPropertiesDeleted,
  [LC3IncomingEvent.EventsMarkedAsSeen]: handleEventsMarkedAsSeen,
  [LC3IncomingEvent.IncomingChat]: handleIncomingChat,
  [LC3IncomingEvent.IncomingEvent]: handleIncomingEvent,
  [LC3IncomingEvent.IncomingMulticast]: handleIncomingMulticast,
  [LC3IncomingEvent.IncomingSneakPeek]: handleIncomingSneakPeek,
  [LC3IncomingEvent.QueuePositionsUpdated]: handleQueuePositionsUpdated,
  [LC3IncomingEvent.RoutingStatusSet]: handleRoutingStatusSet,
  [LC3IncomingEvent.ThreadPropertiesUpdated]: handleThreadPropertiesUpdated,
  [LC3IncomingEvent.ThreadPropertiesDeleted]: handleThreadPropertiesDeleted,
  [LC3IncomingEvent.ThreadTagged]: handleThreadTagged,
  [LC3IncomingEvent.ThreadUntagged]: handleThreadUntagged,
  [LC3IncomingEvent.UserAddedToChat]: handleUserAddedToChat,
  [LC3IncomingEvent.UserRemovedFromChat]: handleUserRemovedFromChat,
  [LC3IncomingEvent.AgentCreated]: handleAgentCreated,
  [LC3IncomingEvent.AgentUpdated]: handleAgentUpdated,
  [LC3IncomingEvent.AgentApproved]: handleAgentApproved,
  [LC3IncomingEvent.AgentSuspended]: handleAgentSuspended,
  [LC3IncomingEvent.AgentUnsuspended]: handleAgentUnsuspended,
  [LC3IncomingEvent.AgentDeleted]: handleAgentDeleted,
  [LC3IncomingEvent.BotCreated]: handleBotCreated,
  [LC3IncomingEvent.BotUpdated]: handleBotUpdated,
  [LC3IncomingEvent.IncomingCustomer]: handleIncomingCustomer,
  [LC3IncomingEvent.CustomerLeft]: handleCustomerLeft,
  [LC3IncomingEvent.ChatAccessUpdated]: handleChatAccessUpdated,
  [LC3IncomingEvent.BotDeleted]: handleBotDeleted,
  [LC3IncomingEvent.IncomingCustomers]: handleIncomingCustomers,
};

/**
 * This object should contain custom handlers in case of LC3.
 * Events to ignore should point to a no-operation handler.
 * Any event undefined here will fallback to their respective LC2 handlers.
 */
const jsonEventHandlers: Record<string, IEventHandler> = {
  [JSONIncomingEvent.TicketSummaryUpdate]: handleTicketSummaryUpdate,
};

export function handleLC3IncomingEvent(eventName: LC3IncomingEvent, payload: unknown, version: string): void {
  triggerLastActivity();

  const handler: IEventHandler = pushEventHandlers[eventName];

  if (handler) {
    handler(payload, AppStateProvider, version);
  }
}

export function handleJSONIncomingEvent(eventName: JSONIncomingEvent, payload: unknown): void {
  triggerLastActivity();
  const handler: IEventHandler = jsonEventHandlers[eventName];

  if (handler) {
    handler(payload, AppStateProvider);
  } else {
    handleLC2JSONIncomingEvent(eventName, payload);
  }
}
