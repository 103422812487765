import { type IncomingMessage } from 'interfaces/incoming-message';
import { getWebsocketParser } from 'services/server/get-websocket-parser';
import { ServerAccessPlatform } from 'services/server/server-access-platform';
import { setWebsocketConnection } from 'services/server/websocket-connection';

interface InitializeServerOptions {
  compressWebsocket: boolean;
}

export function initializeServer({ compressWebsocket }: InitializeServerOptions): void {
  const parser = getWebsocketParser();
  const server = new ServerAccessPlatform(compressWebsocket);
  server.on('message', (message: IncomingMessage) => parser.parseWithReconnect(message));
  setWebsocketConnection(server);
}
