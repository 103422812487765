import { PlanType } from './plan-type';

export const REPLY_SUGGESTIONS_ARTICLE_URL = 'https://www.livechat.com/help/reply-suggestions';

export enum ReplySuggestionEvent {
  CreateReply = 'Replies suggestion requested',
  ReplyReceived = 'Replies suggestion received',
  ReplyUsed = 'Replies suggestion pasted into message box',
  ReplyDismissed = 'Replies suggestion dismissed',
  RegenerateClicked = 'Replies regenerate button clicked',
  CreateReplyShortcutUsed = 'Replies suggestion requested with shortcut',
  ReplyLoaderDismissed = 'Replies loader dismissed',
  ReplySourceClicked = 'Replies source clicked',
  OnboardingLearnMoreClicked = 'Replies onboarding learn more clicked',
  OnboardingAddSourceClicked = 'Replies onboarding add source clicked',
  ErrorShown = 'Replies general error shown',
  NoDataErrorShown = 'Replies no replies error shown',
  NoSourcesErrorShown = 'Replies no sources error shown',
  NoKsAddSourceClicked = 'Replies no knowledge source add source clicked',
  NoKsLearnMoreClicked = 'Replies no knowledge source learn more clicked',
  DetailsAddSourceClicked = 'Details section add source button clicked',
  HideButtonClicked = 'Hide button switch clicked',
  ReplySuggestionOnboardingShown = 'Replies suggestion onboarding shown',
}

export enum ReplySuggestionAutomateEvent {
  LearnMoreClicked = 'Replies learn more clicked',
  UploadSourceCancelled = 'Replies uploading source cancelled',
  DeleteSource = 'Replies knowledge source deleted',
  KnowledgeSourceRenamed = 'Replies knowledge source renamed',
  AddKnowledgeSourceClicked = 'Replies add knowledge sources clicked',
  AddKnowledgeSourceSaveClicked = 'Replies add knowledge source save clicked',
  AddKnowledgeSourceStepCompleted = 'Replies adding source step completed',
  DiscardClicked = 'Replies adding source discarded',
  BannerClosed = 'Reply Suggestions banner closed',
}

// TODO delete all above related to reply-suggestions

export const HALF_MINUTE_IN_MS = 30 * 1000;

export const WEBSITES_PLAN_LIMITS = {
  [PlanType.Starter]: 0,
  [PlanType.Team]: 3,
  [PlanType.Business]: 10,
  [PlanType.EnterprisePlus]: 10,
};

export const PDF_PLAN_LIMITS = {
  [PlanType.Starter]: 0,
  [PlanType.Team]: 3,
  [PlanType.Business]: 30,
  [PlanType.EnterprisePlus]: 30,
};
