import { type FC } from 'react';

import { useSelector } from 'react-redux';

import { NavigationId } from 'constants/navigation';
import { getCanAccessChatbotInLivechat } from 'store/entities/chatbot-in-livechat/selectors';

import { NavigationItem } from './navigation-item/NavigationItem';

export const ChatbotItem: FC = () => {
  const shouldShowItem = useSelector(getCanAccessChatbotInLivechat);

  return shouldShowItem ? (
    <NavigationItem key={NavigationId.ChatbotInLiveChat} id={NavigationId.ChatbotInLiveChat} />
  ) : null;
};
