export const HELPDESK_IN_LIVECHAT_COPY = {
  header: {
    default: 'Save 670 working hours per year on support with the ticketing system',
    blackFriday2024: 'Add the ticketing feature at 90% off ⏱️',
  },
  body: {
    default:
      'Transform chats and emails into tickets and reclaim hundreds of hours each year. Focus on what matters most – solving cases and innovating.',
    blackFriday2024:
      "Upgrade your LiveChat with HelpDesk's powerful ticketing system. This Black Friday, get it for just $3.40 per agent. Enhance support and save big — don't miss out!",
  },
  button: {
    default: 'Add HelpDesk ticketing system',
    blackFriday2024: 'Add HelpDesk with discount',
  },
};
