import { type FC, useCallback } from 'react';

import { Close } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { RoutingActions } from 'store/features/routing/actions';

import * as styles from './styles';

export const PrivateSuggestedCannedsTooltip: FC = () => {
  const dispatch = useDispatch();

  const handleHidePrivateSuggestedCannedTooltip = useCallback(() => {
    dispatch(RoutingActions.hidePrivateSuggestedCannedTooltip());
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div onClick={handleHidePrivateSuggestedCannedTooltip} className={styles.icon}>
        <Icon source={Close} size="xsmall" />
      </div>
      <div className={styles.header}>Introducing private and suggested canned responses</div>
      <p className={styles.text}>
        Now you can create private canned responses and get response suggestions during the chat.
      </p>
    </div>
  );
};
