/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash.isempty';
import { type SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import type { RequestError } from 'services/api/types';
import { aiEngineClient } from 'services/connectivity/ml-gateway-api/ai-engine/client';
import {
  type AddNewAgentPayload,
  type AddNewAgentResponse,
  type AIAgentsListDTO,
} from 'services/connectivity/ml-gateway-api/ai-engine/types';

import { AIAgentsEntitiesActions } from './actions';
import { ONE_AGENT_TEMPLATE, REPLY_SUGGESTIONS_AGENT_TEMPLATE } from './constants';
import { deserializeAIAgents, deserializeNewAiAgents } from './helpers';

function* createOneAndReplySuggestionsAgents(): SagaIterator {
  const agentPayloads: AddNewAgentPayload[] = [
    {
      name: 'Reply Suggestions',
      agent_template_id: REPLY_SUGGESTIONS_AGENT_TEMPLATE.id,
      skill_ids: [],
    },
    {
      name: 'One',
      agent_template_id: ONE_AGENT_TEMPLATE.id,
      skill_ids: [],
    },
  ];

  try {
    const results: RequestResult<AddNewAgentResponse, RequestError>[] = yield all(
      agentPayloads.map((payload) => call(() => aiEngineClient.addNewAgent(payload))),
    );

    const deserializedAIAgents = deserializeNewAiAgents(results.map((result) => result.result));

    yield put(AIAgentsEntitiesActions.saveAIAgents(deserializedAIAgents));
  } catch (error) {
    Sentry.captureException(new Error('Failed to create new AI Agents'));
  }
}

function* fetchAIAgents(): SagaIterator {
  const { result }: RequestResult<AIAgentsListDTO[], RequestError> = yield call(() => aiEngineClient.listAIAgents());

  if (result) {
    if (!isEmpty(result)) {
      const deserializedAIAgents = deserializeAIAgents(result);

      yield put(AIAgentsEntitiesActions.saveAIAgents(deserializedAIAgents));

      return;
    }

    yield call(createOneAndReplySuggestionsAgents);
  }
}

export default function* aiAgentsSagas(): SagaIterator {
  yield takeEvery('APP_READY', fetchAIAgents);
}
