import type { LabelComponentDefinition } from 'interfaces/custom-section/label-component';

export function isValidLabelDefinition(rawDefinitionData: LabelComponentDefinition['data']): boolean {
  if (!rawDefinitionData) {
    return true;
  }

  const { label, value, iconUrl, url } = rawDefinitionData;

  if (
    (label && typeof label !== 'string') ||
    (value && typeof value !== 'string') ||
    (iconUrl && typeof iconUrl !== 'string') ||
    (url && typeof url !== 'string')
  ) {
    return false;
  }

  return true;
}
