import { type ToastProps } from '@livechat/design-system-react-components';

import { type ToastAutoHideDelay } from 'constants/toasts';
import type { KeyMap } from 'helpers/interface';

export const enum ToastVariant {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export const enum ToastHorizontalPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export const enum ToastVerticalPosition {
  Top = 'top',
  Bottom = 'bottom',
}

interface ToastAction {
  onClick: () => void;
  label: string;
  closeOnClick?: boolean;
}

export interface ToastDetails extends ToastProps {
  id: string;
  content: ToastProps['content'];
  horizontalPosition: ToastHorizontalPosition;
  verticalPosition: ToastVerticalPosition;
  consumerName?: string;
}

export interface ToastsState {
  items: KeyMap<ToastDetails>;
}

interface ToastsBasePayload {
  id: string;
  autoHideDelayTime: ToastAutoHideDelay;
  horizontalPosition: ToastHorizontalPosition;
  verticalPosition: ToastVerticalPosition;
  kind: ToastVariant;
  content: ToastProps['content'];
  removable: boolean;
  action?: ToastAction;
}

export interface CreateToastActionParams {
  content: ToastProps['content'];
  autoHideDelayTime?: ToastAutoHideDelay;
  horizontalPosition?: ToastHorizontalPosition;
  verticalPosition?: ToastVerticalPosition;
  kind?: ToastVariant;
  action?: ToastAction;
  id?: string;
}

export interface ToastsCreatePayload extends ToastsBasePayload {}

export interface ToastsShowPayload extends ToastsBasePayload {}

export interface ToastsRemovePayload {
  id: string;
}
