import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getItem, saveItem } from 'services/local-storage';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';

import { PINNED_WIDGETS_KEY } from './constants';

const getLocalStorageValue = (key: string): string[] => {
  const value = getItem<string[]>(key);

  return value || [];
};

interface IUsePinnedFullscreenWidgetStorage {
  data: string[];
  save: (value: string[]) => Promise<void>;
}

export const usePinnedFullscreenWidgetStorage = (): IUsePinnedFullscreenWidgetStorage => {
  const userLogin = useSelector(getLoggedInAgentLogin);
  const PINNED_WIDGETS_STORAGE_KEY = `${PINNED_WIDGETS_KEY}-${userLogin}`;

  const { data } = useQuery([PINNED_WIDGETS_KEY], () => getLocalStorageValue(PINNED_WIDGETS_STORAGE_KEY), {
    initialData: [],
  });

  const save = async (value: string[]): Promise<void> => {
    await new Promise((resolve) => resolve(saveItem(PINNED_WIDGETS_STORAGE_KEY, value)));
  };

  return {
    data,
    save,
  };
};
