import { LanguagePhrase } from 'constants/language-phrase';
import { LANGUAGE_NAMES, type LanguageCode } from 'constants/languages-available';
import type { ILanguagesResult } from 'services/api/languages/interfaces';

import type { Language, LanguagePhrases } from '../../../interfaces/language';

export function deserializeLanguage(apiLanguage: ILanguagesResult, languageCode: LanguageCode): Language {
  const apiPhrases = apiLanguage.reverse(); // the customized phrases are at the end of the array
  const phrases = Object.values(LanguagePhrase).reduce(
    (acc, key: LanguagePhrase) => ({
      ...acc,
      [key]: apiPhrases.find((language) => language?.key?.toLowerCase() === key.toLowerCase())?.phrase,
    }),
    {} as Partial<LanguagePhrases>
  );

  const name = LANGUAGE_NAMES[languageCode];
  const isFetching = false;

  return {
    languageCode,
    name,
    phrases,
    isFetching,
  };
}
