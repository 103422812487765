import { type ITourStep, TourStepType } from '../interfaces';

import { MySavedViewsPageStep, SavedViewsToolbarStep } from './tour-steps';

export enum ReportViewsStep {
  SavedViewsToolbar = 'SavedViewsToolbar',
  MySavedViewsPage = 'MySavedViewsPage',
}

export const reportViewsSteps: ITourStep[] = [
  {
    id: ReportViewsStep.SavedViewsToolbar,
    type: TourStepType.GuideTooltip,
    tooltip: {
      placement: 'bottom-end',
      content: <SavedViewsToolbarStep />,
    },
  },
  {
    id: ReportViewsStep.MySavedViewsPage,
    type: TourStepType.GuideTooltip,
    tooltip: {
      placement: 'right',
      content: <MySavedViewsPageStep />,
      offsetMainAxis: -50,
    },
  },
];
