import { type ApiClientResponse, type ApiClientError } from '../http/types';

export const enum UserType {
  AGENT = 'agent',
  CUSTOMER = 'customer',
}

export const enum Visibility {
  ALL = 'all',
  AGENTS = 'agents',
}

export const enum SurveyType {
  PRE_CHAT = 'pre_chat',
  POST_CHAT = 'post_chat',
}

export const enum FormType {
  PRECHAT = 'prechat',
  POSTCHAT = 'postchat',
  ASK_FOR_EMAIL = 'ask_for_email',
  OFFLINE = 'offline',
  QUEUED = 'queued',
}

export const enum SortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

/**
 * Represents an error type.
 *
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#possible-errors}
 */
export const enum AgentChatApiErrorType {
  AGENT_OFFLINE = 'agent_offline',
  AUTHENTICATION = 'authentication',
  AUTHORIZATION = 'authorization',
  CHAT_INACTIVE = 'chat_inactive',
  ENTITY_TOO_LARGE = 'entity_too_large',
  INTERNAL = 'internal',
  LICENSE_EXPIRED = 'license_expired',
  LICENSE_NOT_FOUND = 'license_not_found',
  LIMIT_REACHED = 'limit_reached',
  MISDIRECTED_REQUEST = 'misdirected_request',
  MISSING_ACCESS = 'missing_access',
  NOT_FOUND = 'not_found',
  REQUEST_TIMEOUT = 'request_timeout',
  SERVICE_UNAVAILABLE = 'service_unavailable',
  TOO_MANY_REQUESTS = 'too_many_requests',
  UNSUPPORTED_VERSION = 'unsupported_version',
  VALIDATION = 'validation',
  WRONG_PRODUCT_VERSION = 'wrong_product_version',
}

/**
 * Represents an error object in the API response.
 *
 * @property {ErrorType} type - The type of the error.
 * @property {string} message - A human-readable message describing the error.
 * @property {Record<string, unknown>} data - Additional data about the error, if available.
 */
interface AgentChatApiError {
  type: AgentChatApiErrorType;
  message: string;
  data?: Record<string, unknown>;
}

/**
 * Represents an error response from the API.
 *
 * @property {ApiError} error - The error object.
 *
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#errors}
 */
export interface AgentChatApiErrorResponse {
  error: AgentChatApiError;
}

/**
 * Type representing the response from the Agent Chat API.
 *
 * @template T The type of the data returned in the response.
 */
export type AgentChatApiResponse<T> = ApiClientResponse<T, AgentChatApiErrorResponse>;

/**
 * Represents an error from the Agent Chat API client.
 */
export type AgentChatApiClientError = ApiClientError<AgentChatApiErrorResponse>;

/**
 * Represents a normalized error object.
 */
export type AgentChatApiNormalizedError = AgentChatApiErrorResponse['error'] & {
  status: AgentChatApiClientError['status'];
};
