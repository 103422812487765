import debug from 'debug';
import { type SagaIterator } from 'redux-saga';
import { delay } from 'redux-saga/effects';

import faviconUnreadMessages from 'assets/img/favicon-unread-messages.svg';
import defaultLogo from 'assets/img/icon-livechat.svg';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';

const NOTIFICATION_COUNT_REGEX = /^\(\d+\) /g;

const favicon = document.querySelector<HTMLAnchorElement>('[rel="icon"]');

const log = debug(DebugLogsNamespace.AppErrors);

function canUseDOM(): boolean {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
}

function clearTabTitle(): void {
  try {
    if (canUseDOM() && document.title) {
      document.title = document.title.replace(NOTIFICATION_COUNT_REGEX, '');

      if (favicon) {
        favicon.href = defaultLogo;
      }
    }
  } catch (error) {
    log('clearTabTitle', error);
  }
}

export function setCounterInTabTitle(notificationCount: number): void {
  try {
    clearTabTitle();

    if (canUseDOM() && document.title && notificationCount > 0) {
      document.title = `(${notificationCount}) ${document.title}`;
      if (favicon) {
        favicon.href = faviconUnreadMessages;
      }
    }
  } catch (error) {
    log('setCounterInTabTitle', error);
  }
}

export function* blinkTabTitle(notificationCount: number): SagaIterator {
  try {
    while (true) {
      setCounterInTabTitle(notificationCount);
      yield delay(1000);
      clearTabTitle();
      yield delay(1000);
    }
  } finally {
    clearTabTitle();
  }
}
