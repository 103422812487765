// @ts-strict-ignore
import { BaseApi } from '../base-api';

import { type IFetchNewArchivesCountParams } from './interfaces';
import ArchiveNotifierApi from './notifier/api';
import { type IFetchNewArchivesCountResult } from './notifier/interfaces';

export class ArchiveApi extends BaseApi {
  fetchNewArchivesCount = <TResult = IFetchNewArchivesCountResult>(
    sinceTimestamp: number,
    params?: IFetchNewArchivesCountParams
  ) => new ArchiveNotifierApi(this.api).fetchNewArchivesCount<TResult>(sinceTimestamp, params);
}
