import { type FC, type ReactNode, useState, useEffect, useRef, useCallback } from 'react';

import { cx } from '@emotion/css';
import { ChevronRight } from '@livechat/design-system-icons';
import { Text } from '@livechat/design-system-react-components';

import { UserGuidedTourStep } from 'components/tours/UserGuidedTourStep';
import { noop } from 'helpers/noop';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';

import { SideNavigationItem } from './SideNavigationItem';

import * as styles from './styles';

interface IProps {
  children: ReactNode;
  label?: ReactNode | ((isOpen: boolean) => ReactNode);
  labelIcon?: ReactNode;
  rightNode?: ReactNode | ((isOpen: boolean) => ReactNode);
  className?: string;
  listClassName?: string;
  labelClassName?: string;
  labelItemClassName?: string;
  labelWrapperClassName?: string;
  isCollapsible?: boolean;
  onItemHover?: () => void;
  tourStep?: ISideNavigationItem['tourStep'];
  shouldOpenOnInit?: boolean;
  shouldOpenOnActive?: boolean;
  noGaps?: boolean;
}

export const SideNavigationList: FC<IProps> = ({
  label,
  labelIcon,
  rightNode,
  className,
  listClassName,
  labelClassName,
  labelItemClassName,
  labelWrapperClassName,
  children,
  isCollapsible,
  onItemHover,
  tourStep,
  shouldOpenOnInit = false,
  shouldOpenOnActive = false,
  noGaps,
}) => {
  const [isOpen, setIsOpen] = useState(!isCollapsible || shouldOpenOnInit);
  const [hasActiveElements, setHasActiveElements] = useState<boolean>(false);
  const hadActiveListElementsRef = useRef(false);

  const localRightNode = typeof rightNode === 'function' ? rightNode(isOpen) : rightNode;
  const localLabel = typeof label === 'function' ? label(isOpen) : label;

  const openList = useCallback((): void => setIsOpen(true), []);
  const toggle = useCallback((): void => {
    if (!isCollapsible) return;
    setIsOpen((prev) => !prev);
  }, [isCollapsible]);

  const onSetListNode = (node: HTMLUListElement | null): void => {
    const listElements = node?.getElementsByTagName('li');
    const hasListActiveElements = listElements?.length
      ? [...listElements].some((el) => el.getAttribute('data-active') === 'true')
      : false;

    setHasActiveElements(hasListActiveElements);
  };

  useEffect(() => {
    if (!shouldOpenOnActive) {
      return;
    }

    if (!hadActiveListElementsRef.current && hasActiveElements) {
      openList();
    }

    hadActiveListElementsRef.current = hasActiveElements;
  }, [hasActiveElements, openList, shouldOpenOnActive]);

  const listContent = (
    <div className={cx([styles.listWrapper, listClassName])}>
      {isCollapsible ? (
        <SideNavigationItem
          icon={
            <>
              <div className={cx({ [styles.defaultChevron]: true, [styles.activeChevron]: isOpen })}>
                <ChevronRight width={16} height={16} />
              </div>

              {labelIcon}
            </>
          }
          label={
            <Text bold className={labelClassName}>
              {localLabel}
            </Text>
          }
          isMainEntry
          onClick={toggle}
          onItemHover={onItemHover}
          rightNode={localRightNode}
          className={labelItemClassName}
        />
      ) : (
        <div className={labelWrapperClassName}>
          <Text onMouseEnter={onItemHover || noop} className={labelClassName} bold>
            {localLabel}
          </Text>
          {localRightNode}
        </div>
      )}

      <ul
        ref={onSetListNode}
        className={cx([
          styles.list,
          { [styles.expandedList]: isOpen, [styles.expandedListGap]: isOpen && isCollapsible && !noGaps },
          className,
        ])}
      >
        {children}
      </ul>
    </div>
  );

  return tourStep ? (
    <UserGuidedTourStep tourId={tourStep.id} step={tourStep.step}>
      {listContent}
    </UserGuidedTourStep>
  ) : (
    listContent
  );
};
