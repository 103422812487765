import { type SagaIterator } from 'redux-saga';
import { call, takeLatest } from 'redux-saga/effects';

import { LocalStorageKey, saveItem } from 'services/local-storage';
import { type IActionWithPayload } from 'store/helper';

import { DevToolsActionNames } from './actions';
import { type IDevToolsTogglePayload } from './interfaces';

function* toggleDevTools(action: IActionWithPayload<string, IDevToolsTogglePayload>): SagaIterator {
  const { isVisible, reloadAfterToggle } = action.payload;
  yield call(saveItem, LocalStorageKey.DevTools, isVisible);
  if (reloadAfterToggle) {
    window.location.reload();
  }
}

export default function* devToolsSagas(): SagaIterator {
  yield takeLatest(DevToolsActionNames.TOGGLE, toggleDevTools);
}
