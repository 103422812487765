export enum Permission {
  Administrator = 'administrator',
  Normal = 'normal',
  Owner = 'owner',
  ViceOwner = 'viceowner',
}

export const PermissionScore = {
  [Permission.Owner]: 4,
  [Permission.ViceOwner]: 3,
  [Permission.Administrator]: 2,
  [Permission.Normal]: 1,
};
