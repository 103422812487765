import { type FC } from 'react';

import { ModalPortal } from '@livechat/design-system-react-components';

import { SomethingWentWrongModal } from 'components/global-modals/something-went-wrong/SomethingWentWrongModal';
import { StackingContextLevel } from 'styles/stacking-context-level';

import { GenericErrorBoundary } from './GenericErrorBoundary';

export const ErrorBoundary: FC = ({ children }) => (
  <GenericErrorBoundary
    onRenderError={() => (
      <ModalPortal zIndex={StackingContextLevel.Modal}>
        <SomethingWentWrongModal onClose={() => window.location.reload()} />
      </ModalPortal>
    )}
  >
    {children}
  </GenericErrorBoundary>
);
