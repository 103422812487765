import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const rightNode = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  color: var(${DesignToken.DecorGray100});
`;

export const badge = css`
  color: var(${DesignToken.DecorGray100});
  background: var(${DesignToken.DecorGray700});
`;
