// @ts-strict-ignore
import { format, parseISO } from 'date-fns';

import { DateFormat } from 'constants/date';
import { Filter } from 'constants/filters/filter';
import { DEFAULT_SERIES_COLOR, PaletteNames, ExtraPalettes } from 'constants/reports/chart-color-palette';
import { formatTimeToHours } from 'helpers/format-time';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { sumValueFromPeriod, getSeriesDataFromPeriod } from 'helpers/reports';
import {
  type ReportsViewFilters,
  type ITotalChatPeriod,
  type IChatAvailabilityPeriod,
  type IChatAvailabilityReport,
  type IChatByStatusReportData,
} from 'interfaces/reports';
import { type ReportData } from 'interfaces/reports/api-v3';
import { AppStateProvider } from 'services/app-state-provider';

import { REPORT_TOTAL_CHATS_SUBPATH } from '../constants';
import { getFilters } from '../selectors';

import { sumArray, subArrays, REPORT_CONTINUOUS_CHATS_SUBPATH } from './total-chats-deserializer';

export enum ChatsByStatusLabel {
  Online = 'Online',
  Offline = 'Offline',
}

interface IChatAvailabilityReportPayload {
  data: {
    chatAvailability: ReportData<IChatAvailabilityPeriod>;
  };
}

interface ITotalChatsReportData {
  data: {
    totalChats: ReportData<ITotalChatPeriod>;
  };
}
enum DistributionKey {
  Minutes = 'minutes',
  Hours = 'hours',
}

export function deserializeChatAvailability({ data }: IChatAvailabilityReportPayload): IChatAvailabilityReport {
  const filters: ReportsViewFilters = AppStateProvider.selectFromStore(getFilters);
  const dateRange = filters[Filter.DateRange];
  const { from, to, compare } = dateRange;
  const dateFrom = from && format(from, DateFormat.ISO8601Date);
  const dateTo = to && format(to, DateFormat.ISO8601Date);
  const dateCompareFrom = compare?.from && format(compare?.from, DateFormat.ISO8601Date);
  const dateCompareTo = compare?.to && format(compare?.to, DateFormat.ISO8601Date);
  const distributionKey =
    dateFrom === dateTo && ((!dateCompareFrom && !dateCompareTo) || dateCompareFrom === dateCompareTo)
      ? DistributionKey.Minutes
      : DistributionKey.Hours;
  const sumOfAvailability = sumValueFromPeriod(data.chatAvailability.records, distributionKey);
  const availabilityData = getSeriesDataFromPeriod<number>(data.chatAvailability.records, distributionKey);
  const formattedDate =
    distributionKey === DistributionKey.Minutes ? DateFormat.Time24HourWithoutSeconds : DateFormat.ISO8601Date;

  const labels = Object.keys(data.chatAvailability.records).map((date) => {
    const parsedIsoDate = parseISO(date);

    return format(parsedIsoDate, formattedDate);
  });

  return {
    chatAvailability: {
      summary: [
        {
          name: 'availability',
          value: formatTimeToHours(
            distributionKey === DistributionKey.Hours ? sumOfAvailability : sumOfAvailability / 60
          ),
          color: DEFAULT_SERIES_COLOR,
        },
      ],
      labels,
      series: [
        {
          color: DEFAULT_SERIES_COLOR,
          name: 'availability',
          data: availabilityData,
          csvColumnName: 'availability',
        },
      ],
    },
  };
}

export function deserializeChatsByStatus({ data }: ITotalChatsReportData): IChatByStatusReportData {
  const totalSeries = getSeriesDataFromPeriod<number>(data.totalChats.records, REPORT_TOTAL_CHATS_SUBPATH);
  const chatsSeriesOffline = getSeriesDataFromPeriod<number>(data.totalChats.records, REPORT_CONTINUOUS_CHATS_SUBPATH);
  const chatsSeriesOnline = subArrays(totalSeries, chatsSeriesOffline);
  const labels = Object.keys(data.totalChats.records);

  const chatsSeriesOnlineSum = sumArray(chatsSeriesOnline);
  const chatsSeriesOfflineSum = sumArray(chatsSeriesOffline);
  const total = chatsSeriesOnlineSum + chatsSeriesOfflineSum;

  return {
    chatsByStatus: {
      summary: [
        {
          name: ChatsByStatusLabel.Online,
          subtitle: 'Accepting chats',
          value: chatsSeriesOnlineSum,
          color: ExtraPalettes.Positive,
          label: getFormattedPercent(chatsSeriesOnlineSum / total, 2),
        },
        {
          name: ChatsByStatusLabel.Offline,
          subtitle: 'Not accepting chats',
          value: chatsSeriesOfflineSum,
          color: ExtraPalettes.Negative,
          label: getFormattedPercent(chatsSeriesOfflineSum / total, 2),
        },
      ],
      labels,
      series: [
        {
          name: 'Total',
          color: PaletteNames.Waterloo,
          data: totalSeries,
          csvColumnName: 'total-chats',
        },
        {
          name: 'Online',
          color: ExtraPalettes.Positive,
          data: chatsSeriesOnline,
          csvColumnName: 'online-chats',
        },
        {
          name: 'Offline',
          color: ExtraPalettes.Negative,
          data: chatsSeriesOffline,
          csvColumnName: 'offline-chats',
        },
      ],
    },
  };
}
