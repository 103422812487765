function isThemeSection(fragment: string): boolean {
  return fragment.includes('/settings/theme');
}

function isWelcomeScreenSection(fragment: string): boolean {
  return fragment.includes('/settings/welcome-screen');
}

function isSubscriptionManageSection(fragment: string): boolean {
  return fragment.includes('/subscription');
}

function isNavigatingToSubsection(currentFragment: string, nextFragment: string): boolean {
  return (
    (isThemeSection(currentFragment) && isThemeSection(nextFragment)) ||
    (isWelcomeScreenSection(currentFragment) && isWelcomeScreenSection(nextFragment)) ||
    (isSubscriptionManageSection(currentFragment) && isSubscriptionManageSection(nextFragment))
  );
}

export function shouldIgnoreDiscardChangesModal(currentFragment: string, nextFragment: string): boolean {
  return isNavigatingToSubsection(currentFragment, nextFragment);
}
