import { type FC, type MouseEvent, useState } from 'react';

import { cx } from '@emotion/css';
import { Android, Apple, DeviceDesktop } from '@livechat/design-system-icons';
import { Switch } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { AgentAvatar } from 'components/avatar/AgentAvatar';
import { Badge, BadgeType } from 'components/badge/Badge';
import { Platform } from 'constants/app-platform';
import { GlobalModal } from 'constants/global-modal';
import { LoginStatus } from 'constants/login-status';
import { STATUS_TO_DESIGN_TOKEN } from 'constants/login-status-to-design-token';
import { EventPlace } from 'helpers/analytics';
import { isMacOS } from 'helpers/browser';
import { userForcesDesktopView } from 'helpers/device';
import { getAppDownloadUrl, getMobileAppDownloadUrl } from 'helpers/downloads';
import { notificationsEnabled } from 'helpers/notifications';
import { navigate, openInNewCard } from 'helpers/routing';
import { toggleViewMode } from 'helpers/view-mode';
import { useChangeCurrentAgentStatus } from 'hooks/api/agents/use-change-current-agent-status';
import { usePartnerType } from 'hooks/use-partner-type';
import { type MyInactivityResponse } from 'services/connectivity/custom-status-api/inactivities/types';
import { getIsDevToolsSwitchVisible } from 'services/dev-tools/helpers';
import { trackEvent } from 'services/event-tracking';
import { ProfileMenuEvent } from 'store/entities/agents/constants';
import { getIsLoggedInAgentAcceptingChats, getLoggedInAgent } from 'store/entities/agents/selectors';
import { getIsInactivityReasonInstalled } from 'store/entities/applications/selectors';
import { CompanyDetailsActions } from 'store/entities/company-details/actions';
import { isDesktopAppDisallowedByLicenseSource } from 'store/features/agent-custom-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getCanManageCompanyDetails } from 'store/features/session/selectors';
import { DevToolsActions } from 'store/views/dev-tools/actions';
import { areDevToolsVisible as areDevToolsVisibleSelector } from 'store/views/dev-tools/selectors';
import { hideForDesktopStyles } from 'styles/device';

import { MY_PROFILE_TEST_ID, STATUS_SWITCH_TEST_ID } from './constants';
import { DarkModeSwitch } from './dark-mode-switch/DarkModeSwitch';
import NavigationDownloadApp from './NavigationDownloadApp';
import {
  appsIconsWrapper,
  appsWrapper,
  avatar,
  menuDivider,
  menuList,
  menuListItem,
  menuProfile,
  menuProfileMeta,
  menuWrapper,
  menuProfileMetaName,
  customStatusButton,
} from './styles/navigation-my-profile-menu';

interface Props {
  hideMenu(): void;
  onNotificationsClick(): void;
  customStatus?: MyInactivityResponse;
}

export const NavigationMyProfileMenu: FC<Props> = ({ hideMenu, onNotificationsClick, customStatus }) => {
  const { handleStatusChange } = useChangeCurrentAgentStatus();
  const [isSwitchingViewMode, setIsSwitchingViewMode] = useState(false);
  const dispatch = useDispatch();
  const isInactivityReasonInstalled = useSelector(getIsInactivityReasonInstalled);
  const isAcceptingChats = useSelector(getIsLoggedInAgentAcceptingChats);
  const { isReseller } = usePartnerType();
  const hasAccessToCompanyDetails = useSelector(getCanManageCompanyDetails);
  const canManageCompanyDetails = hasAccessToCompanyDetails && !isReseller;
  const areDevToolsVisible = useSelector(areDevToolsVisibleSelector);
  const isDesktopAppAllowed = useSelector((state) => !isDesktopAppDisallowedByLicenseSource(state));
  const currentAgent = useSelector(getLoggedInAgent);

  const handleLogout = (): void => {
    dispatch(CompanyDetailsActions.showModalOnLogout());
  };

  const handleShowKeyboardShortcutsModal = (): void => {
    dispatch(GlobalModalActions.showModal(GlobalModal.KeyboardShortcuts));
  };

  const handleOpenCustomStatusModal = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    dispatch(GlobalModalActions.showModal(GlobalModal.CustomStatusModal));
    hideMenu();
  };

  const handleProfileClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    trackEvent(ProfileMenuEvent.ViewProfileClicked, EventPlace.ProfileMenu, {
      type: 'From profile',
    });

    navigate(`/team/agent/${encodeURIComponent(currentAgent.login)}`);
    hideMenu();
  };

  const handleNotificationsClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    trackEvent(ProfileMenuEvent.NotificationsPreferencesClicked, EventPlace.ProfileMenu, {
      type: 'From profile',
    });

    onNotificationsClick();
    hideMenu();
  };

  const handleLogoutClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    handleLogout();
    hideMenu();
  };

  const handleChangeStatusSwitch = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    const status = isAcceptingChats ? LoginStatus.Away : LoginStatus.Online;

    handleStatusChange(
      { status, eventPlace: EventPlace.ProfileMenu },
      {
        onSuccess: () => {
          trackEvent(
            status === LoginStatus.Online
              ? ProfileMenuEvent.StatusChangedToOnline
              : ProfileMenuEvent.StatusChangedToOffline,
            EventPlace.ProfileMenu,
            {
              type: 'From profile',
            },
          );
        },
      },
    );
  };

  const handleSwitchViewMode = (): void => {
    toggleViewMode();
    setIsSwitchingViewMode(true);
  };

  const handleToggleDevTools = (): void => {
    dispatch(DevToolsActions.toggle({ isVisible: !areDevToolsVisible }));
  };

  const handleKeyboardShortcutsClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    handleShowKeyboardShortcutsModal();
    trackEvent(ProfileMenuEvent.KeyboardShortcutsClicked, EventPlace.ProfileMenu, {
      type: 'From profile',
    });

    hideMenu();
  };

  const handleKnowledgeBaseClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    trackEvent('KB widget maximized', EventPlace.HelpMenu);

    openInNewCard(
      'https://www.livechat.com/help/?utm_source=webapp&utm_medium=link&utm_campaign=webapp_knowledge_base',
    );
    hideMenu();
  };

  const handleBugReport = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    hideMenu();
    dispatch(GlobalModalActions.showModal(GlobalModal.ReportBug));
  };

  const handleCompanyDetailsClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    trackEvent(ProfileMenuEvent.CompanyDetailsClicked, EventPlace.ProfileMenu);

    dispatch(
      GlobalModalActions.showModal(GlobalModal.CompanyDetails, {
        eventPlace: EventPlace.ProfileMenu,
        isLoggingOut: false,
      }),
    );
    hideMenu();
  };

  const shouldShowDevToolsSwitch = getIsDevToolsSwitchVisible();

  return (
    <div className={cx([menuWrapper, 'lc-light-theme'])}>
      <ul className={menuList} aria-hidden="true">
        <li className={menuListItem}>
          <a type="button" className={menuProfile} data-testid={MY_PROFILE_TEST_ID} onClick={handleProfileClick}>
            <AgentAvatar src={currentAgent.avatar} text={currentAgent.name} size="large" className={avatar} />
            <div className={menuProfileMeta}>
              <h3 className={menuProfileMetaName}>{currentAgent.name}</h3>
              <span>{currentAgent.login}</span>
            </div>
          </a>
        </li>
      </ul>

      <div className={menuDivider} />

      <ul className={menuList} id="my-profile-menu" role="menu" aria-labelledby="my-profile-menu-button">
        <li className={menuListItem}>
          {isInactivityReasonInstalled ? (
            <>
              {customStatus?.name ? (
                <a
                  type="button"
                  className={customStatusButton(STATUS_TO_DESIGN_TOKEN[LoginStatus.Away])}
                  onClick={handleOpenCustomStatusModal}
                >
                  {customStatus.icon_url && <img src={customStatus.icon_url} width="20px" height="20px" />}
                  {customStatus.name}
                </a>
              ) : (
                <a type="button" onClick={handleOpenCustomStatusModal}>
                  Update your status
                </a>
              )}
            </>
          ) : (
            <a
              type="button"
              onClick={handleChangeStatusSwitch}
              aria-label="Accept chats"
              aria-checked={!isAcceptingChats}
              aria-controls="accept-chats"
            >
              Accept chats
              <Switch
                size="compact"
                className="addition"
                data-testid={STATUS_SWITCH_TEST_ID}
                on={isAcceptingChats}
                aria-label="Accept chats"
                aria-checked={!isAcceptingChats}
                aria-controls="accept-chats"
              />
            </a>
          )}
        </li>
      </ul>

      <div className={menuDivider} />

      <ul className={menuList} aria-hidden="true">
        <li className={menuListItem}>
          <DarkModeSwitch />
        </li>

        {shouldShowDevToolsSwitch && (
          <li className={menuListItem}>
            <a type="button" onClick={handleToggleDevTools}>
              Show Dev Tools
              <Switch size="compact" className="addition" on={areDevToolsVisible} />
            </a>
          </li>
        )}

        <li className={menuListItem}>
          <a type="button" onClick={handleNotificationsClick}>
            Notification preferences
            {!notificationsEnabled() && (
              <span className="addition">
                <Badge type={BadgeType.Alert}>!</Badge>
              </span>
            )}
          </a>
        </li>
      </ul>

      <div className={menuDivider} />

      <ul>
        <li className={menuListItem}>
          <div className={appsWrapper}>
            <div>Download apps</div>
            <div className={appsIconsWrapper}>
              <NavigationDownloadApp
                icon={Android}
                tooltipContent="Android mobile app"
                downloadLink={getMobileAppDownloadUrl(Platform.Android)}
                trackEventContent="Android app download button clicked"
              />
              <NavigationDownloadApp
                icon={Apple}
                tooltipContent="iOS mobile app"
                downloadLink={getMobileAppDownloadUrl(Platform.iOS)}
                trackEventContent="iOS app download button clicked"
              />
              {isDesktopAppAllowed && (
                <NavigationDownloadApp
                  icon={DeviceDesktop}
                  tooltipContent="Desktop app"
                  downloadLink={getAppDownloadUrl()}
                  trackEventContent="Desktop app download button clicked"
                  platform={isMacOS() ? Platform.Mac : Platform.Windows}
                />
              )}
            </div>
          </div>
        </li>
      </ul>

      <div className={menuDivider} />

      <ul className={menuList} aria-hidden="true">
        <li className={menuListItem}>
          <a type="button" onClick={handleKnowledgeBaseClick}>
            Help Center
          </a>
        </li>

        <li className={menuListItem}>
          <a type="button" onClick={handleBugReport}>
            Report an issue
          </a>
        </li>

        <li className={menuListItem}>
          <a type="button" onClick={handleKeyboardShortcutsClick}>
            Keyboard shortcuts
          </a>
        </li>

        {canManageCompanyDetails && (
          <li className={menuListItem}>
            <a type="button" onClick={handleCompanyDetailsClick}>
              Company details
            </a>
          </li>
        )}
      </ul>

      <div className={hideForDesktopStyles}>
        <div className={menuDivider} />
        <ul className={menuList} aria-hidden="true">
          <li className={menuListItem}>
            <a type="button" onClick={handleSwitchViewMode}>
              <span>{isSwitchingViewMode ? 'Switching...' : 'Force desktop view'}</span>
              <Switch size="compact" className="addition" on={userForcesDesktopView()} />
            </a>
          </li>
        </ul>
      </div>

      <div className={menuDivider} />

      <ul className={menuList} aria-hidden="true">
        <li className={menuListItem}>
          <a type="button" onClick={handleLogoutClick}>
            Log out
          </a>
        </li>
      </ul>
    </div>
  );
};
