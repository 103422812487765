import { type IActionWithPayload } from 'store/helper';

import { type DetailsColumnAction, DetailsColumnActionNames } from './actions';
import { DetailsTab } from './constants';
import { type IDetailsColumnView, type ISelectDetailsTabPayload } from './interfaces';

const initialState: IDetailsColumnView = {
  selectedTabId: DetailsTab.CustomerDetails,
};

export const detailsColumnReducer = (
  state: IDetailsColumnView = initialState,
  action: DetailsColumnAction
): IDetailsColumnView => {
  switch (action.type) {
    case DetailsColumnActionNames.SELECT_DETAILS_TAB: {
      const {
        payload: { tabId },
      } = action as IActionWithPayload<string, ISelectDetailsTabPayload>;

      return {
        ...state,
        selectedTabId: tabId,
      };
    }

    default:
      return state;
  }
};
