import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const listStyles = css`
  margin-bottom: var(${SpacingToken.Spacing5});
`;

export const labelStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(${DesignToken.ContentLockedWhite});
  padding: var(${SpacingToken.Spacing1}) var(${SpacingToken.Spacing3});
`;
