import { useRef, useEffect, type MutableRefObject } from 'react';

/**
 * @see https://stackoverflow.com/a/58217148
 */
export const useIsMounted = (): MutableRefObject<boolean> => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return (): void => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
