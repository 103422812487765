import { type FC } from 'react';

import { Info } from '@livechat/design-system-icons';
import { Icon, Tooltip } from '@livechat/design-system-react-components';

import { LEGAL_REMINDER_FOR_BUG_REPORT } from 'constants/report-bug';

import * as styles from './styles';

export const ReportBugTooltip: FC = () => (
  <div className={styles.iconWrapper}>
    <Tooltip
      className={styles.tooltip}
      placement="top"
      kind="invert"
      triggerRenderer={() => <Icon className={styles.tooltipIcon} source={Info} />}
    >
      <span className={styles.text}>{LEGAL_REMINDER_FOR_BUG_REPORT}</span>
    </Tooltip>
  </div>
);
