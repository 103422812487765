// @ts-strict-ignore
import { Assignment } from '@livechat/design-system-icons';

import { AgentAssignmentFilterLabels } from 'constants/filters/agent-assignment-filter';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

export const agentAssignmentFilterConfig: FilterConfig = {
  title: 'Assignment',
  icon: Assignment,
  multiple: false,
  getFilterLabelValues: (filterValues: string[] | null): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    return [
      {
        key: filterValues[0],
        label: AgentAssignmentFilterLabels[filterValues[0]],
      },
    ];
  },
};
