import type { FC } from 'react';

import { useSelector } from 'react-redux';
import { shortenedCountNumber } from 'helpers/string';

import { Badge, BadgeType } from 'components/badge/Badge';
import { getQueuedIds } from 'store/entities/chats/selectors';

export const EngageBadge: FC = () => {
  const queuedChatsCount = useSelector(getQueuedIds).length;

  return queuedChatsCount > 0 ? <Badge type={BadgeType.Alert}>{shortenedCountNumber(queuedChatsCount)}</Badge> : null;
};
