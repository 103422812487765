export enum TrackingEvent {
  Displayed = 'Displayed',
  NotificationDisplayed = 'Notification displayed',
  NotificationClicked = 'Notification clicked',
  StartAcceptingChats = 'Start accepting chats',
  DismissButtonClicked = 'Dismissed',
  ShowModal = 'Modal shown',
  OpenChatWidget = 'Chat Widget opened',
  DownloadReport = 'Bug report downloaded',
  DownloadReportAndOpenWidget = 'Downloaded bug report and opened Chat Widget',
}
