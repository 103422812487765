import { useState, useCallback, useMemo } from 'react';

const MAX_ITEMS_PER_PAGE = 5;

export interface IUseItemsPagination<T> {
  itemsSlice: T[];
  allPages: number;
  currentPage: number;
  onPageLoad: (page: number) => void;
}

export function useItemsPagination<T>(items: T[], itemsPerPage: number = MAX_ITEMS_PER_PAGE): IUseItemsPagination<T> {
  const [currentPage, setCurrentPage] = useState(1);
  const allPages = Math.ceil(items.length / itemsPerPage);

  const onPageLoad = useCallback(
    (page: number) => {
      if (page > allPages) {
        return;
      }
      setCurrentPage(page);
    },
    [allPages]
  );

  const itemsSlice = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;

    return items.slice(startIndex, endIndex);
  }, [currentPage, items, itemsPerPage]);

  return {
    itemsSlice,
    currentPage,
    allPages,
    onPageLoad,
  };
}
