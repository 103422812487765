import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadByChatId } from 'store/entities/chats/selectors';

import { type IChatAccessUpdatedPayload } from '../interfaces';

/**
 * Handles chat access updated push event.
 * @param {IThreadPropertiesUpdatedPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleChatAccessUpdated(payload: IChatAccessUpdatedPayload, store: IAppState): void {
  const {
    id: chatId,
    access: { group_ids: groupIds },
  } = payload;

  const thread = getThreadByChatId(store.getState(), chatId);

  if (!thread) {
    return;
  }

  const [groupId] = groupIds;

  store.dispatch(
    ChatsEntitiesActions.updateChatThread({
      threadId: thread.threadId,
      thread: { groupIds: [groupId.toString()] },
    })
  );
}
