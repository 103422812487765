import type { RequestResult } from 'interfaces/api/client';
import type { UploadEvents, UploadFileResult } from 'services/api/types';

import { BaseApi, PassVersionMode } from '../base-api';

export class LogoApi extends BaseApi {
  protected readonly passVersionMode: PassVersionMode = PassVersionMode.Header;

  uploadImage = (attachment: File, options?: UploadEvents): Promise<RequestResult<UploadFileResult>> => {
    const data = new FormData();
    data.append('image', attachment);

    return this.upload('previews/logo', data, {}, options);
  };
}
