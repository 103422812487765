import property from 'lodash.property';

import { getGreetingType } from 'helpers/get-greeting-type';
import { getGreetingTypeForEvent } from 'helpers/get-greeting-type-for-event';
import { type AmplitudeEventProperties } from 'services/amplitude';

import {
  type IGreeting,
  GreetingRuleType,
  type GreetingRule,
  type IGreetingOwnVariableRule,
  type IGreetingExitIntentRule,
} from './interfaces';

export const getRuleValue = (rules: GreetingRule[], type: GreetingRuleType): string | null => {
  if (!rules) {
    return null;
  }

  if (type === GreetingRuleType.Intent) {
    const item = rules.find((item) => item.type === type) as IGreetingOwnVariableRule;

    return item ? item.value : null;
  }

  if (type === GreetingRuleType.ExitIntent) {
    const item = rules.find((item) => item.type === type) as IGreetingExitIntentRule;

    return item ? item.value.toString() : null;
  }

  return null;
};

export const getGreetingEventProperties = (greeting: IGreeting): AmplitudeEventProperties => {
  return {
    type: getGreetingTypeForEvent(greeting),
    greetingType: getGreetingType(greeting),
    conditions: greeting.rules.length,
    intentValue: getRuleValue(greeting.rules, GreetingRuleType.Intent),
    exitIntentValue: getRuleValue(greeting.rules, GreetingRuleType.ExitIntent),
    hasAddon: !!greeting.addon,
    subtype: greeting.subtype,
    hasTitle: !!property(['richGreetingData', 'elements', '0', 'title'])(greeting),
    hasNameChanged: greeting.isNameChanged,
    hasSubtitle: !!property(['richGreetingData', 'elements', '0', 'subtitle'])(greeting),
    hasImage: !!property(['richGreetingData', 'elements', '0', 'image', 'url'])(greeting),
    hasButtons: !!property(['richGreetingData', 'elements', '0', 'buttons', 'length'])(greeting),
    isActive: greeting.isActive,
  };
};
