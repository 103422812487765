import { createAction, type ActionsUnion } from 'store/helper';

import { type AIAgents } from './interfaces';

export enum AIAgentsEntitiesActionNames {
  SAVE_AI_AGENTS = 'ENTITIES/AI_AGENTS/SAVE_AI_AGENTS',
  CHANGE_SOURCE_TYPE = 'ENTITIES/AI_AGENTS/CHANGE_SOURCE_TYPE',
  ASSIGN_SOURCE_TO_AI_AGENT = 'ENTITIES/AI_AGENTS/ASSIGN_SOURCE_TO_AI_AGENT',
}

export const AIAgentsEntitiesActions = {
  saveAIAgents: (payload: AIAgents) => createAction(AIAgentsEntitiesActionNames.SAVE_AI_AGENTS, payload),
  changeSourceType: (payload: { sourceId: string }) =>
    createAction(AIAgentsEntitiesActionNames.CHANGE_SOURCE_TYPE, payload),
  assignSourceToAIAgents: (payload: { skillId: string; agentIds: string[] }) =>
    createAction(AIAgentsEntitiesActionNames.ASSIGN_SOURCE_TO_AI_AGENT, payload),
};

export type AIAgentsEntitiesAction = ActionsUnion<typeof AIAgentsEntitiesActions>;
