import { getConfig } from 'helpers/config';
import { logRequestError } from 'services/analytics/log-request-error';
import { getAccessToken, getRegion } from 'services/auth/auth-storage-manager';

import { createApiClient } from '../http/client';
import { type ApiClientInstance } from '../http/types';

export const createRestApiClient = (): ApiClientInstance => {
  return createApiClient({
    baseURL: getConfig().api,
    getCredentials: getAccessToken,
    getRegion,
    apiVersion: '2',
    onError: logRequestError,
  });
};
