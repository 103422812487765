import type { IApplicationCore } from 'entries/base/interfaces/application-core';

import { prepareDesktopAppEncapsulation } from '../desktop-app/desktop-app';
import { prepareExternalApi } from '../external-api/api';

let applicationCore: Partial<IApplicationCore> | null = null;

export function prepareApplicationCore(): void {
  const { getApi } = prepareExternalApi();
  /**
   * This temporary solution is to cover Electron desktop app case in which it tries to access `App.SmartClient` and `App.config` immediately after startup.
   * TODO: Update desktop app to wait for api to be ready, use `App.getApi().then((api) => api.desktopApp)` instead of `App.SmartClient`.
   */
  const { config, SmartClient } = prepareDesktopAppEncapsulation();

  const app = {
    getApi,
    config,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SmartClient,
  };

  applicationCore = app;

  window.App = app;
}

export function getApplicationCore(): Partial<IApplicationCore> {
  if (!applicationCore) {
    throw new Error('Application core is not initialized');
  }

  return applicationCore;
}
