// @ts-strict-ignore
import {
  type IAssignee,
  type ICc,
  type IEvent,
  type IRequester,
  type ITicket,
  type IAttachment,
} from 'interfaces/entities/ticket';
import { type IArchiveTicket } from 'store/entities/archives/interfaces';

export abstract class TicketSerializer {
  private static deserializeAssignee(assignee: { [key: string]: any }): IAssignee {
    if (assignee) {
      return {
        id: assignee.id,
        name: assignee.name,
      };
    }

    return null;
  }

  private static deserializeCCs(ccs: { mail: string }[]): ICc[] {
    return ccs.map((cc) => ({
      email: cc.mail,
    }));
  }

  private static deserializeEvents(events: { [key: string]: any }[]): IEvent[] {
    if (!events || !Array.isArray(events)) {
      return null;
    }

    return events.map((event) => ({
      date: new Date(event.date),
      isPrivate: event.is_private,
      type: event.type,
      ...(event.author != null && { author: event.author }),
      ...(event.message != null && { message: event.message }),
      ...(event.from != null && { from: event.from }),
      ...(event.to != null && { to: event.to }),
      ...(event.previous != null && { previous: event.previous }),
      ...(event.current != null && { current: event.current }),
      ...(event.attachments != null &&
        event.attachments.length > 0 && { attachments: TicketSerializer.deserializeAttachments(event.attachments) }),
    }));
  }

  static parseAttachmentUrl(url: string): string {
    if (/^https/.test(url) === false) {
      return `https://${url}`;
    }

    return url;
  }

  static deserializeAttachments(attachments: any[]): IAttachment[] {
    return attachments.map((attachment) => ({
      contentType: attachment.content_type,
      name: attachment.name,
      url: TicketSerializer.parseAttachmentUrl(attachment.url),
      ...(attachment.width && { width: attachment.width }),
      ...(attachment.height && { height: attachment.height }),
    }));
  }

  static deserializeRequester(requester: { [key: string]: any }): IRequester {
    return {
      ...(requester.name && { name: requester.name }),
      email: requester.mail,
      ip: requester.ip,
      ...(requester.recent_tickets &&
        requester.recent_tickets.length > 0 && { recentTickets: requester.recent_tickets }),
    };
  }

  static deserialize(ticket: { [key: string]: any }): ITicket {
    return {
      assignee: TicketSerializer.deserializeAssignee(ticket.assignee),
      ...(ticket.ccs != null && ticket.ccs.length > 0 && { ccs: TicketSerializer.deserializeCCs(ticket.ccs) }),
      events: TicketSerializer.deserializeEvents(ticket.events),
      id: ticket.id,
      requester: TicketSerializer.deserializeRequester(ticket.requester),
      groups: ticket.groups.filter((item) => item && item.id !== null), // HOTFIX for blank groups -> change it after API fix
      status: ticket.status,
      subject: ticket.subject,
      modified: new Date(ticket.modified),
      source: ticket.source,
      tags: ticket.tags,
      date: new Date(ticket.date),
      currentGroup: ticket.currentGroup,
      isSample: ticket.is_sample,
    };
  }

  static deserializeArchiveTicket(ticket: any): IArchiveTicket {
    const { id, status, subject } = ticket;

    return {
      id,
      status,
      subject,
    };
  }

  static deserializeCollection(tickets: any[]): ITicket[] {
    return tickets.map((ticket) => TicketSerializer.deserialize(ticket));
  }
}
