import { ActiveExperiment } from 'constants/active-experiment';
import { Feature } from 'constants/feature';
import { Right } from 'constants/right';
import { ChannelsNavigationItemsWithoutOverview } from 'constants/settings/channels-navigation-items';
import { SettingsNavigationItemId } from 'constants/settings/navigation-item-id';
import type { ISideNavigationItem } from 'interfaces/side-navigation-item';

// TODO: STORE IMPORTS CLEANUP - because of custom checks that require access to the store it should be moved to selectors
// If you update path in any of items below please update them also in source/tsx/store/entities/one/constants.ts
export const NavigationItems: ISideNavigationItem[] = [
  {
    id: SettingsNavigationItemId.Channels,
    name: 'Channels',
    children: ChannelsNavigationItemsWithoutOverview,
  },
  {
    id: SettingsNavigationItemId.ChatPage,
    name: 'Chat page',
    path: '/settings/chat-page',
  },
  {
    id: SettingsNavigationItemId.ChatWindow,
    name: 'Website widget',
    children: [
      {
        id: SettingsNavigationItemId.Theme,
        name: 'Customization',
        path: '/settings/theme',
        right: Right.ThemeManagement,
      },
      {
        id: SettingsNavigationItemId.Language,
        name: 'Language',
        path: '/settings/language',
        right: Right.LanguagesManagement,
      },
      {
        id: SettingsNavigationItemId.Availability,
        name: 'Availability',
        path: '/settings/availability',
        right: Right.MessagingModeManagement,
      },
      {
        id: SettingsNavigationItemId.WelcomeScreen,
        name: 'Welcome screen',
        path: '/settings/welcome-screen',
        right: Right.ChatBoostersAccess,
      },
    ],
  },
  {
    id: SettingsNavigationItemId.Forms,
    name: 'Forms',
    children: [
      {
        id: SettingsNavigationItemId.PreChatForm,
        name: 'Pre-chat form',
        path: '/settings/pre-chat-form',
        right: Right.ChatSurveyManagement,
      },
      {
        id: SettingsNavigationItemId.AskforEmail,
        name: 'Ask for email',
        path: '/settings/ask-for-email',
        right: Right.ChatFormsManagement,
      },
      {
        id: SettingsNavigationItemId.PostChatForm,
        name: 'Post-chat form',
        path: '/settings/post-chat-form',
        right: Right.ChatSurveyManagement,
      },
      {
        id: SettingsNavigationItemId.TicketForm,
        name: 'Ticket form',
        path: '/settings/ticket-form',
        right: Right.ChatFormsManagement,
      },
    ],
  },
  {
    id: SettingsNavigationItemId.Engagement,
    name: 'Engagement',
    onItemHover: () => {
      void Promise.allSettled([
        /* eslint-disable import/no-restricted-paths */
        import(/* webpackChunkName: "chat-buttons" */ 'routes/settings/engagement/chat-buttons/ChatButtonsRouter'),
        import(/* webpackChunkName: "quality-badge" */ 'routes/settings/engagement/quality-badge/QualityPageRouter'),
        /* eslint-enable import/no-restricted-paths */
      ]);
    },
    children: [
      {
        id: SettingsNavigationItemId.EyeCatcher,
        name: 'Eye-catcher',
        path: '/settings/eye-catcher',
        right: Right.EyeCatcherManagement,
      },
      {
        id: SettingsNavigationItemId.ChatButtons,
        name: 'Chat buttons',
        path: '/settings/chat-buttons',
        featureAccess: Feature.ChatButtons,
        right: Right.ChatButtonsManagement,
      },
      {
        id: SettingsNavigationItemId.QualityBadge,
        name: 'Quality showcase',
        path: '/settings/quality-badge',
        right: Right.QualityPageManagement,
      },
    ],
  },

  {
    id: SettingsNavigationItemId.CannedResponses,
    name: 'Canned responses',
    children: [
      {
        id: SettingsNavigationItemId.ResponsesList,
        name: 'Response list',
        path: '/settings/canned-responses',
        right: Right.CannedResponsesAccess,
      },
      {
        id: SettingsNavigationItemId.SuggestedResponses,
        name: 'Suggested responses',
        path: '/settings/suggested-responses',
        right: Right.CannedResponsesAccess,
      },
    ],
  },
  {
    id: SettingsNavigationItemId.Tags,
    name: 'Tags',
    path: '/settings/tags',
    featureAccess: Feature.Tags,
    right: Right.TagsAccess,
  },
  {
    id: SettingsNavigationItemId.SalesTracker,
    name: 'Sales tracker',
    path: '/settings/sales-tracker',
    featureAccess: Feature.SalesTracker,
    right: Right.SalesTrackerManagement,
  },
  {
    id: SettingsNavigationItemId.ChatSettings,
    name: 'Chat settings',
    children: [
      {
        id: SettingsNavigationItemId.ChatAssignment,
        name: 'Chat assignment',
        path: '/settings/chats-assignment',
        right: Right.ChatRoutingManagement,
      },
      {
        id: SettingsNavigationItemId.AutoAccessRules,
        name: 'Routing rules',
        path: '/settings/groups-routing',
        right: Right.AutoAccessRulesManagement,
      },
      {
        id: SettingsNavigationItemId.ArchivesForwarding,
        name: 'Transcript forwarding',
        path: '/settings/archives-forwarding',
        right: Right.TranscriptForwardingManagement,
      },
      {
        id: SettingsNavigationItemId.FileSharing,
        name: 'File sharing',
        path: '/settings/file-sharing',
        featureAccess: Feature.ChatFileSharing,
        right: Right.FileSharingManagement,
      },
      {
        id: SettingsNavigationItemId.InactivityTimeouts,
        name: 'Inactivity timeouts',
        path: '/settings/inactivity-timeouts',
        featureAccess: Feature.AgentsInactivityTimeouts,
        right: Right.InactivityTimeoutsManagement,
      },
    ],
  },
  {
    id: SettingsNavigationItemId.TicketSettings,
    name: 'Ticket settings',
    experiment: {
      in: false,
      name: ActiveExperiment.DisableNativeTickets,
    },
    children: [
      {
        id: SettingsNavigationItemId.AutomatedEmails,
        name: 'Email notifications',
        path: '/settings/automated-emails',
        right: Right.EmailNotificationsManagement,
      },
    ],
  },
  {
    id: SettingsNavigationItemId.Security,
    name: 'Security',
    children: [
      {
        id: SettingsNavigationItemId.TrustedDomains,
        name: 'Trusted domains',
        path: '/settings/trusted-domains',
        right: Right.TrustedDomainsManagement,
      },
      {
        id: SettingsNavigationItemId.BannedCustomers,
        name: 'Banned customers',
        path: '/settings/banned-customers',
        right: Right.BanningVisitorsManagement,
      },
      {
        id: SettingsNavigationItemId.AccessRestriction,
        name: 'Access restrictions',
        path: '/settings/access-restriction',
        featureAccess: Feature.AgentsAccessRestriction,
        right: Right.AccessRestrictionManagement,
      },
      {
        id: SettingsNavigationItemId.CreditCardsMasking,
        name: 'Credit card masking',
        path: '/settings/credit-cards-masking',
        right: Right.CreditCardMaskingManagement,
      },
      {
        id: SettingsNavigationItemId.LoginSettings,
        name: 'Login settings',
        path: '/settings/login-settings',
        right: Right.AgentsAuthenticationManagement,
      },
    ],
  },
];
