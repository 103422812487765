import { type CannedResponseAction, CannedResponseActionNames } from './actions';
import {
  getStateForSetData,
  getStateForAddCannedResponse,
  getStateForRemoveCannedResponse,
  getStateForUpdateOldCannedResponse,
  getStateForUpdateAutotags,
  getStateForFetchAutotagsSuccess,
  getStateForRemoveCannedResponseAutotags,
  getStateForAutotagRemove,
} from './helpers/reducer';
import { type ICannedResponsesState } from './interfaces';

const initialState: ICannedResponsesState = {
  allIds: [],
  byIds: {},
  idsByTags: {},
  autotags: {},
};

export function cannedResponsesReducer(
  state: ICannedResponsesState = initialState,
  action: CannedResponseAction
): ICannedResponsesState {
  switch (action.type) {
    case CannedResponseActionNames.ADD_CANNED_RESPONSE_DATA:
      return getStateForAddCannedResponse(state, action.payload);
    case CannedResponseActionNames.FETCH_CANNED_RESPONSES_SUCCESS:
      return getStateForSetData(state, action.payload);
    case CannedResponseActionNames.REMOVE_CANNED_RESPONSE_DATA:
      return getStateForRemoveCannedResponse(state, action.payload);
    case CannedResponseActionNames.UPDATE_CANNED_RESPONSE_DATA:
      return getStateForUpdateOldCannedResponse(state, action.payload);
    case CannedResponseActionNames.CREATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS:
      return getStateForUpdateAutotags(state, action.payload);
    case CannedResponseActionNames.UPDATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS:
      return getStateForUpdateAutotags(state, action.payload);

    case CannedResponseActionNames.FETCH_CANNED_RESPONSE_AUTOTAGS_SUCCESS:
    case CannedResponseActionNames.CANNED_RESPONSE_AUTOTAGS_SET_DATA:
      return getStateForFetchAutotagsSuccess(state, action.payload);
    case CannedResponseActionNames.REMOVE_CANNED_RESPONSE_AUTOTAGS_SUCCESS:
      return getStateForRemoveCannedResponseAutotags(state, action.payload);
    case CannedResponseActionNames.REMOVE_AUTOTAG_FROM_CANNED_RESPONSE_SUCCESS:
      return getStateForAutotagRemove(state, action.payload);
    default:
      return state;
  }
}
