// @ts-strict-ignore
import {
  FacebookMessengerCustomVariableProperty,
  FacebookMessengerProperty,
  FACEBOOK_MESSENGER_PROPERTY_LABELS,
} from 'constants/integrations/facebook-messenger';
import { type KeyMap } from 'helpers/interface';
import { type ISectionData, type CustomVariable } from 'interfaces/customer-details';

export function getAvatarPath(data: ISectionData): string {
  if (data.facebookVariables && data.facebookVariables.length) {
    const profilePicture = data.facebookVariables.find(
      (item) => item.name === FACEBOOK_MESSENGER_PROPERTY_LABELS[FacebookMessengerProperty.ProfilePicture]
    );
    if (profilePicture) {
      return profilePicture.value;
    }
  }

  return data.avatarPath;
}

export function skipFacebookVariables(customVariables: CustomVariable[]): CustomVariable[] {
  if (!customVariables || !customVariables.length) {
    return null;
  }

  const skippedFacebookVariables = customVariables.filter(
    (item) => !item.name.startsWith(FacebookMessengerCustomVariableProperty.Prefix)
  );

  if (!skippedFacebookVariables.length) {
    return null;
  }

  return skippedFacebookVariables;
}

export function skipCustomVariables(customVariables: KeyMap<string>, variablePrefix: string): KeyMap<string> {
  const skippedFacebookVariables = Object.keys(customVariables)
    .filter((key) => !key.startsWith(variablePrefix))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: customVariables[key],
      }),
      {}
    );

  return skippedFacebookVariables;
}
