export enum SectionName {
  HomeScreen = 'homeScreen',
  Chats = 'chats',
  Customers = 'customers',
  Archives = 'archives',
  Tickets = 'tickets',
  Agents = 'agents',
  Reports = 'reports',
  Marketplace = 'marketplace',
  Subscription = 'subscription',
  Settings = 'settings',
}
