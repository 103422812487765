import cloneDeep from 'lodash.clonedeep';

export interface IRequestEvent {
  name: string;
  request: any;
  response: IRequestResult;
}

interface IRequestResult {
  result?: Response;
  error?: any;
}

const maxStackSize = 30;
// Maybe we can (in the future) keep those lists in localstorage so that it can be customized when needed, without code modification
// Request that we want to track but without huge response body
const eventsToSlimDown = ['GET banlist', 'GET canned_responses'];
// Requests that are highly not likely to be needed for debugging but would consume tracked requests limit
const eventsToDeny = ['GET website/last_activity'];

export const eventsStack: IRequestEvent[] = [];

export const sanitizeAndSlimDownEvent = (event: IRequestEvent): IRequestEvent => {
  const sanitizedEvent: IRequestEvent = cloneDeep(event);
  const hasAuthorizationHeader =
    sanitizedEvent.request && sanitizedEvent.request.headers && sanitizedEvent.request.headers.Authorization;
  if (hasAuthorizationHeader) {
    sanitizedEvent.request.headers.Authorization = '';
  }

  const shouldRemoveResponse = eventsToSlimDown.includes(event.name);
  if (shouldRemoveResponse) {
    sanitizedEvent.response = {};
  }

  return sanitizedEvent;
};

export const addRequestEvent = (event: IRequestEvent): void => {
  const shouldStoreEvent = !eventsToDeny.includes(event.name);
  if (shouldStoreEvent) {
    if (eventsStack.length >= maxStackSize) {
      eventsStack.shift();
    }
    eventsStack.push(sanitizeAndSlimDownEvent(event));
  }
};
