import { createBrowserHistory, type History, type Path } from 'history';

import { getAppRoot, parseQueryParams, type IKeyValue } from 'helpers/url';

export interface IBrowserHistory extends History {}

interface LocationState {
  errorMessage?: string;
  from?: string;
}

const appendBaseName = (
  basename: string,
  to: string | Partial<Path>,
  callback: (to: string | Partial<Path>) => void
): void => {
  const path = typeof to === 'string' ? to : to.pathname ?? '';

  // navigation events invoked by <Navigate /> components already contain basename
  const pathname = path.startsWith(basename) ? path : basename + path.replace(/^\//, '');

  if (typeof to === 'string') {
    return callback(pathname);
  }

  return callback({
    ...to,
    pathname,
  });
};

const createBrowserHistoryWithBasename = (basename = '/'): IBrowserHistory => {
  const history = createBrowserHistory();

  const push = history.push;
  const replace = history.replace;
  history.push = (to) => appendBaseName(basename, to, push);
  history.replace = (to) => appendBaseName(basename, to, replace);

  return history;
};

class BrowserHistory {
  private browserHistory: IBrowserHistory;
  private locationEverChanged = false;

  constructor() {
    this.browserHistory = createBrowserHistoryWithBasename(getAppRoot());
    this.browserHistory.listen(() => {
      // this listener is necessary
      // without it, `browserHistory` will be somehow cleaned up
      // and the `react-router`'s history will not work properly

      this.locationEverChanged = true;
    });
  }

  get browserHistoryObject(): IBrowserHistory {
    return this.browserHistory;
  }

  get hasLocationEverChanged(): boolean {
    return this.locationEverChanged;
  }

  get queryString(): string {
    return (this.browserHistory.location.search || '').replace('?', '');
  }

  get queryParams(): IKeyValue<string> {
    return parseQueryParams(this.queryString);
  }

  get pathname(): string {
    return this.browserHistory.location.pathname;
  }

  push = (pathname: string, search?: string): void => {
    this.browserHistory.push({
      pathname,
      search,
    });
  };

  pushWithState = (pathname: string, state: LocationState): void => {
    this.browserHistory.push(pathname, state);
  };

  pushQueryString = (search: string): void => {
    this.browserHistory.push({
      ...this.browserHistory.location,
      search,
    });
  };

  replaceQueryString = (search: string): void => {
    this.browserHistory.replace({
      ...this.browserHistory.location,
      search,
    });
  };

  replace = (pathname: string, search: string): void => {
    this.browserHistory.replace({
      pathname,
      search,
    });
  };

  replaceUrl = (url: string): void => {
    this.browserHistory.replace(url);
  };

  getState = (): LocationState => {
    return this.browserHistory.location.state as LocationState;
  };
}

export const browserHistory = new BrowserHistory();
