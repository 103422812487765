import { useState } from 'react';

import { useEffectOnce } from './use-effect-once';

interface ICustomScreen {
  isCustomScreen: boolean;
}

interface ICustomSizes {
  maxWidth?: number;
  minWidth?: number;
}

function createMediaQueryList(sizes: ICustomSizes): MediaQueryList {
  const minSize = sizes.minWidth ? `(min-width: ${sizes.minWidth}px)` : null;
  const maxSize = sizes.maxWidth ? `(max-width: ${sizes.maxWidth}px)` : null;

  const mediaQuery = [minSize, maxSize].filter(Boolean).join(' and ');

  return window.matchMedia(mediaQuery);
}

/**
 * To be used reasonably! Most of the time you simply want to use styling, for example:
 * `@media (max-width: ${SOME_BREAKPOINT}px) {}`
 * This hook provides access to the screen size breakpoints when styling can't be applied.
 * Consider using `useOneColumnLayout` for default layouts
 */
export function useScreenSize(customSizes: ICustomSizes): ICustomScreen {
  const [customScreen, setCustomScreen] = useState<ICustomScreen>({
    isCustomScreen: false,
  });

  useEffectOnce(() => {
    const mediaQueryCustomScreen = createMediaQueryList(customSizes);

    const recalculateSizes = (): void => {
      setCustomScreen({
        isCustomScreen: mediaQueryCustomScreen.matches,
      });
    };

    mediaQueryCustomScreen.addEventListener('change', recalculateSizes);
    recalculateSizes();

    return () => {
      mediaQueryCustomScreen.removeEventListener('change', recalculateSizes);
    };
  });

  return customScreen;
}
