// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';

import { getConfig } from 'helpers/config';
import { updateSessionVariables } from 'services/chat-widget';
import { normalizeError } from 'services/connectivity/configuration-api/helpers';
import { propertiesClient } from 'services/connectivity/configuration-api/properties/client';
import {
  type UpdateLicensePropertiesRequest,
  type UpdateLicensePropertiesResponse,
  type ListLicensePropertiesResponse,
} from 'services/connectivity/configuration-api/properties/types';
import { type ConfigurationApiResponse } from 'services/connectivity/configuration-api/types';
import { type IActionWithPayload } from 'store/helper';

import { IntegrationLicensePropertiesActions, IntegrationLicensePropertiesActionNames } from './actions';
import {
  type IUpdateLicensePropertiesPayload,
  type IFetchForNamespacePayload,
  type IFetchLicensePropertiesSuccessPayload,
  IntegrationLicensePropertiesNames,
} from './interfaces';
import { getIntegrationLicenseProperties } from './selectors';

function* fetchLicenseProperties(): SagaIterator {
  try {
    const { error, result }: ConfigurationApiResponse<ListLicensePropertiesResponse> = yield call(
      propertiesClient.listLicense,
      {},
    );

    if (error) {
      const normalizedError = normalizeError(error);
      throw normalizedError;
    }

    yield put(
      IntegrationLicensePropertiesActions.fetchSuccess(result as unknown as IFetchLicensePropertiesSuccessPayload),
    );
  } catch ({ message }) {
    IntegrationLicensePropertiesActions.fetchFailure({ error: message as string });
  }
}

export function* updateProperties({
  payload,
}: IActionWithPayload<string, IUpdateLicensePropertiesPayload>): SagaIterator {
  const currentProperties = yield select(getIntegrationLicenseProperties);
  const config = getConfig();

  const result: ConfigurationApiResponse<UpdateLicensePropertiesResponse> = yield call(propertiesClient.updateLicense, {
    properties: payload.properties,
  } as UpdateLicensePropertiesRequest);

  if (!result?.error) {
    const update: IUpdateLicensePropertiesPayload = {
      properties: {
        ...currentProperties,
        ...payload.properties,
        [config.accountsClientId]: {
          ...currentProperties[config.accountsClientId],
          ...payload.properties[config.accountsClientId],
        },
      },
    };

    yield put(IntegrationLicensePropertiesActions.updateData(update.properties));
  }

  return result;
}

function* fetchIntegrationLicensePropertiesForNamespace({
  payload,
}: IActionWithPayload<string, IFetchForNamespacePayload>): SagaIterator {
  const { result, error }: ConfigurationApiResponse<ListLicensePropertiesResponse> = yield call(
    propertiesClient.listLicense,
    { namespace: payload.namespace },
  );

  if (error) {
    return;
  }

  yield put(IntegrationLicensePropertiesActions.fetchForNamespaceSuccess(result));
}

function fetchLicensePropertiesSuccess({
  payload,
}: IActionWithPayload<string, IFetchLicensePropertiesSuccessPayload>): void {
  const livechatProperties = payload[getConfig().accountsClientId];

  if (!livechatProperties || !livechatProperties[IntegrationLicensePropertiesNames.CampaignEcommerceAgentTrainer]) {
    return;
  }

  updateSessionVariables({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    og_campaign_ecommerce_agent_trainer: true,
  });
}

export function* integrationLicensePropertiesSagas(): SagaIterator {
  yield takeEvery(IntegrationLicensePropertiesActionNames.FETCH_REQUEST, fetchLicenseProperties);
  yield takeEvery(IntegrationLicensePropertiesActionNames.UPDATE_PROPERTIES, updateProperties);
  yield takeEvery(
    IntegrationLicensePropertiesActionNames.FETCH_SINGLE_NAMESPACE_REQUEST,
    fetchIntegrationLicensePropertiesForNamespace,
  );
  yield takeLatest(IntegrationLicensePropertiesActionNames.FETCH_SUCCESS, fetchLicensePropertiesSuccess);
}
