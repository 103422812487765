import { AgentsPerformanceColumn } from 'constants/reports/columns';
import { ReportType } from 'constants/reports/report-type';
import { type IReportColumns } from 'interfaces/reports';

export const REPORT_TOTAL_CHATS_SUBPATH = 'total';

export const enum ReportViewsEvent {
  SaveViewModalOpened = 'Report Views: Save view modal opened',
  SaveView = 'Report Views: Save view',
  ViewSaved = 'Report Views: View saved',
  ViewDeleted = 'Report Views: View deleted',
  ViewSelected = 'Report Views: View selected',
}

export const DEFAULT_COLUMNS_ORDER: IReportColumns = {
  [ReportType.AgentsPerformance]: [
    AgentsPerformanceColumn.Agent,
    AgentsPerformanceColumn.TotalChats,
    AgentsPerformanceColumn.PercentageofAllChats,
    AgentsPerformanceColumn.Satisfaction,
    AgentsPerformanceColumn.FirstResponseTime,
    AgentsPerformanceColumn.AcceptingChatsTime,
    AgentsPerformanceColumn.NotAcceptingChatsTime,
    AgentsPerformanceColumn.LoggedInTime,
    AgentsPerformanceColumn.ChattingTime,
    AgentsPerformanceColumn.Efficiency,
    AgentsPerformanceColumn.ChatLimit,
  ],
};

export const DEFAULT_VISIBLE_COLUMNS: IReportColumns = {
  [ReportType.AgentsPerformance]: [
    AgentsPerformanceColumn.Agent,
    AgentsPerformanceColumn.TotalChats,
    AgentsPerformanceColumn.Satisfaction,
    AgentsPerformanceColumn.FirstResponseTime,
    AgentsPerformanceColumn.ChattingTime,
    AgentsPerformanceColumn.Efficiency,
    AgentsPerformanceColumn.ChatLimit,
  ],
};
