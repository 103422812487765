// @ts-strict-ignore
import { type CompanyDetailsActionTypes, CompanyDetailsActionNames } from './actions';
import { type ICompanyDetailsState } from './interfaces';

const initialState: ICompanyDetailsState = {
  companyDetails: null,
};

export const companyDetailsReducer = (
  state: ICompanyDetailsState = initialState,
  action: CompanyDetailsActionTypes
): ICompanyDetailsState => {
  switch (action.type) {
    case CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS: {
      return {
        ...state,
        companyDetails: action.payload,
      };
    }

    case CompanyDetailsActionNames.UPDATE_COMPANY_DETAILS_SUCCESS: {
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};
