import { deleteAllCookiesExceptRoot } from 'helpers/cookies';
import { uniqueId } from 'helpers/string';
import { loadAppFont } from 'services/app-font-loader';
import { initializeDesktopApp } from 'services/desktop-application/desktop-app';
import { historyService } from 'services/history';
import { saveLog } from 'services/save-log';

export function initializeBase(): void {
  initializeDesktopApp();

  saveLog('info', 'Bonjour, le monde!');

  historyService.setErrorLogger((message: string) => {
    saveLog('error', message);
  });

  deleteAllCookiesExceptRoot();
  loadAppFont();

  // generate random string as ID of current instance of application
  window.instanceID = uniqueId();
}
