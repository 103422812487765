// @ts-strict-ignore
import { getConfig } from 'helpers/config';

declare const gapi: IGoogleApi;

interface IGoogleAuth {
  signIn(): Promise<void>;
  signOut(): void;
  isSignedIn: { get(): boolean };
}

interface IRequestConfig {
  path: string;
  method: string;
  body?: any;
}

interface IGoogleApi {
  load(apiName: string, callback: () => void): void;
  client: {
    init(config: { apiKey: string; clientId: string; scope: string }): Promise<void>;
    request<ResultType>(urlOrConfig: string | IRequestConfig): Promise<{ result: ResultType }>;
  };
  auth2: {
    getAuthInstance(): IGoogleAuth;
  };
}

interface IGTMUserItem {
  key: string;
  props: {
    id: string;
    name: string;
  };
}

export interface IGoogleTagManagerAPI {
  initGapi(): Promise<void>;
  isInitialized(): boolean;
  getLoginStatus(): boolean;
  signIn(): Promise<void>;
  signOut(): void;
  getUserAccounts(): Promise<IGTMUserItem[]>;
  getUserContainers(accountId: string): Promise<IGTMUserItem[]>;
  handleSetTag(accountId: string, containerId: string, license?: string): Promise<void>;
}

export class GoogleTagManagerAPI implements IGoogleTagManagerAPI {
  apiUrl = 'https://content.googleapis.com/tagmanager/v1';
  api: IGoogleApi;
  apiKey: string;
  apiLoaded = false;

  private static singleInstance: IGoogleTagManagerAPI;

  static instance(): IGoogleTagManagerAPI {
    if (!this.singleInstance) {
      const apiKey: string = getConfig().googleTagManagerApiKey;
      this.singleInstance = new GoogleTagManagerAPI(apiKey);
    }

    return this.singleInstance;
  }

  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  private async loadScript() {
    await new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;

      document.body.appendChild(script);
    });

    this.api = gapi;
  }

  async initGapi(): Promise<void> {
    await this.loadScript();

    return new Promise((resolve, reject) => {
      const clientId = getConfig().googleTagManagerClientId;

      const scope = [
        'https://www.googleapis.com/auth/tagmanager.manage.accounts',
        'https://www.googleapis.com/auth/tagmanager.edit.containers',
        'https://www.googleapis.com/auth/tagmanager.edit.containerversions',
        'https://www.googleapis.com/auth/tagmanager.publish',
      ].join(' ');

      this.api.load('client:auth2', () => {
        void this.api.client
          .init({
            apiKey: this.apiKey,
            clientId,
            scope,
          })
          .then(() => {
            this.apiLoaded = true;
          })
          .catch(reject)
          .then(resolve);
      });
    });
  }

  private auth(): IGoogleAuth {
    return this.api.auth2.getAuthInstance();
  }

  isInitialized(): boolean {
    return this.apiLoaded;
  }

  signIn(): Promise<void> {
    return this.auth().signIn();
  }

  signOut(): void {
    this.auth().signOut();
  }

  getLoginStatus(): boolean {
    return this.auth().isSignedIn.get();
  }

  getUserContainers(accountId: string): Promise<IGTMUserItem[]> {
    return this.api.client
      .request<{ containers: any[] }>(`${this.apiUrl}/accounts/${accountId}/containers`)
      .then((data): IGTMUserItem[] =>
        data.result.containers.map((c) => ({ key: c.containerId, props: { id: c.containerId, name: c.name } }))
      );
  }

  getUserAccounts(): Promise<IGTMUserItem[]> {
    return this.api.client
      .request<{ accounts: any[] }>(`${this.apiUrl}/accounts`)
      .then((data): IGTMUserItem[] =>
        data.result.accounts.map((acc) => ({ key: acc.accountId, props: { id: acc.accountId, name: acc.name } }))
      );
  }

  async handleSetTag(accountId: string, containerId: string, trackingCode: string): Promise<void> {
    await this.api.client.request({
      path: `${this.apiUrl}/accounts/${accountId}/containers/${containerId}/tags`,
      method: 'POST',
      body: {
        name: 'LiveChat widget code',
        type: 'html',
        firingTriggerId: ['2147479553'],
        parameter: [
          {
            type: 'template',
            key: 'html',
            value: trackingCode,
          },
          { type: 'boolean', key: 'supportDocumentWrite', value: 'false' },
        ],
        tagFiringOption: 'oncePerEvent',
      },
    });

    const { result } = await this.api.client.request<{ containerVersion: { containerVersionId: string } }>({
      path: `${this.apiUrl}/accounts/${accountId}/containers/${containerId}/versions`,
      method: 'POST',
      body: {
        name: 'LiveChat tracking code published',
        notes:
          "Thanks for using LiveChat's and GTM integration. Your own live chat should be already visible on your website, awesome! If you have any questions or need help, chat live with our Support Heroes at https://www.livechat.com/contact or email us at support@livechatinc.com. Good luck!",
      },
    });

    const versionId = result.containerVersion.containerVersionId;

    await this.api.client.request({
      path: `${this.apiUrl}/accounts/${accountId}/containers/${containerId}/versions/${versionId}/publish`,
      method: 'POST',
    });
  }
}
