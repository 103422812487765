import { css } from '@emotion/css';
import { DesignToken, SpacingToken, RadiusToken } from '@livechat/design-system-react-components';

export const addSourceContent = css`
  background-color: var(${DesignToken.ContentLockedWhite});
  color: var(${DesignToken.ContentLockedDefault});
  padding: var(${SpacingToken.Spacing2});
  .add-source {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: var(${SpacingToken.Spacing2});
    padding: 9px var(${SpacingToken.Spacing2});
    cursor: pointer;
    border-radius: var(${RadiusToken.Radius2});
    &:hover {
      background-color: #ececec;
    }
  }
`;

export const addSourceButton = css`
  white-space: nowrap;
`;
