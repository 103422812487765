// @ts-strict-ignore
import { PureComponent, type MouseEvent } from 'react';

import { type IconSource, Icon, NavigationItem } from '@livechat/design-system-react-components';

import type { BadgeValue } from 'components/new-navigation/hooks/use-badge-counter';

interface IProps {
  icon: IconSource;
  activeIcon: IconSource;
  title: string;
  path: string;
  active: boolean;
  badgeContent?: BadgeValue;
  onItemClick?(path?: string);
}

export class MobileNavigationItem extends PureComponent<IProps> {
  handleOnClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    if (this.props.onItemClick && this.props.path) {
      this.props.onItemClick(this.props.path);
    } else if (this.props.onItemClick) {
      this.props.onItemClick();
    }
  };

  render(): JSX.Element {
    const { icon, activeIcon, title, active, badgeContent } = this.props;

    return (
      <NavigationItem
        id={title}
        label={title}
        isActive={active}
        icon={<Icon source={active ? activeIcon : icon} size="large" />}
        badge={badgeContent || null}
        onClick={this.handleOnClick}
      />
    );
  }
}
