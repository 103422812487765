export const MAX_TARGETED_MESSAGES_NUMBER = 5;
export const MIN_TARGETED_MESSAGES_NUMBER = 1;

export const enum TargetedMessagesTypePath {
  Greetings = 'recurring',
  Announcements = 'one-time',
}

export const enum TargetedMessagesRoutes {
  Base = '/engage/campaigns',
  SelectedType = '/engage/campaigns/:targetedMessageType',
  Add = '/engage/campaigns/:targetedMessageType/add',
  Edit = '/engage/campaigns/:targetedMessageType/:targetedMessageId',
  TemplatesList = '/engage/campaigns/:targetedMessageType/templates',
}
