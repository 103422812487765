export enum ReasonCode {
  NoSkills = 10001,
  NoKnowledge = 10002,
  NoAgents = 10003,
  SkillCancelled = 10004,
  SkillNotFound = 10005,
  SkillNotLearning = 10006,
  UnwantedStatusCode = 10007,
  UnspecifiedError = 10008,
  MaxSkillsLimitReached = 10009,
}
