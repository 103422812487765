import { isRequest } from './is-request';

interface WithMessage {
  message: string;
}

interface WithRequestId {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  request_id?: string;
}

interface WithLocal {
  local?: {
    request?: Request;
  };
}

interface WithRequest {
  request?: Request;
}

function parseRequest(request: Request): { message: string; endpoint: string; method: string } {
  return {
    message: 'Request failed',
    endpoint: request.url,
    method: request.method,
  };
}

export function errorToString(error: unknown): string {
  if (typeof error === 'string') {
    return `A string error: ${error}`;
  }

  if (error === undefined) {
    return 'An undefined error';
  }

  if (error === null) {
    return 'A null error';
  }

  const withMessage = error as WithMessage;
  if (withMessage?.message) {
    return withMessage.message;
  }

  if (Array.isArray(error)) {
    return JSON.stringify(error);
  }

  if (isRequest(error)) {
    return JSON.stringify(parseRequest(error));
  }

  const { request_id: requestId, ...errorWithoutRequestId } = error as WithRequestId;

  const { local } = errorWithoutRequestId as WithLocal;

  if (local) {
    if (isRequest(local)) {
      errorWithoutRequestId['local'] = parseRequest(local);
    } else if (isRequest(local.request)) {
      errorWithoutRequestId['local']['request'] = parseRequest(local.request);
    }
  }

  const { request } = errorWithoutRequestId as WithRequest;

  if (request && isRequest(request)) {
    errorWithoutRequestId['request'] = parseRequest(request);
  }

  return JSON.stringify(errorWithoutRequestId);
}
