// @ts-strict-ignore
import { ChatType } from 'constants/chat-type';
import { type ChatResultType } from 'services/api/chat/interfaces';
import { isChattingAgent, isChatSummaryResult } from 'services/api/interfaces/chat/helpers';

import { isContinuousChatThread } from './common';
import { isChatSupervisedByAgent } from './is-chat-supervised-by-agent';

export function getChatType(data: ChatResultType, currentAgentId: string): ChatType {
  const thread = isChatSummaryResult(data) ? data.last_thread_summary : data.thread;

  // There are cases where there is no `last_thread_summary` nor `thread` e.g removed threads from chat
  if (!thread) {
    return null;
  }

  const isActive = thread.active;

  const chattingAgent = data.users.find(isChattingAgent);

  if (!chattingAgent) {
    const isContinuous = isContinuousChatThread(data.properties);

    if (isContinuous) {
      const isQueuedThread = Boolean(thread.queue?.position && thread.queue?.wait_time) && isActive;

      if (isQueuedThread) {
        return ChatType.Queued;
      }

      return ChatType.Unassigned;
    }

    return ChatType.Queued;
  }

  if (chattingAgent.id === currentAgentId) {
    return ChatType.My;
  }

  if (isChatSupervisedByAgent(data, currentAgentId)) {
    return ChatType.Supervised;
  }

  return ChatType.Other;
}
