import { useCallback, type FC } from 'react';

import { Notifications } from '@livechat/design-system-icons';
import { Icon, NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { Cookie } from 'constants/cookies';
import { GlobalModal } from 'constants/global-modal';
import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { setCookie } from 'helpers/cookies';
import { trackEvent } from 'services/event-tracking';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

interface IProps {
  onSkip(): void;
  isVisible: boolean;
}

const COOKIE_DAYS_EXPIRATION = 5;

export const UnblockNotificationsBar: FC<IProps> = ({ onSkip, isVisible }) => {
  const dispatch = useDispatch();

  const handleUnblockNotificationsBtnClick = (): void => {
    trackEvent('Unblock notifications button clicked', EventPlace.TopBar, {
      topBar: TopBarNotificationType.UnblockNotifications,
    });
    dispatch(GlobalModalActions.showModal(GlobalModal.Notifications));
    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.BrowserNotifications));
  };

  const handleSkip = useCallback(() => {
    setCookie(Cookie.UnblockNotificationsBarSkipped, 1, { days: COOKIE_DAYS_EXPIRATION });
    onSkip();
  }, [onSkip]);

  return (
    <NavigationTopBarAlert
      kind="info"
      isVisible={isVisible}
      primaryCta={{
        label: 'Unblock browser notifications',
        onClick: handleUnblockNotificationsBtnClick,
        size: 'compact',
      }}
      closeButton={{
        onClick: handleSkip,
        'aria-label': 'Close the prompt about blocked notifications', // eslint-disable-line @typescript-eslint/naming-convention
      }}
    >
      <Icon source={Notifications} />
      <Text bold>We strongly recommend unblocking notifications so you don’t miss chats!</Text>
    </NavigationTopBarAlert>
  );
};
