import type { IAppState } from 'services/app-state-provider';
import { deserializeVisitedPages } from 'services/socket-lc3/chat/serialization/deserialize-customer-visited-pages';
import { CustomerActions } from 'store/entities/customers/actions';

import { type IIncomingCustomersPushEvent } from '../interfaces';
import { deserializeIncomingCustomerPushEvent } from '../serialization/deserialize';

export const handleIncomingCustomers = (payload: IIncomingCustomersPushEvent, store: IAppState): void => {
  const customersData = payload.customers.reduce(
    (acc, customer) => {
      acc.customers[customer.id] = deserializeIncomingCustomerPushEvent(customer);
      acc.visitedPages[customer.id] = deserializeVisitedPages(customer.last_visit);

      return acc;
    },
    { customers: {}, visitedPages: {} }
  );

  store.dispatch(CustomerActions.addOrUpdateCustomers(customersData));
};
