/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// @ts-strict-ignore
import { ChatEventType } from 'constants/chat-event-type';
import { SurveyType } from 'constants/survey-type';
import { toKeyMap } from 'helpers/array';
import { type KeyMap } from 'helpers/interface';
import {
  deserializeSystemMessage,
  deserializeChatMessage,
  deserializeAttachment,
  deserializeSurvey,
  deserializeHistoryAttachment,
} from 'services/socket-lc2/chat/serialization/deserialize-event';
import {
  type ChatEventEntity,
  type IMessage,
  type IRichMessage,
  type ISystemMessage,
  type ISurveyMessage,
  type IAttachmentMessage,
} from 'store/entities/chats/interfaces';

import { getThreadEntityTimestampInMs } from './timestamp';

export function deserializeChatEventCollection(
  data: any,
  currentAgentName: string,
  isHistory = false
): KeyMap<ChatEventEntity> {
  const customerId = data.visitor_id;
  const messages: KeyMap<IMessage | IRichMessage> = toKeyMap(
    data.messages.map((item) => deserializeChatMessage(item, customerId)),
    'id'
  );
  const systemMessages: KeyMap<ISystemMessage> = toKeyMap(
    data.events
      .filter((event) => event.type === ChatEventType.Event)
      .map((event) => deserializeSystemMessage(event, currentAgentName)),
    'id'
  );

  const attachments: KeyMap<IAttachmentMessage> = isHistory
    ? toKeyMap(
        data.events
          .filter((event) => event.type === ChatEventType.Attachmment)
          .map((event) => deserializeHistoryAttachment(event)),
        'id'
      )
    : toKeyMap((data.attachments || []).map(deserializeAttachment), 'id');

  const timestamp = data.messages && data.messages[0] ? getThreadEntityTimestampInMs(data.messages[0]) : null;

  const prechatSurvey: ISurveyMessage = deserializeSurvey(data.prechat_survey, customerId, SurveyType.Pre, timestamp);

  return {
    ...(prechatSurvey && { [prechatSurvey.id]: prechatSurvey }),
    ...messages,
    ...systemMessages,
    ...attachments,
  };
}
