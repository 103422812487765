// @ts-strict-ignore
import { createSelector } from 'reselect';

import { AccountStatus } from 'constants/account-status';
import { LoginStatus } from 'constants/login-status';
import { type Permission } from 'constants/permission';
import { anyToBoolean } from 'helpers/boolean';
import type { Subset } from 'helpers/interface';
import { type GroupId } from 'interfaces/groups';
import { type Notification } from 'services/connectivity/configuration-api/agents/types';
import { sortByName } from 'store/helper';

import { isActivated, isAwaitingApproval, isAgent, isOwner } from './helpers';
import type { IAgent, IAgentsState, IEmailUpdates, Notifications } from './interfaces';

const EMPTY_ARRAY = [];

export interface WithAgentsState {
  entities: {
    agents: IAgentsState;
  };
}

type Agents = Record<string, IAgent>;

export function agentsSelector(state: WithAgentsState): Agents {
  return state.entities.agents.agents;
}

export const getAgents = createSelector(agentsSelector, (agents) => Object.values(agents).sort(sortByName));

// GLOBAL ACCOUNTS TODO: ROLE SCOPE CHECK
// GLOBAL ACCOUNTS TODO: NEED TO REFACTOR

export const getActiveAgents = createSelector(getAgents, (agents) =>
  agents.filter((account) => account.accountStatus === AccountStatus.Active),
);

export const getSuspendedAgents = createSelector(getAgents, (agents) =>
  agents.filter((account) => account.accountStatus === AccountStatus.Suspended),
);

export const getWaitingForApprovalAgents = createSelector(getAgents, (agents) =>
  agents.filter((account) => account.accountStatus === AccountStatus.AwaitingApproval),
);

export const getActivatedAccounts = createSelector(getActiveAgents, (agents) => agents.filter(isActivated));

// GLOBAL ACCOUNTS TODO: ROLE SCOPE CHECK
export const getOwner = createSelector(getAgents, (agents) => agents.find(isOwner));

export const getOwnerEmail = createSelector(getOwner, (owner) => owner?.login);

export const getInviteAgentsInputPlaceholderSelector = createSelector(
  getOwnerEmail,
  (state, roleIndex?: number) => roleIndex,
  (ownerEmail, roleIndex) => {
    const defaultEmailDomain = (ownerEmail as string).split('@')[1];
    const roles = ['chatting.agent', 'team.leader', 'marketer', 'sales.rep', 'designer', 'developer', 'researcher'];

    const getRandomRole = (): string => {
      const randomIndex = Math.floor(Math.random() * roles.length);

      return roles[randomIndex];
    };

    if (roleIndex !== undefined) {
      const role = roles[roleIndex % roles.length];

      return `Eg. ${role}@${defaultEmailDomain}`;
    }

    const selectedRoles: string[] = [];

    while (selectedRoles.length < 3 && selectedRoles.length < roles.length) {
      const randomRole = getRandomRole();
      if (!selectedRoles.includes(randomRole)) {
        selectedRoles.push(randomRole);
      }
    }

    const joinedRoles = selectedRoles.map((role) => `${role}@${defaultEmailDomain}`).join(', ');

    return `Eg. ${joinedRoles}...`;
  },
);

export function getAgent(state: WithAgentsState, login: string): IAgent {
  return state.entities.agents.agents[login];
}

export function getAgentAccountId(state: WithAgentsState, login: string): string {
  const agent = getAgent(state, login);

  if (!agent) {
    return null;
  }

  return agent.accountId;
}

export const getAgentByAccountId: (state: WithAgentsState, accountId: string) => IAgent | undefined = createSelector(
  getAgents,
  (_, accountId: string | null) => accountId,
  (agents: IAgent[], accountId: string) => agents.find((agent) => agent.accountId === accountId),
);

export const getAgentByEmailAddress: (state: WithAgentsState, emailAddress: string) => IAgent | undefined =
  createSelector(
    getAgents,
    (_, emailAddress: string) => emailAddress,
    (agents: IAgent[], emailAddress: string) => agents.find((agent) => agent.login === emailAddress),
  );

export function getAgentEmailSubscriptions(state: WithAgentsState, login: string): Subset<IEmailUpdates> {
  const agent = getAgent(state, login);

  return agent?.emailSubscriptions;
}

export function getAgentNotifications(state: WithAgentsState, login: string): Subset<Notifications> {
  const agent = getAgent(state, login);

  return agent?.notifications;
}

export function getAgentPermission(state: WithAgentsState, login: string): Permission {
  const agent = getAgent(state, login);

  return agent?.permission;
}

export function getLoggedInAgent(state: WithAgentsState): IAgent {
  return getAgent(state, state.entities.agents.loggedInAgentLogin) || null;
}

export function getLoggedInAgentStatus(state: WithAgentsState): LoginStatus {
  const agent = getLoggedInAgent(state);

  return agent && agent.status;
}

export function getLoggedInAgentNotifications(state: WithAgentsState): Subset<Notifications> {
  const agent = getLoggedInAgent(state);

  return agent && agent.notifications;
}

export function getIsNotificationEnabled(state: WithAgentsState, notification: Notification): boolean {
  const notifications = getLoggedInAgentNotifications(state);

  return anyToBoolean(notifications?.[notification]);
}

export const getAreNotificationsMuted = (state: WithAgentsState): boolean =>
  getLoggedInAgentNotifications(state).mute_all_sounds;
export const getAreNotificationsSoundsRepeated = (state: WithAgentsState): boolean =>
  getLoggedInAgentNotifications(state).repeat_sound_notifications;

export const getActiveAgentsCount: (state: WithAgentsState) => number = createSelector(
  getActiveAgents,
  (agents) => agents.length,
);

export const getAwaitingApprovalAgentsCount: (state: WithAgentsState) => number = createSelector(
  getAgents,
  (agents) => agents.filter(isAwaitingApproval).length,
);

export function getLoggedInAgentLogin(state: WithAgentsState): string {
  return state.entities.agents.loggedInAgentLogin || '';
}

export function getLoggedInAgentAccountId(state: WithAgentsState): string {
  const agent = getLoggedInAgent(state);

  return agent?.accountId ?? null;
}

export function getLoggedInAgentIPs(state: WithAgentsState): string[] {
  return getLoggedInAgent(state).ips || (EMPTY_ARRAY as string[]);
}

export function getLoggedInAgentSessionsInLC(state: WithAgentsState): string[] {
  return getLoggedInAgent(state).sessionsInLC || (EMPTY_ARRAY as string[]);
}

export function getLoggedInAgentName(state: WithAgentsState): string {
  const agent = getLoggedInAgent(state);

  return agent?.name ?? '';
}

export function getLoggedInAgentGroupsIds(state: WithAgentsState): GroupId[] {
  const agent = getLoggedInAgent(state);

  return agent?.groups ?? (EMPTY_ARRAY as GroupId[]);
}

// GLOBAL ACCOUNTS TODO: ROLE SCOPE CHECK
export function getLoggedInAgentRole(state: WithAgentsState): Permission {
  const agent = getLoggedInAgent(state);

  return agent?.permission ?? null;
}

export function getIsLoggedInAgentOwner(state: WithAgentsState): boolean {
  const agent = getLoggedInAgent(state);

  return isOwner(agent);
}

export function agentExists(state: WithAgentsState, login: string): boolean {
  return !!state.entities.agents.agents[login];
}

export function getIsLoggedInAgentAcceptingChats(state: WithAgentsState): boolean {
  const loggedInAgentLogin = getLoggedInAgentLogin(state);

  const agents = agentsSelector(state);

  return Boolean(agents[loggedInAgentLogin] && agents[loggedInAgentLogin].status === LoginStatus.Online);
}

export function getLoggedInAgentIsNormalAgent(state: WithAgentsState): boolean {
  const agent = getLoggedInAgent(state);

  return isAgent(agent);
}

export function getIsAgentAtLeastAdmin(state: WithAgentsState, agentAccountId: string | null): boolean {
  if (!agentAccountId) {
    return false;
  }
  const agent = getAgentByAccountId(state, agentAccountId);

  return !isAgent(agent);
}
