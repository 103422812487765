// @ts-strict-ignore
import difference from 'lodash.difference';
import { type SagaIterator } from 'redux-saga';
import { all, put, call, takeEvery, takeLatest, select } from 'redux-saga/effects';

import { ToastContent } from 'constants/toasts';
import { ViewActionSource } from 'constants/view-actions-source';
import { toKeyMap } from 'helpers/array';
import { type KeyMap } from 'helpers/interface';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { type IArchive } from 'interfaces/entities/archive';
import { ApiManager } from 'services/api/api-manager';
import { mapFetchAllParamsToBody } from 'services/api/archive/helpers';
import { type IFetchNewArchivesCountResult } from 'services/api/archive/notifier/interfaces';
import { deserializeFetchAllResult, deserializeFetchResult } from 'services/api/archive/serializers';
import { TicketSerializer } from 'services/api/ticket/serializer';
import { chatsClient } from 'services/connectivity/agent-chat-api/chats/client';
import { type ListArchivesResponse } from 'services/connectivity/agent-chat-api/chats/types/list-archives';
import { normalizeError } from 'services/connectivity/agent-chat-api/helpers';
import { threadTagsClient } from 'services/connectivity/agent-chat-api/thread-tags/client';
import { type AgentChatApiResponse } from 'services/connectivity/agent-chat-api/types';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { ArchivesViewActions } from 'store/views/archives/actions';
import { getArchiveChatId } from 'store/views/archives/selectors';

import { ArchiveActions, ArchiveActionNames, TicketActionNames } from './actions';
import { getTagFailures } from './helpers';
import {
  type IFetchArchivesCollectionPayload,
  type IFetchNewArchivesCountPayload,
  type IUpdateArchiveTagsPayload,
  type IFetchArchivePayload,
  type IFetchArchiveTicketsPayload,
  type IArchiveTicket,
} from './interfaces';

function* fetchSingle(action: IActionWithPayload<string, IFetchArchivePayload>): SagaIterator {
  const { id, source } = action.payload;
  const { result, error }: AgentChatApiResponse<ListArchivesResponse> = yield call(chatsClient.listArchives, {
    filters: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      thread_ids: [String(id)],
    },
  });

  if (error || result.found_chats === 0) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.FETCH_SINGLE_ARCHIVE_ERROR),
        kind: ToastVariant.Error,
      })
    );

    return;
  }
  const state: Parameters<typeof deserializeFetchResult>[0] = yield select();
  const chatData: IArchive = deserializeFetchResult(state, result);

  yield put(ArchiveActions.fetchSuccess({ id: chatData.id, value: chatData, source }));
}

export function* fetchCollection(action: IActionWithPayload<string, IFetchArchivesCollectionPayload>): SagaIterator {
  const { source, page, shouldAppend, ...fetchParams } = action.payload;
  const additionalRequestId = [source, shouldAppend ? 'MORE' : 'NEW'].filter(Boolean).join('_');

  const data = mapFetchAllParamsToBody({
    ...fetchParams,
    ...(page && { page }),
  });
  const { result, error }: AgentChatApiResponse<ListArchivesResponse> = yield call(chatsClient.listArchives, data);

  if (result) {
    const state: Parameters<typeof deserializeFetchAllResult>[0] = yield select();
    const { totalCount, data, totalPages, nextPage } = deserializeFetchAllResult(state, result, fetchParams.limit);

    yield put(
      ArchiveActions.fetchCollectionSuccess({
        values: data,
        additionalRequestId,
      })
    );

    if ((source as ViewActionSource) === ViewActionSource.Archives) {
      yield put(
        ArchivesViewActions.setArchivesData({
          data,
          totalCount,
          totalPages,
          shouldAppend,
          nextPage,
        })
      );
    }
  } else {
    const normalizedError = normalizeError(error);
    yield put(
      ArchiveActions.fetchCollectionFailure({
        error: normalizedError.message,
        additionalRequestId,
      })
    );
  }
}

export function* fetchNewArchivesCount(
  action: IActionWithPayload<string, IFetchNewArchivesCountPayload>
): SagaIterator {
  // TODO: Handle both protocols
  const response: RequestResult<IFetchNewArchivesCountResult> = yield call(
    ApiManager.archiveApi.fetchNewArchivesCount,
    action.payload.timestamp,
    { ...action.payload }
  );

  if (response.result) {
    yield put(ArchiveActions.fetchNewCountSuccess({ count: response.result.count }));
  } else {
    yield put(ArchiveActions.fetchNewCountFailure({ error: (response.error as Error).message }));
  }
}

export function* updateTags(action: IActionWithPayload<string, IUpdateArchiveTagsPayload>): SagaIterator {
  const { archiveId, tags, previousTags } = action.payload;

  const chatId = yield select(getArchiveChatId, archiveId);

  const removedTags = difference(previousTags, tags);
  const addedTags = difference(tags, previousTags);

  const removeResponses: AgentChatApiResponse<void>[] = yield all(
    removedTags.map((modifiedTag) =>
      call(threadTagsClient.untag, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        chat_id: chatId,
        tag: modifiedTag,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        thread_id: archiveId,
      })
    )
  );

  const addResponses: AgentChatApiResponse<void>[] = yield all(
    addedTags.map((modifiedTag) =>
      call(threadTagsClient.tag, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        chat_id: chatId,
        tag: modifiedTag,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        thread_id: archiveId,
      })
    )
  );

  const firstFailedResponse = removeResponses.concat(addResponses).find((response) => response.error);

  const failedAdditions = getTagFailures(addResponses, addedTags);
  const failedDeletions = getTagFailures(removeResponses, removedTags);

  if (!firstFailedResponse) {
    yield put(ArchiveActions.updateArchiveTagsSuccess({ archiveId, tags }));
  } else {
    yield put(
      ArchiveActions.updateArchiveTagsFailure({
        archiveId,
        error: normalizeError(firstFailedResponse.error).message,
        failedAdditions,
        failedDeletions,
      })
    );
  }
}

function* fetchTickets(action: IActionWithPayload<string, IFetchArchiveTicketsPayload>): SagaIterator {
  const { id } = action.payload;
  const { result, error }: RequestResult<{ tickets: IArchiveTicket[] }> = yield call(
    ApiManager.ticketApi.fetchByThreadId,
    id
  );

  if (result) {
    const { tickets } = result;

    const archiveTickets: KeyMap<IArchiveTicket> = toKeyMap(
      (tickets || []).map(TicketSerializer.deserializeArchiveTicket),
      'id'
    );

    yield put(ArchiveActions.fetchTicketsSuccess({ id, tickets: archiveTickets }));
  } else {
    yield put(ArchiveActions.fetchTicketsFailure({ id, error: (error as Error).message }));
  }
}

export default function* archiveSagas(): SagaIterator {
  yield all([
    takeEvery(ArchiveActionNames.FETCH_NEW_COUNT_REQUEST, fetchNewArchivesCount),
    takeLatest(ArchiveActionNames.FETCH_COLLECTION_REQUEST, fetchCollection),
    takeEvery(ArchiveActionNames.FETCH_SINGLE_REQUEST, fetchSingle),
    takeEvery(ArchiveActionNames.UPDATE_TAGS_REQUEST, updateTags),
    takeEvery(TicketActionNames.FETCH_TICKETS_REQUEST, fetchTickets),
  ]);
}
