// @ts-strict-ignore
import { createSelector } from 'reselect';

import { type AgentPriority } from 'constants/agent-priority';
import { Permission } from 'constants/permission';
import { type ITeamModal } from 'constants/team/modal';
import { TeamSubPaths } from 'constants/team/routes';
import { type KeyMap } from 'helpers/interface';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import type { Group } from 'interfaces/groups';
import { type WithAgentsState, getAgent, getLoggedInAgentRole } from 'store/entities/agents/selectors';
import { type IWithBotsState, getBot } from 'store/entities/bots/selectors';
import { type IWithGroupsState, getAgentGroups, getBotGroups, getGroup } from 'store/entities/groups/selectors';
import { getCanManageSubscription, getIsStarterPlan } from 'store/features/session/selectors';

import { TeamSectionDetails } from './constants';
import {
  type IBaseTableContent,
  type ISortBy,
  type ITeamState,
  type ITeamTableName,
  type ITeamView,
  type TeamListSubRoutes,
} from './interfaces';

const EMPTY_ARRAY: Group[] = [];

interface WithTeamViewState {
  views: {
    team: ITeamState;
  };
}

function getTeamView(state: WithTeamViewState): ITeamState {
  return state.views.team;
}

export const getTableSortingFor = (
  teamView: ITeamView,
  tableName: ITeamTableName,
  state: WithTeamViewState
): ISortBy => {
  return (getTeamView(state)[teamView][tableName] as IBaseTableContent).sortBy;
};

export const getTableFilterFor = (teamView: ITeamView, state: WithTeamViewState): string => {
  return getTeamView(state)[teamView].filter;
};

export const getCurrentOpenedModal = (state: WithTeamViewState): ITeamModal => {
  return state.views.team.modal;
};

export const getSelectedIdFor = (tableName: ITeamView, state: WithTeamViewState): string | null =>
  getTeamView(state)[tableName].selectedId;

export const getSelectedAgentGroups = (
  state: WithTeamViewState & WithAgentsState & IWithBotsState & IWithGroupsState
): Group[] => {
  const agentId = getSelectedIdFor('agents', state);

  return agentId ? getAgentGroups(state, agentId) : EMPTY_ARRAY;
};

export function getSelectedEntity(
  state: WithTeamViewState & WithAgentsState & IWithBotsState,
  section: TeamSectionDetails
): IAgentBase {
  const entityId = getSelectedIdFor(section, state);

  return section === TeamSectionDetails.Chatbots ? getBot(state, entityId) : getAgent(state, entityId);
}

export const getSelectedSuspendedAgentGroups = (
  state: WithTeamViewState & WithAgentsState & IWithBotsState & IWithGroupsState
): Group[] => {
  const chatbotId = getSelectedIdFor('suspendedAgents', state);

  return chatbotId ? getAgentGroups(state, chatbotId) : EMPTY_ARRAY;
};

export const getSelectedChatbotGroups = (
  state: WithTeamViewState & IWithBotsState & WithAgentsState & IWithGroupsState
): Group[] => {
  const chatbotId = getSelectedIdFor('chatbots', state);
  const chatbotAgent = getBot(state, chatbotId);

  return chatbotAgent ? getBotGroups(state, chatbotId) : EMPTY_ARRAY;
};

export const getSelectedAgents = (state: WithTeamViewState): KeyMap<Pick<IAgentBase, 'name' | 'login'>> =>
  getTeamView(state).selectedAgents;

export const getSelectedAgentsCount = (state: WithTeamViewState): number =>
  Object.keys(getSelectedAgents(state)).length;

export const getIsAgentSelected = (state: WithTeamViewState, agentLogin: string): boolean =>
  !!getSelectedAgents(state)[agentLogin];

export function getSelectedGroup(state: WithTeamViewState & IWithGroupsState): Group {
  const groupId = getSelectedIdFor('groups', state);

  return getGroup(state, groupId);
}

export function getCurrentTeamPage(state: WithTeamViewState): TeamSubPaths {
  return state.views.team.currentTeamPage;
}

export const getGroupFormMembers = (state: WithTeamViewState): KeyMap<AgentPriority> => {
  return getTeamView(state).groupFormMembers;
};

export const getTeamListSubRoutes = createSelector(
  getLoggedInAgentRole,
  getIsStarterPlan,
  getCanManageSubscription,
  (currentUserRole, isStarterPlan, canManageSubscription): TeamListSubRoutes[] => {
    return [
      {
        name: 'Agents',
        subPath: TeamSubPaths.Agents,
      },
      {
        name: 'Chatbots',
        subPath: TeamSubPaths.Chatbots,
      },
      isStarterPlan && !canManageSubscription
        ? undefined
        : {
            name: 'Groups',
            subPath: TeamSubPaths.Groups,
          },
      currentUserRole !== Permission.Normal && {
        name: 'Suspended agents',
        subPath: TeamSubPaths.SuspendedAgents,
      },
    ].filter(Boolean);
  }
);
