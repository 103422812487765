// @ts-strict-ignore
import { LoginStatus } from 'constants/login-status';
import { stringToBoolean } from 'helpers/boolean';
import { type PickRequired } from 'helpers/interface';
import type { Group } from 'interfaces/groups';
import { type GroupDTO, type GroupRTMDTO } from 'services/api/group/interfaces';
import { mapRoutingStatusToLoginStatus } from 'services/socket-lc3/agent/helpers';

export function deserializeGroup(data: GroupDTO): Group {
  return {
    id: String(data.id),
    name: data.name || '',
    language: data.language_code,
    status: data.routing_status || LoginStatus.Offline,
    agentsPriorities: data.agent_priorities ?? {},
    addTagReminder: stringToBoolean(data.properties?.add_tag_reminder),
  };
}

export function deserializePartialGroup(
  data: Partial<GroupDTO> & Pick<GroupRTMDTO, 'status'>
): PickRequired<Group, 'id'> {
  return {
    id: String(data.id),
    ...(data.name && { name: data.name }),
    ...(data.language_code && { language: data.language_code }),
    ...(data.routing_status && !data.status && { status: data.routing_status }),
    ...(data.status && !data.routing_status && { status: mapRoutingStatusToLoginStatus(data.status) }),
    ...(data.agent_priorities && { agentsPriorities: data.agent_priorities }),
  };
}

export function deserializeGroups(data: GroupDTO[]): Group[] {
  return data.map(deserializeGroup);
}
