import { ChatRecipients } from 'constants/chat-recipients';
import { UserType } from 'constants/user-type';
import { type ChatResultType } from 'services/api/chat/interfaces';
import { isChatSummaryResult } from 'services/api/interfaces/chat/helpers';

export function isChatSupervisedByAgent(chat: ChatResultType, agentId: string): boolean {
  const thread = isChatSummaryResult(chat) ? chat.last_thread_summary : chat.thread;

  if (!thread) {
    return false;
  }

  return chat.users.some(
    (user) =>
      user.type === UserType.Agent && user.visibility === ChatRecipients.Agents && user.present && user.id === agentId
  );
}
