import { getPropertyValue } from 'services/serialization/property';

export function isStatusUpdate(properties: any): boolean {
  const isIdle = getPropertyValue(properties, 'routing', 'idle');

  return isIdle !== null;
}

export function isPinnedUpdate(properties: any): boolean {
  const pinned = getPropertyValue(properties, 'routing', 'pinned');

  return pinned !== null;
}
