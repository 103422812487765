export enum RatingFilter {
  Rated = 'rated',
  NotRated = 'not_rated',
  RatedBad = 'rated_bad',
  RatedGood = 'rated_good',
  RatedCommented = 'rated_commented',
  RatedBadCommented = 'rated_bad_commented',
  RatedGoodCommented = 'rated_good_commented',
}

export const RatingFiltersWithoutComment = [
  RatingFilter.Rated,
  RatingFilter.NotRated,
  RatingFilter.RatedBad,
  RatingFilter.RatedGood,
];

export const RatingFiltersWithComment = [
  RatingFilter.RatedCommented,
  RatingFilter.RatedBadCommented,
  RatingFilter.RatedGoodCommented,
];

export const RatingFilters = [...RatingFiltersWithoutComment, ...RatingFiltersWithComment];

export const RatingFilterFormatedValues = {
  [RatingFilter.Rated]: 'Any rating',
  [RatingFilter.NotRated]: 'Not rated',
  [RatingFilter.RatedBad]: 'Rated bad',
  [RatingFilter.RatedGood]: 'Rated good',
  [RatingFilter.RatedCommented]: 'Rated & commented',
  [RatingFilter.RatedBadCommented]: 'Rated bad & commented',
  [RatingFilter.RatedGoodCommented]: 'Rated good & commented',
};

export enum CommentFilter {
  Commented = '1',
}
