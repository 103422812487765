import { Interval } from 'constants/date';
import { dateDifference } from 'helpers/date';

export function calculateLastActivityInDays(lastActivity: string): number | undefined {
  const lastActivityDate = new Date(lastActivity);

  if (isNaN(lastActivityDate.getTime())) {
    return undefined;
  }

  const dateDiff = dateDifference(lastActivityDate, new Date(), Interval.Day);

  return Math.abs(dateDiff);
}
