// @ts-strict-ignore
import { type ReactNode, PureComponent } from 'react';

import { cx } from '@emotion/css';
import { Button, Icon, Tooltip, type IconSource } from '@livechat/design-system-react-components';

import type { WithOperators } from 'components/filters/interfaces';
import Operators from 'components/filters/operators/Operators';
import { type FilterOperator, Filter } from 'constants/filters/filter';
import { KeyCodeEnum } from 'constants/key-code';
import { anyToBoolean } from 'helpers/boolean';
import type { FilterValues } from 'interfaces/filter';

import { type ToggleKeyboardEventsPayload } from './types';

import * as styles from './styles';

const SELECTED_VALUES_PROMO_COUNT = 2;

interface Props {
  title: string;
  innerTitle?: string;
  icon: IconSource;
  isOpen: boolean;
  isValid?: boolean;
  boxClassName?: string;
  buttonClassName?: string;
  actionButtonText?: string;
  turnOffKeyboardEventsOnShow?: boolean;
  withOperator?: WithOperators;
  filterName?: Filter;
  filterValues?: FilterValues;
  filterOperator?: FilterOperator;
  preview?: ReactNode;
  onOperatorChange?: (operator: FilterOperator) => void;
  onActionButtonClick?(): void;
  onClose(): void;
  onOpen?(): void;
  renderContent(payload: {
    onToggleFilterKeyboardEvents(shouldTurnOnEvents: ToggleKeyboardEventsPayload): void;
  }): ReactNode;
  children?: ReactNode;
}

export class ControlledFilter extends PureComponent<Props> {
  static defaultProps = {
    contentZIndex: 2100,
    childrenZIndex: null,
    turnOffKeyboardEventsOnShow: true,
  };

  componentDidMount(): void {
    if (this.props.turnOffKeyboardEventsOnShow === false) {
      this.addAllKeyDownListeners();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const isShown = !prevProps.isOpen && this.props.isOpen;
    const isHidden = prevProps.isOpen && !this.props.isOpen;

    if (isShown && this.props.turnOffKeyboardEventsOnShow === false) {
      this.addAllKeyDownListeners();
    } else if (isHidden) {
      this.removeAllKeyDownListeners();
    }
  }

  componentWillUnmount(): void {
    this.removeAllKeyDownListeners();
  }

  addAllKeyDownListeners = (): void => {
    this.addEnterKeyDownListener();
    this.addEscKeyDownListener();
  };

  removeAllKeyDownListeners = (): void => {
    this.removeEnterKeyDownListener();
    this.removeEscKeyDownListener();
  };

  addEnterKeyDownListener = (): void => {
    document.addEventListener('keydown', this.handleEnterKeyDown);
  };

  removeEnterKeyDownListener = (): void => {
    document.removeEventListener('keydown', this.handleEnterKeyDown);
  };

  addEscKeyDownListener = (): void => {
    document.addEventListener('keydown', this.handleEscKeyDown);
  };

  removeEscKeyDownListener = (): void => {
    document.removeEventListener('keydown', this.handleEscKeyDown);
  };

  toggleKeyboardEvents = (payload: ToggleKeyboardEventsPayload): void => {
    if (typeof payload === 'object') {
      if (payload.esc) {
        this.addEscKeyDownListener();
      } else {
        this.removeEscKeyDownListener();
      }

      if (payload.enter) {
        this.addEnterKeyDownListener();
      } else {
        this.removeEnterKeyDownListener();
      }
    } else if (payload) {
      this.addAllKeyDownListeners();
    } else {
      this.removeAllKeyDownListeners();
    }
  };

  handleOpen = (): void => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  handleKeyDown = (event: KeyboardEvent): void => {
    if ((event.keyCode as KeyCodeEnum) === KeyCodeEnum.Esc || (event.keyCode as KeyCodeEnum) === KeyCodeEnum.Enter) {
      this.props.onClose();
    }
  };

  handleEnterKeyDown = (event: KeyboardEvent): void => {
    if ((event.keyCode as KeyCodeEnum) === KeyCodeEnum.Enter) {
      this.props.onClose();
    }
  };

  handleEscKeyDown = (event: KeyboardEvent): void => {
    if ((event.keyCode as KeyCodeEnum) === KeyCodeEnum.Esc) {
      this.props.onClose();
    }
  };

  render(): JSX.Element {
    const {
      title,
      innerTitle,
      icon,
      isValid,
      boxClassName,
      buttonClassName,
      withOperator,
      filterOperator,
      preview,
      filterName,
      filterValues,
      onOperatorChange,
    } = this.props;

    // We are showing this promo only for Tag filter intentionally. For now there are no more filter types with operators
    const shouldShowPromoTooltip =
      filterName === Filter.Tag &&
      filterValues &&
      Array.isArray(filterValues) &&
      filterValues.length >= SELECTED_VALUES_PROMO_COUNT;

    return (
      <Tooltip
        data-testid={`popperContainer${title}`}
        placement="bottom-start"
        isVisible={this.props.isOpen}
        onOpen={this.handleOpen}
        onClose={this.props.onClose}
        triggerRenderer={() => this.props.children}
        className={cx(styles.boxContainer, boxClassName)}
        triggerOnClick
        triggerOnHover={false}
      >
        <div className={styles.header(!!preview)}>
          <Icon className={styles.filterIcon} source={icon} size="medium" />
          <h3 data-testid="filter-tooltip-header" className={styles.title}>
            {innerTitle ?? title}
          </h3>
          {withOperator && (
            <Operators
              selectedOperator={filterOperator}
              className={styles.operatorsDropdown}
              shouldShowPromoTooltip={shouldShowPromoTooltip && this.props.isOpen}
              onOperatorChange={onOperatorChange}
              availableOperators={withOperator !== true ? withOperator : undefined}
            />
          )}
        </div>
        {preview && preview}
        <div className={styles.body}>
          {this.props.renderContent({
            onToggleFilterKeyboardEvents: this.toggleKeyboardEvents,
          })}
        </div>
        <div className={styles.footer}>
          <Button
            className={cx({
              [buttonClassName]: anyToBoolean(buttonClassName),
              disabled: !isValid,
            })}
            kind="primary"
            onClick={this.props.onActionButtonClick}
            type="button"
            fullWidth
          >
            {this.props.actionButtonText}
          </Button>
        </div>
      </Tooltip>
    );
  }
}
