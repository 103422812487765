import { UnsavedChangesNames, type UnsavedChangesAction } from './actions';
import { type IUnsavedChangesState } from './interfaces';

const initialState: IUnsavedChangesState = {
  hasUnsavedChanges: false,
};

export function unsavedChangesReducer(
  state: IUnsavedChangesState = initialState,
  action: UnsavedChangesAction
): IUnsavedChangesState {
  switch (action.type) {
    case UnsavedChangesNames.SET_UNSAVED_CHANGES:
      return {
        hasUnsavedChanges: action.payload.hasUnsavedChanges,
      };

    default:
      return state;
  }
}
