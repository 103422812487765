import { type KeyMap } from 'helpers/interface';

import { type IAgentActivityData, type IAgentActivityEvent } from '../../../../interfaces/reports';

export function activityDeserializer({ data }: any): IAgentActivityData {
  const activity = data.activity || {};
  const agents = Object.keys(activity);
  const events: KeyMap<IAgentActivityEvent[]> = agents.reduce(function (acc, agent) {
    acc[agent] = activity[agent].map(function (v): IAgentActivityEvent {
      return {
        start: new Date(v.start_time),
        end: new Date(v.end_time),
        type: v.type,
      };
    });

    return acc;
  }, {});

  return {
    events,
  };
}
