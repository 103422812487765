import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Tag, Icon } from '@livechat/design-system-react-components';

import { anyToBoolean } from 'helpers/boolean';
import { dasherize } from 'helpers/string';

import type { FilterButton } from './types';

import * as styles from './styles';

export const FilterButtonTrigger: FC<FilterButton> = (props) => {
  const hasIcon = anyToBoolean(props.icon);

  return (
    <Tag
      size={props.icon ? 'large' : 'small'}
      data-testid={props?.title ? `${dasherize(props.title)}-filter` : 'filter'}
      leftNode={props.icon ? <Icon source={props.icon} /> : undefined}
      disabled={!props.removable}
      onRemove={props.onRemove}
      className={cx(styles.filterButtonWrapper, {
        [styles.removable]: props.removable && hasIcon,
        [styles.withIcon]: hasIcon,
        [styles.withoutIcon]: !hasIcon && props.removable,
      })}
    >
      <div>
        <div
          className={cx(styles.filterButtonTitle, {
            [styles.bigFilterButtonTitle]: props.wide,
          })}
        >
          <span>
            {!props.shouldHideTitle && props.title && <strong>{props.title}</strong>}{' '}
            {!!props.selectedValuesLabel && props.selectedValuesLabel}
          </span>
        </div>
      </div>
    </Tag>
  );
};
