// @ts-strict-ignore
import { getTime } from 'date-fns';
import isObject from 'lodash.isobject';

import { ChatEventStatus } from 'constants/chat-event-status';
import { ChatEventType } from 'constants/chat-event-type';
import { ChatType } from 'constants/chat-type';
import RichMessageTemplate from 'constants/rich-message-template';
import { UserType } from 'constants/user-type';
import { isRichMessageJSON, type IRichMessageJSON } from 'helpers/rich-message';
import type { Properties } from 'interfaces/property';
import { AppStateProvider } from 'services/app-state-provider';
import { deserializeRichMessageDetails } from 'services/serialization/rich-message';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { type INewMessagePayload } from 'store/entities/chats/interfaces';
import { getThread } from 'store/entities/chats/selectors';

export function handleSendMessage(
  threadId: string,
  messageId: string,
  text: string,
  json: any,
  isPrivateMessage: boolean,
  properties: Properties
): void {
  const authorId = AppStateProvider.selectFromStore(getLoggedInAgentLogin);
  const thread = AppStateProvider.selectFromStore(getThread, threadId);

  if (!thread) {
    return;
  }

  const isSupervisorMessage = thread.type === ChatType.Supervised || isPrivateMessage;

  const actionPayload: INewMessagePayload = {
    threadId,
    message: {
      authorId,
      authorType: isSupervisorMessage ? UserType.Supervisor : UserType.Agent,
      id: messageId,
      status: ChatEventStatus.Sent,
      wasSeen: true,
      text,
      timestampInMs: getTime(new Date()),
      type: ChatEventType.Message,
      properties,
    },
  };

  const isRichMessage = Boolean(json && isRichMessageJSON(json));

  if (isRichMessage) {
    const { payload } = json as IRichMessageJSON;

    if (!isObject(payload)) {
      return;
    }

    const richMessageTemplate =
      payload.template_id === 'quick_replies' ? RichMessageTemplate.QuickReplies : RichMessageTemplate.Cards;

    AppStateProvider.dispatch(
      ChatsEntitiesActions.newMessage({
        ...actionPayload,
        message: {
          ...actionPayload.message,
          type: ChatEventType.RichMessage,
          richMessageTemplate,
          richMessageDetails: payload.elements ? deserializeRichMessageDetails(payload.elements) : null,
        },
      })
    );
  } else {
    AppStateProvider.dispatch(ChatsEntitiesActions.newMessage(actionPayload));
  }
}
