import { AcceptingChatsStatus, AgentActivityEventType, LoginStatus } from 'constants/login-status';
import { RoutingStatus } from 'services/socket-lc3/agent/interfaces';

export abstract class AgentStatusSerializerV3 {
  static deserializeStatusAfterLogin(status: string): AcceptingChatsStatus {
    if (
      (status as AgentActivityEventType) === AgentActivityEventType.Away ||
      (status as LoginStatus) === LoginStatus.Away ||
      (status as RoutingStatus) === RoutingStatus.NotAcceptingChats
    ) {
      return AcceptingChatsStatus.NotAcceptingChats;
    }

    if (
      (status as AgentActivityEventType) === AgentActivityEventType.Online ||
      (status as RoutingStatus) === RoutingStatus.AcceptingChats
    ) {
      return AcceptingChatsStatus.AcceptingChats;
    }

    return (status as AcceptingChatsStatus) || AcceptingChatsStatus.NotAcceptingChats;
  }
}
