export const enum FilterType {
  Activity = 'activity',
  AssignedAgent = 'assigned-agent',
  Country = 'country',
  Group = 'group',
  ReturningCustomer = 'returning-customer',
  NumberOfVisits = 'number-of-visits',
  WebCrawlers = 'web-crawlers',
  IP = 'ip',
  Name = 'name',
  Email = 'email',
  City = 'city',
  State = 'state',
  CameFrom = 'came-from',
  LastPageTitle = 'last-page-title',
}

export const enum FilterTypeEquality {
  Is = 'is',
  IsNot = 'is not',
}

export const enum ExtendedFilterTypeEquality {
  IsEmpty = 'is empty',
  IsNotEmpty = 'is not empty',
}

export const enum FilterTypeString {
  IsExactly = 'is exactly',
  IsNot = 'is not',
  Contains = 'contains',
  DoesNotContain = 'does not contain',
  HasAnyValue = 'has any value',
}

export const enum FilterTypeNumber {
  IsExactly = 'is exactly',
  IsNot = 'is not',
  IsGreaterThan = 'is greater than',
  IsGreaterThanOrEqualTo = 'is greater than or equal to',
  IsLessThan = 'is less than',
  IsLessThanOrEqualTo = 'is less than or equal to',
  IsBetween = 'is between',
}

export const enum FilterTypeIp {
  IsNotMy = 'is not my',
  IsNotOther = 'is not other',
}