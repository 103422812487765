import { ReportType } from './report-type';

export const BREAKDOWN_PAGE_SIZE = 100;
export const BREAKDOWN_ROWS_TO_ENABLE_PAGINATION = 150;
export const BREAKDOWN_MAX_CHECKED_ROWS = 4;

type TBreakdownCellChangeComparatorConfig = {
  [K in ReportType]?: (value: number) => boolean;
};

const isLessThanZero = (value: number): boolean => value < 0;

export const breakdownCellChangeComparatorConfig: TBreakdownCellChangeComparatorConfig = {
  [ReportType.ChatResponseTime]: isLessThanZero,
  [ReportType.MissedChats]: isLessThanZero,
  [ReportType.TicketResolutionTime]: isLessThanZero,
  [ReportType.TicketFirstResponseTime]: isLessThanZero,
  [ReportType.QueuedCustomers]: isLessThanZero,
};
