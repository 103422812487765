import { getConfig } from 'helpers/config';

export const DEFAULT_WORK_SCHEDULER_SUMMARY_LABEL = 'Timezone: Always based on agents location';

export const BATCH_REQUESTS_LIMIT = 20;
export const MAX_NUMBER_OF_AGENTS_CHATS = getConfig().concurrentChatsLimit;
export const MAX_NUMBER_OF_BOTS_CHATS = 500;
export const MAX_TOOLTIP_LENGTH = 400;

export enum TeamListName {
  Groups = 'groups',
  Agents = 'agents',
  WaitingForApproval = 'waiting for approval',
  SuspendedAgents = 'suspended',
}
