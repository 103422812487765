import { useCallback, type FC } from 'react';

import { SideNavigationGroup } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { AppsEvent, AppsSection } from 'constants/apps/events';
import { navigateToMarketplace } from 'helpers/routing';
import { preloadMarketplacePage } from 'routes/apps/helpers';
import { trackAppsSectionEvent } from 'services/event-tracking';
import { AppsViewActions } from 'store/views/apps/actions';
import { getCurrentMarketplaceRoute } from 'store/views/apps/selectors';

import { MARKETPLACE_LINKS } from '../constants';
import { checkIfActive } from '../helpers';

const [, ...campaignLinks] = MARKETPLACE_LINKS;

export const IntegrationsList: FC = () => {
  const currentMarketplaceRoute = useSelector(getCurrentMarketplaceRoute);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (link: string): void => {
      navigateToMarketplace(link);
      dispatch(AppsViewActions.setMarketplaceRoute(link));
      trackAppsSectionEvent(AppsEvent.ExternalIntegrationsClicked, AppsSection.SideNavigation);
    },
    [dispatch],
  );

  return (
    <SideNavigationGroup>
      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      {campaignLinks.map(({ label, link, icon: Icon }) => (
        <SideNavigationItem
          isActive={checkIfActive(link, currentMarketplaceRoute)}
          key={link}
          url={link}
          icon={<Icon />}
          label={label}
          onClick={() => handleClick(link)}
          onItemHover={() => preloadMarketplacePage(link)}
        />
      ))}
    </SideNavigationGroup>
  );
};
