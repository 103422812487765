import { trackEvent } from 'services/event-tracking';
import { saveItem, removeItem } from 'services/local-storage';
import { ProfileMenuEvent } from 'store/entities/agents/constants';

import { EventPlace } from './analytics';
import { userForcesDesktopView } from './device';
import { getAppRoot } from './url';

const FORCE_DESKTOP_KEY = 'forceDesktopView';

export const toggleViewMode = (): void => {
  if (userForcesDesktopView()) {
    removeItem(FORCE_DESKTOP_KEY);
    trackEvent(ProfileMenuEvent.SwitchedToMobileView, EventPlace.ProfileMenu);
  } else {
    saveItem(FORCE_DESKTOP_KEY, true);
    trackEvent(ProfileMenuEvent.SwitchedToDesktopView, EventPlace.ProfileMenu);
  }

  // a little better experience when redirect after one second
  setTimeout(() => {
    window.location.pathname = getAppRoot();
  }, 1000);
};
