import { DEFAULT_EARLY_ACCESS_CONFIG } from './constants';
import { type EarlyAccessConfig } from './interfaces';

const getEarlyAccessConfig = (config: Partial<EarlyAccessConfig>): EarlyAccessConfig => {
  try {
    const mergedConfig = {
      ...DEFAULT_EARLY_ACCESS_CONFIG,
      ...config,
    } as EarlyAccessConfig;

    return mergedConfig;
  } catch {
    return DEFAULT_EARLY_ACCESS_CONFIG;
  }
};

export const getEarlyAccessConfigForField = (
  config: Partial<EarlyAccessConfig>,
  property: keyof EarlyAccessConfig
): boolean => {
  return getEarlyAccessConfig(config)[property];
};
