// @ts-strict-ignore
import * as Sentry from '@sentry/browser';
import { type SagaIterator } from 'redux-saga';
import { call, select, delay, put } from 'redux-saga/effects';

import { getEmailiniumId, installEmailinium } from 'helpers/emailinium';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { getApplication } from 'store/entities/applications/selectors';

import { createPluginInstallError } from './helpers';

const LICENSE_INITIALIZATION_TIMEOUT = 4000;

export default function* install(): SagaIterator {
  const isEmailinumInstalled = yield select(getApplication, getEmailiniumId());
  try {
    if (!isEmailinumInstalled) {
      yield delay(LICENSE_INITIALIZATION_TIMEOUT);
      yield call(installEmailinium);
      yield put(ApplicationsActions.fetchCollection({}));
    }
  } catch (error) {
    yield call(Sentry.captureException, createPluginInstallError('Messaging Mode', error.responseText));
  }
}
