import { isAfter, isEqual } from 'date-fns';

import { ChatEventStatus } from 'constants/chat-event-status';
import { isEventWithStatus } from 'store/entities/chats/helpers/common';
import { type ChatEventEntity } from 'store/entities/chats/interfaces';

export function getShouldEventBeMarkedAsRead(event: ChatEventEntity, eventToCompareWith: ChatEventEntity): boolean {
  const isEventToCompareWithOlderOrSame =
    isAfter(eventToCompareWith.timestampInMs, event.timestampInMs) ||
    isEqual(eventToCompareWith.timestampInMs, event.timestampInMs);
  const hasEventBeenNotSeenYet = isEventWithStatus(event) && event.status === ChatEventStatus.Delivered;

  const shouldBeEventMarkedAsRead = hasEventBeenNotSeenYet && isEventToCompareWithOlderOrSame;

  return shouldBeEventMarkedAsRead;
}
