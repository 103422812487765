import { Platform } from 'constants/app-platform';

import { isMacOS } from './browser';

const MAC_DOWNLOAD_APP_LINK = 'https://www.livechat.com/download/Mac/LiveChat.dmg';
const WINDOWS_DOWNLOAD_APP_LINK = 'https://www.livechat.com/download/Windows/LiveChat.exe';
export const IOS_APP_LINK = 'https://apps.apple.com/app/apple-store/id1512395470?pt=118763571&ct=webapp&mt=8';
export const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.livechatinc.android&referrer=utm_source%3Dwebapp';

export function getAppDownloadUrl(): string {
  return isMacOS() ? MAC_DOWNLOAD_APP_LINK : WINDOWS_DOWNLOAD_APP_LINK;
}

export function getMobileAppDownloadUrl(platform: Platform): string {
  if (platform === Platform.iOS) {
    return IOS_APP_LINK;
  }

  return ANDROID_APP_LINK;
}
