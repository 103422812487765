import { type ActionsUnion, createAction } from 'store/helper';

export enum ShortcutsActionNames {
  DISABLE = 'FEATURES/SHORTCUTS/DISABLE',
  ENABLE = 'FEATURES/SHORTCUTS/ENABLE',
}

export const ShortcutsActions = {
  enable: () => createAction(ShortcutsActionNames.ENABLE),
  disable: () => createAction(ShortcutsActionNames.DISABLE),
};

export type ShortcutsAction = ActionsUnion<typeof ShortcutsActions>;
