// @ts-strict-ignore
import memoizeOne from 'memoize-one';

import { type Filters } from 'constants/filters/filter';
import { VisitorState } from 'constants/visitor-state';
import { type KeyMap } from 'helpers/interface';
import { capitalizeFirstLetter } from 'helpers/string';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { calculateCustomerActivity } from 'store/entities/customers/computed';
import { type ICustomer } from 'store/entities/customers/interfaces';
import { type IWithCustomersEntityState } from 'store/entities/customers/selectors';

import { CUSTOMERS_COUNT_REFRESH_THROTTLE_THRESHOLD } from '../constants';
import { TrafficColumn, type TrafficFilter } from '../interfaces';
import { type IWithTrafficViewState } from '../selectors';
import { serializeFilterValues } from '../serializers';

import { createThreadsByCustomerIdMap } from './reducer';

export function shouldThrottleCustomersListRefresh(state: IWithCustomersEntityState & IWithTrafficViewState): boolean {
  const customerCount = state.entities.customers.allIds.length;

  return customerCount > CUSTOMERS_COUNT_REFRESH_THROTTLE_THRESHOLD;
}

export function trafficToCustomersColumns(trafficColumnNames: string): string {
  switch (trafficColumnNames as TrafficColumn) {
    case TrafficColumn.ChatsCount: {
      return 'totalChats';
    }
    case TrafficColumn.ChattingWith: {
      return 'agent';
    }
    case TrafficColumn.CustomerId: {
      return 'customerId';
    }
    case TrafficColumn.GreetingsAccepted: {
      return 'acceptedGreetings';
    }
    case TrafficColumn.GreetingsIgnored: {
      return 'ignoredGreetings';
    }
    case TrafficColumn.GreetingsSent: {
      return 'sentGreetings';
    }
    case TrafficColumn.Groups: {
      return 'group';
    }
    case TrafficColumn.LastGreeting: {
      return 'lastGreetingId';
    }
    case TrafficColumn.LastPage: {
      return 'lastPage';
    }
    case TrafficColumn.LastSeen: {
      return 'lastSeen';
    }
    case TrafficColumn.OperatingSystem: {
      return 'os';
    }
    case TrafficColumn.VisitingTime: {
      return 'totalVisitDuration';
    }
    case TrafficColumn.VisitsCount: {
      return 'visits';
    }
    case TrafficColumn.Intent: {
      return 'intent';
    }
    case TrafficColumn.Priority: {
      return 'priority';
    }
    default: {
      return trafficColumnNames?.toLowerCase();
    }
  }
}

export function customersToTrafficColumns(customerColumnName: string): TrafficColumn {
  switch (customerColumnName) {
    case 'totalChats': {
      return TrafficColumn.ChatsCount;
    }
    case 'agent': {
      return TrafficColumn.ChattingWith;
    }
    case 'customerId': {
      return TrafficColumn.CustomerId;
    }
    case 'acceptedGreetings': {
      return TrafficColumn.GreetingsAccepted;
    }
    case 'ignoredGreetings': {
      return TrafficColumn.GreetingsIgnored;
    }
    case 'sentGreetings': {
      return TrafficColumn.GreetingsSent;
    }
    case 'group': {
      return TrafficColumn.Groups;
    }
    case 'lastGreetingId': {
      return TrafficColumn.LastGreeting;
    }
    case 'lastPage': {
      return TrafficColumn.LastPage;
    }
    case 'lastSeen': {
      return TrafficColumn.LastSeen;
    }
    case 'os': {
      return TrafficColumn.OperatingSystem;
    }
    case 'totalVisitDuration': {
      return TrafficColumn.VisitingTime;
    }
    case 'visits': {
      return TrafficColumn.VisitsCount;
    }
    case 'ip': {
      return TrafficColumn.IP;
    }
    case 'intent': {
      return TrafficColumn.Intent;
    }
    default: {
      return capitalizeFirstLetter(customerColumnName) as TrafficColumn;
    }
  }
}

export const mapTrafficFiltersToCustomSegmentFilters = memoizeOne((filters: TrafficFilter[]): Filters[] => {
  const mappedFilters = filters.reduce((map, filter: TrafficFilter) => {
    map.push({ [filter.filterType]: serializeFilterValues(filter) });

    return map as Filters[];
  }, []);

  return mappedFilters as Filters[];
});

export function getLongestOfflineCustomers(
  customers: KeyMap<ICustomer>,
  threads: KeyMap<ChatThreadEntity>,
  overThreshold: number
): string[] {
  const customerIds = Object.keys(customers);
  const threadsByCustomer = createThreadsByCustomerIdMap(threads);

  const offlineOnly = customerIds.filter((customerId) => {
    const customer = customers[customerId];
    const customerHasEndedVisit = Boolean(customer.visitDetails?.visitEndedTimestampInMs);
    const thread = threadsByCustomer[customerId];
    const activity = calculateCustomerActivity(customer, thread);

    // Customer may have ended the visit but is still "Waiting for reply" in an Unassigned chat,
    // only include those who left without an unreplied chat
    // TODO: also include untrackable customers who's visit end cannot be determined
    return customerHasEndedVisit && activity === VisitorState.LeftWebsite;
  });

  const sortedByVisitEnd = offlineOnly.sort((idA, idB) => {
    const customerA = customers[idA];
    const customerB = customers[idB];

    return customerA.visitDetails.visitEndedTimestampInMs - customerB.visitDetails.visitEndedTimestampInMs;
  });

  return sortedByVisitEnd.slice(0, -overThreshold);
}
