// @ts-strict-ignore
import { ChatType } from 'constants/chat-type';
import { type Filters } from 'constants/filters/filter';
import type { FilterType } from 'constants/filters/filter-type';
import { LogicOperator } from 'constants/logic-operator';
import { type KeyMap } from 'helpers/interface';
import { getRandomNumber } from 'helpers/number';
import type { Filter } from 'interfaces/components/filter/filter';
import type { CustomSegment } from 'interfaces/custom-segment';
import { isClosedThread } from 'store/entities/chats/helpers/common';
import type { ChatThreadEntity } from 'store/entities/chats/interfaces';
import { getNonBannedCustomers } from 'store/entities/customers/helpers/customer-banned';
import type { ICustomer, IVisitedPage } from 'store/entities/customers/interfaces';
import type { EntityData } from 'store/entities/interfaces';

import type {
  ISetIntentCampaignModalStatePayload,
  IAddFilterPayload,
  IApplySegmentFilterPayload,
  IChangeOperatorPayload,
  IColumnsOrderPayload,
  IColumnsVisibilityPayload,
  IRefreshCustomersPayload,
  IRemoveFilterPayload,
  ISetCustomMessageModalStatePayload,
  ISortColumnPayload,
  ITrafficViewState,
  IUpdateCustomersCountersPayload,
  IUpdateFilterPayload,
  TrafficFilter,
} from '../interfaces';
import { deserializeFilter } from '../serializers';

import { ONLINE_FILTER } from './constants';
import { isCustomerWithinFilters } from './filtering';
import { sortCustomers } from './sorting';

const EMPTY_ARRAY = [];

export function filterCustomers(
  customers: ICustomer[],
  threadsByCustomerId: KeyMap<ChatThreadEntity>,
  allVisitedPages: KeyMap<EntityData<IVisitedPage>>,
  filters: TrafficFilter[],
  filterOperator: LogicOperator
): ICustomer[] {
  if (filters.length === 0) {
    return customers;
  }

  return customers.filter((customer): boolean => {
    const thread = threadsByCustomerId[customer.id];
    const visitedPages = allVisitedPages[customer.id];

    return isCustomerWithinFilters(customer, thread, visitedPages, filters, filterOperator);
  });
}

export function getStateForSortColumn(
  state: ITrafficViewState,
  { sortedByColumn, sortOrder }: ISortColumnPayload
): ITrafficViewState {
  return {
    ...state,
    sortedByColumn,
    sortOrder,
  };
}

export function createThreadsByCustomerIdMap(threads: KeyMap<ChatThreadEntity>): KeyMap<ChatThreadEntity> {
  return Object.values(threads ?? {}).reduce<KeyMap<ChatThreadEntity>>((acc, thread) => {
    /**
     * Similar to getMostRelevantThreadByCustomerId selector, we search for most relevant thread for each customer.
     * First, we take whatever is found, then the next threads may overwrite the previous one if they're more relevant.
     * So an active chat (or a queued/other chat) will overwrite a previous closed chat that was found.
     * A closed unassigned chat will also overwrite a regular closed chat.
     * An active chat will never be overwritten because you can have only one active chat or one unassigned chat at the same time.
     */
    if (
      !acc[thread.customerId] || // a first found chat (may be closed)
      !isClosedThread(thread) || // an active chat or a queued/other chat
      thread.type === ChatType.Unassigned // unassigned closed chat
    ) {
      acc[thread.customerId] = thread;
    }

    return acc;
  }, {});
}

export function mapCustomSegmentFilterToTrafficFilter(segmentFilters: Filters[]): TrafficFilter[] {
  return segmentFilters.map((element: Filters): TrafficFilter => {
    const randomId = getRandomNumber(1000);
    const type = Object.keys(element)[0] as FilterType;
    const filterValues = element[type];

    const legacyFilter: Filter = {
      id: randomId,
      type,
      filterValues,
      removable: false,
      isDraft: false,
    };

    return deserializeFilter(legacyFilter);
  });
}

function calculateCustomersAndSegments(
  customers: ICustomer[],
  threadsByCustomerId: KeyMap<ChatThreadEntity>,
  allVisitedPages: KeyMap<EntityData<IVisitedPage>>,
  segments: KeyMap<CustomSegment>,
  selectedSegmentId: string
): { filteredCustomers: ICustomer[]; segmentsCounters: KeyMap<number> } {
  return Object.values(segments ?? {}).reduce(
    (acc, { id, value: { filters, operator } }) => {
      const mappedFilters = mapCustomSegmentFilterToTrafficFilter(filters);
      const filteredCustomers = customers.filter((customer) =>
        isCustomerWithinFilters(
          customer,
          threadsByCustomerId[customer.id],
          allVisitedPages[customer.id],
          mappedFilters,
          operator
        )
      );

      if (id === selectedSegmentId) {
        acc.filteredCustomers = filteredCustomers;
      }

      acc.segmentsCounters[id] = filteredCustomers.length;

      return acc;
    },
    { filteredCustomers: [], segmentsCounters: {} }
  );
}

function calculateCounters(
  customers: ICustomer[],
  threadsByCustomerId: KeyMap<ChatThreadEntity>
): { customersOnlineCount: number } {
  const customersOnlineCount = filterCustomers(
    customers,
    threadsByCustomerId,
    {},
    [ONLINE_FILTER],
    LogicOperator.Or
  ).length;

  return { customersOnlineCount };
}

export function getStateForRefreshList(
  state: ITrafficViewState,
  {
    customers,
    allThreads,
    visitedPages,
    allGroups,
    allAgents,
    allTargetedMessages,
    refreshTimestampInMs,
    segments,
    selectedSegmentId,
  }: IRefreshCustomersPayload
): ITrafficViewState {
  const { sortedByColumn, sortOrder } = state;

  const nonBannedCustomers = getNonBannedCustomers(customers);
  const threadsByCustomerId = createThreadsByCustomerIdMap(allThreads);

  const { customersOnlineCount } = calculateCounters(nonBannedCustomers, threadsByCustomerId);

  const { filteredCustomers, segmentsCounters } = calculateCustomersAndSegments(
    nonBannedCustomers,
    threadsByCustomerId,
    visitedPages,
    segments,
    selectedSegmentId
  );

  const customersList = sortCustomers(
    filteredCustomers,
    sortedByColumn,
    sortOrder,
    threadsByCustomerId,
    allAgents,
    allGroups,
    allTargetedMessages,
    visitedPages,
    refreshTimestampInMs
  );

  return {
    ...state,
    segmentsCounters,
    customersList,
    customersOnlineCount,
  };
}

export function getStateForAddFilter(
  state: ITrafficViewState,
  { filter, addAsDraft }: IAddFilterPayload
): ITrafficViewState {
  return {
    ...state,
    filters: [...state.filters, filter],
    isLastFilterADraft: addAsDraft,
  };
}

export function getStateForRemoveFilter(state: ITrafficViewState, { id }: IRemoveFilterPayload): ITrafficViewState {
  return {
    ...state,
    filters: state.filters.filter((f) => f.id !== id),
    isLastFilterADraft: false,
  };
}

export function getStateForUpdateFilter(state: ITrafficViewState, { filter }: IUpdateFilterPayload): ITrafficViewState {
  const indexToReplace = state.filters.findIndex((f) => f.id === filter.id);

  if (indexToReplace < 0) {
    return state;
  }

  const updatedFilters = [...state.filters];
  updatedFilters.splice(indexToReplace, 1, filter);

  return {
    ...state,
    filters: updatedFilters,
    isLastFilterADraft: false,
  };
}

export function getStateForChangeOperator(
  state: ITrafficViewState,
  { operator }: IChangeOperatorPayload
): ITrafficViewState {
  return {
    ...state,
    filterOperator: operator,
  };
}

export function getStateForApplySegmentFilters(
  state: ITrafficViewState,
  { segmentValue: customSegmentFilters }: IApplySegmentFilterPayload
): ITrafficViewState {
  const filters = mapCustomSegmentFilterToTrafficFilter(customSegmentFilters.filters);

  if (!customSegmentFilters || !filters) return state;

  return {
    ...state,
    filters,
    filterOperator: customSegmentFilters.operator,
    isLastFilterADraft: false,
  };
}

export function getStateForSetColumnsVisibility(
  state: ITrafficViewState,
  payload: IColumnsVisibilityPayload
): ITrafficViewState {
  const newVisibleColumns = payload.visibleColumns;
  const newHiddenColumns = payload.hiddenColumns;

  const updatedState: ITrafficViewState = {
    ...state,
    ...(payload.visibleColumns && { visibleColumns: newVisibleColumns }),
    ...(payload.hiddenColumns && { hiddenColumns: newHiddenColumns }),
  };

  return updatedState;
}

export function getStateForSetColumnsOrder(state: ITrafficViewState, payload: IColumnsOrderPayload): ITrafficViewState {
  const updatedState: ITrafficViewState = {
    ...state,
    columnsOrder: payload.columnsOrder,
  };

  return updatedState;
}

export function getStateForClearFilters(state: ITrafficViewState): ITrafficViewState {
  return {
    ...state,
    filters: EMPTY_ARRAY,
    isLastFilterADraft: false,
  };
}

export function getStateForSetCustomMessageModalState(
  state: ITrafficViewState,
  payload: ISetCustomMessageModalStatePayload
): ITrafficViewState {
  return {
    ...state,
    isCustomMessageModalOpened: payload.isOpen,
    customMessageModalCustomerId: payload.customerId,
  };
}

export function getStateForSetCustomersCounters(
  state: ITrafficViewState,
  payload: IUpdateCustomersCountersPayload
): ITrafficViewState {
  const { customers, allThreads } = payload;

  const nonBannedCustomers = getNonBannedCustomers(customers);
  const threadsByCustomerId = createThreadsByCustomerIdMap(allThreads);

  const { customersOnlineCount } = calculateCounters(nonBannedCustomers, threadsByCustomerId);

  return {
    ...state,
    customersOnlineCount,
  };
}

export function getStateForSetIntentCampaignModalState(
  state: ITrafficViewState,
  payload: ISetIntentCampaignModalStatePayload
): ITrafficViewState {
  return {
    ...state,
    isIntentCampaignModalOpen: payload.isOpen,
    intentCampaignModalCustomerId: payload.customerId,
  };
}
