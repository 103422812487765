// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { anyToBoolean, stringToBoolean } from 'helpers/boolean';
import { getHexFromRGB } from 'helpers/color';
import { lowercaseFirstLetter } from 'helpers/string';
import { isColor, isRGB } from 'helpers/validate';
import type { LanguagePhrases } from 'interfaces/language';
import { type IWidgetSnapshot } from 'interfaces/widget-snapshot';
import {
  Skin,
  ThemeVariant,
  type WidgetFeaturesInterface,
  WidgetMinimizedTypeEnum,
  WidgetPositionTypeEnum,
  type WidgetStateLocalizationInterface,
  type WidgetThemeInterface,
  WidgetVisibility,
} from 'interfaces/widget-state';

interface IGroupProperties {
  [key: string]: any;
}

const serializeWidgetSnapshot = (values: { [key: string]: any }, propsBase: { [key: string]: any }) =>
  Object.keys(propsBase).reduce(
    (result, prop) => ({
      ...result,
      ...(values[prop] ? { [propsBase[prop]]: values[prop] } : {}),
    }),
    {}
  );

const transformToTyped = (value: string, initialValue: string) =>
  typeof value === typeof initialValue
    ? value
    : ((valueToTransform, targetType) => {
        switch (targetType) {
          case 'string':
            return valueToTransform.toString();
          case 'number':
            return parseFloat(valueToTransform);
          case 'boolean':
            return anyToBoolean(valueToTransform);
          default:
            return valueToTransform;
        }
      })(value, typeof initialValue);

const deserializeWidgetSnapshot = (
  values: { [key: string]: any },
  propsBase: { [key: string]: any },
  initialValues: { [key: string]: any }
) =>
  Object.keys(propsBase).reduce(
    (result, prop) => ({
      ...result,
      [prop]:
        typeof values[propsBase[prop]] !== 'undefined'
          ? transformToTyped(values[propsBase[prop]], initialValues[prop])
          : initialValues[prop],
    }),
    initialValues
  );

const extractWidgetSnapshot = (values: { [key: string]: any }, initialValues: { [key: string]: any }) =>
  Object.keys(initialValues).reduce(
    (result, prop) => ({
      ...result,
      [prop]: typeof values[prop] !== 'undefined' ? values[prop] : initialValues[prop],
    }),
    initialValues
  );

// WIDGET CONFIGURATION UTILS
export enum WidgetSnapshotConfigProps {
  minimizedType = 'chat_window.theme.minimized',
  mobileMinimizedType = 'chat_window.mobile_minimized_theme',
  screenPosition = 'chat_window.screen_position',
  mobileScreenPosition = 'chat_window.mobile_screen_position',
  hideOnInit = 'chat_window.hide_on_init',
  mobileHideOnInit = 'chat_window.mobile_hide_on_init',
  disableMinimized = 'chat_window.disable_minimized',
  mobileDisableMinimized = 'chat_window.mobile_disable_minimized',
  offsetX = 'chat_window.offset_x',
  mobileOffsetX = 'chat_window.mobile_offset_x',
  offsetY = 'chat_window.offset_y',
  mobileOffsetY = 'chat_window.mobile_offset_y',
  customMobileSettings = 'chat_window.custom_mobile_settings',
  showOnMobile = 'chat_window.hide_on_mobile',
}

export const initialWidgetSnapshotConfigProps: IWidgetSnapshotConfigProps = {
  minimizedType: WidgetMinimizedTypeEnum.Circle,
  mobileMinimizedType: WidgetMinimizedTypeEnum.Circle,
  screenPosition: WidgetPositionTypeEnum.Right,
  mobileScreenPosition: WidgetPositionTypeEnum.Right,
  hideOnInit: false,
  mobileHideOnInit: false,
  disableMinimized: false,
  mobileDisableMinimized: false,
  offsetX: 0,
  mobileOffsetX: 0,
  offsetY: 0,
  mobileOffsetY: 0,
  customMobileSettings: false,
  showOnMobile: true,
};

export interface IWidgetSnapshotConfigProps {
  minimizedType?: WidgetMinimizedTypeEnum;
  mobileMinimizedType?: WidgetMinimizedTypeEnum;
  screenPosition?: WidgetPositionTypeEnum;
  mobileScreenPosition?: WidgetPositionTypeEnum;
  hideOnInit?: boolean;
  mobileHideOnInit?: boolean;
  disableMinimized?: boolean;
  mobileDisableMinimized?: boolean;
  offsetX?: number;
  mobileOffsetX?: number;
  offsetY?: number;
  mobileOffsetY?: number;
  customMobileSettings?: boolean;
  showOnMobile?: boolean;
  homescreenEnabled?: boolean;
}

export const serializeWidgetSnapshotConfig = (newValues: IWidgetSnapshot): IGroupProperties =>
  ((props) => ({ ...props, 'chat_window.hide_on_mobile': !props['chat_window.hide_on_mobile'] }))(
    serializeWidgetSnapshot(newValues, WidgetSnapshotConfigProps)
  );

export const deserializeWidgetSnapshotConfig = (groupProperties: IGroupProperties): IWidgetSnapshotConfigProps =>
  ((props) => ({ ...props, showOnMobile: !props.showOnMobile }))(
    deserializeWidgetSnapshot(groupProperties, WidgetSnapshotConfigProps, initialWidgetSnapshotConfigProps)
  );

export const extractWidgetSnapshotConfig = (values: { [key: string]: any }): IWidgetSnapshotConfigProps =>
  extractWidgetSnapshot(values, initialWidgetSnapshotConfigProps);

export const lowerCaseSnapshotLocalization = (
  localization: Partial<LanguagePhrases>
): WidgetStateLocalizationInterface => {
  if (!localization) {
    return {};
  }

  return Object.keys(localization).reduce((newLocalization, key) => {
    newLocalization[lowercaseFirstLetter(key)] = localization[key];

    return newLocalization;
  }, {});
};

export const getWidgetStateFromSnapshot = (widgetSnapshot: IWidgetSnapshot, sessionId: string) => {
  return {
    session: {
      id: sessionId,
    },
    application: {
      rtl: false,
      license: 123,
      testGroup: null,
      visibility: { state: widgetSnapshot.visibility },
      config: {
        features: {
          ...widgetSnapshot.features,
          ...(widgetSnapshot.features
            ? {
                agentAvatar: {
                  enabled:
                    widgetSnapshot.visibility === WidgetVisibility.Maximized
                      ? widgetSnapshot.features.agentAvatar.enabled
                      : false,
                },
              }
            : {}),
        },
        ...extractWidgetSnapshotConfig(widgetSnapshot),
      },
      mobile: widgetSnapshot.mobile,
    },
    localization: lowerCaseSnapshotLocalization(widgetSnapshot.localization),
  };
};

// WIDGET FEATURES UTILS
enum WidgetSpanshotFeaturesProps {
  agentAvatar = 'chat_window.display_avatar',
  emailTranscript = 'chat_window.display_transcript_button',
  rating = 'rate_me.enabled',
  logo = 'chat_window.display_logo',
  logoPath = 'chat_window.logo_path',
  disableSounds = 'chat_window.disable_sounds',
  hideTrademark = 'chat_window.hide_trademark',
  boosters = 'chat_boosters',
  homescreenEnabled = 'homescreenEnabled',
}

export const mapToWidgetSnapshotFeaturesProps = (values: { [key: string]: any }): WidgetFeaturesInterface => {
  const result: Partial<WidgetFeaturesInterface> = {};

  if ('agentAvatar' in values) {
    result.agentAvatar = { enabled: stringToBoolean(values.agentAvatar as string | boolean) };
  }

  if ('disableSounds' in values || 'enableSounds' in values) {
    result.disableSounds = {
      enabled:
        typeof values.disableSounds !== 'undefined'
          ? stringToBoolean(values.disableSounds as string | boolean)
          : !stringToBoolean(values.enableSounds as string | boolean),
    };
  }

  if ('emailTranscript' in values) {
    result.emailTranscript = { enabled: stringToBoolean(values.emailTranscript as string | boolean) };
  }

  if ('hideTrademark' in values) {
    result.hideTrademark = { enabled: stringToBoolean(values.hideTrademark as string | boolean) };
  }

  if ('logoEnabled' in values || 'logoPath' in values) {
    result.logo = {
      enabled:
        stringToBoolean(values.logoEnabled as string | boolean) || stringToBoolean(values.logo as string | boolean),
      path: values.logoPath || '',
    };
  }

  if ('rating' in values) {
    result.rating = { enabled: stringToBoolean(values.rating as string | boolean) };
  }

  if ('boosters' in values) {
    result.boosters = {
      enabled: !!values.boosters,
      items: values.boosters ? JSON.parse(values.boosters as string) : null,
    };
  }

  if ('homescreenEnabled' in values) {
    result.homeScreen = {
      enabled: !!values.homescreenEnabled,
    };
  }

  return result as WidgetFeaturesInterface;
};

export const deserializeWidgetSnapshotFeatures = (groupProperties: IGroupProperties): WidgetFeaturesInterface =>
  mapToWidgetSnapshotFeaturesProps(
    deserializeWidgetSnapshot(
      groupProperties,
      { ...WidgetSpanshotFeaturesProps, name: 'chat_window.new_theme.name' },
      {}
    )
  );

export const extractWidgetSnapshotFeatures = (values: { [key: string]: any }) =>
  mapToWidgetSnapshotFeaturesProps(values);

// WIDGET THEME UTILS
enum WidgetSnapshotThemeProps {
  name = 'chat_window.new_theme.name',
  variant = 'chat_window.new_theme.variant',
  agentMessageColorBackground = 'chat_window.new_theme.agent_message_color_background',
  agentMessageColorText = 'chat_window.new_theme.agent_message_color_text',
  agentbarBackgroundColor = 'chat_window.new_theme.agentbar_background_color',
  agentbarText = 'chat_window.new_theme.agentbar_text',
  backgroundColor = 'chat_window.new_theme.background_color',
  minimizedColorBackground = 'chat_window.new_theme.minimized_color_background',
  minimizedColorIcon = 'chat_window.new_theme.minimized_color_icon',
  minimizedColorText = 'chat_window.new_theme.minimized_color_text',
  visitorMessageColorText = 'chat_window.new_theme.visitor_message_color_text',
  visitorMessageColorBackground = 'chat_window.new_theme.visitor_message_color_background',
  titlebarBackgroundColor = 'chat_window.new_theme.titlebar_background_color',
  titlebarText = 'chat_window.new_theme.titlebar_text',
  systemMessageColor = 'chat_window.new_theme.system_message_color',
  ctaColor = 'chat_window.new_theme.cta_color',
}

const initialWidgetSnapshotThemeProps: WidgetThemeInterface = {
  name: Skin.Smooth,
  variant: ThemeVariant.Light,
  agentMessageColorBackground: '',
  agentMessageColorText: '',
  agentbarBackgroundColor: '',
  agentbarText: '',
  backgroundColor: '',
  minimizedColorBackground: '',
  minimizedColorIcon: '',
  minimizedColorText: '',
  visitorMessageColorText: '',
  visitorMessageColorBackground: '',
  titlebarBackgroundColor: '',
  titlebarText: '',
  systemMessageColor: '',
  ctaColor: '',
};

export const serializeWidgetSnapshotTheme = (newValues: WidgetThemeInterface): IGroupProperties =>
  serializeWidgetSnapshot(newValues, WidgetSnapshotThemeProps);
export const extractWidgetSnapshotTheme = (values: { [key: string]: any }): WidgetThemeInterface =>
  extractWidgetSnapshot(values, initialWidgetSnapshotThemeProps) as WidgetThemeInterface;

const deserializeColorsProps = (values: Record<string, string>): Record<string, string> =>
  Object.keys(values).reduce(
    (acc, key) => ({
      ...acc,
      [key]: isColor(values[key]) && isRGB(values[key]) ? getHexFromRGB(values[key]) : values[key],
    }),
    {}
  );

// We need to convert rgb to hex for ChatIO former licenses
export const deserializeWidgetSnapshotTheme = (groupProperties: IGroupProperties): WidgetThemeInterface =>
  deserializeWidgetSnapshot(
    deserializeColorsProps(groupProperties),
    WidgetSnapshotThemeProps,
    initialWidgetSnapshotThemeProps
  ) as WidgetThemeInterface;
