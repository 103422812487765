import { type IFormConfiguration } from 'interfaces/helpdesk';

import { BaseApi, PassVersionMode } from '../base-api';

import { type ILicense, type ITicketRequestBody } from './interfaces';

export class HelpDeskApi extends BaseApi {
  protected readonly passVersionMode: PassVersionMode = PassVersionMode.Prefix;
  protected readonly version = '1';

  createTicket = (body: ITicketRequestBody) => this.post('externalTickets', body);
  fetchLicenses = () => this.get<ILicense[]>('licenses');
  fetchHelpdeskFormConfiguration = (license: number, group: string) =>
    this.get<IFormConfiguration>(`contactForms/formSettings`, {
      lcLicenseID: license,
      lcGroupID: group,
    });
}
