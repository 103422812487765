import type { Experiment } from 'interfaces/experiment';
import { createAction, type ActionsUnion } from 'store/helper';

export enum ExperimentsActionNames {
  FETCH_EXPERIMENTS_REQUEST = 'ENTITIES/EXPERIMENTS/FETCH_EXPERIMENTS_REQUEST',
  FETCH_EXPERIMENTS_SUCCESS = 'ENTITIES/EXPERIMENTS/FETCH_EXPERIMENTS_SUCCESS',
  FETCH_EXPERIMENTS_FAILURE = 'ENTITIES/EXPERIMENTS/FETCH_EXPERIMENTS_FAILURE',
}

export const ExperimentsActions = {
  fetchExperiments: () => createAction(ExperimentsActionNames.FETCH_EXPERIMENTS_REQUEST),
  fetchExperimentsSuccess: (experiments: Experiment[]) =>
    createAction(ExperimentsActionNames.FETCH_EXPERIMENTS_SUCCESS, { experiments }),
  fetchExperimentsFailure: (error) => createAction(ExperimentsActionNames.FETCH_EXPERIMENTS_FAILURE, error),
};

export type ExperimentAction = ActionsUnion<typeof ExperimentsActions>;
