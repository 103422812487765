import { createAction, type ActionsUnion } from 'store/helper';

import { type ISetFacebookPagesPayload } from './interfaces';

export enum FacebookActionsEnum {
  SET_FACEBOOK_CONNECTED_PAGES = 'ENTITIES/FACEBOOK/SET_FACEBOOK_CONNECTED_PAGES',
  FETCH_FACEBOOK_CONNECTED_PAGES = 'ENTITIES/FACEBOOK/FETCH_FACEBOOK_CONNECTED_PAGES',
}

export const FacebookActions = {
  setFacebookMessengerPages: (payload: ISetFacebookPagesPayload) => {
    console.log('setting pages');

    return createAction(FacebookActionsEnum.SET_FACEBOOK_CONNECTED_PAGES, payload);
  },
  fetchFacebookConnectedPages: () => createAction(FacebookActionsEnum.FETCH_FACEBOOK_CONNECTED_PAGES),
};

export type FacebookActionType = ActionsUnion<typeof FacebookActions>;
