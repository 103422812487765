import { BaseApi } from '../base-api';

export interface IUpdateCompanyDetailsParams {
  invoice_email?: string;
}

interface IUpdateCompanyDetailsResponse {
  ok: boolean;
}

export class CompanyDetailsApi extends BaseApi {
  protected readonly prefix = 'subscription/company_details';

  fetch = () => this.get();
  update = (params: IUpdateCompanyDetailsParams) => this.put<IUpdateCompanyDetailsResponse>('', { ...params });
}
