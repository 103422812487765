// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import type { Filters, Filter } from 'constants/filters/filter';
import { BREAKDOWN_MAX_CHECKED_ROWS } from 'constants/reports/breakdown';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { sumReducer, sumArraysValues } from 'helpers/numbers';
import { getSeriesDataFromPeriod } from 'helpers/reports';
import type { IGreetingsConversionPeriodData, IReportsViewGroupFilter } from 'interfaces/reports';
import type { ReportData } from 'interfaces/reports/api-v3';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';
import { type IGreeting, TargetedMessageSubtype } from 'store/entities/greetings/interfaces';
import { getGreetings } from 'store/entities/greetings/selectors';

import { getFilterGroup, getGreetingFilter } from '../selectors';

interface IGreetingsConversionPayload {
  data: {
    greetingsConversion: ReportData<IGreetingsConversionPeriodData>;
  };
}

export function* greetingsConversionDeserializer({ data }: IGreetingsConversionPayload): SagaIterator {
  const greetings: IGreeting[] = yield select(getGreetings);
  const groupFilter: IReportsViewGroupFilter = yield select(getFilterGroup);
  const greetingFilter: Filters[Filter.Greeting] = yield select(getGreetingFilter);

  const series = [];
  const breakdown = [];
  const summary = [];

  let totalDisplayed = new Array(Object.keys(data.greetingsConversion).length).fill(0);
  let totalAccepted = new Array(Object.keys(data.greetingsConversion).length).fill(0);

  greetings
    .filter((greeting) => {
      if (greetingFilter && greetingFilter.length) {
        return greetingFilter.includes(greeting.id);
      }

      if (groupFilter && groupFilter.groups) {
        return groupFilter.groups.includes(greeting.groupId);
      }

      return greeting.subtype !== TargetedMessageSubtype.Announcement;
    })
    .forEach((greeting) => {
      const displayed = getSeriesDataFromPeriod<number>(data.greetingsConversion, greeting.id, 'displayed');
      const accepted = getSeriesDataFromPeriod<number>(data.greetingsConversion, greeting.id, 'accepted');

      const displayedSum = displayed.reduce(sumReducer, 0);
      const acceptedSum = accepted.reduce(sumReducer, 0);
      const getReportName = (namePart: string): string => [greeting.name, namePart].join(' - ');

      // Displayed
      summary.push({
        group: greeting.id,
        name: getReportName('Displayed'),
        nameParts: ['Displayed'],
        color: DEFAULT_SERIES_COLOR,
        value: displayedSum,
        disableLink: true,
      });

      series.push({
        group: greeting.id,
        name: getReportName('Displayed'),
        color: DEFAULT_SERIES_COLOR,
        csvColumnName: `${greeting.name} displayed`,
        data: displayed,
      });

      // Accepted
      summary.push({
        group: greeting.id,
        name: getReportName('Chats'),
        nameParts: ['Chats'],
        color: DEFAULT_SERIES_COLOR,
        value: acceptedSum,
      });

      series.push({
        group: greeting.id,
        name: getReportName('Chats'),
        color: DEFAULT_SERIES_COLOR,
        csvColumnName: `${greeting.name} chats`,
        data: accepted,
      });

      breakdown.push({
        id: greeting.id,
        greeting,
        displayed: displayedSum,
        accepted: acceptedSum,
        conversion: acceptedSum / displayedSum,
      });

      totalDisplayed = sumArraysValues(totalDisplayed, displayed);
      totalAccepted = sumArraysValues(totalAccepted, accepted);
    });

  const totalDisplayedSum = totalDisplayed.reduce(sumReducer, 0);
  const totalAcceptedSum = totalAccepted.reduce(sumReducer, 0);
  const acceptedSummaryLabel = `(${getFormattedPercent(totalAcceptedSum / (totalDisplayedSum || 1), 2)})`;

  const acceptedLabels = totalAccepted.map(
    (acceptedCount, index) => `(${getFormattedPercent(acceptedCount / (totalDisplayed[index] || 0), 2)})`
  );

  series.push({
    group: null,
    name: 'Campaigns displayed',
    color: DEFAULT_SERIES_COLOR,
    data: totalDisplayed,
    disableLink: true, // ToDo: Plz Fix and Enable link after https://livechatinc.atlassian.net/browse/AA-11686 issue
  });

  series.push({
    group: null,
    name: 'Chats from campaigns',
    color: DEFAULT_SERIES_COLOR,
    data: totalAccepted,
    label: acceptedLabels,
    disableLink: true, // ToDo: Plz Fix and Enable link after https://livechatinc.atlassian.net/browse/AA-11686 issue
  });

  summary.push({
    name: 'Campaigns displayed',
    color: DEFAULT_SERIES_COLOR,
    value: totalDisplayedSum,
    disableLink: true, // ToDo: Plz Fix and Enable link after https://livechatinc.atlassian.net/browse/AA-11686 issue,
  });

  summary.push({
    name: 'Chats from campaigns',
    color: DEFAULT_SERIES_COLOR,
    value: totalAcceptedSum,
    label: acceptedSummaryLabel,
    disableLink: true, // ToDo: Plz Fix and Enable link after https://livechatinc.atlassian.net/browse/AA-11686 issue
  });

  return {
    greetingsConversion: {
      summary,
      labels: Object.keys(data.greetingsConversion),
      series,
      breakdown,
    },
    checked: greetingFilter && greetingFilter.length ? greetingFilter.slice(0, BREAKDOWN_MAX_CHECKED_ROWS) : [],
  };
}
