import { type Action } from 'redux';
import { type SagaIterator } from 'redux-saga';
import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { ActionHandlerExecutionDelay, ToastContent } from 'constants/toasts';
import { type EventPlace } from 'helpers/analytics';
import { downloadFile } from 'helpers/download';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type IEmailiniumTranscriptBody } from 'services/api/emailinium/api';
import { AppStateProvider } from 'services/app-state-provider';
import { trackEvent } from 'services/event-tracking';
import { type IActionWithPayload } from 'store/helper';

import { ToastsActions } from '../toasts/actions';
import { ToastVariant } from '../toasts/interfaces';

import { TranscriptFeatureActions, TranscriptFeatureActionsNames } from './actions';
import { type IDownloadTranscriptPayload, type ISendTranscriptPayload } from './interfaces';

const handleSendTranscriptRetry =
  (chatId: string, threadId: string, email: string, shouldIncludeAllDetails: boolean, eventPlace: EventPlace) =>
  (): void => {
    setTimeout(() => {
      AppStateProvider.dispatch(
        TranscriptFeatureActions.sendTranscript(chatId, threadId, email, shouldIncludeAllDetails, eventPlace),
      );
    }, ActionHandlerExecutionDelay.Short);
  };

const sendTranscriptSuccess = (): Action =>
  ToastsActions.createToast({
    content: getToastContent(ToastContent.SEND_TRANSCRIPT_SUCCESS),
    kind: ToastVariant.Success,
  });

const sendTranscriptError = (
  chatId: string,
  threadId: string,
  email: string,
  shouldIncludeAllDetails: boolean,
  eventPlace: EventPlace,
): Action =>
  ToastsActions.createToast({
    content: getToastContent(ToastContent.SEND_TRANSCRIPT_ERROR),
    kind: ToastVariant.Error,
    action: {
      label: 'Retry',
      onClick: handleSendTranscriptRetry(chatId, threadId, email, shouldIncludeAllDetails, eventPlace),
      closeOnClick: true,
    },
  });

function* sendTranscript(
  chatId: string,
  threadId: string,
  email: string,
  shouldIncludeAllDetails: boolean,
  eventPlace: EventPlace,
): SagaIterator {
  const { emailiniumApi } = ApiManager;

  const payload: IEmailiniumTranscriptBody = {
    recipient: email,
    /* eslint-disable @typescript-eslint/naming-convention */
    chat_id: chatId,
    thread_id: threadId,
    include_system_messages: shouldIncludeAllDetails,
    include_supervisors_and_private_notes: shouldIncludeAllDetails,
    include_session_fields: shouldIncludeAllDetails,
    include_geolocation: shouldIncludeAllDetails,
    include_referrer: shouldIncludeAllDetails,
    use_visitor_timezone: true,
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  const response: RequestResult<unknown> = yield call(emailiniumApi.sendTranscript, payload);

  if (response.result) {
    yield put(TranscriptFeatureActions.sendTranscriptSuccess(threadId, email, shouldIncludeAllDetails));
    yield put(sendTranscriptSuccess());
    trackEvent('Transcript sent', eventPlace);
  } else {
    yield put(TranscriptFeatureActions.sendTranscriptFailure(threadId));
    yield put(sendTranscriptError(chatId, threadId, email, shouldIncludeAllDetails, eventPlace));
  }
}

export function* downloadTranscript({
  payload,
}: IActionWithPayload<TranscriptFeatureActionsNames, IDownloadTranscriptPayload>): SagaIterator {
  const { threadId } = payload;
  const { transcriptApi } = ApiManager;

  const response: RequestResult<{ chat: string }> = yield call(transcriptApi.download, threadId);

  if (response.result) {
    yield call(downloadFile, `LiveChat_transcript_${threadId}.txt`, response.result.chat, 'text/plain');
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.DOWNLOAD_TRANSCRIPT_ERROR),
        kind: ToastVariant.Error,
      }),
    );
  }
}

function* watchSendTranscriptRequest(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<TranscriptFeatureActionsNames, ISendTranscriptPayload> = yield take(
      TranscriptFeatureActionsNames.SEND_TRANSCRIPT_REQUEST,
    );
    yield fork(
      sendTranscript,
      payload.chatId,
      payload.threadId,
      payload.email,
      payload.shouldIncludeAllDetails,
      payload.eventPlace,
    );
  }
}

export default function* transcriptFeatureSagas(): SagaIterator {
  yield all([
    takeEvery(TranscriptFeatureActionsNames.DOWNLOAD_TRANSCRIPT_REQUEST, downloadTranscript),
    fork(watchSendTranscriptRequest),
  ]);
}
