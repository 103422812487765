import { type AIAgentsEntitiesAction, AIAgentsEntitiesActionNames } from './actions';
import { type AIAgentsEntitiesState } from './interfaces';

const initialState: AIAgentsEntitiesState = {
  data: {},
  publicSourceIds: [],
  privateSourceIds: [],
};

export const aiAgentsEntitiesReducer = (
  state = initialState,
  action: AIAgentsEntitiesAction,
): AIAgentsEntitiesState => {
  switch (action.type) {
    case AIAgentsEntitiesActionNames.SAVE_AI_AGENTS: {
      return {
        ...action.payload,
      };
    }
    case AIAgentsEntitiesActionNames.CHANGE_SOURCE_TYPE: {
      const { sourceId } = action.payload;
      const isCurrentlyPublic = state.publicSourceIds.includes(sourceId);
      const publicSourceIds = isCurrentlyPublic
        ? state.publicSourceIds.filter((id) => id !== sourceId)
        : [...state.publicSourceIds, sourceId];
      const privateSourceIds = isCurrentlyPublic
        ? [...state.privateSourceIds, sourceId]
        : state.privateSourceIds.filter((id) => id !== sourceId);

      return {
        ...state,
        publicSourceIds,
        privateSourceIds,
      };
    }
    case AIAgentsEntitiesActionNames.ASSIGN_SOURCE_TO_AI_AGENT: {
      const { skillId, agentIds } = action.payload;
      const containsReplySuggestionsAgentId = agentIds.some((agentId) =>
        Object.values(state.data).some((agent) => agent.id === agentId && agent.name === 'Reply Suggestions'),
      );

      const publicSourceIds = containsReplySuggestionsAgentId
        ? [...state.publicSourceIds, skillId]
        : state.publicSourceIds;
      const privateSourceIds = containsReplySuggestionsAgentId
        ? state.privateSourceIds
        : [...state.privateSourceIds, skillId];

      return {
        ...state,
        publicSourceIds,
        privateSourceIds,
      };
    }
    default:
      return state;
  }
};
