// @ts-strict-ignore
import type { ReactNode } from 'react';

import { ActiveExperiment } from 'constants/active-experiment';
import { type GroupProperty, type IGroupProperties } from 'interfaces/entities/group-property';
import { type GroupId } from 'interfaces/groups';
import type { IStoreState } from 'interfaces/store/store-state';
import { type IWidgetCustomization } from 'services/api/group-properties/interfaces';
import { deserializeWidgetCustomization } from 'services/api/group-properties/serializers';
import { isGroupB } from 'store/entities/experiments/selectors';
import {
  getGroupProperties,
  getGroupProperty,
  type IWithGroupPropertiesState,
} from 'store/entities/group-properties/selectors';

import type { ISettingsViewState } from './interfaces';

export interface IWithSettingsViewState {
  views: {
    settings: ISettingsViewState;
  };
}

export const showTicketEmailForwardingIntroduction = (state: IWithSettingsViewState): boolean =>
  state.views.settings.showTicketEmailForwardingIntroduction;

export const getShowBackButton = (state: IWithSettingsViewState): boolean => state.views.settings.showBackButton;

export const getNavigateBackTo = (state: IWithSettingsViewState): string => state.views.settings.navigateBackTo;

export const getLeftNodes = (state: IWithSettingsViewState): ReactNode => state.views.settings.leftNodes;

export const getWidgetPreviewBottomNote = (state: IWithSettingsViewState): ReactNode =>
  state.views.settings.widgetPreviewBottomNote;

export const getIsGroupSelectHidden = (state: IWithSettingsViewState): boolean =>
  state.views.settings.isGroupSelectHidden;

export const getIsWidgetPreviewHidden = (state: IWithSettingsViewState): boolean =>
  state.views.settings.isWidgetPreviewHidden;

export const getIsFullScreen = (state: IWithSettingsViewState): boolean => state.views.settings.isFullScreen;

export const getPageTitle = (state: IStoreState): string | ReactNode => state.views.settings.pageTitle;

export const getCurrentPageId = (state: IStoreState): string | null => state.views.settings.currentPageId;

export const getSelectedGroupId = (state: IStoreState): GroupId => state.views.settings.selectedGroupId;

export const getIsUpgradeModalOpened = (state: IStoreState): boolean => state.views.settings.isUpgradeModalOpened;
export const getIsBulkGroupRoutingSettingsEnabled = (state: IStoreState): boolean =>
  isGroupB(state, ActiveExperiment.BulkGroupRoutingSettings);

export const getWidgetCustomization = (state: IStoreState): IWidgetCustomization => {
  const groupId = getSelectedGroupId(state);
  const groupProperties = getGroupProperties(state, groupId.toString());

  return groupProperties ? deserializeWidgetCustomization(groupProperties) : null;
};

export function getCurrentGroupProperties(state: IWithGroupPropertiesState & IStoreState): IGroupProperties {
  const groupId = getSelectedGroupId(state);

  return getGroupProperties(state, groupId.toString());
}

export function getCurrentGroupProperty(
  state: IWithGroupPropertiesState & IStoreState,
  property: GroupProperty,
): string {
  const groupId = getSelectedGroupId(state);

  return getGroupProperty(state, groupId, property);
}

export const getSelectedGroupName = (state: IStoreState): string =>
  state.entities.groups[getSelectedGroupId(state)].name;

export const getRecentPage = (state: IWithSettingsViewState): string => state.views.settings.recentPage;
