import makeSubject from 'callbag-subject';
import { type Middleware } from 'redux';

export const actions$ = makeSubject();

export const actionStreamMiddleware: Middleware = () => (next) => (action) => {
  actions$(1, action);

  return next(action);
};
