import keyBy from 'lodash.keyby';

import { type KeyMap } from 'helpers/interface';
import type {
  Group,
  GroupsResetPayload,
  GroupAddedPayload,
  GroupUpdatedPayload,
  GroupRemovedPayload,
} from 'interfaces/groups';

import { type GroupAction, GroupActionNames } from './actions';

const initialState = {};

export const groupsReducer = (state: KeyMap<Group> = initialState, action: GroupAction): KeyMap<Group> => {
  switch (action.type) {
    case GroupActionNames.GROUPS_RESET: {
      return keyBy(action.payload as GroupsResetPayload, 'id');
    }

    case GroupActionNames.GROUP_ADD: {
      const payload = action.payload as GroupAddedPayload;

      return { ...state, [payload.id]: { ...payload } };
    }

    case GroupActionNames.GROUP_UPDATE: {
      const payload = action.payload as GroupUpdatedPayload;

      if (!state[payload.id]) {
        return state;
      }

      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload,
        },
      };
    }

    case GroupActionNames.GROUP_REMOVE: {
      const payload = action.payload as GroupRemovedPayload;
      const newState = { ...state };
      delete newState[payload.id];

      return newState;
    }

    default:
      return state;
  }
};
