import { type EventPlace } from 'helpers/analytics';
import { createAction } from 'store/helper';

import {
  type IDownloadTranscriptPayload,
  type ISendTranscriptFailurePayload,
  type ISendTranscriptPayload,
  type ISendTranscriptSuccessPayload,
} from './interfaces';

export enum TranscriptFeatureActionsNames {
  SEND_TRANSCRIPT_REQUEST = 'SEND_TRANSCRIPT_REQUEST',
  SEND_TRANSCRIPT_SUCCESS = 'SEND_TRANSCRIPT_SUCCESS',
  SEND_TRANSCRIPT_FAILURE = 'SEND_TRANSCRIPT_FAILURE',
  DOWNLOAD_TRANSCRIPT_REQUEST = 'DOWNLOAD_TRANSCRIPT_REQUEST',
  DOWNLOAD_TRANSCRIPT_SUCCESS = 'DOWNLOAD_TRANSCRIPT_SUCCESS',
  DOWNLOAD_TRANSCRIPT_FAILURE = 'DOWNLOAD_TRANSCRIPT_FAILURE',
}

export const TranscriptFeatureActions = {
  sendTranscript: (
    chatId: string,
    threadId: string,
    email: string,
    shouldIncludeAllDetails: boolean,
    eventPlace: EventPlace
  ) =>
    createAction(TranscriptFeatureActionsNames.SEND_TRANSCRIPT_REQUEST, {
      chatId,
      threadId,
      email,
      shouldIncludeAllDetails,
      additionalRequestId: threadId,
      eventPlace,
    } as ISendTranscriptPayload),

  sendTranscriptSuccess: (threadId: string, email: string, shouldIncludeAllDetails: boolean) =>
    createAction(TranscriptFeatureActionsNames.SEND_TRANSCRIPT_SUCCESS, {
      threadId,
      email,
      shouldIncludeAllDetails,
      additionalRequestId: threadId,
    } as ISendTranscriptSuccessPayload),

  sendTranscriptFailure: (threadId: string) =>
    createAction(TranscriptFeatureActionsNames.SEND_TRANSCRIPT_FAILURE, {
      additionalRequestId: threadId,
    } as ISendTranscriptFailurePayload),

  downloadTranscript: (payload: IDownloadTranscriptPayload) =>
    createAction(TranscriptFeatureActionsNames.DOWNLOAD_TRANSCRIPT_REQUEST, payload),
};
