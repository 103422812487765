/* eslint-disable @typescript-eslint/naming-convention */
import type { IAppState } from 'services/app-state-provider';
import { CannedResponseActions } from 'store/entities/canned-responses/actions';
import { type ICannedResponseAddPayload } from 'store/entities/canned-responses/interfaces';

export function handleCannedResponseAdd(payload: ICannedResponseAddPayload, store: IAppState): void {
  const { group, canned_response } = payload;
  const { id, creation_date, tags, text, created_by, private: isPrivate } = canned_response;

  store.dispatch(
    CannedResponseActions.addCannedResponseData({
      groupId: String(group),
      id: String(id),
      modificationTimestampInMs: creation_date * 1000,
      tags,
      text,
      createdBy: created_by,
      isPrivate,
    })
  );
}
