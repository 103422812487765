import { type KeyMap } from 'helpers/interface';

export enum ReportType {
  Last7days = 'last-7-days',
  TotalChats = 'total-chats',
  ChatEngagement = 'chat-engagement',
  TotalChatsHeatmap = 'total-chats-heatmap',
  Greetings = 'greetings',
  ChatRatings = 'chat-ratings',
  ChatDuration = 'chat-duration',
  Overview = 'overview',
  RecentInsights = 'recent-insights',
  TopCustomerQuestions = 'top-customer-questions',
  Alerts = 'alerts',
  MissedChats = 'missed-chats',
  MissedChatsHeatmap = 'missed-chats-heatmap',
  ChatResponseTime = 'chat-response-times',
  StaffingPrediction = 'staffing-prediction',
  ChatAvailability = 'availability',
  Activity = 'activity',
  AgentsPerformance = 'agents-performance',
  QueuedCustomers = 'queued-visitors',
  QueuedCustomersHeatmap = 'queued-visitors-heatmap',
  QueueAbandonment = 'queue-abandonment',
  ChatForms = 'chat-forms',
  NewTickets = 'new-tickets',
  NewTicketsHeatmap = 'new-tickets-heatmap',
  TicketRatings = 'ticket-ratings',
  TicketFirstResponseTime = 'ticket-first-response-time',
  SolvedTickets = 'solved-tickets',
  TicketResolutionTime = 'ticket-resolution-time',
  Goals = 'goals',
  Sales = 'sales',
  ScheduledReports = 'scheduled-reports',
  TagsUsage = 'tags-usage',
  ChatTopics = 'chat-topics',
}

/*
  This type will be expanded with subsequent ReportTypes that are migrated to react-query.
*/
export type ReduxReportData = Exclude<
  ReportType,
  | ReportType.Last7days
  | ReportType.NewTicketsHeatmap
  | ReportType.QueuedCustomersHeatmap
  | ReportType.TotalChatsHeatmap
  | ReportType.MissedChatsHeatmap
  | ReportType.ChatEngagement
  | ReportType.TotalChats
  | ReportType.ChatAvailability
  | ReportType.ChatTopics
  | ReportType.QueuedCustomers
  | ReportType.QueueAbandonment
>;

type IReportViewDetail = {
  name: string;
  title?: string;
  category: string;
  order: number;
};

export type IReportViewDetails = Readonly<KeyMap<Readonly<IReportViewDetail>>>;

export const reportViewsDetails: IReportViewDetails = {
  [ReportType.Last7days]: {
    name: 'Last 7 days',
    category: 'Summary',
    order: 1,
  },
  dashboard: {
    name: 'Dashboard',
    category: 'Summary',
    order: 2,
  },
  [ReportType.TotalChats]: {
    name: 'Total Chats',
    category: 'Chats',
    order: 3,
  },
  [ReportType.ChatTopics]: {
    name: 'Chat Topics',
    category: 'Chats',
    order: 4,
  },
  [ReportType.ChatEngagement]: {
    name: 'Chat Engagement',
    category: 'Chats',
    order: 5,
  },
  [ReportType.MissedChats]: {
    name: 'Missed chats',
    category: 'Chats',
    order: 6,
  },
  [ReportType.Greetings]: {
    name: 'Campaigns conversion',
    category: 'Chats',
    order: 7,
  },
  [ReportType.TagsUsage]: {
    name: 'Tags usage',
    category: 'Chats',
    order: 8,
  },
  [ReportType.ChatRatings]: {
    name: 'Chat satisfaction',
    category: 'Chats',
    order: 9,
  },
  [ReportType.ChatAvailability]: {
    name: 'Availability',
    title: 'Chat availability',
    category: 'Chats',
    order: 10,
  },
  [ReportType.ChatForms]: {
    name: 'Chat surveys',
    title: 'Chat forms',
    category: 'Chats',
    order: 11,
  },
  [ReportType.ChatDuration]: {
    name: 'Chat duration',
    category: 'Agents',
    order: 12,
  },
  [ReportType.AgentsPerformance]: {
    name: 'Agent performance',
    category: 'Agents',
    order: 13,
  },
  [ReportType.ChatResponseTime]: {
    name: 'Response times',
    title: 'Chat response times',
    category: 'Agents',
    order: 14,
  },
  [ReportType.StaffingPrediction]: {
    name: 'Staffing prediction',
    category: 'Agents',
    order: 15,
  },
  [ReportType.Activity]: {
    name: 'Agent activity',
    category: 'Agents',
    order: 16,
  },
  [ReportType.QueuedCustomers]: {
    name: 'Queued visitors',
    title: 'Queued customers',
    category: 'Customers',
    order: 17,
  },
  [ReportType.QueueAbandonment]: {
    name: 'Queue abandonment',
    category: 'Customers',
    order: 18,
  },
  [ReportType.NewTickets]: {
    name: 'New tickets',
    category: 'Tickets',
    order: 19,
  },
  [ReportType.TicketRatings]: {
    name: 'Ticket satisfaction',
    category: 'Tickets',
    order: 20,
  },
  [ReportType.TicketFirstResponseTime]: {
    name: 'First response time',
    category: 'Tickets',
    order: 21,
  },
  [ReportType.SolvedTickets]: {
    name: 'Solved tickets',
    category: 'Tickets',
    order: 22,
  },
  [ReportType.TicketResolutionTime]: {
    name: 'Resolution time',
    category: 'Tickets',
    order: 23,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'ticket-sources': {
    name: 'Ticket sources',
    category: 'Tickets',
    order: 24,
  },
  [ReportType.Goals]: {
    name: 'Goals',
    title: 'Achieved goals',
    category: 'Ecommerce',
    order: 25,
  },
  [ReportType.Sales]: {
    name: 'Tracked sales',
    category: 'Ecommerce',
    order: 26,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'generate-report': {
    name: 'Generate report',
    category: 'Export',
    order: 27,
  },
  [ReportType.ScheduledReports]: {
    name: 'Scheduled Reports',
    category: 'Export',
    order: 28,
  },
};
