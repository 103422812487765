import { type FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { ThemeName } from 'constants/themes';
import { getThemeOnCurrentSection } from 'store/features/dark-mode/selectors';

interface IProps {
  shouldBeInverted: boolean;
}

const getInvertedTheme = (theme: ThemeName): ThemeName => {
  if (theme === ThemeName.Light) {
    return ThemeName.Dark;
  }

  return ThemeName.Light;
};

export const ThemeInverter: FC<IProps> = ({ children, shouldBeInverted }) => {
  const currentTheme = useSelector(getThemeOnCurrentSection);
  const [invertedTheme, setInvertedTheme] = useState(currentTheme);

  useEffect(() => {
    if (shouldBeInverted) {
      setInvertedTheme(getInvertedTheme(currentTheme));
    } else {
      setInvertedTheme(currentTheme);
    }
  }, [currentTheme, shouldBeInverted]);

  return <div className={`lc-${invertedTheme}-theme`}>{children}</div>;
};
