import TraceKit from 'tracekit';

import { App } from 'config/setup';
import ChatWidget from 'services/chat-widget';
import { getDesktopApp } from 'services/desktop-application/desktop-app';
import { enableBusinessEventsLogging } from 'services/event-tracking';

/**
 * This method requires store to be initialized before running.
 * You can still intentionally run it without initialized store
 * but the tracked data will be incomplete (e.g. no agent's email value).
 * Example cases for incomplete data: error pages, logout screen.
 */
export function initializeTrackingTools(): void {
  enableBusinessEventsLogging();

  TraceKit.report.subscribe((stack) => {
    getDesktopApp().saveAdvancedLog(stack as unknown as string);
  });

  ChatWidget.setUpCallbacks();

  App.initGoogleAnalytics?.();
  App.setupGTM?.();
  App.setupAmplitudeUserProperties?.();
}
