// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, call, fork, put, take } from 'redux-saga/effects';

import { setAttachmentSafetyConfirmation } from 'services/web-socket/actions/set-attachment-safety-confirmation';
import { type IActionWithPayload } from 'store/helper';

import { AttachmentsFeatureActions, AttachmentsFeatureActionsNames } from './actions';
import { type IUpdateAttachmentSafetyConfirmationPayload } from './interfaces';

export function* updateSafetyConfirmation(payload: IUpdateAttachmentSafetyConfirmationPayload): SagaIterator {
  const { chatId, threadId, eventId, isSafetyConfirmed, historyThreadId } = payload;
  const targetThreadId = historyThreadId ?? threadId;

  try {
    yield call(setAttachmentSafetyConfirmation, chatId, targetThreadId, eventId, isSafetyConfirmed);
    yield put(AttachmentsFeatureActions.updateAttachmentSafetyConfirmationSuccess(payload));
  } catch (error) {
    yield put(AttachmentsFeatureActions.updateAttachmentSafetyConfirmationFailure({ ...payload, error }));
  }
}

export function* watchSafetyConfirmationUpdateRequest(): SagaIterator {
  while (true) {
    const { payload }: IActionWithPayload<AttachmentsFeatureActionsNames, IUpdateAttachmentSafetyConfirmationPayload> =
      yield take(AttachmentsFeatureActionsNames.UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_REQUEST);
    yield fork(updateSafetyConfirmation, payload);
  }
}

export default function* attachmentsFeatureSagas(): SagaIterator {
  yield all([fork(watchSafetyConfirmationUpdateRequest)]);
}
