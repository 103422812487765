// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';

import { PostMessageEvent } from 'constants/post-message-event';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import type { RequestResult } from 'interfaces/api/client';
import type { CreateCartResult, Product, ProductsCart } from 'interfaces/product-cart';
import { ApiManager } from 'services/api/api-manager';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { ProductCartActions } from 'store/entities/product-cart/actions';
import { getCurrentCart, getCurrentCartProductsToSubscribe } from 'store/entities/product-cart/selectors';

/**
 * Load new cart or create new one and add it to the store and fetch applications list
 */
export function* refreshApplicationsData(): SagaIterator {
  yield put(ApplicationsActions.refreshInstalledApplicationsData({}));

  let recentCartResponse: RequestResult<ProductsCart> = yield call(ApiManager.productCartApi.fetchRecent);
  if (recentCartResponse.error) {
    yield call(ApiManager.productCartApi.create);
    recentCartResponse = yield call(ApiManager.productCartApi.fetchRecent);
  }

  yield put(ProductCartActions.saveCurrentCart(recentCartResponse.result));
  // Send updated cart to the embedded Marketplace
  yield call(sendPostMessageToMarketplace, PostMessageEvent.CartUpdated, {
    cart: recentCartResponse.result,
  });
}

/**
 * Finalize cart realize process
 * @param {string} cartId
 */
function* checkoutAndPurchase(cartId: string): SagaIterator {
  const checkoutCartResponse: RequestResult<CreateCartResult> = yield call(
    ApiManager.productCartApi.startCheckout,
    cartId
  );
  if (checkoutCartResponse.error) {
    throw checkoutCartResponse.error;
  }

  const purchaseCartResponse: RequestResult<CreateCartResult> = yield call(ApiManager.productCartApi.purchase, cartId);
  if (purchaseCartResponse.error) {
    throw purchaseCartResponse.error;
  }
}

/**
 * Realizes current cart with applications marked for install
 */
export function* realizeCurrentCart(): SagaIterator {
  const cartProductsMarkedToSubscribe: Product[] = yield select(getCurrentCartProductsToSubscribe);

  if (!cartProductsMarkedToSubscribe.length) {
    return;
  }

  const currentCart: ProductsCart = yield select(getCurrentCart);

  // Remove applications not marked to install from the cart
  // This should be changed when mechanism of selecting products to subscribe will ne implemented on backend side
  yield all(
    currentCart.products?.map(
      (product) =>
        !product.isMarkedForInstall && call(ApiManager.productCartApi.removeApplication, currentCart.id, product.id)
    )
  );

  yield call(checkoutAndPurchase, currentCart.id);
}
