import { type IActionWithPayload } from 'store/helper';

import { type GreetingViewActionTypes, GreetingViewActionsEnum } from './actions';
import { type GreetingsViewState, type IGreetingTableSortSchema } from './interfaces';

const initialState: GreetingsViewState = {
  name: 'New campaign',
  isActive: true,
  tableSortRules: {
    recurring: [],
    'one-time': [],
  },
};

export const greetingsReducer = (
  state: GreetingsViewState = initialState,
  action: GreetingViewActionTypes
): GreetingsViewState => {
  switch (action.type) {
    case GreetingViewActionsEnum.SET_GREETING_DRAFT: {
      const { name, isActive } = action.payload;

      return {
        ...state,
        name,
        isActive,
      };
    }
    case GreetingViewActionsEnum.SET_TABLE_SORT: {
      const { payload } = action as IActionWithPayload<string, Partial<IGreetingTableSortSchema>>;

      return {
        ...state,
        tableSortRules: {
          ...state.tableSortRules,
          ...payload,
        },
      };
    }
    case GreetingViewActionsEnum.RESET_TABLE_SORT: {
      return {
        ...state,
        tableSortRules: {
          recurring: [],
          'one-time': [],
        },
      };
    }
    default:
      return state;
  }
};
