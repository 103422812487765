import type { FC } from 'react';

import { NavigationTopBarAlert } from '@livechat/design-system-react-components';

import { CONNECTION_STATUS_ONLINE_BAR_TEXT } from './constants';

interface Props {
  isVisible: boolean;
}

export const ConnectionRestoredBar: FC<Props> = ({ isVisible }) => {
  return (
    <NavigationTopBarAlert
      kind="success"
      isVisible={isVisible}
    >
      <span>{CONNECTION_STATUS_ONLINE_BAR_TEXT}</span>
    </NavigationTopBarAlert>
  );
};
