export enum TextEnhancementAction {
  SentimentMoreFriendly = 'sentimentMoreFriendly',
  SentimentMoreCasual = 'sentimentMoreCasual',
  SentimentMoreFormal = 'sentimentMoreFormal',
  ExpandMessage = 'expandMessage',
  SummarizeText = 'summarizeText',
  RephraseText = 'rephraseText',
  RephraseTextLikeImFive = 'rephraseTextLikeImFive',
  GrammarCorrection = 'grammarCorrection',
}
