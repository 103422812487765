// @ts-strict-ignore
import { ChatEventStatus } from 'constants/chat-event-status';
import { ActionHandlerExecutionDelay } from 'constants/toasts';
import { type UserType } from 'constants/user-type';
import { EventPlace } from 'helpers/analytics';
import { type GroupedEventsIds } from 'helpers/chat-events-groups';
import { type KeyMap } from 'helpers/interface';
import { navigate } from 'helpers/routing';
import { AppStateProvider } from 'services/app-state-provider';
import { trackEvent } from 'services/event-tracking';
import { getItem, saveItem, removeItem } from 'services/session-storage';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { isEventWithStatus, isEventWithAuthor } from 'store/entities/chats/helpers/common';
import { type IMessage, type ChatEventEntity } from 'store/entities/chats/interfaces';
import { TicketActions } from 'store/entities/tickets/actions';
import { type ICreateTicketPayload } from 'store/entities/tickets/interfaces';

export function handleCreateTicketRetry(payload: ICreateTicketPayload): () => void {
  return () => {
    setTimeout(() => AppStateProvider.dispatch(TicketActions.createTicket(payload)), ActionHandlerExecutionDelay.Short);
  };
}

export function handleOpenTicket(ticketId: string): () => void {
  return () => {
    navigate(`/tickets/${ticketId}`);
    trackEvent('Ticket opened from toast', EventPlace.Chats);
  };
}

export function handleStartSuperviseFailure(chatId: string, currentAgentId: string): () => void {
  return () => {
    AppStateProvider.dispatch(ChatsEntitiesActions.startSupervising({ chatId, currentAgentId }));
  };
}

export function getRandomString(): string {
  return `${+new Date()}.${Math.round(Math.random() * 1e11)}`;
}

export function addMessageToStorage(threadId: string, message: IMessage): void {
  const storedNotDeliveredEvents: KeyMap<{ text: string; timestamp: number }> = getItem(`${threadId}^2`);

  const updatedEventsToStore = {
    ...(storedNotDeliveredEvents || {}),
    ...{
      [message.timestampInMs]: { text: message.text, timestamp: message.timestampInMs, authorType: message.authorType },
    },
  };

  saveItem(`${threadId}^2`, updatedEventsToStore);
}

export function removeMessageFromStorage(threadId: string, messageTimestamp: number): void {
  const messages: KeyMap<{ text: string; timestamp: number }> = getItem(`${threadId}^2`);

  if (messages) {
    const { [messageTimestamp]: removedMessage, ...restMessages } = messages;

    if (!removedMessage) {
      return;
    }

    if (Object.keys(restMessages).length) {
      saveItem(`${threadId}^2`, restMessages);
    } else {
      removeItem(`${threadId}^2`);
    }
  }
}

export function getUnseenEventsIds(
  threadGroupedEventsIds: GroupedEventsIds,
  threadEvents: KeyMap<ChatEventEntity>,
  authorTypes: UserType[]
): string[] {
  const groupsCount = threadGroupedEventsIds.length;
  if (groupsCount === 0) {
    return [];
  }

  let groupIndex = groupsCount - 1;
  let foundLastSeenEvent = false;
  const unseenEvents = [];

  do {
    const lastEventsGroup = threadGroupedEventsIds[groupIndex];

    if (!lastEventsGroup) {
      break;
    }

    let lastEventInGroupIndex = lastEventsGroup.length - 1;

    do {
      const lastEventInGroupId = lastEventsGroup[lastEventInGroupIndex];
      const event = threadEvents[lastEventInGroupId];

      if (event) {
        const isFirstLastSeenEvent =
          isEventWithStatus(event) &&
          isEventWithAuthor(event) &&
          authorTypes.includes(event.authorType) &&
          event.wasSeen;
        if (isFirstLastSeenEvent) {
          foundLastSeenEvent = true;
          break;
        }

        const isSpecificAuthorTypeUnseenEvent =
          isEventWithStatus(event) &&
          isEventWithAuthor(event) &&
          authorTypes.includes(event.authorType) &&
          !event.wasSeen;
        if (isSpecificAuthorTypeUnseenEvent) {
          unseenEvents.push(event);
        }

        lastEventInGroupIndex -= 1;
      } else {
        break;
      }
    } while (!foundLastSeenEvent);

    groupIndex -= 1;
  } while (!foundLastSeenEvent || groupIndex >= 0);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const unseenEventsIds = unseenEvents.map((event) => event.id);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return unseenEventsIds;
}

export function getUnreadEventsIds(
  threadGroupedEventsIds: GroupedEventsIds,
  threadEvents: KeyMap<ChatEventEntity>,
  authorTypes: UserType[]
): string[] {
  const groupsCount = threadGroupedEventsIds.length;
  if (groupsCount === 0) {
    return [];
  }

  let groupIndex = groupsCount - 1;
  let foundLastReadEvent = false;
  const unreadEvents: ChatEventEntity[] = [];

  do {
    const lastEventsGroup = threadGroupedEventsIds[groupIndex];

    if (!lastEventsGroup) {
      break;
    }

    let lastEventInGroupIndex = lastEventsGroup.length - 1;

    do {
      const lastEventInGroupId = lastEventsGroup[lastEventInGroupIndex];
      const event = threadEvents[lastEventInGroupId];

      if (event) {
        const isFirstLastReadEvent =
          isEventWithStatus(event) &&
          isEventWithAuthor(event) &&
          authorTypes.includes(event.authorType) &&
          event.status === ChatEventStatus.Read;
        if (isFirstLastReadEvent) {
          foundLastReadEvent = true;
          break;
        }

        const isSpecificAuthorTypeUnreadEvent =
          isEventWithStatus(event) &&
          isEventWithAuthor(event) &&
          authorTypes.includes(event.authorType) &&
          event.status === ChatEventStatus.Delivered;
        if (isSpecificAuthorTypeUnreadEvent) {
          unreadEvents.push(event);
        }

        lastEventInGroupIndex -= 1;
      } else {
        break;
      }
    } while (!foundLastReadEvent);

    groupIndex -= 1;
  } while (!foundLastReadEvent || groupIndex >= 0);

  const unreadAgentEventsIds = unreadEvents.map((event) => event.id);

  return unreadAgentEventsIds;
}
