export const enum ResponsiveBreakpointType {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

/**
 * @deprecated Use ONE_COLUMN_BREAKPOINT instead or create your own breakpoint const.
 */
export const SMALL_SCREEN_BREAKPOINT = 705;
/**
 * @deprecated Use TWO_COLUMN_BREAKPOINT instead or create your own breakpoint const.
 */
export const MEDIUM_SCREEN_BREAKPOINT = 1025;

export const MOBILE_NAVBAR_HEIGHT = 56;

export const SUBSCRIPTION_SMALL_SCREEN_BREAKPOINT = 774;
