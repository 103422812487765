// @ts-strict-ignore
// calculate price and coupons by plan
// show Grandfathering by plan

import { type IPlanPrices, PlanType } from 'constants/plan-type';
import { ProductBillingCycle } from 'constants/product-cart';
import { RecurlyCoupon } from 'constants/recurly-coupons';
import { isHigherPlan } from 'helpers/subscription-plans';
import { CompanySize } from 'interfaces/entities/company-details';
import { BillingCycleType, type INewSubscription } from 'interfaces/subscription';
import { CUSTOM_PRICE_LABEL, CUSTOM_PRICING_PLANS } from 'routes/subscription/constants';
import { type PlanPrice } from 'routes/subscription/interfaces';

import { getConfig } from './config';
import { isCovidCoupon } from './recurly';

export const LCBillingCycleToProductCycleMap = {
  [BillingCycleType.Monthly]: ProductBillingCycle.Monthly,
  [BillingCycleType.Annually12]: ProductBillingCycle.Annually12,
  [BillingCycleType.Annually24]: ProductBillingCycle.Annually24,
  [BillingCycleType.Annually36]: ProductBillingCycle.Annually36,
};

export const productCycleToLCBillingCycleMap = {
  [ProductBillingCycle.Monthly]: BillingCycleType.Monthly,
  [ProductBillingCycle.Annually12]: BillingCycleType.Annually12,
  [ProductBillingCycle.Annually24]: BillingCycleType.Annually24,
  [ProductBillingCycle.Annually36]: BillingCycleType.Annually36,
};

const planRecommendationMap = {
  [CompanySize.JustMe]: PlanType.Team,
  [CompanySize.To49]: PlanType.Team,
  [CompanySize.To99]: PlanType.Business,
  [CompanySize.To499]: PlanType.EnterprisePlus,
  [CompanySize.To999]: PlanType.EnterprisePlus,
  [CompanySize.Above1000]: PlanType.EnterprisePlus,
};

export function shouldCalculateCouponValue(couponName: RecurlyCoupon, plan: PlanType): boolean {
  if (!isCovidCoupon(couponName)) {
    return true;
  }

  if (couponName === RecurlyCoupon.CovidBusiness && plan === PlanType.Business) {
    return true;
  }

  return false;
}

export function formatPrice(price: number): string {
  return price.toFixed(2).replace(/\.00/, '');
}

export function formatRoundedPrice(price: number): string {
  return Math.ceil(price).toString();
}

export function preparePlanCode(plan: PlanType, billingCycle: BillingCycleType): string {
  return `${plan}${billingCycle}m`;
}

export function prepareHostedPageLink(newSubscription: INewSubscription, license: number): string {
  if (newSubscription && newSubscription.planCode && newSubscription.seats && license) {
    return `${getConfig().recurlyUrl}subscribe/${newSubscription.planCode}/${license}?quantity=${
      newSubscription.seats
    }`;
  }

  return null;
}

export function parsePlanName(planName: string): PlanType {
  switch (planName) {
    case 'starter':
      return PlanType.Starter;
    case 'team':
      return PlanType.Team;
    case 'business':
      return PlanType.Business;
    case 'enterprise':
      return PlanType.EnterprisePlus;
    default:
      return null;
  }
}

export function parseBillingCycle(billingCycle: string): BillingCycleType {
  switch (billingCycle) {
    case 'monthly':
      return BillingCycleType.Monthly;
    case 'annual':
    case 'annual12':
      return BillingCycleType.Annually12;
    case 'annual24':
      return BillingCycleType.Annually24;
    case 'annual36':
      return BillingCycleType.Annually36;
    default:
      return null;
  }
}

export function calculateRecommendedPlan(currentPlan: PlanType, companySize: CompanySize): PlanType {
  if (!companySize || currentPlan === undefined) {
    if (currentPlan === PlanType.EnterprisePlus) {
      return PlanType.EnterprisePlus;
    }

    return PlanType.Business;
  }

  const recommendedPlan: PlanType = planRecommendationMap[companySize] || PlanType.Business;
  const isCurrentPlanHigher = isHigherPlan(currentPlan, recommendedPlan);

  return isCurrentPlanHigher ? currentPlan : recommendedPlan;
}

export const getPlanPriceFromList = (
  type: PlanType,
  billingCircle: BillingCycleType,
  planPrices: IPlanPrices,
): PlanPrice => {
  if (CUSTOM_PRICING_PLANS.includes(type)) {
    return CUSTOM_PRICE_LABEL;
  }

  return planPrices[preparePlanCode(type, billingCircle)] / billingCircle;
};
