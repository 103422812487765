import { createAgentChatApiClient } from '../client';
import { type AgentChatApiErrorResponse } from '../types';

import { type UntagThreadRequest, type TagThreadRequest } from './types';

const client = createAgentChatApiClient();

/**
 * Thread Tags API client.
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#thread-tags}
 */
export const threadTagsClient = {
  /**
   * Tags a given thread with a specified tag.
   * Required scopes: chats--all:rw or chats--access:rw
   * @see {@link https://api.livechatinc.com/v3.5/agent/action/tag_thread}
   */
  tag: async (data: TagThreadRequest) => {
    return client.post<void, AgentChatApiErrorResponse>('tag_thread', {
      json: data,
    });
  },

  /**
   * Removes a tag from a given thread.
   * Required scopes: chats--all:rw or chats--access:rw
   * @see {@link https://api.livechatinc.com/v3.5/agent/action/untag_thread}
   */
  untag: async (data: UntagThreadRequest) => {
    return client.post<void, AgentChatApiErrorResponse>('untag_thread', {
      json: data,
    });
  },
};
