import {
  Chats as ChatsIcon,
  ChatTakeover as ChatTakeoverIcon,
  Automation as AutomationIcon,
  Time as TimeIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
} from '@livechat/design-system-icons';
import { type IconSource } from '@livechat/design-system-react-components';

import { SuggestedResponsesTrigger } from 'interfaces/suggested-responses';

export type SuggestedResponsesConfigItem = {
  trigger: SuggestedResponsesTrigger;
  shortcut: string;
  label: string;
};

type SuggestedResponsesConfig = Record<SuggestedResponsesTrigger, SuggestedResponsesConfigItem>;

export const suggestedResponsesConfig: SuggestedResponsesConfig = {
  [SuggestedResponsesTrigger.OnStartChat]: {
    trigger: SuggestedResponsesTrigger.OnStartChat,
    shortcut: '_OnStartChat_',
    label: 'chat was started',
  },
  [SuggestedResponsesTrigger.OnTransferChat]: {
    trigger: SuggestedResponsesTrigger.OnTransferChat,
    shortcut: '_OnTransferChat_',
    label: 'chat was transferred',
  },
  [SuggestedResponsesTrigger.OnIdle]: {
    trigger: SuggestedResponsesTrigger.OnIdle,
    shortcut: '_OnIdle_',
    label: 'chat went idle',
  },
  [SuggestedResponsesTrigger.OnGoodRate]: {
    trigger: SuggestedResponsesTrigger.OnGoodRate,
    shortcut: '_OnGoodRate_',
    label: 'chat was rated as good',
  },
  [SuggestedResponsesTrigger.OnBadRate]: {
    trigger: SuggestedResponsesTrigger.OnBadRate,
    shortcut: '_OnBadRate_',
    label: 'chat was rated as bad',
  },
  [SuggestedResponsesTrigger.OnAICannedsSuggestion]: {
    trigger: SuggestedResponsesTrigger.OnAICannedsSuggestion,
    shortcut: '_OnAICannedsSuggestion_',
    label: 'AI canned response was proposed',
  },
};

export const SuggestedResponsesTriggerIcons: Record<SuggestedResponsesTrigger, IconSource> = {
  [SuggestedResponsesTrigger.OnBadRate]: ThumbDownIcon,
  [SuggestedResponsesTrigger.OnGoodRate]: ThumbUpIcon,
  [SuggestedResponsesTrigger.OnIdle]: TimeIcon,
  [SuggestedResponsesTrigger.OnStartChat]: ChatsIcon,
  [SuggestedResponsesTrigger.OnTransferChat]: ChatTakeoverIcon,
  [SuggestedResponsesTrigger.OnAICannedsSuggestion]: AutomationIcon,
};

export const ShortcutToTooltipText = {
  [SuggestedResponsesTrigger.OnBadRate]: 'This response was suggested because the chat was rated as bad.',
  [SuggestedResponsesTrigger.OnGoodRate]: 'This response was suggested because the chat was rated as good.',
  [SuggestedResponsesTrigger.OnIdle]: 'This response was suggested because chat went idle.',
  [SuggestedResponsesTrigger.OnStartChat]: 'This response was suggested because the chat has just started.',
  [SuggestedResponsesTrigger.OnTransferChat]:
    'This response was suggested because the chat was transferred from another agent.',
  [SuggestedResponsesTrigger.OnAICannedsSuggestion]:
    'This canned response was suggested based on your canned response usage.',
};
