import { type FC } from 'react';

import { Close } from '@livechat/design-system-icons';
import { Button, Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import { NavigationViewActions } from 'store/views/navigation/actions';

import * as styles from './styles';

enum TrackingEvent {
  NewAvailabilityTooltipCloseClicked = 'New availability tooltip close button clicked',
  NewAvailabilityTooltipNavClicked = 'New availability tooltip navigation link clicked',
}

export const NewAvailabilityTooltip: FC = () => {
  const dispatch = useDispatch();

  const hideTooltip = (): void => void dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: 'engage' }));

  const handleCloseClick = (): void => {
    hideTooltip();
    trackEvent(TrackingEvent.NewAvailabilityTooltipCloseClicked, EventPlace.NewAvailabilityPromoTooltip);
  };

  const handleNavigateLinkClick = (): void => {
    hideTooltip();
    navigate('/settings/availability');
    trackEvent(TrackingEvent.NewAvailabilityTooltipNavClicked, EventPlace.NewAvailabilityPromoTooltip);
  };

  return (
    <div className={styles.wrapper}>
      <div onClick={handleCloseClick} className={styles.icon}>
        <Icon source={Close} size="xsmall" />
      </div>
      <div className={styles.header}>Update your availability</div>
      <p className={styles.text}>
        Whenever you feel ready to change your availability, simply open Settings and go to Chat widget →
        <Button kind="link-light" className={styles.link} onClick={handleNavigateLinkClick}>
          Availability
        </Button>
        .
      </p>
    </div>
  );
};
