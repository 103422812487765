// @ts-strict-ignore
import { getTime } from 'date-fns';

import { UserType } from 'constants/user-type';
import type { KeyMap } from 'helpers/interface';
import type { CustomerLastVisitResult } from 'interfaces/api/chat';
import { type ChatSummaryResult } from 'services/api/chat/interfaces';
import { type IChatCustomerResult } from 'services/api/interfaces/chat/chat';
import { type IVisitedPage } from 'store/entities/customers/interfaces';

export function deserializeVisitedPages(lastVisit?: CustomerLastVisitResult): KeyMap<IVisitedPage> {
  return (lastVisit?.last_pages || []).reduce((acc, { url, title, opened_at: openedAt }) => {
    const timestampInMS = getTime(openedAt);

    acc[timestampInMS] = {
      pageURL: url,
      title,
      timestampInMS,
    };

    return acc;
  }, {});
}

export function deserializeChatsSummaryCustomersVisitedPages(
  chatsSummary: ChatSummaryResult[]
): KeyMap<KeyMap<IVisitedPage>> {
  return chatsSummary.reduce((acc, chatSummary) => {
    const { users } = chatSummary;
    const customerUser = (users || []).find((user) => user.type === UserType.Customer) as IChatCustomerResult;

    if (!customerUser || !customerUser.last_visit) {
      return acc;
    }

    const { id, last_visit: lastVisit } = customerUser;

    acc[id] = deserializeVisitedPages(lastVisit);

    return acc;
  }, {});
}
