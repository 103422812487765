import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ToastContent } from 'constants/toasts';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type IEmailiniumContactAccountManagerBody, type IEmailiniumResponse } from 'services/api/emailinium/api';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { type IRequestSubscriptionDowngradeRequestPayload } from 'store/entities/subscription/interfaces';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';

function* contactAccountManager(
  action: IActionWithPayload<string, IRequestSubscriptionDowngradeRequestPayload>
): SagaIterator {
  const payload: IEmailiniumContactAccountManagerBody = {
    message: action.payload.message,
  };
  const { error }: RequestResult<IEmailiniumResponse, IEmailiniumResponse> = yield call(
    ApiManager.emailiniumApi.contactAccountManager,
    payload
  );

  if (error) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.EMAIL_SENT_FAILURE),
        kind: ToastVariant.Error,
      })
    );
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.EMAIL_SENT_SUCCESS),
        kind: ToastVariant.Success,
      })
    );
  }
}

export function* requestSubscriptionDowngradeSaga(): SagaIterator {
  yield takeEvery(SubscriptionActionNames.REQUEST_SUBSCRIPTION_DOWNGRADE, contactAccountManager);
}
