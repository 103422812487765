import union from 'lodash.union';

import { type KeyMap } from 'helpers/interface';
import type { CannedResponse } from 'interfaces/canned-responses';
import { deserializeCannedResponseCollection } from 'services/api/canned-response/serializer';

import type {
  ICannedResponsesState,
  ISetCannedResponsesDataPayload,
  IRemoveCannedResponsePayload,
  IFetchCannedAutotagsSuccessPayload,
  ICannedResponseAutotagsRemovePayload,
  ICannedResponseAutotagsUpdateSuccessPayload,
  IAutoTagRemoveSuccessPayload,
} from '../interfaces';

import { groupCannedResponsesIdsByTags } from './sagas';

function removeIdFromIdsByTags(base: KeyMap<string[]>, id: string): KeyMap<string[]> {
  return Object.keys(base).reduce((acc: KeyMap<string[]>, tag) => {
    acc[tag] = base[tag].filter((i) => i !== id);

    if (acc[tag].length === 0) {
      delete acc[tag];
    }

    return acc;
  }, {});
}

function mergeIdsByTags(base: KeyMap<string[]>, cannedResponse: CannedResponse): KeyMap<string[]> {
  // Update idsByTags for new list of tags
  const mergedEntries = cannedResponse.tags.reduce((acc, tag) => {
    acc[tag] = union(base[tag] || [], [cannedResponse.id]);

    return acc;
  }, {});

  // Remove id from remaining idsByTags
  const entriesCopy = { ...base };
  cannedResponse.tags.forEach((tag) => {
    delete entriesCopy[tag];
  });
  const filteredEntries = removeIdFromIdsByTags(entriesCopy, cannedResponse.id);

  return {
    ...filteredEntries,
    ...mergedEntries,
  };
}

export function getStateForAddCannedResponse(
  state: ICannedResponsesState,
  payload: CannedResponse
): ICannedResponsesState {
  if (state.byIds[payload.id]) {
    return state;
  }

  return {
    ...state,
    allIds: state.allIds.concat([payload.id]),
    byIds: {
      ...state.byIds,
      [payload.id]: payload,
    },
    idsByTags: mergeIdsByTags(state.idsByTags, payload),
  };
}

export function getStateForSetData(
  state: ICannedResponsesState,
  payload: ISetCannedResponsesDataPayload
): ICannedResponsesState {
  const { cannedResponses } = payload;

  const byIds = { ...state.byIds, ...deserializeCannedResponseCollection(cannedResponses) };
  const idsByTags = groupCannedResponsesIdsByTags(byIds);

  return {
    ...state,
    allIds: Object.keys(byIds),
    byIds,
    idsByTags,
  };
}

export function getStateForRemoveCannedResponse(
  state: ICannedResponsesState,
  payload: IRemoveCannedResponsePayload
): ICannedResponsesState {
  const { id } = payload;
  const { [id]: removedCannedResponse, ...rest } = state.byIds;

  return {
    ...state,
    allIds: state.allIds.filter((i) => i !== id),
    byIds: rest,
    idsByTags: removeIdFromIdsByTags(state.idsByTags, id),
  };
}

export function getStateForUpdateOldCannedResponse(
  state: ICannedResponsesState,
  payload: CannedResponse
): ICannedResponsesState {
  return {
    ...state,
    byIds: {
      ...state.byIds,
      [payload.id]: { ...state.byIds[payload.id], ...payload },
    },
    idsByTags: mergeIdsByTags(state.idsByTags, payload),
  };
}

export function getStateForUpdateAutotags(
  state: ICannedResponsesState,
  payload: ICannedResponseAutotagsUpdateSuccessPayload
): ICannedResponsesState {
  return {
    ...state,
    autotags: {
      ...state.autotags,
      [payload.cannedId]: {
        groupId: payload.groupId,
        tags: payload.autotags,
      },
    },
  };
}

export function getStateForFetchAutotagsSuccess(
  state: ICannedResponsesState,
  payload: IFetchCannedAutotagsSuccessPayload
): ICannedResponsesState {
  return {
    ...state,
    autotags: {
      ...state.autotags,
      ...payload,
    },
  };
}

export function getStateForRemoveCannedResponseAutotags(
  state: ICannedResponsesState,
  payload: ICannedResponseAutotagsRemovePayload
): ICannedResponsesState {
  const { cannedId } = payload;

  const { [cannedId]: removedCannedResponse, ...rest } = state.autotags;

  return {
    ...state,
    autotags: rest,
  };
}

export function getStateForAutotagRemove(
  state: ICannedResponsesState,
  payload: KeyMap<IAutoTagRemoveSuccessPayload>
): ICannedResponsesState {
  return {
    ...state,
    autotags: {
      ...state.autotags,
      ...payload,
    },
  };
}
