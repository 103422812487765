import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Loader, Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { useEffectOnce } from 'hooks/use-effect-once';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getIsCommandSuggestionsOpened, getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import { COMMAND_MENU_LOADER_TEST_ID } from './constants';

import * as styles from './styles';

export const EmptyState: FC = () => {
  const dispatch = useDispatch();
  const isCommandSuggestionsOpened = useSelector(getIsCommandSuggestionsOpened);
  const isExpanded = useSelector(getIsCopilotModalExpanded);

  useEffectOnce(() => {
    dispatch(CopilotViewActions.openCommandSuggestions());

    return () => {
      dispatch(CopilotViewActions.closeCommandSuggestions());
    };
  });

  return (
    <div
      data-testid={COMMAND_MENU_LOADER_TEST_ID}
      className={cx(styles.menu, styles.emptyStateWrapper, 'lc-dark-theme', {
        [styles.popoverMenuEmptyState]: !isExpanded && isCommandSuggestionsOpened,
      })}
    >
      <Loader />
      <Text>
        New message can be sent only, <br />
        when previous request was completed.
      </Text>
    </div>
  );
};
