import { type FC } from 'react';

import { useSelector } from 'react-redux';

import { NavigationId } from 'constants/navigation';
import { getIsHelpDeskInstalled } from 'store/entities/applications/selectors';

import { NavigationItem } from './navigation-item/NavigationItem';

export const HelpDeskItem: FC = () => {
  const isHelpDeskInstalled = useSelector(getIsHelpDeskInstalled);

  return isHelpDeskInstalled ? null : (
    <NavigationItem key={NavigationId.HelpDeskInLiveChat} id={NavigationId.HelpDeskInLiveChat} />
  );
};
