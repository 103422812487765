import * as amplitude from '@amplitude/analytics-browser';

import config from 'env-config';
import { isDevelopmentEnvironment } from 'helpers/feature-toggle';

export type AmplitudeEventProperties = Parameters<typeof amplitude.logEvent>[1];
type AmplitudeUserPropertyValue = amplitude.Types.ValidPropertyType;

export enum AmplitudeUserProperty {
  AccountType = 'account type',
  CompanyDetailsCity = 'company_details.city',
  CompanyDetailsCountry = 'company_details.country',
  Experiments = 'experiments',
  License = 'license',
  Permission = 'permission',
  Plan = 'plan',
  Sentiment = 'Chats list sentiment',
  Technologies = 'techDetected',
  Theme = 'theme',
}

export enum AmplitudeEventProperty {
  SourceName = 'Source Name',
}
const sourceName = 'LiveChat:Web';

const sourceNamePlugin = (): amplitude.Types.EnrichmentPlugin => {
  return {
    // eslint-disable-next-line @typescript-eslint/require-await
    execute: async (event: amplitude.Types.Event) => {
      event.event_properties = {
        ...event.event_properties,
        [AmplitudeEventProperty.SourceName]: sourceName,
      };

      return event;
    },
  };
};

if (config.amplitude_api_key && config.product_event_api_url) {
  const logLevel = isDevelopmentEnvironment() ? amplitude.Types.LogLevel.Warn : amplitude.Types.LogLevel.Error;

  amplitude.init(config.amplitude_api_key, undefined, {
    flushQueueSize: 20,
    flushIntervalMillis: 10000,
    useBatch: true,
    serverUrl: config.product_event_api_url,
    logLevel,
    defaultTracking: {
      pageViews: false,
    },
  });
  amplitude.add(sourceNamePlugin());
}

export function logAmplitudeEvent(eventName: string, eventProperties: AmplitudeEventProperties): void {
  amplitude.logEvent(eventName, eventProperties);
}

export function setUserIdForAmplitude(userId: string): void {
  amplitude.setUserId(userId);
}

export function setUserPropertiesForAmplitude(
  properties: Partial<Record<AmplitudeUserProperty, AmplitudeUserPropertyValue>>
): void {
  const identity = new amplitude.Identify();
  Object.entries(properties).forEach(([key, value]) => {
    identity.set(key, value);
  });
  amplitude.identify(identity);
}
