export enum TeamEvent {
  AgentUpdated = 'Agent updated',
  AgentChatsLimitUpdated = 'Agent chats limit updated',
  AgentDeleted = 'Agent delete',
  AgentsSuspended = 'Agents suspended',
  AgentRoleUpdated = 'Agent role changed',
  PasswordChanged = 'Password changed',
  TimeFormatChanged = 'Time format changed',
  AgentStatusChanged = 'Agent status changed',
  AgentLoggedOut = 'Agent logged out',
  UpgradeToGetWorkSchedulerClick = 'Upgrade to get work scheduler clicked',
  UpgradeToGetReportsClicked = 'Upgrade to get reports clicked',
  DiscardModalConfirmClicked = 'Discard modal confirm clicked',
  WorkdaySelectionChange = 'Checkbox for workday clicked',
  ChangeShiftSelection = 'Day/Night icon clicked',
  ViewAgentReports = 'View agent reports clicked',
  ViewGroupReports = 'View group reports clicked',
  UnsuspendAgentClick = 'Unsuspend agent click',
  AgentUnsuspended = 'Agent unsuspended',
  ApproveAgentClick = 'Approve agent click',
  AgentApproved = 'Agent approved',
  ApproveAllClicked = 'Approve all clicked',
  AgentApprovalModalClosed = 'Agent approval modal closed',
  AgentDenied = 'Agent denied',
  AgentAdded = 'Agent added',
  AgentAddedModalClosed = 'Agent added modal closed',
  ConfirmCancelInvitingModal = 'Confirm cancel inviting modal',
  AgentInvitationResent = 'Agent invitation resent',
  ReactivateEmail = 'Reactivate email link clicked',
  SetWorkingHoursButtonClicked = 'Set work scheduler button clicked',
  ViewReports = 'View reports link clicked',
  BotEdited = 'Bot edited',
  BotChatsLimitUpdated = 'Bot chats limit updated',
  GroupAdded = 'Group added',
  GroupUpdated = 'Group updated',
  ListSorted = 'List sorted',
  CreateNextGroupClicked = 'Create next group button clicked',
  EditStoryClick = 'Edit story button clicked',
  ChangeChatsLimitClick = 'Change chats limit clicked',
  ChangeRoleClicked = 'Change agent role clicked',
  DeleteChatbotClick = 'Delete chatbot button clicked',
  ChatbotRemoved = 'ChatBot removed',
  EditProfileClick = 'Edit profile button clicked',
  AddBotRowClick = 'Add bot row clicked',
  DeleteGroupClicked = 'Delete group clicked',
  GroupDeleted = 'Group deleted',
  GroupAddedFromEmptyState = 'Add group button from empty state clicked',
  ChatbotEnabled = 'Chatbot enabled',
  ChatbotDisabled = 'Chatbot disabled',
  ChatbotLearnMore = 'Bots learn more button clicked',
  DownloadMobileAppBannerClicked = 'Download mobile app link clicked',
  InvitationLinkCopied = 'Invitation link copied',
  InviteAgentsButtonClicked = 'Invite agents button clicked',
  SendInvitesButtonClicked = 'Send invites button clicked',
  InviteMoreAgentsClicked = 'Invite more agents clicked',
  AddAgentsOneByOneButtonClicked = 'Add agents one by one button clicked',
  AddManyAtOnceButtonClicked = 'Add many at once button clicked',
  ConnectWithChatBotClicked = 'Connect with chatbot button clicked',
  SuspendedAgentsEmptyStateClicked = 'Suspended agents empty state clicked',
  StarterPlanUpsellGroupsLearnMoreClicked = 'Starter plan groups upsell learn more about groups clicked',
  StarterPlanUpsellGroupsUpgradeClicked = 'Starter plan groups upsell upgrade plan clicked',
  AvailabilitySettingsButtonClicked = 'Availability settings button clicked',
  AvailabilitySettingsChanged = 'Availability settings changed',
  WorkSchedulerChanged = 'Work scheduler changed',
  UpdateCreditCardDetailsClick = 'Update credit card details button clicked',
  AskForSubscriptionIncreaseClick = 'Send email to admins button clicked',
  AgentHasBeenInvited = 'Agent has been invited',
  InviteAgentsModalOpened = 'Invite agents modal opened',
  CreateChatbotModalOpened = 'Create chatbot modal opened',
  CreateGroupModalOpened = 'Create group modal opened',
  EmailSuggestionShown = 'Email suggestion shown',
  EmailSuggestionApplied = 'Email suggestion applied',
  ShopifyMaxAgentReachedTooltipClicked = 'Shopify max agent reached tooltip clicked',
}
