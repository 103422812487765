import { type SagaIterator } from 'redux-saga';
import { takeEvery, put, select, take, fork, cancel, spawn } from 'redux-saga/effects';

import { NavigationTooltipType } from 'constants/navigation';
import { BaseRouteName } from 'constants/routes';
import { Section } from 'constants/section';
import { notificationsEnabled } from 'helpers/notifications';
import { getBaseRoute } from 'helpers/routes';
import { RequestAction } from 'store/entities/actions';
import { AGENT_CUSTOM_PROPERTIES, AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getNewVisitorTooltipViewed,
  hasFetchedAgentCustomProperties as getHasFetchedAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';
import { type NotificationsActions, NotificationsActionsNames } from 'store/features/notifications/actions';
import { RoutingActionsEnum } from 'store/features/routing/actions';
import { type IWithRoutingState, getIsOnSection } from 'store/features/routing/selectors';
import { NavigationViewActions } from 'store/views/navigation/actions';

const shouldShowNewVisitorTooltip = (type: NavigationTooltipType, tooltipViewed: string): boolean => {
  const baseRouteName = getBaseRoute();

  return (
    type === NavigationTooltipType.NewVisitor &&
    (baseRouteName as BaseRouteName) !== BaseRouteName.Engage &&
    !parseInt(tooltipViewed, 10) &&
    !notificationsEnabled()
  );
};

export function* showNotification(): SagaIterator {
  yield put(NavigationViewActions.hideNavigationItemTooltip({ itemId: BaseRouteName.Reports }));
  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.NewVisitorTooltipViewed]: '1',
    })
  );

  yield put(
    NavigationViewActions.showNavigationItemTooltip({
      itemId: BaseRouteName.Engage,
      type: NavigationTooltipType.NewVisitor,
      kind: 'important',
    })
  );
}

function* waitForRouteChange(): SagaIterator {
  while (true) {
    const isOnboarding = yield select((state: IWithRoutingState) => getIsOnSection(state, Section.Onboarding));
    const isInstallCode = yield select((state: IWithRoutingState) => getIsOnSection(state, Section.InstallCode));

    if (!isOnboarding && !isInstallCode) {
      yield spawn(showNotification);
      yield cancel();
    }

    yield take(RoutingActionsEnum.SET_CURRENT_SECTION);
  }
}

export function* watchNewNotification(data: ReturnType<typeof NotificationsActions.newNotification>): SagaIterator {
  const isOnboarding = yield select((state: IWithRoutingState) => getIsOnSection(state, Section.Onboarding));
  const isInstallCode = yield select((state: IWithRoutingState) => getIsOnSection(state, Section.InstallCode));
  const hasFetchedAgentCustomProperties = yield select(getHasFetchedAgentCustomProperties);

  if (!hasFetchedAgentCustomProperties) {
    yield take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]);
  }

  const tooltipViewed: string = yield select(getNewVisitorTooltipViewed);

  if (shouldShowNewVisitorTooltip(data.payload.type, tooltipViewed)) {
    if (!isOnboarding && !isInstallCode) {
      yield fork(showNotification);
    } else {
      yield fork(waitForRouteChange);
    }
  }
}

export default function* notificationsSagas(): SagaIterator {
  yield takeEvery(NotificationsActionsNames.NEW_NOTIFICATION, watchNewNotification);
}
