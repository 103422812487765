import { type PlanType } from 'constants/plan-type';
import { type CreditCardType } from 'interfaces/recurly';
import { createAction, type ActionsUnion } from 'store/helper';

import { type ISubscriptionViewSetQueryParamsFromURLPayload } from './interfaces';

export enum SubscriptionViewActionNames {
  SET_RECOMMENDED_PLAN_FOR_COMPANY_SIZE = 'VIEWS/SUBSCRIPTION/SET_RECOMMENDED_PLAN_FOR_COMPANY_SIZE',
  SET_RECOMMENDED_PLAN_FROM_QUERY_PARAMS = 'VIEWS/SUBSCRIPTION/SET_RECOMMENDED_PLAN_FROM_QUERY_PARAMS',
  REMOVE_RECOMMENDED_PLAN = 'VIEWS/SUBSCRIPTION/REMOVE_RECOMMENDED_PLAN',
  SET_CURRENT_CARD_BRAND = 'VIEWS/SUBSCRIPTION/SET_CURRENT_CARD_BRAND',
  AGENTS_COUNT_CHANGED = 'VIEWS/SUBSCRIPTION/AGENTS_COUNT_CHANGED',
  SET_CAN_RESTART_TRIAL = 'VIEWS/SUBSCRIPTION/SET_CAN_RESTART_TRIAL',
  RESET_SUBSCRIPTION_STATE = 'VIEWS/SUBSCRIPTION/RESET_SUBSCRIPTION_STATE',
  DISABLE_DOWNGRADE_CONFIRMATION_FLOW = 'VIEWS/SUBSCRIPTION/DISABLE_DOWNGRADE_CONFIRMATION_FLOW',
  LICENSE_DATA_REFRESH_FAILURE = 'VIEWS/SUBSCRIPTION/LICENSE_DATA_REFRESH_FAILURE',
  SET_RECURLY_ERROR = 'VIEWS/SUBSCRIPTION/SET_RECURLY_ERROR',
  SET_IS_SUBSCRIBE_BUTTON_DISABLED = 'VIEWS/SUBSCRIPTION/SET_IS_SUBSCRIBE_BUTTON_DISABLED',
  SET_IS_CALENDLY_MODAL_OPEN = 'VIEWS/SUBSCRIPTION/SET_IS_CALENDLY_MODAL_OPEN',
  SET_IS_COMPARE_PLANS_MODAL_OPEN = 'VIEWS/SUBSCRIPTION/SET_IS_COMPARE_PLANS_MODAL_OPEN',
  SET_SHOULD_GO_BACK_TO_PLANS_MODAL = 'VIEWS/SUBSCRIPTION/SET_SHOULD_OPEN_PLANS_MODAL',
  SET_SHOW_NAVIGATION = 'VIEWS/SUBSCRIPTION/SET_SHOW_NAVIGATION',
}
export const SubscriptionViewActions = {
  agentsCountChanged: () => createAction(SubscriptionViewActionNames.AGENTS_COUNT_CHANGED),
  resetSubscriptionState: () => createAction(SubscriptionViewActionNames.RESET_SUBSCRIPTION_STATE),
  licenseDataRefreshFailure: () => createAction(SubscriptionViewActionNames.LICENSE_DATA_REFRESH_FAILURE),

  setCanRestartTrial: () => createAction(SubscriptionViewActionNames.SET_CAN_RESTART_TRIAL),
  disableDowngradeConfirmationFlow: () => createAction(SubscriptionViewActionNames.DISABLE_DOWNGRADE_CONFIRMATION_FLOW),
  setCurrentCardBrand: (brand: CreditCardType) =>
    createAction(SubscriptionViewActionNames.SET_CURRENT_CARD_BRAND, brand),

  setRecommendedPlanFromQueryParams: (payload: ISubscriptionViewSetQueryParamsFromURLPayload) =>
    createAction(SubscriptionViewActionNames.SET_RECOMMENDED_PLAN_FROM_QUERY_PARAMS, payload),
  setRecommendedPlanForCompanySize: (plan: PlanType) =>
    createAction(SubscriptionViewActionNames.SET_RECOMMENDED_PLAN_FOR_COMPANY_SIZE, plan),
  setRecurlyError: (error: string) => createAction(SubscriptionViewActionNames.SET_RECURLY_ERROR, error),
  removeRecommendedPlan: () => createAction(SubscriptionViewActionNames.REMOVE_RECOMMENDED_PLAN),
  setIsSubscribeButtonDisabled: (isDisabled: boolean) =>
    createAction(SubscriptionViewActionNames.SET_IS_SUBSCRIBE_BUTTON_DISABLED, isDisabled),
  setIsCalendlyModalOpen: (isOpen: boolean) =>
    createAction(SubscriptionViewActionNames.SET_IS_CALENDLY_MODAL_OPEN, isOpen),
  setIsComparePlansModalOpen: (isOpen: boolean) =>
    createAction(SubscriptionViewActionNames.SET_IS_COMPARE_PLANS_MODAL_OPEN, isOpen),
  setShouldGoBackToPlansModal: (shouldGoBack: boolean) =>
    createAction(SubscriptionViewActionNames.SET_SHOULD_GO_BACK_TO_PLANS_MODAL, shouldGoBack),
  setShowNavigation: (showNavigation: boolean) =>
    createAction(SubscriptionViewActionNames.SET_SHOW_NAVIGATION, showNavigation),
};

export type SubscriptionViewActionTypes = ActionsUnion<typeof SubscriptionViewActions>;
