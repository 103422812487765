// @ts-strict-ignore
import { isSameDay } from 'date-fns';

/**
 * Returns timestamp (without milliseconds)
 */
export function getTimestamp(dateString: string = null): number {
  let date: Date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }

  return Math.floor(date.getTime() / 1000);
}

/**
 * Return formatted date: yyyy-MM-dd
 */
export function getBaseDate(d: Date = null, opts: { omitDays?: boolean } = {}): string {
  let date = d;
  if (!date) {
    date = new Date();
  }

  const day = addLeadingZero(date.getDate());
  const month = addLeadingZero(date.getMonth() + 1);
  const year = date.getFullYear();

  if (opts.omitDays) {
    return `${year}-${month}`;
  }

  return `${year}-${month}-${day}`;
}

/**
 * 0 -> 00, 5 -> 05, 10 -> 10, ...
 */
export function addLeadingZero(x: string | number): string {
  const num = typeof x === 'number' ? x : parseInt(x, 10);

  if (num >= 0 && num < 10) {
    return `0${num}`;
  }

  return x.toString();
}

/**
 * Returns date object for today+n days, 23:59:59
 */
export function dateInNDays(n: string | number = 0): Date {
  const nDays = typeof n === 'number' ? n : parseInt(n, 10);
  const dt = new Date();
  const nd = new Date();

  dt.setHours(0, 0, 0);

  nd.setTime(dt.getTime() + 1000 * 3600 * 24 * nDays);
  nd.setHours(23, 59, 59);

  return nd;
}

/**
 * Returns transform time string like 13:00 to 1:00 am
 * - hour range: 0-24
 * - only supports full hours - ignores minutes
 */
export function formatTimeFromHours(rawHours: string): string {
  const parsedHours = parseInt(rawHours, 10);

  if (Number.isNaN(parsedHours) || parsedHours < 0 || parsedHours > 24) {
    return '';
  }
  const hours = parsedHours || 24;

  return `${hours > 12 ? hours - 12 : hours}:00 ${hours >= 12 && hours < 24 ? 'pm' : 'am'}`;
}

/**
 * Returns date in UTC
 */
export function getUTCDate(date: Date): Date {
  const dateUTC = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return dateUTC ? new Date(dateUTC) : new Date();
}

export function getTimelineLabel(date: Date): string {
  const msToday = 86400000;
  const msWeek = msToday * 7;
  if (isSameDay(date, new Date())) {
    return 'Today';
  } else if (isSameDay(date, new Date(new Date().getTime() - msToday))) {
    return 'Yesterday';
  } else if (+new Date() - date.getTime() < msWeek) {
    return 'Last week';
  } else {
    const dateTimestamp = +date;
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    if (+lastMonth < dateTimestamp) {
      return 'Last month';
    } else {
      return date.getFullYear().toString();
    }
  }
}
