import { AccountStatus } from 'constants/account-status';
import type { IAppState } from 'services/app-state-provider';
import { type Agent } from 'services/connectivity/configuration-api/agents/types';
import { AgentActions } from 'store/entities/agents/actions';
import type { IAgent } from 'store/entities/agents/interfaces';
import { SubscriptionViewActions } from 'store/views/subscription/actions';

export function handleAgentSuspended(payload: Pick<Agent, 'id'>, store: IAppState): void {
  store.dispatch(SubscriptionViewActions.agentsCountChanged());
  store.dispatch(
    AgentActions.agentUpdate({ login: payload.id, accountStatus: AccountStatus.Suspended } as Pick<
      IAgent,
      'login' | 'accountStatus'
    >)
  );
}
