import { type IApplicationData } from 'store/features/applications/interfaces';

export interface IFetchApplicationDataRequestResult extends IApplicationData {}

export interface IUninstallApplicationBody {
  reason?: string;
}

export enum ApplicationProduct {
  LiveChat = 'livechat',
}

export enum ApplicationLoadSource {
  AgentDetails = 'AgentDetails',
  ChatMessageBox = 'ChatMessageBox',
  FullScreenApp = 'FullScreenApp',
  SettingsPage = 'SettingsPage',
}

export interface IApplicationLoadedBody {
  appId: string;
  accountId: string;
  organizationId: string;
  source: ApplicationLoadSource;
  timestamp: string;
}
