// @ts-strict-ignore
import { GENERAL_GROUP_ID } from 'constants/groups';
import { LanguagePhrase } from 'constants/language-phrase';
import { LanguageCode } from 'constants/languages-available';
import { ApiManager } from 'services/api/api-manager';
import { getLoggedInAgentName } from 'store/entities/agents/selectors';
import { getCustomerGroupIds, getCustomerName } from 'store/entities/customers/selectors';
import { getGroupLanguageCode } from 'store/entities/groups/selectors';
import { deserializeLanguage } from 'store/entities/languages/serializers';

import { AppStateProvider } from './app-state-provider';

export async function fetchWelcomeMessage(customerId: string): Promise<string> {
  const state = AppStateProvider.getState();
  const visitorName = getCustomerName(state, customerId);
  const [visitorGroupId] = getCustomerGroupIds(state, customerId);
  const loggedInAgentName = getLoggedInAgentName(state);

  const groupLanguageCode = getGroupLanguageCode(state, visitorGroupId);
  const languageId = groupLanguageCode || LanguageCode.English;
  const groupId = visitorGroupId || GENERAL_GROUP_ID;

  const { languagesApi } = ApiManager;
  const { result } = await languagesApi.fetchLanguage(languageId, groupId);
  const language = deserializeLanguage(result, languageId);

  const setVisitorName = visitorName || language.phrases[LanguagePhrase.Client] || 'Customer';

  const welcomeMessage = language.phrases[LanguagePhrase.WelcomeToChat]
    .replace(/%name%/g, setVisitorName)
    .replace(/%agent%/g, loggedInAgentName);

  return welcomeMessage;
}
