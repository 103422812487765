/* eslint-disable @typescript-eslint/naming-convention */
import type { IAppState } from 'services/app-state-provider';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { CannedResponseActions } from 'store/entities/canned-responses/actions';
import { type ICannedResponseUpdatePayload } from 'store/entities/canned-responses/interfaces';

export function handleCannedResponseUpdate(payload: ICannedResponseUpdatePayload, store: IAppState): void {
  const state = store.getState();
  const currentUserLogin = getLoggedInAgentLogin(state);
  const { group, canned_response } = payload;
  const { id, modification_date, tags, text, modified_by, private: isPrivate } = canned_response;
  const wasEditedByCurrentUser = currentUserLogin === modified_by;

  if (isPrivate && !wasEditedByCurrentUser) {
    store.dispatch(CannedResponseActions.removeCannedResponseData({ id: String(id) }));

    return;
  }

  store.dispatch(
    CannedResponseActions.updateCannedResponseData({
      groupId: String(group),
      id: String(id),
      modificationTimestampInMs: modification_date * 1000,
      tags,
      text,
      modifiedBy: modified_by,
      isPrivate,
    })
  );
}
