// @ts-strict-ignore
import { toKeyMap } from 'helpers/array';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { type IActionWithPayload } from 'store/helper';

import type { EntityFetchCollectionSuccess } from '../interfaces';

import { type GreetingAction, GreetingActionNames } from './actions';
import { type IGreeting, type IGreetingsEntityState } from './interfaces';

export const initialState: IGreetingsEntityState = {
  isSavingData: false,
  greetings: {},
};

export const greetingsReducer = (state = initialState, action: GreetingAction): IGreetingsEntityState => {
  switch (action.type) {
    case GreetingActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]:
    case GreetingActionNames.SET_GREETINGS: {
      const { payload } = action as IActionWithPayload<string, EntityFetchCollectionSuccess<IGreeting>>;
      const { values } = payload;

      return {
        ...state,
        greetings: toKeyMap(values, 'id'),
      };
    }

    case GreetingActionNames.SET_GREETING: {
      const { payload } = action as IActionWithPayload<string, IGreeting>;
      const greeting = payload;
      const { greetings } = state;

      return {
        ...state,
        greetings: {
          ...greetings,
          [greeting.id]: {
            ...greeting,
          },
        },
      };
    }
    case GreetingActionNames[CRUDAction.UPDATE][RequestAction.REQUEST]:
    case GreetingActionNames.DELETE_GREETING:
    case GreetingActionNames.CREATE_GREETING:
    case GreetingActionNames.SET_VISIBILITY: {
      return {
        ...state,
        isSavingData: true,
      };
    }
    case GreetingActionNames[CRUDAction.UPDATE][RequestAction.SUCCESS]:
    case GreetingActionNames[CRUDAction.UPDATE][RequestAction.FAILURE]:
    case GreetingActionNames.CREATE_GREETING_SUCCESS:
    case GreetingActionNames.CREATE_GREETING_FAILURE:
    case GreetingActionNames.DELETE_GREETING_FAILURE:
    case GreetingActionNames.DELETE_GREETING_SUCCESS:
    case GreetingActionNames.SET_VISIBILITY_SUCCESS:
    case GreetingActionNames.SET_VISIBILITY_FAILURE: {
      return {
        ...state,
        isSavingData: false,
      };
    }
    default:
      return state;
  }
};
