import type { IApiAutoAccessRule } from 'store/entities/auto-access-rules/interfaces';

import type { RequestResult } from 'interfaces/api/client';
import { BaseApi, PassVersionMode } from '../base-api';

export class AutoAccessRulesApi extends BaseApi {
  protected readonly version = '3.3';
  protected readonly prefix = 'configuration/action';
  protected readonly passVersionMode = PassVersionMode.Prefix;

  fetchAll = (): Promise<RequestResult<IApiAutoAccessRule[]>> => {
    return this.post('list_auto_accesses', {});
  };

  create = (
    rule: Omit<IApiAutoAccessRule, 'id' | 'next_id'>
  ): Promise<RequestResult<Pick<IApiAutoAccessRule, 'id'>>> => {
    return this.post('add_auto_access', rule);
  };

  remove = (id: IApiAutoAccessRule['id']): Promise<RequestResult<void>> => {
    return this.post('delete_auto_access', { id });
  };

  update = (
    rule: Partial<Omit<IApiAutoAccessRule, 'next_id'>>
  ): Promise<RequestResult<Pick<IApiAutoAccessRule, 'id'>>> => {
    return this.post('update_auto_access', rule);
  };

  reorder = (
    id: IApiAutoAccessRule['id'],
    next_id: IApiAutoAccessRule['next_id'] = '' // API requires empty string for 'next_id' if the element should be reordered as the last item
  ): Promise<RequestResult<void>> => {
    return this.post('update_auto_access', { id, next_id });
  };
}
