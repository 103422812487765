// @ts-strict-ignore
import {
  type ISaveCurrentCartPayload,
  type IAddApplicationSuccessPayload,
  type IApplicationIDPayload,
  type IProductCartState,
} from '../interfaces';

export function getStateForSaveCurrentCart(
  state: IProductCartState,
  payload: ISaveCurrentCartPayload
): IProductCartState {
  return {
    ...state,
    currentCart: payload,
  };
}

export function getStateForAddApplicationSuccess(
  state: IProductCartState,
  payload: IAddApplicationSuccessPayload
): IProductCartState {
  return {
    ...state,
    currentCart: {
      ...state.currentCart,
      products: [...(state.currentCart?.products || []), payload],
    },
  };
}

export function getStateForConfirmApplicationSuccess(
  state: IProductCartState,
  payload: IApplicationIDPayload
): IProductCartState {
  return {
    ...state,
    currentCart: {
      ...state.currentCart,
      products: state.currentCart.products.map((product) =>
        product.id === payload.id ? { ...product, isMarkedForInstall: true } : product
      ),
    },
  };
}

export function getStateForConfirmAllApplicationSuccess(state: IProductCartState): IProductCartState {
  return {
    ...state,
    currentCart: {
      ...state.currentCart,
      products: state.currentCart.products?.map((product) => ({ ...product, isMarkedForInstall: true })),
    },
  };
}

export function getStateForRemoveApplicationSuccess(
  state: IProductCartState,
  payload: IApplicationIDPayload
): IProductCartState {
  return {
    ...state,
    currentCart: {
      ...state.currentCart,
      products: state.currentCart.products && state.currentCart.products.filter((product) => product.id !== payload.id),
    },
  };
}
