import type { FC } from 'react';

import { usePrevious } from '@livechat/react-hooks';
import { useSelector } from 'react-redux';

import { Badge, BadgeType } from 'components/badge/Badge';
import { getNotificationsChatsCount } from 'store/views/chats/selectors';

import { calculateBadgeLabel } from './helpers';

import { badgeAnimationStyles } from './styles';

interface IProps {
  isActive: boolean;
}

export const ChatsBadge: FC<IProps> = ({ isActive: active }) => {
  const content = calculateBadgeLabel(useSelector(getNotificationsChatsCount));
  const previousContent = usePrevious(content);
  const shouldBadgeAnimate = !active && content !== previousContent;

  return content ? (
    <div className={shouldBadgeAnimate ? badgeAnimationStyles : undefined}>
      <Badge type={BadgeType.Alert}>{content}</Badge>
    </div>
  ) : null;
};
