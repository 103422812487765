// @ts-strict-ignore
import { ChatEventSubType } from 'constants/chat-event-type';
import { type KeyMap } from 'helpers/interface';
import { Rating } from 'interfaces/customer';

export function shouldCheckEventForComment(eventSubType: ChatEventSubType): boolean {
  return (
    eventSubType === ChatEventSubType.Rated ||
    eventSubType === ChatEventSubType.ChatRatingComment ||
    eventSubType === ChatEventSubType.RateCommentedOnly
  );
}

export function extractCommentFromEventText(text: string): { text: string; comment: string } {
  const commentRegexp = /^[\s\S]*left the following comment:/;
  const isRateComment = Boolean(text.match(commentRegexp));

  if (!isRateComment) {
    return {
      comment: null,
      text,
    };
  }

  const comment = text.replace(commentRegexp, '').trim();
  const newText = text.replace(comment, '').trim();

  return {
    comment,
    text: newText,
  };
}

interface IStandarizedChatRatingEvent extends KeyMap<any> {
  subType: ChatEventSubType;
  text: string;
  textVariables?: {
    score?: string;
    customer?: string;
    comment?: string;
  };
}

export function standardizeChatRatingEvent(
  text: string,
  subType: ChatEventSubType,
  event: any = {}
): IStandarizedChatRatingEvent {
  if (subType === ChatEventSubType.Rated) {
    let chatRating;

    const hasChatInfoRating = [Rating.Good, Rating.Bad].some((rating) => text.includes(`rated this chat as ${rating}`));
    if (hasChatInfoRating) {
      chatRating = text.includes(Rating.Good) ? Rating.Good : Rating.Bad;
    }

    if (chatRating) {
      const ratingRegexp = /rated this chat as (bad|good)\.$/;
      const customer = text.replace(ratingRegexp, '').trim();

      return {
        ...event,
        text,
        subType: ChatEventSubType.RatedOnly,
        textVariables: {
          score: chatRating,
          customer,
        },
      };
    }
  }

  if (shouldCheckEventForComment(subType)) {
    const { text: newText, comment } = extractCommentFromEventText(text);

    if (!comment) {
      return {
        ...event,
        text,
        subType: ChatEventSubType.RatedOnly,
      };
    }

    return {
      ...event,
      text: newText,
      subType: ChatEventSubType.RateCommentedOnly,
      textVariables: {
        comment,
      },
    };
  }

  return {
    ...event,
    text,
    subType,
  };
}
