import { type IBillingInfo } from 'store/entities/billing/interfaces';

import { type IFetchBillingInfoResult } from '../billing/interfaces';

export abstract class BillingInfoSerializer {
  static deserialize = (billingInfo: IFetchBillingInfoResult): IBillingInfo => ({
    cardType: billingInfo.card_type,
    firstSix: billingInfo.first_six,
    lastFour: billingInfo.last_four,
    year: billingInfo.year,
    month: billingInfo.month,
    firstName: billingInfo.first_name,
    lastName: billingInfo.last_name,
    company: billingInfo.company,
    address: billingInfo.address1,
    city: billingInfo.city,
    state: billingInfo.state,
    country: billingInfo.country,
    zip: billingInfo.zip,
    vatNumber: billingInfo.vat_number,
  });

  static serialize = (billingInfo: IBillingInfo): IFetchBillingInfoResult => ({
    card_type: billingInfo.cardType,
    first_six: billingInfo.firstSix,
    last_four: billingInfo.lastFour,
    year: billingInfo.year,
    month: billingInfo.month,
    first_name: billingInfo.firstName,
    last_name: billingInfo.lastName,
    company: billingInfo.company,
    address1: billingInfo.address,
    city: billingInfo.city,
    state: billingInfo.state,
    country: billingInfo.country,
    zip: billingInfo.zip,
    vat_number: billingInfo.vatNumber,
  });
}
