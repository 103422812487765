import { CodeInstallationActionsNames, type CodeInstallationActionTypes } from './actions';
import { type ICodeInstallationState } from './interfaces';

const initialState: ICodeInstallationState = {
  isCodeInstalled: false,
  hadChatsRecently: true,
  channelsActivity: {
    website: {
      isActive: false,
    },
    facebook: {
      isActive: false,
    },
    directChat: {
      isActive: false,
    },
  },
};

export const codeInstallationReducer = (
  state: ICodeInstallationState = initialState,
  action: CodeInstallationActionTypes
): ICodeInstallationState => {
  switch (action.type) {
    case CodeInstallationActionsNames.PARSE_LICENSE_INFO: {
      if (action.payload.account && typeof action.payload.account.daysSinceLastActivity === 'number') {
        return {
          ...state,
          isCodeInstalled: true,
          hadChatsRecently: action.payload.account.daysSinceLastActivity < 3,
        };
      }

      return {
        ...state,
        isCodeInstalled: false,
        hadChatsRecently: false,
      };
    }

    case CodeInstallationActionsNames.CODE_FOUND:
    case CodeInstallationActionsNames.ACTIVITY_FOUND: {
      return {
        ...state,
        isCodeInstalled: true,
        hadChatsRecently: true,
      };
    }

    case CodeInstallationActionsNames.RESET_ACTIVITY: {
      return {
        ...state,
        isCodeInstalled: false,
        hadChatsRecently: false,
      };
    }

    case CodeInstallationActionsNames.FETCH_CHANNELS_ACTIVITY_SUCCESS: {
      return {
        ...state,
        channelsActivity: action.payload.channelsActivity,
      };
    }

    default: {
      return state;
    }
  }
};
