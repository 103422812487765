import { type SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { addProductToCurrentCartSaga } from './add-product-to-cart';
import { fetchCartSaga } from './fetch-cart';
import { removeProductFromCartSaga } from './remove-product-from-cart';

export function* productCartSagas(): SagaIterator {
  yield all([fork(addProductToCurrentCartSaga), fork(fetchCartSaga), fork(removeProductFromCartSaga)]);
}
