import { createAction, type ActionsUnion } from 'store/helper';

import {
  type IFetchGroupPropertiesPayload,
  type IFetchGroupPropertiesSuccessPayload,
  type IFetchGroupPropertiesFailurePayload,
  type IUpdateGroupPropertiesPayload,
  type IUpdateGroupPropertiesSuccessPayload,
  type IUpdateGroupPropertiesFailurePayload,
} from './interfaces';

export enum IntegrationGroupPropertiesActionNames {
  FETCH_REQUEST = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/FETCH_REQUEST',
  FETCH_SUCCESS = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/FETCH_SUCCESS',
  FETCH_FAILURE = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/FETCH_FAILURE',

  UPDATE_REQUEST = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/UPDATE_SUCCESS',
  UPDATE_FAILURE = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/UPDATE_FAILURE',
}

export enum IntegrationGroupPropertiesRequestNames {
  FETCH = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/FETCH',
  UPDATE = 'ENTITIES/INTEGRATION_GROUP_PROPERTIES/UPDATE',
}

export const IntegrationGroupPropertiesActions = {
  fetch: (payload: IFetchGroupPropertiesPayload) =>
    createAction(IntegrationGroupPropertiesActionNames.FETCH_REQUEST, payload),
  fetchSuccess: (payload: IFetchGroupPropertiesSuccessPayload) =>
    createAction(IntegrationGroupPropertiesActionNames.FETCH_SUCCESS, payload),
  fetchFailure: (error: IFetchGroupPropertiesFailurePayload) =>
    createAction(IntegrationGroupPropertiesActionNames.FETCH_FAILURE, error),

  update: (payload: IUpdateGroupPropertiesPayload) =>
    createAction(IntegrationGroupPropertiesActionNames.UPDATE_REQUEST, payload),
  updateSuccess: (payload: IUpdateGroupPropertiesSuccessPayload) =>
    createAction(IntegrationGroupPropertiesActionNames.UPDATE_SUCCESS, payload),
  updateFailure: (error: IUpdateGroupPropertiesFailurePayload) =>
    createAction(IntegrationGroupPropertiesActionNames.UPDATE_FAILURE, error),
};

export type IntegrationGroupPropertiesAction = ActionsUnion<typeof IntegrationGroupPropertiesActions>;
