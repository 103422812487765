import { createAction } from 'store/helper';

import { type ILoadWidgetPayload } from './interfaces';

export enum FullscreenWidgetsViewActionNames {
  LOAD_WIDGET = 'VIEWS/FULLSCREEN_WIDGETS/LOAD_WIDGET',
}

export const FullscreenWidgetsViewActions = {
  load: (payload: ILoadWidgetPayload) => createAction(FullscreenWidgetsViewActionNames.LOAD_WIDGET, payload),
};
