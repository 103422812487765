import { getTime } from 'date-fns';

import { ChatEventSubType, ChatEventType } from 'constants/chat-event-type';
import type { IAppState } from 'services/app-state-provider';
import { standardizeChatRatingEvent } from 'services/serialization/chat-rating-event';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadExists, getThreadCustomerName } from 'store/entities/chats/selectors';

interface ChatRateCommentPayload {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  conference_id: string;
  comment: string;
}

export function handleChatRateComment(payload: ChatRateCommentPayload, store: IAppState): void {
  const { conference_id: threadId, comment } = payload;
  const state = store.getState();
  const threadExist = getThreadExists(state, threadId);

  if (threadExist) {
    const timestampInMs = getTime(new Date());
    const { subType, text, textVariables } = standardizeChatRatingEvent(
      `${getThreadCustomerName(state, threadId)} left the following comment: ${comment}`,
      ChatEventSubType.ChatRatingComment
    );

    store.dispatch(
      ChatsEntitiesActions.newSystemMessage({
        threadId,
        systemMessage: {
          id: `${subType}-${timestampInMs}`,
          subType,
          text,
          timestampInMs,
          type: ChatEventType.Event,
          ...(textVariables && { textVariables }),
        },
      })
    );
  }
}
