import { type FC, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { Pinned, PinnedFilled } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';

import { AppsEvent, AppsSection } from 'constants/apps/events';
import { usePinnedFullscreenWidget } from 'hooks/fullscreen-widgets/use-pinned-fullscreen-widget';
import { trackAppsSectionEvent } from 'services/event-tracking';

import { pin, pinIcon } from './styles';

interface Props {
  appId: string;
  appName: string;
}

export const YourAppsItemPin: FC<Props> = ({ appId, appName }) => {
  const { isPinned, togglePin } = usePinnedFullscreenWidget(appId);
  const icon = isPinned ? PinnedFilled : Pinned;

  const togglePinnedWidget = (event: MouseEvent<HTMLSpanElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    trackAppsSectionEvent(
      isPinned ? AppsEvent.FullscreenWidgetUnpinned : AppsEvent.FullscreenWidgetPinned,
      AppsSection.SideNavigation,
      {
        applicationId: appId,
        applicationName: appName,
      },
    );
    togglePin();
  };

  return (
    <span className={cx(['display-on-hover', 'hide-siblings-on-hover', pin])} onClick={togglePinnedWidget}>
      <Icon source={icon} className={pinIcon} />
    </span>
  );
};
