import { type ReactNode, type FC } from 'react';

import { SkeletonLine } from 'components/skeleton/SkeletonLine';
import { SubscriptionRoutes } from 'constants/subscription/routes';
import { SUBSCRIPTION_LOADER_TEST_ID } from 'routes/subscription/constants';

import * as styles from './styles';

interface IProps {
  isExpired: boolean;
  showOnlyTabs?: boolean;
}

export const SubscriptionSkeleton: FC<IProps> = ({ isExpired, showOnlyTabs }) => {
  const pathName = window.location.pathname;

  const overviewSkeleton: ReactNode = (
    <div className={styles.overViewSkeletonWrapper}>
      <div>
        <SkeletonLine className={styles.overviewSkeletonHeader} loading />
        <SkeletonLine className={styles.overviewSkeletonContent} loading />
      </div>
    </div>
  );

  const accountDetailsSkeleton = (
    <div className={styles.accountDetailsSkeleton}>
      <SkeletonLine className={styles.cardSkeleton} loading />
      <SkeletonLine className={styles.cardSkeleton} loading />
      <SkeletonLine className={styles.cardSkeleton} loading />
      <SkeletonLine className={styles.cardSkeleton} loading />
    </div>
  );

  const renderSkeletonContent = (): ReactNode => {
    if (pathName.includes(SubscriptionRoutes.AccountDetails)) {
      return accountDetailsSkeleton;
    }

    if (pathName.includes(SubscriptionRoutes.Invoices)) {
      return undefined;
    }

    return overviewSkeleton;
  };

  return (
    <div className={styles.wrapper}>
      <div data-testid={SUBSCRIPTION_LOADER_TEST_ID} className={styles.contentWrapper}>
        <div className={styles.headerWrapper}>
          <SkeletonLine className={styles.header} loading />
        </div>
        {!showOnlyTabs && (
          <div className={styles.content}>
            {isExpired && (
              <div className={styles.bannerContainer}>
                <SkeletonLine className={styles.expiredBannerSkeleton} loading />
              </div>
            )}
            {renderSkeletonContent()}
          </div>
        )}
      </div>
    </div>
  );
};
