import type { RequestResult } from 'interfaces/api/client';
import { type IContactInfoApiResponse, type IEmailTakenResponse } from 'services/api/lookup/interfaces';

import { BaseApi } from '../base-api';

export class LookupApi extends BaseApi {
  getEmailTaken = (id: string): Promise<RequestResult<IEmailTakenResponse>> => this.get(`lookup/${id}`);
  getContactInfo = (id: string): Promise<RequestResult<IContactInfoApiResponse>> =>
    this.get(`external_lookup/${id}?ignoreTaken=1`);
}
