import { FC } from 'react';

import { TourStep } from 'components/tours/TourStep';

export const SavedViewsToolbarStep: FC = () => (
  <TourStep
    title="Access your views easily"
    message="All you need to do is open a report and select one of the saved report views."
  />
);

export const MySavedViewsPageStep: FC = () => (
  <TourStep title="Manage your saved views" message="See all saved views for each report in one place." />
);
