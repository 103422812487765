// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-return */
import isEqual from 'lodash.isequal';
import { createSelector } from 'reselect';

import { CustomDateRangeFilterId } from 'constants/filters/date-range-filter';
import { Filter } from 'constants/filters/filter';
import { ReportType } from 'constants/reports/report-type';
import { getFiltersByReportType } from 'helpers/reports';

import type { ReportsViewFilters, IReportView } from '../../../interfaces/reports';

import { initialState } from './reducer';
import { getFilters, type IWithReportsViewState, getCurrentView, getReportViews, getDistribution } from './selectors';

const EMPTY_ARRAY = [];

export const getFilterValuesCount = (state: IWithReportsViewState, filter: Filter): number => {
  const filters = getFilters(state);

  const filterValues = filters[filter] || EMPTY_ARRAY;

  if (Array.isArray(filterValues)) {
    return filterValues.length;
  }
  switch (filter) {
    case Filter.Agent: {
      return filterValues?.agents?.length;
    }
    case Filter.Group: {
      return filterValues?.groups?.length;
    }
  }

  return 0;
};

export const getAvailableFilters = createSelector(
  getCurrentView,
  getFilters,
  (currentReport, filters): ReportsViewFilters => {
    const { filters: reportFiltersForCurrentReport } = getFiltersByReportType(currentReport);

    const availableFilters = Object.keys(filters).reduce((acc, filterName: Filter) => {
      if (reportFiltersForCurrentReport.includes(filterName)) {
        acc[filterName] = filters[filterName];
      }

      return acc;
    }, {});

    return availableFilters;
  }
);

export const getReportViewsForCurrentReport = createSelector(getReportViews, getCurrentView, (views, currentView) => {
  if (!currentView) {
    return EMPTY_ARRAY as IReportView[];
  }

  const viewsForType = views.filter((view) => view.type === currentView);

  if (viewsForType.length === 0) {
    return EMPTY_ARRAY as IReportView[];
  }

  return viewsForType.sort((a, b) => a.name.localeCompare(b.name));
});

export const getHasInitialFilters = createSelector(
  getAvailableFilters,
  getDistribution,
  getCurrentView,
  (availableFilters, distribution, reportType) => {
    const { distribution: initialDistribution, filters: initialFilters } = initialState;

    if (distribution !== initialDistribution) {
      return false;
    }

    return Object.keys(availableFilters).every((key) => {
      if (key === Filter.Date) {
        return availableFilters[key].id === initialFilters[key].id;
      }

      if (key === Filter.DateRange) {
        const isRangeEqual = availableFilters[key].id === initialFilters[key].id;

        if (!isRangeEqual) {
          return false;
        }

        const dateRangeFilter = availableFilters[key];

        if (!dateRangeFilter.compare) {
          return true;
        }

        /**
         * This is taking into account that on Agents performance report
         * initial date range filter is setup to compare previous period.
         *
         * @see fetchReport in /source/tsx/store/views/reports/sagas.ts
         */
        if (
          reportType === ReportType.AgentsPerformance &&
          dateRangeFilter.compare.id === CustomDateRangeFilterId.SamePeriod
        ) {
          return true;
        }

        return dateRangeFilter.compare.id === initialFilters[key].compare?.id;
      }

      return isEqual(availableFilters[key], initialFilters[key]);
    });
  }
);
