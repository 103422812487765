import { ReportDistribution } from 'constants/reports/distribution';
import { ReportType } from 'constants/reports/report-type';
import { type ISerializedFilters } from 'interfaces/reports';
import { staticReportFilters } from 'store/entities/reports/constants/static-filters';

import { type IInsightsMetrics, InsightsMetricType } from './interfaces';

export const AGENTS_PERFORMANCE_REPORT_DEFAULT_FILTERS: ISerializedFilters = {
  ...staticReportFilters[ReportType.AgentsPerformance],
  distribution: ReportDistribution.Day,
};

export const MISSED_CHATS_REPORT_DEFAULT_FILTERS: ISerializedFilters = {
  ...staticReportFilters[ReportType.MissedChats],
  distribution: ReportDistribution.Day,
};

export const SHOW_INSIGHTS_LAST_WEEK_MINIMUM_CHATS_COUNT = 30;

// maps property name (used as keys in redux store) to enum type
export const insightsMetricsKeyToTypeMap: Record<keyof IInsightsMetrics, InsightsMetricType> = {
  chatsCount: InsightsMetricType.TotalChats,
  satisfaction: InsightsMetricType.ChatSatisfaction,
  chatsPerHour: InsightsMetricType.Efficiency,
  firstResponseTime: InsightsMetricType.ResponseTime,
  missedChats: InsightsMetricType.MissedChats,
};

// order of displayed metrics
export const INSIGHTS_METRICS_ORDER: Record<InsightsMetricType, number> = {
  [InsightsMetricType.TotalChats]: 1,
  [InsightsMetricType.ChatSatisfaction]: 2,
  [InsightsMetricType.ResponseTime]: 3,
  [InsightsMetricType.MissedChats]: 4,
  [InsightsMetricType.Efficiency]: 5,
};

export const WEEKLY_CHATS_COUNT_LOW_NORMAL_THRESHOLD = 100;

// hints will be displayed only if percentage difference of metric was >= than values stored in this object
// SMALL - licenses with count of weekly chats up to WEEKLY_CHATS_COUNT_LOW_NORMAL_THRESHOLD
export const INSIGHTS_HINTS_PERCENTAGE_DIFF_THRESHOLD_LOW_CHATS_COUNT: Record<InsightsMetricType, number> = {
  [InsightsMetricType.TotalChats]: 10,
  [InsightsMetricType.ChatSatisfaction]: 10,
  [InsightsMetricType.Efficiency]: 10,
  [InsightsMetricType.ResponseTime]: 10,
  [InsightsMetricType.MissedChats]: 5,
};

// hints will be displayed only if percentage difference of metric was >= than values stored in this object
// NORMAL - licenses with count of weekly chats above WEEKLY_CHATS_COUNT_LOW_NORMAL_THRESHOLD
export const INSIGHTS_HINTS_PERCENTAGE_DIFF_THRESHOLD_NORMAL_CHATS_COUNT: Record<InsightsMetricType, number> = {
  [InsightsMetricType.TotalChats]: 3,
  [InsightsMetricType.ChatSatisfaction]: 5,
  [InsightsMetricType.Efficiency]: 3,
  [InsightsMetricType.ResponseTime]: 5,
  [InsightsMetricType.MissedChats]: 5,
};

export enum InsightsEvent {
  MetricsGenerated = 'Metrics: metrics generated',
  MetricSelected = 'Metric: metric selected',
  HintLinkClicked = 'Hints: hint link clicked',
  EmptyStateRedirectToReports = 'EmptyState: redirect to reports clicked',
}
