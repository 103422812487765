import { useState, useEffect, useCallback, useMemo } from 'react';

import { differenceInSeconds, isPast } from 'date-fns';
import debounce from 'lodash.debounce';

import useInterval from 'hooks/use-interval';

const INTERVAL_DURATION = 500;

const calculateInitialSecondsTillNextReconnect = (nextReconnectAttemptTimestamp: number | null): number | null => {
  if (nextReconnectAttemptTimestamp === null) {
    return null;
  }

  const now = Date.now();

  if (isPast(nextReconnectAttemptTimestamp)) {
    return 0;
  }

  return differenceInSeconds(nextReconnectAttemptTimestamp, now);
};

export const useSecondsUntilNextReconnect = (nextReconnectAttemptTimestamp: number | null): number | null => {
  const calcSecondsTillNextReconnect = useCallback((): number | null => {
    return calculateInitialSecondsTillNextReconnect(nextReconnectAttemptTimestamp);
  }, [nextReconnectAttemptTimestamp]);

  const [secondsTillNextReconnect, setSecondsTillNextReconnect] = useState<number | null>(calcSecondsTillNextReconnect);

  const refreshTimer = useMemo(() => {
    return debounce(() => {
      const seconds = calcSecondsTillNextReconnect();
      setSecondsTillNextReconnect(seconds);
    }, INTERVAL_DURATION);
  }, [calcSecondsTillNextReconnect]);

  useEffect(() => {
    refreshTimer();

    return refreshTimer.cancel;
  }, [nextReconnectAttemptTimestamp, refreshTimer]);

  useInterval(refreshTimer, nextReconnectAttemptTimestamp ? INTERVAL_DURATION : null);

  return secondsTillNextReconnect;
};
