import type { FC } from 'react';

import { Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { getIsCopilotDetailsOpened } from 'store/views/copilot/selectors';

import { useCopilotMessageContext } from '../CopilotMessageContext';

import * as styles from './styles';

interface IProps {
  id: number;
  message: string;
  description: string;
  onClick?: () => void;
}

export const Suggestion: FC<IProps> = ({ id, message, description, onClick }) => {
  const isOneDetailsOpened = useSelector(getIsCopilotDetailsOpened);
  const { handleSendMessage } = useCopilotMessageContext();

  const handleSuggestionClick = (): void => {
    if (onClick) {
      onClick();
      trackEvent(CopilotTrackEvent.MessageSent, EventPlace.One, {
        source: 'suggestion chip',
        message,
        state: 'fullscreen',
      });

      return;
    }
    handleSendMessage(message);
    trackEvent(CopilotTrackEvent.MessageSent, EventPlace.One, {
      source: 'suggestion chip',
      message,
      state: 'fullscreen',
    });
  };

  return (
    <div
      className={styles.suggestion(isOneDetailsOpened)}
      onClick={handleSuggestionClick}
      data-testid={`suggestion-${id}`}
    >
      <Text as="span">{message}</Text>

      <Text as="p">{description}</Text>
    </div>
  );
};
