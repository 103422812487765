import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../../base-api';
import { type IApiChatSurvey } from '../interfaces';

export class PostchatSurveyApi extends BaseApi {
  protected readonly prefix = 'surveys/postchat';

  fetch = (groupId: string): Promise<RequestResult<IApiChatSurvey>> => this.get(`${groupId}`);
  update = (groupId: string, prechatSurvey: IApiChatSurvey): Promise<RequestResult<IApiChatSurvey>> =>
    this.put(`${groupId}`, { ...prechatSurvey });
}
