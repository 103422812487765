// @ts-strict-ignore
import uniq from 'lodash.uniq';
import { type SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import { type Filters, Filter, FilterOperator } from 'constants/filters/filter';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { sumReducer, sumArraysValues } from 'helpers/numbers';
import { getSeriesDataFromPeriod } from 'helpers/reports';
import type { IReportsViewGroupFilter, ITagsUsageData, ITagBreakdown, ITagUsagePeriodData } from 'interfaces/reports';
import type { ReportData } from 'interfaces/reports/api-v3';
import type { ITag } from 'store/entities/tags/interfaces';
import { getTags } from 'store/entities/tags/selectors';

import { getFilterGroup, getFilterTag, getFilterOperator } from '../selectors';

interface ITagsUsagePayload {
  data: {
    tagsUsage: ReportData<ITagUsagePeriodData>;
    untagged: ReportData<ITagUsagePeriodData>;
    tagged: ReportData<ITagUsagePeriodData>;
  };
}

interface IParsedTag {
  name: string;
  groups: string[];
}

export function* tagsUsageDeserializer({ data }: ITagsUsagePayload): SagaIterator {
  const allTags: ITag[] = yield select(getTags);
  const groupFilter: IReportsViewGroupFilter = yield select(getFilterGroup);
  let tagFilter: Filters[Filter.Tag] = yield select(getFilterTag);
  const filterOperator = yield select(getFilterOperator, Filter.Tag);

  if (filterOperator === FilterOperator.Exclude && !!tagFilter?.length) {
    tagFilter = allTags.reduce<string[]>((acc, tag) => {
      if (!tagFilter || !tagFilter.includes(tag.name)) {
        acc.push(tag.name);
      }

      return acc;
    }, []);
  }

  const series = [];
  const breakdown: ITagBreakdown[] = [];
  const summary = [];

  const { records, total: taggedSum } = data.tagsUsage;
  const { records: untaggedRecords, total: totalUntaggedSum } = data.untagged;
  const { records: taggedRecords, total: totalTaggedSum } = data.tagged;

  let tagsUsage: number[] = new Array(Object.keys(records).length).fill(0);
  const totalTagged = getSeriesDataFromPeriod<number>(taggedRecords, ':total:');
  const totalUntagged = getSeriesDataFromPeriod<number>(untaggedRecords, ':total:');

  const allChats = totalTaggedSum + totalUntaggedSum;

  // Parse tags to unique tags with array of group
  const parsedTags = allTags.reduce<IParsedTag[]>((acc, { name, group }) => {
    const tag = acc.find((t) => t.name === name);
    if (!tag) {
      acc.push({ name, groups: [String(group)] });
    } else {
      tag.groups.push(String(group));
    }

    return acc;
  }, []);

  // Filter tags that are enable in filtered groups. If no filter - then show tags from all groups
  const groupTags = (
    groupFilter && groupFilter.groups
      ? parsedTags.filter((tag) => groupFilter.groups.some((group) => tag.groups.includes(group)))
      : parsedTags
  ).map((tag) => tag.name);

  const respondedTags = []
    .concat(...Object.values(records).map((t) => Object.keys(t)))
    .filter((tag) => !groupTags.some((t) => t.toLowerCase() === tag));

  let tags: string[] = uniq([].concat(respondedTags, groupTags, tagFilter || []));

  if (tagFilter && tagFilter.length) {
    tags = tags.filter((tag) => tagFilter.includes(tag));
  }

  tags.forEach((tag) => {
    const usage = getSeriesDataFromPeriod<number>(records, tag);

    const usageSum = usage.reduce(sumReducer, 0);

    summary.push({
      group: tag,
      name: tag,
      color: DEFAULT_SERIES_COLOR,
      value: usageSum,
    });

    series.push({
      group: tag,
      name: tag,
      color: DEFAULT_SERIES_COLOR,
      data: usage,
    });

    breakdown.push({
      id: tag,
      name: tag,
      usage: usageSum,
      conversion: usageSum / totalTaggedSum,
    });

    tagsUsage = sumArraysValues(tagsUsage, usage);
  });

  series.push({
    group: null,
    name: 'Tagged chats',
    color: DEFAULT_SERIES_COLOR,
    data: tagFilter && tagFilter.length ? tagsUsage : totalTagged,
    disableLink: true,
  });

  summary.push({
    name: 'Tagged chats',
    color: DEFAULT_SERIES_COLOR,
    value: tagFilter ? taggedSum : totalTaggedSum,
    label: !tagFilter && `(${getFormattedPercent(totalTaggedSum / (allChats || 1), 2)})`,
    disableLink: true,
  });

  if (!tagFilter || !tagFilter.length) {
    series.push({
      group: null,
      name: 'Not tagged chats',
      color: DEFAULT_SERIES_COLOR,
      data: totalUntagged,
    });

    summary.push({
      name: 'Not tagged chats',
      color: DEFAULT_SERIES_COLOR,
      value: totalUntaggedSum,
      label: `(${getFormattedPercent(totalUntaggedSum / (allChats || 1), 2)})`,
    });
  }

  const checked = tagFilter && tagFilter.length === 1 ? tagFilter : [];

  const result: ITagsUsageData = {
    tagsUsage: {
      summary,
      labels: Object.keys(records),
      series,
      breakdown,
    },
    checked,
  };

  return result;
}
