import type { FC } from 'react';

import { Close } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { getConfig } from 'helpers/config';
import { NavigationViewActions } from 'store/views/navigation/actions';

import * as styles from './styles';

export const HelpDeskAppTooltip: FC = () => {
  const dispatch = useDispatch();

  const handleCancel = (): void => {
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: getConfig().helpDeskAppWidgetId }));
  };

  return (
    <div className={styles.wrapper}>
      <div onClick={handleCancel} className={styles.icon}>
        <Icon source={Close} size="xsmall" />
      </div>
      <p className={styles.text}>Go back to HelpDesk when you’re ready.</p>
    </div>
  );
};
