import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type TransferChatEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#transfer-chat
 */
export async function transferToGroup(chatId: string, groupId: string): Promise<unknown> {
  const event: TransferChatEvent = {
    action: WebSocketAction.TransferChat,
    payload: {
      id: chatId,
      target: {
        type: 'group',
        ids: [parseInt(groupId, 10)],
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ignore_requester_presence: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ignore_agents_availability: true,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
