// @ts-strict-ignore
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { AgentAssignmentFilter } from 'constants/filters/agent-assignment-filter';
import { Filter } from 'constants/filters/filter';
import { TagFilter } from 'constants/filters/tag-filter';
import { TicketsBatchAction, batchActionStatusMap } from 'constants/tickets/batch-action';
import { Route } from 'constants/tickets/navigation-items';
import { booleanToNumericString } from 'helpers/boolean';
import { type IKeyAny } from 'helpers/url';
import type { RequestResult } from 'interfaces/api/client';
import type { TicketCreateParams } from 'interfaces/api/ticket';
import type { IAssignee, IRequester, TicketStatus } from 'interfaces/entities/ticket';
import type { GroupId } from 'interfaces/groups';
import type { IFetchTicketsCollectionPayload } from 'store/entities/tickets/interfaces';
import type { ITicketsViewBatchUpdatePayloadData } from 'store/views/tickets/interfaces';

import { BaseApi } from '../base-api';

export interface IUpdateTicketTagsParams {
  tag: string[];
}

export interface IUpdateTicketTagsResponse {
  tags: string[];
}

export interface IUpdateTicketSubjectParams {
  subject: string;
}

export interface IUpdateTicketSubjectResponse {
  subject: string;
}

interface IUpdateTicketRequesterParams {
  requesterEmail: string;
}

interface ITicketDetails {
  id: string;
  status: TicketStatus;
  subject: string;
}

interface IFetchTicketResult {
  tickets: ITicketDetails[];
}

interface IUpdateTicketRequesterResponse {
  requester: IRequester;
}

interface IUpdateTicketCCsParams {
  ccs: string[];
}

interface IUpdateTicketCCsResponse {
  ccs: {
    mail: string;
  }[];
}

interface IUpdateTicketAssigneeParams {
  assigneeId: string;
}

export interface IUpdateTicketAssigneeResponse {
  assignee: IAssignee;
}

export interface IFetchTicketCountersResult {
  badge_count: number;
  unassigned_tickets: number;
  open_tickets: number;
}

export interface ISendTicketMessageParams {
  message: string;
  isPrivate: boolean;
  attachments: File[];
  status?: TicketStatus;
}
export interface ICreateTicketResponse {
  id: string;
  assignee?: { id: string; name: string; account_id: string };
  events: {
    author: {
      id: string;
      name: string;
      type: string;
      account_id: string;
    };
    date: string;
    is_private: boolean;
    message: string;
    type: string;
    source: { type: string; url?: string };
  }[];
  requester: {
    mail: string;
    name: string;
    ip: string;
  };
  groups: { id: number; name: string; inherited: boolean }[];
  status: TicketStatus;
  subject: string;
  modified: string;
  source: { type: string; url?: string; id?: string };
  opened: [{ from: string }];
  firstResponse: unknown;
  tags: string[];
  rate: 'not_rated';
  date: string;
  is_sample: boolean;
  currentGroup: { id: number; name: string; inherited: boolean };
}

export interface ICreateTicketErrorResponse {
  error?: string | string[];
  errors?: string[];
}

export function getBatchParams(
  ticketIds: string[],
  batchAction: TicketsBatchAction,
  data?: ITicketsViewBatchUpdatePayloadData
): IKeyAny {
  const params: IKeyAny = {
    ids: ticketIds,
  };

  if (batchAction === TicketsBatchAction.ChangeAssignee) {
    params.assignee = {
      id: data.assignee.id,
    };
  } else if (batchAction === TicketsBatchAction.ChangeGroup) {
    params.group = [data.group.id];
  } else {
    params.status = batchActionStatusMap[batchAction];
  }

  return params;
}

export function getUpdateRequesterParams(params: IUpdateTicketRequesterParams): IKeyAny {
  return {
    requester: {
      mail: params.requesterEmail,
    },
  };
}

export function getUpdateAssigneeParams(params: IUpdateTicketAssigneeParams): IKeyAny {
  if (params.assigneeId) {
    return {
      assignee: {
        id: params.assigneeId,
      },
    };
  }

  return {
    assignee: {
      remove: true,
    },
  };
}

export function getUpdateCCsParams(params: IUpdateTicketCCsParams): IKeyAny {
  return {
    ccs: params.ccs.map((cc) => ({
      mail: cc,
    })),
  };
}

export function getCreateTicketParams(params: TicketCreateParams): FormData {
  const data = new FormData();
  data.append('requester[mail]', params.requester.mail);
  if (params.requester.name) {
    data.append('requester[name]', params.requester.name);
  }
  if (params.chatId) {
    data.append('chat_id', params.chatId);
  }
  if (params.subject) {
    data.append('subject', params.subject);
  }
  if (params.message) {
    data.append('message', params.message);
  }
  if (params.assigneeId) {
    data.append('assignee[id]', params.assigneeId);
  }
  if (params.groupIds) {
    if (params.groupIds.length === 1) {
      data.append('group[]', params.groupIds[0]);
    } else {
      params.groupIds.forEach((groupId) => {
        data.append('group', groupId);
      });
    }
  }
  if (params.sourceType) {
    data.append('source[type]', params.sourceType);
  }
  if (params.attachments) {
    params.attachments.forEach((attachment) => {
      data.append('attachments', attachment, attachment.name);
    });
  }

  return data;
}

export function getSendMessageParams(params: ISendTicketMessageParams): FormData {
  const data = new FormData();
  if (params.message) {
    data.append('message', params.message);
  }
  if (params.status) {
    data.append('status', params.status);
  }
  data.append('is_private', booleanToNumericString(params.isPrivate));
  params.attachments.forEach((attachment) => {
    data.append('attachments', attachment, attachment.name);
  });

  return data;
}

const routeStatusMap = {
  [Route.Unassigned]: 'open',
  [Route.MyOpen]: 'open',
  [Route.Open]: 'open',
  [Route.Pending]: 'pending',
  [Route.Solved]: 'solved',
  [Route.Spam]: 'spam',
};

export function getStatusForRoute(route: Route): string {
  return (routeStatusMap[route] as string) || null;
}

const agentAssignmentMap = {
  [AgentAssignmentFilter.Unassigned]: '0',
  [AgentAssignmentFilter.Assigned]: '1',
};

export const filterMappers = {
  [Filter.Agent]: (values) => ({
    assignee: values[0] || null,
  }),
  [Filter.AgentAssignment]: (values) => ({
    assigned: agentAssignmentMap[values[0]] || null,
  }),
  [Filter.Group]: (values: string[]) => ({
    groups: values || null,
  }),
  [Filter.Rating]: (values) => ({
    rate: values[0] || null,
  }),
  [Filter.TicketStatus]: (values) => ({
    status: values[0] || null,
  }),
  [Filter.ResponseAuthor]: (values) => ({
    response_author: values[0] || null,
  }),
  [Filter.DateRange]: (values) => ({
    date_from: format(values.from, DateFormat.ISO8601Date) || null,
    date_to: format(values.to, DateFormat.ISO8601Date) || null,
  }),
  [Filter.RatingDateRange]: (values) => ({
    rate_date_from: format(values.from, DateFormat.ISO8601Date) || null,
    rate_date_to: format(values.to, DateFormat.ISO8601Date) || null,
  }),
  [Filter.ResolutionDateRange]: (values) => ({
    resolution_date_from: format(values.from, DateFormat.ISO8601Date) || null,
    resolution_date_to: format(values.to, DateFormat.ISO8601Date) || null,
  }),
  [Filter.ResponseDateRange]: (values) => ({
    response_date_from: format(values.from, DateFormat.ISO8601Date) || null,
    response_date_to: format(values.to, DateFormat.ISO8601Date) || null,
  }),
  [Filter.CreationDateRange]: (values) => ({
    creation_date_from: format(values.from, DateFormat.ISO8601Date) || null,
    creation_date_to: format(values.to, DateFormat.ISO8601Date) || null,
  }),
};

const sortOrderMap = {
  [Route.Unassigned]: 'asc',
  [Route.MyOpen]: 'asc',
  [Route.Open]: 'asc',
};

export const parseFetchTicketsParams = (
  params: IFetchTicketsCollectionPayload,
  currentUserLogin = ''
): { [key: string]: string } => {
  let finalParams: { [key: string]: string } = {};

  const status = getStatusForRoute(params.route);
  if (status) {
    finalParams.status = status;
  }

  if (params.filters) {
    Object.keys(params.filters).forEach((filterName) => {
      if (params.filters[filterName]) {
        if (filterMappers[filterName]) {
          finalParams = {
            ...finalParams,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            ...filterMappers[filterName](params.filters[filterName]),
          };
        } else {
          finalParams = {
            ...finalParams,
            [filterName]: params.filters[filterName],
          };
        }
      }
    });
  }

  if (params.query && params.route === Route.Search) {
    finalParams.query = params.query;
  }

  if (params.route === Route.Unassigned) {
    finalParams.assigned = '0';
    finalParams.from_all_groups = '0';
    finalParams.has_public_message = '1';
    finalParams.in_current_group = '1';
    finalParams.leftovers = '1';
  } else if (params.route === Route.MyOpen) {
    finalParams.assignee = currentUserLogin;
    finalParams.has_public_message = '1';
    finalParams.leftovers = '1';
  }

  if (sortOrderMap[params.route]) {
    finalParams.order = sortOrderMap[params.route];
  }

  if (finalParams[Filter.Tag] && finalParams[Filter.Tag].includes(TagFilter.NotTagged)) {
    delete finalParams[Filter.Tag];
    finalParams.tagged = '0';
  }

  if (params.page && params.page > 1) {
    finalParams.page = String(params.page);
  }

  return finalParams;
};

export class TicketApi extends BaseApi {
  protected readonly prefix = 'tickets';

  createTicket = (params: TicketCreateParams) => this.post('', getCreateTicketParams({ ...params }));

  fetch = (id: string) => this.get(id);

  fetchAll = (params: IFetchTicketsCollectionPayload, currentUserLogin: string) =>
    this.get('', parseFetchTicketsParams(params, currentUserLogin));

  fetchByThreadId = (threadId: string) => this.get('', { source_id: threadId });

  fetchByRequesterEmail = (requesterEmail: string): Promise<RequestResult<IFetchTicketResult>> =>
    this.get('', { 'requester[mail]': requesterEmail });

  fetchCounters = (): Promise<RequestResult<IFetchTicketCountersResult>> => this.get('summary_count');

  batch = (ticketIds: string[], batchAction: TicketsBatchAction, data?: ITicketsViewBatchUpdatePayloadData) =>
    this.post('batch', getBatchParams(ticketIds, batchAction, data));

  updateTags = (ticketId: string, params: IUpdateTicketTagsParams) =>
    this.put<IUpdateTicketTagsResponse>(`${ticketId}/tags`, { ...params });

  updateSubject = (ticketId: string, params: IUpdateTicketSubjectParams) =>
    this.put<IUpdateTicketSubjectResponse>(`${ticketId}`, { ...params });

  updateRequester = (ticketId: string, params: IUpdateTicketRequesterParams) =>
    this.put<IUpdateTicketRequesterResponse>(`${ticketId}`, getUpdateRequesterParams({ ...params }));

  updateCCs = (ticketId: string, params: IUpdateTicketCCsParams) =>
    this.put<IUpdateTicketCCsResponse>(`${ticketId}`, getUpdateCCsParams({ ...params }));

  updateAssignee = (ticketId: string, params: IUpdateTicketAssigneeParams) =>
    this.put<IUpdateTicketAssigneeResponse>(`${ticketId}`, getUpdateAssigneeParams({ ...params }));

  addGroups = (ticketId: string, groupIds: number[]) => this.put(`${ticketId}`, { group: groupIds });

  removeGroups = (ticketId: string, groupIds: number[]) => this.put(`${ticketId}`, { remove_group: groupIds });

  sendMessage = (ticketId: string, params: ISendTicketMessageParams) =>
    this.put(`${ticketId}`, getSendMessageParams({ ...params }));

  updateStatus = (ticketId: string, status: TicketStatus) =>
    this.put<IUpdateTicketRequesterResponse>(`${ticketId}`, { status });

  fetchEmailsMapping = (group: GroupId) => this.get('emails', { group: Number(group) });

  saveEmailMapping = (email: string, name: string, groupId: GroupId) =>
    this.put('emails', { email, caption: name, group: Number(groupId) });

  clearEmailMapping = (group: GroupId) => this.delete('emails', { group: Number(group) });
}
