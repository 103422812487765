// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { type SagaIterator } from 'redux-saga';
import { call, fork, put, select, take, takeEvery, takeLatest, throttle } from 'redux-saga/effects';

import { type Filters } from 'constants/filters/filter';
import { GENERAL_GROUP_ID } from 'constants/groups';
import { BaseRouteName } from 'constants/routes';
import { SettingsEvent } from 'constants/settings/events';
import { SoundNotification } from 'constants/sound-notifications';
import { TicketsBatchAction } from 'constants/tickets/batch-action';
import { SESSION_STORAGE_DRAFT_KEY } from 'constants/tickets/drafts';
import { Route, routeToPathnameMap } from 'constants/tickets/navigation-items';
import { TicketEvent } from 'constants/tickets/ticket-event';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { ViewActionSource } from 'constants/view-actions-source';
import { EventPlace } from 'helpers/analytics';
import { SettingsSection } from 'helpers/analytics-settings';
import { type KeyMap } from 'helpers/interface';
import { getRoute as getRouteAddres } from 'helpers/routes';
import { navigate } from 'helpers/routing';
import { getFiltersForRoute } from 'helpers/tickets';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { SourceType, type ITicket } from 'interfaces/entities/ticket';
import { ApiManager } from 'services/api/api-manager';
import { type IFetchTicketCountersResult } from 'services/api/ticket/api';
import { Notification } from 'services/connectivity/configuration-api/agents/types';
import { trackEvent, trackSettingsEvent } from 'services/event-tracking';
import { getItem, saveItem } from 'services/session-storage';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { type Notifications } from 'store/entities/agents/interfaces';
import { getIsNotificationEnabled, getLoggedInAgentNotifications } from 'store/entities/agents/selectors';
import type { EntityFetchFailure } from 'store/entities/interfaces';
import { TagActions } from 'store/entities/tags/actions';
import { TICKET, TicketActions } from 'store/entities/tickets/actions';
import {
  type ICreateTicketFailurePayload,
  type ICreateTicketSuccessPayload,
  type IFetchSingleTicketSuccessPayload,
  type IUpdateTicketAssigneeSuccessPayload,
  type IUpdateTicketRequesterSuccessPayload,
} from 'store/entities/tickets/interfaces';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getTicketStatusTooltipSeen } from 'store/features/agent-custom-properties/selectors';
import { BrowserNotificationsActions } from 'store/features/browser-notifications/actions';
import { SoundNotificationActions } from 'store/features/sound-notifications/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { TooltipsActions } from 'store/features/tooltips/actions';
import { TooltipType } from 'store/features/tooltips/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { createHasFetchedSelector } from 'store/requests/selectors';
import { NavigationViewActions } from 'store/views/navigation/actions';
import { TicketsViewActions, TicketsViewActionsNames } from 'store/views/tickets/actions';

import { FETCH_TICKETS_ON_TICKET_UPDATE_THROTTLE_TIME } from './constants';
import type {
  IClearTicketsEmailMappingPayload,
  IEmailMapping,
  IFetchTicketsEmailsMappingPayload,
  ISaveTicketsEmailMappingPayload,
  ITicketsViewBatchUpdatePayload,
  ITicketsViewBatchUpdateSuccessPayload,
  ITicketsViewClearDraftPayload,
  ITicketsViewCreateTicketPayload,
  ITicketsViewSaveDraftPayload,
  ITicketsViewSendMessagePayload,
  ITicketsViewSetRoutePayload,
  ITicketsViewUnspamPayload,
  ITicketsViewUpdateAssigneePayload,
  ITicketsViewUpdateCCsPayload,
  ITicketsViewUpdateFilterPayload,
  ITicketsViewUpdateGroupsPayload,
  ITicketsViewUpdateRequesterPayload,
  ITicketsViewUpdateSubjectPayload,
  ITicketsViewUpdateTagsPayload,
  ITicketsViewUpdateTicketCountersPayload,
} from './interfaces';
import {
  getCurrentPage,
  getEmailsMapping,
  getFilters,
  getFiltersOrder,
  getRoute,
  getSearchQuery,
  getTicket,
  getTickets,
} from './selectors';
import { deserializeTicketsEmailsMapping } from './serializers';

/**
 * @param action An action that invokes fetchTickets. Note that there are many
 *   possible actions that invoke it - see ticketsViewSagas() to find these actions
 */
function* fetchTickets(action: IActionWithPayload<string, unknown>): SagaIterator {
  const route: Route = yield select(getRoute);
  const filters: Filters = yield select(getFilters);
  const query = yield select(getSearchQuery);
  const page = yield select(getCurrentPage);

  if ((action.type as TicketsViewActionsNames) !== TicketsViewActionsNames.UPDATE_TICKET_COUNTERS) {
    yield put(TicketsViewActions.resetSelectedTickets());
  }

  if (route) {
    yield put(
      TicketActions.fetchCollection({
        route,
        query,
        page,
        filters: getFiltersForRoute(filters, route),
      })
    );
  }
}

function* searchQueryChanged(action: IActionWithPayload<string, { query: string }>): SagaIterator {
  const { query } = action.payload;
  const route = yield select(getRoute);

  if (query.length === 0 && route === Route.Search) {
    yield call(navigate, '/tickets/all');
  } else if (query.length > 0) {
    if (route !== Route.Search) {
      yield call(navigate, '/tickets/search');
      trackEvent(TicketEvent.SearchUsed, EventPlace.Tickets);
    } else {
      yield call(fetchTickets, action);
    }
  }
}

function* searchCleared(action: IActionWithPayload<string, null>): SagaIterator {
  const route = yield select(getRoute);
  if (route === Route.Search) {
    navigate('/tickets/all');
  } else {
    yield call(fetchTickets, action);
  }
}

export function* saveDraft(action: IActionWithPayload<string, ITicketsViewSaveDraftPayload>): SagaIterator {
  const { ticketId, message, isPrivate, status, subject, requesterName, requesterEmail, assigneeId, groupIds } =
    action.payload;

  let ticketDrafts: KeyMap<unknown> = yield call(getItem, SESSION_STORAGE_DRAFT_KEY);
  if (!ticketDrafts) {
    ticketDrafts = {};
  }
  ticketDrafts[ticketId] = { message, isPrivate, status, subject, requesterName, requesterEmail, assigneeId, groupIds };

  yield call(saveItem, SESSION_STORAGE_DRAFT_KEY, ticketDrafts);
}

export function* clearDraft(action: IActionWithPayload<string, ITicketsViewClearDraftPayload>): SagaIterator {
  const { ticketId } = action.payload;

  const ticketDrafts: KeyMap<unknown> = yield call(getItem, SESSION_STORAGE_DRAFT_KEY);
  if (ticketDrafts) {
    delete ticketDrafts[ticketId];
    yield call(saveItem, SESSION_STORAGE_DRAFT_KEY, ticketDrafts);
  }
}

export function* unspam(action: IActionWithPayload<string, ITicketsViewUnspamPayload>): SagaIterator {
  yield put(TicketActions.unspam(action.payload));
}

function* copyTicketUrl(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_COPY_URL_SUCCESS),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
  trackEvent(TicketEvent.URLCopied, EventPlace.Tickets);
}

function* showFromAllGroups(): SagaIterator {
  yield call(navigate, routeToPathnameMap[Route.All]);
  trackEvent(TicketEvent.SeeTicketsFromAllGroups, EventPlace.Tickets);
}

function* routeChanged(action: IActionWithPayload<string, ITicketsViewSetRoutePayload>): SagaIterator {
  const { route } = action.payload;
  const searchQuery: string = yield select(getSearchQuery);
  if (route !== Route.Search && searchQuery.length > 0) {
    yield put(TicketsViewActions.setSearchQuery(''));
  }
}

export function* fetchSingle(action: IActionWithPayload<string, { id: string }>): SagaIterator {
  const { id } = action.payload;
  const loadedTickets: ITicket[] = yield select(getTickets);
  const cachedTicket = loadedTickets.find((ticket) => ticket.id === id);
  if (cachedTicket) {
    yield put(TicketsViewActions.setCurrentItem(cachedTicket));
  }
  yield put(TicketActions.fetch({ id, source: ViewActionSource.Tickets }));
}

function* updateCurrentTicket(action: IActionWithPayload<string, IFetchSingleTicketSuccessPayload>): SagaIterator {
  const { value } = action.payload;
  yield put(TicketsViewActions.setCurrentItem(value));
}

function* ticketFetchFailed(action: IActionWithPayload<string, EntityFetchFailure>): SagaIterator {
  const { source } = action.payload;

  if ((source as ViewActionSource) === ViewActionSource.Tickets) {
    yield call(navigate, '/tickets');
  }
}

export function* ticketsUpdated(action: IActionWithPayload<string, { ticketIds: string[] }>): SagaIterator {
  const { ticketIds } = action.payload;
  const currentTicket: ITicket = yield select(getTicket);
  if (currentTicket && ticketIds.some((ticketId) => currentTicket.id === ticketId)) {
    yield put(TicketActions.fetch({ id: currentTicket.id, source: ViewActionSource.Tickets }));
  }
}

function* updateFilter(action: IActionWithPayload<string, ITicketsViewUpdateFilterPayload>): SagaIterator {
  const filtersOrder: string[] = yield select(getFiltersOrder);
  trackEvent(TicketEvent.FilterApplied, EventPlace.Tickets, {
    filter_type: action.payload.name,
    concurrent_filters: filtersOrder.length,
  });
}

function* fetchTicketCounters(): SagaIterator {
  const { result }: RequestResult<IFetchTicketCountersResult> = yield call(ApiManager.ticketApi.fetchCounters);

  if (result) {
    yield put(
      TicketsViewActions.updateTicketCounters({
        [Route.Unassigned]: result.unassigned_tickets,
        [Route.MyOpen]: result.open_tickets,
      })
    );

    const itemId = BaseRouteName.Tickets;
    const badge = { numeric: result.badge_count };

    yield put(
      badge.numeric
        ? NavigationViewActions.setNavigationItemBadge({ itemId, badge })
        : NavigationViewActions.clearNavigationItemBadge({ itemId })
    );

    yield put(TicketsViewActions.fetchTicketCountersSuccess());
  } else {
    yield put(TicketsViewActions.fetchTicketCountersFailure());
  }
}

function* fetchTags(): SagaIterator {
  const wasTagsFetched = yield select(createHasFetchedSelector(['FETCH_COLLECTION_TAG']));

  if (!wasTagsFetched) {
    yield put(TagActions.fetchCollection({}));
  }
}

export function* updateTags(action: IActionWithPayload<string, ITicketsViewUpdateTagsPayload>): SagaIterator {
  yield put(TicketActions.updateTags({ ...action.payload }));
}

export function* updateSubject(action: IActionWithPayload<string, ITicketsViewUpdateSubjectPayload>): SagaIterator {
  yield put(TicketActions.updateSubject(action.payload));
  trackEvent(TicketEvent.SubjectChanged, EventPlace.Tickets);
}

function* updateRequester(action: IActionWithPayload<string, ITicketsViewUpdateRequesterPayload>): SagaIterator {
  yield put(TicketActions.updateRequester(action.payload));
  trackEvent(TicketEvent.RequesterChanged, EventPlace.Tickets);
}

export function* updateCCs(action: IActionWithPayload<string, ITicketsViewUpdateCCsPayload>): SagaIterator {
  const { payload } = action;
  yield put(TicketActions.updateCCs(payload));
  trackEvent(TicketEvent.CCsChanged, EventPlace.Tickets, {
    'CCs total': payload.ccs.length,
  });
}

function* updateAssignee(action: IActionWithPayload<string, ITicketsViewUpdateAssigneePayload>): SagaIterator {
  yield put(TicketActions.updateAssignee(action.payload));
  trackEvent(TicketEvent.AssigneeChanged, EventPlace.Tickets);
}

function* updateGroups(action: IActionWithPayload<string, ITicketsViewUpdateGroupsPayload>): SagaIterator {
  const { payload } = action;
  yield put(TicketActions.updateGroups(payload));
  trackEvent(TicketEvent.GroupsChanged, EventPlace.Tickets, {
    'Groups total': payload.newGroupIds.length,
  });
}

function* updateGroupsSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_GROUPS_UPDATE_SUCCESS),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateGroupsFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_GROUPS_UPDATE_ERROR),
      kind: ToastVariant.Error,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateSubjectSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_SUBJECT_UPDATE_SUCCESS),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateRequesterSuccess(
  action: IActionWithPayload<string, IUpdateTicketRequesterSuccessPayload>
): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_REQUESTER_UPDATE_SUCCESS, { payload: action.payload }),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateAssigneeSuccess(action: IActionWithPayload<string, IUpdateTicketAssigneeSuccessPayload>): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_ASSIGNEE_UPDATE_SUCCESS, { payload: action.payload }),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateCCsSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_CCS_UPDATE_SUCCESS),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateRequesterFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_REQUESTER_UPDATE_ERROR),
      kind: ToastVariant.Error,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateCCsFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_CCS_UPDATE_ERROR),
      kind: ToastVariant.Error,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateAssigneeFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_ASSIGNEE_UPDATE_ERROR),
      kind: ToastVariant.Error,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateTicketFailure(action: IActionWithPayload<string, ICreateTicketFailurePayload>): SagaIterator {
  const isTooManyRequests = action.payload.error === 'Too many requests';
  const message = isTooManyRequests ? ToastContent.TAG_UPDATE_LIMIT_ERROR : ToastContent.TAG_UPDATE_ERROR;
  yield put(
    ToastsActions.createToast({
      content: getToastContent(message),
      autoHideDelayTime: isTooManyRequests ? ToastAutoHideDelay.VeryLong : ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
    })
  );
}

function* createTicket(action: IActionWithPayload<string, ITicketsViewCreateTicketPayload>): SagaIterator {
  const payload = {
    ...action.payload,
    sourceType: SourceType.Manual,
  };
  yield put(TicketActions.createTicket(payload));
  trackEvent(TicketEvent.TicketCreatedManually, EventPlace.Tickets, {
    'Is assigned': payload.assigneeId ? 'Yes' : 'No',
  });
}

function* createTicketSuccess(action: IActionWithPayload<string, ICreateTicketSuccessPayload>): SagaIterator {
  const { payload } = action;

  if (payload.sourceType !== SourceType.Manual) {
    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_SUCCESS),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Success,
    })
  );

  navigate(`/tickets/${payload.ticketId}`);
}

function* createTicketFailure(action: IActionWithPayload<string, ICreateTicketFailurePayload>): SagaIterator {
  const { payload } = action;

  if (payload.sourceType !== SourceType.Manual) {
    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_ERROR, { error: payload.error }),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
    })
  );
}

export function* sendMessage(action: IActionWithPayload<string, ITicketsViewSendMessagePayload>): SagaIterator {
  const { payload } = action;
  yield put(TicketActions.sendMessage(payload));
  trackEvent(TicketEvent.TicketReplied, EventPlace.Tickets, {
    Status: payload.status,
    'Is private': payload.isPrivate,
    Attachments: payload.attachments.length,
  });
}

function* sendMessageSuccess(action: IActionWithPayload<string, ITicketsViewSendMessagePayload>): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_SEND_MESSAGE_SUCCESS, {
        payload: action.payload,
      }),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );

  const wasTicketStatusTooltipSeen = yield select(getTicketStatusTooltipSeen);
  if (!wasTicketStatusTooltipSeen) {
    yield put(
      TooltipsActions.showTooltip({
        tooltip: TooltipType.TicketStatus,
        savePropertyName: AgentCustomPropertyName.TicketStatusTooltipSeen,
        tourId: UserGuidedTourType.TicketStatus,
      })
    );
  }
}

function* sendMessageFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_SEND_MESSAGE_ERROR),
      kind: ToastVariant.Error,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
  yield put(TicketsViewActions.resetSelectedTickets());
}

function* unspamSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_UNSPAM_SUCCESS),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* unspamFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_UNSPAM_ERROR),
      kind: ToastVariant.Error,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
  yield put(TicketsViewActions.resetSelectedTickets());
}

const batchActionToPropertyMap = {
  [TicketsBatchAction.MarkAsOpen]: 'status',
  [TicketsBatchAction.MarkAsSolved]: 'status',
  [TicketsBatchAction.MarkAsSpam]: 'status',
  [TicketsBatchAction.ChangeAssignee]: 'assignee',
  [TicketsBatchAction.ChangeGroup]: 'group',
};

function* updateBatch(action: IActionWithPayload<string, ITicketsViewBatchUpdatePayload>): SagaIterator {
  const { ticketIds, batchAction, data } = action.payload;
  const { result, error }: RequestResult<unknown, { error: unknown }> = yield call(
    ApiManager.ticketApi.batch,
    ticketIds,
    batchAction,
    data
  );

  if (result) {
    yield put(TicketsViewActions.updateBatchSuccess({ ticketIds, batchAction, data }));
  } else {
    yield put(TicketsViewActions.updateBatchFailure({ error: error.error }));
  }
  trackEvent(TicketEvent.ManyTicketsEdited, EventPlace.Tickets, {
    'Tickets edited': ticketIds.length,
    Property: batchActionToPropertyMap[batchAction],
  });
}

function* updateBatchSuccess(action: IActionWithPayload<string, ITicketsViewBatchUpdateSuccessPayload>): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_BATCH_UPDATE_SUCCESS, {
        payload: action.payload,
      }),
      kind: ToastVariant.Success,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
}

function* updateBatchFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.TICKET_BATCH_UPDATE_ERROR),
      kind: ToastVariant.Error,
      autoHideDelayTime: ToastAutoHideDelay.Long,
    })
  );
  yield put(TicketsViewActions.resetSelectedTickets());
}

export function* watchUpdateTicketCounter(): SagaIterator {
  let prevState: ITicketsViewUpdateTicketCountersPayload = {
    [Route.Unassigned]: null,
    [Route.MyOpen]: null,
  };

  // we don't want to notify the user via notifications on initial load
  const initialAction: IActionWithPayload<string, unknown> = yield take(TicketsViewActionsNames.UPDATE_TICKET_COUNTERS);
  prevState = initialAction.payload;

  while (true) {
    const action: IActionWithPayload<string, ITicketsViewUpdateTicketCountersPayload> = yield take(
      TicketsViewActionsNames.UPDATE_TICKET_COUNTERS
    );
    const isNextUnassignedBigger = prevState[Route.Unassigned] < action.payload[Route.Unassigned];
    const isNextMyOpenBigger = prevState[Route.MyOpen] < action.payload[Route.MyOpen];
    const currentPage = getRouteAddres();
    const isOnTicketPage = currentPage.startsWith('/tickets');
    const shouldShowNotification = (isNextMyOpenBigger || isNextUnassignedBigger) && !isOnTicketPage;
    const myTicketsRoute = '/tickets';
    const unassignedTicketsRoute = '/tickets/open?assigned=0';
    const route = isNextUnassignedBigger ? unassignedTicketsRoute : myTicketsRoute;
    if (!shouldShowNotification) {
      continue;
    }

    prevState = action.payload;

    const settings: Notifications = yield select(getLoggedInAgentNotifications);

    const soundNotificationOptions = {
      sound: SoundNotification.NewTicket,
      mute: settings.mute_all_sounds,
      repeat: false,
    };

    const browserNotificationsOptions = {
      title: 'New Ticket',
      tag: 'ticket_notification',
      afterClick: {
        targetPage: route,
      },
    };

    const isNotificationEnabled = yield select(getIsNotificationEnabled, Notification.Ticket);

    if (isNotificationEnabled) {
      yield put(SoundNotificationActions.playSound(soundNotificationOptions));
      yield put(BrowserNotificationsActions.show(browserNotificationsOptions));
    }
  }
}

function* fetchEmailsMapping(action: IActionWithPayload<string, IFetchTicketsEmailsMappingPayload>): SagaIterator {
  const { groupId: payloadGroup } = action.payload;
  const groupId = String(payloadGroup) || GENERAL_GROUP_ID;
  const { result, error }: RequestResult = yield call(ApiManager.ticketApi.fetchEmailsMapping, groupId);

  if (error) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.EMAIL_MAPPING_FETCH_ERROR),
        kind: ToastVariant.Error,
      })
    );
  }

  if (result) {
    const mappingDeserialized = deserializeTicketsEmailsMapping(result);
    const stateMapping: IEmailMapping[] = yield select(getEmailsMapping);

    const mapping =
      groupId === '-1' ? mappingDeserialized : [...stateMapping.filter((m) => String(m.groupId) !== groupId)];

    yield put(TicketsViewActions.setTicketsEmailsMapping({ mapping }));
  }
}

function* saveEmailMapping(action: IActionWithPayload<string, ISaveTicketsEmailMappingPayload>): SagaIterator {
  const { email, name, groupId } = action.payload.mapping;
  const { error }: RequestResult<unknown> = yield call(ApiManager.ticketApi.saveEmailMapping, email, name, groupId);

  if (error) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.EMAIL_MAPPING_SAVE_ERROR),
        kind: ToastVariant.Error,
      })
    );
  } else {
    const stateMapping: IEmailMapping[] = yield select(getEmailsMapping);
    const mapping = [...stateMapping.filter((m) => m.groupId !== groupId), action.payload.mapping];

    yield put(TicketsViewActions.setTicketsEmailsMapping({ mapping }));
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.EMAIL_MAPPING_NAME_SAVE_SUCCESS),
        kind: ToastVariant.Success,
      })
    );
    trackSettingsEvent(SettingsEvent.EmailForwardingSaved, SettingsSection.Email);
  }
}

function* clearEmailMapping(action: IActionWithPayload<string, IClearTicketsEmailMappingPayload>): SagaIterator {
  const { groupId } = action.payload;
  const { error }: RequestResult<unknown> = yield call(ApiManager.ticketApi.clearEmailMapping, String(groupId));

  if (error) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.EMAIL_MAPPING_SAVE_ERROR),
        kind: ToastVariant.Error,
      })
    );
  } else {
    const stateMapping: IEmailMapping[] = yield select(getEmailsMapping);
    const mapping = [...stateMapping.filter((m) => m.groupId !== groupId)];

    yield put(TicketsViewActions.setTicketsEmailsMapping({ mapping }));
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.EMAIL_MAPPING_NAME_CLEAR_SUCCESS),
        kind: ToastVariant.Success,
      })
    );
    trackSettingsEvent(SettingsEvent.EmailForwardingSaved, SettingsSection.Email);
  }
}

function* throttleFetchTickets(): SagaIterator {
  yield throttle(
    FETCH_TICKETS_ON_TICKET_UPDATE_THROTTLE_TIME,
    [TicketsViewActionsNames.UPDATE_TICKET_COUNTERS, TicketsViewActionsNames.UPDATE_BATCH_SUCCESS],
    fetchTickets
  );
}

export function* ticketsViewSagas(): SagaIterator {
  yield takeEvery(TicketsViewActionsNames.FETCH_TICKET_COUNTERS_REQUEST, fetchTicketCounters);
  yield takeEvery(TicketsViewActionsNames.TICKETS_UPDATED, ticketsUpdated);
  yield takeEvery(TicketsViewActionsNames.UPDATE_FILTER, updateFilter);
  yield takeEvery(TicketsViewActionsNames.FETCH_TAGS, fetchTags);
  yield takeEvery(TicketsViewActionsNames.UPDATE_TAGS, updateTags);
  yield takeEvery(TicketsViewActionsNames.UPDATE_SUBJECT, updateSubject);
  yield takeEvery(TicketsViewActionsNames.UPDATE_REQUESTER, updateRequester);
  yield takeEvery(TicketsViewActionsNames.UPDATE_CCS, updateCCs);
  yield takeEvery(TicketsViewActionsNames.UPDATE_ASSIGNEE, updateAssignee);
  yield takeEvery(TicketsViewActionsNames.UPDATE_GROUPS, updateGroups);
  yield takeEvery(TicketsViewActionsNames.UPDATE_BATCH, updateBatch);
  yield takeEvery(TicketsViewActionsNames.UPDATE_BATCH_SUCCESS, updateBatchSuccess);
  yield takeEvery(TicketsViewActionsNames.UPDATE_BATCH_FAILURE, updateBatchFailure);
  yield takeEvery(TicketsViewActionsNames.CREATE_TICKET, createTicket);
  yield takeEvery(TICKET.CREATE[RequestAction.SUCCESS], createTicketSuccess);
  yield takeEvery(TICKET.CREATE[RequestAction.FAILURE], createTicketFailure);
  yield takeEvery(TICKET.UPDATE_TAGS[RequestAction.FAILURE], updateTicketFailure);
  yield takeEvery(TicketsViewActionsNames.SEND_MESSAGE, sendMessage);
  yield takeEvery(TicketsViewActionsNames.SET_ROUTE, routeChanged);
  yield takeEvery(TicketsViewActionsNames.SET_SEARCH_QUERY, searchQueryChanged);
  yield takeEvery(TicketsViewActionsNames.CLEAR_SEARCH, searchCleared);
  yield takeEvery(TicketsViewActionsNames.SAVE_DRAFT, saveDraft);
  yield takeEvery(TicketsViewActionsNames.CLEAR_DRAFT, clearDraft);
  yield takeEvery(TicketsViewActionsNames.UNSPAM, unspam);
  yield takeEvery(TicketsViewActionsNames.COPY_TICKET_URL, copyTicketUrl);
  yield takeEvery(TicketsViewActionsNames.SHOW_FROM_ALL_GROUPS, showFromAllGroups);
  yield takeEvery(
    [
      TicketsViewActionsNames.UPDATE_FILTER,
      TicketsViewActionsNames.SET_QUERY_PARAMS_FROM_URL,
      TicketsViewActionsNames.SET_ROUTE,
      TicketsViewActionsNames.SET_PAGE,
    ],
    fetchTickets
  );
  /** these two tend to fire together when you solve couple tickets at once */
  yield takeLatest(
    [TicketsViewActionsNames.UPDATE_TICKET_COUNTERS, TicketsViewActionsNames.UPDATE_BATCH_SUCCESS],
    throttleFetchTickets
  );
  yield fork(watchUpdateTicketCounter);
  yield takeEvery(TicketsViewActionsNames.FETCH_SINGLE, fetchSingle);
  yield takeEvery(TicketsViewActionsNames.FETCH_EMAILS_MAPPING, fetchEmailsMapping);
  yield takeEvery(TicketsViewActionsNames.SAVE_EMAIL_MAPPING, saveEmailMapping);
  yield takeEvery(TicketsViewActionsNames.CLEAR_EMAIL_MAPPING, clearEmailMapping);
  yield takeEvery(TICKET[CRUDAction.FETCH_SINGLE][RequestAction.SUCCESS], updateCurrentTicket);
  yield takeEvery(TICKET[CRUDAction.FETCH_SINGLE][RequestAction.FAILURE], ticketFetchFailed);
  yield takeEvery(TICKET.UPDATE_SUBJECT[RequestAction.SUCCESS], updateSubjectSuccess);
  yield takeEvery(TICKET.UPDATE_REQUESTER[RequestAction.SUCCESS], updateRequesterSuccess);
  yield takeEvery(TICKET.UPDATE_REQUESTER[RequestAction.FAILURE], updateRequesterFailure);
  yield takeEvery(TICKET.UPDATE_CCS[RequestAction.SUCCESS], updateCCsSuccess);
  yield takeEvery(TICKET.UPDATE_CCS[RequestAction.FAILURE], updateCCsFailure);
  yield takeEvery(TICKET.UPDATE_ASSIGNEE[RequestAction.SUCCESS], updateAssigneeSuccess);
  yield takeEvery(TICKET.UPDATE_ASSIGNEE[RequestAction.FAILURE], updateAssigneeFailure);
  yield takeEvery(TICKET.UPDATE_GROUPS[RequestAction.SUCCESS], updateGroupsSuccess);
  yield takeEvery(TICKET.UPDATE_GROUPS[RequestAction.FAILURE], updateGroupsFailure);
  yield takeEvery(TICKET.SEND_MESSAGE[RequestAction.SUCCESS], sendMessageSuccess);
  yield takeEvery(TICKET.SEND_MESSAGE[RequestAction.FAILURE], sendMessageFailure);
  yield takeEvery(TICKET.UNSPAM[RequestAction.SUCCESS], unspamSuccess);
  yield takeEvery(TICKET.UNSPAM[RequestAction.FAILURE], unspamFailure);
}
