import { useState, type FC } from 'react';

import { ArrowForward, Report } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import { type APISource } from 'store/entities/copilot/interfaces';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';
import { ReportsViewActions } from 'store/views/reports/actions';

import * as styles from './styles';

type Props = APISource;

const handleSourceClick = (): void => {
  trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, { action: 'source clicked' });
};

export const ApiSourceItem: FC<Props> = ({ name, url, params }) => {
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const handleApiSourceClick = (filters: APISource['params'], url: string): void => {
    dispatch(ReportsViewActions.setFilters(filters));
    navigate(url);
    if (isExpanded) {
      dispatch(CopilotViewActions.toggleExpand());
    }
  };

  return (
    <li
      onClick={handleSourceClick}
      className={styles.apiCallLinkContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon source={Report} />
      <p onClick={() => handleApiSourceClick(params, url)} className={styles.sourceItem}>
        {name}
      </p>
      {isHovered && <Icon source={ArrowForward} className={styles.openInNewIcon} />}
    </li>
  );
};
