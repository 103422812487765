import { type Suggestion } from 'interfaces/copilot';

export function tryMatchSuggestion(suggestions: Required<Suggestion>[], text?: string): string | null {
  if (!text?.startsWith('/')) {
    return null;
  }

  const matchedSuggestion = suggestions.find((suggestion) => suggestion.shortcut === text);

  return matchedSuggestion?.message ?? null;
}
