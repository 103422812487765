// @ts-strict-ignore
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { type SoundNotification } from 'constants/sound-notifications';
import { soundElements } from 'helpers/custom-sounds';

import { SoundPriority } from './constants';

const log = debug(DebugLogsNamespace.FeaturesSoundNotifications);

export function playSound(soundName: string): Promise<void> {
  const soundElement = soundElements[soundName];

  if (!soundElement) {
    const error = 'No DOM sound element';
    log('error with playing a notification sound. More:', error);

    return Promise.reject(error);
  }

  return new Promise((resolve, reject) => {
    const listener = (): void => {
      soundElement.removeEventListener('pause', listener);
      resolve();
    };
    soundElement.addEventListener('pause', listener);

    // Following https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    const playedPromise = soundElement.play();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (playedPromise) {
      playedPromise.catch((e) => {
        log('error with playing a notification sound. More:', e);
        reject(e);
      });
    }
  });
}

export function getSoundByPriority(sounds: SoundNotification[] = []): SoundNotification {
  if (!sounds.length) {
    return null;
  }

  const sortedArray = sounds.sort((soundA, soundB) => SoundPriority[soundA] - SoundPriority[soundB]);

  return sortedArray[0];
}
