import { type OnboardingActionType, OnboardingActionsEnum } from '../onboarding/actions';

import { InstallCodeActionsEnum, type InstallCodeActionTypes } from './actions';
import { type IInstallCodeState } from './interfaces';

const initialState = {
  technologies: [],
  installCodeVisited: false,
  wasCtaClicked: false,
};

export const installCodeReducer = (
  state: IInstallCodeState = initialState,
  action: InstallCodeActionTypes | OnboardingActionType
): IInstallCodeState => {
  switch (action.type) {
    case InstallCodeActionsEnum.FETCH_TECHNOLOGIES_SUCCESS: {
      return {
        ...state,
        technologies: [...action.payload.technologies],
      };
    }

    case OnboardingActionsEnum.ONBOARDING_FINISHED:
    case InstallCodeActionsEnum.INSTALL_CODE_VISITED: {
      return {
        ...state,
        installCodeVisited: true,
      };
    }
    case InstallCodeActionsEnum.SET_WAS_CTA_CLICKED: {
      return {
        ...state,
        wasCtaClicked: action.payload.clicked,
      };
    }

    default:
      return state;
  }
};
