import * as Sentry from '@sentry/browser';
import { type SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';

import { getConfig } from 'helpers/config';
import type { Properties } from 'interfaces/property';
import { normalizeError } from 'services/connectivity/configuration-api/helpers';
import { type UpdateLicensePropertiesResponse } from 'services/connectivity/configuration-api/properties/types';
import { type ConfigurationApiResponse } from 'services/connectivity/configuration-api/types';
import { IntegrationLicensePropertiesActions } from 'store/entities/integration-license-properties/actions';
import { IntegrationLicensePropertiesNames } from 'store/entities/integration-license-properties/interfaces';
import { updateProperties } from 'store/entities/integration-license-properties/sagas';
import {
  getIsQueueMessagingEnabled,
  getIntegrationLicenseProperties,
} from 'store/entities/integration-license-properties/selectors';

import { createPluginInstallError } from './helpers';

export default function* install(): SagaIterator {
  let properties: Properties = {};
  const config = getConfig();
  const isQueueMessagingEnabled = yield select(getIsQueueMessagingEnabled);
  if (!isQueueMessagingEnabled) {
    const integrationProperties: Properties = yield select(getIntegrationLicenseProperties);

    properties = {
      ...integrationProperties,
      [config.accountsClientId]: {
        ...integrationProperties[config.accountsClientId],
        [IntegrationLicensePropertiesNames.MessagingInQueueEnabled]: true,
      },
    };
  }

  if (Object.keys(properties).length) {
    const result: ConfigurationApiResponse<UpdateLicensePropertiesResponse> = yield call(
      updateProperties,
      IntegrationLicensePropertiesActions.updateProperties({
        properties: {
          [config.accountsClientId]: {
            [IntegrationLicensePropertiesNames.MessagingInQueueEnabled]: true,
          },
        },
      })
    );

    yield put(IntegrationLicensePropertiesActions.updateData(properties));

    if (result?.error) {
      const normalizedError = normalizeError(result.error);
      yield call(Sentry.captureException, createPluginInstallError('Messaging in queue', normalizedError.message));
    }
  }
}
