import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const listItemWrapper = (isSelected: boolean): string => css`
  margin: 0;
  padding: 14px 0;
  padding-right: 0;
  list-style: none;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.4;
  color: ${isSelected ? `var(${DesignToken.ColorActionDefault})` : '#424d57'};
  position: relative;
  border-top: none;
`;

export const filtersList = css`
  padding: 0 12px 12px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
`;

export const fieldGroup = css`
  justify-content: space-between;
`;

export const datePickerContainer = css`
  padding: 10px;
`;

export const inputField = css`
  input {
    min-height: 40px;
    position: relative;
    -webkit-appearance: none;

    &::-webkit-datetime-edit-day-field:focus,
    &::-webkit-datetime-edit-month-field:focus,
    &::-webkit-datetime-edit-year-field:focus {
      background-color: #fff;
      color: inherit;
      outline: none;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
      -webkit-appearance: none;
    }
  }
`;

export const customPeriodOptionElementWrapper = css`
  width: calc(50% - 18px);
  box-sizing: border-box;
`;

export const customOptionButton = css`
  font-weight: normal;
`;

export const customOptionButtonLabel = css`
  font-size: 13px;
  color: rgba(66, 77, 89, 1);
  display: block;
  line-height: 20px;
  margin-bottom: 4px;
  box-sizing: border-box;
`;

export const filterBox = css`
  width: auto;
`;

export const filterButton = css`
  max-width: 128px;
  margin-left: auto;
`;

export const picker = css`
  margin-bottom: var(${SpacingToken.Spacing5});
`;
