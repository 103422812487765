export interface LoadScriptOptions {
  appendTo: 'head' | 'body';
  async: boolean;
  defer: boolean;
}

export const loadScript = (src: string, options?: Partial<LoadScriptOptions>): Promise<Event | null> =>
  new Promise((resolve, reject) => {
    if (Array.from(document.scripts).findIndex((script) => script.src === src) !== -1) {
      return resolve(null);
    }
    const { appendTo, async, defer } = {
      appendTo: 'body' as LoadScriptOptions['appendTo'],
      async: true,
      defer: true,
      ...options,
    };

    const script = document.createElement('script');
    script.src = src;
    script.async = async;
    script.defer = defer;
    script.onload = resolve;
    script.onerror = reject;

    try {
      document[appendTo].appendChild(script);
    } catch (error) {
      reject(error);
    }
  });
