// @ts-strict-ignore
import EventEmitter from 'events';

import * as Sentry from '@sentry/react';
import type { Primitive } from '@sentry/types';

interface Context {
  license: string;
  login: string;
  plan: string;
  [key: string]: Primitive;
}

class SentryContext {
  emitter: EventEmitter = new EventEmitter();
  context: Context = null;

  public waitForContext(): Promise<Context> {
    return new Promise<Context>((resolve, reject) => {
      if (this.context) {
        resolve(this.context);
      }
      this.emitter.once('set', (context: Context) => {
        if (!context) {
          return reject(new Error('Context not set'));
        }
        this.context = context;

        return resolve(context);
      });
    });
  }

  public setContext(sentryContext: Context): void {
    Sentry.setTags(sentryContext);
    Sentry.setUser(sentryContext);
    this.emitter.emit('set', sentryContext);
  }
}

export const sentryContext = new SentryContext();
