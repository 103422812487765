import { useState, useEffect } from 'react';

import { getReconnector } from 'services/connectivity/reconnector/service';
import { type ReconnectionDetails } from 'services/connectivity/reconnector/types';

export const useReconnectionDetails = (): ReconnectionDetails => {
  const [reconnectionDetails, setReconnectionDetails] = useState<ReconnectionDetails>(
    getReconnector().getReconnectionDetails()
  );

  useEffect(() => {
    const reconnector = getReconnector();
    const handler = (details: ReconnectionDetails): void => {
      setReconnectionDetails(details);
    };

    reconnector.subscribeToReconnectionDetailsChange(handler);

    return () => {
      reconnector.unsubscribeFromReconnectionDetailsChange(handler);
    };
  }, []);

  return reconnectionDetails;
};
