import { type KeyMap } from 'helpers/interface';
import { type ActionsUnion, createAction, type IAction, type IActionWithPayload } from 'store/helper';

import type {
  EntityFetchCollectionSuccess,
  EntityFetchFailure,
  EntityFetchSuccess,
  RequestFailure,
} from '../interfaces';

import { type IAutoAccessRule } from './interfaces';

export enum AutoAccessRulesRequestActions {
  FETCH_COLLECTION = 'ENTITIES/AUTO_ACCESS_RULES/FETCH_COLLECTION',
  UPDATE = 'ENTITIES/AUTO_ACCESS_RULES/UPDATE',
  DELETE = 'ENTITIES/AUTO_ACCESS_RULES/DELETE',
  REORDER = 'ENTITIES/AUTO_ACCESS_RULES/REORDER',
  DUPLICATE = 'ENTITIES/AUTO_ACCESS_RULES/DUPLICATE',
}

export enum AutoAccessRulesActionNames {
  SET = 'ENTITIES/AUTO_ACCESS_RULES/SET',
  SET_COLLECTION = 'ENTITIES/AUTO_ACCESS_RULES/SET_COLLECTION',

  FETCH_COLLECTION = 'ENTITIES/AUTO_ACCESS_RULES/FETCH_COLLECTION_REQUEST',
  FETCH_COLLECTION_SUCCESS = 'ENTITIES/AUTO_ACCESS_RULES/FETCH_COLLECTION_SUCCESS',
  FETCH_COLLECTION_FAILURE = 'ENTITIES/AUTO_ACCESS_RULES/FETCH_COLLECTION_FAILURE',

  UPDATE = 'ENTITIES/AUTO_ACCESS_RULES/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'ENTITIES/AUTO_ACCESS_RULES/UPDATE_SUCCESS',
  UPDATE_FAILURE = 'ENTITIES/AUTO_ACCESS_RULES/UPDATE_FAILURE',

  CREATE = 'ENTITIES/AUTO_ACCESS_RULES/CREATE_REQUEST',
  CREATE_SUCCESS = 'ENTITIES/AUTO_ACCESS_RULES/CREATE_SUCCESS',
  CREATE_FAILURE = 'ENTITIES/AUTO_ACCESS_RULES/CREATE_FAILURE',

  DELETE = 'ENTITIES/AUTO_ACCESS_RULES/DELETE_REQUEST',
  DELETE_SUCCESS = 'ENTITIES/AUTO_ACCESS_RULES/DELETE_SUCCESS',
  DELETE_FAILURE = 'ENTITIES/AUTO_ACCESS_RULES/DELETE_FAILURE',

  REORDER = 'ENTITIES/AUTO_ACCESS_RULES/REORDER_REQUEST',
  REORDER_SUCCESS = 'ENTITIES/AUTO_ACCESS_RULES/REORDER_SUCCESS',
  REORDER_FAILURE = 'ENTITIES/AUTO_ACCESS_RULES/REORDER_FAILURE',

  DUPLICATE = 'ENTITIES/AUTO_ACCESS_RULES/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = 'ENTITIES/AUTO_ACCESS_RULES/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = 'ENTITIES/AUTO_ACCESS_RULES/DUPLICATE_FAILURE',
}

export const AutoAccessRulesActions = {
  set: <T extends IAutoAccessRule>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.SET, payload);
  },
  setCollection: <T extends KeyMap<IAutoAccessRule>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.SET_COLLECTION, payload);
  },

  fetchCollection: (): IAction => {
    return createAction(AutoAccessRulesActionNames.FETCH_COLLECTION);
  },
  fetchCollectionSuccess: <T extends EntityFetchCollectionSuccess<IAutoAccessRule>>(
    payload: T
  ): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.FETCH_COLLECTION_SUCCESS, payload);
  },
  fetchCollectionFailure: <T extends RequestFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.FETCH_COLLECTION_FAILURE, payload);
  },

  update: <T extends Partial<IAutoAccessRule>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.UPDATE, payload);
  },
  updateSuccess: <T extends EntityFetchSuccess<Partial<IAutoAccessRule>>>(
    payload: T
  ): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.UPDATE_SUCCESS, payload);
  },
  updateFailure: <T extends RequestFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.UPDATE_FAILURE, payload);
  },

  create: <T extends Omit<IAutoAccessRule, 'id'>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.CREATE, payload);
  },
  createSuccess: <T extends EntityFetchSuccess<Omit<IAutoAccessRule, 'id'>>>(
    payload: T
  ): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.CREATE_SUCCESS, payload);
  },
  createFailure: <T extends RequestFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.CREATE_FAILURE, payload);
  },

  delete: <T extends Required<IAutoAccessRule['id']>>(id: T): IActionWithPayload<string, { id: T }> => {
    return createAction(AutoAccessRulesActionNames.DELETE, { id });
  },
  deleteSuccess: <T extends Required<Pick<IAutoAccessRule, 'id'>>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.DELETE_SUCCESS, payload);
  },
  deleteFailure: <T extends EntityFetchFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.DELETE_FAILURE, payload);
  },

  reorder: <T extends Required<Pick<IAutoAccessRule, 'id' | 'nextId'>>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.REORDER, payload);
  },
  reorderSuccess: <T extends Required<Pick<IAutoAccessRule, 'id' | 'nextId'>>>(
    payload: T
  ): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.REORDER_SUCCESS, payload);
  },
  reorderFailure: <T extends EntityFetchFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.REORDER_FAILURE, payload);
  },

  duplicate: <T extends Required<IAutoAccessRule['id']>>(id: T): IActionWithPayload<string, { id: T }> => {
    return createAction(AutoAccessRulesActionNames.DUPLICATE, { id });
  },
  duplicateSuccess: <T extends Required<Pick<IAutoAccessRule, 'id'>>>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.DUPLICATE_SUCCESS, payload);
  },
  duplicateFailure: <T extends EntityFetchFailure>(payload: T): IActionWithPayload<string, T> => {
    return createAction(AutoAccessRulesActionNames.DUPLICATE_FAILURE, payload);
  },
};

export type AutoAccessRuleAction = ActionsUnion<typeof AutoAccessRulesActions>;
