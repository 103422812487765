import { getRegion } from 'services/auth/auth-storage-manager';

import { ServiceStatus } from './types';

/**
 * Checks the status of a URL by making a HEAD request.
 *
 * @param {string} url - The URL to check.
 * @param {number} timeout - The timeout in milliseconds for the request.
 * @returns {Promise<ServiceStatus>} A promise that resolves to the service status.
 */
export async function checkUrl(url: string, timeout: number): Promise<ServiceStatus> {
  const controller = new AbortController();
  const signal = controller.signal;

  const region = getRegion();
  /* eslint-disable @typescript-eslint/naming-convention */
  const headers: HeadersInit = {
    ...(region ? { 'X-Region': region } : {}),
  };

  const fetchPromise = fetch(url, {
    method: 'HEAD',
    signal,
    cache: 'no-cache',
    headers,
  });
  /* eslint-enable @typescript-eslint/naming-convention */

  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeout);

  try {
    const response = await fetchPromise;
    clearTimeout(timeoutId);

    if (response.ok) {
      return ServiceStatus.REACHABLE;
    } else if (response.status >= 400 && response.status < 500) {
      return ServiceStatus.CLIENT_ERROR;
    } else if (response.status >= 500) {
      return ServiceStatus.SERVER_ERROR;
    } else {
      return ServiceStatus.UNREACHABLE;
    }
  } catch (error: unknown) {
    clearTimeout(timeoutId);

    if (error instanceof DOMException && error.name === 'AbortError') {
      return ServiceStatus.TIMED_OUT;
    } else if (error instanceof TypeError) {
      return ServiceStatus.NETWORK_ERROR;
    } else {
      return ServiceStatus.UNREACHABLE;
    }
  }
}
