export const MAX_NUMBER_OF_API_SUGGESTIONS = 3;

export const SUGGESTIONS_WRAPPER_DATA_TEST_ID = 'suggestions-wrapper';

export const STATIC_SUGGESTION_DELAY = 3000;
export const SUGGESTION_WIDTH = 402;

export const STATIC_QUESTION = 'How Copilot can assist my team with queries?';
export const STATIC_DESCRIPTION = 'Fuel Copilot with knowledge about your business';
export const STATIC_MESSAGE =
  'I can learn about your products and services and help your team with queries regarding your business. Feed me with knowledge about your business in [Reply suggestions](https://www.livechat.com/help/reply-suggestions/) and I’ll:\n\n - quickly find relevant information from your knowledge base, \n- support agents by providing them reply suggestions during chats with customers.';
