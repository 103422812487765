// @ts-strict-ignore
import { AgentPriority } from 'constants/agent-priority';
import { AgentType } from 'constants/agent-type';
import { GENERAL_GROUP_ID } from 'constants/groups';
import { AcceptingChatsStatus, LoginStatus } from 'constants/login-status';
import { isAgentBot } from 'helpers/agent';
import { type PickRequired, type Subset } from 'helpers/interface';
import { isDefined } from 'helpers/is-defined';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import type { WorkSchedule } from 'interfaces/entities/work-schedule';
import { AppStateProvider } from 'services/app-state-provider';
import { type AgentBase } from 'services/connectivity/configuration-api/agents/types';
import { getAgent } from 'store/entities/agents/selectors';
import { getBot } from 'store/entities/bots/selectors';
import { getAgentOrBotGroups } from 'store/entities/groups/selectors';
import { getAgentOrBot } from 'store/views/team/computed';

import { WorkScheduleSerializer } from '../work-scheduler/work-schedule-serializer';

export abstract class AgentBaseSerializerV3 {
  static deserializeLastLogout(lastLogout: string): number {
    return lastLogout && new Date(lastLogout).getTime() / 1000;
  }

  static deserializeType(isBot: boolean): AgentType {
    if (typeof isBot === 'undefined') {
      return null;
    }

    return isBot ? AgentType.Bot : AgentType.Agent;
  }

  static deserializeStatus(value: LoginStatus | AcceptingChatsStatus): LoginStatus {
    const status = {
      [LoginStatus.Online]: LoginStatus.Online,
      [LoginStatus.Away]: LoginStatus.Away,
      [LoginStatus.Offline]: LoginStatus.Offline,
      [AcceptingChatsStatus.AcceptingChats]: LoginStatus.Online,
      [AcceptingChatsStatus.NotAcceptingChats]: LoginStatus.Away,
      [AcceptingChatsStatus.AcceptingChatsDuringWorkingHours]: LoginStatus.Online,
    };

    return status[value];
  }

  /**
   * Provides default values if data to deserialize lacks some data. It can be also used to provide default data for forms.
   */
  static getDefaults(): IAgentBase {
    return {
      avatar: '',
      jobTitle: '',
      login: '',
      name: '',
      groups: [],
      status: LoginStatus.Offline,
      maxChatsCount: 3,
      lastLogout: 0,
      workSchedule: WorkScheduleSerializer.getDefaults(false),
    };
  }

  /**
   * Deserializes agent data provided by API.
   * @param data Agent data provided in API structure.
   */
  static deserialize(data: AgentBase, status: LoginStatus): IAgentBase {
    return {
      login: data.id,
      name: data.name,
      avatar: data.avatar,
      jobTitle: data.job_title,
      maxChatsCount: data.max_chats_count,
      groups: data.groups ? data.groups.map(({ id }) => String(id)) : [GENERAL_GROUP_ID],
      workSchedule: WorkScheduleSerializer.deserialize(data.work_scheduler),
      lastLogout: this.deserializeLastLogout(data.last_logout),
      status,
    };
  }

  static partialDeserialize(data: PickRequired<AgentBase, 'id'>): PickRequired<IAgentBase, 'login'> {
    const result = { login: data.id } as PickRequired<IAgentBase, 'login'>;

    if (isDefined(data.name)) {
      result.name = data.name;
    }

    if (isDefined(data.job_title)) {
      result.jobTitle = data.job_title;
    }

    if (isDefined(data.avatar)) {
      result.avatar = data.avatar;
    }

    if (isDefined(data.max_chats_count)) {
      result.maxChatsCount = data.max_chats_count;
    }

    if (isDefined(data.groups)) {
      result.groups = data.groups.map(({ id }) => String(id));
    }

    if (isDefined(data.work_scheduler)) {
      result.workSchedule = WorkScheduleSerializer.deserialize(data.work_scheduler);
    }

    if (isDefined(data.last_logout)) {
      result.lastLogout = this.deserializeLastLogout(data.last_logout);
    }

    return result;
  }

  /**
   * Serializes client data into structure required by API.
   * @param model Client structure to be transformed into API structure.
   */
  static serialize(model: PickRequired<Subset<IAgentBase>, 'login'>): PickRequired<AgentBase, 'id'> {
    const serialized: PickRequired<AgentBase, 'id'> = {
      id: model.login,
    };

    if (isDefined(model.name)) {
      serialized.name = model.name;
    }
    if (isDefined(model.avatar)) {
      serialized.avatar = model.avatar;
    }
    if (isDefined(model.jobTitle)) {
      serialized.job_title = model.jobTitle;
    }
    if (isDefined(model.maxChatsCount)) {
      serialized.max_chats_count = +model.maxChatsCount;
    }

    if (isDefined(model.groups)) {
      const agent = AppStateProvider.selectFromStore(getAgentOrBot, model.login);
      const agentsGroups = AppStateProvider.selectFromStore(
        getAgentOrBotGroups,
        model.login,
        isAgentBot(agent) ? getBot : getAgent,
      );

      serialized.groups = model.groups.map((id) => {
        const agentPriority = agentsGroups.find((group) => group.id === id)?.agentsPriorities?.[model.login];

        const priority = agentPriority || (agent.type === AgentType.Agent ? AgentPriority.Normal : AgentPriority.First);

        return {
          id: Number(id),
          priority,
        };
      });
    }

    if (isDefined(model.workSchedule)) {
      serialized.work_scheduler = WorkScheduleSerializer.serialize(model.workSchedule as WorkSchedule);
    }

    return serialized;
  }
}
