export type ColorSeries = string[][][];

export const DEFAULT_SERIES_COLOR = 'default';
export const LEGEND_WITHOUT_SERIES = 'legend_without_series';

export const enum PaletteNames {
  ScienceBlue = 'ScienceBlue',
  GoldenGrass = 'GoldenGrass',
  Cerise = 'Cerise',
  Tango = 'Tango',
  Waterloo = 'Waterloo',
  PurpleHeart = 'PurpleHeart',
  Emerald = 'Emerald',
  Valencia = 'Valencia',
}

export const enum Brightness {
  Darker = 'Darker',
  Dark = 'Dark',
  Default = 'Default',
  Bright = 'Bright',
  Brighter = 'Brighter',
  Brightest = 'Brightest',
}
export type Shades = Record<Brightness, string>;
export type Palettes = Record<PaletteNames, Shades>;
export type ExtraPaletteSet = Record<PaletteNames, Brightness[]>;
export type ExtraPaletteSets = Record<ExtraPalettes, Partial<ExtraPaletteSet>>;

export const paletteSequences: PaletteNames[] = [
  PaletteNames.ScienceBlue,
  PaletteNames.GoldenGrass,
  PaletteNames.Cerise,
  PaletteNames.Tango,
  PaletteNames.Waterloo,
  PaletteNames.PurpleHeart,
  PaletteNames.Emerald,
  PaletteNames.Valencia,
];

export const palettes: Palettes = {
  [PaletteNames.ScienceBlue]: {
    [Brightness.Darker]: '#002C5C',
    [Brightness.Dark]: '#0051A8',
    [Brightness.Default]: '#0076F5',
    [Brightness.Bright]: '#4CA3FF',
    [Brightness.Brighter]: '#94C8FF',
    [Brightness.Brightest]: '#D6EAFF',
  },
  [PaletteNames.GoldenGrass]: {
    [Brightness.Darker]: '#685908',
    [Brightness.Dark]: '#B2980C',
    [Brightness.Default]: '#EECC1B',
    [Brightness.Bright]: '#E8D573',
    [Brightness.Brighter]: '#F9EEB2',
    [Brightness.Brightest]: '#FCF8DE',
  },
  [PaletteNames.Cerise]: {
    [Brightness.Darker]: '#581533',
    [Brightness.Dark]: '#992459',
    [Brightness.Default]: '#D0377D',
    [Brightness.Bright]: '#E07DAA',
    [Brightness.Brighter]: '#EFB9D1',
    [Brightness.Brightest]: '#FAEBF0',
  },
  [PaletteNames.Tango]: {
    [Brightness.Darker]: '#692C06',
    [Brightness.Dark]: '#B64D0B',
    [Brightness.Default]: '#F16D19',
    [Brightness.Bright]: '#F6A06A',
    [Brightness.Brighter]: '#FACDB0',
    [Brightness.Brightest]: '#FDF0E7',
  },
  [PaletteNames.Waterloo]: {
    [Brightness.Darker]: '#36363D',
    [Brightness.Dark]: '#595965',
    [Brightness.Default]: '#838391',
    [Brightness.Bright]: '#AAAAB4',
    [Brightness.Brighter]: '#D5D5DA',
    [Brightness.Brightest]: '#F1F1F3',
  },
  [PaletteNames.PurpleHeart]: {
    [Brightness.Darker]: '#390D5B',
    [Brightness.Dark]: '#6517A2',
    [Brightness.Default]: '#8D24DF',
    [Brightness.Bright]: '#B46FEA',
    [Brightness.Brighter]: '#D6B0F4',
    [Brightness.Brightest]: '#ECDBFA',
  },
  [PaletteNames.Emerald]: {
    [Brightness.Darker]: '#17582C',
    [Brightness.Dark]: '#28964B',
    [Brightness.Default]: '#3DCB69',
    [Brightness.Bright]: '#7CDD9A',
    [Brightness.Brighter]: '#BDEECC',
    [Brightness.Brightest]: '#E3F8E9',
  },
  [PaletteNames.Valencia]: {
    [Brightness.Darker]: '#5C100D',
    [Brightness.Dark]: '#A61D18',
    [Brightness.Default]: '#E33731',
    [Brightness.Bright]: '#E96E69',
    [Brightness.Brighter]: '#F4B3AF',
    [Brightness.Brightest]: '#FBE1E0',
  },
};

export const enum ExtraPalettes {
  'Positive' = 'Positive',
  'Negative' = 'Negative',
}

export const extraPalettes: ExtraPaletteSets = {
  [ExtraPalettes.Negative]: {
    [PaletteNames.Valencia]: [Brightness.Default, Brightness.Bright, Brightness.Dark, Brightness.Brighter],
    [PaletteNames.Cerise]: [Brightness.Default, Brightness.Bright, Brightness.Dark, Brightness.Brighter],
  },
  [ExtraPalettes.Positive]: {
    [PaletteNames.Emerald]: [Brightness.Default, Brightness.Bright, Brightness.Dark, Brightness.Brighter],
    [PaletteNames.GoldenGrass]: [Brightness.Default, Brightness.Bright, Brightness.Dark, Brightness.Brighter],
  },
};

export const brightnessSequences: Brightness[][] = [
  [Brightness.Default],
  [Brightness.Default, Brightness.Brighter],
  [Brightness.Dark, Brightness.Default, Brightness.Brighter],
  [Brightness.Dark, Brightness.Default, Brightness.Bright, Brightness.Brighter],
  [Brightness.Dark, Brightness.Default, Brightness.Bright, Brightness.Brighter, Brightness.Brightest],
  [
    Brightness.Darker,
    Brightness.Dark,
    Brightness.Default,
    Brightness.Bright,
    Brightness.Brighter,
    Brightness.Brightest,
  ],
];

export const brightnessFixedSequences: Brightness[] = [
  Brightness.Default,
  Brightness.Bright,
  Brightness.Darker,
  Brightness.Brighter,
  Brightness.Dark,
  Brightness.Brightest,
];

export const enum SeriesType {
  Palette = 'Palette',
  ExtraPalette = 'ExtraPalette',
  Default = 'Default',
  None = 'None',
}
