import { type KeyMap } from 'helpers/interface';

import { BaseApi } from '../base-api';

export class ExportApi extends BaseApi {
  protected readonly prefix = 'exports';

  fetchScheduledReports = () => this.get<KeyMap>('', { filter: 'scheduled' });

  createReportOneTime = ({ email, format, encoding, type, dateFrom, dateTo }) =>
    this.post('', { email, format, encoding, type, date_from: dateFrom, date_to: dateTo });

  createReportRecurring = ({ email, format, encoding, type, period }) =>
    this.post('', { email, format, encoding, type, period });

  editScheduledReport = ({ email, format, encoding, type, period, typeCurrent, periodCurrent }) =>
    this.put('', { email, format, encoding, type, period, type_current: typeCurrent, period_current: periodCurrent });

  deleteScheduledReport = ({ type, recurs }) => this.delete('', { type, period: recurs });
}
