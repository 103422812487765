import { type ActionsUnion, createAction } from 'store/helper';

import { type Breadcrumb } from './interfaces';

export const enum AppsViewActionNames {
  SET_BREADCRUMBS = 'VIEWS/APPS/SET_BREADCRUMBS',
  SET_MARKETPLACE_ROUTE = 'VIEWS/APPS/SET_MARKETPLACE_ROUTE',
}

export const AppsViewActions = {
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => createAction(AppsViewActionNames.SET_BREADCRUMBS, { breadcrumbs }),
  setMarketplaceRoute: (route: string | null) => createAction(AppsViewActionNames.SET_MARKETPLACE_ROUTE, { route }),
};

export type AppsViewAction = ActionsUnion<typeof AppsViewActions>;
