import { type ActionsUnion, createAction } from 'store/helper';

import type {
  FetchLanguagePayload,
  SetLanguageDataPayload,
  UpdateLanguagePayload,
  SaveLanguagePayload,
} from '../../../interfaces/language';

export enum LanguagesActionNames {
  FETCH_LANGUAGE = 'ENTITIES/LANGUAGES/FETCH_LANGUAGE',
  FETCH_LANGUAGE_SUCCESS = 'ENTITIES/LANGUAGES/FETCH_LANGUAGE_SUCCESS',
  SET_LANGUAGE = 'ENTITIES/LANGUAGES/SET_LANGUAGE',
  SAVE_LANGUAGE = 'ENTITIES/LANGUAGES/SAVE_LANGUAGE',
  SAVE_LANGUAGE_SUCCESS = 'ENTITIES/LANGUAGES/SAVE_LANGUAGE_SUCCESS',
  SAVE_LANGUAGE_FAILURE = 'ENTITIES/LANGUAGES/SAVE_LANGUAGE_FAILURE',
  UPDATE_LANGUAGE = 'ENTITIES/LANGUAGES/UPDATE_LANGUAGE',
  UPDATE_LANGUAGE_SUCCESS = 'ENTITIES/LANGUAGES/UPDATE_LANGUAGE_SUCCESS',
  UPDATE_LANGUAGE_FAILURE = 'ENTITIES/LANGUAGES/UPDATE_LANGUAGE_FAILURE',
}

export const LanguagesActions = {
  fetchLanguage: (payload: FetchLanguagePayload) => createAction(LanguagesActionNames.FETCH_LANGUAGE, payload),
  fetchLanguageSuccess: (payload: SetLanguageDataPayload) =>
    createAction(LanguagesActionNames.FETCH_LANGUAGE_SUCCESS, payload),
  setLanguage: (payload: SetLanguageDataPayload) => createAction(LanguagesActionNames.SET_LANGUAGE, payload),
  saveLanguage: (payload: SaveLanguagePayload) => createAction(LanguagesActionNames.SAVE_LANGUAGE, payload),
  saveLanguageSuccess: (payload: SaveLanguagePayload) =>
    createAction(LanguagesActionNames.SAVE_LANGUAGE_SUCCESS, payload),
  saveLanguageFailure: (payload: UpdateLanguagePayload) =>
    createAction(LanguagesActionNames.SAVE_LANGUAGE_FAILURE, payload),
  updateLanguage: (payload: UpdateLanguagePayload) => createAction(LanguagesActionNames.UPDATE_LANGUAGE, payload),
  updateLanguageSuccess: (payload: UpdateLanguagePayload) =>
    createAction(LanguagesActionNames.UPDATE_LANGUAGE_SUCCESS, payload),
  updateLanguageFailure: (payload: UpdateLanguagePayload) =>
    createAction(LanguagesActionNames.UPDATE_LANGUAGE_FAILURE, payload),
};

export type LanguagesAction = ActionsUnion<typeof LanguagesActions>;
