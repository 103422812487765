import { type Charge } from 'interfaces/one-click-payment';
import { type IConnection } from 'services/widget/connection/connection';
import connectionPool from 'services/widget/connection/connection-pool';

export const formatDecimalPrice = (pence: number): string => {
  return (pence / 100).toFixed(2);
};

export const calculateChargePrice = ({
  charge,
  agentsCount,
}: {
  charge: Charge | null;
  agentsCount: number;
}): {
  taxRate: number;
  unitPrice: number;
  unitPriceTax: number;
  totalPrice: number;
  totalPriceTax: number;
  baseUnitPrice: number;
  baseTotalPrice: number;
} => {
  const agentsMultiplier = charge?.per_account ? agentsCount : 1;
  const quantity = (!charge?.per_account && charge?.quantity) || 1;
  const multiplier = agentsMultiplier * quantity;
  const taxes = charge?.taxes;

  let unitPrice = charge?.price || 0;
  let totalPrice = unitPrice * multiplier;
  let totalPriceTax = 0;
  let unitPriceTax = 0;
  let taxRate = 0;
  const baseUnitPrice = unitPrice;
  const baseTotalPrice = totalPrice;

  if (taxes) {
    totalPrice = taxes.total_price;
    unitPrice = totalPrice / multiplier;
    totalPriceTax = taxes.tax_value;
    unitPriceTax = totalPriceTax / multiplier;
    taxRate = taxes.tax_rate * 100;
  }

  return {
    taxRate,
    unitPrice,
    unitPriceTax,
    totalPrice,
    totalPriceTax,
    baseUnitPrice,
    baseTotalPrice,
  };
};

const WAIT_TIME = 250;
const MAX_ITERATIONS = 20;

export const getWidgetConnection = async (widgetId: string, iteration = 0): Promise<IConnection | null> => {
  const connection = connectionPool.get(widgetId);

  if (iteration >= MAX_ITERATIONS) {
    return null;
  }

  if (!connection) {
    await new Promise((resolve) => setTimeout(resolve, WAIT_TIME));

    return getWidgetConnection(widgetId, iteration + 1);
  }

  return connection;
};
