import type { KeyMap } from 'helpers/interface';
import type { ISideNavigationItem } from 'interfaces/side-navigation-item';

export enum Route {
  Unassigned = 'unassigned',
  MyOpen = 'my-open',
  Open = 'open',
  Pending = 'pending',
  Solved = 'solved',
  Spam = 'spam',
  Search = 'search',
  All = 'all',
}

export const routeTitles = {
  [Route.Unassigned]: 'Unassigned tickets',
  [Route.MyOpen]: 'My open tickets',
  [Route.Open]: 'Open',
  [Route.Pending]: 'Pending',
  [Route.Solved]: 'Solved',
  [Route.Spam]: 'Spam',
  [Route.Search]: 'Search',
  [Route.All]: 'All tickets',
};

export const pathnameToRouteMap: KeyMap<Route> = {
  unassigned: Route.Unassigned,
  'my-open': Route.MyOpen,
  open: Route.Open,
  pending: Route.Pending,
  solved: Route.Solved,
  spam: Route.Spam,
  search: Route.Search,
  all: Route.All,
};

export const routeToPathnameMap = {
  [Route.Unassigned]: '/tickets/unassigned',
  [Route.MyOpen]: '/tickets/my-open',
  [Route.Open]: '/tickets/open',
  [Route.Pending]: '/tickets/pending',
  [Route.Solved]: '/tickets/solved',
  [Route.Spam]: '/tickets/spam',
  [Route.Search]: '/tickets/search',
  [Route.All]: '/tickets/all',
};

export const NavigationItems: ISideNavigationItem[] = [
  {
    id: Route.Unassigned,
    name: routeTitles[Route.Unassigned],
    path: routeToPathnameMap[Route.Unassigned],
  },
  {
    id: Route.MyOpen,
    name: routeTitles[Route.MyOpen],
    path: routeToPathnameMap[Route.MyOpen],
  },
  {
    isDivider: true,
  },
  {
    id: Route.Open,
    name: routeTitles[Route.Open],
    path: routeToPathnameMap[Route.Open],
  },
  {
    id: Route.Pending,
    name: routeTitles[Route.Pending],
    path: routeToPathnameMap[Route.Pending],
  },
  {
    id: Route.Solved,
    name: routeTitles[Route.Solved],
    path: routeToPathnameMap[Route.Solved],
  },
  {
    id: Route.Spam,
    name: routeTitles[Route.Spam],
    path: routeToPathnameMap[Route.Spam],
  },
  {
    isDivider: true,
  },
  {
    id: Route.All,
    name: routeTitles[Route.All],
    path: routeToPathnameMap[Route.All],
  },
];
