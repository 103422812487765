import { OnboardingRoute } from 'constants/onboarding/routes';
import type { KeyMap } from 'helpers/interface';
import type { CannedResponse } from 'interfaces/canned-responses';
import type { ITag } from 'store/entities/tags/interfaces';

import { type IOnboardingStep } from './interfaces';

export const prepareOnboardingSteps = (
  includeDownloadStep: boolean,
  includeInstallStep: boolean,
  includeTeamStep: boolean,
  includeIntegrationsStep: boolean,
  includeWebsiteStep: boolean,
  includeCompanySizeStep: boolean
): IOnboardingStep[] => {
  const steps = [
    { route: OnboardingRoute.Name, condition: true },
    { route: OnboardingRoute.Website, condition: includeWebsiteStep },
    { route: OnboardingRoute.InstallCode, condition: includeInstallStep },
    { route: OnboardingRoute.DownloadApp, condition: includeDownloadStep },
    { route: OnboardingRoute.CompanySize, condition: includeCompanySizeStep },
    { route: OnboardingRoute.Team, condition: includeTeamStep },
    { route: OnboardingRoute.Integrations, condition: includeIntegrationsStep },
  ];

  return steps.reduce((acc, step) => {
    if (step.condition) {
      acc.push({ route: step.route });
    }

    return acc;
  }, [] as IOnboardingStep[]);
};

export const filterAvailableAutotagsSetup = (
  autotagsMap: Record<string, string[]>,
  cannedResponses: KeyMap<CannedResponse>,
  tags: ITag[]
): Record<string, string[]> =>
  Object.entries(autotagsMap).reduce((acc, [cannedId, autotags]) => {
    const canned = cannedResponses[cannedId];
    if (!canned) {
      return acc;
    }

    const filteredAutotags = autotags.filter((tag) => tags.some((defaultTag) => defaultTag.name === tag));
    if (!filteredAutotags.length) {
      return acc;
    }

    acc[cannedId] = filteredAutotags;

    return acc;
  }, {} as Record<string, string[]>);
