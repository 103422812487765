// @ts-strict-ignore
import { type ISurveyResponse } from 'interfaces/entities/archive';

export interface IFilledFormField {
  type?: string;
  id?: string;
  label?: string;
  options?: {
    checked: boolean;
    value: string;
  }[];
  answer?:
    | boolean
    | string
    | {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        group_id?: string | number;
        id?: string | number;
        label?: string;
      };
  answers?: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    group_id?: string | number;
    id?: string | number;
    label?: string;
    chosen?: boolean;
  }[];
  value?: string;
}

interface ISurveyEvent {
  type: string;
  fields?: IFilledFormField[];
}

interface ISurveyField {
  name: string;
  value: string;
  id: number;
}

interface ISurveyJsonExtendedArray extends Array<ISurveyField> {
  decodedAlready?: boolean;
}

export const mergeSurveyResponses = (surveys: ISurveyResponse[], current: ISurveyResponse): ISurveyResponse[] => {
  let responseToMerge = surveys.find((response) => response.id === current.id);
  if (responseToMerge) {
    responseToMerge = { ...responseToMerge, answer: `${responseToMerge.answer}, ${current.answer}` };

    return [...surveys.slice(0, surveys.length - 1), responseToMerge];
  }

  return [...surveys, current];
};

export const parseSurveyFieldValue = (fieldJson: IFilledFormField): string => {
  if (Array.isArray(fieldJson.options)) {
    return fieldJson.options
      .filter(Boolean)
      .map(({ value }) => value)
      .join(', ');
  }
  if (typeof fieldJson.answer === 'boolean') {
    return fieldJson.answer ? 'true' : 'false';
  }
  if (typeof fieldJson.answer === 'object' && fieldJson.answer.label) {
    return fieldJson.answer.label;
  }
  if (Array.isArray(fieldJson.answers)) {
    return fieldJson.answers
      .reduce((acc, answer) => {
        if ((answer.chosen !== undefined && answer.chosen) || answer.chosen === undefined) {
          acc.push(answer.label);
        }

        return acc;
      }, [])
      .join(', ');
  }

  // `value` is for old forma, `answer` is for new format
  return (fieldJson.value || fieldJson.answer || '').toString();
};

export const getSurveyJsonFromEvent = (event: ISurveyEvent): ISurveyJsonExtendedArray => {
  if (event?.type !== 'filled_form') {
    return null;
  }

  const fields = event.fields.filter((field) => !['title', 'information'].includes(field.type));

  return fields.map(
    (field: IFilledFormField, key): ISurveyField => ({
      name: field.label,
      value: parseSurveyFieldValue(field),
      id: key,
    })
  );
};

export const getSurveyJsonFromEvents = (events: ISurveyEvent[]) => {
  const surveyEvents = events.map(getSurveyJsonFromEvent).filter(Boolean); // skip invalid events

  if (surveyEvents.length) {
    // only one survey per chat is supported
    const surveyEvent = surveyEvents[0];

    // strange construction: array with object-like property
    surveyEvent.decodedAlready = true;

    return surveyEvent;
  }

  return null;
};
