// @ts-strict-ignore
import uniq from 'lodash.uniq';

import { CUSTOMER_DETAILS_WIDGET_ID } from 'constants/customer-details';
import { Filter, type Filters } from 'constants/filters/filter';
import { SortOrder } from 'constants/sort-order';
import { prepareFilterOperators } from 'helpers/filters';
import { type IArchive } from 'interfaces/entities/archive';
import { ArchiveActionNames, TicketActionNames } from 'store/entities/archives/actions';
import {
  type IUpdateArchiveTagsSuccessPayload,
  type IFetchArchiveTicketsSuccessPayload,
  type IUpdateArchiveTagsFailurePayload,
} from 'store/entities/archives/interfaces';
import { type UpdateAccessibleThreadNavigation } from 'store/entities/customers/interfaces';
import { AttachmentsFeatureActionsNames, type AttachmentsFeatureAction } from 'store/features/attachments/actions';
import { type IActionWithPayload } from 'store/helper';
import { ArchivesViewActionsNames, type ArchivesViewAction } from 'store/views/archives/actions';

import {
  getStateForShowModal,
  getStateForCloseModal,
  getStateForFetchTicketsSuccess,
  getStateCurrentForUpdateCustomer,
  getStateDataForUpdateCustomer,
  getStateForUpdateFilterOperator,
  getStateForSetScrollPosition,
  getStateForUpdateCurrentArchiveCustomProperty,
  getStateForUpdateArchiveCustomProperty,
} from './helpers/reducer';
import { type IArchivesViewState } from './interfaces';

export const initialState: IArchivesViewState = {
  shownModal: null,
  current: null,
  data: null,
  filters: {
    agent: null,
    agentAssignment: null,
    availability: null,
    channel: null,
    dateRange: null,
    goal: null,
    greeting: null,
    group: null,
    offlineMessage: null,
    rating: null,
    replied: null,
    saleGoal: null,
    survey: null,
    tag: null,
    agentResponse: null,
    countryISO: null,
    topic: null,
    sentiment: null,
    priority: null,
  },
  filtersOrder: [],
  filtersOperators: {},
  newArchivesCount: 0,
  page: 1,
  nextPage: null,
  recentListScrollTop: 0,
  searchPhrase: '',
  sortedIds: [],
  sortOrder: SortOrder.Desc,
  tickets: {},
  totalCount: null,
  totalPages: null,
  scrollPosition: {},
  selectedWidget: {
    id: CUSTOMER_DETAILS_WIDGET_ID,
  },
};

function getArchiveWithUpdatedAttachmentSafetyConfirmation(
  archive: IArchive,
  eventId: string,
  isSafetyConfirmed: boolean,
): IArchive {
  return {
    ...archive,
    events: archive.events.map((event) =>
      event.id === eventId
        ? {
            ...event,
            details: [
              {
                ...event.details[0],
                isSafetyConfirmed,
              },
              ...event.details.slice(1),
            ],
          }
        : event,
    ),
  };
}

export const archivesReducer = (
  state: IArchivesViewState = initialState,
  action: ArchivesViewAction | AttachmentsFeatureAction,
): IArchivesViewState => {
  switch (action.type) {
    case ArchivesViewActionsNames.SET_CURRENT_ITEM: {
      const archiveToShow = action.payload.current || null;

      return {
        ...state,
        current: archiveToShow,
      };
    }

    case ArchivesViewActionsNames.SET_SORT_ORDER: {
      return {
        ...state,
        sortOrder: action.payload.sortOrder,
      };
    }

    case ArchivesViewActionsNames.SET_SEARCH_PHRASE: {
      const stateToChange = {
        ...state,
        searchPhrase: action.payload.searchPhrase,
      };

      if (action.payload.shouldClearFilters) {
        stateToChange.filters = { ...initialState.filters };
        stateToChange.filtersOrder = [...initialState.filtersOrder];
      }

      return stateToChange;
    }

    case ArchivesViewActionsNames.SET_NEW_ARCHIVES_COUNT: {
      return {
        ...state,
        newArchivesCount: action.payload.count,
      };
    }

    case ArchivesViewActionsNames.SET_DATA: {
      const { shouldAppend, data, totalCount, totalPages, nextPage } = action.payload;
      const dataToSet = data.reduce(
        (acc, archive) => ({ ...acc, [archive.id]: archive }),
        shouldAppend && state.data !== null ? state.data : {},
      );

      const sortedIds = data.reduce(
        // Do not include existing items
        (acc, archive) => (!acc.includes(archive.id) ? [...acc, archive.id] : acc),
        shouldAppend ? state.sortedIds : [],
      );

      return {
        ...state,
        data: dataToSet,
        sortedIds,
        page: shouldAppend ? state.page + 1 : 1,
        recentListScrollTop: shouldAppend ? state.recentListScrollTop : 0,
        totalCount,
        totalPages,
        newArchivesCount: shouldAppend ? state.newArchivesCount : 0,
        nextPage,
      };
    }

    case ArchivesViewActionsNames.SET_RECENT_LIST_SCROLL_TOP: {
      return {
        ...state,
        recentListScrollTop: action.payload.scrollTop,
      };
    }

    case ArchivesViewActionsNames.RESET_PAGING: {
      return {
        ...state,
        page: 1,
        nextPage: null,
      };
    }

    case ArchivesViewActionsNames.RESET_SEARCH_PARAMS: {
      return {
        ...state,
        searchPhrase: '',
        filters: { ...initialState.filters },
        filtersOrder: [...initialState.filtersOrder],
      };
    }

    case ArchivesViewActionsNames.RESET_FILTERS: {
      return {
        ...state,
        filters: { ...initialState.filters },
        filtersOrder: [...initialState.filtersOrder],
      };
    }

    case ArchivesViewActionsNames.UPDATE_FILTERS: {
      const { filter } = action.payload;

      const newFilters = { ...state.filters };
      let newFiltersOrder = [...state.filtersOrder];

      if (filter.value === null) {
        newFilters[filter.name] = null;
        newFiltersOrder = newFiltersOrder.filter((filterName) => filterName !== filter.name);
      } else {
        newFilters[filter.name] = filter.value;
        if (!newFiltersOrder.some((filterName) => filterName === filter.name)) {
          newFiltersOrder = [filter.name, ...newFiltersOrder];
        }
      }

      // Block to select agent and assign agent filter together
      if ((filter.name as Filter) === Filter.Agent) {
        newFilters[Filter.AgentAssignment] = null;
        newFiltersOrder = newFiltersOrder.filter((filterName: Filter) => filterName !== Filter.AgentAssignment);
      } else if ((filter.name as Filter) === Filter.AgentAssignment) {
        newFilters[Filter.Agent] = null;
        newFiltersOrder = newFiltersOrder.filter((filterName: Filter) => filterName !== Filter.Agent);
      }

      return {
        ...state,
        filters: newFilters,
        filtersOrder: newFiltersOrder,
      };
    }

    case ArchivesViewActionsNames.SET_QUERY_PARAMS_FROM_URL: {
      const {
        agent,
        agentAssignment,
        dateRange,
        greeting,
        goal,
        group,
        rating,
        replied,
        saleGoal,
        searchPhrase,
        survey,
        tag,
        channel,
        operators,
        offlineMessage,
        agentResponse,
        availability,
        countryISO,
        topic,
        sentiment,
        priority,
      } = action.payload.params;

      const newFilterValues: Filters = {
        ...(agent && { agent }),
        ...(dateRange && { dateRange }),
        ...(goal && { goal }),
        ...(greeting && { greeting }),
        ...(group && { group }),
        ...(rating && { rating }),
        ...(Number.isInteger(replied) && { replied }),
        ...(Number.isInteger(offlineMessage) && { offlineMessage }),
        ...(saleGoal && { saleGoal }),
        ...(survey && { survey }),
        ...(tag && { tag }),
        ...(channel && { channel }),
        ...(agentAssignment && { agentAssignment }),
        ...(Number.isInteger(agentResponse) && { agentResponse }),
        ...(availability && { availability }),
        ...(countryISO && { countryISO }),
        ...(topic && { topic }),
        ...(sentiment && { sentiment }),
        ...(priority && { priority }),
      };

      const filtersOperators = prepareFilterOperators(operators);

      return {
        ...state,
        filters: {
          ...initialState.filters,
          ...newFilterValues,
        },
        searchPhrase: searchPhrase || initialState.searchPhrase,
        filtersOrder: [
          Filter.DateRange,
          Filter.Agent,
          Filter.Group,
          Filter.Goal,
          Filter.SaleGoal,
          Filter.Tag,
          Filter.Rating,
          Filter.Greeting,
          Filter.Survey,
          Filter.Channel,
          Filter.Replied,
          Filter.AgentAssignment,
          Filter.OfflineMessage,
          Filter.Availability,
          Filter.AgentResponse,
          Filter.CountryISO,
          Filter.Topic,
          Filter.Sentiment,
          Filter.Priority,
        ].filter((f) => Boolean(newFilterValues[f]) || !Number.isNaN(parseInt(newFilterValues[f] as string, 10))),
        filtersOperators,
      };
    }

    case AttachmentsFeatureActionsNames.UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_SUCCESS: {
      const { threadId, eventId, isSafetyConfirmed } = action.payload;
      const shouldUpdateCurrentArchive = state.current && state.current.id === threadId;
      const shouldUpdateDataArchive = Boolean(state.data?.[threadId]);

      if (!shouldUpdateDataArchive && !shouldUpdateCurrentArchive) {
        return state;
      }

      const stateToSet = {
        ...state,
      };

      if (shouldUpdateDataArchive) {
        stateToSet.data[threadId] = getArchiveWithUpdatedAttachmentSafetyConfirmation(
          state.data[threadId],
          eventId,
          isSafetyConfirmed,
        );
      }

      if (shouldUpdateCurrentArchive) {
        stateToSet.current = getArchiveWithUpdatedAttachmentSafetyConfirmation(
          state.current,
          eventId,
          isSafetyConfirmed,
        );
      }

      return stateToSet;
    }

    case ArchiveActionNames.UPDATE_TAGS_REQUEST: {
      const { payload } = action as IActionWithPayload<string, IUpdateArchiveTagsSuccessPayload>;

      if (!state.current) {
        return state;
      }

      const nextTags = payload.tags || [];

      const currTags: string[] = uniq(
        (state.current.tags || [])
          .concat(state.current.tagsFailureAdditions ?? [])
          .concat(state.current.tagsFailureDeletions ?? []),
      ); // merge current with failed
      const enterTags = nextTags.filter((x) => !currTags.includes(x));
      const exitTags = currTags.filter((x) => !nextTags.includes(x));

      const tagsProcessing = uniq(enterTags.concat(exitTags));

      const current = {
        ...state.current,
        tags: nextTags,
        tagsProcessing,
      };

      return {
        ...state,
        current,
        data: {
          ...state.data,
          [payload.archiveId]: current,
        },
      };
    }

    case ArchiveActionNames.UPDATE_TAGS_FAILURE: {
      const { payload } = action as IActionWithPayload<string, IUpdateArchiveTagsFailurePayload>;
      const { archiveId, failedAdditions, failedDeletions } = payload;
      let current = state.data[archiveId];

      if (!current) {
        return state;
      }

      const tagsProcessing = [];

      current = {
        ...current,
        tagsFailureAdditions: uniq((state.current?.tagsFailureAdditions ?? []).concat(failedAdditions)),
        tagsFailureDeletions: uniq((state.current?.tagsFailureDeletions ?? []).concat(failedDeletions)),
        tagsProcessing,
      };

      const nextState = {
        ...state,
        data: {
          ...state.data,
          [archiveId]: current,
        },
      };

      // update selected chat as well
      if (archiveId === state.current?.id) {
        nextState.current = current;
      }

      return nextState;
    }

    case ArchiveActionNames.UPDATE_TAGS_SUCCESS: {
      const {
        payload: { archiveId, tags },
      } = action as IActionWithPayload<string, IUpdateArchiveTagsSuccessPayload>;

      const appliesToCurrent = state.current && state.current.id === archiveId;
      const tagsFailureAdditions = (state.current?.tagsFailureAdditions ?? []).filter((tag) => !tags.includes(tag));

      const current = appliesToCurrent
        ? {
            ...state.current,
            tags,
            tagsProcessing: [],
            tagsFailureAdditions,
            tagsFailureDeletions: [],
          }
        : state.current;

      const newState = {
        ...state,
        current,
      };

      const appliesToArchiveInData = !!newState.data[archiveId];
      if (!appliesToArchiveInData) {
        return newState;
      }

      return {
        ...newState,
        data: {
          ...newState.data,
          [archiveId]: {
            ...newState.data[archiveId],
            tags,
            tagsProcessing: [],
            tagsFailureAdditions,
            tagsFailureDeletions: [],
          },
        },
        current,
      };
    }

    case TicketActionNames.FETCH_TICKETS_SUCCESS: {
      const { payload } = action as IActionWithPayload<string, IFetchArchiveTicketsSuccessPayload>;

      return getStateForFetchTicketsSuccess(state, payload);
    }

    case ArchivesViewActionsNames.SHOW_MODAL: {
      return getStateForShowModal(state, action.payload);
    }

    case ArchivesViewActionsNames.CLOSE_MODAL: {
      return getStateForCloseModal(state);
    }

    case ArchivesViewActionsNames.UPDATE_CUSTOMER_INFO: {
      const { userId, name, email } = action.payload;
      const shouldUpdateCurrent = state.current?.visitor?.id === userId;

      return {
        ...state,
        data: getStateDataForUpdateCustomer(state, userId, name, email),
        ...(shouldUpdateCurrent && {
          current: getStateCurrentForUpdateCustomer(state, name, email),
        }),
      };
    }

    case ArchivesViewActionsNames.UPDATE_FILTER_OPERATOR: {
      return getStateForUpdateFilterOperator(state, action.payload);
    }

    case ArchivesViewActionsNames.UPDATE_ACCESSIBLE_THREAD_CURSORS: {
      const {
        payload: { archiveId, nextAccessibleThreadId, previousAccessibleThreadId },
      } = action as IActionWithPayload<string, UpdateAccessibleThreadNavigation>;
      const shouldUpdateCurrent = state.current?.id === archiveId;

      const accessibleThreadIds = {
        ...(nextAccessibleThreadId !== undefined && { nextAccessibleThreadId }),
        ...(previousAccessibleThreadId !== undefined && { previousAccessibleThreadId }),
      };

      return {
        ...state,
        data: {
          ...state.data,
          [archiveId]: {
            ...state.data[archiveId],
            ...accessibleThreadIds,
          },
        },
        ...(shouldUpdateCurrent && {
          current: {
            ...state.current,
            ...accessibleThreadIds,
          },
        }),
      };
    }

    case ArchivesViewActionsNames.SET_SCROLL_POSITION: {
      return getStateForSetScrollPosition(state, action.payload);
    }

    case ArchivesViewActionsNames.UPDATE_CURRENT_ARCHIVE_CUSTOM_PROPERTY: {
      return getStateForUpdateCurrentArchiveCustomProperty(state, action.payload);
    }

    case ArchivesViewActionsNames.UPDATE_ARCHIVE_CUSTOM_PROPERTY: {
      return getStateForUpdateArchiveCustomProperty(state, action.payload);
    }

    case ArchivesViewActionsNames.SET_WIDGET_FROM_BUTTON: {
      return {
        ...state,
        selectedWidget: {
          ...action.payload,
        },
        buttonTarget: {
          ...state.buttonTarget,
          pluginId: action.payload.pluginId,
        },
      };
    }

    case ArchivesViewActionsNames.OPEN_WIDGET_FROM_MORE_MENU_BUTTON: {
      return {
        ...state,
        selectedWidget: {
          ...state.selectedWidget,
          isOpenedFromActionButton: false,
        },
        buttonTarget: {
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};
