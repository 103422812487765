import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { sumValueFromPeriod, getSeriesDataFromPeriod } from 'helpers/reports';
import type { IChatEngagementReportData, IChatEngagementPeriodData } from 'interfaces/reports';
import type { ReportData } from 'interfaces/reports/api-v3';


enum ChatEngagementSource {
  StartedByAgent = 'started_by_agent',
  StartedByCustomerFromGreeting = 'started_by_customer_from_greeting',
  StartedByCustomerWithoutGreeting = 'started_by_customer_without_greeting',
}

enum ChatEngagementSourceLabel {
  StartedByAgent = 'Started by agent',
  FromTargetedMessages = 'From campaigns',
  StartedByCustomer = 'Started by customer',
}

interface IChatEngagementPayload {
  data: {
    chatEngagement: ReportData<IChatEngagementPeriodData>;
  };
}

export function deserializeChatEngagement({ data }: IChatEngagementPayload): IChatEngagementReportData {
  const startedByAgent = sumValueFromPeriod(data.chatEngagement.records, ChatEngagementSource.StartedByAgent);
  const startedByCustomerFromGreeting = sumValueFromPeriod(
    data.chatEngagement.records,
    ChatEngagementSource.StartedByCustomerFromGreeting
  );
  const startedByCustomerWithoutGreeting = sumValueFromPeriod(
    data.chatEngagement.records,
    ChatEngagementSource.StartedByCustomerWithoutGreeting
  );

  const chatEngagementSum = startedByAgent + startedByCustomerFromGreeting + startedByCustomerWithoutGreeting;

  const chartDataStartedByAgent = getSeriesDataFromPeriod<number>(
    data.chatEngagement.records,
    ChatEngagementSource.StartedByAgent
  );
  const chartDataStartedByCustomerFromGreeting = getSeriesDataFromPeriod<number>(
    data.chatEngagement.records,
    ChatEngagementSource.StartedByCustomerFromGreeting
  );
  const chartDataStartedByCustomerWithoutGreeting = getSeriesDataFromPeriod<number>(
    data.chatEngagement.records,
    ChatEngagementSource.StartedByCustomerWithoutGreeting
  );

  const labelMapper = (value: number, index: number): string =>
    `(${getFormattedPercent(
      value /
        (chartDataStartedByAgent[index] +
          chartDataStartedByCustomerFromGreeting[index] +
          chartDataStartedByCustomerWithoutGreeting[index]),
      0
    )})`;

  return {
    chatEngagement: {
      summary: [
        {
          name: ChatEngagementSourceLabel.FromTargetedMessages,
          value: startedByCustomerFromGreeting,
          color: DEFAULT_SERIES_COLOR,
          label: getFormattedPercent(startedByCustomerFromGreeting / chatEngagementSum, 2),
        },
        {
          name: ChatEngagementSourceLabel.StartedByCustomer,
          value: startedByCustomerWithoutGreeting,
          color: DEFAULT_SERIES_COLOR,
          label: getFormattedPercent(startedByCustomerWithoutGreeting / chatEngagementSum, 2),
        },
        {
          name: ChatEngagementSourceLabel.StartedByAgent,
          value: startedByAgent,
          color: DEFAULT_SERIES_COLOR,
          label: getFormattedPercent(startedByAgent / chatEngagementSum, 2),
        },
      ],
      labels: Object.keys(data.chatEngagement.records),
      series: [
        {
          name: ChatEngagementSourceLabel.FromTargetedMessages,
          data: chartDataStartedByCustomerFromGreeting,
          color: DEFAULT_SERIES_COLOR,
          label: chartDataStartedByCustomerFromGreeting.map(labelMapper),
          csvColumnOrder: 1,
        },
        {
          name: ChatEngagementSourceLabel.StartedByCustomer,
          data: chartDataStartedByCustomerWithoutGreeting,
          color: DEFAULT_SERIES_COLOR,
          label: chartDataStartedByCustomerWithoutGreeting.map(labelMapper),
          csvColumnOrder: 2,
        },
        {
          name: ChatEngagementSourceLabel.StartedByAgent,
          data: chartDataStartedByAgent,
          color: DEFAULT_SERIES_COLOR,
          label: chartDataStartedByAgent.map(labelMapper),
          csvColumnOrder: 3,
        },
      ],
    },
  };
}
