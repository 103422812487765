// @ts-strict-ignore
import { CheckCircle } from '@livechat/design-system-icons';

import { TicketStatusFilter, TicketStatusFilterFormatedValues } from 'constants/filters/ticket-status-filter';

import { type FilterLabel, type FilterConfig, type FilterLabelValue } from '../interfaces';

export const ticketStatusFilterConfig: FilterConfig = {
  title: 'Status',
  icon: CheckCircle,
  multiple: false,
  getFilterLabelValues: (filterValues: string[] | null, filterLabels: FilterLabel[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    return filterValues.map((filterValue: string) => ({
      key: filterValue,
      label: filterLabels.find((labelItem) => labelItem.value === filterValue).label,
    }));
  },
  filterLabels: Object.keys(TicketStatusFilter).map((ticketStatus: TicketStatusFilter) => ({
    value: TicketStatusFilter[ticketStatus],
    label: TicketStatusFilterFormatedValues[TicketStatusFilter[ticketStatus]],
  })),
};
