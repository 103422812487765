// @ts-strict-ignore
import { ReportDistribution } from 'constants/reports/distribution';
import { trimObject } from 'helpers/object';

import { BaseApi } from '../base-api';

export class BenchmarkApi extends BaseApi {
  protected readonly prefix = 'daily_benchmark_agent_auth';

  fetchBenchmark = ({
    dateFrom,
    dateTo,
    groupBy = ReportDistribution.Day,
    purpose = null,
    audience = null,
    companySize = null,
    industry = null,
    country = null,
    state = null,
    city = null,
  }) =>
    this.get(
      '',
      trimObject({
        date_from: dateFrom,
        date_to: dateTo,
        group_by: groupBy,
        purpose,
        audience,
        company_size: companySize,
        industry,
        country,
        state,
        city,
      })
    );
}
