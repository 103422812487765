import {
  Android as AndroidIcon,
  Apple as AppleIcon,
  Linux as LinuxIcon,
  Windows as WindowsIcon,
} from '@livechat/design-system-icons';
import type { IconSource } from '@livechat/design-system-react-components';
import memoizeOne from 'memoize-one';

import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';
import { getItem } from 'services/local-storage';

import { DeviceType, getDeviceTypeByUserAgent } from './device-by-user-agent';

enum OsName {
  Windows = 'Windows',
  Mac = 'Mac',
  IOS = 'iOS',
  Android = 'Android',
  Linux = 'Linux',
}

const deviceIcon = new Map<OsName, IconSource>([
  [OsName.Windows, WindowsIcon],
  [OsName.Mac, AppleIcon],
  [OsName.IOS, AppleIcon],
  [OsName.Android, AndroidIcon],
  [OsName.Linux, LinuxIcon],
]);

const FORCE_DESKTOP_KEY = 'forceDesktopView';

export function getOsIconSource(osName: string | undefined): IconSource | null {
  const name = String(osName || '');

  for (const [os, icon] of deviceIcon) {
    if (name.startsWith(os)) {
      return icon;
    }
  }

  return null;
}

// Some laptops with touch screens match "pointer: coarse" media even though they have mouse or touch pad connected
// so we can detect desktop OS (like Windows 10) and treat such device as desktop
export const isDesktopOSDetected = (): boolean =>
  getDeviceTypeByUserAgent(window.navigator.userAgent) === DeviceType.Windows;

// Please avoid exporting the following helpers. Instead, add another helper to the bottom list if needed.

const isScreenSmall = (): boolean => window.matchMedia(`(max-width: ${SMALL_SCREEN_BREAKPOINT}px)`).matches;

const hasCoarsePrimaryPointer = (): boolean => window.matchMedia('(pointer: coarse)').matches;

const hasMouseLikePrimaryPointer = (): boolean => window.matchMedia('(pointer: fine)').matches;

const hasRegularPrimaryHover = (): boolean => window.matchMedia('(hover: hover)').matches;

// stylus digitizers like Cintiq, Wacom
const isStylusDevice = (): boolean => hasMouseLikePrimaryPointer() && !hasRegularPrimaryHover();

// smartphones, tablets, etc.
// some android smartphones provide "hover: hover" media (not quite correctly) so we check only coarse pointer here
const isTouchScreenDevice = (): boolean => hasCoarsePrimaryPointer();

export const isMobileDevice = (): boolean => (isTouchScreenDevice() || isStylusDevice()) && !isDesktopOSDetected();

export const isDesktopDevice = (): boolean => !isMobileDevice();

// *** Sync with mediaQueries.ts and .forMobileOnly() mixin

/**
 * @deprecated Don't detect device type to determine view type, use RWD approach
 */
export const isMobileView = (): boolean => (isScreenSmall() || isMobileDevice()) && !shouldForceDesktopView();

/**
 * @deprecated Don't detect device type to determine view type, use RWD approach
 */
export const isDesktopView = (): boolean => !isMobileView();

/**
 * @deprecated Don't detect device type to determine view type, use RWD approach
 */
export const isMobileViewMemoized = memoizeOne((_widthScreen?: /* Argument for refresh memoized value */ number) =>
  isMobileView()
);

/**
 * @deprecated Don't detect device type to determine view type, use RWD approach
 */
export const isDesktopViewMemoized = (): boolean => !isMobileViewMemoized();

// ***

export const canEasilyInstallCode = (): boolean => isDesktopDevice();

export const shouldHaveMobileOnboardingSteps = (): boolean => isMobileDevice();

export const shouldHideNotificationsTopBar = (): boolean => isMobileDevice();

export const shouldProposeFacebookConnection = (): boolean => isDesktopDevice() || userForcesDesktopView();

export const shouldShowPasteFilesPromoTooltip = (): boolean => isDesktopDevice();

export const shouldSkipSuggestedResponseTooltip = (): boolean => isMobileDevice();

export const shouldShowSuggestedResponses = (): boolean => isDesktopDevice();

export const userForcesDesktopView = (): boolean => getItem(FORCE_DESKTOP_KEY) === true;

export const shouldForceDesktopView = (): boolean => userForcesDesktopView() || isDesktopOSDetected();

export const shouldShowEmojiToolbar = (): boolean => isDesktopDevice();

export const shouldShowAIAssistToolbar = (): boolean => isDesktopDevice();

export const shouldShowCloseChatButton = (): boolean => isDesktopDevice();

export const shouldShowCannedResponsesPromoTooltip = (): boolean => isDesktopDevice();
