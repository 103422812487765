import { ChatEventStatus } from 'constants/chat-event-status';
import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadEvent } from 'store/entities/chats/selectors';

/**
 * Handle responds after sending the event (eg. message)
 *
 * This handler involves taking care of following scenarios:
 * 1. Change `status` of event from `ChatEventStatus.Send` to `ChatEventStatus.Delivered` or `ChatEventStatus.NotDelivered`
 *
 * @param payload.conference_id ID of thread
 * @param payload.message_id ID of event that was sent
 * @param payload.success Information if message was sent with success
 * @param store store
 */
export function handleSendMessageResponse(payload: any, store: IAppState): void {
  const { conference_id: threadId, success: wasMessageSentWithSuccess, message_id: eventId } = payload;

  const state = store.getState();
  const event = getThreadEvent(state, threadId, eventId);
  const status = wasMessageSentWithSuccess ? ChatEventStatus.Delivered : ChatEventStatus.NotDelivered;

  /**
   * 1. Change `status` of event from `ChatEventStatus.Send` to `ChatEventStatus.Delivered` or `ChatEventStatus.NotDelivered`
   */
  if (event) {
    store.dispatch(
      ChatsEntitiesActions.updateEvents({
        threadId,
        events: {
          [eventId]: {
            ...event,
            status,
          },
        },
      })
    );
  }
}
