export enum AIAgent {
  One = 'one',
  ReplySuggestions = 'reply-suggestions',
}

export const REPLY_SUGGESTIONS_AGENT_TEMPLATE = {
  id: '8a16d02c-11c7-477f-ac3f-d23584956476',
  name: `reply-suggestions`,
};

export const ONE_AGENT_TEMPLATE = {
  id: '12ff651d-081c-4ad3-a689-bfa64b5a9d47',
  name: `one`,
};
