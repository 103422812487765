export enum TicketsBatchAction {
  MarkAsOpen,
  MarkAsSolved,
  MarkAsSpam,
  ChangeAssignee,
  ChangeGroup,
}

export const batchActionStatusMap = {
  [TicketsBatchAction.MarkAsOpen]: 'open',
  [TicketsBatchAction.MarkAsSolved]: 'solved',
  [TicketsBatchAction.MarkAsSpam]: 'spam',
};
