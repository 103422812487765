import { type SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type IFormPayload } from 'services/api/forms/interfaces';
import { deserializeForm, serializeForm } from 'services/api/forms/serializer';

import { FormsActionNames, FormsActions } from './actions';

interface RequestError {
  type: string;
  message: string;
}

function* fetch(action: ReturnType<typeof FormsActions.fetch>): SagaIterator {
  const { formsApi } = ApiManager;
  const { id, groupId } = action.payload;
  const { result, error }: RequestResult<IFormPayload, RequestError> = yield call(formsApi.fetch, id, groupId);

  if (error) {
    yield put(FormsActions.fetchFailure({ id, error: error.message }));
  }

  yield put(FormsActions.fetchSuccess({ id, value: result ? deserializeForm(result) : null }));
}

function* update(action: ReturnType<typeof FormsActions.update>): SagaIterator {
  const { formsApi } = ApiManager;
  const { id, groupId, form } = action.payload;
  const { result, error }: RequestResult<IFormPayload, RequestError> = yield call(
    formsApi.update,
    id,
    serializeForm(form),
    groupId
  );

  if (error) {
    yield put(FormsActions.updateFailure({ id, error: error.message }));
  }

  yield put(FormsActions.updateSuccess({ id, value: result ? deserializeForm(result) : null }));
}

export function* formsSagas(): SagaIterator {
  yield takeEvery(FormsActionNames.FETCH, fetch);
  yield takeEvery(FormsActionNames.UPDATE, update);
}
