import { createAgentChatApiClient } from '../client';
import { type AgentChatApiErrorResponse } from '../types';

import { type DeactivateChatRequest } from './types/deactivate-chat';
import { type GetChatRequest, type GetChatResponse } from './types/get-chat';
import { type ListArchivesRequest, type ListArchivesResponse } from './types/list-archives';
import { type ListChatsRequest, type ListChatsResponse } from './types/list-chats';
import { type ListThreadsRequest, type ListThreadsResponse } from './types/list-threads';
import { type ResumeChatResponse, type ResumeChatRequest } from './types/resume-chat';
import { type StartChatRequest, type StartChatResponse } from './types/start-chat';

const client = createAgentChatApiClient();

/**
 * Chats API client.
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#chats}
 */
export const chatsClient = {
  /**
   * Gets a chat.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#get-chat}
   */
  getChat: async (data: GetChatRequest) => {
    return client.post<GetChatResponse, AgentChatApiErrorResponse>('get_chat', {
      json: data,
    });
  },
  /**
   * Lists threads.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#list-threads}
   */
  listThreads: async (data: ListThreadsRequest) => {
    return client.post<ListThreadsResponse, AgentChatApiErrorResponse>('list_threads', {
      json: data,
    });
  },
  /**
   * Lists chats.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#list-chats}
   */
  listChats: async (data: ListChatsRequest) => {
    return client.post<ListChatsResponse, AgentChatApiErrorResponse>('list_chats', {
      json: data,
    });
  },
  /**
   * Lists archives.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#list-archives}
   */
  listArchives: async (data: ListArchivesRequest) => {
    return client.post<ListArchivesResponse, AgentChatApiErrorResponse>('list_archives', {
      json: data,
    });
  },
  /**
   * Starts a new chat.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#start-chat}
   */
  startChat: async (data: StartChatRequest) => {
    return client.post<StartChatResponse, AgentChatApiErrorResponse>('start_chat', {
      json: data,
    });
  },
  /**
   * Resumes an existing chat.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#resume-chat}
   */
  resumeChat: async (data: ResumeChatRequest) => {
    return client.post<ResumeChatResponse, AgentChatApiErrorResponse>('resume_chat', {
      json: data,
    });
  },

  /**
   * Deactivates a chat.
   * @see {@link https://platform.text.com/docs/messaging/agent-chat-api#deactivate-chat}
   */
  deactivateChat: async (data: DeactivateChatRequest) => {
    return client.post<null, AgentChatApiErrorResponse>('deactivate_chat', {
      json: data,
    });
  },
};
