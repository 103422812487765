import { EnvironmentType } from 'constants/environment';

import { type ProfilesSampleRateConfig, type TracingConfig } from './types';

export const enum SentryOrigin {
  Pageload = 'auto.pageload.browser',
  Navigation = 'auto.navigation.browser',
}

export const enum SentryTraceOperation {
  Pageload = 'pageload',
  Navigation = 'navigation',
}

export const CHAT_ID_REGEX = /\/[A-Z0-9]{10}/g;
export const UUID_REGEX = /\/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}/g;

export const TRACEABLE_MEASUREMENTS = ['fcp', 'lcp', 'cls', 'fid'];

export const TRANSACTION_IDLE_TIMEOUT = 5000;
export const EXCLUDED_SUBPATHS = ['/team/groups', '/team/chatbots', '/team'];

export const profilesSampleRate: ProfilesSampleRateConfig = {
  [EnvironmentType.Labs]: 1,
  [EnvironmentType.Staging]: 1,
  [EnvironmentType.Production]: 0.05,
};

export const tracingConfig: Record<SentryOrigin, TracingConfig> = {
  [SentryOrigin.Pageload]: {
    pathNames: ['/chats/', '/engage/', '/home', '/reports/', '/archives/', '/team/'],
    sampleRate: {
      [EnvironmentType.Labs]: 1,
      [EnvironmentType.Staging]: 1,
      [EnvironmentType.Production]: 0.05,
    },
  },
  [SentryOrigin.Navigation]: {
    pathNames: ['/chats/', '/engage/'],
    sampleRate: {
      [EnvironmentType.Labs]: 1,
      [EnvironmentType.Staging]: 1,
      [EnvironmentType.Production]: 0.0002,
    },
  },
};
