import { type ActionsUnion, createAction } from 'store/helper';
import { type MobileAppPromoBannerPlace } from 'store/views/mobile-app-promo-banner/interfaces';

export const enum MobileAppPromoBannerActionsEnum {
  SAVE_IS_MOBILE_APP_PROMO_BANNER_VISIBLE_FOR = 'VIEWS/MOBILE_APP_PROMO_BANNER/SAVE_IS_MOBILE_APP_PROMO_BANNER_VISIBLE_FOR',
}

export const MobileAppPromoBannerActions = {
  saveIsMobileAppPromoBannerVisibleFor: (place: MobileAppPromoBannerPlace, isVisible: boolean) =>
    createAction(MobileAppPromoBannerActionsEnum.SAVE_IS_MOBILE_APP_PROMO_BANNER_VISIBLE_FOR, { place, isVisible }),
};

export type MobileAppPromoBannerActionTypes = ActionsUnion<typeof MobileAppPromoBannerActions>;
