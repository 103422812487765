import { getConfig } from 'helpers/config';
import { getAccessToken, getRegion } from 'services/auth/auth-storage-manager';

import { createApiClient } from '../http/client';
import { type ApiClientInstance } from '../http/types';

export const createMlGatewayApiClient = (): ApiClientInstance => {
  return createApiClient({
    baseURL: getConfig().mlGatewayApiUrl,
    getCredentials: getAccessToken,
    getRegion,
    prefix: '/nlp-gateway/v2/',
  });
};
