import { type UseMutateFunction, useMutation } from '@tanstack/react-query';

import { errorDeserialize } from 'helpers/error-deserialize';
import { showToast } from 'helpers/toast';
import { handleOnboardingStepSuccess } from 'hooks/api/onboarding/helpers';
import type { RequestResult } from 'interfaces/api/client';
import { type ProductOnboardingStep } from 'interfaces/entities/product-onboarding';
import type { RequestError } from 'services/api/types';
import { ApiManager } from 'services/api/api-manager';
import { serializeOnboardingStep } from 'services/api/onboarding/serializer';
import { ToastVariant } from 'store/features/toasts/interfaces';

import { useFetchOnboardingProgress } from './use-fetch-onboarding-progress';

interface CompleteOnboardingStepPayload {
  step: ProductOnboardingStep;
  isLastStep?: boolean;
}

interface CompleteOnboardingStep {
  completeStep: UseMutateFunction<void, string, CompleteOnboardingStepPayload, unknown>;
  isProcessing: boolean;
  error: string | null;
}

export async function completeOnboardingStepRequest({ step }: CompleteOnboardingStepPayload): Promise<void> {
  const { error }: RequestResult<unknown, RequestError> = await ApiManager.onboardingApi.saveChecklistProgress(
    serializeOnboardingStep(step)
  );

  if (error) {
    throw errorDeserialize(error);
  }
}

export function handleError(error: string): void {
  showToast(ToastVariant.Error, error);
}

export const useCompleteOnboardingStep = (): CompleteOnboardingStep => {
  const { data } = useFetchOnboardingProgress();
  const mutation = useMutation<void, string, CompleteOnboardingStepPayload, unknown>(completeOnboardingStepRequest, {
    onSuccess: (_, variables) =>
      handleOnboardingStepSuccess({
        ...variables,
        eventName: 'Checklist item completed',
      }),
    onError: handleError,
  });

  const handleCompleteStep: UseMutateFunction<void, string, CompleteOnboardingStepPayload, unknown> = (
    variables,
    options
  ): void => {
    if (data && data[variables.step]) {
      return;
    }

    mutation.mutate(variables, options);
  };

  return {
    completeStep: handleCompleteStep,
    isProcessing: mutation.isLoading,
    error: mutation.error,
  };
};
