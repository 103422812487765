import { type ActionsUnion, createAction } from 'store/helper';

import { createEntityActionNames, createEntityActions } from '../actions';

import { type RefreshInstalledApplicationsData, type IApplication } from './interfaces';

const APPLICATION = createEntityActionNames('APPLICATION');

export const ApplicationsActionNames = {
  ...APPLICATION,
  FETCH_PRIVATE_APPLICATIONS: 'ENTITIES/APPLICATIONS/FETCH_PRIVATE_APPLICATIONS',
  FETCH_PRIVATE_APPLICATIONS_SUCCESS: 'ENTITIES/APPLICATIONS/FETCH_PRIVATE_APPLICATIONS_SUCCESS',
  FETCH_PRIVATE_APPLICATIONS_FAILURE: 'ENTITIES/APPLICATIONS/FETCH_PRIVATE_APPLICATIONS_FAILURE',
  FETCH_IN_REVIEW_APPLICATIONS: 'ENTITIES/APPLICATIONS/FETCH_IN_REVIEW_APPLICATIONS',
  FETCH_IN_REVIEW_APPLICATIONS_SUCCESS: 'ENTITIES/APPLICATIONS/FETCH_IN_REVIEW_APPLICATIONS_SUCCESS',
  FETCH_IN_REVIEW_APPLICATIONS_FAILURE: 'ENTITIES/APPLICATIONS/FETCH_IN_REVIEW_APPLICATIONS_FAILURE',
  REFRESH_INSTALLED_APPLICATIONS_DATA: 'ENTITIES/APPLICATIONS/REFRESH_INSTALLED_APPLICATIONS_DATA',
} as const;

export const ApplicationsActions = {
  ...createEntityActions<IApplication>(APPLICATION),
  fetchPrivateApplications: () => createAction(ApplicationsActionNames.FETCH_PRIVATE_APPLICATIONS),
  fetchPrivateApplicationsSuccess: (applications: IApplication[]) =>
    createAction(ApplicationsActionNames.FETCH_PRIVATE_APPLICATIONS_SUCCESS, applications),
  fetchPrivateApplicationsFailure: (error: any) =>
    createAction(ApplicationsActionNames.FETCH_PRIVATE_APPLICATIONS_FAILURE, error),
  fetchInReviewApplications: () => createAction(ApplicationsActionNames.FETCH_IN_REVIEW_APPLICATIONS),
  fetchInReviewApplicationsSuccess: (applications: IApplication[]) =>
    createAction(ApplicationsActionNames.FETCH_IN_REVIEW_APPLICATIONS_SUCCESS, applications),
  fetchInReviewApplicationsFailure: (error: any) =>
    createAction(ApplicationsActionNames.FETCH_IN_REVIEW_APPLICATIONS_FAILURE, error),
  refreshInstalledApplicationsData: (payload: RefreshInstalledApplicationsData) =>
    createAction(ApplicationsActionNames.REFRESH_INSTALLED_APPLICATIONS_DATA, payload),
};

export type ApplicationsAction = ActionsUnion<typeof ApplicationsActions>;
