import type { IApiGreeting } from 'store/entities/greetings/interfaces';

import type { RequestResult } from 'interfaces/api/client';
import { BaseApi } from '../base-api';

export class GreetingApi extends BaseApi {
  protected readonly prefix = 'greetings';

  fetchAll = (): Promise<RequestResult<IApiGreeting[]>> => this.get();
  fetch = (greetingId: string): Promise<RequestResult<IApiGreeting>> => this.get(`${greetingId}`);
  create = (greeting: IApiGreeting): Promise<RequestResult<IApiGreeting>> => this.post('', greeting);
  update = (greeting: Partial<IApiGreeting>): Promise<RequestResult<IApiGreeting>> =>
    this.put(`${greeting.id}`, greeting);

  remove = (greetingId: string): Promise<RequestResult<void>> => this.delete(greetingId);
}
