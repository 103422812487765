import type { RequestFailure } from '../interfaces';

export enum RecurlyCouponType {
  Percent = 'percent',
  Dollars = 'dollars',
}

export enum RecurlyCouponDuration {
  SingleUse = 'single_use',
  Forever = 'forever',
  Temporal = 'temporal',
}
export enum RecurlyCouponTemporalUnit {
  Month = 'month',
}

export enum RedemptionResource {
  Account = 'account',
  Subscription = 'subscription',
}

interface IRecurlyCouponDiscount {
  type: RecurlyCouponType;
  value: number;
  suffixValue: string;
}

export interface IRecurlyCoupon {
  exists: boolean;
  discount?: IRecurlyCouponDiscount;
  duration?: RecurlyCouponDuration;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  temporal_amount?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  temporal_unit?: RecurlyCouponTemporalUnit;
  appliesToAllPlans?: boolean;
  plans?: string[];
  plansNames?: string[];
  hideDiscount?: boolean;
  redemptionResource?: RedemptionResource;
  redemptionError?: string;
}

export interface ICheckRecurlyCouponPayload {
  coupon: string;
  hideDiscount?: boolean;
}

export interface ICheckRecurlyCouponFailurePayload extends RequestFailure {}
export interface IRedemCouponCouponFailurePayload extends RequestFailure {}

export interface IRecurlyState {
  currentCoupon?: IRecurlyCoupon;
  newCoupon?: IRecurlyCoupon;
  fetched: boolean;
}
