import { Section } from 'constants/section';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

export enum AppWithInterviewModalName {
  Shopify = 'Shopify',
}

export const APP_INTERVIEW_AGENT_CUSTOM_PROPERTY = {
  [AppWithInterviewModalName.Shopify]: AgentCustomPropertyName.ShopifyCallScheduledTo,
};

export const ALLOWED_SECTIONS = [Section.HomePage, Section.Engage, Section.Settings];
