import type { RequestResult } from 'interfaces/api/client';
import { type GroupId } from 'interfaces/groups';

import { BaseApi } from '../base-api';

import {
  type IApiEyeCatcherPayload,
  type IApiEyeCatcherGalleryResponse,
  type IApiUpdateEyeCatcherPayload,
} from './interfaces';

export class EyeCatcherApi extends BaseApi {
  protected readonly prefix = 'eye_catchers';

  fetchGallery = (): Promise<RequestResult<IApiEyeCatcherGalleryResponse>> => this.get(`gallery`);

  fetchEyeCatcher = (groupId: GroupId): Promise<RequestResult<IApiEyeCatcherPayload>> => this.get(`?group=${groupId}`);

  updateEyeCatcher = (payload: IApiUpdateEyeCatcherPayload): Promise<RequestResult<IApiEyeCatcherPayload>> =>
    this.put('', payload);
}
