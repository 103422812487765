import { type SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getIsOnboardingFinished } from 'store/views/onboarding/selectors';

import { SubscriptionActionNames } from '../subscription/actions';
import { getSubscriptionIsPastDue } from '../subscription/selectors';

function* fetchInAppMessages(): SagaIterator {
  const isPastDue = yield select(getSubscriptionIsPastDue);
  const isOnboardingFinished = yield select(getIsOnboardingFinished);

  if (!isPastDue || !isOnboardingFinished) {
    return;
  }

  trackEvent('Past Due notification displayed', EventPlace.Subscription);
  yield put(GlobalModalActions.showModal(GlobalModal.PastDueNotification));
}

export function* inAppMessagesSagas(): SagaIterator {
  yield takeEvery([SubscriptionActionNames.SUBSCRIPTION_CHANGED], fetchInAppMessages);
}
