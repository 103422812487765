import { type FC } from 'react';

import { HelpDeskColored } from '@livechat/design-system-icons';
import { Button, Heading, Icon, Loader, Text } from '@livechat/design-system-react-components';
import { isAfter, isBefore } from 'date-fns';

import img from 'assets/img/helpdesk-in-livechat/start-trial-graphic.png';
import { EventPlace } from 'helpers/analytics';
import { getHelpDeskAppId } from 'helpers/applications';
import { navigate } from 'helpers/routing';
import { useProductData } from 'hooks/api/use-product-data';
import { useInstallTrustedApplication } from 'hooks/use-install-trusted-application';
import { trackEvent } from 'services/event-tracking';

import { HELPDESK_IN_LIVECHAT_COPY } from './constants';

import * as styles from './styles';

const HD_BF_2024_START_OF_CAMPAIGN_DATE = '2024-11-26T00:00:00.000Z';
const HD_BF_2024_END_OF_CAMPAIGN_DATE = '2024-12-02T23:59:59.999Z';

export const HelpDeskInLivechat: FC = () => {
  const { isProcessing, install } = useInstallTrustedApplication();
  const { installedProducts, subscriptions } = useProductData();

  const now = new Date();
  const isBlackFriday2024CampaignActive =
    isAfter(now, new Date(HD_BF_2024_START_OF_CAMPAIGN_DATE)) &&
    isBefore(now, new Date(HD_BF_2024_END_OF_CAMPAIGN_DATE));
  const hasHelpDeskProduct = installedProducts.some((product) => product.product === 'HelpDesk');
  const isPaidHelpDeskSubscription =
    hasHelpDeskProduct &&
    (subscriptions.helpdesk?.status === 'active' || subscriptions.helpdesk?.status === 'past_due');
  const fetchingProductData = installedProducts.length === 0;
  const displayHelpDeskBlackFridayPromo =
    isBlackFriday2024CampaignActive && (!hasHelpDeskProduct || !isPaidHelpDeskSubscription);
  const pageCopyTheme = displayHelpDeskBlackFridayPromo ? 'blackFriday2024' : 'default';

  const handleInstallHelpDesk = (): void => {
    trackEvent('Start free trial clicked', EventPlace.HDMenuPromoPage);

    install(
      { appId: getHelpDeskAppId() },
      {
        onSuccess: () => {
          navigate('/apps/helpdesk');
        },
      },
    );
  };

  if (isBlackFriday2024CampaignActive && fetchingProductData) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Icon source={HelpDeskColored} size="xxlarge" className={styles.iconStyles} />
        <div className={styles.promo}>
          <Heading size="lg">{HELPDESK_IN_LIVECHAT_COPY.header[pageCopyTheme]}</Heading>
          <Text>{HELPDESK_IN_LIVECHAT_COPY.body[pageCopyTheme]}</Text>
          <Button kind="primary" className={styles.buttonStyles} loading={isProcessing} onClick={handleInstallHelpDesk}>
            {HELPDESK_IN_LIVECHAT_COPY.button[pageCopyTheme]}
          </Button>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <img className={styles.imageStyles} src={img} alt="promo" />
      </div>
    </div>
  );
};
