import { type FC } from 'react';

import { css } from '@emotion/css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const panelStyles = css`
  width: calc(100% - 64px) !important;
  left: 64px !important;
`;

const panelProps = {
  className: panelStyles,
};

export const ReactQueryDevTools: FC = () => {
  return <ReactQueryDevtools position="bottom-right" panelProps={panelProps} initialIsOpen={false} />;
};
