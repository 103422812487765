import type { Product } from 'interfaces/product-cart';

import {
  type IAddCreditCardSuccessPayload,
  type IApplication,
  type IBillingState,
  type ICancelApplicationRecurringSuccessPayload,
  type IFetchBillingInfoSuccessPayload,
} from '../interfaces';

export function getStateForFetchBillingInfoSuccess(
  state: IBillingState,
  payload: IFetchBillingInfoSuccessPayload
): IBillingState {
  return {
    ...state,
    billingInfo: payload,
  };
}

export function getStateForAddCreditCardSuccess(
  state: IBillingState,
  payload: IAddCreditCardSuccessPayload
): IBillingState {
  return {
    ...state,
    billingInfo: payload,
  };
}

export function getStateForSetRecurrentApplications(state: IBillingState, payload: Product[]): IBillingState {
  return {
    ...state,
    recurrentPaymentApplications: payload,
  };
}

export function getStateForCancelApplicationRecurringsSuccess(
  state: IBillingState,
  payload: ICancelApplicationRecurringSuccessPayload
): IBillingState {
  return {
    ...state,
    recurrentPaymentApplications: state.recurrentPaymentApplications.filter(
      (application) => application.id !== payload.applicationId
    ),
  };
}

export function getStateForMarkApplicationToUninstall(state: IBillingState, { id }: IApplication): IBillingState {
  return {
    ...state,
    recurrentPaymentApplications: state.recurrentPaymentApplications.map((application) =>
      application.id === id ? { ...application, isMarkedForUninstall: true } : application
    ),
  };
}

export function getStateForUnmarkApplicationToUninstall(state: IBillingState, { id }: IApplication): IBillingState {
  return {
    ...state,
    recurrentPaymentApplications: state.recurrentPaymentApplications.map((application) =>
      application.id === id ? { ...application, isMarkedForUninstall: false } : application
    ),
  };
}
