import { createAction } from 'store/helper';

import {
  type IFetchArchivePayload,
  type IFetchArchiveSuccessPayload,
  type IFetchArchiveTicketsFailurePayload,
  type IFetchArchiveTicketsPayload,
  type IFetchArchiveTicketsSuccessPayload,
  type IFetchArchivesCollectionFailurePayload,
  type IFetchArchivesCollectionPayload,
  type IFetchArchivesCollectionSuccessPayload,
  type IFetchNewArchivesCountFailurePayload,
  type IFetchNewArchivesCountPayload,
  type IFetchNewArchivesCountSuccessPayload,
  type IUpdateArchiveTagsFailurePayload,
  type IUpdateArchiveTagsPayload,
  type IUpdateArchiveTagsSuccessPayload,
} from './interfaces';

export const ArchiveActionNames = {
  FETCH_NEW_COUNT_REQUEST: 'ENTITIES/ARCHIVE/FETCH_NEW_COUNT_REQUEST',
  FETCH_NEW_COUNT_SUCCESS: 'ENTITIES/ARCHIVE/FETCH_NEW_COUNT_SUCCESS',
  FETCH_NEW_COUNT_FAILURE: 'ENTITIES/ARCHIVE/FETCH_NEW_COUNT_FAILURE',
  FETCH_SINGLE_REQUEST: 'ENTITIES/ARCHIVE/FETCH_SINGLE_REQUEST',
  FETCH_SINGLE_SUCCESS: 'ENTITIES/ARCHIVE/FETCH_SINGLE_SUCCESS',
  FETCH_COLLECTION_REQUEST: 'ENTITIES/ARCHIVE/FETCH_COLLECTION_REQUEST',
  FETCH_COLLECTION_SUCCESS: 'ENTITIES/ARCHIVE/FETCH_COLLECTION_SUCCESS',
  FETCH_COLLECTION_FAILURE: 'ENTITIES/ARCHIVE/FETCH_COLLECTION_FAILURE',
  UPDATE_TAGS_REQUEST: 'ENTITIES/ARCHIVE/UPDATE_TAGS_REQUEST',
  UPDATE_TAGS_SUCCESS: 'ENTITIES/ARCHIVE/UPDATE_TAGS_SUCCESS',
  UPDATE_TAGS_FAILURE: 'ENTITIES/ARCHIVE/UPDATE_TAGS_FAILURE',
};

export const TicketActionNames = {
  FETCH_TICKETS_REQUEST: 'ENTITIES/ARCHIVE/FETCH_TICKETS_REQUEST',
  FETCH_TICKETS_SUCCESS: 'ENTITIES/ARCHIVE/FETCH_TICKETS_SUCCESS',
  FETCH_TICKETS_FAILURE: 'ENTITIES/ARCHIVE/FETCH_TICKETS_FAILURE',
};

const fetchNewArchivesActions = {
  fetchNewCount: (payload: IFetchNewArchivesCountPayload) =>
    createAction(ArchiveActionNames.FETCH_NEW_COUNT_REQUEST, payload),
  fetchNewCountSuccess: (payload: IFetchNewArchivesCountSuccessPayload) =>
    createAction(ArchiveActionNames.FETCH_NEW_COUNT_SUCCESS, payload),
  fetchNewCountFailure: (payload: IFetchNewArchivesCountFailurePayload) =>
    createAction(ArchiveActionNames.FETCH_NEW_COUNT_FAILURE, payload),
  fetch: (payload: IFetchArchivePayload) => createAction(ArchiveActionNames.FETCH_SINGLE_REQUEST, payload),
  fetchSuccess: (payload: IFetchArchiveSuccessPayload) =>
    createAction(ArchiveActionNames.FETCH_SINGLE_SUCCESS, payload),
  fetchCollectionRequest: (payload: IFetchArchivesCollectionPayload) =>
    createAction(ArchiveActionNames.FETCH_COLLECTION_REQUEST, payload),
  fetchCollectionSuccess: (payload: IFetchArchivesCollectionSuccessPayload) =>
    createAction(ArchiveActionNames.FETCH_COLLECTION_SUCCESS, payload),
  fetchCollectionFailure: (payload: IFetchArchivesCollectionFailurePayload) =>
    createAction(ArchiveActionNames.FETCH_COLLECTION_FAILURE, payload),
};

const updateTagsActions = {
  updateArchiveTags: (payload: IUpdateArchiveTagsPayload) =>
    createAction(ArchiveActionNames.UPDATE_TAGS_REQUEST, payload),
  updateArchiveTagsSuccess: (payload: IUpdateArchiveTagsSuccessPayload) =>
    createAction(ArchiveActionNames.UPDATE_TAGS_SUCCESS, payload),
  updateArchiveTagsFailure: (payload: IUpdateArchiveTagsFailurePayload) =>
    createAction(ArchiveActionNames.UPDATE_TAGS_FAILURE, payload),
};

const fetchTicketsActions = {
  fetchTickets: (payload: IFetchArchiveTicketsPayload) =>
    createAction(TicketActionNames.FETCH_TICKETS_REQUEST, payload),
  fetchTicketsSuccess: (payload: IFetchArchiveTicketsSuccessPayload) =>
    createAction(TicketActionNames.FETCH_TICKETS_SUCCESS, payload),
  fetchTicketsFailure: (payload: IFetchArchiveTicketsFailurePayload) =>
    createAction(TicketActionNames.FETCH_TICKETS_FAILURE, payload),
};

export const ArchiveActions = {
  ...fetchNewArchivesActions,
  ...updateTagsActions,
  ...fetchTicketsActions,
};
