// @ts-strict-ignore
import { AccountCircle } from '@livechat/design-system-icons';

import { FilterOperator } from 'constants/filters/filter';
import { toKeyMap } from 'helpers/array';

import { getComparisonLabelValues } from '../helpers';
import { type FilterCompareLabelValues, type FilterConfig, type FilterLabelValue } from '../interfaces';

import { type IAgentFilterItem } from './agent-filter';

const getAgentLabelValues = (filterValues: string[] | null, agents: IAgentFilterItem[]): FilterLabelValue[] => {
  if (!filterValues) {
    return null;
  }
  const agentsHash = toKeyMap(agents, 'id');

  return filterValues.reduce((acc: FilterLabelValue[], agentLogin) => {
    if (agentsHash[agentLogin]) {
      acc.push({ key: agentsHash[agentLogin].id, label: agentsHash[agentLogin].name });
    }

    return acc;
  }, []);
};

const getAgentCompareLabelValues = (
  filterValues: string[] | null,
  agents: IAgentFilterItem[],
  filterCompareValues: string[] | null,
): FilterCompareLabelValues | null => {
  if (!filterValues || !filterCompareValues) {
    return null;
  }

  const { labels, compareLabels } = getComparisonLabelValues(filterValues, filterCompareValues, agents);

  return {
    operator: FilterOperator.Or,
    firstValues: labels,
    secondValues: compareLabels,
  };
};

export const agentFilterConfigMultiple: FilterConfig = {
  title: 'Agent',
  icon: AccountCircle,
  mobileViewTitle: 'Select agents',
  multiple: true,
  getFilterLabelValues: getAgentLabelValues,
  getFilterCompareLabelValues: getAgentCompareLabelValues,
};
