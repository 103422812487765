// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery, select } from 'redux-saga/effects';

import { PostMessageEvent } from 'constants/post-message-event';
import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import type { ProductsCart, CreateCartResult } from 'interfaces/product-cart';
import { ApiManager } from 'services/api/api-manager';
import { isApplicationInstalled } from 'store/entities/applications/selectors';
import { ProductCartActionNames, ProductCartActions } from 'store/entities/product-cart/actions';
import { type IApplicationIDPayload } from 'store/entities/product-cart/interfaces';
import { getCurrentCart } from 'store/entities/product-cart/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';

/**
 * Removes application from current cart
 * @param action
 */
function* removeProductFromCart(
  action: IActionWithPayload<ProductCartActionNames, IApplicationIDPayload>
): SagaIterator {
  const { id } = action.payload;
  const isAppInstalled: boolean = yield select(isApplicationInstalled, id);
  const currentCart: ProductsCart = yield select(getCurrentCart);

  // We don't want to remove app from cart when it's already installed
  if (isAppInstalled) {
    yield put(ProductCartActions.removeApplicationFailure({ id }));

    return;
  }

  // Application is not in the cart
  if (!currentCart?.products.some((product) => product.id === id)) {
    yield put(ProductCartActions.removeApplicationSuccess({ id }));

    return;
  }

  const removeApplicationResponse: RequestResult<CreateCartResult> = yield call(
    ApiManager.productCartApi.removeApplication,
    currentCart.id,
    id
  );
  if (removeApplicationResponse.error) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.REMOVE_APPLICATION_FROM_THE_CART_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      })
    );

    yield put(ProductCartActions.removeApplicationFailure({ id }));

    return;
  }

  yield put(ProductCartActions.removeApplicationSuccess({ id }));

  // Send updated cart to the embedded Marketplace
  yield call(sendPostMessageToMarketplace, PostMessageEvent.CartUpdated, {
    cart: { ...currentCart, products: currentCart.products.filter((product) => product.id !== id) },
  });
}

export function* removeProductFromCartSaga(): SagaIterator {
  yield takeEvery(ProductCartActionNames.REMOVE_APPLICATION, removeProductFromCart);
}
