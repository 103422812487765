import type { AnyAction, Store, Unsubscribe } from 'redux';

import type { IStoreState } from 'interfaces/store/store-state';

type Selector<Selected = unknown, Params = unknown[]> = (state: IStoreState, ...params: Params[]) => Selected;

export class AppStateProvider {
  private static store: Store<IStoreState>;

  static setup(store: Store<IStoreState>): void {
    this.store = store;
  }

  static dispatch(action: AnyAction): AnyAction {
    if (!this.store) {
      throw new Error('Store is not passed to state provider');
    }

    return this.store.dispatch(action);
  }

  static selectFromStore<Selected = unknown, Params = unknown[]>(
    selector: Selector<Selected, Params>,
    ...args: Params[]
  ): Selected {
    if (!this.store) {
      throw new Error('Store is not passed to state provider');
    }

    return selector(this.store.getState(), ...args);
  }

  static getState(): IStoreState {
    if (!this.store) {
      throw new Error('Store is not passed to state provider');
    }

    return this.store.getState();
  }

  static subscribe(listener: VoidFunction): Unsubscribe {
    if (!this.store) {
      throw new Error('Store is not passed to state provider');
    }

    return this.store.subscribe(listener);
  }
}

export type IAppState = Pick<typeof AppStateProvider, 'dispatch' | 'getState' | 'subscribe'>;
