// @ts-strict-ignore
export function downloadFile(fileName: string, fileContent: string, type = 'application/json'): Promise<void> {
  return new Promise((resolve, reject): void => {
    const blob = new Blob([fileContent], { type });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      /** IE workaround for "HTML7007:
       * One or more blob URLs were revoked by closing the blob for which they were created.
       * These URLs will no longer resolve as the data backing the URL has been freed."
       */
      window.navigator.msSaveBlob(blob, fileName);
    } else if ((window.URL || window.webkitURL) && (window.URL || window.webkitURL).createObjectURL) {
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);

      if (fileName) {
        // use HTML5 a[download] attribute to specify filename
        const a = document.createElement('a');
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.open(downloadUrl, '_blank');
        } else {
          a.href = downloadUrl;
          a.download = fileName;
          a.click();
        }
      } else {
        window.open(downloadUrl, '_blank');
      }

      setTimeout(() => {
        URL.revokeObjectURL(downloadUrl);
      }, 100); // cleanup
    } else {
      reject();
    }

    resolve();
  });
}
