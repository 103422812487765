import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ToastContent } from 'constants/toasts';
import { getToastContent } from 'helpers/toast';
import { agentsClient } from 'services/connectivity/configuration-api/agents/client';
import { SubscriptionActionNames, SubscriptionActions } from 'store/entities/subscription/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';

function* contactSubscriptionOwner(): SagaIterator {
  const { error } = yield call(agentsClient.requestAgentUnsuspension);

  if (error) {
    yield put(SubscriptionActions.contactSubscriptionOwnerFailure());
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.REQUEST_ACTIVATE_FAILURE),
        kind: ToastVariant.Error,
      })
    );

    return;
  }

  yield put(SubscriptionActions.contactSubscriptionOwnerSuccess());
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REQUEST_ACTIVATE_SUCCESS),
      kind: ToastVariant.Success,
    })
  );
}

export function* contactSubscriptionOwnerSaga(): SagaIterator {
  yield takeEvery(SubscriptionActionNames.CONTACT_SUBSCRIPTION_OWNER_REQUEST, contactSubscriptionOwner);
}
