import { GlobalModal } from 'constants/global-modal';

import { type ITourStep, TourStepType } from '../interfaces';

import { GreetingsStep, AnnouncementsStep, ContextMenuStep } from './tour-steps';

enum TargetedMessagesTourStep {
  Modal = 'Modal',
  Greetings = 'Greetings',
  Announcements = 'Announcements',
  ContextMenu = 'ContextMenu',
}

export const targetedMessagesSteps: ITourStep[] = [
  {
    id: TargetedMessagesTourStep.Modal,
    type: TourStepType.Modal,
    modal: GlobalModal.TargetedMessagesTourInvitation,
  },
  {
    id: TargetedMessagesTourStep.Greetings,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <GreetingsStep />,
      placement: 'bottom',
    },
  },
  {
    id: TargetedMessagesTourStep.Announcements,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <AnnouncementsStep />,
      placement: 'bottom',
    },
  },
  {
    id: TargetedMessagesTourStep.ContextMenu,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <ContextMenuStep />,
      placement: 'left',
    },
  },
];
