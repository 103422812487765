// @ts-strict-ignore
import { format, addDays, addMonths, differenceInDays, differenceInMonths, isBefore } from 'date-fns';

import { DateFormat } from 'constants/date';
import { ReportDistribution } from 'constants/reports/distribution';
import { ReportType } from 'constants/reports/report-type';
import { MINUTE_IN_SECONDS, HOUR_IN_SECONDS } from 'constants/time-units';
import { formatTime } from 'helpers/format-time';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { round } from 'helpers/numbers';
import { parseFilters } from 'helpers/reports';
import { getFormattedNumberValue } from 'helpers/string';
import { type IBenchmarkDeserializer, type IBenchmarkData } from 'interfaces/reports';
import { AppStateProvider } from 'services/app-state-provider';

import { getFilterDateRange, getDistribution, START_COLLECTION_OF_BENCHMARK_DATA } from '../selectors';

const reportValueLabel = {
  [ReportType.TotalChats]: 'chats_per_day',
  [ReportType.ChatDuration]: 'chats_handle_time',
  [ReportType.ChatResponseTime]: 'chats_first_response_time',
  [ReportType.ChatRatings]: 'chats_customer_happiness',
};

const reportNameLabel = {
  [ReportType.TotalChats]: "Industry's average total chats",
  [ReportType.ChatDuration]: "Industry's average chat duration",
  [ReportType.ChatResponseTime]: "Industry's average first response time",
  [ReportType.ChatRatings]: "Industry's average satisfaction rate",
};

const reportCsvNameLabel = {
  [ReportType.TotalChats]: 'industry_average_total_chats',
  [ReportType.ChatDuration]: 'industry_average_chat_duration',
  [ReportType.ChatResponseTime]: 'industry_average_first_response_time',
  [ReportType.ChatRatings]: 'industry_average satisfaction rate',
};

const countSummaryValue = (
  reportType: ReportType,
  benchmarkData: number[],
  benchmarkDataWithPositiveValuesLength: number
): string => {
  if (benchmarkDataWithPositiveValuesLength < 1) {
    return reportType === ReportType.TotalChats || reportType === ReportType.ChatRatings ? '0' : '0s';
  }

  if (reportType === ReportType.TotalChats) {
    return getFormattedNumberValue(benchmarkData.reduce((acc, val) => acc + val, 0));
  }

  if (reportType === ReportType.ChatDuration) {
    const sumBenchmark = benchmarkData.reduce((acc, val) => acc + val * MINUTE_IN_SECONDS, 0) / HOUR_IN_SECONDS;

    return formatTime(sumBenchmark / benchmarkDataWithPositiveValuesLength);
  }

  if (reportType === ReportType.ChatResponseTime) {
    const sumBenchmark = benchmarkData.reduce((acc, val) => acc + val, 0) / HOUR_IN_SECONDS;

    return formatTime(sumBenchmark / benchmarkDataWithPositiveValuesLength);
  }

  if (reportType === ReportType.ChatRatings) {
    const chatRatingsSum = benchmarkData.reduce((acc, val) => acc + val, 0);

    return getFormattedPercent(chatRatingsSum / benchmarkDataWithPositiveValuesLength);
  }

  return null;
};

export function deserializeBenchmark(data: IBenchmarkData[], reportType: ReportType): IBenchmarkDeserializer {
  const state = AppStateProvider.getState();
  const distribution = getDistribution(state);
  const filtersDateRange = getFilterDateRange(state);

  const filters = parseFilters(filtersDateRange, distribution);

  const dateDifference =
    distribution === ReportDistribution.Day
      ? differenceInDays(filters.to, filters.from) + 1
      : differenceInMonths(filters.to, filters.from) + 1;

  const reportValue: string = reportValueLabel[reportType];
  const shouldRound = reportType === ReportType.ChatDuration;

  const transformedData = data.reduce((acc, curr) => {
    acc[curr.date] = shouldRound ? round(curr[reportValue] / MINUTE_IN_SECONDS, 2) : curr[reportValue];

    return acc;
  }, {});

  const benchmarkData: number[] = new Array(dateDifference).fill(null).map((el, index) => {
    const date =
      distribution === ReportDistribution.Day
        ? format(addDays(filters.from, index), DateFormat.ISO8601Date)
        : format(addMonths(filters.from, index), DateFormat.ISO8601Date);

    if (isBefore(date, START_COLLECTION_OF_BENCHMARK_DATA)) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return transformedData[date] || 0;
  });

  const benchmarkDataWithPositiveValuesLength = benchmarkData.filter((value) => value > 0).length;
  const summaryValue = countSummaryValue(reportType, benchmarkData, benchmarkDataWithPositiveValuesLength);
  const isChatRatingsReport = reportType === ReportType.ChatRatings;

  return {
    benchmarkSummary: {
      name: reportNameLabel[reportType],
      color: '#ffd02c',
      value: summaryValue,
      disableLink: true,
      filters,
      displayAsPercentageCircle: isChatRatingsReport,
    },
    benchmarkSeries: isChatRatingsReport
      ? null
      : {
          name: reportNameLabel[reportType],
          color: '#ffd02c',
          data: benchmarkData,
          csvColumnName: reportCsvNameLabel[reportType],
          benchmark: true,
          filters,
        },
  };
}
