/* eslint-disable @typescript-eslint/naming-convention */
import { AppStateProvider } from 'services/app-state-provider';
import { isOtherChat, isSupervisedChat } from 'store/entities/chats/helpers/common';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { getThread } from 'store/entities/chats/selectors';

export function updateCurrentUserTimestamp<T extends { id: string }>(
  currentUserLogin: string,
  currentTimestamp: number,
  chatUsers: T[]
): (T & { last_seen_timestamp?: number })[] {
  return chatUsers.reduce((acc, user) => {
    if (user.id === currentUserLogin) {
      acc.push({ ...user, last_seen_timestamp: currentTimestamp });

      return acc;
    }

    acc.push({ ...user } as T & { last_seen_timestamp: number });

    return acc;
  }, [] as (T & { last_seen_timestamp: number })[]);
}

export function getShouldIgnoreIncomingChatThreadNotifications(
  thread: ChatThreadEntity,
  requesterId?: string
): boolean {
  const existingThread = AppStateProvider.selectFromStore(getThread, thread?.threadId);

  const isTheSameThreadTypeExisting = thread.type === existingThread?.type;
  const isLoggedAgentRequester = requesterId === thread.currentAgentId; // chat started from the customers list or takeover

  return isSupervisedChat(thread) || isOtherChat(thread) || isTheSameThreadTypeExisting || isLoggedAgentRequester;
}
