import { isBooleanOrUndefined } from 'helpers/boolean';

export function isValidLinkDefinition(rawDefinitionData: unknown): boolean {
  if (!rawDefinitionData) {
    return false;
  }

  const { url, inline } = rawDefinitionData as Record<string, unknown>;

  return Boolean(url && isBooleanOrUndefined(inline));
}
