import { type SagaIterator } from 'redux-saga';
import { put, takeEvery, delay } from 'redux-saga/effects';

import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { getTourEventName } from 'services/tour/helpers';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { type IActionWithPayload } from 'store/helper';

import { TooltipsActionsNames, TooltipsActions } from './actions';
import { type ITooltipPayload, type IShowDelayedTooltipPayload } from './interfaces';

function* markTooltipAsSeen(action: IActionWithPayload<string, ITooltipPayload>): SagaIterator {
  const { savePropertyName, tourId } = action.payload;

  if (savePropertyName) {
    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [savePropertyName]: '1',
      })
    );
  }

  if (tourId) {
    // only when showing tooltip (not when hiding)
    trackEvent('Informative', EventPlace.Tour, {
      name: getTourEventName(tourId),
      startedManually: false,
    });
  }
}

function* showDelayedTooltip(action: IActionWithPayload<string, IShowDelayedTooltipPayload>): SagaIterator {
  const { payload } = action;
  yield delay(payload.delay);
  yield put(TooltipsActions.showTooltip(payload));
}

export function* tooltipsSagas(): SagaIterator {
  yield takeEvery(TooltipsActionsNames.SHOW_DELAYED_TOOLTIP, showDelayedTooltip);
  yield takeEvery(TooltipsActionsNames.SHOW_TOOLTIP, markTooltipAsSeen);
  yield takeEvery(TooltipsActionsNames.HIDE_TOOLTIP, markTooltipAsSeen);
}
