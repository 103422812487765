import { Flag } from '@livechat/design-system-icons';

import { type IItemConfig, type PriorityFilter, PriorityFilterItemConfig } from 'constants/filters/priority-filter';

import type { FilterConfig, FilterLabelValue } from '../interfaces';

export const priorityFilterConfig: FilterConfig = {
  title: 'Priority',
  icon: Flag,
  mobileViewTitle: 'Priority',
  multiple: false,
  getFilterLabelValues(filterValue: PriorityFilter | null): FilterLabelValue[] | null {
    if (!filterValue) {
      return null;
    }

    const itemConfig: IItemConfig = PriorityFilterItemConfig[filterValue];

    return [
      {
        key: filterValue,
        label: itemConfig.label,
      },
    ];
  },
};
