import { BaseRouteName } from 'constants/routes';
import type { IAppState } from 'services/app-state-provider';
import { NavigationViewActions } from 'store/views/navigation/actions';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function handleTicketSummaryUpdate(payload: { badge_count: number }, store: IAppState): void {
  const itemId = BaseRouteName.Tickets;
  const badge = { numeric: payload.badge_count };

  store.dispatch(
    badge.numeric
      ? NavigationViewActions.setNavigationItemBadge({ itemId, badge })
      : NavigationViewActions.clearNavigationItemBadge({ itemId })
  );
}
