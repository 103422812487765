import { createAction, type ActionsUnion } from 'store/helper';

import {
  type IFetchLicenseCustomPropertiesSuccessPayload,
  type IFetchLicenseCustomPropertiesFailurePayload,
  type ISaveLicenceCustomPropertyPayload,
} from './interfaces';

export enum LicenseCustomPropertiesActionNames {
  SAVE_LICENSE_CUSTOM_PROPERTY_REQUEST = 'ENTITIES/LICENSE_CUSTOM_PROPERTIES/SAVE_LICENSE_CUSTOM_PROPERTY_REQUEST',
  SAVE_LICENSE_CUSTOM_PROPERTY_SUCCESS = 'ENTITIES/LICENSE_CUSTOM_PROPERTIES/SAVE_LICENSE_CUSTOM_PROPERTY_SUCCESS',
  SAVE_LICENSE_CUSTOM_PROPERTY_FAILURE = 'ENTITIES/LICENSE_CUSTOM_PROPERTIES/SAVE_LICENSE_CUSTOM_PROPERTY_FAILURE',
  FETCH_LICENSE_CUSTOM_PROPERTIES_REQUEST = 'ENTITIES/LICENSE_CUSTOM_PROPERTIES/FETCH_LICENSE_CUSTOM_PROPERTIES_REQUEST',
  FETCH_LICENSE_CUSTOM_PROPERTIES_SUCCESS = 'ENTITIES/LICENSE_CUSTOM_PROPERTIES/FETCH_LICENSE_CUSTOM_PROPERTIES_SUCCESS',
  FETCH_LICENSE_CUSTOM_PROPERTIES_FAILURE = 'ENTITIES/LICENSE_CUSTOM_PROPERTIES/FETCH_LICENSE_CUSTOM_PROPERTIES_FAILURE',
}

export const LicenseCustomPropertiesActions = {
  saveLicenseCustomProperty: (payload: ISaveLicenceCustomPropertyPayload) =>
    createAction(LicenseCustomPropertiesActionNames.SAVE_LICENSE_CUSTOM_PROPERTY_REQUEST, payload),
  saveLicenseCustomPropertySuccess: (payload: IFetchLicenseCustomPropertiesSuccessPayload) =>
    createAction(LicenseCustomPropertiesActionNames.SAVE_LICENSE_CUSTOM_PROPERTY_SUCCESS, payload),
  saveLicenseCustomPropertyFailure: (error: IFetchLicenseCustomPropertiesFailurePayload) =>
    createAction(LicenseCustomPropertiesActionNames.SAVE_LICENSE_CUSTOM_PROPERTY_FAILURE, error),
  fetchLicenseCustomProperties: () =>
    createAction(LicenseCustomPropertiesActionNames.FETCH_LICENSE_CUSTOM_PROPERTIES_REQUEST),
  fetchLicenseCustomPropertiesSuccess: (payload: IFetchLicenseCustomPropertiesSuccessPayload) =>
    createAction(LicenseCustomPropertiesActionNames.FETCH_LICENSE_CUSTOM_PROPERTIES_SUCCESS, payload),
  fetchLicenseCustomPropertiesFailure: (error: IFetchLicenseCustomPropertiesFailurePayload) =>
    createAction(LicenseCustomPropertiesActionNames.FETCH_LICENSE_CUSTOM_PROPERTIES_FAILURE, error),
};

export type LicenseCustomPropertiesAction = ActionsUnion<typeof LicenseCustomPropertiesActions>;
