import { type FC, useCallback, useEffect, type MutableRefObject } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, KEY_ENTER_COMMAND } from 'lexical';
import { useSelector } from 'react-redux';

import { getIsCopilotModalExpanded, getCopilotIsLoading } from 'store/views/copilot/selectors';

import { useSendCopilotMessage } from '../../hooks/use-send-copilot-message';

interface IProps {
  isCommandMenuVisibleRef?: MutableRefObject<boolean>;
}

export const EnterDownPlugin: FC<IProps> = ({ isCommandMenuVisibleRef }) => {
  const [editor] = useLexicalComposerContext();
  const isLoading = useSelector(getCopilotIsLoading);
  const handleSendMessage = useSendCopilotMessage();
  const isExpanded = useSelector(getIsCopilotModalExpanded);

  const onEnterDown = useCallback((): void => {
    if (isLoading || isCommandMenuVisibleRef?.current) {
      return;
    }
    handleSendMessage({ source: 'message box', state: isExpanded ? 'fullscreen' : 'popover' });
  }, [handleSendMessage, isLoading, isExpanded]);

  useEffect(
    () =>
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (event: KeyboardEvent) => {
          event?.preventDefault();

          if (event.shiftKey) {
            return false;
          }

          onEnterDown();

          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [editor, onEnterDown],
  );

  return null;
};
