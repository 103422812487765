// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';

import { ReportType } from 'constants/reports/report-type';
import { ApiManager } from 'services/api/api-manager';
import { serializeFilters } from 'services/api/report/filters';
import { serializeReportDistribution } from 'services/api/report/v3/filters';
import { type IActionWithPayload } from 'store/helper';

import {
  type IFetchReportPayload,
  type IFetchReportOptions,
  ReportRequestName,
  type IReportRequest,
  RequestVersion,
} from '../interfaces';

import { makeFetchingSaga } from './helpers';

export function* fetchChatRating(action: IActionWithPayload<string, IFetchReportPayload>): SagaIterator {
  const { payload } = action;
  const { filters, distribution } = payload;

  const serializedFilters = serializeFilters(filters, distribution);

  const requests: IReportRequest[] = [
    {
      version: RequestVersion.V3,
      name: ReportRequestName.ChatRatingResponse,
      interface: ApiManager.reportApiV3.fetchChatSatisfaction,
    },
  ];

  const options: IFetchReportOptions = {
    apiFiltersSerializer: serializeReportDistribution,
  };

  if (!serializedFilters.agents) {
    requests.push({
      version: RequestVersion.V3,
      name: ReportRequestName.ChatRankingResponse,
      interface: ApiManager.reportApiV3.fetchChatRanking,
      disableComparison: true,
    });
  }

  yield makeFetchingSaga(ReportType.ChatRatings, action, requests, options);
}
