export function alphabeticalSort(a: string, b: string, desc = false): number {
  // Do not compare empty strings
  if (a.length === 0 && b.length === 0) {
    return 0;
  }

  // Move empty string at the bottom of list
  if (a.length === 0) {
    return 1;
  }

  // Move reference value at the top of the list
  if (b.length === 0) {
    return -1;
  }

  return (desc ? -1 : 1) * a.localeCompare(b);
}

export const getSortOrder = (isDescending: boolean): string => {
  return isDescending ? 'descending' : 'ascending';
};

/**
 * Shuffles an array in place.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array to shuffle.
 * @returns {T[]} The shuffled array.
 */
export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}
