import { saveAs } from 'file-saver';

import { LEGAL_REMINDER_FOR_BUG_REPORT } from 'constants/report-bug';
import { AppStateProvider } from 'services/app-state-provider';
import { consoleLogHistoryManager } from 'services/console-log-history-manager';
import { eventsStack as requestsEventsStack } from 'services/request-events-history-manager';
import { websocketEventsHistoryManager } from 'services/web-sockets-events-history-manager';
import type { ActionWithPayload } from 'store/middlewares/action-history';

export async function generateIssueReport(actionsHistory: ActionWithPayload[]): Promise<void> {
  const JSZip = (await import(/* webpackMode: "lazy" */ 'jszip')).default;
  const jsZip = new JSZip();

  jsZip.file('legal.txt', LEGAL_REMINDER_FOR_BUG_REPORT);
  jsZip.file('livechat_actions.json', JSON.stringify(actionsHistory));
  jsZip.file('livechat_state.json', JSON.stringify(AppStateProvider.getState()));
  jsZip.file('livechat_web_sockets_events.json', JSON.stringify(websocketEventsHistoryManager.geEvents()));
  jsZip.file('livechat_request_events.json', JSON.stringify(requestsEventsStack));
  jsZip.file('livechat_console_logs.json', JSON.stringify(consoleLogHistoryManager.history));

  const content = await jsZip.generateAsync({ type: 'blob' });
  saveAs(content, 'livechat_bug_report.zip');
}
