import { createAction } from 'store/helper';

import {
  type IUrlQueryParamsFeatureRegisterViewPayload,
  type IUrlQueryParamsFeatureUnregisterViewPayload,
} from './interfaces';

export enum UrlQueryParamsFeatureActionNames {
  REGISTER_VIEW = 'FEATURES/URL_QUERY_PARAMS/REGISTER_VIEW',
  UNREGISTER_VIEW = 'FEATURES/URL_QUERY_PARAMS/UNREGISTER_VIEW',
}

export const UrlQueryParamsFeatureActions = {
  registerView: (payload: IUrlQueryParamsFeatureRegisterViewPayload) =>
    createAction(UrlQueryParamsFeatureActionNames.REGISTER_VIEW, payload),

  unregisterView: (payload: IUrlQueryParamsFeatureUnregisterViewPayload) =>
    createAction(UrlQueryParamsFeatureActionNames.UNREGISTER_VIEW, payload),
};
