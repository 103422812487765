import { type KeyMap } from 'helpers/interface';
import type { CannedResponse } from 'interfaces/canned-responses'

export function groupCannedResponsesIdsByTags(cannedResponses: KeyMap<CannedResponse>): KeyMap<string[]> {
  return Object.values(cannedResponses).reduce((acc: KeyMap<string[]>, item) => {
    item.tags.forEach((key) => {
      acc[key] = (Object.prototype.hasOwnProperty.call(acc, key) ? acc[key] : []).concat([item.id]);
    });

    return acc;
  }, {});
}
