import { UserType } from 'constants/user-type';
import type { IAppState } from 'services/app-state-provider';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import { getAreNotificationsSoundsRepeated } from 'store/entities/agents/selectors';
import { isMyChat, isSupervisedChat } from 'store/entities/chats/helpers/common';
import { getThreadByChatId } from 'store/entities/chats/selectors';
import { SoundNotificationActions } from 'store/features/sound-notifications/actions';
import { ChatsViewActions } from 'store/views/chats/actions';

import { type IEventsMarkedAsSeenPushEvent } from '../interfaces';

/**
 * Handles events marked as seen push event.
 * 1. Update events status to `ChatEventStatus.Read`
 * @param {IEventsMarkedAsSeenPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleEventsMarkedAsSeen(payload: IEventsMarkedAsSeenPushEvent, store: IAppState): void {
  const { chat_id: chatId, user_id: authorId, seen_up_to: seenUpTo } = payload;

  const state = store.getState();
  const thread = getThreadByChatId(state, chatId);

  if (thread) {
    const { threadId } = thread;
    const isAuthorChattingAgent = authorId === thread.currentAgentId; // it's the chatting agent that has seen the messages
    const isAuthorCustomer = authorId === thread.customerId; // it's the customer that has seen the messages
    const isAuthorSupervisor = !isAuthorChattingAgent && !isAuthorCustomer; // if both false (neither agent nor customer), then it's the supervisor

    const isLoggedUserChatting = isMyChat(thread);
    const isLoggedUserSupervising = isSupervisedChat(thread);

    // should I mark my own messages as READ by the other person
    // the other person is customer when I'm chatting agent or agent when I'm supervising
    // I ignore any other people that read my messages, e.g. my supervisor
    // marking as READ means the other user has seen my messages and I can mark that in UI
    const shouldMarkMyMessagesAsReadByOtherPerson =
      (isLoggedUserChatting && isAuthorCustomer) || (isLoggedUserSupervising && isAuthorChattingAgent);

    // should I mark messages as SEEN by me
    // messages could belong to other people or myself
    // marking as SEEN means that I have seen the chat messages up to some point and can update the unseen messages counter in UI
    const shouldMarkMessagesAsSeenByMe =
      (isLoggedUserChatting && isAuthorChattingAgent) || (isLoggedUserSupervising && isAuthorSupervisor);

    if (shouldMarkMyMessagesAsReadByOtherPerson) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const upToTimestampInMs = getThreadEntityTimestampInMs({ created_at: seenUpTo });

      store.dispatch(
        ChatsViewActions.markEventsAsRead({
          threadId,
          typesOfAuthorEventsToMarkAsRead: [UserType.Agent, UserType.Supervisor],
          upToTimestampInMs,
        })
      );
    }

    if (shouldMarkMessagesAsSeenByMe) {
      store.dispatch(
        ChatsViewActions.markEventsAsSeen({
          threadId,
          typesOfAuthorEventsToMarkAsSeen: [UserType.Agent, UserType.Supervisor, UserType.Customer],
          sendEvent: false,
        })
      );

      if (getAreNotificationsSoundsRepeated(state)) {
        store.dispatch(SoundNotificationActions.stopRepeatSound());
      }
    }
  }
}
