export interface DateRangeFilter {
  from: Date;
  to: Date;
}

export interface CustomDateRange extends DateRangeFilter {
  id: CustomDateRangeFilterId;
}

export interface CustomDateRangeFilter extends CustomDateRange {
  compare?: CustomDateRange;
}

export const enum CustomDateRangeFilterId {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last_7_days',
  Last30Days = 'last_30_days',
  LastMonth = 'last_month',
  CurrentMonth = 'current_month',
  SamePeriod = 'same_period',
  SamePeriodInLastYear = 'same_period_in_last_year',
  CustomPeriod = 'custom_period',
}

export const DateRangeFilterFormatedValues = {
  [CustomDateRangeFilterId.Today]: 'Today',
  [CustomDateRangeFilterId.Yesterday]: 'Yesterday',
  [CustomDateRangeFilterId.Last7Days]: 'Last 7 days',
  [CustomDateRangeFilterId.Last30Days]: 'Last 30 days',
  [CustomDateRangeFilterId.LastMonth]: 'Last month',
  [CustomDateRangeFilterId.CurrentMonth]: 'Current month',
  [CustomDateRangeFilterId.SamePeriod]: 'Previous period',
  [CustomDateRangeFilterId.SamePeriodInLastYear]: 'Previous year',
  [CustomDateRangeFilterId.CustomPeriod]: 'Custom period',
};
