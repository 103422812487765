// @ts-strict-ignore
import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { App } from 'config/setup';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { ServerError } from 'constants/server-error';
import { trackGTMEvent } from 'helpers/analytics';
import { delay } from 'helpers/delay';
import { redirectToAccounts } from 'helpers/redirect-to-accounts';
import { redirectToLicenseExpired, redirectToStartupError, redirectToSubscription } from 'helpers/routing';
import { AppStateProvider } from 'services/app-state-provider';
import ChatWidget from 'services/chat-widget';
import { DEFAULT_RECONNECT_RETRY_COUNT } from 'services/connectivity/reconnector/events';
import { getReconnector, isReconnectorInitialized } from 'services/connectivity/reconnector/service';
import { hideGlobalLoader } from 'services/global-loader';
import { renderGlobalModals } from 'services/global-modals';
import { isMainLayoutRendered, renderMainLayout } from 'services/render-main-layout';
import { endSession } from 'services/session';
import { getCanManageSubscription } from 'store/features/session/selectors';
import { StartupErrorActions } from 'store/views/startup-error/actions';

import { logout } from './logout';
import { isWebsocketOpen } from './websocket-connection';

const log = debug(DebugLogsNamespace.AppServerConnection);

export async function handleServerError(error: ServerError, errorObject: unknown): Promise<void> {
  log(`Handling server error: ${error}`, errorObject);

  if (isReconnectorInitialized()) {
    const reconnector = getReconnector();
    reconnector.stop();
    reconnector.loginFailed();
  }

  if (error === ServerError.InvalidSession) {
    // Don't redirect to accounts if manual logout is in progress
    // The redirect is taking place in the other mechanism (App.destroy)
    if (!App.manualLogout) {
      log('Invalid session, redirecting to accounts');
      endSession();
      redirectToAccounts();
    }

    return;
  }

  if (error === ServerError.TooManyRequests) {
    log(`Too many requests, reconnecting...`);

    const initialDelay = 1000 + Math.random() * 3000;

    // A rare case in which we got too_many_requests during startup
    if (!isReconnectorInitialized()) {
      log('Reconnector not initialized, refresh the page instead');
      await delay(initialDelay);
      window.location.reload();

      return;
    }

    const reconnector = getReconnector();
    void reconnector.reconnect({
      reason: 'too_many_requests',
      initialDelay,
      maxAttempts: DEFAULT_RECONNECT_RETRY_COUNT,
    });

    return;
  }

  AppStateProvider.dispatch(StartupErrorActions.setSignInError({ signInError: error }));

  if (!isMainLayoutRendered()) {
    renderMainLayout();
  }

  renderGlobalModals();

  if (error === ServerError.UnexpectedError) {
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Handling unexpected server error: ${JSON.stringify(errorObject)}`,
    });

    redirectToStartupError(errorObject);

    return;
  }

  ChatWidget.updateCustomerDetails();

  trackGTMEvent('VirtualPageview', {
    virtualPageURL: document.location.pathname,
    virtualPageTitle: document.title,
  });

  if (error === ServerError.LicenseBlockedInCRM) {
    log('License blocked in CRM, redirecting to error page');
    App.visitInfoPage('/license-inaccessible');

    return;
  }

  if (error === ServerError.IpNotAllowed) {
    log(`IP not allowed, redirecting to error page`);
    App.visitInfoPage('/ip-not-allowed');

    return;
  }

  if (error === ServerError.AccountSuspended) {
    log(`Account suspended, redirecting to error page`);
    App.visitInfoPage('/suspended');

    return;
  }

  if (error === ServerError.WaitingForApproval) {
    log(`Waiting for approval, redirecting to error page`);
    App.visitInfoPage('/waiting-for-approval');

    return;
  }

  const isSubscriptionPath = /\/subscription$/.test(window.location.pathname);

  if (error === ServerError.TooManyAgents && !isSubscriptionPath) {
    log(`Too many agents, redirecting to error page`);
    App.visitInfoPage('/too-many-agents');

    return;
  }

  if (error === ServerError.LicenseExpired) {
    if (isWebsocketOpen()) {
      log('Logging out from websocket');
      await logout();
      log('Logged out successfully');
    }
  }

  const canManageSubscription = AppStateProvider.selectFromStore(getCanManageSubscription);

  if (!canManageSubscription) {
    log(`Redirecting to license expired`);
    redirectToLicenseExpired();

    return;
  }

  hideGlobalLoader();
  AppStateProvider.dispatch({ type: 'APP_ERROR' });

  log(`Redirecting to subscription`);
  redirectToSubscription();
}
