/* eslint-disable @typescript-eslint/naming-convention */
// @ts-strict-ignore
import { round } from './numbers';

const badCharsBeforeEscapeGlobal = /[&<>"'`=]/g;
const badCharsBeforeEscape = /[&<>"'`=]/;
const escapeCharsMap: Record<string, string> = {
  // eslint-disable @typescript-eslint/naming-convention
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '`': '&#x60;',
  '=': '&#x3D;',
  // eslint-enable @typescript-eslint/naming-convention
};

function escapeBadChar(char: string): string {
  return escapeCharsMap[char];
}

/**
 * Safely escapes HTML entities within a string.
 *
 * This function is designed to prevent Cross-Site Scripting (XSS) attacks by replacing potentially harmful characters in the input string with their corresponding HTML entities. It's particularly useful when dealing with user-generated content that will be rendered as HTML.
 *
 * @param {string} string - The input string to be escaped.
 * @returns {string} - The escaped string with HTML entities replacing any potentially harmful characters.
 */
export function escapeString(string: string): string {
  if (!badCharsBeforeEscape.test(string)) {
    return string;
  }

  return string.replace(badCharsBeforeEscapeGlobal, escapeBadChar);
}

/*
 * Return formatted number with separators and comas ex. 123456.789 -> 123,456.789
 */
export function getFormattedNumberValue(value: number | string, precision: number = null): string {
  if (typeof value === 'number') {
    const roundedValue = precision !== null ? round(value, precision) : value;
    const options = precision !== null && {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    };

    return roundedValue.toLocaleString('en-EN', options);
  }

  return value;
}

/**
 * Shows shortened number if maximum limit was exceeded. for example with 150 value and 99 limit it will show '99+'.
 * @param count Number to show.
 * @param limit Maximum value possible to show.
 */
export function shortenedCountNumber(count: number, limit = 99): string {
  return count > limit ? `${limit}+` : String(count);
}

/**
 * Converts text to a dasherized form. Eg. `Example text FOR you` => `example-text-for-you`.
 * @param text Input value to dasherize
 */
export function dasherize(text: string): string {
  return text
    .trim()
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
}

export function removeSpacesFromString(text: string): string {
  return text.replace(/\s/g, '');
}

export function removeDuplicateSpacesFromString(string: string): string {
  return string.replace(/  +/g, ' ');
}

export function removeNewlinesFromString(text: string): string {
  return removeDuplicateSpacesFromString(text.replace(/\n|\r/g, ''));
}

/**
 * Converts text to lowercase and then capitalize the first letter of each word
 * @param name Input value to convert
 */
export function capitalizeName(name: string): string {
  return name.toLowerCase().replace(/(^|-|\s)[a-z]/g, (letter) => {
    return letter.toUpperCase();
  });
}

/**
 * Simplified version of truncate text used previously in webapp. Full version can be found here:
 * https://gist.github.com/jdrzejb/6e2ddb27cea978723276bd0abe03e88c
 *
 * @param text
 * @param size
 * @param append String to append at the end of the text if truncated. Elipsis by default.
 */
export function truncateText(text: string, size: number, append = '…'): string {
  if (text.length - append.length > size) {
    return text.substr(0, size - append.length) + append;
  }

  return text;
}

/**
 * A legacy helper used in old Visitor api pushes. It returns an empty string if the string was '$' or null/undefined.
 * I would never dare to think of an actual use case.
 * @todo Remove it when no longer used
 */
export function removeDollar(str = ''): string {
  return str.replace(/^\$$/g, '');
}

/**
 * Another weird legacy helper used in Visitor model. It removes last semicolon from the string. Why, you ask?
 * Because that's how we wrote stuff back in 2012.
 * @todo Remove it when no longer used
 */
export function removeLastSemicolon(str = ''): string {
  return str.replace(/;$/, '');
}

export function isAlphaNumeric(string: string): boolean {
  let code: number;
  let i: number;
  let len: number;

  for (i = 0, len = string.length; i < len; i += 1) {
    code = string.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }

  return true;
}

function hashCode(string: string): number {
  let hash = 0;
  let i: number;
  const len: number = string.length;
  for (i = 0; i < len; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return Math.abs(hash);
}

export const cyrb53 = (str: string, seed = 0): string => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
};

export function convertToNumberRange(string: string, from: number, to: number): number {
  return from + (hashCode(string) % (to - from + 1));
}

/**
 * Capitalize first letter
 *
 * @example
 *   capitalizeFirstLetter('hello world') // 'Hello world'
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Lowercase first letter
 *
 * @example
 *   lowercaseFirstLetter('Hello World') // 'hello World'
 */
export function lowercaseFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * plural(1, "foo", "bar"); // "foo"
 * plural(2, "foo", "bar"); // "bar"
 */
export function plural(metric: number, singularForm: string, pluralForm: string, zeroForm?: string): string {
  if (zeroForm && metric === 0) return zeroForm;

  return metric === 1 ? singularForm : pluralForm;
}

export function uniqueId(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
}

const commasRegexp = /(\d+)(\d{3})/;

export function addCommas(number: number): string {
  const s = String(number);
  const splitted = s.split('.');
  let x1 = splitted[0];
  const x2 = splitted.length > 1 ? `.${splitted[1]}` : '';
  while (commasRegexp.test(x1)) {
    x1 = x1.replace(commasRegexp, '$1,$2');
  }

  return x1 + x2;
}
