import { AppStateProvider } from 'services/app-state-provider';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

export function markDetailsPanelOnboardingFinishedKB(): void {
  AppStateProvider.dispatch(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.DetailsPanelOnboardingFinishedKB]: '1',
    })
  );
}
