import { injectGlobal } from '@emotion/css';

import { beamer } from './beamer';
import { buttons } from './buttons';
import { common } from './common';
import { forms } from './forms';
import { mailingManage } from './mailing-manage';
import { overlays } from './overlays';
import { cssReset } from './reset';
import { scrollbar } from './scrollbar';
import { sprig } from './sprig';

export const injectGlobalStyles = (): void =>
  injectGlobal(`
    ${cssReset};
    ${common};
    ${scrollbar};
    ${beamer};
    ${sprig};
    ${buttons};
    ${overlays};
    ${mailingManage};
    ${forms};
`);
