export enum AppsSection {
  Apps = 'Apps',
  Marketplace = 'Marketplace',
  SideNavigation = 'Side Navigation',
  PlatformApi = 'Platform API',
  Settings = 'Settings',
  Webhooks = 'Webhooks',
  ApiTab = 'API tab',
  ConfirmUninstallModal = 'Confirm uninstall modal',
}

export enum AppsEvent {
  SectionOpened = 'Section opened',
  ApplicationNoSettingsOpenChatClicked = 'Application has no settings, open chats button clicked',
  ApplicationNoSettingsOpenFullscreenAppClicked = 'Application has no settings, open fullscreen app button clicked',
  ApplicationNoSettingsTutorialLinkClicked = 'Application has no settings, tutorial link clicked',
  ApplicationNoSettingsOpenReportsClicked = 'Application has no settings, open reports button clicked',
  OpenAppInMarketplaceActionItemClicked = 'Open app in Marketplace action menu item clicked',
  OpenAppSettingsActionItemClicked = 'Open app settings action menu item clicked',
  OpenAppActionItemClicked = 'Open app action menu item clicked',
  SetupInstructionsActionItemClicked = 'Setup instructions action menu item clicked',
  FinishInstallationActionItemClicked = 'Finish installation action menu item clicked',
  ContactDeveloperActionItemClicked = 'Contact developer action menu item clicked',
  UninstallAppActionItemClicked = 'Uninstall app action menu item clicked',
  InstallAppActionItemClicked = 'Install app action menu item clicked',
  SubmitAndUninstallButtonClicked = 'Submit and uninstall button clicked',
  ExploreButtonClicked = 'Explore button clicked',
  StartBuildingButtonClicked = 'Start building button clicked',
  ApiUsageButtonClicked = 'API Usage button clicked',
  ExploreApiConsoleButtonClicked = 'Explore API Console button clicked',
  CheckApiButtonClicked = 'Check API button clicked',
  GoToConsoleButtonClicked = 'Go to Console button clicked',
  ReadTutorialButtonClicked = 'Read tutorial button clicked',
  ExploreAppsClicked = 'Explore apps clicked',
  ExternalIntegrationsClicked = 'External integrations clicked',
  SectionItemClicked = 'Section item clicked',
  GoToCartClicked = 'Go to Cart clicked',
  FullscreenAppClicked = 'Application Fullscreen app clicked',
  SettingsClicked = 'Application Settings clicked',
  YourAppsChevronItemClicked = 'Your Apps item clicked',
  YourAppsConfigurationListOpened = 'Your Apps configuration list opened',
  YourAppsConfigurationListClosed = 'Your Apps configuration list closed',
  FullscreenWidgetPinned = 'Fullscreen widget pinned',
  FullscreenWidgetUnpinned = 'Fullscreen widget unpinned',
}
