import { TopBarNotificationType } from 'constants/notifications';
import type { KeyMap } from 'helpers/interface';
import { type ITopBarNotification } from 'interfaces/notification-bar';

import { isDesktopView } from './device';

interface ITopBarNotificationOptions {
  priority: number;
  visibleOnMobile: boolean;
}

export const getTopBarNotificationOptions = (
  type: TopBarNotificationType,
  canManageSubscription?: boolean,
): ITopBarNotificationOptions => {
  // to remove after tickets are sunsetted
  // agents should see notification about tickets before notification about desktop app
  // vice-owners and owners should see notification about desktop app after notification about tickets
  const sunsettingTicketsOptions: KeyMap<ITopBarNotificationOptions> = canManageSubscription
    ? {
        [TopBarNotificationType.StatusAway]: {
          priority: 2,
          visibleOnMobile: true,
        },
        [TopBarNotificationType.NativeTicketsSunset]: {
          priority: 3,
          visibleOnMobile: false,
        },
        [TopBarNotificationType.DownloadDesktopApp]: {
          priority: 4,
          visibleOnMobile: false,
        },
      }
    : {
        [TopBarNotificationType.NativeTicketsSunset]: {
          priority: 2,
          visibleOnMobile: false,
        },
        [TopBarNotificationType.DownloadDesktopApp]: {
          priority: 3,
          visibleOnMobile: false,
        },
        [TopBarNotificationType.StatusAway]: {
          priority: 4,
          visibleOnMobile: true,
        },
      };

  // Higher priority should be displayed first
  const TopBarNotificationsOptions: KeyMap<ITopBarNotificationOptions> = {
    [TopBarNotificationType.TrialWarning]: {
      priority: 1,
      visibleOnMobile: false,
    },
    ...sunsettingTicketsOptions,
    [TopBarNotificationType.TrialWarningImportant]: {
      priority: 5,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.SendPendingInvites]: {
      priority: 6,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.BrowserNotifications]: {
      priority: 7,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.InstallCode]: {
      priority: 8,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.ConnectionRestored]: {
      priority: 9,
      visibleOnMobile: true,
    },
    [TopBarNotificationType.Reconnecting]: {
      priority: 10,
      visibleOnMobile: true,
    },
    [TopBarNotificationType.Offline]: {
      priority: 11,
      visibleOnMobile: true,
    },
  };

  return TopBarNotificationsOptions[type];
};

export const getVisibleNotifications = (
  notifications: ITopBarNotification[],
  canManageSubscription?: boolean,
): ITopBarNotification[] =>
  notifications.filter(
    (notification) =>
      isDesktopView() || getTopBarNotificationOptions(notification.name, canManageSubscription).visibleOnMobile,
  );
