export enum TeamRoute {
  AgentsList = '/team',
  AgentEdit = '/team/agent/:agentId',
  SuspendedAgents = '/team/suspended-agents',
  BotsList = '/team/chatbots',
  BotEdit = '/team/chatbot/:chatbotId',
  Chatbots = '/team/chatbots',
  GroupsList = '/team/groups',
  GroupEdit = '/team/group/:groupId',
}

export enum TeamSubPaths {
  Agents = '',
  Chatbots = '/chatbots',
  Groups = '/groups',
  SuspendedAgents = '/suspended-agents',
}
