// @ts-strict-ignore
import chunk from 'lodash.chunk';
import flatten from 'lodash.flatten';
import isEqual from 'lodash.isequal';
import { type SagaIterator } from 'redux-saga';
import { takeEvery, call, put, cancel, select, all } from 'redux-saga/effects';

import { CannedResponseEvent } from 'constants/canned-responses/event';
import type { CannedResponse } from 'interfaces/canned-responses';
import { type GroupId } from 'interfaces/groups';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import {
  type ICannedCreateBody,
  type ICannedUpdateBody,
  type IFetchCannedResult,
} from 'services/api/canned-response/interfaces';
import { deserializeCannedResponse } from 'services/api/canned-response/serializer';
import { trackEvent } from 'services/event-tracking';
import { getLoggedInAgentGroupsIds } from 'store/entities/agents/selectors';
import { getArePrivateCannedResponsesAvailable } from 'store/features/session/selectors';
import { type IActionWithPayload } from 'store/helper';

import { CannedResponseActions, CannedResponseActionNames } from '../actions';
import type {
  ISaveCannedResponsePayload,
  CannedResponseResult,
  IRemoveCannedResponsePayload,
  IFetchCannedResponsesPayload,
} from '../interfaces';
import { getCannedResponseAutotagsById, getCannedResponseById } from '../selectors';

export function* saveCannedResponse(action: IActionWithPayload<string, ISaveCannedResponsePayload>): SagaIterator {
  const {
    payload: {
      groupId: group,
      trackingEventSource,
      text,
      tags,
      shouldCreateAnotherOne,
      eventProperties,
      isPrivate,
      autotags,
    },
  } = action;
  const { cannedResponsesApi } = ApiManager;

  let body: ICannedCreateBody = { text, tags, private: isPrivate ? '1' : '0' };

  if (group) {
    body = {
      ...body,
      group,
    };
  }

  const { result, error }: RequestResult<IFetchCannedResult> = yield call(cannedResponsesApi.save, body);

  if (result) {
    yield put(
      CannedResponseActions.saveCannedResponseSuccess({
        trackingEventSource,
        result,
        shouldCreateAnotherOne,
        isPrivate,
        eventProperties,
        groupId: group,
      })
    );

    yield put(
      CannedResponseActions.addCannedResponseData({
        groupId: String(group),
        id: String(result.id),
        modificationTimestampInMs: result.creation_date * 1000,
        tags,
        text,
        createdBy: result.created_by,
        isPrivate: result.private,
      })
    );

    if (autotags?.length) {
      yield put(
        CannedResponseActions.createCannedResponseAutotags({
          cannedId: String(result.id),
          autotags,
          groupId: String(group),
        })
      );
    }
  } else {
    yield put(CannedResponseActions.saveCannedResponseFailure({ trackingEventSource, error }));
  }
}

function* fetchCannedResponses(action: IActionWithPayload<string, IFetchCannedResponsesPayload>): SagaIterator {
  const {
    payload: { groupIds },
  } = action;
  const { cannedResponsesApi } = ApiManager;

  const loggedInAgentGroupsIds: GroupId[] = yield select(getLoggedInAgentGroupsIds);
  const arePrivateCRAvailable: boolean = yield select(getArePrivateCannedResponsesAvailable);
  const groups: GroupId[] = groupIds || loggedInAgentGroupsIds;

  // We need to chunk the requests due to HTTP URL limit allowing max of 2083 characters.
  // 125 groups would give us nearly 1900 characters for 4 digit ids and we'll keep this value as safe one.
  const MAX_GROUP_COUNT_PER_REQUEST = 125;
  const chunkedGroups = chunk(groups, MAX_GROUP_COUNT_PER_REQUEST);

  const responses: RequestResult<IFetchCannedResult[], string>[] = yield all(
    chunkedGroups.map((groupChunk) => call(cannedResponsesApi.fetchAll, groupChunk, arePrivateCRAvailable))
  );

  const combinedErrors = responses
    .reduce((acc: string[], response) => (response.error ? [...acc, response.error] : acc), [])
    .join(', ');

  if (combinedErrors) {
    yield put(CannedResponseActions.fetchCannedResponsesFailure({ error: combinedErrors }));
    yield cancel();
  } else {
    const cannedResponses: CannedResponse[] = flatten(responses.map((response) => response.result)).map(
      deserializeCannedResponse
    );
    yield put(CannedResponseActions.fetchCannedResponsesSuccess({ cannedResponses }));
    yield put(CannedResponseActions.fetchCannedResponseAutotags());
  }
}

export function* updateCannedResponse(action: IActionWithPayload<string, ISaveCannedResponsePayload>): SagaIterator {
  const {
    payload: { id, groupId: group, trackingEventSource, text, tags, isPrivate, eventProperties, autotags },
  } = action;

  const currentAutotags: string[] = yield select(getCannedResponseAutotagsById, id);
  const shouldUpdateAutotags = !!autotags && !isEqual([...currentAutotags].sort(), [...autotags].sort());

  const { cannedResponsesApi } = ApiManager;
  const cannedPendingUpdate: CannedResponse = yield select(getCannedResponseById, id);
  let body: ICannedUpdateBody = { text, tags, private: Number(!!isPrivate).toString() };

  const eventPayload = {
    ...eventProperties,
    isPrivate,
    autotagsCount: autotags?.length ?? 0,
  };
  let changedPrivateSetting = {};
  const cannedPrivateSettingsHasChanged = isPrivate !== cannedPendingUpdate.isPrivate;

  if (cannedPrivateSettingsHasChanged) {
    if (isPrivate && !!cannedPendingUpdate.isPrivate) {
      changedPrivateSetting = { changedFromSharedToPrivate: true };
    } else {
      changedPrivateSetting = { changedFromPrivateToShared: true };
    }
  }

  if (group) {
    body = {
      ...body,
      group,
    };
  }

  const { result, error }: RequestResult<CannedResponseResult> = yield call(cannedResponsesApi.update, id, body);

  if (result) {
    yield put(CannedResponseActions.updateCannedResponseSuccess());

    yield put(
      CannedResponseActions.updateCannedResponseData({
        groupId: String(group),
        id: String(id),
        modificationTimestampInMs: result.modification_date * 1000,
        tags,
        text,
        modifiedBy: result.modified_by,
        isPrivate: result.private,
      })
    );

    if (shouldUpdateAutotags) {
      yield put(
        CannedResponseActions.updateCannedResponseAutotags({
          cannedId: String(result.id),
          autotags,
          groupId: String(group),
        })
      );
    }

    trackEvent(CannedResponseEvent.CannedResponseUpdated, trackingEventSource, {
      ...changedPrivateSetting,
      ...eventPayload,
    });
  } else {
    yield put(CannedResponseActions.updateCannedResponseFailure({ trackingEventSource, error }));
  }
}

function* removeCannedResponse(action: IActionWithPayload<string, IRemoveCannedResponsePayload>): SagaIterator {
  const {
    payload: { id, trackingEventSource },
  } = action;
  const { cannedResponsesApi } = ApiManager;

  const { result, error }: RequestResult<string, string> = yield call(cannedResponsesApi.remove, id);
  if (result) {
    yield put(CannedResponseActions.removeCannedResponseSuccess());
    yield put(CannedResponseActions.removeCannedResponseData({ id }));
    yield put(CannedResponseActions.removeCannedResponseAutotags({ cannedId: id }));
  } else {
    yield put(CannedResponseActions.removeCannedResponseFailure({ trackingEventSource, error }));
  }
}

export function* cannedResponsesSagas(): Generator {
  yield takeEvery(CannedResponseActionNames.FETCH_CANNED_RESPONSES_REQUEST, fetchCannedResponses);
  yield takeEvery(CannedResponseActionNames.SAVE_CANNED_RESPONSE_REQUEST, saveCannedResponse);
  yield takeEvery(CannedResponseActionNames.UPDATE_CANNED_RESPONSE, updateCannedResponse);
  yield takeEvery(CannedResponseActionNames.REMOVE_CANNED_RESPONSE, removeCannedResponse);
}
