// @ts-strict-ignore
import { type KeyMap } from 'helpers/interface';
import { type GroupId } from 'interfaces/groups';
import { CRUDAction, RequestAction } from 'store/entities/actions';

import { type EntityFetchCollectionSuccess } from '../interfaces';

import { type TagAction, TagActionNames } from './actions';
import { type ITagAddedPayload, type ITagRemovedPayload, type ITag } from './interfaces';

export const initialState = {};

/**
 * Generates unique id for tag to be stored in a key map.
 * @param   {string} name Tag name.
 * @param   {number} group Group in which tag is allowed to be used.
 * @returns {string} Generated id.
 */
export function buildTagId(name: string, group: GroupId): string {
  return `${group}-${name}`;
}

export const tagsReducer = (state: KeyMap<ITag> = initialState, action: TagAction): KeyMap<ITag> => {
  switch (action.type) {
    case TagActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]: {
      const { values } = action.payload as EntityFetchCollectionSuccess<ITag>;

      return values.reduce((acc, tag) => {
        const tagId = buildTagId(tag.name, tag.group);

        acc[tagId] = tag;

        return acc;
      }, {});
    }

    case TagActionNames.TAG_ADDED: {
      const { name, group, countInChats, countInTickets } = action.payload as ITagAddedPayload;

      return {
        ...state,
        [buildTagId(name, group)]: { name, group, countInChats, countInTickets },
      };
    }

    case TagActionNames.TAG_REMOVED: {
      const { name, group } = action.payload as ITagRemovedPayload;
      const newState = { ...state };
      delete newState[buildTagId(name, group)];

      return newState;
    }

    default:
      return state;
  }
};
