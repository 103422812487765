import { getTopBarNotificationOptions } from 'helpers/notification-bar';
import { type ITopBarNotification, type ITopBarNotificationsState } from 'interfaces/notification-bar';

interface IWithTopBarNotificationsState {
  features: {
    notificationsBar: ITopBarNotificationsState;
  };
}

export function isNotificationBarVisible(state: IWithTopBarNotificationsState): boolean {
  return state.features.notificationsBar.display;
}

export function getNotifications(state: IWithTopBarNotificationsState): ITopBarNotification[] {
  return state.features.notificationsBar.notifications;
}

export function hasNotifications(state: IWithTopBarNotificationsState): boolean {
  return state.features.notificationsBar.notifications.length > 0;
}

export function hasMobileNotifications(state: IWithTopBarNotificationsState): boolean {
  const notifications = getNotifications(state);
  const mobileNotifications = notifications.filter(
    (notification) => getTopBarNotificationOptions(notification.name).visibleOnMobile,
  );

  return mobileNotifications.length > 0;
}
