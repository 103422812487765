import { type ActionsUnion, createAction } from 'store/helper';

import {
  type IAgentBot,
  type IBotUpdateSuccessPayload,
  type IBotRemovedPayload,
  type IBotsFetchedPayload,
  type IChatbotInfoSuccessPayload,
} from './interfaces';

export enum AgentBotActionNames {
  BOTS_FETCHED = 'ENTITIES/BOTS/BOTS_FETCHED',
  BOT_ADD = 'ENTITIES/BOTS/BOT_ADD',
  BOT_UPDATE = 'ENTITIES/BOTS/BOT_UPDATE',
  BOT_REMOVE = 'ENTITIES/BOTS/BOT_REMOVE',
  CHATBOT_INFO_FETCH_REQUEST = 'ENTITIES/BOTS/CHATBOT_INFO_FETCH_REQUEST',
  CHATBOT_INFO_FETCH_SUCCESS = 'ENTITIES/BOTS/CHATBOT_INFO_FETCH_SUCCESS',
  CHATBOT_INFO_FETCH_FAILURE = 'ENTITIES/BOTS/CHATBOT_INFO_FETCH_FAILURE',
  CHATBOT_INFO_FETCH = 'ENTITIES/BOTS/CHATBOT_INFO_FETCH',
}

export const AgentBotActions = {
  botsFetched: (payload: IBotsFetchedPayload) => createAction(AgentBotActionNames.BOTS_FETCHED, payload),
  botAdd: (payload: IAgentBot) => createAction(AgentBotActionNames.BOT_ADD, payload),
  botUpdate: (payload: IBotUpdateSuccessPayload) => createAction(AgentBotActionNames.BOT_UPDATE, payload),
  botRemove: (payload: IBotRemovedPayload) => createAction(AgentBotActionNames.BOT_REMOVE, payload),
  fetchChatbotInfo: () => createAction(AgentBotActionNames.CHATBOT_INFO_FETCH_REQUEST, {}),
  fetchChatbotInfoSuccess: (payload: IChatbotInfoSuccessPayload) =>
    createAction(AgentBotActionNames.CHATBOT_INFO_FETCH_SUCCESS, payload),
  fetchChatbotInfoFailure: () => createAction(AgentBotActionNames.CHATBOT_INFO_FETCH_FAILURE, {}),
};
export type AgentBotAction = ActionsUnion<typeof AgentBotActions>;
