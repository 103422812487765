import { AppStateProvider } from 'services/app-state-provider';
import { chatFollowManager } from 'services/chat-follow-manager';
import { type ILoginResponsePushEvent } from 'services/web-socket/interfaces';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getAllMyChatCustomerIds } from 'store/entities/chats/selectors';
import { CustomerActions } from 'store/entities/customers/actions';
import { getAllCustomers } from 'store/entities/customers/selectors';

import { deserializeChatSummaryCollection } from '../serialization/deserialize-collection';
import { deserializeChatsSummaryCustomers } from '../serialization/deserialize-customer';
import { getWasEventSeen } from '../serialization/helpers/common';

export function handleLogin(payload: ILoginResponsePushEvent): void {
  const currentAgentId = AppStateProvider.selectFromStore(getLoggedInAgentLogin);

  const { threads, events, chatIdsToFollow, threadTags } = deserializeChatSummaryCollection(
    payload.chats_summary,
    currentAgentId,
    getWasEventSeen,
  );
  chatFollowManager.setFollowedChatsIds(chatIdsToFollow);

  // We need to get own chats customers from the store in case of reconnect and customers leaving website in the meantime - LC-552
  const customerIdsFromChats = AppStateProvider.selectFromStore(getAllMyChatCustomerIds);
  const stateCustomers = AppStateProvider.selectFromStore(getAllCustomers);

  const filteredStateCustomers = Object.fromEntries(
    Object.entries(stateCustomers).filter(([customerId]) => customerIdsFromChats.has(customerId)),
  );

  const newCustomers = deserializeChatsSummaryCustomers(payload.chats_summary);
  const mergedCustomers = { ...filteredStateCustomers, ...newCustomers };

  AppStateProvider.dispatch(CustomerActions.setData({ customers: mergedCustomers }));

  AppStateProvider.dispatch(
    ChatsEntitiesActions.setChatsSummary({
      threads,
      events,
      threadTags,
    }),
  );

  AppStateProvider.dispatch(ChatsEntitiesActions.fetchUnassignedChatsSummary());
}
