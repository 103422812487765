import { type FC, useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, INSERT_LINE_BREAK_COMMAND } from 'lexical';

export const PreventNewLinePlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const unregister = editor.registerCommand(INSERT_LINE_BREAK_COMMAND, () => true, COMMAND_PRIORITY_LOW);

    return () => {
      unregister();
    };
  }, [editor]);

  return null;
};
