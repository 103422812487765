import { type FC } from 'react';

import { SideNavigation as DSSideNavigation } from '@livechat/design-system-react-components';

import { AdditionalSectionsList } from './additional-sections-list/AdditionalSectionsList';
import { ExploreApps } from './ExploreApps';
import { IntegrationsList } from './integrations-list/IntegrationsList';
import { YourAppsList } from './your-apps-list/YourAppsList';

import { sideNavigationStyles } from './styles';

export const AppsSideNavigation: FC = () => (
  <DSSideNavigation title="Apps" noGaps className={sideNavigationStyles}>
    <ExploreApps />
    <YourAppsList />
    <IntegrationsList />
    <AdditionalSectionsList />
  </DSSideNavigation>
);
