import { createCustomStatusApiClient } from '../client';

import { type MyInactivityResponse, type AddInactivityRequest } from './types';

const client = createCustomStatusApiClient('inactivities');

/**
 * Custom Status API inactivities client.
 */
export const inactivitiesClient = {
  /**
   * Add a new inactivity with reason.
   */
  create: async (data: AddInactivityRequest) => {
    return client.post<unknown, Error>('', { json: data });
  },

  /**
   * Retrieves my inactivity.
   */
  get: async () => {
    return client.get<MyInactivityResponse, Error>('my');
  },
};
