import { type FC, type ReactNode, Suspense } from 'react';

import { ErrorBoundary } from 'components/error-boundary/ErrorBoundary';
import { AppSkeleton } from 'components/skeleton/AppSkeleton';

interface IProps {
  children?: ReactNode;
  fallback?: ReactNode;
}

export const SuspenseWrapper: FC<IProps> = ({ children, fallback }) => (
  <ErrorBoundary>
    <Suspense fallback={fallback !== undefined ? fallback : <AppSkeleton />}>{children}</Suspense>
  </ErrorBoundary>
);
