// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { put, call, fork, takeEvery } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { deserializeLicenseCustomProperties } from 'services/api/license-properties/serializer';
import { setupRiskDetection } from 'store/features/signup-risk-detection/sagas';
import { type IActionWithPayload } from 'store/helper';

import { LicenseCustomPropertiesActions, LicenseCustomPropertiesActionNames } from './actions';
import { type ISaveLicenceCustomPropertyPayload } from './interfaces';

function* saveLicenseCustomPropertiesSaga(
  action: IActionWithPayload<LicenseCustomPropertiesActionNames, ISaveLicenceCustomPropertyPayload>
): SagaIterator {
  const { result, error }: RequestResult<any> = yield call(ApiManager.licensePropertiesApi.setCustom, action.payload);

  if (result) {
    yield put(
      LicenseCustomPropertiesActions.saveLicenseCustomPropertySuccess(deserializeLicenseCustomProperties(result))
    );
  } else {
    yield put(LicenseCustomPropertiesActions.saveLicenseCustomPropertyFailure({ error: error.message }));
  }
}

function* fetchLicenseCustomPropertiesSaga(): SagaIterator {
  const { result, error }: RequestResult<any> = yield call(ApiManager.licensePropertiesApi.fetchAllCustom);

  if (result) {
    const properties = result.custom ? deserializeLicenseCustomProperties(result.custom) : null;

    yield put(LicenseCustomPropertiesActions.fetchLicenseCustomPropertiesSuccess(properties));
    yield fork(setupRiskDetection);
  } else {
    yield put(LicenseCustomPropertiesActions.fetchLicenseCustomPropertiesFailure({ error: error.message }));
  }
}

export function* licenseCustomPropertiesSagas(): SagaIterator {
  yield takeEvery(
    ['APP_READY', 'APP_ERROR', LicenseCustomPropertiesActionNames.FETCH_LICENSE_CUSTOM_PROPERTIES_REQUEST],
    fetchLicenseCustomPropertiesSaga
  );
  yield takeEvery(
    LicenseCustomPropertiesActionNames.SAVE_LICENSE_CUSTOM_PROPERTY_REQUEST,
    saveLicenseCustomPropertiesSaga
  );
}
