// @ts-strict-ignore
import type { CompanySize, ICompanyDetails } from 'interfaces/entities/company-details';
import type { IStoreState } from 'interfaces/store/store-state';
import { createRequestFetchingSelector, createHasFetchedSelector } from 'store/requests/selectors';

import { CompanyDetailsRequestFetchingSelectorsNames } from './actions';
import { type ICompanyDetailsState } from './interfaces';

interface IWithCompanyDetailsState {
  entities: {
    companyDetails: ICompanyDetailsState;
  };
}

export const getIsFetchingCompanyDetails = (state: IStoreState): boolean =>
  createRequestFetchingSelector([CompanyDetailsRequestFetchingSelectorsNames.FETCH_COMPANY_DETAILS_REQUEST])(state);

export const getHasFetchedCompanyDetails = (state: IStoreState): boolean =>
  createHasFetchedSelector([CompanyDetailsRequestFetchingSelectorsNames.FETCH_COMPANY_DETAILS_REQUEST])(state);

export const getCompanyDetails = (state: IWithCompanyDetailsState): ICompanyDetails | null =>
  state.entities.companyDetails.companyDetails;

export const getCompanyName = (state: IWithCompanyDetailsState): string =>
  state.entities.companyDetails.companyDetails?.company;

export const getCompanyDetailsCountry = (state: IWithCompanyDetailsState): string =>
  state.entities.companyDetails.companyDetails?.country;

export const getCompanyDetailsSize = (state: IWithCompanyDetailsState): CompanySize =>
  state.entities.companyDetails.companyDetails?.size;

export const getCompanyDetailsWebsite = (state: IWithCompanyDetailsState): string =>
  state.entities.companyDetails.companyDetails?.website;

export const shouldShowCompanyDetailsModal = (state: IWithCompanyDetailsState): boolean => {
  const { company, website, city, country, industry, size, b2b, b2c, internal } = getCompanyDetails(state) || {};

  const shouldShow = !company || !website || !city || !country || !industry || !size || !(b2b || b2c || internal);

  return shouldShow;
};
