// @ts-strict-ignore
import { ComponentType } from '../../_constants/custom-section';
import { type CustomSectionDefinition } from '../../interfaces/custom-section';

import { isValidButtonDefinition } from './validators/button';
import { isValidLabelDefinition } from './validators/label';
import { isValidLinkDefinition } from './validators/link';
import { isValidTitleDefinition } from './validators/title';

const COMPONENT_TYPES = Object.values(ComponentType).reduce((types, value) => ({ ...types, [value]: true }), {});

const getComponentValidator = (type: ComponentType): ((data: any) => boolean) => {
  switch (type) {
    case ComponentType.Button:
      return isValidButtonDefinition;
    case ComponentType.Link:
      return isValidLinkDefinition;
    case ComponentType.Title:
      return isValidTitleDefinition;
    case ComponentType.LabelValue:
      return isValidLabelDefinition;
    default:
      return null;
  }
};

export function isValidComponentDefinition(component): boolean {
  const { type, data } = component;

  if (!type || !COMPONENT_TYPES[type]) {
    return false;
  }

  const validateComponent = getComponentValidator(type);

  return validateComponent ? validateComponent(data) : true;
}

function isValidComponentsDefinition(components: any[]): boolean {
  return components.every(isValidComponentDefinition);
}

export function isValidSectionDefinition(section: CustomSectionDefinition): boolean {
  const { title, components } = section;

  if (!title || !components || !Array.isArray(components)) {
    return false;
  }

  return isValidComponentsDefinition(components);
}
