import type { FC, MouseEvent } from 'react';

import { Tooltip } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { NavigationId } from 'constants/navigation';
import { getIsSubscriptionInTrialAndNotSubscribed } from 'store/entities/subscription/selectors';
import { getCanSeeSubscriptionMenuItem, getTrialDaysLeft } from 'store/features/session/selectors';
import { plural } from 'helpers/string';

import { navigationItems } from '../../configuration';
import { onItemClick } from '../navigation-item/helpers';
import { NavigationItem } from '../navigation-item/NavigationItem';

import * as styles from './styles';

export const SubscriptionItem: FC = () => {
  const canSeeSubscriptionMenuItem = useSelector(getCanSeeSubscriptionMenuItem);
  const isTrial = useSelector(getIsSubscriptionInTrialAndNotSubscribed);
  const trialDaysLeft = useSelector(getTrialDaysLeft);
  const config = navigationItems.get(NavigationId.Subscription);

  if (!config || !canSeeSubscriptionMenuItem) {
    return null;
  }

  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  const trackingTitle = config.trackingTitle!;
  const path = config.path!;
  /* eslint-enable @typescript-eslint/no-unnecessary-type-assertion */
  const handleItemClick = (event: MouseEvent<HTMLAnchorElement>): void => onItemClick({ event, path, trackingTitle });

  if (!isTrial || trialDaysLeft > 7) {
    return <NavigationItem id={NavigationId.Subscription} />;
  }

  return (
    <li className={styles.container}>
      <Tooltip
        offsetMainAxis={12}
        hoverOnDelay={400}
        placement="right-start"
        kind="important"
        className={styles.tooltip}
        triggerRenderer={() => (
          <a className={styles.link} href={path} onClick={handleItemClick}>
            <div className={styles.dayCounter}>{trialDaysLeft}</div>
            <div className={styles.label}>{plural(trialDaysLeft, 'day', 'days')}</div>
          </a>
        )}
      >
        Subscribe!
      </Tooltip>
    </li>
  );
};
