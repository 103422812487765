import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { NavigationId } from 'constants/navigation';
import { getQueuedIds } from 'store/entities/chats/selectors';
import { getSubscriptionIsPastDue } from 'store/entities/subscription/selectors';
import { getShouldShowAutomateTooltip } from 'store/features/agent-custom-properties/selectors';
import { getNotificationsChatsCount } from 'store/views/chats/selectors';
import { getNavigationItemBadge } from 'store/views/navigation/selectors';
import { getBadgesCount } from 'store/views/reports/selectors';

import { shouldShowSettingsBadge } from '../badges/settings-badge/helpers';

import { getCalculatedAppsBadge, getCalculatedTeamBadge } from './selectors';

export type BadgeValue = number | 'alert' | 'dot' | null;

export const useBadgeCounter = () => {
  const chatsBadge = useSelector(getNotificationsChatsCount);
  const engageBadge = useSelector(getQueuedIds).length;
  const ticketsBadge = useSelector((state) => getNavigationItemBadge(state, NavigationId.Tickets)?.numeric || 0);
  const teamBadge = useSelector(getCalculatedTeamBadge);
  const automateBadge = useSelector(getShouldShowAutomateTooltip) ? 'dot' : null;
  const reportsBadge = useSelector(getBadgesCount);
  const appsBadge = useSelector(getCalculatedAppsBadge);
  const settingsBadge = useSelector(shouldShowSettingsBadge) ? 'alert' : null;
  const subscriptionBadge = useSelector(getSubscriptionIsPastDue) ? 'alert' : null;

  const getBadgeValueById = useCallback(
    (badgeId?: NavigationId) => {
      switch (badgeId) {
        case NavigationId.Chats:
          return chatsBadge;
        case NavigationId.Engage:
          return engageBadge;
        case NavigationId.Tickets:
          return ticketsBadge;
        case NavigationId.Team:
          return teamBadge;
        case NavigationId.Automate:
          return automateBadge;
        case NavigationId.Reports:
          return reportsBadge;
        case NavigationId.Apps:
          return appsBadge;
        case NavigationId.Settings:
          return settingsBadge;
        case NavigationId.Subscription:
          return subscriptionBadge;
        default:
          return null;
      }
    },
    [
      chatsBadge,
      engageBadge,
      ticketsBadge,
      teamBadge,
      automateBadge,
      reportsBadge,
      appsBadge,
      settingsBadge,
      subscriptionBadge,
    ],
  );

  return getBadgeValueById;
};
