import { createAction } from 'store/helper';

import { type PlaySoundPayload } from '../../../interfaces/sound';

export enum NotificationsActionsNames {
  PLAY_SOUND = 'FEATURES/SYSTEM_NOTIFICATIONS/PLAY_SOUND',
  STOP_REPEAT_SOUND = 'FEATURES/SYSTEM_NOTIFICATIONS/STOP_REPEAT_SOUND',
}

export const SoundNotificationActions = {
  playSound(notificationOptions: PlaySoundPayload) {
    return createAction(NotificationsActionsNames.PLAY_SOUND, notificationOptions);
  },

  stopRepeatSound() {
    return createAction(NotificationsActionsNames.STOP_REPEAT_SOUND);
  },
};
