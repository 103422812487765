import { useSelector } from 'react-redux';

import { type IApplication } from 'store/entities/applications/interfaces';
import {
  getFilteredInstalledAndPrivateSortedApplications,
  getCustomApplicationsOrder,
} from 'store/entities/applications/selectors';

import { sortByCustomApplicationOrder } from '../helpers';

export const useInstalledOrderedApps = (): IApplication[] => {
  const installedApplications = useSelector(getFilteredInstalledAndPrivateSortedApplications);
  const applicationsOrder = useSelector(getCustomApplicationsOrder);

  return installedApplications.sort(sortByCustomApplicationOrder(applicationsOrder));
};
