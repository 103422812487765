import { type SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { contactSubscriptionOwnerSaga } from './contact-subscription-owner';
import { createSubscriptionSaga } from './create-subscription';
import { fetchSubscriptionSaga } from './fetch-subscription';
import { recommendedPlanSaga } from './recommended-plan';
import { refreshSubscriptionSaga } from './refresh-subscription';
import { requestSubscriptionDowngradeSaga } from './request-subscription-downgrade';
import { resetSubscriptionStateSaga } from './reset-subscription-state';
import { toggleTrialExpiringTopBarSaga } from './toggle-trial-expiring-top-bar';
import { updateSubscriptionSaga } from './update-subscription';
import { upgradeSubscriptionSaga } from './upgrade-subscription';

export function* subscriptionSagas(): SagaIterator {
  yield all([
    fork(contactSubscriptionOwnerSaga),
    fork(createSubscriptionSaga),
    fork(fetchSubscriptionSaga),
    fork(refreshSubscriptionSaga),
    fork(recommendedPlanSaga),
    fork(requestSubscriptionDowngradeSaga),
    fork(resetSubscriptionStateSaga),
    fork(toggleTrialExpiringTopBarSaga),
    fork(updateSubscriptionSaga),
    fork(upgradeSubscriptionSaga),
  ]);
}
