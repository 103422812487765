import { type IOutboxMessage } from './outbox-message';
import { createOutboxMessage } from './outbox-message-factory';
import { OutboxMessageTitles } from './outbox-message-titles';

interface IPageData {
  queryParams: object;
}

interface IPageDataMessage extends IOutboxMessage {
  readonly title: OutboxMessageTitles.PageData;
  readonly data: IPageData;
}

export function createPageDataMessage(pluginId: string, urlSearchParams: URLSearchParams): IPageDataMessage {
  let queryParams = {};

  if (urlSearchParams.size > 0) {
    queryParams = Object.fromEntries(urlSearchParams.entries());
  }

  return createOutboxMessage<IPageDataMessage>(OutboxMessageTitles.PageData, pluginId, { queryParams });
}
