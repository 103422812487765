import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import type {
  IApiFetchWebhooks,
  IApiRemoveWebhooks,
  IApiAddWebhook,
  IApiWebhookPayload,
} from './interfaces';

export class WebhooksApi extends BaseApi {
  protected readonly prefix = 'webhooks';

  fetch = (): Promise<RequestResult<IApiFetchWebhooks>> => this.get();
  remove = (id: string): Promise<RequestResult<IApiRemoveWebhooks>> => this.delete(id);
  add = (payload: IApiAddWebhook): Promise<RequestResult<IApiWebhookPayload>> => this.post('', { ...payload });
}
