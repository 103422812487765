/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-strict-ignore
import findLastIndex from 'lodash.findlastindex';
import isEmpty from 'lodash.isempty';
import createCachedSelector, { LruObjectCache } from 're-reselect';
import { createSelector } from 'reselect';

import automaticMessageSvg from 'assets/img/automatic-message.svg';
import { AgentType } from 'constants/agent-type';
import { ChatEventStatus } from 'constants/chat-event-status';
import { ChatEventSubType, ChatEventType } from 'constants/chat-event-type';
import { ChatType } from 'constants/chat-type';
import { AUTOMATIC_MESSAGE, DEFAULT_AGENT_NAME, DEFAULT_VISITOR_NAME } from 'constants/localization';
import { SortOrder, type SortOrderType } from 'constants/sort-order';
import { SurveyType } from 'constants/survey-type';
import { UserType } from 'constants/user-type';
import { anyToBoolean } from 'helpers/boolean';
import { AGENT_CHAT_CHANNELS, ChatChannel, getChatChannelByClientId } from 'helpers/chat-channels';
import { getDefaultMessageForEventType } from 'helpers/chat-events';
import { type GroupedEventsIds } from 'helpers/chat-events-groups';
import { getStatusFromFeatureFlag } from 'helpers/get-status-from-feature-flag';
import { type KeyMap } from 'helpers/interface';
import { getSystemMessageString } from 'helpers/system-messages';
import { getImageWithCDN } from 'helpers/url';
import { type ITextEnhancementError } from 'hooks/text-enhancements/interfaces';
import type { ChatEvent, ChatEventsGroupAuthorData } from 'interfaces/chat/event';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import { GroupProperty } from 'interfaces/entities/group-property';
import { type IStoreState } from 'interfaces/store/store-state';
import { type ReplySuggestionsSuggestionError } from 'services/connectivity/ml-gateway-api/ai-engine/types';
import { getLoggedInAgent, getLoggedInAgentLogin, type WithAgentsState } from 'store/entities/agents/selectors';
import { getInstalledApplicationByClientId } from 'store/entities/applications/selectors';
import type { IWithBotsState } from 'store/entities/bots/selectors';
import {
  isClosedThread,
  isEventWithAuthor,
  isEventWithStatus,
  isEventWithText,
  isMessage,
  isMyChat,
  isOtherChat,
  isPrivateMessage,
  isRichMessage,
  isSupervisedChat,
  isSurvey,
  isSystemMessage,
  isUnassignedChat,
} from 'store/entities/chats/helpers/common';
import {
  type ChatEventEntity,
  type ChatThreadEntity,
  type IAttachmentMessage,
  type IChatHistoryThreadEntity,
  type IMessage,
  type IRichMessage,
  type ISurveyMessage,
  type ISystemMessage,
  type IWithAuthorEvent,
} from 'store/entities/chats/interfaces';
import {
  getAgentSneakPeek,
  getAllChats,
  getAnotherActiveThreadWithOtherAgent,
  getChatClientId,
  getChatIdByThreadId,
  getChatType,
  getEventAuthorName,
  getHistoryThread,
  getHistoryThreadEvent,
  getHistoryThreadGroupedEventsIds,
  getHistoryThreadsOrder,
  getIsChatSupervised,
  getIsQueuedChatVisuallyClosed,
  getIsUnassignedThread,
  getMyChatsIds,
  getOtherAgentsChatsIds,
  getQueuedIds,
  getSneakPeek,
  getSupervisedIds,
  getThread,
  getThreadAgentId,
  getThreadEvent,
  getThreadEvents,
  getThreadGroupedEventsIds,
  getThreadType,
  getUnassignedIds,
  type IWithChatsEntityState,
} from 'store/entities/chats/selectors';
import { type Source } from 'store/entities/copilot/interfaces';
import { getCustomerAvatarUrl, type IWithCustomersEntityState } from 'store/entities/customers/selectors';
import { getGroupProperty, type IWithGroupPropertiesState } from 'store/entities/group-properties/selectors';
import { type IWithGroupsState } from 'store/entities/groups/selectors';
import { type IIntegrationLicensePropertiesState } from 'store/entities/integration-license-properties/interfaces';
import { getReplySuggestionsStatus } from 'store/entities/integration-license-properties/selectors';
import {
  AgentCustomPropertyName,
  ChatListMode,
  PersistedChatsSortType,
  type IAgentCustomPropertiesState,
} from 'store/features/agent-custom-properties/interfaces';
import {
  getAgentCustomProperties,
  getAgentCustomProperty,
  type IWithAgentCustomPropertiesState,
} from 'store/features/agent-custom-properties/selectors';
import type { ICodeInstallationState } from 'store/features/code-installation/interfaces';
import type { IRoutingState } from 'store/features/routing/interfaces';
import type { ISessionState } from 'store/features/session/interfaces';
import {
  getCanTakeOverChat,
  getCanUseTags,
  isAtLeastTeamPlan,
  type IWithAgentsSessionState,
  type IWithSessionState,
} from 'store/features/session/selectors';
import { getAgentOrBot } from 'store/views/team/computed';

import { getUnreadEventsIds, getUnseenEventsIds } from './helpers/sagas';
import {
  compareQueuedChatListItems,
  compareStartedChatListItems,
  compareSupervisedChatListItems,
  getPrevOrNextThreadIdIfExist,
} from './helpers/selectors';
import {
  type IChatsViewState,
  type IChatTagsOperations,
  type IMyChatListItem,
  type IQueuedListItem,
  type ISupervisedListItem,
  type ITextEnhancementsToolbarState,
  type IUnassignedListItem,
} from './interfaces';

const EMPTY_ARRAY: string[] = [];
const EMPTY_ARRAY_OF_ARRAYS: string[][] = [];
const EMPTY_OBJECT = {};

const EVENT_TYPES_WITH_TEXT_EXCLUDING_SYSTEM_MESSAGE = [
  ChatEventType.Message,
  ChatEventType.RichMessage,
  ChatEventType.Attachmment,
];

const EVENT_TYPES_WITH_TEXT = [...EVENT_TYPES_WITH_TEXT_EXCLUDING_SYSTEM_MESSAGE, ChatEventType.Event];

type ChatHistoricalEventEntity = ChatEventEntity & {
  historicalThreadId: string;
};

type LastEvent = IMessage | IAttachmentMessage | IRichMessage | ISystemMessage | ISurveyMessage;

type ChatsEntityStateWithAgentCustomProperties = IWithChatsEntityState & IWithAgentCustomPropertiesState;
export interface IWithChatsViewState extends ChatsEntityStateWithAgentCustomProperties {
  views: {
    chats: IChatsViewState;
  };
  features: {
    agentCustomProperties: IAgentCustomPropertiesState;
    codeInstallation: ICodeInstallationState;
    session: ISessionState;
    routing?: IRoutingState;
  };
}

const getUnassignedSortType = (state: IWithChatsViewState & IWithAgentCustomPropertiesState): SortOrderType => {
  return getChatsSortType(state, ChatType.Unassigned);
};

export const getUnassigned = createSelector(
  getAllChats,
  getUnassignedIds,
  getUnassignedSortType,
  (allChats, unassignedIds, sortType): IUnassignedListItem[] => {
    const sortedUnassigned = unassignedIds.map((id) => allChats[id] as IUnassignedListItem);

    if (sortType === SortOrder.Desc) {
      return sortedUnassigned.reverse();
    }

    return sortedUnassigned;
  },
);

const getMyChatsSortType = (state: IWithChatsViewState & IWithAgentCustomPropertiesState): SortOrderType => {
  return getChatsSortType(state, ChatType.My);
};

const getQueuedSortType = (state: IWithChatsViewState & IWithAgentCustomPropertiesState): SortOrderType => {
  return getChatsSortType(state, ChatType.Queued);
};

export const getMyChats = createSelector(
  getAllChats,
  getMyChatsIds,
  getMyChatsSortType,
  (allChats, myChatIds, sortType): IMyChatListItem[] => {
    const shouldReverseMyChats = sortType === SortOrder.Desc;

    const sortedMyChats = myChatIds
      .map((id) => allChats[id] as IMyChatListItem)
      .sort((a, b) => compareStartedChatListItems(a, b, shouldReverseMyChats));

    if (shouldReverseMyChats) {
      return sortedMyChats.reverse();
    }

    return sortedMyChats;
  },
);

/**
 * @deprecated Avoid using reselect for frequently changed data.
 */
export const getQueued = createSelector(
  getAllChats,
  getQueuedIds,
  getQueuedSortType,
  (allChats, queuedIds, sortType): IQueuedListItem[] => {
    const sortedQueuedChats = queuedIds.map((id) => allChats[id] as IQueuedListItem).sort(compareQueuedChatListItems);

    if (sortType === SortOrder.Desc) {
      return sortedQueuedChats.reverse();
    }

    return sortedQueuedChats;
  },
);

export const getSupervised = createSelector(
  getAllChats,
  getSupervisedIds,
  (allChats, supervisedIds): ISupervisedListItem[] =>
    supervisedIds.map((id) => allChats[id] as ISupervisedListItem).sort(compareSupervisedChatListItems),
);

export function getIsChatSelected(state: IWithChatsViewState, threadId: string): boolean {
  return state.views.chats.selectedId === threadId;
}

export function getSelectedThreadId(state: IWithChatsViewState): string {
  return state?.views?.chats?.selectedId;
}

function getVisitedThreadIds(state: IWithChatsViewState): string[] {
  return state?.views?.chats?.visitedThreadIds;
}

export function getIsThreadAlreadyVisited(state: IWithChatsViewState, threadId: string): boolean {
  const visitedThreadIds = getVisitedThreadIds(state);

  return visitedThreadIds.includes(threadId);
}

export function getSelectedThread(state: IWithChatsViewState): ChatThreadEntity {
  const threadId = getSelectedThreadId(state);

  return threadId ? getThread(state, threadId) : null;
}

export const getThreadGroups = createSelector(getThread, (thread) => thread?.groupIds);

export function getSelectedThreadChatId(state: IWithChatsViewState): string {
  const thread = state.entities.chats.threads[getSelectedThreadId(state)];
  if (thread) {
    return thread.chatId;
  }

  return null;
}

export function getSelectedThreadCustomerId(state: IWithChatsViewState): string {
  const thread = state.entities.chats.threads[getSelectedThreadId(state)];
  if (thread) {
    return thread.customerId;
  }

  return null;
}

export function getThreadTags(state: IWithChatsViewState, threadId: string): string[] {
  return state.entities.chats.tags.chatTags[threadId] || EMPTY_ARRAY;
}

function getThreadTagsOperations(state: IWithChatsViewState, threadId: string): IChatTagsOperations {
  const tagsOperations = state.views.chats.tagsOperations[threadId];

  if (!tagsOperations) {
    return null;
  }

  return tagsOperations;
}

export function getThreadTagsProcessing(state: IWithChatsViewState, threadId: string): string[] {
  const tagsOperations = getThreadTagsOperations(state, threadId);

  return tagsOperations?.inProgress || EMPTY_ARRAY;
}

export function getThreadTagsFailure(state: IWithChatsViewState, threadId: string): string[] {
  const tagsOperations = getThreadTagsOperations(state, threadId);

  return tagsOperations?.failure || EMPTY_ARRAY;
}

export function getIsCustomerMarkedAsAwaitingNavigation(state: IWithChatsViewState, customerId: string): boolean {
  return state.views.chats.customerIdAwatingNavigation === customerId;
}

export function getIsTyping(state: IWithChatsViewState, threadId: string): boolean {
  return !!getSneakPeek(state, threadId);
}

export function getIsAgentTyping(state: IWithChatsViewState, threadId: string): boolean {
  return !!getAgentSneakPeek(state, threadId);
}

function getIsAnyCustomerChatsAvailable(state: IWithChatsViewState): boolean {
  const chats = getAllChats(state);
  const otherAgentsChatIds = getOtherAgentsChatsIds(state);

  return Object.keys(chats).length > otherAgentsChatIds.length;
}

export function getAnyChatsAvailable(
  state: IWithChatsViewState & IWithSessionState & IIntegrationLicensePropertiesState,
): boolean {
  return getIsAnyCustomerChatsAvailable(state);
}

export function getChatsLoaded(state: IWithChatsViewState): boolean {
  return state.entities.chats.initialized;
}

export function getNextThreadToSelect(state: IWithChatsViewState): string | null {
  const selectedThreadId = getSelectedThreadId(state);
  const allChats = getUnassigned(state).concat(getMyChats(state), getQueued(state) as any, getSupervised(state));
  const nextThreadId = getPrevOrNextThreadIdIfExist(allChats, selectedThreadId, 'next');

  return nextThreadId || null;
}

export function getPreviousThreadToSelect(state: IWithChatsViewState): string | null {
  const selectedThreadId = getSelectedThreadId(state);
  const allChats = getUnassigned(state).concat(getMyChats(state), getQueued(state) as any, getSupervised(state));
  const previousThreadId = getPrevOrNextThreadIdIfExist(allChats, selectedThreadId, 'previous');

  return previousThreadId || null;
}

export function getFirstAvailableChatThreadId(
  state: IWithChatsViewState & IWithSessionState & IIntegrationLicensePropertiesState,
): string | null {
  if (!getAnyChatsAvailable(state)) {
    return null;
  }

  const unassignedChats = getUnassigned(state);
  if (unassignedChats.length > 0) {
    return unassignedChats[0].threadId;
  }

  const myChats = getMyChats(state);
  if (myChats.length > 0) {
    return myChats[0].threadId;
  }

  const queuedChats = getQueued(state);
  if (queuedChats.length > 0) {
    return queuedChats[0].threadId;
  }

  const supervisedChats = getSupervised(state);
  if (supervisedChats.length > 0) {
    return supervisedChats[0].threadId;
  }

  return null;
}

export function getSelectedOrFirstAvailableThreadId(
  state: IWithChatsViewState & IWithSessionState & IIntegrationLicensePropertiesState,
): string {
  const selectedThread = getSelectedThread(state);

  return selectedThread && selectedThread.type !== ChatType.Other
    ? selectedThread.threadId
    : getFirstAvailableChatThreadId(state);
}

export function getFirstMyChatThreadId(state: IWithChatsViewState): string {
  const myChats = getMyChats(state);

  return myChats[0]?.threadId;
}

export function getFirstQueueThreadId(state: IWithChatsViewState): string {
  const queuedIds = getQueuedIds(state);

  return queuedIds[0];
}

export function getThreadIdToSelectAfterThreadClose(state: IWithChatsViewState, threadId: string): string {
  const unassignedChats = getUnassigned(state);
  const myChats = getMyChats(state);
  const queuedChats = getQueued(state);
  const supervisedChatIds = getSupervised(state);
  const threadType = getThreadType(state, threadId);
  let shouldJumpToNextSection = false;
  let threadIdToSelect: string = null;

  if (threadType === ChatType.Unassigned) {
    threadIdToSelect = getPrevOrNextThreadIdIfExist(unassignedChats, threadId, 'previous');

    if (threadIdToSelect) {
      return threadIdToSelect;
    }

    shouldJumpToNextSection = true;
  }

  if (threadType === ChatType.My || shouldJumpToNextSection) {
    threadIdToSelect = getPrevOrNextThreadIdIfExist(myChats, threadId, 'previous');

    if (threadIdToSelect) {
      return threadIdToSelect;
    }

    shouldJumpToNextSection = true;
  }

  if (threadType === ChatType.Queued || shouldJumpToNextSection) {
    threadIdToSelect = getPrevOrNextThreadIdIfExist(queuedChats, threadId, 'previous');

    if (threadIdToSelect) {
      return threadIdToSelect;
    }

    shouldJumpToNextSection = true;
  }

  if (threadType === ChatType.Supervised || shouldJumpToNextSection) {
    threadIdToSelect = getPrevOrNextThreadIdIfExist(supervisedChatIds, threadId, 'previous');

    if (threadIdToSelect) {
      return threadIdToSelect;
    }
  }

  threadIdToSelect = getPrevOrNextThreadIdIfExist(
    unassignedChats.concat(myChats, queuedChats as any, supervisedChatIds),
    threadId,
    'previous',
  );

  if (threadIdToSelect) {
    return threadIdToSelect;
  }

  return null;
}

export function getChatLastEvent(
  state: IWithChatsViewState,
  threadId: string,
  eventTypes?: ChatEventType[],
): ChatEventEntity {
  const threadGroupedEventsIds = getThreadGroupedEventsIds(state, threadId);
  const groupsCount = threadGroupedEventsIds.length;

  if (groupsCount === 0) {
    return null;
  }

  let groupIndex = groupsCount - 1;
  let lastEvent: ChatEventEntity = null;

  do {
    const lastGroup = threadGroupedEventsIds[groupIndex];
    if (!lastGroup) {
      break;
    }
    const lastEventId = lastGroup[lastGroup.length - 1];

    const event = getThreadEvent(state, threadId, lastEventId);

    if (event) {
      if (!eventTypes || eventTypes.includes(event.type)) {
        lastEvent = event;
      } else {
        groupIndex -= 1;
      }
    } else {
      break;
    }
  } while (!lastEvent);

  return lastEvent;
}

/**
 * Function is looping backward all historical threads and their events to find last event with specific type
 * Once function find last event id it will end looping
 * @param state The state
 * @param threadId ThreadID
 * @param eventTypes Unique type of events which we want to find
 */
export function getChatHistoricalLastEvent(
  state: IWithChatsViewState,
  threadId: string,
  eventTypes?: ChatEventType[],
): ChatHistoricalEventEntity {
  const historicalThreadsOrder = getHistoryThreadsOrder(state, threadId);

  if (!historicalThreadsOrder.length) {
    return null;
  }

  let threadIdIndex = historicalThreadsOrder.length - 1;
  let lastEvent: ChatHistoricalEventEntity = null;
  let historicalThreadId = null;

  do {
    historicalThreadId = historicalThreadsOrder[threadIdIndex];

    if (!historicalThreadId) {
      return null;
    }

    const historyThreadGroupedEventsIds = getHistoryThreadGroupedEventsIds(state, threadId, historicalThreadId);
    const groupsCount = historyThreadGroupedEventsIds.length;
    let groupIndex = groupsCount - 1;

    do {
      if (groupsCount === 0) {
        break;
      }

      const lastGroup = historyThreadGroupedEventsIds[groupIndex];
      if (!lastGroup) {
        break;
      }

      const lastEventId = lastGroup[lastGroup.length - 1];
      const event = getHistoryThreadEvent(state, threadId, historicalThreadId, lastEventId);
      if (event) {
        if (!eventTypes || eventTypes.includes(event.type)) {
          lastEvent = { ...event, historicalThreadId };
        } else {
          groupIndex -= 1;
        }
      } else {
        break;
      }
    } while (!lastEvent);

    threadIdIndex -= 1;
  } while (threadIdIndex >= 0 && !lastEvent);

  return lastEvent;
}

export function getNumberOfCustomerMessagesInThread(
  state: IWithChatsViewState & WithAgentsState,
  threadId: string,
): number {
  const events = getThreadEvents(state, threadId) as Record<string, ChatEvent>;

  return Object.values(events).filter(
    ({ authorType, type }) => authorType === UserType.Customer && type === ChatEventType.Message,
  ).length;
}

function getLastEvent(state: IWithChatsViewState & WithAgentsState, threadId: string): LastEvent {
  const isUnassignedChatThread = getIsUnassignedThread(state, threadId);

  return (
    getChatLastEvent(
      state,
      threadId,
      isUnassignedChatThread ? EVENT_TYPES_WITH_TEXT_EXCLUDING_SYSTEM_MESSAGE : EVENT_TYPES_WITH_TEXT,
    ) ||
    getChatHistoricalLastEvent(
      state,
      threadId,
      isUnassignedChatThread ? EVENT_TYPES_WITH_TEXT_EXCLUDING_SYSTEM_MESSAGE : EVENT_TYPES_WITH_TEXT,
    )
  );
}

export function getChatLastEventText(
  state: IWithChatsViewState & WithAgentsState & IWithBotsState,
  threadId: string,
): string {
  const lastEvent = getLastEvent(state, threadId);

  if (lastEvent) {
    const authorName = getEventAuthorName(
      state,
      threadId,
      lastEvent.id,
      (lastEvent as ChatHistoricalEventEntity)?.historicalThreadId,
    );

    if (isEventWithText(lastEvent)) {
      if (isSystemMessage(lastEvent)) {
        if (lastEvent.subType === ChatEventSubType.RateCommentedOnly) {
          return `${lastEvent.text} ${lastEvent.textVariables.comment}`;
        }

        return getSystemMessageString(lastEvent.text, lastEvent.subType, lastEvent.textVariables);
      }

      if (lastEvent.text) {
        if (isMessage(lastEvent) && lastEvent.authorType === UserType.Customer && lastEvent.translation) {
          return lastEvent.translation.targetMessage;
        }

        return lastEvent.text;
      }
    }

    return getDefaultMessageForEventType(lastEvent.type, authorName);
  }

  return null;
}

/**
 * Returns all unread events for the thread.
 */
export const getUnreadAllEventsCount = createCachedSelector(
  getThreadEvents,
  getLoggedInAgentLogin,
  (threadEvents, loggedInAgentLogin): number => {
    const unreadMessages = Object.values(threadEvents).filter(
      (event: ChatEventEntity): boolean =>
        isEventWithAuthor(event) &&
        isEventWithStatus(event) &&
        !event.wasSeen &&
        (event.authorType === UserType.Customer || event.authorId !== loggedInAgentLogin),
    );

    return unreadMessages.length;
  },
)((_state, threadId): string => threadId, {
  cacheObject: new LruObjectCache({ cacheSize: 50 }),
});

export const getUnreadSupervisorEventsCount = createCachedSelector(
  getThreadEvents,
  getLoggedInAgentLogin,
  (threadEvents, loggedInAgentLogin): number => {
    if (isEmpty(threadEvents)) {
      return 0;
    }

    const unreadMessages = Object.values(threadEvents).filter(
      (event: ChatEventEntity): boolean =>
        isEventWithAuthor(event) &&
        isEventWithStatus(event) &&
        event.authorType === UserType.Supervisor &&
        !event.wasSeen &&
        event.authorId !== loggedInAgentLogin,
    );

    return unreadMessages.length;
  },
)((_state, threadId): string => threadId, {
  cacheObject: new LruObjectCache({ cacheSize: 50 }),
});

export const getUnreadAgentEventsCount = createCachedSelector(
  getThreadEvents,
  getLoggedInAgentLogin,
  (threadEvents, loggedInAgentLogin): number => {
    if (isEmpty(threadEvents)) {
      return 0;
    }

    const unreadMessages = Object.values(threadEvents).filter(
      (event: ChatEventEntity): boolean =>
        isEventWithAuthor(event) &&
        isEventWithStatus(event) &&
        (event.authorType === UserType.Agent || event.authorType === UserType.BotAgent) &&
        !event.wasSeen &&
        event.authorId !== loggedInAgentLogin,
    );

    return unreadMessages.length;
  },
)((_state, threadId): string => threadId, {
  cacheObject: new LruObjectCache({ cacheSize: 50 }),
});

export const getUnreadCustomerEventsCount = createCachedSelector(
  getThreadEvents,
  getLoggedInAgentLogin,
  (threadEvents) => {
    if (isEmpty(threadEvents)) {
      return 0;
    }

    const unreadMessages = Object.values(threadEvents).filter(
      (event: ChatEventEntity) =>
        isEventWithAuthor(event) &&
        isEventWithStatus(event) &&
        event.authorType === UserType.Customer &&
        !event.wasSeen,
    );

    return unreadMessages.length;
  },
)((_state, threadId) => threadId, {
  cacheObject: new LruObjectCache({ cacheSize: 50 }),
});

/**
 * Returns timestamp of a first message for a last group of customer messages.
 * If the last message is provided by non-customer, then a null is returned.
 */
export const getCustomerWaitingForResponseTimestampInMs = createCachedSelector(getThreadEvents, (threadEvents) => {
  if (isEmpty(threadEvents)) {
    return null;
  }

  const authorTypesRemovingImportance = [UserType.Agent, UserType.BotAgent];
  const events = Object.values(threadEvents)
    .filter(
      (event: ChatEventEntity) =>
        isEventWithAuthor(event) &&
        event.authorType !== UserType.Supervisor &&
        (!isSurvey(event) || event.surveyType !== SurveyType.Pre),
    )
    .sort((a, b) => a.timestampInMs - b.timestampInMs);
  const lastEventByAgentIndex = findLastIndex(events, (event: ChatEventEntity) => {
    if (isEventWithAuthor(event)) {
      return authorTypesRemovingImportance.includes(event.authorType);
    }

    return false;
  });

  // Agent message is the last one;
  if (lastEventByAgentIndex === events.length - 1) {
    return null;
  }

  return events[lastEventByAgentIndex + 1].timestampInMs;
})((_state, threadId) => threadId, {
  cacheObject: new LruObjectCache({ cacheSize: 50 }),
});

export function isEventsGroupASystemEvent(
  state: IWithChatsViewState,
  threadId: string,
  eventsGroupIds: string[],
  historyThreadId?: string,
): boolean {
  const eventId = eventsGroupIds[0];
  let event;

  if (historyThreadId) {
    event = getHistoryThreadEvent(state, threadId, historyThreadId, eventId);
  } else {
    event = getThreadEvent(state, threadId, eventId);
  }

  return Boolean(event && event.type === ChatEventType.Event);
}

export function isEventsGroupASurveyEvent(
  state: IWithChatsViewState,
  threadId: string,
  eventsGroupIds: string[],
  historyThreadId?: string,
): boolean {
  const eventId = eventsGroupIds[0];
  let event: ChatEventEntity | IChatHistoryThreadEntity;

  if (historyThreadId) {
    event = getHistoryThreadEvent(state, threadId, historyThreadId, eventId);
  } else {
    event = getThreadEvent(state, threadId, eventId);
  }

  return Boolean(event?.type === ChatEventType.FilledForm);
}

export function getChatEventsGroupAuthorData(
  state: IWithChatsEntityState & WithAgentsState & IWithBotsState & IWithCustomersEntityState,
  threadId: string,
  firstEventInGroup: IWithAuthorEvent,
  historyThreadId?: string,
): ChatEventsGroupAuthorData {
  const initialState = {
    authorId: '',
    authorName: '',
    authorAvatarUrl: '',
    authorType: UserType.Agent,
    isOwn: true,
  };
  let thread: ChatThreadEntity | IChatHistoryThreadEntity;

  if (historyThreadId) {
    thread = getHistoryThread(state, threadId, historyThreadId);
  } else {
    thread = getThread(state, threadId);
  }

  if (!thread || !firstEventInGroup) {
    return initialState;
  }

  const { authorId, authorType } = firstEventInGroup;

  if (authorType === UserType.Customer) {
    return {
      authorId,
      authorName: thread.customerName || DEFAULT_VISITOR_NAME,
      authorAvatarUrl: getCustomerAvatarUrl(state, authorId, threadId),
      authorType: UserType.Customer,
      isOwn: false,
    };
  }

  if (authorType === UserType.Automatic) {
    return {
      authorId,
      authorName: AUTOMATIC_MESSAGE,
      authorAvatarUrl: automaticMessageSvg,
      authorType: UserType.Agent,
      isOwn: true,
    };
  }

  const agent = getAgentOrBot(state, authorId);

  let authorAvatarUrl = agent?.avatar ? getImageWithCDN(agent.avatar) : null;
  let authorName = agent?.name;
  const botOrAgentType = agent?.type === AgentType.Bot ? UserType.BotAgent : UserType.Agent;
  const agentAuthorType = authorType === UserType.Supervisor ? UserType.Supervisor : botOrAgentType;

  if (!authorAvatarUrl || !authorName) {
    const chatId = getChatIdByThreadId(state, threadId);
    const chatUser = state.entities.chats.chatsUsers[chatId]?.find(({ id, avatar }) => id === authorId && !!avatar);

    if (!authorAvatarUrl && chatUser) {
      authorAvatarUrl = getImageWithCDN(chatUser.avatar);
    }

    if (!authorName) {
      authorName = chatUser?.name || DEFAULT_AGENT_NAME;
    }
  }

  return {
    authorName,
    authorId,
    authorAvatarUrl,
    authorType: agentAuthorType,
    isOwn: true,
  };
}

export function shouldAppendSneakPeekToGroup(
  state: IWithChatsEntityState & IWithChatsViewState,
  threadId: string,
  eventsIds: string[],
  isAgentSneakPeek?: boolean,
): boolean {
  const isTyping = isAgentSneakPeek ? getIsAgentTyping(state, threadId) : getIsTyping(state, threadId);
  const authorType = isAgentSneakPeek ? UserType.Agent : UserType.Customer;

  if (!isTyping || !eventsIds) {
    return false;
  }

  const firstEventInGroup = getThreadEvent(state, threadId, eventsIds[0]);

  if (!firstEventInGroup) {
    return false;
  }

  if (isSystemMessage(firstEventInGroup) || isRichMessage(firstEventInGroup) || isSurvey(firstEventInGroup)) {
    return false;
  }

  if (firstEventInGroup.authorType === authorType) {
    return true;
  }

  return false;
}

export function getThreadAgentName(
  state: IWithChatsEntityState & WithAgentsState & IWithBotsState,
  threadId: string,
): string {
  const thread = getThread(state, threadId);

  if (thread) {
    const threadAgent = getAgentOrBot(state, thread.currentAgentId);

    return threadAgent ? threadAgent.name : null;
  }

  return null;
}

export function getThreadAgentAvatar(
  state: IWithChatsEntityState & WithAgentsState & IWithBotsState,
  threadId: string,
): string {
  const thread = getThread(state, threadId);

  if (!thread) {
    return null;
  }

  const threadAgent = getAgentOrBot(state, thread.currentAgentId);

  return threadAgent?.avatar || null;
}

export function hasDisplayedTagReminder(state: IWithChatsEntityState & IWithChatsViewState, threadId: string): boolean {
  const tagsOperations = getThreadTagsOperations(state, threadId);

  return Boolean(tagsOperations?.tagReminderDisplayed);
}

export function shouldDisplayTagReminder(
  state: IWithChatsEntityState &
    IWithChatsViewState &
    IWithGroupsState &
    IWithAgentsSessionState &
    IWithGroupPropertiesState,
  threadId: string,
  tagsToIgnore?: string[],
): boolean {
  const thread = getThread(state, threadId);

  if (!thread || (!isMyChat(thread) && !isUnassignedChat(thread))) {
    return false;
  }

  const loggedInAgentId = getLoggedInAgentLogin(state);
  if (thread.currentAgentId !== loggedInAgentId && !isUnassignedChat(thread)) {
    return false;
  }

  const groupId = thread.groupIds[0];
  const tags = state.entities.chats.tags.chatTags[threadId] || [];
  const tagsToCheck = tagsToIgnore ? tags.filter((tag) => !tagsToIgnore.includes(tag)) : tags;
  const hasGroupTagReminder = anyToBoolean(getGroupProperty(state, groupId, GroupProperty.AddTagReminder));

  return tagsToCheck.length === 0 && hasGroupTagReminder && !hasDisplayedTagReminder(state, threadId);
}

export function getTotalUnassignedChats(state: IWithChatsEntityState): number {
  return state.entities.chats.totalUnassignedChats;
}

export function getIsFetchingMoreUnassignedChats(state: IWithChatsViewState): boolean {
  return state.views.chats.isFetchingMoreUnassignedChats;
}

export function getDraftMessage(state: IWithChatsViewState, threadId: string): string {
  return state.views.chats.draftMessages[threadId];
}

export function getSelectedCanneds(state: IWithChatsViewState, threadId: string): string[] {
  return state.views.chats.selectedCanneds[threadId];
}

export function getCanUsePrivateModeForIntegrations(state: IWithChatsViewState, threadId: string): boolean {
  const clientId = getChatClientId(state, threadId);
  const channel = getChatChannelByClientId(clientId);
  const application = getInstalledApplicationByClientId(state, clientId);

  const supportedChannels = [
    ...AGENT_CHAT_CHANNELS,
    ChatChannel.ChatWidget,
    ChatChannel.FacebookMessenger,
    ChatChannel.WhatsAppBusiness,
    ChatChannel.Instagram,
    ChatChannel.Telegram,
    ChatChannel.Line,
    ChatChannel.Viber,
  ];

  /**
   * Enables private mode for integrations.
   * If the channel was found or it is among supported ones, or the application does have customProps.channelSupportsPrivateNotes, return `true`.
   * @see https://livechat.slack.com/archives/CRNB0NER2/p1592568351012000
   * @see https://livechatinc.atlassian.net/browse/AA-9423
   */

  if (channel || supportedChannels.includes(channel) || !!application?.customProps?.channelSupportsPrivateNotes) {
    return true;
  }

  return false;
}

export function getPrivateMode(state: IWithChatsViewState, threadId: string): boolean {
  const threadType = getChatType(state, threadId);
  if (threadType === ChatType.Supervised) {
    return true;
  }

  const canUsePrivateModeForIntegrations = getCanUsePrivateModeForIntegrations(state, threadId);

  return canUsePrivateModeForIntegrations ? Boolean(state.views.chats.privateMode[threadId]) : false;
}

export function getIsFetchingHistory(state: IWithChatsViewState, threadId: string): boolean {
  return state.views.chats.isFetchingHistory[threadId] || false;
}

function getSelectedThreadGroupedEventsIds(state: IWithChatsViewState): GroupedEventsIds {
  const selectedThreadId = getSelectedThreadId(state);

  return state.entities.chats.events.groupedIds[selectedThreadId] || EMPTY_ARRAY_OF_ARRAYS;
}

export function getSelectedThreadEvents(state: IWithChatsViewState): KeyMap<ChatEventEntity> {
  const selectedThreadId = getSelectedThreadId(state);

  return state.entities.chats.events.byIds[selectedThreadId] || EMPTY_OBJECT;
}

/**
 * Function is looping backward all events to find all unseen events
 * Once function find last seen event it will end looping and return these
 * @param state The state
 * @return Array of unseen customer events ids
 */
export const getSelectedChatUnseenCustomerEventsIds: (state: IWithChatsEntityState) => string[] = createSelector(
  getSelectedThreadGroupedEventsIds,
  getSelectedThreadEvents,
  (selectedThreadGroupedEventsIds, selectedThreadEvents) => {
    return getUnseenEventsIds(selectedThreadGroupedEventsIds, selectedThreadEvents, [UserType.Customer]);
  },
);

/**
 * Function is looping backward all events to find all unseen events
 * Once function find last seen event it will end looping and return these
 * @param state The state
 * @return Array of unseen customer events ids
 */
export const getChatUnseenCustomerEventsIds: (state: IWithChatsEntityState, threadId: string) => string[] =
  createSelector(getThreadGroupedEventsIds, getThreadEvents, (threadGroupedEventsIds, threadEvents) => {
    return getUnseenEventsIds(threadGroupedEventsIds, threadEvents, [UserType.Customer]);
  });

/**
 * Function is looping backward all events to find all unread events
 * Once function find last unread event it will end looping and return these
 * @param state The state
 * @return Array of unread customer events ids
 */
export const getChatUnreadCustomerEventsIds: (state: IWithChatsEntityState, threadId: string) => string[] =
  createSelector(getThreadGroupedEventsIds, getThreadEvents, (threadGroupedEventsIds, threadEvents) => {
    return getUnreadEventsIds(threadGroupedEventsIds, threadEvents, [UserType.Customer]);
  });

/**
 * Function is looping backward all events to find all unseen events
 * Once function find last seen event it will end looping and return these
 * @param state The state
 * @return Array of unseen agent events ids
 */
export const getSelectedChatUnseenAgentEventsIds: (state: IWithChatsEntityState) => string[] = createSelector(
  getSelectedThreadGroupedEventsIds,
  getSelectedThreadEvents,
  (selectedThreadGroupedEventsIds, selectedThreadEvents) => {
    return getUnseenEventsIds(selectedThreadGroupedEventsIds, selectedThreadEvents, [
      UserType.Agent,
      UserType.BotAgent,
      UserType.Supervisor,
    ]);
  },
);

/**
 * Function is looping backward all events to find all unseen events
 * Once function find last seen event it will end looping and return these
 * @param state The state
 * @return Array of unseen agent events ids
 */
export const getChatUnseenAgentEventsIds: (state: IWithChatsEntityState, threadId: string) => string[] = createSelector(
  getThreadGroupedEventsIds,
  getThreadEvents,
  (threadGroupedEventsIds, threadEvents) => {
    return getUnseenEventsIds(threadGroupedEventsIds, threadEvents, [
      UserType.Agent,
      UserType.BotAgent,
      UserType.Supervisor,
    ]);
  },
);

/**
 * Function is looping backward all events to find all unread events
 * Once function find last unread event it will end looping and return these
 * @param state The state
 * @return Array of unread agent events ids
 */
export const getChatUnreadAgentEventsIds: (state: IWithChatsEntityState, threadId: string) => string[] = createSelector(
  getThreadGroupedEventsIds,
  getThreadEvents,
  (threadGroupedEventsIds, threadEvents) => {
    return getUnreadEventsIds(threadGroupedEventsIds, threadEvents, [
      UserType.Agent,
      UserType.BotAgent,
      UserType.Supervisor,
    ]);
  },
);

/**
 * Returns ids for unseen supervisor events.
 * Function is looping backward all events to find all unseen events
 * Once function find last seen event it will end looping and return these
 * @param state Redux store state.
 * @return Array of unseen supervisor events ids
 */
export const getSelectedChatUnseenSupervisorEventsIds: (state: IWithChatsEntityState) => string[] = createSelector(
  getSelectedThreadGroupedEventsIds,
  getSelectedThreadEvents,
  (selectedThreadGroupedEventsIds, selectedThreadEvents) => {
    return getUnseenEventsIds(selectedThreadGroupedEventsIds, selectedThreadEvents, [UserType.Supervisor]);
  },
);

/**
 * Returns ids for unseen supervisor events.
 * Function is looping backward all events to find all unseen events
 * Once function find last seen event it will end looping and return these
 * @param state Redux store state.
 * @return Array of unseen supervisor events ids
 */
export const getChatUnseenSupervisorEventsIds: (state: IWithChatsEntityState, threadId: string) => string[] =
  createSelector(getThreadGroupedEventsIds, getThreadEvents, (threadGroupedEventsIds, threadEvents) => {
    return getUnseenEventsIds(threadGroupedEventsIds, threadEvents, [UserType.Supervisor]);
  });

/**
 * Returns ids for unread supervisor events.
 * Function is looping backward all events to find all unread events
 * Once function find last unread event it will end looping and return these
 * @param state Redux store state.
 * @return Array of unread supervisor events ids
 */
export const getChatUnreadSupervisorEventsIds: (state: IWithChatsEntityState, threadId: string) => string[] =
  createSelector(getThreadGroupedEventsIds, getThreadEvents, (threadGroupedEventsIds, threadEvents) => {
    return getUnreadEventsIds(threadGroupedEventsIds, threadEvents, [UserType.Supervisor]);
  });

/**
 * Returns last event that satisfies the status condition.
 * Function is looping backward all events to find last event id with specific statuses we pass.
 * Once function find last event id it will end looping.
 * @param state Redux store state.
 * @param threadId Thread id.
 * @param enentStatus Unique statuses of events which we want to find.
 */
function getLastCurrentAgentEventIdOfSpecificStatus(
  state: IWithChatsViewState & IWithSessionState,
  threadId: string,
  eventStatus: ChatEventStatus,
): string {
  const isSupervisor = getIsChatSupervised(state, threadId);
  const threadGroupedEventsIds = getThreadGroupedEventsIds(state, threadId);
  const groupsCount = threadGroupedEventsIds.length;
  const currentAgentId = getLoggedInAgentLogin(state);

  if (groupsCount === 0) {
    return null;
  }

  let groupIndex = groupsCount - 1;

  do {
    const lastEventsGroup = threadGroupedEventsIds[groupIndex];

    if (!lastEventsGroup) {
      break;
    }

    let lastEventInGroupIndex = lastEventsGroup.length - 1;

    do {
      const lastEventInGroupId = lastEventsGroup[lastEventInGroupIndex];
      const event = getThreadEvent(state, threadId, lastEventInGroupId);

      if (event) {
        const isLastAgentEventOfSpecificStatus =
          isEventWithStatus(event) &&
          isEventWithAuthor(event) &&
          !isPrivateMessage(event, currentAgentId) &&
          event.status === eventStatus &&
          event.authorType === UserType.Agent &&
          (event.authorId === currentAgentId || isSupervisor);

        if (isLastAgentEventOfSpecificStatus) {
          return event.id;
        }

        lastEventInGroupIndex -= 1;
      } else {
        break;
      }
    } while (lastEventInGroupIndex >= 0);

    groupIndex -= 1;
  } while (groupIndex >= 0);

  return null;
}

/**
 * Returns last customer event.
 * Function is looping backward all events to find last event id for customer.
 * Once function find last event id it will end looping.
 * @param state Redux store state.
 * @param threadId Thread id.
 */
function getLastCurrentCustomerEventId(state: IWithChatsViewState & IWithSessionState, threadId: string): string {
  const threadGroupedEventsIds = getThreadGroupedEventsIds(state, threadId);
  const groupsCount = threadGroupedEventsIds.length;

  if (groupsCount === 0) {
    return null;
  }

  let groupIndex = groupsCount - 1;

  do {
    const lastEventsGroup = threadGroupedEventsIds[groupIndex];

    if (!lastEventsGroup) {
      break;
    }

    let lastEventInGroupIndex = lastEventsGroup.length - 1;

    do {
      const lastEventInGroupId = lastEventsGroup[lastEventInGroupIndex];
      const event = getThreadEvent(state, threadId, lastEventInGroupId);

      if (event) {
        const isLastAgentEventOfSpecificStatus =
          isEventWithStatus(event) && isEventWithAuthor(event) && event.authorType === UserType.Customer;

        if (isLastAgentEventOfSpecificStatus) {
          return event.id;
        }

        lastEventInGroupIndex -= 1;
      } else {
        break;
      }
    } while (lastEventInGroupIndex >= 0);

    groupIndex -= 1;
  } while (groupIndex >= 0);

  return null;
}

/**
 * Determines if the status should be shown for the event. Currently Agent App shows only the status for
 * events involving currently logged in Agent.
 * @param state Redux store state.
 * @param threadId Thread id that contains the event.
 * @param eventId Id of the event to be analyzed.
 */
export function getShouldShowAgentEventStatus(
  state: IWithChatsEntityState & IWithChatsViewState & IWithSessionState,
  threadId: string,
  eventId: string,
): boolean {
  const event = getThreadEvent(state, threadId, eventId);
  const isSupervisor = getIsChatSupervised(state, threadId);

  if (!isEventWithStatus(event) || event.authorType === UserType.Supervisor) {
    return false;
  }

  const isLastReadEvent = getLastCurrentAgentEventIdOfSpecificStatus(state, threadId, ChatEventStatus.Read) === eventId;
  const isLastDeliveredEvent =
    getLastCurrentAgentEventIdOfSpecificStatus(state, threadId, ChatEventStatus.Delivered) === eventId;
  const isSendInProgress = isMessage(event) && event.status === ChatEventStatus.Pending;
  const isNotDelivered = isMessage(event) && event.status === ChatEventStatus.NotDelivered;
  const isSupervisedEventFromAgent = isSupervisor && (event as IWithAuthorEvent).authorType === UserType.Agent;
  const qualifiedEvent = isLastDeliveredEvent || isLastReadEvent || isSendInProgress || isNotDelivered;
  const shouldShowForSupervisor = isSupervisedEventFromAgent && qualifiedEvent;

  return qualifiedEvent || shouldShowForSupervisor;
}

/**
 * Determines if the status should be shown for the event. Currently Agent App shows only the status for
 * events involving currently logged in Agent.
 * @param state Redux store state.
 * @param threadId Thread id that contains the event.
 * @param eventId Id of the event to be analyzed.
 */
export function getShouldShowCustomerEventStatus(
  state: IWithChatsEntityState & IWithChatsViewState & IWithSessionState,
  threadId: string,
  eventId: string,
): boolean {
  const event = getThreadEvent(state, threadId, eventId);

  if (!isEventWithStatus(event) || event.authorType === UserType.Supervisor) {
    return false;
  }

  const isLastCustomerEvent = getLastCurrentCustomerEventId(state, threadId) === eventId;

  return isLastCustomerEvent;
}

export function getIsChatMarkedAsNew(state: IWithChatsViewState, threadId: string): boolean {
  return state.views.chats.newChatThreadIds.includes(threadId);
}

export function hasUnseenMessages(state: IWithChatsViewState & IWithAgentsSessionState, threadId: string): boolean {
  const event = getChatLastEvent(state, threadId, [
    ChatEventType.Message,
    ChatEventType.RichMessage,
    ChatEventType.Attachmment,
  ]);
  const loggedInUserLogin = getLoggedInAgentLogin(state);

  if (!event) {
    return false;
  }

  if (isEventWithStatus(event) && isEventWithAuthor(event) && event.authorId !== loggedInUserLogin) {
    return !event.wasSeen;
  }

  return false;
}

export function getChatScrollPosition(state: IWithChatsViewState, threadId: string): number {
  return state.views.chats.scrollPosition[threadId] || state.views.chats.scrollPosition[threadId] === 0
    ? state.views.chats.scrollPosition[threadId]
    : null;
}

export function getNotificationsChatsCount(state: IWithChatsViewState): number {
  return state.views.chats.notificationsCount || 0;
}

export function getThreadAgent(
  state: IWithChatsEntityState & WithAgentsState & IWithBotsState,
  threadId: string,
): IAgentBase {
  const thread = getThread(state, threadId);

  if (!thread) {
    return null;
  }

  const threadAgent = getAgentOrBot(state, thread.currentAgentId);

  return threadAgent || null;
}

export function canTakeoverChat(state: IWithChatsViewState & IWithBotsState, threadId: string): boolean {
  if (!getCanTakeOverChat(state)) {
    return false;
  }

  const chattingAgent = getThreadAgent(state, threadId);

  if (!chattingAgent) {
    return false;
  }

  if (chattingAgent.type === AgentType.Bot) {
    return true;
  }

  const supervisedAgent = getLoggedInAgent(state);

  if (!supervisedAgent) {
    return false;
  }

  return true;
}

export function getCanChatFeedHistoryUseTags(state: IWithChatsViewState, threadId: string): boolean {
  const threadType = getThreadType(state, threadId);

  return getCanUseTags(state) && threadType !== ChatType.Queued && threadType !== ChatType.Unassigned;
}

export const getPreChatSurveyEvent: (state: IWithChatsEntityState, threadId: string) => ISurveyMessage = createSelector(
  getThreadEvents,
  (threadEvents) => {
    if (isEmpty(threadEvents)) {
      return null;
    }

    return Object.values(threadEvents).find(
      (event) => isSurvey(event) && event.surveyType === SurveyType.Pre,
    ) as ISurveyMessage;
  },
);

export const getPostChatSurveyEvent: (state: IWithChatsEntityState, threadId: string) => ISurveyMessage =
  createSelector(getThreadEvents, (threadEvents) => {
    if (isEmpty(threadEvents)) {
      return null;
    }

    return Object.values(threadEvents).find(
      (event) => isSurvey(event) && event.surveyType === SurveyType.Post,
    ) as ISurveyMessage;
  });

function getListMode(state: IWithAgentCustomPropertiesState): ChatListMode {
  const customProperties = getAgentCustomProperties(state);

  return customProperties && (customProperties[AgentCustomPropertyName.ChatListMode] as ChatListMode);
}

export function getIsChatListModeDetailed(state: IWithAgentCustomPropertiesState): boolean {
  const listMode = getListMode(state);

  return listMode === ChatListMode.Detailed;
}

export function getPersistedChatsSortType(state: IWithAgentCustomPropertiesState, chatType: ChatType): SortOrder {
  const defaultSort = chatType === ChatType.Queued || chatType === ChatType.Supervised ? SortOrder.Asc : SortOrder.Desc;

  return (
    (PersistedChatsSortType[chatType] &&
      (getAgentCustomProperty(state, PersistedChatsSortType[chatType]) as SortOrder)) ||
    defaultSort
  );
}

export function getChatsSortType(
  state: IWithChatsViewState & IWithAgentCustomPropertiesState,
  chatType: ChatType,
): SortOrderType {
  return state.views.chats.sortOrderMap[chatType] || getPersistedChatsSortType(state, chatType);
}

export function getIsChatInaccessible(
  state: IWithChatsViewState & IWithAgentCustomPropertiesState,
  chatId: string,
): boolean {
  const { inaccessibleChatIds } = state.views.chats;

  return inaccessibleChatIds.indexOf(chatId) >= 0;
}

export function getIsSelectedThreadOtherChat(state: IWithChatsViewState): boolean {
  const selectedThread = getSelectedThread(state);

  return isOtherChat(selectedThread);
}

const getWasChatDeactivated = (state: IWithChatsEntityState, threadId: string): boolean => {
  const thread = getThread(state, threadId);
  const wasChatDeactivated = thread?.customProperties?.wasChatDeactivated;

  return Boolean(wasChatDeactivated);
};

export const getWasChatTransferred = (
  state: IWithChatsEntityState & IWithAgentsSessionState,
  threadId: string,
): boolean => {
  const wasChatDeactivated = getWasChatDeactivated(state, threadId);

  if (wasChatDeactivated) {
    return false;
  }

  const threadType = getThreadType(state, threadId);
  const isMy = threadType === ChatType.My;
  const loggedInAgent = getLoggedInAgentLogin(state);
  const currentThreadAgent = getThreadAgentId(state, threadId);

  return isMy && currentThreadAgent && currentThreadAgent !== loggedInAgent;
};

export const getShouldShowSuperviseInActionBar = (
  state: IWithChatsEntityState & IWithAgentsSessionState,
  threadId: string,
): boolean => {
  const wasChatDeactivated = getWasChatDeactivated(state, threadId);
  const hasOngoingOtherActiveThread = !!getAnotherActiveThreadWithOtherAgent(state, threadId);

  if (wasChatDeactivated && !hasOngoingOtherActiveThread) {
    return false;
  }

  const thread = getThread(state, threadId);
  const isOther = thread?.type === ChatType.Other;
  const isQueuedChatVisuallyClosed = getIsQueuedChatVisuallyClosed(state, threadId);
  const wasChatTransferred = getWasChatTransferred(state, threadId);

  return hasOngoingOtherActiveThread || isOther || isQueuedChatVisuallyClosed || wasChatTransferred;
};

export function getIsReplySuggestionTriggered(state: IWithChatsViewState, threadId: string): boolean {
  return !!state.views.chats.replySuggestions.toolbarState[threadId];
}

export function getIsReplySuggestionLoading(state: IWithChatsViewState, threadId: string): boolean {
  return state.views.chats.replySuggestions.toolbarState[threadId]?.isLoading;
}

export function getReplySuggestionTraceId(state: IWithChatsViewState, threadId: string): string | null {
  return state.views.chats.replySuggestions.toolbarState[threadId]?.metadata.traceId || null;
}

export function getReplySuggestionError(
  state: IWithChatsViewState,
  threadId: string,
): ReplySuggestionsSuggestionError | null {
  return state.views.chats.replySuggestions.toolbarState[threadId]?.error || null;
}

export function getIsReplySuggestionOnboarding(state: IWithChatsViewState, threadId: string): boolean {
  return state.views.chats.replySuggestions.toolbarState[threadId]?.isOnboardingSuggestion || false;
}

export function getReplySuggestionData(
  state: IWithChatsViewState,
  threadId: string,
): { response: string; sources: Source[] } | null {
  return state.views.chats.replySuggestions.toolbarState[threadId]?.data || null;
}

export function getCanUseReplySuggestionsFeature(
  state: IWithSessionState & IIntegrationLicensePropertiesState & IWithAgentCustomPropertiesState,
): boolean {
  const knowledgeSuggestionsStatus = getReplySuggestionsStatus(state);
  const canUseFeature = isAtLeastTeamPlan(state);

  return getStatusFromFeatureFlag(knowledgeSuggestionsStatus, canUseFeature);
}

export function getCanUseReplySuggestions(state: IStoreState, threadId: string): boolean {
  if (!getCanUseReplySuggestionsFeature(state)) {
    return false;
  }

  const thread = getThread(state, threadId);

  return (isMyChat(thread) || isSupervisedChat(thread)) && !isClosedThread(thread);
}

export function getReplySuggestionsCurrentSessionId(state: IWithChatsViewState, threadId: string): string | null {
  return state.views.chats.replySuggestions.currentThreadSessions[threadId] || null;
}

export function getTextEnhancementsToolbarState(
  state: IWithChatsViewState,
  threadId: string,
): ITextEnhancementsToolbarState | null {
  return state.views.chats.textEnhancements.toolbarState[threadId] || null;
}

export function getTextEnhancementsCurrentThreadSessions(state: IWithChatsViewState): KeyMap<string> {
  return state.views.chats.textEnhancements.currentThreadSessions;
}

function getIsTextEnhancemenetsLoading(state: IWithChatsViewState, threadId: string): boolean {
  return state.views.chats.textEnhancements.toolbarState[threadId]?.isLoading;
}

function getTextEnhancementsError(state: IWithChatsViewState, threadId: string): ITextEnhancementError | null {
  return state.views.chats.textEnhancements.toolbarState[threadId]?.error || null;
}

function getTextEnhancementsResponse(state: IWithChatsViewState, threadId: string): string | null {
  return state.views.chats.textEnhancements.toolbarState[threadId]?.response || null;
}

export function getIsTextEnhancementsSuccessResponse(state: IWithChatsViewState, threadId: string): boolean {
  const response = getTextEnhancementsResponse(state, threadId);
  const error = getTextEnhancementsError(state, threadId);
  const isLoading = getIsTextEnhancemenetsLoading(state, threadId);

  return response && !error && !isLoading;
}

export function getIsTransferringChat(state: IWithChatsViewState): boolean {
  return state.views.chats.isTransferringChat;
}

export function getCurrentMessageBoxValue(state: IWithChatsViewState): string {
  return state.views.chats.currentMessageboxValue || '';
}

export function getIsTextEnhancementsToolbarOpen(state: IWithChatsViewState): boolean {
  return state.views.chats.textEnhancements.isOpen;
}
