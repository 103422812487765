/**
 * Reorder item in array
 */
export function reorder<T = unknown>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = list.slice(0);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
}
