import { type ActionsUnion, createAction } from 'store/helper';

import { type GroupId } from 'interfaces/groups';

import { type IEyeCatcherState } from './interfaces';

export enum EyeCatcherActionNames {
  TOGGLE_ENABLED = 'ENTITIES/EYE_CATCHER/TOGGLE_ENABLED',
  SET_PATH = 'ENTITIES/EYE_CATCHER/SET_PATH',
  SET_POSITION = 'ENTITIES/EYE_CATCHER/SET_POSITION',
  SET_EYE_CATCHER = 'ENTITIES/EYE_CATCHER/SET_EYE_CATCHER',
}

export const EyeCatcherActions = {
  toggleEnabled: (enabled: boolean, groupId: GroupId) =>
    createAction(EyeCatcherActionNames.TOGGLE_ENABLED, { enabled, groupId }),
  setPatch: (path: string, groupId: GroupId) => createAction(EyeCatcherActionNames.SET_PATH, { path, groupId }),
  setPosition: (x: number, y: number, groupId: GroupId) =>
    createAction(EyeCatcherActionNames.SET_POSITION, { x, y, groupId }),
  setEyeCatcher: (eyeCatcher: IEyeCatcherState, groupId: GroupId) =>
    createAction(EyeCatcherActionNames.SET_EYE_CATCHER, { eyeCatcher, groupId }),
};

export type EyeCatcherAction = ActionsUnion<typeof EyeCatcherActions>;
