import type { FC } from 'react';

import { NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';

import { CONNECTION_STATUS_OFFLINE_BAR_TEXT } from './constants';

interface Props {
  isVisible: boolean;
}

export const OfflineBar: FC<Props> = ({ isVisible }) => {
  return (
    <NavigationTopBarAlert kind="warning" isVisible={isVisible}>
      <Text bold>{CONNECTION_STATUS_OFFLINE_BAR_TEXT}</Text>
    </NavigationTopBarAlert>
  );
};
