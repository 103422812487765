import mitt from 'mitt';

import { type AppApi } from 'entries/base/interfaces/app-api';

const API_READY = 'api-ready';

type Events = {
  [API_READY]: AppApi;
};

const emitter = mitt<Events>();

let appApi: AppApi | null = null;

async function getApi(): Promise<AppApi> {
  if (appApi) {
    return appApi;
  }

  return new Promise((resolve) => {
    emitter.on(API_READY, (api: AppApi) => {
      resolve(api);
    });
  });
}

export function prepareExternalApi(): { getApi: () => Promise<AppApi> } {
  const app = {
    getApi,
  };

  return app;
}

export function externalApiReady(api: AppApi): void {
  appApi = api;

  emitter.emit(API_READY, api);
}
