import { ChatType } from 'constants/chat-type';
import type { IAppState } from 'services/app-state-provider';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { isClosedThread, isSupervisedChat } from 'store/entities/chats/helpers/common';
import { getThread } from 'store/entities/chats/selectors';

import type { UserRemovedFromChatPushEvent } from '../interfaces';

/**
 * Handles user removed from chat push event.
 */
export function handleUserRemovedFromChat(payload: UserRemovedFromChatPushEvent, store: IAppState): void {
  const { thread_id: threadId, user_id: agentId } = payload;
  const state = store.getState();
  const currentAgentId = getLoggedInAgentLogin(state);
  const isCurrentAgentRemovedFromThread = currentAgentId === agentId;
  const thread = getThread(state, threadId);

  if (!thread) {
    return;
  }

  /**
   *  1. Supervising was stopped by current agent.
   *  Move chat to Other agents chats
   */
  const shouldMoveToOtherChats = isSupervisedChat(thread) && !isClosedThread(thread) && isCurrentAgentRemovedFromThread;
  if (shouldMoveToOtherChats) {
    store.dispatch(
      ChatsEntitiesActions.updateChatThread({
        threadId: thread.threadId,
        thread: { type: ChatType.Other },
      })
    );
  }

  /**
   *  2. Supervising was stopped by current agent or other agent.
   *  Update the list of supervisors.
   */
  const supervisors = (thread.supervisorsIds || []).filter((id) => id !== agentId);

  store.dispatch(
    ChatsEntitiesActions.updateChatSupervisors({
      threadId,
      supervisors,
    })
  );
}
