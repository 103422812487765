import { useRef, useEffect, type MutableRefObject } from 'react';

export function usePreviousRef<T>(value: T, initialValue: T | null = null): MutableRefObject<T | null> {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T | null>(initialValue);

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref;
}
