import { type ActionsUnion, createAction } from 'store/helper';

import {
  type IUpdateAttachmentSafetyConfirmationPayload,
  type IUpdateAttachmentSafetyConfirmationSuccessPayload,
  type IUpdateAttachmentSafetyConfirmationFailurePayload,
} from './interfaces';

export enum AttachmentsFeatureActionsNames {
  UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_REQUEST = 'UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_REQUEST',
  UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_SUCCESS = 'UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_SUCCESS',
  UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_FAILURE = 'UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_FAILURE',
}

export const AttachmentsFeatureActions = {
  updateAttachmentSafetyConfirmation: (payload: IUpdateAttachmentSafetyConfirmationPayload) =>
    createAction(AttachmentsFeatureActionsNames.UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_REQUEST, payload),

  updateAttachmentSafetyConfirmationSuccess: (payload: IUpdateAttachmentSafetyConfirmationSuccessPayload) =>
    createAction(AttachmentsFeatureActionsNames.UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_SUCCESS, payload),

  updateAttachmentSafetyConfirmationFailure: (payload: IUpdateAttachmentSafetyConfirmationFailurePayload) =>
    createAction(AttachmentsFeatureActionsNames.UPDATE_ATTACHMENT_SAFETY_CONFIRMATION_FAILURE, payload),
};

export type AttachmentsFeatureAction = ActionsUnion<typeof AttachmentsFeatureActions>;
