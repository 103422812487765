import type { FC } from 'react';

import { Add, OpenInNew } from '@livechat/design-system-icons';
import { Button, Icon } from '@livechat/design-system-react-components';
import uniqueId from 'lodash.uniqueid';
import { useDispatch } from 'react-redux';

import { trackEvent } from 'services/event-tracking';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';
import type {
  CopilotButton,
  CopilotConversationButton,
  CopilotNavigateButton,
} from 'store/entities/copilot/interfaces';
import { CopilotButtonTypes } from 'store/entities/copilot/interfaces';

import * as styles from './styles';

const iconMapping = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'open-in-new': OpenInNew,
  add: Add,
};

export const CopilotActionButton: FC<CopilotButton> = (props) => {
  const dispatch = useDispatch();
  const { icon, type, kind, iconPosition, content, id } = props;
  const iconComponent = icon ? <Icon source={iconMapping[icon]} /> : undefined;

  const timestamp = Date.now();

  const handleConversationAction = (conversation: CopilotConversationButton['conversation']): void => {
    dispatch(
      CopilotEntitiesActions.addCopilotConversation([
        {
          event: {
            authorId: 'agent',
            authorType: 'agent',
            eventId: uniqueId(),
            text: conversation.agentMessage,
            timestampInMs: timestamp,
            type: 'text-message',
          },
          delay: conversation.delay,
        },
        {
          event: {
            authorId: 'live-assistant',
            authorType: 'live-assistant',
            eventId: uniqueId(),
            text: conversation.copilotMessage,
            timestampInMs: timestamp,
            type: 'text-message',
          },
        },
      ]),
    );
  };

  const handleNavigateAction = (navigate: CopilotNavigateButton['navigate']): void => {
    dispatch(
      CopilotEntitiesActions.copilotNavigateAction({
        content,
        props: navigate,
        eventId: id,
      }),
    );
  };

  const handleClick = (): void => {
    if (props.event) {
      const { name, eventPlace, eventProperties } = props.event;
      trackEvent(name, eventPlace, eventProperties);
    }

    switch (props.type) {
      case CopilotButtonTypes.CONVERSATION:
        handleConversationAction(props.conversation);
        break;
      case CopilotButtonTypes.NAVIGATE:
        handleNavigateAction(props.navigate);
        break;
    }
  };

  return (
    <Button
      className={type === 'navigate' ? styles.navigateButton : undefined}
      kind={kind ?? 'secondary'}
      icon={iconComponent}
      iconPosition={iconPosition ?? 'left'}
      onClick={handleClick}
    >
      {content}
    </Button>
  );
};
