import {
  getBrowserByUserAgent,
  getOperatingSystemByUserAgent,
  isMobileDeviceByUserAgent,
} from 'helpers/device-by-user-agent';
import { DeviceGeneralType } from 'store/entities/common/interfaces';

export function getBrowserNameForList(userAgent?: string): string | undefined {
  const { name, version } = getBrowserByUserAgent(userAgent) ?? {};
  const namePart = name || '';
  const versionPart = version ? ` (${version})` : '';

  return `${namePart}${versionPart}` || undefined;
}

export function getDeviceGeneralTypeForList(userAgent?: string): DeviceGeneralType | undefined {
  if (!userAgent) {
    return undefined;
  }

  const isMobile = isMobileDeviceByUserAgent(userAgent);

  return isMobile ? DeviceGeneralType.Mobile : DeviceGeneralType.Desktop;
}

export function getOperatingSystemForList(userAgent?: string): string | undefined {
  const { name, version } = getOperatingSystemByUserAgent(userAgent) ?? {};
  const namePart = name || '';
  const versionPart = version ? ` (${version})` : '';

  return `${namePart}${versionPart}` || undefined;
}
