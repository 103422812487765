import { type ComponentType, lazy } from 'react';

interface IImport {
  default: ComponentType<any>;
}

function retry(fn, retriesLeft = 5, interval = 500): Promise<IImport> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);

            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function lazyWithRetry(fn) {
  return lazy(() => retry(fn));
}
