import { AutomateNavigationItemId } from 'constants/automate/constants';
import { type SettingsNavigationItemId } from 'constants/settings/navigation-item-id';
import { filterActiveKnowledgeSources } from 'helpers/knowledge-hub';
import { isSourceFileType } from 'helpers/knowledge-hub/knowledge-hub';
import { type KnowledgeSourceItem, KnowledgeSourceType } from 'interfaces/knowledge-sources';

export const getSourcesCountByType = (
  itemId: SettingsNavigationItemId | AutomateNavigationItemId,
  data?: KnowledgeSourceItem[],
): number => {
  if (!data) {
    return 0;
  }

  const activeItems = filterActiveKnowledgeSources(data);

  if (itemId === AutomateNavigationItemId.KnowledgeHubAllSources) {
    return activeItems.length;
  }

  if (itemId === AutomateNavigationItemId.KnowledgeHubWebsites) {
    return activeItems.filter((source) => source.sourceType === KnowledgeSourceType.URL).length;
  }

  if (itemId === AutomateNavigationItemId.KnowledgeHubPDF) {
    return activeItems.filter(isSourceFileType).filter(({ fileType }) => fileType === 'PDF').length;
  }

  return 0;
};
