import type { FC, SyntheticEvent } from 'react';

import { Error as ErrorIcon } from '@livechat/design-system-icons';
import { Button, Icon, Modal, ModalHeader, Heading, Text } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';
import { EventPlace, trackGTMEvent } from 'helpers/analytics';

import { TrackingEvent } from 'constants/tracking-events';
import { useEffectOnce } from 'hooks/use-effect-once';
import { openChatWidget } from 'services/chat-widget';
import { trackEvent } from 'services/event-tracking';
import { ReportBugActions } from 'store/features/report-bug/actions';

import { ReportBugTooltip } from '../report-bug/ReportBugTooltip';

import * as styles from './styles';

const handleRestart = (): void => {
  window.location.reload();
};

interface IProps {
  onClose: () => void;
}

export const SomethingWentWrongModal: FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();

  const handleDownload = (): void => {
    dispatch(ReportBugActions.downloadData());
  };

  useEffectOnce(() => {
    trackEvent(TrackingEvent.ShowModal, EventPlace.SomethingWentWrongModal);
    trackGTMEvent(TrackingEvent.ShowModal, { eventPlace: EventPlace.SomethingWentWrongModal });
  });

  const handleBugReport = (): void => {
    handleDownload();

    trackEvent(TrackingEvent.DownloadReport, EventPlace.SomethingWentWrongModal);
  };

  const handleOpenWidget = (event: SyntheticEvent): void => {
    event.preventDefault();
    openChatWidget();

    trackEvent(TrackingEvent.OpenChatWidget, EventPlace.SomethingWentWrongModal);
  };

  return (
    <Modal
      heading={<ModalHeader />}
      className={styles.modal}
      closeOnEscPress
      closeOnOverlayPress
      onClose={onClose}
      contentClassName={styles.content}
      footer={
        <div className={styles.footer}>
          <Button kind="primary" size="large" onClick={handleRestart}>
            Refresh
          </Button>
          <Button kind="secondary" size="large" onClick={handleBugReport}>
            Download bug report
          </Button>
        </div>
      }
    >
      <Icon source={ErrorIcon} kind="action-neutral" className={styles.icon} />
      <Heading>Oops! Something went wrong</Heading>
      <Text>
        This page didn&lsquo;t load correctly. Please refresh the application. <br />
        If you continue having an issue, please contact our&nbsp;
        <a href="" onClick={handleOpenWidget}>
          support team
        </a>
        <ReportBugTooltip />.
      </Text>
    </Modal>
  );
};
