// @ts-strict-ignore
import initial from 'lodash.initial';
import isArray from 'lodash.isarray';
import isEqual from 'lodash.isequal';
import keyBy from 'lodash.keyby';
import last from 'lodash.last';
import orderBy from 'lodash.orderby';
import reduce from 'lodash.reduce';

import { type KeyMap } from './interface';

/**
 * Determines if two arrays are equal regardless of the order of elements.
 * @param {T[]} firstArray - The first array to compare.
 * @param {T[]} secondArray - The second array to compare.
 * @returns {boolean} - Returns true if the arrays are equal regardless of the order of elements, false otherwise.
 * @example
 * ```typescript
 * const arrA = [1, 2, 3];
 * const arrB = [3, 2, 1];
 * const result = areArraysEqualRegardlessOfOrder(arrA, arrB);
 * console.log(result); // logs true
 * ```
 * @remarks
 * This function uses lodash's isEqual and sortBy functions to compare the arrays. It first sorts the arrays, then compares them. It returns false if either of the inputs is not an array or if the arrays have different lengths.
 * @see [lodash.isEqual](https://lodash.com/docs/#isEqual)
 * @see [lodash.sortBy](https://lodash.com/docs/#sortBy)
 */
export const areArraysEqualRegardlessOfOrder = <T>(firstArray: T[], secondArray: T[]): boolean => {
  if (!isArray(firstArray) || !isArray(secondArray)) {
    return false;
  }

  if (firstArray.length !== secondArray.length) {
    return false;
  }

  const sortedFirstArray = orderBy(firstArray, [(item) => (typeof item === 'string' ? item : JSON.stringify(item))]);
  const sortedSecondArray = orderBy(secondArray, [(item) => (typeof item === 'string' ? item : JSON.stringify(item))]);

  return isEqual(sortedFirstArray, sortedSecondArray);
};

/**
 * @todo Replace with lodash.keyBy. Be aware lodash.keyby will just ignore missing key property instead of returning null.
 * Converts an array to a key map using passed key.
 * @param {T[]} inputArray - Array to convert.
 * @param {string} key - Key name for property that should be treated as key in returned key map.
 * @returns {KeyMap<T> | null} - The key map created from the array, or null if any item in the array is missing the key property.
 * @example
 * ```typescript
 * const arr = [{ id: 1, name: 'apple' }, { id: 2, name: 'banana' }, { id: 3, name: 'cherry' }];
 * const keyMap = toKeyMap(arr, 'id');
 * console.log(keyMap); // logs { '1': { id: 1, name: 'apple' }, '2': { id: 2, name: 'banana' }, '3': { id: 3, name: 'cherry' } }
 * ```
 * @remarks
 * This function uses lodash's keyBy function to create the key map. It also checks if any item in the array is missing the key property or has an undefined value for the key property, and returns null in that case.
 * @see [lodash.keyBy](https://lodash.com/docs/#keyBy)
 */
export function toKeyMap<T extends object>(inputArray: T[], key: string): KeyMap<T> {
  const anyIsMissingKeyProperty = inputArray.some((item) => !(key in item) || item[key] === undefined);
  if (anyIsMissingKeyProperty) {
    return null;
  }

  return keyBy(inputArray, key);
}

/**
 * @todo Replace with lodash.uniq. Be aware that lodash.uniq might be less performant in case of reducers.
 * Returns an array without duplicate entries.
 * @param {T[]} elements - Array to remove duplicates from.
 * @returns {T[]} - The array with all duplicate entries removed.
 * @example
 * ```typescript
 * const arr = ['apple', 'apple', 'banana', 'banana', 'cherry'];
 * const result = removeDuplicates(arr);
 * console.log(result); // logs ['apple', 'banana', 'cherry']
 * ```
 * @remarks
 * This function uses the JavaScript Set object to remove duplicates. The Set object lets you store unique values of any type, whether primitive values or object references.
 * @see [Set](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set)
 */
export function removeDuplicates<T extends string | number | boolean>(elements: T[]): T[] {
  return [...new Set(elements)];
}

/**
 * Joins an array of strings using ',' and uses a specified separator for the last item.
 * @param {string[]} stringArray - Array of strings to join together.
 * @param {string} separator - String to be placed with the last item. Default is 'and'.
 * @returns {string} - A string that is the result of joining the array elements, with the last element separated by the specified separator.
 * @example
 * ```typescript
 * const arr = ['apple', 'banana', 'cherry'];
 * const result = joinArrayWithFormat(arr, 'and');
 * console.log(result); // logs 'apple, banana, and cherry'
 * ```
 * @remarks
 * If the array has two or fewer elements, the function simply joins them with the separator.
 * @see [Array.prototype.join](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/join)
 */
export function joinArrayWithFormat(stringArray: string[], separator = 'and'): string {
  if (stringArray.length <= 2) {
    return stringArray.join(` ${separator} `);
  }

  return `${initial(stringArray).join(', ')} ${separator} ${last(stringArray)}`;
}

/**
 * Selects a random index from an array based on the provided weights.
 * @param {number[]} weights - An array of numbers where each number represents the weight (likelihood) of its associated index being selected.
 * @returns {number} - The selected index from the weights array. If no index could be selected (e.g., if all weights are zero), it returns -1.
 * @example
 * ```typescript
 * const weights = [1, 2, 3];
 * const index = getWeightedRandomIndex(weights);
 * console.log(index); // logs a random index from the weights array, with a higher chance of logging indices associated with higher weights
 * ```
 * @remarks
 * This function uses a weighted random selection algorithm.
 * The original source can be found at btmills/weighted-random on GitHub.
 * @see [btmills/weighted-random](https://github.com/btmills/weighted-random)
 */
export function getWeightedRandomIndex(weights: number[]): number {
  const totalWeight = reduce(weights, (previousValue, weight) => previousValue + weight, 0);
  let random = Math.random() * totalWeight;

  for (let i = 0; i < weights.length; i += 1) {
    if (random < weights[i]) {
      return i;
    }

    random -= weights[i];
  }

  return -1;
}

/**
 * Checks if any value in the array is falsy.
 * @param {T[]} array - The array to check.
 * @returns {boolean} - Returns true if any value in the array is falsy, false otherwise.
 * @example
 * ```typescript
 * const arr = [1, 2, 0, 4];
 * const result = containsFalsyValue(arr);
 * console.log(result); // logs true
 * ```
 * @remarks
 * This function iterates over the array and checks if any value is falsy.
 */
export function containsFalsyValue<T>(array: T[]): boolean {
  return array.some((item) => !item);
}
