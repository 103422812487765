// @ts-strict-ignore
import type { KeyMap } from 'helpers/interface';
import type { IArchive } from 'interfaces/entities/archive';
import type { Properties } from 'interfaces/property';
import type { IFetchArchiveTicketsSuccessPayload } from 'store/entities/archives/interfaces';

import type {
  IArchiveViewsShowModalPayload,
  ISetArchiveScrollPositionPayload,
  IUpdateArchiveCustomPropertyPayload,
  IUpdateFilterOperatorPayload,
  IArchivesViewState,
} from '../interfaces';

export function getStateForShowModal(
  state: IArchivesViewState,
  payload: IArchiveViewsShowModalPayload
): IArchivesViewState {
  const { type } = payload;

  return {
    ...state,
    shownModal: type,
  };
}

export function getStateForCloseModal(state: IArchivesViewState): IArchivesViewState {
  return {
    ...state,
    shownModal: null,
  };
}

export function getStateForFetchTicketsSuccess(
  state: IArchivesViewState,
  payload: IFetchArchiveTicketsSuccessPayload
): IArchivesViewState {
  const { tickets, id } = payload;

  return {
    ...state,
    tickets: {
      ...state.tickets,
      [id]: tickets,
    },
  };
}

export function getStateCurrentForUpdateCustomer(state: IArchivesViewState, name: string, email: string): IArchive {
  if (!state.current) {
    return null;
  }

  const updatedCurrentEvents = state.current.events.map((event) => {
    if (event.userType === 'customer') {
      return {
        ...event,
        authorName: name,
      };
    }

    return event;
  });

  return {
    ...state.current,
    events: updatedCurrentEvents,
    visitor: {
      ...state.current.visitor,
      name,
      email,
    },
  };
}

export function getStateDataForUpdateCustomer(
  state: IArchivesViewState,
  userId: string,
  name: string,
  email: string
): KeyMap<IArchive> {
  if (!state.data) {
    return null;
  }

  const archivesData = Object.keys(state.data).reduce((acc, archiveId) => {
    /*
     Platform API allows a thread to exist without a visitor.
     Such a thread can be created by starting the chat with Agent Chat API and then deactivating it.
     Therefore, we can skip such threads when updating customers.
     */
    if (state.data[archiveId].visitor?.id === userId && state.data[archiveId].events) {
      const updatedDataEvents = state.data[archiveId].events.map((event) => {
        if (event.userType === 'customer') {
          return {
            ...event,
            authorName: name,
          };
        }

        return event;
      });

      return {
        ...acc,
        [archiveId]: {
          ...state.data[archiveId],
          events: updatedDataEvents,
          visitor: {
            ...state.data[archiveId].visitor,
            name,
            email,
          },
        },
      };
    }

    return {
      ...acc,
      [archiveId]: state.data[archiveId],
    };
  }, {});

  return archivesData;
}

export function getStateForUpdateFilterOperator(
  state: IArchivesViewState,
  payload: IUpdateFilterOperatorPayload
): IArchivesViewState {
  const { filter, operator } = payload;

  const { filtersOperators } = state;

  let newOperators = {};

  if (operator) {
    newOperators = { ...filtersOperators, [filter]: operator };
  } else {
    const { [filter]: operatorToRemove, ...restOperator } = filtersOperators;
    newOperators = { ...restOperator };
  }

  return {
    ...state,
    filtersOperators: newOperators,
  };
}

export function getStateForSetScrollPosition(
  state: IArchivesViewState,
  payload: ISetArchiveScrollPositionPayload
): IArchivesViewState {
  const { threadId, scrollPosition } = payload;

  const currentScrollPosition = { ...state.scrollPosition };

  return {
    ...state,
    scrollPosition: {
      ...currentScrollPosition,
      [threadId]: scrollPosition,
    },
  };
}

export function getStateForUpdateCurrentArchiveCustomProperty(
  state: IArchivesViewState,
  payload: Properties
): IArchivesViewState {
  return {
    ...state,
    current: {
      ...state.current,
      customProperties: {
        ...state.current.customProperties,
        ...payload,
      },
    },
  };
}

export function getStateForUpdateArchiveCustomProperty(
  state: IArchivesViewState,
  payload: IUpdateArchiveCustomPropertyPayload
): IArchivesViewState {
  const { id, properties } = payload;
  const thread = state.data[id];

  if (!thread) {
    return state;
  }

  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...thread,
        customProperties: {
          ...thread.customProperties,
          ...properties,
        },
      },
    },
  };
}
