import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type IFetchBillingInfoResult } from 'services/api/billing/interfaces';
import { BillingInfoSerializer } from 'services/api/billing-info/serializer';
import { BillingActionNames, BillingActions } from 'store/entities/billing/actions';

function* fetchBillingInfo(): SagaIterator {
  const { result }: RequestResult<IFetchBillingInfoResult> = yield call(ApiManager.billingApi.fetchBillingInfo);

  if (result) {
    yield put(BillingActions.billingInfoFetched(BillingInfoSerializer.deserialize(result)));
  }
}

export function* fetchBillingInfoSaga(): SagaIterator {
  yield takeEvery(BillingActionNames.FETCH_BILLING_INFO, fetchBillingInfo);
  yield takeEvery(['APP_READY', 'APP_ERROR'], fetchBillingInfo);
}
