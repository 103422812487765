import type { ITag } from 'store/entities/tags/interfaces';

export function mapTagsToStrings(tags: ITag[], usageAllowed = true): string[] {
  if (!usageAllowed) {
    return [];
  }

  // Use hashmap to find duplicates from tags
  const tagsHashMap = {};

  return tags
    .sort((a, b) => b.countInChats + b.countInTickets - (a.countInChats + a.countInTickets))
    .reduce((acc: string[], tag) => {
      if (tag.name in tagsHashMap) {
        return acc;
      }

      tagsHashMap[tag.name] = true;
      acc.push(tag.name);

      return acc;
    }, []);
}
