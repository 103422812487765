import { createSelector } from 'reselect';

import { PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE } from 'constants/copilot';
import { isCopilotMessageEvent } from 'helpers/copilot-guards';
import { type KeyMap } from 'helpers/interface';

import { buildEventGroups } from './helpers';
import { type CopilotMessageEvent } from './interfaces';
import { getCopilotAllEvents, getCopilotGroupedEventsIds } from './selectors';

const getCopilotMessageEvents = createSelector(getCopilotAllEvents, (allEvents) =>
  Object.entries(allEvents).reduce((acc, [eventId, event]) => {
    if (isCopilotMessageEvent(event)) {
      acc[eventId] = event;
    }

    return acc;
  }, {} as KeyMap<CopilotMessageEvent>),
);

export const getCopilotGroupedEvents = createSelector(
  getCopilotGroupedEventsIds,
  getCopilotAllEvents,
  (groupedEventIds, copilotEvents) => buildEventGroups(groupedEventIds, copilotEvents),
);

export const getIsOnlyWelcomeMessage = createSelector(getCopilotMessageEvents, (events) => {
  const copilotEvents = Object.values(events);

  if (!copilotEvents.length || copilotEvents.length >= 2) {
    return false;
  }

  return copilotEvents[0].text.endsWith(PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE);
});
