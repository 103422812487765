import { AppStateProvider } from 'services/app-state-provider';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { getThreadByChatId } from 'store/entities/chats/selectors';

import { removeUserFromChat } from './remove-user-from-chat';

export async function stopSupervisingChat(chatId: string): Promise<unknown> {
  const thread = AppStateProvider.selectFromStore(getThreadByChatId, chatId);

  if (!thread) {
    return;
  }

  const currentLogin = AppStateProvider.selectFromStore(getLoggedInAgentLogin);

  return await removeUserFromChat(chatId, currentLogin);
}
