import { QueryKey } from 'constants/query-key';
import { updateFirstChatDate } from 'services/chat-widget';
import { normalizeError } from 'services/connectivity/configuration-api/helpers';
import { propertiesClient } from 'services/connectivity/configuration-api/properties/client';
import { PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { type PlatformNamespaceProperties } from 'services/connectivity/configuration-api/properties/types';
import { type ConfigurationApiErrorResponse } from 'services/connectivity/configuration-api/types';
import { type ApiClientResponse } from 'services/connectivity/http/types';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';

import { getFirstChatDate } from './website-last-activity';

export async function fetchWebsiteLastActivity(): Promise<void> {
  const { result, error }: ApiClientResponse<PlatformNamespaceProperties, ConfigurationApiErrorResponse> =
    await propertiesClient.listLicense({ namespace: PlatformNamespace.STATISTICS });

  if (error) {
    throw normalizeError(error);
  }

  getQueryClient().setQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.STATISTICS),
    result[PlatformNamespace.STATISTICS] ?? {}
  );

  updateFirstChatDate(getFirstChatDate());
}
