import * as Sentry from '@sentry/browser';

import { ChatEventSubType } from 'constants/chat-event-type';
import { Rating } from 'interfaces/customer';

export const ANCHOR_LINK_MARKER = '%anchor_link%';

/**
 * Replaces in text all variable keys for dedicated values.
 * @param text Text to replace within.
 * @param textVars Variables.
 */
const applyTextVariables = (text: string, textVars: Record<string, string | number> = {}): string =>
  Object.keys(textVars).reduce((str, key) => str.replace(new RegExp(`%${key}%`, 'g'), String(textVars[key])), text);

/**
 * System events that should include close link in message.
 */
const eventsWithCloseLink = [
  ChatEventSubType.ManuallyArchivedByCustomer,
  ChatEventSubType.CustomerBanned,
  ChatEventSubType.ArchivedCustomerDisconnected,
];

/**
 * Adds `%close_window_link%` as a suffix to original message if defined in `eventsWithCloseLink` array.
 * @param message Original message.
 * @param eventType System event type that may include close link.
 */
const addCloseLinkToMessage = (message: string, eventType: ChatEventSubType): string => {
  if (eventsWithCloseLink.includes(eventType)) {
    return `${message} %close_window_link%.`;
  }

  return message;
};

/**
 * Gets system message value based on system event type and variables. If no custom value is defined for the vent, original value is returned.
 * @param originalMessage Message text sent originally with event.
 * @param eventType Type of system event.
 * @param textVariables Variables passed with system event to build custom message.
 * @param addCloseLink Should new message include close link if it's defined in system event type.
 */
export const getSystemMessageString = (
  originalMessage: string,
  eventType: ChatEventSubType,
  textVariables: Record<string, string | number> = {},
  addCloseLink?: boolean
): string => {
  let message = originalMessage;

  try {
    switch (eventType) {
      case ChatEventSubType.ManuallyArchivedByCustomer:
      case ChatEventSubType.ArchivedCustomerDisconnected:
      case ChatEventSubType.CustomerLeft:
        message = 'Archived - customer left the chat';
        break;
      case ChatEventSubType.ManuallyArchivedByAgent:
        message = 'Archived - closed by agent';
        break;
      case ChatEventSubType.TransferredDueToInactivity:
        message = `Transferred - to ${textVariables.agent_added} due to no reply from ${textVariables.agent_removed} for ${textVariables.duration} min`;
        break;
      case ChatEventSubType.QueuedDueToAgentRemoved:
        message = `Queued - ${textVariables.agent}'s account deleted`;
        break;
      case ChatEventSubType.TransferredDueToAgentLogout:
        message = `Transferred - to ${textVariables.agent_added} because ${textVariables.agent_removed} had signed out`;
        break;
      case ChatEventSubType.TransferredDueToAgentRemoteLogout:
        message = `Transferred - to ${textVariables.agent_added} because ${textVariables.agent_removed} had been remotely signed out`;
        break;
      case ChatEventSubType.TransferredDueToAgentRemoved:
        message = `Transferred - to ${textVariables.agent_added} because ${textVariables.agent_removed} account had been deleted`;
        break;
      case ChatEventSubType.ReassignedDueToAgentJoin:
        message = `Reassigned to ${textVariables.agent}`;
        break;
      case ChatEventSubType.TicketCreated:
        message = 'Ticket created';
        break;
      case ChatEventSubType.Idle:
        message = `Idle - ${textVariables.duration} min inactivity`;
        break;
      case ChatEventSubType.ArchivedByInactivity:
        message = `Archived - ${textVariables.duration} min inactivity`;
        break;
      case ChatEventSubType.CustomerBanned:
        message = `Archived – customer banned for ${textVariables.duration} ${
          textVariables.duration > 1 ? 'days' : 'day'
        } by ${textVariables.agent}`;
        break;
      case ChatEventSubType.ChatTransferred:
        message = `Transferred – by ${textVariables.initiator} to ${textVariables.targets}`;
        break;
      case ChatEventSubType.UnassignedRemotelySignedOut:
        message = `Queued - ${textVariables.agent} had been remotely signed out`;
        break;
      case ChatEventSubType.UnassignedSignedOut:
        message = `Queued - ${textVariables.agent} signed out`;
        break;
      case ChatEventSubType.UnassignedDisconnected:
        message = `Queued - ${textVariables.agent} lost internet connection`;
        break;
      case ChatEventSubType.Rated:
      case ChatEventSubType.RatedOnly:
        message = `Chat rated ${textVariables.score === Rating.Good ? 'good' : 'bad'}`;
        break;
      case ChatEventSubType.RateCommentedOnly:
        message = `Customer left the comment`;
        break;
      case ChatEventSubType.ChatAssignedOther:
        message = `Assigned - to ${textVariables.agent}`;
        break;
      default:
        message = applyTextVariables(originalMessage, textVariables);
    }
  } catch (error) {
    message = originalMessage;

    if (!(error instanceof TypeError)) {
      Sentry.captureException(error);
    }
  }

  return addCloseLink ? addCloseLinkToMessage(message, eventType) : message;
};

/**
 * Replaces the original system message text with a modified version, mostly
 * in order to address currently logged in agent directly.
 * @param text Original system message text.
 * @param type System message type.
 * @param currentAgentName Currently logged in agent name.
 */
export function replaceSystemMessageText(text: string, type: ChatEventSubType, currentAgentName: string): string {
  if (type === ChatEventSubType.Transferred) {
    return text.replace(
      /(.*transferred the chat to) (.*)\./,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (__, beginning: string, transferTarget: string) =>
        `${beginning} ${transferTarget === currentAgentName ? 'you' : transferTarget}.`
    );
  }

  return text;
}
