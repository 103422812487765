export enum NavigationId {
  Apps = 'apps',
  Archives = 'archives',
  ChatbotInLiveChat = 'chatbot-in-livechat',
  Chats = 'chats',
  Engage = 'engage',
  HelpDeskInLiveChat = 'helpdesk-in-livechat',
  Home = 'home',
  News = 'news',
  Reports = 'reports',
  Settings = 'settings',
  Subscription = 'subscription',
  Team = 'team',
  Tickets = 'tickets',
  Traffic = 'traffic',
  Automate = 'automate',
}

export enum NavigationPath {
  Agents = '/agents',
  Apps = '/apps/marketplace/apps',
  Archives = '/archives',
  Chatbot = '/chatbot-in-livechat',
  Chats = '/chats',
  Engage = '/engage',
  HelpDeskInLiveChat = '/helpdesk-in-livechat',
  Home = '/home',
  MobileProfile = '/mobile-profile',
  OneChat = '/chats/one',
  Reports = '/reports',
  Settings = '/settings',
  Subscription = '/subscription',
  Team = '/team',
  Tickets = '/tickets',
  Traffic = '/traffic',
  Automate = '/automate',
}

export enum NavigationLabel {
  Agents = 'Agents',
  Apps = 'Apps',
  Archives = 'Archives',
  Chatbot = 'ChatBot',
  Chats = 'Chats',
  Engage = 'Engage',
  HelpDesk = 'HelpDesk',
  Home = 'Home',
  News = 'News',
  Reports = 'Reports',
  Settings = 'Settings',
  Team = 'Team',
  Tickets = 'Tickets',
  Traffic = 'Traffic',
  Automate = 'Automate',
}

export enum NavigationTooltipType {
  Automate = 'automate',
  NewVisitor = 'new_visitor',
  HelpDeskApp = 'helpdesk_app',
  HelpDeskPromo = 'helpdesk_promo',
  TagsUsageReport = 'tags_usage_report',
  Traffic = 'traffic',
  TargetedMessages = 'targeted_messages',
  NewAvailability = 'new_availability',
  PrivateSuggestedCanneds = 'private_suggested_canneds',
}

export const NAVIGATION_BAR_WIDTH = 54;
export const SIDE_NAVIGATION_WIDTH = 296;
