import { GoalType } from 'constants/goal';
import { anyToBoolean } from 'helpers/boolean';
import type { IApiBaseGoal, IApiExtendedGoal, IBaseGoal, IExtendedGoal, IGoal } from 'store/entities/goals/interfaces';

export function deserializeGoal(data): IGoal {
  const { id, name, type, active } = data;

  return { id, name, type, active: anyToBoolean(active) };
}

export function deserializeBaseGoal(goal: IApiBaseGoal): IBaseGoal {
  const { id, type, name, active } = goal;

  return { id, type, name, active: anyToBoolean(active) };
}

export function deserializeExtendedGoal(goal: IApiExtendedGoal): IExtendedGoal {
  const { type } = goal;
  const extendedGoal = deserializeBaseGoal(goal);

  switch (type) {
    case GoalType.Api:
      return {
        ...extendedGoal,
        type: GoalType.Api,
      };
    case GoalType.Url:
      return {
        ...extendedGoal,
        type: GoalType.Url,
        matchType: goal.match_type,
        url: goal.url,
      };
    case GoalType.CustomVariable:
      return {
        ...extendedGoal,
        type: GoalType.CustomVariable,
        customVariableName: goal.custom_variable_name,
        customVariableValue: goal.custom_variable_value,
      };
    case GoalType.Goal:
      return {
        ...extendedGoal,
        type: GoalType.Goal,
      };
    case GoalType.Sale:
      return {
        ...extendedGoal,
        type: GoalType.Sale,
        currency: goal.currency,
        value: goal.value,
        valueType: goal.value_type,
        valueFieldName: goal.value_field_name,
        orderFieldName: goal.order_field_name,
        timeRange: goal.time_range,
        salesTrackerId: goal.sales_tracker_id,
      };
    default:
      throw new Error(`Error while deserializing Goal - unknown goal type: ${type as unknown as string}`);
  }
}
