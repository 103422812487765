// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery, all, select, take } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import type { Product } from 'interfaces/product-cart';
import { ApiManager } from 'services/api/api-manager';
import { type ICreateRecurringRequestResult } from 'services/api/billing/interfaces';
import { deserializeApplicationRecurrings } from 'services/api/billing/recurrings-serializer';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';
import { ApplicationsActionNames as EntitiesApplicationsActionNames } from 'store/entities/applications/actions';
import { Frequency, type IApplication, type IApplicationIcons } from 'store/entities/applications/interfaces';
import { BillingActions } from 'store/entities/billing/actions';
import { type EntityFetchCollectionSuccess } from 'store/entities/interfaces';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { getCanManageSubscription } from 'store/features/session/selectors';
import { type IActionWithPayload } from 'store/helper';

export async function fetchApplicationRecurring(
  activeAgentsCount: number,
  clientId: string,
  applicationId: string,
  icons?: IApplicationIcons
): Promise<Product> {
  const { result, error }: RequestResult<{ result: ICreateRecurringRequestResult[] }> =
    await ApiManager.billingApi.fetchRecurrings(clientId);
  if (error || !result?.result?.length) {
    return null;
  }

  return deserializeApplicationRecurrings(activeAgentsCount, result.result, applicationId, icons);
}

function* fetchRecurrentPayments(
  action: IActionWithPayload<string, EntityFetchCollectionSuccess<IApplication>>
): SagaIterator {
  const canManageSubscription = yield select(getCanManageSubscription);
  if (!canManageSubscription) {
    return;
  }

  const subscription = yield select(getSubscription);
  if (!subscription) {
    yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
  }

  const filteredApplications = (action.payload.values || []).filter(
    ({ payment }) => payment && payment.frequency !== Frequency.Once
  );

  const activeAgentsCount: number = yield select(getActiveAgentsCount);

  const mappedApplications: Product[] = yield all(
    filteredApplications.map(({ authorization, id, icons }) =>
      call(fetchApplicationRecurring, activeAgentsCount, authorization.clientId, id, icons)
    )
  );
  yield put(BillingActions.setRecurrentApplications(mappedApplications.filter(Boolean)));
}

export function* fetchRecurrentPaymentsSaga(): SagaIterator {
  yield takeEvery(
    EntitiesApplicationsActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS],
    fetchRecurrentPayments
  );
}
