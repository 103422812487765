import { type ParamValue } from './url';

export interface ICookiesOptions {
  days?: number;
  domain?: string;
}

export type CookieItem = object | ParamValue | null;

/**
 * A helper method to add days to a date.
 * @param date The date to add days to.
 * @param days The number of days to add.
 * @returns A new date with the added days.
 */
export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
}

/**
 * Retrieves the value of a specified cookie.
 *
 * @param key - The name of the cookie to retrieve.
 * @returns The value of the cookie if it exists, otherwise null.
 */
export function getCookie<T = CookieItem>(key: string): T | null {
  const cookies = document.cookie.split(';');
  const found = cookies.find((cookie) => {
    const [cookieName] = cookie.trim().split('=');

    return decodeURIComponent(cookieName) === key;
  });

  if (!found) {
    return null;
  }

  const [, cookieValue] = found.split('=');
  const cookie = decodeURIComponent(cookieValue);

  try {
    return JSON.parse(cookie) as T;
  } catch (_) {
    return cookie as unknown as T | null;
  }
}

/**
 * Sets a cookie with the specified key and value.
 *
 * @param key - The name of the cookie to set.
 * @param value - The value to set for the cookie.
 * @param options - Optional settings for the cookie.
 */
export function setCookie(key: string, value: CookieItem, options: ICookiesOptions = {}): void {
  const { days, domain } = options;
  const path = '/';

  const expires = days ? `; expires=${addDays(new Date(), days).toUTCString()}` : '';
  const writePath = path ? `; path=${path}` : '';
  const writeDomain = domain ? `; domain=${domain}` : '';
  const writeSecure = window.location.protocol === 'https:' ? '; secure' : '';
  const stringifiedValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
  const cookie = `${encodeURIComponent(key)}=${encodeURIComponent(stringifiedValue)}`;
  document.cookie = `${cookie}${expires}${writePath}${writeDomain}${writeSecure}`;
}

/**
 * Removes a cookie with the specified key.
 *
 * @param key - The name of the cookie to remove.
 * @param domain - The domain of the cookie to remove.
 */
export function removeCookie(key: string, domain?: string): void {
  setCookie(key, '', { days: -1, domain });
}

/**
 * Clears all cookies.
 */
export function clearCookies(): void {
  document.cookie.split(';').forEach((c) => {
    const [cookieName] = c.replace(/^ +/, '').split('=');
    document.cookie = `${cookieName}=;expires=${new Date().toUTCString()};path=/`;
  });
}

/**
 * Deletes all cookies for specific paths except the root path.
 * The paths where cookies will be deleted are app's sections.
 * This function does not return any value.
 */
export function deleteAllCookiesExceptRoot(): void {
  const cookies = document.cookie.split(';');

  const paths = ['/chats', '/archives', '/tickets', '/engage', '/reports', '/settings', '/team'];

  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();

    paths.forEach((path) => {
      document.cookie = `${cookieName}=;path=${path};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    });
  });
}
