type LogLevels = 'log' | 'info' | 'warn' | 'error';

type LogData = Parameters<Console['log']>;

interface LogEntry {
  timestamp: string;
  level: LogLevels;
  data: LogData;
}

class ConsoleLogHistoryManager {
  private static readonly maxStackSize = 50;

  private static readonly logLevels: LogLevels[] = ['log', 'info', 'warn', 'error'];

  private originals = new Map<LogLevels, Console['log']>();

  history: LogEntry[] = [];

  startCapture(): void {
    if (this.originals.size) {
      return;
    }

    ConsoleLogHistoryManager.logLevels.forEach((level) => {
      /* eslint-disable no-console */
      this.originals.set(level, console[level]);
      console[level] = (...data: LogData): void => {
        /* eslint-enable no-console */
        if (this.history.length >= ConsoleLogHistoryManager.maxStackSize) {
          this.history.shift();
        }

        this.history.push({
          timestamp: new Date().toISOString(),
          level,
          data,
        });
        this.originals.get(level)?.(...data);
      };
    });
  }
}

export const consoleLogHistoryManager = new ConsoleLogHistoryManager();
