import { createGlobalAccountsApiClient } from '../client';
import { type GlobalAccountsApiErrorResponse } from '../types';

import { type TokenInfoResponse } from './types';

const client = createGlobalAccountsApiClient();

/**
 * Global Accounts API tokens client.
 * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Tokens}
 */
export const tokensClient = {
  /**
   * Retrieves the details of an access token.
   * @param code - The authorization token code. Optional, as the token may be passed in the Authorization header.
   * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Tokens/paths/~1info/get}
   */
  info: async (code?: string) => {
    const searchParams = code ? { code } : undefined;

    return client.get<TokenInfoResponse, GlobalAccountsApiErrorResponse>('info', { searchParams });
  },
};
