import { createElement, memo, useEffect, useState, type FC, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { Tooltip, NavigationItem as DSNavigationItem } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { type BadgeValue, useBadgeCounter } from 'components/new-navigation/hooks/use-badge-counter';
import type { NavigationId } from 'constants/navigation';
import { getCurrentSection } from 'store/features/routing/selectors';

import { navigationItems } from '../../configuration';
import { useNavigationTooltip } from '../../hooks/use-navigation-tooltip';
import { NavigationIcon } from '../../navigation-icon/NavigationIcon';

import { activityComparator as defaultActivityComparator, onItemClick as defaultOnItemClick } from './helpers';

import * as styles from './styles';

interface Props {
  id: NavigationId;
  className?: string;
}

export const NavigationItem: FC<Props> = memo(({ id, className }) => {
  const section = useSelector(getCurrentSection);
  const tooltip = useNavigationTooltip(id);
  const config = navigationItems.get(id);
  const [badge, setBadge] = useState<BadgeValue>(null);
  const getBadgeValueById = useBadgeCounter();
  const badgeValue = getBadgeValueById(config?.badgeId);

  useEffect(() => {
    setBadge(badgeValue);
  }, [badgeValue]);

  if (!config) {
    return null;
  }

  const {
    icon: { inactiveIcon, activeIcon, className: iconClassName },
    title,
    label,
    testId,
    activityComparator,
    onItemClick,
    onItemHover,
  } = config;

  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  const trackingTitle = config.trackingTitle!;
  const path = config.path!;
  /* eslint-enable @typescript-eslint/no-unnecessary-type-assertion */
  const handleItemClick = (event: MouseEvent<HTMLAnchorElement>): void =>
    onItemClick
      ? onItemClick({ event, path, trackingTitle, defaultOnItemClick })
      : defaultOnItemClick({ event, path, trackingTitle });

  const isActive = activityComparator
    ? activityComparator({ section, id })
    : defaultActivityComparator({ section, id });

  const handleItemHover = (event: MouseEvent<HTMLAnchorElement>): void => onItemHover?.({ event, path });

  const item = (
    <DSNavigationItem
      data-testid={testId}
      key={title || label}
      id={title || label}
      label={title || label}
      icon={
        <NavigationIcon
          inactiveIcon={inactiveIcon}
          activeIcon={activeIcon}
          isActive={isActive}
          className={iconClassName}
        />
      }
      onClick={handleItemClick}
      isActive={isActive}
      url={path}
      {...(badge && { badge })} // eslint-disable-line react/jsx-props-no-spreading
      onMouseEnter={handleItemHover}
    />
  );

  return (
    <li className={cx(styles.container, className)}>
      {tooltip && tooltip.content ? (
        <Tooltip
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tooltip.props}
          className={styles.tooltip}
          triggerRenderer={() => item}
        >
          {createElement(tooltip.content)}
        </Tooltip>
      ) : (
        item
      )}
    </li>
  );
});
