import { type IVisitedPage } from 'store/entities/customers/interfaces';

const FILTERED_HOSTS = ['direct-labs.lc.chat', 'cdn.livechatinc.com'];

export const getListOfVisitedPages = (visitedPages: Record<string, IVisitedPage>): string[] => [
  ...new Set(
    Object.values(visitedPages)
      .map((visitedPage) => new URL(visitedPage.pageURL).hostname)
      .filter((item) => !FILTERED_HOSTS.includes(item)),
  ),
];
