import { type ChatRecipients } from 'constants/chat-recipients';
import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type AddUserToChatEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#add-user-to-chat
 */
export async function addUserToChat(
  chatId: string,
  userId: string,
  userType: 'agent' | 'customer',
  visibility: ChatRecipients
): Promise<unknown> {
  const event: AddUserToChatEvent = {
    action: WebSocketAction.AddUserToChat,
    payload: {
      /* eslint-disable @typescript-eslint/naming-convention */
      chat_id: chatId,
      user_id: userId,
      user_type: userType,
      /* eslint-enable @typescript-eslint/naming-convention */
      visibility,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
