import type { IFetchAgentCustomPropertiesSuccessPayload } from 'store/features/agent-custom-properties/interfaces';
import { type ActionsUnion, createAction } from 'store/helper';

import { type IInsightsMetricDetails, type InsightsCoreData, type InsightsHintId } from './interfaces';

export enum InsightsActionsNames {
  GET_INSIGHTS = 'ENTITIES/INSIGHTS/GET_INSIGHTS',
  GET_INSIGHTS_SUCCESS = 'ENTITIES/INSIGHTS/GET_INSIGHTS_SUCCESS',
  GET_INSIGHTS_FAILURE = 'ENTITIES/INSIGHTS/GET_INSIGHTS_FAILURE',
  SELECT_METRIC = 'ENTITIES/INSIGHTS/SELECT_METRIC',
  ACKNOWLEDGE_HINT = 'ENTITIES/INSIGHTS/ACKNOWLEDGE_HINT',
  ACKNOWLEDGE_HINT_SUCCESS = 'ENTITIES/INSIGHTS/ACKNOWLEDGE_HINT_SUCCESS',
}

export const InsightsActions = {
  getInsights: (agentCustomProps: IFetchAgentCustomPropertiesSuccessPayload) =>
    createAction(InsightsActionsNames.GET_INSIGHTS, agentCustomProps),
  getInsightsSuccess: (payload: InsightsCoreData) => createAction(InsightsActionsNames.GET_INSIGHTS_SUCCESS, payload),
  getInsightsFailure: () => createAction(InsightsActionsNames.GET_INSIGHTS_FAILURE),
  selectMetric: (metricDetails: IInsightsMetricDetails | null) =>
    createAction(InsightsActionsNames.SELECT_METRIC, metricDetails),
  acknowledgeHint: (hintId: InsightsHintId) => createAction(InsightsActionsNames.ACKNOWLEDGE_HINT, hintId),
  acknowledgeHintSuccess: (hintId: InsightsHintId) =>
    createAction(InsightsActionsNames.ACKNOWLEDGE_HINT_SUCCESS, hintId),
};

export type InsightsAction = ActionsUnion<typeof InsightsActions>;
