// @ts-strict-ignore
import { PureComponent } from 'react';

import { cx } from '@emotion/css';
import { NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';

import { EventPlace } from 'helpers/analytics';
import { openInNewCard } from 'helpers/routing';
import { plural } from 'helpers/string';
import { trackEvent } from 'services/event-tracking';

import * as styles from './styles';

const WARNING_DAYS_COUNTER = 7;
const EMAIL_SUBJECT =
  '?subject=%5BURGENT%5D%20Expiring%20LiveChat%20Trial&body=Hi%20there!%0AWe%20would%20like%20to%20continue%20using%20LiveChat%20on%20our%20website%20and%20we%20need%20your%20help.%20Please%20check%20our%20plan%20and%20seat%20number%20by%20clicking%20the%20link%20below%20and%20subscribe.%0A%0Ahttps://my.livechatinc.com/subscription/%0A%0AThanks!';

interface IOwnProps {
  daysLeft: number;
  onNavigateToSubscription();
  canManageSubscription: boolean;
  viceOwnersEmails: string[];
  isVisible: boolean;
}

interface IProps extends Partial<IOwnProps> {}

export class TrialExpiringBar extends PureComponent<IProps> {
  handleClickContactAdmins = () => {
    const { viceOwnersEmails } = this.props;
    const emailToContent = `mailto:${viceOwnersEmails ? `,${viceOwnersEmails.join(',')}` : ''}${EMAIL_SUBJECT}`;

    trackEvent('Trial expiring contact administrator clicked', EventPlace.TopBar, {
      trialDaysLeft: this.props.daysLeft,
    });
    openInNewCard(emailToContent);
  };

  handleClickContactAdministrator = () => {
    trackEvent('Trial expiring contact administrator clicked', EventPlace.TopBar, {
      trialDaysLeft: this.props.daysLeft,
    });
  };

  handleSubscribeNow = () => {
    trackEvent('Subscribe now link clicked', EventPlace.TopBar, {
      trialDaysLeft: this.props.daysLeft,
    });
    this.props.onNavigateToSubscription();
  };

  daysLeftCards(): JSX.Element[] | null {
    const { daysLeft } = this.props;
    if (daysLeft !== undefined) {
      const daysLeftString = daysLeft.toString().split('');
      const showWarning = daysLeft <= WARNING_DAYS_COUNTER;

      return daysLeftString.map((item, key) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <div className={styles.numberCard(showWarning)} key={key}>
          {item}
        </div>
      ));
    }

    return null;
  }

  render() {
    const { canManageSubscription, daysLeft } = this.props;
    const btnText = canManageSubscription ? 'Upgrade now' : 'Contact administrators to upgrade';
    const handleClick = canManageSubscription ? this.handleSubscribeNow : this.handleClickContactAdmins;

    return (
      <NavigationTopBarAlert
        kind="info"
        isVisible={this.props.isVisible}
        primaryCta={{
          label: btnText,
          onClick: handleClick,
          size: 'compact',
        }}
      >
        <span className={cx(styles.contentContainer, styles.trialExpiringBar)}>
          {this.daysLeftCards()}
          <Text as="span">{plural(daysLeft, 'day', 'days')} left in your trial.</Text>
        </span>
      </NavigationTopBarAlert>
    );
  }
}
