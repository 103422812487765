import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';
import { getSeriesDataFromPeriod, sumValueFromPeriod } from 'helpers/reports';
import type { ITotalChatsReportData, ITotalChatPeriod } from 'interfaces/reports';
import type { ReportData } from 'interfaces/reports/api-v3';

import { REPORT_TOTAL_CHATS_SUBPATH } from '../constants';

export const REPORT_CONTINUOUS_CHATS_SUBPATH = 'continuous';
const REPORT_NAME = 'Total chats';

interface TotalChatsPayload {
  data: {
    totalChats: ReportData<ITotalChatPeriod>;
  };
}

export function sumArray(arr: number[]): number {
  return arr.reduce((sum, value) => sum + value);
}

export function subArrays(arr1: number[], arr2: number[]): number[] {
  const result: number[] = [];
  for (let i = 0; i < arr1.length; i += 1) {
    result.push(arr1[i] - arr2[i]);
  }

  return result;
}

export function deserializeTotalChats({ data }: TotalChatsPayload): ITotalChatsReportData {
  const chatsSeries = getSeriesDataFromPeriod<number>(data.totalChats.records, REPORT_TOTAL_CHATS_SUBPATH);

  return {
    totalChats: {
      summary: [
        {
          color: DEFAULT_SERIES_COLOR,
          value: sumValueFromPeriod(data.totalChats.records, REPORT_TOTAL_CHATS_SUBPATH),
          name: REPORT_NAME,
        },
      ],
      labels: Object.keys(data.totalChats.records),
      series: [
        {
          name: REPORT_NAME,
          color: DEFAULT_SERIES_COLOR,
          data: chatsSeries,
          csvColumnName: 'chats',
        },
      ],
    },
  };
}
