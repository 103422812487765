import { parseDate } from 'helpers/date';

import { type IChatSurveysData } from '../../../../interfaces/reports';

function getLabels(type: string, required: boolean): string[] {
  const options: string[] = [];

  switch (type) {
    case 'radio':
      options.push('Choice list');
      break;
    case 'skill':
      options.push('Group selection');
      break;
    case 'select':
      options.push('Dropdown');
      break;
    case 'checkbox':
      options.push('Multiple choice list');
      break;
    default:
      break;
  }

  options.push(required ? 'Required' : 'Optional');

  return options;
}

export function chatSurveyDeserializer({ data }: any): IChatSurveysData {
  return {
    surveys: data.chatSurveys
      .map((survey) => {
        const group = survey.questions[0] && survey.questions[0].group;
        const created = survey.questions[0] && survey.questions[0].created;

        return {
          created: parseDate((parseInt(created, 10) * 1000).toString()),
          filledCount: survey.total,
          groupId: String(group),
          questions: survey.questions
            .filter((question) => question.type !== 'rating')
            .map(({ id: questionId, question, type, filled, required, answers }) => ({
              id: questionId,
              type,
              labels: getLabels(type, required),
              question,
              answersCount: filled,
              answers: answers.map(({ id, name, count }) => ({ id, name, count })),
            })),
        };
      })
      .reverse(),
  };
}
