// @ts-strict-ignore
import { OnboardingActionsEnum, type OnboardingActionType } from './actions';
import { type IOnboardingState } from './interfaces';

const initialState: IOnboardingState = {
  isOnboardingFinished: false,
  isShowingInviteDeveloperForm: false,
  isCompanySizeDetected: false,
  isInstallationStepSkipped: false,
  isRefetchTechnologies: true,
};

export const onboardingReducer = (
  state: IOnboardingState = initialState,
  action?: OnboardingActionType
): IOnboardingState => {
  switch (action.type) {
    case OnboardingActionsEnum.ONBOARDING_FINISHED: {
      return {
        ...state,
        isOnboardingFinished: true,
      };
    }

    case OnboardingActionsEnum.ONBOARDING_STATUS_FETCHED: {
      return {
        ...state,
        isOnboardingFinished: action.payload.isOnboardingFinished,
      };
    }

    case OnboardingActionsEnum.SET_SHOW_INVITE_DEVELOPER_FORM: {
      return {
        ...state,
        isShowingInviteDeveloperForm: action.payload.isShowingInviteDeveloperForm,
      };
    }

    case OnboardingActionsEnum.SET_IS_COMPANY_SIZE_DETECTED: {
      return {
        ...state,
        isCompanySizeDetected: action.payload.isCompanySizeDetected,
      };
    }

    case OnboardingActionsEnum.SET_SKIP_INSTALLATION_STEP: {
      return {
        ...state,
        isInstallationStepSkipped: true,
      };
    }

    case OnboardingActionsEnum.SET_IS_REFETCH_TECHNOLOGIES: {
      return {
        ...state,
        isRefetchTechnologies: action.payload.isRefetch,
      };
    }

    default:
      return state;
  }
};
