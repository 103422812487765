import type { GroupBase } from 'interfaces/groups';

export interface IAssignee {
  id: string;
  name: string;
}

export interface ICc {
  email: string;
}

export interface IRecentTicket {
  id: string;
  status: TicketStatus;
  subject: string;
}

export interface IRequester {
  email: string;
  ip: string;
  name?: string;
  recentTickets?: IRecentTicket[];
}

export enum SourceType {
  Chat = 'lc2',
  Email = 'mail',
  TicketForm = 'chat-window',
  Manual = 'agent-app-manual',
}

export enum Author {
  Agent = 'agent',
  Customer = 'client',
}

export interface IEventAuthor {
  id: string;
  name: string;
  type: Author;
}

export enum EventType {
  Message = 'message',
  CompressionMessage = 'compression_message',
  AssigneeChanged = 'assignee_changed',
  AssigneeRemoved = 'assignee_removed',
  StatusChanged = 'status_changed',
  CcChanged = 'cc_changed',
  SubjectChanged = 'subject_changed',
  RequesterChanged = 'requester_changed',
  GroupAdded = 'skill_added',
  GroupRemoved = 'skill_removed',
  CurrentGroupAdded = 'current_skill_added',
  CurrentGroupChanged = 'current_skill_changed',
  CurrentGroupRemoved = 'current_skill_removed',
  FollowUpSent = 'follow_up_sent',
  RatingOfferSent = 'rating_offer_sent',
  RatingOfferRefused = 'rating_offer_refused',
  Rated = 'rated',
}

export interface IEvent {
  date: Date;
  isPrivate: boolean;
  type: EventType;
  author?: IEventAuthor;
  message?: string;
  from?: {
    id: string;
    name: string;
  };
  to?: {
    id: string;
    name: string;
  };
  current?: string;
  previous?: string;
  attachments?: IAttachment[];
}

export enum TicketStatus {
  Open = 'open',
  Pending = 'pending',
  Solved = 'solved',
  Spam = 'spam',
}

export interface ITicketSource {
  type: SourceType;
  id?: string;
  url?: string;
}

export interface IAttachment {
  contentType: string;
  name: string;
  url: string;
  width?: number;
  height?: number;
}

export interface ITicket {
  events: IEvent[];
  id: string;
  requester: IRequester;
  groups: GroupBase[];
  status: TicketStatus;
  subject: string;
  modified: Date;
  source: ITicketSource;
  tags: string[];
  date: Date;
  currentGroup: GroupBase;
  assignee?: IAssignee;
  ccs?: ICc[];
  draft?: IDraft;
  isSample?: boolean;
}

export interface IDraft {
  message: string;
  isPrivate?: boolean;
  status?: TicketStatus;
  subject?: string;
  requesterName?: string;
  requesterEmail?: string;
  assigneeId?: string;
  groupIds?: string[];
}
