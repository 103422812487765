import { type RefObject, useRef, useCallback, useEffect, useState, useMemo } from 'react';

import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { useDispatch, useSelector } from 'react-redux';

import { UserType } from 'constants/user-type';
import { usePrevious } from 'hooks/use-previous';
import { type CopilotEvent } from 'store/entities/copilot/interfaces';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCopilotScrollPosition, getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import { useCopilotMessageContext } from '../CopilotMessageContext';

interface Props {
  scrollRef: RefObject<HTMLDivElement>;
  groupedEvents: CopilotEvent[][];
  isLoading: boolean;
}

interface UseScroll {
  handleScroll: () => void;
  scrollToBottom: () => void;
}

const SCROLL_DEBOUNCE_TIME = 100;
const SCROLL_AT_BOTTOM_THRESHOLD = 25;
const SCROLL_THROTTLE_TIME = 50;

export const useScroll = ({ scrollRef, groupedEvents, isLoading }: Props): UseScroll => {
  const dispatch = useDispatch();

  const [isScrolledManually, setIsScrolledManually] = useState(false);
  const isScrolledManuallyRef = useRef(false);

  const isInitialScrollSet = useRef(false);
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const previousExpanded = usePrevious(isExpanded);
  const copilotScrollPosition = useSelector(getCopilotScrollPosition);
  const currentScrollPosition = useRef<number | null>(copilotScrollPosition);
  const { message } = useCopilotMessageContext();
  isScrolledManuallyRef.current = isScrolledManually;

  const scrollToBottom = useCallback(() => {
    scrollRef.current?.scrollTo({ top: scrollRef.current?.scrollHeight });
  }, [scrollRef]);

  const throttledScrollToBottom = useMemo(
    () => throttle(scrollToBottom, SCROLL_THROTTLE_TIME, { leading: true, trailing: true }),
    [scrollToBottom],
  );

  // Perform scroll to bottom if we are receiving new characters from streaming message and user is not scrolled manually
  useEffect(() => {
    if (isLoading && !isScrolledManually) {
      throttledScrollToBottom();
    }
  }, [message, isLoading, throttledScrollToBottom, isScrolledManually]);

  const setScrollPosition = useCallback(() => {
    dispatch(CopilotViewActions.setScrollPosition(currentScrollPosition?.current || null));
  }, [dispatch]);

  // eslint-disable-next-line react-compiler/react-compiler
  const debouncedSetScrollPositionRef = useRef(debounce(setScrollPosition, SCROLL_DEBOUNCE_TIME));

  useEffect(() => {
    debouncedSetScrollPositionRef.current = debounce(setScrollPosition, SCROLL_DEBOUNCE_TIME);
  }, [setScrollPosition]);

  useEffect(() => {
    return () => {
      debouncedSetScrollPositionRef.current.cancel();
    };
  }, []);

  const handleScroll = (): void => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    currentScrollPosition.current = scrollTop ?? null;
    const atBottom = scrollHeight - clientHeight <= scrollTop + SCROLL_AT_BOTTOM_THRESHOLD;
    setIsScrolledManually(!atBottom);
    // Perform debounced scroll position save to store
    debouncedSetScrollPositionRef.current();
  };

  useEffect(() => {
    if (!!copilotScrollPosition && !isInitialScrollSet.current) {
      scrollRef?.current?.scrollTo({ top: copilotScrollPosition });
      currentScrollPosition.current = copilotScrollPosition;
    }
    isInitialScrollSet.current = true;
  }, [copilotScrollPosition]);

  useEffect(() => {
    const [lastGroupEvent] = groupedEvents.slice(-1)[0];

    const isLastMessageFromAgent = lastGroupEvent?.authorType === UserType.Agent;
    const isLastMessageFromCopilot = lastGroupEvent?.authorType === 'live-assistant';

    // Perform scroll to bottom if the last message is from agent
    if (isLastMessageFromAgent) {
      scrollToBottom();

      return;
    }
    /*
     * Perform scroll to bottom if user is not scrolled manually.
     * This handles case where we add event to history and we add additional component to message which contain sources list.
     * As this component adds new height we should scrollToBottom to display whole component
     */
    if (isLastMessageFromCopilot && !isScrolledManuallyRef.current) {
      scrollToBottom();
    }
  }, [groupedEvents, scrollToBottom]);

  // We should always scroll to bottom when the modal is expanded or collapsed
  useEffect(() => {
    if (isExpanded !== previousExpanded && previousExpanded !== null) {
      scrollToBottom();
    }
  }, [isExpanded, previousExpanded, scrollToBottom]);

  return { handleScroll, scrollToBottom };
};
