import { createAction } from 'store/helper';

import { type IPerformActionPayload } from './interfaces';

export enum ChatButtonsActionsNames {
  PERFORM_ACTION = 'FEATURES/CHAT_BUTTONS/PERFORM_ACTION',
}

export const ChatButtonsActions = {
  performAction: (payload: IPerformActionPayload) => createAction(ChatButtonsActionsNames.PERFORM_ACTION, payload),
};
