export const enum ProductBillingCycle {
  Monthly = 'pay-monthly',
  Once = 'pay-once',
  Annually12 = 'pay-yearly',
  Annually24 = 'pay-yearly-24',
  Annually36 = 'pay-yearly-36',
}

export const enum PaymentType {
  PerAgent = 'per-agent',
  PerOrganization = 'per-organization',
}
