// @ts-strict-ignore
import { isNullOrNotArray } from 'helpers/data';
import type { RichMessageDetailsResult } from 'interfaces/api/event/rich-message';

function deserializeRichMessageImageDetails(imageDetails: { [key: string]: any }): RichMessageDetailsResult['image'] {
  return imageDetails ? { url: imageDetails.url, link: imageDetails.link, srcset: imageDetails.srcset } : null;
}

export function deserializeRichMessageDetails(
  richMessageDetails: { [key: string]: any }[]
): RichMessageDetailsResult[] {
  if (isNullOrNotArray(richMessageDetails)) {
    return null;
  }

  return richMessageDetails.map((details) => ({
    title: details.title,
    subtitle: details.subtitle,
    image: deserializeRichMessageImageDetails(details.image),
    buttons: Array.isArray(details.buttons)
      ? details.buttons.map((button) => ({
          type: button.type,
          text: button.text,
        }))
      : null,
  }));
}
