// @ts-strict-ignore
import { isBefore } from 'date-fns';

import { AUTOMATE_SECTION_RELEASE_DATE } from 'constants/automate/constants';
import { SettingsRoutePage } from 'constants/settings/routing';
import { type ISideNavigationItem, OnOffBadge } from 'interfaces/side-navigation-item';
import { AppStateProvider } from 'services/app-state-provider';
import { hasRight, getCanUseFeature, fulfillsExperimentRestrictions } from 'store/features/session/selectors';

import { getEnvironment } from './feature-toggle';
import { isWiXIntegration } from './is-wix-integration';

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
function mapItemByRights(item: ISideNavigationItem): ISideNavigationItem {
  const state = AppStateProvider.getState();

  if (item.featureAccess && !getCanUseFeature(state, item.featureAccess)) {
    return null;
  }

  if (item.right && !hasRight(state, item.right)) {
    return null;
  }

  if (item.children) {
    return {
      ...item,
      children: item.children.map((childItem) => mapItemByRights(childItem)).filter(Boolean),
    };
  }

  return item;
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function filterItemsByRights(items: ISideNavigationItem[]): ISideNavigationItem[] {
  return items.map((item) => mapItemByRights(item)).filter(Boolean);
}

function appendBadge(item: ISideNavigationItem, itemId: string): ISideNavigationItem {
  if (item.id === itemId) {
    return {
      ...item,
      badge: true,
    };
  }

  if (item.children) {
    return {
      ...item,
      children: item.children.map((childItem) => appendBadge(childItem, itemId)).filter(Boolean),
    };
  }

  return item;
}

export function filterItemsByExperiment(items: ISideNavigationItem[]): ISideNavigationItem[] {
  const state = AppStateProvider.getState();

  return items
    .filter((item) => fulfillsExperimentRestrictions(state, item.experiment))
    .map((item) => ({
      ...item,
      ...(item.children && {
        children: item.children.filter((childItem) => fulfillsExperimentRestrictions(state, childItem.experiment)),
      }),
    }));
}

export function filterItemsByIntegration(items: ISideNavigationItem[]): ISideNavigationItem[] {
  const predicate = (item: ISideNavigationItem): boolean => {
    if (item.id === 'chat-buttons') {
      return !isWiXIntegration();
    }

    return true;
  };

  return items
    .filter(predicate)
    .map((item) => ({ ...item, ...(item.children && { children: item.children.filter(predicate) }) }));
}

export function filterItemsByAutomateSectionAvailability(
  items: ISideNavigationItem[],
  licenseCreationDate: number,
): ISideNavigationItem[] {
  const isLicenseCreatedBeforeAutomationSection = isBefore(
    new Date(licenseCreationDate),
    AUTOMATE_SECTION_RELEASE_DATE,
  );

  const sectionsMovedToAutomate = [
    `/settings/${SettingsRoutePage.CannedResponses}`,
    `/settings/${SettingsRoutePage.GroupsRouting}`,
    `/settings/${SettingsRoutePage.SuggestedResponses}`,
  ];
  const predicate = (item: ISideNavigationItem): boolean => {
    return !(!isLicenseCreatedBeforeAutomationSection && sectionsMovedToAutomate.includes(item.path));
  };

  return items
    .filter(predicate)
    .map((item) => ({ ...item, ...(item.children && { children: item.children.filter(predicate) }) }));
}

export function filterItemsByCustomCheck(items: ISideNavigationItem[]): ISideNavigationItem[] {
  const predicate = (item: ISideNavigationItem): boolean => {
    if (item.customCheck) {
      return item.customCheck();
    }

    return true;
  };

  return items
    .filter(predicate)
    .map((item) => ({ ...item, ...(item.children && { children: item.children.filter(predicate) }) }));
}

export function filterItemsByEnvironment(items: ISideNavigationItem[]): ISideNavigationItem[] {
  const predicate = (item: ISideNavigationItem): boolean => {
    return !item.allowedEnv || item.allowedEnv.includes(getEnvironment());
  };

  return items
    .filter(predicate)
    .map((item) => ({ ...item, ...(item.children && { children: item.children.filter(predicate) }) }));
}

export function displayTrackingCodeBadge(items: ISideNavigationItem[]): ISideNavigationItem[] {
  return items.map((item) => appendBadge(item, 'channels')).filter(Boolean);
}

function appendOnOffBadge(item: ISideNavigationItem, itemId: string, onOffBadge: OnOffBadge): ISideNavigationItem {
  if (item.id === itemId) {
    return {
      ...item,
      onOffBadge,
    };
  }

  if (item.children) {
    return {
      ...item,
      children: item.children.map((childItem) => appendOnOffBadge(childItem, itemId, onOffBadge)),
    };
  }

  return item;
}

export function displayOnOffBadge(items: ISideNavigationItem[], itemId: string, isOn: boolean): ISideNavigationItem[] {
  return items.map((item) => appendOnOffBadge(item, itemId, isOn ? OnOffBadge.On : OnOffBadge.Off));
}

interface IActiveItems {
  activeItem: ISideNavigationItem;
  activeParentItem: ISideNavigationItem;
}

/**
 * Find both parent & child items according to tree structure
 * that leads to an item with id={activeItemId}
 */
export function getActiveItems(items: ISideNavigationItem[], activeItemId: string): IActiveItems {
  let activeItem = null;
  let activeParentItem = null;

  if (activeItemId === undefined) {
    return { activeItem, activeParentItem };
  }

  activeParentItem = items.find((parentItem) => parentItem.id === activeItemId);
  if (activeParentItem) {
    activeItem = activeParentItem;
  } else {
    items.some((parentItem) => {
      if (parentItem.children) {
        activeItem = parentItem.children.find((childItem) => childItem.id === activeItemId);
        if (activeItem) {
          activeParentItem = parentItem;
        }
      }

      return activeItem;
    });
  }

  return { activeItem, activeParentItem };
}

export function updateCodeInstallation(items: ISideNavigationItem[], isCodeInstalled: boolean): ISideNavigationItem[] {
  return items.map((item) => {
    if (item.id !== 'channels') {
      return item;
    }

    return {
      ...item,
      children: item.children.map((item) => {
        if (item.id !== 'code') {
          return item;
        }

        return {
          ...item,
          important: !isCodeInstalled,
          badge: !isCodeInstalled,
        };
      }),
    };
  });
}
