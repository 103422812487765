// @ts-strict-ignore
import { useSelector } from 'react-redux';

import { PartnerType } from 'interfaces/entities/current-license';
import { CoreProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';
import { getIsOnTrial, getIsPPABusinessModel } from 'store/features/session/selectors';

import { useLicenseProperty } from './connectivity/configuration-api/properties/use-license-property';
import { useSubscription } from './use-subscription';

interface UsePartnerType {
  partnerType: PartnerType | undefined;
  isFetching: boolean;
  isReseller: boolean;
  isInvitingBlockedForReseller: boolean;
}

export function usePartnerType(): UsePartnerType {
  const { data: partnerType, isFetching } = useLicenseProperty<PartnerType>(
    PlatformNamespace.CORE,
    CoreProperty.PartnerType,
  );
  const isReseller = partnerType === PartnerType.Reseller;

  const isTrial = useSelector(getIsOnTrial);
  const isPPA = useSelector(getIsPPABusinessModel);
  const agentsCount = useSelector(getActiveAgentsCount);

  const { data: subscription } = useSubscription();

  const isInvitingBlockedForReseller =
    isReseller &&
    (!isTrial || (isTrial && !!subscription?.currentPeriodStartedAt)) &&
    subscription?.seats <= agentsCount &&
    isPPA;

  return {
    isFetching,
    partnerType,
    isReseller,
    isInvitingBlockedForReseller,
  };
}
