import type { RequestResult } from 'interfaces/api/client';
import { BaseApi } from '../base-api';

export class TrustedDomainsApi extends BaseApi {
  protected readonly prefix = 'v2/domain_whitelist';

  fetchAll = () => this.get();
  add = (domain: string): Promise<RequestResult<unknown>> => this.post(``, { domain });
  remove = (domain: string): Promise<RequestResult<unknown>> => this.delete(`${encodeURIComponent(domain)}`);
}
