import { useCallback, useEffect, useState, type FC } from 'react';

import { Error } from '@livechat/design-system-icons';
import { Button, Heading, Icon, Modal, ModalHeader, ModalPortal, Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { DISCARD_CHANGES_EVENT_NAME } from 'constants/global-events';
import { type CallbackFunction } from 'helpers/await-emitter';
import { historyService } from 'services/history';
import { UnsavedChanges } from 'store/features/unsaved-changes/actions';
import { getHasUnsavedChanges } from 'store/features/unsaved-changes/selectors';
import { DiscardChangesModalActions } from 'store/views/discard-changes-modal/actions';
import { isDiscardChangesModalOpen } from 'store/views/discard-changes-modal/selectors';
import { StackingContextLevel } from 'styles/stacking-context-level';

import { CANCEL_BUTTON_LABEL, UNSAVED_CHANGES_DETECTED_MESSAGE } from './constants';
import { shouldIgnoreDiscardChangesModal } from './helpers/discard-changes-modal';

import * as styles from './styles';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  cancelButtonLabel?: string;
}

export const DiscardChangesModal: FC<Props> = ({
  onConfirm,
  onCancel,
  isOpen,
  cancelButtonLabel = CANCEL_BUTTON_LABEL,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalPortal zIndex={StackingContextLevel.Modal}>
      <Modal
        heading={<ModalHeader />}
        className={styles.modal}
        onClose={onCancel}
        footer={
          <div className={styles.footer}>
            <Button size="large" onClick={onCancel} kind="secondary">
              {cancelButtonLabel}
            </Button>
            <Button size="large" onClick={onConfirm} kind="destructive">
              Discard changes
            </Button>
          </div>
        }
        contentClassName={styles.content}
      >
        <Icon kind="action-neutral" source={Error} />
        <Heading>You have unsaved changes</Heading>
        <Text>Your changes will be lost if you continue. Are you sure you want to discard them?</Text>
      </Modal>
    </ModalPortal>
  );
};

export const DiscardChangesModalContainer: FC = () => {
  const isOpen = useSelector(isDiscardChangesModalOpen);
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
  const [callbacks, setCallbacks] = useState({ onConfirm: () => {}, onCancel: () => {} });
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(DiscardChangesModalActions.toggleModal(false));
    callbacks.onCancel();
  }, [callbacks, dispatch]);

  const onConfirm = useCallback(() => {
    const discardChangesEvent = document.createEvent('Event');
    discardChangesEvent.initEvent(DISCARD_CHANGES_EVENT_NAME, true, true);
    document.dispatchEvent(discardChangesEvent);

    dispatch(DiscardChangesModalActions.toggleModal(false));
    dispatch(UnsavedChanges.setRouteHasUnsavedChanges(false));
    callbacks.onConfirm();
  }, [callbacks, dispatch]);

  const detectUnsavedChanges = useCallback(
    (fragment: string) => {
      return hasUnsavedChanges && !shouldIgnoreDiscardChangesModal(window.location.pathname, fragment);
    },
    [hasUnsavedChanges]
  );

  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();

        return UNSAVED_CHANGES_DETECTED_MESSAGE;
      }
    },
    [hasUnsavedChanges]
  );

  useEffect(() => {
    const checkForUnsavedChanges = ((fragment: string): Promise<void> =>
      new Promise((resolve, reject) => {
        if (detectUnsavedChanges(fragment)) {
          setCallbacks({ onConfirm: resolve, onCancel: reject });
          dispatch(DiscardChangesModalActions.toggleModal(true));
        } else {
          resolve();
        }
      })) as CallbackFunction;

    historyService.onBeforeNavigate(checkForUnsavedChanges);
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      historyService.offBeforeNavigate(checkForUnsavedChanges);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload, detectUnsavedChanges, dispatch]);

  return <DiscardChangesModal isOpen={isOpen} onCancel={onCancel} onConfirm={onConfirm} />;
};
