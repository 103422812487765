export interface DateFilter {
  date: Date;
}

export interface CustomDateFilter extends DateFilter {
  id: DateFilterId;
}

export enum DateFilterId {
  Today = 'today',
  Yesterday = 'yesterday',
  TwoDaysAgo = 'two_days_ago',
  ThreeDaysAgo = 'three_days_ago',
  CustomDate = 'custom_date',
}

export const DateFilterFormatedValues = {
  [DateFilterId.Today]: 'Today',
  [DateFilterId.Yesterday]: 'Yesterday',
  [DateFilterId.CustomDate]: 'Custom date',
};
