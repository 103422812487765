import { createAction, type ActionsUnion } from 'store/helper';

import { type ITooltipPayload, type IShowDelayedTooltipPayload } from './interfaces';

export enum TooltipsActionsNames {
  SHOW_DELAYED_TOOLTIP = 'FEATURES/TOOLTIPS/SHOW_DELAYED_TOOLTIP',
  SHOW_TOOLTIP = 'FEATURES/TOOLTIPS/SHOW_TOOLTIP',
  HIDE_TOOLTIP = 'FEATURES/TOOLTIPS/HIDE_TOOLTIP',
}

export const TooltipsActions = {
  showTooltip: (payload: ITooltipPayload) => createAction(TooltipsActionsNames.SHOW_TOOLTIP, payload),
  showDelayedTooltip: (payload: IShowDelayedTooltipPayload) =>
    createAction(TooltipsActionsNames.SHOW_DELAYED_TOOLTIP, payload),
  hideTooltip: (payload: ITooltipPayload) => createAction(TooltipsActionsNames.HIDE_TOOLTIP, payload),
};

export type TooltipAction = ActionsUnion<typeof TooltipsActions>;
