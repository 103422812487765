// @ts-strict-ignore
import { Calendar } from '@livechat/design-system-icons';
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import {
  CustomDateRangeFilterId,
  DateRangeFilterFormatedValues,
  type CustomDateRange,
  type CustomDateRangeFilter,
} from 'constants/filters/date-range-filter';

import { type FilterCompareLabelValues, type FilterConfig, type FilterLabelValue } from '../interfaces';

import * as styles from './styles';

const getDateRangeLabel = (filterValues: CustomDateRangeFilter): string => {
  if (filterValues.id !== CustomDateRangeFilterId.CustomPeriod || !filterValues.from || !filterValues.to) {
    return DateRangeFilterFormatedValues[filterValues.id];
  }

  return `${format(filterValues.from, DateFormat.ShortDate)} - ${format(filterValues.to, DateFormat.ShortDate)}`;
};

const getDateRangeLabelValues = (filterValues: CustomDateRangeFilter | null): FilterLabelValue[] | null => {
  if (filterValues === null) {
    return null;
  }

  const label = getDateRangeLabel(filterValues);

  return [
    {
      key: filterValues.id,
      label,
    },
  ];
};

const getFilterBoxClassName = (filterValue: CustomDateRange, filterCompareValues: CustomDateRange): string | null => {
  const isNotCustomPeriod =
    (filterValue === null || filterValue.id !== CustomDateRangeFilterId.CustomPeriod) &&
    (filterCompareValues === null ||
      (filterCompareValues && filterCompareValues.id !== CustomDateRangeFilterId.CustomPeriod));
  if (isNotCustomPeriod) {
    return null;
  }

  return styles.filterBox;
};

const getFilterButtonClassName = (filterValue: CustomDateRangeFilter): string | null => {
  if (filterValue === null || filterValue.id !== CustomDateRangeFilterId.CustomPeriod) {
    return null;
  }

  return styles.filterButton;
};

const getDateRangeCompareLabelValues = (
  filterValues: CustomDateRangeFilter | null,
  items,
  filterCompareValues: CustomDateRange | null,
): FilterCompareLabelValues | null => {
  if (filterValues === null) {
    return null;
  }
  const label = getDateRangeLabel(filterValues);

  const compareLabel = filterCompareValues ? getDateRangeLabel(filterCompareValues) : null;

  return {
    operator: '',
    firstValues: [label],
    secondValues: compareLabel ? [compareLabel] : [],
  };
};

export const dateRangeFilterConfig: FilterConfig = {
  title: 'Date',
  icon: Calendar,
  multiple: false,
  getFilterLabelValues: getDateRangeLabelValues,
  getFilterCompareLabelValues: getDateRangeCompareLabelValues,
  getFilterBoxClassName,
  getFilterButtonClassName,
};
