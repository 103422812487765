/* eslint-disable @typescript-eslint/naming-convention */
import { type PickRequired } from 'helpers/interface';

import { createMlGatewayApiClient } from '../client';
import { type GenericMlGatewayError, type MLGatewayError } from '../types';

import { COPILOT_MESSAGE_TIMEOUT, REPLY_SUGGESTIONS_TIMEOUT } from './constant';
import type {
  GenerateReplyRequest,
  GenerateReplyResponse,
  SendMessageRequest,
  AddKnowledgeSource,
  KnowledgeSourceResponse,
  ListSkillsResponse,
  LearnSkill,
  RemoveSkill,
  CancelLearning,
  UpdateSkill,
  ListSuggestionsResponse,
  AnnotateReplyRequest,
  AIAgentsListDTO,
  AssignToAgents,
  GetDocumentStructureResponse,
  GetDocumentResponse,
  KnowledgeSourceFileResponse,
  AddNewAgentPayload,
  AddNewAgentResponse,
  NotificationListDTO,
  AcknowledgeNotificationsRequest,
  AiAgentsError,
} from './types';

const client = createMlGatewayApiClient();

export const aiEngineClient = {
  generateReply: (payload: GenerateReplyRequest) =>
    client.post<GenerateReplyResponse, GenericMlGatewayError>('ai-agents/responses/generate', {
      json: payload,
      timeout: REPLY_SUGGESTIONS_TIMEOUT,
    }),

  sendMessage: (payload: SendMessageRequest) =>
    client.post<GenerateReplyResponse, GenericMlGatewayError>('ai-agents/chat/send-message', {
      json: payload,
      timeout: COPILOT_MESSAGE_TIMEOUT,
    }),

  addUrlSkill: (payload: AddKnowledgeSource) =>
    client.post<KnowledgeSourceResponse, MLGatewayError<AiAgentsError>>('ai-agents/skills/add-url', { json: payload }),

  listSkills: () => client.post<ListSkillsResponse, GenericMlGatewayError>('ai-agents/skills/list', { json: {} }),

  learnSkill: (payload: LearnSkill) =>
    client.post<LearnSkill, GenericMlGatewayError>('ai-agents/skills/learn', { json: payload }),

  removeSkill: (payload: RemoveSkill) =>
    client.post<RemoveSkill, GenericMlGatewayError>('ai-agents/skills/remove', { json: payload }),

  cancelLearningSkill: (payload: CancelLearning) =>
    client.post<CancelLearning, GenericMlGatewayError>('ai-agents/skills/cancel-learning', { json: payload }),

  updateSkill: (payload: PickRequired<UpdateSkill, 'id'>) =>
    client.post<UpdateSkill, GenericMlGatewayError>('ai-agents/skills/update', { json: payload }),

  batchCancelLearning: (payload: string[]) =>
    client.post<CancelLearning, GenericMlGatewayError>('ai-agents/skills/batch-cancel-learning', {
      json: { ids: payload },
    }),

  batchLearnSkill: (payload: string[]) =>
    client.post<LearnSkill, GenericMlGatewayError>('ai-agents/skills/batch-learn', { json: { ids: payload } }),

  listSuggestions: () =>
    client.post<ListSuggestionsResponse, GenericMlGatewayError>('ai-agents/chat/list-suggestions', { json: {} }),

  sendAnnotation: (payload: AnnotateReplyRequest) => client.post('ai-agents/responses/annotate', { json: payload }),

  listAIAgents: () => client.post<AIAgentsListDTO[], GenericMlGatewayError>('ai-agents/agents/list', { json: {} }),

  assignToAIAgents: (payload: AssignToAgents) =>
    client.post<AssignToAgents, GenericMlGatewayError>('ai-agents/skills/assign-to-agents', { json: payload }),

  unassignFromAIAgents: (payload: AssignToAgents) =>
    client.post<AssignToAgents, GenericMlGatewayError>('ai-agents/skills/unassign-from-agents', { json: payload }),

  getStructure: (source_id: string) =>
    client.post<GetDocumentStructureResponse, GenericMlGatewayError>('ai-agents/sources/get-structure', {
      json: { source_id },
    }),

  getDocument: (source_id: string, document_id: string) =>
    client.post<GetDocumentResponse, GenericMlGatewayError>('ai-agents/sources/get-document', {
      json: { document_id, source_id },
    }),

  removeDocuments: (source_id: string, document_ids: string[]) =>
    client.post<GetDocumentResponse, GenericMlGatewayError>('ai-agents/sources/remove-documents', {
      json: { document_ids, source_id },
    }),

  addFileSkill: (formData: FormData) =>
    client.post<KnowledgeSourceFileResponse, GenericMlGatewayError>('ai-agents/skills/add-file', { body: formData }),

  addNewAgent: (payload: AddNewAgentPayload) =>
    client.post<AddNewAgentResponse, GenericMlGatewayError>('ai-agents/agents/add', { json: payload }),

  listNotifications: () =>
    client.post<NotificationListDTO, GenericMlGatewayError>('ai-agents/notifications/list', { json: {} }),

  acknowledgeNotifications: (payload: AcknowledgeNotificationsRequest) =>
    client.post('ai-agents/notifications/acknowledge', { json: payload }),
};
