import { useGoals } from 'hooks/api/goals/use-goals';

export function useActiveGoalsCount(): number {
  const { data } = useGoals({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['data'],
  });

  return data ? data.reduce((count, goal) => (goal.active ? count + 1 : count), 0) : 0;
}
