import { AppStateProvider } from 'services/app-state-provider';
import { ApplicationsActions } from 'store/features/applications/actions';

import { getConfig } from './config';

export const getEmailiniumId = (): string => {
  const {
    emailiniumAppId,
    datacenter: { current },
  } = getConfig();

  return emailiniumAppId[current] as string;
};

export const installEmailinium = (): void => {
  const appId = getEmailiniumId();
  AppStateProvider.dispatch(ApplicationsActions.installApplication(appId));
};
