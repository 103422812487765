import { type ICodeInstallationState, type IChannelsActivity } from 'store/features/code-installation/interfaces';

interface IWithCodeInstallation {
  features: {
    codeInstallation: ICodeInstallationState;
  };
}

export function getIsCodeInstalled(state: IWithCodeInstallation): boolean {
  return state.features.codeInstallation.isCodeInstalled;
}

export function getHadChatsRecently(state: IWithCodeInstallation): boolean {
  return state.features.codeInstallation.hadChatsRecently;
}

export function getChannelsActivity(state: IWithCodeInstallation): IChannelsActivity {
  return state.features.codeInstallation.channelsActivity;
}
