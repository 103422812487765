// @ts-strict-ignore

import { Channels } from '@livechat/design-system-icons';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

import { type IChatChannelFilterItem } from './channel-filter';

export const channelFilterConfigMultiple: FilterConfig = {
  title: 'Channel',
  icon: Channels,
  mobileViewTitle: 'Select channel',
  multiple: true,
  getFilterLabelValues: (filterValues: string[] | null, channels: IChatChannelFilterItem[]): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    return filterValues.map((filterValue: string) => ({
      key: filterValue,
      label: channels.find((channel) => channel.id === filterValue).name,
    }));
  },
};
