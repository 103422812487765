import type { FC } from 'react';

import { Close } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { Section } from 'constants/section';
import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import { NavigationViewActions } from 'store/views/navigation/actions';

import * as styles from './styles';

export const NewVisitorTooltip: FC = () => {
  const dispatch = useDispatch();

  const handleClick = (): void => {
    navigate(`/${Section.EngageTraffic}`);
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: Section.Engage }));
    trackEvent('New visitor tooltip clicked', EventPlace.Traffic);
  };

  const handleCancel = (): void => {
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: Section.Engage }));
  };

  return (
    <div className={styles.wrapper}>
      <div onClick={handleCancel} className={styles.icon}>
        <Icon source={Close} size="xsmall" />
      </div>
      <p className={styles.text}>New visitor on your website</p>
      <span className={styles.link} onClick={handleClick}>
        Go to Traffic
      </span>
    </div>
  );
};
