import { type FC } from 'react';

import { Info } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { getConfig } from 'helpers/config';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { NavigationViewActions } from 'store/views/navigation/actions';

export const HelpDeskPromoTooltip: FC = () => {
  const dispatch = useDispatch();

  const onCancel = (): void => {
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: getConfig().helpDeskAppWidgetId }));
    dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.HelpDeskNavPromoSeen]: '1',
      }),
    );
  };

  return (
    <Info
      header="Use the new ticketing system"
      text="Smoothly create your first ticket from chat and then manage it in HelpDesk."
      handleCloseAction={onCancel}
      theme="important"
    />
  );
};
