import { type ActionsUnion, createAction } from 'store/helper';

import {
  type IAssingChatPayload,
  type ICancelSuggestionRequestPayload,
  type IChangeTagReminderVisibilityPayload,
  type IClearScrollPositionPayload,
  type ICloseAndArchiveChatPayload,
  type ICloseChatPayload,
  type ICreateTicketPayload,
  type IMarkChatAsNewPayload,
  type IMarkCustomerAsAwaitingNavigationPayload,
  type IMarkCustomerEventsAsSeenPayload,
  type IMarkEventsAsReadPayload,
  type IMarkEventsAsSeenPayload,
  type INavigateToChatPayload,
  type INavigateToNextAvailableChatPayload,
  type INavigateToSelectedOrFirstAvailableChatPayload,
  type IPerformChatActionPayload,
  type IPickFromQueuePayload,
  type IRemoveDraftMessagePayload,
  type IRemovePrivateModePayload,
  type IRemoveReplySuggestionsToolbarStatePayload,
  type IRemoveTextEnhancementsToolbarStatePayload,
  type IReplySuggestionsGenerateReplyPayload,
  type ISaveDraftMessagePayload,
  type ISavePrivateModePayload,
  type ISaveReplySuggestionsToolbarStatePayload,
  type ISaveTextEnhancementsToolbarStatePayload,
  type ISelectChatFromRoutePayload,
  type ISelectChatPayload,
  type ISendMessagePayload,
  type ISendTranscriptPayload,
  type ISetAgentMessageSneakPeekPayload,
  type ISetChatEventAttachmentSafetyConfirmationPayload,
  type ISetChatTagFailurePayload,
  type ISetChatTagRequestPayload,
  type ISetChatTagSuccessPayload,
  type ISetChatsSortOrderPayload,
  type ISetInaccessibleChatIdPayload,
  type ISetReplySuggestionSessionIdPayload,
  type ISetScrollPositionPayload,
  type ISetSelectedIdPayload,
  type ISetTextEnhancementsSessionIdPayload,
  type ISetTypingIndicatorPayload,
  type IStartChatPayload,
  type IStopSupervisingPayload,
  type ISuperviseChatPayload,
  type ITakeoverChatPayload,
  type ITransferChatPayload,
  type ITransferChatWithOptionsPayload,
  type ITriggerChatNotificationPayload,
  type IUnmarkChatThreadIdsAsNewPayload,
  type IUnsetChatTagRequestPayload,
  type IUpdateNotificationsCountPayload,
  type RequestTextEnhancementsPayload,
  type ISaveSelectedCannedsPayload,
  type IRemoveSelectedCannedsPayload,
} from './interfaces';

export enum ChatsViewActionsNames {
  MARK_CHAT_AS_NEW = 'VIEWS/CHATS/MARK_CHAT_AS_NEW',
  MARK_CUSTOMER_AS_AWAITING_NAVIGATION = 'VIEWS/CHATS/MARK_CUSTOMER_AS_AWAITING_NAVIGATION',
  CLEAR_AWAITING_NAVIGATION = 'VIEWS/CHATS/CLEAR_AWAITING_NAVIGATION',
  CLOSE_AND_ARCHIVE_CHAT = 'VIEWS/CHATS/CLOSE_AND_ARCHIVE_CHAT',
  CLOSE_CHAT = 'VIEWS/CHATS/CLOSE_CHAT',
  CREATE_TICKET = 'VIEWS/CHATS/CREATE_TICKET',
  NAVIGATE_TO_CHAT = 'VIEWS/CHATS/NAVIGATE_TO_CHAT',
  NAVIGATE_TO_SELECTED_OR_FIRST_AVAILABLE_CHAT = 'VIEWS/CHATS/NAVIGATE_TO_FIRST_AVAILABLE_CHAT',
  NAVIGATE_TO_NEXT_AVAILABLE_CHAT = 'VIEWS/CHATS/NAVIGATE_TO_NEXT_AVAILABLE_CHAT',
  PICK_FROM_QUEUE = 'VIEWS/CHATS/PICK_FROM_QUEUE',
  PERFORM_CHAT_ACTION = 'VIEWS/CHATS/PERFORM_CHAT_ACTION',
  SELECT_CHAT = 'VIEWS/CHATS/SELECT_CHAT',
  SELECT_NEXT_CHAT = 'VIEWS/CHATS/SELECT_NEXT_CHAT',
  SELECT_PREVIOUS_CHAT = 'VIEWS/CHATS/SELECT_PREVIOUS_CHAT',
  SELECT_CHAT_FROM_ROUTE = 'VIEWS/CHATS/SELECT_CHAT_FROM_ROUTE',
  SET_SELECTED_ID = 'VIEWS/CHATS/SET_SELECTED_ID',
  SEND_MESSAGE = 'VIEWS/CHATS/SEND_MESSAGE',
  SEND_TRANSCRIPT = 'VIEWS/CHATS/SEND_TRANSCRIPT',
  SUPERVISE_CHAT = 'VIEWS/CHATS/SUPERVISE_CHAT',
  STOP_SUPERVISING = 'VIEWS/CHATS/STOP_SUPERVISING',
  TAKEOVER_CHAT = 'VIEWS/CHATS/TAKEOVER_CHAT',
  TRANSFER_CHAT = 'VIEW/CHATS/TRANSFER_CHAT',
  TRANSFER_CHAT_WITH_OPTIONS = 'VIEWS/CHATS/TRANSFER_CHAT_WITH_OPTIONS',
  TRANSFER_CHAT_SUCCESSFUL = 'VIEW/CHATS/TRANSFER_CHAT_SUCCESSFUL',
  TRANSFER_CHAT_FAILURE = 'VIEW/CHATS/TRANSFER_CHAT_FAILURE',
  FETCH_TAGS = 'VIEWS/CHATS/FETCH_TAGS',
  SET_CHAT_EVENT_ATTACHMENT_SAFETY_CONFIRMATION = 'VIEWS/CHATS/SET_CHAT_EVENT_ATTACHMENT_SAFETY_CONFIRMATION',
  SET_CHAT_TAG_REQUEST = 'VIEWS/CHATS/SET_CHAT_TAG_REQUEST',
  SET_CHAT_TAG_SUCCESS = 'VIEWS/CHATS/SET_CHAT_TAG_SUCCESS',
  SET_CHAT_TAG_FAILURE = 'VIEWS/CHATS/SET_CHAT_TAG_FAILURE',
  START_CHAT = 'VIEWS/CHATS/START_CHAT',
  UNSET_CHAT_TAG_REQUEST = 'VIEWS/CHATS/UNSET_CHAT_TAG_REQUEST',
  UNSET_CHAT_TAG_SUCCESS = 'VIEWS/CHATS/UNSET_CHAT_TAG_SUCCESS',
  UNSET_CHAT_TAG_FAILURE = 'VIEWS/CHATS/UNSET_CHAT_TAG_FAILURE',
  ASSIGN_CHAT = 'VIEWS/CHATS/ASSIGN_CHAT',
  CHANGE_TAG_REMINDER_VISIBILITY = 'VIEWS/CHATS/CHANGE_TAG_REMINDER_VISIBILITY',
  CHANGE_LIST_MODE = 'VIEWS/CHATS/CHANGE_LIST_MODE',
  LOAD_MORE_UNASSIGNED_CHATS = 'VIEWS/CHATS/LOAD_MORE_UNASSIGNED_CHATS',
  SET_TYPING_INDICATOR = 'VIEWS/CHATS/SET_TYPING_INDICATOR',
  SET_AGENT_MESSAGE_SNEAK_PEEK = 'VIEWS/CHATS/SET_AGENT_MESSAGE_SNEAK_PEEK',
  SET_SCROLL_POSITION = 'VIEWS/CHATS/SET_SCROLL_POSITION',
  SAVE_DRAFT_MESSAGE = 'VIEWS/CHATS/SAVE_DRAFT_MESSAGE',
  REMOVE_DRAFT_MESSAGE = 'VIEWS/CHATS/REMOVE_DRAFT_MESSAGE',
  UNMARK_CHAT_AS_NEW = 'VIEWS/CHATS/UNMARK_CHAT_AS_NEW',
  MARK_CUSTOMER_EVENTS_AS_SEEN = 'VIEWS/CHATS/MARK_CUSTOMER_EVENTS_AS_SEEN',
  MARK_EVENTS_AS_SEEN = 'VIEWS/CHATS/MARK_EVENTS_AS_SEEN',
  MARK_EVENTS_AS_READ = 'VIEWS/CHATS/MARK_EVENTS_AS_READ',
  UPDATE_NOTIFICATIONS_COUNT = 'VIEWS/CHATS/UPDATE_NOTIFICATIONS_COUNT',
  CLEAR_SCROLL_POSITION = 'VIEWS/CHATS/CLEAR_SCROLL_POSITION',
  SAVE_PRIVATE_MODE = 'VIEWS/CHATS/SAVE_PRIVATE_MODE',
  REMOVE_PRIVATE_MODE = 'VIEWS/CHATS/REMOVE_PRIVATE_MODE',
  SET_CHATS_SORT_ORDER = 'VIEWS/CHATS/SET_CHATS_SORT_ORDER',
  SET_INACCESSIBLE_CHAT_ID = 'VIEWS/CHATS/SET_INACCESSIBLE_CHAT_ID',
  TRIGGER_CHAT_NOTIFICATION = 'VIEWS/CHATS/TRIGGER_CHAT_NOTIFICATION',
  SAVE_TEXT_ENHANCEMENTS_TOOLBAR_STATE = 'VIEWS/CHATS/SAVE_TEXT_ENHANCEMENTS_TOOLBAR_STATE',
  SET_TEXT_ENHANCEMENTS_REQUEST_ID = 'VIEWS/CHATS/SET_TEXT_ENHANCEMENTS_REQUEST_ID',
  REMOVE_THREAD_TEXT_ENHANCEMENTS_TOOLBAR_STATE = 'VIEWS/CHATS/REMOVE_THREAD_TEXT_ENHANCEMENTS_TOOLBAR_STATE',
  REMOVE_TEXT_ENHANCEMENTS_TOOLBAR_STATES = 'VIEWS/CHATS/REMOVE_TEXT_ENHANCEMENTS_TOOLBAR_STATES',
  GENERATE_REPLY_SUGGESTIONS_REPLY = 'VIEWS/CHATS/GENERATE_KNOWLEDGE_SUGGESTIONS_REPLY',
  SAVE_REPLY_SUGGESTIONS_TOOLBAR_STATE = 'VIEWS/CHATS/SAVE_KNOWLEDGE_SUGGESTIONS_TOOLBAR_STATE',
  REMOVE_REPLY_SUGGESTIONS_TOOLBAR_STATE = 'VIEWS/CHATS/REMOVE_KNOWLEDGE_SUGGESTIONS_TOOLBAR_STATE',
  CANCEL_REPLY_SUGGESTION_REPLY = 'VIEWS/CHATS/CANCEL_KNOWLEDGE_SUGGESTION_REPLY',
  SET_REPLY_SUGGESTIONS_REQUEST_ID = 'VIEWS/CHATS/SET_KNOWLEDGE_SUGGESTIONS_REQUEST_ID',
  SET_CURRENT_MESSAGEBOX_VALUE = 'VIEWS/CHATS/SET_CURRENT_MESSAGEBOX_VALUE',
  REQUEST_TEXT_ENHANCEMENTS = 'VIEWS/CHATS/REQUEST_TEXT_ENHANCEMENTS',
  TOGGLE_TEXT_ENAHANCEMENTS_TOOLBAR = 'VIEWS/CHATS/TOGGLE_TEXT_ENAHANCEMENTS_TOOLBAR',
  OPEN_TEXT_ENHANCEMENTS_TOOLBAR = 'VIEWS/CHATS/OPEN_TEXT_ENHANCEMENTS_TOOLBAR',
  CLOSE_TEXT_ENHANCEMENTS_TOOLBAR = 'VIEWS/CHATS/CLOSE_TEXT_ENHANCEMENTS_TOOLBAR',
  SAVE_SELECTED_CANNEDS = 'VIEWS/CHATS/SAVE_SELECTED_CANNEDS',
  REMOVE_SELECTED_CANNEDS = 'VIEWS/CHATS/REMOVE_SELECTED_CANNEDS',
}

const replySuggestionActions = {
  generateReply: (payload: IReplySuggestionsGenerateReplyPayload) =>
    createAction(ChatsViewActionsNames.GENERATE_REPLY_SUGGESTIONS_REPLY, payload),
  saveReplySuggestionToolbarState: (payload: ISaveReplySuggestionsToolbarStatePayload) =>
    createAction(ChatsViewActionsNames.SAVE_REPLY_SUGGESTIONS_TOOLBAR_STATE, payload),
  removeReplySuggestionToolbarState: (payload: IRemoveReplySuggestionsToolbarStatePayload) =>
    createAction(ChatsViewActionsNames.REMOVE_REPLY_SUGGESTIONS_TOOLBAR_STATE, payload),
  setReplySuggestionSessionId: (payload: ISetReplySuggestionSessionIdPayload) =>
    createAction(ChatsViewActionsNames.SET_REPLY_SUGGESTIONS_REQUEST_ID, payload),
  cancelSuggestionRequest: (payload: ICancelSuggestionRequestPayload) =>
    createAction(ChatsViewActionsNames.CANCEL_REPLY_SUGGESTION_REPLY, payload),
};

const textEnhancementsActions = {
  saveTextEnhancementsToolbarState: (payload: ISaveTextEnhancementsToolbarStatePayload) =>
    createAction(ChatsViewActionsNames.SAVE_TEXT_ENHANCEMENTS_TOOLBAR_STATE, payload),
  removeThreadTextEnhancementsToolbarState: (payload: IRemoveTextEnhancementsToolbarStatePayload) =>
    createAction(ChatsViewActionsNames.REMOVE_THREAD_TEXT_ENHANCEMENTS_TOOLBAR_STATE, payload),
  removeTextEnhancementToolbarStates: () => createAction(ChatsViewActionsNames.REMOVE_TEXT_ENHANCEMENTS_TOOLBAR_STATES),
  setTextEnhancementsSessionId: (payload: ISetTextEnhancementsSessionIdPayload) =>
    createAction(ChatsViewActionsNames.SET_TEXT_ENHANCEMENTS_REQUEST_ID, payload),
  requestTextEnhancements: (payload: RequestTextEnhancementsPayload) =>
    createAction(ChatsViewActionsNames.REQUEST_TEXT_ENHANCEMENTS, payload),
  toggleTextEnhancementsToolbar: () => createAction(ChatsViewActionsNames.TOGGLE_TEXT_ENAHANCEMENTS_TOOLBAR),
  openTextEnhancementsToolbar: () => createAction(ChatsViewActionsNames.OPEN_TEXT_ENHANCEMENTS_TOOLBAR),
  closeTextEnhancementsToolbar: () => createAction(ChatsViewActionsNames.CLOSE_TEXT_ENHANCEMENTS_TOOLBAR),
};

export const ChatsViewActions = {
  ...textEnhancementsActions,
  ...replySuggestionActions,
  ...textEnhancementsActions,
  closeAndArchiveChat: (payload: ICloseAndArchiveChatPayload) =>
    createAction(ChatsViewActionsNames.CLOSE_AND_ARCHIVE_CHAT, payload),
  closeChat: (payload: ICloseChatPayload) => createAction(ChatsViewActionsNames.CLOSE_CHAT, payload),
  createTicket: (payload: ICreateTicketPayload) => createAction(ChatsViewActionsNames.CREATE_TICKET, payload),
  markCustomerAsAwaitingNavigation: (payload: IMarkCustomerAsAwaitingNavigationPayload) =>
    createAction(ChatsViewActionsNames.MARK_CUSTOMER_AS_AWAITING_NAVIGATION, payload),
  clearAwaitingNavigation: () => createAction(ChatsViewActionsNames.CLEAR_AWAITING_NAVIGATION),
  navigateToChat: (payload: INavigateToChatPayload) => createAction(ChatsViewActionsNames.NAVIGATE_TO_CHAT, payload),
  navigateToSelectedOrFirstAvailableChat: (payload: INavigateToSelectedOrFirstAvailableChatPayload) =>
    createAction(ChatsViewActionsNames.NAVIGATE_TO_SELECTED_OR_FIRST_AVAILABLE_CHAT, payload),
  navigateToNextAvailableChat: (payload: INavigateToNextAvailableChatPayload) =>
    createAction(ChatsViewActionsNames.NAVIGATE_TO_NEXT_AVAILABLE_CHAT, payload),
  pickFromQueue: (payload: IPickFromQueuePayload) => createAction(ChatsViewActionsNames.PICK_FROM_QUEUE, payload),
  performChatAction: (payload: IPerformChatActionPayload) =>
    createAction(ChatsViewActionsNames.PERFORM_CHAT_ACTION, payload),
  markChatAsNew: (payload: IMarkChatAsNewPayload) => createAction(ChatsViewActionsNames.MARK_CHAT_AS_NEW, payload),
  selectChat: (payload: ISelectChatPayload) => createAction(ChatsViewActionsNames.SELECT_CHAT, payload),
  selectNextChat: () => createAction(ChatsViewActionsNames.SELECT_NEXT_CHAT),
  selectPreviousChat: () => createAction(ChatsViewActionsNames.SELECT_PREVIOUS_CHAT),
  selectChatFromRoute: (payload: ISelectChatFromRoutePayload) =>
    createAction(ChatsViewActionsNames.SELECT_CHAT_FROM_ROUTE, payload),
  setSelectedId: (payload: ISetSelectedIdPayload) => createAction(ChatsViewActionsNames.SET_SELECTED_ID, payload),
  startChat: (payload: IStartChatPayload) => createAction(ChatsViewActionsNames.START_CHAT, payload),
  superviseChat: (payload: ISuperviseChatPayload) => createAction(ChatsViewActionsNames.SUPERVISE_CHAT, payload),
  takeoverChat: (payload: ITakeoverChatPayload) => createAction(ChatsViewActionsNames.TAKEOVER_CHAT, payload),
  transferChat: (payload: ITransferChatPayload) => createAction(ChatsViewActionsNames.TRANSFER_CHAT, payload),
  transferChatSuccessful: () => createAction(ChatsViewActionsNames.TRANSFER_CHAT_SUCCESSFUL),
  transferChatFailure: () => createAction(ChatsViewActionsNames.TRANSFER_CHAT_FAILURE),
  transferChatWithOptions: (payload: ITransferChatWithOptionsPayload) =>
    createAction(ChatsViewActionsNames.TRANSFER_CHAT_WITH_OPTIONS, payload),
  fetchTags: () => createAction(ChatsViewActionsNames.FETCH_TAGS),
  setChatEventAttachmentSafetyConfirmation: (payload: ISetChatEventAttachmentSafetyConfirmationPayload) =>
    createAction(ChatsViewActionsNames.SET_CHAT_EVENT_ATTACHMENT_SAFETY_CONFIRMATION, payload),
  setScrollPosition: (payload: ISetScrollPositionPayload) =>
    createAction(ChatsViewActionsNames.SET_SCROLL_POSITION, payload),
  clearScrollPosition: (payload: IClearScrollPositionPayload) =>
    createAction(ChatsViewActionsNames.CLEAR_SCROLL_POSITION, payload),
  setChatTagRequest: (payload: ISetChatTagRequestPayload) =>
    createAction(ChatsViewActionsNames.SET_CHAT_TAG_REQUEST, payload),
  setChatTagSuccess: (payload: ISetChatTagSuccessPayload) =>
    createAction(ChatsViewActionsNames.SET_CHAT_TAG_SUCCESS, payload),
  setChatTagFailure: (payload: ISetChatTagFailurePayload) =>
    createAction(ChatsViewActionsNames.SET_CHAT_TAG_FAILURE, payload),
  unsetChatTagRequest: (payload: IUnsetChatTagRequestPayload) =>
    createAction(ChatsViewActionsNames.UNSET_CHAT_TAG_REQUEST, payload),
  unsetChatTagSuccess: (payload: IUnsetChatTagRequestPayload) =>
    createAction(ChatsViewActionsNames.UNSET_CHAT_TAG_SUCCESS, payload),
  unsetChatTagFailure: (payload: IUnsetChatTagRequestPayload) =>
    createAction(ChatsViewActionsNames.UNSET_CHAT_TAG_FAILURE, payload),
  assignChat: (payload: IAssingChatPayload) => createAction(ChatsViewActionsNames.ASSIGN_CHAT, payload),
  setTypingIndicator: (payload: ISetTypingIndicatorPayload) =>
    createAction(ChatsViewActionsNames.SET_TYPING_INDICATOR, payload),
  setAgentMessageSneakPeek: (payload: ISetAgentMessageSneakPeekPayload) =>
    createAction(ChatsViewActionsNames.SET_AGENT_MESSAGE_SNEAK_PEEK, payload),
  stopSupervising: (payload: IStopSupervisingPayload) => createAction(ChatsViewActionsNames.STOP_SUPERVISING, payload),
  changeTagReminderVisibility: (payload: IChangeTagReminderVisibilityPayload) =>
    createAction(ChatsViewActionsNames.CHANGE_TAG_REMINDER_VISIBILITY, payload),
  loadMoreUnassignedChats: () => createAction(ChatsViewActionsNames.LOAD_MORE_UNASSIGNED_CHATS),
  sendMessage: (payload: ISendMessagePayload) => createAction(ChatsViewActionsNames.SEND_MESSAGE, payload),
  sendTranscript: (payload: ISendTranscriptPayload) => createAction(ChatsViewActionsNames.SEND_TRANSCRIPT, payload),
  saveDraftMessage: (payload: ISaveDraftMessagePayload) =>
    createAction(ChatsViewActionsNames.SAVE_DRAFT_MESSAGE, payload),
  removeDraftMessage: (payload: IRemoveDraftMessagePayload) =>
    createAction(ChatsViewActionsNames.REMOVE_DRAFT_MESSAGE, payload),
  unmarkChatThreadIdsAsNew: (payload: IUnmarkChatThreadIdsAsNewPayload) =>
    createAction(ChatsViewActionsNames.UNMARK_CHAT_AS_NEW, payload),
  markCustomerEventsAsSeen: (payload: IMarkCustomerEventsAsSeenPayload) =>
    createAction(ChatsViewActionsNames.MARK_CUSTOMER_EVENTS_AS_SEEN, payload),
  markEventsAsSeen: (payload: IMarkEventsAsSeenPayload) =>
    createAction(ChatsViewActionsNames.MARK_EVENTS_AS_SEEN, payload),
  markEventsAsRead: (payload: IMarkEventsAsReadPayload) =>
    createAction(ChatsViewActionsNames.MARK_EVENTS_AS_READ, payload),
  updateNotificationsCount: (payload: IUpdateNotificationsCountPayload) =>
    createAction(ChatsViewActionsNames.UPDATE_NOTIFICATIONS_COUNT, payload),
  savePrivateMode: (payload: ISavePrivateModePayload) => createAction(ChatsViewActionsNames.SAVE_PRIVATE_MODE, payload),
  removePrivateMode: (payload: IRemovePrivateModePayload) =>
    createAction(ChatsViewActionsNames.REMOVE_PRIVATE_MODE, payload),
  setChatsSortOrder: (payload: ISetChatsSortOrderPayload) =>
    createAction(ChatsViewActionsNames.SET_CHATS_SORT_ORDER, payload),
  setInaccessibleChatId: (payload: ISetInaccessibleChatIdPayload) =>
    createAction(ChatsViewActionsNames.SET_INACCESSIBLE_CHAT_ID, payload),
  triggerChatNotification: (payload: ITriggerChatNotificationPayload) =>
    createAction(ChatsViewActionsNames.TRIGGER_CHAT_NOTIFICATION, payload),
  setCurrentMessageboxValue: (message: string) =>
    createAction(ChatsViewActionsNames.SET_CURRENT_MESSAGEBOX_VALUE, message),
  saveSelectedCanneds: (payload: ISaveSelectedCannedsPayload) =>
    createAction(ChatsViewActionsNames.SAVE_SELECTED_CANNEDS, payload),
  removeSelectedCanneds: (payload: IRemoveSelectedCannedsPayload) =>
    createAction(ChatsViewActionsNames.REMOVE_SELECTED_CANNEDS, payload),
};

export type ChatsViewAction = ActionsUnion<typeof ChatsViewActions>;
