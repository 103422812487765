import { GENERAL_GROUP_ID } from 'constants/groups';

import { SettingsViewActionNames, type SettingsViewActionTypes } from './actions';
import type { ISettingsViewState } from './interfaces';
import { LanguageViewActionNames, type LanguageViewAction } from './language/actions';
import languageReducer from './language/reducer';

export const initialState: ISettingsViewState = {
  pageTitle: '',
  sidebarColumns: 1,
  selectedGroupId: GENERAL_GROUP_ID,
  currentPageId: null,
  isUpgradeModalOpened: false,
  showTicketEmailForwardingIntroduction: true,
  showBackButton: false,
  showNavigation: true,
  isFullScreen: false,
};

export const settingsReducer = (
  state: ISettingsViewState = initialState,
  action: SettingsViewActionTypes | LanguageViewAction,
): ISettingsViewState => {
  switch (action.type) {
    case SettingsViewActionNames.SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload.pageTitle,
      };

    case SettingsViewActionNames.SET_SIDEBAR_COLUMNS:
      return {
        ...state,
        sidebarColumns: action.payload.column,
      };

    case SettingsViewActionNames.SET_SELECTED_GROUP_ID:
      return {
        ...state,
        selectedGroupId: action.payload.groupId,
      };

    case SettingsViewActionNames.SET_CURRENT_PAGE_ID:
      return {
        ...state,
        currentPageId: action.payload.currentPageId,
      };

    case SettingsViewActionNames.TOGGLE_PGRADE_MODAL:
      return {
        ...state,
        isUpgradeModalOpened: !state.isUpgradeModalOpened,
      };

    case SettingsViewActionNames.HIDE_TICKET_EMAIL_FORWARDING_INTRODUCTION: {
      return {
        ...state,
        showTicketEmailForwardingIntroduction: false,
      };
    }

    case SettingsViewActionNames.SET_SHOW_BACK_BUTTON: {
      return {
        ...state,
        showBackButton: action.payload.showBackButton,
        navigateBackTo: action.payload.navigateBackTo,
      };
    }

    case SettingsViewActionNames.SET_LEFT_NODES: {
      return {
        ...state,
        leftNodes: action.payload.leftNodes,
      };
    }

    case SettingsViewActionNames.SHOULD_SHOW_NAVIGATION: {
      return {
        ...state,
        showNavigation: action.payload.showNavigation,
      };
    }

    case SettingsViewActionNames.SET_WIDGET_PREVIEW_BOTTOM_NOTE: {
      return {
        ...state,
        widgetPreviewBottomNote: action.payload.bottomNote,
      };
    }

    case SettingsViewActionNames.SET_HIDE_GROUP_SELECT: {
      return {
        ...state,
        isGroupSelectHidden: action.payload.hideGroupSelect,
      };
    }

    case SettingsViewActionNames.SET_HIDE_WIDGET_PREVIEW: {
      return {
        ...state,
        isWidgetPreviewHidden: action.payload.hideWidgetPreview,
      };
    }

    case SettingsViewActionNames.SET_IS_FULL_SCREEN: {
      return {
        ...state,
        isFullScreen: action.payload.isFullScren,
      };
    }

    case LanguageViewActionNames.SET_CURRENT_LANGUAGE_CODE:
    case LanguageViewActionNames.SET_CURRENT_LANGUAGE: {
      return {
        ...state,
        language: languageReducer(state.language, action),
      };
    }

    case SettingsViewActionNames.SET_RECENT_PAGE: {
      return {
        ...state,
        recentPage: action.payload.recentPage,
      };
    }

    default:
      return state;
  }
};
