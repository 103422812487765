import { createConfigurationApiClient } from '../client';
import { type ConfigurationApiErrorResponse } from '../types';

import {
  type CreateGroupResponse,
  type CreateGroupRequest,
  type UpdateGroupRequest,
  type DeleteGroupRequest,
  type ListGroupsResponse,
  type GetGroupRequest,
  type GetGroupResponse,
  type ListGroupsRequest,
} from './types';

const client = createConfigurationApiClient();

/**
 * Configuration API groups client.
 * @see {@link https://platform.text.com/docs/management/configuration-api#groups}
 */
export const groupsClient = {
  /**
   * Lists all groups.
   * @see {@link https://platform.text.com/docs/management/configuration-api#list-groups}
   */
  list: async (data: ListGroupsRequest) => {
    return client.post<ListGroupsResponse, ConfigurationApiErrorResponse>('list_groups', { json: data });
  },

  /**
   * Retrieves a group.
   * @see {@link https://platform.text.com/docs/management/configuration-api#get-group}
   */
  get: async (data: GetGroupRequest) => {
    return client.post<GetGroupResponse, ConfigurationApiErrorResponse>('get_group', { json: data });
  },

  /**
   * Creates a new group.
   * @see {@link https://platform.text.com/docs/management/configuration-api#create-group}
   */
  create: async (data: CreateGroupRequest) => {
    return client.post<CreateGroupResponse, ConfigurationApiErrorResponse>('create_group', { json: data });
  },

  /**
   * Updates a group.
   * @see {@link https://platform.text.com/docs/management/configuration-api#update-group}
   */
  update: async (data: UpdateGroupRequest) => {
    return client.post<CreateGroupResponse, ConfigurationApiErrorResponse>('update_group', { json: data });
  },

  /**
   * Deletes a group.
   * @see {@link https://platform.text.com/docs/management/configuration-api#delete-group}
   */
  delete: async (data: DeleteGroupRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('delete_group', { json: data });
  },
};
