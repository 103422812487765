// @ts-strict-ignore
import { useCallback, type FC } from 'react';

import { Notifications } from '@livechat/design-system-icons';
import { Icon, NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { type NotificationStatus } from 'constants/browser-notifications';
import { Cookie } from 'constants/cookies';
import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { setCookie } from 'helpers/cookies';
import { trackEvent } from 'services/event-tracking';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

const COOKIE_DAYS_EXPIRATION = 5;

interface IProps {
  onSkip(): void;
  isVisible: boolean;
}

export const EnableNotificationsBar: FC<IProps> = ({ onSkip, isVisible }) => {
  const dispatch = useDispatch();

  const handleEnableNotifications = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Notification.requestPermission((notificationStatus: NotificationStatus) => {
      trackEvent('Enable notifications button clicked', EventPlace.TopBar, {
        topBar: TopBarNotificationType.EnableNotifications,
      });
      trackEvent(`Permission requested - status ${notificationStatus}`, EventPlace.TopBar, {
        topBar: TopBarNotificationType.EnableNotifications,
      });
      dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.BrowserNotifications));
    });
  }, [dispatch]);

  const handleSkip = useCallback(() => {
    setCookie(Cookie.EnableNotificationsBarSkipped, 1, { days: COOKIE_DAYS_EXPIRATION });
    onSkip();
  }, [onSkip]);

  return (
    <NavigationTopBarAlert
      kind="info"
      isVisible={isVisible}
      closeButton={{
        onClick: handleSkip,
        'aria-label': 'Close the prompt about enabling browser notifications', // eslint-disable-line @typescript-eslint/naming-convention
      }}
      primaryCta={{
        label: 'Enable browser notifications',
        onClick: handleEnableNotifications,
        size: 'compact',
        icon: <Icon source={Notifications} />,
      }}
      data-testid="enable-notifications-alert"
    >
      <Text bold>Never miss a chat!</Text>
    </NavigationTopBarAlert>
  );
};
