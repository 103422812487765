import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type IncomingResponseMessage } from 'interfaces/incoming-message';
import type { Properties } from 'interfaces/property';
import { type UpdateEventPropertiesEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#update-event-properties
 */
export async function updateEventProperties(
  chatId: string,
  threadId: string,
  eventId: string,
  properties: Properties
): Promise<IncomingResponseMessage<void>> {
  const event: UpdateEventPropertiesEvent = {
    action: WebSocketAction.UpdateEventProperties,
    payload: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      chat_id: chatId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      thread_id: threadId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_id: eventId,
      properties,
    },
    version: '3.5',
  };

  return await sendEvent<void>(getServer(), event);
}
