import { NavigationTooltipType } from 'constants/navigation';
import type { IAppState } from 'services/app-state-provider';
import { Notification } from 'services/connectivity/configuration-api/agents/types';
import { getIsNotificationEnabled } from 'store/entities/agents/selectors';
import { CustomerActions } from 'store/entities/customers/actions';
import { getCustomerNameForList, isCustomerReturning } from 'store/entities/customers/helpers/customer';
import { isCustomerBanned } from 'store/entities/customers/helpers/customer-banned';
import { type IUpdateCustomerPayload } from 'store/entities/customers/interfaces';
import { BrowserNotificationsActions } from 'store/features/browser-notifications/actions';
import { NotificationsActions } from 'store/features/notifications/actions';
import { SoundNotificationActions } from 'store/features/sound-notifications/actions';

import { NEW_CUSTOMER_TITLE, RETURNING_CUSTOMER_TITLE } from './constants';
import { shouldNotifyAboutCustomer } from './helpers';

export function invokeCustomerUpdateWithNotifications(
  store: IAppState,
  { customer, visitedPages }: IUpdateCustomerPayload
): void {
  const state = store.getState();

  const shouldNotify = shouldNotifyAboutCustomer(state, customer);

  store.dispatch(CustomerActions.updateCustomer({ customer, visitedPages }));

  if (shouldNotify) {
    if (isCustomerBanned(customer)) {
      // don't notify about banned customers
      return;
    }

    const isReturning = isCustomerReturning(customer);
    const soundId: Notification = isReturning ? Notification.ReturningVisitor : Notification.NewVisitor;
    const notificationTitle = isReturning ? RETURNING_CUSTOMER_TITLE : NEW_CUSTOMER_TITLE;
    const isNotificationMuted = !getIsNotificationEnabled(state, soundId);
    const muteAllSounds = getIsNotificationEnabled(state, Notification.MuteAllSounds);

    if (!isReturning) {
      // display "New visitor" tooltip if not seen yet
      store.dispatch(
        NotificationsActions.newNotification({
          type: NavigationTooltipType.NewVisitor,
        })
      );
    }

    // play "New/Returning visitor" sound if not muted
    store.dispatch(
      SoundNotificationActions.playSound({
        sound: soundId,
        mute: muteAllSounds || isNotificationMuted,
        repeat: false,
      })
    );

    if (!isNotificationMuted) {
      // display "New/Returning visitor" browser notification if not disabled
      store.dispatch(
        BrowserNotificationsActions.show({
          title: notificationTitle,
          text: getCustomerNameForList(customer),
          tag: 'new_visitor',
          afterClick: {
            targetPage: '/traffic',
          },
        })
      );
    }
  }
}
