import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { type IApplication } from 'store/entities/applications/interfaces';
import {
  getApplicationsOrder,
  getSelectedApplications,
  getUnselectedApplications,
} from 'store/entities/applications/selectors';

import { sortByCustomApplicationOrder } from '../helpers';

export const useAllVisibleApps = (): IApplication[] => {
  const selectedApplications = useSelector(getSelectedApplications);
  const unselectedApplications = useSelector(getUnselectedApplications);
  const applicationsOrder = useSelector(getApplicationsOrder);

  const allVisibleApps = useMemo(
    () => [...selectedApplications, ...unselectedApplications].sort(sortByCustomApplicationOrder(applicationsOrder)),
    [applicationsOrder, selectedApplications, unselectedApplications],
  );

  return allVisibleApps;
};
