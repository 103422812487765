import { type ITimelinePeriod } from 'store/entities/chats/interfaces';

import { BaseApi } from '../base-api';

export class ChatHistoryApi extends BaseApi {
  protected readonly prefix: string = 'chats';

  fetch = (customerId: string, period: ITimelinePeriod, page: number) => {
    const { dateFrom, dateTo } = period;

    const payload: any = {
      visitor_id: customerId,
      sort_order: 'asc',
      page,
    };

    if (dateFrom) {
      payload.date_from = dateFrom;
    }

    if (dateTo) {
      payload.date_to = dateTo;
    }

    return this.get('', payload);
  };
}
