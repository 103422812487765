import { type FC } from 'react';

import * as styles from './styles';

interface IProps {
  value: string;
  important?: boolean;
}

export const OnOffBadge: FC<IProps> = ({ value, important }) => {
  return <div className={styles.onOffBadge(value === 'On', important)}>{value.toUpperCase()}</div>;
};
