// @ts-strict-ignore
import type * as Sentry from '@sentry/browser';

/**
 * Returns `true` if extra event information includes stackless `NotAllowedError` name and message related to audio play being disallowed.
 * @param extra Sentry event extra info.
 */
function eventExtraDataHasAudioPlayNotAllowedException(extra?: Sentry.Event['extra']): boolean {
  /* eslint-disable no-underscore-dangle */
  if (!extra || !extra.__serialized__) {
    return false;
  }

  const serialized = extra.__serialized__ as { name: string; message: string };

  const isNotAllowedError = serialized.name === 'NotAllowedError';
  const isPlayMethodMessage = serialized.message && serialized.message.startsWith('The play method is not allowed');

  return isNotAllowedError && isPlayMethodMessage;
  /* eslint-enable no-underscore-dangle */
}

/**
 * Detects if event to be logged is related to ignored case of `NotAllowedError` related to audio play method.
 * This issue may occur without any stacktrace as a general error report for browser (eg. Firefox). Error omits any catch logic.
 * @example https://sentry.io/organizations/livechat/issues/1228732850/events/901159b52cde4e2c8a04bcceac93bed1
 * @param event Sentry event.
 */
export function isAudioPlayNotAllowedException(event: Sentry.Event): boolean {
  return eventExtraDataHasAudioPlayNotAllowedException(event.extra);
}
