import { getStatusFromFeatureFlag } from 'helpers/get-status-from-feature-flag';
import { type IIntegrationLicensePropertiesState } from 'store/entities/integration-license-properties/interfaces';
import { getCopilotChatStatus } from 'store/entities/integration-license-properties/selectors';
import { type IWithAgentCustomPropertiesState } from 'store/features/agent-custom-properties/selectors';
import { type IWithSessionState } from 'store/features/session/selectors';

import { type WithCopilotViewState } from './interfaces';

export function getCanUseCopilotPopover(
  state: IWithSessionState & IIntegrationLicensePropertiesState & IWithAgentCustomPropertiesState,
): boolean {
  const copilotChatStatus = getCopilotChatStatus(state);

  return getStatusFromFeatureFlag(copilotChatStatus, true);
}

export function getCopilotDraftMessage(state: WithCopilotViewState): string {
  return state.views.copilot.draftMessage;
}

export function getCopilotUndreadMessageCount(state: WithCopilotViewState): number {
  return state.views.copilot.unreadMessagesCount;
}

export function getCopilotIsLoading(state: WithCopilotViewState): boolean {
  return state.views.copilot.isLoading;
}

export function getIsCopilotModalOpened(state: WithCopilotViewState): boolean {
  return state.views.copilot.modal.isOpened || false;
}

export function getIsCopilotModalExpanded(state: WithCopilotViewState): boolean {
  return state.views.copilot.modal.isExpanded || false;
}

export function getCopilotScrollPosition(state: WithCopilotViewState): number | null {
  return state.views.copilot.modal.scrollPosition;
}

export function getIsCopilotDetailsOpened(state: WithCopilotViewState): boolean {
  return state.views.copilot.modal.isDetailsOpened || false;
}

export function getIsCopilotNewMessageToastVisible(state: WithCopilotViewState): boolean {
  return state.views.copilot.isNewMessageToastVisible;
}

export function getIsCommandSuggestionsOpened(state: WithCopilotViewState): boolean {
  return state.views.copilot.isCommandSuggestionsOpened;
}

export function getWidgetWebsite(state: WithCopilotViewState): string | null {
  return state.views.copilot.widgetWebsite;
}
