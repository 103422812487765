import type { IAppState } from 'services/app-state-provider';
import { CustomerActions } from 'store/entities/customers/actions';
import { getCustomer } from 'store/entities/customers/selectors';

import { type ICustomerPageUpdatedPushEvent } from '../interfaces';
import { deserializeCustomerPageUpdatedPushEvent } from '../serialization/deserialize';

/**
 * Handles customer visited pages data update.
 * @param {ICustomerPageUpdatedPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleCustomerPageUpdated(payload: ICustomerPageUpdatedPushEvent, store: IAppState): void {
  const { customer_id: customerId } = payload;

  const state = store.getState();
  const customer = getCustomer(state, customerId);

  if (customer) {
    const visitedPage = deserializeCustomerPageUpdatedPushEvent(payload);

    store.dispatch(
      CustomerActions.addVisitedPage({
        customerId,
        visitedPage,
      })
    );
  }
}
