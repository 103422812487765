import { type FC } from 'react';

import { cx } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

import * as styles from './styles';

export enum BadgeType {
  Alert,
  Warning,
  Info,
  White,
}

interface IProps {
  className?: string;
  type: BadgeType;
  dot?: boolean;
}

export const Badge: FC<IProps> = ({ type, dot, className, children, ...props }) => {
  let badgeBackground = '#4384f5';
  let textColor = '#ffffff';

  switch (type) {
    case BadgeType.Alert:
      badgeBackground = withVar(DesignToken.SurfaceAccentEmphasisHighNegative);
      break;

    case BadgeType.Warning:
      badgeBackground = '#efa842';
      break;

    case BadgeType.White:
      badgeBackground = '#ffffff';
      textColor = '#333333';
      break;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cx(styles.badge({ badgeBackground, textColor }), className)} {...props}>
      {dot ? <span className={styles.whiteDot} /> : <span>{children}</span>}
    </div>
  );
};
