import { css } from '@emotion/css';

import { StackingContextLevel } from 'styles/stacking-context-level';

export const globalToastWrapper = css`
  z-index: ${StackingContextLevel.GlobalToast};

  .lc-toast__content {
    overflow: clip;
    text-overflow: ellipsis;
  }
`;
