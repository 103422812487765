import type { UserGuidedTourType } from 'constants/user-guided-tour-type';;
import { type ActionsUnion, createAction } from 'store/helper';

export enum UserGuidedTourActionNames {
  TOUR_READY = 'FEATURES/USER_GUIDED_TOUR/TOUR_READY',
  SHOW_TOUR = 'FEATURES/USER_GUIDED_TOUR/SHOW_TOUR',
  HIDE_TOUR = 'FEATURES/USER_GUIDED_TOUR/HIDE_TOUR',
}

export const UserGuidedTourActions = {
  tourReady: (tourId: UserGuidedTourType, isReady: boolean) =>
    createAction(UserGuidedTourActionNames.TOUR_READY, { tourId, isReady }),
  showTour: (tourId: UserGuidedTourType) => createAction(UserGuidedTourActionNames.SHOW_TOUR, { tourId }),
  hideTour: (tourId: UserGuidedTourType) => createAction(UserGuidedTourActionNames.HIDE_TOUR, { tourId }),
};

export type UserGuidedTourActionType = ActionsUnion<typeof UserGuidedTourActions>;
