// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, cancel, take, fork, delay, takeEvery, race, put, select } from 'redux-saga/effects';

import { Section } from 'constants/section';
import { isTabFocused } from 'helpers/browser';
import { preloadSounds } from 'helpers/custom-sounds';
import { getIsOnSection } from 'store/features/routing/selectors';
import { NotificationsActionsNames } from 'store/features/sound-notifications/actions';
import { createAction, type IActionWithPayload } from 'store/helper';
import { tabFocusChannel } from 'store/helpers/tab-focus';
import { ChatsViewActionsNames } from 'store/views/chats/actions';
import { getNotificationsChatsCount } from 'store/views/chats/selectors';

import type { PlaySoundPayload } from '../../../interfaces/sound';

import { INCOMING_SOUNDS_BUFFER_TIMEOUT } from './constants';
import { playSound, getSoundByPriority } from './helpers';

export function* playSoundInLoop(sound: string): SagaIterator {
  try {
    yield call(playSound, sound);

    while (true) {
      yield race({
        shouldStopReapeat: take(ChatsViewActionsNames.UPDATE_NOTIFICATIONS_COUNT),
        shouldPlaySound: delay(3000),
      });

      const notificationsCount: number = yield select(getNotificationsChatsCount);
      const isOnChatsSection = yield select(getIsOnSection, Section.Chats, false);
      const tabFocused = yield select(isTabFocused);

      // check for chats with new messages
      if (notificationsCount <= 0 || (isOnChatsSection && tabFocused)) {
        yield put(createAction(NotificationsActionsNames.STOP_REPEAT_SOUND));
        break;
      }

      yield call(playSound, sound);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Sound in loop notification error: ', e);
  }
}

const soundsPlaying = {};
let soundsBuffer = [];

export function* watchPlaySound(action: IActionWithPayload<string, PlaySoundPayload>): SagaIterator {
  const { sound, mute, repeat } = action.payload;

  if (mute || soundsPlaying[sound]) {
    return;
  }

  soundsPlaying[sound] = true;

  try {
    if (repeat) {
      const play = yield fork(playSoundInLoop, sound);
      while (true) {
        const [stop, tracker]: any = yield race([
          take(NotificationsActionsNames.STOP_REPEAT_SOUND),
          take(tabFocusChannel, 'focus-in'),
        ]);

        const isOnChats = yield select(getIsOnSection, Section.Chats, false);

        if ((tracker && isOnChats) || stop) {
          yield cancel(play);
          break;
        }
      }
    } else {
      yield delay(INCOMING_SOUNDS_BUFFER_TIMEOUT);

      soundsBuffer.push(sound);
      const soundToPlay = getSoundByPriority(soundsBuffer);

      if (soundToPlay) {
        yield call(playSound, soundToPlay);
      }

      soundsBuffer = [];
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Sound notification error: ', e);
  } finally {
    soundsPlaying[sound] = false;
  }
}

export default function* notificationsSaga(): SagaIterator {
  yield take('APP_READY');
  yield call(preloadSounds);
  yield takeEvery(NotificationsActionsNames.PLAY_SOUND, watchPlaySound);
}
