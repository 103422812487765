// @ts-strict-ignore
import { ToggleLeft } from '@livechat/design-system-icons';

import {
  AvailabilityFilterItemConfig,
  type IItemConfig,
  type AvailabilityFilter,
} from 'constants/filters/availability-filter';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

export const availabilityFilterConfig: FilterConfig = {
  title: 'Availability',
  innerTitle: 'Status when chat started',
  icon: ToggleLeft,
  multiple: false,
  getFilterLabelValues: (filterValues: AvailabilityFilter | null): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const itemConfig: IItemConfig = AvailabilityFilterItemConfig[filterValues];

    return [
      {
        key: filterValues,
        label: itemConfig.label,
      },
    ];
  },
};
