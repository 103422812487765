import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

export { container } from '../navigation-item/styles';
import { link as defaultLink, tooltip as defaultTooltip } from '../navigation-item/styles';

const linkBackground = `background: ${withVar(DesignToken.SurfaceAccentEmphasisHighNegative)}`;

export const link = css`
  ${defaultLink}
  ${linkBackground};

  &:hover {
    ${linkBackground};
  }
`;

export const tooltip = css`
  ${defaultTooltip}
  min-width: auto;
`;

export const dayCounter = css`
  font-size: 24px;
  font-weight: 600;
`;

export const label = css`
  font-size: 11px;
  font-weight: 400;
`;
