import { isDesktopDevice } from 'helpers/device';
import { type KnowledgeSourceUrlItem } from 'interfaces/knowledge-sources';

import { shouldShowNewBadgeBasedOnAgentCreateDate } from './settings';

const KNOWLEDGE_SOURCES_LIMITS_ALERT_RELEASE = '2024-02-10';
const DAYS_TO_RELEASE_LIMITS = 31;

export function shouldShowKnowledgeSourcesLimitAlert(data?: KnowledgeSourceUrlItem[] | null): boolean {
  if (!isDesktopDevice()) return false;

  const shouldShowKnowledgeSourcesLimitAlert = shouldShowNewBadgeBasedOnAgentCreateDate(
    KNOWLEDGE_SOURCES_LIMITS_ALERT_RELEASE,
    DAYS_TO_RELEASE_LIMITS
  );

  if (data) {
    const dataLength = data.length;
    const hasAnyKnowledgeSources = dataLength >= 1;

    return hasAnyKnowledgeSources && shouldShowKnowledgeSourcesLimitAlert;
  }

  return shouldShowKnowledgeSourcesLimitAlert;
}
