// @ts-strict-ignore
/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/browser';
import { type SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { SectionName } from 'constants/section-name';
import { ActionHandlerExecutionDelay, ToastContent } from 'constants/toasts';
import { getCustomVariablesMap, getRegisteredIntegrationTicket } from 'helpers/integration';
import { openInNewCard } from 'helpers/routing';
import { getToastContent } from 'helpers/toast';
import { stringifyQueryParams } from 'helpers/url';
import { type IArchive } from 'interfaces/entities/archive';
import { AppStateProvider } from 'services/app-state-provider';
import { getAccessToken } from 'services/auth/auth-storage-manager';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { getApplication, getApplicationButton, getApplicationWidget } from 'store/entities/applications/selectors';
import { getChatIdByThreadId, getThreadCustomerId, getThreadExists } from 'store/entities/chats/selectors';
import { getCustomerProvidedName } from 'store/entities/customers/helpers/customer';
import { getCustomer } from 'store/entities/customers/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getLicenseId } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { ArchivesViewActions } from 'store/views/archives/actions';
import { getCurrentArchive } from 'store/views/archives/selectors';
import { DetailsColumnActions } from 'store/views/customer-details/actions';

import { ChatButtonsActions, ChatButtonsActionsNames } from './actions';
import { replaceLegacyUrlMarkers } from './helpers';
import {
  ActionPayloadSource,
  type IActionPayload,
  type IArchivesActionPayload,
  type IPerformActionPayload,
} from './interfaces';

function assert(value, message): void {
  if (!value) {
    throw new Error(message);
  }
}

function* createActionPayload(threadId: string, source: ActionPayloadSource): SagaIterator {
  const licenseId: number = yield select(getLicenseId);
  const agentId: string = yield select(getLoggedInAgentLogin);
  const threadExists = yield select(getThreadExists, threadId);
  const customerId = yield select(getThreadCustomerId, threadId);
  const customer = yield select(getCustomer, customerId);

  let payload: IActionPayload = {
    licence_id: licenseId.toString(),
    agent_id: agentId,
    time: new Date().getTime().toString(),
    instance_id: window.instanceID,
    source,
  };

  if (threadExists) {
    payload.chat_id = yield select(getChatIdByThreadId, threadId);
    payload.thread_id = threadId;
  }

  if (customer) {
    const { id, email, host, ip, geolocation, customVariables } = customer;

    payload.customer_id = id;
    payload.customer_name = getCustomerProvidedName(customer);
    payload.customer_email = email;
    payload.customer_host = host;
    payload.customer_ip = ip;

    if (geolocation) {
      const { latitude, longitude, country, state, city, timezone } = geolocation;

      payload.customer_latitude = latitude;
      payload.customer_longitude = longitude;
      payload.customer_country = country;
      payload.customer_region = state;
      payload.customer_city = city;
      payload.customer_timezone = timezone;
    }

    if (customVariables) {
      const customVariablesPayload = getCustomVariablesMap(
        Object.keys(customVariables).map((key) => ({ key, value: customVariables[key] }))
      );
      payload = {
        ...payload,
        ...customVariablesPayload,
      };
    }
  }

  return payload;
}

function* createArchiveActionPayload(): SagaIterator {
  const archive: IArchive = yield select(getCurrentArchive);
  const licenseId: number = yield select(getLicenseId);
  const agentId: string = yield select(getLoggedInAgentLogin);

  const basePayload: IActionPayload = {
    licence_id: licenseId.toString(),
    agent_id: agentId,
    time: new Date().getTime().toString(),
    instance_id: window.instanceID,
    source: ActionPayloadSource.Archives,
    chat_id: archive.chatId,
    thread_id: archive.id,
    customer_id: archive.visitor.id,
    customer_name: archive.visitor.name,
    customer_email: archive.visitor.email,
    customer_ip: archive.visitor.ip,
    customer_country: archive.visitor.country,
    customer_region: archive.visitor.region,
    customer_city: archive.visitor.city,
    customer_timezone: archive.visitor.timezone,
    ...getCustomVariablesMap(archive.customVariables),
  };

  // Additional props added for legacy integrations from CRM
  const payload: IArchivesActionPayload = {
    ...basePayload,
    ticket: getRegisteredIntegrationTicket(),
    requestID: basePayload.thread_id,
  };

  return payload;
}

function* performAction(action): any {
  const payload = (action.payload || {}) as IPerformActionPayload;
  const { integrationAppId, integrationButtonId, threadId, source } = payload;

  assert(integrationAppId, 'param `integrationAppId` is required');
  assert(integrationButtonId, 'param `integrationButtonId` is required');
  assert(threadId, 'param `threadId` is required');
  assert(source, 'param `source` is required`');
  assert(Object.values(ActionPayloadSource).includes(source), 'param `source` is invalid`');

  const integration = yield select(getApplication, integrationAppId);

  assert(integration, `Integration with id=${integrationAppId} doesn't exist`);

  let actionPayload;
  if (source === ActionPayloadSource.Archives) {
    actionPayload = yield call(createArchiveActionPayload);
  } else {
    actionPayload = yield call(createActionPayload, threadId, source);
  }

  const button = yield select(getApplicationButton, integrationAppId, integrationButtonId);
  const buttonAction = button && button.action;

  switch (buttonAction) {
    case 'openLink': {
      const url = replaceLegacyUrlMarkers(button.sourceUrl, actionPayload);
      openInNewCard(`${url}?${stringifyQueryParams(actionPayload)}`);
      break;
    }

    case 'openModal':
      yield put(
        GlobalModalActions.showModal(GlobalModal.DevelopersPlatformChatButton, {
          url: `${button.sourceUrl}?${stringifyQueryParams(actionPayload)}`,
          heading: button.caption,
        })
      );
      break;

    case 'sendWebhook': {
      const url = `${button.sourceUrl}?${stringifyQueryParams(actionPayload)}`;
      const accessToken = getAccessToken();

      try {
        yield call(fetch, url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.CHAT_MENU_INTEGRATION_SUCCESS),
            kind: ToastVariant.Success,
          })
        );
      } catch (e) {
        if (!payload.retry) {
          yield put(
            ToastsActions.createToast({
              content: getToastContent(ToastContent.CHAT_MENU_INTEGRATION_ERROR),
              kind: ToastVariant.Error,
              action: {
                label: 'Retry',
                onClick: () => {
                  payload.retry = 1;
                  setTimeout(
                    () => AppStateProvider.dispatch(ChatButtonsActions.performAction(payload)),
                    ActionHandlerExecutionDelay.Short
                  );
                },
                closeOnClick: true,
              },
            })
          );
        } else {
          yield put(
            ToastsActions.createToast({
              content: getToastContent(ToastContent.DEFAULT_ERROR),
              kind: ToastVariant.Error,
            })
          );
        }

        console.error(e);
      }
      break;
    }

    case 'openWidget': {
      const applicationWidget = yield select(getApplicationWidget, integrationAppId, button.widgetId);
      if (!applicationWidget) {
        const errorMessage = `LIVECHAT MARKETPLACE: The widget does not exist. Please contact our support. Application id: ${integrationAppId}`;
        console.error(errorMessage);
        Sentry.captureException(new Error(errorMessage));

        return;
      }

      yield put(
        AgentCustomPropertiesActions.updateSectionDetailsOpen({
          section: SectionName.Chats,
          isOpen: true,
        })
      );
      yield put(
        ArchivesViewActions.setWidgetFromButton({
          id: applicationWidget.id,
          url: applicationWidget.url,
          isOpenedFromActionButton: true,
        })
      );

      yield put(DetailsColumnActions.selectDetailsTab({ tabId: applicationWidget.id }));
      break;
    }

    default:
      throw new Error(`Unknown button action type: ${buttonAction}`);
  }
}

export function* chatButtonsSaga(): SagaIterator {
  yield takeEvery(ChatButtonsActionsNames.PERFORM_ACTION, performAction);
}
