import { Route } from './navigation-items';

export enum Column {
  Checkbox = 'checkbox',
  Requester = 'requester',
  Subject = 'subject',
  Assignee = 'assignee',
  Status = 'status',
  UnrepliedSince = 'unrepliedSince',
  LastMessageDate = 'lastMessageDate',
}

export const Columns: { [key in Route]: Column[] } = {
  [Route.Unassigned]: [Column.Requester, Column.Subject, Column.UnrepliedSince],
  [Route.MyOpen]: [Column.Requester, Column.Subject, Column.UnrepliedSince],
  [Route.Open]: [Column.Requester, Column.Subject, Column.Assignee, Column.UnrepliedSince],
  [Route.Pending]: [Column.Requester, Column.Subject, Column.Assignee, Column.LastMessageDate],
  [Route.Solved]: [Column.Requester, Column.Subject, Column.Assignee, Column.LastMessageDate],
  [Route.Spam]: [Column.Requester, Column.Subject, Column.LastMessageDate],
  [Route.Search]: [Column.Requester, Column.Subject, Column.Assignee, Column.Status, Column.LastMessageDate],
  [Route.All]: [Column.Requester, Column.Subject, Column.Assignee, Column.Status, Column.LastMessageDate],
};

export const ColumnWidths: { [key in Column]?: number } = {
  [Column.Checkbox]: 16,
  [Column.Requester]: 200,
  [Column.Assignee]: 160,
  [Column.Status]: 70,
  [Column.UnrepliedSince]: 150,
  [Column.LastMessageDate]: 150,
};
