import { type FC } from 'react';

import { Close, Maximize } from '@livechat/design-system-icons';
import { Avatar, Button, Icon, Text, Tooltip } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { COPILOT_LOGO } from 'constants/copilot';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { getCopilotAllEvents } from 'store/entities/copilot/selectors';
import { CopilotViewActions } from 'store/views/copilot/actions';

import { HEADER_TOOLTIP_HOVER_DELAY, COPILOT_CLOSE_MODAL_TEST_ID, COPILOT_TOGGLE_MODAL_BTN_TEST_ID } from './constants';
import { FeedbackTooltip } from './FeedbackTooltip';

import * as styles from './styles';

export const CopilotPopoverHeader: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const copilotAllEvents = useSelector(getCopilotAllEvents);

  const handleCloseModal = (): void => {
    dispatch(CopilotViewActions.hideModal());
    trackEvent(CopilotTrackEvent.ChatClosed, EventPlace.One, {
      state: 'popover',
      url: location?.pathname,
      messages: Object.keys(copilotAllEvents).length,
    });
  };

  const toggleModal = (): void => {
    dispatch(CopilotViewActions.toggleExpand());
    trackEvent(CopilotTrackEvent.Resized, EventPlace.One, { resizedTo: 'fullscreen' });
  };

  return (
    <div className={styles.headerWrapper}>
      <div>
        <Avatar size="xsmall" type="image" src={COPILOT_LOGO} className={styles.copilotLogo} />
        <Text as="h2">Copilot</Text>
      </div>

      <div className={styles.copilotPopoverHeaderButtons}>
        <FeedbackTooltip />
        <Tooltip
          hoverOnDelay={HEADER_TOOLTIP_HOVER_DELAY}
          kind="invert"
          triggerRenderer={
            <Button
              kind="plain"
              size="compact"
              onClick={toggleModal}
              icon={<Icon className={styles.icon} source={Maximize} />}
              data-testid={COPILOT_TOGGLE_MODAL_BTN_TEST_ID}
            />
          }
        >
          <Text>Expand</Text>
        </Tooltip>

        <Tooltip
          hoverOnDelay={HEADER_TOOLTIP_HOVER_DELAY}
          kind="invert"
          triggerRenderer={
            <Button
              kind="plain"
              size="compact"
              data-testid={COPILOT_CLOSE_MODAL_TEST_ID}
              onClick={handleCloseModal}
              icon={<Icon className={styles.icon} source={Close} />}
            />
          }
        >
          <Text>Close</Text>
        </Tooltip>
      </div>
    </div>
  );
};
