import isString from 'lodash.isstring';
import { type Row } from 'react-table-7';

import { GENERAL_GROUP_ID } from 'constants/groups';
import { TeamRoute, TeamSubPaths } from 'constants/team/routes';
import type { WorkSchedule, WorkScheduleDays } from 'interfaces/entities/work-schedule';
import type { Group, GroupWithAgents } from 'interfaces/groups';
import { type WeekSchedule } from 'interfaces/work-scheduler';
import { getAgentPermissionScore, getStatusSortScore } from 'routes/team/agents/helpers';
import { WorkScheduleSerializer } from 'services/api/common/work-scheduler/work-schedule-serializer';
import { AppStateProvider } from 'services/app-state-provider';
import type { IAgent } from 'store/entities/agents/interfaces';
import type { IAgentBot } from 'store/entities/bots/interfaces';
import { getTeamListSubRoutes } from 'store/views/team/selectors';

import { type IGroupMember, type INewGroupMember } from './group-form/interfaces';

export const isNewGroupMember = (groupMember: IGroupMember): groupMember is INewGroupMember =>
  (groupMember as INewGroupMember)?.newGroupMember === true;

export const redirectFromAgentsToTeam = (path: string): string => {
  // in case of chatbots we redirect also to agent edit view, but there is a check of agent type and it again redirects to a proper chatbot / agent form - useCorrectAgentFormRoute
  if (path.includes('agents/edit/')) {
    return path.replace('agents/edit/', 'team/agent/');
  }

  if (path.includes('agents/chatbots')) {
    return TeamRoute.Chatbots;
  }

  if (path.includes('agents/groups/edit/')) {
    return path.replace('agents/groups/edit/', 'team/group/');
  }

  if (path.includes('agents/groups')) {
    return TeamRoute.GroupsList;
  }

  return TeamRoute.AgentsList;
};

export function getRedirectTeamPath(currentPathname: string, currentTeamView: TeamSubPaths | null): string | null {
  const paths = currentPathname.slice(1).split('/');
  const subpath = paths[1] ? `/${paths[1]}` as TeamSubPaths : TeamSubPaths.Agents;

  if (subpath === currentTeamView) {
    return null;
  }

  const availableSubpaths = getTeamListSubRoutes(AppStateProvider.getState()).map(({ subPath }) => subPath);
  const isCorrectSubpath = availableSubpaths.includes(subpath as TeamSubPaths);

  if (!isCorrectSubpath) {
    return null;
  }

  if (isString(currentTeamView) && currentTeamView !== subpath) {
    return currentTeamView;
  }

  return subpath;
}

export const sortByAgentPermission = (
  { original: { permission: a, type: typeA } }: { original: IAgent | IAgentBot },
  { original: { permission: b, type: typeB } }: { original: IAgent | IAgentBot }
): number => {
  if (!a || !b) {
    return 0;
  }

  return getAgentPermissionScore(a, typeA) - getAgentPermissionScore(b, typeB);
};

export const sortByGroupMemberPermission = (
  { original: a }: Row<IGroupMember>,
  { original: b }: Row<IGroupMember>
): number => {
  if (isNewGroupMember(a) || isNewGroupMember(b)) {
    return 0;
  }

  return getAgentPermissionScore(a.permission, a.type) - getAgentPermissionScore(b.permission, b.type);
};

export const sortByAgentStatus = (
  { original: { status: aStatus, lastLogout: aLastLogout } }: { original: IAgent | IAgentBot },
  { original: { status: bStatus, lastLogout: bLastLogout } }: { original: IAgent | IAgentBot }
): number => {
  return getStatusSortScore(aStatus, aLastLogout) - getStatusSortScore(bStatus, bLastLogout);
};

export const sortByGroupMemberStatus = (
  { original: a }: Row<IGroupMember>,
  { original: b }: Row<IGroupMember>
): number => {
  if (isNewGroupMember(a) || isNewGroupMember(b)) {
    return 0;
  }

  return getStatusSortScore(a.status, a.lastLogout) - getStatusSortScore(b.status, b.lastLogout);
};

export const sortByGroupStatus = (
  { original: { onlineAgents: aOnlineAgents } }: { original: GroupWithAgents },
  { original: { onlineAgents: bOnlineAgents } }: { original: GroupWithAgents }
): number => {
  return aOnlineAgents - bOnlineAgents;
};

export const disableWorkSchedulerDays = ({ enabled, timezone, ...days }: WorkSchedule): WorkScheduleDays =>
  Object.keys(days).reduce((acc, day) => {
    acc[day] = { ...days[day], isEnabled: false };

    return acc;
  }, {} as WorkScheduleDays);

export const serializeEnabledWorkSchedulerDays = (weekSchedule: WeekSchedule): WorkSchedule => {
  const enabledWorkSchedulerRanges = Object.entries(weekSchedule).reduce((acc, [key, workScheduleRange]) => {
    if (workScheduleRange.enabled) {
      acc[key] = workScheduleRange;
    }

    return acc;
  }, {} as WeekSchedule);

  const serializedWorkScheduler = WorkScheduleSerializer.serializeFormData(enabledWorkSchedulerRanges);

  return serializedWorkScheduler;
};

export const disableGeneralGroupAndSort = (groups: Group[]): Group[] => {
  return groups
    .map((group) =>
      group.id === GENERAL_GROUP_ID
        ? {
            ...group,
            isDisabled: true,
          }
        : group
    )
    .sort((a, b) => {
      if (a.id === GENERAL_GROUP_ID) return -1;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
};
