import { FilterTypeEquality } from 'constants/filters/filter-type';
import { LogicOperator } from 'constants/logic-operator';
import { TrafficSegment } from 'constants/traffic-segment';
import { VisitorState } from 'constants/visitor-state';
import { type KeyMap } from 'helpers/interface';
import type { CustomSegment } from 'interfaces/custom-segment';

export const MAX_CUSTOM_SEGMENTS_COUNT = 9;

export enum CustomSegmentsSection {
  Traffic = 'traffic',
}

export const enum SegmentLabel {
  AllCustomers = 'All customers',
  Chatting = 'Chatting',
  Supervised = 'Supervised',
  Queued = 'Queued',
  WaitingForReply = 'Waiting for reply',
  Invited = 'Invited',
  Browsing = 'Browsing',
}

export const enum CustomSegmentEvent {
  SettingsOpened = 'Segment settings opened',
  SegmentAdded = 'Segment added',
  SegmentChanged = 'Segment changed',
  SegmentDeleted = 'Segment deleted',
  SegmentTooComplex = 'Too complex segment to be saved',
  SegmentLabelChanged = 'Segment label changed',
  SegmentLabelChangedError = 'Segment label changed - error',
  SaveSegmentModal = 'Save segment modal opened',
  OrderChanged = 'Segment order changed',
  OrderChangedError = 'Segment order changed - error',
}

export const TRAFFIC_DEFAULT_SEGMENT_CONFIG: CustomSegment = {
  id: TrafficSegment.AllCustomers,
  label: SegmentLabel.AllCustomers,
  value: {
    filters: [],
    operator: LogicOperator.And,
  },
};

export const TRAFFIC_DEFAULT_SEGMENTS_CONFIG: KeyMap<CustomSegment> = {
  [TrafficSegment.Chatting]: {
    id: TrafficSegment.Chatting,
    label: SegmentLabel.Chatting,
    value: {
      filters: [
        {
          activity: {
            type: FilterTypeEquality.Is,
            values: {
              [FilterTypeEquality.Is]: [VisitorState.Chatting, VisitorState.Supervised],
              [FilterTypeEquality.IsNot]: [],
            },
          },
        },
      ],
      operator: LogicOperator.And,
    },
  },
  [TrafficSegment.Supervised]: {
    id: TrafficSegment.Supervised,
    label: SegmentLabel.Supervised,
    value: {
      filters: [
        {
          activity: {
            type: FilterTypeEquality.Is,
            values: {
              [FilterTypeEquality.Is]: [VisitorState.Supervised],
              [FilterTypeEquality.IsNot]: [],
            },
          },
        },
      ],
      operator: LogicOperator.And,
    },
  },
  [TrafficSegment.Queued]: {
    id: TrafficSegment.Queued,
    label: SegmentLabel.Queued,
    value: {
      filters: [
        {
          activity: {
            type: FilterTypeEquality.Is,
            values: {
              [FilterTypeEquality.Is]: [VisitorState.Queued],
              [FilterTypeEquality.IsNot]: [],
            },
          },
        },
      ],
      operator: LogicOperator.And,
    },
  },
  [TrafficSegment.WaitingForReply]: {
    id: TrafficSegment.WaitingForReply,
    label: SegmentLabel.WaitingForReply,
    value: {
      filters: [
        {
          activity: {
            type: FilterTypeEquality.Is,
            values: {
              [FilterTypeEquality.Is]: [VisitorState.WaitingForReply],
              [FilterTypeEquality.IsNot]: [],
            },
          },
        },
      ],
      operator: LogicOperator.And,
    },
  },
  [TrafficSegment.Invited]: {
    id: TrafficSegment.Invited,
    label: SegmentLabel.Invited,
    value: {
      filters: [
        {
          activity: {
            type: FilterTypeEquality.Is,
            values: {
              [FilterTypeEquality.Is]: [VisitorState.Invited],
              [FilterTypeEquality.IsNot]: [],
            },
          },
        },
      ],
      operator: LogicOperator.And,
    },
  },
  [TrafficSegment.Browsing]: {
    id: TrafficSegment.Browsing,
    label: SegmentLabel.Browsing,
    value: {
      filters: [
        {
          activity: {
            type: FilterTypeEquality.Is,
            values: {
              [FilterTypeEquality.Is]: [VisitorState.Browsing],
              [FilterTypeEquality.IsNot]: [],
            },
          },
        },
      ],
      operator: LogicOperator.And,
    },
  },
};


export const DEFAULT_SEGMENTS_CONFIG: KeyMap<KeyMap<CustomSegment>> = {
  [CustomSegmentsSection.Traffic]: TRAFFIC_DEFAULT_SEGMENTS_CONFIG,
};

export const SEGMENTS_DEFAULT_SEGMENT_ID: KeyMap<string> = {
  [CustomSegmentsSection.Traffic]: TrafficSegment.AllCustomers,
};

export const SEGMENTS_DEFAULT_SEGMENT_CONFIG: KeyMap<CustomSegment> = {
  [CustomSegmentsSection.Traffic]: TRAFFIC_DEFAULT_SEGMENT_CONFIG,
};
