import { AbsoluteAutomateRoutes } from 'constants/automate/constants';

import { CopilotButtonTypes, type CopilotButton } from '../interfaces';

type Message = {
  text: string;
  buttons?: CopilotButton[];
  delay?: number;
} | null;

export type OnboardingConfig = ((contextVariables: { name: string; hasAnyKnowledgeSources: boolean }) => Message)[];

export const COPILOT_ONBOARDING_CONFIG: ((contextVariables: {
  name: string;
  hasAnyKnowledgeSources: boolean;
}) => Message)[] = [
  ({ hasAnyKnowledgeSources }) => ({
    text: `I'm your AI assistant, here to make your job easier. I can:

• answer your questions about LiveChat

• provide your performance metrics

• share product updates

• learn about your business to provide chat support`,
    delay: hasAnyKnowledgeSources ? 2000 : undefined,
    buttons: !hasAnyKnowledgeSources
      ? [
          {
            id: 'train-sources',
            type: CopilotButtonTypes.NAVIGATE,
            content: 'Train me with sources',
            kind: 'primary',
            navigate: {
              responseType: 'final-message',
              url: AbsoluteAutomateRoutes.KnowledgeHubAllSources,
              delay: 2000,
            },
          },
        ]
      : undefined,
  }),
];

export const COPILOT_ONBOARDING_CONFIG_OLD_USERS: ((contextVariables: {
  name: string;
  hasAnyKnowledgeSources: boolean;
}) => Message)[] = [
  () => ({
    text: 'Check out the new and improved Knowledge Hub (previously called Reply suggestions). You can now manage sources more easily and add PDF sources, too.',
  }),
];
