import { type FC } from 'react';

import { Avatar, Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE, COPILOT_LOGO } from 'constants/copilot';
import { getLoggedInAgentName } from 'store/entities/agents/selectors';

import { Suggestions } from './suggestions/Suggestions';

import * as styles from './styles';

export const CopilotFullscreenEmptyState: FC = () => {
  const agentName = useSelector(getLoggedInAgentName);

  return (
    <div className={styles.emptyState}>
      <div className={styles.emptyStateContainer}>
        <Avatar type="image" src={COPILOT_LOGO} size="xxlarge" />
        <div className={styles.emptyStateText}>
          <Text className={styles.emptyStateHeader}>
            {`Hey ${agentName} 👋 ${PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE}`}
          </Text>
          <p className={styles.emptyStateDescription}>I’m Copilot – your AI-powered assistant.</p>
        </div>
      </div>
      <Suggestions />
    </div>
  );
};
