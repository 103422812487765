// @ts-strict-ignore
import { reorder } from 'helpers/reorder';
import { dasherize } from 'helpers/string';

import type {
  SaveCustomSegmentsPayload,
  SelectCustomSegmentPayload,
  LoadCustomSegmentsPayload,
  UpdateCustomSegmentLabelSuccessPayload,
  DeleteCustomSegmentSuccessPayload,
  SaveCustomSegmentSuccessPayload,
  UpdateCustomSegmentSuccessPayload,
  UpdateCustomSegmnetsOrderPayload,
  SetCustomSegmentEditModePayload,
  UpdateCustomSegmentLabelPayload,
  UpdateCustomSegmentLabelFailurePayload,
  CustomSegmentsState,
} from '../interfaces';

export function getStateForSaveCustomSegmentSuccess(
  state: CustomSegmentsState,
  payload: SaveCustomSegmentSuccessPayload
): CustomSegmentsState {
  const { newSortedIds, segmentName, sectionName, valueToSave } = payload;
  const newSegmentId = dasherize(segmentName);

  const newSegmentValue = {
    id: newSegmentId,
    label: segmentName,
    value: valueToSave,
  };

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      selectedId: newSegmentId,
      sortedIds: newSortedIds,
      data: {
        ...state[sectionName]?.data,
        [newSegmentId]: newSegmentValue,
      },
    },
  };
}

export function getStateForUpdateCustomSegmentSuccess(
  state: CustomSegmentsState,
  payload: UpdateCustomSegmentSuccessPayload
): CustomSegmentsState {
  const { newSortedIds, segmentName, sectionName, valueToSave } = payload;
  const newSegmentId = dasherize(segmentName);
  const { selectedId } = state[sectionName];
  const { [selectedId]: oldSegment, ...newDataState } = state[sectionName].data;

  const newSegmentValue = {
    id: newSegmentId,
    label: segmentName,
    value: valueToSave,
  };

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      selectedId: newSegmentId,
      sortedIds: newSortedIds,
      data: {
        ...newDataState,
        [newSegmentId]: newSegmentValue,
      },
    },
  };
}

export function getStateForSaveCustomSegments(
  state: CustomSegmentsState,
  payload: SaveCustomSegmentsPayload
): CustomSegmentsState {
  const { segments, sectionName } = payload;
  const sortedIds = Object.keys(segments);

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      sortedIds,
      data: segments,
    },
  };
}

export function getStateForLoadCustomSegments(
  state: CustomSegmentsState,
  payload: LoadCustomSegmentsPayload
): CustomSegmentsState {
  const { customSegments } = payload;

  return {
    ...state,
    ...customSegments,
  };
}

export function getStateForSelectCustomSegment(
  state: CustomSegmentsState,
  payload: SelectCustomSegmentPayload
): CustomSegmentsState {
  const { segmentId, sectionName } = payload;

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      selectedId: segmentId,
    },
  };
}

export function getStateForUpdateCustomSegmentLabel(
  state: CustomSegmentsState,
  payload: UpdateCustomSegmentLabelPayload
): CustomSegmentsState {
  const { name, sectionName, segmentId } = payload;

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      editedSegment: {
        id: segmentId,
        value: name,
      },
    },
  };
}

export function getStateForUpdateCustomSegmentLabelSuccess(
  state: CustomSegmentsState,
  payload: UpdateCustomSegmentLabelSuccessPayload
): CustomSegmentsState {
  const { sectionName, segmentId, newSegmentId, newSegmentValues, newSortedIds } = payload;
  const { [segmentId]: oldSegment, ...newDataState } = state[sectionName].data;
  const newValueToAdd = {
    [newSegmentId]: {
      ...newSegmentValues,
    },
  };

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      sortedIds: newSortedIds,
      data: {
        ...newValueToAdd,
        ...newDataState,
      },
      editedSegment: null,
    },
  };
}

export function getStateForUpdateCustomSegmentLabelFailure(
  state: CustomSegmentsState,
  payload: UpdateCustomSegmentLabelFailurePayload
): CustomSegmentsState {
  const { sectionName } = payload;

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      editedSegment: null,
    },
  };
}

export function getStateForUpdateCustomSegmentsOrder(
  state: CustomSegmentsState,
  payload: Omit<UpdateCustomSegmnetsOrderPayload, 'eventPlace'>
): CustomSegmentsState {
  const { sectionName, currentIndex, newIndex } = payload;
  const sectionSegmentsOrderIds = state[sectionName]?.sortedIds;
  const newSegmentsOrderIds = reorder(sectionSegmentsOrderIds, currentIndex, newIndex);

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      sortedIds: newSegmentsOrderIds,
    },
  };
}

export function getStateForDeleteCustomSegmentSuccess(
  state: CustomSegmentsState,
  payload: DeleteCustomSegmentSuccessPayload
): CustomSegmentsState {
  const { sectionName, segmentId, sortedIds } = payload;
  const { [segmentId]: segmentToRemove, ...newDataState } = state[sectionName].data;

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      data: newDataState,
      sortedIds,
    },
  };
}

export function getStateForSetCustomSegmentEditMode(
  state: CustomSegmentsState,
  payload: SetCustomSegmentEditModePayload
): CustomSegmentsState {
  const { sectionName, segmentId } = payload;

  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      editedSegment: segmentId
        ? {
            id: segmentId,
            value: null,
          }
        : null,
    },
  };
}
