import { createRequestTypes } from 'store/entities/actions';
import { createAction, type ActionsUnion, type IAction } from 'store/helper';

import type {
  ChangeChatThreadPriorityPayload,
  IAddChatUsersPayload,
  IAddReactionPayload,
  IAssignChatFailurePayload,
  IAssignChatPayload,
  IAssignChatToOtherAgentPayload,
  IChatPickedByOtherAgent,
  IClearAgentSneakPeekPayload,
  IFetchAdditionalUnassignedChatsSummaryPayload,
  IFetchAdditionalUnassignedChatsSummarySuccess,
  IFetchAutoTagsPayload,
  IFetchChatDataPayload,
  IFetchChatHistoryCompletedPayload,
  IFetchChatHistoryPayload,
  IFetchChatThreadsDetailsFailurePayload,
  IFetchChatThreadsDetailsPayload,
  IFetchChatThreadsDetailsSuccessPayload,
  IFetchIncompleteThreadEventsPayload,
  IFetchMoreUnassignedChatsSummarySuccess,
  IFetchTagSuggestionsPayload,
  IFetchUnassignedChatsSummaryFailurePayload,
  IFetchUnassignedChatsSummarySuccessPayload,
  IIncomingChatThreadPayload,
  IMarkChatAsImportantPayload,
  IMarkMultipleChatsAsImportantPayload,
  INewAttachmentMessagePayload,
  INewMessagePayload,
  INewSurveyPayload,
  INewSystemMessagePayload,
  IPickFromQueuePayload,
  IRemoveChatThreadPayload,
  IRemoveChatThreadSuccessPayload,
  IRemoveNotDeliveredMessagesPayload,
  IRemoveTagPayload,
  ISaveTagPayload,
  ISaveTagSuggestionsPayload,
  ISetAgentSneakPeekPayload,
  ISetChatHistoryTimelinePayload,
  ISetChatsDataPayload,
  ISetChatsSummaryPayload,
  ISetSneakPeekPayload,
  ISetTagFailurePayload,
  ISetTagRequestPayload,
  ISetTagSuccessPayload,
  ISetThreadsEventsPayload,
  ISetUnassignedChatsSummaryPayload,
  ISortChatList,
  IStartChatPayload,
  IStartSupervisingFailurePayload,
  IStartSupervisingPayload,
  IStartSupervisingSuccessPayload,
  IStopSupervisingPayload,
  ISupervisePayload,
  IUnmarkChatAsImportantPayload,
  IUnpinChatPayload,
  IUnsetTagFailurePayload,
  IUnsetTagRequestPayload,
  IUnsetTagSuccessPayload,
  IUpdateChatPayload,
  IUpdateChatSupervisorsPayload,
  IUpdateChatTagsPayload,
  IUpdateChatThreadPayload,
  IUpdateEventPayload,
  IUpdateEventsPayload,
  IUpdateMessagePayload,
  IUpdateQueuePositionsPayload,
  IUpdateUnassignedChatsCountPayload,
  SendTagSuggestionsEventPayload,
} from './interfaces';

export enum ChatsEntitiesActionNames {
  ADD_CHAT_USERS = 'ENTITIES/CHATS/ADD_CHAT_USERS',
  ASSIGN_CHAT_TO_OTHER_AGENT = 'ENTITIES/CHATS/ASSIGN_CHAT_TO_OTHER_AGENT',
  ADD_NEW_UNASSIGNED_THREADS = 'ENTITIES/CHATS/ADD_NEW_UNASSIGNED_THREADS',
  CHAT_PICKED_BY_OTHER_AGENT = 'ENTITIES/CHATS/CHAT_PICKED_BY_OTHER_AGENT',
  FETCH_AUTO_TAGS = 'ENTITIES/CHATS/FETCH_AUTO_TAGS',
  SAVE_TAG_SUGGESTIONS = 'ENTITIES/CHAT/SAVE_TAG_SUGGESTIONS',
  SAVE_TAG = 'ENTITIES/CHATS/SAVE_TAG',
  CLOSE_CHAT_THREAD = 'ENTITIES/CHATS/CLOSE_CHAT_THREAD',
  INCOMING_CHAT_THREAD = 'ENTITIES/CHATS/INCOMING_CHAT_THREAD',
  FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY = 'ENTITIES/CHATS/FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY',
  FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_SUCCESS = 'ENTITIES/CHATS/FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_SUCCESS',
  FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_FAILURE = 'ENTITIES/CHATS/FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_FAILURE',
  FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_SUCCESS = 'ENTITIES/CHATS/FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_SUCCESS',
  FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_FAILURE = 'ENTITIES/CHATS/FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_FAILURE',
  FETCH_UNASSIGNED_CHATS_SUMMARY = 'ENTITIES/CHATS/FETCH_UNASSIGNED_CHATS_SUMMARY',
  FINALIZE_CHAT_DATA_LOADING = 'ENTITIES/CHATS/FINALIZE_CHAT_DATA_LOADING',
  NEW_ATTACHMENT = 'ENTITIES/CHAT/NEW_ATTACHMENT',
  NEW_MESSAGE = 'ENTITIES/CHATS/NEW_MESSAGE',
  NEW_SYSTEM_MESSAGE = 'ENTITIES/CHATS/NEW_SYSTEM_MESSAGE',
  NEW_SURVEY = 'ENTITIES/CHAT/NEW_SURVEY',
  MARK_CHAT_AS_IMPORTANT = 'ENTITIES/CHATS/MARK_CHAT_AS_IMPORTANT',
  MARK_MULTIPLE_CHATS_AS_IMPORTANT = 'VIEWS/CHATS/MARK_MULTIPLE_CHATS_AS_IMPORTANT',
  PICK_FROM_QUEUE = 'ENTITIES/CHATS/PICK_FROM_QUEUE',
  START_CHAT = 'ENTITIES/CHATS/START_CHAT',
  SUPERVISE_CHAT = 'ENTITIES/CHATS/SUPERVISE_CHAT',
  ASSIGN_CHAT = 'ENTITIES/CHATS/ASSIGN_CHAT_REQUEST',
  ASSIGN_CHAT_FAILURE = 'ENTITIES/CHATS/ASSIGN_CHAT_FAILURE',
  ASSIGN_CHAT_SUCCESS = 'ENTITIES/CHATS/ASSIGN_CHAT_SUCCESS',
  REMOVE_CHAT_THREAD = 'ENTITIES/CHATS/REMOVE_CHAT_THREAD',
  REMOVE_CHAT_THREAD_SUCCESS = 'ENTITIES/CHATS/REMOVE_CHAT_THREAD_SUCCESS',
  REMOVE_NOT_DELIVERED_MESSAGES = 'ENTITIES/CHATS/REMOVE_NOT_DELIVERED_MESSAGES',
  SET_DATA = 'ENTITIES/CHATS/SET_DATA',
  SET_CHATS_SUMMARY = 'ENTITIES/CHATS/SET_CHATS_SUMMARY',
  SET_UNASSIGNED_CHATS_SUMMARY = 'ENTITIES/CHATS/SET_UNASSIGNED_CHATS_SUMMARY',
  SET_TAG_REQUEST = 'ENTITIES/CHATS/SET_TAG_REQUEST',
  SET_TAG_SUCCESS = 'ENTITIES/CHATS/SET_TAG_SUCCESS',
  SET_TAG_FAILURE = 'ENTITIES/CHATS/SET_TAG_FAILURE',
  SET_THREADS_EVENTS = 'ENTITIES/CHATS/SET_THREADS_EVENTS',
  SET_SNEAK_PEEK = 'ENTITIES/CHATS/SET_SNEAK_PEEK',
  SET_AGENT_SNEAK_PEEK = 'ENTITIES/CHATS/SET_AGENT_SNEAK_PEEK',
  CLEAR_AGENT_SNEAK_PEEK = 'ENTITIES/CHAT/CLEAR_AGENT_SNEAK_PEEK',
  START_SUPERVISING = 'ENTITIES/CHATS/START_SUPERVISING',
  START_SUPERVISING_SUCCESS = 'ENTITIES/CHATS/START_SUPERVISING_SUCCESS',
  START_SUPERVISING_FAILURE = 'ENTITIES/CHATS/START_SUPERVISING_FAILURE',
  STOP_SUPERVISING = 'ENTITIES/CHATS/STOP_SUPERVISING',
  REMOVE_TAG = 'ENTITIES/CHATS/REMOVE_TAG',
  UNSET_TAG_REQUEST = 'ENTITIES/CHATS/UNSET_TAG_REQUEST',
  UNSET_TAG_SUCCESS = 'ENTITIES/CHATS/UNSET_TAG_SUCCESS',
  UNSET_TAG_FAILURE = 'ENTITIES/CHATS/UNSET_TAG_FAILURE',
  UNPIN_CHAT = 'ENTITIES/CHATS/UNPIN_CHAT',
  UPDATE_CHAT = 'ENTITIES/CHATS/UPDATE_CHAT',
  UPDATE_CHAT_THREAD = 'ENTITIES/CHATS/UPDATE_CHAT_THREAD',
  UPDATE_CHAT_TAGS = 'ENTITIES/CHATS/UPDATE_CHAT_TAGS',
  UPDATE_EVENT = 'ENTITIES/CHATS/UPDATE_EVENT',
  UPDATE_EVENTS = 'ENTITIES/CHATS/UPDATE_EVENTS',
  UPDATE_INCOMPLETE_THREAD_EVENTS = 'ENTITIES/CHATS/UPDATE_INCOMPLETE_THREAD_EVENTS',
  UPDATE_MESSAGE = 'ENTITIES/CHATS/UPDATE_MESSAGE',
  UPDATE_UNASSIGNED_CHATS_COUNT = 'ENTITIES/CHATS/UPDATE_UNASSIGNED_CHATS_COUNT',
  UPDATE_UNASSIGNED_CHATS_COUNT_ABORTED = 'ENTITIES/CHATS/UPDATE_UNASSIGNED_CHATS_COUNT_ABORTED',
  UNMARK_CHAT_AS_IMPORTANT = 'VIEWS/CHATS/UNMARK_CHAT_AS_IMPORTANT',
  SORT_CHAT_LIST = 'ENTITIES/CHATS/SORT_CHAT_LIST',
  // Chat history
  FETCH_CHAT_HISTORY = 'ENTITIES/CHATS/FETCH_CHAT_HISTORY',
  FETCH_CHAT_HISTORY_COMPLETED = 'ENTITIES/CHATS/FETCH_CHAT_HISTORY_COMPLETED',
  SET_CHAT_HISTORY_TIMELINE = 'ENTITIES/CHATS/SET_CHAT_HISTORY_TIMELINE',
  UPDATE_CHAT_HISTORY_CUSTOMER_NAME = 'ENTITIES/CHATS/UPDATE_CHAT_HISTORY_CUSTOMER_NAME',
  UPDATE_QUEUE_POSITIONS = 'ENTITIES/CHATS/UPDATE_QUEUE_POSITIONS',
  FETCH_CHAT_THREAD_DETAILS_REQUEST = 'ENTITIES/CHATS/FETCH_CHAT_THREAD_DETAILS_REQUEST',
  FETCH_CHAT_THREAD_DETAILS_SUCCESS = 'ENTITIES/CHATS/FETCH_CHAT_THREAD_DETAILS_SUCCESS',
  FETCH_CHAT_THREAD_DETAILS_FAILURE = 'ENTITIES/CHATS/FETCH_CHAT_THREAD_DETAILS_FAILURE',
  FETCH_CHAT_DATA = 'ENTITIES/CHATS/FETCH_CHAT_DATA',
  UPDATE_CHAT_SUPERVISORS = 'ENTITIES/CHATS/UPDATE_CHAT_SUPERVISORS',
  FETCH_TAG_SUGGESTIONS = 'ENTITIES/CHATS/FETCH_TAG_SUGGESTIONS',
  SET_SENTIMENT_SUCCESS = 'ENTITIES/CHATS/SET_SENTIMENT_SUCCESS',
  ADD_REACTION_REQUEST = 'ENTITIES/CHATS/ADD_REACTION_REQUEST',
  ADD_REACTION_SUCCESS = 'ENTITIES/CHATS/ADD_REACTION_SUCCESS',
  ADD_REACTION_FAILURE = 'ENTITIES/CHATS/ADD_REACTION_FAILURE',
  SEND_TAG_SUGGESTION_EVENT = 'ENTITIES/CHATS/SEND_TAG_SUGGESTION_EVENT',
  SET_TAG_SUGGESTION_MODEL_VERSION = 'ENTITIES/CHATS/SET_TAG_SUGGESTION_MODEL_VERSION',
  CHANGE_CHAT_THREAD_PRIORITY = 'ENTITIES/CHATS/CHANGE_CHAT_THREAD_PRIORITY',
}

export enum ChatsEntitiesRequestFetchingSelectorsNames {
  ASSIGN_CHAT = 'ENTITIES/CHATS/ASSIGN_CHAT',
}

export const CHATS = {
  FETCH_CHAT_THREAD_DETAILS: createRequestTypes('ENTITIES/CHATS/FETCH_CHAT_THREAD_DETAILS'),
  FETCH_INCOMPLETE_THREAD_EVENTS: createRequestTypes('ENTITIES/CHATS/FETCH_INCOMPLETE_THREAD_EVENTS'),
  FETCH_UNASSIGNED_CHATS_SUMMARY: createRequestTypes(ChatsEntitiesActionNames.FETCH_UNASSIGNED_CHATS_SUMMARY),
};

// Used to prevent counter update actions duplication
const lastCounterUpdateAction = {};

const fetchChatThreadDetailsActions = {
  fetchChatThreadDetails: (payload: IFetchChatThreadsDetailsPayload) =>
    createAction(ChatsEntitiesActionNames.FETCH_CHAT_THREAD_DETAILS_REQUEST, payload),
  fetchChatThreadDetailsSuccess: (payload: IFetchChatThreadsDetailsSuccessPayload) =>
    createAction(ChatsEntitiesActionNames.FETCH_CHAT_THREAD_DETAILS_SUCCESS, payload),
  fetchChatThreadDetailsFailure: (payload: IFetchChatThreadsDetailsFailurePayload) =>
    createAction(ChatsEntitiesActionNames.FETCH_CHAT_THREAD_DETAILS_FAILURE, payload),
};

const fetchAdditionalUnassignedChatsSummaryActions = {
  fetchAdditionalUnassignedChatsSummary: (payload: IFetchAdditionalUnassignedChatsSummaryPayload) =>
    createAction(ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY, payload),
  fetchAdditionalUnassignedChatsSummarySuccess: (payload: IFetchAdditionalUnassignedChatsSummarySuccess) =>
    createAction(ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_SUCCESS, payload),
  fetchAdditionalUnassignedChatsSummaryFailure: () =>
    createAction(ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_FAILURE),
};

const fetchMoreUnassignedChatsSummaryActions = {
  fetchMoreUnassignedChatsSummarySuccess: (payload: IFetchMoreUnassignedChatsSummarySuccess) =>
    createAction(ChatsEntitiesActionNames.FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_SUCCESS, payload),
  fetchMoreUnassignedChatsSummaryFailure: () =>
    createAction(ChatsEntitiesActionNames.FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_FAILURE),
};

const fetchUnassignedChatsSummaryActions = {
  fetchUnassignedChatsSummary: () => createAction('ENTITIES/CHATS/FETCH_UNASSIGNED_CHATS_SUMMARY_REQUEST'),
  fetchUnassignedChatsSummarySuccess: (payload: IFetchUnassignedChatsSummarySuccessPayload) =>
    createAction('ENTITIES/CHATS/FETCH_UNASSIGNED_CHATS_SUMMARY_SUCCESS', payload),
  fetchUnassignedChatsSummaryFailure: (payload: IFetchUnassignedChatsSummaryFailurePayload) =>
    createAction('ENTITIES/CHATS/FETCH_UNASSIGNED_CHATS_SUMMARY_FAILURE', payload),
};

const fetchIncompleteThreadEventsActions = {
  fetchIncompleteThreadEvents: (payload: IFetchIncompleteThreadEventsPayload) =>
    createAction('ENTITIES/CHATS/FETCH_INCOMPLETE_THREAD_EVENTS_REQUEST', payload),
};

const startSupervisingActions = {
  startSupervising: (payload: IStartSupervisingPayload) =>
    createAction(ChatsEntitiesActionNames.START_SUPERVISING, payload),
  startSupervisingSuccess: (payload: IStartSupervisingSuccessPayload) =>
    createAction(ChatsEntitiesActionNames.START_SUPERVISING_SUCCESS, payload),
  startSupervisingFailure: (payload: IStartSupervisingFailurePayload) =>
    createAction(ChatsEntitiesActionNames.START_SUPERVISING_FAILURE, payload),
};

const fetchChatHistoryActions = {
  fetchChatHistory: (payload: IFetchChatHistoryPayload) =>
    createAction(ChatsEntitiesActionNames.FETCH_CHAT_HISTORY, payload),
  fetchChatHistoryCompleted: (payload: IFetchChatHistoryCompletedPayload) =>
    createAction(ChatsEntitiesActionNames.FETCH_CHAT_HISTORY_COMPLETED, payload),
  setChatHistoryTimeline: (payload: ISetChatHistoryTimelinePayload) =>
    createAction(ChatsEntitiesActionNames.SET_CHAT_HISTORY_TIMELINE, payload),
};

const chatReactionActions = {
  addReactionRequest: (payload: IAddReactionPayload) =>
    createAction(ChatsEntitiesActionNames.ADD_REACTION_REQUEST, payload),
  addReactionSuccess: () => createAction(ChatsEntitiesActionNames.ADD_REACTION_SUCCESS),
  addReactionFailure: () => createAction(ChatsEntitiesActionNames.ADD_REACTION_FAILURE),
};

export const ChatsEntitiesActions = {
  ...fetchChatThreadDetailsActions,
  ...fetchAdditionalUnassignedChatsSummaryActions,
  ...fetchMoreUnassignedChatsSummaryActions,
  ...fetchUnassignedChatsSummaryActions,
  ...fetchIncompleteThreadEventsActions,
  ...startSupervisingActions,
  ...fetchChatHistoryActions,
  ...chatReactionActions,
  assignChatToOtherAgent: (payload: IAssignChatToOtherAgentPayload) =>
    createAction(ChatsEntitiesActionNames.ASSIGN_CHAT_TO_OTHER_AGENT, payload),
  chatPickedByOtherAgent: (payload: IChatPickedByOtherAgent) =>
    createAction(ChatsEntitiesActionNames.CHAT_PICKED_BY_OTHER_AGENT, payload),
  fetchAutoTags: (payload: IFetchAutoTagsPayload) => createAction(ChatsEntitiesActionNames.FETCH_AUTO_TAGS, payload),
  finalizeChatDataLoading: (): IAction<ChatsEntitiesActionNames.FINALIZE_CHAT_DATA_LOADING> =>
    createAction(ChatsEntitiesActionNames.FINALIZE_CHAT_DATA_LOADING),
  incomingChatThread: (payload: IIncomingChatThreadPayload) =>
    createAction(ChatsEntitiesActionNames.INCOMING_CHAT_THREAD, payload),
  newMessage: (payload: INewMessagePayload) => createAction(ChatsEntitiesActionNames.NEW_MESSAGE, payload),
  newSystemMessage: (payload: INewSystemMessagePayload) =>
    createAction(ChatsEntitiesActionNames.NEW_SYSTEM_MESSAGE, payload),
  newSurvey: (payload: INewSurveyPayload) => createAction(ChatsEntitiesActionNames.NEW_SURVEY, payload),
  newAttachmentMessage: (payload: INewAttachmentMessagePayload) =>
    createAction(ChatsEntitiesActionNames.NEW_ATTACHMENT, payload),
  markChatAsImportant: (payload: IMarkChatAsImportantPayload) =>
    createAction(ChatsEntitiesActionNames.MARK_CHAT_AS_IMPORTANT, payload),
  markMultipleChatsAsImportant: (payload: IMarkMultipleChatsAsImportantPayload) =>
    createAction(ChatsEntitiesActionNames.MARK_MULTIPLE_CHATS_AS_IMPORTANT, payload),
  removeChatThread: (payload: IRemoveChatThreadPayload) =>
    createAction(ChatsEntitiesActionNames.REMOVE_CHAT_THREAD, payload),
  removeChatThreadSuccess: (payload: IRemoveChatThreadSuccessPayload) =>
    createAction(ChatsEntitiesActionNames.REMOVE_CHAT_THREAD_SUCCESS, payload),
  setData: (payload: ISetChatsDataPayload) => createAction(ChatsEntitiesActionNames.SET_DATA, payload),
  setChatsSummary: (payload: ISetChatsSummaryPayload) =>
    createAction(ChatsEntitiesActionNames.SET_CHATS_SUMMARY, payload),
  setUnassignedChatsSummary: (payload: ISetUnassignedChatsSummaryPayload) =>
    createAction(ChatsEntitiesActionNames.SET_UNASSIGNED_CHATS_SUMMARY, payload),
  saveTagSuggestions: (payload: ISaveTagSuggestionsPayload) =>
    createAction(ChatsEntitiesActionNames.SAVE_TAG_SUGGESTIONS, payload),
  saveTag: (payload: ISaveTagPayload) => createAction(ChatsEntitiesActionNames.SAVE_TAG, payload),
  setTagRequest: (payload: ISetTagRequestPayload) => createAction(ChatsEntitiesActionNames.SET_TAG_REQUEST, payload),
  setTagSuccess: (payload: ISetTagSuccessPayload) => createAction(ChatsEntitiesActionNames.SET_TAG_SUCCESS, payload),
  setTagFailure: (payload: ISetTagFailurePayload) => createAction(ChatsEntitiesActionNames.SET_TAG_FAILURE, payload),
  setSneakPeek: (payload: ISetSneakPeekPayload) => createAction(ChatsEntitiesActionNames.SET_SNEAK_PEEK, payload),
  setAgentSneakPeek: (payload: ISetAgentSneakPeekPayload) =>
    createAction(ChatsEntitiesActionNames.SET_AGENT_SNEAK_PEEK, payload),
  clearAgentSneakPeek: (payload: IClearAgentSneakPeekPayload) =>
    createAction(ChatsEntitiesActionNames.CLEAR_AGENT_SNEAK_PEEK, payload),
  pickFromQueue: (payload: IPickFromQueuePayload) => createAction(ChatsEntitiesActionNames.PICK_FROM_QUEUE, payload),
  startChat: (payload: IStartChatPayload) => createAction(ChatsEntitiesActionNames.START_CHAT, payload),
  supervise: (payload: ISupervisePayload) => createAction(ChatsEntitiesActionNames.SUPERVISE_CHAT, payload),
  assignChat: (payload: IAssignChatPayload) => createAction(ChatsEntitiesActionNames.ASSIGN_CHAT, payload),
  assignChatSuccess: (payload: IAssignChatPayload) =>
    createAction(ChatsEntitiesActionNames.ASSIGN_CHAT_SUCCESS, payload),
  assignChatFailure: (payload: IAssignChatFailurePayload) =>
    createAction(ChatsEntitiesActionNames.ASSIGN_CHAT_FAILURE, payload),
  stopSupervising: (payload: IStopSupervisingPayload) =>
    createAction(ChatsEntitiesActionNames.STOP_SUPERVISING, payload),
  removeTag: (payload: IRemoveTagPayload) => createAction(ChatsEntitiesActionNames.REMOVE_TAG, payload),
  unsetTagRequest: (payload: IUnsetTagRequestPayload) =>
    createAction(ChatsEntitiesActionNames.UNSET_TAG_REQUEST, payload),
  unsetTagSuccess: (payload: IUnsetTagSuccessPayload) =>
    createAction(ChatsEntitiesActionNames.UNSET_TAG_SUCCESS, payload),
  unsetTagFailure: (payload: IUnsetTagFailurePayload) =>
    createAction(ChatsEntitiesActionNames.UNSET_TAG_FAILURE, payload),
  updateChat: (payload: IUpdateChatPayload) => createAction(ChatsEntitiesActionNames.UPDATE_CHAT, payload),
  updateChatThread: (payload: IUpdateChatThreadPayload) =>
    createAction(ChatsEntitiesActionNames.UPDATE_CHAT_THREAD, payload),
  updateChatTags: (payload: IUpdateChatTagsPayload) => createAction(ChatsEntitiesActionNames.UPDATE_CHAT_TAGS, payload),
  updateEvent: (payload: IUpdateEventPayload) => createAction(ChatsEntitiesActionNames.UPDATE_EVENT, payload),
  updateEvents: (payload: IUpdateEventsPayload) => createAction(ChatsEntitiesActionNames.UPDATE_EVENTS, payload),
  updateIncompleteThreadEvents: (payload: IUpdateEventsPayload) =>
    createAction(ChatsEntitiesActionNames.UPDATE_INCOMPLETE_THREAD_EVENTS, payload),
  updateUnassignedChatsCount: (payload: IUpdateUnassignedChatsCountPayload) => {
    /**
     * Prevents duplication of the action with the same type
     * Action of the same type shouldn't be performed for the same thread twice in a row...
     * ... and should be aborted
     * Thanks to that Unassigned Chats counter should be more accurate
     */
    const { threadId, type } = payload;
    if (lastCounterUpdateAction[threadId] === type) {
      return createAction(ChatsEntitiesActionNames.UPDATE_UNASSIGNED_CHATS_COUNT_ABORTED, payload);
    }

    return createAction(ChatsEntitiesActionNames.UPDATE_UNASSIGNED_CHATS_COUNT, payload);
  },
  updateMessage: (payload: IUpdateMessagePayload) => createAction(ChatsEntitiesActionNames.UPDATE_MESSAGE, payload),
  unmarkChatAsImportant: (payload: IUnmarkChatAsImportantPayload) =>
    createAction(ChatsEntitiesActionNames.UNMARK_CHAT_AS_IMPORTANT, payload),
  removeNotDeliveredMessages: (payload: IRemoveNotDeliveredMessagesPayload) =>
    createAction(ChatsEntitiesActionNames.REMOVE_NOT_DELIVERED_MESSAGES, payload),
  setThreadsEvents: (payload: ISetThreadsEventsPayload) =>
    createAction(ChatsEntitiesActionNames.SET_THREADS_EVENTS, payload),
  updateChatThreadHistoryName: (payload: IUpdateChatThreadPayload) =>
    createAction(ChatsEntitiesActionNames.UPDATE_CHAT_HISTORY_CUSTOMER_NAME, payload),
  addChatUsers: (payload: IAddChatUsersPayload) => createAction(ChatsEntitiesActionNames.ADD_CHAT_USERS, payload),
  unpinChat: (payload: IUnpinChatPayload) => createAction(ChatsEntitiesActionNames.UNPIN_CHAT, payload),
  sortChatList: (payload: ISortChatList) => createAction(ChatsEntitiesActionNames.SORT_CHAT_LIST, payload),
  updateQueuePositions: (payload: IUpdateQueuePositionsPayload) =>
    createAction(ChatsEntitiesActionNames.UPDATE_QUEUE_POSITIONS, payload),
  fetchChatData: (payload: IFetchChatDataPayload) => createAction(ChatsEntitiesActionNames.FETCH_CHAT_DATA, payload),
  updateChatSupervisors: (payload: IUpdateChatSupervisorsPayload) =>
    createAction(ChatsEntitiesActionNames.UPDATE_CHAT_SUPERVISORS, payload),
  fetchTagSuggestions: (payload: IFetchTagSuggestionsPayload) =>
    createAction(ChatsEntitiesActionNames.FETCH_TAG_SUGGESTIONS, payload),
  sendTagSuggestionEvent: (payload: SendTagSuggestionsEventPayload) =>
    createAction(ChatsEntitiesActionNames.SEND_TAG_SUGGESTION_EVENT, payload),
  setTagSuggestionModelVersion: (modelVersion: string) =>
    createAction(ChatsEntitiesActionNames.SET_TAG_SUGGESTION_MODEL_VERSION, modelVersion),
  setSentimentSuccess: () => createAction(ChatsEntitiesActionNames.SET_SENTIMENT_SUCCESS),
  changeChatThreadPriority: (payload: ChangeChatThreadPriorityPayload) =>
    createAction(ChatsEntitiesActionNames.CHANGE_CHAT_THREAD_PRIORITY, payload),
};

export type ChatEntitiesAction = ActionsUnion<typeof ChatsEntitiesActions>;
