// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { Cookie } from 'constants/cookies';
import { removeCookie } from 'helpers/cookies';
import { RecurlyService } from 'services/recurly';
import { getSubscription } from 'store/entities/subscription/selectors';
import { type IActionWithPayload } from 'store/helper';

import { SubscriptionActions } from '../subscription/actions';

import { RecurlyActions, RecurlyActionNames } from './actions';
import { type ICheckRecurlyCouponPayload, type IRecurlyCoupon } from './interfaces';

const checkCoupon = (coupon: string): Promise<IRecurlyCoupon> => RecurlyService.checkCoupon(coupon);

export function* checkCurrentRecurlyCouponSaga(): SagaIterator {
  try {
    const { coupon }: ReturnType<typeof getSubscription> = yield select(getSubscription);
    const response: IRecurlyCoupon = yield call(checkCoupon, coupon && coupon.name);
    yield put(RecurlyActions.checkCurrentRecurlyCouponSuccess(response));
  } catch (error) {
    yield put(RecurlyActions.checkCurrentRecurlyCouponFailure(error));
  }
}

function* checkNewRecurlyCouponSaga(data: IActionWithPayload<string, ICheckRecurlyCouponPayload>): SagaIterator {
  try {
    const { coupon, hideDiscount } = data.payload;
    const response: IRecurlyCoupon = yield call(checkCoupon, coupon);
    yield put(RecurlyActions.checkNewRecurlyCouponSuccess({ ...response, hideDiscount }));
  } catch (error) {
    yield put(RecurlyActions.checkNewRecurlyCouponFailure(error));
  }
}

export function* removeNewCouponSaga(): SagaIterator {
  yield put(SubscriptionActions.subscriptionUpdated({ couponCode: '' }));
  yield put(SubscriptionActions.subscriptionChangeUpdated({ couponCode: '' }));
  removeCookie(Cookie.CouponCode);
}

export function* recurlySaga(): SagaIterator {
  yield takeLatest(RecurlyActionNames.CHECK_CURRENT_RECURLY_COUPON_REQUEST, checkCurrentRecurlyCouponSaga);
  yield takeLatest(
    [RecurlyActionNames.CHECK_NEW_RECURLY_COUPON_REQUEST, RecurlyActionNames.CHECK_COVID_COUPON_REQUEST],
    checkNewRecurlyCouponSaga
  );
  yield takeLatest(RecurlyActionNames.REMOVE_NEW_COUPON, removeNewCouponSaga);
}
