import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { WebSocketAction } from 'constants/web-socket-action';
import { getServer } from 'helpers/get-server';
import { type MarkEventsAsSeenEvent } from 'interfaces/web-socket-events';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#mark-events-as-seen
 */
export async function markEventsAsSeen(chatId: string, seenUpToInSeconds: number): Promise<unknown> {
  const seenUpTo = format(new Date(seenUpToInSeconds * 1000), DateFormat.ISO8601DateTimeWithMicrosAndTimezone);

  const event: MarkEventsAsSeenEvent = {
    action: WebSocketAction.MarkEventsAsSeen,
    payload: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      chat_id: chatId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      seen_up_to: seenUpTo,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
