import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { type IUpdateQueuePositionsPayload } from 'store/entities/chats/interfaces';
import { getThreadExists } from 'store/entities/chats/selectors';

import { type IQueuePositionsUpdatedPushEvent } from '../interfaces';

/**
 * Handles queue positions updated push event.
 * 1. Update position and wait time for affected threads.
 * @param {IQueuePositionsUpdatedPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleQueuePositionsUpdated(payload: IQueuePositionsUpdatedPushEvent, store: IAppState): void {
  const state = store.getState();
  const threads: IUpdateQueuePositionsPayload['threads'] = payload
    .filter((t) => getThreadExists(state, t.thread_id))
    .map((t) => ({
      id: t.thread_id,
      position: t.queue.position,
      waitingTime: t.queue.wait_time,
    }));

  /**
   * 1. Update position and wait time for affected threads.
   */
  if (threads.length > 0) {
    store.dispatch(
      ChatsEntitiesActions.updateQueuePositions({
        threads,
      })
    );
  }
}
