import type { IAppState } from 'services/app-state-provider';
import { type Agent } from 'services/connectivity/configuration-api/agents/types';
import { AgentActions } from 'store/entities/agents/actions';
import { SubscriptionViewActions } from 'store/views/subscription/actions';
import { TeamActions } from 'store/views/team/actions';
import { getIsAgentSelected } from 'store/views/team/selectors';

export function handleAgentDeleted(payload: Pick<Agent, 'id'>, store: IAppState): void {
  store.dispatch(AgentActions.agentRemove({ login: payload.id }));
  store.dispatch(SubscriptionViewActions.agentsCountChanged());

  const isAgentSelected = getIsAgentSelected(store.getState(), payload.id);
  if (isAgentSelected) {
    store.dispatch(TeamActions.removeSelectedAgentLogin(payload.id));
  }
}
