import { ThemeName } from 'constants/themes';
import { shouldApplySelectedTheme } from 'helpers/dark-mode';
import {
  type IWithAgentCustomPropertiesState,
  getCurrentTheme,
} from 'store/features/agent-custom-properties/selectors';

export function getThemeOnCurrentSection(state: IWithAgentCustomPropertiesState): ThemeName {
  const theme = getCurrentTheme(state);

  return shouldApplySelectedTheme(window.location.pathname) ? theme : ThemeName.Light;
}

export function isDarkModeOnCurrentSection(state: IWithAgentCustomPropertiesState): boolean {
  const theme = getThemeOnCurrentSection(state);

  return theme === ThemeName.Dark;
}
