export enum Platform {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iOS = 'iOS',
  Android = 'Android',
  Windows = 'Windows',
  Mac = 'Mac',
  Other = 'Other',
}

export const CustomPercyUserAgent = 'custom-percy-user-agent';
