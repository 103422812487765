import { type FC, useLayoutEffect, useState } from 'react';

import { type History } from 'history';
import { Router } from 'react-router-dom';

import { getAppRoot } from 'helpers/url';

interface Props {
  history: History;
}

const getBasename = (): string => {
  const appRoot = getAppRoot();

  return appRoot.endsWith('/') ? appRoot.slice(0, -1) : appRoot;
}

// INFO - this custom router is needed, as after react-router-dom migration to v6, we need to manually listen to history changes
export const HistoryRouter: FC<Props> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);
  const basename = getBasename();

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
