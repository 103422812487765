// @ts-strict-ignore

import { AgentPriority } from 'constants/agent-priority';
import { AgentType } from 'constants/agent-type';
import { type LoginStatus } from 'constants/login-status';
import { Role } from 'constants/role';
import { getBrowserTimezone } from 'helpers/get-browser-timezone';
import type { PickRequired } from 'helpers/interface';
import { isDefined } from 'helpers/is-defined';
import { type RoutingStatus } from 'services/connectivity/agent-chat-api/status/types';
import { type Bot, type UpdateBotRequest } from 'services/connectivity/configuration-api/bots/types';
import { mapRoutingStatusToLoginStatus } from 'services/socket-lc3/agent/helpers';
import type { IAgentBot } from 'store/entities/bots/interfaces';

import { AgentStatusSerializerV3 } from '../agent/v3/agent-status-serializer';
import { AgentBaseSerializerV3 } from '../common/agent-base/agent-base-serializer-v3';

export abstract class AgentBotSerializer {
  /**
   * Deserializes bot agent data provided by API.
   * @param data Bot agent data provided in API structure.
   */
  static deserializeV3(data: Bot, status: LoginStatus): IAgentBot {
    return {
      ...AgentBaseSerializerV3.deserialize(data, status),
      statusAfterLogin: AgentStatusSerializerV3.deserializeStatusAfterLogin(data.login_status),
      defaultGroupPriority: AgentPriority.First,
      timezone: data.timezone,
      type: AgentType.Bot,
      role: Role.Chatbot,
      status,
    };
  }

  static partialDeserializeV3(data: PickRequired<Bot, 'id'>): PickRequired<IAgentBot, 'login'> {
    const result = AgentBaseSerializerV3.partialDeserialize(data) as PickRequired<IAgentBot, 'login'>;

    if (isDefined(data.login_status)) {
      result.statusAfterLogin = AgentStatusSerializerV3.deserializeStatusAfterLogin(data.login_status);
    }

    if (isDefined(data.timezone)) {
      result.timezone = data.timezone;
    }

    return result;
  }

  static serializeV35(model: PickRequired<IAgentBot, 'login'>): UpdateBotRequest {
    const serialized: UpdateBotRequest = AgentBaseSerializerV3.serialize(model);

    if (model.workSchedule) {
      serialized.work_scheduler = {
        ...serialized.work_scheduler,
        timezone: getBrowserTimezone(),
      };
    }

    return serialized;
  }
}

export function deserializeBots(data: Bot[], statuses: Record<string, RoutingStatus>): IAgentBot[] {
  return data.map((bot) => AgentBotSerializer.deserializeV3(bot, mapRoutingStatusToLoginStatus(statuses[bot.id])));
}
