import isEmpty from 'lodash.isempty';

import { type KeyMap } from 'helpers/interface';
import { type ICampaign, type ICampaignApplication } from 'store/features/applications/interfaces';

export const filterHiddenAndInstalledApps = (
  campaigns: ICampaign[],
  installedApplications: ICampaignApplication[]
): KeyMap<ICampaignApplication[]> =>
  campaigns.reduce((acc, campaign) => {
    const galleryList: ICampaignApplication[] = campaign.applications.filter(
      (app) => !installedApplications.find((installed) => installed.id === app.id) && !app.hidden
    );
    if (!isEmpty(galleryList)) {
      acc[campaign.id] = galleryList;
    }

    return acc;
  }, {});
