import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Navigation as DSNavigation, NavigationGroup } from '@livechat/design-system-react-components';
import { QueryClientProvider } from '@tanstack/react-query';

import { MyProfileItem } from 'components/new-navigation/items/my-profile-item/MyProfileItem';
import { NavigationItem } from 'components/new-navigation/items/navigation-item/NavigationItem';
import { SubscriptionItem } from 'components/new-navigation/items/subscription-item/SubscriptionItem';
import { TopNavigation } from 'components/new-navigation/top-navigation/TopNavigation';
import { NavigationId } from 'constants/navigation';
import { getQueryClient } from 'services/query-client/client';

import { NAVIGATION_BAR_TEST_ID } from '../constants';
import { handleItemClick } from '../helpers/item-click';
import { NavigationApps } from '../navigation-apps/NavigationApps';

import * as styles from './styles';

export const Navigation: FC = () => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <DSNavigation data-testid={NAVIGATION_BAR_TEST_ID} className={styles.container}>
        <NavigationGroup scrollable>
          <TopNavigation />
          <NavigationApps onItemClick={handleItemClick} />
        </NavigationGroup>
        <NavigationGroup>
          <SubscriptionItem />
          <NavigationItem id={NavigationId.Settings} />
          <NavigationItem id={NavigationId.News} className={cx('beamerTrigger', styles.news)} />
          <MyProfileItem />
        </NavigationGroup>
      </DSNavigation>
    </QueryClientProvider>
  );
};
