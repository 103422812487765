// @ts-strict-ignore
import { type ReactNode } from 'react';

import { css } from '@emotion/css';
import { format } from 'date-fns';
import difference from 'lodash.difference';

import type { LabelTheme } from 'components/label/Label';
import { DateFormat } from 'constants/date';
import { type Filter, type Filters } from 'constants/filters/filter';
import { batchActionStatusMap, TicketsBatchAction } from 'constants/tickets/batch-action';
import { type Column, ColumnWidths } from 'constants/tickets/columns';
import { SESSION_STORAGE_DRAFT_KEY, NEW_TICKET_TEMP_ID } from 'constants/tickets/drafts';
import { availableFiltersRouteMap } from 'constants/tickets/filters';
import { Route, pathnameToRouteMap, routeToPathnameMap } from 'constants/tickets/navigation-items';
import { MAX_TICKET_MESSAGE_EVENT_LENGTH } from 'constants/tickets/validation';
import { type KeyMap } from 'helpers/interface';
import { escapeString, capitalizeFirstLetter } from 'helpers/string';
import { type IState } from 'interfaces/components/tickets/ticket-add';
import {
  Author,
  EventType,
  type IEvent,
  type IRequester,
  type ITicket,
  type ITicketSource,
  SourceType,
  TicketStatus,
  type IDraft,
} from 'interfaces/entities/ticket';
import type { Group, GroupBase } from 'interfaces/groups';
import { getItem } from 'services/session-storage';
import { type ITicketsViewBatchUpdatePayloadData } from 'store/views/tickets/interfaces';

import { timeAgo } from './date';
import { mapTextToReactElements } from './dom-parser';
import { url as urlRegExp } from './regexp';

export const getColumnWidthsCss = (column: Column): string => css`
  ${ColumnWidths[column] ? `width: ${ColumnWidths[column]}px` : null};
`;

export function eventSupportsMessage(event: IEvent): boolean {
  return [EventType.Message, EventType.CompressionMessage].includes(event.type);
}

export function getLastMessageEvent(events: IEvent[]): IEvent {
  if (events.length === 0) {
    return null;
  }

  return [...events].reverse().find((e: IEvent) => eventSupportsMessage(e)) || null;
}

export function getLastPublicMessageEvent(events: IEvent[]): IEvent {
  if (!events.length) {
    return null;
  }

  return (
    [...events]
      .reverse()
      .find((e: IEvent) => (e.type === EventType.Message && !e.isPrivate) || e.type === EventType.CompressionMessage) ||
    null
  );
}

export function getLastCustomerMessageEvent(events: IEvent[]): IEvent {
  if (events.length === 0) {
    return null;
  }

  return (
    [...events].reverse().find((e: IEvent) => e.type === EventType.Message && e.author.type === Author.Customer) || null
  );
}

const routeToBatchActionsMap = {
  [Route.Unassigned]: [
    TicketsBatchAction.MarkAsSolved,
    TicketsBatchAction.MarkAsSpam,
    TicketsBatchAction.ChangeAssignee,
    TicketsBatchAction.ChangeGroup,
  ],
  [Route.MyOpen]: [
    TicketsBatchAction.MarkAsSolved,
    TicketsBatchAction.MarkAsSpam,
    TicketsBatchAction.ChangeAssignee,
    TicketsBatchAction.ChangeGroup,
  ],
  [Route.Open]: [
    TicketsBatchAction.MarkAsSolved,
    TicketsBatchAction.MarkAsSpam,
    TicketsBatchAction.ChangeAssignee,
    TicketsBatchAction.ChangeGroup,
  ],
  [Route.Pending]: [
    TicketsBatchAction.MarkAsSolved,
    TicketsBatchAction.MarkAsSpam,
    TicketsBatchAction.ChangeAssignee,
    TicketsBatchAction.ChangeGroup,
  ],
  [Route.Solved]: [TicketsBatchAction.MarkAsOpen, TicketsBatchAction.MarkAsSpam],
  [Route.Spam]: [TicketsBatchAction.MarkAsOpen],
  [Route.Search]: [TicketsBatchAction.ChangeAssignee, TicketsBatchAction.ChangeGroup],
  [Route.All]: [TicketsBatchAction.ChangeAssignee, TicketsBatchAction.ChangeGroup],
};

export function getBatchActionsForRoute(route: Route): TicketsBatchAction[] {
  return routeToBatchActionsMap[route] || [];
}

export function getToastContent(
  ticketIds: string[],
  batchAction: TicketsBatchAction,
  data?: ITicketsViewBatchUpdatePayloadData
): string {
  if (batchAction === TicketsBatchAction.ChangeAssignee) {
    if (ticketIds.length === 1) {
      return `Ticket ${ticketIds[0]} has been assigned to ${data.assignee.name}.`;
    }

    return `${ticketIds.length} tickets have been assigned to ${data.assignee.name}.`;
  }

  if (batchAction === TicketsBatchAction.ChangeGroup) {
    if (ticketIds.length === 1) {
      return `Ticket ${ticketIds[0]} has been moved to ${data.group.name}.`;
    }

    return `${ticketIds.length} tickets have been moved to ${data.group.name}.`;
  }

  const status = batchActionStatusMap[batchAction];
  if (ticketIds.length === 1) {
    return `Ticket ${ticketIds[0]} has been marked as ${status}.`;
  }

  return `${ticketIds.length} tickets have been marked as ${status}.`;
}

export function getFiltersForRoute(filters: Filters, route: Route): Filters {
  return Object.keys(filters).reduce((acc, filterName) => {
    if (availableFiltersRouteMap[route].find((availableFilter) => availableFilter.filter === (filterName as Filter))) {
      return {
        ...acc,
        [filterName]: filters[filterName],
      };
    }

    return acc;
  }, {});
}

export function eventIsValidInSummary(event: IEvent): boolean {
  return (event.type === EventType.Message && !event.isPrivate) || event.type === EventType.CompressionMessage;
}

export function getTicketSummary(ticket: ITicket): { firstMessage?: IEvent; lastMessage?: IEvent } {
  let firstMessage = null;
  let lastMessage = null;

  firstMessage = ticket.events.find(eventIsValidInSummary);
  lastMessage = [...ticket.events].reverse().find(eventIsValidInSummary);

  if (!firstMessage) {
    return {};
  }

  return { firstMessage, lastMessage };
}

const ticketStatusToLabelMap: KeyMap<LabelTheme> = {
  [TicketStatus.Open]: 'blue',
  [TicketStatus.Pending]: 'black',
  [TicketStatus.Solved]: 'green',
  [TicketStatus.Spam]: 'grey',
};

export function getLabelColorByStatus(ticketStatus: string): LabelTheme {
  return ticketStatusToLabelMap[ticketStatus] || 'blue';
}

export function getRouteByPathname(pathname: string): Route {
  if (!pathname) {
    return null;
  }

  const path = pathname.split('/').filter(Boolean).pop().split('?')[0].split('#')[0];

  return pathnameToRouteMap[path];
}

export function getPathnameByRoute(route: Route): string {
  return routeToPathnameMap[route] || '/tickets';
}

export function parseEventMessage(message = ''): string {
  let msg = message;
  msg = msg.trim();
  msg = msg.replace(/<BR>/g, '\n');
  msg = escapeString(msg);
  msg = msg.replace(/\n{3,}/g, '\n\n');

  if (msg.length > MAX_TICKET_MESSAGE_EVENT_LENGTH && !message.startsWith('Chat transcript:')) {
    const result = msg.slice(MAX_TICKET_MESSAGE_EVENT_LENGTH).search(/\s/);
    const maxTicketMessageLength = MAX_TICKET_MESSAGE_EVENT_LENGTH + Math.max(result, 0);

    msg = `${msg.substring(0, maxTicketMessageLength)} ...`;
  }

  msg = msg
    .split(urlRegExp)
    .filter(Boolean)
    .map((splitted: string) => {
      const isUrl = urlRegExp.test(splitted);
      if (isUrl) {
        const href = splitted.startsWith('www.') ? `http://${splitted}` : splitted;

        return `<a target="_blank" rel="nofollow noreferrer noopener" href="${href}">${splitted}</a>`;
      }

      return splitted;
    })
    .join('');

  msg = msg.replace(/\n/g, '<BR>');

  return msg;
}

export function parseEventMessageToHtml(message = ''): ReactNode {
  return mapTextToReactElements(parseEventMessage(message), ['A', 'BR']);
}

export function getTicketStatusLabel(status: string): string {
  return capitalizeFirstLetter(status).replace('-', ' ');
}

export function getTicketSource(source: ITicketSource): ReactNode {
  switch (source.type) {
    case SourceType.Chat:
      return <a href={`/archives/${source.id}`}>Chat</a>;

    case SourceType.Email:
      return 'Email';

    case SourceType.TicketForm:
      return (
        <>
          <span>Ticket form</span>
          {source.url && (
            <a href={source.url} title={source.url} target="_blank" rel="noreferrer">
              {source.url}
            </a>
          )}
        </>
      );

    case SourceType.Manual:
      return 'Created manually';

    default:
      return null;
  }
}

export function getEventDescriptionHtml(
  event: IEvent,
  requester?: IRequester,
  groups?: Group[],
  ticketGroups?: GroupBase[]
): string {
  const sanitizedRequesterName = requester ? escapeString(requester.name || requester.email) : '';
  const sanitizedAuthorName = event.author ? escapeString(event.author.name) : '';

  switch (event.type) {
    case EventType.AssigneeChanged:
      if (sanitizedAuthorName) {
        return `${sanitizedAuthorName} assigned ticket to <strong>${escapeString(event.to.name)}</strong>`;
      }

      return `Ticket assigned to <strong>${escapeString(event.to.name)}</strong>`;

    case EventType.AssigneeRemoved:
      if (event.from) {
        return `${sanitizedAuthorName} unassigned ticket from <strong>${escapeString(event.from.name)}</strong>`;
      }

      return null;

    case EventType.CurrentGroupChanged: {
      if (!groups) {
        return null;
      }

      let groupName: string;
      const eventGroup = groups.find((group) => String(group.id) === event.current);
      if (eventGroup) {
        groupName = eventGroup.name;
      } else {
        const eventTicketGroup = ticketGroups.find((group) => String(group.id) === event.current);
        if (eventTicketGroup) {
          groupName = eventTicketGroup.name;
        }
      }

      if (groupName) {
        if (sanitizedAuthorName) {
          return `${sanitizedAuthorName} assigned ticket to group <strong>${escapeString(groupName)}</strong>`;
        }

        return `Ticket assigned to <strong>${escapeString(groupName)}</strong>`;
      }

      return 'Ticket group has been removed';
    }

    case EventType.SubjectChanged:
      return `${sanitizedAuthorName} changed ticket subject to <strong>${escapeString(event.current)}</strong>`;

    case EventType.RequesterChanged:
      return `${sanitizedAuthorName} changed requester e-mail to <strong>${escapeString(event.current)}</strong>`;

    case EventType.StatusChanged:
      if (sanitizedAuthorName) {
        return `${sanitizedAuthorName} changed ticket status from ${getTicketStatusLabel(
          event.previous
        )} to <strong>${getTicketStatusLabel(event.current)}</strong>`;
      }

      return `Ticket status changed from ${getTicketStatusLabel(event.previous)} to <strong>${getTicketStatusLabel(
        event.current
      )}</strong>`;

    case EventType.CcChanged: {
      const ccsCurrent = event.current.split(',');
      const ccsPrevious = event.previous.split(',');
      const sanitizedCCsAdded = escapeString(difference(ccsCurrent, ccsPrevious).join(', '));
      const sanitizedCCsRemoved = escapeString(difference(ccsPrevious, ccsCurrent).join(', '));

      if (sanitizedCCsAdded && sanitizedCCsRemoved) {
        return `${sanitizedAuthorName} added <strong>${sanitizedCCsAdded}</strong> and removed <strong>${sanitizedCCsRemoved}</strong> from CC`;
      }
      if (sanitizedCCsAdded) {
        return `${sanitizedAuthorName} added <strong>${sanitizedCCsAdded}</strong> to CC`;
      }

      return `${sanitizedAuthorName} removed <strong>${sanitizedCCsRemoved}</strong> from CC`;
    }

    case EventType.FollowUpSent:
      return `Auto follow-up sent to <strong>${sanitizedRequesterName}</strong> after 3 days without a reply`;

    case EventType.RatingOfferSent:
      return `<strong>${sanitizedRequesterName}</strong> was asked to rate the customer service`;

    case EventType.Rated:
      return `<strong>${sanitizedRequesterName}</strong> rated the customer service as <strong>${escapeString(
        event.current
      )}</strong>`;

    case EventType.CompressionMessage:
      // this will be done in a separate PR
      return 'Click to view previous messages: …';

    default:
      return null;
  }
}

export function getEventDate(eventDate: Date): string {
  return format(eventDate, DateFormat.ShortDateWithDayOfWeekAndTime);
}

export function getFormattedEventDate(eventDate: Date): string {
  const now = new Date();

  return timeAgo(eventDate, now, { dateFormat: DateFormat.ShortDateWithDayOfWeekAndTime, useStrict: false });
}

export function getTicketAdditionInitialState(): IState {
  const ticketDrafts: Record<string, IDraft> = getItem(SESSION_STORAGE_DRAFT_KEY) || {};
  const draft: IDraft = ticketDrafts[NEW_TICKET_TEMP_ID] || { message: '' };

  const subject = draft.subject || '';
  const requesterEmail = draft.requesterEmail || '';
  const requesterName = draft.requesterName || '';
  const assigneeId = draft.assigneeId;
  const groupIds = draft.groupIds || ['0'];
  const message = draft.message || '';

  return {
    formElements: {
      subject: { value: subject },
      requesterEmail: { value: requesterEmail },
      requesterName: { value: requesterName },
    },
    assigneeId,
    groupIds,
    message,
  };
}
