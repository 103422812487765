import debug from 'debug';
import '../../app';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { waitForFreeAccessPage, waitForInitError, waitForInitialData } from 'entries/base/base';
import { type InitialData } from 'entries/base/interfaces/initial-data';
import { type StartupDataResultFailure } from 'entries/base/interfaces/startup-data';

import { initializeFreeAccessPage } from './initialize/initialize-free-access-page';
import { initializeWithData } from './initialize/initialize-with-data';
import { initializeWithError } from './initialize/initialize-with-error';

const log = debug(DebugLogsNamespace.AppStartup);

function start(): void {
  void waitForInitialData().then((initialData: InitialData) => {
    log('startup data ready', { initialData });
    initializeWithData(initialData);
  });

  void waitForInitError().then((error: StartupDataResultFailure) => {
    log('startup data error', { error });
    void initializeWithError(error);
  });

  void waitForFreeAccessPage().then((path: string) => {
    // initialize the app just to show /keep-on-chatting-with-customers or /ticket_rating page
    log('showing free access page', path);
    initializeFreeAccessPage(path);
  });
}

start();
