// @ts-strict-ignore
import { type LanguageViewAction, LanguageViewActionNames } from './actions';
import { type ILanguageViewState } from './interfaces';

const initialState: ILanguageViewState = {
  language: null,
};

const languageReducer = (state: ILanguageViewState = initialState, action: LanguageViewAction): ILanguageViewState => {
  switch (action.type) {
    case LanguageViewActionNames.SET_CURRENT_LANGUAGE: {
      return {
        ...state,
        language: action.payload.language,
      };
    }

    default:
      return state;
  }
};

export default languageReducer;
