
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';
import { getSeriesDataFromPeriod, sumValueFromPeriod } from 'helpers/reports';
import { type IAchievedGoalsData } from 'interfaces/reports';

export function achievedGoalsDeserializer({ data }: any): IAchievedGoalsData {
  const goals = getSeriesDataFromPeriod<number>(data.goals, 'goals');
  const goalsSummary = sumValueFromPeriod(data.goals, 'goals');

  return {
    goals: {
      summary: [
        {
          name: 'goals',
          value: goalsSummary,
          color: DEFAULT_SERIES_COLOR,
        },
      ],
      labels: Object.keys(data.goals),
      series: [
        {
          name: 'goals',
          color: DEFAULT_SERIES_COLOR,
          data: goals,
        },
      ],
    },
  };
}
