// @ts-strict-ignore
import type { IKeyValue, ParamValue } from 'helpers/url';

export type SessionStorageItem = ParamValue | ParamValue[] | IKeyValue;

const supportsSessionStorage = (): boolean => !!window.sessionStorage;

export function getItem<T = SessionStorageItem>(name: string): T | null {
  if (!supportsSessionStorage()) {
    return null;
  }

  let value: string | null;

  try {
    value = window.sessionStorage.getItem(name);
  } catch {
    return null;
  }

  if (value === null) return null;

  try {
    return JSON.parse(value) as T;
  } catch {
    return value as T;
  }
}

export const saveItem = (name: string, value: unknown): boolean => {
  if (!supportsSessionStorage()) {
    return false;
  }

  const toSave = typeof value !== 'string' ? JSON.stringify(value) : value;

  try {
    window.sessionStorage.setItem(name, toSave);

    return true;
  } catch (e) {
    return false;
  }
};

export const removeItem = (name: string): boolean => {
  if (!supportsSessionStorage()) {
    return false;
  }

  try {
    window.sessionStorage.removeItem(name);

    return true;
  } catch (e) {
    return false;
  }
};
