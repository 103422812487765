import { isBooleanOrUndefined } from 'helpers/boolean';

export function isValidButtonDefinition(rawDefinitionData: unknown): boolean {
  if (!rawDefinitionData) {
    return false;
  }
  const { label, id, openApp } = rawDefinitionData as Record<string, unknown>;

  return Boolean(label && id && isBooleanOrUndefined(openApp));
}
