import { useSelector } from 'react-redux';

import { getWaitingForApprovalAgents } from 'store/entities/agents/selectors';
import {
  getHasPendingInvites,
  getPendingInvitesCount,
  getTeamApproveAllSeen,
} from 'store/features/agent-custom-properties/selectors';
import { getCanManageInvitations } from 'store/features/session/selectors';

import { type BadgeProps, calculateBadgeProps } from './helpers';

export const useTeamBadgeProps = (): BadgeProps => {
  const canManageInvitations = useSelector(getCanManageInvitations);
  const teamApproveAllSeen = useSelector(getTeamApproveAllSeen);
  const hasPendingInvites = useSelector(getHasPendingInvites);
  const waitingForApprovalAgents = useSelector(getWaitingForApprovalAgents);
  const pendingInvitesCount = useSelector(getPendingInvitesCount);

  return calculateBadgeProps(
    canManageInvitations,
    teamApproveAllSeen,
    hasPendingInvites,
    waitingForApprovalAgents.length,
    pendingInvitesCount
  );
};
