import { HOUR_IN_SECONDS } from 'constants/time-units';
import { formatTime } from 'helpers/format-time';
import { getPeriodValuesFilled, getAverageSeconds, getSeriesDataFromPeriod } from 'helpers/reports';
import {
  type IChatResponseTimeData,
  type IChatResponseTimePeriodData,
  type IChatFirstResponseTimePeriodData,
} from 'interfaces/reports';
import { type ReportData } from 'interfaces/reports/api-v3';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';

interface IChatResponseTimePayload {
  data: {
    firstResponseTime: ReportData<IChatFirstResponseTimePeriodData>;
    responseTime: ReportData<IChatResponseTimePeriodData>;
  };
}

export function deserializeChatResponseTime({ data }: IChatResponseTimePayload): IChatResponseTimeData {
  const firstResponseTimeData = getSeriesDataFromPeriod<IChatFirstResponseTimePeriodData>(
    data.firstResponseTime.records
  );
  const firstResponseTimeSecondsList = getPeriodValuesFilled<IChatFirstResponseTimePeriodData>(
    firstResponseTimeData,
    'first_response_time'
  );
  const firstResponseTimeCountList = getPeriodValuesFilled<IChatFirstResponseTimePeriodData>(
    firstResponseTimeData,
    'count'
  );
  const averageFirstResponseTimeData = getAverageSeconds<IChatFirstResponseTimePeriodData>(
    firstResponseTimeData,
    'first_response_time'
  );

  const responseTimeData = getSeriesDataFromPeriod<IChatResponseTimePeriodData>(data.responseTime.records);
  const responseTimeSecondsList = getPeriodValuesFilled<IChatResponseTimePeriodData>(responseTimeData, 'response_time');
  const responseTimeCountList = getPeriodValuesFilled<IChatResponseTimePeriodData>(responseTimeData, 'count');
  const averageResponseTimeData = getAverageSeconds<IChatResponseTimePeriodData>(responseTimeData, 'response_time');

  return {
    firstResponseTime: {
      summary: [
        {
          name: 'First response time',
          value: formatTime(averageFirstResponseTimeData / HOUR_IN_SECONDS),
          color: DEFAULT_SERIES_COLOR,
        },
      ],
      labels: Object.keys(data.firstResponseTime.records),
      series: [
        {
          data: firstResponseTimeSecondsList.map(Math.round),
          color: DEFAULT_SERIES_COLOR,
          extras: {
            counts: firstResponseTimeCountList,
          },
          name: 'First response time',
          csvColumnName: 'First response time',
        },
      ],
    },
    responseTime: {
      summary: [
        {
          name: 'Avg. response time',
          value: formatTime(averageResponseTimeData / HOUR_IN_SECONDS),
          color: DEFAULT_SERIES_COLOR,
        },
      ],
      labels: Object.keys(data.responseTime.records),
      series: [
        {
          data: responseTimeSecondsList,
          color: DEFAULT_SERIES_COLOR,
          extras: {
            counts: responseTimeCountList,
          },
          name: 'Avg. response time',
          csvColumnName: 'Avg. response time',
        },
      ],
    },
  };
}
