import { type MutateOptions, type UseMutateFunction } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { type LoginStatus } from 'constants/login-status';
import { type EventPlace } from 'helpers/analytics';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';

import { useStatusChange } from './use-status-change';

interface IUseChangeCurrentAgentStatus {
  handleStatusChange: UseMutateFunction<void, string, IChangeCurrentAgentStatusPayload, unknown>;
  isProcessing: boolean;
  isSuccess: boolean;
  error: string | null;
}

interface IChangeCurrentAgentStatusPayload {
  status: LoginStatus;
  eventPlace?: EventPlace;
}

export const useChangeCurrentAgentStatus = (): IUseChangeCurrentAgentStatus => {
  const login = useSelector(getLoggedInAgentLogin);
  const { error, handleStatusChange, isProcessing, isSuccess } = useStatusChange();

  const handleCurrentAgentStatusChange = (
    payload: IChangeCurrentAgentStatusPayload,
    options?: MutateOptions<void, string, IChangeCurrentAgentStatusPayload, unknown>
  ): void => handleStatusChange({ ...payload, login }, options);

  return {
    handleStatusChange: handleCurrentAgentStatusChange,
    isProcessing,
    isSuccess,
    error,
  };
};
