import isNil from 'lodash.isnil';
import keyBy from 'lodash.keyby';

import type { KeyMap } from 'helpers/interface';
import type { EntityFetchCollectionSuccess, EntityFetchSuccess } from 'store/entities/interfaces';

import { type GoalAction, GoalActionNames } from './actions';
import { type IGoal, type IGoalRemovedPayload } from './interfaces';

const initialState = {};

export const goalsReducer = (state: KeyMap<IGoal> = initialState, action: GoalAction): KeyMap<IGoal> => {
  switch (action.type) {
    case GoalActionNames.GOALS_RESET: {
      const { values } = action.payload as EntityFetchCollectionSuccess<IGoal>;

      return keyBy(values, 'id');
    }

    case GoalActionNames.GOAL_ADD: {
      const { value, id } = action.payload as EntityFetchSuccess<IGoal>;

      if (!isNil(value)) {
        return {
          ...state,
          [id]: value,
        };
      }

      return state;
    }

    case GoalActionNames.GOAL_UPDATE: {
      const { value, id } = action.payload as EntityFetchSuccess<IGoal>;

      return {
        ...state,
        [id]: {
          ...state[id],
          ...value,
        },
      };
    }

    case GoalActionNames.GOAL_REMOVE: {
      const payload = action.payload as IGoalRemovedPayload;
      const newState = { ...state };
      delete newState[payload.id];

      return newState;
    }

    default:
      return state;
  }
};
