import { type ActionsUnion, createAction } from 'store/helper';

import { type ISetCurrentLanguagePayload, type ISetCurrentLanguagePhrasesPayload } from './interfaces';

export enum LanguageViewActionNames {
  SET_CURRENT_LANGUAGE_CODE = 'VIEWS/SETTINGS/LANGUAGE/SET_CURRENT_LANGUAGE_CODE',
  SET_CURRENT_LANGUAGE = 'VIEWS/SETTINGS/LANGUAGE/SET_CURRENT_LANGUAGE',
}

export const LanguageViewActions = {
  setCurrentLanguageCode: (payload: ISetCurrentLanguagePayload) =>
    createAction(LanguageViewActionNames.SET_CURRENT_LANGUAGE_CODE, payload),
  setCurrentLanguage: (payload: ISetCurrentLanguagePhrasesPayload) =>
    createAction(LanguageViewActionNames.SET_CURRENT_LANGUAGE, payload),
};

export type LanguageViewAction = ActionsUnion<typeof LanguageViewActions>;
