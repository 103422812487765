import { type FC, useCallback } from 'react';

import { TopBarNotificationType } from 'constants/notifications';

import { ChangeBrowserBar } from './ChangeBrowserBar';
import { EnableNotificationsBar } from './EnableNotificationsBar';
import { UnblockNotificationsBar } from './UnblockNotififcationsBar';

interface IProps {
  type?: TopBarNotificationType;
  onSkip(name: TopBarNotificationType): void;
  shouldBeVisible: boolean;
}
export const BrowserNotificationsBar: FC<IProps> = ({ type, onSkip, shouldBeVisible }) => {
  const isBarVisible = (barType: TopBarNotificationType): boolean => type === barType && shouldBeVisible;
  const handleSkipClick = useCallback((): void => {
    onSkip(TopBarNotificationType.BrowserNotifications);
  }, [onSkip]);

  return (
    <>
      <ChangeBrowserBar
        isVisible={isBarVisible(TopBarNotificationType.ChangeBrowser)}
        onSkip={handleSkipClick}
      />
      <EnableNotificationsBar
        isVisible={isBarVisible(TopBarNotificationType.EnableNotifications)}
        onSkip={handleSkipClick}
      />
      <UnblockNotificationsBar
        isVisible={isBarVisible(TopBarNotificationType.UnblockNotifications)}
        onSkip={handleSkipClick}
      />
    </>
  );
};
