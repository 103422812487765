import { useSelector, type DefaultRootState } from 'react-redux';

type Selector<Result, Params extends unknown[]> = (state: DefaultRootState, ...args: Params) => Result;

export function useParamSelector<Result, Params extends unknown[]>(
  selector: Selector<Result, Params>,
  ...params: Params
): Result {
  return useSelector((state) => selector(state, ...params));
}
