import { type KeyMap } from 'helpers/interface';

import { LicenseCustomPropertiesActionNames, type LicenseCustomPropertiesAction } from './actions';

const initialState: KeyMap<string | string[]> = {};

export function licenseCustomPropertiesReducer(
  state: KeyMap<string | string[]> = initialState,
  action: LicenseCustomPropertiesAction
): KeyMap<string | string[]> {
  switch (action.type) {
    case LicenseCustomPropertiesActionNames.FETCH_LICENSE_CUSTOM_PROPERTIES_SUCCESS:
      return {
        ...action.payload,
      };

    case LicenseCustomPropertiesActionNames.SAVE_LICENSE_CUSTOM_PROPERTY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
