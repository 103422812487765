import {
  KnowledgeSourceItemStatus,
  KnowledgeSourceType,
  type KnowledgeSourceItem,
  type KnowledgeSourcePdfItem,
  type KnowledgeSourceFileItem,
} from 'interfaces/knowledge-sources';
import { AppStateProvider } from 'services/app-state-provider';
import { getPublicKnowledgeSourceIds } from 'store/entities/ai-agents/selectors';

export const getIsAnyPublicKnowledgeSourceLearned = (data: KnowledgeSourceItem[] = []): boolean => {
  const publicSourceIds = AppStateProvider.selectFromStore(getPublicKnowledgeSourceIds);

  return data.some((item) => {
    if (!publicSourceIds.length || !publicSourceIds.includes(item.id)) {
      return false;
    }

    return (
      item.status === KnowledgeSourceItemStatus.COMPLETED ||
      (item.status === KnowledgeSourceItemStatus.FAILED && !!item?.learnedAt)
    );
  });
};

export function isSourceFileType(item: unknown): item is KnowledgeSourceFileItem {
  return (item as KnowledgeSourcePdfItem).sourceType === KnowledgeSourceType.File;
}
