import { createConfig, isValidRegion, updateRegion } from '@config';
import type { IConfig } from '@config/interfaces';
import envConfig from 'env-config';
import { getRegion } from 'services/auth/auth-storage-manager';

import { getUrlParam } from './url';

export function updateDatacenter(config: IConfig, datacenter?: string): void {
  const region = datacenter || config.datacenter.current;

  if (isValidRegion(region)) {
    updateRegion(envConfig, config, region);
  }
}

let config: IConfig | null = null;

export function getConfig(reg?: string | null): IConfig {
  if (!config || reg) {
    config = createConfig(envConfig);
    const region = getUrlParam('region') || reg || getRegion();
    if (region) {
      updateDatacenter(config, region);
    }
  }

  return config;
}
