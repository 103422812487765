import { type FC, useEffect, useState } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Text } from '@livechat/design-system-react-components';

import { useListSuggestions } from 'hooks/use-list-suggestions';
import { useParamSelector } from 'hooks/use-param-selector';
import { getIsAIAgentAvailableByName } from 'store/entities/ai-agents/selectors';

import { deserializeSuggestionWithShortcuts } from '../helpers';

import * as styles from './styles';

export const MessageBoxTip: FC = () => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [editor] = useLexicalComposerContext();
  const hasOneAIAgent = useParamSelector(getIsAIAgentAvailableByName, 'one');

  const { data } = useListSuggestions();
  const suggestions = deserializeSuggestionWithShortcuts(data);

  useEffect(() => {
    const unregisterTextContentListener = editor.registerTextContentListener((textContent) =>
      setIsEmpty(textContent.length === 0),
    );

    return () => {
      unregisterTextContentListener();
    };
  }, [editor]);

  if (!hasOneAIAgent) {
    return null;
  }

  if (isEmpty && suggestions.length) {
    return (
      <Text className={styles.messageBoxTip}>
        Type <strong>/</strong> to get suggestions
      </Text>
    );
  }

  return (
    <Text className={styles.messageBoxTip}>
      Hit <strong>&crarr;</strong> to send a message
    </Text>
  );
};
