import { createConfigurationApiClient } from '../client';
import {
  type ApiClientBatchResponse,
  type ConfigurationApiClientError,
  type ConfigurationApiErrorResponse,
} from '../types';

import {
  type ApproveAgentRequest,
  type BatchApproveAgentsRequest,
  type BatchCreateAgentsRequest,
  type BatchSuspendAgentsRequest,
  type BatchUpdateAgentsRequest,
  type CreateAgentResponse,
  type DeleteAgentRequest,
  type GetAgentRequest,
  type GetAgentResponse,
  type ListAgentsRequest,
  type ListAgentsResponse,
  type ReactivateEmailRequest,
  type SuspendAgentRequest,
  type UnsuspendAgentRequest,
  type UpdateAgentRequest,
  type UpdateAgentResponse,
} from './types';

const client = createConfigurationApiClient();

/**
 * Configuration API agents client.
 * @see {@link https://platform.text.com/docs/management/configuration-api#agents}
 */
export const agentsClient = {
  /**
   * Batch create agents.
   * @see {@link https://platform.text.com/docs/management/configuration-api#create-agent}
   */
  batchCreate: async (data: BatchCreateAgentsRequest) => {
    return client.post<ApiClientBatchResponse<CreateAgentResponse>, ConfigurationApiErrorResponse>(
      'batch_create_agents',
      { json: data }
    );
  },

  /**
   * Retrieves an agent by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#get-agent}
   */
  get: async (data: GetAgentRequest) => {
    return client.post<GetAgentResponse, ConfigurationApiErrorResponse>('get_agent', { json: data });
  },

  /**
   * Lists all agents.
   * @see {@link https://platform.text.com/docs/management/configuration-api#list-agents}
   */
  list: async (data: ListAgentsRequest) => {
    return client.post<ListAgentsResponse, ConfigurationApiErrorResponse>('list_agents', { json: data });
  },

  /**
   * Updates an agent by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#update-agent}
   */
  update: async (data: UpdateAgentRequest) => {
    return client.post<UpdateAgentResponse, ConfigurationApiErrorResponse>('update_agent', { json: data });
  },

  /**
   * Batch update agents.
   * @see {@link https://platform.text.com/docs/management/configuration-api#update-agent}
   */
  batchUpdate: async (data: BatchUpdateAgentsRequest) => {
    return client.post<ApiClientBatchResponse<void>, ConfigurationApiClientError>('batch_update_agents', {
      json: data,
    });
  },

  /**
   * Deletes an agent by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#delete-agent}
   */
  delete: async (data: DeleteAgentRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('delete_agent', { json: data });
  },

  /**
   * Suspends an agent by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#suspend-agent}
   */
  suspend: async (data: SuspendAgentRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('suspend_agent', { json: data });
  },

  /**
   * Batch suspend agents.
   * @see {@link https://platform.text.com/docs/management/configuration-api#suspend-agent}
   */
  batchSuspend: async (data: BatchSuspendAgentsRequest) => {
    return client.post<ApiClientBatchResponse<void>, ConfigurationApiClientError>('batch_suspend_agents', {
      json: data,
    });
  },

  /**
   * Unsuspends an agent by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#unsuspend-agent}
   */
  unsuspend: async (data: UnsuspendAgentRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('unsuspend_agent', { json: data });
  },

  /**
   * Requests an agent unsuspension.
   * @see {@link https://platform.text.com/docs/management/configuration-api#request-agent-unsuspension}
   */
  requestAgentUnsuspension: async () => {
    return client.post<void, ConfigurationApiErrorResponse>('request_agent_unsuspension', { json: {} });
  },

  /**
   * Approves an agent by ID.
   * @see {@link https://platform.text.com/docs/management/configuration-api#approve-agent}
   */
  approve: async (data: ApproveAgentRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('approve_agent', { json: data });
  },

  /**
   * Batch approve agents.
   * @see {@link https://platform.text.com/docs/management/configuration-api#approve-agent}
   */
  batchApprove: async (data: BatchApproveAgentsRequest) => {
    return client.post<ApiClientBatchResponse<void>, ConfigurationApiErrorResponse>('batch_approve_agents', {
      json: data,
    });
  },

  /**
   * Reactivates an agent by ID.
   */
  reactivateEmail: async (data: ReactivateEmailRequest) => {
    return client.post<void, ConfigurationApiErrorResponse>('reactivate_email', {
      json: data,
      prefixUrl: new URL('v3.6/configuration/action', client.baseURL).toString(),
    });
  },
};
