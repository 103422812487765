import { combineReducers } from 'redux';

import { type KeyMap } from 'helpers/interface';

import { type TooltipAction, TooltipsActionsNames } from './actions';

const tooltipsVisible = (state: KeyMap<boolean> = {}, action: TooltipAction): KeyMap<boolean> => {
  switch (action.type) {
    case TooltipsActionsNames.SHOW_TOOLTIP:
      return { ...state, [action.payload.tooltip]: true };
    case TooltipsActionsNames.HIDE_TOOLTIP:
      return { ...state, [action.payload.tooltip]: false };
    default:
      return state;
  }
};

export const tooltipsReducer = combineReducers({
  tooltipsVisible,
});
