export enum FormId {
  Offline = 'offline',
  Queued = 'queued',
  Email = 'email',
  AskForEmail = 'ask_for_email',
  PostChat = 'postchat',
}

export enum FormFieldType {
  Header = 'header',
  Name = 'name',
  Email = 'email',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Textarea = 'textarea',
  Subject = 'subject',
  GroupChooser = 'group_chooser',
  Question = 'question',
  Select = 'select',
}

export interface IFormField {
  id?: string;
  type: FormFieldType;
  required: boolean;
  label: string;
  answers?: string[];
  checked?: boolean[];
}

type FormFieldPayload = Pick<IFormField, 'label' | 'type' | 'id'> & { required: number };

export interface IForm {
  fields: IFormField[];
}

export interface IFormPayload {
  fields: FormFieldPayload[];
}
