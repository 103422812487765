// @ts-strict-ignore
import { type KeyMap } from 'helpers/interface';
import { deserializeThread } from 'services/socket-lc3/chat/serialization/deserialize';
import { getThreadEventsMergedWithNotDelivered } from 'services/socket-lc3/chat/serialization/deserialize-event-collection';
import { getWasEventSeen } from 'services/socket-lc3/chat/serialization/helpers/common';
import { type ChatEventEntity, type IUnassignedChat } from 'store/entities/chats/interfaces';

import { type IChatResult } from './interfaces';

export interface IDeserializeChatThreadDetails {
  threads: KeyMap<IUnassignedChat>;
  events: KeyMap<KeyMap<ChatEventEntity>>;
  threadTags: KeyMap<string[]>;
}

export function deserializeChatsThreadsDetails(
  chats: IChatResult[],
  currentAgentId: string
): IDeserializeChatThreadDetails {
  return chats.reduce(
    (acc, chatResult) => {
      const { thread: rawThread, users: chatUsers } = chatResult;
      const hasThreadRestrictedAccess = rawThread.restricted_access;
      if (hasThreadRestrictedAccess) {
        return acc;
      }

      const thread = deserializeThread(chatResult, currentAgentId);
      const isChatTypeSupported = thread !== null;

      if (isChatTypeSupported) {
        const events = getThreadEventsMergedWithNotDelivered(
          thread.threadId,
          currentAgentId,
          rawThread.events,
          chatUsers,
          getWasEventSeen
        );

        return {
          threads: {
            ...acc.threads,
            [thread.threadId]: thread,
          },
          events: {
            ...acc.events,
            ...(events && { [thread.threadId]: events }),
          },
          threadTags: {
            ...acc.threadTags,
            [thread.threadId]: rawThread.tags,
          },
        };
      }

      return acc;
    },
    {
      threads: {},
      events: {},
      threadTags: {},
    }
  );
}
