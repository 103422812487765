// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { deserializeError } from 'helpers/deserialize-error';
import { trimProtocol } from 'helpers/url';
import type { RequestResult } from 'interfaces/api/client';
import { GroupProperty, type IGroupProperties } from 'interfaces/entities/group-property';
import { ApiManager } from 'services/api/api-manager';
import { type IActionWithPayload } from 'store/helper';
import { SettingsViewActionNames } from 'store/views/settings/actions';
import { getSelectedGroupId } from 'store/views/settings/selectors';

import { GroupPropertiesActionNames, GroupPropertiesActions } from './actions';
import { type IFetchGroupPropertiesPayload, type ISaveGroupPropertiesPayload } from './interfaces';

function* fetchCurrentGroupProperties(): SagaIterator {
  const groupId: ReturnType<typeof getSelectedGroupId> = yield select(getSelectedGroupId);
  yield put(GroupPropertiesActions.fetch({ groupId }));
}
function* fetchGroupProperties(action: IActionWithPayload<string, IFetchGroupPropertiesPayload>): SagaIterator {
  const { groupId } = action.payload;
  const { result, error }: RequestResult<IGroupProperties> = yield call(
    ApiManager.groupApi.fetchGroupProperties,
    groupId
  );

  if (result) {
    yield put(GroupPropertiesActions.fetchSuccess({ groupId, properties: result }));
  } else {
    yield put(GroupPropertiesActions.fetchFailure({ groupId, error: error.message }));
  }
}

function* saveGroupProperties(action: IActionWithPayload<string, ISaveGroupPropertiesPayload>): SagaIterator {
  const { groupId, properties: rawProperties } = action.payload;
  const { [GroupProperty.ChatWindowLogoPath]: logo, ...properties } = rawProperties;

  const responses: RequestResult[] = yield all([
    Object.keys(properties).length > 0 &&
      call(ApiManager.groupApi.saveGroupProperties, groupId, properties as Partial<IGroupProperties>),
    logo && call(ApiManager.groupApi.saveGroupLogo, groupId, trimProtocol(logo)),
  ]);

  const error = responses
    .filter((response) => !!response?.error)
    .map(deserializeError)
    .join('. ');

  if (!error) {
    yield put(
      GroupPropertiesActions.saveSuccess({
        groupId,
        properties: logo ? { [GroupProperty.ChatWindowLogoPath]: logo, ...properties } : properties,
      })
    );
  } else {
    yield put(GroupPropertiesActions.saveFailure({ error }));
  }
}

export function* groupPropertiesSagas(): SagaIterator {
  yield takeEvery(SettingsViewActionNames.SET_SELECTED_GROUP_ID, fetchCurrentGroupProperties);
  yield takeEvery(GroupPropertiesActionNames.FETCH, fetchGroupProperties);
  yield takeEvery(GroupPropertiesActionNames.SAVE, saveGroupProperties);
}
