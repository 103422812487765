import { keyframes, css } from '@emotion/css';

const typingAnimation = keyframes`
	0% {
		opacity: 0.2;
	}

	20% {
		opacity: 0.6;
	}

	100% {
		opacity: 0.2;
	}
`;

export const typingIndicator = css`
  display: inline-block;
  width: 25px;
  height: 17px;
  background-color: #e2e5e7;
  line-height: 13px;
  border-radius: 15px;
  padding: 6px 5px;
  box-sizing: border-box;
  vertical-align: middle;

  span {
    animation-name: ${typingAnimation};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    background-color: #000;
    border-radius: 50%;
    display: block;
    height: 3px;
    width: 3px;
    float: left;
    margin: 1px;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
`;

export const selectedThreadTypingIndicator = css`
  background-color: #7dadff;

  span {
    background-color: #16356b;
  }
`;

export const hoveredThreadTypingIndicator = css`
  background-color: #d2d2d2;
`;
