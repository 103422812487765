// @ts-strict-ignore
import { ChatThreadVisualStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { Section } from 'constants/section';
import { UserType } from 'constants/user-type';
import { EventPlace } from 'helpers/analytics';
import { isChattingAgent, isSupervisingAgent } from 'services/api/interfaces/chat/helpers';
import type { IAppState } from 'services/app-state-provider';
import { chatFollowManager } from 'services/chat-follow-manager';
import { trackEvent } from 'services/event-tracking';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import {
  getIsChatSupervised,
  getIsQueueThread,
  getIsUnassignedThread,
  getThread,
  getThreadType,
  getWasThreadSupervised,
} from 'store/entities/chats/selectors';
import { getIsQueueMessagingEnabled } from 'store/entities/integration-license-properties/selectors';
import { getSectionName } from 'store/features/routing/selectors';
import { ChatsViewActions } from 'store/views/chats/actions';
import { getNumberOfCustomerMessagesInThread } from 'store/views/chats/selectors';

import type { IUserAddedToChatPushEvent } from '../interfaces';

import { ChatOperatorEvent } from './constants';

/**
 * Handles user added to chat push event.
 * 1. Current agent has started supervising.
 * 2. Current agent is now chatting due to transfer or auto assignment, etc.
 * 3. Queued or unnasigned chat was assigned to other agent as chatting
 * 4. Chat that is supervised by current agent has changed chatting agent to different one
 * 5. Supervising was started by other agent.
 * @param {IUserAddedToChatPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleUserAddedToChat(payload: IUserAddedToChatPushEvent, store: IAppState): void {
  const { thread_id: threadId, user, chat_id: chatId } = payload;
  const { id: agentId } = user;
  const isAgentAddedAsChatting = isChattingAgent(user);
  const isAgentAddedAsSupervisor = isSupervisingAgent(user);
  const state = store.getState();
  const currentAgentId = getLoggedInAgentLogin(state);
  const isCurrentAgentAddedToThread = currentAgentId === agentId;
  const isChatSupervisedByCurrentAgent = getIsChatSupervised(state, threadId);
  const isQueuedChat = getIsQueueThread(state, threadId);
  const isUnassignedChat = getIsUnassignedThread(state, threadId);
  const isFollowing = chatFollowManager.isChatFollowed(chatId);
  const isOtherAgentAddedToChat = user.type === UserType.Agent && !isCurrentAgentAddedToThread;
  const isQueueMessagingEnabled = getIsQueueMessagingEnabled(state);

  /**
   * 1. Current agent has started supervising.
   */
  if (isCurrentAgentAddedToThread && isAgentAddedAsSupervisor) {
    store.dispatch(
      ChatsEntitiesActions.startSupervising({
        chatId,
        currentAgentId,
      }),
    );

    const isMessagingInQueueEnabled = getIsQueueMessagingEnabled(state);
    const threadType = getThreadType(state, threadId);
    const numOfCustomerEvents = getNumberOfCustomerMessagesInThread(state, threadId);
    const section = getSectionName(state);

    trackEvent(
      ChatOperatorEvent.SuperviseStarted,
      section === Section.EngageTraffic ? EventPlace.Traffic : EventPlace.Chats,
      {
        ...(section !== Section.EngageTraffic && {
          numOfCustomerEvents,
          isMessagingInQueueEnabled,
          chatType: threadType,
        }),
      },
    );

    return;
  }

  /**
   * 2. Current agent is now chatting due to transfer or auto assignment, etc.
   * Mark chat as new as it was transferred or was in queue
   */
  if (isCurrentAgentAddedToThread) {
    /**
     * Probably temporary hotfix for problems with picking chat from queue, reported on Slack.
     * Chat state wasn't updated and users couldn't start chatting (only whole page refresh helped).
     * This dispatch fetches current chat state details.
     * @see {@link https://text.slack.com/archives/CCRBKU2AD/p1727787762696879}
     */
    store.dispatch(
      ChatsEntitiesActions.fetchChatThreadDetails({
        chatId,
        threadIds: [threadId],
      }),
    );
    store.dispatch(ChatsViewActions.markChatAsNew({ threadId }));

    return;
  }

  /**
   * 3. Queued or unnasigned chat was assigned to other agent as chatting.
   */
  if ((isQueuedChat || isUnassignedChat) && isAgentAddedAsChatting) {
    const wasSupervised = getWasThreadSupervised(state, threadId);

    // Bring back supervising
    if (wasSupervised) {
      store.dispatch(
        ChatsEntitiesActions.startSupervising({
          chatId,
          currentAgentId,
        }),
      );

      return;
    }

    if (isQueueMessagingEnabled && isQueuedChat && isFollowing) {
      store.dispatch(
        ChatsEntitiesActions.updateChatThread({
          threadId,
          thread: { visualStatus: ChatThreadVisualStatus.QueuedChatVisuallyClosed },
        }),
      );

      store.dispatch(
        ChatsEntitiesActions.addChatUsers({
          chatId,
          chatUsers: [user],
        }),
      );
      chatFollowManager.unfollowChat(chatId);
    } else {
      store.dispatch(
        ChatsEntitiesActions.updateChatThread({
          threadId,
          thread: {
            type: ChatType.Other,
          },
        }),
      );
    }

    if (isUnassignedChat) {
      store.dispatch(
        ChatsEntitiesActions.updateUnassignedChatsCount({
          threadId,
          type: 'decrease',
        }),
      );
    }

    return;
  }

  /**
   * 4. Chat that is supervised by current agent has changed chatting agent to different one.
   */
  if (isChatSupervisedByCurrentAgent && isAgentAddedAsChatting && isOtherAgentAddedToChat) {
    store.dispatch(
      ChatsEntitiesActions.updateChatThread({
        threadId,
        thread: {
          currentAgentId: agentId,
        },
      }),
    );

    return;
  }

  /**
   *  5. Supervising was started by other agent.
   *  Update the list of supervisors.
   */
  if (isOtherAgentAddedToChat && isAgentAddedAsSupervisor) {
    const thread = getThread(state, threadId);

    if (thread) {
      const supervisors = (thread.supervisorsIds || []).concat(agentId);

      store.dispatch(
        ChatsEntitiesActions.updateChatSupervisors({
          threadId,
          supervisors,
        }),
      );
    }

    return;
  }
}
