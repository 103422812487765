// @ts-strict-ignore
import { type PickRequired } from 'helpers/interface';
import { isDefined } from 'helpers/is-defined';
import { AgentSerializer } from 'services/api/agent/v3/agent-serializer';
import type { IAppState } from 'services/app-state-provider';
import { type Agent } from 'services/connectivity/configuration-api/agents/types';
import { AgentActions } from 'store/entities/agents/actions';
import { GroupActions } from 'store/entities/groups/actions';
import { isMe } from 'store/features/session/selectors';

export function handleAgentUpdated(payload: PickRequired<Agent, 'id'>, store: IAppState): void {
  const deserializedPayload = AgentSerializer.partialDeserialize(payload);
  store.dispatch(AgentActions.agentUpdate(deserializedPayload));
  const isItMe = isMe(store.getState(), payload.id);

  if (payload.groups) {
    store.dispatch(GroupActions.groupsFetch());
  }

  if (isDefined(payload.notifications) && isItMe) {
    store.dispatch(AgentActions.agentUpdate({ notifications: deserializedPayload.notifications }));
  }
}
