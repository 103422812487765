import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { QueryKey } from 'constants/query-key';
import type { IAppState } from 'services/app-state-provider';
import { getQueryClient } from 'services/query-client/client';
import { AgentActions } from 'store/entities/agents/actions';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { AgentBotActions } from 'store/entities/bots/actions';
import { getIsBotFromLogin } from 'store/entities/bots/selectors';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

import { mapRoutingStatusToLoginStatus } from '../helpers';
import { type IRoutingStatusSetPushEvent } from '../interfaces';

export function handleRoutingStatusSet(payload: IRoutingStatusSetPushEvent, store: IAppState): void {
  const { agent_id: login, status: apiStatus } = payload;
  const currentUserLogin = getLoggedInAgentLogin(store.getState());
  const status = mapRoutingStatusToLoginStatus(apiStatus);
  const isBot = getIsBotFromLogin(store.getState(), login);
  const actionPayload = {
    login,
    status,
    ...(status === LoginStatus.Offline && {
      ips: [],
      sessionTypes: [],
    }),
  };

  if (isBot) {
    store.dispatch(AgentBotActions.botUpdate(actionPayload));
  } else {
    store.dispatch(AgentActions.agentUpdate(actionPayload));
  }

  if (currentUserLogin !== login) {
    return;
  }

  const queryClient = getQueryClient();
  queryClient.setQueryData([QueryKey.MyInactivity], () => ({}));

  // Wait until webhook is processed before invalidate query
  setTimeout(() => {
    void queryClient.invalidateQueries([QueryKey.MyInactivity]);
  }, 3000);

  if (status === LoginStatus.Away) {
    store.dispatch(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.StatusAway }));
  } else {
    store.dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.StatusAway));
  }
}
