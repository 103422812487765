import { ReportType } from 'constants/reports/report-type';

export const REPORTS_MENU_TEST_ID = 'reports-menu';
export const REPORTS_GRID_CONTENT_TEST_ID = 'reports-grid-content';
export const REPORTS_HEADER_TEST_ID = 'reports-header';

export const GROUP_STATUS_MONITOR_START_DATE = '2021-07-01';

export const REPORTS_HEATMAP_RETRY_NUMBER = 1;

export const REPORT_CACHE_TIME = 1000 * 60 * 3;

export const REPORTS_BETA_TOOLTIP_CONTENT: Partial<{ [key in ReportType]: { title: string; content: string } }> = {
  [ReportType.ChatTopics]: {
    title: 'Introducing Beta AI features',
    content:
      'Check what your customers talk about and how they feel about those topics. Create canned responses and update your FAQ page to cover the most frequent topics.',
  },
};
