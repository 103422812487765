// @ts-strict-ignore
import { isAfter } from 'date-fns';
import { createSelector } from 'reselect';

import { STARTER_ONE_SEAT_LIMIT_DATE } from 'constants/limits';
import { type IPlanPrices, PlanType } from 'constants/plan-type';
import { PaymentOrigin } from 'constants/subscription/payment-origins';
import { type BillingCycleType, type ISubscription, SubscriptionChangedType } from 'interfaces/subscription';
import { type IWithSessionState } from 'store/features/session/selectors';
import { createRequestFetchingSelector, type IWithRequestsState } from 'store/requests/selectors';

import { getActiveAgentsCount } from '../agents/selectors';

import { SubscriptionRequestFetchingSelectorsNames } from './actions';
import { type ISubscriptionState } from './interfaces';

export interface IWithSubscriptionState {
  entities: {
    subscription: ISubscriptionState;
  };
}

/* plan prices */

export function getPlanPrices(state: IWithSubscriptionState): IPlanPrices {
  return state.entities.subscription.planPrices;
}

export function getEstimatedPlanPrice(state: IWithSubscriptionState, seats: number, planCode: string): number {
  const { planPrices } = state.entities.subscription;

  return planPrices[planCode] * seats;
}

/* subscription */

export function getSubscription(state: IWithSubscriptionState): ISubscription {
  return state.entities.subscription.subscription;
}

export function getSubscriptionPlanName(state: IWithSubscriptionState): string {
  return state.entities.subscription.subscription?.planName;
}

export function getIsSubscriptionInTrial(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscription?.inTrial;
}

export function getIsSubscriptionCanceled(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscription?.isCanceled;
}

export function getIsSubscriptionSubscribed(state: IWithSubscriptionState): boolean {
  return (
    !!state.entities.subscription.subscription?.currentPeriodStartedAt &&
    !state.entities.subscription.subscription?.isExpired
  );
}

export function getSubscriptionSeats(state: IWithSubscriptionState): number {
  return state.entities.subscription.subscription?.seats;
}

export function getSubscriptionIsPastDue(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscription?.isPastDue;
}

export function getSubscriptionEmail(state: IWithSubscriptionState): string {
  return state.entities.subscription.subscription?.email;
}

/**
 * Returns the value of the current recurly plan.
 * The recurly plan can be a temporary overrided, so do NOT use this selector outside of the subscription.
 * @param state
 * @returns PlanType
 */
export function getSubscriptionPlan(state: IWithSubscriptionState): PlanType {
  return state.entities.subscription.subscription?.plan;
}

export function getIsCardAdded(state: IWithSubscriptionState): boolean {
  return !!state.entities.subscription.subscription?.card;
}

export function getIsCustomPlan(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscription?.isCustomDeal;
}

export function getHasManualPayments(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscription?.hasManualPayments;
}

export function getWasSubscriptionCreated(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscription?.subscriber;
}

export function getIsSubscriptionInTrialAndNotSubscribed(state: IWithSubscriptionState): boolean {
  return getIsSubscriptionInTrial(state) && !getIsSubscriptionSubscribed(state);
}

function getIsSubscriptionExpired(state: IWithSessionState): boolean {
  const expirationTimestamp = state.features.session.license?.expiresAt ?? null;

  return expirationTimestamp && isAfter(new Date(), new Date(expirationTimestamp * 1000));
}

function getIsAfterStarterLimit(state: IWithSessionState): boolean {
  const licenseCreateDate = state.features.session.license?.createdAt * 1000;

  return isAfter(new Date(licenseCreateDate), STARTER_ONE_SEAT_LIMIT_DATE);
}

/* subscription change */
export const getSubscriptionChange = createSelector(
  [
    getIsSubscriptionExpired,
    getIsAfterStarterLimit,
    (state: IWithSubscriptionState) => state.entities.subscription.subscriptionChange,
  ],
  (isSubscriptionExpired, isAfterStarterLimit, newSubscription) => {
    const isNewSubscriptionStarterEnforcingOneSeat =
      (isSubscriptionExpired || isAfterStarterLimit) && newSubscription.plan === PlanType.Starter;

    return isNewSubscriptionStarterEnforcingOneSeat
      ? {
          ...newSubscription,
          seats: 1,
        }
      : newSubscription;
  },
);

export function getSubscriptionChangeSeats(state: IWithSubscriptionState & IWithSessionState): number {
  return getSubscriptionChange(state).seats;
}

export function getSubscriptionChangeCouponName(state: IWithSubscriptionState): string {
  return state.entities.subscription.subscriptionChange.couponCode;
}

export function getSubscriptionChangeBillingCycle(state: IWithSubscriptionState): BillingCycleType {
  return state.entities.subscription.subscriptionChange.billingCycle;
}

export function getSubscriptionChangePlan(state: IWithSubscriptionState): PlanType {
  return state.entities.subscription.subscriptionChange.plan;
}

export function getSubscriptionChangePlanCode(state: IWithSubscriptionState): string {
  return state.entities.subscription.subscriptionChange.planCode;
}

export function getIsSubscriptionUnchanged(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscriptionChange.changeType === SubscriptionChangedType.Unchanged;
}

export function getIsSubscriptionDowngraded(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscriptionChange.changeType === SubscriptionChangedType.Downgrade;
}

export function getIsSubscriptionChangeToEnterprisePlan(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscriptionChange.plan === PlanType.EnterprisePlus;
}

export function getIsAutomaticUpsellingEnabled(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscriptionChange.automaticUpsellingEnabled;
}

export function getIsSubscriptionProcessing(state: IWithRequestsState): boolean {
  return (
    createRequestFetchingSelector([SubscriptionRequestFetchingSelectorsNames.CREATE_SUBSCRIPTION_REQUEST])(state) ||
    createRequestFetchingSelector([SubscriptionRequestFetchingSelectorsNames.UPDATE_SUBSCRIPTION_REQUEST])(state) ||
    createRequestFetchingSelector([SubscriptionRequestFetchingSelectorsNames.UPGRADE_SUBSCRIPTION_REQUEST])(state)
  );
}

function getIsSubscriptionManagedByShopify(state: IWithSubscriptionState): boolean {
  return state.entities.subscription.subscription?.origin === PaymentOrigin.Shopify;
}

export const getIsManagedByShopifyAndReachedMaxAgents = (additionalAgentsCount: number) =>
  createSelector(
    [getSubscriptionChangeSeats, getActiveAgentsCount, getIsSubscriptionManagedByShopify],
    (subscriptionSeats, activeAgentsCount, isManagedByShopify) =>
      isManagedByShopify && (activeAgentsCount >= subscriptionSeats || additionalAgentsCount > 0),
  );
