export const enum SettingsNavigationItemId {
  Channels = 'channels',
  Overview = 'Overview',
  Code = 'code',
  ChatPage = 'chat-page',
  FacebookMessenger = 'facebook-messenger',
  FacebookMessengerLC3 = 'facebook-messenger-lc3',
  Email = 'email',
  AppleBusinessChat = 'apple-messages-for-business',
  ChatWindow = 'chat-window',
  Theme = 'theme',
  Language = 'language',
  Availability = 'availability',
  ChatBoosters = 'chat-boosters',
  WelcomeScreen = 'welcome-screen',
  Forms = 'forms',
  PreChatForm = 'pre-chat-form',
  AskforEmail = 'ask-for-email',
  PostChatForm = 'post-chat-form',
  TicketForm = 'ticket-form',
  Engagement = 'engagement',
  TargetedMessages = 'targeted-messages',
  EyeCatcher = 'eye-catcher',
  ChatButtons = 'chat-buttons',
  Goals = 'goals',
  SalesTracker = 'sales-tracker',
  AgentTools = 'agent-tools',
  CannedResponses = 'canned-responses',
  Tags = 'tags',
  ChatSettings = 'chat-settings',
  ChatAssignment = 'chats-assignment',
  ArchivesForwarding = 'archives-forwarding',
  FileSharing = 'file-sharing',
  InactivityTimeouts = 'inactivity-timeouts',
  TicketSettings = 'ticket-settings',
  AutomatedEmails = 'automated-emails',
  Apps = 'apps',
  Integrations = 'integrations',
  Marketplace = 'marketplace',
  QualityBadge = 'quality-badge',
  Security = 'security',
  TrustedDomains = 'trusted-domains',
  BannedCustomers = 'banned-customers',
  AccessRestriction = 'access-restriction',
  CreditCardsMasking = 'credit-cards-masking',
  LoginSettings = 'login-settings',
  AutoAccessRules = 'auto-access-rules',
  ResponsesList = 'responses-list',
  SuggestedResponses = 'suggested-responses',
  HelpDesk = 'helpdesk',
}
