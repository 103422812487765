import { TourStepType, ITourStep } from '../interfaces';

import { ButtonStep, CustomSegmentsStep, ManageSegmentsStep } from './tour-steps';

export enum CustomSegmentsTourSteps {
  Button = 'Button',
  CustomSegments = 'CustomSegments',
  ManageSegments = 'ManageSegments',
}

export const customSegmentsSteps: ITourStep[] = [
  {
    id: CustomSegmentsTourSteps.Button,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <ButtonStep />,
    },
  },
  {
    id: CustomSegmentsTourSteps.CustomSegments,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <CustomSegmentsStep />,
    },
  },
  {
    id: CustomSegmentsTourSteps.ManageSegments,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <ManageSegmentsStep />,
      placement: 'left-start',
    },
  },
];
