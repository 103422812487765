import { SoundNotification } from 'constants/sound-notifications';

export const INCOMING_SOUNDS_BUFFER_TIMEOUT = 500;

/**
 * If there will be more than one sound to be played at given time we need to pick the most important (1 is the most important).
 */
export const SoundPriority = {
  [SoundNotification.Message]: 1,
  [SoundNotification.IncomingChat]: 2,
  [SoundNotification.QueuedVisitor]: 3,
  [SoundNotification.ReturningVisitor]: 4,
  [SoundNotification.NewVisitor]: 5,
  [SoundNotification.NewTicket]: 6,
};
