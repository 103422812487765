import { type IApplication } from 'store/entities/applications/interfaces';

import { BaseApi, PassVersionMode } from '../base-api';

import {
  type IFetchApplicationDataRequestResult,
  type IUninstallApplicationBody,
  ApplicationProduct,
  type IApplicationLoadedBody,
} from './interfaces';

export class IntegrationsApi extends BaseApi {
  protected readonly passVersionMode = PassVersionMode.None;
  protected readonly prefix = 'applications';

  fetch = () => this.get<IApplication[]>('installed', { product: ApplicationProduct.LiveChat });
  fetchPrivateApps = () => this.get('', { product: ApplicationProduct.LiveChat });
  fetchInReviewApps = () => this.get('reviewed', { product: ApplicationProduct.LiveChat });
  fetchAppData = (applicationId: string) =>
    this.get<IFetchApplicationDataRequestResult>(`${applicationId}/published`, {
      product: ApplicationProduct.LiveChat,
    });

  install = (applicationId: string) => this.put(`${applicationId}/install`);
  uninstall = (applicationId: string, payload?: IUninstallApplicationBody) =>
    this.delete(`${applicationId}/install`, payload);

  trackAppLoaded = (applicationId: string, payload: IApplicationLoadedBody) =>
    this.post(`${applicationId}/loaded`, payload);
}
