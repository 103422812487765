export enum RecurlyFieldType {
  CreditCard = 'card',
  CreditCardNumber = 'number',
  CVV = 'cvv',
  Month = 'month',
  Year = 'year',
  ExpirationDate = 'expiration_date',
  FirstName = 'first_name',
  LastName = 'last_name',
  PostalCode = 'postal_code',
  Company = 'company',
  VatNumber = 'vat_number',
  Address = 'address1',
  City = 'city',
  State = 'state',
  Country = 'country',
}

export enum RecurlyErrorType {
  Recurly = 'recurly',
  Validation = 'validation',
  ApiError = 'api-error',
  InvalidParameter = 'invalid-parameter',
}

export enum CreditCardType {
  Visa = 'visa',
  Mastercard = 'master',
  Discover = 'discover',
  AmericanExpress = 'american_express',
}

export interface IRecurlyError {
  code: RecurlyErrorType;
  fields: RecurlyFieldType[];
  message: string;
  name?: string;
}

export interface IMappedRecurlyError {
  [RecurlyErrorType.Recurly]?: string | [];
  [RecurlyFieldType.CreditCard]?: string;
  [RecurlyFieldType.CreditCardNumber]?: string;
  [RecurlyFieldType.CVV]?: string;
  [RecurlyFieldType.Month]?: string;
  [RecurlyFieldType.Year]?: string;
  [RecurlyFieldType.ExpirationDate]?: string;
  [RecurlyFieldType.FirstName]?: string;
  [RecurlyFieldType.LastName]?: string;
  [RecurlyFieldType.PostalCode]?: string;
  [RecurlyFieldType.Company]?: string;
  [RecurlyFieldType.VatNumber]?: string;
  [RecurlyFieldType.Address]?: string;
  [RecurlyFieldType.City]?: string;
  [RecurlyFieldType.State]?: string;
  [RecurlyFieldType.Country]?: string;
}
