// @ts-strict-ignore
import { Group } from '@livechat/design-system-icons';

import { FilterOperator } from 'constants/filters/filter';
import { toKeyMap } from 'helpers/array';
import type { GroupBase } from 'interfaces/groups';

import { getComparisonLabelValues } from '../helpers';
import { type FilterCompareLabelValues, type FilterConfig, type FilterLabelValue } from '../interfaces';

const getGroupLabelValues = (filterValues: string[] | null, groups: GroupBase[]): FilterLabelValue[] => {
  if (!filterValues) {
    return null;
  }

  const groupsHash = toKeyMap(groups, 'id');

  return filterValues.reduce((acc: FilterLabelValue[], groupId) => {
    if (groupsHash[groupId]) {
      acc.push({ key: groupsHash[groupId].id.toString(), label: groupsHash[groupId].name });
    } else {
      acc.push({
        key: groupId,
        label: `Group ${groupId} (removed)`,
      });
    }

    return acc;
  }, []);
};

const getGroupCompareLabelValues = (
  filterValues: string[] | null,
  groups: GroupBase[],
  filterCompareValues: string[] | null,
): FilterCompareLabelValues | null => {
  if (!filterValues || !filterCompareValues) {
    return null;
  }

  const { labels, compareLabels } = getComparisonLabelValues(filterValues, filterCompareValues, groups);

  return {
    operator: FilterOperator.Or,
    firstValues: labels,
    secondValues: compareLabels,
  };
};

export const groupFilterConfigMultiple: FilterConfig = {
  title: 'Group',
  icon: Group,
  mobileViewTitle: 'Select groups',
  multiple: true,
  getFilterLabelValues: getGroupLabelValues,
  getFilterCompareLabelValues: getGroupCompareLabelValues,
};
