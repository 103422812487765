import { useRef, useState, type FC } from 'react';

import { cx } from '@emotion/css';
import { FiletypeHtml, OpenInNew } from '@livechat/design-system-icons';
import { Icon, Tooltip } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { openInNewCard } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import { type URLSource } from 'store/entities/copilot/interfaces';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import * as styles from './styles';

type Props = URLSource;

export const UrlSourceItem: FC<Props> = ({ name, url }) => {
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const [isHovered, setIsHovered] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleSourceClick = (): void => {
    openInNewCard(url);
    trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, { action: 'source clicked' });
  };

  return (
    <li
      onClick={handleSourceClick}
      className={styles.urlLinkContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tooltip
        isVisible={isHovered}
        placement="top-start"
        kind="invert"
        className={styles.sourceMaxWidth}
        hoverOnDelay={300}
        referenceElement={tooltipRef?.current ?? undefined}
        triggerRenderer={() => (
          <div className={cx(styles.urlSourceName, { [styles.sourceMaxWidth]: isExpanded })}>
            <Icon source={FiletypeHtml} />
            <div ref={tooltipRef} className={styles.sourceItem}>
              {name}
            </div>
            {isHovered && <Icon source={OpenInNew} className={styles.openInNewIcon} />}
          </div>
        )}
      >
        <p className={styles.tooltipSourceLink}>{url}</p>
      </Tooltip>
    </li>
  );
};
