import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { type GroupDTO } from 'services/api/group/interfaces';
import { deserializeGroups } from 'services/api/group/serializer';
import { groupsClient } from 'services/connectivity/configuration-api/groups/client';
import { GroupActionNames, GroupActions } from 'store/entities/groups/actions';

function* fetchGroups(): SagaIterator {
  const { result } = yield call(() => groupsClient.list({ fields: ['routing_status', 'agent_priorities'] }));

  if (result) {
    // Temporary cast as groups response does not contain properties
    yield put(GroupActions.groupsReset(deserializeGroups(result as GroupDTO[])));
  }
}

export function* groupsSagas(): SagaIterator {
  yield takeEvery(GroupActionNames.GROUPS_FETCH, fetchGroups);
}
