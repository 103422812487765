import { type SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { type IOrganizationDTO } from 'services/api/accounts/deserializer';
import { type ListAuthorizationsResponse } from 'services/api/accounts/interfaces';
import { ApiManager } from 'services/api/api-manager';

import { AccountsActionNames, AccountsActions } from './actions';
import { getOrganizationId } from './selectors';

function* fetchOrganizationInfo(): SagaIterator {
  const organizationId: string = yield select(getOrganizationId);

  const { result }: RequestResult<IOrganizationDTO> = yield call(
    ApiManager.accountsApi.getOrganizationInfo,
    organizationId,
  );

  if (result) {
    yield put(AccountsActions.setInvitationMode(result.invitation_mode));
  }
}

function* fetchAuthorizations(): SagaIterator {
  const { result, error }: RequestResult<ListAuthorizationsResponse> = yield call(
    ApiManager.accountsApi.getAppsAuthorizations,
  );

  if (error) {
    throw error;
  }

  if (result) {
    const clientIds = result.map((auth) => auth.client_id);
    yield put(AccountsActions.setAuthorizationsClientIds(clientIds));
  }
}

export function* accountsSagas(): SagaIterator {
  yield takeLatest(AccountsActionNames.SET_CURRENT_ORGANIZATION_ID, fetchOrganizationInfo);
  yield takeEvery(['APP_READY'], fetchAuthorizations);
}
