export enum LanguagePhrase {
  AgentsNotAvailable = 'Agents_not_available',
  AgentsNotAvailableContinuous = 'Agents_not_available_continuous',
  AskForEmailQuestion = 'Ask_for_email_question',
  AskForEmailConfirmation = 'Ask_for_email_confirmation',
  ButtonAddFeedback = 'Button_add_feedback',
  BackToChat = 'Back_to_chat',
  Client = 'Client',
  CloseChat = 'Close_chat',
  EmailPromptConfirmation = 'Email_prompt_confirmation',
  EmailPromptFirstTime = 'Email_prompt_first_time',
  EmbeddedChatNow = 'Embedded_chat_now',
  EmbeddedChatWith = 'Embedded_chat_with',
  EmbeddedGreetingAccept = 'embedded_greeting_accept',
  EmbeddedInvitationAccept = 'Embedded_invitation_accept',
  EmbeddedLeaveMessage = 'Embedded_leave_message',
  EmbeddedMinimizeWindow = 'Embedded_minimize_window',
  EmbeddedTextareaPlaceholder = 'Embedded_textarea_placeholder',
  FormsChoose = 'forms_choose',
  FormsChooseDepartment = 'forms_choose_department',
  FormsEmail = 'forms_email',
  FormsEmailConfirmation = 'forms_email_confirmation',
  FormsFirstAnswer = 'forms_first_answer',
  FormsMessage = 'forms_message',
  FormsName = 'forms_name',
  FormsPrivacyPolicyAgree = 'Forms_privacy_policy_agree',
  FormsPrivacyPolicyConsent = 'Forms_privacy_policy_consent',
  FormsQuestion = 'forms_question',
  FormsSecondAnswer = 'forms_second_answer',
  FormsSubject = 'forms_subject',
  FormsThankYou = 'forms_thank_you',
  FormsWelcome = 'forms_welcome',
  LeaveChatConfirm = 'Leave_chat_confirm',
  MessageRead = 'Message_read',
  NewGreetingDefaultMessage = 'New_greeting_default_message',
  GreetingDefaultMessage = 'greeting_default_message',
  NewMessage = 'New_message',
  Mute = 'Mute',
  OfflineMessageSent = 'Offline_message_sent',
  PostchatLeaveComment = 'Postchat_leave_comment',
  PostchatRateChat = 'Postchat_rate_chat',
  PrechatSubmitLabel = 'Prechat_submit_label',
  RateMeAddComment = 'Rate_me_add_comment',
  RateMeBadTooltip = 'Rate_me_bad_tooltip',
  RateMeConfirmationBad = 'Rate_me_confirmation_bad',
  RateMeGoodTooltip = 'Rate_me_good_tooltip',
  Send = 'Send',
  SendTranscript = 'Send_transcript',
  SendTranscriptToEmail = 'Send_transcript_to_email',
  ShowLess = 'Show_less',
  ShowMore = 'Show_more',
  Submit = 'Submit',
  SurveyFillInRequiredFields = 'Survey_fill_in_required_fields',
  SkillStatusOffline = 'Skill_status_offline',
  ThemeOptions = 'Theme_options',
  UserInQueue = 'User_in_queue',
  WelcomeTitle = 'Welcome_title',
  WelcomeToChat = 'Welcome_to_chat',
  WriteAMessage = 'Write_a_message',
  Sounds = 'Sounds',
  SendFile = 'Send_file',
  AttachScreenshot = 'Attach_screenshot',
  Back = 'Back',
  GoBackToHomescreen = 'Go_back_to_homescreen',
  RateGoodAndOpenModal = 'Rate_good_and_open_modal',
  RateBadAndOpenModal = 'Rate_bad_and_open_modal',
  RateAgent = 'Rate_agent',
  Tickets = 'Tickets',
  Home = 'Home',
  Chat = 'Chat',
  Help = 'Help',
  Next = 'Next',
  Previous = 'Previous',
  OfflineFormSubmitLabel = 'Offline_form_submit_label',
}
