import { type KeyMap } from 'helpers/interface';
import { createHasFetchedSelector, createRequestFetchingSelector } from 'store/requests/selectors';

import { AutoAccessRulesActionNames, AutoAccessRulesRequestActions } from './actions';
import { type IAutoAccessRule, type IWithAutoAccessRulesState } from './interfaces';

export const getHasFetchedAccessRules = createHasFetchedSelector([AutoAccessRulesRequestActions.FETCH_COLLECTION]);
export const getIsFetchingAccessRules = createRequestFetchingSelector([AutoAccessRulesActionNames.FETCH_COLLECTION]);

export const getAllAutoAccessRules = (state: IWithAutoAccessRulesState): KeyMap<IAutoAccessRule> =>
  state.entities.autoAccessRules.autoAccessRules;

export const getSingleAutoAccessRule = (state: IWithAutoAccessRulesState, id: string): IAutoAccessRule =>
  state.entities.autoAccessRules.autoAccessRules[id];
