import isEmpty from 'lodash.isempty';

import { ActiveExperiment } from 'constants/active-experiment';
import { type KeyMap } from 'helpers/interface';
import type { Experiment, WithExperimentsState } from 'interfaces/experiment';
import { createHasFetchedSelector, createRequestFetchingSelector } from 'store/requests/selectors';

import { ExperimentsActionNames } from './actions';

export function getExperimentsGroups(state: WithExperimentsState): KeyMap<number> {
  const { experiments } = state.entities;

  return Object.keys(experiments).reduce((acc: KeyMap<number>, item: string): KeyMap<number> => {
    acc[item] = experiments[item].variation;

    return acc;
  }, {});
}

export function getExperiment(state: WithExperimentsState, experimentName: ActiveExperiment): Experiment {
  return state.entities.experiments?.[experimentName];
}

export function selectIsDisableNativeTicketsExperiment(state: WithExperimentsState): boolean {
  const experiment = getExperiment(state, ActiveExperiment.DisableNativeTickets);

  return !isEmpty(experiment);
}

export function selectIsNewOnboardingChecklistEnabled(state: WithExperimentsState): boolean {
  return isGroupA(state, ActiveExperiment.NewOnboardingChecklist);
}

function isGroupA(state: WithExperimentsState, experimentName: ActiveExperiment): boolean {
  const experiment = getExperiment(state, experimentName);

  return experiment ? experiment.variation === 1 : false;
}

export function isGroupB(state: WithExperimentsState, experimentName: ActiveExperiment): boolean {
  const experiment = getExperiment(state, experimentName);

  return experiment ? experiment.variation === 2 : false;
}

export const hasFetchedExperiments = createHasFetchedSelector([ExperimentsActionNames.FETCH_EXPERIMENTS_SUCCESS]);

export const getIsFetchingExperiments = createRequestFetchingSelector([
  ExperimentsActionNames.FETCH_EXPERIMENTS_REQUEST,
]);
