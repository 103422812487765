// @ts-strict-ignore
import { format } from 'date-fns';
import property from 'lodash.property';
import { all, call, put, select } from 'redux-saga/effects';

import { DateFormat } from 'constants/date';
import { type ReportType } from 'constants/reports/report-type';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { type KeyMap } from 'helpers/interface';
import { isReportTypeIsDisabled } from 'helpers/is-report-type-is-disabled';
import { parseLegacySerializedFilters } from 'helpers/reports';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import type { IBenchmarkDataPayload, ISerializedFilters } from 'interfaces/reports';
import type {
  ReportResponse,
  ReportResponseData,
} from 'interfaces/reports/api-v3';
import { ApiManager } from 'services/api/api-manager';
import { serializeFilters } from 'services/api/report/filters';
import { serializeJSONFilters } from 'services/api/report/v3/filters';
import { getCompanyDetails } from 'store/entities/company-details/selectors';
import {
  type IFetchReportOptions,
  type IFetchReportPayload,
  type IReportRequest,
  type ISetsResolver,
  RequestVersion,
} from 'store/entities/reports/interfaces';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { ReportsViewActions } from 'store/views/reports/actions';

import { ReportActions } from '../actions';

function* prepareBenchmarkResponse(payload: IFetchReportPayload): any {
  const { filters, distribution, shouldBenchmarkFetch } = payload;
  if (shouldBenchmarkFetch) {
    const companyDetails = yield select(getCompanyDetails);
    const benchmarkPayload: IBenchmarkDataPayload = {
      dateFrom: format(filters.dateRange.from, DateFormat.ISO8601Date),
      dateTo: format(filters.dateRange.to, DateFormat.ISO8601Date),
      groupBy: distribution,
      country: companyDetails && companyDetails.country,
      industry: companyDetails && companyDetails.industry,
      companySize: companyDetails && companyDetails.size,
    };

    const benchmarkResponse = yield call(ApiManager.benchmarkApi.fetchBenchmark, benchmarkPayload);
    if (benchmarkResponse.error) {
      yield put(ReportsViewActions.setReportBenchmarkEnabled(false));
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.DEFAULT_ERROR),
          autoHideDelayTime: ToastAutoHideDelay.Long,
          kind: ToastVariant.Error,
        })
      );
    }

    return benchmarkResponse;
  }

  return null;
}
export function getCombinations(elements: any[]): any[] {
  const results = [[]];
  elements.forEach((value) => {
    const copy = [...results];
    copy.forEach((prefix) => {
      results.push(prefix.concat(value));
    });
  });

  return results.filter(({ length }) => length);
}

function defaultSetsResolver(
  reportType: ReportType,
  requests: IReportRequest[],
  { filters, distribution, filtersOperators }: IFetchReportPayload,
  extendedFilters: ISerializedFilters
): ISetsResolver {
  const serializedFilters = {
    ...serializeFilters(filters, distribution),
    ...extendedFilters,
  };

  const isComparisonDisabled = isReportTypeIsDisabled(reportType);
  const comparisons = !isComparisonDisabled
    ? Object.keys(filters).filter((filter) => property('compare')(filters[filter]))
    : [];

  const combinations = getCombinations(comparisons).reduce(
    (acc, comparison) => {
      acc.push({
        ...serializeFilters(filters, distribution, comparison),
        ...extendedFilters,
      });

      return acc;
    },
    [serializedFilters]
  );

  const combinationsKeys = combinations.map(JSON.stringify);

  const filtersSets = combinations.filter(
    (combination, index) => combinationsKeys.indexOf(JSON.stringify(combination)) === index
  );

  const dataSets = requests.reduce((acc, request) => {
    if (request.disableComparison || isComparisonDisabled) {
      let requestFilters = {
        ...filtersSets[0],
        ...(request.extendedFilters || {}),
        operators: filtersOperators,
      };

      if (request.version === RequestVersion.V3) {
        requestFilters = serializeJSONFilters(requestFilters);
      }

      if (!request.version || request.version === RequestVersion.V2) {
        requestFilters = parseLegacySerializedFilters(requestFilters);
      }

      return acc.concat([
        {
          filters: requestFilters,
          filterSetId: 0,
          request,
          data: null,
        },
      ]);
    }

    return acc.concat(
      ...filtersSets.map((filtersSet, index) => {
        let requestFilters = {
          ...filtersSet,
          ...(request.extendedFilters || {}),
          operators: filtersOperators,
        };
        if (request.version === RequestVersion.V3) {
          requestFilters = serializeJSONFilters(requestFilters);
        }
        if (!request.version || request.version === RequestVersion.V2) {
          requestFilters = parseLegacySerializedFilters(requestFilters);
        }

        return {
          filters: requestFilters,
          filterSetId: index,
          request,
          data: null,
        };
      })
    );
  }, []);

  return {
    dataSets,
    filtersSets,
  };
}

export const defaultResponseResolver = <T = KeyMap, U = KeyMap>(
  responses: RequestResult<KeyMap | ReportResponse<KeyMap>>[],
  requests: IReportRequest[],
  filterSetIndex: number,
  filtersSets: ISerializedFilters[]
): ReportResponseData<T, U> => {
  return requests.reduce((acc, request, requestId) => {
    if (responses[requestId * filtersSets.length + filterSetIndex]) {
      acc[request.name] = responses[requestId * filtersSets.length + filterSetIndex].result;
    }

    return acc;
  }, {});
};

export function* makeFetchingSaga(
  reportType: ReportType,
  action: IActionWithPayload<string, IFetchReportPayload>,
  requests: IReportRequest[] = [],
  customOptions: IFetchReportOptions = {}
): any {
  const { payload } = action;

  const options: IFetchReportOptions = {
    extendedFilters: {},
    setsResolver: defaultSetsResolver,
    responseResolver: defaultResponseResolver,
    apiFiltersSerializer: (filters) => filters,
    ...customOptions,
  };

  const { extendedFilters, setsResolver, responseResolver, apiFiltersSerializer } = options;
  const sets = yield setsResolver(reportType, requests, payload, extendedFilters);
  let { dataSets } = sets;
  const { filtersSets } = sets;
  const benchmarkResponse = yield prepareBenchmarkResponse(payload);

  const responses = yield all(dataSets.map((set) => call(set.request.interface, apiFiltersSerializer(set.filters))));

  if (responses.every((response) => response.result)) {
    dataSets = filtersSets.map((filtersSet, index) => ({
      filters: filtersSet,
      data: responseResolver(responses, requests, index, filtersSets),
    }));

    yield put(
      ReportActions.fetchSuccess({
        data: dataSets,
        benchmarkData: benchmarkResponse && benchmarkResponse.result,
        source: payload.source,
        reportType,
      })
    );
  } else {
    const errorMessage = responses
      .map((request) => request.error && request.error.message)
      .filter(Boolean)
      .join(', ');

    payload.retry = payload.retry || 0;
    yield put(ReportActions.fetchFailure({ error: errorMessage, reportType, action }));
  }
}
