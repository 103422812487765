// @ts-strict-ignore
import { isAfter } from 'date-fns';
import uniq from 'lodash.uniq';
import { createSelector } from 'reselect';

import { ChatType } from 'constants/chat-type';
import { type Intent } from 'constants/intent';
import { VisitorState } from 'constants/visitor-state';
import type { IStoreState } from 'interfaces/store/store-state';
import { isClosedThread, isUnassignedChat } from 'store/entities/chats/helpers/common';
import type { ChatThreadEntity } from 'store/entities/chats/interfaces';
import { getActiveThreadByCustomerId } from 'store/entities/chats/selectors';
import { type IWithGroupsState } from 'store/entities/groups/selectors';

import { resolveCustomerIntent } from './helpers/customer';
import { type ICustomer } from './interfaces';
import { type IWithCustomersEntityState, getCustomer, getAllCustomers } from './selectors';

export function calculateCustomerActivity(customer: ICustomer, thread: ChatThreadEntity): VisitorState {
  /**
   * If there is no customer available in the store it most probably means that person has left website.
   */
  if (!customer) {
    return VisitorState.LeftWebsite;
  }

  /**
   * Customer is trackable when he is managed by LiveChat tracking code
   * (not by external integrations, like Messenger)
   */
  const customerIsTrackable = Boolean(customer.visitDetails);
  const customerHasEndedVisit = Boolean(customer.visitDetails?.visitEndedTimestampInMs);
  const customerStartedNewVisit = isAfter(
    customer.visitDetails?.visitStartedTimestampInMs,
    customer.visitDetails?.visitEndedTimestampInMs,
  );
  /**
   * FIXME: Offline customers appear as "online" when they have Unassigned chat
   * because they don't provide `visitEndedTimestampInMs` attribute.
   * In that case we are unable to distinguish online from offline customers (we assume online).
   */

  /**
   * There is no chat related to the customer. The customer never had a chat.
   */
  if (!thread) {
    /**
     * Customer has ended the visit and did not start a new one
     */
    if (customerHasEndedVisit && !customerStartedNewVisit) {
      return VisitorState.LeftWebsite;
    }

    /**
     * In an unlikely scenario where customer has no chatting nor tracking history, return Left website.
     */
    if (!customerIsTrackable) {
      return VisitorState.LeftWebsite;
    }

    /**
     * Customer was invited via targeted message.
     */
    if (customer.visitDetails.wasInvited) {
      return VisitorState.Invited;
    }

    /**
     * Customer is just browsing
     */
    return VisitorState.Browsing;
  }

  /**
   * There is an active chat related to the customer.
   */
  if (!isClosedThread(thread)) {
    return {
      [ChatType.My]: VisitorState.Chatting,
      [ChatType.Queued]: VisitorState.Queued,
      [ChatType.Supervised]: VisitorState.Supervised,
      [ChatType.Unassigned]: VisitorState.WaitingForReply,
      [ChatType.Other]: VisitorState.Chatting,
    }[thread.type];
  }

  /**
   * There is a closed (inactive) chat related to the customer.
   */

  /**
   * Customer has left an unreplied chat.
   */
  if (isUnassignedChat(thread)) {
    return VisitorState.WaitingForReply;
  }

  /**
   * Customer has finished chatting.
   * Only chats that include Chat Widget code provide visit details,
   * making it also possible to track if a customer is currently `browsing`.
   */
  if (customerHasEndedVisit) {
    return VisitorState.LeftWebsite;
  }

  if (customerIsTrackable) {
    return VisitorState.Browsing;
  }

  /**
   * Customer finished the chat but is not trackable, display him as "offline"
   */
  return VisitorState.LeftWebsite;
}

export function getCustomerActivity(state: IStoreState, customerId: string, thread: ChatThreadEntity): VisitorState {
  return calculateCustomerActivity(getCustomer(state, customerId), thread);
}

export function calculateAgentIdChattingWithCustomer(customer: ICustomer, thread: ChatThreadEntity): string | null {
  /**
   * If there is no customer available in the store it most probably means that person has left website.
   * No existing chat means no agent is chatting with the customer
   */
  if (!customer || !thread) {
    return null;
  }

  /**
   * There is an active chat related to the customer.
   */
  if (!isClosedThread(thread)) {
    if ([ChatType.My, ChatType.Supervised, ChatType.Other].includes(thread.type)) {
      return thread.currentAgentId;
    }

    return null;
  }

  return null;
}

export function getAgentIdChattingWithCustomer(state: IStoreState, customerId: string): string {
  return calculateAgentIdChattingWithCustomer(
    getCustomer(state, customerId),
    getActiveThreadByCustomerId(state, customerId),
  );
}

export function getCustomerIntent(
  state: IWithCustomersEntityState & IWithGroupsState,
  customerId: string,
): Intent | null {
  const customer = getCustomer(state, customerId);

  return resolveCustomerIntent(customer);
}

export const getAllUniqueCustomerIntents = createSelector(getAllCustomers, (customers) => {
  const allIntents = Object.values(customers).map((customer) => resolveCustomerIntent(customer));

  return uniq(allIntents);
});
