import { css } from '@emotion/css';
import { DesignToken, RadiusToken, ShadowToken, SpacingToken } from '@livechat/design-system-react-components';

import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';
import { resetLegacyLcBorderBox } from 'styles/reset-border-box';
import { StackingContextLevel } from 'styles/stacking-context-level';

import {
  COMMAND_MENU_BOTTOM_EXPANDED_OFFSET,
  COMMAND_MENU_BOTTOM_MOBILE_OFFSET,
  COMMAND_MENU_BOTTOM_OFFSET,
  COMMAND_MENU_LEFT_OFFSET,
  COMMAND_MENU_WIDTH,
} from './constants';

export const menu = css`
  ${resetLegacyLcBorderBox};
  position: absolute;
  bottom: ${COMMAND_MENU_BOTTOM_EXPANDED_OFFSET}px;
  width: ${COMMAND_MENU_WIDTH}px;
  max-height: 306px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: var(${ShadowToken.PopOver});
  background-color: var(${DesignToken.PickerListBackground});
  padding: var(${SpacingToken.Spacing3});
  border-radius: var(${RadiusToken[3]});
  z-index: ${StackingContextLevel.Popover};

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
    box-sizing: border-box;
    position: fixed;
    bottom: ${COMMAND_MENU_BOTTOM_MOBILE_OFFSET}px;
    width: calc(95% - ${COMMAND_MENU_LEFT_OFFSET}px);
    max-width: ${COMMAND_MENU_WIDTH}px;
    left: ${COMMAND_MENU_LEFT_OFFSET}px;
  }
`;

export const popoverMenu = css`
  width: 358px;
  bottom: ${COMMAND_MENU_BOTTOM_OFFSET}px;
  left: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 -2px 2px 1px rgb(0 0 0 / 15%), 0 -4px 16px 1px rgb(1 1 1 / 50%), inset 0 1px 0 0 rgb(255 255 255 / 15%);
`;

export const popoverMenuEmptyState = css`
  ${popoverMenu};
  height: 306px;
`;

export const emptyStateWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: var(${SpacingToken.Spacing3});
  height: 170px;

  p {
    color: var(${DesignToken.ContentBasicPrimary});
    line-height: 22px;
  }
`;

export const optionTitle = css`
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: var(${DesignToken.ContentBasicPrimary});
`;
export const optionDescription = css`
  color: var(${DesignToken.ContentBasicSecondary});
`;

export const optionContainer = css`
  display: flex;
  align-items: center;
  padding: 7px 0;
  transition: background-color 0.2s ease;

  &:hover {
    cursor: pointer;
  }
`;

export const integrationIconWrapper = css`
  height: 32px;
  width: 32px;
  margin: 0 var(${SpacingToken.Spacing2});
`;

export const iconWrapper = css`
  display: flex
  align-items: center;
  justify-content: center;
  border-radius: var(${SpacingToken.Spacing2});
  padding: 6px;
  margin: 0  var(${SpacingToken.Spacing2});
  background-color: var(${DesignToken.SurfaceSecondaryDefault});

  svg {
    color: var(${DesignToken.ContentBasicPrimary});
  }
`;

export const activeItem = css`
  background-color: var(${DesignToken.PickerListOptionBackgroundHover});
  border-radius: var(${SpacingToken.Spacing2});
`;
