import { css } from '@emotion/css';

import { focusBoxShadow } from 'styles/focus-box-shadow';

export const dropdownTrigger = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  padding: 0;

  &:focus {
    ${focusBoxShadow};
    outline: none;
  }
`;

export const itemContent = css`
  margin-right: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
`;
