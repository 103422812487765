import { type FC, type ChangeEvent } from 'react';

import { cx } from '@emotion/css';
import { ThemeClassName } from '@livechat/design-system-react-components';
import { type DraggableProvided, type DraggableRubric, type DraggableStateSnapshot } from 'react-beautiful-dnd';

import { useSelectedVisibleApps } from 'routes/apps/hooks/use-selected-visible-apps';
import { type IApplication } from 'store/entities/applications/interfaces';

import { ApplicationListItem } from '../ApplicationListItem/ApplicationListItem';

import { droppableContainer, item } from './styles';

interface Props {
  applications: IApplication[];
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  rubric: DraggableRubric;
  handleAppSelected: (event: ChangeEvent<HTMLInputElement>, appId: string) => void;
}

export const ApplicationRenderItem: FC<Props> = ({ applications, provided, snapshot, rubric, handleAppSelected }) => {
  const [, selectedAppsIds] = useSelectedVisibleApps();

  const rubricIndexApp = applications[rubric.source.index];
  const appIcon = rubricIndexApp.interfaceDarkIcons?.small || rubricIndexApp.icons?.small;
  const isChecked = selectedAppsIds.includes(rubricIndexApp.id);

  return (
    <div ref={provided.innerRef} className={droppableContainer}>
      <ApplicationListItem
        draggableProvided={provided}
        snapshot={snapshot}
        appId={rubricIndexApp.id}
        name={rubricIndexApp.name}
        appIcon={appIcon}
        className={cx(item, ThemeClassName.Dark)}
        checked={isChecked}
        onAppSelected={handleAppSelected}
      />
    </div>
  );
};
