export class ExternallyResolvablePromise<T = unknown, E = unknown> {
  private internal: {
    promise: Promise<T>;
    resolve: (value: T) => void;
    reject: (reason?: E) => void;
  };

  constructor() {
    this.internal = {} as typeof this.internal;
    this.internal.promise = new Promise<T>((resolve, reject) => {
      this.internal.resolve = resolve;
      this.internal.reject = reject;
    });
  }

  get promise(): Promise<T> {
    return this.internal.promise;
  }

  resolve(value: T): void {
    this.internal.resolve(value);
  }

  reject(reason?: E): void {
    this.internal.reject(reason);
  }
}
