// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';

import { ReportType } from 'constants/reports/report-type';
import { ApiManager } from 'services/api/api-manager';
import { serializeFilters } from 'services/api/report/filters';
import { type IActionWithPayload } from 'store/helper';

import { type IFetchReportPayload, type IReportRequest, ReportRequestName } from '../interfaces';

import { makeFetchingSaga } from './helpers';

export function* fetchTicketRating(action: IActionWithPayload<string, IFetchReportPayload>): SagaIterator {
  const { payload } = action;
  const { filters, distribution } = payload;

  const serializedFilters = serializeFilters(filters, distribution);

  const requests: IReportRequest[] = [
    {
      name: ReportRequestName.TicketsRatingResponse,
      interface: ApiManager.reportApi.fetchTicketsSatisfaction,
    },
  ];

  if (!serializedFilters.agents) {
    requests.push({
      name: ReportRequestName.TicketsRankingResponse,
      interface: ApiManager.reportApi.fetchTicketsRanking,
      disableComparison: true,
    });
  }

  yield makeFetchingSaga(ReportType.TicketRatings, action, requests);
}
