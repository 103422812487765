import { type SagaIterator } from 'redux-saga';
import { select, takeEvery } from 'redux-saga/effects';

import type { KeyMap } from 'helpers/interface';
import { setUserPropertiesForAmplitude } from 'services/amplitude';
import { LicenseCustomPropertiesActionNames } from 'store/entities/license-custom-properties/actions';
import { getLicenseCustomProperties } from 'store/entities/license-custom-properties/selectors';

const propertiesToSave = [
  'risk_level',
  'risk_score',
  'risk_reasons',
  'risk_email_valid',
  'risk_email_socialMatch',
  'risk_email_companyMatch',
  'risk_email_nameMatch',
  'risk_email_disposable',
  'risk_email_freeProvider',
  'risk_email_blacklisted',
  'risk_ip_proxy',
  'risk_ip_geoMatch',
  'risk_ip_blacklisted',
  'risk_ip_rateLimited',
];

export function* setupRiskDetection(): SagaIterator {
  const properties: KeyMap<string | string[]> = yield select(getLicenseCustomProperties);

  if (properties) {
    const userProperties = Object.keys(properties).reduce(
      (acc, item) => (propertiesToSave.includes(item) ? { ...acc, [item]: properties[item] } : acc),
      {}
    );

    setUserPropertiesForAmplitude(userProperties);
  }
}

export function* setupRiskDetectionSagas(): SagaIterator {
  yield takeEvery(LicenseCustomPropertiesActionNames.FETCH_LICENSE_CUSTOM_PROPERTIES_SUCCESS, setupRiskDetection);
}
