// @ts-strict-ignore
export interface CallbackFunction extends CallableFunction {
  (...params: unknown[]): boolean | Promise<unknown>;
}

function promisifyCallback(result: boolean | Promise<unknown>): Promise<unknown> {
  if (typeof result === 'object' && typeof result.then === 'function') {
    return result;
  }

  return new Promise((resolve, reject) => (result ? resolve(result) : reject(result)));
}

export default class AwaitEmitter {
  private callbacks: CallbackFunction[] = [];

  on(callback: CallbackFunction): VoidFunction {
    this.callbacks.push(callback);

    return () => this.off(callback);
  }

  off(callback: CallbackFunction): void {
    const index = this.callbacks.indexOf(callback);

    if (index !== -1) {
      this.callbacks.splice(index, 1);
    }
  }

  trigger(...params: unknown[]): Promise<unknown> {
    const promises = [];

    this.callbacks.forEach((callback: CallbackFunction) => {
      promises.push(promisifyCallback(callback.call(null, ...params)));
    });

    return Promise.all(promises);
  }

  reset(): void {
    this.callbacks = [];
  }
}
