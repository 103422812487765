import { css } from '@emotion/css';

export const filterBox = css`
  width: auto;
`;

export const filterButton = css`
  max-width: 100%;
`;

export const datePickerWrapper = css`
  display: flex;
  justify-content: center;
`;

export const datePicker = css`
  display: flex;
`;
