import { BaseApi } from '../base-api';

import { type FetchInAppMessagesParams, type IFetchInAppsResponse } from './interfaces';

export class InAppMessagesApi extends BaseApi {
  protected readonly prefix = 'in_app_messages';

  fetchAll = (body: FetchInAppMessagesParams) => this.get<IFetchInAppsResponse[]>(undefined, body);
  markAsRead = (id: string) => this.delete<IFetchInAppsResponse[]>(id);
  markAllAsRead = () => this.delete<IFetchInAppsResponse[]>('batch');
}
