// @ts-strict-ignore
import type { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ApiManager } from 'services/api/api-manager';
import type { IActionWithPayload } from 'store/helper';
import { OnboardingActionsEnum } from 'store/views/onboarding/actions';

import { ChatbotInLivechatActions } from './actions';
import type { IInitChatbotInLivechatPayload } from './interfaces';

function* initChatbotInLivechatSaga(action: IActionWithPayload<string, IInitChatbotInLivechatPayload>): SagaIterator {
  const { chatbotInLivechatApi } = ApiManager;

  try {
    yield call(chatbotInLivechatApi.initChatbot, action.payload);
    yield put(ChatbotInLivechatActions.initChatbotInLivechatSuccess({ status: 'OK' }));
  } catch (error) {
    yield put(ChatbotInLivechatActions.initChatbotInLivechatFailure({ error: error.message }));
  }
}

export function* chatbotInLivechatSagas(): SagaIterator {
  yield takeEvery(OnboardingActionsEnum.INITIALIZE_CHATBOT, initChatbotInLivechatSaga);
}
