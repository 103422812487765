import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import { type FetchInfoResponse } from './interfaces';

export class ChatPageApi extends BaseApi {
  protected readonly prefix = 'chatting_page';

  fetchInfo = (licenseId: number, groupId: string): Promise<RequestResult<FetchInfoResponse>> =>
    this.get('info', {
      id: licenseId,
      group: groupId,
    });
}
