import { type SagaIterator } from 'redux-saga';
import { fork, select, take, takeEvery } from 'redux-saga/effects';

import { type ISubscription } from 'interfaces/subscription';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { SubscriptionViewActionNames } from 'store/views/subscription/actions';

import { mapSubscriptionToNewSubscription } from './helpers';

function* resetSubscriptionState(): SagaIterator {
  const subscription: ISubscription = yield select(getSubscription);
  if (!subscription) {
    yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
  }

  yield fork(mapSubscriptionToNewSubscription, subscription);
}

export function* resetSubscriptionStateSaga(): SagaIterator {
  yield takeEvery(SubscriptionViewActionNames.RESET_SUBSCRIPTION_STATE, resetSubscriptionState);
}
