// @ts-strict-ignore
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { type KeyMap } from 'helpers/interface';
import { ApiManager } from 'services/api/api-manager';
import { type ApplicationLoadSource } from 'services/api/integrations/interfaces';
import { AppStateProvider } from 'services/app-state-provider';
import { getLoggedInAgent } from 'store/entities/agents/selectors';

const log = debug(DebugLogsNamespace.IntegrationLoadTracker);

const cache: KeyMap<boolean> = {};

export async function trackAppLoad(applicationId: string, source: ApplicationLoadSource): Promise<void> {
  const cacheKey = `${applicationId}_${source}`;

  if (cache[cacheKey]) {
    return;
  }

  const agent = AppStateProvider.selectFromStore(getLoggedInAgent);

  const payload = {
    appId: applicationId,
    accountId: agent.accountId,
    organizationId: agent.organizationId,
    source,
    timestamp: new Date().getTime().toString(),
  };
  log(`%cApplicationLoaded from:${source} %c${JSON.stringify(payload)}`, 'color: #54a3d1', 'color: #547ed1');
  await ApiManager.integrationsApi.trackAppLoaded(applicationId, payload);

  cache[cacheKey] = true;
}
