import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { isLabsEnvironment, isProductionEnvironment } from 'helpers/feature-toggle';
import { AgentChatApiErrorType } from 'services/connectivity/agent-chat-api/types';
import { isApiErrorResponse, isWithApiHttpError } from 'services/connectivity/configuration-api/type-guards';
import { isNetworkIssue } from 'services/connectivity/http/helpers';
import { HTTPStatus, type RequestErrorParams } from 'services/connectivity/http/types';
import { waitBeforeRetry } from 'services/connectivity/retry-policy/wait-before-retry';
import { connectivityStatusClient } from 'services/connectivity/status/client';

import { isError, isHTTPError } from '../connectivity/http/type-guards';
import { logsClient } from '../connectivity/queue-api/logs/client';

const log = debug(DebugLogsNamespace.AppServerConnectionErrors);

// a workaround for the case when the license id is not available (it is required in the request, but we want to log all errors)
const FAKE_LICENSE_ID = '12345';
const LOG_THRESHOLD = 0.01; // 1%
const MAX_RETRIES = 10;

const logRandomCast = Math.random();
let storedLicenseId: string = FAKE_LICENSE_ID;

/**
 * Network errors are hopefully detected and handled by a yellow bar or startup error page or some other way.
 * We don't have to log them to graylog.
 */
function shouldSkipForError(error: unknown): boolean {
  if (isNetworkIssue(error)) {
    log('Network issue, skip logging');

    return true;
  }

  return false;
}

/**
 * Some requests fail to process but they return a valid response which is handled on our side.
 * We don't have to log them to graylog because these errors are handled by red alert, redirect to accounts, etc.
 */
function shouldSkipForRequest(
  endpoint: string,
  status: HTTPStatus | null,
  apiErrorType: string | null,
  apiErrorMessage: string,
): boolean {
  if (status === HTTPStatus.Unauthorized) {
    log('Unauthorized request, skip logging');

    return true;
  }

  if (status === HTTPStatus.Forbidden && apiErrorMessage === 'Your account is suspended') {
    log('Account is suspended, skip logging');

    return true;
  }

  if (
    ['add_user_to_chat', 'start_chat', 'resume_chat'].includes(endpoint) &&
    status === HTTPStatus.UnprocessableEntity
  ) {
    log('Chats limit reached or chat already assigned, skip logging');

    return true;
  }

  if (['start_chat', 'resume_chat'].includes(endpoint) && status === HTTPStatus.NotFound) {
    log("Can't start chat, customer went offline, skip logging");

    return true;
  }

  if (['add_user_to_chat', 'resume_chat'].includes(endpoint) && status === HTTPStatus.Forbidden) {
    log('Agent lost access to chat, skip logging');

    return true;
  }

  if (
    endpoint === 'add_user_to_chat' &&
    status === HTTPStatus.BadRequest &&
    apiErrorType === (AgentChatApiErrorType.CHAT_INACTIVE as string)
  ) {
    log('Chat was dropped from queue before picking up was possible, skip logging');

    return true;
  }

  if (
    ['list_groups', 'list_routing_statuses'].includes(endpoint) &&
    status === HTTPStatus.BadRequest &&
    apiErrorType === (AgentChatApiErrorType.LICENSE_EXPIRED as string)
  ) {
    log('Fetching startup data resulted with license expired, skip logging');

    return true;
  }

  return false;
}

/**
 * Skip logging for dev environment and on production with a certain probability.
 */
function shouldSkipForEnvironment(): boolean {
  return isLabsEnvironment() || (isProductionEnvironment() && logRandomCast >= LOG_THRESHOLD);
}

/**
 * Don't log all request bodies, as they may be too large.
 * Log only specific requests which parameters should be analyzed.
 */
function getRequestBodyToBeLogged(requestBody: unknown, status: HTTPStatus | null): string | undefined {
  if (status === HTTPStatus.BadRequest || status === HTTPStatus.NotFound || status === HTTPStatus.UnprocessableEntity) {
    return JSON.stringify(requestBody);
  }

  return undefined;
}

async function sendLog(logObject: Record<string, unknown>, retryAttempt = 1): Promise<void> {
  try {
    log('Sending log', logObject);
    await logsClient.log({
      message: `[livechat-web] ${JSON.stringify(logObject)}`,
      /* eslint-disable @typescript-eslint/naming-convention */
      event_id: 'webapp_log',
      licence_id: +storedLicenseId,
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  } catch (error) {
    log('Failed to send log', error);

    if (retryAttempt >= MAX_RETRIES) {
      log('Max retries reached, skip logging');

      return;
    }

    log('Retrying...');

    await waitBeforeRetry(retryAttempt);

    await sendLog(logObject, retryAttempt + 1);
  }
}

async function logError(error: unknown, params: RequestErrorParams): Promise<void> {
  log('Logging request error', params);
  log(error);

  if (shouldSkipForError(error)) {
    return;
  }

  const { endpoint, method, logTimestamp, requestTimestamp, parsedError, requestBody: reqBody } = params;
  const isOnline = connectivityStatusClient.getIsOnline();
  const connectionInfo = {
    ['connection.onLine']: navigator.onLine,
    ['connection.type']: navigator.connection?.effectiveType,
    ['connection.downlink']: navigator.connection?.downlink,
    ['connection.rtt']: navigator.connection?.rtt,
    ['connection.saveData']: navigator.connection?.saveData,
    ['store.isOnline']: isOnline,
  };
  const status = isHTTPError(error) ? (error.response.status as HTTPStatus) : null;

  const { message: errorMessage, name: errorName } = isError(error)
    ? error
    : { message: 'No error message', name: 'UnknownError' };

  const { type: apiErrorType, message: apiErrorMessage } =
    isWithApiHttpError(parsedError) && parsedError.error && isApiErrorResponse(parsedError.error.http)
      ? parsedError.error.http.error
      : { message: JSON.stringify(parsedError), type: 'UnknownParsedError' };

  if (shouldSkipForRequest(endpoint, status, apiErrorType, apiErrorMessage)) {
    return;
  }

  const requestBody = getRequestBodyToBeLogged(reqBody, status);

  const logObject = {
    errorMessage,
    errorName,
    apiErrorType,
    apiErrorMessage,
    endpoint,
    method,
    logTimestamp,
    requestTimestamp,
    status,
    requestBody,
    ...connectionInfo,
  };

  if (shouldSkipForEnvironment()) {
    log('Skipping logging due to environment limitations', logObject);

    return;
  }

  await sendLog(logObject);
}

export function saveLicenseIdForRequestLogger(licenseId: string): void {
  storedLicenseId = licenseId;
}

export function logRequestError(error: unknown, params: RequestErrorParams): void {
  void logError(error, params);
}
