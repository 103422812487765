// @ts-strict-ignore
import { type DevToolsAction, DevToolsActionNames } from './actions';
import { type IDevToolsViewState } from './interfaces';

const initialState = {
  isVisible: false,
};

export const devToolsReducer = (
  state: IDevToolsViewState = initialState,
  action?: DevToolsAction
): IDevToolsViewState => {
  switch (action.type) {
    case DevToolsActionNames.TOGGLE: {
      return {
        ...state,
        isVisible: action.payload.isVisible,
      };
    }

    default:
      return state;
  }
};
