import { EnvironmentType } from 'constants/environment';

export const ENGAGE_APPLICATIONS = [
  {
    id: 'pXThABRNg',
    environment: EnvironmentType.Production,
    widgetId: 'YGlC5kcrS',
  },
  {
    id: 'o4PHZUCSR',
    environment: EnvironmentType.Labs,
    widgetId: 'qqg96HLtn-',
  },
];
