import { useCallback, type FC } from 'react';

import { NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { GlobalModal } from 'constants/global-modal';
import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { GlobalModalActions } from 'store/features/global-modals/actions';

import { CLOSE_BUTTON_TEST_ID } from './constants';

import * as styles from './styles';

interface IProps {
  onFollowLinkClicked(): void;
  onSendCodeBtnClicked(): void;
  onSkip(name: TopBarNotificationType): void;
  isVisible: boolean;
}

export const CodeNotInstalledBar: FC<IProps> = ({ onSendCodeBtnClicked, onFollowLinkClicked, onSkip, isVisible }) => {
  const dispatch = useDispatch();

  const handleSendToDeveloperClick = useCallback((): void => {
    dispatch(GlobalModalActions.showModal(GlobalModal.InviteAgents, { eventPlace: EventPlace.TopBar }));

    onSendCodeBtnClicked();
  }, [onSendCodeBtnClicked, dispatch]);

  const handleSkipClick = useCallback((): void => {
    onSkip(TopBarNotificationType.InstallCode);
  }, [onSkip]);

  return (
    <NavigationTopBarAlert
      kind="warning"
      isVisible={isVisible}
      primaryCta={{
        label: 'Install now',
        onClick: onFollowLinkClicked,
        size: 'compact',
        kind: 'secondary',
      }}
      secondaryCta={{
        label: 'Invite developer',
        onClick: handleSendToDeveloperClick,
        size: 'compact',
        kind: 'secondary',
      }}
      closeButton={{
        onClick: handleSkipClick,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'data-testid': CLOSE_BUTTON_TEST_ID,
      }}
    >
      <Text as="span" bold className={styles.codeNotInstalledBarText}>
        Install LiveChat to start chatting with your customers.
      </Text>
    </NavigationTopBarAlert>
  );
};
