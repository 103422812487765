import { type AddWebsiteSourceFormData, type AddPDFSourceFormData } from 'interfaces/knowledge-hub';
import { KnowledgeSourceItemStatus } from 'interfaces/knowledge-sources';

export const ADD_WEBSITE_SOURCE_FORM_INITIAL_STATE: AddWebsiteSourceFormData = {
  knowledgeType: 'public',
  pages: 'All pages',
  websiteUrl: '',
  autoSynchronise: true,
  autoSynchronisePeriod: '30',
  sourceName: '',
};

export const ADD_PDF_SOURCE_FORM_INITIAL_STATE: AddPDFSourceFormData = {
  knowledgeType: 'public',
  file: null,
};

export const LOADING_STATUSES = [
  KnowledgeSourceItemStatus.IN_PROGRESS,
  KnowledgeSourceItemStatus.PENDING,
  KnowledgeSourceItemStatus.IDLE,
];

export enum KnowledgeHubEvent {
  AddWebsiteTriggered = 'Knowledge Hub Add website triggered',
  AddWebsiteFinalized = 'Knowledge Hub Add website finalized',
  WebsiteSourceWasAdded = 'Knowledge Hub website source was added',
  WebsiteBannerSeen = 'Knowledge Hub website banner seen',
  AddWebsiteWithSelectedPagesNextClicked = 'Knowledge Hub Add website with Selected pages next clicked',
  AddWebsiteFlowAbandoned = 'Knowledge Hub Add website flow abandoned',
  AddPDFTriggered = 'Knowledge Hub Add PDF triggered',
  AddPDFFinalized = 'Knowledge Hub Add PDF finalized',
  PDFSourceWasAdded = 'Knowledge Hub PDF source was added',
  SourceDeleteClicked = 'Knowledge Hub source delete clicked',
  SourceTypeWasModified = 'Knowledge Hub source type was modified',
  WebsitePagesDeleted = 'Knowledge Hub website pages deleted',
  AddingSourceWasCanceled = 'Knowledge Hub adding source was canceled',
  UpdatingSourceWasCanceled = 'Knowledge Hub updating source was canceled',
  UpdatingSourceWasRequested = 'Knowledge Hub updating source was requested',
  SourceAllSourcesClicked = 'Knowledge Hub source overview clicked',
  OnboardingSuccesfull = 'Knowledge Hub onboarding succesfull',
  OnboardingSeen = 'Knowledge Hub onboarding seen',
  OneWasUsed = 'Knowledge Hub One was used',
  UpgradeClicked = 'Knowledge Hub upgrade clicked',
}
