import { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import type {
  AgentCustomPropertyDataType,
  AgentCustomPropertyName,
} from 'store/features/agent-custom-properties/interfaces';
import {
  hasFetchedAgentCustomProperties,
  getAgentCustomProperty,
} from 'store/features/agent-custom-properties/selectors';

export function useAgentCustomProperty<T extends AgentCustomPropertyDataType = string>(
  propertyName: AgentCustomPropertyName,
  initialValue: T | null = null
): [T, (value: T) => void] {
  const dispatch = useDispatch();
  const agentProperty = useSelector((state) => getAgentCustomProperty(state, propertyName));
  const isPropsFetched = useSelector(hasFetchedAgentCustomProperties);
  const property = agentProperty ?? initialValue;

  const handleSetProperty = useCallback(
    (value: T): void => {
      if (!isPropsFetched || property === value) {
        return;
      }

      dispatch(
        AgentCustomPropertiesActions.setAgentCustomProperty({
          [propertyName]: value,
        })
      );
    },
    [dispatch, property, isPropsFetched, propertyName]
  );

  return [property as T, handleSetProperty];
}
