import type { FC } from 'react';

import { NavigationBadge } from '../navigation-badge/NavigationBadge';

import { useTeamBadgeProps } from './use-team-badge-props';

export const TeamBadge: FC = () => {
  const params = useTeamBadgeProps();

  return params && <NavigationBadge type={params.type} count={params.count} />;
};
