import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import { type IFlashScreenshotRequest } from './interfaces';

export class FlashScreenshotApi extends BaseApi {
  getImage = (params: IFlashScreenshotRequest): Promise<RequestResult<string>> =>
    this.get<string>('url-to-image', { ...params });
}
