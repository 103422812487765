import { type FC } from 'react';

import { cx } from '@emotion/css';
import { Help } from '@livechat/design-system-icons';
import { Button, Tooltip, Icon } from '@livechat/design-system-react-components';

import { openInNewCard } from 'helpers/routing';

import { GOOGLE_FEEDBACK_FORM } from './constants';

import * as styles from './styles';

function handleLeaveFeedback(): void {
  openInNewCard(GOOGLE_FEEDBACK_FORM);
}

export const FeedbackTooltip: FC = () => (
  <Tooltip
    kind="invert"
    placement="bottom-end"
    hoverOnDelay={300}
    className={styles.feedbackTooltip}
    triggerClassName={styles.feedbackTooltipContainer}
    triggerRenderer={<Icon source={Help} className={styles.feedbackIcon} />}
  >
    <div className={styles.feedbackTooltipContent}>
      <p className={cx('lc-light-theme', styles.feedbackText)}>
        Help us shape this feature to best fit your needs by testing it out and sharing your thoughts.
      </p>
      <Button className="lc-light-theme" onClick={handleLeaveFeedback} kind="high-contrast" type="submit">
        Leave feedback
      </Button>
    </div>
  </Tooltip>
);
