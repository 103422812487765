// @ts-strict-ignore
import { createSelector } from 'reselect';

import { LoginStatus } from 'constants/login-status';
import { type KeyMap } from 'helpers/interface';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import { type IAgent } from 'store/entities/agents/interfaces';
import { agentsSelector, getAgents, getLoggedInAgent, type WithAgentsState } from 'store/entities/agents/selectors';
import { type IAgentBot } from 'store/entities/bots/interfaces';
import { botsSelector, getBots, type IWithBotsState } from 'store/entities/bots/selectors';
import { sortByName } from 'store/helper';
import { type IAgentsAndBots } from 'store/views/team/interfaces';

export function agentsAndBotsSelector(state: WithAgentsState & IWithBotsState): IAgentsAndBots {
  return { ...state.entities.agents.agents, ...state.entities.bots };
}

export function allAgentsIdsSelector(state: WithAgentsState & IWithBotsState): string[] {
  return [...Object.keys(state.entities.agents.agents), ...Object.keys(state.entities.bots)];
}

export const getAllAgentsIds = createSelector(agentsSelector, botsSelector, (agents, bots) => [
  ...Object.keys(agents),
  ...Object.keys(bots),
]);

export const getAgentsAndBots = createSelector(agentsSelector, botsSelector, (agents, bots) =>
  [...Object.values(agents), ...Object.values(bots)].sort(sortByName),
);

export function getAgentOrBot(state: WithAgentsState & IWithBotsState, login: string): IAgentBase {
  return state.entities.agents.agents[login] || state.entities.bots[login];
}

export const getAgentOrBotWithAccountId: (
  state: WithAgentsState & IWithBotsState,
  accountId: string | null,
) => IAgentBase | IAgentBot | undefined = createSelector(
  getAgents,
  getBots,
  (_, accountId: string | null) => accountId,
  (agents: IAgent[], bots: IAgentBot[], accountId: string | null) =>
    agents.find((agent) => agent.accountId === accountId) || bots.find((bot) => bot.login === accountId),
);

export function getAgentOrBotName(state: WithAgentsState & IWithBotsState, login: string): string {
  const agent = getAgentOrBot(state, login);

  return agent && agent.name;
}

export function getAgentOrBotAvatar(state: WithAgentsState & IWithBotsState, login: string): string {
  const agent = getAgentOrBot(state, login);

  return agent && agent.avatar;
}

export const getOnlineAgentsAndBots: (state: WithAgentsState & IWithBotsState) => IAgentBase[] = createSelector(
  getAgentsAndBots,
  (agents) => agents.filter((agent) => agent.status === LoginStatus.Online || agent.status === LoginStatus.Away),
);

export const getOnlineAgentsAndBotsExcludingMe: (state: WithAgentsState & IWithBotsState) => IAgentBase[] =
  createSelector(getOnlineAgentsAndBots, getLoggedInAgent, (agents, loggedInAgent) =>
    agents.filter(
      (agent) =>
        (agent.status === LoginStatus.Online || agent.status === LoginStatus.Away) &&
        loggedInAgent.login !== agent.login,
    ),
  );

export const getAgentsAndBotsWorkSchedulers = (
  state: WithAgentsState & IWithBotsState,
  agentLogins: string[],
): KeyMap<IAgent['workSchedule']> => {
  if (!agentLogins.length) return {};
  return getAgentsAndBots(state).reduce(
    (acc, agent) => {
      if (agentLogins.includes(agent.login)) {
        acc[agent.login] = agent.workSchedule;
      }

      return acc;
    },
    {} as KeyMap<IAgent['workSchedule']>,
  );
};

export const getLoggedAgentsAndBotsCount = (state: WithAgentsState & IWithBotsState): number => {
  const agents = getAgentsAndBots(state);

  return agents.filter(({ status }) => {
    return status === LoginStatus.Online || status === LoginStatus.Away;
  }).length;
};

export const getTotalAgentsAndBotsCount = (state: WithAgentsState & IWithBotsState): number =>
  getAgentsAndBots(state).length;
