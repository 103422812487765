import { type FC } from 'react';

import { Badge } from '@livechat/design-system-react-components';

import { useParamSelector } from 'hooks/use-param-selector';
import { getNavigationItemBadge } from 'store/views/navigation/selectors';

import { badge } from './styles';

interface Props {
  widgetId: string;
}

export const YourAppsItemBadge: FC<Props> = ({ widgetId }) => {
  const badgeContentFromStore = useParamSelector(getNavigationItemBadge, widgetId);

  if (badgeContentFromStore?.numeric) {
    return <Badge count={badgeContentFromStore.numeric} className={badge} />;
  }

  return null;
};
