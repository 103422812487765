// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import {
  format,
  differenceInCalendarDays,
  isToday,
  isYesterday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameMonth,
  isValid,
  subDays,
  subYears,
  isEqual,
  subMonths,
  isSameDay,
  startOfDay,
  endOfDay,
} from 'date-fns';

import { type WithOperators } from 'components/filters/interfaces';
import { DateFormat } from 'constants/date';
import { DateFilterId, type CustomDateFilter, type DateFilter } from 'constants/filters/date-filter';
import {
  CustomDateRangeFilterId,
  type CustomDateRangeFilter,
  type DateRangeFilter,
} from 'constants/filters/date-range-filter';
import { type Filters, type FiltersOperators, type Filter, FilterOperator } from 'constants/filters/filter';
import type { ComplexFilter, RoutingFilter, WithProperties } from 'interfaces/chat/filter';

import { parseDate } from './date';
import { type IKeyValue } from './url';

const MAX_ITEMS_FOR_MATCH_ALL = 20;

export function getCustomDate(date: Date): DateFilterId {
  let id: DateFilterId;
  if (isToday(date)) {
    id = DateFilterId.Today;
  } else if (isYesterday(date)) {
    id = DateFilterId.Yesterday;
  } else if (isToday(subDays(date, -2))) {
    id = DateFilterId.TwoDaysAgo;
  } else if (isToday(subDays(date, -3))) {
    id = DateFilterId.ThreeDaysAgo;
  } else {
    id = DateFilterId.CustomDate;
  }

  return id;
}

export const isWholeMonth = (from: Date, to: Date): boolean =>
  isFirstDayOfMonth(from) && isLastDayOfMonth(to) && isSameMonth(from, to);

export function getCustomDateRange(from: Date, to: Date): CustomDateRangeFilterId {
  const diff = Math.abs(differenceInCalendarDays(from, to));
  let id: CustomDateRangeFilterId;
  if (diff === 0 && isToday(from)) {
    id = CustomDateRangeFilterId.Today;
  } else if (diff === 0 && isYesterday(from)) {
    id = CustomDateRangeFilterId.Yesterday;
  } else if (diff === 6 && isToday(to)) {
    id = CustomDateRangeFilterId.Last7Days;
  } else if (diff === 29 && isToday(to)) {
    id = CustomDateRangeFilterId.Last30Days;
  } else if (isWholeMonth(from, to) && isSameMonth(from, subMonths(new Date(), 1))) {
    id = CustomDateRangeFilterId.LastMonth;
  } else if (
    isFirstDayOfMonth(from) &&
    isSameDay(to, new Date()) &&
    isSameMonth(from, to) &&
    isSameMonth(from, new Date())
  ) {
    id = CustomDateRangeFilterId.CurrentMonth;
  } else {
    id = CustomDateRangeFilterId.CustomPeriod;
  }

  return id;
}

export function getCustomCompareDateRange(
  from: Date,
  to: Date,
  compareFrom: Date,
  compareTo: Date,
): CustomDateRangeFilterId {
  const daysDiff = Math.abs(differenceInCalendarDays(from, to)) + 1;

  const isSamePeriod = isEqual(subDays(from, daysDiff), compareFrom) && isEqual(subDays(to, daysDiff), compareTo);

  if (isSamePeriod) {
    return CustomDateRangeFilterId.SamePeriod;
  } else if (isEqual(subYears(from, 1), compareFrom) && isEqual(subYears(to, 1), compareTo)) {
    return CustomDateRangeFilterId.SamePeriodInLastYear;
  } else if (
    isWholeMonth(from, to) &&
    isWholeMonth(compareFrom, compareTo) &&
    isSameMonth(compareFrom, subMonths(from, 1))
  ) {
    return CustomDateRangeFilterId.SamePeriod;
  }

  return CustomDateRangeFilterId.CustomPeriod;
}

export function deserializeDateRangeURLParams(dateFrom: string, dateTo: string): DateRangeFilter {
  if (!dateFrom || !dateTo) {
    return null;
  }

  const from = startOfDay(parseDate(dateFrom));
  const to = endOfDay(parseDate(dateTo));

  if (!isValid(from) || !isValid(to) || from > to) {
    return null;
  }

  return { from, to };
}

export function deserializeCustomDateRangeURLParams(
  dateFrom: string,
  dateTo: string,
  compareDateFrom?: string,
  compareDateTo?: string,
): CustomDateRangeFilter {
  const dateRangeUrlParams = deserializeDateRangeURLParams(dateFrom, dateTo);
  if (!dateRangeUrlParams) {
    return null;
  }

  const compareDateRangeUrlParams = deserializeDateRangeURLParams(compareDateFrom, compareDateTo);

  const compare = compareDateRangeUrlParams && {
    id: getCustomCompareDateRange(
      dateRangeUrlParams.from,
      dateRangeUrlParams.to,
      compareDateRangeUrlParams.from,
      compareDateRangeUrlParams.to,
    ),
    ...compareDateRangeUrlParams,
  };

  const { from, to } = dateRangeUrlParams;

  return {
    from,
    to,
    id: getCustomDateRange(from, to),
    ...(compare && { compare: compare || null }),
  };
}

export function deserializeDateURLParams(dateString: string): DateFilter {
  if (!dateString) {
    return null;
  }

  const date = parseDate(dateString);
  if (!isValid(date)) {
    return null;
  }

  return { date };
}

export function deserializeCustomDateURLParams(dateString: string): CustomDateFilter {
  const dateUrlParams = deserializeDateURLParams(dateString);
  if (!dateUrlParams) {
    return null;
  }

  const { date } = dateUrlParams;

  return {
    date,
    id: getCustomDate(date),
  };
}

function isCustomDateRangeFilter(filterValue: unknown): filterValue is CustomDateRangeFilter {
  return typeof filterValue === 'object' && filterValue !== null && 'compare' in filterValue;
}

/**
 * Formats a date to ISO8601 format if it exists.
 * @param date - The date to format.
 * @returns The formatted date, or undefined if the date doesn't exist.
 */
function formatIfDefined(date: Date | undefined): string | undefined {
  return date ? format(date, DateFormat.ISO8601Date) : undefined;
}

/**
 * Converts a custom date range filter's compare property to a key-value pair.
 * @param filterValue - The custom date range filter.
 * @returns A key-value pair representing the compare property, or undefined if it doesn't exist.
 */
function mapCompareToQueryString(filterValue: CustomDateRangeFilter): IKeyValue<string> | undefined {
  if (!filterValue.compare) {
    return undefined;
  }

  return {
    compare_date_from: formatIfDefined(filterValue.compare.from),
    compare_date_to: formatIfDefined(filterValue.compare.to),
  };
}

/**
 * Converts a date range filter into a key-value pair that can be used in a query string.
 *
 * @param filterValue - The date range filter to convert. This can be a custom date range filter or a standard date range filter.
 * @returns A key-value pair representing the date range filter. The keys are 'date_from' and 'date_to' for the date range, and 'compare_date_from' and 'compare_date_to' for the comparison date range (if applicable). The values are the corresponding dates in ISO 8601 format. If `filterValue` is null or not an object, an empty object is returned.
 */
export function mapDateRangeToQueryString(filterValue: CustomDateRangeFilter | DateRangeFilter): IKeyValue<string> {
  if (filterValue == null || typeof filterValue !== 'object') {
    return {};
  }

  const compare = isCustomDateRangeFilter(filterValue) ? mapCompareToQueryString(filterValue) : undefined;

  return {
    date_from: formatIfDefined(filterValue.from),
    date_to: formatIfDefined(filterValue.to),
    ...compare,
  };
}

export function mapDateToQueryString(filterValue: DateFilter): IKeyValue<string> {
  if (filterValue === null || typeof filterValue !== 'object') {
    return {};
  }

  return {
    date: format(filterValue.date, DateFormat.ISO8601Date),
  };
}

export function hasAnyFilter(filters?: Filters): boolean {
  if (!filters) {
    return false;
  }

  return Object.keys(filters).some((key) => {
    const filter = filters[key];
    if (Array.isArray(filter)) {
      return filter.length > 0;
    }

    return Boolean(filter);
  });
}

function getIsRequireAllValues(operators: FiltersOperators, filter: Filter): boolean {
  if (!operators) {
    return false;
  }

  return operators[filter] === FilterOperator.And;
}

export function getIsExcludingValues(operators: FiltersOperators, filter: Filter): boolean {
  if (!operators) {
    return false;
  }

  return operators[filter] === FilterOperator.Exclude;
}

export function prepareFilterOperators(operators: Partial<Record<FilterOperator, Filter[]>>): FiltersOperators {
  const filtersOperators: FiltersOperators = {};
  if (!operators) {
    return filtersOperators;
  }

  Object.entries(operators).forEach(([operator, filters]) =>
    filters?.forEach((filter) => {
      filtersOperators[filter] = operator as FilterOperator;
    }),
  );

  return filtersOperators;
}

type IOperatorsConfig = Partial<{
  [key in Filter]: { withOperator?: true | WithOperators };
}>;

export function extractOperatorsToQueryParams(
  operators: FiltersOperators,
  customConfig?: IOperatorsConfig,
): IKeyValue<string> {
  const queryParams: IKeyValue<string> = {};
  if (!operators) {
    return {};
  }
  Object.entries(operators).forEach(([filter, operator]) => {
    if (customConfig) {
      const { withOperator } = customConfig[filter] || {};
      if (!withOperator) {
        return;
      }
    }

    const key = `op:${operator}`;
    if (!queryParams[key]) {
      queryParams[key] = [];
    }
    (queryParams[key] as string[]).push(filter);
  });

  return queryParams;
}

interface IGetFilterByOperators<T> {
  operators: FiltersOperators;
  values: T[];
  filter: Filter;
  maxItemsForMatchAll?: number;
}

enum FilterValueKey {
  Values = 'values',
  ExcludeValues = 'exclude_values',
}

export function getFilterByOperator<T>({
  operators,
  values,
  filter,
  maxItemsForMatchAll = MAX_ITEMS_FOR_MATCH_ALL,
}: IGetFilterByOperators<T>): ComplexFilter<T> {
  const isMatchingAll = getIsRequireAllValues(operators, filter);
  const isExcluding = getIsExcludingValues(operators, filter);

  const valuesKey = isExcluding ? FilterValueKey.ExcludeValues : FilterValueKey.Values;

  return {
    [valuesKey]: isMatchingAll ? values.slice(0, maxItemsForMatchAll) : values,
    require_every_value: isMatchingAll,
  };
}

export function prepareRoutingFilters<T>(property: keyof RoutingFilter, value: ComplexFilter<T>): WithProperties {
  return {
    properties: {
      routing: {
        [property]: value,
      },
    },
  };
}
