import { type IForm } from 'services/api/forms/interfaces';

import type { EntityFetchSuccess } from '../interfaces';

import { type FormsAction, FormsActionNames } from './actions';
import { type IFormsEntityState } from './interfaces';

export const initialState: IFormsEntityState = {};

/**
 * A Redux reducer function for managing form entities in the application state.
 * It handles actions for fetching and updating form entities, and ensures the state is always up-to-date and consistent.
 *
 * @param {IFormsEntityState} state - The current state of form entities. It's an object where each key is a form id and the value is the form data.
 * @param {FormsAction} action - The action dispatched. It should be of type `FETCH_SUCCESS` or `UPDATE_SUCCESS` with a payload containing the form id and data.
 *
 * @returns {IFormsEntityState} - The new state of form entities after applying the action. If the action type is unrecognized, it returns the current state.
 *
 * @example
 * ```
 * const action = {
 *   type: FormsActionNames.FETCH_SUCCESS,
 *   payload: { id: '1', value: { fields: [nameField, emailField] } },
 * };
 * const newState = formsReducer(currentState, action);
 * ```
 *
 * @remarks
 * This reducer function is a part of the Redux store and is used to manage the state of form entities. It ensures that the state is always consistent with the actions dispatched to it.
 *
 * @see `FormsActionNames` - The enum containing the action types that this reducer handles.
 * @see `IForm` - The interface defining the structure of a form entity.
 */
export function formsReducer(state: IFormsEntityState = initialState, action: FormsAction): IFormsEntityState {
  switch (action.type) {
    case FormsActionNames.FETCH_SUCCESS:
    case FormsActionNames.UPDATE_SUCCESS: {
      const { id, value } = action.payload as EntityFetchSuccess<IForm>;
      if (value) {
        return { ...state, [id]: { ...value } };
      }

      if (id in state) {
        const { [id]: removed, ...rest } = state;

        return rest;
      }

      return state;
    }

    default:
      return state;
  }
}
