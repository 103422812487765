import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadExists } from 'store/entities/chats/selectors';

import { deserializeAttachment } from '../serialization/deserialize-event';

export function handleIncomingChatAttachment(payload: any, store: IAppState): void {
  const { conference_id: threadId } = payload;
  const threadExists = getThreadExists(store.getState(), threadId);

  if (threadExists) {
    store.dispatch(
      ChatsEntitiesActions.newAttachmentMessage({
        threadId,
        file: deserializeAttachment(payload),
      })
    );
  }
}
