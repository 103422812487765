import { getServer } from 'helpers/get-server';
import { type FollowChatEvent } from 'interfaces/web-socket-events';
import { WebSocketAction } from 'constants/web-socket-action';

import { sendEvent } from './send-event';

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#follow-chat
 */
export async function followChat(chatId: string): Promise<unknown> {
  const event: FollowChatEvent = {
    action: WebSocketAction.FollowChat,
    payload: {
      id: chatId,
    },
    version: '3.5',
  };

  return await sendEvent<unknown>(getServer(), event);
}
