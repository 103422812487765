import { type ILicenseInfo } from 'interfaces/license-info';
import type { RequestFailure } from 'store/entities/interfaces';
import { createAction, type ActionsUnion } from 'store/helper';

import { type IChannelsActivity } from './interfaces';

export enum CodeInstallationActionsNames {
  ACTIVITY_FOUND = 'FEATURES/CODE_INSTALLATION/ACTIVITY_FOUND',
  CODE_FOUND = 'FEATURES/CODE_INSTALLATION/CODE_FOUND',
  PARSE_LICENSE_INFO = 'FEATURES/CODE_INSTALLATION/PARSE_LICENSE_INFO',
  SCAN_FOR_CODE = 'FEATURES/CODE_INSTALLATION/SCAN_FOR_CODE',
  FETCH_CHANNELS_ACTIVITY = 'FEATURES/CODE_INSTALLATION/FETCH_CHANNELS_ACTIVITY',
  FETCH_CHANNELS_ACTIVITY_SUCCESS = 'FEATURES/CODE_INSTALLATION/FETCH_CHANNELS_ACTIVITY_SUCCESS',
  FETCH_CHANNELS_ACTIVITY_FAILURE = 'FEATURES/CODE_INSTALLATION/FETCH_CHANNELS_ACTIVITY_FAILURE',
  RESET_ACTIVITY = 'FEATURES/CODE_INSTALLATION/RESET_ACTIVITY',
  CHECK_CODE_INSTALLATION = 'FEATURES/CODE_INSTALLATION/CHECK_CODE_INSTALLATION',
}

export const CodeInstallationActions = {
  activityFound: () => createAction(CodeInstallationActionsNames.ACTIVITY_FOUND),
  codeFound: () => createAction(CodeInstallationActionsNames.CODE_FOUND),
  scanForCode: () => createAction(CodeInstallationActionsNames.SCAN_FOR_CODE),
  parseLicenseInfo: (account: ILicenseInfo | null) =>
    createAction(CodeInstallationActionsNames.PARSE_LICENSE_INFO, { account }),
  fetchChannelsActivity: () => createAction(CodeInstallationActionsNames.FETCH_CHANNELS_ACTIVITY),
  fetchChannelsActivitySuccess: (channelsActivity: IChannelsActivity) =>
    createAction(CodeInstallationActionsNames.FETCH_CHANNELS_ACTIVITY_SUCCESS, { channelsActivity }),
  fetchChannelsActivityFailure: (error: RequestFailure) =>
    createAction(CodeInstallationActionsNames.FETCH_CHANNELS_ACTIVITY_FAILURE, { error }),
  resetActivity: () => createAction(CodeInstallationActionsNames.RESET_ACTIVITY),
  checkCodeInstallation: () => createAction(CodeInstallationActionsNames.CHECK_CODE_INSTALLATION),
};

export type CodeInstallationActionTypes = ActionsUnion<typeof CodeInstallationActions>;
