// @ts-strict-ignore
import { toKeyMap } from 'helpers/array';
import { type IActionWithPayload } from 'store/helper';

import type { EntityFetchCollectionSuccess } from '../interfaces';

import { type AutoAccessRuleAction, AutoAccessRulesActionNames, type AutoAccessRulesActions } from './actions';
import { type IAutoAccessRule, type IAutoAccessRulesEntityState } from './interfaces';

export const initialState: IAutoAccessRulesEntityState = {
  autoAccessRules: {},
};

export function autoAccessRulesReducer(
  state: IAutoAccessRulesEntityState = initialState,
  action: AutoAccessRuleAction
): IAutoAccessRulesEntityState {
  switch (action.type) {
    case AutoAccessRulesActionNames.SET_COLLECTION: {
      const { payload } = action as ReturnType<typeof AutoAccessRulesActions.setCollection>;

      return {
        ...state,
        autoAccessRules: {
          ...payload,
        },
      };
    }

    case AutoAccessRulesActionNames.SET: {
      const { payload } = action as ReturnType<typeof AutoAccessRulesActions.set>;

      return {
        ...state,
        autoAccessRules: {
          ...state.autoAccessRules,
          [payload.id]: {
            ...payload,
          },
        },
      };
    }

    case AutoAccessRulesActionNames.FETCH_COLLECTION_SUCCESS: {
      const { payload } = action as IActionWithPayload<string, EntityFetchCollectionSuccess<IAutoAccessRule>>;

      return {
        ...state,
        autoAccessRules: toKeyMap(payload.values, 'id'),
      };
    }

    case AutoAccessRulesActionNames.CREATE_SUCCESS: {
      const { payload } = action as ReturnType<typeof AutoAccessRulesActions.createSuccess>;
      const values = Object.values(state.autoAccessRules);
      if (values.length === 0) {
        return {
          ...state,
          autoAccessRules: {
            [payload.id]: {
              ...payload.value,
              id: String(payload.id),
              nextId: undefined,
            },
          },
        };
      }

      const lastRule = values.find(({ nextId }) => nextId === undefined);

      return {
        ...state,
        autoAccessRules: {
          ...state.autoAccessRules,
          [lastRule.id]: {
            ...state.autoAccessRules[lastRule.id],
            nextId: String(payload.id),
          },
          [payload.id]: {
            ...payload.value,
            id: String(payload.id),
            nextId: undefined,
          },
        },
      };
    }

    case AutoAccessRulesActionNames.DELETE_SUCCESS: {
      const { payload } = action as ReturnType<typeof AutoAccessRulesActions.deleteSuccess>;
      const { [payload.id]: removedRule, ...restRules } = state.autoAccessRules;
      const currentPredecessor = Object.values(restRules).find(({ nextId }) => nextId === payload.id);

      return {
        ...state,
        autoAccessRules: {
          ...restRules,
          ...(!!currentPredecessor && {
            [currentPredecessor.id]: {
              ...currentPredecessor,
              nextId: state.autoAccessRules[payload.id].nextId,
            },
          }),
        },
      };
    }

    case AutoAccessRulesActionNames.UPDATE_SUCCESS: {
      const { payload } = action as ReturnType<typeof AutoAccessRulesActions.updateSuccess>;

      return {
        ...state,
        autoAccessRules: {
          ...state.autoAccessRules,
          [payload.id]: {
            ...state.autoAccessRules[payload.id],
            ...payload.value,
            nextId: state.autoAccessRules[payload.id].nextId,
          },
        },
      };
    }

    case AutoAccessRulesActionNames.REORDER: {
      const { payload } = action as ReturnType<typeof AutoAccessRulesActions.reorder>;
      const targetRule = state.autoAccessRules[payload.id];
      const values = Object.values(state.autoAccessRules);

      if (targetRule.nextId === payload.nextId || values.length < 2) {
        return {
          ...state,
        };
      }

      const currentRredecessor = values.find(({ nextId }) => nextId === payload.id);
      const newRredecessor = values.find(({ nextId }) => nextId === payload.nextId);

      return {
        ...state,
        autoAccessRules: {
          ...state.autoAccessRules,
          [targetRule.id]: {
            ...state.autoAccessRules[targetRule.id],
            nextId: payload.nextId,
          },
          ...(currentRredecessor && {
            [currentRredecessor.id]: {
              ...state.autoAccessRules[currentRredecessor.id],
              nextId: targetRule.nextId,
            },
          }),
          ...(newRredecessor && {
            [newRredecessor.id]: {
              ...state.autoAccessRules[newRredecessor.id],
              nextId: targetRule.id,
            },
          }),
        },
      };
    }

    default:
      return state;
  }
}
