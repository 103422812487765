// @ts-strict-ignore
import { ProductCartActionNames, type ProductCartActionTypes } from './actions';
import {
  getStateForConfirmApplicationSuccess,
  getStateForSaveCurrentCart,
  getStateForRemoveApplicationSuccess,
  getStateForAddApplicationSuccess,
  getStateForConfirmAllApplicationSuccess,
} from './helpers/reducer';
import { type IProductCartState } from './interfaces';

export const initialState: IProductCartState = {
  currentCart: null,
};

export const productCartReducer = (
  state: IProductCartState = initialState,
  action: ProductCartActionTypes
): IProductCartState => {
  switch (action.type) {
    case ProductCartActionNames.SAVE_CURRENT_CART: {
      return getStateForSaveCurrentCart(state, action.payload);
    }

    case ProductCartActionNames.ADD_APPLICATION_SUCCESS: {
      return getStateForAddApplicationSuccess(state, action.payload);
    }

    // TODO: this should be changed to saga when selectiong apps to buy will be implemented on backend side
    case ProductCartActionNames.CONFIRM_APPLICATION: {
      return getStateForConfirmApplicationSuccess(state, action.payload);
    }

    case ProductCartActionNames.MARKETPLACE_CHECKOUT_PROCESS_STARTED: {
      return getStateForConfirmAllApplicationSuccess(state);
    }

    case ProductCartActionNames.REMOVE_APPLICATION_SUCCESS: {
      return getStateForRemoveApplicationSuccess(state, action.payload);
    }

    default:
      return state;
  }
};
