import { isBefore, startOfDay } from 'date-fns';
import { type SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';

import { CopilotViewActions } from 'store/views/copilot/actions';
import { getIsCopilotModalOpened } from 'store/views/copilot/selectors';

import { getAccountCreateDate } from '../accounts/selectors';
import { getLoggedInAgentAccountId } from '../agents/selectors';

import { COPILOT_POPOVER_RELEASE_DATE } from './constants';

export function* isAccountCreatedBeforeCopilotPopoverRelease(): SagaIterator {
  const currentAgentId: string = yield select(getLoggedInAgentAccountId);
  const agentCreationDate: string = yield select(getAccountCreateDate, currentAgentId);

  if (!agentCreationDate) {
    return false;
  }

  const sectionReleaseDate = startOfDay(new Date(COPILOT_POPOVER_RELEASE_DATE));

  return isBefore(agentCreationDate, sectionReleaseDate);
}

export function* setUnreadMessagesCount(payload?: number): SagaIterator {
  const isCopilotModalOpened = yield select(getIsCopilotModalOpened);

  if (!isCopilotModalOpened) {
    yield put(CopilotViewActions.increaseCopilotUnreadMessagesCount(payload ?? 1));
  }
}
