import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

import { resetLegacyLcBorderBox } from 'styles/reset-border-box';

export const modal = css`
  ${resetLegacyLcBorderBox};
  width: 600px;
`;

export const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(${SpacingToken.Spacing0}) var(${SpacingToken.Spacing8}) var(${SpacingToken.Spacing5});

  h3 {
    margin-top: var(${SpacingToken.Spacing4});
    margin-bottom: var(${SpacingToken.Spacing3});
  }
`;

export const icon = css`
  svg {
    height: 48px;
    width: 48px;
  }
`;

export const footer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  padding-bottom: var(${SpacingToken.Spacing10});
`;
