import { type FC } from 'react';

import { Avatar, Text } from '@livechat/design-system-react-components';

import { COPILOT_LOGO, COPILOT_NO_AI_AGENTS_MESSAGE } from 'constants/copilot';

import { Suggestions } from './suggestions/Suggestions';

import * as styles from './styles';

export const CopilotFullscreenNoAIAgentsScreen: FC = () => {
  return (
    <div className={styles.emptyState}>
      <div className={styles.emptyStateContainer}>
        <Avatar type="image" src={COPILOT_LOGO} size="xxlarge" />
        <div className={styles.emptyStateText}>
          <Text className={styles.emptyStateHeader}>{COPILOT_NO_AI_AGENTS_MESSAGE.header}</Text>
          <p className={styles.emptyStateDescription}>{COPILOT_NO_AI_AGENTS_MESSAGE.text}</p>
        </div>
      </div>
      <Suggestions />
    </div>
  );
};
