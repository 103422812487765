import { UAParser } from 'ua-parser-js';

export enum DeviceType {
  IPad = 'ipad',
  IPhone = 'iphone',
  Windows = 'win',
  Linux = 'linux',
  Mac = 'mac',
  Android = 'android',
}

export const getDeviceTypeByUserAgent = (userAgent?: string): DeviceType | undefined => {
  if (!userAgent) {
    return undefined;
  }

  const uaparser = new UAParser(userAgent);
  const { name: os } = uaparser.getOS();

  switch (os) {
    case 'Android':
      return DeviceType.Android;
    case 'iOS':
      if (uaparser.getDevice().type === 'tablet') {
        return DeviceType.IPad;
      }

      return DeviceType.IPhone;

    case 'Mac OS':
      return DeviceType.Mac;

    case 'Linux':
    case 'Ubuntu':
    case 'Debian':
    case 'Fedora':
    case 'CentOS':
      return DeviceType.Linux;

    case 'Windows':
      return DeviceType.Windows;

    default:
      return undefined;
  }
};

// used e.g to determine device type of a customer
export const isMobileDeviceByUserAgent = (userAgent?: string): boolean => {
  const deviceType = getDeviceTypeByUserAgent(userAgent);

  return !!deviceType && [DeviceType.Android, DeviceType.IPad, DeviceType.IPhone].includes(deviceType);
};

export const getOperatingSystemByUserAgent = (userAgent?: string): UAParser.IOS | undefined => {
  if (!userAgent) {
    return undefined;
  }

  const uaparser = new UAParser(userAgent);

  return uaparser.getOS();
};

export const getBrowserByUserAgent = (userAgent?: string): UAParser.IBrowser | undefined => {
  if (!userAgent) {
    return undefined;
  }

  const uaparser = new UAParser(userAgent);

  return uaparser.getBrowser();
};
