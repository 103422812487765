// @ts-strict-ignore
import { format } from 'date-fns';
import property from 'lodash.property';

import { DateFormat } from 'constants/date';
import { categories12hFromApi, HEATMAP_NULL_CELL_COLOR } from 'constants/reports/heatmap';
import { ReportType } from 'constants/reports/report-type';
import { type KeyMap } from 'helpers/interface';
import type { IHeatmapData, IHeatmapSingleData, ILegacyHeatmapData } from 'interfaces/reports';

import { REPORT_TOTAL_CHATS_SUBPATH } from '../constants';

const heatmapKeys = {
  [ReportType.TotalChatsHeatmap]: REPORT_TOTAL_CHATS_SUBPATH,
  [ReportType.MissedChatsHeatmap]: REPORT_TOTAL_CHATS_SUBPATH,
  [ReportType.NewTicketsHeatmap]: 'new_tickets',
  [ReportType.QueuedCustomersHeatmap]: ['queued', 'count'],
};

type HoursInDay = {
  [K in (typeof categories12hFromApi)[number]]: unknown;
};

const hoursInDay = categories12hFromApi.reduce((acc, hour) => {
  acc[hour] = {};

  return acc;
}, {} as KeyMap<HoursInDay>);

export function heatmapLegacyDeserializer<T = any>(
  days: ILegacyHeatmapData<T>[],
  reportType: ReportType,
): IHeatmapData {
  const chartLabels = days.map((element) => element.date);
  const chartSeries = days.reduce<IHeatmapSingleData[]>(
    (acc, day, dayIndex) =>
      acc.concat(
        Object.values(day.data).map((hourData: any, hourIndex: number, hours): IHeatmapSingleData => {
          const heatmapValue = (property(heatmapKeys[reportType])(hourData) || 0) as number;

          return {
            x: dayIndex,
            y: hours.length - hourIndex - 1,
            value: heatmapValue,
            color: heatmapValue === 0 ? HEATMAP_NULL_CELL_COLOR : '',
          };
        }),
      ),
    [],
  );

  return {
    chartSeries,
    chartLabels,
  };
}

export function heatmapDeserializer(dayHours: KeyMap<unknown>, reportType: ReportType): IHeatmapData {
  const daysMap: KeyMap<HoursInDay> = {};

  Object.keys(dayHours).forEach((dayHour) => {
    const data = dayHours[dayHour];
    const dateKey = format(new Date(dayHour), DateFormat.ISO8601Date);
    const timeKey = format(new Date(dayHour), DateFormat.Time12HourWithoutSeconds);

    if (!daysMap[dateKey]) {
      daysMap[dateKey] = { ...hoursInDay };
    }

    if (daysMap[dateKey][timeKey]) {
      daysMap[dateKey][timeKey] = data;
    }
  });

  const days = Object.values(daysMap);
  const chartLabels = Object.keys(daysMap);
  const chartSeries = days.reduce((acc: IHeatmapSingleData[], day, dayIndex) => {
    const dayHours = Object.values(day);

    dayHours.forEach((hourData, hourIndex) => {
      const heatmapValue = (property(heatmapKeys[reportType])(hourData) || 0) as number;

      acc.push({
        x: dayIndex,
        y: hourIndex,
        value: heatmapValue,
        color: !heatmapValue ? HEATMAP_NULL_CELL_COLOR : '',
      });
    });

    return acc;
  }, []);

  return {
    chartSeries,
    chartLabels,
  };
}
