import { type SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { GroupProperty } from 'interfaces/entities/group-property';
import { ApiManager } from 'services/api/api-manager';
import { type ILanguagesResult } from 'services/api/languages/interfaces';
import { GroupActions } from 'store/entities/groups/actions';
import { getGroupLanguageCode } from 'store/entities/groups/selectors';
import { type IActionWithPayload } from 'store/helper';
import { SettingsViewActionNames } from 'store/views/settings/actions';
import { getSelectedGroupId } from 'store/views/settings/selectors';
import { WidgetSnapshotActions } from 'store/views/widget/actions';

import type {
  FetchLanguagePayload,
  SetLanguageDataPayload,
  UpdateLanguagePayload,
  SaveLanguagePayload,
} from '../../../interfaces/language';

import { LanguagesActionNames, LanguagesActions } from './actions';
import { deserializeLanguage } from './serializers';

function* fetchCurrentGroupLanguage(): SagaIterator {
  const groupId: ReturnType<typeof getSelectedGroupId> = yield select(getSelectedGroupId);
  const languageCode = yield select(getGroupLanguageCode, groupId);
  yield put(LanguagesActions.fetchLanguage({ groupId, languageCode }));
}

function* fetchLanguage({ payload }: IActionWithPayload<string, FetchLanguagePayload>): SagaIterator {
  const { languageCode, groupId } = payload;
  const { languagesApi } = ApiManager;

  const { result }: RequestResult<ILanguagesResult> = yield call(languagesApi.fetchLanguage, languageCode, groupId);

  yield put(LanguagesActions.fetchLanguageSuccess({ language: deserializeLanguage(result, languageCode), groupId }));
}

function* setLanguage({ payload }: IActionWithPayload<string, SetLanguageDataPayload>): SagaIterator {
  const { language, groupId } = payload;
  yield put(LanguagesActions.setLanguage({ language, groupId }));
  yield put(WidgetSnapshotActions.setSnapshot({ localization: language.phrases }));
}

function* saveLanguage({ payload }: IActionWithPayload<string, SaveLanguagePayload>): SagaIterator {
  const { languageCode, phrases, groupId, languageToStore } = payload;
  const { languagesApi, groupApi } = ApiManager;
  const { error: languageError }: RequestResult<void> = yield call(
    languagesApi.saveLanguage,
    languageCode,
    phrases,
    groupId
  );

  if (!languageError) {
    const { error: groupPropError }: RequestResult<void> = yield call(groupApi.saveGroupProperties, groupId, {
      [GroupProperty.Language]: languageCode,
    });

    if (!groupPropError) {
      yield put(LanguagesActions.saveLanguageSuccess({ languageCode, phrases, groupId, languageToStore }));

      return;
    }
  }

  yield put(LanguagesActions.saveLanguageFailure({ languageCode, phrases, groupId }));
}

function* setNewGroupLanguage({ payload }: IActionWithPayload<string, SaveLanguagePayload>): SagaIterator {
  const { languageCode, groupId, languageToStore } = payload;
  yield put(
    GroupActions.groupUpdated({
      id: groupId,
      language: languageCode,
    })
  );

  yield put(LanguagesActions.setLanguage({ language: languageToStore, groupId }));
}

function* updateLanguage({ payload }: IActionWithPayload<string, UpdateLanguagePayload>): SagaIterator {
  const { languageCode, phrases, groupId } = payload;

  const { languagesApi } = ApiManager;
  const { error }: RequestResult<void> = yield call(languagesApi.saveLanguage, languageCode, phrases, groupId);

  if (error) {
    yield put(LanguagesActions.updateLanguageFailure({ languageCode, phrases, groupId }));
  } else {
    yield put(LanguagesActions.updateLanguageSuccess({ languageCode, phrases, groupId }));
  }
}

export function* languagesSagas(): SagaIterator {
  yield takeEvery([SettingsViewActionNames.SET_SELECTED_GROUP_ID], fetchCurrentGroupLanguage);
  yield takeEvery(LanguagesActionNames.FETCH_LANGUAGE, fetchLanguage);
  yield takeEvery(LanguagesActionNames.FETCH_LANGUAGE_SUCCESS, setLanguage);
  yield takeEvery(LanguagesActionNames.SAVE_LANGUAGE, saveLanguage);
  yield takeEvery(LanguagesActionNames.SAVE_LANGUAGE_SUCCESS, setNewGroupLanguage);
  yield takeEvery(LanguagesActionNames.UPDATE_LANGUAGE, updateLanguage);
}
