import { css, keyframes } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { TOP_BAR_HEIGHT } from 'components/notifications-bar/constants';
import { CONTENT_PAGE_MARGIN } from 'constants/spotlight';

import {
  COLLAPSE_ANIMATION_DURATION,
  EXPAND_ANIMATION_DURATION,
  COPILOT_ANIMATION_TIMING_FUNCTION,
  COPILOT_DARK_BOX_SHADOW,
  COPILOT_LIGHT_BOX_SHADOW,
} from './constants';

const glowLight = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
    box-shadow: none;
  }
  12.5% {
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 -4px 22px 1px rgba(0, 102, 255, 0.3), 0 12px 32px 1px rgba(113, 111, 255, 0.6), inset 0 0 0 1px #9146FF;
  }
  50% {
    box-shadow: 0 -4px 22px 1px rgba(0, 102, 255, 0.3), 0 12px 36px 1px rgba(113, 111, 255, 0.75), inset 0 0 0 1px #9146FF
  }
  65% {
    box-shadow: 0 -2px 24px 1px rgba(0, 102, 255, 0.6), 0 12px 32px 1px rgba(113, 111, 255, 0.4), inset 0 0 0 1px #0066FF;
  }
  95%, to {
    box-shadow: ${COPILOT_LIGHT_BOX_SHADOW};
  }
`;

const glowDark = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
    box-shadow: none;
  }
  12.5% {
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 0 3px 0 var(${DesignToken.BorderBasicPurple}) inset, 0 -4px 30 1px rgba(0, 102, 255, 0.30), 0 12px 32px 1px rgba(145, 70, 255, 0.60);
  }
  50% {
    box-shadow: 0 0 3px 0 var(${DesignToken.BorderBasicInfo}) inset, 0 -8px 32px 1px rgba(50, 141, 255, 0.40), 0 12px 32px 1px rgba(145, 70, 255, 0.30);
  }
  75% {
    box-shadow: 0 0 2px 0 var(${DesignToken.BorderBasicInfo}) inset, 0 -8px 32px 1px rgba(50, 141, 255, 0.20), 0 12px 32px 1px rgba(145, 70, 255, 0.15);
  }
  85% {
    box-shadow: 0 4px 16px 1px hsla(0, 0%, 0%, 0.5), 0 0 0 1px hsla(0, 0%, 100%, 0.15) inset, 0 0 2px 1px hsla(0, 0%, 0%, 0.15), 0 12px 32px 1px hsla(264, 100%, 64%, 0.1), 0 -8px 28px 1px hsla(213, 100%, 60%, 0.2);
  }
  95%, to {
    box-shadow: ${COPILOT_DARK_BOX_SHADOW};
  }
`;

export const glowLightAnimation = css`
  animation: ${glowLight} 1.2s ease-in 1;
`;

export const glowDarkAnimation = css`
  animation: ${glowDark} 1.2s linear 1;
`;

const expand = (height: number): string => keyframes`
  from {
    width: 360px;
    height: ${height}px;
  }

  to {
    max-height: calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px);
    width: calc(100% - 54px - ${CONTENT_PAGE_MARGIN}px);
  }
`;

const expandContent = keyframes`
  from {
    width: 360px;
    opacity: 1;
    display: flex;
    align-items: right;
    opacity: 0;
  }

  35% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  to {
    width: 100%;
  }
`;

const expandChatContent = keyframes`
  from {
    overflow: hidden;
  }

  90% {
    overflow: hidden;
  }

  to {
    overflow: auto;
  }
`;

const collapse = keyframes`
  from {
    opacity: 0;
  }

  35% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`;

export const expandAnimation = (height: number): string => css`
  animation: ${expand(height)} ${EXPAND_ANIMATION_DURATION}s ${COPILOT_ANIMATION_TIMING_FUNCTION} 1;
`;

export const expandContentAnimation = css`
  animation: ${expandContent} ${EXPAND_ANIMATION_DURATION}s ${COPILOT_ANIMATION_TIMING_FUNCTION} 1;
`;

export const expandChatContentAnimation = css`
  animation: ${expandChatContent} ${EXPAND_ANIMATION_DURATION}s ${COPILOT_ANIMATION_TIMING_FUNCTION} 1;
`;

export const collapseAnimation = css`
  animation: ${collapse} ${COLLAPSE_ANIMATION_DURATION}s cubic-bezier(0.2, -0.01, 0.14, 0.62) 1;
`;
