import { ReportType } from 'constants/reports/report-type';
import { createAction } from 'store/helper';

import { RequestAction } from '../actions';

import {
  type ICreateReportFailurePayload,
  type ICreateReportPayload,
  type ICreateReportSuccessPayload,
  type ICreateTicketFromQueueAbandonmentFailurePayload,
  type ICreateTicketFromQueueAbandonmentPayload,
  type ICreateTicketFromQueueAbandonmentSuccessPayload,
  type IDeleteReportFailurePayload,
  type IDeleteReportPayload,
  type IDeleteReportSuccessPayload,
  type IEditReportFailurePayload,
  type IEditReportPayload,
  type IEditReportSuccessPayload,
  type IFetchReportFailurePayload,
  type IFetchReportPayload,
  type IFetchReportSuccessPayload,
} from './interfaces';

type IReportActionNames = { [key in ReportType]: { [key in RequestAction]: string } };

export const createSingleReportRequestTypeBase = (reportName: string) =>
  `ENTITIES/REPORT/FETCH_${reportName.replace(/-/g, '_').toUpperCase()}`;

const createSingleRequestTypes = (actionBase: string) =>
  [RequestAction.REQUEST, RequestAction.SUCCESS, RequestAction.FAILURE].reduce(
    (acc, type) => ({
      ...acc,
      [type]: `${actionBase}_${type}`,
    }),
    {}
  );

export const REPORT: IReportActionNames = Object.keys(ReportType).reduce(
  (acc, reportName) => ({
    ...acc,
    [ReportType[reportName]]: createSingleRequestTypes(createSingleReportRequestTypeBase(ReportType[reportName])),
  }),
  {}
) as IReportActionNames;

export enum ReportsEntitiesActionsNames {
  CREATE_REPORT = 'ENTITIES/REPORT/CREATE_REPORT',
  EDIT_SCHEDULED_REPORT = 'ENTITIES/REPORT/EDIT_SCHEDULED_REPORT',
  DELETE_SCHEDULED_REPORT = 'ENTITIES/REPORT/DELETE_SCHEDULED_REPORT',
  CREATE_TICKET_FROM_QUEUE_ABANDONMENT = 'ENTITIES/CREATE_TICKET_FROM_QUEUE_ABANDONMENT',
}

export const CreateReportActionNames = createSingleRequestTypes(ReportsEntitiesActionsNames.CREATE_REPORT);
export const EditScheduledReportActionNames = createSingleRequestTypes(
  ReportsEntitiesActionsNames.EDIT_SCHEDULED_REPORT
);
export const DeleteScheduledReportActionNames = createSingleRequestTypes(
  ReportsEntitiesActionsNames.DELETE_SCHEDULED_REPORT
);
export const CreateTicketFromQueueAbandonmentActionNames = createSingleRequestTypes(
  ReportsEntitiesActionsNames.CREATE_TICKET_FROM_QUEUE_ABANDONMENT
);

export const ReportActions = {
  fetch: (payload: IFetchReportPayload) =>
    REPORT[payload.reportType] ? createAction(REPORT[payload.reportType][RequestAction.REQUEST], payload) : null,

  fetchHeatMap: (payload: IFetchReportPayload) =>
    REPORT[payload.reportType] ? createAction(REPORT[payload.reportType][RequestAction.REQUEST], payload) : null,

  fetchSuccess: <TData>(payload: IFetchReportSuccessPayload<TData>) =>
    createAction(REPORT[payload.reportType][RequestAction.SUCCESS], payload),

  fetchFailure: (payload: IFetchReportFailurePayload) =>
    createAction(REPORT[payload.reportType][RequestAction.FAILURE], payload),

  createReport: (payload: ICreateReportPayload) =>
    createAction(CreateReportActionNames[RequestAction.REQUEST], payload),

  createReportSuccess: (payload: ICreateReportSuccessPayload) =>
    createAction(CreateReportActionNames[RequestAction.SUCCESS], payload),

  createReportFailure: (payload: ICreateReportFailurePayload) =>
    createAction(CreateReportActionNames[RequestAction.FAILURE], payload),

  editScheduledReport: (payload: IEditReportPayload) =>
    createAction(EditScheduledReportActionNames[RequestAction.REQUEST], payload),

  editScheduledReportSuccess: (payload: IEditReportSuccessPayload) =>
    createAction(EditScheduledReportActionNames[RequestAction.SUCCESS], payload),

  editScheduledReportFailure: (payload: IEditReportFailurePayload) =>
    createAction(EditScheduledReportActionNames[RequestAction.FAILURE], payload),

  deleteScheduledReport: (payload: IDeleteReportPayload) =>
    createAction(DeleteScheduledReportActionNames[RequestAction.REQUEST], payload),

  deleteScheduledReportSuccess: (payload: IDeleteReportSuccessPayload) =>
    createAction(DeleteScheduledReportActionNames[RequestAction.SUCCESS], payload),

  deleteScheduledReportFailure: (payload: IDeleteReportFailurePayload) =>
    createAction(DeleteScheduledReportActionNames[RequestAction.FAILURE], payload),

  createTicketFromQueueAbandonment: (payload: ICreateTicketFromQueueAbandonmentPayload) =>
    createAction(CreateTicketFromQueueAbandonmentActionNames[RequestAction.REQUEST], payload),

  createTicketFromQueueAbandonmentSuccess: (ticketId: ICreateTicketFromQueueAbandonmentSuccessPayload) =>
    createAction(CreateTicketFromQueueAbandonmentActionNames[RequestAction.SUCCESS], ticketId),

  createTicketFromQueueAbandonmentFailure: (payload: ICreateTicketFromQueueAbandonmentFailurePayload) =>
    createAction(CreateTicketFromQueueAbandonmentActionNames[RequestAction.FAILURE], payload),
};
