// @ts-strict-ignore
const INPUT_TAG_NAMES = ['input', 'textarea'];

export const getIsInputFocused = (): boolean => {
  const { activeElement } = document;

  if (!activeElement) {
    return;
  }

  const isContenteditableElement = activeElement.hasAttribute('contenteditable');

  return INPUT_TAG_NAMES.includes(activeElement.tagName.toLowerCase()) || isContenteditableElement;
};
