import { type Middleware } from 'redux';

import { type IActionWithPayload } from 'store/helper';

export type ActionWithPayload = IActionWithPayload<string, unknown>;

type Options = {
  accessHistory?: {
    actionType: string;
    callback: (history: ActionWithPayload[]) => unknown;
  }
  historyLimit?: number;
  logActions?: boolean;
}

export const createActionHistory = ({
  historyLimit = 150,
  logActions = false,
  accessHistory,
}: Options): Middleware => {
  const actionsHistory: ActionWithPayload[] = [];

  return () => (next) => (action: ActionWithPayload) => {
    if (logActions) {
      /* eslint-disable no-console */
      console.groupCollapsed('action', action.type, );
      console.log(action);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    actionsHistory.push(action);

    if (actionsHistory.length > historyLimit) {
      actionsHistory.shift();
    }

    if (accessHistory?.actionType === action.type) {
      accessHistory.callback(actionsHistory);
    }

    return next(action);
  };
};
