import { useSelector } from 'react-redux';

import { type ISubscription } from 'interfaces/subscription';
import { getSubscription } from 'store/entities/subscription/selectors';

type UseSubscription = {
  data: ISubscription | null;
};

export const useSubscription = (): UseSubscription => {
  const subscription = useSelector(getSubscription);

  return {
    data: subscription,
  };
};
