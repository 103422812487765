// @ts-strict-ignore
import { getHours, getMinutes } from 'date-fns';

import type { Day } from 'constants/day';
import { WorkWeek } from 'constants/work-week';
import type {
  BreakTimeSchedule,
  DaySchedule,
  TimeSchedule,
  WorkSchedule,
  WorkScheduleDays,
} from 'interfaces/entities/work-schedule';
import type { TimeRange } from 'interfaces/time-range';
import type { FormDaySchedule, WeekSchedule, WeekSchedulerFormValues } from 'interfaces/work-scheduler';
import type { WorkSchedulerDTO } from 'services/api/common/agent-base/interfaces';
import { type WorkScheduler } from 'services/connectivity/configuration-api/agents/types';

import type { DayScheduleBody, IWorkSchedulerBody, SerializedDaySchedule } from './interfaces';

const DEFAULT_START_HOUR = '08:00';
const DEFAULT_END_HOUR = '16:00';

export abstract class WorkScheduleSerializer {
  static getDefaultWorkHours(enabled = true): DaySchedule {
    return {
      isEnabled: enabled,
      data: [
        {
          startHours: {
            hour: 8,
            minute: 0,
          },
          endHours: {
            hour: 16,
            minute: 0,
          },
        },
      ],
    };
  }

  /**
   * Provides default values if data to deserialize lacks some data. It can be also used to provide default data for forms.
   * @param enabled Defines if work schedule should be enabled by default.
   */
  static getDefaults(enabled = false): WorkSchedule {
    const defaults = {
      enabled,
    };

    WorkWeek.forEach((day: Day) => {
      defaults[day] = WorkScheduleSerializer.getDefaultWorkHours(enabled);
    });

    return defaults as WorkSchedule;
  }

  static getEmptyWorkSchedulerBody(enabled = false): IWorkSchedulerBody {
    const emptyWorkSchedulerBody = WorkWeek.reduce((acc, day) => {
      acc[day] = {
        enabled: Number(enabled),
        data: [],
      };

      return acc;
    }, {} as IWorkSchedulerBody);

    return emptyWorkSchedulerBody;
  }

  private static parseTime(time: string): TimeSchedule {
    return {
      hour: parseInt(time.slice(0, 2), 10),
      minute: parseInt(time.slice(3, 5), 10),
    };
  }

  private static formatTime(time: TimeSchedule) {
    return `${String(time.hour).padStart(2, '0')}:${String(time.minute).padStart(2, '0')}`;
  }

  private static deserializeEnabled(data: IWorkSchedulerBody): boolean {
    return Object.keys(data).some((day: Day) => data[day].enabled);
  }

  private static deserializeDay(data: DayScheduleBody): DaySchedule {
    if (!data.enabled) {
      return {
        isEnabled: Boolean(data.enabled),
        data: data.data.map((period) => {
          const isLegacyDefault = period.start === '00:00' && period.end === '00:00';

          return {
            startHours: WorkScheduleSerializer.parseTime(isLegacyDefault ? DEFAULT_START_HOUR : period.start),
            endHours: WorkScheduleSerializer.parseTime(isLegacyDefault ? DEFAULT_END_HOUR : period.end),
          };
        }),
      };
    }

    const periods = data.data.map((period) => {
      const isLegacyDefault = period.start === '00:00' && period.end === '00:00';

      return {
        startHours: WorkScheduleSerializer.parseTime(isLegacyDefault ? DEFAULT_START_HOUR : period.start),
        endHours: WorkScheduleSerializer.parseTime(isLegacyDefault ? DEFAULT_END_HOUR : period.end),
      };
    });

    return {
      isEnabled: !!data.enabled,
      data: periods,
    };
  }

  private static deserializeDays(data: IWorkSchedulerBody): WorkScheduleDays {
    const days = Object.keys(data).reduce((acc, day: Day) => {
      if (WorkWeek.includes(day)) {
        acc[day] = WorkScheduleSerializer.deserializeDay(data[day]);

        return acc;
      }

      return acc;
    }, {} as WorkScheduleDays);

    return days;
  }

  /**
   * Deserializes work schedule data provided by API.
   * @param data Agent data provided in REST API structure.
   */
  static deserializeLegacyFormat(data: IWorkSchedulerBody): WorkSchedule {
    const hasAllDayKeys = data && WorkWeek.every((day) => Object.keys(data).some((dayKey) => (dayKey as Day) === day));

    if (!hasAllDayKeys) {
      return WorkScheduleSerializer.getDefaults();
    }

    return {
      enabled: WorkScheduleSerializer.deserializeEnabled(data),
      ...WorkScheduleSerializer.deserializeDays(data),
    };
  }

  static deserialize(data?: WorkScheduler): WorkSchedule {
    if (!data) {
      return WorkScheduleSerializer.getDefaults();
    }

    const emptyWorkSchedulerBody = WorkScheduleSerializer.getEmptyWorkSchedulerBody();

    const workSchedulerBody = data.schedule.reduce((acc, daySchedule) => {
      const { day, start, end, enabled } = daySchedule;

      acc[day].data.push({
        start,
        end,
      });
      acc[day].enabled = Number(enabled);

      return acc;
    }, emptyWorkSchedulerBody);

    return {
      enabled: data.schedule.some((daySchedule) => daySchedule.enabled),
      timezone: data?.timezone,
      ...WorkScheduleSerializer.deserializeDays(
        WorkScheduleSerializer.populateEmptyDaysWithDefaults(workSchedulerBody)
      ),
    };
  }

  private static populateEmptyDaysWithDefaults(workSchedulerBody: IWorkSchedulerBody): IWorkSchedulerBody {
    return Object.keys(workSchedulerBody).reduce((acc, day: Day) => {
      if (workSchedulerBody[day].data.length === 0) {
        acc[day].data.push({
          start: DEFAULT_START_HOUR,
          end: DEFAULT_END_HOUR,
        });
        acc[day].enabled = 0;
      }

      return acc;
    }, workSchedulerBody);
  }

  private static serializeDay(schedule: DaySchedule, enabled: boolean, day: Day): SerializedDaySchedule[] {
    return schedule.data.map((item) => ({
      start: WorkScheduleSerializer.formatTime(item.startHours),
      end: WorkScheduleSerializer.formatTime(item.endHours),
      enabled,
      day,
    }));
  }

  private static serializeDays({ enabled, timezone, ...days }: WorkSchedule): SerializedDaySchedule[] {
    return Object.keys(days).flatMap((day: Day) =>
      WorkScheduleSerializer.serializeDay(days[day], days[day].isEnabled, day)
    );
  }

  /**
   * Serializes client data into structure required by API.
   * @param data Client structure to be transformed into API structure.
   */
  static serialize(data: WorkSchedule): WorkSchedulerDTO {
    return {
      schedule: WorkScheduleSerializer.serializeDays(data),
      timezone: '', // In first iteration we don't support timezones dropdown, by passing empty value api will use current agent timezone
    };
  }

  private static serializeFormSingleDay(day: FormDaySchedule): DayScheduleBody {
    if (day.data.length === 0) {
      return {
        enabled: 0,
        data: [{ start: DEFAULT_START_HOUR, end: DEFAULT_END_HOUR }],
      };
    }

    const data = day.data.map((range: TimeRange) => ({
      start: WorkScheduleSerializer.formatTime({ hour: getHours(range.from), minute: getMinutes(range.from) }),
      end: WorkScheduleSerializer.formatTime({ hour: getHours(range.to), minute: getMinutes(range.to) }),
      day,
    }));

    return {
      enabled: Number(day.enabled),
      data,
    };
  }

  /**
   * Serializes work scheduler form data into structure required by API.
   * @param data Work scheduler structure to be transformed into API structure.
   */
  static serializeFormData(data: WeekSchedule): WorkSchedule {
    const days = Object.keys(data).reduce((acc, day: Day) => {
      if (WorkWeek.includes(day)) {
        acc[day] = WorkScheduleSerializer.serializeFormSingleDay(data[day]);

        return acc;
      }

      return acc;
    }, {} as IWorkSchedulerBody);

    return {
      enabled: WorkScheduleSerializer.deserializeEnabled(days),
      ...WorkScheduleSerializer.deserializeDays(days),
    };
  }

  private static parseDate(hour: number, minute: number): Date {
    const currentDate = new Date(null);

    currentDate.setHours(hour);
    currentDate.setMinutes(minute);

    return currentDate;
  }

  private static deserializeFormSingleDay(day: DaySchedule): FormDaySchedule {
    const result = day.data.map((range: BreakTimeSchedule) => {
      const {
        startHours: { hour: startHour, minute: startMinute },
        endHours: { hour: endHour, minute: endMinute },
      } = range;

      return {
        from: WorkScheduleSerializer.parseDate(startHour, startMinute),
        to: WorkScheduleSerializer.parseDate(endHour, endMinute),
      };
    });

    return {
      enabled: day.isEnabled,
      data: result,
    };
  }

  /**
   * Deserializes work schedule data provided by API to work scheduler form format.
   * @param data Agent data provided in API structure.
   */
  static deserializeFormData(data: WorkSchedule): WeekSchedulerFormValues | null {
    if (!data) {
      return null;
    }

    const days = Object.keys(data).reduce((acc, day: Day) => {
      if (WorkWeek.includes(day)) {
        acc[day] = WorkScheduleSerializer.deserializeFormSingleDay(data[day]);

        return acc;
      }

      return acc;
    }, {} as WeekSchedulerFormValues);

    return days;
  }
}
