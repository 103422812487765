import { ToastAutoHideDelay } from 'constants/toasts';
import { uniqueId } from 'helpers/string';
import { type ActionsUnion, createAction } from 'store/helper';

import {
  type ToastsCreatePayload,
  type ToastsShowPayload,
  type ToastsRemovePayload,
  ToastVerticalPosition,
  ToastVariant,
  ToastHorizontalPosition,
  type CreateToastActionParams,
} from './interfaces';

export enum ToastsActionsNames {
  CREATE = 'FEATURE/TOASTS/CREATE',
  SHOW = 'FEATURE/TOASTS/SHOW',
  REMOVE = 'FEATURE/TOASTS/REMOVE',
  REMOVE_ALL = 'FEATURE/TOASTS/REMOVE_ALL',
}

export const ToastsActions = {
  createToast: (toastDetails: CreateToastActionParams) => {
    const payload: ToastsCreatePayload = {
      autoHideDelayTime: ToastAutoHideDelay.Short,
      horizontalPosition: ToastHorizontalPosition.Center,
      kind: ToastVariant.Info,
      verticalPosition: ToastVerticalPosition.Top,
      removable: true,
      ...toastDetails,
      id: toastDetails.id || uniqueId(),
    };

    return createAction(ToastsActionsNames.CREATE, payload);
  },
  showToast: (payload: ToastsShowPayload) => createAction(ToastsActionsNames.SHOW, payload),
  removeToast: (payload: ToastsRemovePayload) => createAction(ToastsActionsNames.REMOVE, payload),
  removeAllToasts: () => createAction(ToastsActionsNames.REMOVE_ALL),
};

export type ToastsAction = ActionsUnion<typeof ToastsActions>;
