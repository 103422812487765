import { type FC } from 'react';

import { LoaderCircle } from './LoaderCircle';

import * as styles from './styles';

export const SkeletonLoader: FC = () => (
  <div className={styles.loaderContainer}>
    <LoaderCircle className={styles.skeletonLoaderCircle} />
  </div>
);
