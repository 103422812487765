import { QueryClient, type QueryClientConfig } from '@tanstack/react-query';

import { type QueryKey } from 'constants/query-key';

import { CUSTOM_QUERY_CLIENT_OPTIONS } from './constants';

export const queryOptions: QueryClientConfig = {
  defaultOptions: {
    mutations: {
      networkMode: 'offlineFirst',
    },
    queries: {
      networkMode: 'offlineFirst',
    },
  },
};

interface IQuerySubscriber {
  onQueryUpdated<T>(queryKey: QueryKey, handler: (data: T) => void): VoidFunction;
  onQueryUpdateFailure<T>(queryKey: QueryKey, handler: (data: T) => void): VoidFunction;
  onMutationSuccess<T>(queryKey: QueryKey, handler: (data: T) => void): VoidFunction;
}

type QueryClientSubscribable = QueryClient & IQuerySubscriber;

// eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
let _queryClient: QueryClientSubscribable;

/**
 * Gets existing QueryClientSubscribable instance or create a new one
 * @returns {QueryClientSubscribable}
 */
export function getQueryClient(): QueryClientSubscribable {
  if (!_queryClient) {
    const client: QueryClient = new QueryClient(queryOptions);

    Object.entries(CUSTOM_QUERY_CLIENT_OPTIONS).forEach(([key, queryOptions]) => {
      client.setQueryDefaults([key], queryOptions);
    });

    const queryCache = client.getQueryCache();
    const onQueryUpdated = <T>(queryKey: QueryKey, handler: (data: T) => void): VoidFunction => {
      const unsubscribe = queryCache.subscribe((event) => {
        if (
          event.type === 'updated' &&
          event.action.type === 'success' &&
          (event.query.queryKey as QueryKey[]).includes(queryKey)
        ) {
          handler(event.action.data as T);
        }
      });

      return unsubscribe;
    };
    const onQueryUpdateFailure = <T>(queryKey: QueryKey, handler: (data: T) => void): VoidFunction => {
      const unsubscribe = queryCache.subscribe((event) => {
        if (
          event.type === 'updated' &&
          event.action.type === 'failed' &&
          (event.query.queryKey as QueryKey[]).includes(queryKey)
        ) {
          handler(event.action.error as T);
        }
      });

      return unsubscribe;
    };

    const mutationCache = client.getMutationCache();
    const onMutationSuccess = <T>(queryKey: QueryKey, handler: (data: T) => void): VoidFunction => {
      const unsubscribe = mutationCache.subscribe((event) => {
        if (
          event.type === 'updated' &&
          event.action.type === 'success' &&
          event.mutation.options.mutationKey?.includes(queryKey)
        ) {
          handler(event.mutation.options as T);
        }
      });

      return unsubscribe;
    };

    _queryClient = Object.assign(client, { onQueryUpdated, onQueryUpdateFailure, onMutationSuccess });
  }

  return _queryClient;
}
