import { type ReactElement, type FC } from 'react';

import { ChevronDown, FiletypeHtml, FiletypePdf } from '@livechat/design-system-icons';
import { Popover, Button, Icon, Text, type ButtonKind } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { AbsoluteAutomateRoutes } from 'constants/automate/constants';
import { KnowledgeHubEvent } from 'constants/knowledge-hub';
import { type EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import { AutomateViewActions } from 'store/views/automate/actions';

import * as styles from './styles';

interface Props {
  eventPlace: EventPlace;
  place: string;
  onAddCustomWebsite?: () => void;
  onAddCustomPdf?: () => void;
  buttonText?: string;
  buttonKind?: ButtonKind;
  customTriggerRenderer?: () => ReactElement;
}

export const AddSourceButtonWithPopover: FC<Props> = ({
  eventPlace,
  place,
  onAddCustomPdf,
  onAddCustomWebsite,
  buttonText,
  buttonKind,
  customTriggerRenderer,
}) => {
  const dispatch = useDispatch();
  const handleAddWebsite = (): void => {
    dispatch(AutomateViewActions.setKnowledgeHubModal('add-website'));
    navigate(AbsoluteAutomateRoutes.KnowledgeHubWebsites);

    trackEvent(KnowledgeHubEvent.AddWebsiteTriggered, eventPlace, { origin: place });
  };

  const handleAddPdf = (): void => {
    dispatch(AutomateViewActions.setKnowledgeHubModal('add-pdf'));
    navigate(AbsoluteAutomateRoutes.KnowledgeHubPDF);

    trackEvent(KnowledgeHubEvent.AddPDFTriggered, eventPlace, { origin: place });
  };

  return (
    <Popover
      className={styles.addSourceContent}
      triggerRenderer={
        customTriggerRenderer
          ? customTriggerRenderer
          : () => (
              <Button
                className={styles.addSourceButton}
                kind={buttonKind || 'primary'}
                icon={<Icon source={ChevronDown} />}
                iconPosition="right"
              >
                {buttonText || 'Add source'}
              </Button>
            )
      }
    >
      <div onClick={onAddCustomWebsite || handleAddWebsite} className="add-source">
        <Icon source={FiletypeHtml} />
        <Text>Add website source</Text>
      </div>
      <div onClick={onAddCustomPdf || handleAddPdf} className="add-source">
        <Icon source={FiletypePdf} />
        <Text>Add PDF file</Text>
      </div>
    </Popover>
  );
};
