import { type KeyMap } from 'helpers/interface';

import { type ToastDetails, type ToastsState } from './interfaces';

interface IWithToastsState {
  features: {
    toast: ToastsState;
  };
}

export function getToasts(state: IWithToastsState): KeyMap<ToastDetails> {
  return state.features.toast.items;
}

export function getToast(state: IWithToastsState, toastId: string): ToastDetails | null {
  return getToasts(state)[toastId];
}
