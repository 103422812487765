export const sendEmail = (emailContent: string) => {
  (window as any).location = emailContent;
};

export const prepareEmailContent = (to: string, subject: string, body: string): string =>
  `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

export const askForSubscriptionIncrease = (managersList: string[]): void => {
  const recipients = managersList.join(',');
  const emailContent = prepareEmailContent(
    recipients,
    'Urgent: LiveChat Payment Decline',
    `Hi,

    We couldn't invite new people to LiveChat due to insufficient funds on the credit card linked to our subscription.
    Could you consider topping up the card or updating the billing details in the Subscription section in the LiveChat app?

    https://my.livechatinc.com/subscription/account-details#billing-details

    Thank you!`
  );
  sendEmail(emailContent);
};

export const askForPaymentMethodUpdate = (managersList: string[], hasManualPayments: boolean): void => {
  const recipients = managersList.join(',');
  const emailContent = prepareEmailContent(
    recipients,
    'Urgent: LiveChat Payment Decline',
    `Hi,

     Our LiveChat subscription is about to expire and the payment for the next billing cycle was declined. Could you check it and, if necessary, ${
       hasManualPayments ? 'settle the overdue invoice' : 'update the billing details'
     } in the Subscription section in the LiveChat app?'

     Thank you!`
  );
  sendEmail(emailContent);
};
