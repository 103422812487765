/* eslint-disable @typescript-eslint/naming-convention */
export type LearningProgress = {
  step: 'INITIAL' | 'SYNCING_DATA' | 'PARSING' | 'CHUNKING' | 'COMPLETED';
  updated_at: string;
};

export enum KnowledgeSourceType {
  URL = 'URL',
  File = 'FILE',
}

export type KnowledgeSourceFileItemType = 'PDF';

export enum KnowledgeSourceItemStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type MaxUrlDepth = 0 | 3;

export type VectorDatabase = 'weaviate' | 'sources-storage';

interface AIAgent {
  agentId: string;
}

export interface KnowledgeSourceItemBase {
  id: string;
  name: string;
  status: KnowledgeSourceItemStatus;
  sourceType: KnowledgeSourceType;
  customDescription: string;
  createdAt: string;
  updatedAt: string;
  learnedAt: string | null;
  authorId: string | null;
  nextSyncAt: string | null;
  syncInterval: number;
  vectorDatabase?: VectorDatabase;
  aiAgents?: AIAgent[];
  learningProgress: null | LearningProgress;
}

export interface KnowledgeSourceFileItem extends KnowledgeSourceItemBase {
  fileName: string;
  fileType: KnowledgeSourceFileItemType;
  fileSize: number;
}

export interface KnowledgeSourceUrlItem extends KnowledgeSourceItemBase {
  url: string;
  depth: MaxUrlDepth;
  pageCount: number;
}

export interface KnowledgeSourcePdfItem extends KnowledgeSourceFileItem {
  fileType: 'PDF';
}

export type KnowledgeSourceItem = KnowledgeSourceUrlItem | KnowledgeSourcePdfItem;

export enum AnnotateReplyType {
  ResponseUsed = 'response_used',
  ConversationRate = 'conversation_rate',
}

export type AnnotateReplyValue = 0 | 1;
