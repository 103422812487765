export type BooleanNumericFlag = '0' | '1';

export type TernaryNumericFlag = '-1' | '0' | '1';

export function isBooleanOrUndefined(value: unknown): boolean {
  return typeof value === 'undefined' || typeof value === 'boolean';
}

export function booleanToNumericString(value: boolean): BooleanNumericFlag {
  return value ? '1' : '0';
}

export function anyToBoolean(value: unknown): boolean {
  if (typeof value === 'string') {
    return value.length !== 0 && value !== '0' && value.toLocaleLowerCase() !== 'false';
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'object') {
    return value !== null && !(Object.keys(value).length === 0 && value.constructor === Object);
  }

  return !!value;
}

export function stringToBoolean(text: string | boolean): boolean {
  return text === true || text === 'true' || !!parseInt(String(text), 10);
}

export function booleanToNumeric(value: boolean): 0 | 1 {
  return value ? 1 : 0;
}
