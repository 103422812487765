import { type ReactElement } from 'react';

import { Badge, Text } from '@livechat/design-system-react-components';

import { type SettingsNavigationItemId } from 'constants/settings/navigation-item-id';
import { useReplySuggestionsSourcesList } from 'hooks/use-reply-suggestions-list';

import { getSourcesCountByType } from './helpers';
import { type AutomateSideNavigationItemCustomComponentProps } from './interfaces';

import * as styles from './styles';

export const KnowledgeSourcesItem = ({
  item,
  isActive,
}: AutomateSideNavigationItemCustomComponentProps): ReactElement => {
  const { data } = useReplySuggestionsSourcesList();
  const count = getSourcesCountByType(item.id as SettingsNavigationItemId, data);

  return (
    <div className={styles.wrapper}>
      <Text bold={isActive} className={styles.knowledgeSourcesLabel(isActive)}>
        {item.name}
      </Text>
      <Badge count={count} kind="tertiary" className={styles.itemsCount} />
    </div>
  );
};
