import { type MouseEvent, type FC } from 'react';

import { NavigationItem } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { AgentAvatar } from 'components/avatar/AgentAvatar';
import { getLoggedInAgent } from 'store/entities/agents/selectors';

interface IProps {
  path: string;
  active: boolean;
  onItemClick?(path?: string): void;
}

const handleClick = (event: MouseEvent<HTMLAnchorElement>, path?: string, callback?: IProps['onItemClick']): void => {
  event.preventDefault();

  callback?.(path);
};

const MobileNavigationMyProfileItem: FC<IProps> = ({ path, active, onItemClick }) => {
  const currentAgent = useSelector(getLoggedInAgent);

  return (
    <NavigationItem
      id="mobile-profile"
      disableOpacity
      isActive={active}
      url="/"
      icon={
        <AgentAvatar src={currentAgent.avatar} status={currentAgent.status} text={currentAgent.name} size="small" />
      }
      onClick={(event) => handleClick(event, path, onItemClick)}
    />
  );
};

export default MobileNavigationMyProfileItem;
