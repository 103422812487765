// @ts-strict-ignore
import { ChatType } from 'constants/chat-type';
import type { IAppState } from 'services/app-state-provider';
import { getPropertyValue } from 'services/serialization/property';
import { markEventsAsSeen } from 'services/web-socket/actions/mark-events-as-seen';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { isMyChat, isOtherChat, isSupervisedChat, isUnassignedChat } from 'store/entities/chats/helpers/common';
import { type ChatThreadEntity, type IOtherChat } from 'store/entities/chats/interfaces';
import { getIsUnassignedThread, getThreadBy, getUnassignedIds } from 'store/entities/chats/selectors';
import { CustomerActions } from 'store/entities/customers/actions';
import { ChatsViewActions } from 'store/views/chats/actions';
import { getSelectedThreadId, getTotalUnassignedChats } from 'store/views/chats/selectors';

import { type IIncomingChatPushEvent } from '../interfaces';
import { deserializeThread } from '../serialization/deserialize';
import { deserializeChatSummaryCustomer } from '../serialization/deserialize-customer';
import { getThreadEventsMergedWithNotDelivered } from '../serialization/deserialize-event-collection';
import { getWasEventSeen } from '../serialization/helpers/common';

import { getShouldIgnoreIncomingChatThreadNotifications, updateCurrentUserTimestamp } from './helpers/incoming-chat';

/**
 * Handles assigning Unassigned Chat to another agent
 * 1. thread was in the store and on the chats list
 * 2. thread was unassigned but was not on the chats list
 * 3. thread was of type My and was reactivated by a customer
 * @param rawPayload incoming_chat message payload
 * @param thread deserialized thread
 * @param state Redux state.
 */
function handleThreadAssignToOtherAgent(
  rawPayload: IIncomingChatPushEvent,
  thread: IOtherChat,
  store: IAppState
): void {
  const isPinned = getPropertyValue(rawPayload.chat.properties, 'routing', 'pinned');

  if (isPinned) {
    const previousUnassignedThread = getThreadBy(
      store.getState(),
      (t) => thread.chatId === t.chatId && t.type === ChatType.Unassigned
    );
    const totalUnassignedChatsCount = getTotalUnassignedChats(store.getState());

    if (previousUnassignedThread) {
      /**
       *  1. Chat thread was in the store and on the chats list
       * `shouldLoadAdditionalUnassignedChat` flag is responsible for auto fetch new Unassigned Chats
       */
      const currentUnassignedChatsCount = getUnassignedIds(store.getState()).length;
      const shouldLoadAdditionalUnassignedChat = totalUnassignedChatsCount > 5 && currentUnassignedChatsCount - 1 < 5;

      store.dispatch(
        ChatsEntitiesActions.assignChatToOtherAgent({
          threadId: previousUnassignedThread.threadId,
          shouldLoadAdditionalUnassignedChat,
        })
      );
    } else {
      /**
       *  2. Chat thread was unassigned but was not on the chats list
       * Dispatching action which decrease counter of Unassigned Chats (closed and active)
       */
      store.dispatch(
        ChatsEntitiesActions.updateUnassignedChatsCount({
          threadId: thread.threadId,
          type: 'decrease',
        })
      );
    }
  }
}

/**
 * Handler for threads not assigned to current agent
 * 1. Handling incoming_chat message triggered by follow_chat.
 *    Existing thread is updated in the store.
 *    Customer data is also updated.
 * 2. Handling a new thread.
 *   a. Add thread to the store.
 *   b. For incoming Unassigned or converted from Unassigned (also to yet another Unassiged)
 *      it's possible to receive new thread in the same chat. In that case chat history
 *      should be fetched to keep the context of the chat.
 *   c. Unassigned chats counters must be updated.
 * @param rawPayload incoming_chat message payload
 * @param thread deserialized thread
 * @param state Redux state.
 */
function handleNewChatThread(rawPayload: IIncomingChatPushEvent, thread: ChatThreadEntity, store: IAppState): void {
  const currentUserLogin = getLoggedInAgentLogin(store.getState());
  const {
    chat: { thread: rawThread, users },
    requester_id: requesterId,
  } = rawPayload;
  let chatUsers = users;

  /**
   * Special condition for supervised chat. Assuming that all events that already exist in supervised
   * chat should be marked as read by supervisor, the supervising agent 'last_seen_timestamp' timestamp
   * should be updated, both in current users array and in the API.
   */
  if (isSupervisedChat(thread)) {
    const { chatId } = thread;
    const currentTimestamp = Math.round(Date.now() / 1000);

    chatUsers = updateCurrentUserTimestamp(currentUserLogin, currentTimestamp, chatUsers);

    void markEventsAsSeen(chatId, currentTimestamp);
  } else {
    // Prevent displaying other agent sneak peek as ours after supervised chat is transfered or taken over
    store.dispatch(
      ChatsEntitiesActions.clearAgentSneakPeek({
        threadId: rawPayload.chat.thread.id,
      })
    );
  }

  store.dispatch(ChatsEntitiesActions.addChatUsers({ chatId: thread.chatId, chatUsers }));

  // Add customer information to the store.
  const customer = deserializeChatSummaryCustomer(rawPayload.chat);

  if (customer) {
    store.dispatch(CustomerActions.updateCustomer({ customer }));
  }

  const events = getThreadEventsMergedWithNotDelivered(
    thread.threadId,
    currentUserLogin,
    rawThread.events,
    chatUsers,
    getWasEventSeen
  );

  /**
   * 1. Handling incoming_chat message triggered by follow_chat
   * Updates chat thread when thread already exists, it is unassigned and its type did't change
   * Especially should update events of chat cause until follow_chat there were no incoming_event messages in ws
   */
  const isThreadCurrentlyUnassigned = getIsUnassignedThread(store.getState(), thread.threadId);
  const isIncomingThreadUnassigned = isUnassignedChat(thread);

  if (isThreadCurrentlyUnassigned && isIncomingThreadUnassigned) {
    store.dispatch(
      ChatsEntitiesActions.updateChat({
        thread,
        events,
      })
    );

    return;
  }

  /**
   * 2. Handling a new thread
   * `convertedChatPayload` - it can already exist in the store as Unassigned Chat
   * so in the payload additional informations are passed
   */
  const previousUnassignedThread = getThreadBy(
    store.getState(),
    (t: ChatThreadEntity): boolean => thread.chatId === t.chatId && t.type === ChatType.Unassigned
  );
  const isConvertedFromUnassigned = !!previousUnassignedThread;
  const convertedChatPayload = isConvertedFromUnassigned
    ? {
        convertedFrom: ChatType.Unassigned,
        previousThreadId: previousUnassignedThread.threadId,
      }
    : null;

  /**
   * 2a. Add thread to the store.
   */
  const shouldSkipNotification = getShouldIgnoreIncomingChatThreadNotifications(thread, requesterId);

  store.dispatch(
    ChatsEntitiesActions.incomingChatThread({
      thread,
      events,
      shouldSkipNotification,
      ...(rawThread.tags && { tags: rawThread.tags }),
      ...(convertedChatPayload || {}),
    })
  );

  /**
   * 2b. For incoming Unassigned or converted from Unassigned (also to yet another Unassiged)
   *     it's possible to receive new thread in the same chat. In that case chat history
   *     should be fetched to keep the context of the chat.
   */
  if (isConvertedFromUnassigned || isIncomingThreadUnassigned) {
    store.dispatch(
      ChatsEntitiesActions.fetchChatHistory({
        threadId: thread.threadId,
      })
    );
  }

  /**
   * 2c. Unassigned chats counters must be updated.
   */
  const wasPinned = getPropertyValue(rawPayload.chat.properties, 'routing', 'was_pinned');

  if (isUnassignedChat(thread) && !wasPinned) {
    /**
     * Increase counter only for new Unassigned Chats
     * incoming chat thread can be a part of a chat already pinned but not visible (pagination of unassigned chats)
     */
    store.dispatch(
      ChatsEntitiesActions.updateUnassignedChatsCount({
        threadId: thread.threadId,
        type: 'increase',
      })
    );
  } else if (!isUnassignedChat(thread) && isConvertedFromUnassigned) {
    /**
     * Decrease counter for new thread with type other than Unassigned if its converted from Unassigned
     * it will handle counter change after 'Assign to me' action use or automatic routing after turning on 'Accept Chats' feature
     */
    store.dispatch(
      ChatsEntitiesActions.updateUnassignedChatsCount({
        threadId: thread.threadId,
        type: 'decrease',
      })
    );
  }

  /**
   * 3. Handling old thread from the same chat
   * if the customer reopens archived chat, the old thread should be removed and disappear from chat list
   */
  const previousMyChatThread = getThreadBy(
    store.getState(),
    (t: ChatThreadEntity): boolean => thread.chatId === t.chatId && t.type === ChatType.My
  );
  if (previousMyChatThread && isMyChat(thread) && previousMyChatThread.threadId !== thread.threadId) {
    const selectedThreadId = getSelectedThreadId(store.getState());
    if (selectedThreadId === previousMyChatThread.threadId) {
      store.dispatch(
        ChatsViewActions.setSelectedId({
          threadId: thread.threadId,
        })
      );
    }
    store.dispatch(
      ChatsEntitiesActions.removeChatThread({
        threadId: previousMyChatThread.threadId,
        omitTags: true,
      })
    );
  }
}

/**
 * Handles incoming chat push event.
 * 1. Handling a new thread or incoming_chat message triggered by follow_chat.
 * 2. Assigning Unassigned Chat to another agent.
 * @param {IIncomingChatPushEvent} payload Push event payload.
 */
export function handleIncomingChat(payload: IIncomingChatPushEvent, store: IAppState): void {
  const isNotActiveChat = payload.chat.thread.active === false;
  if (isNotActiveChat) {
    return;
  }

  const currentAgentId = getLoggedInAgentLogin(store.getState());
  const thread = deserializeThread(payload.chat, currentAgentId);
  const isChatTypeSupported = thread !== null;

  if (isChatTypeSupported) {
    if (!isOtherChat(thread) || !isUnassignedChat(thread)) {
      /**
       * 1. Handling a new thread or incoming_chat message triggered by follow_chat
       */
      handleNewChatThread(payload, thread, store);
    } else {
      /**
       * 2. Assigning Unassigned Chat to another agent
       * Thread has type === ChatType.Other so it's probably a chat assigned to the other agent
       */
      handleThreadAssignToOtherAgent(payload, thread, store);
    }
  }
}
