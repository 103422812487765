import { type ITicket } from 'interfaces/entities/ticket';
import { type ActionsUnion, createAction } from 'store/helper';

import {
  type ITicketsViewSetQueryParamsFromURLPayload,
  type ITicketsViewBatchUpdatePayload,
  type ITicketsViewBatchUpdateSuccessPayload,
  type ITicketsViewUpdateTicketCountersPayload,
  type ITicketsViewSetTicketsDataPayload,
  type ITicketsViewUpdateTagsPayload,
  type ITicketsViewUpdateAssigneePayload,
  type ITicketsViewUpdateGroupsPayload,
  type ITicketsViewSendMessagePayload,
  type ITicketsViewSaveDraftPayload,
  type ITicketsViewClearDraftPayload,
  type ITicketsViewUnspamPayload,
  type ITicketsViewCreateTicketPayload,
  type ITicketsViewFromAllGroupsPayload,
  type ITicketsViewUpdateFilterPayload,
  type ITicketsViewSetRoutePayload,
  type IFetchTicketsEmailsMappingPayload,
  type ISaveTicketsEmailMappingPayload,
  type IClearTicketsEmailMappingPayload,
  type ISetTicketsEmailMappingPayload,
} from './interfaces';

export enum TicketsViewActionsNames {
  SET_ROUTE = 'VIEWS/TICKETS/SET_ROUTE',
  SET_FETCHED_ROUTE = 'VIEWS/TICKETS/SET_FETCHED_ROUTE',
  SET_DATA = 'VIEWS/TICKETS/SET_DATA',
  SET_PAGE = 'VIEWS/TICKETS/SET_PAGE',
  TICKETS_UPDATED = 'VIEWS/TICKETS/TICKETS_UPDATED',
  UPDATE_FILTER = 'VIEWS/TICKETS/UPDATE_FILTER',
  SET_QUERY_PARAMS_FROM_URL = 'VIEWS/TICKETS/SET_QUERY_PARAMS_FROM_URL',
  FETCH_TAGS = 'VIEWS/TICKETS/FETCH_TAGS',
  UPDATE_TAGS = 'VIEWS/TICKETS/UPDATE_TAGS',
  FETCH_TICKET_COUNTERS_REQUEST = 'VIEWS/TICKETS/FETCH_TICKET_COUNTERS_REQUEST',
  FETCH_TICKET_COUNTERS_SUCCESS = 'VIEWS/TICKETS/FETCH_TICKET_COUNTERS_SUCCESS',
  FETCH_TICKET_COUNTERS_FAILURE = 'VIEWS/TICKETS/FETCH_TICKET_COUNTERS_FAILURE',
  SELECT_TICKETS = 'VIEWS/TICKETS/SELECT',
  UPDATE_BATCH = 'VIEWS/TICKETS/UPDATE_BATCH_REQUEST',
  UPDATE_BATCH_SUCCESS = 'VIEWS/TICKETS/UPDATE_BATCH_SUCCESS',
  UPDATE_BATCH_FAILURE = 'VIEWS/TICKETS/UPDATE_BATCH_FAILURE',
  SET_SEARCH_QUERY = 'VIEWS/TICKETS/SET_SEARCH_QUERY',
  UPDATE_TICKET_COUNTERS = 'VIEWS/TICKETS/UPDATE_TICKET_COUNTERS',
  CLEAR_SEARCH = 'VIEWS/TICKETS/CLEAR_SEARCH',
  RESET_SELECTED_TICKETS = 'VIEWS/TICKETS/RESET_SELECTED_TICKETS',

  // single ticket
  CREATE_TICKET = 'VIEWS/TICKETS/CREATE_TICKET',
  FETCH_SINGLE = 'VIEWS/TICKETS/FETCH_SINGLE',
  SET_CURRENT_ITEM = 'VIEWS/TICKETS/SET_CURRENT_ITEM',
  UPDATE_SUBJECT = 'VIEWS/TICKETS/UPDATE_SUBJECT',
  UPDATE_REQUESTER = 'VIEWS/TICKETS/UPDATE_REQUESTER',
  UPDATE_CCS = 'VIEWS/TICKETS/UPDATE_CCS',
  UPDATE_ASSIGNEE = 'VIEWS/TICKETS/UPDATE_ASSIGNEE',
  UPDATE_GROUPS = 'VIEWS/TICKETS/UPDATE_GROUPS',
  SEND_MESSAGE = 'VIEWS/TICKETS/SEND_MESSAGE',
  SAVE_DRAFT = 'VIEWS/TICKETS/SAVE_DRAFT',
  CLEAR_DRAFT = 'VIEWS/TICKETS/CLEAR_DRAFT',
  UNSPAM = 'VIEWS/TICKETS/UNSPAM',
  COPY_TICKET_URL = 'VIEWS/TICKETS/COPY_TICKET_URL',
  SHOW_FROM_ALL_GROUPS = 'VIEWS/TICKETS/SHOW_FROM_ALL_GROUPS',

  // email mapping
  SET_EMAILS_MAPPING = 'VIEWS/TICKETS/SET_EMAILS_MAPPING',
  FETCH_EMAILS_MAPPING = 'VIEWS/TICKETS/FETCH_EMAILS_MAPPING',
  SAVE_EMAIL_MAPPING = 'VIEWS/TICKETS/SAVE_EMAIL_MAPPING',
  CLEAR_EMAIL_MAPPING = 'VIEWS/TICKETS/CLEAR_EMAIL_MAPPING',
}

export enum TicketsViewRequestNames {
  FETCH_TICKET_COUNTERS_REQUEST = 'VIEWS/TICKETS/FETCH_TICKET_COUNTERS',
}

export const TicketsViewActions = {
  // tickets list
  fetchTicketCounters: () => createAction(TicketsViewActionsNames.FETCH_TICKET_COUNTERS_REQUEST),
  fetchTicketCountersSuccess: () => createAction(TicketsViewActionsNames.FETCH_TICKET_COUNTERS_SUCCESS),
  fetchTicketCountersFailure: () => createAction(TicketsViewActionsNames.FETCH_TICKET_COUNTERS_FAILURE),
  setRoute: (payload: ITicketsViewSetRoutePayload) => createAction(TicketsViewActionsNames.SET_ROUTE, payload),
  setTicketsData: (payload: ITicketsViewSetTicketsDataPayload) =>
    createAction(TicketsViewActionsNames.SET_DATA, payload),
  onTicketsUpdated: (ticketIds: string[]) => createAction(TicketsViewActionsNames.TICKETS_UPDATED, { ticketIds }),
  setPage: (page: number) => createAction(TicketsViewActionsNames.SET_PAGE, { page }),
  updateFilter: (payload: ITicketsViewUpdateFilterPayload) =>
    createAction(TicketsViewActionsNames.UPDATE_FILTER, payload),
  setQueryParamsFromURL: (payload: ITicketsViewSetQueryParamsFromURLPayload) =>
    createAction(TicketsViewActionsNames.SET_QUERY_PARAMS_FROM_URL, payload),
  fetchTags: () => createAction(TicketsViewActionsNames.FETCH_TAGS),
  updateTags: (payload: ITicketsViewUpdateTagsPayload) => createAction(TicketsViewActionsNames.UPDATE_TAGS, payload),
  selectTicketIds: (ticketIds: string[]) => createAction(TicketsViewActionsNames.SELECT_TICKETS, { ticketIds }),
  updateBatch: (payload: ITicketsViewBatchUpdatePayload) => createAction(TicketsViewActionsNames.UPDATE_BATCH, payload),
  updateBatchSuccess: (payload: ITicketsViewBatchUpdateSuccessPayload) =>
    createAction(TicketsViewActionsNames.UPDATE_BATCH_SUCCESS, payload),
  updateBatchFailure: (payload: { error: any }) => createAction(TicketsViewActionsNames.UPDATE_BATCH_FAILURE, payload),
  setSearchQuery: (query: string) => createAction(TicketsViewActionsNames.SET_SEARCH_QUERY, { query }),
  updateTicketCounters: (payload: ITicketsViewUpdateTicketCountersPayload) =>
    createAction(TicketsViewActionsNames.UPDATE_TICKET_COUNTERS, payload),
  clearSearch: () => createAction(TicketsViewActionsNames.CLEAR_SEARCH),
  resetSelectedTickets: () => createAction(TicketsViewActionsNames.RESET_SELECTED_TICKETS),

  // single ticket
  createTicket: (payload: ITicketsViewCreateTicketPayload) =>
    createAction(TicketsViewActionsNames.CREATE_TICKET, payload),
  fetchSingle: (id: string) => createAction(TicketsViewActionsNames.FETCH_SINGLE, { id }),
  setCurrentItem: (ticket: ITicket) => createAction(TicketsViewActionsNames.SET_CURRENT_ITEM, { ticket }),
  updateSubject: (ticketId: string, subject: string) =>
    createAction(TicketsViewActionsNames.UPDATE_SUBJECT, { ticketId, subject }),
  updateRequester: (ticketId: string, requesterEmail: string) =>
    createAction(TicketsViewActionsNames.UPDATE_REQUESTER, { ticketId, requesterEmail }),
  updateCCs: (ticketId: string, ccs: string[]) => createAction(TicketsViewActionsNames.UPDATE_CCS, { ticketId, ccs }),
  updateAssignee: (payload: ITicketsViewUpdateAssigneePayload) =>
    createAction(TicketsViewActionsNames.UPDATE_ASSIGNEE, payload),
  updateGroups: (payload: ITicketsViewUpdateGroupsPayload) =>
    createAction(TicketsViewActionsNames.UPDATE_GROUPS, payload),
  sendMessage: (payload: ITicketsViewSendMessagePayload) => createAction(TicketsViewActionsNames.SEND_MESSAGE, payload),
  saveDraft: (payload: ITicketsViewSaveDraftPayload) => createAction(TicketsViewActionsNames.SAVE_DRAFT, payload),
  clearDraft: (payload: ITicketsViewClearDraftPayload) => createAction(TicketsViewActionsNames.CLEAR_DRAFT, payload),
  unspam: (payload: ITicketsViewUnspamPayload) => createAction(TicketsViewActionsNames.UNSPAM, payload),
  copyTicketUrl: () => createAction(TicketsViewActionsNames.COPY_TICKET_URL),
  showTicketsFromAllGroups: (payload: ITicketsViewFromAllGroupsPayload) =>
    createAction(TicketsViewActionsNames.SHOW_FROM_ALL_GROUPS, payload),

  // email mapping
  setTicketsEmailsMapping: (payload: ISetTicketsEmailMappingPayload) =>
    createAction(TicketsViewActionsNames.SET_EMAILS_MAPPING, payload),
  fetchTicketsEmailsMapping: (payload: IFetchTicketsEmailsMappingPayload) =>
    createAction(TicketsViewActionsNames.FETCH_EMAILS_MAPPING, payload),
  saveTicketsEmailMapping: (payload: ISaveTicketsEmailMappingPayload) =>
    createAction(TicketsViewActionsNames.SAVE_EMAIL_MAPPING, payload),
  clearTicketsEmailMapping: (payload: IClearTicketsEmailMappingPayload) =>
    createAction(TicketsViewActionsNames.CLEAR_EMAIL_MAPPING, payload),
};

export type TicketsViewAction = ActionsUnion<typeof TicketsViewActions>;
