import { getTime } from 'date-fns';

import { AppStateProvider } from 'services/app-state-provider';
import { getLicenseId } from 'store/features/session/selectors';

import { downloadFile } from './download';

export function downloadState(): Promise<void> {
  const state = AppStateProvider.getState();
  const licenseId = getLicenseId(state) || 'unknownLicense';
  const timestamp = getTime(new Date());

  const fileName = `state-${licenseId}-${timestamp}.json`;
  const fileContent = JSON.stringify(state);

  return downloadFile(fileName, fileContent);
}
