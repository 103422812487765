import { type FC, type ReactNode } from 'react';

import { titleStyles, imageStyles, messageStyles } from './styled';

interface IProps {
  title: string;
  message: ReactNode;
  image?: ReactNode;
}

export const TourStep: FC<IProps> = ({ title, image, message }) => (
  <>
    <h3 className={titleStyles}>{title}</h3>
    {image && <div className={imageStyles}>{image}</div>}
    <p className={messageStyles}>{message}</p>
  </>
);
