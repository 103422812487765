export enum Scope {
  AgentsRead = 'agents_read',
  AgentsWrite = 'agents_write',
  AgentsTransferOwnership = 'agents_transfer_ownership',
  ArchivesRead = 'archives_read',
  ArchivesWrite = 'archives_write',
  BanlistRead = 'banlist_read',
  BanlistWrite = 'banlist_write',
  ButtonsManage = 'buttons_manage',
  CannedResponsesRead = 'canned_responses_read',
  CannedResponsesWrite = 'canned_responses_write',
  ChatLogosManage = 'chat_logos_manage',
  CodeManage = 'code_manage',
  DomainManage = 'domain_manage',
  EyeCatcherManage = 'eye_catcher_manage',
  FormsManage = 'forms_manage',
  GoalsRead = 'goals_read',
  GoalsWrite = 'goals_write',
  GreetingsRead = 'greetings_read',
  GreetingsWrite = 'greetings_write',
  GroupsRead = 'groups_read',
  GroupsWrite = 'groups_write',
  IntegrationsRead = 'integrations_read',
  IntegrationsManage = 'integrations_manage',
  KBIntegrationManage = 'kb.integration.settings.lc--all:rw',
  HDIntegrationManage = 'hd.integration.settings.lc--all:rw',
  InAppMsgManage = 'in_app_msg_manage',
  PropertiesManage = 'properties_manage',
  ReportsRead = 'reports_read',
  RoutingRead = 'routing_read',
  RoutingWrite = 'routing_write',
  SubscriptionLCRW = 'subscription.lc:rw', // owner, vice-owner - only LiveChat product
  SubscriptionRW = 'subscription:rw', // owner - all products
  SubscriptionLCRO = 'subscription.lc:ro', // agent and admin on lex mode
  SurveysRead = 'surveys_read',
  SurveysManage = 'surveys_manage',
  TicketsRead = 'tickets_read',
  TicketsWrite = 'tickets_write',
  TicketsManage = 'tickets_manage',
  TagsRead = 'tags_read',
  TagsWrite = 'tags_write',
  TagsManagement = 'tags--groups:rw',
  WebhooksManage = 'webhooks_manage',
  VisitorsRead = 'visitors_read',
  OauthClientsManage = 'oauth_clients_manage',
  LanguagesRead = 'languages_read',
  LanguagesWrite = 'languages_write',
  BanCustomer = 'ban_customer',
  UnbanCustomer = 'unban_customer',
  WebhooksWrite = 'webhooks_write',
  OnboardingManage = 'onboarding_manage',
  BillingManage = 'billing_manage',
  BillingAdmin = 'billing_admin',
  InvoicesManage = 'invoices_manage',
  PersonalAccessManage = 'personal_access_manage',
  SessionsALLRO = 'sessions--all:ro',
  SessionsAllRW = 'sessions--all:rw',
  ChatsAllRO = 'chats--all:ro',
  ChatsAccessRO = 'chats--access:ro',
  ChatsMyRO = 'chats--my:ro',
  ChatsConversationAllRW = 'chats.conversation--all:rw',
  ChatsConversationAccessRW = 'chats.conversation--access:rw',
  ChatsConversationMyRW = 'chats.conversation--my:rw',
  ChatsAllRW = 'chats--all:rw',
  ChatsAccessRW = 'chats--access:rw',
  ChatsMyRW = 'chats--my:rw',
  CustomersBanRW = 'customers.ban:rw',
  CustomersOwn = 'customers:own',
  CustomersRO = 'customers:ro',
  CustomersRW = 'customers:rw',
  MulticastRW = 'multicast:rw',
  AgentsMyRO = 'agents--my:ro',
  AgentsAllRO = 'agents--all:ro',
  AgentsMyRW = 'agents--my:rw',
  AgentsAllRW = 'agents--all:rw',
  AccessRulesRO = 'access_rules:ro',
  AccessRulesRW = 'access_rules:rw',
  PropertiesMyRO = 'properties--my:ro',
  PropertiesMyRW = 'properties--my:rw',
  PropertiesAllRO = 'properties--all:ro',
  WebhooksMyRO = 'webhooks--my:ro',
  WebhooksMyRW = 'webhooks--my:rw',
  WebhooksAllRO = 'webhooks--all:ro',
  WebhooksAllRW = 'webhooks--all:rw',
  AgentsBotAllRO = 'agents-bot--all:ro',
  AgentsBotMyRO = 'agents-bot--my:ro',
  AgentsBotMyRW = 'agents-bot--my:rw',
  AgentsBotAllRW = 'agents-bot--all:rw',
  GroupsMyRO = 'groups--my:ro',
  GroupsAllRO = 'groups--all:ro',
  GroupsMyRW = 'groups--my:rw',
  GroupsAllRW = 'groups--all:rw',
  AccountsMyRO = 'accounts--my:ro',
  AccountsMyRW = 'accounts--my:rw',
  AccountsAllRO = 'accounts--all:ro',
  AccountsAllRW = 'accounts--all:rw',
  AccountsAllRC = 'accounts--all:rc',
  AccountsRolesLCAllRW = 'accounts.roles.lc--all:rw',
  AccountsPasswordMyRW = 'accounts.password--my:rw',
  AccountsPasswordAllRW = 'accounts.password--all:rw',
  ClientsConsentMyRW = 'clients.consent--my:rw',
  SessionsMyRO = 'sessions--my:ro',
  SessionsMyRW = 'sessions--my:rw',
  OrganizationManagement = 'organization--my:rw',
  OrganizationProductActivation = 'organization.product--my:rw',
  GroupPropertiesManagement = 'properties.group.value--all:rw',
}
