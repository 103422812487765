// @ts-strict-ignore
import { createSelector } from 'reselect';

import { type LanguageCode } from 'constants/languages-available';
import { type KeyMap } from 'helpers/interface';
import type { GroupId, Group, GroupExtended } from 'interfaces/groups';
import { shouldIncludeAgentInGroupAgentPriorities } from 'store/entities/agents/helpers';
import {
  getAgent,
  getLoggedInAgent,
  getLoggedInAgentLogin,
  type WithAgentsState,
} from 'store/entities/agents/selectors';
import { type IWithBotsState, getBot } from 'store/entities/bots/selectors';
import { getCanSeeOtherAgentsGroups } from 'store/features/session/selectors';
import { getOnlineAgentsAndBots } from 'store/views/team/computed';

export interface IWithGroupsState {
  entities: {
    groups: KeyMap<Group>;
  };
}

export function groupsSelector(state: IWithGroupsState): KeyMap<Group> {
  return state.entities.groups;
}

function sortByName(a: Group, b: Group): number {
  return a.name.localeCompare(b.name);
}

export const getGroups = createSelector(groupsSelector, (groups: KeyMap<Group>) =>
  Object.keys(groups)
    .map((id) => groups[id])
    .sort(sortByName),
);

export const getCurrentAgentGroupsWithinPermissionScope = createSelector(
  [getGroups, getCanSeeOtherAgentsGroups, getLoggedInAgent],
  (groups, canSeeOthersGroups, currentAgent) => {
    if (canSeeOthersGroups) {
      return groups;
    }

    return groups.filter((group) => Object.keys(group.agentsPriorities).includes(currentAgent.login));
  },
);

export const getCurrentAgentGroups = createSelector([getGroups, getLoggedInAgent], (groups, currentAgent) => {
  return groups.filter((group) => currentAgent.groups.includes(group.id));
});

export const getAgentGroups = createSelector(
  [getGroups, (state: WithAgentsState, login: string) => getAgent(state, login)],
  (groups, agent) => {
    return agent && groups.filter((group) => Object.keys(group.agentsPriorities).includes(agent.login));
  },
);

export const getBotGroups = createSelector(
  [getGroups, (state: IWithBotsState, login: string) => getBot(state, login)],
  (groups, agent) => {
    return agent && groups.filter((group) => Object.keys(group.agentsPriorities).includes(agent.login));
  },
);

export const getAgentOrBotGroups = createSelector(
  [
    getGroups,
    (state: IWithBotsState & WithAgentsState, login: string, getEntitySelector: typeof getBot | typeof getAgent) =>
      getEntitySelector(state, login),
  ],
  (groups, agent) => {
    return agent && groups.filter((group) => Object.keys(group.agentsPriorities).includes(agent.login));
  },
);

export function getGroup(state: IWithGroupsState, groupId: GroupId): Group | null {
  return state.entities.groups[groupId] || null;
}

export function groupExists(state: IWithGroupsState, groupId: GroupId): boolean {
  return !!getGroup(state, groupId);
}

export function getGroupsById(state: IWithGroupsState, groupIds: GroupId[]): Group[] {
  const allGroups = Object.values(state.entities.groups);

  return allGroups.filter((group) => groupIds.includes(group.id));
}

export function getGroupName(state: IWithGroupsState, groupId: GroupId): string {
  const group = getGroup(state, groupId);

  return group ? group.name : '';
}

export function getGroupLanguageCode(state: IWithGroupsState, groupId: GroupId): LanguageCode {
  const group = getGroup(state, groupId);

  return group?.language as LanguageCode;
}

export function getLastGroupLanguageCode(state: IWithGroupsState, groupIds: GroupId[]): LanguageCode {
  const group = getGroup(state, groupIds[groupIds.length - 1]);

  return group?.language as LanguageCode;
}

export const getGroupsWithAtLeastOneOnlineAgent: (state: IWithGroupsState) => GroupExtended[] = createSelector(
  getGroups,
  getOnlineAgentsAndBots,
  getLoggedInAgentLogin,
  (groups, agents, currentAgent) => {
    return groups.reduce<GroupExtended[]>((acc, group) => {
      const agentsInGroup = agents.filter((agent) =>
        shouldIncludeAgentInGroupAgentPriorities(agent, currentAgent, group),
      );

      if (agentsInGroup.length) {
        acc.push({ ...group, onlineAgents: agentsInGroup.length });
      }

      return acc;
    }, []);
  },
);

export const getAllGroups: (state: IWithGroupsState) => GroupExtended[] = createSelector(
  getGroups,
  getOnlineAgentsAndBots,
  getLoggedInAgentLogin,
  (groups, agents, currentAgent) => {
    return groups.reduce<GroupExtended[]>((acc, group) => {
      const onlineAgents = agents.filter((agent) =>
        shouldIncludeAgentInGroupAgentPriorities(agent, currentAgent, group),
      ).length;

      acc.push({ ...group, onlineAgents });

      return acc;
    }, []);
  },
);
