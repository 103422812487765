import { type ReactNode, createElement } from 'react';

function mapNodeListToReactElements(nodes: NodeListOf<ChildNode>, allowedNodeNames: string[]): ReactNode[] {
  return Array.from(nodes).map((node: ChildNode, index: number) => {
    const props: Record<string, unknown> = { key: index };

    if (node.nodeName === '#text') {
      return node.textContent;
    }

    const element = node as HTMLElement;

    if (!allowedNodeNames.includes(element.nodeName)) {
      return element.hasChildNodes() ? mapNodeListToReactElements(element.childNodes, allowedNodeNames) : null;
    }

    if (element.nodeName === 'A' && element.hasAttribute('href')) {
      props.href = element.getAttribute('href');
      props.target = '_blank';
      props.rel = 'nofollow noopener';
    }

    return createElement(element.nodeName.toLowerCase(), {
      ...props,
      ...(element.hasChildNodes() && {
        children: mapNodeListToReactElements(element.childNodes, allowedNodeNames),
      }),
    });
  });
}

export function mapTextToReactElements(text: string, allowedNodeNames: string[]): ReactNode {
  const parser = new DOMParser();
  const { body } = parser.parseFromString(text, 'text/html');

  return body ? mapNodeListToReactElements(body.childNodes, allowedNodeNames) : '';
}

export function decodeEntities(text: string): string {
  if (!text) {
    return '';
  }

  const parser = new DOMParser();
  const decodedString = parser.parseFromString(text, 'text/html').documentElement.textContent || '';

  return decodedString;
}
