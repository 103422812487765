// @ts-strict-ignore
import { UserGuidedTourActionNames, type UserGuidedTourActionType } from './actions';
import { type IUserGuidedToursState } from './interfaces';

const initialState: IUserGuidedToursState = {
  toursVisible: [],
  toursReady: [],
};

export const userGuidedToursReducer = (
  state: IUserGuidedToursState = initialState,
  action?: UserGuidedTourActionType
): IUserGuidedToursState => {
  switch (action.type) {
    case UserGuidedTourActionNames.SHOW_TOUR:
    case UserGuidedTourActionNames.HIDE_TOUR: {
      const { type } = action;
      const { tourId } = action.payload;
      const isVisible = type === UserGuidedTourActionNames.SHOW_TOUR;

      return {
        ...state,
        toursVisible: isVisible ? [...state.toursVisible, tourId] : state.toursVisible.filter((id) => id !== tourId),
      };
    }
    case UserGuidedTourActionNames.TOUR_READY: {
      const { tourId, isReady } = action.payload;

      return {
        ...state,
        toursReady: isReady ? [...state.toursReady, tourId] : state.toursReady.filter((id) => id !== tourId),
      };
    }

    default:
      return state;
  }
};
