// @ts-strict-ignore
import { AccountsApi } from './accounts/api';
import { AgentPropertiesApi } from './agent-properties/api';
import { AmplitudeApi } from './amplitude/api';
import ApiClient from './api-client';
import { ArchiveApi } from './archive/api';
import { AutoAccessRulesApi } from './auto-access-rules/api';
import { BannedCustomersApi } from './ban-customer/api';
import { BenchmarkApi } from './benchmark/api';
import { BillingApi } from './billing/api';
import { BillingApiV3 } from './billing-v3/api';
import { ButtonsApi } from './buttons/api';
import { CampaignsApi } from './campaigns/api';
import { CannedResponseApi } from './canned-response/api';
import { ChannelsActivityApi } from './channels-activity/api';
import { ChatHistoryApi } from './chat-history/api';
import { ChatPageApi } from './chat-page/api';
import { ChatbotApi } from './chatbot-api/api';
import { ChatbotInLivechatApi } from './chatbot-in-livechat-api/api';
import { ClientSsoApi } from './client-sso/api';
import { CodeInstructionsApi } from './code-instructions/api';
import { CompanyDetailsApi } from './company-details/api';
import { CompanyDetailsWithEnrichmentApi } from './company-details-with-enrichment/api';
import { DevelopersApi } from './developers/api';
import { EmailSubscriptionApi } from './email-subscription/api';
import { EmailiniumApi } from './emailinium/api';
import { ExportApi } from './export/api';
import { EyeCatcherApi } from './eye-catcher/api';
import { FacebookMessengerApi } from './facebook-messenger/api';
import { FeatureControlApi } from './feature-control/api';
import { FlashScreenshotApi } from './flash-screenshot/api';
import { FormsApi } from './forms/api';
import { GreetingApi } from './greeting/api';
import { GroupApi } from './group/api';
import { HelpDeskApi } from './helpdesk/helpdesk-api';
import { InAppMessagesApi } from './in-app-messages/api';
import { InAppUpgradesApi } from './in-app-upgrades/api';
import { InsightsApi } from './insights-api/api';
import { IntegrationsApi } from './integrations/api';
import { InvoiceApi } from './invoice/api';
import { LanguagesApi } from './languages/api';
import { LegacyIntegrationsApi } from './legacy-integrations/api';
import { LicensePropertiesApi } from './license-properties/api';
import { LogoApi } from './logo/api';
import { LookupApi } from './lookup/api';
import { OnboardingApi } from './onboarding/api';
import { PreviewApi } from './preview/api';
import { ProductCartApi } from './product-cart/api';
import { ReportApiV2 } from './report/v2/api';
import { ReportApiV3 } from './report/v3/api';
import { SessionApi } from './session/api';
import { SubscriptionApi } from './subscription/api';
import { PostchatSurveyApi } from './surveys/postchat/api';
import { PrechatSurveyApi } from './surveys/prechat/api';
import { TagApi } from './tag/api';
import { TicketApi } from './ticket/api';
import { TicketRatingApi } from './ticket-rating/api';
import { TranscriptApi } from './transcript/api';
import { TrustedDomainsApi } from './trusted-domains/api';
import { WebhooksApi } from './webhooks/api';

interface BaseApiConstructor<T> {
  new (api: ApiClient): T;
}

type ApiType =
  | AccountsApi
  | AgentPropertiesApi
  | AmplitudeApi
  | ArchiveApi
  | AutoAccessRulesApi
  | BenchmarkApi
  | BillingApi
  | BillingApiV3
  | CampaignsApi
  | CannedResponseApi
  | ChannelsActivityApi
  | CodeInstructionsApi
  | ChatHistoryApi
  | ChatPageApi
  | ChatbotApi
  | ChatbotInLivechatApi
  | ClientSsoApi
  | CompanyDetailsApi
  | CompanyDetailsWithEnrichmentApi
  | DevelopersApi
  | EmailiniumApi
  | EmailSubscriptionApi
  | ExportApi
  | FacebookMessengerApi
  | FeatureControlApi
  | FlashScreenshotApi
  | FormsApi
  | GreetingApi
  | GroupApi
  | HelpDeskApi
  | InAppMessagesApi
  | InAppUpgradesApi
  | InsightsApi
  | IntegrationsApi
  | InvoiceApi
  | LanguagesApi
  | LegacyIntegrationsApi
  | LicensePropertiesApi
  | LogoApi
  | LookupApi
  | OnboardingApi
  | PreviewApi
  | ProductCartApi
  | ReportApiV2
  | ReportApiV3
  | SessionApi
  | SubscriptionApi
  | TagApi
  | TicketApi
  | TicketRatingApi
  | TranscriptApi
  | TrustedDomainsApi
  | ButtonsApi
  | WebhooksApi
  | EyeCatcherApi
  | PostchatSurveyApi
  | PrechatSurveyApi
  | BannedCustomersApi;

class ApiManager {
  private apiClient: ApiClient;
  private billingApiClient: ApiClient;
  private billingApiV3Client: ApiClient;
  private accountsApiClient: ApiClient;
  private devPlatform: ApiClient;
  private facebookApiClient: ApiClient;
  private flashApiClient: ApiClient;
  private chatbotApiClient: ApiClient;
  private chatbotInLivechatApiClient: ApiClient;
  private helpDeskApiClient: ApiClient;
  private insightsApiClient: ApiClient;
  private featureControlApiClient: ApiClient;
  private emailiniumApiClient: ApiClient;

  private apiInstances: Map<BaseApiConstructor<ApiType>, ApiType> = new Map();

  constructor() {
    this.apiClient = new ApiClient('api');
    this.billingApiClient = new ApiClient('billing');
    this.billingApiV3Client = new ApiClient('billing');
    this.devPlatform = new ApiClient('devPlatform');
    this.accountsApiClient = new ApiClient('accounts');
    this.facebookApiClient = new ApiClient('facebookMessengerApiUrl');
    this.flashApiClient = new ApiClient('flashApiUrl');
    this.chatbotApiClient = new ApiClient('chatBotApiUrl');
    this.chatbotInLivechatApiClient = new ApiClient('chatbotInLivechatApiUrl');

    this.helpDeskApiClient = new ApiClient('helpdeskApiUrl', { passTimezone: false });
    this.insightsApiClient = new ApiClient('insightsApiUrl');
    this.featureControlApiClient = new ApiClient('featureControlApiUrl');
    this.emailiniumApiClient = new ApiClient('emailiniumApiUrl');
  }

  private getApiInstance = (
    TypeConstructor: BaseApiConstructor<ApiType>,
    apiClient: ApiClient = this.apiClient,
  ): ApiType => {
    let instance = this.apiInstances.get(TypeConstructor);

    if (!instance) {
      instance = new TypeConstructor(apiClient);
      this.apiInstances.set(TypeConstructor, instance);
    }

    return instance;
  };

  public get accountsApi(): AccountsApi {
    return this.getApiInstance(AccountsApi, this.accountsApiClient) as AccountsApi;
  }

  public get agentPropertiesApi(): AgentPropertiesApi {
    return this.getApiInstance(AgentPropertiesApi) as AgentPropertiesApi;
  }

  public get archiveApi(): ArchiveApi {
    return this.getApiInstance(ArchiveApi) as ArchiveApi;
  }

  public get bannedCustomersApi(): BannedCustomersApi {
    return this.getApiInstance(BannedCustomersApi) as BannedCustomersApi;
  }

  public get chatHistoryApi(): ChatHistoryApi {
    return this.getApiInstance(ChatHistoryApi) as ChatHistoryApi;
  }

  public get chatPageApi(): ChatPageApi {
    return this.getApiInstance(ChatPageApi) as ChatPageApi;
  }

  public get cannedResponsesApi(): CannedResponseApi {
    return this.getApiInstance(CannedResponseApi) as CannedResponseApi;
  }

  public get codeInstructionsApi(): CodeInstructionsApi {
    return this.getApiInstance(CodeInstructionsApi) as CodeInstructionsApi;
  }

  public get eyeCatcherApi(): EyeCatcherApi {
    return this.getApiInstance(EyeCatcherApi) as EyeCatcherApi;
  }

  public get legacyIntegrationsApi(): LegacyIntegrationsApi {
    return this.getApiInstance(LegacyIntegrationsApi) as LegacyIntegrationsApi;
  }

  public get licensePropertiesApi(): LicensePropertiesApi {
    return this.getApiInstance(LicensePropertiesApi) as LicensePropertiesApi;
  }

  public get emailSubscriptionApi(): EmailSubscriptionApi {
    return this.getApiInstance(EmailSubscriptionApi) as EmailSubscriptionApi;
  }

  public get facebookMessengerApi(): FacebookMessengerApi {
    return this.getApiInstance(FacebookMessengerApi, this.facebookApiClient) as FacebookMessengerApi;
  }

  public get greetingApi(): GreetingApi {
    return this.getApiInstance(GreetingApi) as GreetingApi;
  }

  public get groupApi(): GroupApi {
    return this.getApiInstance(GroupApi) as GroupApi;
  }

  public get integrationsApi(): IntegrationsApi {
    return this.getApiInstance(IntegrationsApi, this.devPlatform) as IntegrationsApi;
  }

  public get inAppUpgradesApi(): InAppUpgradesApi {
    return this.getApiInstance(InAppUpgradesApi, this.devPlatform) as InAppUpgradesApi;
  }

  public get inAppMessagesApi(): InAppMessagesApi {
    return this.getApiInstance(InAppMessagesApi) as InAppMessagesApi;
  }

  public get developersApi(): DevelopersApi {
    return this.getApiInstance(DevelopersApi, this.devPlatform) as DevelopersApi;
  }

  public get logoApi(): LogoApi {
    return this.getApiInstance(LogoApi) as LogoApi;
  }

  public get lookupApi(): LookupApi {
    return this.getApiInstance(LookupApi) as LookupApi;
  }

  public get onboardingApi(): OnboardingApi {
    return this.getApiInstance(OnboardingApi) as OnboardingApi;
  }

  public get previewApi(): PreviewApi {
    return this.getApiInstance(PreviewApi) as PreviewApi;
  }

  public get tagApi(): TagApi {
    return this.getApiInstance(TagApi) as TagApi;
  }

  public get preChatSurveyApi(): PrechatSurveyApi {
    return this.getApiInstance(PrechatSurveyApi) as PrechatSurveyApi;
  }

  public get postChatSurveyApi(): PostchatSurveyApi {
    return this.getApiInstance(PostchatSurveyApi) as PostchatSurveyApi;
  }

  public get ticketApi(): TicketApi {
    return this.getApiInstance(TicketApi) as TicketApi;
  }

  public get ticketRatingApi(): TicketRatingApi {
    return this.getApiInstance(TicketRatingApi, this.apiClient) as TicketRatingApi;
  }

  public get transcriptApi(): TranscriptApi {
    return this.getApiInstance(TranscriptApi) as TranscriptApi;
  }

  public get emailiniumApi(): EmailiniumApi {
    return this.getApiInstance(EmailiniumApi, this.emailiniumApiClient) as EmailiniumApi;
  }

  public get trustedDomainsApi(): TrustedDomainsApi {
    return this.getApiInstance(TrustedDomainsApi) as TrustedDomainsApi;
  }

  public get webhooksApi(): WebhooksApi {
    return this.getApiInstance(WebhooksApi) as WebhooksApi;
  }

  public get invoiceApi(): InvoiceApi {
    return this.getApiInstance(InvoiceApi) as InvoiceApi;
  }

  public get reportApi(): ReportApiV2 {
    return this.getApiInstance(ReportApiV2) as ReportApiV2;
  }

  public get reportApiV3(): ReportApiV3 {
    return this.getApiInstance(ReportApiV3) as ReportApiV3;
  }

  public get exportApi(): ExportApi {
    return this.getApiInstance(ExportApi) as ExportApi;
  }

  public get companyDetailsApi(): CompanyDetailsApi {
    return this.getApiInstance(CompanyDetailsApi) as CompanyDetailsApi;
  }

  public get subscriptionApi(): SubscriptionApi {
    return this.getApiInstance(SubscriptionApi) as SubscriptionApi;
  }

  public get billingApi(): BillingApi {
    return this.getApiInstance(BillingApi, this.billingApiClient) as BillingApi;
  }

  public get billingApiV3(): BillingApiV3 {
    return this.getApiInstance(BillingApiV3, this.billingApiV3Client) as BillingApiV3;
  }

  public get amplitudeApi(): AmplitudeApi {
    return this.getApiInstance(AmplitudeApi) as AmplitudeApi;
  }

  public get benchmarkApi(): BenchmarkApi {
    return this.getApiInstance(BenchmarkApi) as BenchmarkApi;
  }

  public get channelsActivityApi(): ChannelsActivityApi {
    return this.getApiInstance(ChannelsActivityApi) as ChannelsActivityApi;
  }

  public get campaignsApi(): CampaignsApi {
    return this.getApiInstance(CampaignsApi, this.devPlatform) as CampaignsApi;
  }

  public get sessionApi(): SessionApi {
    return this.getApiInstance(SessionApi, this.accountsApiClient) as SessionApi;
  }

  public get clientSsoApi(): ClientSsoApi {
    return this.getApiInstance(ClientSsoApi, this.accountsApiClient) as ClientSsoApi;
  }

  public get productCartApi(): ProductCartApi {
    return this.getApiInstance(ProductCartApi, this.devPlatform) as ProductCartApi;
  }

  public get autoAccessRulesApi(): AutoAccessRulesApi {
    return this.getApiInstance(AutoAccessRulesApi) as AutoAccessRulesApi;
  }

  public get languagesApi(): LanguagesApi {
    return this.getApiInstance(LanguagesApi) as LanguagesApi;
  }

  public get formsApi(): FormsApi {
    return this.getApiInstance(FormsApi) as FormsApi;
  }

  public get flashScreenshotApi(): FlashScreenshotApi {
    return this.getApiInstance(FlashScreenshotApi, this.flashApiClient) as FlashScreenshotApi;
  }

  public get helpDeskApi(): HelpDeskApi {
    return this.getApiInstance(HelpDeskApi, this.helpDeskApiClient) as HelpDeskApi;
  }

  public get insightsApi(): InsightsApi {
    return this.getApiInstance(InsightsApi, this.insightsApiClient) as InsightsApi;
  }

  public get chatbotApi(): ChatbotApi {
    return this.getApiInstance(ChatbotApi, this.chatbotApiClient) as ChatbotApi;
  }

  public get chatbotInLivechatApi(): ChatbotInLivechatApi {
    return this.getApiInstance(ChatbotInLivechatApi, this.chatbotInLivechatApiClient) as ChatbotInLivechatApi;
  }

  public get companyDetailsWithEnrichmentApi(): CompanyDetailsWithEnrichmentApi {
    return this.getApiInstance(CompanyDetailsWithEnrichmentApi) as CompanyDetailsWithEnrichmentApi;
  }

  public get featureControlApi(): FeatureControlApi {
    return this.getApiInstance(FeatureControlApi, this.featureControlApiClient) as FeatureControlApi;
  }

  public get buttonsApi(): ButtonsApi {
    return this.getApiInstance(ButtonsApi) as ButtonsApi;
  }
}

const apiManager = new ApiManager();

export { apiManager as ApiManager };
