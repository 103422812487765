export const enum IntegrationCardName {
  BigCommerce = 'BigCommerce',
  Drupal = 'Drupal',
  Ecwid = 'Ecwid',
  GTM = 'Google Tag Manager',
  InstallCodeManually = 'InstallCode',
  Joomla = 'Joomla',
  Magento = 'Magento',
  Segment = 'Segment',
  Shopify = 'Shopify',
  SquareOnline = 'SquareOnline',
  SquareSpace = 'SquareSpace',
  Weebly = 'Weebly',
  Webflow = 'Webflow',
  Wix = 'Wix',
  WooCommerce = 'WooCommerce',
  WordPress = 'WordPress',
}
