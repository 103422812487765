// @ts-strict-ignore
import type { Product, ProductsCart } from 'interfaces/product-cart';
import { type IApplicationsState } from 'store/entities/applications/interfaces';
import { getInstalledApplications } from 'store/entities/applications/selectors';
import {
  getIsSubscriptionProcessing,
  getIsSubscriptionSubscribed,
  type IWithSubscriptionState,
} from 'store/entities/subscription/selectors';
import { getIsSubscriptionExpired, type IWithSessionState } from 'store/features/session/selectors';
import { type IWithRequestsState, createRequestFetchingSelector } from 'store/requests/selectors';

import { getApplicationsToUninstall, type IWithBillingEntitiesState } from '../billing/selectors';

import { ProductCartRequestFetchingSelectorsNames } from './actions';
import { getSuggestedItemsArray } from './constants';
import { type IProductCartState } from './interfaces';

export interface IWithProductCartEntitiesState {
  entities: {
    productCart: IProductCartState;
    applications: IApplicationsState;
  };
}

export function getIsProductCartFetching(state: IWithRequestsState): boolean {
  return createRequestFetchingSelector([ProductCartRequestFetchingSelectorsNames.LOAD_CART_REQUEST])(state);
}

export function getIsAddingProductToCart(state: IWithRequestsState, id: string): boolean {
  return createRequestFetchingSelector([`${ProductCartRequestFetchingSelectorsNames.ADD_APPLICATION_REQUEST}_${id}`])(
    state
  );
}

export function getCurrentCart(state: IWithProductCartEntitiesState): ProductsCart | null {
  return state.entities.productCart.currentCart;
}

function getCurrentCartProducts(state: IWithProductCartEntitiesState): Product[] {
  return state.entities.productCart.currentCart?.products || [];
}

export function getIsProductInCart(state: IWithProductCartEntitiesState, id: string): boolean {
  const products = getCurrentCartProducts(state);

  return products.some((product) => product.id === id);
}

export const getCartItemsCount = (state: IWithProductCartEntitiesState): number => {
  return getCurrentCartProducts(state).length;
};

export function getCurrentCartProductsToSubscribe(state: IWithProductCartEntitiesState): Product[] {
  return getCurrentCartProducts(state).filter(({ isMarkedForInstall }) => isMarkedForInstall);
}

export function getCurrentCartProductsToConfirm(state: IWithProductCartEntitiesState): Product[] {
  return getCurrentCartProducts(state).filter(({ isMarkedForInstall }) => !isMarkedForInstall);
}

export function getSuggestedApps(
  state: IWithProductCartEntitiesState &
    IWithSubscriptionState &
    IWithRequestsState &
    IWithBillingEntitiesState &
    IWithSessionState
): Product[] {
  const isSubscriptionProcessing = getIsSubscriptionProcessing(state);

  if (isSubscriptionProcessing) {
    return [];
  }

  const isExpired = getIsSubscriptionExpired(state);
  const isSubscriber = getIsSubscriptionSubscribed(state);
  const products = getCurrentCartProducts(state);
  const installedApps = getInstalledApplications(state);
  const applicationsToUninstall = getApplicationsToUninstall(state);

  return getSuggestedItemsArray({ forceAppsInstall: !isExpired && !isSubscriber }).filter(
    (suggestedItem) =>
      !products.some((cartProduct) => cartProduct.id === suggestedItem.id) &&
      !installedApps.some((installedApp) => installedApp.id === suggestedItem.id) &&
      !applicationsToUninstall.some((app) => app.id === suggestedItem.id)
  );
}
