import { AppsViewActionNames, type AppsViewAction } from './actions';
import { type AppsViewState } from './interfaces';

const initialState: AppsViewState = {
  breadcrumbs: [],
  currentMarketplaceRoute: null,
};

export const appsViewReducer = (state: AppsViewState = initialState, action: AppsViewAction): AppsViewState => {
  switch (action.type) {
    case AppsViewActionNames.SET_BREADCRUMBS: {
      const { breadcrumbs } = action.payload;

      return {
        ...state,
        breadcrumbs,
      };
    }

    case AppsViewActionNames.SET_MARKETPLACE_ROUTE: {
      const { route } = action.payload;

      return {
        ...state,
        currentMarketplaceRoute: route,
      };
    }

    default:
      return state;
  }
};
