// @ts-strict-ignore
import { ReportType } from 'constants/reports/report-type';
import { ApiManager } from 'services/api/api-manager';
import { type IActionWithPayload } from 'store/helper';

import { type IFetchReportPayload, type IReportRequest, ReportRequestName } from '../interfaces';

import { makeFetchingSaga } from './helpers';

export function* fetchTicketFirstResponseTime(action: IActionWithPayload<string, IFetchReportPayload>) {
  const requests: IReportRequest[] = [
    {
      name: ReportRequestName.FirstResponseTime,
      interface: ApiManager.reportApi.fetchTicketsFirstResponseTime,
    },
  ];

  yield makeFetchingSaga(ReportType.TicketFirstResponseTime, action, requests);
}
