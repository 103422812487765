import { createAction, type ActionsUnion } from 'store/helper';

import {
  type ICheckRecurlyCouponFailurePayload,
  type ICheckRecurlyCouponPayload,
  type IRecurlyCoupon,
  type IRedemCouponCouponFailurePayload,
} from './interfaces';

export enum RecurlyActionNames {
  CHECK_CURRENT_RECURLY_COUPON_REQUEST = 'ENTITIES/RECURLY/CHECK_CURRENT_RECURLY_COUPON_REQUEST',
  CHECK_CURRENT_RECURLY_COUPON_SUCCESS = 'ENTITIES/RECURLY/CHECK_CURRENT_RECURLY_COUPON_SUCCESS',
  CHECK_CURRENT_RECURLY_COUPON_FAILURE = 'ENTITIES/RECURLY/CHECK_CURRENT_RECURLY_COUPON_FAILURE',
  CHECK_NEW_RECURLY_COUPON_REQUEST = 'ENTITIES/RECURLY/CHECK_NEW_RECURLY_COUPON_REQUEST',
  CHECK_NEW_RECURLY_COUPON_SUCCESS = 'ENTITIES/RECURLY/CHECK_NEW_RECURLY_COUPON_SUCCESS',
  CHECK_NEW_RECURLY_COUPON_FAILURE = 'ENTITIES/RECURLY/CHECK_NEW_RECURLY_COUPON_FAILURE',
  CHECK_COVID_COUPON_REQUEST = 'ENTITIES/RECURLY/CHECK_COVID_COUPON_REQUEST',
  CHECK_COVID_COUPON_SUCCESS = 'ENTITIES/RECURLY/CHECK_COVID_COUPON_SUCCESS',
  CHECK_COVID_COUPON_FAILURE = 'ENTITIES/RECURLY/CHECK_COVID_COUPON_FAILURE',
  RESET_RECURLY_COUPONS = 'ENTITIES/RECURLY/RESET_RECURLY_COUPONS',
  REMOVE_NEW_COUPON = 'ENTITIES/RECURLY/REMOVE_NEW_COUPON',
  ACTIVATE_NEW_COUPON = 'ENTITIES/RECURLY/ACTIVATE_NEW_COUPON',
  REDEMPTION_ERROR = 'ENTITIES/RECURLY/REDEMPTION_ERROR',
}

export enum RecurlyRequestFetchingSelectorsNames {
  CHECK_CURRENT_RECURLY_COUPON_REQUEST = 'ENTITIES/RECURLY/CHECK_CURRENT_RECURLY_COUPON',
  CHECK_NEW_RECURLY_COUPON_REQUEST = 'ENTITIES/RECURLY/CHECK_NEW_RECURLY_COUPON',
}

export const RecurlyActions = {
  checkCurrentRecurlyCoupon: () => createAction(RecurlyActionNames.CHECK_CURRENT_RECURLY_COUPON_REQUEST),
  checkCurrentRecurlyCouponSuccess: (payload: IRecurlyCoupon) =>
    createAction(RecurlyActionNames.CHECK_CURRENT_RECURLY_COUPON_SUCCESS, payload),
  checkCurrentRecurlyCouponFailure: (error: ICheckRecurlyCouponFailurePayload) =>
    createAction(RecurlyActionNames.CHECK_CURRENT_RECURLY_COUPON_FAILURE, error),

  checkNewRecurlyCoupon: (payload: ICheckRecurlyCouponPayload) =>
    createAction(RecurlyActionNames.CHECK_NEW_RECURLY_COUPON_REQUEST, payload),
  checkNewRecurlyCouponSuccess: (payload: IRecurlyCoupon) =>
    createAction(RecurlyActionNames.CHECK_NEW_RECURLY_COUPON_SUCCESS, payload),
  checkNewRecurlyCouponFailure: (error: ICheckRecurlyCouponFailurePayload) =>
    createAction(RecurlyActionNames.CHECK_NEW_RECURLY_COUPON_FAILURE, error),

  checkCovidCoupon: (payload: ICheckRecurlyCouponPayload) =>
    createAction(RecurlyActionNames.CHECK_COVID_COUPON_REQUEST, payload),
  checkCovidCouponSuccess: (payload: IRecurlyCoupon) =>
    createAction(RecurlyActionNames.CHECK_COVID_COUPON_SUCCESS, payload),
  checkCovidCouponFailure: (error: ICheckRecurlyCouponFailurePayload) =>
    createAction(RecurlyActionNames.CHECK_COVID_COUPON_FAILURE, error),

  resetRecurlyCoupons: () => createAction(RecurlyActionNames.RESET_RECURLY_COUPONS),
  removeNewCoupon: () => createAction(RecurlyActionNames.REMOVE_NEW_COUPON),
  activateNewCoupon: () => createAction(RecurlyActionNames.ACTIVATE_NEW_COUPON),
  redemptionError: (error: IRedemCouponCouponFailurePayload) =>
    createAction(RecurlyActionNames.REDEMPTION_ERROR, error),
};

export type RecurlyActionTypes = ActionsUnion<typeof RecurlyActions>;
