import { ChatEventType } from 'constants/chat-event-type';
import { ReportType } from 'constants/reports/report-type';
import { prepareRoutingFilters } from 'helpers/filters';
import { deepMerge } from 'helpers/object';
import { type ISerializedFilters } from 'interfaces/reports';

const chatsWithoutOfflineMessages: ISerializedFilters = prepareRoutingFilters('offline_message', { exists: false });
const chatsWithUnreplied: ISerializedFilters = prepareRoutingFilters('unreplied', { values: [true] });

export const staticReportFilters: Partial<Record<ReportType, ISerializedFilters>> = {
  [ReportType.TotalChats]: chatsWithoutOfflineMessages,
  [ReportType.TotalChatsHeatmap]: chatsWithoutOfflineMessages,
  [ReportType.ChatEngagement]: chatsWithoutOfflineMessages,
  [ReportType.TagsUsage]: chatsWithoutOfflineMessages,
  [ReportType.ChatDuration]: deepMerge(true, {}, chatsWithoutOfflineMessages),
  [ReportType.MissedChats]: {
    ...chatsWithUnreplied,
    agentAssignment: 'true',
    eventTypes: [ChatEventType.Message],
  },
  [ReportType.MissedChatsHeatmap]: {
    ...chatsWithUnreplied,
    agentAssignment: 'true',
    eventTypes: [ChatEventType.Message],
  },
  [ReportType.ChatResponseTime]: {
    agentResponse: {
      exists: true,
    },
  },
  [ReportType.AgentsPerformance]: {
    agentAssignment: 'true',
  },
};
