import { createQueueApiClient } from '../client';

import { type QueueApiErrorResponse, type LogRequest } from './types';

const client = createQueueApiClient();

export const logsClient = {
  log: async (data: LogRequest) => {
    return client.post<LogRequest, QueueApiErrorResponse>('logs', {
      json: data,
    });
  },
};
