import { type Language, type LanguagesState } from '../../../interfaces/language';

import { type LanguagesAction, LanguagesActionNames } from './actions';

const initialState: LanguagesState = {};

export function languagesReducer(state: LanguagesState = initialState, action: LanguagesAction): LanguagesState {
  switch (action.type) {
    case LanguagesActionNames.SET_LANGUAGE: {
      const { language, groupId } = action.payload;

      return {
        ...state,
        [groupId]: language,
      };
    }

    case LanguagesActionNames.UPDATE_LANGUAGE_SUCCESS: {
      const { phrases, groupId } = action.payload;
      const prevLanguage = state[groupId];

      const updatedLanguage: Language = {
        ...prevLanguage,
        phrases: {
          ...prevLanguage.phrases,
          ...phrases,
        },
      };

      return {
        ...state,
        [groupId]: updatedLanguage,
      };
    }

    case LanguagesActionNames.FETCH_LANGUAGE: {
      const { groupId } = action.payload;
      const language = state[groupId];

      if (!language) {
        return state;
      }

      return {
        ...state,
        [groupId]: {
          ...language,
          isFetching: true,
        },
      };
    }

    default:
      return state;
  }
}
