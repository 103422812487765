import type { UserGuidedTourType } from 'constants/user-guided-tour-type';;

import { type IWithUserGuidedToursState } from './interfaces';

export const isTourReady = (state: IWithUserGuidedToursState, tourId: UserGuidedTourType): boolean => {
  return state.features.userGuidedTours.toursReady.includes(tourId);
};

export const isTourVisible = (state: IWithUserGuidedToursState, tourId: UserGuidedTourType): boolean => {
  return state.features.userGuidedTours.toursVisible.includes(tourId);
};
