/* eslint-disable @typescript-eslint/naming-convention */
import isNumber from 'lodash.isnumber';
import keyBy from 'lodash.keyby';
import { type SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import { HOUR_IN_SECONDS } from 'constants/time-units';
import { type KeyMap } from 'helpers/interface';
import { round, sumReducer } from 'helpers/numbers';
import { deepMerge } from 'helpers/object';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import type {
  IAgentsPerformanceAgentStat,
  IAgentsPerformanceData,
  IAgentsPerformancePeriodData,
  IAgentsPerformanceSummary,
} from 'interfaces/reports';
import { type ReportData } from 'interfaces/reports/api-v3';
import { agentsAndBotsSelector } from 'store/views/team/computed';


interface IAgentsPerformancePeriodPayload {
  data: {
    performance: ReportData<IAgentsPerformancePeriodData, IAgentsPerformanceSummary>;
  };
}

export function* agentsPerformanceDeserializer({ data }: IAgentsPerformancePeriodPayload, index): SagaIterator {
  const valueKey = index === 0 ? 'value' : 'compare';
  const chatStats: IAgentsPerformanceSummary = data.performance.summary || {
    chats_count: 0,
    chats_rated_good: 0,
    chats_rated_bad: 0,
    first_response_time: null,
    first_response_chats_count: 0,
  };

  const storedAgents: KeyMap<IAgentBase> = yield select(agentsAndBotsSelector);

  const agents = data.performance.records;

  const efficiencies: number[] = [];

  const agentsReport: IAgentsPerformanceAgentStat[] = Object.keys(agents).map((login) => {
    const agent = agents[login];
    const ratedChats = agent.chats_rated_good + agent.chats_rated_bad;
    const agentEfficiency =
      isNumber(agent.chats_count) && isNumber(agent.accepting_chats_time)
        ? round(agent.chats_count / (agent.accepting_chats_time / HOUR_IN_SECONDS || 1), 1)
        : null;

    if (agentEfficiency) {
      efficiencies.push(agentEfficiency);
    }

    return {
      login,
      isBot: agent.is_bot ?? false,
      isRemoved: !storedAgents[login],
      chatsCount: {
        [valueKey]: agent.chats_count,
      },
      satisfaction: {
        [valueKey]: ratedChats ? round(agent.chats_rated_good / ratedChats, 3) : null,
      },
      acceptingTime: {
        [valueKey]: isNumber(agent.accepting_chats_time) && round(agent.accepting_chats_time / HOUR_IN_SECONDS, 2),
      },
      notAcceptingTime: {
        [valueKey]:
          isNumber(agent.not_accepting_chats_time) && round(agent.not_accepting_chats_time / HOUR_IN_SECONDS, 2),
      },
      loggedInTime: {
        [valueKey]: isNumber(agent.logged_in_time) && round(agent.logged_in_time / HOUR_IN_SECONDS, 2),
      },
      percentageOfAllChats: {
        [valueKey]:
          isNumber(agent.chats_count) && chatStats.chats_count && round(agent.chats_count / chatStats.chats_count, 3),
      },
      chatsPerHour: {
        [valueKey]: agentEfficiency,
      },
      firstResponseTime: {
        [valueKey]:
          agent.first_response_time && agent.first_response_time !== 0
            ? agent.first_response_time / HOUR_IN_SECONDS
            : null,
      },
      chatsLimit: agent.max_chats_count,
      chattingTime: {
        [valueKey]: isNumber(agent.chatting_time) && round(agent.chatting_time / HOUR_IN_SECONDS, 2),
      },
    } as IAgentsPerformanceAgentStat;
  });

  const ratedChats = chatStats.chats_rated_good + chatStats.chats_rated_bad;
  const agentsAcceptingChats = agentsReport.filter((agent) => agent.acceptingTime[valueKey]).length;
  const efficiency = efficiencies.reduce(sumReducer, 0) / Math.max(1, agentsAcceptingChats);

  const result: IAgentsPerformanceData = {
    agents: keyBy(agentsReport, 'login'),
    stats: {
      chatsCount: {
        [valueKey]: chatStats.chats_count,
      },
      satisfaction: {
        [valueKey]: ratedChats ? round(chatStats.chats_rated_good / ratedChats, 2) : null,
      },
      chatsPerHour: {
        [valueKey]: efficiency && round(efficiency, 1),
      },
      firstResponseTime: {
        [valueKey]:
          chatStats.first_response_time && chatStats.first_response_time !== 0 ? chatStats.first_response_time : null,
      },
    },
  };

  return result;
}

/**
 * Merge datasets and calculate the change
 */
export function agentsPerformanceDataMutator(dataSets: { data: IAgentsPerformanceData }[]): IAgentsPerformanceData {
  const merged: IAgentsPerformanceData = deepMerge(true, {}, ...dataSets.map(({ data }) => data));

  if (dataSets.length > 1) {
    const allAgentsIds = Object.keys(merged.agents);

    const compareKeys = [
      'chatsCount',
      'satisfaction',
      'acceptingTime',
      'notAcceptingTime',
      'loggedInTime',
      'percentageOfAllChats',
      'chatsPerHour',
      'firstResponseTime',
      'chattingTime',
    ];

    allAgentsIds.forEach((agent) => {
      compareKeys.forEach((key) => {
        const isNullable = merged.agents[agent][key].value === null || merged.agents[agent][key].compare === null;

        merged.agents[agent][key].change = !isNullable
          ? merged.agents[agent][key].value - merged.agents[agent][key].compare
          : null;
      });
    });
  }

  return merged;
}
