import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { GoalType } from 'constants/goal';
import { QueryKey } from 'constants/query-key';
import { deserializeBaseGoal } from 'services/api/goal/serializer';
import { goalsApi } from 'services/connectivity/rest-api/goals/client';

export function useGoals(options?: UseQueryOptions<Awaited<ReturnType<typeof fetchGoals>>>) {
  return useQuery({
    queryKey: [QueryKey.Goals],
    queryFn: fetchGoals,
    ...options,
  });
}

async function fetchGoals() {
  const { result, error } = await goalsApi.fetchGoals();

  if (error) {
    throw error;
  }

  return result.filter((item) => item.type !== GoalType.Sale).map((item) => deserializeBaseGoal(item));
}
