import { Calendar } from '@livechat/design-system-icons';
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { CustomDateRangeFilterId, type DateRangeFilter } from 'constants/filters/date-range-filter';
import { Filter } from 'constants/filters/filter';
import { getCustomDateRange } from 'helpers/filters';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

export const creationDateRangeFilterConfig: FilterConfig = {
  title: 'Creation date',
  multiple: false,
  icon: Calendar,
  getFilterLabelValues: (filterValues: DateRangeFilter): FilterLabelValue[] => {
    if (filterValues === null) {
      return [];
    }

    const dateRangeId: CustomDateRangeFilterId = getCustomDateRange(filterValues.from, filterValues.to);
    const labelsMap = {
      [CustomDateRangeFilterId.Today]: 'Created today',
      [CustomDateRangeFilterId.Yesterday]: 'Created yesterday',
      [CustomDateRangeFilterId.Last7Days]: 'Created in last 7 days',
      [CustomDateRangeFilterId.LastMonth]: 'Created last month',
      [CustomDateRangeFilterId.CurrentMonth]: 'Created current month',
    };
    const label =
      labelsMap[dateRangeId] ||
      `Created: ${format(filterValues.from, DateFormat.ISO8601Date)} – ${format(
        filterValues.to,
        DateFormat.ISO8601Date
      )}`;

    return [
      {
        key: Filter.CreationDateRange,
        label,
      },
    ];
  },
};
