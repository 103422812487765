import { type SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type ILanguagesResult } from 'services/api/languages/interfaces';
import { LanguagesActionNames } from 'store/entities/languages/actions';
import { type SetLanguageDataPayload } from 'interfaces/language';
import { deserializeLanguage } from 'store/entities/languages/serializers';
import { type IActionWithPayload } from 'store/helper';

import { getSelectedGroupId } from '../selectors';

import { LanguageViewActionNames, LanguageViewActions } from './actions';
import { type ISetCurrentLanguagePayload } from './interfaces';

function* setCurrentLanguageCode(action: IActionWithPayload<string, ISetCurrentLanguagePayload>): SagaIterator {
  const { languageCode } = action.payload;
  const { languagesApi } = ApiManager;

  const groupId: ReturnType<typeof getSelectedGroupId> = yield select(getSelectedGroupId);
  const { result }: RequestResult<ILanguagesResult> = yield call(languagesApi.fetchLanguage, languageCode, groupId);

  yield put(LanguageViewActions.setCurrentLanguage({ language: deserializeLanguage(result, languageCode) }));
}

function* setCurrentLanguage(action: IActionWithPayload<string, SetLanguageDataPayload>): SagaIterator {
  const { language } = action.payload;
  yield put(LanguageViewActions.setCurrentLanguage({ language }));
}

export function* languageViewSaga(): SagaIterator {
  yield takeEvery(LanguagesActionNames.FETCH_LANGUAGE_SUCCESS, setCurrentLanguage);
  yield takeEvery(LanguageViewActionNames.SET_CURRENT_LANGUAGE_CODE, setCurrentLanguageCode);
}
