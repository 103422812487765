// @ts-strict-ignore
import { WidgetContent } from 'constants/widget/content';
import { initialWidgetSnapshotConfigProps } from 'helpers/widget-preview';
import { WidgetVisibility } from 'interfaces/widget-state';

import { type WidgetSnapshotAction, WidgetSnapshotActionNames } from './actions';
import type { IWidgetState } from './interfaces';

const initialState: IWidgetState = {
  snapshot: {
    visibility: WidgetVisibility.Maximized,
    content: WidgetContent.Chat,
    mobile: false,
    fields: {
      preChat: [],
      postChat: [],
      offline: [],
    },
    emptyStateIsVisible: false,
    ...initialWidgetSnapshotConfigProps,
  },
};

export function widgetReducer(state: IWidgetState = initialState, action: WidgetSnapshotAction): IWidgetState {
  switch (action.type) {
    case WidgetSnapshotActionNames.SET_SNAPSHOT:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          ...action.payload,
          localization: {
            ...state.snapshot.localization,
            ...action.payload.localization,
          },
        },
      };

    case WidgetSnapshotActionNames.SET_SNAPSHOT_PRECHAT_FIELDS:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          fields: {
            ...state.snapshot.fields,
            preChat: action.payload,
          },
        },
      };

    case WidgetSnapshotActionNames.SET_SNAPSHOT_POSTCHAT_FIELDS:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          fields: {
            ...state.snapshot.fields,
            postChat: action.payload,
          },
        },
      };

    case WidgetSnapshotActionNames.SET_SNAPSHOT_OFFLINE_FIELDS:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          fields: {
            ...state.snapshot.fields,
            offline: action.payload,
          },
        },
      };

    case WidgetSnapshotActionNames.SET_SNAPSHOT_FEATURES:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          features: {
            ...state.snapshot.features,
            ...action.payload,
          },
        },
      };

    default:
      return state;
  }
}
