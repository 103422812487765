import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { ServerError } from 'constants/server-error';
import { redirectToAccounts, redirectToAccountsSignout } from 'helpers/redirect-to-accounts';
import {
  isLicenseBlockedInCRM,
  isLicenseExpired,
  isRequestSuspended,
  isRequestUnauthorized,
  isRequestWaitingForApproval,
  type ApiError,
} from 'helpers/request-decoder';
import { type IMessageErrorPayload } from 'interfaces/incoming-message';
import { type SignoutReason } from 'interfaces/session';
import { ApiErrorMessagePart } from 'services/api/constants';
import {
  ConfigurationApiErrorMessage,
  type ConfigurationApiNormalizedError,
} from 'services/connectivity/configuration-api/types';
import { type GlobalAccountsApiNormalizedError } from 'services/connectivity/global-accounts-api/types';
import { getReconnector } from 'services/connectivity/reconnector/service';

import { handleServerError } from './handle-server-error';

const log = debug(DebugLogsNamespace.AppServerConnection);

function mapConfigurationApiErrorToServerError(error: ConfigurationApiNormalizedError): ServerError | null {
  switch (error.message as ConfigurationApiErrorMessage) {
    case ConfigurationApiErrorMessage.AwaitingApproval:
      return ServerError.WaitingForApproval;
    case ConfigurationApiErrorMessage.UserNotActive:
      return ServerError.LicenseBlockedInCRM;
    case ConfigurationApiErrorMessage.AccountSuspended:
      return ServerError.AccountSuspended;
    default:
      break;
  }

  switch (error.type) {
    case 'too_many_requests':
      return ServerError.TooManyRequests;
    case 'authentication':
    case 'authorization':
      return ServerError.InvalidSession;
    case 'license_expired':
      return ServerError.LicenseExpired;
  }

  return null;
}

function mapAccountsApiErrorToServerError(error: GlobalAccountsApiNormalizedError): ServerError | null {
  switch (error.error) {
    case 'unauthorized_client':
    case 'unauthorized':
      return ServerError.InvalidSession;
  }

  return null;
}

function mapApiErrorToServerError(error: ApiError): ServerError | null {
  if (isLicenseBlockedInCRM(error)) {
    return ServerError.LicenseBlockedInCRM;
  }

  if (isLicenseExpired(error)) {
    return ServerError.LicenseExpired;
  }

  if (isRequestUnauthorized(error)) {
    return ServerError.InvalidSession;
  }

  if (isRequestSuspended(error)) {
    return ServerError.AccountSuspended;
  }

  if (isRequestWaitingForApproval(error)) {
    return ServerError.WaitingForApproval;
  }

  return null;
}

export async function handleConfigurationApiError(error: ConfigurationApiNormalizedError): Promise<void> {
  const serverError = mapConfigurationApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received configuration api error', serverError);

  await handleServerError(serverError, error);
}

export async function handleAccountsApiError(error: GlobalAccountsApiNormalizedError): Promise<void> {
  const serverError = mapAccountsApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received global accounts api error', serverError);

  await handleServerError(serverError, error);
}

export async function handleApiError(error: ApiError): Promise<void> {
  const serverError = mapApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received api error', serverError);

  await handleServerError(serverError, error);
}

export async function handleLoginFailure(error: IMessageErrorPayload | Error): Promise<void> {
  log('Login failure', error);
  const payload = error as IMessageErrorPayload;

  switch (payload.error?.type) {
    case 'license_expired':
      await handleServerError(ServerError.LicenseExpired, error);

      return;

    case 'seats_limit_exceeded':
      await handleServerError(ServerError.TooManyAgents, error);

      return;

    case 'requester_awaiting_approval':
      await handleServerError(ServerError.WaitingForApproval, error);

      return;

    case 'requester_suspended':
      await handleServerError(ServerError.AccountSuspended, error);

      return;

    case 'authentication':
    case 'authorization':
      if (payload.error.message === (ApiErrorMessagePart.IpNotAllowed as string)) {
        await handleServerError(ServerError.IpNotAllowed, error);
      } else if (
        [ApiErrorMessagePart.AlreadyLoggedIn, ApiErrorMessagePart.AlreadyLoggingIn].includes(
          payload.error.message as ApiErrorMessagePart,
        )
      ) {
        // We reconnected too quickly after getting back online
        // or the restored connection did not require logging in
        log('Authorization error: Agent already logged in');
        getReconnector().loginSuccessful();
      } else if (payload.error.message === (ApiErrorMessagePart.InvalidAccessToken as string)) {
        redirectToAccounts();
      } else {
        Sentry.captureException(new Error(`Authorization error (payload=${JSON.stringify(error)})`));
        redirectToAccountsSignout(payload.error?.message as SignoutReason);
      }

      return;

    case 'too_many_requests':
      await handleServerError(ServerError.TooManyRequests, error);

      return;
  }

  Sentry.captureException(new Error(`Login failure unknown error (payload=${JSON.stringify(error)})`));
  redirectToAccountsSignout();
}
