import { type KeyMap } from 'helpers/interface';
import type { CannedResponse } from 'interfaces/canned-responses';
import { createAction, type ActionsUnion } from 'store/helper';
import { type IApplyAutotags } from 'store/views/chats/interfaces';

import type {
  ISaveCannedResponsePayload,
  ISaveCannedResponseFailurePayload,
  ISetCannedResponsesDataPayload,
  IRemoveCannedResponsePayload,
  ICannedResponseSuccessPayload,
  IRemoveCannedResponseFailurePayload,
  IFetchCannedResponsesPayload,
  IFetchCannedResponseFailurePayload,
  ICannedResponseAutotagsUpdatePayload,
  ICannedResponseAutotagsCreatePayload,
  IFetchCannedAutotagsSuccessPayload,
  ICannedResponseAutotagsRemovePayload,
  IAutotagRemovePayload,
  IAutoTagRemoveSuccessPayload,
  ICannedResponseAutoTagsCreateSuccessPayload,
  ICannedResponseAutotagsUpdateSuccessPayload,
  IBatchAutotagRemovePayload,
} from './interfaces';

export enum CannedResponseActionNames {
  FETCH_CANNED_RESPONSES = 'ENTITIES/CANNED_RESPONSES/FETCH_CANNED_RESPONSES',
  FETCH_CANNED_RESPONSES_REQUEST = 'ENTITIES/CANNED_RESPONSES/FETCH_CANNED_RESPONSES_REQUEST',
  FETCH_CANNED_RESPONSES_SUCCESS = 'ENTITIES/CANNED_RESPONSES/FETCH_CANNED_RESPONSES_SUCCESS',
  FETCH_CANNED_RESPONSES_FAILURE = 'ENTITIES/CANNED_RESPONSES/FETCH_CANNED_RESPONSES_FAILURE',

  ADD_CANNED_RESPONSE_DATA = 'ENTITIES/CANNED_RESPONSE/ADD_CANNED_RESPONSE_DATA',
  SAVE_CANNED_RESPONSE = 'ENTITIES/CANNED_RESPONSE/SAVE_CANNED_RESPONSE',
  SAVE_CANNED_RESPONSE_REQUEST = 'ENTITIES/CANNED_RESPONSE/SAVE_CANNED_RESPONSE_REQUEST',
  SAVE_CANNED_RESPONSE_SUCCESS = 'ENTITIES/CANNED_RESPONSE/SAVE_CANNED_RESPONSE_SUCCESS',
  SAVE_CANNED_RESPONSE_FAILURE = 'ENTITIES/CANNED_RESPONSE/SAVE_CANNED_RESPONSE_FAILURE',

  UPDATE_CANNED_RESPONSE = 'ENTITIES/CANNED_RESPONSE/UPDATE_CANNED_RESPONSE_REQUEST',
  UPDATE_CANNED_RESPONSE_SUCCESS = 'ENTITIES/CANNED_RESPONSE/UPDATE_CANNED_RESPONSE_SUCCESS',
  UPDATE_CANNED_RESPONSE_FAILURE = 'ENTITIES/CANNED_RESPONSE/UPDATE_CANNED_RESPONSE_FAILURE',

  REMOVE_CANNED_RESPONSE = 'ENTITIES/CANNED_RESPONSE/REMOVE_CANNED_RESPONSE',
  REMOVE_CANNED_RESPONSE_SUCCESS = 'ENTITIES/CANNED_RESPONSE/REMOVE_CANNED_RESPONSE_SUCCESS',
  REMOVE_CANNED_RESPONSE_FAILURE = 'ENTITIES/CANNED_RESPONSE/REMOVE_CANNED_RESPONSE_FAILURE',
  REMOVE_CANNED_RESPONSE_DATA = 'ENTITIES/CANNED_RESPONSE/REMOVE_CANNED_RESPONSE_DATA',
  UPDATE_CANNED_RESPONSE_DATA = 'ENTITIES/CANNED_RESPONSE/UPDATE_CANNED_RESPONSE_DATA',

  FETCH_CANNED_RESPONSE_AUTOTAGS_BASE = 'ENTITIES/CANNED_RESPONSE/FETCH_CANNED_RESPONSE_AUTOTAGS',
  FETCH_CANNED_RESPONSE_AUTOTAGS_REQUEST = 'ENTITIES/CANNED_RESPONSE/FETCH_CANNED_RESPONSE_AUTOTAGS_REQUEST',
  FETCH_CANNED_RESPONSE_AUTOTAGS_SUCCESS = 'ENTITIES/CANNED_RESPONSE/FETCH_CANNED_RESPONSE_AUTOTAGS_SUCCESS',
  FETCH_CANNED_RESPONSE_AUTOTAGS_FAILURE = 'ENTITIES/CANNED_RESPONSE/FETCH_CANNED_RESPONSE_AUTOTAGS_FAILURE',
  CREATE_CANNED_RESPONSE_AUTOTAGS = 'ENTITIES/CANNED_RESPONSE/CREATE_CANNED_RESPONSE_AUTOTAGS_REQUEST',
  CREATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS = 'ENTITIES/CANNED_RESPONSE/CREATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS',
  UPDATE_CANNED_RESPONSE_AUTOTAGS = 'ENTITIES/CANNED_RESPONSE/UPDATE_CANNED_RESPONSE_AUTOTAGS_REQUEST',
  UPDATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS = 'ENTITIES/CANNED_RESPONSE/UPDATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS',
  REMOVE_CANNED_RESPONSE_AUTOTAGS = 'ENTITIES/CANNED_RESPONSE/REMOVE_CANNED_RESPONSE_AUTOTAGS_REQUEST',
  REMOVE_CANNED_RESPONSE_AUTOTAGS_SUCCESS = 'ENTITIES/CANNED_RESPONSE/REMOVE_CANNED_RESPONSE_AUTOTAGS_SUCCESS',
  REMOVE_AUTOTAG_FROM_CANNED_RESPONSE = 'ENTITIES/CANNED_RESPONSE/REMOVE_AUTOTAG_FROM_CANNED_RESPONSE_REQUEST',
  REMOVE_AUTOTAG_FROM_CANNED_RESPONSE_SUCCESS = 'ENTITIES/CANNED_RESPONSE/REMOVE_AUTOTAG_FROM_CANNED_RESPONSE_SUCCESS',
  BATCH_REMOVE_AUTOTAGS_FROM_CANNED_RESPONSES = 'ENTITIES/CANNED_RESPONSE/BATCH_REMOVE_AUTOTAGS_FROM_CANNED_RESPONSES',
  APPLY_AUTOTAGS = 'ENTITIES/CANNED_RESPONSE/APPLY_AUTOTAGS_REQUEST',
  CANNED_RESPONSE_AUTOTAGS_SET_DATA = 'ENTITIES/CANNED_RESPONSE/CANNED_RESPONSE_AUTOTAGS_SET_DATA',
}

const saveCannedResponseActions = {
  fetchCannedResponses: (payload: IFetchCannedResponsesPayload) =>
    createAction(CannedResponseActionNames.FETCH_CANNED_RESPONSES_REQUEST, payload),
  fetchCannedResponsesSuccess: (payload: ISetCannedResponsesDataPayload) =>
    createAction(CannedResponseActionNames.FETCH_CANNED_RESPONSES_SUCCESS, payload),
  fetchCannedResponsesFailure: (payload: IFetchCannedResponseFailurePayload) =>
    createAction(CannedResponseActionNames.FETCH_CANNED_RESPONSES_FAILURE, payload),
  saveCannedResponse: (payload: ISaveCannedResponsePayload) =>
    createAction(CannedResponseActionNames.SAVE_CANNED_RESPONSE_REQUEST, payload),
  saveCannedResponseSuccess: (payload: ICannedResponseSuccessPayload) =>
    createAction(CannedResponseActionNames.SAVE_CANNED_RESPONSE_SUCCESS, payload),
  saveCannedResponseFailure: (payload: ISaveCannedResponseFailurePayload) =>
    createAction(CannedResponseActionNames.SAVE_CANNED_RESPONSE_FAILURE, payload),
  updateCannedResponse: (payload: ISaveCannedResponsePayload) =>
    createAction(CannedResponseActionNames.UPDATE_CANNED_RESPONSE, payload),
  updateCannedResponseSuccess: () => createAction(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_SUCCESS),
  updateCannedResponseFailure: (payload: ISaveCannedResponseFailurePayload) =>
    createAction(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_FAILURE, payload),
  removeCannedResponse: (payload: IRemoveCannedResponsePayload) =>
    createAction(CannedResponseActionNames.REMOVE_CANNED_RESPONSE, payload),
  removeCannedResponseSuccess: () => createAction(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_SUCCESS),
  removeCannedResponseFailure: (payload: IRemoveCannedResponseFailurePayload) =>
    createAction(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_FAILURE, payload),
};

const cannedAutotagsActions = {
  updateCannedResponseAutotags: (payload: ICannedResponseAutotagsUpdatePayload) =>
    createAction(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_AUTOTAGS, payload),
  updateCannedResponseAutotagsSuccess: (payload: ICannedResponseAutotagsUpdateSuccessPayload) =>
    createAction(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS, payload),
  createCannedResponseAutotags: (payload: ICannedResponseAutotagsCreatePayload) =>
    createAction(CannedResponseActionNames.CREATE_CANNED_RESPONSE_AUTOTAGS, payload),
  createCannedResponseAutotagsSuccess: (payload: ICannedResponseAutoTagsCreateSuccessPayload) =>
    createAction(CannedResponseActionNames.CREATE_CANNED_RESPONSE_AUTOTAGS_SUCCESS, payload),
  fetchCannedResponseAutotags: () => createAction(CannedResponseActionNames.FETCH_CANNED_RESPONSE_AUTOTAGS_REQUEST),
  fetchCannedResponseAutotagsSuccess: (payload: IFetchCannedAutotagsSuccessPayload) =>
    createAction(CannedResponseActionNames.FETCH_CANNED_RESPONSE_AUTOTAGS_SUCCESS, payload),
  fetchCannedResponseAutotagsFailure: () =>
    createAction(CannedResponseActionNames.FETCH_CANNED_RESPONSE_AUTOTAGS_FAILURE),
  removeCannedResponseAutotags: (payload: ICannedResponseAutotagsRemovePayload) =>
    createAction(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_AUTOTAGS, payload),
  removeCannedResponseAutotagsSuccess: (payload: ICannedResponseAutotagsRemovePayload) =>
    createAction(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_AUTOTAGS_SUCCESS, payload),
  removeAutotagFromCannedResponse: (payload: IAutotagRemovePayload) =>
    createAction(CannedResponseActionNames.REMOVE_AUTOTAG_FROM_CANNED_RESPONSE, payload),
  removeAutotagFromCannedResponseSuccess: (payload: KeyMap<IAutoTagRemoveSuccessPayload>) =>
    createAction(CannedResponseActionNames.REMOVE_AUTOTAG_FROM_CANNED_RESPONSE_SUCCESS, payload),
  applyAutotags: (payload: IApplyAutotags) => createAction(CannedResponseActionNames.APPLY_AUTOTAGS, payload),
  setCannedResponseAutotagsData: (payload: IFetchCannedAutotagsSuccessPayload) =>
    createAction(CannedResponseActionNames.CANNED_RESPONSE_AUTOTAGS_SET_DATA, payload),
  batchRemoveAutotagsFromCannedResponses: (payload: IBatchAutotagRemovePayload[]) =>
    createAction(CannedResponseActionNames.BATCH_REMOVE_AUTOTAGS_FROM_CANNED_RESPONSES, payload),
};

export const CannedResponseActions = {
  ...saveCannedResponseActions,
  ...cannedAutotagsActions,
  addCannedResponseData: (payload: CannedResponse) =>
    createAction(CannedResponseActionNames.ADD_CANNED_RESPONSE_DATA, payload),
  removeCannedResponseData: (payload: IRemoveCannedResponsePayload) =>
    createAction(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_DATA, payload),
  updateCannedResponseData: (payload: CannedResponse) =>
    createAction(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_DATA, payload),
};

export type CannedResponseAction = ActionsUnion<typeof CannedResponseActions>;
