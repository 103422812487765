import { EnvironmentType } from 'constants/environment';
import { LocalStorageKey, getItem } from 'services/local-storage';

import { anyToBoolean } from './boolean';
import { getConfig } from './config';

export function getEnvironment(ignoreOverride = false): EnvironmentType {
  const config = getConfig();
  const lsEnvironment = getItem('environment');

  return (ignoreOverride ? config.env : lsEnvironment || config.env) as EnvironmentType;
}

/**
 * It will hide a feature behind a flag for environments that are not present in argument array.
 * @param envs Envs that will display the feature. example ['labs'] or ['labs', 'staging']
 */
export const showForGivenEnvironments = (envs: EnvironmentType[]): boolean => {
  const config = getConfig();
  const lsEnvironment = getItem('environment');

  if (
    lsEnvironment &&
    ((config.env as EnvironmentType) === EnvironmentType.Staging ||
      (config.env as EnvironmentType) === EnvironmentType.Labs)
  ) {
    return envs.some((env) => env === lsEnvironment);
  }

  return envs.some((env: string) => env === config.env);
};

export const isLabsEnvironment = (): boolean => {
  return showForGivenEnvironments([EnvironmentType.Labs]);
};

export const isDevelopmentEnvironment = (): boolean => {
  return showForGivenEnvironments([EnvironmentType.Labs, EnvironmentType.Staging]);
};

export const isStagingEnvironment = (): boolean => {
  return showForGivenEnvironments([EnvironmentType.Staging]);
};

export const isProductionEnvironment = (): boolean => {
  return showForGivenEnvironments([EnvironmentType.Production]);
};

export const applyNewDetailsColumnOnArchivesSection = (): boolean => {
  return anyToBoolean(getItem(LocalStorageKey.NewCustomerDetailsOnArchives));
};

export const shouldShowRichTextEditor = (): boolean => {
  return anyToBoolean(getItem(LocalStorageKey.RichTextEditor));
};

export const shouldShowAiResponses = (): boolean => {
  return anyToBoolean(getItem(LocalStorageKey.AISuggestions));
};

export const shouldShowPriorityChat = (): boolean => {
  return anyToBoolean(getItem(LocalStorageKey.PriorityChat));
};

export const shouldShowAICannedInChips = (): boolean => {
  return anyToBoolean(getItem(LocalStorageKey.AICannedInChips));
};
