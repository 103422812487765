import { type ActionsUnion, createAction } from 'store/helper';

import { createEntityActionNames, createEntityActions,} from '../actions';
import type { EntityFetchCollectionSuccess, EntityUpdate } from '../interfaces';

import { type IGreeting } from './interfaces';

const GREETING = createEntityActionNames('GREETING');

export const GreetingActionNames = {
  ...GREETING,
  FETCH_GREETINGS_TABLE: 'ENTITIES/GREETINGS/FETCH_TABLE',
  CREATE_GREETING: 'ENTITIES/GREETINGS/CREATE',
  CREATE_GREETING_SUCCESS: 'ENTITIES/GREETINGS/CREATE_SUCCESS',
  CREATE_GREETING_FAILURE: 'ENTITIES/GREETINGS/CREATE_FAILURE',
  DELETE_GREETING_SUCCESS: 'ENTITIES/GREETINGS/DELETE_SUCCESS',
  DELETE_GREETING_FAILURE: 'ENTITIES/GREETINGS/DELETE_FAILURE',
  DELETE_GREETING: 'ENTITIES/GREETINGS/DELETE',
  SET_GREETINGS: 'ENTITIES/GREETINGS/SET',
  SET_GREETING: 'ENTITIES/GREETINGS/SET_SINGLE',
  SET_VISIBILITY: 'ENTITIES/GREETINGS/SET_VISIBILITY',
  SET_VISIBILITY_SUCCESS: 'ENTITIES/GREETINGS/SET_VISIBILITY_SUCCESS',
  SET_VISIBILITY_FAILURE: 'ENTITIES/GREETINGS/SET_VISIBILITY_FAILURE',
  DUPLICATE_GREETING: 'ENTITIES/GREETINGS/DUPLICATE',
  UPDATE_ONBOARDING_GREETING: 'ENTITIES/GREETINGS/UPDATE_ONBOARDING_GREETING',
  CREATE_GREETING_FROM_MODAL: 'ENTITIES/GREETINGS/CREATE_FROM_MODAL',
};

export const GreetingActions = {
  ...createEntityActions<IGreeting>(GREETING),
  fetchGreetingsTable: () => createAction(GreetingActionNames.FETCH_GREETINGS_TABLE),
  createGreeting: (payload: IGreeting) => createAction(GreetingActionNames.CREATE_GREETING, payload),
  createGreetingSuccess: () => createAction(GreetingActionNames.CREATE_GREETING_SUCCESS),
  createGreetingFailure: () => createAction(GreetingActionNames.CREATE_GREETING_FAILURE),
  deleteGreeting: (greetingId: string) => createAction(GreetingActionNames.DELETE_GREETING, greetingId),
  deleteGreetingSuccess: () => createAction(GreetingActionNames.DELETE_GREETING_SUCCESS),
  deleteGreetingFailure: () => createAction(GreetingActionNames.DELETE_GREETING_FAILURE),
  setGreetings: (payload: EntityFetchCollectionSuccess<IGreeting>) =>
    createAction(GreetingActionNames.SET_GREETINGS, payload),
  setGreeting: (payload: IGreeting) => createAction(GreetingActionNames.SET_GREETING, payload),
  setGreetingVisibility: (greetingId: string, isActive: boolean) =>
    createAction(GreetingActionNames.SET_VISIBILITY, { greetingId, isActive }),
  setGreetingVisibilitySuccess: () => createAction(GreetingActionNames.SET_VISIBILITY_SUCCESS),
  setGreetingVisibilityFailure: () => createAction(GreetingActionNames.SET_VISIBILITY_FAILURE),
  duplicateGreeting: (greetingId: string) => createAction(GreetingActionNames.DUPLICATE_GREETING, greetingId),
  updateOnboardingGreeting: (payload: EntityUpdate<IGreeting>) =>
    createAction(GreetingActionNames.UPDATE_ONBOARDING_GREETING, payload),
  createGreetingFromModal: (payload: IGreeting) =>
    createAction(GreetingActionNames.CREATE_GREETING_FROM_MODAL, payload),
};

export type GreetingAction = ActionsUnion<typeof GreetingActions>;
