import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const knowledgeSourcesLabel = (isActive = false): string => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${isActive ? 1 : 0.6};
  gap: var(${SpacingToken.Spacing2});
  color: var(${DesignToken.ContentLockedWhite});
`;

export const wrapper = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  flex-grow: 1;
  min-width: 0;
`;

export const itemsCount = css`
  margin-left: auto;
`;

export const betaTag = css`
  background: linear-gradient(95deg, #513e79 0%, #1f2d44 110.26%);

  span {
    background: var(${DesignToken.ContentBasicGradient01});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
`;

export const knowledgeSourcesItem = css`
  [class^='lc-SideNavigationItem-module__side-navigation-item__custom-label'] {
    width: 100%;
  }
`;
