import { createAction, type ActionsUnion } from 'store/helper';

import {
  type SaveCustomSegmentsPayload,
  type SelectCustomSegmentPayload,
  type UpdateCustomSegmentLabelPayload,
  type LoadCustomSegmentsPayload,
  type UpdateCustomSegmentLabelSuccessPayload,
  type UpdateCustomSegmnetsOrderPayload,
  type DeleteCustomSegmentPayload,
  type DeleteCustomSegmentSuccessPayload,
  type SaveCustomSegmentPayload,
  type SaveCustomSegmentSuccessPayload,
  type UpdateCustomSegmentPayload,
  type UpdateCustomSegmentSuccessPayload,
  type UpdateCustomSegmnetsOrderFailurePayload,
  type SetCustomSegmentEditModePayload,
  type UpdateCustomSegmentLabelFailurePayload,
  type UpdateCustomSegmentFailurePayload,
  type CreateCustomSegmentFailurePayload,
} from './interfaces';

export enum CustomSegmentsActionsNames {
  CREATE_CUSTOM_SEGMENT = 'FEATURES/CUSTOM_SEGMENTS/CREATE_CUSTOM_SEGMENT',
  CREATE_CUSTOM_SEGMENT_SUCCESS = 'FEATURES/CUSTOM_SEGMENTS/CREATE_CUSTOM_SEGMENT_SUCCESS',
  CREATE_CUSTOM_SEGMENT_FAILURE = 'FEATURES/CUSTOM_SEGMENTS/CREATE_CUSTOM_SEGMENT_FAILURE',
  UPDATE_CUSTOM_SEGMENT = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENT',
  UPDATE_CUSTOM_SEGMENT_SUCCESS = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENT_SUCCESS',
  UPDATE_CUSTOM_SEGMENT_FAILURE = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENT_FAILURE',
  SAVE_CUSTOM_SEGMENTS = 'FEATURES/CUSTOM_SEGMENTS/SAVE_CUSTOM_SEGMENTS',
  LOAD_CUSTOM_SEGMENTS = 'FEATURES/CUSTOM_SEGMENTS/LOAD_CUSTOM_SEGMENTS',
  SELECT_CUSTOM_SEGMENT = 'FEATURES/CUSTOM_SEGMENTS/SELECT_CUSTOM_SEGMENT',
  UPDATE_CUSTOM_SEGMENT_LABEL = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENT_LABEL',
  UPDATE_CUSTOM_SEGMENT_LABEL_SUCCESS = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENT_LABEL_SUCCESS',
  UPDATE_CUSTOM_SEGMENT_LABEL_FAILURE = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENT_LABEL_FAILURE',
  UPDATE_CUSTOM_SEGMENTS_ORDER = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENTS_ORDER',
  UPDATE_CUSTOM_SEGMENTS_ORDER_SUCCESS = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENTS_ORDER_SUCCESS',
  UPDATE_CUSTOM_SEGMENTS_ORDER_FAILURE = 'FEATURES/CUSTOM_SEGMENTS/UPDATE_CUSTOM_SEGMENTS_ORDER_FAILURE',
  DELETE_CUSTOM_SEGMENT = 'FEATURES/CUSTOM_SEGMENTS/DELETE_CUSTOM_SEGMENT',
  DELETE_CUSTOM_SEGMENT_SUCCESS = 'FEATURES/CUSTOM_SEGMENTS/DELETE_CUSTOM_SEGMENT_SUCCESS',
  DELETE_CUSTOM_SEGMENT_FAILURE = 'FEATURES/CUSTOM_SEGMENTS/DELETE_CUSTOM_SEGMENT_FAILURE',
  SET_EDITED_CUSTOM_SEGMENT = 'FEATURES/CUSTOM_SEGMENTS/SET_EDITED_CUSTOM_SEGMENT',
  SET_CUSTOM_SEGMENT_EDITED_ID = 'FEATURES/CUSTOM_SEGMENTS/SET_CUSTOM_SEGMENT_EDITED_ID',
}

export const CustomSegmentsActions = {
  createCustomSegment: (payload: SaveCustomSegmentPayload) =>
    createAction(CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT, payload),
  createCustomSegmentSuccess: (payload: SaveCustomSegmentSuccessPayload) =>
    createAction(CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT_SUCCESS, payload),
  createCustomSegmentFailure: (payload: CreateCustomSegmentFailurePayload) =>
    createAction(CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT_FAILURE, payload),
  updateCustomSegment: (payload: UpdateCustomSegmentPayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT, payload),
  updateCustomSegmentSuccess: (payload: UpdateCustomSegmentSuccessPayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_SUCCESS, payload),
  updateCustomSegmentFailure: (payload: UpdateCustomSegmentFailurePayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_FAILURE, payload),
  saveCustomSegments: (payload: SaveCustomSegmentsPayload) =>
    createAction(CustomSegmentsActionsNames.SAVE_CUSTOM_SEGMENTS, payload),
  loadCustomSegments: (payload: LoadCustomSegmentsPayload) =>
    createAction(CustomSegmentsActionsNames.LOAD_CUSTOM_SEGMENTS, payload),
  selectCustomSegment: (payload: SelectCustomSegmentPayload) =>
    createAction(CustomSegmentsActionsNames.SELECT_CUSTOM_SEGMENT, payload),
  updateCustomSegmentLabel: (payload: UpdateCustomSegmentLabelPayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL, payload),
  updateCustomSegmentLabelSuccess: (payload: UpdateCustomSegmentLabelSuccessPayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL_SUCCESS, payload),
  updateCustomSegmentLabelFailure: (payload: UpdateCustomSegmentLabelFailurePayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL_FAILURE, payload),
  updateCustomSegmentsOrder: (payload: UpdateCustomSegmnetsOrderPayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER, payload),
  updateCustomSegmentsOrderSuccess: () => createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER_SUCCESS),
  updateCustomSegmentsOrderFailure: (payload: UpdateCustomSegmnetsOrderFailurePayload) =>
    createAction(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER_FAILURE, payload),
  deleteCustomSegment: (payload: DeleteCustomSegmentPayload) =>
    createAction(CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT, payload),
  deleteCustomSegmentSuccess: (payload: DeleteCustomSegmentSuccessPayload) =>
    createAction(CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT_SUCCESS, payload),
  deleteCustomSegmentFailure: () => createAction(CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT_FAILURE),
  setEditedSegment: (payload: SetCustomSegmentEditModePayload) =>
    createAction(CustomSegmentsActionsNames.SET_EDITED_CUSTOM_SEGMENT, payload),
  setEditedId: (payload: SetCustomSegmentEditModePayload) =>
    createAction(CustomSegmentsActionsNames.SET_CUSTOM_SEGMENT_EDITED_ID, payload),
};

export type CustomSegmentsAction = ActionsUnion<typeof CustomSegmentsActions>;
