import { type IApiSession } from 'interfaces/session';
import type { RequestResult } from 'interfaces/api/client';

import { BaseApi, PassVersionMode } from '../base-api';

export class SessionApi extends BaseApi {
  protected readonly prefix = 'v2/sessions';
  protected readonly passVersionMode: PassVersionMode = PassVersionMode.None;

  fetchAll = (): Promise<RequestResult<IApiSession[]>> => this.get('');
  deleteSingle = (sessionId: string): Promise<RequestResult<void>> => this.delete(`${sessionId}`);
  deleteAll = (): Promise<RequestResult<void>> => this.delete('');
}
