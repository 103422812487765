import { createStore, applyMiddleware, compose, type Action } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import 'regenerator-runtime/runtime'; // eslint-disable-line import/no-extraneous-dependencies

import { isDevelopmentEnvironment } from 'helpers/feature-toggle';
import type { IStoreState } from 'interfaces/store/store-state';

import { ReportBugActionNames } from './features/report-bug/actions';
import { generateIssueReport } from './helpers/store';
import { createActionHistory } from './middlewares/action-history';
import { actionStreamMiddleware } from './middlewares/action-stream';
import { reducers } from './reducers';
import { sagaMiddleware } from './sagas/middleware';

const composeEnhancers: typeof compose =
  /* eslint-disable no-underscore-dangle */
  (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: isDevelopmentEnvironment() ? 150 : 50 })) ||
  /* eslint-enable no-underscore-dangle */
  compose;
const middlewares = [
  sagaMiddleware,
  actionStreamMiddleware,
  createActionHistory({
    logActions: localStorage.debugStore,
    accessHistory: {
      actionType: ReportBugActionNames.DOWNLOAD_DATA,
      callback: generateIssueReport,
    },
  }),
];

export const store = createStore<IStoreState, Action<unknown>, unknown, unknown>(
  enableBatching(reducers),
  composeEnhancers(applyMiddleware(...middlewares)),
);
