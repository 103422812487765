import { type IInboxMessage } from '../message/inbox/inbox-message';
import { type IOutboxMessage } from '../message/outbox/outbox-message';

export const CONNECTION_MISSING_ERROR_MESSAGE = 'Widget connection is missing';
export const POSTMESSAGE_UNAVAILABLE_ERROR_MESSAGE = 'Iframe or postMessage mechanism is unavailable';

export interface IInboxMessageSubscriber {
  (inboxMessage: IInboxMessage, connectionId: string): void;
}

export interface IConnection {
  connectionId: string;
  initialize(): void;
  subscribe(subscriber: IInboxMessageSubscriber): void;
  unsubscribe(subscriber: IInboxMessageSubscriber): void;
  send(message: IOutboxMessage): Promise<void>;
  destroy(): void;
  setContext(context: any): void;
  getContext(): any;
}
