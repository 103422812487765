import { IntegrationLicensePropertiesActionNames, type IntegrationLicensePropertiesAction } from './actions';
import { type IIntegrationLicenseProperties } from './interfaces';

const initialState: IIntegrationLicenseProperties = {};

export function integrationLicensePropertiesReducer(
  state: IIntegrationLicenseProperties = initialState,
  action: IntegrationLicensePropertiesAction
): IIntegrationLicenseProperties {
  switch (action.type) {
    case IntegrationLicensePropertiesActionNames.FETCH_SUCCESS:
      return {
        ...action.payload,
      };

    case IntegrationLicensePropertiesActionNames.UPDATE_DATA:
    case IntegrationLicensePropertiesActionNames.FETCH_SINGLE_NAMESPACE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
