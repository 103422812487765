import uniqueId from 'lodash.uniqueid';

import { fetchWelcomeMessage } from 'services/chat-welcome-message';
import { getCustomerGroupIds, getCustomerRevokeChatId } from 'store/entities/customers/selectors';

import { AppStateProvider } from './app-state-provider';
import { chatsClient } from './connectivity/agent-chat-api/chats/client';
import { type ResumeChatResponse } from './connectivity/agent-chat-api/chats/types/resume-chat';
import { type StartChatResponse } from './connectivity/agent-chat-api/chats/types/start-chat';
import { type AgentChatApiResponse, UserType, Visibility } from './connectivity/agent-chat-api/types';
import { EventType } from './connectivity/agent-chat-api/types/event';

export async function startChat(
  customerId: string,
  message?: string
): Promise<AgentChatApiResponse<StartChatResponse | ResumeChatResponse>> {
  const state = AppStateProvider.getState();
  const groupIds: number[] = (getCustomerGroupIds(state, customerId) || []).map(Number);

  const welcomeMessage: string = message || (await fetchWelcomeMessage(customerId));

  const revokeChatId: string | undefined = getCustomerRevokeChatId(state, customerId);

  const chat = {
    access: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      group_ids: groupIds,
    },
    thread: {
      events: [
        {
          type: EventType.MESSAGE,
          visibility: Visibility.ALL,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          custom_id: uniqueId(),
          text: welcomeMessage,
        },
      ],
    },
  };

  return revokeChatId
    ? chatsClient.resumeChat({
        chat: {
          ...chat,
          id: revokeChatId,
        },
      })
    : chatsClient.startChat({
        chat: {
          ...chat,
          users: [
            {
              id: customerId,
              type: UserType.CUSTOMER,
            },
          ],
        },
      });
}
