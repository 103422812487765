import { ReportType } from 'constants/reports/report-type';

import { achievedGoalsDeserializer } from './data-deserializers/achieved-goals-deserializer';
import { activityDeserializer } from './data-deserializers/activity-deserializer';
import {
  agentsPerformanceDeserializer,
  agentsPerformanceDataMutator,
} from './data-deserializers/agents-performance-deserializer';
import {
  deserializeChatAvailability,
  deserializeChatsByStatus,
} from './data-deserializers/chat-availability-deserializer';
import { chatDurationDeserializer } from './data-deserializers/chat-duration-deserializer';
import { deserializeChatEngagement } from './data-deserializers/chat-engagement-deserializer';
import { chatRatingsDeserializer } from './data-deserializers/chat-ratings-deserializer';
import { deserializeChatResponseTime } from './data-deserializers/chat-response-time-deserializer';
import { chatSurveyDeserializer } from './data-deserializers/chat-survey-deserializer';
import { greetingsConversionDeserializer } from './data-deserializers/greetings-conversion-deserializer';
import { deserializeLast7Days } from './data-deserializers/last-7-days-deserializer';
import { missedChatsDeserializer } from './data-deserializers/missed-chats-deserializer';
import { newTicketsDeserializer } from './data-deserializers/new-tickets-deserializer';
import { deserializeScheduledReports } from './data-deserializers/scheduled-reports-deserializer';
import { solvedTicketsDeserializer } from './data-deserializers/solved-tickets-deserializer';
import { staffingPredictionDeserializer } from './data-deserializers/staffing-prediction-deserializer';
import { tagsUsageDeserializer } from './data-deserializers/tags-usage-deserializer';
import { ticketFirstResponseTimeDeserializer } from './data-deserializers/ticket-first-response-time-deserializer';
import { ticketRatingsDeserializer } from './data-deserializers/ticket-ratings-deserializer';
import { ticketResolutionTimeDeserializer } from './data-deserializers/ticket-resolution-time-deserializer';
import { deserializeTotalChats } from './data-deserializers/total-chats-deserializer';
import { trackedSalesDeserializer } from './data-deserializers/tracked-sales-deserializer';

export const dataMutator = {
  [ReportType.AgentsPerformance]: agentsPerformanceDataMutator,
};

export default {
  [ReportType.Activity]: activityDeserializer,
  [ReportType.AgentsPerformance]: agentsPerformanceDeserializer,
  [ReportType.Last7days]: deserializeLast7Days,
  [ReportType.TotalChats]: deserializeTotalChats,
  [ReportType.ChatEngagement]: deserializeChatEngagement,
  [ReportType.ChatRatings]: chatRatingsDeserializer,
  [ReportType.TicketRatings]: ticketRatingsDeserializer,
  [ReportType.ChatDuration]: chatDurationDeserializer,
  [ReportType.ChatResponseTime]: deserializeChatResponseTime,
  [ReportType.ChatAvailability]: deserializeChatAvailability,
  deserializeChatsByStatus,
  [ReportType.MissedChats]: missedChatsDeserializer,
  [ReportType.Greetings]: greetingsConversionDeserializer,
  [ReportType.ScheduledReports]: deserializeScheduledReports,
  [ReportType.SolvedTickets]: solvedTicketsDeserializer,
  [ReportType.TicketFirstResponseTime]: ticketFirstResponseTimeDeserializer,
  [ReportType.NewTickets]: newTicketsDeserializer,
  [ReportType.TicketResolutionTime]: ticketResolutionTimeDeserializer,
  [ReportType.Goals]: achievedGoalsDeserializer,
  [ReportType.Sales]: trackedSalesDeserializer,
  [ReportType.StaffingPrediction]: staffingPredictionDeserializer,
  [ReportType.ChatForms]: chatSurveyDeserializer,
  [ReportType.TagsUsage]: tagsUsageDeserializer,
};
