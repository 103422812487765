import { getConfig } from 'helpers/config';
import { logRequestError } from 'services/analytics/log-request-error';
import { getAccessToken } from 'services/auth/auth-storage-manager';

import { createApiClient } from '../http/client';
import { type ApiClientInstance } from '../http/types';

export const createCustomStatusApiClient = (prefix: string): ApiClientInstance => {
  return createApiClient({
    baseURL: getConfig().customStatusApiUrl,
    getCredentials: getAccessToken,
    prefix,
    onError: logRequestError,
  });
};
