import { ITourStep, TourStepType } from '../interfaces';

import { SendTicketsToStep } from './tour-steps';

export enum TicketFormSettingsTourSteps {
  SendTicketsTo = 'SendTicketsTo',
}

export const ticketFormSettingsSteps: ITourStep[] = [
  {
    id: TicketFormSettingsTourSteps.SendTicketsTo,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <SendTicketsToStep />,
      placement: 'left',
    },
  },
];
