// @ts-strict-ignore
import { Encoding } from 'constants/reports/encoding';
import { FileFormat } from 'constants/reports/file-format';
import { getNextReportFromPeriod, getRecursFromPeriod, getSourceFromType, getReportId } from 'helpers/reports';
import { type IScheduledReportsData } from 'interfaces/reports';


export function deserializeScheduledReports(rawData: any): IScheduledReportsData {
  const data = {};
  const sortedIds = [];

  const reports = rawData.data ? rawData.data : rawData;

  reports.scheduledReports.forEach((report) => {
    const { email, encoding, format, name, period, status, type, licence_id } = report;

    const id = getReportId(type, period);
    const source = getSourceFromType(type);
    const recurs = getRecursFromPeriod(period);
    const nextReport = getNextReportFromPeriod(period);
    const fileFormat = encoding === Encoding.Utf16le ? FileFormat.TsvExcel : format;

    sortedIds.push(id);
    data[id] = {
      id,
      recipients: email,
      format: fileFormat,
      name,
      recurs,
      status,
      source,
      licenceId: licence_id,
      nextReport,
    };
  });

  return {
    data,
    sortedIds,
  };
}

export function deserializeScheduledReportCreate(rawData) {
  const { encoding, email, format, period, status = 'scheduled', type, licence_id } = rawData;

  const id = getReportId(type, period);
  const source = getSourceFromType(type);
  const recurs = getRecursFromPeriod(period);
  const nextReport = getNextReportFromPeriod(period);
  const fileFormat = encoding === Encoding.Utf16le ? FileFormat.TsvExcel : format;
  const reportName = `${type}Export`;

  const data = {
    id,
    recipients: email,
    format: fileFormat,
    name: reportName,
    recurs,
    status,
    source,
    licenceId: licence_id,
    nextReport,
  };

  return data;
}

export function deserializeScheduledReportEdit(rawData) {
  const { encoding, email, format, name, period, status, type, licence_id, typeCurrent, periodCurrent } = rawData;

  const id = getReportId(type, period);
  const source = getSourceFromType(type);
  const recurs = getRecursFromPeriod(period);
  const nextReport = getNextReportFromPeriod(period);
  const fileFormat = encoding === Encoding.Utf16le ? FileFormat.TsvExcel : format;
  const reportName = `${type}Export`;

  const data = {
    id,
    recipients: email,
    format: fileFormat,
    name: name || reportName,
    recurs,
    status: status || 'scheduled',
    source,
    licenceId: licence_id,
    nextReport,
    typeCurrent,
    periodCurrent,
  };

  return data;
}
