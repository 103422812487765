import { type ILanguageBase } from 'interfaces/entities/language';

export enum LanguageCode {
  Arabic = 'ar',
  Armenian = 'hy',
  Azerbaijan = 'az',
  Bulgarian = 'bg',
  Catalan = 'ca',
  ChineseSimplified = 'cn',
  ChineseTraditional = 'zh',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  English = 'en',
  Estonian = 'et',
  Farsi = 'fa',
  Finnish = 'fi',
  French = 'fr',
  Georgian = 'ka',
  German = 'de',
  Greek = 'el',
  Hebrew = 'he',
  Hindi = 'hi',
  Hungarian = 'hu',
  Icelandic = 'is',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Kazakh = 'kk',
  Korean = 'ko',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Madagascar = 'mg',
  Malaysian = 'ms',
  Norwegian = 'no',
  NorwegianBokmal = 'nb',
  Polish = 'pl',
  Portuguese = 'pt',
  PortugueseBrazilian = 'br',
  Romanian = 'ro',
  Russian = 'ru',
  Serbian = 'sr',
  Slovak = 'sk',
  Slovene = 'sl',
  Spanish = 'es',
  Swedish = 'sv',
  Thai = 'th',
  Turkish = 'tr',
  Ukrainian = 'uk',
  Vietnamese = 'vi',
}

export const LANGUAGE_NAMES = {
  [LanguageCode.Arabic]: 'العَرَبِيَّة',
  [LanguageCode.Armenian]: 'Հայերեն',
  [LanguageCode.Azerbaijan]: 'Azərbaycan',
  [LanguageCode.Bulgarian]: 'български',
  [LanguageCode.Catalan]: 'Català',
  [LanguageCode.ChineseSimplified]: '简体中文',
  [LanguageCode.ChineseTraditional]: '繁體中文',
  [LanguageCode.Croatian]: 'Hrvatski',
  [LanguageCode.Czech]: 'Česky',
  [LanguageCode.Danish]: 'Dansk',
  [LanguageCode.Dutch]: 'Nederlands',
  [LanguageCode.English]: 'English',
  [LanguageCode.Estonian]: 'Eesti',
  [LanguageCode.Farsi]: 'زبان فارسی',
  [LanguageCode.Finnish]: 'Suomi',
  [LanguageCode.French]: 'Français',
  [LanguageCode.Georgian]: 'ქართული',
  [LanguageCode.German]: 'Deutsch',
  [LanguageCode.Greek]: 'Ελληνικά',
  [LanguageCode.Hebrew]: 'עברית',
  [LanguageCode.Hindi]: 'हिन्दी',
  [LanguageCode.Hungarian]: 'Magyar',
  [LanguageCode.Icelandic]: 'Íslenska',
  [LanguageCode.Indonesian]: 'Bahasa Indonesia',
  [LanguageCode.Italian]: 'Italiano',
  [LanguageCode.Japanese]: '日本語',
  [LanguageCode.Kazakh]: 'қазақ тілі',
  [LanguageCode.Korean]: '한국어',
  [LanguageCode.Latvian]: 'Latviešu',
  [LanguageCode.Lithuanian]: 'Lietuvių',
  [LanguageCode.Madagascar]: 'Malagasy',
  [LanguageCode.Malaysian]: 'Bahasa Malaysia',
  [LanguageCode.NorwegianBokmal]: 'Norsk (bokmål)',
  [LanguageCode.Norwegian]: 'Norsk (nynorsk)',
  [LanguageCode.Polish]: 'Polski',
  [LanguageCode.PortugueseBrazilian]: 'Português brasileiro',
  [LanguageCode.Portuguese]: 'Português',
  [LanguageCode.Romanian]: 'Română',
  [LanguageCode.Russian]: 'Русский',
  [LanguageCode.Serbian]: 'Srpski',
  [LanguageCode.Slovak]: 'Slovenčina',
  [LanguageCode.Slovene]: 'Slovenščina',
  [LanguageCode.Spanish]: 'Español',
  [LanguageCode.Swedish]: 'Svenska',
  [LanguageCode.Thai]: 'ไทย',
  [LanguageCode.Turkish]: 'Türkçe',
  [LanguageCode.Ukrainian]: 'Українська',
  [LanguageCode.Vietnamese]: 'Tiếng Việt',
};
export const ALL_LANGUAGES: ILanguageBase[] = Object.values(LanguageCode).map((languageCode: LanguageCode) => ({
  languageCode,
  name: LANGUAGE_NAMES[languageCode],
}));
