import { type IGTMState } from './interfaces';

export interface IWithGTMState {
  features: {
    googleTagManager: IGTMState;
  };
}

export function getGoogleTagManager(state: IWithGTMState): IGTMState {
  return state.features.googleTagManager;
}

export function getContainerId(state: IWithGTMState): string {
  return state.features.googleTagManager.installation.selectedContainerId;
}

export function getAccountId(state: IWithGTMState): string {
  return state.features.googleTagManager.installation.selectedAccountId;
}

export function getContainers(state: IWithGTMState) {
  return state.features.googleTagManager.installation.containers;
}

export function getAccounts(state: IWithGTMState) {
  return state.features.googleTagManager.installation.accounts;
}
