import { type SagaIterator } from 'redux-saga';
import { put, delay, takeEvery } from 'redux-saga/effects';

import { type IActionWithPayload } from 'store/helper';

import { ToastsActions, ToastsActionsNames } from './actions';
import type { ToastsCreatePayload } from './interfaces';

export function* createToast(action: IActionWithPayload<ToastsActionsNames, ToastsCreatePayload>): SagaIterator {
  const { payload } = action;
  yield put(ToastsActions.showToast({ ...payload }));
  if (payload.autoHideDelayTime) {
    yield delay(payload.autoHideDelayTime);
    yield put(ToastsActions.removeToast({ id: payload.id }));
  }
}

export default function* archivesSagas(): SagaIterator {
  yield takeEvery(ToastsActionsNames.CREATE, createToast);
}
