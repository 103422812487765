import type { INavigationBadgeProps } from '../navigation-badge/NavigationBadge';

export type BadgeProps = {
  type: INavigationBadgeProps['type'];
  count?: INavigationBadgeProps['count'];
} | null;

export const calculateBadgeProps = (
  canManageInvitations: boolean,
  wasTeamApproveAllSeen: boolean,
  hasPendingInvites: boolean,
  waitingForApprovalAgentsCount: number,
  pendingInvitesCount: number
): BadgeProps => {
  if (!canManageInvitations) {
    return hasPendingInvites ? { type: 'counter', count: pendingInvitesCount } : null;
  }

  if (hasPendingInvites && wasTeamApproveAllSeen) {
    return { type: 'counter', count: waitingForApprovalAgentsCount + pendingInvitesCount };
  }

  if (!waitingForApprovalAgentsCount) {
    return null;
  }

  if (!wasTeamApproveAllSeen && waitingForApprovalAgentsCount > 1) {
    return { type: 'alert' };
  }

  return { type: 'counter', count: waitingForApprovalAgentsCount };
};
